import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Transition, TransitionService } from '@uirouter/core';
import { Constants } from '../Common/Constants';
import { Global } from '../Common/Global';
import { SessionService } from '../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'company',
  templateUrl: './Company.component.html'
})
export class CompanyComponent implements OnInit {
  private editModeValue = false;
  @Input()
  public get editMode(): boolean {
    return this.editModeValue;
  }
  public set editMode(value: boolean) {
    if (this.editModeValue !== value) {
      this.editModeValue = value;
      this.editModeChange.emit(value);
    }
  }
  @Output() public editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get IsMobile(): boolean {
    return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
  }

  public mobileWarningDialogVisible = false;
  public isDirty = false;
  public showSaveDataConfirmationChangeState = false;
  public companyClick = false;

  private get companyModuleTabEnabled(): boolean {
    return this.sessionService.role.IsFullAdmin;
  }
  private get companyOptionTabEnabled(): boolean {
    return this.sessionService.role.IsFullAdmin || this.sessionService.role.IsReadOnly;
  }
  private get companyDataImportTabEnabled(): boolean {
    return !this.sessionService.role.IsReadOnly;
  }
  private get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  private isCompanyTemplateState = false;
  private isCompanyGenneralState = false;
  private navigationToStateName: string;

  constructor(public sessionService: SessionService, private transitionService: TransitionService) {}

  public ngOnInit(): void {
    if (this.sessionService.companyTabState !== '') {
      this.sessionService.currentState = this.sessionService.companyTabState;
      setTimeout(() => {
        this.checkSwitchingCompanyTab();
      });
    } else {
      const currentRoleKey: string = Global.SESSION.CurrentRole.Key;
      if (currentRoleKey) {
        switch (currentRoleKey) {
          case 'FullAdmin':
            this.sessionService.NavigateTo('tabs.company.salarybatches');
            break;
          case 'ReadOnly':
            this.sessionService.NavigateTo('tabs.company.salarybatches');
            break;
          case 'Employee':
            this.sessionService.NavigateTo('tabs.selfservice.payslip');
            break;
          case 'SalaryAdmin':
            this.sessionService.NavigateTo('tabs.company.salarybatches');
            break;
          default:
            break;
        }
      }
    }

    this.transitionService.onStart({}, (transition: Transition) => {
      const fromState: string = transition.from().name;
      this.navigationToStateName = transition.to().name;

      const generalState = 'tabs.company.general';
      const templateState = 'tabs.company.configuration.hiringstatus';
      const integrationState = 'tabs.company.configuration.integrations';

      if (
        this.editMode &&
        fromState &&
        (fromState === generalState || fromState === templateState || fromState === integrationState)
      ) {
        if (this.isDirty) {
          // If validation fails, alert user and do nothing.
          // Else show action confirmation.
          if (fromState === templateState) {
            this.isCompanyTemplateState = true;
          }
          if (fromState === generalState) {
            this.isCompanyGenneralState = true;
          }
          this.showSaveDataConfirmationChangeState = true;
          return false;
        } else {
          // Editmode but no changes, we close editmode without doing anything
          this.editMode = false;
          return true;
        }
      } else {
        this.editMode = false;
        this.editModeChange.emit(false);
        return true;
      }
    });

    this.mobileWarningDialogVisible =
      this.sessionService.browser.isHybridApp &&
      (this.sessionService.currentState === 'tabs.company.configuration.dataimport' ||
        this.sessionService.currentState === 'tabs.company.configuration.timeentrytypes');
  }

  public checkSwitchingCompanyTab(): void {
    if (this.sessionService.currentState) {
      switch (this.sessionService.currentState) {
        case 'tabs.company.configuration.settings':
          if (!this.companyOptionTabEnabled) {
            this.sessionService.checkNavigateDefaultRole(false, true);
          } else {
            this.sessionService.NavigateTo(this.sessionService.companyTabState);
          }
          break;
        case 'tabs.company.configuration.dataimport':
          if (!this.companyDataImportTabEnabled) {
            this.sessionService.checkNavigateDefaultRole(false, true);
          } else {
            this.sessionService.NavigateTo(this.sessionService.companyTabState);
          }
          break;
        case 'tabs.company.modules':
          if (!this.companyModuleTabEnabled || this.IsGreenlandCompany) {
            this.sessionService.checkNavigateDefaultRole(false, true);
          } else {
            this.sessionService.NavigateTo(this.sessionService.companyTabState);
          }
          break;
        default:
          this.sessionService.NavigateTo(this.sessionService.companyTabState);
          break;
      }
    }
  }

  public onConfirmSaveDataOnChangeState(action: string): void {
    if (action === 'ContinueAndSave') {
      this.editMode = false;
      if (this.navigationToStateName) {
        if (!this.isCompanyTemplateState && !this.isCompanyGenneralState) {
          this.sessionService.NavigateTo(this.navigationToStateName);
        } else {
          this.sessionService.nextState = this.navigationToStateName;
        }
      }
    } else if (action === 'DiscardAndLeave') {
      this.editMode = false;
      this.isDirty = false;
      if (this.navigationToStateName) {
        this.sessionService.NavigateTo(this.navigationToStateName);
      }
    }
    this.navigationToStateName = undefined;
  }

  public clickAreamenuToggledChange(): void {
    this.sessionService.menuToggled = true;
    this.sessionService.showUserMenuToggled = false;
    this.sessionService.showMoreMenuToggled = false;
    this.sessionService.showHelpMenuToggled = false;
    this.companyClick = true;
  }

  // Tabs
  public get isCompanyGeneralTabVisisble(): boolean {
    return this.sessionService.currentState.includes('tabs.company.general');
  }

  public get isCompanyApprovalVisisble(): boolean {
    return (
      this.sessionService.currentState.includes('tabs.company.approval') ||
      this.sessionService.currentState.includes('tabs.company.approval.timeentry')
    );
  }

  public get isCompanyPayrollDataTabVisisble(): boolean {
    return this.sessionService.currentState.includes('tabs.company.payrolldata');
  }

  public get isCompanySalaryBatchesTabVisisble(): boolean {
    return this.sessionService.currentState.includes('tabs.company.salarybatches');
  }

  public get isCompanyConfigurationTabVisisble(): boolean {
    return (
      this.sessionService.currentState.includes('tabs.company.configuration') ||
      this.sessionService.currentState.includes('tabs.company.configuration.advancedsalarytypes') ||
      this.sessionService.currentState.includes('tabs.company.configuration.timeentrytypes') ||
      this.sessionService.currentState.includes('tabs.company.configuration.settings') ||
      this.sessionService.currentState.includes('tabs.company.configuration.dataimport') ||
      this.sessionService.currentState.includes('tabs.company.configuration.integrations') ||
      this.sessionService.currentState.includes('tabs.company.configuration.hiringstatus')
    );
  }

  public get isCompanyExtraTabVisisble(): boolean {
    return this.sessionService.currentState.includes('tabs.company.modules');
  }
}
