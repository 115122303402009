<app-dialog
  [title]="'Accountant.AddNewCompany' | translate"
  [(visible)]="visible"
  (action)="dialogAction($event)"
  [minWidth]="700"
  [overlayClose]="false"
>
  <div class="Form--horizontal">
    <div>{{ 'Accountant.CreateNewCompanyIntro' | translate }}</div>
    <app-text-edit
      [label]="'Accountant.CVRNumber' | translate"
      [required]="CVRNumberValid"
      [editMode]="true"
      [type]="'number'"
      [(value)]="cvrNumber"
      (blur)="lookupCompany()"
      (valueChange)="isValidValue()"
    ></app-text-edit>
    <div *ngIf="seNumberRequired">{{ 'Accountant.CompanyExists' | translate }}</div>
    <app-text-edit
      [label]="'Accountant.SENumber' | translate"
      [required]="seNumberRequired"
      [editMode]="true"
      [type]="'number'"
      [(value)]="seNumber"
    ></app-text-edit>
    <app-text-edit
      [label]="'Accountant.CompanyName' | translate"
      [required]="nameValid"
      [editMode]="true"
      [(value)]="name"
      (valueChange)="isValidValue()"
    ></app-text-edit>
    <div class="FormElement PaddingTop">
      <span class="FormElement-label">{{ 'Accountant.SelectPackage' | translate }} :* </span>
      <div class="FormElement-control">
        <div class="FormElement">
          <app-radio-edit [editMode]="true" [(value)]="modulePackage">
            <app-radio-edit-option [value]="-1" [label]="'Accountant.None' | translate"></app-radio-edit-option>
            <app-radio-edit-option
              *ngFor="let package of staticData.ModulePackage | async"
              [value]="package.Id"
              [label]="package.Name"
            ></app-radio-edit-option>
          </app-radio-edit>
        </div>
        <div class="FormElement helpPackages">
          <a
            href="https://gratisal.dk/priser?_ga=2.190427307.1871532062.1552879033-1383993970.1544609209"
            target="_blank"
            >{{ 'Accountant.LearnModulePackage' | translate }}</a
          >
        </div>
      </div>
    </div>
    <check-edit
      [label]="'Accountant.AcceptTerms'"
      [editMode]="true"
      [(value)]="acceptTerms"
      [isLink]="true"
    ></check-edit>

    <hr class="FormElementHr" />

    <combobox-edit-control
      [label]="'Accountant.CopyTypesFrom' | translate"
      [nullValueLabel]="'DropdownList.None' | translate"
      [editMode]="true"
      [comboboxDataSource]="sourceCompanyFilter"
      [textField]="'Relevant'"
      [idField]="'Id'"
      [(value)]="salaryTypesFrom"
    ></combobox-edit-control>

    <combobox-edit-control
      [label]="'Accountant.CopyTemplatesFrom' | translate"
      [nullValueLabel]="'DropdownList.None' | translate"
      [editMode]="true"
      [comboboxDataSource]="sourceCompanyFilter"
      [textField]="'Relevant'"
      [idField]="'Id'"
      [(value)]="templatesFrom"
    ></combobox-edit-control>

    <check-edit
      [label]="'Accountant.GrantAllUsersAccess' | translate"
      [editMode]="true"
      [(value)]="grantAccess"
    ></check-edit>
    <combobox-edit-control
      [label]="'Accountant.AsRole' | translate"
      [editMode]="true"
      [disable]="!grantAccess"
      [required]="grantAccess"
      [comboboxDataSource]="staticData.Role | async"
      [idField]="'Id'"
      [textField]="'Name'"
      [(value)]="accessRole"
    ></combobox-edit-control>
  </div>
  <p innerHTML="{{ 'Accountant.CreateMessageEndForm' | translate }}">></p>

  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Create'" [disabled]="!isValid" [close]="false"></app-dialog-action>
</app-dialog>

<app-loading-tips
  class="loadScreenCreateCompany"
  [tiptext]="'Accountant.CreatingTheCompany' | translate"
  [visible]="isWaittingCreat"
></app-loading-tips>
