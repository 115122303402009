/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./NumericEdit.component.ngfactory";
import * as i2 from "./NumericEdit.component";
import * as i3 from "../Services/SharedServices/Session/SessionService";
import * as i4 from "./ShareAmount.component";
var styles_ShareAmountComponent = [];
var RenderType_ShareAmountComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShareAmountComponent, data: {} });
export { RenderType_ShareAmountComponent as RenderType_ShareAmountComponent };
export function View_ShareAmountComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "FormElement has-fixedAddon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "FormElement-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ":"])), (_l()(), i0.ɵeld(3, 0, null, null, 10, "div", [["class", "FormElement-control alignItems"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "numeric-edit", [], null, [[null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isDirtyChange" === en)) {
        var pd_0 = ((_co.childIsDirty[0] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.firstValue = $event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = (_co.onChange() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_NumericEditComponent_0, i1.RenderType_NumericEditComponent)), i0.ɵdid(5, 49152, null, 0, i2.NumericEditComponent, [i0.ElementRef, i3.SessionService, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], option: [3, "option"], disable: [4, "disable"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange" }), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "FormElement-addon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0\u00A0"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "FormElement-addon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["/"])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "numeric-edit", [], null, [[null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isDirtyChange" === en)) {
        var pd_0 = ((_co.childIsDirty[1] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.secondValue = $event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = (_co.onChange() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_NumericEditComponent_0, i1.RenderType_NumericEditComponent)), i0.ɵdid(11, 49152, null, 0, i2.NumericEditComponent, [i0.ElementRef, i3.SessionService, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], option: [3, "option"], disable: [4, "disable"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange" }), (_l()(), i0.ɵeld(12, 0, null, null, 1, "span", [["class", "FormElement-addon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0\u00A0"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.firstValue; var currVal_2 = _co.editMode; var currVal_3 = _co.childIsDirty[0]; var currVal_4 = _co.numericTextBoxOption; var currVal_5 = _co.disable; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.secondValue; var currVal_7 = _co.editMode; var currVal_8 = _co.childIsDirty[1]; var currVal_9 = _co.numericTextBoxOption; var currVal_10 = _co.disable; _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); }); }
export function View_ShareAmountComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "share-amount", [], null, null, null, View_ShareAmountComponent_0, RenderType_ShareAmountComponent)), i0.ɵdid(1, 180224, null, 0, i4.ShareAmountComponent, [], null, null)], null, null); }
var ShareAmountComponentNgFactory = i0.ɵccf("share-amount", i4.ShareAmountComponent, View_ShareAmountComponent_Host_0, { editMode: "editMode", isDirty: "isDirty", isValid: "isValid", disable: "disable", label: "label", firstValue: "firstValue", secondValue: "secondValue" }, { editModeChange: "editModeChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange", valueChange: "valueChange", firstValueChange: "firstValueChange", secondValueChange: "secondValueChange" }, []);
export { ShareAmountComponentNgFactory as ShareAmountComponentNgFactory };
