import { Component, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import { Global } from '../../../../Common/Global';
import { SalaryRecordItemHelper } from '../../../../Employee/EmployeeTab/Payroll/Controls/SalaryRecordItemHelper';
import { UnitTypeEnum } from '../../../../Model/Enum';
import {
  ISalaryRecordPersistenceType,
  ISalaryTypeView,
  ITemplateSalaryRecord,
  IUnitType
} from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';
import { ISalaryTemplateRecordView } from './ISalaryTemplateRecordView';

@Component({
  selector: 'salary-template',
  templateUrl: './SalaryTemplate.component.html'
})
export class SalaryTemplateComponent extends FormComponentBase implements OnInit {
  @Output() public salaryTemplateChange: EventEmitter<void> = new EventEmitter<void>();

  private visibleValue = false;
  @Input()
  get visible(): boolean {
    return this.visibleValue;
  }
  set visible(value: boolean) {
    if (this.visibleValue !== value) {
      if (value) {
        document.getElementById('salaryTemplateDialog').addEventListener('keydown', (event: any): void => {
          this.onKeyDown(event);
        });
      } else {
        document.getElementById('salaryTemplateDialog').removeEventListener('keydown', (event: any): void => {
          //this.onKeyDown(event);
        });
      }
      this.visibleValue = value;
      this.visibleChange.emit(value);
      if (value && (!this.editMode || this.loadSalaryRecords)) {
        this.loadSalaryTemplateRecords();
      }
    }
  }
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private templateIdValue: number;
  @Input()
  public get templateId(): number {
    return this.templateIdValue;
  }
  public set templateId(value: number) {
    if (this.templateIdValue !== value) {
      this.templateIdValue = value;
      this.loadSalaryRecords = true;
    }
  }

  private updatedSalaryTemplateValue: any;
  @Input()
  public get updatedSalaryTemplate(): any {
    return this.updatedSalaryTemplateValue;
  }
  public set updatedSalaryTemplate(value: any) {
    this.updatedSalaryTemplateValue = value;
    this.updatedSalaryTemplateChange.emit(value);
  }
  @Output() public updatedSalaryTemplateChange: EventEmitter<any> = new EventEmitter<any>();

  public gridData: ISalaryTemplateRecordView[] = [];
  public salaryTypes: ISalaryTypeView[];
  public unitTypes: IUnitType[];
  public persistanceTypes: ISalaryRecordPersistenceType[];
  public numericOptions: any = { format: 'n2', decimals: 2, step: 1, min: 0, spinners: false };
  public selectedRecord: ISalaryTemplateRecordView;
  public editDescriptionDialogVisible = false;
  public confirmationDialogVisible = false;
  private dirty = false;
  private loadSalaryRecords = true;

  constructor(
    private dataService: RxDataService,
    private staticDataService: StaticDataService,
    private renderer: Renderer2,
    public sessionService: SessionService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loadSalaryTypes();
    this.unitTypes = Global.UNIT_TYPES;
    // document.getElementById("salaryTemplateDialog").addEventListener("keydown", (event: any): void => {
    //     this.onKeyDown(event);
    // });
    this.staticDataService.SalaryRecordPersistenceType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalaryRecordPersistenceType[]) => (this.persistanceTypes = data)
    );
  }

  protected hasChanges(): boolean {
    return this.dirty;
  }

  private loadSalaryTypes(): void {
    this.dataService.SalaryTypes_GetSalaryTypeViews().subscribe((salaryTypes: ISalaryTypeView[]): void => {
      this.salaryTypes = salaryTypes.filter((salaryType: ISalaryTypeView) => salaryType.IsVisible);
    });
  }

  private loadSalaryTemplateRecords(): void {
    this.selectedRecord = undefined;
    this.loadSalaryRecords = false;
    this.dataService
      .EmploymentTemplates_GetTemplateSalaryRecordByEmploymentTemplate(this.templateId)
      .subscribe((salaryRecords: ITemplateSalaryRecord[]): void => {
        this.gridData = salaryRecords.map((data: ITemplateSalaryRecord) => {
          const view: any = data;
          view.GridId = view.Id;
          this.updateView(view);
          return view;
        });
      });
  }

  public onGridSelectionChange(record: ISalaryTemplateRecordView): void {
    this.selectedRecord = record;
  }

  public onGridAction(action: string): void {
    if (action === 'EditDescription' && this.selectedRecord) {
      this.editDescriptionDialogVisible = true;
    }
  }

  public onEditDescriptionDialogAction(): void {
    this.onGridSave(this.selectedRecord);
  }

  public onGridAdd(record: any): void {
    (record as any).GridId = 0 - (this.gridData.length + 1);
    record.Id = null;
    record.Units = null;
    record.AmountPerUnit = null;
    record.Amount = null;
    record.BaseAmount = null;
    record.AllowEditUnitType = true;
    record.AmountPerUnitEnabled = true;
    record.EmploymentTemplateId = this.templateId;
    record.IsActive = true;
    record.SalaryTypeId = this.salaryTypes[0].SalaryTypeId;
    record.PersistenceTypeId = this.persistanceTypes[0].Id;

    this.selectedRecord = record;
    const salaryRecordItemHelper: SalaryRecordItemHelper = new SalaryRecordItemHelper(
      this.selectedRecord,
      this.renderer,
      this.persistanceTypes,
      this.salaryTypes
    );
    salaryRecordItemHelper.updateRelavantFields({ SalaryTypeId: this.selectedRecord.SalaryTypeId }, false);

    this.updateView(this.selectedRecord);
    this.updatedSalaryTemplate = this.gridData;
    this.setComponentDirty();
  }

  public customDeleteRow = false;
  private gridRemoveRowArgs: any;
  public onGridRemove(args: any): void {
    if (this.customDeleteRow) {
      const dataItem: any = args ? args.dataItem : args;
      if (dataItem && dataItem.Id) {
        this.gridRemoveRowArgs = args;
        this.confirmationDialogVisible = true;
      } else if (dataItem) {
        const dataIndex: any = this.gridData.indexOf(dataItem);
        this.gridData.splice(dataIndex, 1);
        this.updatedSalaryTemplate = this.gridData;
        this.setComponentDirty();
      }
    } else {
      this.updatedSalaryTemplate = this.gridData;
      this.setComponentDirty();
    }
  }

  public onConfirmationDialogAction(action: string): void {
    if (action === 'Delete' && this.gridRemoveRowArgs) {
      const indexOfRemovedItem: number = this.gridData.indexOf(this.gridRemoveRowArgs.dataItem);
      this.gridData.splice(indexOfRemovedItem, 1);
      this.gridRemoveRowArgs.sender.closeRow(this.gridRemoveRowArgs.rowIndex);
      this.updatedSalaryTemplate = this.gridData;
      this.setComponentDirty();
    }

    this.gridRemoveRowArgs = undefined;
  }

  public onGridSave(args: any): void {
    const { dataItem, column, formGroup } = args;
    this.selectedRecord = dataItem ? dataItem : args;
    const modifiedField: any = {};
    if (column) {
      modifiedField[column.field] = dataItem[column.field];
    }

    const salaryRecordItemHelper: SalaryRecordItemHelper = new SalaryRecordItemHelper(
      this.selectedRecord,
      this.renderer,
      this.persistanceTypes,
      this.salaryTypes
    );
    const valid: boolean = salaryRecordItemHelper.validate(modifiedField);
    if (!valid) {
      if (column && column.field && formGroup && formGroup.value) {
        // Revert invalid values.
        dataItem[column.field] = formGroup.value[column.field];
      }

      return;
    }

    salaryRecordItemHelper.updateRelavantFields(modifiedField, false);
    this.updateView(this.selectedRecord);
    this.updatedSalaryTemplate = this.gridData;
    this.setComponentDirty();
  }

  private updateView(view: ISalaryTemplateRecordView): void {
    const salaryType: ISalaryTypeView = this.getSalaryType(view.SalaryTypeId);
    if (salaryType) {
      view.AllowEditUnitType = salaryType.AllowEditUnitType;
      view.UnitTypeClass = view.AllowEditUnitType ? '' : 'grid-disable-cell';
    }

    view.UnitsEnabled = !!view.UnitTypeId;
    view.UnitsClass = view.UnitsEnabled ? '' : 'grid-disable-cell';

    view.AmountPerUnitEnabled = !!view.UnitTypeId && view.UnitTypeId !== UnitTypeEnum.Percent;
    view.AmountPerUnitClass = view.AmountPerUnitEnabled ? '' : 'grid-disable-cell';
  }

  private getSalaryType(salaryTypeId: number): ISalaryTypeView {
    if (!salaryTypeId || !this.salaryTypes || this.salaryTypes.length === 0) {
      return undefined;
    }

    return this.salaryTypes.find((e: ISalaryTypeView) => e.SalaryTypeId === salaryTypeId);
  }

  private setComponentDirty(): void {
    this.dirty = true;
    this.onChange();
    this.salaryTemplateChange.emit();
  }

  public refreshData = false;
  private keyPress: any = { 17: false, 18: false, 107: false, 109: false };
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: any): void {
    if (this.visible) {
      if (this.editMode) {
        if (event.keyCode in this.keyPress) {
          this.keyPress[event.keyCode] = true;
          if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
            const defaultDataItem: any = {};
            this.onGridAdd(defaultDataItem);
            this.setKeypress();
            this.gridData.push(defaultDataItem);
            this.refreshData = true;
          } else if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
            if (this.selectedRecord) {
              this.onGridRemove(this.selectedRecord);
              this.setKeypress();
              this.refreshData = true;
              this.selectedRecord = undefined;
            }
          }
        } else {
          this.setKeypress();
        }
      }
    }
  }

  private setKeypress(): void {
    this.keyPress[17] = false;
    this.keyPress[18] = false;
    this.keyPress[107] = false;
    this.keyPress[109] = false;
  }
}
