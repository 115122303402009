import { EventEmitter } from '@angular/core';
import { RxDataService } from '../../../../Services/RxDataService';
var CompanyTemplateEmployeeListComponent = /** @class */ (function () {
    function CompanyTemplateEmployeeListComponent(dataService) {
        this.dataService = dataService;
        this.hasEmployeesChange = new EventEmitter();
        this.employees = [];
    }
    Object.defineProperty(CompanyTemplateEmployeeListComponent.prototype, "selectedTemplateId", {
        get: function () {
            return this.currentSelectedTemplateId;
        },
        set: function (value) {
            if (this.currentSelectedTemplateId !== value) {
                this.currentSelectedTemplateId = value;
                this.getEmployees();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplateEmployeeListComponent.prototype, "hasEmployees", {
        get: function () {
            return this.templateHasEmployees;
        },
        set: function (value) {
            var _this = this;
            if (this.templateHasEmployees !== value) {
                this.templateHasEmployees = value;
                setTimeout(function () { return _this.hasEmployeesChange.emit(value); });
            }
        },
        enumerable: true,
        configurable: true
    });
    CompanyTemplateEmployeeListComponent.prototype.getEmployees = function () {
        var _this = this;
        if (this.currentSelectedTemplateId) {
            this.dataService
                .EmploymentTemplates_GetCompanyUsersByEmploymentTemplate(this.currentSelectedTemplateId)
                .subscribe(function (employees) {
                _this.employees = employees;
                _this.hasEmployees = _this.employees && _this.employees.length > 0;
            });
        }
        else {
            this.employees = [];
            this.hasEmployees = false;
        }
    };
    return CompanyTemplateEmployeeListComponent;
}());
export { CompanyTemplateEmployeeListComponent };
