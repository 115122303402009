import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Constants } from '../../../../Common/Constants';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import { Global } from '../../../../Common/Global';
import { NumericTextBoxOptions } from '../../../../CustomControls/NumericTextBoxOptions';
import { ITaxCardModel } from '../../../../Model/ITaxCardModel';
import { ICompanyUser, IUserEmployment, IUserTaxInfo } from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'tax-card',
  templateUrl: './TaxCard.component.html'
})
export class TaxCardComponent extends FormComponentBase {
  @Output() reloadEmploymentEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() annualTaxComment: EventEmitter<void> = new EventEmitter<void>();
  @Output() companyUserChange: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();

  @Input() public allowEdit: boolean;
  @Input() public disable: boolean;
  @Input() public selfService = '';
  @Input() public isSelfService = false;
  private userEmploymentValue: IUserEmployment;
  private taxcardValue: ITaxCardModel;
  private companyUserValue: ICompanyUser;
  public option: NumericTextBoxOptions = { format: 'n0' };

  @Input()
  public get companyUser(): ICompanyUser {
    return this.companyUserValue;
  }
  public set companyUser(value: ICompanyUser) {
    if (!this.companyUserValue && !value) {
      return;
    }

    this.companyUserValue = value;
    this.companyUserChange.emit(value);
  }

  @Input()
  public get userEmployment(): IUserEmployment {
    if (this.userEmploymentValue === undefined) {
      this.userEmploymentValue = {} as any;
    }
    return this.userEmploymentValue;
  }

  public set userEmployment(value: IUserEmployment) {
    this.userEmploymentValue = value;
  }

  @Input()
  public get taxCard(): ITaxCardModel {
    if (!this.taxcardValue) {
      this.taxcardValue = { taxInfo: {} } as any;
    }

    if (!this.taxcardValue.taxInfo) {
      this.taxcardValue.taxInfo = {} as any;
    }

    return this.taxcardValue;
  }

  public set taxCard(value: ITaxCardModel) {
    if (value && value.taxInfo === undefined) {
      value.taxInfo = {} as IUserTaxInfo;
    }
    if (value && value.userEmploymentId && JSON.stringify(this.taxcardValue) !== JSON.stringify(value)) {
      this.loadEmploymentTaxInfo(value.userEmploymentId);
    }
    this.taxcardValue = value;
  }

  @Output() public requestTaxCard: EventEmitter<void> = new EventEmitter<void>();
  @Output() public viewTaxCardHistory: EventEmitter<IUserTaxInfo> = new EventEmitter<IUserTaxInfo>();
  @Output() public requestTaxcardConfirm: EventEmitter<IUserTaxInfo> = new EventEmitter<IUserTaxInfo>();
  @Output() public refreshTaxCardChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private refreshTaxCardValue: boolean;
  @Input() public get refreshTaxCard(): boolean {
    return this.refreshTaxCardValue;
  }
  public set refreshTaxCard(value: boolean) {
    if (this.refreshTaxCardValue !== value) {
      this.refreshTaxCardValue = value;
      this.refreshTaxCardChange.emit(value);
      if (value) {
        this.reloadTaxCard();
      }
    }
  }

  public get hasEmployment(): boolean {
    return this.taxCard && this.taxCard.userEmploymentId ? true : false;
  }

  public get hasSpecialTaxCardRule(): boolean {
    return this.hasEmployment && this.taxCard && this.taxCard.specialTaxBrief;
  }

  public get showTaxCardInfo(): boolean {
    return this.hasEmployment && ((this.taxCard && !this.taxCard.specialTaxBrief) || !this.taxCard);
  }

  public get showFreeAmount(): boolean {
    return this.taxCard && this.taxCard.taxInfo && this.taxCard.taxInfo.TaxCardTypeId === 3;
  }

  public get showDeductionPerMonth(): boolean {
    return this.taxCard && this.taxCard.taxInfo && this.taxCard.taxInfo.TaxCardTypeId === 1;
  }

  public get isAllowEditTaxCard(): boolean {
    return this.allowEdit && this.hasEmployment;
  }

  public get isTaxCardRequested(): boolean {
    return this.taxcardValue && this.taxcardValue.taxCardRequestTime ? true : false;
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get isDanishCompany(): boolean {
    return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
  }

  public get IsSelfServiceGenneral(): boolean {
    return this.sessionService.currentState === 'tabs.selfservice.general';
  }

  public get top8px(): string {
    return this.sessionService.currentState === 'tabs.selfservice.general' && this.editMode ? 'Top8px' : '';
  }

  public get isDanishCompanyAndInSelfServiceGenneral(): boolean {
    if (this.isDanishCompany && this.IsSelfServiceGenneral) {
      return true;
    }
    return false;
  }

  public get admin(): boolean {
    if (
      Global.SESSION.CurrentRole.Key === 'FullAdmin' ||
      Global.SESSION.CurrentRole.Key === 'SalaryAdmin' ||
      this.sessionService.currentState === 'tabs.selfservice.general'
    ) {
      return true;
    }
    return false;
  }

  constructor(private dataService: RxDataService, public sessionService: SessionService) {
    super();
    this.childIsValid = Array(5).fill(true);
    this.childIsDirty = Array(5).fill(false);
  }

  private showRequestTaxCardDialog(): void {
    this.requestTaxCard.emit();
  }

  public onViewTaxCardHistoryButtonClickEvent(): void {
    this.viewTaxCardHistory.emit(this.taxCard.taxInfo);
  }

  public onRequestTaxCardClickEvent(): void {
    if (this.isTaxCardRequested) {
      this.requestTaxcardConfirm.emit(this.taxCard.taxInfo);
    } else {
      this.showRequestTaxCardDialog();
    }
  }

  public reloadTaxCard(): void {
    this.loadEmploymentTaxInfo(this.taxCard.userEmploymentId);
    this.onChange();
    this.isDirty = false;
    this.reloadEmploymentEvent.emit();
  }

  public taxCardState: Subscription;
  private loadEmploymentTaxInfo(userEmploymentId: number): void {
    // if (this.taxCardState) {
    //   this.taxCardState.unsubscribe();
    //   return;
    // }
    this.dataService.CompanyUsers_GetCurrentTaxInfo(userEmploymentId).subscribe((data: IUserTaxInfo): void => {
      this.taxCard.taxInfo = data;
    });
  }

  public onControlChanged(): void {
    this.onChange();
  }
}
