import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { CellClickEvent, GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Global } from '../Common/Global';
import { ISimpleCompany } from '../Services/ApiModel';
import { SessionService } from '../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'app-company-selector',
  templateUrl: './CompanySelector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanySelectorComponent implements AfterViewInit {
  @Input() public companies: ISimpleCompany[] = [];

  private selectedCompanyIdValue: number;
  @Input()
  public get selectedCompanyId(): number {
    return this.selectedCompanyIdValue;
  }
  public set selectedCompanyId(value: number) {
    if (this.selectedCompanyIdValue !== value) {
      this.selectedCompanyIdValue = value;
      this.selectedCompanyIdChange.emit(value);
    }
  }

  @Output() public selectedCompanyIdChange: EventEmitter<number> = new EventEmitter<number>();

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
      if (value) {
        if (Global.SESSION && Global.SESSION.IsAccountant) {
          this.longList = true;
          this.companies.forEach((company: ISimpleCompany) => {
            (company as any).DisplayName =
              company.Name + (Global.SESSION.HasBackendAccess ? ' (' + company.Id + ')' : '');
          });
          this.companies.sort(this.compareByCompanyName);
          this.gridData = this.companies;

          if (!this.isMobile) {
            setTimeout(() => this.filterInput.nativeElement.focus());
          }
        } else {
          this.longList = false;
          this.companies.forEach((company: ISimpleCompany) => {
            (company as any).DisplayName =
              (company.Name.length < 30 ? company.Name : company.Name.substring(0, 30) + '...') +
              (Global.SESSION.HasBackendAccess ? ' (' + company.Id + ')' : '');
          });
          this.companies.sort(this.compareByCompanyName);
          this.comboBoxData = this.companies;
        }
      } else {
        this.gridData = [];
        this.comboBoxData = [];
        this.filterValue = '';
        this.selectedRows = [];
      }

      this.loadItems();
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public companyChange: EventEmitter<number> = new EventEmitter<number>();

  public longList: boolean;
  public comboBoxData: ISimpleCompany[] = [];
  public gridView: GridDataResult;
  public pageSize = 30;
  public skip = 0;
  public selectedRows: any[] = [];

  private filterValue: string;
  public get filter(): string {
    return this.filterValue;
  }
  public set filter(value: string) {
    this.filterValue = value;
    this.filterSubject.next(value);
  }

  private compareByCompanyName(company1: any, company2: any): number {
    if (company1.DisplayName.toLowerCase() < company2.DisplayName.toLowerCase()) {
      return -1;
    }

    if (company1.DisplayName.toLowerCase() > company2.DisplayName.toLowerCase()) {
      return 1;
    }

    return 0;
  }

  @ViewChild(GridComponent, { static: false }) private grid: GridComponent;
  @ViewChild('companyFilter', { static: false }) private filterInput: ElementRef;
  @ViewChild('pageInfo', { static: false }) public pageInfo: any;

  private gridData: ISimpleCompany[] = [];
  private filterSubject: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(public sessionService: SessionService, private changeDetectorRef: ChangeDetectorRef) {}

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public ngAfterViewInit(): void {
    this.filterSubject
      .asObservable()
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((value: string) => this.applyFilter(value));
  }

  public onFilterKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if (this.selectedRows && this.selectedRows.length === 1) {
        const companyId: number = this.selectedRows[0];
        this.emitCompanyId(companyId);
        return;
      }
      if (this.gridView.data.length === 1) {
        const companyId: number = this.gridView.data[0].Id;
        this.emitCompanyId(companyId);
      } else {
        this.selectedRows = this.gridView.data && this.gridView.data.length > 0 ? [this.gridView.data[0].Id] : [];
        this.grid.focusCell(0, 0);
      }
    }
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      if (this.gridView.data && this.gridView.data.length > 0) {
        let rowIndex =
          this.grid.activeRow && this.grid.activeRow.index
            ? this.grid.activeRow.index
            : this.selectedRows && this.selectedRows.length > 0
            ? this.gridView.data.indexOf(
                this.gridView.data.find((model: ISimpleCompany) => model.Id === this.selectedRows[0])
              ) + 1
            : 0;
        if (event.key === 'ArrowUp') {
          rowIndex = rowIndex - 1;
        } else if (event.key === 'ArrowDown') {
          rowIndex = rowIndex + 1;
        }
        if (rowIndex > this.gridView.data.length + 1) {
          rowIndex = 1;
        }
        if (this.gridView.data[rowIndex - 1]) {
          this.selectedRows = [this.gridView.data[rowIndex - 1].Id];
        } else {
          this.selectedRows = [this.gridView.data[0].Id];
          this.grid.focusCell(0, 0);
        }
      }
    }
    // if (event.key === 'ArrowDown') {
    //   this.selectedRows = this.gridView.data && this.gridView.data.length > 0 ? [this.gridView.data[0].Id] : [];
    //   this.grid.focusCell(0, 0);
    // }
    if (event.key === 'Escape') {
      this.visible = false;
    }
  }

  public onDialogEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if (this.selectedRows && this.selectedRows.length === 1) {
        const companyId: number = this.selectedRows[0];
        this.emitCompanyId(companyId);
        return;
      }
      if (this.gridView.data.length === 1) {
        const companyId: number = this.gridView.data[0].Id;
        this.emitCompanyId(companyId);
      } else {
        this.selectedRows = this.gridView.data && this.gridView.data.length > 0 ? [this.gridView.data[0].Id] : [];
        this.grid.focusCell(0, 0);
      }
    }
  }

  public onGridKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if (this.selectedRows && this.selectedRows.length > 0) {
        const companyId: number = this.selectedRows[0];
      }
    }

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      if (this.selectedRows && this.selectedRows.length > 0 && this.gridView.data && this.gridView.data.length > 0) {
        let rowIndex =
          this.grid.activeRow && this.grid.activeRow.index
            ? this.grid.activeRow.index
            : this.gridView.data.indexOf(
                this.gridView.data.find((model: ISimpleCompany) => model.Id === this.selectedRows[0])
              ) + 1;
        if (event.key === 'ArrowUp') {
          rowIndex = rowIndex - 1;
        } else if (event.key === 'ArrowDown') {
          rowIndex = rowIndex + 1;
        }
        if (rowIndex > this.gridView.data.length + 1) {
          rowIndex = 1;
        }
        if (this.gridView.data[rowIndex - 1]) {
          this.selectedRows = [this.gridView.data[rowIndex - 1].Id];
        } else {
          this.selectedRows = [this.gridView.data[0].Id];
          this.grid.focusCell(0, 0);
        }
      }
    }

    if (event.key === 'Escape') {
      this.visible = false;
    }
  }

  public onCellClick(event: CellClickEvent): void {
    const companyId: number = event.dataItem.Id;
    this.emitCompanyId(companyId);
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  private emitCompanyId(companyId: number): void {
    setTimeout(() => {
      this.companyChange.emit(companyId);
      this.selectedCompanyId = companyId;
      this.visible = false;
    });
  }

  private applyFilter(value: string): void {
    if (this.companies) {
      if (value) {
        const searchText: string = value.toLocaleLowerCase();
        this.gridData = this.companies.filter(
          (c: any) =>
            (c.VatRegistrationNumber && c.VatRegistrationNumber.toLocaleLowerCase().includes(searchText)) ||
            c.DisplayName.toLocaleLowerCase().includes(searchText)
        );
      } else {
        this.gridData = this.companies;
      }
    }

    this.skip = 0;
    this.loadItems();
  }

  private loadItems(): void {
    this.gridView = {
      data: this.gridData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridData.length
    };
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }
}
