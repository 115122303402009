import { ChangeDetectorRef, ElementRef, EventEmitter } from '@angular/core';
import { ReflectionHelper } from './ReflectionHelper';
var ControlComponentBase = /** @class */ (function () {
    function ControlComponentBase(elementRef, changeDetectorRef) {
        this.elementRef = elementRef;
        this.changeDetectorRef = changeDetectorRef;
        this.valueChange = new EventEmitter();
        this.editModeValue = false;
        this.editModeChange = new EventEmitter();
        this.childIsDirty = [];
        this.isDirtyValue = false;
        this.isDirtyChange = new EventEmitter();
        this.childIsValid = [];
        this.isValidValue = true;
        this.isValidChange = new EventEmitter();
        this.setTimeout = true;
    }
    Object.defineProperty(ControlComponentBase.prototype, "value", {
        get: function () {
            return this.currentValue;
        },
        set: function (value) {
            // if (this.isFiltering) {
            //     return;
            // }
            this.valueSet = true;
            if ((this.original === undefined || this.original === this.nullIdValue) &&
                (!this.editMode || this.allowSetOriginal)) {
                this.original = this.convertValue(value);
                this.currentValue = this.convertValue(value);
                if (this.isGrid) {
                    this.changeDetectorRef.markForCheck();
                }
            }
            else if (value instanceof Date) {
                if ((!this.currentValue && value) ||
                    (this.currentValue && !value) ||
                    (this.currentValue && value && this.currentValue.toString() !== this.convertValue(value).toString())) {
                    this.setCurrentValueAndTriggerChange(value);
                }
            }
            else if (this.currentValue !== this.convertValue(value)) {
                this.setCurrentValueAndTriggerChange(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    ControlComponentBase.prototype.setCurrentValueAndTriggerChange = function (value) {
        this.currentValue = this.convertValue(value);
        if (!this.editMode) {
            this.original = this.convertValue(value);
            this.isDirty = false;
        }
        else {
            this.onChange();
        }
    };
    Object.defineProperty(ControlComponentBase.prototype, "editMode", {
        get: function () {
            return this.editModeValue;
        },
        set: function (value) {
            if (this.editModeValue !== value) {
                if (value) {
                    if (!this.valueSet) {
                        this.allowSetOriginal = true;
                    }
                    else {
                        this.original = this.currentValue;
                    }
                }
                else {
                    this.allowSetOriginal = false;
                }
                this.editModeValue = value;
                this.editModeChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ControlComponentBase.prototype, "isDirty", {
        get: function () {
            return this.isDirtyValue;
        },
        set: function (value) {
            if (this.isDirtyValue !== value) {
                this.isDirtyValue = value;
                // setTimeout(() => this.isDirtyChange.emit(value));
                this.isDirtyChange.emit(value);
                if (!value) {
                    ////this.value = this.original;
                    this.original = this.value;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ControlComponentBase.prototype, "isValid", {
        get: function () {
            return this.isValidValue;
        },
        set: function (value) {
            if (this.isValidValue !== value) {
                this.isValidValue = value;
                this.isValidChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ControlComponentBase.prototype, "hostClass", {
        get: function () {
            return this.hostClassValue;
        },
        set: function (value) {
            if (this.hostClassValue !== value) {
                this.hostClassValue = value;
                this.removePreviousHostClass();
                if (value && value !== '') {
                    this.previousHostClass = value;
                    this.elementRef.nativeElement.parentElement.classList.add(value);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ControlComponentBase.prototype.onChange = function (triggerChanged) {
        var _this = this;
        if (triggerChanged === void 0) { triggerChanged = true; }
        if (this.setTimeout) {
            setTimeout(function () {
                _this.isValid = _this.valid();
                _this.isDirty = _this.hasChanges();
                if (triggerChanged) {
                    _this.onEmitValueChange(_this.value);
                }
            });
        }
        else {
            this.isValid = this.valid();
            this.isDirty = this.hasChanges();
            if (triggerChanged) {
                this.onEmitValueChange(this.value);
            }
        }
    };
    ControlComponentBase.prototype.onEmitValueChange = function (value) {
        if (!this.preventChange) {
            this.valueChange.emit(this.nullIdValue && value === this.nullIdValue ? undefined : value);
        }
    };
    ControlComponentBase.prototype.convertValue = function (value) {
        return this.nullIdValue
            ? (!value && value !== 0) || (!value && value === this.defaultnullvalue)
                ? this.nullIdValue
                : value
            : value;
    };
    ControlComponentBase.prototype.valid = function () {
        return this.childIsValid.every(function (c) { return c; });
    };
    ControlComponentBase.prototype.hasChanges = function () {
        if (this.childIsDirty.some(function (c) { return c; })) {
            return true;
        }
        if (this.original === undefined && this.currentValue === undefined) {
            return false;
        }
        if ((this.original !== undefined && this.currentValue === undefined) ||
            (this.original === undefined && this.currentValue !== undefined)) {
            return true;
        }
        if (ReflectionHelper.isObject(this.original) && !ReflectionHelper.isDate(this.original)) {
            return !ReflectionHelper.isEqualShallow(this.original, this.currentValue);
        }
        else {
            return this.original !== this.currentValue;
        }
    };
    ControlComponentBase.prototype.removePreviousHostClass = function () {
        this.elementRef.nativeElement.parentElement.classList.remove('undefined');
        if (this.previousHostClass) {
            this.elementRef.nativeElement.parentElement.classList.remove(this.previousHostClass);
            this.previousHostClass = '';
        }
    };
    return ControlComponentBase;
}());
export { ControlComponentBase };
