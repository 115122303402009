import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Raven from 'raven-js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { version } from '../../../version';
import { Browser } from '../../Common/Browser';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { AuthenticationService } from '../../Services/Authentication/AuthenticationService';
import { Broadcaster } from '../../Services/Broadcaster';
import { RxDataService } from '../../Services/RxDataService';
import { ModalService } from '../../Services/SharedServices/ModalService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'login',
  templateUrl: './Login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  public userName = '';
  public password = '';
  public remember = false;
  public Message: string = undefined;
  public language: string;
  public showAcceptDPADialog: boolean;
  public showResetPasswordDialog = false;
  public hideFooter = false;
  public showPassword = false;
  public signupDialogVisible = false;
  public upgradeWarningDialogVisible = false;
  public unsupportedIEDialogVisible = false;
  public unsupportedBrowserDialogVisible = false;
  public localStorageDialogVisible = false;
  public changeTemporaryPasswordVisible = false;
  public languageDropdownOpen: boolean;
  public preFillEmailResetPassword: string;

  public get isIOSMobileApp(): boolean {
    return this.sessionService.browser.iOSMobileDevice;
  }
  public get iOSversion(): any {
    return this.sessionService.browser.iOSversion;
  }
  public get IsIE(): boolean {
    return this.sessionService.browser.isIE;
  }
  public get IsAndroidMobile(): boolean {
    return (
      !this.sessionService.browser.isHybridApp &&
      this.sessionService.browser.isAndroidDevice &&
      this.sessionService.browser.isMobile
    );
  }
  public get IsIOsMobile(): boolean {
    return (
      !this.sessionService.browser.isHybridApp &&
      this.sessionService.browser.iOSMobileDevice &&
      this.sessionService.browser.isMobile
    );
  }
  public get signupLink(): string {
    return '/signup/' + this.urlCultureCode;
  }

  private unauthorized: string;
  private wrongUsernameOrPassword: string;
  private networkErrorMessage: string;
  private checkedBrowser = false;
  private urlCultureCode = 'da';
  private isLoggingIn = false;
  private mustChangePassword = false;
  private firstLoginMustChangePassword: boolean;
  private loginResponse: any;

  @ViewChild('loginUsername', { static: true }) loginUsername: ElementRef;
  @ViewChild('loginPassword', { static: true }) loginPassword: ElementRef;

  constructor(
    public sessionService: SessionService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private settingService: SettingService,
    private dataService: RxDataService,
    private staticDataService: StaticDataService,
    public modalService: ModalService,
    private broadcaster: Broadcaster,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    if (this.sessionService.IsAuthenticated) {
      this.authenticationService.logout().subscribe(() => {});
    }

    if (window.location.href.indexOf('file://') > -1 && !this.sessionService.browser.isHybridApp) {
      window.location.href = 'https://app.gratisal.dk';
    }

    this.renderer.removeClass(document.body, 'main-bg');
    this.renderer.addClass(document.body, 'Login-bg');
    this.renderer.listen(window, 'keyboardWillShow', () => (this.hideFooter = true));
    this.renderer.listen(window, 'keyboardDidHide', () => (this.hideFooter = false));

    let languageCode: string;
    const urlFragments: string[] = window.location.href.split('/');
    const param: string = urlFragments[urlFragments.length - 1];
    if (param) {
      if (param.substring(0, 8) === 'username') {
        this.userName = param.replace('username=', '');
      }
      if (param === 'Unauthorized') {
        this.Message = this.unauthorized;
      } else {
        const shortCultureCode: string = param.substr(0, 2);
        if (this.checkLanguageCodeExist(shortCultureCode)) {
          languageCode = this.urlCultureCode = shortCultureCode;
        }
      }
    }

    if (!languageCode) {
      languageCode = this.getLanguageCodeById(this.getCookie('languageId'));
    }

    this.changeLanguageTo(languageCode || 'da', true);
  }

  public ngAfterViewInit(): void {
    if (this.checkLocalStorageBrower()) {
      this.init();
    }
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get unsupportIOSApp(): boolean {
    const ver: any = this.iOSversion;
    if (ver && ver[0] < 11) {
      return true;
    }

    return false;
  }

  public onUpgradeWarningDialogAction(action: string): void {
    if (action === 'Update') {
      if (this.sessionService.browser.isHybridApp) {
        window.open('market://details?id=com.gratisal.mobile', '_top');
      } else {
        window.location.reload();
      }
    }
  }

  public unsupportedIEDialogAction(action: string): void {
    switch (action) {
      case 'Chrome':
        window.open('https://www.google.com/chrome/');
        break;
      case 'Firefox':
        window.open('https://getfirefox.com/');
        break;
    }
  }

  public onToggleLanguageDropdown(): void {
    this.languageDropdownOpen = !this.languageDropdownOpen;
  }

  public onTemporaryPasswordChanged(newPassword: string): void {
    this.password = newPassword;
    this.authenticationService.credentials.Password = this.password;
    this.login();
  }

  public gotosignup(url: string): void {
    window.location.href = url;
  }

  public changeLanguageTo(language: string, firstLoad?: boolean): void {
    this.language = language;
    this.urlCultureCode = language;
    this.settingService.applyTranslations(language);
    this.translate.use(language).subscribe(() => {
      if (firstLoad) {
        this.ShowWaringUsuportIE();
      }
    });

    this.languageDropdownOpen = false;
    localStorage.setItem('localLanguageCode', this.language);
  }

  public keydown(e: any): void {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (!this.userName) {
        this.loginUsername.nativeElement.focus();
        return;
      }

      if (!this.password) {
        this.loginPassword.nativeElement.focus();
        return;
      }

      this.login();
    } else {
      this.Message = undefined;
    }
  }

  public get disableLogin(): boolean {
    let userAutoFill = false;
    let passAutoFill = false;
    try {
      userAutoFill = getComputedStyle(this.loginUsername.nativeElement).webkitTextFillColor === 'rgb(27, 41, 47)';
      passAutoFill = getComputedStyle(this.loginPassword.nativeElement).webkitTextFillColor === 'rgb(27, 41, 47)';
    } catch (e) {}

    if ((userAutoFill && passAutoFill && !this.isLoggingIn) || (this.userName && passAutoFill)) {
      return false;
    }

    if (!this.userName || this.userName === '' || !this.password || this.password === '' || this.isLoggingIn) {
      return true;
    }

    return false;
  }

  public login(): void {
    if (!this.password) {
      return;
    }

    if (this.remember) {
      localStorage.setItem('lastUserName', this.userName);
    } else {
      localStorage.setItem('lastUserName', '');
    }

    localStorage.setItem('saveLoginUserName', this.userName);

    this.authenticationService.credentials = {
      Username: this.userName,
      Password: this.password,
      Remember: this.remember
    };

    this.isLoggingIn = true;
    let message: string;
    this.loginResponse = undefined;
    this.authenticationService
      .login()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response: any) => {
          const isFirstLogin: boolean = this.firstLoginMustChangePassword
            ? this.firstLoginMustChangePassword
            : response.IsFirstLogin || false;
          this.sessionService.isApprovalMessage = response.IsFirstLogin || false;
          const mustChangePassword: any = response.MustChangePassword;
          if (mustChangePassword) {
            this.firstLoginMustChangePassword = isFirstLogin;
            this.mustChangePassword = true;
            const cultureCode: string = response.Language.CultureCode;
            const shortCultureCode: string = cultureCode.substr(0, 2);

            if (this.mustChangePassword) {
              this.mustChangePassword = false;
              this.changeTemporaryPassword();
            }
          } else {
            const company: any = response.Company ? response.Company : undefined;
            const role: any = response ? response.CurrentRole : undefined;
            if (role && role.Key === 'FullAdmin' && company && !company.HasAcceptedTerms) {
              this.loginResponse = response;
              this.showAcceptDPADialog = true;
            } else {
              this.onLoginCompleted(response);
            }
          }

          this.isLoggingIn = false;
        },
        (reason: any): void => {
          this.isLoggingIn = false;

          // Failure
          switch (reason.status) {
            case 0:
              message = this.networkErrorMessage;
              break;
            case 401:
              message = this.wrongUsernameOrPassword;
              break;
            case 400:
              message = reason.error.Message;
              break;
            case 500:
              // Internal server error
              let errorMessage: string = reason.statusText;
              if (reason.config && reason.config.url) {
                errorMessage += ' - ' + reason.config.url;
              }

              Raven.captureException(new Error(errorMessage), { extra: reason });
              break;
            default:
              let othermessage: string = reason.statusText;
              if (reason.config && reason.config.url) {
                othermessage += ' - ' + reason.config.url;
              }

              Raven.captureException(new Error(othermessage), { extra: reason });
              break;
          }

          this.Message = message;
          console.error('Login failed: ');
          console.error(reason);
        }
      );
  }

  public onDPATermAccepted(isValid: boolean): void {
    this.isLoggingIn = false;
    if (isValid) {
      this.onLoginCompleted();
    }

    this.loginResponse = undefined;
  }

  public onResetPassword(): void {
    this.preFillEmailResetPassword = this.userName && this.userName.lastIndexOf('@') !== -1 ? this.userName : '';
    this.showResetPasswordDialog = true;
  }

  public openLink(): void {
    if (this.isIOSMobileApp) {
      return;
    }

    const languageCode: string =
      Global.SESSION && Global.SESSION.SignOnToken.Language
        ? Global.SESSION.SignOnToken.Language.CultureCode.split('-')[0]
        : 'da';
    location.href = 'http://www.gratisal.dk/' + languageCode;
  }

  private init(): void {
    this.language = this.translate.currentLang;
    this.translateText();
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) => {
      this.language = this.translate.currentLang;
      this.translateText();
    });

    this.dataService.Miscellaneous_GetMinimumClientVersion().subscribe((clientMinVersion: string) => {
      const fragments: string[] = version.split('.');
      let formattedVersion: string;
      if (fragments.length < 3) {
        formattedVersion = version;
      } else {
        formattedVersion =
          this.zeroPad(this.sessionService.parseInt(fragments[0]), 2) +
          this.zeroPad(this.sessionService.parseInt(fragments[1]), 3) +
          this.zeroPad(this.sessionService.parseInt(fragments[2]), 4);
      }

      if (clientMinVersion > formattedVersion && environment.environment !== 'DEV') {
        setTimeout(() => {
          this.upgradeWarningDialogVisible = true;
        });
      }
    });
  }

  private ShowWaringUsuportIE(): void {
    if (this.sessionService.browser.isIE) {
      this.unsupportedIEDialogVisible = true;
    }
  }

  private getCookie(cookieName: string): string {
    const name: string = cookieName + '=';
    const decodedCookie: string = decodeURIComponent(document.cookie);
    const ca: string[] = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c: string = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  }

  private checkBrowser(): boolean {
    if (this.checkedBrowser) {
      return true;
    }

    this.checkedBrowser = true;
    if (
      !this.sessionService.browser.isSupportedBrowser ||
      this.sessionService.browser.isOutdatedBrowser ||
      this.unsupportIOSApp
    ) {
      this.unsupportedBrowserDialogVisible = true;
      return false;
    }

    return true;
  }

  private checkLocalStorageBrower(): boolean {
    if (!this.sessionService.browser.localStorageEnabled) {
      this.localStorageDialogVisible = true;
      return false;
    }

    return true;
  }

  private checkLanguageCodeExist(languageCode: string): boolean {
    return languageCode === 'en' || languageCode === 'da' || languageCode === 'kl';
  }

  private getLanguageCodeById(languageId: string): string {
    const id: number = this.sessionService.parseInt(languageId);
    switch (id) {
      case 1:
        return 'da';
      case 2:
        return 'en';
      case 3:
        return 'kl';
      default:
        return '';
    }
  }

  private zeroPad(num: number, places: number): string {
    const zero: number = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
  }

  private translateText(): void {
    this.translate
      .get([
        'GlobalDialog.Ok',
        'GlobalDialog.Yes',
        'GlobalDialog.No',
        'GlobalDialog.Cancel',
        'GlobalDialog.Close',
        'GlobalDialog.Delete',
        'GlobalDialog.Reject',
        'GlobalDialog.Create',
        'GlobalDialog.Approve',
        'GlobalDialog.Enable',
        'GlobalDialog.Apply',
        'GlobalDialog.Proceed',
        'GlobalDialog.Understood',
        'GlobalDialog.MoreHelp',
        'GlobalDialog.ReadMore',
        'GlobalDialog.ChangePassword',
        'GlobalDialog.ResetPassword',
        'Error.NetworkError',
        'GlobalDialog.YesPlease',
        'GlobalDialog.NoThankYou',
        'Warning.Continue',
        'GlobalDialog.Update'
      ])
      .subscribe((translations: { [key: string]: string }) => {
        this.sessionService.modalTranslations = translations;
      });

    this.translate
      .get(['Login.AuthenticationFailed', 'Login.WrongUserNameOrPassword', 'Error.NetworkError', 'UnsuccessfulAPI.404'])
      .subscribe((translations: { [key: string]: string }) => {
        this.unauthorized = translations['Login.AuthenticationFailed'];
        this.wrongUsernameOrPassword = translations['Login.WrongUserNameOrPassword'];
        this.networkErrorMessage = translations['Error.NetworkError'];
        this.sessionService.errorMessage404 = translations['UnsuccessfulAPI.404'];

        if (this.checkBrowser()) {
          // if (!this.userName && localStorage) {
          //   this.userName = localStorage.getItem('lastUserName');
          // }
          if (localStorage && localStorage.getItem('lastUserName')) {
            this.userName = localStorage.getItem('lastUserName');
          } else {
            this.userName = '';
            this.password = '';
          }
        }

        // if (this.mustChangePassword) {
        //     this.mustChangePassword = false;
        //     this.changeTemporaryPassword();
        // }
      });
  }

  public isFullAdmin = false;
  public isReadOnly = false;
  public isSalaryAdmin = false;
  public isEmployee = false;

  private onLoginCompleted(response?: any): void {
    response = response ? response : this.loginResponse;
    if (response) {
      const isFirstLogin: boolean = this.firstLoginMustChangePassword
        ? this.firstLoginMustChangePassword
        : response.IsFirstLogin || false;
      const welcomeMessageSettings: { [key: string]: boolean } = {};
      welcomeMessageSettings['Employee.General'] = isFirstLogin;
      welcomeMessageSettings['Employee.Employment'] = isFirstLogin;
      welcomeMessageSettings['Employee.Time'] = isFirstLogin;
      welcomeMessageSettings['Employee.PayrollData'] = isFirstLogin;
      welcomeMessageSettings['Employee.Payslip'] = isFirstLogin;
      welcomeMessageSettings['Company.General'] = isFirstLogin;
      welcomeMessageSettings['Company.Templates'] = isFirstLogin;
      welcomeMessageSettings['Company.SalaryBatches'] = isFirstLogin;
      welcomeMessageSettings['Company.SalaryTypes'] = isFirstLogin;
      welcomeMessageSettings['Company.TimeEntryTypes'] = isFirstLogin;
      welcomeMessageSettings['Account.Modules'] = isFirstLogin;
      welcomeMessageSettings['SelfService.Payslip'] = isFirstLogin;
      welcomeMessageSettings['ShowUpdateNotification'] = response.ShowUpdateNotification && !isFirstLogin;
      welcomeMessageSettings['company.timeentrytypes'] = isFirstLogin;
      welcomeMessageSettings['company.integrations'] = isFirstLogin;
      welcomeMessageSettings['company.advancedsalarytypes'] = isFirstLogin;
      Global.WELCOME_MESSAGE_SETTINGS = welcomeMessageSettings;

      const betaMessageSettings: { [key: string]: boolean } = {};
      betaMessageSettings['Company.SalaryBatch.EditPayroll'] = true;
      betaMessageSettings['Company.SalaryBatch.EditPayrollIEEdge'] = true;
      Global.BETA_MODULE_MESSAGE_SETTINGS = betaMessageSettings;

      this.isLoggingIn = false;
      this.renderer.addClass(document.body, 'is-loggingin');
      if (response.CurrentRole && response.CurrentRole.Key) {
        switch (response.CurrentRole.Key) {
          case 'FullAdmin':
            this.isFullAdmin = true;
            break;
          case 'ReadOnly':
            this.isReadOnly = true;
            break;
          case 'Employee':
            this.isEmployee = true;
            break;
          case 'SalaryAdmin':
            this.isSalaryAdmin = true;
            break;
          default:
            break;
        }
      }
      this.staticDataService.loadStaticData().then(() => this.navigateToApp());
    }
  }

  private navigateToApp(): void {
    Global.STARTUP_TASKS_VISIBILITY = true;
    setTimeout(() => this.renderer.removeClass(document.body, 'is-loggingin'), 200);

    // Navigate to Employee states.
    // if (this.sessionService.Role.IsEmployeeRole) {
    //     this.sessionService.NavigateTo("tabs.selfservice");
    // } else {
    //     if (Global.IsEmployeeTaskCompleted) {
    //         this.sessionService.NavigateTo("tabs.employee.payrolldata");
    //     } else {
    //         this.sessionService.NavigateTo("tabs.employee.general");
    //     }
    // }

    if (this.isFullAdmin) {
      if (Global.IsEmployeeTaskCompleted) {
        this.sessionService.NavigateTo('tabs.employee.payrolldata');
      }
      this.sessionService.NavigateTo('tabs.employee.general');
    }

    if (this.isReadOnly || this.isSalaryAdmin) {
      this.sessionService.NavigateTo('tabs.employee.payrolldata');
    }

    if (this.isEmployee) {
      this.sessionService.NavigateTo('tabs.selfservice.payslip');
    }

    if (Global.SESSION.SignOnToken.LanguageId) {
      try {
        document.cookie = 'languageId=' + Global.SESSION.SignOnToken.LanguageId;
      } catch (e) {
        this.sessionService.isDetectedCookieDisable = true;
      }
    }
  }

  private changeTemporaryPassword(): void {
    this.broadcaster.broadcast(Constants.ACCOUNT_CHANGE_TEMPORARY_PASSWORD, this.password);
    this.changeTemporaryPasswordVisible = true;
  }

  public get isMobile(): boolean {
    const browser: Browser = new Browser();
    return browser.isMobile;
  }

  public showPaswordForMobile(): void {
    if (this.isMobile) {
      this.showPassword = !this.showPassword;
    }
  }
}
