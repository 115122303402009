import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { AdditionalVacationModel } from './AdditionalVacationModel';
var AdditionalVacationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AdditionalVacationComponent, _super);
    function AdditionalVacationComponent() {
        var _this = _super.call(this) || this;
        _this.modelChange = new EventEmitter();
        _this.numbericTextBoxFFSeniorityRequirementMonthsOptions = {
            min: -120000,
            max: 120000,
            step: 1,
            decimals: 0,
            spinners: false,
            format: '0'
        };
        _this.NumbericTextBoxFFHoursEarnedPerYearOptions = {
            format: 'n2',
            decimals: 2,
            step: 1,
            spinners: false
        };
        _this.childIsValid = Array(3).fill(true);
        _this.childIsDirty = Array(3).fill(false);
        return _this;
    }
    Object.defineProperty(AdditionalVacationComponent.prototype, "model", {
        get: function () {
            if (!this.currentmodel) {
                this.currentmodel = {};
            }
            return this.currentmodel;
        },
        set: function (value) {
            this.currentmodel = value;
            if (this.modelChange) {
                this.modelChange.emit(this.currentmodel);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdditionalVacationComponent.prototype, "IsEmptyFFHoursEarnedPerYear", {
        get: function () {
            return !this.model || !this.model.FFHoursEarnedPerYear || this.model.FFHoursEarnedPerYear === 0;
        },
        enumerable: true,
        configurable: true
    });
    return AdditionalVacationComponent;
}(FormComponentBase));
export { AdditionalVacationComponent };
