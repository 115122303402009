import { OnInit } from '@angular/core';
import * as Raven from 'raven-js';
import { version } from '../../../version';
import { Global } from '../../Common/Global';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
var FeedbackComponent = /** @class */ (function () {
    function FeedbackComponent(dataService, sessionService, settingService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.settingService = settingService;
        this.apiVersion = '';
        this.clientVersion = '';
        this.feedbackDialogVisible = false;
        this.successDialogVisible = false;
        this.country = '';
        this.widthDestopFooter = '';
        this.paddingDestopFooter = '';
        this.clientVersion = version;
        this.getApiVersion();
    }
    Object.defineProperty(FeedbackComponent.prototype, "demo", {
        get: function () {
            return this.sessionService.feature.IsDemoVersion;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FeedbackComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    FeedbackComponent.prototype.ngOnInit = function () {
        this.userId = Global.SESSION.CurrentCompanyUser.UserId;
        this.accountId = Global.SESSION.AccountId;
        this.companyId = Global.SESSION.CurrentCompanyId;
        this.country = Global.SESSION.CurrentCompany.Country.Name;
        if (!this.isMobile) {
            this.widthDestopFooter = 'widthDestopFooter';
        }
    };
    Object.defineProperty(FeedbackComponent.prototype, "isPayrolldataMobile", {
        get: function () {
            return this.sessionService.browser.isMobile && this.sessionService.currentState === 'tabs.employee.payrolldata';
        },
        enumerable: true,
        configurable: true
    });
    FeedbackComponent.prototype.onAction = function (action) {
        if (action === 'Submit') {
            this.submitFeedback();
        }
    };
    FeedbackComponent.prototype.getApiVersion = function () {
        var _this = this;
        if (this.settingService.apiVersion) {
            this.apiVersion = this.settingService.apiVersion;
            Raven.setTagsContext({ apiVersion: this.settingService.apiVersion });
            Raven.setTagsContext({ clientVersion: this.clientVersion });
        }
        else {
            this.dataService.Miscellaneous_GetVersion().subscribe(function (data) {
                _this.apiVersion = data;
                _this.settingService.apiVersion = data;
                Raven.setTagsContext({ apiVersion: data });
                Raven.setTagsContext({ clientVersion: _this.clientVersion });
            });
        }
    };
    FeedbackComponent.prototype.submitFeedback = function () {
        var _this = this;
        if (!this.feedbackText || this.feedbackText.trim() === '') {
            this.feedbackDialogVisible = false;
            return;
        }
        this.dataService
            .Miscellaneous_SubmitFeedback({ Message: this.feedbackText })
            .subscribe(function () { return (_this.successDialogVisible = true); });
    };
    return FeedbackComponent;
}());
export { FeedbackComponent };
