<div *ngIf="!longList" [hidden]="!visible" class="is-active Dropdown Dropdown--top Settings-companyDropdown">
  <combobox-edit-control
    (valueChange)="companyChange.emit($event)"
    [editMode]="true"
    [disable]="sessionService.isGetting || sessionService.isSubmitting"
    [idField]="'Id'"
    [textField]="'DisplayName'"
    [comboboxDataSource]="comboBoxData"
    [filterFields]="['DisplayName', 'VatRegistrationNumber']"
    [isBigdataSource]="true"
    [isFocus]="!longList && visible"
    [(value)]="selectedCompanyId"
    (blur)="visible = false"
  >
  </combobox-edit-control>
</div>

<app-dialog
  *ngIf="longList"
  (keydown)="onDialogEnter($event)"
  [(visible)]="visible"
  [width]="800"
  [title]="'CompanySelector.SelectCompany' | translate"
>
  <div class="Form--horizontal">
    <div class="FormElement PaddingTop">
      <span class="FormElement-label">{{ 'CompanySelector.FilterPlaceHolder' | translate }}</span>
      <div class="FormElement-control">
        <input
          #companyFilter
          kendoTextBox
          class="FormElement-input"
          [(ngModel)]="filter"
          (keydown)="onFilterKeyDown($event)"
        />
      </div>
    </div>
  </div>

  <kendo-grid
    class="company-selector-grid GridSelect"
    [data]="gridView"
    [pageSize]="pageSize"
    [skip]="skip"
    [navigable]="true"
    (pageChange)="pageChange($event)"
    (keydown)="onGridKeyDown($event)"
    (cellClick)="onCellClick($event)"
    [pageable]="true"
    [selectable]="true"
    [selectedKeys]="selectedRows"
    [kendoGridSelectBy]="'Id'"
  >
    <kendo-pager-info #pageInfo></kendo-pager-info>
    <kendo-grid-column [title]="'CompanySelector.Name' | translate" [field]="'DisplayName'"></kendo-grid-column>
    <kendo-grid-column [title]="'CompanySelector.RegNo' | translate" [field]="'VatRegistrationNumber'" [width]="120">
    </kendo-grid-column>
    <kendo-grid-column [title]="'Accountant.Role' | translate" [field]="'RoleName'" [width]="120"></kendo-grid-column>
  </kendo-grid>
</app-dialog>
