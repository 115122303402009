import { EventEmitter, OnInit } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { Constants } from '../Common/Constants';
import { Global } from '../Common/Global';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
var CompanyComponent = /** @class */ (function () {
    function CompanyComponent(sessionService, transitionService) {
        this.sessionService = sessionService;
        this.transitionService = transitionService;
        this.editModeValue = false;
        this.editModeChange = new EventEmitter();
        this.mobileWarningDialogVisible = false;
        this.isDirty = false;
        this.showSaveDataConfirmationChangeState = false;
        this.companyClick = false;
        this.isCompanyTemplateState = false;
        this.isCompanyGenneralState = false;
    }
    Object.defineProperty(CompanyComponent.prototype, "editMode", {
        get: function () {
            return this.editModeValue;
        },
        set: function (value) {
            if (this.editModeValue !== value) {
                this.editModeValue = value;
                this.editModeChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "IsMobile", {
        get: function () {
            return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "companyModuleTabEnabled", {
        get: function () {
            return this.sessionService.role.IsFullAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "companyOptionTabEnabled", {
        get: function () {
            return this.sessionService.role.IsFullAdmin || this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "companyDataImportTabEnabled", {
        get: function () {
            return !this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    CompanyComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.sessionService.companyTabState !== '') {
            this.sessionService.currentState = this.sessionService.companyTabState;
            setTimeout(function () {
                _this.checkSwitchingCompanyTab();
            });
        }
        else {
            var currentRoleKey = Global.SESSION.CurrentRole.Key;
            if (currentRoleKey) {
                switch (currentRoleKey) {
                    case 'FullAdmin':
                        this.sessionService.NavigateTo('tabs.company.salarybatches');
                        break;
                    case 'ReadOnly':
                        this.sessionService.NavigateTo('tabs.company.salarybatches');
                        break;
                    case 'Employee':
                        this.sessionService.NavigateTo('tabs.selfservice.payslip');
                        break;
                    case 'SalaryAdmin':
                        this.sessionService.NavigateTo('tabs.company.salarybatches');
                        break;
                    default:
                        break;
                }
            }
        }
        this.transitionService.onStart({}, function (transition) {
            var fromState = transition.from().name;
            _this.navigationToStateName = transition.to().name;
            var generalState = 'tabs.company.general';
            var templateState = 'tabs.company.configuration.hiringstatus';
            var integrationState = 'tabs.company.configuration.integrations';
            if (_this.editMode &&
                fromState &&
                (fromState === generalState || fromState === templateState || fromState === integrationState)) {
                if (_this.isDirty) {
                    // If validation fails, alert user and do nothing.
                    // Else show action confirmation.
                    if (fromState === templateState) {
                        _this.isCompanyTemplateState = true;
                    }
                    if (fromState === generalState) {
                        _this.isCompanyGenneralState = true;
                    }
                    _this.showSaveDataConfirmationChangeState = true;
                    return false;
                }
                else {
                    // Editmode but no changes, we close editmode without doing anything
                    _this.editMode = false;
                    return true;
                }
            }
            else {
                _this.editMode = false;
                _this.editModeChange.emit(false);
                return true;
            }
        });
        this.mobileWarningDialogVisible =
            this.sessionService.browser.isHybridApp &&
                (this.sessionService.currentState === 'tabs.company.configuration.dataimport' ||
                    this.sessionService.currentState === 'tabs.company.configuration.timeentrytypes');
    };
    CompanyComponent.prototype.checkSwitchingCompanyTab = function () {
        if (this.sessionService.currentState) {
            switch (this.sessionService.currentState) {
                case 'tabs.company.configuration.settings':
                    if (!this.companyOptionTabEnabled) {
                        this.sessionService.checkNavigateDefaultRole(false, true);
                    }
                    else {
                        this.sessionService.NavigateTo(this.sessionService.companyTabState);
                    }
                    break;
                case 'tabs.company.configuration.dataimport':
                    if (!this.companyDataImportTabEnabled) {
                        this.sessionService.checkNavigateDefaultRole(false, true);
                    }
                    else {
                        this.sessionService.NavigateTo(this.sessionService.companyTabState);
                    }
                    break;
                case 'tabs.company.modules':
                    if (!this.companyModuleTabEnabled || this.IsGreenlandCompany) {
                        this.sessionService.checkNavigateDefaultRole(false, true);
                    }
                    else {
                        this.sessionService.NavigateTo(this.sessionService.companyTabState);
                    }
                    break;
                default:
                    this.sessionService.NavigateTo(this.sessionService.companyTabState);
                    break;
            }
        }
    };
    CompanyComponent.prototype.onConfirmSaveDataOnChangeState = function (action) {
        if (action === 'ContinueAndSave') {
            this.editMode = false;
            if (this.navigationToStateName) {
                if (!this.isCompanyTemplateState && !this.isCompanyGenneralState) {
                    this.sessionService.NavigateTo(this.navigationToStateName);
                }
                else {
                    this.sessionService.nextState = this.navigationToStateName;
                }
            }
        }
        else if (action === 'DiscardAndLeave') {
            this.editMode = false;
            this.isDirty = false;
            if (this.navigationToStateName) {
                this.sessionService.NavigateTo(this.navigationToStateName);
            }
        }
        this.navigationToStateName = undefined;
    };
    CompanyComponent.prototype.clickAreamenuToggledChange = function () {
        this.sessionService.menuToggled = true;
        this.sessionService.showUserMenuToggled = false;
        this.sessionService.showMoreMenuToggled = false;
        this.sessionService.showHelpMenuToggled = false;
        this.companyClick = true;
    };
    Object.defineProperty(CompanyComponent.prototype, "isCompanyGeneralTabVisisble", {
        // Tabs
        get: function () {
            return this.sessionService.currentState.includes('tabs.company.general');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "isCompanyApprovalVisisble", {
        get: function () {
            return (this.sessionService.currentState.includes('tabs.company.approval') ||
                this.sessionService.currentState.includes('tabs.company.approval.timeentry'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "isCompanyPayrollDataTabVisisble", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.company.payrolldata');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "isCompanySalaryBatchesTabVisisble", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.company.salarybatches');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "isCompanyConfigurationTabVisisble", {
        get: function () {
            return (this.sessionService.currentState.includes('tabs.company.configuration') ||
                this.sessionService.currentState.includes('tabs.company.configuration.advancedsalarytypes') ||
                this.sessionService.currentState.includes('tabs.company.configuration.timeentrytypes') ||
                this.sessionService.currentState.includes('tabs.company.configuration.settings') ||
                this.sessionService.currentState.includes('tabs.company.configuration.dataimport') ||
                this.sessionService.currentState.includes('tabs.company.configuration.integrations') ||
                this.sessionService.currentState.includes('tabs.company.configuration.hiringstatus'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "isCompanyExtraTabVisisble", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.company.modules');
        },
        enumerable: true,
        configurable: true
    });
    return CompanyComponent;
}());
export { CompanyComponent };
