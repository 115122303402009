<app-tab-panel [parentTab]="'accountant'"></app-tab-panel>

<div
  class="Main Island Scrollable companypadding"
  [ngClass]="{ Mobile: IsMobile }"
  tabindex="-1"
  (click)="clickAreamenuToggledChange()"
>
  <div class="wrapper" [ngClass]="{ 'padding-bottom': isAccountantCompaniesTab }">
    <app-accountant-companies *ngIf="state.currentStateIs('tabs.accountant.companies')"></app-accountant-companies>
  </div>

  <div class="wrapper" [ngClass]="{ 'padding-bottom': isAccountantUserTab }">
    <app-accountant-users *ngIf="state.currentStateIs('tabs.accountant.users')"></app-accountant-users>
  </div>

  <app-feedback></app-feedback>
</div>
