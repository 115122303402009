import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UniqueId } from '../../../Common/UniqueId';
import { ImportButtonAction, ImportTypesEnum } from '../../../Model/Enum';
import { ImportMapping } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { ImportOptionHelper } from './ImportOptionHelper';
var UploadImportFileDialogComponent = /** @class */ (function () {
    function UploadImportFileDialogComponent(translateService, dataService, sessionService, staticDataService) {
        this.translateService = translateService;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.showEmptyMessage = false;
        this.mappingDetail = new ImportMapping();
        this.existingMappingsForCurrentImportType = [];
        this.confirmDeleteDialogVisible = false;
        this.confirmReplaceDialogVisible = false;
        this.successDialogVisible = false;
        this.successDialogFotalVisible = false;
        this.payrollImportColumnOperationId = 1;
        this.payrollImportRowOperationId = 5;
        this.timeEntriesImportRowOperationId = 6;
        this.balanceImportRowOperationId = 0;
        this.addonLinkEmployees = 'http://support.gratisal.com/hc/da/articles/115003261669';
        this.addonLinkBalances = 'http://support.gratisal.com/hc/da/articles/115003280345 ';
        this.addonLinkPayroll = 'https://support.gratisal.com/hc/da/articles/115003178565-Import-af-l%C3%B8ndata-fra-Excel';
        this.ngUnsubscribe = new Subject();
        this.IsPreviewExpanded = false;
    }
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
                if (value) {
                    this.IsPreviewExpanded = false;
                    this.showDataImportDialog();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "addonReadMoreVisibility", {
        get: function () {
            return this.importType === ImportTypesEnum.Payroll && this.selectedMappingId < 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "gratisalStandardTemplateColumnPreviewUnavailable", {
        get: function () {
            return this.importType === ImportTypesEnum.Payroll && this.gratisalStandardTemplateColumnSelected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "gratisalStandardTemplateSelected", {
        get: function () {
            return !this.selectedMappingId || this.selectedMappingId < 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "importPayrollDataOptionsVisible", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "disableEditDefinitionButton", {
        get: function () {
            return this.gratisalStandardTemplateSelected || !this.formData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "importButtonEnable", {
        get: function () {
            return !!this.formData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "gratisalStandardTemplateColumnSelected", {
        get: function () {
            return this.selectedMappingId === -1;
        },
        enumerable: true,
        configurable: true
    });
    UploadImportFileDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.ImportSpecification.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (importSpecifications) { return (_this.allImportSpecifications = importSpecifications); });
        this.translateText();
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            return _this.translateText();
        });
        this.visibleChange.subscribe(function () { return (_this.uploadFileName = ''); });
    };
    UploadImportFileDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    UploadImportFileDialogComponent.prototype.onDialogAction = function (action) {
        switch (action) {
            case 'Edit':
                this.onEditClicked();
                break;
            case 'Delete':
                this.confirmDeleteDialogVisible = true;
                break;
            case 'Import':
                this.importMapping(ImportButtonAction.Import);
                break;
        }
    };
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "ImportTypeForMappingEditor", {
        get: function () {
            if (this.importType === ImportTypesEnum.Payroll) {
                if (this.SelectedMapping && this.SelectedMapping.OperationId === this.payrollImportRowOperationId) {
                    return ImportTypesEnum.PayrollRow;
                }
            }
            else if (this.importType === ImportTypesEnum.TimeEntries) {
                if (this.SelectedMapping && this.SelectedMapping.OperationId === this.timeEntriesImportRowOperationId) {
                    return ImportTypesEnum.TimeEntriesRow;
                }
            }
            return this.importType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "SelectedMapping", {
        get: function () {
            var _this = this;
            if (this.selectedMappingId > 0 &&
                this.existingMappingsForCurrentImportType &&
                this.existingMappingsForCurrentImportType.length > 0) {
                return this.existingMappingsForCurrentImportType.find(function (mapp) {
                    return mapp.Id === _this.selectedMappingId;
                });
            }
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "addonLinks", {
        get: function () {
            switch (this.importType) {
                case ImportTypesEnum.Employees:
                    return this.addonLinkEmployees;
                case ImportTypesEnum.Initial_Balances:
                    return this.addonLinkBalances;
                case ImportTypesEnum.Payroll:
                    return this.addonLinkPayroll;
                default:
                    return this.addonLinkEmployees;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "OptionsRadioButtonName", {
        get: function () {
            if (!this.optionsRadioButtonName) {
                this.optionsRadioButtonName = 'optionsRadioButtonName_' + UniqueId();
            }
            return this.optionsRadioButtonName;
        },
        enumerable: true,
        configurable: true
    });
    UploadImportFileDialogComponent.prototype.onSaveMapingEvent = function () {
        this.loadExistingMappingComboboxDataSource();
    };
    UploadImportFileDialogComponent.prototype.onCompleteImportEvent = function () {
        this.importMapping(ImportButtonAction.CompleteImport);
    };
    UploadImportFileDialogComponent.prototype.closeDialogAndFireClosedEvent = function () {
        this.visible = false;
    };
    UploadImportFileDialogComponent.prototype.onComboboxSelectMappingChanged = function () {
        this.showPreviewFile();
    };
    UploadImportFileDialogComponent.prototype.onConfirmDeleteDialogAction = function (action) {
        var _this = this;
        if (action === 'Delete') {
            this.dataService
                .Import_DeleteMapping(this.selectedMappingId)
                .subscribe(function () { return _this.onDeleteMapping(_this.selectedMappingId); });
        }
    };
    UploadImportFileDialogComponent.prototype.onConfirmReplaceDialogAction = function (action) {
        if (action === 'Understood') {
            if (this.confirmReplaceParameter === 'mode') {
                this.previousMode = this.confirmReplaceValue;
            }
        }
        else {
            var mode = this.importOptions.find(function (option) { return option.ParameterValue === 'mode'; });
            var index = this.importOptions.indexOf(mode);
            mode.ControlValue = this.previousMode
                ? this.previousMode
                : mode.Options && mode.Options[0]
                    ? mode.Options[0].RadioValue
                    : undefined;
            this.importOptions[index] = mode;
        }
    };
    UploadImportFileDialogComponent.prototype.onImportOptionsChanged = function (parameter, value) {
        if (ImportOptionHelper.getOptionsString(this.importOptions) === 'mode=replaceall') {
            this.confirmReplaceParameter = parameter;
            this.confirmReplaceValue = value;
            this.confirmReplaceDialogVisible = true;
        }
        else {
            if (parameter === 'mode') {
                this.previousMode = value;
            }
        }
    };
    UploadImportFileDialogComponent.prototype.onFileSelected = function (file) {
        if (file) {
            this.formData = new FormData();
            this.formData.append('file', file, file.name);
            this.showPreviewFile();
        }
    };
    UploadImportFileDialogComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService
            .get([
            'CompanyDataImport.GratisalStandardTemplate',
            'CompanyDataImport.GratisalStandardTemplateColumn',
            'CompanyDataImport.GratisalStandardTemplateRow'
        ])
            .subscribe(function (translations) {
            _this.gratisalStandardTemplateText = translations['CompanyDataImport.GratisalStandardTemplate'];
            _this.gratisalStandardTemplateColumnText = translations['CompanyDataImport.GratisalStandardTemplateColumn'];
            _this.gratisalStandardTemplateRowText = translations['CompanyDataImport.GratisalStandardTemplateRow'];
        });
    };
    UploadImportFileDialogComponent.prototype.onEditClicked = function () {
        var _this = this;
        this.dataService.Import_LoadMapping(this.selectedMappingId).subscribe(function (mapping) {
            _this.mappingName = mapping.Name;
            _this.startIndex = mapping.StartRow;
            _this.mappingDetail = mapping;
            _this.showImportMappingDialog = true;
        });
    };
    UploadImportFileDialogComponent.prototype.loadExistingMappingComboboxDataSource = function (defaultMappingId) {
        var _this = this;
        this.getExistingMappingDataSource().then(function (importMappings) {
            var standardTemplateItem = _this.createStandardTemplateItem();
            importMappings = importMappings.concat(standardTemplateItem);
            _this.setExistingMappingDataSource(importMappings, defaultMappingId);
            _this.showPreviewFile();
            var optionKey = ImportOptionHelper.getImportTypeKey(_this.importType);
            if (optionKey && optionKey === 'ImportEmployees') {
                // reload employee
                _this.staticDataService.loadDepartments();
                _this.staticDataService.loadCompanyUsers();
                _this.reloadEmployeeListContent();
            }
        });
    };
    UploadImportFileDialogComponent.prototype.getExistingMappingDataSource = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var optionKey = ImportOptionHelper.getImportTypeKey(_this.importType);
            // Check for existing mapping
            var concateResult = [];
            if (_this.importType === ImportTypesEnum.TimeEntries) {
                //// Split to this logic since ImportTimeEntries does not has value.
                var standardTemplateRowName = ImportOptionHelper.getImportTypeKey(_this.importType) + 'Row';
                if (standardTemplateRowName) {
                    _this.getMappingsForImportType(standardTemplateRowName, false).then(function (result) {
                        concateResult = concateResult.concat(result);
                        return resolve(concateResult);
                    });
                }
                else {
                    return resolve(concateResult);
                }
            }
            else if (_this.importType === ImportTypesEnum.Payroll /* || this.importType === ImportTypesEnum.TimeEntries*/) {
                _this.getMappingsForImportType(optionKey)
                    .then(function (result) {
                    concateResult = result;
                })
                    .then(function () {
                    var standardTemplateRowName = ImportOptionHelper.getImportTypeKey(_this.importType) + 'Row';
                    if (standardTemplateRowName) {
                        _this.getMappingsForImportType(standardTemplateRowName, false).then(function (result) {
                            concateResult = concateResult.concat(result);
                            return resolve(concateResult);
                        });
                    }
                    else {
                        return resolve(concateResult);
                    }
                });
            }
            else {
                _this.getMappingsForImportType(optionKey).then(function (result) {
                    return resolve(result);
                });
            }
        });
    };
    UploadImportFileDialogComponent.prototype.getMappingsForImportType = function (operationName, isSetOptions) {
        var _this = this;
        if (isSetOptions === void 0) { isSetOptions = true; }
        return new Promise(function (resolve, reject) {
            _this.dataService
                .Import_CompanyOperationMappings(operationName)
                .subscribe(function (existingMappings) {
                _this.allCustomMappings = existingMappings;
                var result = [];
                if (existingMappings) {
                    var specification_1 = _this.allImportSpecifications.find(function (item) { return item.Key === operationName; });
                    if (isSetOptions) {
                        _this.importOptions = ImportOptionHelper.createImportOptionObjects(specification_1.ImportOptions);
                    }
                    if (specification_1) {
                        result = existingMappings.filter(function (item) { return item.CompanyId && item.OperationId === specification_1.Id; });
                    }
                }
                return resolve(result);
            });
        });
    };
    UploadImportFileDialogComponent.prototype.getMappingStandardTemplateRow = function (customTemplate, action) {
        var _this = this;
        var standardTemplateRowName = ImportOptionHelper.getImportTypeKey(this.importType) + 'Row';
        var importSpec = this.allImportSpecifications.find(function (item) { return item.Key === standardTemplateRowName; });
        var mapping;
        this.dataService
            .Import_CompanyOperationMappings(standardTemplateRowName)
            .subscribe(function (standardRowMappings) {
            mapping = standardRowMappings.find(function (item) { return item.OperationId === importSpec.Id && !item.CompanyId; });
            var mappingId = mapping.Id;
            if (!mappingId) {
                return;
            }
            if (customTemplate) {
                _this.performImportCustomTemplate(action, mappingId);
            }
            else {
                _this.getPreviewOfFile(mappingId);
            }
        });
    };
    UploadImportFileDialogComponent.prototype.getStandardTemplateMappingId = function () {
        var importMapping = this.allCustomMappings.find(function (item) { return !item.CompanyId; });
        return importMapping ? importMapping.Id : -1;
    };
    UploadImportFileDialogComponent.prototype.setExistingMappingDataSource = function (importMappings, defaultMappingId) {
        this.existingMappingsForCurrentImportType = [];
        this.selectedMappingId = undefined;
        if (importMappings.length === 0) {
            return;
        }
        this.existingMappingsForCurrentImportType = importMappings;
        this.selectedMappingId = defaultMappingId ? defaultMappingId : this.existingMappingsForCurrentImportType[0].Id;
    };
    UploadImportFileDialogComponent.prototype.showDataImportDialog = function () {
        this.mappingName = undefined;
        this.formData = undefined;
        this.previewDataResponse = this.previewDataHeader = undefined;
        this.showEmptyMessage = false;
        this.importOptions = undefined;
        this.loadExistingMappingComboboxDataSource();
    };
    UploadImportFileDialogComponent.prototype.createStandardTemplateItem = function () {
        if (this.importType === ImportTypesEnum.Payroll /* || this.importType === ImportTypesEnum.TimeEntries*/) {
            return [
                {
                    Id: -2,
                    Name: this.gratisalStandardTemplateRowText,
                    StartRow: 1,
                    OperationId: this.payrollImportRowOperationId
                },
                {
                    Id: -1,
                    Name: this.gratisalStandardTemplateColumnText,
                    StartRow: 1,
                    OperationId: this.payrollImportColumnOperationId
                }
            ];
        }
        else if (this.importType === ImportTypesEnum.TimeEntries) {
            //// Temporarily enable only row template.
            return [
                {
                    Id: -2,
                    Name: this.gratisalStandardTemplateRowText,
                    StartRow: 1,
                    OperationId: this.timeEntriesImportRowOperationId
                }
            ];
        }
        else {
            return [
                { Id: -1, Name: this.gratisalStandardTemplateText, StartRow: 1, OperationId: this.balanceImportRowOperationId }
            ];
        }
    };
    UploadImportFileDialogComponent.prototype.importMapping = function (action) {
        if (this.selectedMappingId === -1) {
            this.importStandardTemplate(action);
        }
        else {
            this.importCustomTemplate(action);
        }
    };
    UploadImportFileDialogComponent.prototype.importCustomTemplate = function (action) {
        var mappingId = this.selectedMappingId;
        if (mappingId === -2) {
            this.getMappingStandardTemplateRow(true, action);
        }
        else {
            this.performImportCustomTemplate(action, mappingId);
        }
    };
    UploadImportFileDialogComponent.prototype.performImportCustomTemplate = function (action, mappingId) {
        var _this = this;
        var optionString = ImportOptionHelper.getInportOptionsString(this.importOptions, action);
        this.dataService
            .ImportMapping_CustomImportExcelOptions(mappingId, optionString, this.formData)
            .subscribe(function (result) { return _this.onImportDataCompleted(result, action); });
    };
    UploadImportFileDialogComponent.prototype.onDeleteMapping = function (mappingId) {
        this.loadExistingMappingComboboxDataSource();
    };
    UploadImportFileDialogComponent.prototype.importStandardTemplate = function (action) {
        switch (this.importType) {
            case ImportTypesEnum.Employees:
                this.postEmployeeFilledTemplate(action);
                break;
            case ImportTypesEnum.Initial_Balances:
                this.postBalanceFilledTemplate(action);
                break;
            case ImportTypesEnum.Payroll:
                this.postPayrollDataTemplate(action);
                break;
        }
    };
    UploadImportFileDialogComponent.prototype.postEmployeeFilledTemplate = function (action) {
        var _this = this;
        var optionString = ImportOptionHelper.getInportOptionsString(this.importOptions, action);
        this.dataService
            .CompanyDataImport_PostEmployeeFilledTemplateWithOptions(this.formData, optionString)
            .subscribe(function (data) { return _this.onImportDataCompleted(data, action); });
    };
    UploadImportFileDialogComponent.prototype.postBalanceFilledTemplate = function (action) {
        var _this = this;
        var optionString = ImportOptionHelper.getInportOptionsString(this.importOptions, action);
        this.dataService
            .CompanyDataImport_PostBalancesFilledTemplate(this.formData, optionString)
            .subscribe(function (result) { return _this.onImportDataCompleted(result, action); });
    };
    UploadImportFileDialogComponent.prototype.postPayrollDataTemplate = function (action) {
        var _this = this;
        var optionString = ImportOptionHelper.getInportOptionsString(this.importOptions, action);
        this.dataService
            .Import_PayrollDataImport(this.formData, optionString)
            .subscribe(function (result) { return _this.onImportDataCompleted(result, action); });
    };
    UploadImportFileDialogComponent.prototype.onImportDataCompleted = function (result, action) {
        if (this.importType === ImportTypesEnum.Employees) {
            this.staticDataService.loadDepartments();
            this.staticDataService.loadCompanyUsers();
            this.reloadEmployeeListContent();
        }
        if (action === ImportButtonAction.CompleteImport) {
            this.visible = false;
            if (result.FatalMessage) {
                this.resultfotalMessage = result.FatalMessage;
                this.successDialogFotalVisible = true;
                return;
            }
            this.successDialogVisible = true;
            return; //// don't show errors or warnings for complete import
        }
        if (!this.hasError) {
            this.successDialogVisible = true;
            return;
        }
        this.importResults = result;
    };
    UploadImportFileDialogComponent.prototype.hasError = function (result) {
        return result.Errors && result.Errors.length > 0;
    };
    UploadImportFileDialogComponent.prototype.reloadEmployeeListContent = function () {
        this.staticDataService.loadCompanyUsers();
    };
    UploadImportFileDialogComponent.prototype.showPreviewFile = function () {
        if (!this.formData) {
            this.resetPreivewOfFile();
            return;
        }
        switch (this.selectedMappingId) {
            case -1:
                if (this.importType !== ImportTypesEnum.Payroll && this.importType !== ImportTypesEnum.TimeEntries) {
                    this.getPreviewOfFile(this.getStandardTemplateMappingId());
                }
                else {
                    this.resetPreivewOfFile();
                }
                break;
            case -2:
                this.getMappingStandardTemplateRow(false);
                break;
            default:
                this.getPreviewOfFile(this.selectedMappingId);
                break;
        }
    };
    UploadImportFileDialogComponent.prototype.getPreviewOfFile = function (mappingId) {
        var _this = this;
        this.dataService.CompanyDataImport_CustomPreviewOfFile(mappingId, 4, this.formData).subscribe(function (response) {
            if (!response || response.length <= 0) {
                _this.showEmptyMessage = true;
                return;
            }
            _this.showEmptyMessage = false;
            _this.previewDataHeader = response.shift();
            _this.previewDataResponse = response;
            if (_this.previewDataHeader && _this.previewDataHeader.length > 4) {
                _this.IsPreviewExpanded = true;
            }
        });
    };
    UploadImportFileDialogComponent.prototype.resetPreivewOfFile = function () {
        this.IsPreviewExpanded = false;
        this.previewDataResponse = this.previewDataHeader = undefined;
    };
    return UploadImportFileDialogComponent;
}());
export { UploadImportFileDialogComponent };
