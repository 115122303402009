<div class="company-salary-batches-padding-bottom">
  <div *ngIf="!IsMobile" class="companySalaryBatches">
    <div class="Cards Cards--flex">
      <div class="Card Card--large">
        <salary-batches
          [salaryBatches]="entityContext.SalaryBatches"
          [salaryCycles]="entityContext.SalaryCycles"
          [selectedBatch]="entityContext.SelectedSalaryBatch"
          (recalculateDraft)="onRecalculate()"
          (newSalaryBatch)="showNewSalaryBatchDialog()"
          (selectionChanged)="selectedSalaryBatchChanged($event)"
          (editSalaryBatch)="onEditSalaryBatch($event)"
          (showErrors)="showErrorModal($event)"
          (finalizeDraft)="onFinalize()"
        ></salary-batches>
        <salary-batch-payslips
          (revertBatchCompleted)="onRevertBatchCompleted()"
          [salaryStatements]="salaryStatements"
          [selectedSalaryBatch]="entityContext.SelectedSalaryBatch"
        ></salary-batch-payslips>
      </div>

      <div class="Card Card--large">
        <div class="Card-inner">
          <salary-batch-salary-totals
            [SalaryBatchId]="entityContext.SelectedSalaryBatch?.Id"
            [SalaryTypeTotals]="salaryTypeTotals"
            [UnitTotals]="unitTotals"
          ></salary-batch-salary-totals>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="IsMobile" class="Island ConfigurationContent tabs">
    <combobox-edit-control
      *ngIf="entityContext.SalaryCycles && entityContext.SalaryCycles.length > 1"
      class="Company-batchesCycles"
      [label]="'CompanySalaryBatches.Cycle' | translate"
      [editMode]="true"
      [idField]="'Id'"
      [textField]="'Name'"
      [comboboxDataSource]="entityContext.SalaryCycles"
      [(value)]="salaryCycleId"
      [nullValueLabel]="'CompanySalaryBatches.SalaryCycle_Item_All_Text' | translate"
    ></combobox-edit-control>
    <div class="u-trailer"></div>

    <!-- Salary batch combobobox -->
    <combobox-edit-control
      [disable]="salaryBatchesDataSource && salaryBatchesDataSource.length === 1"
      [label]="'CompanySalaryBatches.Batches' | translate"
      [showNavigationButton]="salaryBatchesDataSource && salaryBatchesDataSource.length > 1"
      [resetDropdownOnNullDS]="true"
      [editMode]="true"
      [idField]="'Id'"
      [textField]="'TitleDescription'"
      [comboboxDataSource]="salaryBatchesDataSource"
      [(value)]="selectedComboboxSalaryBatchId"
      class="fixComboboxNextButton"
    ></combobox-edit-control>
    <div class="u-trailer"></div>

    <kendo-tabstrip #salaryBatchTab (tabSelect)="onSalaryBatchTabSelected($event)">
      <kendo-tabstrip-tab [title]="'CompanySalaryBatches.Basic' | translate" [selected]="true">
        <ng-template kendoTabContent>
          <salary-batch-info
            [popup]="false"
            [visible]="true"
            [hasIntegration]="integrationName"
            [salaryBatch]="basicSalaryBatch"
            [salaryCycles]="entityContext.SalaryCycles"
            [salaryStatements]="salaryStatements"
            [(selectedEmployeeIds)]="modalSelectedEmployeeIds"
            [isPaymentApprover]="session.IsPaymentApprover"
            [tooltipFinalizeContent]="tooltipFinalizeContent"
            (createPayrollBatch)="showNewSalaryBatchDialog()"
            (salaryBatchAction)="onSalaryBatchDialogAction($event)"
            (reloadSalaryBatches)="getSalaryBatches()"
            (closeDialog)="closeDialog()"
          ></salary-batch-info>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab
        *ngIf="entityContext.SelectedSalaryBatch?.HasErrors"
        [title]="'CompanySalaryBatches.Error' | translate"
      >
        <ng-template kendoTabContent>
          <salary-batch-errors-dialog
            [visible]="true"
            [salaryBatch]="entityContext.SelectedSalaryBatch"
            (resendBatch)="resendBatch($event)"
            (refreshSource)="getSalaryBatches()"
          ></salary-batch-errors-dialog>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'CompanySalaryBatches.Payslips' | translate">
        <ng-template kendoTabContent>
          <salary-batch-payslips
            (revertBatchCompleted)="onRevertBatchCompleted()"
            [salaryStatements]="salaryStatements"
            [selectedSalaryBatch]="entityContext.SelectedSalaryBatch"
          ></salary-batch-payslips>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'CompanySalaryBatches.Totals' | translate">
        <ng-template kendoTabContent>
          <salary-batch-salary-totals
            [SalaryBatchId]="entityContext.SelectedSalaryBatch?.Id"
            [showTitle]="false"
            [SalaryTypeTotals]="salaryTypeTotals"
            [UnitTotals]="unitTotals"
          ></salary-batch-salary-totals>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>

  <div class="Actions">
    <action-button
      id="ActionButtonAdd"
      [buttonClass]="'Action-button Action-buttonAdd'"
      [icon]="'Add'"
      [label]="'Company.New'"
      (click)="showNewSalaryBatchDialog()"
      [tooltip]="'CompanySalaryBatches.SalaryBatchAddNewButton'"
    ></action-button>
    <action-button
      id="ActionButtonReports"
      [buttonClass]="'Action-button Action-buttonReports'"
      [icon]="'File'"
      [label]="'Company.Reports'"
      (click)="prepareReportDialogParams()"
      [tooltip]="'Company.Reports'"
    ></action-button>
    <more-menu
      [module]="'company'"
      [currentTab]="'tabs.company.salarybatches'"
      (menuClick)="onMoreMenuOptionClick($event)"
    ></more-menu>
    <help-button [module]="'company'"></help-button>
  </div>
</div>

<!-- New salary batch dialog -->
<salary-batch-info
  [popup]="true"
  [(visible)]="salaryBatchDialogVisible"
  [hasIntegration]="integrationName"
  [salaryBatch]="childWindowSalaryBatch"
  [salaryStatements]="salaryStatements"
  [salaryCycles]="entityContext.SalaryCycles"
  [(selectedEmployeeIds)]="modalSelectedEmployeeIds"
  [isPaymentApprover]="session.IsPaymentApprover"
  [tooltipFinalizeContent]="tooltipFinalizeContent"
  [(salaryBatchContext)]="salaryBatchEntityContext"
  (salaryBatchAction)="onSalaryBatchDialogAction($event)"
  (reloadSalaryBatches)="getSalaryBatches()"
  (closeDialog)="closeDialog()"
></salary-batch-info>
<!-- /New salary batch dialog -->

<app-report-dialog
  class="SalaryBatchReport"
  [(visible)]="reportDialogVisible"
  [key]="'SalaryBatches'"
  [param]="reportParams"
></app-report-dialog>

<app-dialog
  (action)="onConfirmPasswordDialogAction($event)"
  [overlayClose]="false"
  [(visible)]="confirmPasswordDialogVisible"
>
  <div class="FormElement">
    <label class="FormElement-label">{{ 'Finalize.Warnings' | translate }}</label>
  </div>
  <div>
    <salary-batch-other-error-grid
      [visible]="confirmPasswordDialogVisible"
      [salaryBatch]="entityContext.SelectedSalaryBatch"
    ></salary-batch-other-error-grid>
  </div>

  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'AcceptWarnings'"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="alertDialogVisible" (action)="afterFinalizeError()">
  <div
    *ngIf="alertDialogType === 'noBatchSelectedWarning'"
    [innerHTML]="'CompanySalaryBatches.NoSalaryBatchSelectedWarning' | translate"
  ></div>
  <div
    *ngIf="alertDialogType === 'denyFinalizeAdminText'"
    [innerHTML]="'CompanySalaryBatches.DenyFinalize_AdminText' | translate"
  ></div>
  <div
    *ngIf="alertDialogType === 'denyFinalizeText'"
    [innerHTML]="'CompanySalaryBatches.DenyFinalize_Text' | translate"
  ></div>
  <div
    *ngIf="alertDialogType === 'finalizeErrorText'"
    [innerHTML]="'CompanySalaryBatches.FinalizeErrorText' | translate"
  ></div>
  <div
    *ngIf="alertDialogType === 'invalidDateMessage'"
    [innerHTML]="'CompanySalaryBatches.InvalidPaymentDate' | translate"
  ></div>
  <div
    *ngIf="alertDialogType === 'resendBatchCompletedMessage'"
    [innerHTML]="'CompanySalaryBatches.ResendBatchCompleteMessage' | translate"
  ></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onConfirmDeleteDialogAction($event)"
  [(visible)]="confirmDeleteDialogVisible"
  [leadingText]="'CompanySalaryBatches.deletingConfirmMessage' | translate"
>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Delete'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onConfirmRevertLockDialogAction($event)"
  [(visible)]="confirmRevertLockDialogVisible"
  [leadingText]="'CompanySalaryBatches.RevertToDraftConfirm' | translate"
>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onConfirmRevertLockDialogAction($event)"
  [(visible)]="confirmRejectDialogVisible"
  [leadingText]="'CompanySalaryBatches.RejectToDraftConfirm' | translate"
>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onEmptySalaryBatchDialogAction($event)"
  [(visible)]="emptySalaryBatchDialogVisible"
  [leadingText]="'CompanySalaryBatches.EmptySalaryBatchMessage' | translate"
>
  <app-dialog-action [type]="'No'"></app-dialog-action>
  <app-dialog-action [type]="'Yes'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onChooseResendTargetDialogAction($event)"
  [(visible)]="chooseResendTargetDialogVisible"
  [leadingText]="'CompanySalaryBatches.ResendBatch' | translate"
>
  <app-dialog-action [action]="'eincome'" [label]="'CompanySalaryBatches.Resend EIncome' | translate">
  </app-dialog-action>
  <app-dialog-action
    *ngIf="integrationName"
    [action]="'external'"
    [label]="
      ('CompanySalaryBatches.Resend External System' | translate).replace('$$$ExtSystemName$$$', externalSystemName)
    "
  ></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onResendBatchToEIncomeDialogAction($event)"
  [(visible)]="resendBatchToEIncomeDialogVisible"
  [leadingText]="'CompanySalaryBatches.ResendBatchMessage' | translate"
>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Resend'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onResendBatchToIntegrationDialogAction($event)"
  [(visible)]="resendBatchToIntegrationConfirmationDialogVisible"
  [leadingText]="
    ('CompanySalaryBatches.ResendBatchIntegrationConfirmMessage' | translate).replace(
      '$$$ExtSystemName$$$',
      externalSystemName
    )
  "
>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Resend'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="closeDialog()"
  [(visible)]="resendBatchExtCompletedDialogVisible"
  [leadingText]="
    ('CompanySalaryBatches.ResendBatchToExtSystemCompletedMessage' | translate).replace(
      '$$$ExtSystemName$$$',
      externalSystemName
    )
  "
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<lock-salary-batch
  *ngIf="lockSalaryBatchDialogVisible"
  [(visible)]="lockSalaryBatchDialogVisible"
  [salaryBatchId]="salaryBatchEntityContext?.BatchId"
  (batchLocked)="reloadSalaryBatches()"
>
</lock-salary-batch>

<salary-batch-errors-dialog
  [isTab]="false"
  [(visible)]="errorListDetailsDialogVisible"
  [salaryBatch]="entityContext.SelectedSalaryBatch"
  (resendBatch)="resendBatch($event)"
  (refreshSource)="getSalaryBatches()"
>
</salary-batch-errors-dialog>

<batch-finalization-step-2-dialogue
  [IntegrationName]="integrationName"
  [salarybatch]="entityContext.SelectedSalaryBatch"
  [(visible)]="salaryBatchFinalizationStep1Completed"
  [isClose]="salaryBatchFinalizationCloseAll"
  (stepCompletedChange)="onsalaryBatchFinalizationStep2Completed()"
  (finalizationExit)="closeFinalizationWizardDialogs()"
>
</batch-finalization-step-2-dialogue>
<!--StatusId: 10 => "Draft"-->
<batch-finalization-step-3-dialogue
  [salaryBatch]="entityContext.SelectedSalaryBatch"
  [(visible)]="salaryBatchFinalizationStep2Completed"
  [isClose]="salaryBatchFinalizationCloseAll"
  [(doRecalculation)]="salaryBatchFinalizationStep3RecalculationConfirm"
  (stepCompletedChange)="onsalaryBatchFinalizationStep3Completed()"
  (finalizationExit)="closeFinalizationWizardDialogs()"
>
</batch-finalization-step-3-dialogue>

<batch-finalization-step-4-dialogue
  [salaryBatch]="entityContext.SelectedSalaryBatch"
  [step1Completed]="salaryBatchFinalizationStep1Completed"
  [doRecalculation]="salaryBatchFinalizationStep3RecalculationConfirm"
  [(visible)]="salaryBatchFinalizationStep3Completed"
  (finalizationExit)="closeFinalizationWizardDialogs()"
  (finalizationDone)="onFinalizationProcessDone()"
>
</batch-finalization-step-4-dialogue>

<revert-finalized-batch
  [integrationName]="integrationName"
  [salaryBatch]="entityContext.SelectedSalaryBatch"
  [(visible)]="showRevertSalaryBatchDialog"
  (revertBatchCompleted)="onRevertBatchCompleted()"
>
</revert-finalized-batch>

<app-dialog [(visible)]="noEmployeeActiveVisible" [leadingText]="'CompanySalaryBatches.NoActiveEmployee' | translate">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
