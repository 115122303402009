<div class="FormElement PaddingTop" [ngClass]="{ 'has-fixedAddon': addonIcon, 'FormElement-ViewMode': !editMode }">
  <span
    *ngIf="label"
    kendoTooltip
    title="{{ tooltipContent | translate }}"
    [ngClass]="{ 'u-hiddenVisually': icon }"
    class="FormElement-label keepIconInline"
  >
    <abbr *ngIf="isCPRno" class="FormElement-required" title="required">*</abbr>{{ label
    }}<i *ngIf="!hasMask && editMode && isTextArea">( {{ TextLenght }} )</i>:
    <span *ngIf="addonIcon && !addonSize" class="iconColor iconPosition">
      <app-icon [icon]="addonIcon" [tooltip]="addonTooltip" [link]="addonLink" (click)="addonClick.emit()"></app-icon>
    </span>
    <span *ngIf="addonIcon && addonSize" class="iconColor iconPosition">
      <app-icon
        [icon]="addonIcon"
        [size]="addonSize"
        [tooltip]="addonTooltip"
        [link]="addonLink"
        (click)="addonClick.emit()"
      ></app-icon>
    </span>
  </span>

  <div class="FormElement-control" [ngClass]="{ 'FormElement-control--iconLeft': icon }">
    <div class="FormElement-static" *ngIf="!editMode" [ngClass]="viewClass">
      {{ value }}
    </div>

    <app-icon *ngIf="icon" [icon]="icon"></app-icon>

    <kendo-maskedtextbox
      [id]="elementId"
      [mask]="mask"
      [disabled]="disabled"
      *ngIf="hasMask && editMode"
      [readonly]="readOnly"
      [ngClass]="{ inputClass: inputClass, 'input-required': !isValid || this.requiredValueMissing }"
      [(ngModel)]="value"
      (focus)="onFocus()"
      (blur)="onBlur()"
    >
    </kendo-maskedtextbox>

    <input
      autocomplete="{{ autocorrect }}"
      kendoTextBox
      [id]="elementId"
      [maxLength]="maxLength || 9999"
      [placeholder]="placeholder || ''"
      class="FormElement-input"
      [disabled]="disabled"
      [readonly]="readOnly"
      *ngIf="!hasMask && editMode && !isTextArea"
      [ngClass]="setInputClass()"
      [(ngModel)]="value"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [type]="type === 'password' ? (showPassword ? 'text' : 'password') : type"
    />

    <textarea
      kendoTextArea
      [id]="elementId"
      [maxLength]="maxLength || 9999"
      [placeholder]="placeholder || ''"
      class="FormElement-input {{ TextAreaClass }}"
      [disabled]="disabled"
      [readonly]="readOnly"
      *ngIf="!hasMask && editMode && isTextArea"
      [ngClass]="setInputClass()"
      [(ngModel)]="value"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keypress)="TextAreakeypress()"
      rows="{{ TextAreaRows }}"
    ></textarea>

    <button
      *ngIf="type === 'password'"
      id="showPasswordButton"
      class="FormElement-passwordToggle"
      type="button"
      (mousedown)="showPassword = true"
      (mouseup)="showPassword = false"
      (mouseleave)="showPassword = false"
    >
      <app-icon [icon]="'Preview'"></app-icon>
    </button>
  </div>
</div>
