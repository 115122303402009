/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./EmployeeList/EmployeeList.component.ngfactory";
import * as i2 from "./EmployeeList/EmployeeList.component";
import * as i3 from "../Services/RxDataService";
import * as i4 from "../Services/SharedServices/SettingService";
import * as i5 from "../Services/SharedServices/Session/SessionService";
import * as i6 from "../Services/SharedServices/StaticData.service";
import * as i7 from "./EmployeeTab/EmployeeTabs.component.ngfactory";
import * as i8 from "./EmployeeTab/EmployeeTabs.component";
import * as i9 from "@uirouter/core";
import * as i10 from "./Employee.component";
import * as i11 from "../Services/Broadcaster";
var styles_EmployeeComponent = [];
var RenderType_EmployeeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmployeeComponent, data: {} });
export { RenderType_EmployeeComponent as RenderType_EmployeeComponent };
export function View_EmployeeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-employee-list", [], null, [[null, "refreshEmployeeChange"], [null, "selectedEmployeeChange"], [null, "addEmployee"], [null, "preLoadAvatar"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("refreshEmployeeChange" === en)) {
        var pd_0 = ((_co.refreshCurrentEmployee = $event) !== false);
        ad = (pd_0 && ad);
    } if (("selectedEmployeeChange" === en)) {
        var pd_1 = (_co.setCurrentEmployee($event) !== false);
        ad = (pd_1 && ad);
    } if (("addEmployee" === en)) {
        var pd_2 = (_co.onAddEmployeeClicked() !== false);
        ad = (pd_2 && ad);
    } if (("preLoadAvatar" === en)) {
        var pd_3 = (_co.preLoadAvatar($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_EmployeeListComponent_0, i1.RenderType_EmployeeListComponent)), i0.ɵdid(2, 245760, null, 0, i2.EmployeeListComponent, [i3.RxDataService, i4.SettingService, i5.SessionService, i6.StaticDataService], { editMode: [0, "editMode"], isAvatarChanged: [1, "isAvatarChanged"], newEmployee: [2, "newEmployee"], deletedEmployee: [3, "deletedEmployee"], refreshEmployee: [4, "refreshEmployee"], globalKeyDown: [5, "globalKeyDown"] }, { selectedEmployeeChange: "selectedEmployeeChange", preLoadAvatar: "preLoadAvatar", addEmployee: "addEmployee", refreshEmployeeChange: "refreshEmployeeChange" }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "employee-tabs", [], null, [[null, "employeeChange"], [null, "editModeChange"], [null, "newEmployeeCreated"], [null, "isAvatarChanged"], [null, "employeeUpdated"], [null, "employeeDeleted"], [null, "addNewEmployeeFiredChange"], [null, "avatarChangeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("employeeChange" === en)) {
        var pd_0 = ((_co.currentCompanyUser = $event) !== false);
        ad = (pd_0 && ad);
    } if (("editModeChange" === en)) {
        var pd_1 = ((_co.editMode = $event) !== false);
        ad = (pd_1 && ad);
    } if (("newEmployeeCreated" === en)) {
        var pd_2 = (_co.selectNewEmployee($event) !== false);
        ad = (pd_2 && ad);
    } if (("isAvatarChanged" === en)) {
        var pd_3 = (_co.onAvatarChanged() !== false);
        ad = (pd_3 && ad);
    } if (("employeeUpdated" === en)) {
        var pd_4 = (_co.updateEmployee($event) !== false);
        ad = (pd_4 && ad);
    } if (("employeeDeleted" === en)) {
        var pd_5 = (_co.reloadEmployeeList($event) !== false);
        ad = (pd_5 && ad);
    } if (("addNewEmployeeFiredChange" === en)) {
        var pd_6 = ((_co.addEmployeeFired = $event) !== false);
        ad = (pd_6 && ad);
    } if (("avatarChangeChange" === en)) {
        var pd_7 = ((_co.avatarChange = $event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, i7.View_EmployeeTabsComponent_0, i7.RenderType_EmployeeTabsComponent)), i0.ɵdid(4, 114688, null, 0, i8.EmployeeTabsComponent, [i3.RxDataService, i4.SettingService, i5.SessionService, i9.TransitionService, i6.StaticDataService], { globalKeyDown: [0, "globalKeyDown"], isBlankEmail: [1, "isBlankEmail"], avatarChange: [2, "avatarChange"], employee: [3, "employee"], editMode: [4, "editMode"], addNewEmployeeFired: [5, "addNewEmployeeFired"] }, { employeeChange: "employeeChange", isAvatarChanged: "isAvatarChanged", editModeChange: "editModeChange", addNewEmployeeFiredChange: "addNewEmployeeFiredChange", newEmployeeCreated: "newEmployeeCreated", employeeDeleted: "employeeDeleted", employeeUpdated: "employeeUpdated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editMode; var currVal_1 = _co.isAvatarChanged; var currVal_2 = _co.newCompanyUser; var currVal_3 = _co.deletedEmployeeId; var currVal_4 = _co.refreshCurrentEmployee; var currVal_5 = _co.globalKeyDown; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.globalKeyDown; var currVal_7 = _co.isBlankEmail; var currVal_8 = _co.avatarChange; var currVal_9 = _co.currentCompanyUser; var currVal_10 = _co.editMode; var currVal_11 = _co.addEmployeeFired; _ck(_v, 4, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }, null); }
export function View_EmployeeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "employee", [], null, null, null, View_EmployeeComponent_0, RenderType_EmployeeComponent)), i0.ɵdid(1, 180224, null, 0, i10.EmployeeComponent, [i11.Broadcaster, i5.SessionService], null, null)], null, null); }
var EmployeeComponentNgFactory = i0.ɵccf("employee", i10.EmployeeComponent, View_EmployeeComponent_Host_0, { editMode: "editMode" }, { editModeChange: "editModeChange" }, []);
export { EmployeeComponentNgFactory as EmployeeComponentNgFactory };
