import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Constants } from '../../../../Common/Constants';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import { Global } from '../../../../Common/Global';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
var TaxCardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TaxCardComponent, _super);
    function TaxCardComponent(dataService, sessionService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.sessionService = sessionService;
        _this.reloadEmploymentEvent = new EventEmitter();
        _this.annualTaxComment = new EventEmitter();
        _this.companyUserChange = new EventEmitter();
        _this.selfService = '';
        _this.isSelfService = false;
        _this.option = { format: 'n0' };
        _this.requestTaxCard = new EventEmitter();
        _this.viewTaxCardHistory = new EventEmitter();
        _this.requestTaxcardConfirm = new EventEmitter();
        _this.refreshTaxCardChange = new EventEmitter();
        _this.childIsValid = Array(5).fill(true);
        _this.childIsDirty = Array(5).fill(false);
        return _this;
    }
    Object.defineProperty(TaxCardComponent.prototype, "companyUser", {
        get: function () {
            return this.companyUserValue;
        },
        set: function (value) {
            if (!this.companyUserValue && !value) {
                return;
            }
            this.companyUserValue = value;
            this.companyUserChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "userEmployment", {
        get: function () {
            if (this.userEmploymentValue === undefined) {
                this.userEmploymentValue = {};
            }
            return this.userEmploymentValue;
        },
        set: function (value) {
            this.userEmploymentValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "taxCard", {
        get: function () {
            if (!this.taxcardValue) {
                this.taxcardValue = { taxInfo: {} };
            }
            if (!this.taxcardValue.taxInfo) {
                this.taxcardValue.taxInfo = {};
            }
            return this.taxcardValue;
        },
        set: function (value) {
            if (value && value.taxInfo === undefined) {
                value.taxInfo = {};
            }
            if (value && value.userEmploymentId && JSON.stringify(this.taxcardValue) !== JSON.stringify(value)) {
                this.loadEmploymentTaxInfo(value.userEmploymentId);
            }
            this.taxcardValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "refreshTaxCard", {
        get: function () {
            return this.refreshTaxCardValue;
        },
        set: function (value) {
            if (this.refreshTaxCardValue !== value) {
                this.refreshTaxCardValue = value;
                this.refreshTaxCardChange.emit(value);
                if (value) {
                    this.reloadTaxCard();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "hasEmployment", {
        get: function () {
            return this.taxCard && this.taxCard.userEmploymentId ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "hasSpecialTaxCardRule", {
        get: function () {
            return this.hasEmployment && this.taxCard && this.taxCard.specialTaxBrief;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "showTaxCardInfo", {
        get: function () {
            return this.hasEmployment && ((this.taxCard && !this.taxCard.specialTaxBrief) || !this.taxCard);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "showFreeAmount", {
        get: function () {
            return this.taxCard && this.taxCard.taxInfo && this.taxCard.taxInfo.TaxCardTypeId === 3;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "showDeductionPerMonth", {
        get: function () {
            return this.taxCard && this.taxCard.taxInfo && this.taxCard.taxInfo.TaxCardTypeId === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "isAllowEditTaxCard", {
        get: function () {
            return this.allowEdit && this.hasEmployment;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "isTaxCardRequested", {
        get: function () {
            return this.taxcardValue && this.taxcardValue.taxCardRequestTime ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "isDanishCompany", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "IsSelfServiceGenneral", {
        get: function () {
            return this.sessionService.currentState === 'tabs.selfservice.general';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "top8px", {
        get: function () {
            return this.sessionService.currentState === 'tabs.selfservice.general' && this.editMode ? 'Top8px' : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "isDanishCompanyAndInSelfServiceGenneral", {
        get: function () {
            if (this.isDanishCompany && this.IsSelfServiceGenneral) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "admin", {
        get: function () {
            if (Global.SESSION.CurrentRole.Key === 'FullAdmin' ||
                Global.SESSION.CurrentRole.Key === 'SalaryAdmin' ||
                this.sessionService.currentState === 'tabs.selfservice.general') {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    TaxCardComponent.prototype.showRequestTaxCardDialog = function () {
        this.requestTaxCard.emit();
    };
    TaxCardComponent.prototype.onViewTaxCardHistoryButtonClickEvent = function () {
        this.viewTaxCardHistory.emit(this.taxCard.taxInfo);
    };
    TaxCardComponent.prototype.onRequestTaxCardClickEvent = function () {
        if (this.isTaxCardRequested) {
            this.requestTaxcardConfirm.emit(this.taxCard.taxInfo);
        }
        else {
            this.showRequestTaxCardDialog();
        }
    };
    TaxCardComponent.prototype.reloadTaxCard = function () {
        this.loadEmploymentTaxInfo(this.taxCard.userEmploymentId);
        this.onChange();
        this.isDirty = false;
        this.reloadEmploymentEvent.emit();
    };
    TaxCardComponent.prototype.loadEmploymentTaxInfo = function (userEmploymentId) {
        var _this = this;
        // if (this.taxCardState) {
        //   this.taxCardState.unsubscribe();
        //   return;
        // }
        this.dataService.CompanyUsers_GetCurrentTaxInfo(userEmploymentId).subscribe(function (data) {
            _this.taxCard.taxInfo = data;
        });
    };
    TaxCardComponent.prototype.onControlChanged = function () {
        this.onChange();
    };
    return TaxCardComponent;
}(FormComponentBase));
export { TaxCardComponent };
