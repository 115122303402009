import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ReflectionHelper } from '../Common/ReflectionHelper';

@Component({
  selector: 'checkgrid-edit',
  templateUrl: './CheckboxGrid.component.html'
})
export class CheckboxGridComponent {
  @Input() public label: string;
  @Input() public tooltip = '';
  @Input() public disable: boolean;
  @Input() public helpLink: string;
  @Input() public helpTooltip = '';
  @Input() public isOpenLink = true;
  @Input() public customClass: string;
  @Input() public helpIcon = 'Help';
  @Input() public selfService = '';
  @Input() public isLink = false;
  @Input() public isGrid = true;
  @Input() public editMode = true;
  @Input() public isDirty: any;
  @Input() public value: any;
  @Input() public hostClass: any;
  @Input() public preventChange: any;
  @Input() public isValid: any;

  public get labelClass(): string {
    return this.customClass + ' ' + (this.isGrid ? 'checkBox-label-center' : '');
  }

  public get isDisabled(): boolean {
    return this.disable ? this.disable : !this.editMode;
  }

  @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {}
  protected valid(): boolean {
    return true;
  }

  protected convertValue(value: any): boolean {
    if (value && ReflectionHelper.isString(value)) {
      return value.toLowerCase() === 'true';
    } else {
      return value;
    }
  }

  public onChange(value: boolean) {
    this.valueChange.emit(value);
  }
}
