import { EventEmitter } from '@angular/core';
import { SalaryBatchViewModel } from '../SalaryBatchViewModel';
var BatchFinalizationStep3DialogueComponent = /** @class */ (function () {
    function BatchFinalizationStep3DialogueComponent() {
        this.autorecalculate = false;
        this.doRecalculationChange = new EventEmitter();
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.stepCompletedChange = new EventEmitter();
        this.finalizationExit = new EventEmitter();
        this.recalculateBeforeFinalizing = false;
    }
    Object.defineProperty(BatchFinalizationStep3DialogueComponent.prototype, "isClose", {
        get: function () {
            return this.visible;
        },
        set: function (value) {
            if (value) {
                this.visible = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep3DialogueComponent.prototype, "doRecalculation", {
        get: function () {
            return this.doRecalculationValue;
        },
        set: function (value) {
            var _this = this;
            if (this.doRecalculationValue !== value) {
                this.doRecalculationValue = value;
                setTimeout(function () { return _this.doRecalculationChange.emit(value); });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep3DialogueComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            var _this = this;
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                setTimeout(function () { return _this.visibleChange.emit(value); });
                if (value) {
                    var draftSalaryBatch = this.salaryBatch.StatusId === 10;
                    if ( /*this.autorecalculate &&*/draftSalaryBatch) {
                        this.doRecalculation = true;
                        this.stepCompleted = true;
                        this.visibleValue = false;
                        // this.stepCompletedChange.emit(value);
                    }
                    else {
                        this.recalculateBeforeFinalizing = draftSalaryBatch;
                        this.doRecalculation = false;
                    }
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep3DialogueComponent.prototype, "stepCompleted", {
        // @Input()
        get: function () {
            return this.stepCompletedValue;
        },
        set: function (value) {
            var _this = this;
            if (this.stepCompletedValue !== value) {
                // this.stepCompletedValue = value;
                setTimeout(function () { return _this.stepCompletedChange.emit(value); });
            }
        },
        enumerable: true,
        configurable: true
    });
    BatchFinalizationStep3DialogueComponent.prototype.onDialogAction = function (action) {
        if (action === 'Proceed') {
            this.doRecalculation = this.recalculateBeforeFinalizing;
            this.stepCompleted = true;
        }
        else {
            this.finalizationExit.emit();
        }
    };
    return BatchFinalizationStep3DialogueComponent;
}());
export { BatchFinalizationStep3DialogueComponent };
