<div class="FormElement" [ngClass]="{ 'has-fixedAddon': hasFeedback, 'FormElement-ViewMode': !editMode }">
  <span class="FormElement-label" *ngIf="!hideLabel">
    <abbr *ngIf="requireSign" class="FormElement-required" title="required">*</abbr> {{ translatedLabel }}:
  </span>
  <div class="FormElement-control">
    <div class="FormElement-static" *ngIf="!editMode">{{ displayTimeValue }}</div>

    <kendo-combobox
      #kendoComboboxControl
      class="FormElement-select"
      *ngIf="!isMobile && editMode"
      [placeholder]="translatedPlaceHolder"
      [disabled]="disable"
      [ngClass]="{ disable: disable }"
      [suggest]="true"
      [valuePrimitive]="true"
      [allowCustom]="true"
      [filterable]="true"
      (filterChange)="onComboBoxFiltering($event)"
      [data]="timeDataSource"
      [(ngModel)]="value"
      (focus)="onfocus($event)"
      (valueChange)="onComboboxChange($event)"
      (keydown)="onkeydown($event)"
    >
    </kendo-combobox>

    <kendo-dropdownlist
      #kendoDropdownListControl
      class="FormElement-select"
      *ngIf="isMobile && editMode"
      [disabled]="disable"
      [ngClass]="{ disable: disable }"
      [valuePrimitive]="true"
      [data]="timeDataSource"
      [(ngModel)]="value"
      [filterable]="true"
      (filterChange)="onComboBoxFiltering($event)"
      (valueChange)="onComboboxChange($event)"
    >
    </kendo-dropdownlist>

    <span *ngIf="addonVisible && addonIcon" class="FormElement-addon FormElement-addonSpace Icon-addOn">
      <app-icon [icon]="addonIcon" [tooltip]="addonTooltip" [link]="addonLink" (click)="onAddonButtonClick()">
      </app-icon>
    </span>
  </div>

  <div class="FormElement-feedback" *ngIf="hasFeedback">{{ feedbackKey | translate }}</div>
</div>
