import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../Common/Constants';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { Global } from '../../Common/Global';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { OffDayCompensationModel } from './OffDayCompensationModel';
var OffDayCompensationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OffDayCompensationComponent, _super);
    function OffDayCompensationComponent(staticDataService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.modelChange = new EventEmitter();
        _this.childIsDirty = Array(2).fill(false);
        return _this;
    }
    Object.defineProperty(OffDayCompensationComponent.prototype, "model", {
        get: function () {
            if (!this.modelValue) {
                this.modelValue = new OffDayCompensationModel();
            }
            return this.modelValue;
        },
        set: function (value) {
            this.modelValue = value;
            if (this.modelChange) {
                this.modelChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    OffDayCompensationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.PensionBaseCalculationMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.offDayCompensationCalculationMethods = data); });
    };
    Object.defineProperty(OffDayCompensationComponent.prototype, "isDanishCompany", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    return OffDayCompensationComponent;
}(FormComponentBase));
export { OffDayCompensationComponent };
