// tslint:disable:prefer-const
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as Model from './ApiModel';

@Injectable()
export class RxApiDataService {
  public baseUrl: string = environment.apiUrl;
  private datePipe: DatePipe = new DatePipe('en-US');

  constructor() {}

  /**
   * Retrieves information about the Account object related to the current user. An Account is a containing entity for
   * Companies and Users, and can refer to a payroll agency, accountant firm, corporation or similar.
   */
  public Account_GetCurrentAccount(): Observable<Model.IAccount> {
    let path: string = this.baseUrl + '/api/account/current';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all companies that belong to the current Account, with various information targeted towards
   * accountants/bookkeepers. Note that this method cannot be used in conjunction with PUT or POST methods, it is
   * read-only.
   */
  public Account_GetAccountOverview(): Observable<Model.ICompanyAccountView[]> {
    let path: string = this.baseUrl + '/api/account/companies';
    return this.httpGet(path);
  }

  /**
   * Creates a new company belonging to the current Account. Automatically grants administrator access to the current
   * user. Provides options to copy configuration from an existing company.
   * @param An object containing information about the company to create.
   */
  public Account_CreateCompany(request: Model.AccountCompanyCreationRequest): Observable<Model.ICompany> {
    let path: string = this.baseUrl + '/api/account/companies';
    return this.httpPost(path, request);
  }

  /**
   * Attaches an existing Gratisal company to the current Account. Requires that the current user already has full
   * administrator access to the company.
   * @param The ID of the company to attach.
   */
  public Account_AttachCompany(companyId: number): Observable<Model.ICompany> {
    let path: string = this.baseUrl + '/api/account/companies/existing/{companyId}';
    path = path.replace('{companyId}', companyId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Sets AccountComment for the relevant company. This field is only visible to users associated with the company's
   * Account.
   * @param The ID of the company for which to update the comment.
   * @param An object containing the body of the comment.
   */
  public Account_SetAccountComment(
    companyId: number,
    request: Model.AccountCommentRequest
  ): Observable<Model.IAccountCommentRequest> {
    let path: string = this.baseUrl + '/api/account/companies/{companyId}/comment';
    path = path.replace('{companyId}', companyId.toString());
    return this.httpPut(path, request);
  }

  /**
   * Retrieves a list of all users that belong to the current Account.
   */
  public Account_GetAccountUsers(): Observable<Model.IUser[]> {
    let path: string = this.baseUrl + '/api/account/users';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all users that belong to the current Account, along with information about their access to
   * the specified company.
   * @param The ID of the company for which to relate the access information.
   */
  public Account_GetUserCompanyAccess(companyId: number): Observable<Model.IUserCompanyAccess[]> {
    let path: string = this.baseUrl + '/api/account/companies/{companyId}/users';
    path = path.replace('{companyId}', companyId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all companies that belong to the current Account, along with information about a specific
   * user's access to that company.
   * @param The ID of the user for which to relate the access information.
   */
  public Account_GetCompanyAccessByUser(userId: number): Observable<Model.IUserCompanyAccess[]> {
    let path: string = this.baseUrl + '/api/account/users/{userId}/companies';
    path = path.replace('{userId}', userId.toString());
    return this.httpGet(path);
  }

  /**
   * Grants or revokes access to a specific company for a user within the relevant Account. This can have the effect
   * of either creating, updating, or deactivating a CompanyUser.
   * @param The ID of the company for which to grant or revoke access.
   * @param The ID of the user to assign access to.
   * @param The ID of the Role (api/staticdata/Role) to assign the user in the specified company. Send 0 to revoke
   *        access, deactivating the CompanyUser.
   * @param Indicates whether the user should be allowed to finalize payroll batches. This is defined independently of
   *        the role. Submit "true" or "false".
   */
  public Account_AssignUserAccessToCompany(
    companyId: number,
    userId: number,
    roleId: number,
    isPaymentApprover: boolean
  ): Observable<Model.ICompanyUser> {
    let path: string =
      this.baseUrl +
      '/api/account/companies/{companyId}/user/{userId}/role/{roleId}/ispaymentapprover/{isPaymentApprover}';
    path = path.replace('{companyId}', companyId.toString());
    path = path.replace('{userId}', userId.toString());
    path = path.replace('{roleId}', roleId.toString());
    path = path.replace('{isPaymentApprover}', isPaymentApprover.toString());
    return this.httpPut(path, undefined);
  }

  /**
   * Submits a user identifier (e-mail, CPR number or username) to retrieve a session token.
   */
  public Auth_Login(): Observable<any> {
    let path: string = this.baseUrl + '/api/auth/login';
    return this.httpPost(path, undefined);
  }

  public Auth_RefreshLogin(): Observable<any> {
    let path: string = this.baseUrl + '/api/auth/login/refresh';
    return this.httpPost(path, undefined);
  }

  /**
   * Terminates a login session.
   */
  public Auth_Logout(): Observable<string> {
    let path: string = this.baseUrl + '/api/auth/logout';
    return this.httpPost(path, undefined);
  }

  /**
   * Retrieves information about the current login session.
   */
  public Auth_GetSessionInfo(): Observable<Model.IApiUser> {
    let path: string = this.baseUrl + '/api/auth/session';
    return this.httpGet(path);
  }

  /**
   * Determines whether the login session is still active (response code 200 OK) or has expired (response code 401
   * Unauthorized).
   *
   * NOTE: A) Calling this method will not extend the lifetime of the session. B) This method is also used to check
   * for important notifications. If such a notification is relevant, it will be returned with a 400 Bad Request
   * response.
   */
  public Auth_IsSessionAlive(): Observable<boolean> {
    let path: string = this.baseUrl + '/api/auth/session/isalive';
    return this.httpGet(path);
  }

  /**
   * Changes the password of the currently logged-in user.
   */
  public Auth_ChangePassword(request: Model.ChangePasswordRequest): Observable<void> {
    let path: string = this.baseUrl + '/api/auth/changepw';
    return this.httpPost(path, request);
  }

  public Auth_ValidatePassword(request: Model.ValidatePasswordRequest): Observable<boolean> {
    let path: string = this.baseUrl + '/api/auth/validatepw';
    return this.httpPost(path, request);
  }

  /**
   * Requests a password reset. Identity number (CPR) and e-mail must match. NOTE: This method is deprecated, use
   * RequestPasswordResetWithLanguage instead.
   */
  public Auth_RequestPasswordReset(request: Model.ResetPasswordRequest): Observable<void> {
    let path: string = this.baseUrl + '/api/auth/resetpw';
    return this.httpPost(path, request);
  }

  /**
   * Requests a password reset. Identity number (CPR) and e-mail must match.
   * @param The language code for any messages that should be returned to the user. Valid values are "dk", "gl" or
   *        "en".
   * @param An object containing the information necessary to identify the user.
   */
  public Auth_RequestPasswordResetWithLanguage(
    languageCode: string,
    request: Model.ResetPasswordRequest
  ): Observable<void> {
    let path: string = this.baseUrl + '/api/auth/resetpw/lang/{languageCode}';
    path = path.replace('{languageCode}', languageCode.toString());
    return this.httpPost(path, request);
  }

  /**
   * Sets the current company context for the login session. All subsequent calls will be taken in the context of the
   * specified company.
   */
  public Auth_SetCompanyContext(companyId: number): Observable<Model.ICompany> {
    let path: string = this.baseUrl + '/api/auth/company/{companyId}';
    path = path.replace('{companyId}', companyId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Sets the current language context for the login session. All subsequent server messages etc. will be served in
   * the specified language.
   */
  public Auth_SetLanguageContext(languageId: number): Observable<string> {
    let path: string = this.baseUrl + '/api/auth/language/{languageId}';
    path = path.replace('{languageId}', languageId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Sends invalid email notifications to the administrator of the passed list of emails. NOTE: This method is only
   * accessible to Gratisal employees.
   */
  public Backend_NotifyAdminsOfFailedEmails(emails: string): Observable<string[]> {
    let path: string = this.baseUrl + '/api/backend/emailnotify';
    path += '?emails=' + emails;
    return this.httpPost(path, undefined);
  }

  /**
   * Registers billable usage on a company, typically support. This method is available to Gratisal employees only.
   * @param An object containing information about the module and amount of units to be billed.
   */
  public Backend_RegisterUsage(usage: Model.BillingUsage): Observable<Model.IBillingUsage> {
    let path: string = this.baseUrl + '/api/backend/usage';
    return this.httpPost(path, usage);
  }

  /**
   * Creates a copy of the current database with the specified name on the same SQL Azure server. Note this method is
   * for internal development use only. It should not be called by external API clients, and it will not work in a
   * production environment.
   * @param The desired name of the new database. Note the input is sanitized, and any spaces (among other things) are
   *        removed.
   */
  public Backend_CreateDatabaseCopy(newDatabaseName: string): Observable<string[]> {
    let path: string = this.baseUrl + '/api/backend/copydatabase/{newDatabaseName}';
    path = path.replace('{newDatabaseName}', newDatabaseName.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Retrieves a list of all companies that the user has access to.
   */
  public Companies_GetAll(): Observable<Model.ICompany[]> {
    let path: string = this.baseUrl + '/api/companies';
    return this.httpGet(path);
  }

  /**
   * Retrieves a simplified list of all companies that the user has access to - including only a few key properties.
   */
  public Companies_GetAllSimple(): Observable<Model.ISimpleCompany[]> {
    let path: string = this.baseUrl + '/api/companies/simple';
    return this.httpGet(path);
  }

  /**
   * Submits a request to sign up as a new customer - creating an account, a company and a user. This method may be
   * called anonymously.
   * @param An object containing information about the company to create. Note that AccountId and RequestingUserId
   *        must always be null when using this route. To create a new company when logged on, call POST to
   *        api/account/companies instead.
   */
  public Companies_Signup(request: Model.CompanyCreationRequest): Observable<Model.ICompany> {
    let path: string = this.baseUrl + '/api/companies/signup';
    return this.httpPost(path, request);
  }

  /**
   * Gets information about a company by CVR number. Powered by www.cvrapi.dk.
   */
  public Companies_GetByCvr(cvrNumber: string): Observable<Model.ICvrCompany> {
    let path: string = this.baseUrl + '/api/companies/cvr/{cvrNumber}';
    path = path.replace('{cvrNumber}', cvrNumber.toString());
    return this.httpGet(path);
  }

  /**
   * Gets information about the currently active company.
   */
  public Companies_GetCurrent(): Observable<Model.ICompany> {
    let path: string = this.baseUrl + '/api/companies/current';
    return this.httpGet(path);
  }

  /**
   * Updates the currently active company.
   * @param The company to update.
   */
  public Companies_UpdateCurrent(company: Model.Company): Observable<Model.ICompany> {
    let path: string = this.baseUrl + '/api/companies/current';
    return this.httpPut(path, company);
  }

  /**
   * Gets information about the specified company, naturally assuming you have access to it.
   */
  public Companies_Get(companyId: number): Observable<Model.ICompany> {
    let path: string = this.baseUrl + '/api/companies/{companyId}';
    path = path.replace('{companyId}', companyId.toString());
    return this.httpGet(path);
  }

  /**
   * Gets the currently active company's logo as a raw byte array. Defaults to the current user if no Id is specified.
   */
  public Companies_GetCompanyLogo(): Observable<string> {
    let path: string = this.baseUrl + '/api/companies/current/logo';
    return this.httpGet(path);
  }

  /**
   * Sets the current company's logo. The image must be attached to the request as a file.
   */
  public Companies_SetCompanyLogo(): Observable<string> {
    let path: string = this.baseUrl + '/api/companies/current/logo';
    return this.httpPost(path, undefined);
  }

  /**
   * Gets a list of departments in the currently active company.
   */
  public Companies_GetDepartments(): Observable<Model.IDepartment[]> {
    let path: string = this.baseUrl + '/api/companies/current/departments';
    return this.httpGet(path);
  }

  /**
   * Updates an existing department under the currently active company.
   * @param The department to update.
   */
  public Companies_UpdateDepartment(department: Model.Department): Observable<Model.IDepartment> {
    let path: string = this.baseUrl + '/api/companies/current/departments';
    return this.httpPut(path, department);
  }

  /**
   * Creates a new department under the currently active company.
   * @param The department to create.
   */
  public Companies_CreateDepartment(department: Model.Department): Observable<Model.IDepartment> {
    let path: string = this.baseUrl + '/api/companies/current/departments';
    return this.httpPost(path, department);
  }

  /**
   * Deletes or deactivates an existing department in the currently active company. If the department references any
   * employees, templates or payroll batch records it will be deactivated; otherwise deleted.
   * @param The ID of the department to update.
   */
  public Companies_DeleteDepartment(departmentId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/companies/current/departments/{departmentId}';
    path = path.replace('{departmentId}', departmentId.toString());
    return this.httpDelete(path);
  }

  /**
   * Specifies that the company has accepted Gratisal's general terms. This is a legacy method only relevant for
   * companies that signed up in May 2018 or earlier.
   * @param An object containing the password of the currently logged-in user.
   */
  public Companies_AcceptTerms(password: Model.AcceptTermsRequest): Observable<void> {
    let path: string = this.baseUrl + '/api/companies/current/terms';
    return this.httpPost(path, password);
  }

  /**
   * Gets a list of all active users in the currently active company (i.e. CompanyUsers) along with their employment
   * info (if any).
   */
  public CompanyUsers_GetCompanyUsers(): Observable<Model.ICompanyUser[]> {
    let path: string = this.baseUrl + '/api/companyusers';
    return this.httpGet(path);
  }

  /**
   * Updates an existing company user, including submitted Address and UserEmployment child objects (but not child
   * objects of UserEmployments).
   *
   * Notice that other child objects cannot be added and removed this way, only modified. Provide null values (not
   * empty lists) to leave child objects unmodified.
   *
   * Note that the convenience fields UserLanguageId and UserIdentityNumber, which really belong to the User object in
   * the hiarchy, may be updated this way.
   *
   * Finally, notice that users with the Employee role can only update their own CompanyUsers, and NOT the attached
   * UserEmployment objects.
   * @param The company user to update.
   */
  public CompanyUsers_UpdateCompanyUser(companyUser: Model.CompanyUser): Observable<Model.ICompanyUser> {
    let path: string = this.baseUrl + '/api/companyusers';
    return this.httpPut(path, companyUser);
  }

  /**
   * Adds a user to the currently active company, optionally specifying EITHER a set of employment data OR a template
   * to use for the employment. The user is created in the Gratisal database if he/she does not already exist, or
   * mapped to the company if he/she does.
   * @param An object containing information about the user to create. Note: To generate a dummy CPR number, specify
   *        "DDMMYYXXXX" where DDMMYY is the user's birth date and XXXX are literal values.
   */
  public CompanyUsers_AddUserToCompany(request: Model.AddUserToCompanyRequest): Observable<Model.IUserCreationData> {
    let path: string = this.baseUrl + '/api/companyusers';
    return this.httpPost(path, request);
  }

  /**
   * Gets a list of all users (including inactive ones) in the currently active company (i.e. CompanyUsers) along with
   * their employment info (if any).
   */
  public CompanyUsers_GetAllCompanyUsers(): Observable<Model.ICompanyUser[]> {
    let path: string = this.baseUrl + '/api/companyusers/all';
    return this.httpGet(path);
  }

  /**
   * Gets a list of all active users in the currently active company (i.e. CompanyUsers), exposing only a few key
   * properties.
   */
  public CompanyUsers_GetCompanyUsersSimple(): Observable<Model.ISimpleCompanyUser[]> {
    let path: string = this.baseUrl + '/api/companyusers/simple';
    return this.httpGet(path);
  }

  /**
   * Gets a simplified list of all users (including inactive ones) in the currently active company (i.e.
   * CompanyUsers), exposing only a few key properties.
   */
  public CompanyUsers_GetAllCompanyUsersSimple(): Observable<Model.ISimpleCompanyUser[]> {
    let path: string = this.baseUrl + '/api/companyusers/simple/all';
    return this.httpGet(path);
  }

  /**
   * Updates existing company users, including submitted Address and UserEmployment child objects (but not child
   * objects of UserEmployments).
   *
   * Notice that other child objects of company users cannot be added and removed this way, only modified. Provide
   * null values (not empty lists) to leave child objects unmodified.
   *
   * Note that the convenience fields UserLanguageId and UserIdentityNumber, which really belong to the User object in
   * the hiarchy, may be updated this way.
   *
   * Finally, notice that users with the Employee role can only update their own CompanyUsers, and NOT the attached
   * UserEmployment objects.
   * @param A list of company users to update.
   */
  public CompanyUsers_UpdateCompanyUsers(
    companyUsers: Model.CompanyUser[]
  ): Observable<Model.IBatchOperationResponse_CompanyUser_CompanyUser> {
    let path: string = this.baseUrl + '/api/companyusers/batch';
    return this.httpPut(path, companyUsers);
  }

  /**
   * Adds users to the currently active company, optionally specifying either a set of employment data or a template
   * to use for the employment. The users are created in the Gratisal database if they do not already exist, or mapped
   * to the company if they do.
   * @param A list of objects containing information about the users to create. Note: To generate a dummy CPR number,
   *        specify "DDMMYYXXXX" where DDMMYY is the user's birth date and XXXX are literal values.
   */
  public CompanyUsers_AddUsersToCompany(
    requests: Model.AddUserToCompanyRequest[]
  ): Observable<Model.IBatchOperationResponse_UserCreationData_AddUserToCompanyRequest> {
    let path: string = this.baseUrl + '/api/companyusers/batch';
    return this.httpPost(path, requests);
  }

  /**
   * Retrieves detailed information about the specified company user.
   * @param The Id of the company user to retrieve information about.
   */
  public CompanyUsers_GetCompanyUserById(companyUserId: number): Observable<Model.ICompanyUser> {
    let path: string = this.baseUrl + '/api/companyusers/{companyUserId}';
    path = path.replace('{companyUserId}', companyUserId.toString());
    return this.httpGet(path);
  }

  /**
   * Deletes the specified company user. Only allowed if the user has no finalized payslips or other data that must be
   * preserved.
   * @param The Id of the company user to delete.
   */
  public CompanyUsers_DeleteCompanyUser(companyUserId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/companyusers/{companyUserId}';
    path = path.replace('{companyUserId}', companyUserId.toString());
    return this.httpDelete(path);
  }

  /**
   * Resends welcome e-mail to specified company user.
   * @param The Id of the employment to resend the e-mail to.
   */
  public CompanyUsers_ResendUserEmploymentEmail(companyUserId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/companyusers/{companyUserId}/resendemail';
    path = path.replace('{companyUserId}', companyUserId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Resets the password for the specified user.
   *
   * <param name="companyUserId">The Id of the company user to reset the password of.</param>
   */
  public CompanyUsers_ResetUserEmploymentPassword(companyUserId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/companyusers/{companyUserId}/resetpw';
    path = path.replace('{companyUserId}', companyUserId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Gets a list of all employments in the company, along with many additional properties taken from the CompanyUser
   * and User objects.
   */
  public Employments_GetEmploymentViews(): Observable<Model.IUserEmploymentView[]> {
    let path: string = this.baseUrl + '/api/employments/overview';
    return this.httpGet(path);
  }

  /**
   * Gets a list of all employments in the company for the current CompanyUser, along with many additional properties
   * taken from the CompanyUser and User objects.
   */
  public Employments_GetCurrentEmploymentViews(): Observable<Model.IUserEmploymentView[]> {
    let path: string = this.baseUrl + '/api/employments/current';
    return this.httpGet(path);
  }

  /**
   * Gets a list of all employments on the specified CompanyUser.
   */
  public Employments_GetEmploymentsByCompanyUser(companyUserId: number): Observable<Model.IUserEmployment[]> {
    let path: string = this.baseUrl + '/api/employments/companyuser/{companyUserId}';
    path = path.replace('{companyUserId}', companyUserId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves detailed information about the specified employment.
   * @param The Id of the employment to retrieve information about.
   */
  public Employments_GetEmployment(userEmploymentId: number): Observable<Model.IUserEmployment> {
    let path: string = this.baseUrl + '/api/employments/{userEmploymentId}';
    path = path.replace('{userEmploymentId}', userEmploymentId.toString());
    return this.httpGet(path);
  }

  /**
   * Updates an existing employment.
   * @param The employment to update.
   */
  public Employments_UpdateEmployment(employment: Model.UserEmployment): Observable<Model.IUserEmployment> {
    let path: string = this.baseUrl + '/api/employments';
    return this.httpPut(path, employment);
  }

  /**
   * Creates a new employment for the specified company user.
   * @param Details about the employment. Title and CompanyUserId are always mandatory. You may fill out all other
   *        fields except BasedOnTemplateId, or just BasedOnTemplateId. If the latter field has a value, most other
   *        fields are taken from the template, ignoring the input values provided.
   */
  public Employments_CreateUserEmployment(employment: Model.UserEmployment): Observable<Model.IUserEmployment> {
    let path: string = this.baseUrl + '/api/employments';
    return this.httpPost(path, employment);
  }

  /**
   * Creates new employments for specified company users and updates existing employments.
   * @param List of employment objects.
   *
   *        Employments with id=0 will be created with the specified details. Title and CompanyUserId are mandatory
   *        for them. You may fill out all other fields except BasedOnTemplateId, or just BasedOnTemplateId. If the
   *        latter field has a value, most other fields are taken from the template, ignoring the input values
   *        provided.
   *
   *        Employments with Id &gt; 0 will be updated based on the data provided.
   */
  public Employments_UpsertUserEmployments(
    employments: Model.UserEmployment[]
  ): Observable<Model.IBatchOperationResponse_UserEmployment_UserEmployment> {
    let path: string = this.baseUrl + '/api/employments/batch';
    return this.httpPost(path, employments);
  }

  /**
   * Deletes the specified employment. Only allowed if the employment has no finalized payslips or other data that
   * must be preserved.
   * @param The Id of the employment to delete.
   */
  public Employments_DeleteUserEmployment(employmentId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/employments/{employmentId}';
    path = path.replace('{employmentId}', employmentId.toString());
    return this.httpDelete(path);
  }

  /**
   * Retrieves a list of previously used titles for employments in the currently active company.
   */
  public Employments_GetUsedTitles(): Observable<string[]> {
    let path: string = this.baseUrl + '/api/employments/titles';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of tax cards for the specified employment.
   * @param The Id of the employment to retrieve tax cards for.
   */
  public Employments_GetTaxCards(userEmploymentId: number): Observable<Model.IUserTaxInfo[]> {
    let path: string = this.baseUrl + '/api/employments/{userEmploymentId}/taxcards';
    path = path.replace('{userEmploymentId}', userEmploymentId.toString());
    return this.httpGet(path);
  }

  /**
   * Submits a request for updated tax card information.
   * @param The Id of the employment for which to request a tax card.
   * @param The type of tax card to request (Main / Secondary / Free).
   */
  public Employments_RequestTaxInfo(userEmploymentId: number, taxCardTypeId: number): Observable<string> {
    let path: string = this.baseUrl + '/api/employments/{userEmploymentId}/taxcards/requestnew/type/{taxCardTypeId}';
    path = path.replace('{userEmploymentId}', userEmploymentId.toString());
    path = path.replace('{taxCardTypeId}', taxCardTypeId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Retrieves the currently active tax info for the specified employment.
   * @param The Id of the employment to retrieve tax information about.
   */
  public Employments_GetCurrentTaxInfo(userEmploymentId: number): Observable<Model.IUserTaxInfo> {
    let path: string = this.baseUrl + '/api/employments/{userEmploymentId}/taxcards/current';
    path = path.replace('{userEmploymentId}', userEmploymentId.toString());
    return this.httpGet(path);
  }

  /**
   * Updates a tax information record. Note that for now, only the monthly deduction can be updated. The daily and
   * weekly values will be modified accordingly. This method can only be used for tax cards that were created manually
   * with a POST call, not tax cards retrieved from E-Income.
   * @param The tax information record to update.
   */
  public Employments_UpdateTaxInfo(taxInfo: Model.UserTaxInfo): Observable<Model.IUserTaxInfo> {
    let path: string = this.baseUrl + '/api/employments/taxcards';
    return this.httpPut(path, taxInfo);
  }

  /**
   * Creates a new tax information record (tax card).
   * @param The tax information record to create.
   */
  public Employments_CreateTaxInfo(taxInfo: Model.UserTaxInfo): Observable<Model.IUserTaxInfo> {
    let path: string = this.baseUrl + '/api/employments/taxcards';
    return this.httpPost(path, taxInfo);
  }

  /**
   * Updates an employment to match their currently set template.
   * @param The Id of the employment to update.
   */
  public Employments_UpdateEmploymentToCurrentTemplate(employmentId: number): Observable<Model.IUserEmployment> {
    let path: string = this.baseUrl + '/api/employments/{employmentId}/matchtemplate';
    path = path.replace('{employmentId}', employmentId.toString());
    return this.httpPut(path, undefined);
  }

  /**
   * Retrieves a list of employment templates in the currently active company.
   */
  public EmploymentTemplates_GetEmploymentTemplates(): Observable<Model.IUserEmploymentTemplate[]> {
    let path: string = this.baseUrl + '/api/employmenttemplates';
    return this.httpGet(path);
  }

  /**
   * Updates an existing employment template, including (optionally) TemplateSalaryRecord, OvertimeSupplement and
   * SenioritySupplement records included in the request (if the required module has been activated). In the latter
   * case, all records of the relevant type are replaced with the data contained in the request. Submit null to leave
   * that data unchanged.
   * @param The template to update.
   */
  public EmploymentTemplates_UpdateEmploymentTemplate(
    template: Model.UserEmploymentTemplate
  ): Observable<Model.IUserEmploymentTemplate> {
    let path: string = this.baseUrl + '/api/employmenttemplates';
    return this.httpPut(path, template);
  }

  /**
   * Creates a new employment template.
   * @param The template to create.
   */
  public EmploymentTemplates_CreateEmploymentTemplate(
    template: Model.UserEmploymentTemplate
  ): Observable<Model.IUserEmploymentTemplate> {
    let path: string = this.baseUrl + '/api/employmenttemplates';
    return this.httpPost(path, template);
  }

  /**
   * Retrieves information about the specified employment template.
   * @param The ID of the template to retrieve.
   */
  public EmploymentTemplates_GetEmploymentTemplate(templateId: number): Observable<Model.IUserEmploymentTemplate> {
    let path: string = this.baseUrl + '/api/employmenttemplates/{templateId}';
    path = path.replace('{templateId}', templateId.toString());
    return this.httpGet(path);
  }

  /**
   * Deletes the specified employment template.
   * @param The ID of the template to delete.
   */
  public EmploymentTemplates_DeleteEmploymentTemplate(templateId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/employmenttemplates/{templateId}';
    path = path.replace('{templateId}', templateId.toString());
    return this.httpDelete(path);
  }

  /**
   * Updates an existing employment template, and applies the changed values (only) to existing employees based on
   * that template. This includes TemplateSalaryRecords if these are included in the request (pass null to retain the
   * existing data unchanged, or an empty list to delete all records).
   * @param The template to update.
   */
  public EmploymentTemplates_UpdateEmploymentTemplateAndApplyChanges(
    template: Model.UserEmploymentTemplate
  ): Observable<Model.IUserEmploymentTemplate> {
    let path: string = this.baseUrl + '/api/employmenttemplates/withapply';
    return this.httpPut(path, template);
  }

  /**
   * Creates an employment template from an existing employment.
   * @param An object specifing the name of the new template, and the Id of the UserEmployment entity on which to base
   *        the template. Note: Do not provide the Id of a CompanyUser or User entity.
   */
  public EmploymentTemplates_CreateTemplateFromEmployment(
    request: Model.TemplateCreationRequest
  ): Observable<Model.IUserEmploymentTemplate> {
    let path: string = this.baseUrl + '/api/employmenttemplates/fromemployment';
    return this.httpPost(path, request);
  }

  /**
   * Duplicates existing employment template. Including sub-records like TemplateSalaryRecords, OvertimeSupplement,
   * SenioritySupplements
   * @param The ID of the template to duplicate.
   */
  public EmploymentTemplates_DuplicateEmploymentTemplate(
    templateId: number
  ): Observable<Model.IUserEmploymentTemplate> {
    let path: string = this.baseUrl + '/api/employmenttemplates/{templateId}/duplicate';
    path = path.replace('{templateId}', templateId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Gets a list of employments that are based on the specified template.
   */
  public EmploymentTemplates_GetCompanyUsersByEmploymentTemplate(templateId: number): Observable<any[]> {
    let path: string = this.baseUrl + '/api/employmenttemplates/{templateId}/companyusers';
    path = path.replace('{templateId}', templateId.toString());
    return this.httpGet(path);
  }

  /**
   * Gets the salary template records with the passed EmploymentTemplate Id.
   * @param The Id to search for.
   */
  public EmploymentTemplates_GetTemplateSalaryRecordByEmploymentTemplate(
    templateId: number
  ): Observable<Model.ITemplateSalaryRecord[]> {
    let path: string = this.baseUrl + '/api/employmenttemplates/{templateId}/salaryrecords';
    path = path.replace('{templateId}', templateId.toString());
    return this.httpGet(path);
  }

  /**
   * Updates an existing salary template record template.
   * @param The record to update.
   */
  public EmploymentTemplates_UpdateTemplateSalaryRecord(
    templateSalaryRecord: Model.TemplateSalaryRecord,
    templateId: string
  ): Observable<Model.ISalaryRecord> {
    let path: string = this.baseUrl + '/api/employmenttemplates/{templateId}/salaryrecords';
    path = path.replace('{templateId}', templateId.toString());
    return this.httpPut(path, templateSalaryRecord);
  }

  /**
   * Creates a new salary template record.
   * @param The record to create.
   */
  public EmploymentTemplates_CreateTemplateSalaryRecord(
    record: Model.TemplateSalaryRecord
  ): Observable<Model.ITemplateSalaryRecord> {
    let path: string = this.baseUrl + '/api/employmenttemplates/salaryrecords';
    return this.httpPost(path, record);
  }

  /**
   * Deletes a template salary record.
   * @param The ID of the record to delete
   */
  public EmploymentTemplates_DeleteTemplateSalaryRecord(recordId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/employmenttemplates/{recordId}/salaryrecords';
    path = path.replace('{recordId}', recordId.toString());
    return this.httpDelete(path);
  }

  /**
   * Gets the salary template records with the passed Id.
   * @param The record to get.
   */
  public EmploymentTemplates_GetTemplateSalaryRecord(recordId: number): Observable<Model.ITemplateSalaryRecord[]> {
    let path: string = this.baseUrl + '/api/employmenttemplates/salaryrecords/{recordId}';
    path = path.replace('{recordId}', recordId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a string of column display name-index pairs in format ColumnName:ColumnIndex;.
   * @param The type of import to scan the file in reference to.
   * @param The row the table starts on.
   */
  public Import_GetExcelHeadersOfFile(operation: string, startrow: number): Observable<string> {
    let path: string = this.baseUrl + '/api/import/columnheaders/operation/{operation}/startrow/{startrow}';
    path = path.replace('{operation}', operation.toString());
    path = path.replace('{startrow}', startrow.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Retrieves a preview of the provided excel file based on the selected mapping.
   * @param The mapping to make a preview based on.
   * @param The number of data rows to copy from the file.
   */
  public Import_CustomPreviewOfFile(mappingId: number, size: number): Observable<string[]> {
    let path: string = this.baseUrl + '/api/import/preview/mappings/{mappingId}/size/{size}';
    path = path.replace('{mappingId}', mappingId.toString());
    path = path.replace('{size}', size.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Retrieves a list of possible column headers for Payroll Data import.
   */
  public Import_GetPayrollDataColumnHeaders(): Observable<Model.IDynamicSalaryRecordImportColumn[]> {
    let path: string = this.baseUrl + '/api/import/columnheaders/operation/payrolldata';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of dimension column headers for Payroll Data import.
   */
  public Import_GetPayrollDataDimensionColumnHeaders(): Observable<Model.IDynamicSalaryRecordImportColumn[]> {
    let path: string = this.baseUrl + '/api/import/columnheaders/operation/payrolldata/dimensions';
    return this.httpGet(path);
  }

  /**
   * Gets the custom dimensions set by the company administrators, if any.
   */
  public Import_GetBasicDimensions(): Observable<string[]> {
    let path: string = this.baseUrl + '/api/import/dimensions';
    return this.httpGet(path);
  }

  /**
   * Gets the custom dimensions set by the company administrators in the format used in column mappings, if any are
   * used in the passed operation.
   */
  public Import_GetEncodedDimensions(operation: string): Observable<any> {
    let path: string = this.baseUrl + '/api/import/columnheaders/operation/{operation}/dimensions';
    path = path.replace('{operation}', operation.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of ImportMappings for the current Company
   */
  public Import_CompanyMappings(): Observable<Model.IImportMapping[]> {
    let path: string = this.baseUrl + '/api/import/mappings';
    return this.httpGet(path);
  }

  /**
   * Updates the passed ImportMapping.
   * @param The ImportMapping to update.
   */
  public Import_UpdateMapping(importMapping: Model.ImportMapping): Observable<Model.IImportMapping> {
    let path: string = this.baseUrl + '/api/import/mappings';
    return this.httpPut(path, importMapping);
  }

  /**
   * Creates a new ImportMapping and save it.
   * @param The mapping to create.
   */
  public Import_SaveMapping(mapping: Model.SimpleImportMapping): Observable<Model.IImportMapping> {
    let path: string = this.baseUrl + '/api/import/mappings';
    return this.httpPost(path, mapping);
  }

  /**
   * Loads the ImportMapping with passed id.
   * @param The id of the ImportMapping to scan the file in reference to.
   */
  public Import_LoadMapping(mappingId: number): Observable<Model.IImportMapping> {
    let path: string = this.baseUrl + '/api/import/mappings/{mappingId}';
    path = path.replace('{mappingId}', mappingId.toString());
    return this.httpGet(path);
  }

  /**
   * Deletes the ImportMapping with the specified ID.
   * @param The Id of the ImportMapping to delete.
   */
  public Import_DeleteMapping(mappingId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/import/mappings/{mappingId}';
    path = path.replace('{mappingId}', mappingId.toString());
    return this.httpDelete(path);
  }

  /**
   * Returns the ImportMappings for the current Company and given operation
   * @param Which import operation the mappings should be for.
   */
  public Import_CompanyOperationMappings(operation: string): Observable<Model.IImportMapping[]> {
    let path: string = this.baseUrl + '/api/import/mappings/operation/{operation}';
    path = path.replace('{operation}', operation.toString());
    return this.httpGet(path);
  }

  /**
   * Runs the specfied import operation with default options and the given file and mapping.
   * @param The id of the ImportMapping to use in the import.
   */
  public Import_CustomImportExcel(mappingId: number): Observable<Model.ISimpleImportResponse> {
    let path: string = this.baseUrl + '/api/import/mappings/{mappingId}/execute';
    path = path.replace('{mappingId}', mappingId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Runs the specfied import operation with the given file, mapping and options.
   * @param The id of the ImportMapping to use in the import.
   * @param The options to use when running this import. Options are in format key=value-key2=value2. Contact Gratisal
   *        for more information.
   */
  public Import_CustomImportExcelOptions(mappingId: number, options: string): Observable<Model.ISimpleImportResponse> {
    let path: string = this.baseUrl + '/api/import/mappings/{mappingId}/execute/options/{options}';
    path = path.replace('{mappingId}', mappingId.toString());
    path = path.replace('{options}', options.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Runs the specfied import operation with the given file, mapping and options.
   * @param An object containing the mapping/mapping id and options.
   */
  public Import_CustomImportExcelWithBody(
    importRequest: Model.SimpleImportRequest
  ): Observable<Model.ISimpleImportResponse> {
    let path: string = this.baseUrl + '/api/import/execute';
    return this.httpPost(path, importRequest);
  }

  /**
   * Retrieves a template file for importing employee data.
   */
  public Import_GetEmployeeImportTemplate(): Observable<any> {
    let path: string = this.baseUrl + '/api/import/employees/template';
    return this.httpGet(path);
  }

  /**
   * Exports a filled employee template with the data of the company's current employees.
   */
  public Import_GetFilledEmploymentTemplate(): Observable<any> {
    let path: string = this.baseUrl + '/api/import/employees/template/filled';
    return this.httpGet(path);
  }

  /**
   * Retrieves a pre-filled template populated with the current balances of the company's active employees.
   */
  public Import_GetFilledBalanceTemplate(perDate: string): Observable<any> {
    let path: string = this.baseUrl + '/api/import/balances/template/filled/perdate/{perDate}';
    path = path.replace('{perDate}', perDate.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a template file for importing payroll data.
   */
  public Import_GetPayrollImportTemplate(idType: string): Observable<any> {
    let path: string = this.baseUrl + '/api/import/salaryrecordsrow/template/id/{idType}';
    path = path.replace('{idType}', idType.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a template file for importing TimeEntry data.
   */
  public Import_TimeEntryImportTemplate(idType: string): Observable<any> {
    let path: string = this.baseUrl + '/api/import/timeentry/template/id/{idType}';
    path = path.replace('{idType}', idType.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves the available idTypes in the current language
   */
  public Import_GetIdTypes(): Observable<any> {
    let path: string = this.baseUrl + '/api/import/idtypes';
    return this.httpGet(path);
  }

  /**
   * Performs import of employees based on submitted data in a filled-out template file, which must be attached to the
   * request.
   */
  public Import_PerformEmployeeImport(): Observable<Model.ISimpleImportResponse> {
    let path: string = this.baseUrl + '/api/import/employees/execute';
    return this.httpPost(path, undefined);
  }

  /**
   * Performs import of employees using the provided options based on submitted data in a filled-out template file,
   * which must be attached to the request.
   * @param The options to apply to this employee import. Contact Gratisal for more information.
   */
  public Import_PerformEmployeeImportOptions(options: string): Observable<Model.ISimpleImportResponse> {
    let path: string = this.baseUrl + '/api/import/employees/execute/options/{options}';
    path = path.replace('{options}', options.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Retrieves a template file for importing initial balances (or corrections of existing balances) on all employees.
   * @param The date per which the import should have affect. Usually the last day of a month. Please provide in the
   *        format yyyyMMdd.
   */
  public Import_GetBalancesImportTemplateDate(perDate: string): Observable<any> {
    let path: string = this.baseUrl + '/api/import/balances/template/perdate/{perDate}';
    path = path.replace('{perDate}', perDate.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a template file for importing initial balances (or corrections of existing balances) on a single
   * employment.
   * @param The date per which the import should have affect. Usually the last day of a month. Please provide in the
   *        format yyyyMMdd.
   * @param The ID of the UserEmployment on whom balances should be imported/corrected.
   */
  public Import_GetBalancesImportTemplateDateEmployment(perDate: string, employmentid: number): Observable<any> {
    let path: string = this.baseUrl + '/api/import/balances/template/perdate/{perDate}/employment/{employmentid}';
    path = path.replace('{perDate}', perDate.toString());
    path = path.replace('{employmentid}', employmentid.toString());
    return this.httpGet(path);
  }

  /**
   * Performs import of initial balances based on submitted data in a filled-out template file, which must be attached
   * to the request.
   */
  public Import_PerformBalancesImport(): Observable<Model.ISimpleImportResponse> {
    let path: string = this.baseUrl + '/api/import/balances';
    return this.httpPost(path, undefined);
  }

  /**
   * Performs import of initial balances based on submitted data in a filled-out template file, which must be attached
   * to the request.
   * @param The options to use when performing this import. Contact Gratisal for more information.
   */
  public Import_PerformBalancesImportOptions(options: string): Observable<Model.ISimpleImportResponse> {
    let path: string = this.baseUrl + '/api/import/balances/{options}';
    path = path.replace('{options}', options.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Performs import of payroll data (SalaryRecords) based on submitted data in an Excel file, which must be attached
   * to the request and adhere to a specific format described in our support guidelines. Records will be updated or
   * inserted as relevant.
   */
  public Import_PerformSalaryRecordsImport(): Observable<Model.ISimpleImportResponse> {
    let path: string = this.baseUrl + '/api/import/salaryrecords';
    return this.httpPost(path, undefined);
  }

  /**
   * Performs import of payroll data (SalaryRecords) based on submitted data in an Excel file, which must be attached
   * to the request and adhere to a specific format described in our support guidelines. New records will always be
   * inserted, and existing data left alone.
   */
  public Import_PerformSalaryRecordsImportOptions(options: string): Observable<Model.ISimpleImportResponse> {
    let path: string = this.baseUrl + '/api/import/salaryrecords/{options}';
    path = path.replace('{options}', options.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Processes danløn pdfs and returns the contents as a string array
   * @param What sort of import operation to perform.
   */
  public Import_DanloenPdf(operation: string): Observable<string[]> {
    let path: string = this.baseUrl + '/api/import/danloenpdf/{operation}';
    path = path.replace('{operation}', operation.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Process danløn pdfsand returns the contents as an excel file
   * @param What sort of import operation to perform.
   */
  public Import_DanloenPdfToExcel(operation: string): Observable<number[]> {
    let path: string = this.baseUrl + '/api/import/danloenpdf/{operation}/excel';
    path = path.replace('{operation}', operation.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Imports the passed string array as the contents of a DanlønPdf.
   * @param What sort of import operation to perform.
   * @param A wrapper containing a 2-d string array named Data, containing the import data.
   */
  public Import_DanloenPdfImport(
    operation: string,
    wrapper: Model.StringDataWrapper
  ): Observable<Model.ISimpleImportResponse> {
    let path: string = this.baseUrl + '/api/import/danloenpdf/{operation}/execute';
    path = path.replace('{operation}', operation.toString());
    return this.httpPost(path, wrapper);
  }

  /**
   * Imports the passed string array as the contents of a DanlønPdf using the given options.
   * @param What sort of import operation to perform.
   * @param The options to perform the import with. Contact Gratisal for more information.
   * @param A wrapper containing a 2-d string array named Data, containing the import data.
   */
  public Import_DanloenPdfImportOptions(
    operation: string,
    options: string,
    wrapper: Model.StringDataWrapper
  ): Observable<Model.ISimpleImportResponse> {
    let path: string = this.baseUrl + '/api/import/danloenpdf/{operation}/execute/options/{options}';
    path = path.replace('{operation}', operation.toString());
    path = path.replace('{options}', options.toString());
    return this.httpPost(path, wrapper);
  }

  /**
   * Converts an EPWin dat files into an excel file importable with the standard template
   * @param The type of data to import. Currently only supports 'SalaryRecords' and 'TimeEntry'.
   */
  public Import_EpWinToExcel(importType: string): Observable<number[]> {
    let path: string = this.baseUrl + '/api/import/convert/epwin/excel/{importType}';
    path = path.replace('{importType}', importType.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Converts a PlanDay csv file into an excel file importable with the standard template
   * @param The type of data to import. Currently only supports 'ImportEmployees'.
   */
  public Import_PlanDayToExcel(importType: string): Observable<number[]> {
    let path: string = this.baseUrl + '/api/import/convert/planday/excel/{importType}';
    path = path.replace('{importType}', importType.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Retrieves all Integrations for the current Company.
   */
  public Integrations_RetrieveIntegrations(): Observable<Model.IIntegration[]> {
    let path: string = this.baseUrl + '/api/integrations';
    return this.httpGet(path);
  }

  /**
   * Retrieves Integrations with the specified operation active for the current Company.
   */
  public Integrations_GetIntegrationsByOperation(operationId: number): Observable<Model.IIntegration[]> {
    let path: string = this.baseUrl + '/api/integrations/operation/{operationId}';
    path = path.replace('{operationId}', operationId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves all Integrations for the current Company, including their child objects.
   */
  public Integrations_RetrieveIntegrationsAll(): Observable<Model.IIntegration[]> {
    let path: string = this.baseUrl + '/api/integrations/all';
    return this.httpGet(path);
  }

  /**
   * Gets one Integration in the current Company with all child objects.
   * @param The Id of the Integration to retrieve.
   */
  public Integrations_RetrieveIntegrationWithAllChildren(integrationId: number): Observable<Model.IIntegration> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}';
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpGet(path);
  }

  /**
   * Sets values on the Integration's child objects corresponding to the passed ExternalSystemParameter keys.
   * @param Which integration to set the value in.
   * @param A collection of SimpleKeyValuePair with the following properties: Key should be set to the
   *        ExternalSystemParameter's key, Value will be parsed to the appropriate type.
   */
  public Integrations_SetIntegrationValuesByKey(
    integrationId: number,
    parameters: Model.SimpleKeyValuePair[]
  ): Observable<void> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}';
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpPost(path, parameters);
  }

  /**
   * Delete an Integration.
   * @param The Id of the integration to delete.
   */
  public Integrations_DeleteIntegration(integrationId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}';
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpDelete(path);
  }

  /**
   * Create a new Integration based on the referenced ExternalSystem.
   * @param The Id of the ExternalSystem to create a new Integration for.
   */
  public Integrations_CreateIntegration(externalSystemId: number): Observable<Model.IIntegration> {
    let path: string = this.baseUrl + '/api/integrations/externalsystem/{externalSystemId}';
    path = path.replace('{externalSystemId}', externalSystemId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Sets IsActive of a single IntegrationOperation.
   * @param Which integration to set the value in.
   * @param Id of the operation to change.
   * @param Boolean to set the operation to.
   */
  public Integrations_SetSpecificSingleIntegrationOperationValueByKey(
    integrationId: number,
    operationId: number,
    value: boolean
  ): Observable<void> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}/operation/{operationId}/single';
    path = path.replace('{integrationId}', integrationId.toString());
    path = path.replace('{operationId}', operationId.toString());
    path += '?value=' + value;
    return this.httpPost(path, undefined);
  }

  /**
   * Sets IsActive of a single IntegrationOperation.
   * @param Which integration to set the value in.
   * @param Key should be set to the Key of the corresponding ExternalSystem object, Value will be parsed to the
   *        appropriate type.
   */
  public Integrations_SetSingleIntegrationOperationValueByKey(
    integrationId: number,
    keyValuePair: Model.SimpleKeyValuePair
  ): Observable<void> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}/operation/single';
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpPost(path, keyValuePair);
  }

  /**
   * Sets IsActive of IntegrationOperations.
   * @param Which integration to set the value in.
   * @param Keys should be set to the Key of the corresponding ExternalSystem object, Value will be parsed to the
   *        appropriate type.
   */
  public Integrations_SetIntegrationOperationValuesByKey(
    integrationId: number,
    keyValuePairList: Model.SimpleKeyValuePair[]
  ): Observable<void> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}/operation';
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpPost(path, keyValuePairList);
  }

  /**
   * Sets the Value of a single IntegrationConfiguration.
   * @param Which integration to set the value in.
   * @param Key should be set to the Key of the corresponding ExternalSystem object, Value will be parsed to the
   *        appropriate type.
   */
  public Integrations_SetIntegrationConfigurationValueByKey(
    integrationId: number,
    keyValuePair: Model.SimpleKeyValuePair
  ): Observable<void> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}/configuration/single';
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpPost(path, keyValuePair);
  }

  /**
   * Sets Value of IntegrationConfigurations.
   * @param Which integration to set the value in.
   * @param Keys should be set to the Key of the corresponding ExternalSystem object, Value will be parsed to the
   *        appropriate type.
   */
  public Integrations_SetIntegrationConfigurationValuesByKey(
    integrationId: number,
    keyValuePairList: Model.SimpleKeyValuePair[]
  ): Observable<void> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}/configuration';
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpPost(path, keyValuePairList);
  }

  /**
   * Sets the value of a single IntegrationCredential.
   * @param Which integration to set the value in.
   * @param Key should be set to the Key of the corresponding ExternalSystem object, Value will be parsed to the
   *        appropriate type.
   */
  public Integrations_SetIntegrationCredentialValueByKey(
    integrationId: number,
    keyValuePair: Model.SimpleKeyValuePair
  ): Observable<void> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}/credential/single';
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpPost(path, keyValuePair);
  }

  /**
   * Sets IsActive of IntegrationOperations.
   * @param Which integration to set the value in.
   * @param Keys should be set to the Key of the corresponding ExternalSystem object, Value will be parsed to the
   *        appropriate type.
   */
  public Integrations_SetIntegrationCredentialValuesByKey(
    integrationId: number,
    keyValuePairList: Model.SimpleKeyValuePair[]
  ): Observable<void> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}/credential';
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpPost(path, keyValuePairList);
  }

  /**
   * Set a single value on the Integration child object corresponding to the passed key.
   * @param Which integration to set the value in.
   * @param Key should be set to the Type.Key of the corresponding externalSystem object, Value will be parsed to the
   *        appropriate type.
   */
  public Integrations_SetIntegrationValueByKey(
    integrationId: number,
    keyValuePair: Model.SimpleKeyValuePair
  ): Observable<void> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}/single';
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpPost(path, keyValuePair);
  }

  /**
   * Runs all relevant operations on all implemented integrations
   */
  public Integrations_RunAllIntegrations(): Observable<void> {
    let path: string = this.baseUrl + '/api/integrations/run';
    return this.httpPost(path, undefined);
  }

  /**
   * Runs all relevant operation for a specific integration
   */
  public Integrations_RunIntegration(integrationId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}/run';
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Runs specific operation for a specific integration
   */
  public Integrations_RunIntegrationsOperation(integrationId: number, operationId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}/operation/{operationId}/run';
    path = path.replace('{integrationId}', integrationId.toString());
    path = path.replace('{operationId}', operationId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Gets a list of the specified integration's external accounts.
   */
  public Integrations_GetExternalAccounts(integrationId: number): Observable<Model.IExternalAccount[]> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}/externalaccounts';
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpGet(path);
  }

  /**
   * Gets a list of the current company's external accounts.
   */
  public Integrations_GetAllExternalAccounts(): Observable<Model.IExternalAccount[]> {
    let path: string = this.baseUrl + '/api/integrations/externalaccounts';
    return this.httpGet(path);
  }

  /**
   * Returns the available dimensions for the specified external system.
   * @param The key of the external system for which to retrieve dimensions. (Call api/staticdata/ExternalSystem for
   *        valid values)
   */
  public Integrations_GetExternalSystemDimensions(externalSystemKey: string): Observable<Model.IDimension[]> {
    let path: string = this.baseUrl + '/api/integrations/dimensions/{externalSystemKey}';
    path = path.replace('{externalSystemKey}', externalSystemKey.toString());
    return this.httpGet(path);
  }

  public Integrations_GetJournalBatches(integrationId: number): Observable<Model.INameObject[]> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}/AvailableJournalBatches';
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpGet(path);
  }

  public Integrations_GetJournalTemplates(integrationId: number): Observable<Model.INameObject[]> {
    let path: string = this.baseUrl + '/api/integrations/{integrationId}/AvailableJournalTemplates';
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves the current version of the API.
   */
  public Miscellaneous_GetVersion(): Observable<string> {
    let path: string = this.baseUrl + '/api/version';
    return this.httpGet(path);
  }

  /**
   * Retrieves a boolean indicating whether the API is deployed in a production environment.
   */
  public Miscellaneous_IsProduction(): Observable<boolean> {
    let path: string = this.baseUrl + '/api/isprod';
    return this.httpGet(path);
  }

  /**
   * Retrieves a string indicating the minimum allowed version number for the main Gratisal client. If the client is a
   * lower version number, it should update itself.
   */
  public Miscellaneous_GetMinimumClientVersion(): Observable<string> {
    let path: string = this.baseUrl + '/api/clientminversion';
    return this.httpGet(path);
  }

  /**
   * Submits feedback to Gratisal. Note that the message will usually not invoke a reply.
   */
  public Miscellaneous_SubmitFeedback(feedback: Model.Feedback): Observable<void> {
    let path: string = this.baseUrl + '/api/feedback';
    return this.httpPost(path, feedback);
  }

  /**
   * Reports that an unexpected error occurred in a client application. Do not call this method without previous
   * approval from Gratisal. Please include as much information as possible in the request body.
   * @param An object containing detailed information about the error.
   */
  public Miscellaneous_ReportClientSideError(errorDetails: Model.Feedback): Observable<void> {
    let path: string = this.baseUrl + '/api/clientfail';
    return this.httpPost(path, errorDetails);
  }

  /**
   * Simulates an unexpected error.
   */
  public Miscellaneous_FailHard(): Observable<void> {
    let path: string = this.baseUrl + '/api/fail/hard';
    return this.httpGet(path);
  }

  /**
   * Simulates an expected error, such as invalid user input or similar.
   */
  public Miscellaneous_FailSoft(): Observable<void> {
    let path: string = this.baseUrl + '/api/fail/soft';
    return this.httpGet(path);
  }

  /**
   * Simulates an error due to insufficient access privileges or an expired authentication token.
   */
  public Miscellaneous_FailUnauhorized(): Observable<void> {
    let path: string = this.baseUrl + '/api/fail/unauthorized';
    return this.httpGet(path);
  }

  /**
   * Simulates an unexpected response from the web API, of the kind that you should not normally receive.
   */
  public Miscellaneous_Random(): Observable<void> {
    let path: string = this.baseUrl + '/api/fail/random';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all supported languages.
   */
  public Miscellaneous_GetLanguages(): Observable<Model.ILanguage[]> {
    let path: string = this.baseUrl + '/api/languages';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of possible DISCO codes for job functions when reporting statistics.
   */
  public Miscellaneous_GetStatisticsDiscoCodes(): Observable<Model.IStatisticsDiscoCode[]> {
    let path: string = this.baseUrl + '/api/statistics/discocodes';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of relevant values for dimension no. X (1, 2 or 3). Allowed values may or may not be restricted
   * to this list, based on a company setting (preference).
   */
  public Miscellaneous_GetDimensionValues(dimensionNumber: number): Observable<string[]> {
    let path: string = this.baseUrl + '/api/dimensions/{dimensionNumber}/values';
    path = path.replace('{dimensionNumber}', dimensionNumber.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of salary cycles used by at least one active employee in the currently active company.
   */
  public Miscellaneous_GetUsedSalaryCycles(): Observable<Model.ISalaryCycle[]> {
    let path: string = this.baseUrl + '/api/salarycycles/used';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all available modules including information about package and billing principle. This method
   * is meant to be called anonymously. It currently only supports Danish pricing. Note that you should simply call
   * api/modules if you are authenticated and want to see prices for your company.
   */
  public Modules_GetModuleViewsTranslated(languageCode: string): Observable<Model.IModuleCompanyView[]> {
    let path: string = this.baseUrl + '/api/modules/lang/{languageCode}';
    path = path.replace('{languageCode}', languageCode.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of modules with information about whether each is enabled for the current company.
   */
  public Modules_GetModulesForCurrentCompany(): Observable<Model.IModuleCompanyView[]> {
    let path: string = this.baseUrl + '/api/modules';
    return this.httpGet(path);
  }

  /**
   * Enables the list of modules (and optionally a package) for the active company. Disables all others. Note this has
   * billing consequences.
   * @param The package to activate, if any. See api/staticdata/ModulePackage for possible values. Specify 0 for no
   *        package. Modules that are included in the package do not need to be specified explicitly in the request
   *        body.
   * @param A list of ID's of the modules to enable. Modules not contained in either this list or the specified
   *        package will be disabled. Modules that have InfoOnly = true will be ignored.
   */
  public Modules_SetEnabledModules(packageLevel: number, modulesToActivate: number[]): Observable<void> {
    let path: string = this.baseUrl + '/api/modules/package/{packageLevel}';
    path = path.replace('{packageLevel}', packageLevel.toString());
    return this.httpPut(path, modulesToActivate);
  }

  /**
   * Enables the selected module for the currently active account. Note that this has billing consequences.
   */
  public Modules_EnableModule(moduleId: number): Observable<string> {
    let path: string = this.baseUrl + '/api/modules/{moduleId}';
    path = path.replace('{moduleId}', moduleId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Schedules the selected module deactivation in the currently active company. The notice period is 3 months to the
   * end of a month. Note that this has consequences for billing and available functionality.
   */
  public Modules_DisableModule(moduleId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/modules/{moduleId}';
    path = path.replace('{moduleId}', moduleId.toString());
    return this.httpDelete(path);
  }

  /**
   * Gets a list of the current company's preferences.
   */
  public Preferences_GetCompanyPreferences(): Observable<Model.ISimpleKeyValuePair[]> {
    let path: string = this.baseUrl + '/api/preferences/company';
    return this.httpGet(path);
  }

  /**
   * Sets one of the current company's preferences to the desired value. Note that this can have billing consequences
   * as any required modules will be automatically activated.
   */
  public Preferences_SetCompanyPreference(preference: Model.SimpleKeyValuePair): Observable<Model.ISimpleKeyValuePair> {
    let path: string = this.baseUrl + '/api/preferences/company';
    return this.httpPut(path, preference);
  }

  /**
   * Gets the preferences of the current user.
   */
  public Preferences_GetUserPreferences(): Observable<Model.ISimpleKeyValuePair[]> {
    let path: string = this.baseUrl + '/api/preferences/user';
    return this.httpGet(path);
  }

  /**
   * Updates a single preference of the current user.
   */
  public Preferences_SetUserPreference(preference: Model.SimpleKeyValuePair): Observable<Model.ISimpleKeyValuePair> {
    let path: string = this.baseUrl + '/api/preferences/user';
    return this.httpPut(path, preference);
  }

  /**
   * Retrieves a list of all reports available in the system.
   */
  public Reports_GetAllReports(): Observable<Model.IReport[]> {
    let path: string = this.baseUrl + '/api/reports';
    return this.httpGet(path);
  }

  /**
   * Retrieves a report based on the submitted data.
   */
  public Reports_GetReport(request: Model.ReportRequest): Observable<any> {
    let path: string = this.baseUrl + '/api/reports';
    return this.httpPost(path, request);
  }

  /**
   * Retrieves a list of reports relevant to the specified area. See api/staticdata/ReportArea for possible values.
   * @param The Id of the ReportArea to get reports for.
   */
  public Reports_GetReportsByArea(areaId: number): Observable<Model.IReport[]> {
    let path: string = this.baseUrl + '/api/reports/area/{areaId}';
    path = path.replace('{areaId}', areaId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a report based on the submitted data. This is a convenience method that simply sets OutputFormat of the
   * request body to the value specified in the route.
   * @param The desired output format for the report. Suppported values are "html", "csv", "xlsx" and "pdf", although
   *        not all formats are supported by all reports.
   * @param Spefication for information the report requires.
   */
  public Reports_GetReportWithOutput(outputFormat: string, request: Model.ReportRequest): Observable<any> {
    let path: string = this.baseUrl + '/api/reports/{outputFormat}';
    path = path.replace('{outputFormat}', outputFormat.toString());
    return this.httpPost(path, request);
  }

  /**
   * Retrieves a list of all payroll batches in the currently active company. Note that this method has the
   * side-effect of recalculating all open draft batches with pending changes, so response time can sometimes be
   * longer than usual.
   */
  public SalaryBatches_GetSalaryBatches(): Observable<Model.ISalaryBatchView[]> {
    let path: string = this.baseUrl + '/api/salarybatches';
    return this.httpGet(path);
  }

  /**
   * Updates an existing payroll batch. Only the Message, PayoutDate and PayoutAllFlex properties can be modified.
   * NOTE: This re-runs validation logic on the batch but does not recalculate it.
   * @param The payroll batch to update.
   */
  public SalaryBatches_UpdateSalaryBatch(batch: Model.SalaryBatch): Observable<Model.ISalaryBatch> {
    let path: string = this.baseUrl + '/api/salarybatches';
    return this.httpPut(path, batch);
  }

  /**
   * Creates and calculates a new payroll batch for the specified salary period, optionally as a preview-only
   * operation.
   * @param An object containing details about the request. Note that to specify an
   */
  public SalaryBatches_CreateSalaryBatch(request: Model.SalaryBatchRequest): Observable<Model.ISalaryBatch> {
    let path: string = this.baseUrl + '/api/salarybatches';
    return this.httpPost(path, request);
  }

  /**
   * Retrieves a list of all non-finalized payroll batches in the currently active company.
   */
  public SalaryBatches_GetOpenSalaryBatches(): Observable<Model.ISalaryBatchView[]> {
    let path: string = this.baseUrl + '/api/salarybatches/open';
    return this.httpGet(path);
  }

  /**
   * Retrieves details about a single payroll batch, including all its records and salary statements.
   */
  public SalaryBatches_GetSalaryBatch(batchId: number): Observable<Model.ISalaryBatch> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpGet(path);
  }

  /**
   * Deletes a payroll batch.
   * @param The ID of the batch to delete.
   */
  public SalaryBatches_DeleteSalaryBatch(batchId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpDelete(path);
  }

  /**
   * Retrieves a list of E-Income response lines for the specified payroll batch. Note that this request is only
   * relevant for finalized payroll batches.
   */
  public SalaryBatches_GetEIncomeResponseLines(batchId: number): Observable<Model.IEIncomeResponseLine[]> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/eincome/response';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of validation warnings and errors for the specified payroll batch. Note that this request is
   * only relevant for non-finalized payroll batches.
   */
  public SalaryBatches_GetValidationIssues(batchId: number): Observable<Model.ISalaryBatchValidationIssue[]> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/validation';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a sum of amounts by salary type for the specified payroll batch.
   */
  public SalaryBatches_GetSalaryBatchTotalsBySalaryType(
    batchId: number
  ): Observable<Model.ISalaryBatchTotalsApiView[]> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/totals/salarytypes';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a sum of amounts by employee for the specified salary type within the specified payroll batch.
   */
  public SalaryBatches_GetSalaryTypeTotalsByEmployee(
    batchId: number,
    salaryTypeId: number
  ): Observable<Model.ISalaryTypeSumsByEmploymentView[]> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/totals/salarytype/{salaryTypeId}';
    path = path.replace('{batchId}', batchId.toString());
    path = path.replace('{salaryTypeId}', salaryTypeId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a sum of relevant units (time entries, vacation days etc.) for the specified payroll batch.
   */
  public SalaryBatches_GetSalaryBatchUnitTotals(batchId: number): Observable<Model.ISalaryBatchUnitTotal[]> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/totals/units';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a sum of relevant unit totals (time entries, vacation days etc.) by employee for the specified payroll
   * batch and category.
   * @param The ID of the salary batch to retrieve the data for.
   * @param A string designating the type of balance you want to see. Call api/salarybatches/{batchId}/totals/units
   *        for a list of possible values.
   */
  public SalaryBatches_GetUnitTotalsByEmployee(
    batchId: number,
    key: string
  ): Observable<Model.ISalaryBatchEmployeeTotal[]> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/totals/units/key/{key}';
    path = path.replace('{batchId}', batchId.toString());
    path = path.replace('{key}', key.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of salary periods in the specified salary cycle.
   */
  public SalaryBatches_GetSalaryPeriods(cycleId: number): Observable<Model.ISalaryPeriod[]> {
    let path: string = this.baseUrl + '/api/salarybatches/periods/cycle/{cycleId}';
    path = path.replace('{cycleId}', cycleId.toString());
    return this.httpGet(path);
  }

  /**
   * Suggests the next relevant salary period in the specified salary cycle. Defined as the first period after the
   * company's newest finalized payroll batch.
   */
  public SalaryBatches_SuggestSalaryPeriod(cycleId: number): Observable<Model.ISalaryPeriod> {
    let path: string = this.baseUrl + '/api/salarybatches/periods/cycle/{cycleId}/suggest';
    path = path.replace('{cycleId}', cycleId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves information about whether or not the specified salary period already has one or more payroll batches.
   */
  public SalaryBatches_SalaryPeriodHasBatches(periodId: number): Observable<boolean> {
    let path: string = this.baseUrl + '/api/salarybatches/periods/{periodId}/hasbatch';
    path = path.replace('{periodId}', periodId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of potentially relevant salary periods
   */
  public SalaryBatches_GetRelevantPeriods(): Observable<Model.ISalaryPeriod[]> {
    let path: string = this.baseUrl + '/api/salarybatches/periods/relevant';
    return this.httpGet(path);
  }

  /**
   * Fully recalculates a payroll batch, deleting all existing batch records and re-fetching employee salary records.
   * @param The ID of the payroll batch to recalculate.
   */
  public SalaryBatches_RecalculateSalaryBatch(batchId: number): Observable<Model.ISalaryBatchRecord[]> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/recalc';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpPut(path, undefined);
  }

  /**
   * Recalculates a single employment within an existing payroll batch. This is possible even for batches that are
   * awaiting approval (NOT finalized ones). Use this when you know that only one employee's data has been changed and
   * do not wish to recalculate the entire batch.
   * @param The ID of the payroll batch to recalculate.
   * @param The UserEmploymentId of the employee to recalculate.
   */
  public SalaryBatches_RecalculateSalaryBatchSingleEmployment(
    batchId: number,
    userEmploymentId: number
  ): Observable<Model.ISalaryBatchRecord[]> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/recalc/employment/{userEmploymentId}';
    path = path.replace('{batchId}', batchId.toString());
    path = path.replace('{userEmploymentId}', userEmploymentId.toString());
    return this.httpPut(path, undefined);
  }

  /**
   * Recalculates all open draft payroll batches in the currently active company. NOTE: This method only recalculates
   * employees that have been updated since the last recalculation.
   */
  public SalaryBatches_RecalculateDraftSalaryBatches(): Observable<void> {
    let path: string = this.baseUrl + '/api/salarybatches/recalcdrafts';
    return this.httpPut(path, undefined);
  }

  /**
   * Approves a payroll batch, locking it for editing. This prevents re-calculation but does not finalize the batch.
   * Employees are notified and given access to preliminary payslips.
   * @param The ID of the batch to approve.
   */
  public SalaryBatches_ApproveSalaryBatchWithPreliminaryPayslips(batchId: number): Observable<Model.ISalaryBatch> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/approve/withpreliminarypayslips';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Approves a payroll batch, locking it for editing. This prevents re-calculation but does not finalize the batch.
   * (Another way to think of it is submitting the batch for final approval). Employees are NOT notified nor given
   * access to preliminary paylips - to enable this, call the /withpreliminarypayslips route instead.
   * @param The ID of the batch to approve.
   */
  public SalaryBatches_ApproveSalaryBatch(batchId: number): Observable<Model.ISalaryBatch> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/approve';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Reverts all approval/locking of the payroll batch, resetting it to draft status and allowing it to be
   * recalculated.
   * @param The ID of the batch to approve.
   */
  public SalaryBatches_ResetBatchToDraft(batchId: number): Observable<Model.ISalaryBatch> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/reset';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Finalizes a payroll batch.
   * @param The ID of the batch to approve.
   * @param An object containing the user's password, which must be provided as an extra security measure. Also a
   *        boolean specifying whether to recalculate the batch before finalizing.
   */
  public SalaryBatches_FinalizeSalaryBatch(
    batchId: number,
    request: Model.SalaryBatchApprovalRequest
  ): Observable<Model.ISalaryBatchApprovalResponse> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/finalize';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpPost(path, request);
  }

  /**
   * Reverts a finalized payroll batch, setting its status to Cancelled.
   * @param The ID of the batch to revert.
   * @param An object containing the user's password, which must be provided as an extra security measure.
   */
  public SalaryBatches_RevertSalaryBatch(batchId: number, request: Model.SalaryBatchApprovalRequest): Observable<void> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/revert';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpPost(path, request);
  }

  /**
   * Rejects approval of a payroll batch, optionally specifying a reason. This moves the batch back to draft status.
   * @param The ID of the batch to reject.
   * @param The request to reject including reason.
   */
  public SalaryBatches_RejectSalaryBatch(
    batchId: number,
    request: Model.SalaryBatchRejectionRequest
  ): Observable<Model.ISalaryBatch> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/reject';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpPost(path, request);
  }

  /**
   * Resends a payroll batch to E-Income. Dispatch and response are usually processed within 5-10 minutes. This will
   * also clear the error status of the batch.
   */
  public SalaryBatches_ResendEIncome(batchId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/eincome/resend';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpPost(path, undefined);
  }

  public SalaryBatches_ResendToDefaultIntegration(batchId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/integration/resend';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Re-sends a payroll batch to the configured external system.
   */
  public SalaryBatches_ResendToIntegration(batchId: number, integrationId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/integration/{integrationId}/resend';
    path = path.replace('{batchId}', batchId.toString());
    path = path.replace('{integrationId}', integrationId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Clears E-Income error and warning status for the specified payroll batch. Use this when errors have been resolved
   * manually. The actual error data will not be deleted.
   */
  public SalaryBatches_ClearEIncomeErrors(batchId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/eincome/errors';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpDelete(path);
  }

  /**
   * Retrieves a list of accounting records for the specified payroll batch, to be imported into the general ledger of
   * an external ERP system. This method returns the totals with no grouping.
   */
  public SalaryBatches_GetExternalAccountingRecords(batchId: number): Observable<Model.IExternalAccountingRecord[]> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/accounting';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of accounting records for the specified payroll batch, to be imported into the general ledger of
   * an external ERP system. This method returns the totals grouped by department.
   */
  public SalaryBatches_GetExternalAccountingRecordsByDepartment(
    batchId: number
  ): Observable<Model.IExternalAccountingRecord[]> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/accounting/department';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of accounting records for the specified payroll batch, to be imported into the general ledger of
   * an external ERP system. This method returns the totals grouped by employee - notice that this may expose
   * sensitive data.
   */
  public SalaryBatches_GetExternalAccountingRecordsByEmployee(
    batchId: number
  ): Observable<Model.IExternalAccountingRecord[]> {
    let path: string = this.baseUrl + '/api/salarybatches/{batchId}/accounting/employee';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of salary records for the specified employment.
   * @param The ID of the employment for which to retrieve salary records. Note: Do not pass a UserId or
   *        CompanyUserId.
   */
  public SalaryRecords_GetSalaryRecordsByEmployment(userEmploymentId: number): Observable<Model.ISalaryRecordView[]> {
    let path: string = this.baseUrl + '/api/salaryrecords/employment/{userEmploymentId}';
    path = path.replace('{userEmploymentId}', userEmploymentId.toString());
    return this.httpGet(path);
  }

  /**
   * Creates and/or updates an array of SalaryRecords (payroll data). SalaryRecords passed with an Id of 0 (or
   * omitted) will be added as new records, while records with Id &gt; 0 will be updated.
   * @param A list of salary records to create and/or update. The list can span multiple employees but only within the
   *        currently active company.
   */
  public SalaryRecords_UpsertSalaryRecords(
    salaryRecords: Model.SalaryRecord[]
  ): Observable<Model.IBatchOperationResponse_SalaryRecord_SalaryRecord> {
    let path: string = this.baseUrl + '/api/salaryrecords/batch';
    return this.httpPost(path, salaryRecords);
  }

  /**
   * Updates an existing salary record.
   * @param The record to update.
   */
  public SalaryRecords_UpdateSalaryRecord(record: Model.SalaryRecord): Observable<Model.ISalaryRecord> {
    let path: string = this.baseUrl + '/api/salaryrecords';
    return this.httpPut(path, record);
  }

  /**
   * Creates a new salary record.
   * @param The record to create.
   */
  public SalaryRecords_CreateSalaryRecord(record: Model.SalaryRecord): Observable<Model.ISalaryRecord> {
    let path: string = this.baseUrl + '/api/salaryrecords';
    return this.httpPost(path, record);
  }

  /**
   * Updates an existing salary record, specifying only a few essential properties.
   * @param The record to update.
   */
  public SalaryRecords_UpdateSalaryRecordSimple(
    request: Model.SimpleSalaryRecordUpdateRequest
  ): Observable<Model.ISalaryRecord> {
    let path: string = this.baseUrl + '/api/salaryrecords/simple';
    return this.httpPut(path, request);
  }

  /**
   * Creates a new salary record, specifying only a few essential properties.
   * @param The essential details of the record to create.
   */
  public SalaryRecords_CreateSalaryRecordSimple(
    request: Model.SimpleSalaryRecordCreationRequest
  ): Observable<Model.ISalaryRecord> {
    let path: string = this.baseUrl + '/api/salaryrecords/simple';
    return this.httpPost(path, request);
  }

  /**
   * Deletes a salary record if it is not used in any payroll batches. Deactivates it otherwise.
   * @param The ID of the record to delete or deactivate.
   */
  public SalaryRecords_DeleteSalaryRecord(recordId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/salaryrecords/{recordId}';
    path = path.replace('{recordId}', recordId.toString());
    return this.httpDelete(path);
  }

  /**
   * Retrieves a list of employees and selected payroll data, governed by the values of SalaryType.OverviewIndex and
   * "flipped" to provide a total of 7x3 named fields instead of a collection of records. See Company -&gt; Payroll
   * Data in the interface for the intended usage. Use the routes marked "simple" to update or add SalaryRecords based
   * on data served from this view.
   */
  public SalaryRecords_GetSalaryRecordsOverview(): Observable<Model.IGetSalaryRecordsOverview_Result[]> {
    let path: string = this.baseUrl + '/api/salaryrecords/overview';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of employees and selected payroll data, based on the values of SalaryType.OverviewIndex and the
   * filters supplied in the request body. This is a filtered version of api/salaryrecords/overview, see additional
   * comments on that method.
   */
  public SalaryRecords_GetSalaryRecordsOverviewFiltered(
    request: Model.SalaryRecordsOverviewFilterRequest
  ): Observable<Model.IGetSalaryRecordsOverview_Result[]> {
    let path: string = this.baseUrl + '/api/salaryrecords/overview/filtered';
    return this.httpPost(path, request);
  }

  /**
   * Retrieves a list of all employees, each with a collection of salary records.
   */
  public SalaryRecords_GetEmploymentsWithSalaryRecords(): Observable<Model.IUserEmployment[]> {
    let path: string = this.baseUrl + '/api/salaryrecords/byemployee';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all employees, each with a collection of salary records, filtered by the specified
   * SalaryCycleId.
   */
  public SalaryRecords_GetEmploymentsWithSalaryRecordsBySalaryCycle(
    salaryCycleId: number
  ): Observable<Model.IUserEmployment[]> {
    let path: string = this.baseUrl + '/api/salaryrecords/byemployee/cycle/{salaryCycleId}';
    path = path.replace('{salaryCycleId}', salaryCycleId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all salary statements for the current user, across companies.
   */
  public SalaryStatements_GetSalaryStatementsCurrentUser(): Observable<Model.ISalaryStatement[]> {
    let path: string = this.baseUrl + '/api/salarystatements';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all salary statements for the currently logged-in company user.
   */
  public SalaryStatements_GetSalaryStatementsCurrentCompanyUser(): Observable<Model.ISalaryStatement[]> {
    let path: string = this.baseUrl + '/api/salarystatements/companyUser/current';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all salary statements for the specified company user.
   * @param The ID of the CompanyUser for whom to retrieve payslips.
   */
  public SalaryStatements_GetSalaryStatementsByCompanyUser(
    companyUserId: number
  ): Observable<Model.ISalaryStatement[]> {
    let path: string = this.baseUrl + '/api/salarystatements/companyUser/{companyUserId}';
    path = path.replace('{companyUserId}', companyUserId.toString());
    return this.httpGet(path);
  }

  /**
   * /// Retrieves a list of all salary statements for the specified employment.
   */
  public SalaryStatements_GetSalaryStatementsByEmployment(
    userEmploymentId: number
  ): Observable<Model.ISalaryStatement[]> {
    let path: string = this.baseUrl + '/api/salarystatements/employment/{userEmploymentId}';
    path = path.replace('{userEmploymentId}', userEmploymentId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all salary statements for the specified payroll batch.
   * @param The ID of the SalaryBatch for which to retrieve payslips.
   */
  public SalaryStatements_GetSalaryStatementsByBatch(batchId: number): Observable<Model.ISalaryStatement[]> {
    let path: string = this.baseUrl + '/api/salarystatements/salarybatch/{batchId}';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a simplified list of all salary statements for the specified payroll batch.
   * @param The ID of the SalaryBatch for which to retrieve payslips.
   */
  public SalaryStatements_GetSalaryStatementsByBatchSimplified(
    batchId: number
  ): Observable<Model.ISimpleSalaryStatement[]> {
    let path: string = this.baseUrl + '/api/salarystatements/salarybatch/{batchId}/simple';
    path = path.replace('{batchId}', batchId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a merged PDF file of all payslips in the specified payroll batch.
   * @param The ID of the payroll batch for which you want the payslips.
   */
  public SalaryStatements_GetPdfMergedBySalaryBatch(salaryBatchId: number): Observable<any> {
    let path: string = this.baseUrl + '/api/salarystatements/salarybatch/{salaryBatchId}/pdf/merged';
    path = path.replace('{salaryBatchId}', salaryBatchId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a merged PDF file of all payslips in the specified payroll batch.
   * @param The ID of the payroll batch for which you want the payslips.
   */
  public SalaryStatements_GetPdfMergedBySalaryBatchEncoded(salaryBatchId: number): Observable<any> {
    let path: string = this.baseUrl + '/api/salarystatements/salarybatch/{salaryBatchId}/pdf/merged/encoded';
    path = path.replace('{salaryBatchId}', salaryBatchId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a salary statement (payslip) including attached SalaryBatchRecords.
   * @param The ID of the salary statement to return.
   */
  public SalaryStatements_GetSingle(salaryStatementId: number): Observable<Model.ISalaryStatement> {
    let path: string = this.baseUrl + '/api/salarystatements/{salaryStatementId}';
    path = path.replace('{salaryStatementId}', salaryStatementId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a simplified list of the SalaryBatchRecords contained in a specified SalaryStatement.
   * @param The ID of the salary statement to return.
   */
  public SalaryStatements_GetSimplifiedBatchRecords(
    salaryStatementId: number
  ): Observable<Model.ISimpleSalaryBatchRecord[]> {
    let path: string = this.baseUrl + '/api/salarystatements/{salaryStatementId}/batchrecords/simple';
    path = path.replace('{salaryStatementId}', salaryStatementId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a salary statement (payslip) as a PDF file.
   * @param The ID of the salary statement to return as a PDF payslip.
   */
  public SalaryStatements_GetPdf(salaryStatementId: number): Observable<any> {
    let path: string = this.baseUrl + '/api/salarystatements/{salaryStatementId}/pdf';
    path = path.replace('{salaryStatementId}', salaryStatementId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a salary statement (payslip) as a PDF file, encoded as a Base64 string.
   * @param The ID of the salary statement to return as a PDF payslip.
   */
  public SalaryStatements_GetPdfEncoded(salaryStatementId: number): Observable<any> {
    let path: string = this.baseUrl + '/api/salarystatements/{salaryStatementId}/pdf/encoded';
    path = path.replace('{salaryStatementId}', salaryStatementId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a salary statement as a PDF file based on data submitted in the request. Use this for preview
   * functionality.
   * @param A salary statement to generate into a PDF file. The object must contain all relevant SalaryBatchRecords.
   */
  public SalaryStatements_GetPdfFromClientData(statement: Model.SalaryStatement): Observable<any> {
    let path: string = this.baseUrl + '/api/salarystatements/pdf/fromclient';
    return this.httpPost(path, statement);
  }

  /**
   * Retrieves a salary statement as a PDF file based on data submitted in the request. Use this for preview
   * functionality.
   * @param A salary statement to generate into a PDF file. The object must contain all relevant SalaryBatchRecords.
   */
  public SalaryStatements_GetPdfFromClientDataEncoded(statement: Model.SalaryStatement): Observable<any> {
    let path: string = this.baseUrl + '/api/salarystatements/pdf/fromclient/encoded';
    return this.httpPost(path, statement);
  }

  /**
   * Retrieves a salary statement as HTML.
   *
   * Response can be returned as JSON-wrapped HTML or raw text/HTML depending on the accept headers in the request
   */
  public SalaryStatements_GetHtml(salaryStatementId: number, outputType: string): Observable<any> {
    let path: string = this.baseUrl + '/api/salarystatements/{salaryStatementId}/{outputType}';
    path = path.replace('{salaryStatementId}', salaryStatementId.toString());
    path = path.replace('{outputType}', outputType.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a salary statement as HTML based on data submitted in the request. Use this for preview functionality.
   *
   * Response can be returned as JSON-wrapped HTML or raw text/HTML depending on the accept headers in the request
   * @param What html format to output, matching a page size or responsive html.
   * @param A salary statement to generate into an HTML file. The object must contain all relevant SalaryBatchRecords.
   */
  public SalaryStatements_GetHtmlPreview(outputType: string, statement: Model.SalaryStatement): Observable<any> {
    let path: string = this.baseUrl + '/api/salarystatements/{outputType}/fromclient';
    path = path.replace('{outputType}', outputType.toString());
    return this.httpPost(path, statement);
  }

  /**
   * Retrieves a salary statement preview based on a single UserEmployment. The system suggests the most relevant
   * period. Notice that another method exists where you can specify the period to use.
   */
  public SalaryStatements_GetSingleEmploymentPreview(userEmploymentId: number): Observable<Model.ISalaryStatement> {
    let path: string = this.baseUrl + '/api/salarystatements/preview/employment/{userEmploymentId}';
    path = path.replace('{userEmploymentId}', userEmploymentId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a salary statement preview for the specified period, based on a single UserEmployment.
   */
  public SalaryStatements_GetSingleEmploymentPreviewByPeriod(
    userEmploymentId: number,
    periodId: number
  ): Observable<Model.ISalaryStatement> {
    let path: string = this.baseUrl + '/api/salarystatements/preview/employment/{userEmploymentId}/period/{periodId}';
    path = path.replace('{userEmploymentId}', userEmploymentId.toString());
    path = path.replace('{periodId}', periodId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a token that can be used to download a payslip through an anonymous GET request (to facilitate standard
   * browser download functionality). Call
   * api/salarystatements/{salaryStatementId}/token/{token}/lang/{languageCode}/{unusedFileName}.{payslipFormat} to
   * retrieve the file.
   * @param The ID of the payslip (SalaryStatement) to retrieve.
   */
  public SalaryStatements_GetPayslipDownloadToken(salaryStatementId: number): Observable<number> {
    let path: string = this.baseUrl + '/api/salarystatements/{salaryStatementId}/downloadtoken';
    path = path.replace('{salaryStatementId}', salaryStatementId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a payslip as PDF through an anonymous GET request to facilitate standard browser download
   * functionality. Must be preceded by a call to api/salarystatement/{salaryStatementId}/downloadtoken.
   * @param The ID of the payslip (SalaryStatement) to retrieve.
   * @param A unique reference generated by calling api/salarystatements/
   * @param The language in which to retrieve the payslip. Accepted values are "en" for English or "da" or "dk" for
   *        Danish. Currently all other values will result in Danish, but this may change in the future.
   * @param The format in which the payslip will be. Accepted values: "html" or "pdf". Currently all other values will
   *        result in "pdf", but this may change in the future.
   */
  public SalaryStatements_DownloadPayslipByToken(
    salaryStatementId: number,
    token: string,
    languageCode: string,
    payslipFormat: string,
    unusedFileName: string
  ): Observable<Model.ISalaryStatement> {
    let path: string =
      this.baseUrl +
      '/api/salarystatements/{salaryStatementId}/token/{token}/lang/{languageCode}/format/{payslipFormat}/{unusedFileName}';
    path = path.replace('{salaryStatementId}', salaryStatementId.toString());
    path = path.replace('{token}', token.toString());
    path = path.replace('{languageCode}', languageCode.toString());
    path = path.replace('{payslipFormat}', payslipFormat.toString());
    path = path.replace('{unusedFileName}', unusedFileName.toString());
    return this.httpGet(path);
  }

  /**
   * Reverts a finalized payslip, creating a new batch with status Cancelled and moving the payslip to that batch.
   * @param The ID of the salary statement (payslip) to revert.
   * @param An object containing the user's password, which must be provided as an extra security measure.
   */
  public SalaryStatements_RevertSalaryStatement(
    statementId: number,
    request: Model.SalaryBatchApprovalRequest
  ): Observable<void> {
    let path: string = this.baseUrl + '/api/salarystatements/{statementId}/revert';
    path = path.replace('{statementId}', statementId.toString());
    return this.httpPost(path, request);
  }

  /**
   * Gets a list of all salary types belonging to the currently active company. Note that this method exposes the
   * company's raw salary types, so if inheritance is disabled then most properties will be null. Translations and
   * base salary type are included in the response.
   */
  public SalaryTypes_GetRawSalaryTypes(): Observable<Model.ISalaryType[]> {
    let path: string = this.baseUrl + '/api/salarytypes/raw';
    return this.httpGet(path);
  }

  /**
   * Gets a list of all salary types belonging to the currently active company. Note that this method exposes the
   * company's raw salary types, so if inheritance is disabled then most properties will be null. Translations and
   * base salary type are included in the response. However, the Name, Description and DefaultPayslipText properties
   * are translated to the current session's language.
   */
  public SalaryTypes_GetRawSalaryTypesTranslated(): Observable<Model.ISalaryType[]> {
    let path: string = this.baseUrl + '/api/salarytypes/raw/translated';
    return this.httpGet(path);
  }

  /**
   * Gets a list of all effective salary types relevant for the currently active company, excluding hidden and
   * inactive ones. The result is the final values after accounting for inheritance (if any).
   */
  public SalaryTypes_GetSalaryTypeViews(): Observable<Model.ISalaryTypeView[]> {
    let path: string = this.baseUrl + '/api/salarytypes';
    return this.httpGet(path);
  }

  /**
   * Updates a salary type. NOTE: Ensure that you use this in conjunction with the /raw method to avoid
   * unintentionally inheriting a base salary type.
   * @param The salary type to update.
   */
  public SalaryTypes_UpdateSalaryType(salaryType: Model.SalaryType): Observable<Model.ISalaryType> {
    let path: string = this.baseUrl + '/api/salarytypes';
    return this.httpPut(path, salaryType);
  }

  /**
   * Gets a list of all salary types relevant for the currently active company, including hidden ones. The result is
   * the final values after accounting for inheritance (if any).
   */
  public SalaryTypes_GetAllSalaryTypeViews(): Observable<Model.ISalaryTypeView[]> {
    let path: string = this.baseUrl + '/api/salarytypes/all';
    return this.httpGet(path);
  }

  /**
   * Gets a list of all default salary types.
   */
  public SalaryTypes_GetDefaultSalaryTypes(): Observable<Model.ISalaryType[]> {
    let path: string = this.baseUrl + '/api/salarytypes/default';
    return this.httpGet(path);
  }

  /**
   * Gets a list of all salary type categories.
   */
  public SalaryTypes_GetAllSalaryTypeCategories(): Observable<Model.ISalaryTypeCategoryView[]> {
    let path: string = this.baseUrl + '/api/salarytypes/categories';
    return this.httpGet(path);
  }

  /**
   * Creates a new custom salary type.
   */
  public SalaryTypes_CreateCustomSalaryType(salaryType: Model.SalaryType): Observable<Model.ISalaryType> {
    let path: string = this.baseUrl + '/api/salarytypes/custom';
    return this.httpPost(path, salaryType);
  }

  /**
   * Deletes a salary type.
   */
  public SalaryTypes_DeleteSalaryType(salaryTypeId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/salarytypes/{salaryTypeId}';
    path = path.replace('{salaryTypeId}', salaryTypeId.toString());
    return this.httpDelete(path);
  }

  /**
   * Inherits a default salary type in the currently active company, creating a new company-specific salary type.
   */
  public SalaryTypes_InheritBaseSalaryType(request: Model.InheritSalaryTypeRequest): Observable<Model.ISalaryType> {
    let path: string = this.baseUrl + '/api/salarytypes/inherit';
    return this.httpPost(path, request);
  }

  /**
   * Retrieves the set of translations for a salary type.
   */
  public SalaryTypes_GetTranslations(salaryTypeId: number): Observable<Model.ISalaryTypeTranslation[]> {
    let path: string = this.baseUrl + '/api/salarytypes/{salaryTypeId}/translations';
    path = path.replace('{salaryTypeId}', salaryTypeId.toString());
    return this.httpGet(path);
  }

  /**
   * Creates / updates a set of salary type translations. Entities with Id = 0 will be created, others will be
   * updated.
   */
  public SalaryTypes_SetTranslations(
    translations: Model.SalaryTypeTranslation[]
  ): Observable<Model.ISalaryTypeTranslation[]> {
    let path: string = this.baseUrl + '/api/salarytypes/translations/list';
    return this.httpPost(path, translations);
  }

  /**
   * Updates an existing salary type, specifying only the external reference.
   * @param The salary type to update.
   */
  public SalaryTypes_UpdateSalaryTypeSimple(
    request: Model.SimpleSalaryTypeUpdateRequest
  ): Observable<Model.ISalaryType> {
    let path: string = this.baseUrl + '/api/salarytypes/simple';
    return this.httpPut(path, request);
  }

  /**
   * Sets the field OverviewIndex and OverviewDisplayMode for a specific salary type. If another salary type has the
   * same value, it is automatically swapped.
   * @param The ID of the salary type to update.
   * @param The 1-based index indicating the order to display the salary type in the overview tab (Company -&gt;
   *        Payroll Data). Must be a number between 1 and 7.
   * @param A value indicating how to display the salary type in the overview. Call
   *        api/staticdata/SalaryRecordsOverviewDisplayMode for possible values.
   */
  public SalaryTypes_ConfigureOverviewBehavior(
    salaryTypeId: number,
    overviewIndex: number,
    overviewDisplayMode: number
  ): Observable<Model.ISalaryType[]> {
    let path: string =
      this.baseUrl + '/api/salarytypes/{salaryTypeId}/overviewindex/{overviewIndex}/displaymode/{overviewDisplayMode}';
    path = path.replace('{salaryTypeId}', salaryTypeId.toString());
    path = path.replace('{overviewIndex}', overviewIndex.toString());
    path = path.replace('{overviewDisplayMode}', overviewDisplayMode.toString());
    return this.httpPut(path, undefined);
  }

  /**
   * Retrieves a list of all salary type groups in the currently active company.
   */
  public SalaryTypes_GetCompanySalaryTypeGroups(): Observable<Model.ISalaryTypeGroup[]> {
    let path: string = this.baseUrl + '/api/salarytypes/groups';
    return this.httpGet(path);
  }

  /**
   * Updates an existing salary type group. NOTE: This feature is not currently used.
   * @param The group to update.
   */
  public SalaryTypes_UpdateSalaryTypeGroup(group: Model.SalaryTypeGroup): Observable<Model.ISalaryTypeGroup> {
    let path: string = this.baseUrl + '/api/salarytypes/groups';
    return this.httpPut(path, group);
  }

  /**
   * Creates a new salary type group. NOTE: This feature is not currently used.
   * @param The group to create.
   */
  public SalaryTypes_CreateSalaryTypeGroup(group: Model.SalaryTypeGroup): Observable<Model.ISalaryTypeGroup> {
    let path: string = this.baseUrl + '/api/salarytypes/groups';
    return this.httpPost(path, group);
  }

  /**
   * Deletes a salary type group. NOTE: This feature is not currently used.
   * @param The ID of the group to delete.
   */
  public SalaryTypes_DeleteSalaryTypeGroup(groupId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/salarytypes/groups/{groupId}';
    path = path.replace('{groupId}', groupId.toString());
    return this.httpDelete(path);
  }

  /**
   * Automaps salary types to the Integration that currently has the AutoMap operation enabled.
   */
  public SalaryTypes_AutoMapSalaryTypes(): Observable<Model.ISalaryType[]> {
    let path: string = this.baseUrl + '/api/salarytypes/automap';
    return this.httpPost(path, undefined);
  }

  /**
   * Retrieves a list of tasks and their status for the current company. Note that StatusId is 0 = Todo, 1 = Completed
   * and -1 = Skipped.
   */
  public Startuptasks_GetStartupTasksWithStatus(): Observable<Model.IStartupTaskCompanyView[]> {
    let path: string = this.baseUrl + '/api/startuptasks/status';
    return this.httpGet(path);
  }

  /**
   * Skips the specified task.
   */
  public Startuptasks_SkipTask(taskId: number): Observable<Model.IStartupTaskCompanyView> {
    let path: string = this.baseUrl + '/api/startuptasks/{taskId}/skip';
    path = path.replace('{taskId}', taskId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Completes the specified task.
   */
  public Startuptasks_CompleteTask(taskId: number): Observable<Model.IStartupTaskCompanyView> {
    let path: string = this.baseUrl + '/api/startuptasks/{taskId}/done';
    path = path.replace('{taskId}', taskId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Undoes completion or skipping of the specified task.
   */
  public Startuptasks_ResetTask(taskId: number): Observable<Model.IStartupTaskCompanyView> {
    let path: string = this.baseUrl + '/api/startuptasks/{taskId}/reset';
    path = path.replace('{taskId}', taskId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Retrieves a list of all static data in the system. Note: Consider getting the data by type instead.
   */
  public StaticData_GetStaticData(): Observable<Model.IStaticData[]> {
    let path: string = this.baseUrl + '/api/staticdata';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all possible types of static data.
   */
  public StaticData_GetStaticDataTypes(): Observable<string[]> {
    let path: string = this.baseUrl + '/api/staticdata/types';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all values of the specified static data type. This method allows you to specify the desired
   * output language.
   */
  public StaticData_GetStaticDataByTypeTranslated(
    type: string,
    languageCode: string
  ): Observable<Model.IStaticDataEntity[]> {
    let path: string = this.baseUrl + '/api/staticdata/{type}/lang/{languageCode}';
    path = path.replace('{type}', type.toString());
    path = path.replace('{languageCode}', languageCode.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known Banks.
   */
  public StaticData_GetBanks(): Observable<Model.IBank[]> {
    let path: string = this.baseUrl + '/api/staticdata/bank';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known PaymentExportFormats.
   */
  public StaticData_GetPaymentExportFormats(): Observable<Model.IPaymentExportFormat[]> {
    let path: string = this.baseUrl + '/api/staticdata/paymentexportformat';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known AccountRoles.
   */
  public StaticData_GetAccountRoles(): Observable<Model.IAccountRole[]> {
    let path: string = this.baseUrl + '/api/staticdata/accountrole';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known AccountTypes.
   */
  public StaticData_GetAccountTypes(): Observable<Model.IAccountType[]> {
    let path: string = this.baseUrl + '/api/staticdata/accounttype';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known Dimensions.
   */
  public StaticData_GetDimensions(): Observable<Model.IDimension[]> {
    let path: string = this.baseUrl + '/api/staticdata/dimension';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known WorkBookDimensions.
   */
  public StaticData_GetWorkBookDimensions(): Observable<Model.IWorkBookDimension[]> {
    let path: string = this.baseUrl + '/api/staticdata/workbookdimension';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known UnicontaDimensions.
   */
  public StaticData_GetUnicontaDimensions(): Observable<Model.IUnicontaDimension[]> {
    let path: string = this.baseUrl + '/api/staticdata/unicontadimension';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known InventioItDimensions.
   */
  public StaticData_GetInventioItDimensions(): Observable<Model.IInventioItDimension[]> {
    let path: string = this.baseUrl + '/api/staticdata/inventioitdimension';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known TimeEntryStatusExtendeds.
   */
  public StaticData_GetTimeEntryStatusExtendeds(): Observable<Model.ITimeEntryStatusExtended[]> {
    let path: string = this.baseUrl + '/api/staticdata/timeentrystatusextended';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known WorkBookVersions.
   */
  public StaticData_GetWorkBookVersions(): Observable<Model.IWorkBookVersion[]> {
    let path: string = this.baseUrl + '/api/staticdata/workbookversion';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known ExternalSystemConfigurations.
   */
  public StaticData_GetExternalSystemConfigurations(): Observable<Model.IExternalSystemConfiguration[]> {
    let path: string = this.baseUrl + '/api/staticdata/externalsystemconfiguration';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known CompanyPreferenceCategories.
   */
  public StaticData_GetCompanyPreferenceCategories(): Observable<Model.ICompanyPreferenceCategory[]> {
    let path: string = this.baseUrl + '/api/staticdata/companypreferencecategory';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known SalaryRecordsOverviewDisplayModes.
   */
  public StaticData_GetSalaryRecordsOverviewDisplayModes(): Observable<Model.ISalaryRecordsOverviewDisplayMode[]> {
    let path: string = this.baseUrl + '/api/staticdata/salaryrecordsoverviewdisplaymode';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known ExternalSystemOperations.
   */
  public StaticData_GetExternalSystemOperations(): Observable<Model.IExternalSystemOperation[]> {
    let path: string = this.baseUrl + '/api/staticdata/externalsystemoperation';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known IntegrationStatuses.
   */
  public StaticData_GetIntegrationStatuses(): Observable<Model.IIntegrationStatus[]> {
    let path: string = this.baseUrl + '/api/staticdata/integrationstatus';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known Municipalities.
   */
  public StaticData_GetMunicipalities(): Observable<Model.IMunicipality[]> {
    let path: string = this.baseUrl + '/api/staticdata/municipality';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known ExternalSystemCredentials.
   */
  public StaticData_GetExternalSystemCredentials(): Observable<Model.IExternalSystemCredential[]> {
    let path: string = this.baseUrl + '/api/staticdata/externalsystemcredential';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known FinanceAccountTypes.
   */
  public StaticData_GetFinanceAccountTypes(): Observable<Model.IFinanceAccountType[]> {
    let path: string = this.baseUrl + '/api/staticdata/financeaccounttype';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known EIncomeGroupByParameters.
   */
  public StaticData_GetEIncomeGroupByParameters(): Observable<Model.IEIncomeGroupByParameter[]> {
    let path: string = this.baseUrl + '/api/staticdata/eincomegroupbyparameter';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known OvertimeSupplementPrinciples.
   */
  public StaticData_GetOvertimeSupplementPrinciples(): Observable<Model.IOvertimeSupplementPrinciple[]> {
    let path: string = this.baseUrl + '/api/staticdata/overtimesupplementprinciple';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known SalaryTypeTotalsMultipleBatchesGroupByParameters.
   */
  public StaticData_GetSalaryTypeTotalsMultipleBatchesGroupByParameters(): Observable<
    Model.ISalaryTypeTotalsMultipleBatchesGroupByParameter[]
  > {
    let path: string = this.baseUrl + '/api/staticdata/salarytypetotalsmultiplebatchesgroupbyparameter';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known Currencies.
   */
  public StaticData_GetCurrencies(): Observable<Model.ICurrency[]> {
    let path: string = this.baseUrl + '/api/staticdata/currency';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known Cities.
   */
  public StaticData_GetCities(): Observable<Model.ICity[]> {
    let path: string = this.baseUrl + '/api/staticdata/city';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known PaymentProviders.
   */
  public StaticData_GetPaymentProviders(): Observable<Model.IPaymentProvider[]> {
    let path: string = this.baseUrl + '/api/staticdata/paymentprovider';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known EmailTemplates.
   */
  public StaticData_GetEmailTemplates(): Observable<Model.IEmailTemplate[]> {
    let path: string = this.baseUrl + '/api/staticdata/emailtemplate';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known BillingPrinciples.
   */
  public StaticData_GetBillingPrinciples(): Observable<Model.IBillingPrinciple[]> {
    let path: string = this.baseUrl + '/api/staticdata/billingprinciple';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known ImportLists.
   */
  public StaticData_GetImportLists(): Observable<Model.IImportList[]> {
    let path: string = this.baseUrl + '/api/staticdata/importlist';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known ModulePackages.
   */
  public StaticData_GetModulePackages(): Observable<Model.IModulePackage[]> {
    let path: string = this.baseUrl + '/api/staticdata/modulepackage';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known ImportSpecifications.
   */
  public StaticData_GetImportSpecifications(): Observable<Model.IImportSpecification[]> {
    let path: string = this.baseUrl + '/api/staticdata/importspecification';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known Preferences.
   */
  public StaticData_GetPreferences(): Observable<Model.IPreference[]> {
    let path: string = this.baseUrl + '/api/staticdata/preference';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known UserPreferences.
   */
  public StaticData_GetUserPreferences(): Observable<Model.IUserPreference[]> {
    let path: string = this.baseUrl + '/api/staticdata/userpreference';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known CompanyPreferences.
   */
  public StaticData_GetCompanyPreferences(): Observable<Model.ICompanyPreference[]> {
    let path: string = this.baseUrl + '/api/staticdata/companypreference';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known StatisticsRecipients.
   */
  public StaticData_GetStatisticsRecipients(): Observable<Model.IStatisticsRecipient[]> {
    let path: string = this.baseUrl + '/api/staticdata/statisticsrecipient';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known SignupReferrals.
   */
  public StaticData_GetSignupReferrals(): Observable<Model.ISignupReferral[]> {
    let path: string = this.baseUrl + '/api/staticdata/signupreferral';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known ReportParameters.
   */
  public StaticData_GetReportParameters(): Observable<Model.IReportParameter[]> {
    let path: string = this.baseUrl + '/api/staticdata/reportparameter';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known StatisticsSalaryPrinciples.
   */
  public StaticData_GetStatisticsSalaryPrinciples(): Observable<Model.IStatisticsSalaryPrinciple[]> {
    let path: string = this.baseUrl + '/api/staticdata/statisticssalaryprinciple';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known StatisticsEmploymentPrinciples.
   */
  public StaticData_GetStatisticsEmploymentPrinciples(): Observable<Model.IStatisticsEmploymentPrinciple[]> {
    let path: string = this.baseUrl + '/api/staticdata/statisticsemploymentprinciple';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known StatisticsEmploymentTermses.
   */
  public StaticData_GetStatisticsEmploymentTermses(): Observable<Model.IStatisticsEmploymentTerms[]> {
    let path: string = this.baseUrl + '/api/staticdata/statisticsemploymentterms';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known StatisticsJobStatuses.
   */
  public StaticData_GetStatisticsJobStatuses(): Observable<Model.IStatisticsJobStatus[]> {
    let path: string = this.baseUrl + '/api/staticdata/statisticsjobstatus';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known IntervalTypes.
   */
  public StaticData_GetIntervalTypes(): Observable<Model.IIntervalType[]> {
    let path: string = this.baseUrl + '/api/staticdata/intervaltype';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known TermExplanations.
   */
  public StaticData_GetTermExplanations(): Observable<Model.ITermExplanation[]> {
    let path: string = this.baseUrl + '/api/staticdata/termexplanation';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known TimeEntryRecordAcrossSalaryPeriodStrategies.
   */
  public StaticData_GetTimeEntryRecordAcrossSalaryPeriodStrategies(): Observable<
    Model.ITimeEntryRecordAcrossSalaryPeriodStrategy[]
  > {
    let path: string = this.baseUrl + '/api/staticdata/timeentryrecordacrosssalaryperiodstrategy';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known TimeEntryStatuses.
   */
  public StaticData_GetTimeEntryStatuses(): Observable<Model.ITimeEntryStatus[]> {
    let path: string = this.baseUrl + '/api/staticdata/timeentrystatus';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known Months.
   */
  public StaticData_GetMonths(): Observable<Model.IMonth[]> {
    let path: string = this.baseUrl + '/api/staticdata/month';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known BankHolidaies.
   */
  public StaticData_GetBankHolidaies(): Observable<Model.IBankHoliday[]> {
    let path: string = this.baseUrl + '/api/staticdata/bankholiday';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known ExternalSystems.
   */
  public StaticData_GetExternalSystems(): Observable<Model.IExternalSystem[]> {
    let path: string = this.baseUrl + '/api/staticdata/externalsystem';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known PaymentMethods.
   */
  public StaticData_GetPaymentMethods(): Observable<Model.IPaymentMethod[]> {
    let path: string = this.baseUrl + '/api/staticdata/paymentmethod';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known PaymentStatuses.
   */
  public StaticData_GetPaymentStatuses(): Observable<Model.IPaymentStatus[]> {
    let path: string = this.baseUrl + '/api/staticdata/paymentstatus';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known ReportAreas.
   */
  public StaticData_GetReportAreas(): Observable<Model.IReportArea[]> {
    let path: string = this.baseUrl + '/api/staticdata/reportarea';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known Reports.
   */
  public StaticData_GetReports(): Observable<Model.IReport[]> {
    let path: string = this.baseUrl + '/api/staticdata/report';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known PensionProviders.
   */
  public StaticData_GetPensionProviders(): Observable<Model.IPensionProvider[]> {
    let path: string = this.baseUrl + '/api/staticdata/pensionprovider';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known CompanyStatuses.
   */
  public StaticData_GetCompanyStatuses(): Observable<Model.ICompanyStatus[]> {
    let path: string = this.baseUrl + '/api/staticdata/companystatus';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known SalarySummaryCategories.
   */
  public StaticData_GetSalarySummaryCategories(): Observable<Model.ISalarySummaryCategory[]> {
    let path: string = this.baseUrl + '/api/staticdata/salarysummarycategory';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known SalaryRecordPersistenceTypes.
   */
  public StaticData_GetSalaryRecordPersistenceTypes(): Observable<Model.ISalaryRecordPersistenceType[]> {
    let path: string = this.baseUrl + '/api/staticdata/salaryrecordpersistencetype';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known AtpRates.
   */
  public StaticData_GetAtpRates(): Observable<Model.IAtpRate[]> {
    let path: string = this.baseUrl + '/api/staticdata/atprate';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known DataTypes.
   */
  public StaticData_GetDataTypes(): Observable<Model.IDataType[]> {
    let path: string = this.baseUrl + '/api/staticdata/datatype';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known PensionBaseCalculationMethods.
   */
  public StaticData_GetPensionBaseCalculationMethods(): Observable<Model.IPensionBaseCalculationMethod[]> {
    let path: string = this.baseUrl + '/api/staticdata/pensionbasecalculationmethod';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known UnitTypes.
   */
  public StaticData_GetUnitTypes(): Observable<Model.IUnitType[]> {
    let path: string = this.baseUrl + '/api/staticdata/unittype';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known CompanyTypes.
   */
  public StaticData_GetCompanyTypes(): Observable<Model.ICompanyType[]> {
    let path: string = this.baseUrl + '/api/staticdata/companytype';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known AtpAgreements.
   */
  public StaticData_GetAtpAgreements(): Observable<Model.IAtpAgreement[]> {
    let path: string = this.baseUrl + '/api/staticdata/atpagreement';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known IncomeTypes.
   */
  public StaticData_GetIncomeTypes(): Observable<Model.IIncomeType[]> {
    let path: string = this.baseUrl + '/api/staticdata/incometype';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known SalaryTypeCategories.
   */
  public StaticData_GetSalaryTypeCategories(): Observable<Model.ISalaryTypeCategory[]> {
    let path: string = this.baseUrl + '/api/staticdata/salarytypecategory';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known TaxCardTypes.
   */
  public StaticData_GetTaxCardTypes(): Observable<Model.ITaxCardType[]> {
    let path: string = this.baseUrl + '/api/staticdata/taxcardtype';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known SalaryCycles.
   */
  public StaticData_GetSalaryCycles(): Observable<Model.ISalaryCycle[]> {
    let path: string = this.baseUrl + '/api/staticdata/salarycycle';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known Modules.
   */
  public StaticData_GetModules(): Observable<Model.IModule[]> {
    let path: string = this.baseUrl + '/api/staticdata/module';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known VacationProviders.
   */
  public StaticData_GetVacationProviders(): Observable<Model.IVacationProvider[]> {
    let path: string = this.baseUrl + '/api/staticdata/vacationprovider';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known VacationTypeGLs.
   */
  public StaticData_GetVacationTypeGLs(): Observable<Model.IVacationTypeGL[]> {
    let path: string = this.baseUrl + '/api/staticdata/vacationtypegl';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known VacationTypes.
   */
  public StaticData_GetVacationTypes(): Observable<Model.IVacationType[]> {
    let path: string = this.baseUrl + '/api/staticdata/vacationtype';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known SalaryBatchStatuses.
   */
  public StaticData_GetSalaryBatchStatuses(): Observable<Model.ISalaryBatchStatus[]> {
    let path: string = this.baseUrl + '/api/staticdata/salarybatchstatus';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known AccountStatuses.
   */
  public StaticData_GetAccountStatuses(): Observable<Model.IAccountStatus[]> {
    let path: string = this.baseUrl + '/api/staticdata/accountstatus';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known Countries.
   */
  public StaticData_GetCountries(): Observable<Model.ICountry[]> {
    let path: string = this.baseUrl + '/api/staticdata/country';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of all known Roles.
   */
  public StaticData_GetRoles(): Observable<Model.IRole[]> {
    let path: string = this.baseUrl + '/api/staticdata/role';
    return this.httpGet(path);
  }

  public Test_TestStringFormatting(): Observable<any> {
    let path: string = this.baseUrl + '/api/test/strings';
    return this.httpGet(path);
  }

  public Test_TestLanguageContext(): Observable<any> {
    let path: string = this.baseUrl + '/api/test/translations';
    return this.httpGet(path);
  }

  public Test_TestBatchCalculation(): Observable<any> {
    let path: string = this.baseUrl + '/api/test/batchcalc';
    return this.httpPost(path, undefined);
  }

  public Test_TestData(): Observable<any> {
    let path: string = this.baseUrl + '/api/test/data';
    return this.httpPost(path, undefined);
  }

  public Test_TestSmallPdf(): Observable<any> {
    let path: string = this.baseUrl + '/api/test/pdf/small';
    return this.httpGet(path);
  }

  public Test_TestLargePdf(): Observable<any> {
    let path: string = this.baseUrl + '/api/test/pdf/large';
    return this.httpGet(path);
  }

  public Test_TestSmallPdfEncoded(): Observable<any> {
    let path: string = this.baseUrl + '/api/test/pdf/small/encoded';
    return this.httpGet(path);
  }

  public Test_TestLargePdfEncoded(): Observable<any> {
    let path: string = this.baseUrl + '/api/test/pdf/large/encoded';
    return this.httpGet(path);
  }

  public Test_TestXlsx(): Observable<any> {
    let path: string = this.baseUrl + '/api/test/xlsx';
    return this.httpGet(path);
  }

  public Test_TestPropertySanitization(sanitizedClass: Model.TestClassWithHtmlSanitization): Observable<any> {
    let path: string = this.baseUrl + '/api/test/sanitization1';
    return this.httpPost(path, sanitizedClass);
  }

  public Test_TestRouteSanitization(sanitizedClass: Model.TestClassWithHtmlSanitization): Observable<any> {
    let path: string = this.baseUrl + '/api/test/sanitization2';
    return this.httpPost(path, sanitizedClass);
  }

  public Test_TestSanitization(anInt: number, anInt2: number): Observable<any> {
    let path: string = this.baseUrl + '/api/test/sanitization3/{anInt}/{anInt2}';
    path = path.replace('{anInt}', anInt.toString());
    path = path.replace('{anInt2}', anInt2.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Retrieves a list of time entry records for the specified employment in the specified salary period.
   * @param The ID of the employment for which to retrieve salary records. Note: Do not pass a UserId or
   *        CompanyUserId.
   * @param The ID of the salary period for which to retrieve records.
   */
  public TimeEntry_GetTimeEntryRecordsByEmployment(
    userEmploymentId: number,
    periodId: number
  ): Observable<Model.ITimeEntryRecord[]> {
    let path: string = this.baseUrl + '/api/timeentry/employment/{userEmploymentId}/period/{periodId}';
    path = path.replace('{userEmploymentId}', userEmploymentId.toString());
    path = path.replace('{periodId}', periodId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of time entry records across all employees that you have access to, in the specified salary
   * period.
   * @param The ID of the salary period for which to retrieve records.
   */
  public TimeEntry_GetTimeEntryRecordsByPeriod(periodId: number): Observable<Model.ITimeEntryRecord[]> {
    let path: string = this.baseUrl + '/api/timeentry/period/{periodId}';
    path = path.replace('{periodId}', periodId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of open (status = draft or employee-approved) time entry records across all periods, optionally
   * filtered for salary cycle.
   * @param The ID of the salary period for which to retrieve records. Send 0 to include all cycles.
   */
  public TimeEntry_GetOpenTimeEntryRecordsAcrossPeriods(cycleId: number): Observable<Model.ITimeEntryRecord[]> {
    let path: string = this.baseUrl + '/api/timeentry/salarycycle/{cycleId}/open';
    path = path.replace('{cycleId}', cycleId.toString());
    return this.httpGet(path);
  }

  /**
   * Updates an existing time entry record. Note that the StatusId cannot be modified this way - call the approval
   * methods instead. After editing, the status will always be "Open". This works for rejected and employee-approved
   * records too.
   * @param The record to update.
   */
  public TimeEntry_UpdateTimeEntryRecord(record: Model.TimeEntryRecord): Observable<Model.ITimeEntryRecord> {
    let path: string = this.baseUrl + '/api/timeentry';
    return this.httpPut(path, record);
  }

  /**
   * Creates a time entry record. Note that the value SalaryPeriodId need not be specified - it will automatically be
   * set to match EntryDate.
   * @param The record to create.
   */
  public TimeEntry_CreateTimeEntryRecord(record: Model.TimeEntryRecord): Observable<Model.ITimeEntryRecord> {
    let path: string = this.baseUrl + '/api/timeentry';
    return this.httpPost(path, record);
  }

  /**
   * Deletes an open time entry record.
   * @param The ID of the record to delete or deactivate.
   */
  public TimeEntry_DeleteTimeEntryRecord(recordId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/timeentry/{recordId}';
    path = path.replace('{recordId}', recordId.toString());
    return this.httpDelete(path);
  }

  /**
   * Approves all open time entry records for the specified UserEmployment in the specified salary period - or across
   * periods if the value 0 is provided for periodId.
   * @param The ID of the employment for which to approve records.
   * @param The ID of the SalaryPeriod to approve. Send the value 0 to approve all open records across periods.
   */
  public TimeEntry_ApprovePeriodForEmployee(userEmploymentId: number, periodId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/timeentry/employment/{userEmploymentId}/period/{periodId}/approval';
    path = path.replace('{userEmploymentId}', userEmploymentId.toString());
    path = path.replace('{periodId}', periodId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Approves all open time entry records in the specified salary period, or across periods if the value 0 is provided
   * for periodId.
   * @param The ID of the SalaryPeriod to approve. Send the value 0 to approve all open records across periods and
   *        cycles.
   */
  public TimeEntry_ApproveEntirePeriod(periodId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/timeentry/period/{periodId}/approval';
    path = path.replace('{periodId}', periodId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Approves all open time entry records across all periods in the specified salary cycle.
   * @param The ID of the SalaryCycle to approve for.
   */
  public TimeEntry_ApproveEntireCycle(cycleId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/timeentry/cycle/{cycleId}/approval';
    path = path.replace('{cycleId}', cycleId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Approve a single time entry record.
   * @param The ID of the record to delete or deactivate.
   */
  public TimeEntry_ApproveTimeEntryRecord(recordId: number): Observable<Model.ITimeEntryRecord> {
    let path: string = this.baseUrl + '/api/timeentry/{recordId}/approve';
    path = path.replace('{recordId}', recordId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Rejects an employee-approved time entry record, optionally specifying a reason.
   * @param The ID of the record to delete or deactivate.
   * @param An optional object specifying a reason for the rejection. May be omitted.
   */
  public TimeEntry_RejectTimeEntryRecord(
    recordId: number,
    info: Model.RejectionInfo
  ): Observable<Model.ITimeEntryRecord> {
    let path: string = this.baseUrl + '/api/timeentry/{recordId}/reject';
    path = path.replace('{recordId}', recordId.toString());
    return this.httpPost(path, info);
  }

  /**
   * Gets a list of all time entry types belonging to the currently active company. Note that this method exposes the
   * company's raw time entry types, so if inheritance is disabled then most properties will be null. Translations and
   * base time entry type are included in the response.
   */
  public TimeEntryTypes_GetRawTimeEntryTypes(): Observable<Model.ITimeEntryType[]> {
    let path: string = this.baseUrl + '/api/timeentrytypes/raw';
    return this.httpGet(path);
  }

  /**
   * Gets a list of all time entry types belonging to the currently active company. Note that this method exposes the
   * company's raw time entry types, so if inheritance is disabled then most properties will be null. Translations and
   * base time entry type are included in the response. However, the Name, Description and DefaultPayslipText
   * properties are translated to the current session's language.
   */
  public TimeEntryTypes_GetRawTimeEntryTypesTranslated(): Observable<Model.ITimeEntryType[]> {
    let path: string = this.baseUrl + '/api/timeentrytypes/raw/translated';
    return this.httpGet(path);
  }

  /**
   * Gets a list of all effective time entry types relevant for the currently active company, excluding hidden and
   * inactive ones. The result is the final values after accounting for inheritance (if any).
   */
  public TimeEntryTypes_GetTimeEntryTypeViews(): Observable<Model.ITimeEntryTypeView[]> {
    let path: string = this.baseUrl + '/api/timeentrytypes';
    return this.httpGet(path);
  }

  /**
   * Updates a time entry type. NOTE: Ensure that you use this in conjunction with the /raw method to avoid
   * unintentionally inheriting a base time entry type.
   * @param The time entry type to update.
   */
  public TimeEntryTypes_UpdateTimeEntryType(timeEntryType: Model.TimeEntryType): Observable<Model.ITimeEntryType> {
    let path: string = this.baseUrl + '/api/timeentrytypes';
    return this.httpPut(path, timeEntryType);
  }

  /**
   * Gets a list of all default time entry types.
   */
  public TimeEntryTypes_GetDefaultTimeEntryTypes(): Observable<Model.ITimeEntryType[]> {
    let path: string = this.baseUrl + '/api/timeentrytypes/default';
    return this.httpGet(path);
  }

  /**
   * Gets a list of all default time entry types that are not currently mapped to the company.
   */
  public TimeEntryTypes_GetUnmappedDefaultTimeEntryTypes(): Observable<Model.ITimeEntryType[]> {
    let path: string = this.baseUrl + '/api/timeentrytypes/default/unmapped';
    return this.httpGet(path);
  }

  /**
   * Creates a new custom time entry type.
   */
  public TimeEntryTypes_CreateCustomTimeEntryType(
    timeEntryType: Model.TimeEntryType
  ): Observable<Model.ITimeEntryType> {
    let path: string = this.baseUrl + '/api/timeentrytypes/custom';
    return this.httpPost(path, timeEntryType);
  }

  /**
   * Deletes a time entry type.
   */
  public TimeEntryTypes_DeleteTimeEntryType(timeEntryTypeId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/timeentrytypes/{timeEntryTypeId}';
    path = path.replace('{timeEntryTypeId}', timeEntryTypeId.toString());
    return this.httpDelete(path);
  }

  /**
   * Inherits a default time entry type in the currently active company, creating a new company-specific time entry
   * type.
   */
  public TimeEntryTypes_InheritBaseTimeEntryType(
    request: Model.InheritTimeEntryTypeRequest
  ): Observable<Model.ITimeEntryType> {
    let path: string = this.baseUrl + '/api/timeentrytypes/inherit';
    return this.httpPost(path, request);
  }

  /**
   * Retrieves the set of translations for a time entry type.
   */
  public TimeEntryTypes_GetTranslations(timeEntryTypeId: number): Observable<Model.ITimeEntryTypeTranslation[]> {
    let path: string = this.baseUrl + '/api/timeentrytypes/{timeEntryTypeId}/translations';
    path = path.replace('{timeEntryTypeId}', timeEntryTypeId.toString());
    return this.httpGet(path);
  }

  /**
   * Creates / updates a set of time entry type translations. Entities with Id = 0 will be created, others will be
   * updated.
   */
  public TimeEntryTypes_SetTranslations(
    translations: Model.TimeEntryTypeTranslation[]
  ): Observable<Model.ITimeEntryTypeTranslation[]> {
    let path: string = this.baseUrl + '/api/timeentrytypes/translations/list';
    return this.httpPost(path, translations);
  }

  /**
   * Deletes the specified TimeEntryTypeConfiguration
   */
  public TimeEntryTypes_DeleteTimeEntryTypeConfiguration(timeEntryTypeConfigurationId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/timeentrytypes/configurations/{timeEntryTypeConfigurationId}';
    path = path.replace('{timeEntryTypeConfigurationId}', timeEntryTypeConfigurationId.toString());
    return this.httpDelete(path);
  }

  /**
   * Get all TimeEntryTypeConfigurations for the current company.
   */
  public TimeEntryTypes_GetTimeEntryTypeConfigurations(): Observable<Model.ITimeEntryTypeConfiguration[]> {
    let path: string = this.baseUrl + '/api/timeentrytypes/configurations';
    return this.httpGet(path);
  }

  /**
   * Updates the specified TimeEntryTypeConfiguration.
   * @param The TimeEntryTypeConfiguration to update.
   */
  public TimeEntryTypes_UpdateTimeEntryTypeConfiguration(
    configuration: Model.TimeEntryTypeConfiguration
  ): Observable<Model.ITimeEntryTypeConfiguration> {
    let path: string = this.baseUrl + '/api/timeentrytypes/configurations';
    return this.httpPut(path, configuration);
  }

  /**
   * Creates a new TimeEntryTypeConfiguration.
   * @param The TimeEntryTypeConfiguration to create.
   */
  public TimeEntryTypes_CreateTimeEntryTypeConfiguration(
    configuration: Model.TimeEntryTypeConfiguration
  ): Observable<Model.ITimeEntryTypeConfiguration> {
    let path: string = this.baseUrl + '/api/timeentrytypes/configurations';
    return this.httpPost(path, configuration);
  }

  /**
   * Get all TimeEntryTypeConfiguration belonging to a TimeEntryType for the current company.
   */
  public TimeEntryTypes_GetTimeEntryTypeConfigurationsByTimeEntry(
    timeEntryTypeId: number
  ): Observable<Model.ITimeEntryTypeConfiguration[]> {
    let path: string = this.baseUrl + '/api/timeentrytypes/{timeEntryTypeId}/configurations';
    path = path.replace('{timeEntryTypeId}', timeEntryTypeId.toString());
    return this.httpGet(path);
  }

  /**
   * Checks that a given identity (CPR) number is well-formed, and whether it represents an existing Gratisal user.
   * Also gives the associated date of birth, and whether a warning should be raised for a failed "modulo 11 check".
   */
  public Users_CheckIdentityNumber(identityNumber: string): Observable<Model.IIdentityCheckResponse> {
    let path: string = this.baseUrl + '/api/users/identity/{identityNumber}';
    path = path.replace('{identityNumber}', identityNumber.toString());
    return this.httpGet(path);
  }

  /**
   * Checks a given username is well-formed, and whether represents an existing Gratisal user.
   */
  public Users_CheckUsername(username: string): Observable<Model.IUsernameCheckResponse> {
    let path: string = this.baseUrl + '/api/users/username/{username}';
    path = path.replace('{username}', username.toString());
    return this.httpGet(path);
  }

  /**
   * Gets the current user's image as a raw byte array.
   */
  public Users_GetCurrentUserImage(): Observable<string> {
    let path: string = this.baseUrl + '/api/users/current/image';
    return this.httpGet(path);
  }

  /**
   * Sets the current user's image. The image must be attached to the request as a file.
   */
  public Users_SetCurrentUserImage(): Observable<string> {
    let path: string = this.baseUrl + '/api/users/current/image';
    return this.httpPost(path, undefined);
  }

  /**
   * Gets the specified user's image as a raw byte array. Defaults to the current user if no Id is specified.
   * @param The ID of the user for which to get the image. Note: Do not provide CompayUserId.
   */
  public Users_GetUserImageById(userId: number): Observable<string> {
    let path: string = this.baseUrl + '/api/users/{userId}/image';
    path = path.replace('{userId}', userId.toString());
    return this.httpGet(path);
  }

  /**
   * Sets the specified user's image. The image must be attached to the request as a file.
   * @param The ID of the user for which to set an image. Note: Do not provide CompanyUserId.
   */
  public Users_SetUserImageById(userId: number): Observable<string> {
    let path: string = this.baseUrl + '/api/users/{userId}/image';
    path = path.replace('{userId}', userId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Retrieves detailed information about the specified user.
   * @param The Id of the user to retrieve information about.
   */
  public Users_GetUser(userId: number): Observable<Model.IUser> {
    let path: string = this.baseUrl + '/api/users/{userId}';
    path = path.replace('{userId}', userId.toString());
    return this.httpGet(path);
  }

  /**
   * Retrieves information about the currently logged-in user.
   */
  public Users_GetCurrentUser(): Observable<Model.IUser> {
    let path: string = this.baseUrl + '/api/users/current';
    return this.httpGet(path);
  }

  /**
   * Retrieves a list of companies that the currently logged-in user has access to.
   */
  public Users_GetCompanies(): Observable<Model.ICompanyUserView[]> {
    let path: string = this.baseUrl + '/api/users/current/companies';
    return this.httpGet(path);
  }

  /**
   * Sets the specified company as default for the current user.
   * @param The Id of the company to use as default.
   */
  public Users_SetDefaultCompany(companyId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/users/current/defaultcompany/{companyId}';
    path = path.replace('{companyId}', companyId.toString());
    return this.httpPost(path, undefined);
  }

  /**
   * Sets the specified language for the current user. Call GET to api/languages to get a list of supported languages.
   * Note that the change may not have any effect if the company has not enabled the Language module.
   * @param The Id of the language to set for the current user.
   */
  public Users_SetLanguage(languageId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/users/current/language/{languageId}';
    path = path.replace('{languageId}', languageId.toString());
    return this.httpPut(path, undefined);
  }

  /**
   * Sets the specified language for the specified user. Call GET to api/languages to get a list of supported
   * languages. Note that the change may not have any effect if the company has not enabled the Language module.
   * @param The ID of the user whose language to change.
   * @param The Id of the language to set for the user.
   */
  public Users_SetLanguageUser(userId: number, languageId: number): Observable<void> {
    let path: string = this.baseUrl + '/api/users/{userId}/language/{languageId}';
    path = path.replace('{userId}', userId.toString());
    path = path.replace('{languageId}', languageId.toString());
    return this.httpPut(path, undefined);
  }

  /**
   * Sets the identity (CPR) number for the specified user.
   * @param The Id of the user whose identity number to change.
   * @param An object containing the new identity number (CPR) to set for the user. May optionally contain a hyphen.
   */
  public Users_ChangeIdentityNumber(userId: number, request: Model.IdentityNumberRequest): Observable<void> {
    let path: string = this.baseUrl + '/api/users/{userId}/identity';
    path = path.replace('{userId}', userId.toString());
    return this.httpPut(path, request);
  }

  /**
   * Updates an existing user's master data, including Address and UserEmails. Note that only UserEmail objects can be
   * added and removed using this method. Provide null values (not empty lists) to leave child objects unmodified.
   * This method is only accessible to the user himself.
   * @param The user to update.
   */
  public Users_UpdateUser(user: Model.User): Observable<Model.IUser> {
    let path: string = this.baseUrl + '/api/users';
    return this.httpPut(path, user);
  }

  protected httpGet(path: string, options?: any): Observable<any> {
    return Observable.create((observer: any) => {
      observer.complete();
    });
  }

  protected httpPost(path: string, body?: any, options?: any): Observable<any> {
    return Observable.create((observer: any) => {
      observer.complete();
    });
  }

  protected httpPut(path: string, body?: any, options?: any): Observable<any> {
    return Observable.create((observer: any) => {
      observer.complete();
    });
  }

  protected httpDelete(path: string, options?: any): Observable<any> {
    return Observable.create((observer: any) => {
      observer.complete();
    });
  }
}
