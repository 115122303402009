import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter } from '@angular/core';
import { WindowReference } from '../../../../windowReference';
import { GridHelper } from '../../../Common/GridHelper';
import { ModuleCompanyView } from '../../../Services/ApiModel';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
var PriceComponent = /** @class */ (function () {
    function PriceComponent(windowRef, sessionService, elementRef) {
        this.windowRef = windowRef;
        this.sessionService = sessionService;
        this.elementRef = elementRef;
        this.usersMonthly = 0;
        this.users14Day = 0;
        this.usersWeekly = 0;
        this.isHideToolTip = false;
        this.modulesValue = [];
        this.submit = new EventEmitter();
        this.currentCompanyClick = false;
        this.companyClickChange = new EventEmitter();
        this.submitEnabled = false;
        this.packages = [];
        this.priceElements = [];
        this.totalCost = 0;
        this.isLoading = true;
        this.calculatedPricePerPayslip = 0;
        this.isEnableTooltip = true;
    }
    Object.defineProperty(PriceComponent.prototype, "modules", {
        get: function () {
            return this.modulesValue;
        },
        set: function (value) {
            var _this = this;
            this.modulesValue = value;
            this.buildPackages().then(function () {
                if (value.length > 0) {
                    _this.isLoading = false;
                }
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PriceComponent.prototype, "IsMobile", {
        get: function () {
            return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PriceComponent.prototype, "companyClick", {
        get: function () {
            return this.currentCompanyClick;
        },
        set: function (value) {
            if (value) {
                this.isHideToolTip = !this.isHideToolTip;
            }
            this.companyClickChange.emit(false);
        },
        enumerable: true,
        configurable: true
    });
    PriceComponent.prototype.onEmployeesChange = function () {
        this.updateTotal();
    };
    PriceComponent.prototype.onSubmit = function () {
        this.submit.emit(this.selectedPackageId);
    };
    PriceComponent.prototype.onPackageChange = function (packageId) {
        var modulePackage = this.packages.find(function (p) { return p.PackageId === packageId; });
        modulePackage.Selected = !modulePackage.Selected;
        if (modulePackage.Selected) {
            this.selectPackage(modulePackage);
        }
        else {
            this.deSelectPackage(modulePackage);
        }
        this.updateTotal();
    };
    PriceComponent.prototype.automaticReselectOfPackages = function (activate) {
        var nonExtraPackages = this.packages
            .filter(function (p) { return p.PackageId !== 9999; })
            .sort(function (a, b) { return GridHelper.sortByNumberValue(a.Price, b.Price); })
            .reverse();
        var nonExtraModulesArrays = nonExtraPackages.map(function (mp) { return mp.Modules; });
        var nonExtraModules = [].concat.apply([], nonExtraModulesArrays);
        if (activate) {
            // Logic to select package if price of modules exceeds pack price
            var selectPackageId = void 0;
            var _loop_1 = function (index) {
                var p = nonExtraPackages[index];
                if (!nonExtraModules.some(function (module) {
                    return module.PackageId === p.PackageId && module.IsEnabled && module.ModulePrice > 0;
                })) {
                    return "continue";
                }
                var packageSelectedModulesPrice = this_1.getPackageSelectedModulesPrice(nonExtraPackages, nonExtraModules, index);
                if (packageSelectedModulesPrice >= p.Price) {
                    if (!p.Selected) {
                        selectPackageId = p.PackageId;
                        this_1.showAutoSelectPackageHint();
                    }
                    return "break";
                }
            };
            var this_1 = this;
            for (var index = 0; index <= nonExtraPackages.length - 1; index++) {
                var state_1 = _loop_1(index);
                if (state_1 === "break")
                    break;
            }
            if (selectPackageId && selectPackageId !== 0) {
                this.selectPackage(this.getPackage(selectPackageId));
            }
        }
        else {
            this.showAutoSelectPackageHint(false);
            var selectPackage = void 0;
            var _loop_2 = function (index) {
                var p = nonExtraPackages[index];
                if (nonExtraModules
                    .filter(function (m) { return m.PackageId <= p.PackageId; })
                    .every(function (m) { return m.IsEnabled; })) {
                    selectPackage = p;
                    return "break";
                }
            };
            for (var index = 0; index <= nonExtraPackages.length - 1; index++) {
                var state_2 = _loop_2(index);
                if (state_2 === "break")
                    break;
            }
            var deselectPackage = nonExtraPackages.find(function (p) { return p.Selected; });
            deselectPackage === undefined ? (deselectPackage = undefined) : (deselectPackage.Selected = false);
            if (selectPackage !== null && selectPackage !== undefined) {
                selectPackage.Selected = true;
            }
        }
    };
    PriceComponent.prototype.getPackageSelectedModulesPrice = function (packages, nonExtraModules, index) {
        var packagePrice = 0;
        var modulePackage = index < packages.length ? packages[index] : undefined;
        if (modulePackage) {
            if (nonExtraModules
                .filter(function (fm) { return fm.PackageId <= modulePackage.PackageId; })
                .every(function (m) { return m.IsEnabled; })) {
                packagePrice += modulePackage.Price;
            }
            else {
                nonExtraModules
                    .filter(function (m) { return m.PackageId === modulePackage.PackageId && m.IsEnabled; })
                    .forEach(function (m) {
                    packagePrice += m.ModulePrice;
                });
                packagePrice += this.getPackageSelectedModulesPrice(packages, nonExtraModules, index + 1);
            }
        }
        return packagePrice;
    };
    PriceComponent.prototype.showAutoSelectPackageHint = function (active) {
        if (active === void 0) { active = true; }
        this.AutoSelectPackageActive = active;
        ////if (active) {
        ////    setTimeout(() => { this.AutoSelectPackageActive = false; }, 7000);
        ////}
    };
    PriceComponent.prototype.onModuleChange = function (moduleId) {
        var module = this.modules.find(function (m) { return m.ModuleId === moduleId; });
        if (module.InfoOnly === true || module.FreeUntilDate !== null) {
            return;
        }
        module.IsEnabled = !module.IsEnabled;
        var parentPackage = this.packages.find(function (p) { return p.PackageId === module.PackageId; });
        if (parentPackage) {
            this.automaticReselectOfPackages(module.IsEnabled);
        }
        this.updateTotal();
    };
    Object.defineProperty(PriceComponent.prototype, "tooltipPosition", {
        get: function () {
            return this.windowRef && this.windowRef.nativeWindow && this.windowRef.nativeWindow.innerWidth < 790
                ? 'left'
                : 'top';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PriceComponent.prototype, "tooltipWidth", {
        get: function () {
            var width = this.windowRef && this.windowRef.nativeWindow ? this.windowRef.nativeWindow.innerWidth : 800;
            var result = width > 790 ? 500 : 500 - (790 - width) - 55;
            return result > 150 ? result : 150;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PriceComponent.prototype, "selectedPackageId", {
        get: function () {
            var selectedPackage = this.packages.find(function (p) { return p.Selected; });
            return selectedPackage ? selectedPackage.PackageId : 0;
        },
        enumerable: true,
        configurable: true
    });
    PriceComponent.prototype.getPackage = function (packageId) {
        return this.packages.find(function (p) { return p.PackageId === packageId; }) || {};
    };
    PriceComponent.prototype.getModule = function (moduleId) {
        var module = this.modules.find(function (m) { return m.ModuleId === moduleId; }) || new ModuleCompanyView();
        return module;
    };
    PriceComponent.prototype.selectPackage = function (modulePackage) {
        var _this = this;
        modulePackage.Selected = true;
        this.packages
            .filter(function (p) { return p.PackageId !== modulePackage.PackageId && p.Selected; })
            .forEach(function (p) {
            _this.deSelectPackage(p);
        });
        var modulesToEnable = this.modules.filter(function (m) { return (m.PackageId ? m.PackageId : Infinity) <= modulePackage.PackageId; });
        modulesToEnable.forEach(function (m) { return (m.IsEnabled = true); });
    };
    PriceComponent.prototype.deSelectPackage = function (modulePackage) {
        modulePackage.Selected = false;
        this.modules
            .filter(function (m) {
            return m.FreeUntilDate === null && !m.InfoOnly && (m.PackageId ? m.PackageId : Infinity) <= modulePackage.PackageId;
        })
            .forEach(function (m) { return (m.IsEnabled = false); });
    };
    PriceComponent.prototype.updateTotal = function () {
        var _this = this;
        setTimeout(function () {
            _this.priceElements = _this.modules
                .filter(function (m) { return m.IsEnabled; })
                .map(function (m) { return m.ModuleName; });
            _this.totalCost = _this.calculateCost();
            var currentModules = _this.modules
                .filter(function (m) { return m.IsEnabled && !m.InfoOnly; })
                .map(function (val) { return val.ModuleId; })
                .sort();
            // console.log(JSON.stringify(this.initialModules));
            // console.log(JSON.stringify(currentModules));
            var modulesIncludedInPerPayslipCalc = _this.modules.filter(function (m) { return m.IsEnabled && m.ModuleBillingPrincipleId === 1 && !m.InfoOnly && !m.FreeUntilDate; });
            var prices = modulesIncludedInPerPayslipCalc.map(function (m) {
                if (_this.selectedPackageId !== 0) {
                    if (m.PackageId !== 0 && m.PackageId !== null && m.PackageId <= _this.selectedPackageId) {
                        return 0;
                    }
                    else {
                        return m.ModulePrice;
                    }
                }
                else {
                    return m.ModulePrice;
                }
            });
            var currentPackagePrice = _this.getPackage(_this.selectedPackageId).Price;
            if (currentPackagePrice !== undefined) {
                prices.push(currentPackagePrice);
            }
            _this.calculatedPricePerPayslip = prices.reduce(function (a, b) { return a + b; }, 0);
            if (JSON.stringify(_this.initialModules) !== JSON.stringify(currentModules)) {
                _this.submitEnabled = true;
            }
            else {
                _this.submitEnabled = false;
            }
        });
    };
    PriceComponent.prototype.calculateCost = function () {
        var _this = this;
        var total = 0;
        if (this.packages) {
            var selectedPackage = this.packages.find(function (p) { return p.Selected; });
            var selectedPackageId_1 = 0;
            if (selectedPackage) {
                selectedPackageId_1 = selectedPackage.PackageId;
                total = this.packagePrice(selectedPackage);
            }
            var otherModules = this.modules.filter(function (m) {
                return m.FreeUntilDate === null && m.IsEnabled && (m.PackageId ? m.PackageId : Infinity) > selectedPackageId_1;
            });
            if (otherModules) {
                otherModules.forEach(function (m) { return (total += _this.modulePrice(m)); });
            }
            total = Math.round(total * 100) / 100;
        }
        return total;
    };
    PriceComponent.prototype.formatPrice = function (priceValue) {
        if (priceValue === 0) {
            return 'Free';
        }
        else if (priceValue % 1 === 0) {
            return priceValue.toString() + ',-';
        }
        else if (priceValue === undefined) {
            return 'undefined';
        }
        else {
            return priceValue.toLocaleString('da-DK') + '0';
        }
    };
    PriceComponent.prototype.packagePrice = function (modulePackage) {
        return this.calculatePriceByBillingPrinciple(modulePackage.BillingPrincipleId, modulePackage.Price);
    };
    PriceComponent.prototype.modulePrice = function (module) {
        return this.calculatePriceByBillingPrinciple(module.ModuleBillingPrincipleId, module.ModulePrice);
    };
    PriceComponent.prototype.calculatePriceByBillingPrinciple = function (billingPrincipleId, price) {
        var totalUsers = 0 + this.users14Day + this.usersMonthly + this.usersWeekly;
        switch (billingPrincipleId) {
            case 1: // Per payslip
                return price * (this.usersMonthly + this.users14Day * 2.17 + this.usersWeekly * 4.33);
            case 2: // Per salary batch
                //// TODO: Ask Daniel
                return 0;
            case 3: // Per month
                return price;
            case 4: // Per user per month
                return price * totalUsers;
            case 5: // Per unit
                //// TODO: Ask Daniel
                return 0;
        }
        return 0;
    };
    PriceComponent.prototype.buildPackages = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, _a, module, packageId, selectedPackage;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                this.packages = [];
                this.modules
                    .filter(function (m) { return m.InfoOnly || m.FreeUntilDate !== null; })
                    .forEach(function (m) { return (m.IsEnabled = true); });
                for (_i = 0, _a = this.modules; _i < _a.length; _i++) {
                    module = _a[_i];
                    packageId = module.PackageId || 9999;
                    if (!this.packages[packageId]) {
                        this.packages[packageId] = {
                            PackageId: packageId,
                            Selected: false,
                            Name: module.PackageName || 'Ekstra moduler',
                            Description: module.PackageDescription || '',
                            Price: module.PackagePrice || 0,
                            BillingPrinciple: module.PackageBillingPrinciple || '',
                            BillingPrincipleId: module.PackageBillingPrincipleId || 4,
                            Modules: []
                        };
                    }
                    this.packages[packageId].Modules.push(module);
                }
                this.packages = this.packages.filter(function (p) { return p.PackageId; });
                selectedPackage = this.packages.find(function (p) { return p.PackageId === _this.modules[0].CompanyCurrentPackageLevel; });
                if (selectedPackage) {
                    this.selectPackage(selectedPackage);
                }
                this.updateTotal();
                this.initialModules = this.modules
                    .filter(function (m) { return m.IsEnabled && !m.InfoOnly; })
                    .map(function (val) {
                    return val.ModuleId;
                })
                    .sort();
                return [2 /*return*/];
            });
        });
    };
    PriceComponent.prototype.isModuleLocked = function (moduleId) {
        return this.getModule(moduleId).InfoOnly || this.getModule(moduleId).FreeUntilDate !== null;
    };
    PriceComponent.prototype.isModulePriceStrikethrough = function (moduleId) {
        var module = this.getModule(moduleId);
        var minSelectedPackageId = module.PackageId;
        if (module.ModulePrice === 0) {
            return false;
        }
        else {
            if (module.FreeUntilDate !== null) {
                return true;
            }
            while (minSelectedPackageId <= 3) {
                if (this.getPackage(minSelectedPackageId).Selected && module.PackageId !== null) {
                    return true;
                }
                minSelectedPackageId++;
            }
            return false;
        }
    };
    PriceComponent.prototype.onClick = function (event) {
        this.isHideToolTip = !this.isHideToolTip;
        event.stopPropagation();
    };
    PriceComponent.prototype.moduleTooltipHtml = function (link, des) {
        if (link && des) {
            if (this.sessionService.browser.iOSMobileDevice && this.sessionService.browser.isHybridApp) {
                return '<a class="tooltipStyle">' + des + '</a>';
            }
            return '<a class="tooltipStyle" href="' + link + '" target="_blank">' + des + '</a>';
        }
        return '';
    };
    return PriceComponent;
}());
export { PriceComponent };
