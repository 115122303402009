/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../../CustomControls/TextEditUpdate.component.ngfactory";
import * as i3 from "../../../../CustomControls/TextEditUpdate.component";
import * as i4 from "../../../../Services/SharedServices/Session/SessionService";
import * as i5 from "./SalaryBatchPayslipIcons.component.ngfactory";
import * as i6 from "./SalaryBatchPayslipIcons.component";
import * as i7 from "../../../../CustomControls/Grid/Grid.componnent.ngfactory";
import * as i8 from "../../../../CustomControls/Grid/Grid.componnent";
import * as i9 from "@angular/forms";
import * as i10 from "../../../../CustomControls/Grid/GridColumn.component.ngfactory";
import * as i11 from "../../../../CustomControls/Grid/GridColumn.component";
import * as i12 from "./SalaryBatchPayslipDetails.component.ngfactory";
import * as i13 from "./SalaryBatchPayslipDetails.component";
import * as i14 from "../../../../CustomControls/ComboboxEdit.component.ngfactory";
import * as i15 from "../../../../CustomControls/ComboboxEdit.component";
import * as i16 from "../../../../CustomControls/Dialog/Dialog.component.ngfactory";
import * as i17 from "../../../../CustomControls/Dialog/Dialog.component";
import * as i18 from "@angular/platform-browser";
import * as i19 from "../../../../CustomControls/Dialog/DialogAction.component.ngfactory";
import * as i20 from "../../../../CustomControls/Dialog/DialogAction.component";
import * as i21 from "@angular/common";
import * as i22 from "./SalaryBatchPayslips.component";
import * as i23 from "../../../../Services/RxDataService";
import * as i24 from "../../../../Services/SharedServices/DownloadService";
import * as i25 from "../../../../Services/SharedServices/SettingService";
var styles_SalaryBatchPayslipsComponent = [];
var RenderType_SalaryBatchPayslipsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SalaryBatchPayslipsComponent, data: {} });
export { RenderType_SalaryBatchPayslipsComponent as RenderType_SalaryBatchPayslipsComponent };
function View_SalaryBatchPayslipsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "Card-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "Card-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("CompanySalaryBatches.Payslips")); _ck(_v, 2, 0, currVal_0); }); }
function View_SalaryBatchPayslipsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "Cards Cards--flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "Card Form--stacked Company-payslipStacked"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "Card-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "app-text-edit", [["class", "FormEmlement Company-totalsPayslipsSearch"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.searchText = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TextEditUpdateComponent_0, i2.RenderType_TextEditUpdateComponent)), i0.ɵdid(4, 114688, null, 0, i3.TextEditUpdateComponent, [i4.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], label: [2, "label"], placeholder: [3, "placeholder"], icon: [4, "icon"], readonlyOnNotFocus: [5, "readonlyOnNotFocus"] }, { valueChange: "valueChange" }), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "Card Form--stacked Company-payslipStacked"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "Card-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "salary-batch-payslip-icons", [], null, [[null, "downloadPayslipClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("downloadPayslipClick" === en)) {
        var pd_0 = (_co.downloadSalaryStatement($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_SalaryBatchPayslipIconsComponent_0, i5.RenderType_SalaryBatchPayslipIconsComponent)), i0.ɵdid(10, 49152, null, 0, i6.SalaryBatchPayslipIconsComponent, [i4.SessionService], { selectedSalaryStatement: [0, "selectedSalaryStatement"], IsFinalizedBatch: [1, "IsFinalizedBatch"] }, { downloadPayslipClick: "downloadPayslipClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchText; var currVal_1 = true; var currVal_2 = i0.ɵunv(_v, 4, 2, i0.ɵnov(_v, 5).transform("CompanySalaryBatches.Search")); var currVal_3 = i0.ɵunv(_v, 4, 3, i0.ɵnov(_v, 6).transform("CompanySalaryBatches.Search")); var currVal_4 = "Search"; var currVal_5 = true; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.selectedSalaryStatement; var currVal_7 = _co.IsFinalizedBatch; _ck(_v, 10, 0, currVal_6, currVal_7); }, null); }
function View_SalaryBatchPayslipsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "Cards Cards--flex hintPayrollBatches"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "Card-inner"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("CompanySalaryBatches.HintDoubleClickViewEmployeeData")); _ck(_v, 2, 0, currVal_0); }); }
function View_SalaryBatchPayslipsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "div", [["class", "Cards Cards--flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "div", [["class", "Card Form--stacked"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 13, "div", [["class", "Card-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 12, "div", [["class", "FormElement"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 11, "grid", [["class", "FormElement-grid Company-payslipsGrid"]], null, [[null, "selectedItemChange"], [null, "dblClick"], [null, "filterChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedItemChange" === en)) {
        var pd_0 = ((_co.selectedSalaryStatement = $event) !== false);
        ad = (pd_0 && ad);
    } if (("dblClick" === en)) {
        var pd_1 = (_co.onDoubleClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("filterChange" === en)) {
        var pd_2 = (_co.onFilterChange($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_CustomGridComponent_0, i7.RenderType_CustomGridComponent)), i0.ɵdid(5, 4243456, null, 2, i8.CustomGridComponent, [i9.FormBuilder, i0.ChangeDetectorRef, i4.SessionService], { editMode: [0, "editMode"], idColumn: [1, "idColumn"], filterable: [2, "filterable"], filter: [3, "filter"], selectedItem: [4, "selectedItem"], data: [5, "data"] }, { selectedItemChange: "selectedItemChange", filterChange: "filterChange", dblClick: "dblClick" }), i0.ɵqud(603979776, 1, { columns: 1 }), i0.ɵqud(603979776, 2, { actions: 1 }), (_l()(), i0.ɵeld(8, 0, null, null, 3, "gridColumn", [], null, null, null, i10.View_GridColumnComponent_0, i10.RenderType_GridColumnComponent)), i0.ɵdid(9, 49152, [[3, 4], [1, 4]], 1, i11.GridColumnComponent, [], { title: [0, "title"], field: [1, "field"], width: [2, "width"], type: [3, "type"], isFilterable: [4, "isFilterable"] }, null), i0.ɵqud(603979776, 3, { columns: 1 }), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(12, 0, null, null, 3, "gridColumn", [], null, null, null, i10.View_GridColumnComponent_0, i10.RenderType_GridColumnComponent)), i0.ɵdid(13, 49152, [[4, 4], [1, 4]], 1, i11.GridColumnComponent, [], { title: [0, "title"], field: [1, "field"], width: [2, "width"], type: [3, "type"], isFilterable: [4, "isFilterable"] }, null), i0.ɵqud(603979776, 4, { columns: 1 }), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(16, 0, null, null, 3, "div", [["class", "Card Form--stacked"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 2, "div", [["class", "Card-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "salary-batch-payslip-details", [], null, [[null, "revertBatchCompleted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("revertBatchCompleted" === en)) {
        var pd_0 = (_co.revertBatchCompleted.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_SalaryBatchPayslipDetailsComponent_0, i12.RenderType_SalaryBatchPayslipDetailsComponent)), i0.ɵdid(19, 49152, null, 0, i13.SalaryBatchPayslipDetailsComponent, [], { selectedSalaryStatement: [0, "selectedSalaryStatement"], salaryStatementDetails: [1, "salaryStatementDetails"], IsFinalizedBatch: [2, "IsFinalizedBatch"] }, { revertBatchCompleted: "revertBatchCompleted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = "Id"; var currVal_2 = "menu"; var currVal_3 = _co.filter; var currVal_4 = _co.selectedSalaryStatement; var currVal_5 = _co.gridData; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 11).transform("EmployeePayslip.Employee")); var currVal_7 = "UserFullName"; var currVal_8 = 200; var currVal_9 = "text"; var currVal_10 = true; _ck(_v, 9, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = i0.ɵunv(_v, 13, 0, i0.ɵnov(_v, 15).transform("EmployeePayslip.PaidOut")); var currVal_12 = "PayoutAmount"; var currVal_13 = 100; var currVal_14 = "numeric"; var currVal_15 = true; _ck(_v, 13, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_16 = _co.selectedSalaryStatement; var currVal_17 = _co.salaryStatementDetails; var currVal_18 = _co.IsFinalizedBatch; _ck(_v, 19, 0, currVal_16, currVal_17, currVal_18); }, null); }
function View_SalaryBatchPayslipsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "Cards Cards--flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "Card Form--stacked"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "Card-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "combobox-edit-control", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.selectedSalaryStatementId = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_ComboboxEditComponent_0, i14.RenderType_ComboboxEditComponent)), i0.ɵdid(4, 4440064, null, 0, i15.ComboboxEditComponent, [i4.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], idField: [2, "idField"], textField: [3, "textField"], comboboxDataSource: [4, "comboboxDataSource"], disable: [5, "disable"] }, { valueChange: "valueChange" }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "salary-batch-payslip-icons", [], null, [[null, "downloadPayslipClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("downloadPayslipClick" === en)) {
        var pd_0 = (_co.downloadSalaryStatement($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_SalaryBatchPayslipIconsComponent_0, i5.RenderType_SalaryBatchPayslipIconsComponent)), i0.ɵdid(6, 49152, null, 0, i6.SalaryBatchPayslipIconsComponent, [i4.SessionService], { selectedSalaryStatement: [0, "selectedSalaryStatement"], IsFinalizedBatch: [1, "IsFinalizedBatch"] }, { downloadPayslipClick: "downloadPayslipClick" }), (_l()(), i0.ɵeld(7, 0, null, null, 1, "salary-batch-payslip-details", [], null, [[null, "revertBatchCompleted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("revertBatchCompleted" === en)) {
        var pd_0 = (_co.revertBatchCompleted.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_SalaryBatchPayslipDetailsComponent_0, i12.RenderType_SalaryBatchPayslipDetailsComponent)), i0.ɵdid(8, 49152, null, 0, i13.SalaryBatchPayslipDetailsComponent, [], { selectedSalaryStatement: [0, "selectedSalaryStatement"], salaryStatementDetails: [1, "salaryStatementDetails"], IsFinalizedBatch: [2, "IsFinalizedBatch"] }, { revertBatchCompleted: "revertBatchCompleted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedSalaryStatementId; var currVal_1 = true; var currVal_2 = "Id"; var currVal_3 = "UserFullName"; var currVal_4 = _co.filteredSalaryStatements; var currVal_5 = (_co.filteredSalaryStatements && (_co.filteredSalaryStatements.length === 1)); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.selectedSalaryStatement; var currVal_7 = _co.IsFinalizedBatch; _ck(_v, 6, 0, currVal_6, currVal_7); var currVal_8 = _co.selectedSalaryStatement; var currVal_9 = _co.salaryStatementDetails; var currVal_10 = _co.IsFinalizedBatch; _ck(_v, 8, 0, currVal_8, currVal_9, currVal_10); }, null); }
function View_SalaryBatchPayslipsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-dialog", [], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.newTabBlockedDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_DialogComponent_0, i16.RenderType_DialogComponent)), i0.ɵdid(1, 49152, null, 1, i17.DialogComponent, [i18.DomSanitizer, i4.SessionService, i0.ElementRef], { leadingText: [0, "leadingText"], visible: [1, "visible"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 5, { buttonElements: 1 }), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i19.View_DialogActionComponent_0, i19.RenderType_DialogActionComponent)), i0.ɵdid(5, 180224, [[5, 4]], 0, i20.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 3).transform("Report.NewTabBlocked")); var currVal_1 = _co.newTabBlockedDialogVisible; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "Ok"; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_SalaryBatchPayslipsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipsComponent_1)), i0.ɵdid(1, 16384, null, 0, i21.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipsComponent_2)), i0.ɵdid(3, 16384, null, 0, i21.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipsComponent_3)), i0.ɵdid(5, 16384, null, 0, i21.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipsComponent_4)), i0.ɵdid(7, 16384, null, 0, i21.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipsComponent_5)), i0.ɵdid(9, 16384, null, 0, i21.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipsComponent_6)), i0.ɵdid(11, 16384, null, 0, i21.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 2, "app-dialog", [["class", "PayslipPopup"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.previewDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_DialogComponent_0, i16.RenderType_DialogComponent)), i0.ɵdid(13, 49152, null, 1, i17.DialogComponent, [i18.DomSanitizer, i4.SessionService, i0.ElementRef], { overlayClose: [0, "overlayClose"], isOverlayKeydown: [1, "isOverlayKeydown"], visible: [2, "visible"], iframeContent: [3, "iframeContent"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 6, { buttonElements: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.IsMobile; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.IsMobile; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.IsMobile; _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.IsMobile; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.IsMobile; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.IsWeb; _ck(_v, 11, 0, currVal_5); var currVal_6 = false; var currVal_7 = true; var currVal_8 = _co.previewDialogVisible; var currVal_9 = _co.previewContent; _ck(_v, 13, 0, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_SalaryBatchPayslipsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "salary-batch-payslips", [], null, null, null, View_SalaryBatchPayslipsComponent_0, RenderType_SalaryBatchPayslipsComponent)), i0.ɵdid(1, 180224, null, 0, i22.SalaryBatchPayslipsComponent, [i4.SessionService, i23.RxDataService, i24.DownloadService, i0.Renderer2, i25.SettingService], null, null)], null, null); }
var SalaryBatchPayslipsComponentNgFactory = i0.ɵccf("salary-batch-payslips", i22.SalaryBatchPayslipsComponent, View_SalaryBatchPayslipsComponent_Host_0, { selectedSalaryBatch: "selectedSalaryBatch", salaryStatements: "salaryStatements" }, { revertBatchCompleted: "revertBatchCompleted" }, []);
export { SalaryBatchPayslipsComponentNgFactory as SalaryBatchPayslipsComponentNgFactory };
