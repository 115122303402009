import { Component, Input, OnInit } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { Global } from '../../Common/Global';
import { ICompany, ICompanyUser } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'app-company-general',
  templateUrl: './CompanyGeneral.component.html'
})
export class CompanyGeneralComponent extends FormComponentBase implements OnInit {
  public onDoubleClick(): void {
    if (this.sessionService.role.IsFullAdmin) {
      this.editMode = true;
    }
  }

  private currentTriggerEditmodeChange = false;
  @Input()
  public get TriigerEditMode(): boolean {
    return this.currentTriggerEditmodeChange;
  }
  public set TriigerEditMode(value: boolean) {
    if (this.currentTriggerEditmodeChange !== value) {
      this.currentTriggerEditmodeChange = value;
    }
    if (!value && this.isDirty) {
      this.onSave(this.sessionService.nextState);
    }
  }

  public company: ICompany;
  public contacts: ICompanyUser[];
  public discardDialogVisible = false;
  public invalidDialogVisible = false;
  public isShowtestEmailDialog = false;
  public showInvalidFormWarning = false;
  public showSaveDataConfirmationChangeState = false;
  private navigationToStateName: string;
  public resetChildFrom = false;

  constructor(
    private dataService: RxDataService,
    private settingService: SettingService,
    private staticDataService: StaticDataService,
    public sessionService: SessionService,
    private transitionService: TransitionService
  ) {
    super();
    this.childIsValid = Array(3).fill(true);
    this.childIsDirty = Array(3).fill(false);
  }

  public ngOnInit(): void {
    this.getCompanyContacts();
    this.getCurrentCompany();
    this.company = Global.COMPANY;
    // if (!this.company) {
    //   this.getCurrentCompany();
    // }
    this.settingService.showModuleMessage('Company.General');
    this.sessionService.nextState = undefined;

    // this.transitionService.onStart({}, (trans: Transition): any => {
    //     let fromState: any = trans.from().name;
    //     let toState: any = this.navigationToStateName = trans.to().name;
    //     let generalState: string = "tabs.company.general";

    //     if (this.editMode && fromState && fromState === generalState) {
    //         if (this.isDirty) {
    //             // If validation fails, alert user and do nothing.
    //             // If validation passes, ask user to confirm before navigating
    //             if (!this.isValid) {
    //                 this.showInvalidFormWarning = true;
    //             } else {
    //                 this.showSaveDataConfirmationChangeState = true;
    //             }

    //             return false;
    //         } else {
    //             // Editmode but no changes, we close editmode without doing anything
    //             this.editMode = false;
    //             return true;
    //         }
    //     }
    // });
  }

  public onConfirmSaveDataOnChangeState(action: string): void {
    if (action === 'Continue') {
      this.onSave(this.sessionService.nextState);
    } else if (action === 'DiscardAndLeave') {
      this.discardDialogAction('ConfirmDiscard');
      if (this.navigationToStateName) {
        setTimeout(() => {
          this.sessionService.NavigateTo(this.navigationToStateName);
        });
      }
    }

    this.navigationToStateName = undefined;
  }

  public onMoreMenuOptionClick(menu: string): void {
    switch (menu) {
      case 'companyGeneral_importData':
        this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
        break;
      case 'companyGeneral_testMail':
        this.isShowtestEmailDialog = true;
        break;
      default:
        break;
    }
  }

  public onEditClick(): void {
    this.editMode = !this.editMode;
    // if (!this.editMode) {
    //     this.onSave(this.sessionService.nextState);
    // }
    // setTimeout(() => {
    //     if (this.editMode) {
    //         this.onSave();
    //         this.editMode = false;
    //     } else {
    //         this.editMode = true;
    //     }
    // });
  }

  public onSave(navigationState?: string): void {
    if (this.isValid && this.isDirty && this.company) {
      this.checkCompanyInformation(this.company);
      this.dataService.Companies_UpdateCurrent(this.company).subscribe(
        (responseCompany: ICompany): void => {
          this.editMode = false;
          this.company = responseCompany;
          Global.COMPANY = responseCompany;
          this.resetForm();
          if (navigationState) {
            setTimeout(() => {
              this.sessionService.NavigateTo(navigationState);
            });
          }
        },
        (error: any): void => {
          this.editMode = true;
          this.getCurrentCompany();
        }
      );
    } else if (this.isDirty && !this.isValid) {
      this.invalidDialogVisible = true;
      if (navigationState) {
        this.resetForm();
        this.sessionService.nextState = navigationState;
        // setTimeout(() => { this.sessionService.NavigateTo(navigationState); });
      } else {
        this.getCurrentCompany();
      }
    }
  }

  private checkCompanyInformation(company: ICompany) {
    if (company.StatisticsRecipientId === undefined) {
      this.company.StatisticsRecipientId = null;
    }
  }

  public ChangeState(): void {
    if (this.sessionService.nextState) {
      this.sessionService.NavigateTo(this.sessionService.nextState);
    }
  }

  public confirmDiscardChanges(): void {
    if (this.isDirty) {
      this.discardDialogVisible = true;
    } else {
      this.editMode = false;
    }
  }

  public discardDialogAction(action: string): void {
    if (action === 'ConfirmDiscard') {
      this.isDirty = false;
      this.editMode = false;
      this.getCurrentCompany();
    }
  }

  private getCompanyContacts(): void {
    this.staticDataService.companyUsers
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((companyUsers: ICompanyUser[]): void => {
        this.contacts = companyUsers;
      });
  }

  private getCurrentCompany(): void {
    this.dataService.Companies_GetCurrent().subscribe((company: ICompany): void => {
      this.company = company;
      this.resetForm();
      Global.COMPANY = company;
    });
  }

  private resetForm(): void {
    this.childIsDirty.fill(false);
    this.isDirty = false;
    this.resetChildFrom = true;
  }
}
