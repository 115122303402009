import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { Global } from '../../Common/Global';
import { RxDataService } from '../../Services/RxDataService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { EmploymentStatisticsModel } from './EmploymentStatisticsModel';
var StatisticsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(StatisticsComponent, _super);
    function StatisticsComponent(dataService, staticData) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.staticData = staticData;
        _this.statisticsValue = {};
        _this.jobFunctionCodeStrings = [];
        _this.childIsValid = Array(4).fill(true);
        _this.childIsDirty = Array(10).fill(false);
        return _this;
    }
    Object.defineProperty(StatisticsComponent.prototype, "statistics", {
        get: function () {
            return this.statisticsValue;
        },
        set: function (value) {
            this.statisticsValue = value || {};
        },
        enumerable: true,
        configurable: true
    });
    StatisticsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticData.StatisticsEmploymentPrinciple.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.statisticsEmploymentPrinciple = data;
        });
        this.staticData.StatisticsEmploymentTerms.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.statisticsEmploymentTerms = data;
        });
        this.dataService.Miscellaneous_GetStatisticsDiscoCodes().subscribe(function (data) {
            _this.jobFunctionCodes = data;
            _this.jobFunctionCodeStrings = _this.prepareDataForSearchJobFunctionCodes(_this.jobFunctionCodes);
        });
        this.staticData.StatisticsJobStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.statisticsJobStatus = data;
        });
        this.staticData.StatisticsSalaryPrinciple.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.salaryPrinciples = data;
        });
    };
    StatisticsComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(StatisticsComponent.prototype, "IsEmployeeEmploymentStatistics", {
        get: function () {
            return this.statistics && this.statistics.HireDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StatisticsComponent.prototype, "JobFunctionName", {
        get: function () {
            var _this = this;
            if (!this.statistics ||
                !this.statistics.StatisticsJobFunctionCode ||
                !this.jobFunctionCodes ||
                this.jobFunctionCodes.length === 0) {
                return '';
            }
            var code = this.jobFunctionCodes.find(function (c) { return c.Code.toString() === _this.statistics.StatisticsJobFunctionCode.toString(); });
            return code ? code.Name : '';
        },
        enumerable: true,
        configurable: true
    });
    StatisticsComponent.prototype.onJobFunctionCodeChanged = function () {
        this.setJobFunctionCode(this.statistics.StatisticsJobFunctionCode);
        this.onChange();
    };
    StatisticsComponent.prototype.prepareDataForSearchJobFunctionCodes = function (jobFunctionCodes) {
        var _this = this;
        var codes = jobFunctionCodes.map(function (item) { return _this.formatJobFunctionCodes(item); });
        return codes;
    };
    StatisticsComponent.prototype.formatJobFunctionCodes = function (jobFunctionCode) {
        return jobFunctionCode.Code.toString() + ' - ' + jobFunctionCode.Name;
    };
    StatisticsComponent.prototype.setJobFunctionCode = function (jobFunctionCodeKey) {
        var jobFunctionCode = this.getJobFunctionCode(jobFunctionCodeKey);
        if (!jobFunctionCode) {
            this.statistics.StatisticsJobFunctionCode = undefined;
            return;
        }
        this.statistics.StatisticsJobFunctionCode = jobFunctionCode.Code;
    };
    StatisticsComponent.prototype.getJobFunctionCode = function (code) {
        var _this = this;
        if (!code) {
            return undefined;
        }
        var jobFunctionCode = this.jobFunctionCodes
            ? this.jobFunctionCodes.find(function (item) {
                var formattedJobFunctionCode = _this.formatJobFunctionCodes(item);
                return formattedJobFunctionCode === code;
            })
            : undefined;
        if (jobFunctionCode) {
            return jobFunctionCode;
        }
        var jobFunctionCodes = this.jobFunctionCodes
            ? this.jobFunctionCodes.find(function (item) {
                var formattedJobFunctionCode = _this.formatJobFunctionCodes(item);
                return formattedJobFunctionCode.indexOf(code) >= 0;
            })
            : undefined;
        return jobFunctionCodes;
    };
    Object.defineProperty(StatisticsComponent.prototype, "isShow", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.StatisticsRecipientId && Global.COMPANY.StatisticsRecipientId === 7;
        },
        enumerable: true,
        configurable: true
    });
    return StatisticsComponent;
}(FormComponentBase));
export { StatisticsComponent };
