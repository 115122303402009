/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./FileUpload.component.ngfactory";
import * as i3 from "./FileUpload.component";
import * as i4 from "../Services/SharedServices/StaticData.service";
import * as i5 from "./Dialog/Dialog.component.ngfactory";
import * as i6 from "./Dialog/Dialog.component";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../Services/SharedServices/Session/SessionService";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./Dialog/DialogAction.component.ngfactory";
import * as i11 from "./Dialog/DialogAction.component";
import * as i12 from "./PictureEdit.component";
var styles_PictureEditComponent = [];
var RenderType_PictureEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PictureEditComponent, data: {} });
export { RenderType_PictureEditComponent as RenderType_PictureEditComponent };
function View_PictureEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "u-maxHeight75"], ["id", "imgUpdatedCompanyLogo"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("data:image/jpeg;base64," + _co.imageSource); _ck(_v, 0, 0, currVal_0); }); }
function View_PictureEditComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "u-maxHeight75"], ["id", "imgUpdatedCompanyLogoEditMode"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("data:image/jpeg;base64," + _co.imageSource); _ck(_v, 0, 0, currVal_0); }); }
function View_PictureEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "button", [["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onChangePictureButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "u-DefaultLogo"], ["id", "imgCompanyLogoEditMode"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PictureEditComponent_4)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "Avatar-overlay"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.imageSource && (_co.imageSource.length > 0)); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageSource; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.changeText; _ck(_v, 6, 0, currVal_2); }); }
function View_PictureEditComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "u-maxHeight75"], ["id", "imgUpdatedCompanyLogoEditMode"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("data:image/jpeg;base64," + _co.imageSource); _ck(_v, 0, 0, currVal_0); }); }
function View_PictureEditComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "button", [["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onTakePhotoButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "u-DefaultLogo"], ["id", "imgCompanyLogoEditMode"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PictureEditComponent_6)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "Avatar-overlay"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.imageSource && (_co.imageSource.length > 0)); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageSource; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.takePhotoText; _ck(_v, 6, 0, currVal_2); }); }
function View_PictureEditComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "FormElement-help"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hint; _ck(_v, 1, 0, currVal_0); }); }
function View_PictureEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "FormElement"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "FormElement-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ":"])), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "FormElement-control"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PictureEditComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PictureEditComponent_3)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PictureEditComponent_5)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PictureEditComponent_7)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.imageSource && (_co.imageSource.length > 0)); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.editMode; _ck(_v, 8, 0, currVal_3); var currVal_4 = ((_co.editMode && _co.isHybridApp) && _co.isAndroidDevice); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.editMode; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.editMode; _ck(_v, 4, 0, currVal_1); }); }
function View_PictureEditComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "Avatar-bg"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { "background-image": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (("url(data:image/jpeg;base64," + _co.imageSource) + ")")); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PictureEditComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "u-DefaultLogo"], ["id", "imgCompanyLogoEditMode"]], [[8, "hidden", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageSource; _ck(_v, 0, 0, currVal_0); }); }
function View_PictureEditComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn-avatar"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onTakePhotoButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.takePhotoText; _ck(_v, 2, 0, currVal_0); }); }
function View_PictureEditComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "Avatar-bg Avatar-relative Avatar-overlay"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { "background-image": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "Avatar-overlay Avatar-overlayEdit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "button", [["class", "btn-avatar"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onChangePictureButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PictureEditComponent_12)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (("url(data:image/jpeg;base64," + _co.imageSource) + ")")); _ck(_v, 2, 0, currVal_0); var currVal_2 = (_co.isHybridApp && _co.isAndroidDevice); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.changeText; _ck(_v, 7, 0, currVal_1); }); }
function View_PictureEditComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "Avatar-container"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "change-Avatar": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PictureEditComponent_9)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PictureEditComponent_10)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PictureEditComponent_11)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Avatar-container"; var currVal_1 = _ck(_v, 3, 0, _co.isEmployeeAvatar); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = (_co.imageSource && (_co.imageSource.length > 0)); _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.editMode; _ck(_v, 8, 0, currVal_4); var currVal_5 = _co.editMode; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.editMode; _ck(_v, 4, 0, currVal_2); }); }
export function View_PictureEditComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { fileUpload: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-file-upload", [], null, [[null, "fileSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fileSelected" === en)) {
        var pd_0 = (_co.onFileSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FileUploadComponent_0, i2.RenderType_FileUploadComponent)), i0.ɵdid(2, 49152, [[1, 4], ["fileupload", 4]], 0, i3.FileUploadComponent, [i4.StaticDataService], { accept: [0, "accept"] }, { fileSelected: "fileSelected" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PictureEditComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PictureEditComponent_8)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 5, "app-dialog", [], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.errorDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_DialogComponent_0, i5.RenderType_DialogComponent)), i0.ɵdid(8, 49152, null, 1, i6.DialogComponent, [i7.DomSanitizer, i8.SessionService, i0.ElementRef], { leadingText: [0, "leadingText"], visible: [1, "visible"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 2, { buttonElements: 1 }), i0.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(11, 0, null, 0, 1, "app-dialog-action", [["type", "Ok"]], null, null, null, i10.View_DialogActionComponent_0, i10.RenderType_DialogActionComponent)), i0.ɵdid(12, 180224, [[2, 4]], 0, i11.DialogActionComponent, [i8.SessionService], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "image/*"; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isAvatar; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isAvatar; _ck(_v, 6, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 10).transform("CompanyGeneral.FileSizeErrorMessage")); var currVal_4 = _co.errorDialogVisible; _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = "Ok"; _ck(_v, 12, 0, currVal_5); }, null); }
export function View_PictureEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-picture-edit", [], null, null, null, View_PictureEditComponent_0, RenderType_PictureEditComponent)), i0.ɵdid(1, 49152, null, 0, i12.PictureEditComponent, [i8.SessionService, i4.StaticDataService], null, null)], null, null); }
var PictureEditComponentNgFactory = i0.ɵccf("app-picture-edit", i12.PictureEditComponent, View_PictureEditComponent_Host_0, { editMode: "editMode", label: "label", hint: "hint", changeText: "changeText", takePhotoText: "takePhotoText", isAvatar: "isAvatar", isEmployeeAvatar: "isEmployeeAvatar", avatarChange: "avatarChange", imageSource: "imageSource" }, { change: "change", imageSourceChange: "imageSourceChange" }, []);
export { PictureEditComponentNgFactory as PictureEditComponentNgFactory };
