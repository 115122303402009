<div *ngIf="!editMode" class="FormElement FormElement-ViewMode">
  <span class="FormElement-label">{{ 'Employee.CPR no' | translate }}:</span>
  <div class="FormElement-control" *ngIf="!dummyIdentityNumber">
    <span class="FormElement-static"> {{ value | CPRFormat }} </span>
  </div>
  <div class="FormElement-feedback" *ngIf="dummyIdentityNumber">{{ 'Employee.Missing' | translate }}</div>
</div>

<app-text-edit
  *ngIf="editMode"
  [label]="'Employee.CPR no' | translate"
  [editMode]="editMode"
  [type]="'text'"
  [required]="true"
  [requiredMessageKey]="'NewEmployee.CPRErrorMessage'"
  [(isValid)]="isValid"
  [(value)]="value"
></app-text-edit>
