import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { Global } from '../../../Common/Global';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
var CompanyOptionComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyOptionComponent, _super);
    function CompanyOptionComponent(dataService, sessionService, staticDataService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.resetChildFromChange = new EventEmitter();
        _this.hasStatisticModule = false;
        _this.hasLanguageModule = false;
        _this.hasPaymentModule = false;
        _this.childIsDirty = Array(7).fill(false);
        return _this;
    }
    Object.defineProperty(CompanyOptionComponent.prototype, "resetChildFrom", {
        set: function (value) {
            if (value) {
                this.childIsDirty.fill(false);
                this.childIsValid.fill(true);
            }
            this.resetChildFromChange.emit(false);
        },
        enumerable: true,
        configurable: true
    });
    CompanyOptionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.PaymentMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.paymentMethods = data); });
        this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.languages = data;
        });
        this.staticDataService.StatisticsRecipient.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (statisticOptions) {
            _this.statisticOptions = statisticOptions;
        });
        this.staticDataService.Bank.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (bankOption) {
            if (bankOption && bankOption.length > 0) {
                _this.bankOption = bankOption.filter(function (model) { return model.CountryId === _this.company.CountryId; });
            }
        });
    };
    Object.defineProperty(CompanyOptionComponent.prototype, "isAdminUser", {
        get: function () {
            return this.sessionService.role.IsFullAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyOptionComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyOptionComponent.prototype, "isAccountAdmin", {
        get: function () {
            return Global.SESSION && Global.SESSION.IsAccountAdmin;
        },
        enumerable: true,
        configurable: true
    });
    CompanyOptionComponent.prototype.onAdditionalOptionClick = function () {
        this.sessionService.NavigateTo('tabs.company.configuration.settings');
    };
    return CompanyOptionComponent;
}(FormComponentBase));
export { CompanyOptionComponent };
