<div class="Island Cards">
  <div class="Card">
    <div
      style="padding-bottom: 10px; padding-top: 20px;"
      [innerHTML]="'CompanyDataImport.BriefIntroduction1' | translate"
    ></div>
    <div style="padding-bottom: 10px;" [innerHTML]="'CompanyDataImport.BriefIntroduction2' | translate"></div>
    <div style="padding-bottom: 10px;" [innerHTML]="'CompanyDataImport.BriefIntroduction3' | translate"></div>
    <div style="padding-bottom: 10px;" [innerHTML]="'CompanyDataImport.BriefIntroduction4' | translate"></div>

    <fieldset class="Form--horizontal">
      <legend class="Card-title">{{ 'CompanyDataImport.ImportType?' | translate }}</legend>
      <app-radio-edit
        (valueChange)="onCHeckBoxImportTypeChange()"
        [editMode]="true"
        [vertical]="true"
        [(value)]="importType"
      >
        <app-radio-edit-option
          [value]="1"
          [label]="'CompanyDataImport.Employees' | translate"
          [addonLink]="'http://support.gratisal.com/hc/da/articles/115003261669'"
        ></app-radio-edit-option>
        <app-radio-edit-option
          [value]="2"
          [label]="'CompanyDataImport.Initial balances' | translate"
          [addonLink]="'http://support.gratisal.com/hc/da/articles/115003280345'"
        ></app-radio-edit-option>
        <app-radio-edit-option
          [value]="3"
          [label]="'CompanyDataImport.PayrollData' | translate"
          [addonLink]="'http://support.gratisal.com/hc/da/articles/115003178565'"
        ></app-radio-edit-option>
        <app-radio-edit-option
          [moduleCheckWidth]="700"
          [isCheckModule]="true"
          [ModuleId]="8"
          [value]="5"
          [label]="'CompanyDataImport.TimeEntries' | translate"
          [addonLink]="'https://support.gratisal.com/hc/da/articles/360015525831-Import-af-Tidsdata-fra-Excel'"
        ></app-radio-edit-option>
      </app-radio-edit>
    </fieldset>
    <fieldset class="Form--horizontal">
      <legend class="Card-title">{{ 'CompanyDataImport.ActionType?' | translate }}</legend>
      <app-radio-edit
        [editMode]="true"
        [vertical]="true"
        [(value)]="actionType"
        (valueChange)="importFromExternalSourceValue = 1"
      >
        <app-radio-edit-option
          [value]="1"
          [label]="'CompanyDataImport.Get template' | translate"
        ></app-radio-edit-option>
        <app-radio-edit-option
          *ngIf="isShowExportExistingData"
          [value]="5"
          [label]="'CompanyDataImport.ExportExistingData' | translate"
        ></app-radio-edit-option>
        <app-radio-edit-option
          [value]="4"
          [label]="'CompanyDataImport.ConverFromOtherSystem' | translate"
        ></app-radio-edit-option>
        <app-radio-edit-option
          [value]="3"
          [label]="'CompanyDataImport.CustomImport' | translate"
        ></app-radio-edit-option>
        <app-radio-edit-option
          [value]="2"
          [label]="'CompanyDataImport.Upload completed file' | translate"
        ></app-radio-edit-option>
      </app-radio-edit>

      <combobox-edit-control
        *ngIf="payrollOrTimeTemplateIdTypesVisible"
        [editMode]="true"
        [textField]="'Name'"
        [idField]="'Id'"
        [comboboxDataSource]="payrollOrTimeEntriesDataIdTypesDatasource"
        [label]="'Id type'"
        [(value)]="payrollOrTimeEntriesDataIdType"
      >
      </combobox-edit-control>

      <combobox-edit-control
        *ngIf="!isPayrollDataImport && convertFromOtherSystemFieldsVisible"
        [editMode]="true"
        [textField]="'Name'"
        [idField]="'Id'"
        [comboboxDataSource]="importType === 1 ? importFromExternalSourceEmployee : importFromExternalSource"
        [label]="'External system'"
        [(value)]="importFromExternalSourceValue"
      >
      </combobox-edit-control>

      <combobox-edit-control
        *ngIf="isPayrollDataImport && convertFromOtherSystemFieldsVisible"
        [editMode]="true"
        [textField]="'Name'"
        [idField]="'Id'"
        [comboboxDataSource]="importType === 3 ? importFromExternalSourcePayrollData : importPayrollFromExternalSource"
        [label]="'External system'"
        [(value)]="importPayrollFromExternalSourceValue"
      >
      </combobox-edit-control>
    </fieldset>

    <div class="CompanyDataImportViewButtonSection">
      <button class="Button--primary" *ngIf="showDownLoadButton" type="button" (click)="onDownLoad()">
        {{ 'CompanyDataImport.Download' | translate }}
      </button>
      <button class="Button--primary" *ngIf="showUploadButton" type="button" (click)="onUpload()">
        {{ 'CompanyDataImport.Upload' | translate }}
      </button>
      <button class="Button--primary" *ngIf="showCreateCustomFile" type="button" (click)="onCreate()">
        {{ 'CompanyDataImport.Create' | translate }}
      </button>
    </div>

    <app-file-upload #fileupload (fileSelected)="onFileSelected($event)" [accept]="acceptFileType"></app-file-upload>
  </div>
  <div class="Actions">
    <help-button module="company" currentTab="tabs.company.configuration.dataimport"></help-button>
  </div>
</div>

<app-dialog [overlayClose]="false" (action)="onBalanceDateDialogAction($event)" [(visible)]="balanceDateDialogVisible">
  <div class="Modal-header" [innerHTML]="'CompanyDataImport.DateForBalance' | translate"></div>
  <date-picker-control
    [label]="'CompanyDataImport.Date' | translate"
    [editMode]="true"
    [required]="true"
    [(value)]="balanceDate"
  ></date-picker-control>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action *ngIf="actionType !== 2" [type]="'Ok'"></app-dialog-action>
  <app-dialog-action *ngIf="actionType === 2" [type]="'Upload'"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="alertDialogVisible">
  <div *ngIf="alertType === 'SafariWarning'" [innerHTML]="'Download.SafariWarning_XLSX' | translate"></div>
  <div *ngIf="alertType === 'FileSizeError'" [innerHTML]="'CompanyGeneral.FileSizeErrorMessage_64M' | translate"></div>
  <div
    *ngIf="alertType === 'DanlonPDFSuccess'"
    [innerHTML]="'CompanyDataImport.DanlonPDFSuccessMessage' | translate"
  ></div>
  <div
    *ngIf="alertType === 'BalancesDanlonPDFSuccess'"
    [innerHTML]="'CompanyDataImport.BalancesDanlonPDFSuccessMessage' | translate"
  ></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<create-import-template-dialog [(visible)]="showCreateTemplateDialog" [importType]="importType">
</create-import-template-dialog>
<upload-import-file-dialog [(visible)]="showUploadFileDialog" [importType]="importType"></upload-import-file-dialog>
