import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { AccountantServiceBase } from '../AccountantServiceBase';
import * as i0 from "@angular/core";
import * as i1 from "../../Services/RxDataService";
import * as i2 from "../../Services/SharedServices/Session/SessionService";
var AccountantUsersService = /** @class */ (function (_super) {
    tslib_1.__extends(AccountantUsersService, _super);
    function AccountantUsersService(api, sessionService) {
        var _this = _super.call(this, api) || this;
        _this.api = api;
        _this.sessionService = sessionService;
        _this.usersSubject = new BehaviorSubject([]);
        _this.allUsers = [];
        return _this;
    }
    Object.defineProperty(AccountantUsersService.prototype, "users", {
        get: function () {
            return this.usersSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    AccountantUsersService.prototype.loadData = function () {
        var _this = this;
        this.api.Account_GetAccountUsers().subscribe(function (data) {
            _this.allUsers = data;
            _this.applyFilter();
        });
    };
    AccountantUsersService.prototype.getRoles = function () {
        var _this = this;
        if (this.selectedId) {
            this.api.Account_GetCompanyAccessByUser(this.selectedId).subscribe(function (data) {
                if (data.length > 0) {
                    if (data[0].UserId === _this.selectedId) {
                        _this.rolesSubject.next(data);
                    }
                    else {
                        if (!environment.production) {
                            console.error("UserId " + _this.selectedId + " doesnt match recieved data");
                        }
                    }
                }
                else {
                    if (!environment.production) {
                        console.log('No roles');
                    }
                }
            });
        }
    };
    AccountantUsersService.prototype.applyFilter = function () {
        if (this.filter) {
            this.currentPage = 0;
            var searchText_1 = this.filter.toLocaleLowerCase();
            var data = this.allUsers.filter(function (c) { return c.FullName.toLocaleLowerCase().includes(searchText_1) || c.Id.toString().includes(searchText_1); });
            this.usersSubject.next(data);
        }
        else {
            this.usersSubject.next(this.allUsers);
        }
        this.selectedId = undefined;
    };
    AccountantUsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountantUsersService_Factory() { return new AccountantUsersService(i0.ɵɵinject(i1.RxDataService), i0.ɵɵinject(i2.SessionService)); }, token: AccountantUsersService, providedIn: "root" });
    return AccountantUsersService;
}(AccountantServiceBase));
export { AccountantUsersService };
