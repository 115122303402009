import { OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Raven from 'raven-js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Browser } from '../../Common/Browser';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { AuthenticationService } from '../../Services/Authentication/AuthenticationService';
import { Broadcaster } from '../../Services/Broadcaster';
import { ModalService } from '../../Services/SharedServices/ModalService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
var ReloginComponent = /** @class */ (function () {
    function ReloginComponent(translateService, authenticationService, broadcaster, sessionService, modalService, staticDataService) {
        this.translateService = translateService;
        this.authenticationService = authenticationService;
        this.broadcaster = broadcaster;
        this.sessionService = sessionService;
        this.modalService = modalService;
        this.staticDataService = staticDataService;
        this.password = '';
        this.ngUnsubscribe = new Subject();
        this.showResetPasswordDialog = false;
        this.setupEventHandlers();
    }
    ReloginComponent.prototype.ngOnInit = function () {
        this.userName = localStorage.getItem('saveLoginUserName');
        this.translateText();
    };
    ReloginComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    ReloginComponent.prototype.translateText = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService
                .get(['Error.NetworkError', 'Login.WrongUserNameOrPassword'])
                .subscribe(function (translations) {
                _this.networkErrorMessage = translations['Error.NetworkError'];
                _this.WrongUsernameOrPassword = translations['Login.WrongUserNameOrPassword'];
            });
        });
    };
    ReloginComponent.prototype.setupEventHandlers = function () {
        var _this = this;
        this.broadcaster
            .on(Constants.SESSION_EXPIRED)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.visible = true;
        });
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) { });
    };
    ReloginComponent.prototype.keydown = function (e) {
        if (e.keyCode === 13) {
            this.login();
        }
        else {
            this.errorMessage = undefined;
        }
    };
    ReloginComponent.prototype.logout = function () {
        var _this = this;
        this.authenticationService
            .logout()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function () {
            _this.navigateToLogin();
        }, function () {
            _this.navigateToLogin();
        });
    };
    ReloginComponent.prototype.navigateToLogin = function () {
        this.sessionService.NavigateTo('login');
    };
    ReloginComponent.prototype.reloadStaticData = function () {
        var _this = this;
        this.staticDataService.loadStaticData().then(function () {
            _this.close();
            window.location.reload();
        });
    };
    ReloginComponent.prototype.login = function () {
        var _this = this;
        this.authenticationService.credentials = {
            Username: Global.SESSION ? Global.SESSION.SignOnToken.Username : '',
            Password: this.password ? this.password : '',
            Remember: false
        };
        var message;
        this.authenticationService
            .login()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function () {
            _this.reloadStaticData();
        }, function (reason) {
            // Failure
            switch (reason.status) {
                case 0:
                    message = _this.networkErrorMessage;
                    break;
                case 401:
                    message = _this.WrongUsernameOrPassword;
                    break;
                case 400:
                    message = reason.data.Message;
                    break;
                case 500:
                    // Internal server error
                    var errorMessage = reason.statusText;
                    if (reason.config && reason.config.url) {
                        errorMessage += ' - ' + reason.config.url;
                    }
                    Raven.captureException(new Error(errorMessage), { extra: reason });
                    break;
                default:
                    var othermessage = reason.statusText;
                    if (reason.config && reason.config.url) {
                        othermessage += ' - ' + reason.config.url;
                    }
                    Raven.captureException(new Error(othermessage), { extra: reason });
                    break;
            }
            _this.errorMessage = message;
            console.error('Login failed: ');
            console.error(reason);
        });
    };
    ReloginComponent.prototype.close = function () {
        this.visible = false;
    };
    ReloginComponent.prototype.showpassword = function (isShow) {
        if (isShow) {
            this.show = isShow;
        }
        else {
            this.show = false;
        }
    };
    ReloginComponent.prototype.onResetPassword = function () {
        this.showResetPasswordDialog = true;
    };
    ReloginComponent.prototype.onDialogAction = function (action) {
        if (action === 'Continue') {
            this.login();
        }
        else {
            this.logout();
        }
    };
    Object.defineProperty(ReloginComponent.prototype, "isMobile", {
        get: function () {
            var browser = new Browser();
            return browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    ReloginComponent.prototype.showPaswordForMobile = function () {
        if (this.isMobile) {
            this.show = true;
        }
    };
    return ReloginComponent;
}());
export { ReloginComponent };
