import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IModuleCompanyView, ISalaryCycle, IUserEmploymentView } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'company-modules',
  templateUrl: './CompanyModules.component.html'
})
export class CompanyModulesComponent implements OnInit, OnDestroy {
  public usersMonthly = 0;
  public users14Day = 0;
  public usersWeekly = 0;
  public modules: IModuleCompanyView[] = [];
  public confirmDialogVisible = false;
  public reportDialogVisible = false;

  private selectedPackageId = 0;
  private currentCompanyClick = false;

  @Input()
  public get companyClick(): boolean {
    return this.currentCompanyClick;
  }
  public set companyClick(value: boolean) {
    this.currentCompanyClick = value;
    this.companyClickChange.emit(value);
  }

  @Output() private companyClickChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private dataService: RxDataService,
    private staticDataService: StaticDataService,
    public sessionService: SessionService
  ) {}

  public ngOnInit(): void {
    if (this.sessionService.role.IsFullAdmin) {
      this.staticDataService.loadStaticData().then(() => {
        this.getEmployees();
        this.staticDataService.moduleCompanyView
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((data: IModuleCompanyView[]) => {
            this.modules = JSON.parse(JSON.stringify(data));
          });
      });
    }
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onSubmit(packageId: number): void {
    this.selectedPackageId = packageId;
    this.confirmDialogVisible = true;
  }

  public onConfirmDialogAction(action: string): void {
    if (action === 'Understood') {
      const selectedModules: number[] = this.modules
        .filter((m: IModuleCompanyView) => m.IsEnabled && !m.InfoOnly)
        .map((m: IModuleCompanyView) => m.ModuleId);
      this.dataService.Modules_SetEnabledModules(this.selectedPackageId, selectedModules).subscribe(() => {
        this.staticDataService.loadStaticData().then(() => {
          window.location.reload();
        });
      });
    }
  }

  private getEmployees(): void {
    this.staticDataService.SalaryCycle.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ISalaryCycle[]) => {
      const salaryCycles: ISalaryCycle[] = data;
      this.dataService.Employments_GetEmploymentViews().subscribe((employementData: IUserEmploymentView[]): void => {
        let userEmployments: IUserEmploymentView[] = employementData;
        userEmployments = userEmployments.filter((item: IUserEmploymentView): boolean => {
          const now: Date = new Date();
          return item.IsActive && (!item.ExpireDate || new Date(item.ExpireDate) > now);
        });

        this.usersWeekly = 0;
        this.users14Day = 0;
        this.usersMonthly = 0;
        for (const employment of userEmployments) {
          const employmentCycle: ISalaryCycle = salaryCycles
            ? salaryCycles.find((cycle: ISalaryCycle) => cycle.Id === employment.SalaryCycleId)
            : undefined;
          if (employmentCycle) {
            switch (employmentCycle.Key) {
              case 'Weekly':
                this.usersWeekly++;
                break;

              case '14Days':
              case '14Days2':
                this.users14Day++;
                break;

              default:
                // GS-2187 : Cycle not Weekly nor 14days is Monthly.
                this.usersMonthly++;
                break;
            }
          }
        }
        if (this.usersWeekly === 0 && this.users14Day === 0 && this.usersMonthly === 0) {
          this.usersMonthly = 1;
        }
      });
    });
  }
}
