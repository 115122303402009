import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { WindowReference } from '../../../../windowReference';
import './Price.component.less';

@Component({
  selector: 'tool-tip-price',
  templateUrl: './toolTipPrice.component.html'
})
export class TooltippriceComponent {
  constructor(private windowRef: WindowReference) {}

  @Input() public Tooltiptitle: string;
  @Input() public tooltipNumber: number;
  @Input()
  public get isHideToolTip(): boolean {
    return this.isHideToolTipValue;
  }
  public set isHideToolTip(value: boolean) {
    this.tooltip.hide();
    this.hide.emit();
  }

  @ViewChild('tooltip', { static: true }) public tooltip: any;
  @Output() private show: EventEmitter<any> = new EventEmitter<any>();
  @Output() public hide: EventEmitter<any> = new EventEmitter<any>();

  public tooltipDir: TooltipDirective;
  public isHideToolTipValue = false;

  public onClick(packet: any): void {
    setTimeout(() => {
      if (this.tooltip) {
        this.tooltip.show(packet);
        this.show.emit();
      }
    });
  }

  public get tooltipPackageWidth(): number {
    const width: number = this.windowRef && this.windowRef.nativeWindow ? this.windowRef.nativeWindow.innerWidth : 800;
    return width > 790 ? 500 : width * 0.9;
  }
}
