var CompanySalaryBatchesEntityContext = /** @class */ (function () {
    function CompanySalaryBatchesEntityContext() {
        this.SalaryBatches = [];
        this.SalaryCycles = [];
    }
    Object.defineProperty(CompanySalaryBatchesEntityContext.prototype, "IsRecalculateDraft", {
        get: function () {
            return this.AutoRecalDraftPreference && this.AutoRecalDraftPreference.Value.toLowerCase() === 'true';
        },
        enumerable: true,
        configurable: true
    });
    return CompanySalaryBatchesEntityContext;
}());
export { CompanySalaryBatchesEntityContext };
