import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'lock-salary-batch',
  templateUrl: './LockSalaryBatch.component.html'
})
export class LockSalaryBatchComponent {
  @Input() public salaryBatchId: number;

  private visibleValue: boolean;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (value !== this.visibleValue) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
      if (value) {
        this.notifyEmployee = true;
      }
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public batchLocked: EventEmitter<boolean> = new EventEmitter<boolean>();

  public notifyEmployee = true;

  constructor(private dataService: RxDataService, public sessionService: SessionService) {}

  public onDialogAction(action: string): void {
    // switch (action) {
    //     case "Lock":
    //         this.dataService.SalaryBatches_LockSalaryBatch(this.salaryBatchId).subscribe(() => {
    //             this.batchLocked.emit();
    //             this.visible = false;
    //         });
    //         break;
    //     case "LockAndGrantAccess":
    //         this.dataService.SalaryBatches_ApproveSalaryBatchPreliminary(this.salaryBatchId).subscribe(() => {
    //             this.batchLocked.emit();
    //             this.visible = false;
    //         });
    //         break;
    // }
    if (action === 'SubmitForApproval') {
      if (this.notifyEmployee) {
        this.dataService.SalaryBatches_ApproveSalaryBatchWithPreliminaryPayslips(this.salaryBatchId).subscribe(() => {
          this.batchLocked.emit();
          this.visible = false;
        });
      } else {
        this.dataService.SalaryBatches_ApproveSalaryBatch(this.salaryBatchId).subscribe(() => {
          this.batchLocked.emit();
          this.visible = false;
        });
      }
    }
  }
}
