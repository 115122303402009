<div class="FormElement EmployeeInfo-position" *ngIf="!editMode && hasSingleEmployment">
  <span class="FormElement-label">{{ 'Employee.Title' | translate }}:</span>
  <div class="FormElement-control">
    <span class="FormElement-static">
      {{ userEmploymentDataSource[0].DisplayTitle }}
    </span>
    <span *ngIf="!isTabSelfService" class="FormElement-addon FormElement-addonSpace Icon--text">
      <app-icon
        [icon]="'circleplus'"
        [tooltip]="'EmployeeGeneral.Create employment' | translate"
        [size]="'small'"
        (click)="onAddonClick()"
      >
      </app-icon>
    </span>
  </div>
</div>

<combobox-edit-control
  *ngIf="!editMode && hasMultipleEmployments"
  [label]="'Employee.Title' | translate"
  [editMode]="true"
  [keepDataSourceOrder]="true"
  [comboboxDataSource]="userEmploymentDataSource"
  [(isValid)]="childIsValid[1]"
  [(isDirty)]="childIsDirty[1]"
  [textField]="'DisplayTitle'"
  [idField]="'Id'"
  [(value)]="currentPositionId"
  (valueChange)="onPositionChangedEvent()"
  [addonVisible]="isEmployeeTab"
  [addonSize]="'small'"
  [addonIcon]="'circleplus'"
  [addonTooltip]="'EmployeeGeneral.Create employment' | translate"
  (addonClick)="onAddonClick()"
  class="EmployeeInfo-position"
></combobox-edit-control>

<app-text-edit
  *ngIf="allowEditTitle"
  [label]="'Employee.Title' | translate"
  [editMode]="editMode"
  [type]="'text'"
  [required]="true"
  [(isValid)]="childIsValid[0]"
  [(isDirty)]="childIsDirty[0]"
  [(value)]="currentUserEmployment.Title"
  (valueChange)="onPositionTextInputChange()"
  [addonIcon]="'circleplus'"
  [addonSize]="'small'"
  [addonTooltip]="'EmployeeGeneral.Create employment' | translate"
  (addonClick)="onAddonClick()"
></app-text-edit>
