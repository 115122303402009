<div *ngIf="visible" class="AlertBox AlertBox--disable">
  <div *ngIf="content" class="AlertBox--content" [innerHTML]="content">
    <span
      style="font-size: large;
    				font-weight: bolder;"
      (click)="onHidePopup()"
      class="addCursor"
      >x</span
    >
  </div>
  <div class="AlertBox-footer">
    <button
      *ngFor="let action of actions"
      class="AlertBox-buttonSecondary"
      type="button"
      (click)="onActionClicked(action)"
    >
      {{ action }}
    </button>
  </div>
</div>
