import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ComboBoxComponent, DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { ListHelper } from '../Common/ListHelper';
import { UniqueId } from '../Common/UniqueId';
import { SessionService } from '../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'combobox-edit-control',
  templateUrl: './ComboboxEdit.component.html'
})
export class ComboboxEditComponent extends ControlComponentBase<number> implements OnInit, OnDestroy, AfterViewInit {
  @Input() public idField: string;
  @Input() public textField: string;
  @Input() public tooltipField: string;
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public changeConfirmMessage: string;
  @Input() public filterFields: string[];
  @Input() public viewClass = '';
  @Input() public checkFilterable = true;
  @Input() public noToggle = false;
  @Input() public resetDropdownOnNullDS = false;
  @Input() public isValueIcon: boolean;

  private nullValueLabelValue: string;
  @Input()
  public get nullValueLabel(): string {
    return this.nullValueLabelValue;
  }
  public set nullValueLabel(value: string) {
    this.nullValueLabelValue = value;
    this.addNullItem();
  }

  private dataSource: any[];
  @Input()
  public get comboboxDataSource(): any[] {
    return this.dataSource;
  }
  public set comboboxDataSource(value: any[]) {
    if (value && this.dataSource !== value) {
      this.dataSource = JSON.parse(JSON.stringify(value));
      this.addNullItem();
    }
  }

  @Input() public hasFeedback: boolean;
  @Input() public feedbackKey: string;
  @Input() public required: boolean;
  @Input() public keepDataSourceOrder = true;
  @Input() public addonVisible: boolean;
  @Input() public addonIcon: string;
  @Input() public addonLink: string;
  @Input() public addonTooltip: string;
  @Input() public showNavigationButton: boolean;
  @Input() public disable = false;
  @Input() public specialShowNavigate = false;
  @Input() public tooltipContent: string;
  @Input() public addonSize: string;

  @Output() public addonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public blur: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('kendoComboboxControl', { static: false }) public kendoComboboxControl: ComboBoxComponent;
  @ViewChild('kendoDropdownListControl', { static: false }) public kendoDropdownListControl: DropDownListComponent;

  public componentId: string;
  public previousButtonDisable: boolean;
  public nextButtonDisable: boolean;
  public confirmDialogVisible = false;
  public componentIdField = 'Id';
  public componentTextField = 'Text';

  private translatedComboBoxDataSource: any[];
  private displayDataSourceValue: any[];

  constructor(private sessionService: SessionService, elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
    this.nullIdValue = -9999;
    this.componentId = 'comboboxEditComponentId' + UniqueId();
  }

  private inputEvent: any;

  public ngOnInit(): void {
    this.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (value) {
        this.selectNullValue();
      }
    });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    if (this.kendoComboboxControl) {
      setTimeout(() => {
        this.kendoComboboxControl.toggle(false);
      });
    }
    if (this.kendoDropdownListControl) {
      setTimeout(() => {
        this.kendoDropdownListControl.toggle(false);
      });
    }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public confirmDialogAction(action: string): void {
    if (action !== 'Proceed') {
      this.isDirty = false;
    }
  }

  public onAddonButtonClick(): void {
    if (this.addonClick) {
      this.addonClick.emit();
    }
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get currentSelectedValueText(): string {
    let currentSelectedValue: any;
    const value: any = this.value || this.value === 0 ? this.value : this.nullIdValue;
    if (this.translatedComboBoxDataSource && (value || value === 0)) {
      currentSelectedValue = this.translatedComboBoxDataSource.find((data: any) => {
        return (
          (data[this.componentIdField] || data[this.componentIdField] === 0) &&
          data[this.componentIdField].toString() === value.toString()
        );
      });
    }

    let result = '';
    if (
      this.specialShowNavigate &&
      currentSelectedValue &&
      currentSelectedValue.hasOwnProperty(this.componentTextField)
    ) {
      result = currentSelectedValue[this.componentTextField];
    } else if (currentSelectedValue && currentSelectedValue.hasOwnProperty('Text')) {
      result = currentSelectedValue['Text'];
    }
    return result;
  }

  public onPeviousButtonClick(e: any): void {
    if (
      !this.translatedComboBoxDataSource ||
      (this.translatedComboBoxDataSource && this.translatedComboBoxDataSource.length === 0)
    ) {
      return;
    }

    const currentIndex: number = this.getSelectedComboboxIndex();
    if (currentIndex === 0) {
      return;
    }

    this.value = (this.translatedComboBoxDataSource[currentIndex - 1] as any)[this.componentIdField];
    if (this.specialShowNavigate) {
      this.onEmitValueChange(this.value);
    }

    this.enablePreviousAndNextButtons();
  }

  public onNextButtonClick(e: any): void {
    if (
      !this.translatedComboBoxDataSource ||
      (this.translatedComboBoxDataSource && this.translatedComboBoxDataSource.length === 0)
    ) {
      return;
    }

    const currentIndex: number = this.getSelectedComboboxIndex();
    if (currentIndex === this.translatedComboBoxDataSource.length - 1) {
      return;
    }

    this.value = (this.translatedComboBoxDataSource[currentIndex + 1] as any)[this.componentIdField];
    if (this.specialShowNavigate) {
      this.onEmitValueChange(this.value);
    }

    this.enablePreviousAndNextButtons();
  }

  @Input() public isBigdataSource = false;

  public onSmallDatahandleFilter(event: any): void {
    if (!this.isBigdataSource) {
      if (event && event.length >= 1) {
        this.onComboBoxFiltering(event);
      } else {
        this.displayDataSourceValue = this.translatedComboBoxDataSource;
      }
    }
  }

  public onComboBoxFiltering(value?: any): void {
    if (value && value.length >= 1 && this.translatedComboBoxDataSource) {
      this.isFiltering = true;
      this.comboboxFilteringSelectedId = undefined;
      if (this.filterFields && this.filterFields.length > 0) {
        this.displayDataSourceValue = this.translatedComboBoxDataSource.filter((item: any) => {
          let result: boolean;
          this.filterFields.forEach((field: string) => {
            result = result
              ? result
              : field && item[field] && item[field].toLowerCase().indexOf(value.toString().toLowerCase()) !== -1;
          });
          return result;
        });

        if (
          this.displayDataSourceValue.length === 1 &&
          this.kendoComboboxControl &&
          !this.kendoComboboxControl.text &&
          this.isBigdataSource
        ) {
          (this.kendoComboboxControl as any).wrapper.getElementsByClassName(
            'k-input'
          )[0].value = this.displayDataSourceValue[0][this.textField];
          (this.kendoComboboxControl as any).wrapper.getElementsByClassName('k-input')[0].select();
        }
      } else {
        this.displayDataSourceValue = this.translatedComboBoxDataSource.filter((item: any) => {
          return (
            item[this.componentTextField] &&
            item[this.componentTextField].toLowerCase().indexOf(value.toString().toLowerCase()) !== -1
          );
        });
      }
    } else {
      this.isFiltering = false;
      this.displayDataSourceValue = this.translatedComboBoxDataSource;
    }
  }

  public onComboboxSelectionChanged(dataItem: any): void {
    this.comboboxFilteringSelectedId = dataItem ? dataItem[this.componentIdField] : dataItem;
  }

  private preEnvent: any;
  private preSearch: string;
  public onkeydown(event: any): void {
    this.preEnvent = event;
    if (this.isBigdataSource && event.key === 'ArrowUp' && event.key === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown' && event.keyCode !== 9 && this.isBigdataSource) {
      this.inputEvent.subscribe((x: any) => {
        if (x && x.length > 0) {
          if (this.preEnvent.key !== 'ArrowUp' && this.preEnvent.key !== 'ArrowDown') {
            this.onComboBoxFiltering(x);
          }
        } else {
          this.displayDataSourceValue = this.translatedComboBoxDataSource;
        }
      });
    }
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      //this.value = this.valueOnFocus;
      if (this.kendoComboboxControl && this.kendoComboboxControl.isOpen) {
        event.preventDefault();
        event.stopPropagation();
      }
    } else if (event.keyCode === 9 && this.isGrid) {
      this.dataSource.forEach((data) => {
        if (this.comboboxFilteringSelectedId === data.TimeEntryTypeId) {
          this.value = data.TimeEntryTypeId;
          return;
        }
      });
    } else if (event.keyCode === 13) {
      this.selectFirstFilteringItem();
    }
  }

  public onComboboxClicked(event: any): void {
    event.stopPropagation();
  }

  public onComboboxBlur(): void {
    this.selectFirstFilteringItem();
    this.displayDataSourceValue = this.translatedComboBoxDataSource;
    if (
      (!this.isBigdataSource && this.value === undefined) ||
      (this.value === this.nullIdValue && !this.nullValueLabel)
    ) {
      setTimeout(() => {
        const filtervalueText: any = (this.displayDataSource as any[]).find(
          (model: any) => model[this.componentIdField] === this.valueOnFocus
        );
        if (
          this.kendoComboboxControl &&
          (this.kendoComboboxControl as any).wrapper.getElementsByClassName('k-input')[0]
        ) {
          (this.kendoComboboxControl as any).wrapper.getElementsByClassName('k-input')[0].value =
            filtervalueText && filtervalueText[this.componentTextField] ? filtervalueText[this.componentTextField] : '';
          this.value = this.nullIdValue;
          setTimeout(() => {
            this.value =
              filtervalueText && filtervalueText[this.componentIdField]
                ? filtervalueText[this.componentIdField]
                : this.nullIdValue;
            this.blur.emit();
          });
        }
      });
    } else {
      this.blur.emit();
    }
  }

  private comboboxFilteringSelectedId: any;
  private selectFirstFilteringItem(): void {
    if (this.isFiltering) {
      this.isFiltering = false;
      if (
        this.displayDataSource &&
        this.displayDataSource.length > 0 &&
        this.displayDataSource.length !== this.translatedComboBoxDataSource.length
      ) {
        const selectedItem: any = this.comboboxFilteringSelectedId
          ? this.displayDataSource.find((item: any) => item[this.componentIdField] === this.comboboxFilteringSelectedId)
          : undefined;
        this.value = selectedItem
          ? selectedItem[this.componentIdField]
          : this.displayDataSource[0][this.componentIdField];
        if (this.value === this.valueOnFocus) {
          setTimeout(() => {
            const filtervalueText: any = (this.displayDataSource as any[]).find(
              (model: any) => model[this.componentIdField] === this.valueOnFocus
            );
            if (
              this.kendoComboboxControl &&
              (this.kendoComboboxControl as any).wrapper.getElementsByClassName('k-input')[0]
            ) {
              (this.kendoComboboxControl as any).wrapper.getElementsByClassName('k-input')[0].value =
                filtervalueText && filtervalueText[this.componentTextField]
                  ? filtervalueText[this.componentTextField]
                  : '';
              this.value = this.nullIdValue;
              setTimeout(() => {
                this.value =
                  filtervalueText && filtervalueText[this.componentIdField]
                    ? filtervalueText[this.componentIdField]
                    : this.nullIdValue;
              });
            }
          });
        }
      } else {
        setTimeout(() => {
          const filtervalueText: any = (this.displayDataSource as any[]).find(
            (model: any) => model[this.componentIdField] === this.valueOnFocus
          );
          if (
            this.kendoComboboxControl &&
            (this.kendoComboboxControl as any).wrapper.getElementsByClassName('k-input')[0]
          ) {
            (this.kendoComboboxControl as any).wrapper.getElementsByClassName('k-input')[0].value =
              filtervalueText && filtervalueText[this.componentTextField]
                ? filtervalueText[this.componentTextField]
                : '';
            this.value = this.nullIdValue;
            setTimeout(() => {
              this.value =
                filtervalueText && filtervalueText[this.componentIdField]
                  ? filtervalueText[this.componentIdField]
                  : this.nullIdValue;
            });
          }
        });
      }
    }
  }

  public get displayDataSource(): any {
    if (!this.displayDataSourceValue) {
      return this.translatedComboBoxDataSource;
    }

    return this.displayDataSourceValue;
  }

  private getSelectedComboboxIndex(): number {
    return this.translatedComboBoxDataSource.findIndex((sourceItem: any) => {
      return sourceItem[this.componentIdField] === this.value;
    });
  }

  private enablePreviousAndNextButtons(): void {
    const currentIndex: number = this.getSelectedComboboxIndex();
    this.nextButtonDisable = currentIndex === this.translatedComboBoxDataSource.length - 1;
    this.previousButtonDisable = currentIndex === 0;
  }

  private selectNullValue(): void {
    if ((this.value === undefined || this.value === 0 || this.value === null) && this.nullValueLabel) {
      this.value = this.nullIdValue;
    }

    if (
      this.resetDropdownOnNullDS &&
      this.kendoDropdownListControl &&
      (!this.displayDataSource || this.displayDataSourceValue.length === 0)
    ) {
      this.kendoDropdownListControl.dataItem = undefined;
      this.kendoDropdownListControl.writeValue(undefined);
    }
  }

  @Input() isConvertTime = true;
  public NoneValueItem: any;
  private addNullItem(): void {
    if (this.nullValueLabel && this.nullValueLabel.length > 0) {
      const noneItem: any = { Id: undefined, Text: this.nullValueLabel };
      noneItem[this.idField] = this.nullIdValue;
      noneItem[this.componentIdField] = this.nullIdValue;
      noneItem[this.textField] = this.nullValueLabel;
      noneItem[this.componentTextField] = this.nullValueLabel;
      this.NoneValueItem = noneItem;
      this.translatedComboBoxDataSource = ListHelper.mapDataSource(
        this.sessionService,
        this.dataSource,
        this.idField,
        this.textField,
        noneItem,
        this.keepDataSourceOrder ? false : true,
        this.isConvertTime,
        this.filterFields,
        this.tooltipField
      );
    } else {
      this.translatedComboBoxDataSource = ListHelper.mapDataSource(
        this.sessionService,
        this.dataSource,
        this.idField,
        this.textField,
        undefined,
        false,
        this.isConvertTime,
        this.filterFields,
        this.tooltipField
      );
    }

    this.displayDataSourceValue = this.translatedComboBoxDataSource;
    this.selectNullValue();
  }

  private isFocusValue: boolean;
  @Input() public get isFocus(): boolean {
    return this.isFocusValue;
  }
  public set isFocus(value: boolean) {
    if (this.isFocusValue !== value) {
      this.isFocusValue = value;
      if (value) {
        setTimeout(() => {
          this.onfocus();
        });
      } else {
        if (this.kendoComboboxControl) {
          setTimeout(() => {
            this.kendoComboboxControl.toggle(false);
          });
        }
        if (this.kendoDropdownListControl) {
          setTimeout(() => {
            this.kendoDropdownListControl.toggle(false);
          });
        }
      }
    }
  }

  public ngAfterViewInit(): void {
    if (this.isFocus && !this.isMobile) {
      this.onfocus();
    }
  }

  public onClose(): void {
    setTimeout(() => {
      this.onComboBoxFiltering();
    });
  }

  public onfocus(): void {
    this.addClassAndEventForDropdown();
    if (this.dataSource && this.dataSource.length > 0) {
      if (this.kendoComboboxControl) {
        setTimeout(() => {
          const input: any = (this.kendoComboboxControl as any).wrapper.getElementsByClassName('k-input')[0];
          if (input) {
            input.focus();
          }
        });
        if (this.isBigdataSource) {
          const input: any = (this.kendoComboboxControl as any).wrapper.getElementsByClassName('k-input')[0];
          this.inputEvent = fromEvent(input, 'keyup').pipe(
            map((x: any) => {
              return x.target.value;
            }),
            debounceTime(400)
          );
        }
        this.kendoComboboxControl.toggle(true);
        if ((this.kendoComboboxControl as any).wrapper.getElementsByClassName('k-input')[0]) {
          (this.kendoComboboxControl as any).wrapper.getElementsByClassName('k-input')[0].select();
        }
        if (this.value !== undefined && this.value !== this.nullIdValue) {
          this.valueOnFocus = this.value;
        }
      }
      if (this.kendoDropdownListControl) {
        this.kendoDropdownListControl.toggle(true);
      }
    }
  }

  public valueOnFocus: any;
  public onOpen(): void {
    this.addClassAndEventForDropdown();
  }

  public addClassAndEventForDropdown(): void {
    if (this.kendoDropdownListControl) {
      this.kendoDropdownListControl.toggle(true);
      setTimeout(() => {
        Array.from(document.getElementsByClassName('k-animation-container')).forEach((model: any) => {
          if (model) {
            model.classList.add('MobiDropdownPopup');
            model.addEventListener('click', (): void => {
              model.style.display = 'none';
              model.classList.remove('MobiDropdownPopup');
            });
          }
        });
        const elementdown1: any = document.getElementsByClassName('EditingInfo Discard')[0];
        if (elementdown1) {
          elementdown1.classList.add('setZIndex');
          elementdown1.addEventListener('click', (): void => {
            elementdown1.classList.remove('setZIndex');
          });
        }
      });
    }

    if (this.comboboxDataSource && this.comboboxDataSource.length < 8 && !this.isFiltering) {
      this.checkFilterable = false;
    }
  }
}
