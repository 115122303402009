import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IResetPasswordRequest } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { ModalService } from '../../Services/SharedServices/ModalService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'reset-password',
  templateUrl: './ResetPassword.component.html'
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public isChangePasswordSuccessDialog = false;
  public sendPasswordMessage: string;
  public cprNo?: string;

  private isVisibleValue = false;
  private boolRequestingService = false;

  @Input() public email?: string;

  @Input()
  public get isVisible(): boolean {
    return this.isVisibleValue;
  }
  public set isVisible(value: boolean) {
    if (value) {
    }

    this.isVisibleValue = value;
    this.isVisibleChange.emit(value);
  }
  @Output() public isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private isReLoginValue?: boolean;
  @Input()
  public get isReLogin(): boolean {
    return this.isReLoginValue;
  }
  public set isReLogin(value: boolean) {
    if (value) {
    }

    this.isReLoginValue = value;
    this.isReLoginChange.emit(value);
  }
  @Output() public isReLoginChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public ngOnInit(): void {
    //this.isVisible = true;
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  keydown(e: any): void {
    if (e.keyCode !== 13) {
      return;
    }

    this.onSendTemporaryPassword();
  }

  constructor(
    public modalService: ModalService,
    private dataService: RxDataService,
    private translateService: TranslateService,
    private sessionService: SessionService
  ) {}

  onClose(): void {
    // Go to login page in case the current state is not login (fx. when forget password is used with session expired dialog)
    // if (!this.sessionService.IsAuthenticated && this.sessionService.currentState !== "login" && !this.isReLogin) {
    //     this.sessionService.NavigateTo("login");
    // }
  }

  onSendTemporaryPassword(): void {
    if (this.boolRequestingService) {
      return;
    }

    this.boolRequestingService = true;
    const requestPasswordRequest: IResetPasswordRequest = { IdentityNumber: this.cprNo, Email: this.email };
    const rsPasswLanguageCode: any = this.translateService.currentLang;
    this.dataService
      .Auth_RequestPasswordResetWithLanguage(rsPasswLanguageCode, requestPasswordRequest)
      .pipe(
        finalize(() => {
          this.boolRequestingService = false;
        })
      )
      .subscribe((data: any): any => {
        this.boolRequestingService = false;
        this.isChangePasswordSuccessDialog = true;
        this.email = '';
        this.cprNo = '';
      });
  }

  public onResetPasswordDialogAction(action: string): void {
    if (action === 'ResetPassword') {
      this.onSendTemporaryPassword();
    } else {
      this.isVisible = false;
    }
  }

  public onChangePasswordSuccessDialogAction(action: string): void {
    if (!this.sessionService.IsAuthenticated && !this.sessionService.currentState.includes('login')) {
      this.sessionService.NavigateTo('login');
    } else {
      this.isVisible = false;
    }
  }
}
