import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { Global } from '../../../Common/Global';
import {
  IBank,
  ICompany,
  ICompanyUser,
  ILanguage,
  IPaymentMethod,
  IStatisticsRecipient
} from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'app-company-option',
  templateUrl: './CompanyOption.component.html'
})
export class CompanyOptionComponent extends FormComponentBase implements OnInit {
  @Input() public contacts: ICompanyUser[];
  @Input() public company: ICompany;
  @Input()
  public set resetChildFrom(value: boolean) {
    if (value) {
      this.childIsDirty.fill(false);
      this.childIsValid.fill(true);
    }
    this.resetChildFromChange.emit(false);
  }

  @Output() public resetChildFromChange: EventEmitter<any> = new EventEmitter<any>();

  public statisticOptions: IStatisticsRecipient[];
  public paymentMethods: IPaymentMethod[];
  public bankOption: IBank[];
  public languages: ILanguage[];
  public hasStatisticModule = false;
  public hasLanguageModule = false;
  public hasPaymentModule = false;

  constructor(
    private dataService: RxDataService,
    private sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {
    super();
    this.childIsDirty = Array(7).fill(false);
  }

  public ngOnInit(): void {
    this.staticDataService.PaymentMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IPaymentMethod[]) => (this.paymentMethods = data)
    );
    this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ILanguage[]) => {
      this.languages = data;
    });

    this.staticDataService.StatisticsRecipient.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (statisticOptions: IStatisticsRecipient[]): void => {
        this.statisticOptions = statisticOptions;
      }
    );

    this.staticDataService.Bank.pipe(takeUntil(this.ngUnsubscribe)).subscribe((bankOption: IBank[]): void => {
      if (bankOption && bankOption.length > 0) {
        this.bankOption = bankOption.filter((model: IBank) => model.CountryId === this.company.CountryId);
      }
    });
  }

  public get isAdminUser(): boolean {
    return this.sessionService.role.IsFullAdmin;
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get isAccountAdmin(): boolean {
    return Global.SESSION && Global.SESSION.IsAccountAdmin;
  }

  public onAdditionalOptionClick(): void {
    this.sessionService.NavigateTo('tabs.company.configuration.settings');
  }
}
