<div *ngIf="!vertical" class="FormElement-control">
  <label *ngFor="let option of options" class="FormElement-radio">
    <input
      type="radio"
      [id]="option.value"
      [checked]="value === option.value"
      [name]="controlId"
      [disabled]="disable || !editMode"
      (click)="onClick(option.value)"
    />
    <span class="FormElement-radioFaux"></span>
    <span class="FormElement-label">{{ option.label }}</span>
  </label>
</div>

<div *ngIf="vertical">
  <div *ngFor="let option of options" class="FormElement-control">
    <div *ngIf="!option.isCheckModule" class="FormElement-static">
      <label class="FormElement-radio">
        <input
          type="radio"
          [id]="option.value"
          [checked]="value === option.value"
          [name]="controlId"
          [disabled]="disable || !editMode"
          (click)="onClick(option.value)"
        />
        <span class="FormElement-radioFaux"></span>
        <span class="FormElement-label">{{ option.label }}</span>
      </label>
    </div>

    <div *ngIf="option.isCheckModule" class="FormElement-static">
      <module-check [moduleId]="option.ModuleId" [dialogWidth]="option.moduleCheckWidth">
        <label class="FormElement-radio">
          <input
            type="radio"
            [id]="option.value"
            [checked]="value === option.value"
            [name]="controlId"
            [disabled]="!editMode"
            (click)="onClick(option.value)"
          />
          <span class="FormElement-radioFaux"></span>
          <span class="FormElement-label">{{ option.label }}</span>
        </label>
      </module-check>
    </div>

    <div *ngIf="option.addonLink" class="FormElement-addon FormElement-addonSpace Icon-addOn">
      <app-icon
        [icon]="'Question'"
        [tooltip]="'GlobalDialog.ReadMore' | translate"
        [link]="option.addonLink"
      ></app-icon>
    </div>
  </div>
</div>
