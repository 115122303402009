import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { Global } from '../../../Common/Global';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
var RevertPayslipComponent = /** @class */ (function () {
    function RevertPayslipComponent(translate, dataService, sessionService, staticDataService) {
        this.translate = translate;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.isVisibleValue = false;
        this.isVisibleChange = new EventEmitter();
        this.revertCompleted = new EventEmitter();
        this.isVisibleUnderStood = false;
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(RevertPayslipComponent.prototype, "employeeId", {
        get: function () {
            return this.currentEmployeeId;
        },
        set: function (value) {
            if (value && this.employeeId !== value) {
                this.currentEmployeeId = value;
                this.reloadEmployee();
            }
        },
        enumerable: true,
        configurable: true
    });
    RevertPayslipComponent.prototype.reloadEmployee = function () {
        var _this = this;
        this.dataService.Employments_GetEmployment(this.employeeId).subscribe(function (model) {
            _this.employee = model.CompanyUser;
        });
    };
    Object.defineProperty(RevertPayslipComponent.prototype, "isVisible", {
        get: function () {
            return this.isVisibleValue;
        },
        set: function (value) {
            if (this.isVisibleValue !== value) {
                this.isVisibleValue = value;
                this.isVisibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertPayslipComponent.prototype, "noEmail", {
        get: function () {
            return !this.employee || (!this.employee.CompanyEmail && !this.employee.PersonalEmail);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertPayslipComponent.prototype, "isExternalSystemRelevant", {
        get: function () {
            // GS-3436
            if (this.integrationName) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertPayslipComponent.prototype, "ExternalSystemRevertMessage", {
        get: function () {
            // GS-3436
            return this.isExternalSystemRelevant && this.revertBatchExtSystemMessage
                ? this.revertBatchExtSystemMessage.replace('{externalSystem.Name}', this.integrationName)
                : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertPayslipComponent.prototype, "isGreenland", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    RevertPayslipComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userName = localStorage.getItem('saveLoginUserName');
        this.translateText();
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            return _this.translateText();
        });
        this.staticDataService.ExternalSystemOperation.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.IntergrationOpation = data;
            var objectfillter = _this.IntergrationOpation.find(function (model) { return model.Key === 'ExportNewPayrollBatch'; });
            if (objectfillter) {
                _this.ExportNewPayrollBatchId = objectfillter.Id;
                if (_this.ExportNewPayrollBatchId) {
                    // this.staticDataService.GetIntegration2Operation(
                    //   this.ExportNewPayrollBatchId,
                    //   this.Integration2Operation,
                    //   this.integrationName
                    // );
                    _this.GetIntegration2Operation();
                }
            }
        });
    };
    // Using static dataService observer to prevent Integrations_GetIntegrationsByOperation run twice
    RevertPayslipComponent.prototype.GetIntegration2Operation = function () {
        var _this = this;
        this.dataService
            .Integrations_GetIntegrationsByOperation(this.ExportNewPayrollBatchId)
            .subscribe(function (model) {
            _this.Integration2Operation = model;
            if (_this.Integration2Operation && _this.Integration2Operation.length > 0) {
                _this.integrationName = _this.Integration2Operation[0].Name;
            }
        });
    };
    RevertPayslipComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    RevertPayslipComponent.prototype.revertModalAction = function (action) {
        if (action === 'RevertBatch') {
            this.isVisibleUnderStood = true;
        }
    };
    RevertPayslipComponent.prototype.revertModalUnderStoodAction = function (action) {
        var _this = this;
        if (action === 'Proceed') {
            var request = { Password: this.resetPasswordModel, DoRecalc: false };
            this.dataService.SalaryStatements_RevertSalaryStatement(this.salaryStatement.Id, request).subscribe(function () {
                _this.revertCompleted.emit();
                _this.onCancel();
            }, function (e) {
                console.error(e);
            });
        }
        else {
            this.onCancel();
        }
    };
    RevertPayslipComponent.prototype.translateText = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translate
                .get(['RevertPayslip.RevertConfirmationResult7'])
                .subscribe(function (translations) {
                _this.revertBatchExtSystemMessage = translations['RevertPayslip.RevertConfirmationResult7'];
            });
        });
    };
    RevertPayslipComponent.prototype.onCancel = function () {
        this.employeeId = undefined;
        this.employee = undefined;
        this.isVisible = false;
        this.isVisibleUnderStood = false;
    };
    return RevertPayslipComponent;
}());
export { RevertPayslipComponent };
