import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { Global } from '../../Common/Global';
import { IIncomeType } from '../../Services/ApiModel';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { TaxEIncomeModel } from './TaxEIncomeModel';

@Component({
  selector: 'tax-eincome',
  templateUrl: './TaxEIncome.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaxEIncomeComponent extends FormComponentBase implements OnInit {
  private modelValue: TaxEIncomeModel;
  @Input()
  public get model(): TaxEIncomeModel {
    if (!this.modelValue) {
      this.modelValue = {} as any;
    }

    return this.modelValue;
  }
  public set model(value: TaxEIncomeModel) {
    this.modelValue = value;
    if (value) {
      this.previousImcomeTypeId = value.IncomeTypeId;
    }
  }

  public get admin(): boolean {
    if (
      Global.SESSION.CurrentRole.Key === 'FullAdmin' ||
      Global.SESSION.CurrentRole.Key === 'SalaryAdmin' ||
      this.sessionService.currentState === 'tabs.selfservice.general'
    ) {
      return true;
    }
    return false;
  }

  public get iscompanyTemplatesTab(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.hiringstatus';
  }
  public incomeTypes: IIncomeType[];
  public warningDialogVisible = false;

  private previousImcomeTypeId: number;

  constructor(
    private staticDataService: StaticDataService,
    private changeDetectorRef: ChangeDetectorRef,
    public sessionService: SessionService
  ) {
    super();
    this.childIsDirty = Array(3).fill(false);
    this.editModeChange.subscribe((value: boolean) => {
      if (value && this.model) {
        this.previousImcomeTypeId = this.model.IncomeTypeId;
      }
    });
  }

  public ngOnInit(): void {
    this.staticDataService.IncomeType.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IIncomeType[]) => {
      this.incomeTypes = data;
      this.changeDetectorRef.markForCheck();
    });
  }

  public onWarningDialogAction(action: string): void {
    if (action === 'Continue') {
      this.previousImcomeTypeId = this.model.IncomeTypeId;
      this.onChange();
    } else {
      this.model.IncomeTypeId = this.previousImcomeTypeId;
    }
  }

  public onIncomeTypeChanged(): void {
    if (this.model.IncomeTypeId === 5) {
      this.warningDialogVisible = true;
      this.changeDetectorRef.markForCheck();
    } else {
      this.onChange();
    }
  }
}
