import * as tslib_1 from "tslib";
import { TranslateService } from '@ngx-translate/core';
import * as Raven from 'raven-js';
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/observable/of'; //proper way to import the 'of' operator
import { map } from 'rxjs/operators';
import { version } from '../../../version';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { RouterStateService } from '../../Common/RouterState.service';
import { RxDataService } from '../RxDataService';
import { ModalService } from './ModalService';
import { SessionService } from './Session/SessionService';
import { SettingService } from './SettingService';
var StaticDataService = /** @class */ (function () {
    function StaticDataService(dataService, translateService, settingsService, sessionService, modalService, state) {
        var _this = this;
        this.dataService = dataService;
        this.translateService = translateService;
        this.settingsService = settingsService;
        this.sessionService = sessionService;
        this.modalService = modalService;
        this.state = state;
        this.translateErrorMessages();
        this.configureRaven();
        this.sessionService.OnTranslateChanged.subscribe(function () { return _this.translateErrorMessages(); });
    }
    StaticDataService.prototype.loadStaticData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadSessionInfo()];
                    case 1:
                        _a.sent();
                        if (!this.moduleCompanyViewSubject) {
                            this.loadModuleCompanyView();
                        }
                        return [4 /*yield*/, this.loadUnitTypes()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    StaticDataService.prototype.loadSessionInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, shortCultureCode;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.Auth_GetSessionInfo().toPromise()];
                    case 1:
                        data = _a.sent();
                        Global.SESSION = data;
                        Raven.setExtraContext({
                            AccountId: data.AccountId,
                            AccountRoleId: data.AccountRoleId,
                            CurrentCompanyId: data.CurrentCompany.Id,
                            CurrentCompanyName: data.CurrentCompany.Name,
                            CurrentRoleId: data.CurrentRole.Id,
                            UserId: data.Id,
                            FullName: data.FullName,
                            HasBackendAccess: data.HasBackendAccess,
                            IsAccountAdmin: data.IsAccountAdmin,
                            IsAccountant: data.IsAccountant,
                            IsGratisalSupportUser: data.IsGratisalSupportUser,
                            IsPaymentApprover: data.IsPaymentApprover
                        });
                        if (!this.enabledModulesSubject) {
                            this.enabledModulesSubject = new BehaviorSubject([]);
                        }
                        this.enabledModulesSubject.next(data.Modules);
                        shortCultureCode = data.SignOnToken.Language.CultureCode.substr(0, 2);
                        return [4 /*yield*/, this.translateService.use(shortCultureCode).toPromise()];
                    case 2:
                        _a.sent();
                        this.sessionService.applyLocale(shortCultureCode);
                        return [4 /*yield*/, this.getStartupTasks()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.translateErrorMessages()];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.loadCompanyPreferences().toPromise()];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.loadUserPreferences().toPromise()];
                    case 6:
                        _a.sent();
                        return [4 /*yield*/, this.checkProduction().toPromise()];
                    case 7:
                        _a.sent();
                        if (Global.SESSION && Global.SESSION.SignOnToken.LanguageId) {
                            try {
                                document.cookie = 'languageId=' + Global.SESSION.SignOnToken.LanguageId;
                            }
                            catch (e) {
                                this.sessionService.isDetectedCookieDisable = true;
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    StaticDataService.prototype.getStartupTasks = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tasks;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(Global.SESSION.CurrentRole.Key === 'FullAdmin' &&
                            Global.SESSION.CurrentCompanyUser.IsActive &&
                            Global.SESSION.CurrentCompany.CountryId !== Constants.GREENLAND_COUNTRY_ID)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.dataService
                                .Startuptasks_GetStartupTasksWithStatus()
                                .toPromise()];
                    case 1:
                        tasks = _a.sent();
                        Global.STARTUP_TASKS = tasks;
                        this.settingsService.updateSettingsForEmployeeCompany(tasks);
                        return [3 /*break*/, 3];
                    case 2:
                        Global.IsCompanyDataCompleted = true;
                        Global.IsEmployeeTaskCompleted = true;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    StaticDataService.prototype.translateErrorMessages = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.translateService
                                .get(['Error.UnExpectedErrorTitle', 'Error.UnExpectedErrorMessage'])
                                .toPromise()];
                    case 1:
                        _a.translations = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    StaticDataService.prototype.configureRaven = function () {
        var _this = this;
        Raven.setDataCallback(function (info) {
            var extra = info.extra;
            // We dont show unexpected for network error that already catched.
            var isConnectionError = info.exception &&
                info.exception.values &&
                info.exception.values.some(function (ex) { return ex.value === 'GSNetworkError'; });
            if (isConnectionError ||
                (extra && extra.__serialized__ && (extra.__serialized__.status || extra.__serialized__.status === 0))) {
                return info;
            }
            if (_this.translations['Error.UnExpectedErrorTitle'] || _this.translations['Error.UnExpectedErrorMessage']) {
                _this.modalService.alert(_this.translations['Error.UnExpectedErrorTitle'], _this.translations['Error.UnExpectedErrorMessage']);
            }
            return info;
        });
        Raven.setShouldSendCallback(function (info) {
            var extra = info.extra;
            if (extra &&
                extra.__serialized__ &&
                (extra.__serialized__.status === 400 ||
                    extra.__serialized__.status === 401 ||
                    extra.__serialized__.status === 404)) {
                return false;
            }
            if (info.message && info.message.indexOf('a[b].target.className.indexOf') >= 0) {
                return false;
            }
            var noneExceptionMessage = 'Non-Error exception captured with keys: error, headers, message';
            if (info.message &&
                info.message.indexOf(noneExceptionMessage) >= 0 &&
                extra &&
                extra.__serialized__ &&
                (extra.__serialized__.status || extra.__serialized__.status === 0)) {
                return false;
            }
            return version !== 'Local-build';
        });
    };
    // Setup default routes and redirects depending on enabled modules and user role.
    StaticDataService.prototype.registerDefaultRoutes = function () {
        var _this = this;
        this.moduleCompanyView.subscribe(function () {
            // Accountant
            _this.state.registerDefault('tabs.accountant.companies');
            // Company - Approval
            _this.state.registerDefault('tabs.company.approval.timeentry');
            // Company - Configuration
            _this.state.registerDefault('tabs.company.configuration.hiringstatus');
            // Self Service
            _this.state.registerDefault('tabs.selfservice.payslip');
            if (!_this.sessionService.feature.hasModuleId(8)) {
                _this.state.registerRedirect('tabs.selfservice.time', 'tabs.selfservice.payslip');
            }
            else {
                _this.state.clearRedirect('tabs.selfservice.time');
            }
        });
    };
    // Company Preferences
    StaticDataService.prototype.loadCompanyPreferences = function () {
        return this.dataService.Preferences_GetCompanyPreferences().pipe(map(function (data) {
            Global.COMPANY_PREFERENCES = data;
        }));
    };
    // User Preferences
    StaticDataService.prototype.loadUserPreferences = function () {
        return this.dataService.Preferences_GetUserPreferences().pipe(map(function (data) {
            Global.USER_PREFERENCES = data;
        }));
    };
    StaticDataService.prototype.checkProduction = function () {
        return this.dataService.Miscellaneous_IsProduction().pipe(map(function (isProduction) {
            Global.IsDemo = !isProduction;
        }));
    };
    Object.defineProperty(StaticDataService.prototype, "enabledModules", {
        get: function () {
            if (!this.enabledModulesSubject) {
                this.enabledModulesSubject = new BehaviorSubject([]);
                this.loadSessionInfo();
            }
            return this.enabledModulesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaticDataService.prototype, "currentLogo", {
        get: function () {
            if (!this.currentLogoSubject) {
                this.loadCurrentLogo();
            }
            return this.currentLogoSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCurrentLogo = function () {
        var _this = this;
        if (!this.currentLogoSubject) {
            this.currentLogoSubject = new BehaviorSubject(undefined);
        }
        this.dataService.Companies_GetCompanyLogo().subscribe(function (image) {
            _this.currentLogoSubject.next(image);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "currentUserImage", {
        get: function () {
            if (!this.currentUserImageSubject) {
                this.loadcurrentUserImage();
            }
            return this.currentUserImageSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadcurrentUserImage = function () {
        var _this = this;
        if (!this.currentUserImageSubject) {
            this.currentUserImageSubject = new BehaviorSubject(undefined);
        }
        this.dataService.Users_GetUserImage().subscribe(function (image) {
            _this.currentUserImageSubject.next(image);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "currentCompany", {
        get: function () {
            if (!this.currentCompanySubject) {
                this.loadCurrentCompany();
            }
            return this.currentCompanySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCurrentCompany = function () {
        var _this = this;
        if (!this.currentCompanySubject) {
            this.currentCompanySubject = new BehaviorSubject(undefined);
        }
        this.dataService.Companies_GetCurrent().subscribe(function (model) {
            _this.currentCompanySubject.next(model);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "currentAccount", {
        get: function () {
            if (!this.currentAccountSubject) {
                this.loadCurrentAccount();
            }
            return this.currentAccountSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCurrentAccount = function () {
        var _this = this;
        if (!this.currentAccountSubject) {
            this.currentAccountSubject = new BehaviorSubject(undefined);
        }
        this.dataService.Account_GetCurrentAccount().subscribe(function (model) {
            _this.currentAccountSubject.next(model);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "moduleCompanyView", {
        get: function () {
            if (!this.moduleCompanyViewSubject) {
                this.loadModuleCompanyView();
            }
            return this.moduleCompanyViewSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadModuleCompanyView = function () {
        var _this = this;
        if (!this.moduleCompanyViewSubject) {
            this.moduleCompanyViewSubject = new BehaviorSubject([]);
        }
        this.dataService.Modules_GetModulesForCurrentCompany().subscribe(function (data) {
            _this.sessionService.feature.modules = data;
            _this.moduleCompanyViewSubject.next(data);
            _this.registerDefaultRoutes();
        });
    };
    Object.defineProperty(StaticDataService.prototype, "companyUsers", {
        get: function () {
            if (!this.companyUsersSubject) {
                this.loadCompanyUsers();
            }
            return this.companyUsersSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCompanyUsers = function () {
        var _this = this;
        if (!this.companyUsersSubject) {
            this.companyUsersSubject = new BehaviorSubject([]);
        }
        this.dataService.CompanyUsers_GetCompanyUsers().subscribe(function (companyUsers) {
            _this.companyUsersSubject.next(companyUsers);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "usersCompanies", {
        get: function () {
            if (!this.usersCompaniesSubject) {
                this.loadUsersCompanies();
            }
            return this.usersCompaniesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadUsersCompanies = function () {
        var _this = this;
        if (!this.usersCompaniesSubject) {
            this.usersCompaniesSubject = new BehaviorSubject([]);
        }
        this.dataService.Users_GetCompanies().subscribe(function (usersCompanies) {
            _this.usersCompaniesSubject.next(usersCompanies);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "companiesSimple", {
        get: function () {
            if (!this.companiesSimpleSubject) {
                this.loadCompaniesSimple();
            }
            return this.companiesSimpleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCompaniesSimple = function () {
        var _this = this;
        if (!this.usersCompaniesSubject) {
            this.companiesSimpleSubject = new BehaviorSubject([]);
        }
        this.dataService.Companies_GetAllSimple().subscribe(function (usersCompanies) {
            _this.companiesSimpleSubject.next(usersCompanies);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "Bank", {
        get: function () {
            if (!this.bankSubject) {
                this.loadBank();
            }
            return this.bankSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadBank = function () {
        var _this = this;
        if (!this.bankSubject) {
            this.bankSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetBanks().subscribe(function (data) {
            _this.bankSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "BankHoliday", {
        get: function () {
            if (!this.bankHolidaySubject) {
                this.loadBankHoliday();
            }
            return this.bankHolidaySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadBankHoliday = function () {
        var _this = this;
        if (!this.bankHolidaySubject) {
            this.bankHolidaySubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetBankHolidaies().subscribe(function (data) {
            _this.bankHolidaySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "BillingPrinciple", {
        get: function () {
            if (!this.billingPrincipleSubject) {
                this.loadBillingPrinciple();
            }
            return this.billingPrincipleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadBillingPrinciple = function () {
        var _this = this;
        if (!this.billingPrincipleSubject) {
            this.billingPrincipleSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetBillingPrinciples().subscribe(function (data) {
            _this.billingPrincipleSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "departments", {
        get: function () {
            if (!this.departmenstSubject) {
                this.loadDepartments();
            }
            return this.departmenstSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadDepartments = function () {
        var _this = this;
        if (!this.departmenstSubject) {
            this.departmenstSubject = new BehaviorSubject([]);
        }
        this.dataService.Companies_GetDepartments().subscribe(function (departments) {
            departments.sort(function (a, b) {
                if (a.IsActive === b.IsActive) {
                    if (a.Name > b.Name) {
                        return 1;
                    }
                    if (a.Name < b.Name) {
                        return -1;
                    }
                    return 0;
                }
                return a.IsActive ? 1 : 0;
            });
            departments.sort(function (a, b) { return a.Name.localeCompare(b.Name); });
            _this.departmenstSubject.next(departments);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "languages", {
        get: function () {
            if (!this.languagesSubject) {
                this.loadLanguages();
            }
            return this.languagesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadLanguages = function () {
        var _this = this;
        if (!this.languagesSubject) {
            this.languagesSubject = new BehaviorSubject([]);
        }
        this.dataService.Miscellaneous_GetLanguages().subscribe(function (data) {
            _this.languagesSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "AccountType", {
        get: function () {
            if (!this.accountTypeSubject) {
                this.loadAccountType();
            }
            return this.accountTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadAccountType = function () {
        var _this = this;
        if (!this.accountTypeSubject) {
            this.accountTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetAccountTypes().subscribe(function (data) {
            _this.accountTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "City", {
        get: function () {
            if (!this.citySubject) {
                this.loadCity();
            }
            return this.citySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCity = function () {
        var _this = this;
        if (!this.citySubject) {
            this.citySubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetCities().subscribe(function (data) {
            _this.citySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "employmentTitle", {
        get: function () {
            if (!this.employmentTitleSubject) {
                this.loadEmploymentTitle();
            }
            return this.employmentTitleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadEmploymentTitle = function () {
        var _this = this;
        if (!this.employmentTitleSubject) {
            this.employmentTitleSubject = new BehaviorSubject([]);
        }
        this.dataService.Employments_GetUsedTitles().subscribe(function (model) {
            _this.employmentTitleSubject.next(model);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "CompanyType", {
        get: function () {
            if (!this.companyTypeSubject) {
                this.loadCompanyType();
            }
            return this.companyTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCompanyType = function () {
        var _this = this;
        if (!this.companyTypeSubject) {
            this.companyTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetCompanyTypes().subscribe(function (data) {
            _this.companyTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "Country", {
        get: function () {
            if (!this.countrySubject) {
                this.loadCountry();
            }
            return this.countrySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCountry = function () {
        var _this = this;
        if (!this.countrySubject) {
            this.countrySubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetCountries().subscribe(function (data) {
            _this.countrySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "Currency", {
        get: function () {
            if (!this.currencySubject) {
                this.loadCurrency();
            }
            return this.currencySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCurrency = function () {
        var _this = this;
        if (!this.currencySubject) {
            this.currencySubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetCurrencies().subscribe(function (data) {
            _this.currencySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ExternalSystem", {
        get: function () {
            if (!this.externalSystemSubject) {
                this.loadExternalSystem();
            }
            return this.externalSystemSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadExternalSystem = function () {
        var _this = this;
        if (!this.externalSystemSubject) {
            this.externalSystemSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetExternalSystems().subscribe(function (data) {
            _this.externalSystemSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ExternalSystemConfiguration", {
        get: function () {
            if (!this.externalSystemConfigurationSubject) {
                this.loadExternalSystemConfiguration();
            }
            return this.externalSystemConfigurationSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadExternalSystemConfiguration = function () {
        var _this = this;
        if (!this.externalSystemConfigurationSubject) {
            this.externalSystemConfigurationSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetExternalSystemConfigurations()
            .subscribe(function (data) {
            _this.externalSystemConfigurationSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ExternalSystemCredential", {
        get: function () {
            if (!this.externalSystemCredentialSubject) {
                this.loadExternalSystemCredential();
            }
            return this.externalSystemCredentialSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadExternalSystemCredential = function () {
        var _this = this;
        if (!this.externalSystemCredentialSubject) {
            this.externalSystemCredentialSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetExternalSystemCredentials().subscribe(function (data) {
            _this.externalSystemCredentialSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ExternalSystemOperation", {
        get: function () {
            if (!this.externalSystemOperationSubject) {
                this.loadExternalSystemOperation();
            }
            return this.externalSystemOperationSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadExternalSystemOperation = function () {
        var _this = this;
        if (!this.externalSystemOperationSubject) {
            this.externalSystemOperationSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetExternalSystemOperations().subscribe(function (data) {
            _this.externalSystemOperationSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "FinanceAccountType", {
        get: function () {
            if (!this.financeAccountTypeSubject) {
                this.loadFinanceAccountType();
            }
            return this.financeAccountTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadFinanceAccountType = function () {
        var _this = this;
        if (!this.financeAccountTypeSubject) {
            this.financeAccountTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetFinanceAccountTypes().subscribe(function (data) {
            _this.financeAccountTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ImportSpecification", {
        get: function () {
            if (!this.importSpecificationSubject) {
                this.loadImportSpecification();
            }
            return this.importSpecificationSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadImportSpecification = function () {
        var _this = this;
        if (!this.importSpecificationSubject) {
            this.importSpecificationSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetImportSpecifications().subscribe(function (data) {
            _this.importSpecificationSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "IncomeType", {
        get: function () {
            if (!this.incomeTypeSubject) {
                this.loadIncomeType();
            }
            return this.incomeTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadIncomeType = function () {
        var _this = this;
        if (!this.incomeTypeSubject) {
            this.incomeTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetIncomeTypes().subscribe(function (data) {
            _this.incomeTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "IntervalType", {
        get: function () {
            if (!this.intervalTypeSubject) {
                this.loadIntervalType();
            }
            return this.intervalTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadIntervalType = function () {
        var _this = this;
        if (!this.intervalTypeSubject) {
            this.intervalTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetIntervalTypes().subscribe(function (data) {
            _this.intervalTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "IntegrationStatus", {
        get: function () {
            if (!this.integrationStatusSubject) {
                this.loadIntegrationStatus();
            }
            return this.integrationStatusSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadIntegrationStatus = function () {
        var _this = this;
        if (!this.integrationStatusSubject) {
            this.integrationStatusSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetIntegrationStatuses().subscribe(function (data) {
            _this.integrationStatusSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ModulePackage", {
        get: function () {
            if (!this.modulePackageSubject) {
                this.loadModulePackage();
            }
            return this.modulePackageSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadModulePackage = function () {
        var _this = this;
        if (!this.modulePackageSubject) {
            this.modulePackageSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetModulePackages().subscribe(function (data) {
            _this.modulePackageSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "Month", {
        get: function () {
            if (!this.monthSubject) {
                this.loadMonth();
            }
            return this.monthSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadMonth = function () {
        var _this = this;
        if (!this.monthSubject) {
            this.monthSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetMonths().subscribe(function (data) {
            _this.monthSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "Municipality", {
        get: function () {
            if (!this.municipalitySubject) {
                this.loadMunicipality();
            }
            return this.municipalitySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadMunicipality = function () {
        var _this = this;
        if (!this.municipalitySubject) {
            this.municipalitySubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetMunicipalities().subscribe(function (data) {
            _this.municipalitySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "OvertimeSupplementPrinciple", {
        get: function () {
            if (!this.overtimeSupplementPrincipleSubject) {
                this.loadOvertimeSupplementPrinciple();
            }
            return this.overtimeSupplementPrincipleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadOvertimeSupplementPrinciple = function () {
        var _this = this;
        if (!this.overtimeSupplementPrincipleSubject) {
            this.overtimeSupplementPrincipleSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetOvertimeSupplementPrinciples()
            .subscribe(function (data) {
            _this.overtimeSupplementPrincipleSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "PaymentMethod", {
        get: function () {
            if (!this.paymentMethodSubject) {
                this.loadPaymentMethod();
            }
            return this.paymentMethodSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadPaymentMethod = function () {
        var _this = this;
        if (!this.paymentMethodSubject) {
            this.paymentMethodSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetPaymentMethods().subscribe(function (data) {
            _this.paymentMethodSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "PensionBaseCalculationMethod", {
        get: function () {
            if (!this.pensionBaseCalculationMethodSubject) {
                this.loadPensionBaseCalculationMethod();
            }
            return this.pensionBaseCalculationMethodSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadPensionBaseCalculationMethod = function () {
        var _this = this;
        if (!this.pensionBaseCalculationMethodSubject) {
            this.pensionBaseCalculationMethodSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetPensionBaseCalculationMethods()
            .subscribe(function (data) {
            _this.pensionBaseCalculationMethodSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "PensionProvider", {
        get: function () {
            if (!this.pensionProviderSubject) {
                this.loadPensionProvider();
            }
            return this.pensionProviderSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadPensionProvider = function () {
        var _this = this;
        if (!this.pensionProviderSubject) {
            this.pensionProviderSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetPensionProviders().subscribe(function (data) {
            _this.pensionProviderSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ReportArea", {
        get: function () {
            if (!this.reportAreaSubject) {
                this.loadReportArea();
            }
            return this.reportAreaSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadReportArea = function () {
        var _this = this;
        if (!this.reportAreaSubject) {
            this.reportAreaSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetReportAreas().subscribe(function (data) {
            _this.reportAreaSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ReportParameter", {
        get: function () {
            if (!this.reportParameterSubject) {
                this.loadReportParameter();
            }
            return this.reportParameterSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadReportParameter = function () {
        var _this = this;
        if (!this.reportParameterSubject) {
            this.reportParameterSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetReportParameters().subscribe(function (data) {
            _this.reportParameterSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "Role", {
        get: function () {
            if (!this.roleSubject) {
                this.loadRole();
            }
            return this.roleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadRole = function () {
        var _this = this;
        if (!this.roleSubject) {
            this.roleSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetRoles().subscribe(function (data) {
            _this.roleSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "SalaryCycle", {
        get: function () {
            if (!this.salaryCycleSubject) {
                this.loadSalaryCycle();
            }
            return this.salaryCycleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadSalaryCycle = function () {
        var _this = this;
        if (!this.salaryCycleSubject) {
            this.salaryCycleSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetSalaryCycles().subscribe(function (data) {
            _this.salaryCycleSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "SalarySummaryCategory", {
        get: function () {
            if (!this.salarySummaryCategorySubject) {
                this.loadSalarySummaryCategory();
            }
            return this.salarySummaryCategorySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadSalarySummaryCategory = function () {
        var _this = this;
        if (!this.salarySummaryCategorySubject) {
            this.salarySummaryCategorySubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetSalarySummaryCategories().subscribe(function (data) {
            _this.salarySummaryCategorySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "SalaryRecordPersistenceType", {
        get: function () {
            if (!this.salaryRecordPersistenceTypeSubject) {
                this.loadSalaryRecordPersistenceType();
            }
            return this.salaryRecordPersistenceTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadSalaryRecordPersistenceType = function () {
        var _this = this;
        if (!this.salaryRecordPersistenceTypeSubject) {
            this.salaryRecordPersistenceTypeSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetSalaryRecordPersistenceTypes()
            .subscribe(function (data) {
            _this.salaryRecordPersistenceTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "SalaryTypeCategory", {
        get: function () {
            if (!this.salaryTypeCategorySubject) {
                this.loadSalaryTypeCategory();
            }
            return this.salaryTypeCategorySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadSalaryTypeCategory = function () {
        var _this = this;
        if (!this.salaryTypeCategorySubject) {
            this.salaryTypeCategorySubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetSalaryTypeCategories().subscribe(function (data) {
            _this.salaryTypeCategorySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "StatisticsEmploymentPrinciple", {
        get: function () {
            if (!this.statisticsEmploymentPrincipleSubject) {
                this.loadStatisticsEmploymentPrinciple();
            }
            return this.statisticsEmploymentPrincipleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadStatisticsEmploymentPrinciple = function () {
        var _this = this;
        if (!this.statisticsEmploymentPrincipleSubject) {
            this.statisticsEmploymentPrincipleSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetStatisticsEmploymentPrinciples()
            .subscribe(function (data) {
            _this.statisticsEmploymentPrincipleSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "StatisticsEmploymentTerms", {
        get: function () {
            if (!this.statisticsEmploymentTermsSubject) {
                this.loadStatisticsEmploymentTerms();
            }
            return this.statisticsEmploymentTermsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadStatisticsEmploymentTerms = function () {
        var _this = this;
        if (!this.statisticsEmploymentTermsSubject) {
            this.statisticsEmploymentTermsSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetStatisticsEmploymentTermses()
            .subscribe(function (data) {
            _this.statisticsEmploymentTermsSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "StatisticsJobStatus", {
        get: function () {
            if (!this.statisticsJobStatusSubject) {
                this.loadStatisticsJobStatus();
            }
            return this.statisticsJobStatusSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadStatisticsJobStatus = function () {
        var _this = this;
        if (!this.statisticsJobStatusSubject) {
            this.statisticsJobStatusSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetStatisticsJobStatuses().subscribe(function (data) {
            _this.statisticsJobStatusSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "StatisticsRecipient", {
        get: function () {
            if (!this.statisticsRecipientSubject) {
                this.loadStatisticsRecipient();
            }
            return this.statisticsRecipientSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadStatisticsRecipient = function () {
        var _this = this;
        if (!this.statisticsRecipientSubject) {
            this.statisticsRecipientSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetStatisticsRecipients().subscribe(function (data) {
            _this.statisticsRecipientSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "StatisticsSalaryPrinciple", {
        get: function () {
            if (!this.statisticsSalaryPrincipleSubject) {
                this.loadStatisticsSalaryPrinciple();
            }
            return this.statisticsSalaryPrincipleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadStatisticsSalaryPrinciple = function () {
        var _this = this;
        if (!this.statisticsSalaryPrincipleSubject) {
            this.statisticsSalaryPrincipleSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetStatisticsSalaryPrinciples()
            .subscribe(function (data) {
            _this.statisticsSalaryPrincipleSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "TaxCardType", {
        get: function () {
            if (!this.taxCardTypeSubject) {
                this.loadTaxCardType();
            }
            return this.taxCardTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadTaxCardType = function () {
        var _this = this;
        if (!this.taxCardTypeSubject) {
            this.taxCardTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetTaxCardTypes().subscribe(function (data) {
            _this.taxCardTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "TimeEntryRecordAcrossSalaryPeriodStrategy", {
        get: function () {
            if (!this.timeEntryRecordAcrossSalaryPeriodStrategySubject) {
                this.loadTimeEntryRecordAcrossSalaryPeriodStrategy();
            }
            return this.timeEntryRecordAcrossSalaryPeriodStrategySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadTimeEntryRecordAcrossSalaryPeriodStrategy = function () {
        var _this = this;
        if (!this.timeEntryRecordAcrossSalaryPeriodStrategySubject) {
            this.timeEntryRecordAcrossSalaryPeriodStrategySubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetTimeEntryRecordAcrossSalaryPeriodStrategies()
            .subscribe(function (data) {
            _this.timeEntryRecordAcrossSalaryPeriodStrategySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "TimeEntryStatus", {
        get: function () {
            if (!this.timeEntryStatusSubject) {
                this.loadTimeEntryStatus();
            }
            return this.timeEntryStatusSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadTimeEntryStatus = function () {
        var _this = this;
        if (!this.timeEntryStatusSubject) {
            this.timeEntryStatusSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetTimeEntryStatuses().subscribe(function (data) {
            _this.timeEntryStatusSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "SalaryRecordsOverviewDisplayMode", {
        get: function () {
            if (!this.salaryRecordsOverviewDisplayModeSubject) {
                this.loadSalaryRecordsOverviewDisplayMode();
            }
            return this.salaryRecordsOverviewDisplayModeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadSalaryRecordsOverviewDisplayMode = function () {
        var _this = this;
        if (!this.salaryRecordsOverviewDisplayModeSubject) {
            this.salaryRecordsOverviewDisplayModeSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetSalaryRecordsOverviewDisplayModes()
            .subscribe(function (data) {
            _this.salaryRecordsOverviewDisplayModeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "UnitTypes", {
        get: function () {
            if (!this.unitTypesSubject) {
                this.loadUnitTypes();
            }
            return this.unitTypesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadUnitTypes = function () {
        var _this = this;
        if (!this.unitTypesSubject) {
            this.unitTypesSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetUnitTypes().subscribe(function (data) {
            _this.unitTypesSubject.next(data);
            Global.UNIT_TYPES = data;
        });
    };
    Object.defineProperty(StaticDataService.prototype, "VacationProvider", {
        get: function () {
            if (!this.vacationProviderSubject) {
                this.loadVacationProvider();
            }
            return this.vacationProviderSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadVacationProvider = function () {
        var _this = this;
        if (!this.vacationProviderSubject) {
            this.vacationProviderSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetVacationProviders().subscribe(function (data) {
            _this.vacationProviderSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "VacationType", {
        get: function () {
            if (!this.vacationTypeSubject) {
                this.loadVacationType();
            }
            return this.vacationTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadVacationType = function () {
        var _this = this;
        if (!this.vacationTypeSubject) {
            this.vacationTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetVacationTypes().subscribe(function (data) {
            _this.vacationTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "VacationTypeGL", {
        get: function () {
            if (!this.vacationTypeSubjectGL) {
                this.loadVacationTypeGL();
            }
            return this.vacationTypeSubjectGL.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadVacationTypeGL = function () {
        var _this = this;
        if (!this.vacationTypeSubjectGL) {
            this.vacationTypeSubjectGL = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetVacationTypeGLs().subscribe(function (data) {
            _this.vacationTypeSubjectGL.next(data);
        });
    };
    StaticDataService.prototype.getCurrentdate = function (isEnddate, selectedDate) {
        var today = new Date();
        if (selectedDate !== undefined) {
            today = selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
        }
        var yyyy = today.getFullYear();
        var mm = today.getMonth();
        var dd = today.getDate();
        if (isEnddate === true) {
            dd = 0;
            mm = today.getMonth() + 1;
        }
        var hour = today.getTimezoneOffset() / 60;
        hour = hour === 12 || hour === -12 ? 0 : hour;
        if (hour > 0) {
            return new Date(yyyy, mm, dd, hour, 0, 0);
        }
        return new Date(yyyy, mm, dd, -hour, 0, 0);
    };
    StaticDataService.prototype.dataURItoBlob = function (dataURI, type) {
        var byteString = window.atob(dataURI);
        var arrayBuffer = new ArrayBuffer(byteString.length);
        var int8Array = new Uint8Array(arrayBuffer);
        for (var i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([int8Array], { type: type });
        return blob;
    };
    StaticDataService.prototype.checkModelRecord = function (record) {
        Object.keys(record).forEach(function (key) {
            if (record[key] === undefined) {
                record[key] = null;
            }
        });
        return record;
    };
    StaticDataService.prototype.GetIntegration2Operation = function (exportNewPayrollBatchId, Integration2Operation, integrationName) {
        var _this = this;
        if (this.observable) {
            // if `this.observable` is set then the request is in progress
            // return the `Observable` for the ongoing request
            return this.observable;
        }
        else {
            // create the request, store the `Observable` for subsequent subscribers
            this.observable = this.dataService.Integrations_GetIntegrationsByOperation(exportNewPayrollBatchId).pipe(map(function (model) {
                Integration2Operation = model;
                if (Integration2Operation && Integration2Operation.length > 0) {
                    integrationName = Integration2Operation[0].Name;
                    setTimeout(function () {
                        _this.observable = undefined;
                    }, 1000);
                }
            }));
            return this.observable.subscribe();
        }
    };
    return StaticDataService;
}());
export { StaticDataService };
