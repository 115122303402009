import { ElementRef, EventEmitter, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as srcDoc from 'srcdoc-polyfill';
import { SessionService } from '../Session/SessionService';
var IFrameDialogComponent = /** @class */ (function () {
    function IFrameDialogComponent(sanitizer, sessionService, renderer, elementRef) {
        this.sanitizer = sanitizer;
        this.sessionService = sessionService;
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.title = ' ';
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.helpDownloadVisible = false;
    }
    Object.defineProperty(IFrameDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                if (value === false) {
                    if (this.sessionService.browser.isHybridApp) {
                        window.screen.orientation.lock('portrait');
                    }
                }
                this.visibleValue = value;
                this.contentLoaded = false;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IFrameDialogComponent.prototype, "iframeContent", {
        set: function (value) {
            if (this.iframeContentValue !== value) {
                this.iframeContentValue = value;
                this.contentLoaded = false;
                this.safeContent = this.sanitizer.bypassSecurityTrustHtml(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IFrameDialogComponent.prototype, "isEdge", {
        get: function () {
            return this.sessionService.browser.isEdge;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IFrameDialogComponent.prototype, "isFirefox", {
        get: function () {
            return this.sessionService.browser.isFirefox;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IFrameDialogComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    IFrameDialogComponent.prototype.iframeLoaded = function () {
        if (this.isEdge && this.iframeContentValue && !this.contentLoaded) {
            // https://stackoverflow.com/questions/42257549/angular-2-srcdoc-polyfill-doesnt-work
            srcDoc.set(this.iframe.nativeElement, this.iframeContentValue);
            this.contentLoaded = true;
        }
    };
    return IFrameDialogComponent;
}());
export { IFrameDialogComponent };
