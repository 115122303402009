<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeEmployment.Tax E-Income' | translate }}</legend>
    <combobox-edit-control
      [editMode]="editMode"
      [label]="'EmployeeEmployment.Income type' | translate"
      [comboboxDataSource]="incomeTypes"
      [(isDirty)]="childIsDirty[0]"
      [(value)]="model.IncomeTypeId"
      (valueChange)="onIncomeTypeChanged()"
      [textField]="'Name'"
      [idField]="'Id'"
      [tooltipField]="'Description'"
    ></combobox-edit-control>
    <check-edit
      [label]="'EmployeeEmployment.Works elsewhere' | translate"
      [(value)]="model.WorksElsewhere"
      [(isDirty)]="childIsDirty[1]"
      [editMode]="editMode"
      (valueChange)="onChange()"
    ></check-edit>
    <check-edit
      [label]="'EmployeeEmployment.Temp worker (ATP)' | translate"
      [(value)]="model.IsAtpTempWorker"
      [(isDirty)]="childIsDirty[2]"
      [editMode]="editMode"
      (valueChange)="onChange()"
    ></check-edit>
    <check-edit
      *ngIf="iscompanyTemplatesTab"
      [label]="'EmployeeGeneral.AllowExtendedTaxCardDeductions' | translate"
      [(value)]="model.AllowExtendedTaxCardDeductions"
      [(isDirty)]="childIsDirty[3]"
      [editMode]="editMode && admin"
      (valueChange)="onChange()"
      [helpIcon]="'Info'"
      [helpLink]="'Info'"
      [helpTooltip]="'EmployeeGeneral.helpTooltipAllowExtendedTaxCardDeductions' | translate"
    ></check-edit>
    <module-check [moduleId]="8" [hasModule]="false">
      <check-edit
        [label]="'EmployeeGeneral.UseDailyDeductionBasedOnTimeEntries' | translate"
        [editMode]="editMode && admin"
        [(isDirty)]="childIsDirty[5]"
        [(value)]="model.UseTaxDeductionPerWorkDay"
        (valueChange)="onChange()"
        [helpIcon]="'Info'"
        [helpLink]="'Info'"
        [isOpenLink]="false"
        [helpTooltip]="'EmployeeGeneral.UseDailyDeductionBasedOnTimeEntriesToolTip' | translate"
      ></check-edit>
    </module-check>
  </fieldset>
</form>

<app-dialog (action)="onWarningDialogAction($event)" [(visible)]="warningDialogVisible">
  {{ 'EmployeeEmployment.IncomeType5WarningMessage' | translate }}
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Continue'"></app-dialog-action>
</app-dialog>
