import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUserEmploymentTemplate } from '../../../../Services/ApiModel';

@Component({
  selector: 'company-template-list',
  templateUrl: './CompanyTemplateList.component.html'
})
export class CompanyTemplateListComponent {
  @Input() public templates: IUserEmploymentTemplate[];
  @Input() public locked = false;

  private currentEditmode = false;
  @Input()
  public get isEditMode(): boolean {
    return this.currentEditmode;
  }
  public set isEditMode(value: boolean) {
    setTimeout(() => {
      if (this.templates && this.templates.length > 0) {
        if (value) {
          this.templates.forEach((model: any) => {
            model.isActiveFeild = model.Id === this.selectedId ? 'activeCell' : '';
          });
          this.refreshList = true;
        } else {
          this.templates.forEach((model: any) => {
            model.isActiveFeild = '';
          });
        }
      }
    });
    this.currentEditmode = value;
  }

  private selectedIdValue: number;
  @Input()
  public get selectedId(): number {
    return this.selectedIdValue;
  }
  public set selectedId(value: number) {
    if (this.selectedIdValue !== value) {
      this.selectedIdValue = value;
      setTimeout(() => this.selectedIdChange.emit(value));
      // if (!this.isEditMode) {
      //     setTimeout(() => this.selectedIdChange.emit(value));
      // }
    }
  }
  @Output() public selectedIdChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() public selectionChange: EventEmitter<IUserEmploymentTemplate> = new EventEmitter<IUserEmploymentTemplate>();

  @Input() public get refreshList(): boolean {
    return this.refreshListValue;
  }
  public set refreshList(value: boolean) {
    // if (this.refreshListValue !== value) {
    //     this.refreshListValue = value;
    //     this.triggerGridRefresh = true;
    // }
    if (value) {
      this.triggerGridRefresh = true;
    }
  }

  public triggerGridRefresh: boolean;
  private refreshListValue: boolean;

  public onSelectionChange(template: IUserEmploymentTemplate): void {
    // setTimeout(() => this.selectedIdChange.emit(template ? template.Id : undefined));
    this.selectedIdChange.emit(template ? template.Id : undefined);
    this.selectionChange.emit(template);
  }

  public get classDisableSelect(): string {
    if (this.isEditMode) {
      return 'disableSelect';
    }
    return 'GridSelect';
  }
}
