/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./RadioEditOption.compoenet";
var styles_RadioEditOptionComponent = [];
var RenderType_RadioEditOptionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RadioEditOptionComponent, data: {} });
export { RenderType_RadioEditOptionComponent as RenderType_RadioEditOptionComponent };
export function View_RadioEditOptionComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_RadioEditOptionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-radio-edit-option", [], null, null, null, View_RadioEditOptionComponent_0, RenderType_RadioEditOptionComponent)), i0.ɵdid(1, 49152, null, 0, i1.RadioEditOptionComponent, [], null, null)], null, null); }
var RadioEditOptionComponentNgFactory = i0.ɵccf("app-radio-edit-option", i1.RadioEditOptionComponent, View_RadioEditOptionComponent_Host_0, { label: "label", value: "value", addonLink: "addonLink", isCheckModule: "isCheckModule", ModuleId: "ModuleId", moduleCheckWidth: "moduleCheckWidth" }, {}, []);
export { RadioEditOptionComponentNgFactory as RadioEditOptionComponentNgFactory };
