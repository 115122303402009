import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ElementRef } from '@angular/core';
import { ControlComponentBase } from '../../Common/ControlComponentBase';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { IconDefinitionsComponent } from './IconDefinitions.component';

@Component({
  selector: 'app-icon',
  templateUrl: './Icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent extends ControlComponentBase<string> {
  @Input() public icon: string;
  @Input() public size: string;
  @Input() public tooltip: string;
  @Input() public link: string;
  @Input() public iconClass: string;
  @Input() public istooltipHTML = false;
  @Input() public disable = false;
  @Input() public isSelfService = '';
  @Input() public isOpenLink = true;
  @Input() public tooltipWidth: number;

  public get disableClass(): string {
    if (this.disable) {
      return 'disableClass';
    }
    return '';
  }

  @Output() public click: EventEmitter<void> = new EventEmitter<void>();

  // public get absoluteUrl(): string { return window.location.href; }
  public get absoluteUrl(): string {
    return '';
  }

  private get isIOSMobileApp(): boolean {
    return this.sessionService.browser.iOSMobileDevice;
  }

  constructor(elementRef: ElementRef, private sessionService: SessionService, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
  }

  public get class(): string {
    if (this.iconClass) {
      return this.iconClass;
    } else {
      let result = 'Icon ';
      const icon: { class: string; svg: string } = IconDefinitionsComponent.icons[this.icon];
      if (icon) {
        result += icon.class ? ' ' + icon.class : '';
        result += this.size ? ' Icon--' + this.size : '';
      }

      return result;
    }
  }

  public get iconSvg(): string {
    const icon: { class: string; svg: string } = IconDefinitionsComponent.icons[this.icon];
    if (icon) {
      return IconDefinitionsComponent.icons[this.icon].svg;
    } else {
      console.error('Unknown icon:', this.icon);
      return 'share';
    }
  }

  public onIconClick(): void {
    if (this.link && !this.isIOSMobileApp && this.isOpenLink) {
      window.open(this.link);
    } else {
      this.click.emit();
    }
  }
}
