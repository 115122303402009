import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Global } from '../../Common/Global';
import { IAccountCompanyCreationRequest, ICompany, ICvrCompany, ISimpleCompany } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { AccountantCompaniesService } from './AccountantCompanies.service';

@Component({
  selector: 'app-accountant-add-new-company-dialog',
  templateUrl: './AccountantAddNewCompanyDialog.component.html'
})
export class AccountantAddNewCompanyDialogComponent {
  public visibleValue = false;
  @Input()
  get visible(): boolean {
    return this.visibleValue;
  }
  set visible(value: boolean) {
    this.visibleValue = value;
    this.visibleChange.emit(value);
    if (!value) {
      this.cvrNumber = undefined;
      this.seNumber = undefined;
      this.name = undefined;
      this.modulePackage = -1;
      this.salaryTypesFrom = undefined;
      this.templatesFrom = undefined;
      this.grantAccess = false;
      this.accessRole = undefined;
      this.seNumberRequired = false;
      this.CVRNumberValid = false;
      this.nameValid = false;
      this.seNumberRequired = false;
      this.acceptTerms = false;
    }
  }
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() createSuccess: EventEmitter<any> = new EventEmitter<any>();

  public get isValid(): boolean {
    return (
      this.cvrNumber &&
      this.name &&
      this.acceptTerms &&
      (!this.grantAccess || !!this.accessRole) &&
      (!this.seNumberRequired || !!this.seNumber)
    );
  }

  public cvrNumber: string;
  public CVRNumberValid = false;
  public seNumber: string;
  public seNumberRequired: boolean;
  public name: string;
  public nameValid = false;
  public modulePackage = -1;
  public salaryTypesFrom: number;
  public templatesFrom: number;
  public grantAccess: boolean;
  public accessRole: number;
  public acceptTerms: boolean;
  public isWaittingCreat = false;
  public sourceCompanyFilter: any[] = [];

  constructor(
    public service: AccountantCompaniesService,
    public staticData: StaticDataService,
    private api: RxDataService
  ) {
    this.staticData.companiesSimple.subscribe((data: ISimpleCompany[]) => {
      if (data && data.length > 0) {
        data.forEach((model: any) => {
          model.Relevant = model.Name + (Global.SESSION.HasBackendAccess ? ' (' + model.Id + ')' : '');
        });
        this.sourceCompanyFilter = data.filter((model: any) => model.RoleId >= 30);
      }
    });
  }

  public lookupCompany(): void {
    if (this.cvrNumber) {
      this.api.Companies_GetByCvr(this.cvrNumber).subscribe((data: ICvrCompany) => {
        if (data) {
          this.seNumberRequired = data.ExistsInGratisal;
          this.name = data.Name;
        } else {
          this.seNumberRequired = false;
        }
      });
    } else {
      this.seNumberRequired = false;
    }
  }

  public isValidValue(): void {
    if (this.cvrNumber) {
      this.CVRNumberValid = true;
    }
    if (this.name) {
      this.nameValid = true;
    }
  }

  public dialogAction(action: string): void {
    if (action === 'Create') {
      const request: IAccountCompanyCreationRequest = {
        CompanyName: this.name,
        DefaultRoleId: this.accessRole,
        PackageLevel: this.modulePackage === -1 ? undefined : this.modulePackage,
        VatRegistrationNumber: this.cvrNumber,
        SecondaryVatRegistrationNumber: this.seNumber,
        CopySalaryAndTimeEntryTypesFromCompanyId: this.salaryTypesFrom,
        CopyTemplatesFromCompanyId: this.templatesFrom
      };

      // if (this.salaryTypesFrom) {
      //   const match: RegExpExecArray = new RegExp(/\[(\d+)\]$/).exec(this.salaryTypesFrom);
      //   if (match && match.length === 2) {
      //     request.CopySalaryAndTimeEntryTypesFromCompanyId = parseInt(match[1], 10);
      //   }
      // }

      // if (this.templatesFrom) {
      //   const match: RegExpExecArray = new RegExp(/\[(\d+)\]$/).exec(this.templatesFrom);
      //   if (match && match.length === 2) {
      //     request.CopyTemplatesFromCompanyId = parseInt(match[1], 10);
      //   }
      // }

      this.isWaittingCreat = true;
      this.api.Account_CreateCompany(request).subscribe(
        (model: ICompany) => {
          this.service.filter = this.name;
          this.createSuccess.emit(model.Id);
          this.isWaittingCreat = false;
          this.visible = false;
        },
        () => {
          this.visible = false;
          this.isWaittingCreat = false;
        }
      );
    }
  }
}
