import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Raven from 'raven-js';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Constants } from '../Common/Constants';
import { Utf8ArrayToStr } from '../Common/Utf8';
import { Broadcaster } from './Broadcaster';
import { ModalService } from './SharedServices/ModalService';
import { SessionService } from './SharedServices/Session/SessionService';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private modalService: ModalService,
    private broadcaster: Broadcaster,
    private sessionService: SessionService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: any, caught: Observable<any>) => {
        if (err instanceof HttpErrorResponse) {
          const error: HttpErrorResponse = err;
          switch (error.status) {
            case 0:
            case -1:
              const networkError: string = this.sessionService.modalTranslations['Error.NetworkError'];
              const localLanguageCode: string = localStorage.getItem('localLanguageCode');
              let networkErrorMessage =
                'Gratisal har mistet forbindelsen til serveren. Dette kan være et midlertidigt problem, især hvis du bruger wifi eller mobilt internet. Tjek din internetforbindelse og prøv igen, eller kontakt os hvis problemet fortsætter.';
              if (localLanguageCode === 'en') {
                networkErrorMessage =
                  "Gratisal could not connect to the server. This can happen intermittently, especially if you're using wifi or mobile data. Check your internet connection and try again, or contact us if the issue persists.";
              }
              this.modalService.alert('', networkError ? networkError : networkErrorMessage);
              break;
            case 400:
              if (error.error.Message) {
                this.modalService.alert('', error.error.Message);
              } else {
                let decodedString: string;
                try {
                  const array: Uint8Array = new Uint8Array(error.error);
                  decodedString = Utf8ArrayToStr(array);
                  const obj: any = JSON.parse(decodedString);
                  if (obj && obj.Message) {
                    this.modalService.alert('', obj.Message);
                  }
                } catch (e) {
                  if (decodedString) {
                    this.modalService.alert('', decodedString);
                  }
                }
              }

              break;
            case 401:
              // if (this.sessionService.IsAuthenticated) {
              //   this.sessionService.IsAuthenticated = false;
              //   this.broadcaster.broadcast(Constants.SESSION_EXPIRED);
              // }
              this.broadcaster.broadcast(Constants.SESSION_EXPIRED);
              break;
            case 404:
              this.modalService.alert('', this.sessionService.errorMessage404);
              break;
            default:
              if (this.sessionService.checkIsAliveActive === true && error.status === 500) {
                this.sessionService.checkIsAliveActive = false;
                break;
              }
              let message: string = error.statusText;
              if (error.url) {
                message += ' - ' + error.url;
              }

              Raven.captureException(new Error(message), { extra: error });
              break;
          }
        }

        ////return of([]);
        return throwError(err);
      })
    );
  }
}
