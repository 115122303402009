import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IUserCompanyAccess } from '../Services/ApiModel';
import { RxDataService } from '../Services/RxDataService';

export abstract class AccountantServiceBase {
  private editModeValue: boolean;
  public get editMode(): boolean {
    return this.editModeValue;
  }
  public set editMode(value: boolean) {
    this.editModeValue = value;
  }

  public get roles(): Observable<IUserCompanyAccess[]> {
    return this.rolesSubject.asObservable();
  }

  private filterValue: string;
  public get filter(): string {
    return this.filterValue;
  }
  public set filter(value: string) {
    this.filterValue = value;
    this.filterSubject.next(value);
  }

  private currentPageValue = 0;
  public get currentPage(): number {
    return this.currentPageValue;
  }
  public set currentPage(value: number) {
    this.currentPageValue = value;
  }

  private selectedIdValue: number;
  public get selectedId(): number {
    return this.selectedIdValue;
  }
  public set selectedId(value: number) {
    if (this.selectedIdValue !== value) {
      this.selectedIdValue = value;
      this.getRoles();
    }
  }

  protected rolesSubject: BehaviorSubject<IUserCompanyAccess[]> = new BehaviorSubject<IUserCompanyAccess[]>([]);
  private filterSubject: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(protected api: RxDataService) {
    this.loadData();
    this.filterSubject
      .asObservable()
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(() => this.applyFilter());
  }

  public abstract loadData(): void;

  public save(event: any): void {
    if (event && event.dataItem) {
      if (!event.dataItem.RoleId) {
        event.dataItem.RoleId = 0;
      }
      this.api
        .Account_AssignUserAccessToCompany(
          event.dataItem.CompanyId,
          event.dataItem.UserId,
          event.dataItem.RoleId,
          event.dataItem.IsPaymentApprover
        )
        .subscribe(
          (): void => {
            this.getRoles();
          },
          (): void => {
            this.getRoles();
          }
        );
    }
  }

  public discard(): void {
    this.getRoles();
  }

  protected abstract getRoles(): void;

  protected abstract applyFilter(): void;
}
