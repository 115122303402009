import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import { IIntervalType } from '../../../../Services/ApiModel';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'seniority-supplement',
  templateUrl: './SenioritySupplement.component.html'
})
export class SenioritySupplementComponent extends FormComponentBase implements OnInit {
  @Input() templateId: number;
  @Input() seniority: any[];

  private visibleValue = false;
  @Input()
  get visible(): boolean {
    return this.visibleValue;
  }
  set visible(value: boolean) {
    if (this.visibleValue !== value) {
      if (value) {
        document.getElementById('seniorityDialog').addEventListener('keydown', (event: any): void => {
          this.onKeyDown(event);
        });
      } else {
        document.getElementById('seniorityDialog').removeEventListener('keydown', (event: any): void => {
          //this.onKeyDown(event);
        });
      }
      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
  }
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public allowCustom = true;
  public intervalTypes: IIntervalType[];
  public numericOptions: any = {
    format: 'n2',
    decimals: 2,
    step: 1,
    min: 0,
    spinners: false
  };

  private dirty = false;

  constructor(private staticDataService: StaticDataService, public sessionService: SessionService) {
    super();
  }

  public ngOnInit(): void {
    // document.addEventListener("keydown", (event: any): void => {
    //     this.onKeyDown(event);
    // });
    this.staticDataService.IntervalType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IIntervalType[]) => (this.intervalTypes = data)
    );
    this.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (value) {
        this.dirty = false;
      }
    });
  }

  protected hasChanges(): boolean {
    return this.dirty;
  }

  public saveChanges(args: CellCloseEvent): void {
    ////let dataItem: any = args.dataItem ? args.dataItem : args;
    this.dirty = true;
    this.onChange();
  }

  public addSeniorityHandler(dataItem: any): void {
    const defaultDataItem: any = this.createNewDataItem();
    Object.assign(dataItem, defaultDataItem);
    this.dirty = true;
    this.onChange();
  }

  public removeSeniorityHandler(args: any): void {
    // if (args && args.Id) {
    //     this.dirty = true;
    //     this.onChange();
    // } else {
    //    let dataIndex: any = this.seniority.indexOf(args);
    //    this.seniority.splice(dataIndex, 1);
    // }
    this.dirty = true;
    this.onChange();
  }

  private createNewDataItem(): any {
    return {
      Id: null,
      EmploymentTemplateId: this.templateId,
      SeniorityMonthsStart: 0,
      SeniorityMonthsEnd: 0,
      SupplementAmount: 0,
      SupplementIntervalType: this.intervalTypes ? this.intervalTypes[0].Id : null
    };
  }

  public selectedItem: any;
  public refreshData = false;
  private keyPress: any = { 17: false, 18: false, 107: false, 109: false };
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: any): void {
    if (this.visible) {
      if (this.editMode) {
        if (event.keyCode in this.keyPress) {
          this.keyPress[event.keyCode] = true;
          if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
            const defaultDataItem: any = {};
            this.addSeniorityHandler(defaultDataItem);
            this.setKeypress();
            this.seniority.push(defaultDataItem);
            this.refreshData = true;
          } else if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
            if (this.selectedItem) {
              this.removeSeniorityHandler(this.selectedItem);
              this.setKeypress();
              this.refreshData = true;
              this.selectedItem = undefined;
            }
          }
        } else {
          this.setKeypress();
        }
      }
    }
  }

  private setKeypress(): void {
    this.keyPress[17] = false;
    this.keyPress[18] = false;
    this.keyPress[107] = false;
    this.keyPress[109] = false;
  }
}
