import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import * as Raven from 'raven-js';
import { environment } from '../../../environments/environment';
import { Constants } from '../../Common/Constants';
import { Utf8ArrayToStr } from '../../Common/Utf8';
import { Broadcaster } from '../Broadcaster';
import { ModalService } from './ModalService';
import { SessionService } from './Session/SessionService';
var UploadService = /** @class */ (function () {
    function UploadService(modalService, sessionService, broadcaster, fileChooser, transfer, filePath) {
        this.modalService = modalService;
        this.sessionService = sessionService;
        this.broadcaster = broadcaster;
        this.fileChooser = fileChooser;
        this.transfer = transfer;
        this.filePath = filePath;
        this.fileTransfer = this.transfer.create();
        this.host = environment.apiUrl;
    }
    Object.defineProperty(UploadService.prototype, "isCordova", {
        get: function () {
            return this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    UploadService.prototype.getFileName = function (filePath) {
        return filePath ? filePath.split('/').pop() : undefined;
    };
    UploadService.prototype.openFileChooser = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.fileChooser) {
                _this.fileChooser
                    .open()
                    .then(function (uri) {
                    _this.filePath
                        .resolveNativePath(uri)
                        .then(function (resolveUri) {
                        return resolve(resolveUri);
                    })
                        .catch(function (error) {
                        return resolve(uri);
                    });
                })
                    .catch(function (err) {
                    return reject(err);
                });
            }
            else {
                return reject('No cordova file chooser found!');
            }
        });
    };
    UploadService.prototype.upload = function (api, filePath, params, trustAllHosts) {
        var _this = this;
        if (trustAllHosts === void 0) { trustAllHosts = true; }
        return new Promise(function (resolve, reject) {
            if (!api || !filePath) {
                console.log('File Transfer ::: No required parameter supllied.');
                return reject('No required parameter supllied.');
            }
            var authorization = '';
            try {
                authorization = sessionStorage.getItem('token');
            }
            catch (e) {
                _this.sessionService.isDetectedCookieDisable = true;
            }
            var server = _this.host + api;
            var options = { headers: { Authorization: authorization } };
            options.params = params ? params : undefined;
            _this.fileTransfer
                .upload(server, filePath, options, trustAllHosts)
                .then(function (result) {
                var response = JSON.parse(result.response);
                return resolve(response);
            })
                .catch(function (error) {
                _this.handleUnexpectedErrors(error);
                return reject(error);
            });
        });
    };
    UploadService.prototype.handleUnexpectedErrors = function (error) {
        var errorData = JSON.parse(error.body);
        if (!error.http_status) {
            // Network error.
            this.modalService.alert('', this.sessionService.modalTranslations['Error.NetworkError']);
        }
        else {
            switch (error.http_status) {
                case 400: // Bad request.
                    if (errorData.Message) {
                        this.modalService.alert('', errorData.Message);
                    }
                    else {
                        var array = new Uint8Array(errorData);
                        var decodedString = Utf8ArrayToStr(array);
                        var obj = JSON.parse(decodedString);
                        this.modalService.alert('', obj.Message);
                    }
                    break;
                case 401: // Not Authenticated.
                    this.sessionService.IsAuthenticated = false;
                    this.broadcaster.broadcast(Constants.SESSION_EXPIRED);
                    break;
                case 404: // Not found Api.
                    this.modalService.alert('', 'UnsuccessfulAPI.404');
                    break;
                case 500: // Internal server error.
                    var message = error.http_status;
                    if (error.target) {
                        message += ' - ' + error.target;
                    }
                    Raven.captureException(new Error(message), { extra: error });
                    break;
                default:
                    // Default error.
                    var othermessage = error.http_status;
                    if (error.target) {
                        othermessage += ' - ' + error.target;
                    }
                    Raven.captureException(new Error(othermessage), { extra: error });
                    break;
            }
        }
    };
    return UploadService;
}());
export { UploadService };
