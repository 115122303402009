<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeEmployment.Additional vacation days' | translate }}</legend>

    <numeric-edit
      [label]="'EmployeeEmployment.Hours earned per year' | translate"
      [editMode]="editMode"
      [(value)]="model.FFHoursEarnedPerYear"
      [(isDirty)]="childIsDirty[0]"
      [disable]="disable"
      [option]="NumbericTextBoxFFHoursEarnedPerYearOptions"
      (valueChange)="onChange()"
    ></numeric-edit>

    <numeric-edit
      [hidden]="IsEmptyFFHoursEarnedPerYear"
      [editMode]="editMode"
      [disable]="disable"
      [label]="'EmployeeEmployment.Months of seniority required' | translate"
      [(value)]="model.FFSeniorityRequirementMonths"
      [option]="numbericTextBoxFFSeniorityRequirementMonthsOptions"
      [(isDirty)]="childIsDirty[1]"
      (valueChange)="onChange()"
    ></numeric-edit>

    <check-edit
      [hidden]="IsEmptyFFHoursEarnedPerYear"
      [disable]="disable"
      [(isDirty)]="childIsDirty[2]"
      [label]="'EmployeeEmployment.Balance paid out upon termination' | translate"
      (valueChange)="onChange()"
      [editMode]="editMode"
      [(value)]="model.FFAutoPayout"
    ></check-edit>
  </fieldset>
</form>
