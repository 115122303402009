import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constants } from '../../../Common/Constants';
import { Global } from '../../../Common/Global';
import { ISalaryCycle, ISimpleSalaryStatement } from '../../../Services/ApiModel';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { CompanySalaryBatchesChildFormEntityContext } from '../CompanySalaryBatchesChildFormEntityContext';
import { SalaryBatchViewModel } from '../SalaryBatchViewModel';

@Component({
  selector: 'salary-batch-info',
  templateUrl: './SalaryBatchInfo.component.html'
})
export class SalaryBatchInfoComponent implements OnInit {
  @Output() public createPayrollBatch: EventEmitter<void> = new EventEmitter<void>();
  @Output() public salaryBatchAction: EventEmitter<{ action: string; context: any }> = new EventEmitter<{
    action: string;
    context: any;
  }>();
  @Output() public reloadSalaryBatches: EventEmitter<void> = new EventEmitter<void>();
  @Output() public closeDialog: EventEmitter<void> = new EventEmitter<void>();

  @Input() public popup: boolean;

  @Input() public get salaryBatch(): SalaryBatchViewModel {
    return this.salaryBatchValue;
  }
  public set salaryBatch(value: SalaryBatchViewModel) {
    if (this.salaryBatchValue !== value) {
      this.salaryBatchValue = value;
    } else if (!this.popup && !value) {
      this.salaryBatchValue = value;
    }
  }

  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
  }

  @Output() public selectedEmployeeIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Input() public get selectedEmployeeIds(): number[] {
    return this.selectedEmployeeIdsValue;
  }
  public set selectedEmployeeIds(value: number[]) {
    if (this.selectedEmployeeIdsValue !== value) {
      this.selectedEmployeeIdsValue = value;
      this.selectedEmployeeIdsChange.emit(value);
    }
  }

  @Input() public salaryCycles: ISalaryCycle[];
  @Input() public salaryStatements: ISimpleSalaryStatement[];
  @Input() public isPaymentApprover: boolean;
  @Input() public tooltipFinalizeContent: string;
  @Input() public hasIntegration: boolean;

  @Input()
  public get salaryBatchContext(): CompanySalaryBatchesChildFormEntityContext {
    return this.salaryBatchContextValue ? this.salaryBatchContextValue : ({} as any);
  }

  public set salaryBatchContext(value: CompanySalaryBatchesChildFormEntityContext) {
    if (this.salaryBatchContextValue !== value) {
      this.salaryBatchContextValue = value;
      this.salaryBatchContextChange.emit(value);
    }
  }

  @Output() public salaryBatchContextChange: EventEmitter<
    CompanySalaryBatchesChildFormEntityContext
  > = new EventEmitter<CompanySalaryBatchesChildFormEntityContext>();

  private visibleValue: boolean;
  private selectedEmployeeIdsValue: number[];
  private salaryBatchValue: SalaryBatchViewModel;
  private salaryBatchContextValue: CompanySalaryBatchesChildFormEntityContext;

  constructor(public sessionService: SessionService) {}

  public ngOnInit(): void {}

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public onCreatePayrollBatchClicked(): void {
    this.createPayrollBatch.emit();
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public onDetailContextChange(value: any): void {
    this.salaryBatchContext = value;
  }

  public get isGreenland(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public onSalaryBatchDialogAction(action: string): void {
    this.salaryBatchAction.emit({ action, context: this.salaryBatchContext });
  }
}
