<div class="Company-Form">
  <form *ngIf="company" class="Card Card-inner">
    <fieldset class="Form--horizontal">
      <legend class="Card-title">{{ 'CompanyGeneral.Options' | translate }}</legend>

      <div *ngIf="!IsGreenlandCompany">
        <module-check [moduleId]="4" [(hasModule)]="hasStatisticModule" [companyOption]="true">
          <combobox-edit-control
            [label]="'CompanyGeneral.Statistics' | translate"
            [editMode]="editMode"
            [comboboxDataSource]="statisticOptions"
            [nullValueLabel]="'DropdownList.None' | translate"
            [textField]="'Name'"
            [idField]="'Id'"
            [(isDirty)]="childIsDirty[0]"
            [disable]="!hasStatisticModule"
            [(value)]="company.StatisticsRecipientId"
            (valueChange)="onChange()"
          ></combobox-edit-control>

          <div class="FormElement" *ngIf="company.StatisticsRecipientId">
            <span class="FormElement-label"></span>
            <app-radio-edit
              [disable]="!hasStatisticModule"
              [(isDirty)]="childIsDirty[6]"
              [editMode]="editMode"
              [(value)]="company.StaticsReportsYearly"
              (valueChange)="onChange()"
            >
              <app-radio-edit-option
                [value]="false"
                [label]="'CompanyGeneral.Quarterly' | translate"
              ></app-radio-edit-option>
              <app-radio-edit-option
                [value]="true"
                [label]="'CompanyGeneral.Yearly' | translate"
              ></app-radio-edit-option>
            </app-radio-edit>
          </div>
        </module-check>
      </div>

      <module-check [moduleId]="5" [(hasModule)]="hasLanguageModule" [companyOption]="true">
        <combobox-edit-control
          [disable]="!hasLanguageModule"
          [(isDirty)]="childIsDirty[2]"
          [label]="'CompanyGeneral.DefaultLanguage' | translate"
          [editMode]="editMode && isAccountAdmin"
          [comboboxDataSource]="languages"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="company.DefaultLanguageId"
          (valueChange)="onChange()"
        ></combobox-edit-control>
      </module-check>

      <module-check [moduleId]="10" [(hasModule)]="hasPaymentModule" [companyOption]="true">
        <combobox-edit-control
          [(isDirty)]="childIsDirty[3]"
          [label]="'CompanyGeneral.PaymentHandlingSalary' | translate"
          [editMode]="editMode"
          [disable]="!hasPaymentModule"
          [comboboxDataSource]="paymentMethods"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="company.PaymentMethodSalary"
          (valueChange)="onChange()"
        ></combobox-edit-control>

        <combobox-edit-control
          [(isDirty)]="childIsDirty[4]"
          [label]="'CompanyGeneral.PaymentHandlingPension' | translate"
          [editMode]="editMode"
          [disable]="!hasPaymentModule"
          [comboboxDataSource]="paymentMethods"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="company.PaymentMethodPension"
          (valueChange)="onChange()"
        ></combobox-edit-control>

        <combobox-edit-control
          [(isDirty)]="childIsDirty[5]"
          [label]="'CompanyGeneral.PaymentHandlingTax' | translate"
          [editMode]="editMode"
          [disable]="!hasPaymentModule"
          [comboboxDataSource]="paymentMethods"
          *ngIf="!IsGreenlandCompany"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="company.PaymentMethodTax"
          (valueChange)="onChange()"
        ></combobox-edit-control>
      </module-check>
      <combobox-edit-control
        [(isDirty)]="childIsDirty[6]"
        [label]="'CompanyGeneral.Bank' | translate"
        [nullValueLabel]="'DropdownList.None' | translate"
        [editMode]="editMode"
        [comboboxDataSource]="bankOption"
        [textField]="'Name'"
        [idField]="'Id'"
        [(value)]="company.BankId"
        (valueChange)="onChange()"
      ></combobox-edit-control>

      <button class="Link Options" *ngIf="isAdminUser" type="button" (click)="onAdditionalOptionClick()">
        {{ 'Preference.AdditionalOption' | translate }}
      </button>
    </fieldset>
  </form>
</div>
