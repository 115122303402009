<h1 class="Company-accountName" [innerHTML]="accountName"></h1>
<div (dblclick)="onEditModeChange()" class="Island">
  <div class="Cards Cards--flex">
    <div class="Card Card--large Card-inner accountantCompaniesGrid">
      <div class="Card-title">{{ 'Accountant.Companies' | translate }}</div>
      <div class="Employee-timeControls" style="margin-bottom: 12px;">
        <div class="FormElement-control PaddingTop">
          <span class="FormElement-label">{{ 'Accountant.FilterCompany' | translate }}</span>
          <div class="FormElement-control margR20">
            <input kendoTextBox class="FormElement-input" [disabled]="service.editMode" [(ngModel)]="service.filter" />
          </div>
        </div>
        <button type="button" class="Button--card accountantAdNewButton" (click)="addNewCompanyDialogVisible = true">
          {{ 'Accountant.AddNewCompany' | translate }}
        </button>
      </div>

      <grid
        class="accountant"
        [data]="gridFilterData"
        [editMode]="false"
        [(skip)]="service.currentPage"
        [pageable]="true"
        [disabled]="service.editMode"
        [(selectedId)]="service.selectedId"
        [idColumn]="'Id'"
        (clickAppGridIcon)="changeCompany($event.dataItem.Id)"
        [pageSize]="100"
        [filterable]="'menu'"
        [filter]="filter"
        (filterChange)="onFilterChange($event)"
        (selectedIdChange)="onSelectedIdChange($event)"
      >
        <gridColumn
          [field]="'Id'"
          [type]="'numeric'"
          [width]="45"
          [title]="'Accountant.Id' | translate"
          [isFilterable]="true"
          [numbericOption]="'0'"
        ></gridColumn>
        <gridColumn
          [field]="'CountryCode'"
          [type]="'image'"
          [title]="'Accountant.CountryCode' | translate"
          [width]="75"
          [isFilterable]="true"
          [comboboxDataSource]="countryCodes"
          [comboboxValueField]="'Name'"
          [comboboxDisplayField]="'Name'"
        ></gridColumn>
        <gridColumn
          [field]="'Name'"
          [type]="'text'"
          [title]="'Accountant.CompanyName' | translate"
          [width]="150"
          [isFilterable]="true"
        ></gridColumn>
        <gridColumn
          [field]="'VATRegistrationNumber'"
          [type]="'text'"
          [title]="'Accountant.VATRegistrationNumber' | translate"
          [width]="90"
          [isFilterable]="true"
        ></gridColumn>
        <gridColumn
          [field]="'MainContactFullName'"
          [type]="'text'"
          [title]="'Accountant.MainContactFullName' | translate"
          [width]="150"
          [isFilterable]="true"
        ></gridColumn>
        <gridColumn
          [field]="'ContactEmail'"
          [type]="'text'"
          [title]="'Accountant.ContactEmail' | translate"
          [width]="150"
          [isFilterable]="true"
        ></gridColumn>
        <gridColumn
          [field]="'InvoiceEmail'"
          [type]="'text'"
          [title]="'Accountant.InvoiceEmail' | translate"
          [isFilterable]="true"
          [width]="150"
        ></gridColumn>
        <gridColumn
          [field]="'EmployeeCount'"
          [type]="'numeric'"
          [numbericOption]="'n0'"
          [title]="'Accountant.EmployeeCount' | translate"
          [width]="90"
          [isFilterable]="true"
        ></gridColumn>
        <gridColumn
          [field]="'LastFinalized'"
          [type]="'datepicker'"
          [title]="'Accountant.LastFinalized' | translate"
          [width]="90"
          [isFilterable]="true"
        ></gridColumn>
        <gridColumn
          [field]="'LastFinalizedFullName'"
          [type]="'text'"
          [title]="'Accountant.LastFinalizedFullName' | translate"
          [width]="90"
          [isFilterable]="true"
        ></gridColumn>
        <gridColumn
          [field]="'NextDeadline'"
          [type]="'datepicker'"
          [title]="'Accountant.NextDeadline' | translate"
          [width]="90"
          [isFilterable]="true"
        ></gridColumn>
        <gridColumn
          [field]="'HasBatchesForApproval'"
          [type]="'checkbox'"
          [title]="'Accountant.HasBatchesForApproval' | translate"
          [width]="90"
          [isFilterable]="true"
        ></gridColumn>
        <gridColumn [iconField]="'GoToIcon'" [type]="'icon'" [title]="'Accountant.GoToIcon' | translate" [width]="50">
        </gridColumn>
      </grid>
    </div>
    <div class="Card Card--nomal Card-inner">
      <div class="Card-title">{{ 'Accountant.Users' | translate }}</div>
      <grid
        class="companyaccountantrole"
        [data]="gridRoleFilterData"
        [editMode]="true"
        [pageSize]="100"
        [hiddenCount]="true"
        [buttonCount]="5"
        [pageable]="true"
        (saveChangesEvent)="service.save($event)"
        [filterable]="'menu'"
        [filter]="roleFilter"
        (filterChange)="onRoleFilterChange($event)"
      >
        <gridColumn
          [field]="'FullName'"
          [editable]="false"
          [type]="'text'"
          [title]="'Accountant.FullName' | translate"
          [width]="100"
          [isFilterable]="true"
        ></gridColumn>
        <gridColumn
          [field]="'RoleId'"
          [type]="'combobox'"
          [comboboxDataSource]="staticData.Role | async"
          [comboboxValueField]="'Id'"
          [comboboxDisplayField]="'Name'"
          [allowComboeditNull]="true"
          [editable]="true"
          [title]="'Accountant.Role' | translate"
          [width]="75"
          [isFilterable]="true"
          [defaultnullvalue]="0"
        ></gridColumn>
        <gridColumn
          [field]="'IsPaymentApprover'"
          [editable]="true"
          [type]="'checkboxgrid'"
          [title]="'Accountant.Finalize' | translate"
          [headerTooltip]="'Accountant.FinalizeTooltip' | translate"
          [width]="50"
          [isFilterable]="true"
        ></gridColumn>
      </grid>

      <div>
        <div class="Card-title Card-title-equal-margin-top">{{ 'Accountant.AccountComment' | translate }}</div>
        <app-text-edit
          *ngIf="service.company"
          [label]=""
          [editMode]="service.editMode"
          [(value)]="service.company.AccountComment"
          [isTextArea]="true"
          [textAreaLimitChar]="200"
        ></app-text-edit>
      </div>
    </div>
  </div>
</div>

<div [ngClass]="{ 'is-editing': service.editMode }">
  <div class="Actions">
    <action-button
      id="ActionButtonAdd"
      [buttonClass]="'Action-button Action-buttonAdd'"
      [icon]="'Add'"
      [label]="'Accountant.AddNewCompany'"
      (click)="addNewCompanyDialogVisible = true"
      [tooltip]="'Accountant.AddNewCompany'"
    ></action-button>
    <more-menu [currentTab]="activeTab" (menuClick)="onMoreMenuOptionClick($event)"> </more-menu>
    <edit-button
      *ngIf="service.selectedId"
      [module]="'employee'"
      [editMode]="service.editMode"
      [isVisible]="true"
      (change)="onEditModeChange()"
    ></edit-button>
  </div>
  <div class="EditingInfo" *ngIf="service.editMode"></div>
  <button-component
    [isUseOneClick]="true"
    [buttonClass]="'EditingInfo Discard'"
    (click)="confirmAndDiscardChanges()"
    [label]="'Employee.DiscardButton' | translate"
    *ngIf="service.editMode"
  ></button-component>
</div>

<app-dialog
  (action)="discardDialogAction($event)"
  [(visible)]="discardDialogVisible"
  [leadingText]="'Employee.DiscardMessage' | translate"
>
  <app-dialog-action type="CancelDiscard"></app-dialog-action>
  <app-dialog-action type="ConfirmDiscard"></app-dialog-action>
</app-dialog>

<app-accountant-add-new-company-dialog
  (createSuccess)="onCreateSuccess($event)"
  [(visible)]="addNewCompanyDialogVisible"
></app-accountant-add-new-company-dialog>

<app-dialog
  (action)="onDoneCreateSuccess($event)"
  [(visible)]="createSuccessConfim"
  [leadingText]="'Accountant.CreateConfirm' | translate"
>
  <app-dialog-action type="No"></app-dialog-action>
  <app-dialog-action type="Yes"></app-dialog-action>
</app-dialog>

<app-dialog [width]="600" (action)="onAttachingAnExistingCompany($event)" [(visible)]="attachexistingcompanyVisable">
  <label
    [innerHTML]="
      ReplayMoreMenuLabelAttachingAnExistingCompany('Accountant.MoreMenuLabelAttachingAnExistingCompany' | translate)
    "
  ></label>
  <combobox-edit-control
    [editMode]="true"
    [textField]="'Relevant'"
    [idField]="'Id'"
    [comboboxDataSource]="userCompanyUserViews"
    [(value)]="selectUserCompanyUserViews"
  >
  </combobox-edit-control>
  <check-edit [editMode]="true" [(value)]="isUnderStoodTheAbove" [label]="'Accountant.UnderstoodTheAbove' | translate">
  </check-edit>
  <app-dialog-action [disabled]="!isUnderStoodTheAbove" type="Submit"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="isCompleteAttached"
  (action)="onDoneCreateSuccess($event)"
  [leadingText]="'Accountant.CompleteAttached' | translate"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
