import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, QueryList } from '@angular/core';
import { ControlComponentBase } from '../../Common/ControlComponentBase';
import { UniqueId } from '../../Common/UniqueId';
import { RadioEditOptionComponent } from './RadioEditOption.compoenet';
var RadioEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RadioEditComponent, _super);
    function RadioEditComponent(elementRef, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.vertical = false;
        _this.controlId = 'radioEdit' + UniqueId();
        return _this;
    }
    RadioEditComponent.prototype.valid = function () {
        return true;
    };
    RadioEditComponent.prototype.onClick = function (value) {
        this.value = value;
        _super.prototype.onChange.call(this);
    };
    return RadioEditComponent;
}(ControlComponentBase));
export { RadioEditComponent };
