<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeGeneral.Bank Account' | translate }}</legend>

    <app-text-edit
      [label]="'EmployeeGeneral.Bank reg no' | translate"
      [viewClass]="bankInfo.AutoDetermineBankAccount ? 'u-strikeThrough' : ''"
      [editMode]="editMode"
      [disabled]="bankInfo.AutoDetermineBankAccount"
      [(value)]="bankInfo.BankRegNo"
      [(isValid)]="childIsValid[0]"
      [(isDirty)]="childIsDirty[0]"
      (valueChange)="onChange()"
    ></app-text-edit>

    <app-text-edit
      [label]="'EmployeeGeneral.Bank account no' | translate"
      [viewClass]="bankInfo.AutoDetermineBankAccount ? 'u-strikeThrough' : ''"
      [editMode]="editMode"
      [disabled]="bankInfo.AutoDetermineBankAccount"
      [(isValid)]="childIsValid[1]"
      [(isDirty)]="childIsDirty[1]"
      [(value)]="bankInfo.BankAccountNo"
      (valueChange)="onChange()"
    ></app-text-edit>

    <module-check [moduleId]="13" [(hasModule)]="hasNemKontoEnabled">
      <check-edit
        [label]="'EmployeeGeneral.UseNemKonto' | translate"
        [disable]="!hasNemKontoEnabled"
        [editMode]="editMode"
        [(value)]="bankInfo.AutoDetermineBankAccount"
        [(isValid)]="childIsValid[2]"
        [(isDirty)]="childIsDirty[2]"
        (valueChange)="onChange()"
      ></check-edit>
    </module-check>

    <app-text-edit
      [label]="'EmployeeGeneral.IBAN' | translate"
      [editMode]="editMode"
      [(value)]="bankInfo.IbanNo"
      [(isValid)]="childIsValid[3]"
      [(isDirty)]="childIsDirty[3]"
      (valueChange)="onChange()"
    ></app-text-edit>

    <app-text-edit
      [label]="'EmployeeGeneral.BIC SWIFT' | translate"
      [editMode]="editMode"
      [(value)]="bankInfo.BicSwiftNo"
      [(isValid)]="childIsValid[4]"
      [(isDirty)]="childIsDirty[4]"
      (valueChange)="onChange()"
    ></app-text-edit>

    <combobox-edit-control
      [label]="'EmployeeGeneral.PreferredCurrency' | translate"
      [editMode]="editMode"
      [textField]="'Name'"
      [idField]="'Id'"
      [nullValueLabel]="'DropdownList.None' | translate"
      [comboboxDataSource]="currencies"
      [(value)]="bankInfo.PreferredCurrencyId"
      [(isValid)]="childIsValid[5]"
      [(isDirty)]="childIsDirty[5]"
      (valueChange)="onChange()"
    ></combobox-edit-control>
  </fieldset>
</form>
