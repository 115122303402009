import { ErrorHandler } from '@angular/core';
import * as Raven from 'raven-js';
import { environment } from '../environments/environment';

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (
      err.message === 'GSNetworkError' ||
      err.status === 0 ||
      (err.status && (err.status === 400 || err.status === 401 || err.status === 404))
    ) {
      return;
    }

    if (
      err.message === 'GSNetworkError' ||
      (err.rejection &&
        (err.rejection.status === 0 ||
          (err.rejection.status &&
            (err.rejection.status === 400 || err.rejection.status === 401 || err.rejection.status === 404))))
    ) {
      return;
    }

    if (err && err.message && err.message.indexOf('a[b].target.className.indexOf') >= 0) {
      return;
    }

    if (err && err.message && err.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') >= 0) {
      return;
    }

    if (environment.environment === 'DEV') {
      console.error(err);
    }

    Raven.captureException(err);
  }
}
