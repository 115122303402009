import { EventEmitter, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterDateTimeGrid } from '../../../../Common/FilterDateTimeGrid';
import { Global } from '../../../../Common/Global';
import { PayrolCommonl } from '../../../../Common/payrolCommonl';
import { ImportTypesEnum } from '../../../../Model/Enum';
import { SalaryRecord } from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';
import { SalaryRecordItemHelper } from './SalaryRecordItemHelper';
var PayrollDataGridComponent = /** @class */ (function () {
    function PayrollDataGridComponent(translateService, dataService, settingService, sessionService, staticDataService, renderer) {
        this.translateService = translateService;
        this.dataService = dataService;
        this.settingService = settingService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.renderer = renderer;
        this.triggerGridComponentUpdate = false;
        this.recalculateValue = false;
        this.recalculateChange = new EventEmitter();
        this.currentIsHidePreviewGrid = false;
        this.isHidePreviewGridChange = new EventEmitter();
        this.update = new EventEmitter();
        this.IsEnableDateAdjustment = false;
        this.DimensionXValue = [];
        this.salaryRecords = [];
        this.salaryTypeFilterFields = ['Name', 'Description'];
        // private enableDateAdjustmentPreferenceKey: string = "PayrollData.EnableDateAdjustments";
        this.registerRecalculateClickedEvent = false;
        this.ngUnsubscribe = new Subject();
        this.negativeDefaultTranslationText = {};
        this.negativeDefaultMoreDialogModel = {};
        this.keyPress = { 17: false, 18: false, 107: false, 109: false };
        this.isUseHotKey = false;
        this.gridData = filterBy(this.salaryRecords, this.filter);
    }
    Object.defineProperty(PayrollDataGridComponent.prototype, "userEmployment", {
        get: function () {
            return this.userEmploymentValue;
        },
        set: function (value) {
            if (this.userEmploymentValue !== value) {
                this.userEmploymentValue = value;
                if (value && value.Id) {
                    this.loadSalaryTypes();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "recalculate", {
        get: function () {
            return this.recalculateValue;
        },
        set: function (value) {
            this.recalculateValue = value;
            this.recalculateChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "isHidePreviewGrid", {
        get: function () {
            return this.currentIsHidePreviewGrid;
        },
        set: function (value) {
            if (this.currentIsHidePreviewGrid !== value) {
                this.currentIsHidePreviewGrid = value;
                this.isHidePreviewGridChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "NumericOptions", {
        get: function () {
            if (this.numericOptions) {
                return this.numericOptions;
            }
            return (this.numericOptions = {
                format: 'n2',
                decimals: 2,
                step: 1,
                min: 0,
                spinners: false
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "IconisHidePreviewGrid", {
        get: function () {
            if (!this.isHidePreviewGrid) {
                return 'angle-double-right';
            }
            return 'angle-double-left';
        },
        enumerable: true,
        configurable: true
    });
    // public get IsEnableDateAdjustment(): boolean {
    //     let enableDateAdjustment: ISimpleKeyValuePair = PayrolCommonl.getLocalStorageEnableDatePreferenceSetting();
    //     if (!enableDateAdjustment) {
    //         enableDateAdjustment = PayrolCommonl.createLocalStorageEnableDatePreferenceSetting(false);
    //     }
    //     let value: string = enableDateAdjustment.Value.toLowerCase();
    //     return value === "true";
    // }
    PayrollDataGridComponent.prototype.getIsEnableDateAdjustment = function () {
        var enableDateAdjustment = PayrolCommonl.getLocalStorageEnableDatePreferenceSetting();
        if (!enableDateAdjustment) {
            enableDateAdjustment = PayrolCommonl.createLocalStorageEnableDatePreferenceSetting(false);
        }
        var value = enableDateAdjustment.Value.toLowerCase();
        return value === 'true';
    };
    Object.defineProperty(PayrollDataGridComponent.prototype, "Dimension1Preference", {
        get: function () {
            return Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.find(function (pref) { return pref.Key === 'Dimensions.Dimension1Name'; })
                : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "Dimension2Preference", {
        get: function () {
            return Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.find(function (pref) { return pref.Key === 'Dimensions.Dimension2Name'; })
                : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "Dimension3Preference", {
        get: function () {
            return Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.find(function (pref) { return pref.Key === 'Dimensions.Dimension3Name'; })
                : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "AmountFlipMessage", {
        get: function () {
            return this.isAutoFlipAmountsSetting
                ? this.negativeDefaultTranslationText['EnableSetting']
                : this.negativeDefaultTranslationText['DisableSetting'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "AmountFlipButtons", {
        get: function () {
            return !this.isAutoFlipAmountsSetting
                ? [this.negativeDefaultTranslationText['MoreInfo']]
                : [
                    this.negativeDefaultTranslationText['MoreInfo'],
                    this.negativeDefaultTranslationText['UsePositiveAmountThisTime']
                ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "isAutoFlipAmountsSetting", {
        get: function () {
            return PayrolCommonl.isAutoFlipAmountsSetting();
        },
        enumerable: true,
        configurable: true
    });
    PayrollDataGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.disableGridToolbar = true;
        this.unitTypes = Global.UNIT_TYPES;
        this.salaryRecordItemHelper = undefined;
        this.translateText();
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            _this.translateText();
        });
        this.staticDataService.SalaryRecordPersistenceType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.persistanceTypes = data;
        });
        this.IsEnableDateAdjustment = this.getIsEnableDateAdjustment();
    };
    PayrollDataGridComponent.prototype.ngOnDestroy = function () {
        //document.removeEventListener("keydown", this.onKeyDown);
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    PayrollDataGridComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService
            .get([
            'EmploymentPayrollData.NegativeDefaultMessage_EnableSetting',
            'EmploymentPayrollData.NegativeDefaultMessage_DisableSetting',
            'EmploymentPayrollData.UsePositiveAmountThisTime',
            'EmploymentPayrollData.MoreInfo',
            'EmploymentPayrollData.UnitsAutoConfigured',
            'EmploymentPayrollData.HandlingOfSygedagpenge'
        ])
            .subscribe(function (translations) {
            _this.negativeDefaultTranslationText['EnableSetting'] =
                translations['EmploymentPayrollData.NegativeDefaultMessage_EnableSetting'];
            _this.negativeDefaultTranslationText['DisableSetting'] =
                translations['EmploymentPayrollData.NegativeDefaultMessage_DisableSetting'];
            _this.negativeDefaultTranslationText['UsePositiveAmountThisTime'] =
                translations['EmploymentPayrollData.UsePositiveAmountThisTime'];
            _this.negativeDefaultTranslationText['MoreInfo'] = translations['EmploymentPayrollData.MoreInfo'];
            _this.negativeDefaultTranslationText['UnitsAutoConfigured'] =
                translations['EmploymentPayrollData.UnitsAutoConfigured'];
            _this.negativeDefaultTranslationText['HandlingOfSygedagpenge'] =
                translations['EmploymentPayrollData.HandlingOfSygedagpenge'];
        });
    };
    PayrollDataGridComponent.prototype.runEnableDateAdjustmentsRules = function () {
        var enableDateAdjustment = this.IsEnableDateAdjustment;
        this.showDateColumns(enableDateAdjustment);
    };
    PayrollDataGridComponent.prototype.showDateColumns = function (show) {
        this.isHideDateColumns = !show;
    };
    PayrollDataGridComponent.prototype.enableDateAdjustmentSetting = function (enable) {
        this.updateEnableDatePreferenceSetting(enable);
    };
    PayrollDataGridComponent.prototype.updateEnableDatePreferenceSetting = function (enableSetting) {
        var _this = this;
        var companyPreference = {
            Key: PayrolCommonl.enableDateAdjustmentPreferenceKey,
            Value: enableSetting ? 'true' : 'false'
        };
        this.dataService.Preferences_SetCompanyPreference(companyPreference).subscribe(function (enableDatePref) {
            _this.updateLocalStorageEnableDatePreferenceSetting(enableDatePref);
            _this.IsEnableDateAdjustment = _this.getIsEnableDateAdjustment();
            _this.showDateColumns(enableSetting);
            _this.triggerGridComponentUpdate = true;
        }, function (error) {
            _this.showDateColumns(!enableSetting);
        });
    };
    PayrollDataGridComponent.prototype.updateLocalStorageEnableDatePreferenceSetting = function (enableDatePref) {
        var preferences = JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES));
        var enableDateAdjustment = preferences.filter(function (pref) { return pref.Key === PayrolCommonl.enableDateAdjustmentPreferenceKey; })[0];
        if (enableDateAdjustment) {
            enableDateAdjustment.Value = enableDatePref.Value;
            Global.COMPANY_PREFERENCES = preferences;
        }
        return enableDateAdjustment;
    };
    // private createLocalStorageEnableDatePreferenceSetting(defaultValue: boolean): ISimpleKeyValuePair {
    //     let preferences: ISimpleKeyValuePair[] = Global.COMPANY_PREFERENCES ? JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES)) : [];
    //     // Add a default setting = true.
    //     let companyPreference: ISimpleKeyValuePair = { Key: PayrolCommonl.enableDateAdjustmentPreferenceKey, Value: defaultValue ? "true" : "false" };
    //     preferences.push(companyPreference);
    //     Global.COMPANY_PREFERENCES = preferences;
    //     return companyPreference;
    // }
    // private getLocalStorageEnableDatePreferenceSetting(): ISimpleKeyValuePair {
    //     let preferences: ISimpleKeyValuePair[] = JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES));
    //     if (preferences) {
    //         return preferences.filter((pref: any) => pref.Key === PayrolCommonl.enableDateAdjustmentPreferenceKey)[0];
    //     }
    //     return undefined;
    // }
    PayrollDataGridComponent.prototype.loadSalaryTypes = function () {
        var _this = this;
        this.salaryRecords = undefined;
        this.salaryTypes = undefined;
        this.disableGridToolbar = true;
        this.dataService.SalaryTypes_GetSalaryTypeViews().subscribe(function (salaryTypes) {
            if (_this.userEmploymentValue.IncomeTypeId === 5) {
                _this.salaryTypes = salaryTypes.filter(function (salaryType) { return salaryType.IsVisible && salaryType.RelatesToIncomeType5 !== false; });
            }
            else {
                _this.salaryTypes = salaryTypes.filter(function (salaryType) { return salaryType.IsVisible && !salaryType.RelatesToIncomeType5; });
            }
            _this.loadSalaryRecords();
        });
        if (this.Dimension1Preference && this.Dimension1Preference.Value) {
            this.dataService.Miscellaneous_GetDimensionValues(1).subscribe(function (data) {
                _this.DimensionXValue[0] = data.filter(function (value) { return value; });
            });
        }
        if (this.Dimension2Preference && this.Dimension2Preference.Value) {
            this.dataService.Miscellaneous_GetDimensionValues(2).subscribe(function (data) {
                _this.DimensionXValue[1] = data.filter(function (value) { return value; });
            });
        }
        if (this.Dimension3Preference && this.Dimension3Preference.Value) {
            this.dataService.Miscellaneous_GetDimensionValues(3).subscribe(function (data) {
                _this.DimensionXValue[2] = data.filter(function (value) { return value; });
            });
        }
    };
    PayrollDataGridComponent.prototype.loadSalaryRecords = function () {
        var _this = this;
        if (!this.userEmployment) {
            return;
        }
        this.dataService
            .SalaryRecords_GetSalaryRecordsByEmployment(this.userEmployment.Id)
            .subscribe(function (salaryRecords) {
            _this.salaryRecords = salaryRecords.map(function (data) {
                return PayrolCommonl.assignEditableFields(data, _this.salaryTypes);
            });
            _this.gridData = filterBy(_this.salaryRecords, _this.filter);
            _this.runEnableDateAdjustmentsRules();
            _this.disableGridToolbar = false;
        });
    };
    PayrollDataGridComponent.prototype.onNegativeDefaultMoreInfoClick = function (dataItem, fieldName) {
        this.negativeDefaultMoreDialogModel = {
            setting: this.isAutoFlipAmountsSetting,
            fieldName: fieldName,
            dataItem: dataItem
        };
        this.showNegativeDefaultMoreInfoDialog = true;
    };
    PayrollDataGridComponent.prototype.onMoreInfoUsePositiveAmount = function (args) {
        this.usePositiveAmount(args.dataItem, args.fieldName);
    };
    PayrollDataGridComponent.prototype.usePositiveAmount = function (dataItem, fieldName) {
        this.salaryRecordItemHelper.usePositiveAmount(fieldName);
        this.saveChanges(dataItem);
        this.triggerGridComponentUpdate = true;
    };
    PayrollDataGridComponent.prototype.autoCorrect = function (args) {
        this.salaryRecordItemHelper.autoCorrect(args.fieldName);
        this.saveChanges(args.dataItem);
        this.triggerGridComponentUpdate = true;
    };
    PayrollDataGridComponent.prototype.removeHandler = function (e) {
        this.gridRemoveRowArgs = e;
        this.showDeleteItemConfirmation = true;
        this.update.emit();
    };
    PayrollDataGridComponent.prototype.onRemoveRowConfirmed = function (action) {
        if (action === 'Delete' && this.gridRemoveRowArgs) {
            if (this.gridRemoveRowArgs.dataItem.Id > 0) {
                this.deleteSalaryRecord(this.gridRemoveRowArgs.dataItem.Id);
            }
            else {
                var indexOfRemovedItem = this.salaryRecords.indexOf(this.gridRemoveRowArgs.dataItem);
                this.salaryRecords.splice(indexOfRemovedItem, 1);
                this.gridRemoveRowArgs = undefined;
            }
        }
    };
    PayrollDataGridComponent.prototype.deleteSalaryRecord = function (salaryRecordId) {
        var _this = this;
        if (salaryRecordId && salaryRecordId > 0) {
            this.dataService.SalaryRecords_DeleteSalaryRecord(salaryRecordId).subscribe(function (data) {
                _this.recalculate = true;
                var indexOfRemovedItem = _this.salaryRecords.indexOf(_this.gridRemoveRowArgs.dataItem);
                _this.salaryRecords.splice(indexOfRemovedItem, 1);
                if (_this.salaryRecords.length > 0) {
                    var index = indexOfRemovedItem > _this.salaryRecords.length - 1 ? 0 : indexOfRemovedItem;
                    _this.kendoGrid.editCell(index, _this.kendoGrid.columns.length, _this.createFormGroup(_this.salaryRecords[index]));
                }
                _this.gridRemoveRowArgs = undefined;
                _this.gridData = filterBy(_this.salaryRecords, _this.filter);
            }, function (error) {
                _this.gridRemoveRowArgs = undefined;
                _this.loadSalaryRecords();
            });
        }
    };
    PayrollDataGridComponent.prototype.addHandler = function (dataItem) {
        var _this = this;
        this.update.emit();
        this.salaryRecordItemHelper = new SalaryRecordItemHelper(dataItem, this.renderer, this.persistanceTypes, this.salaryTypes, this.settingService);
        this.salaryRecordItemHelper.updateSalaryType(this.salaryTypes[0]);
        // this.assignEditableFields(dataItem);
        PayrolCommonl.assignEditableFields(dataItem, this.salaryTypes);
        var salaryRecord = PayrolCommonl.createSalaryRecord(dataItem, this.userEmployment.Id);
        this.dataService.SalaryRecords_CreateSalaryRecord(salaryRecord).subscribe(function (data) {
            _this.gridData = filterBy(_this.salaryRecords, _this.filter);
            dataItem.Id = data.Id;
            // Object.assign(dataItem, data);
            _this.selectedId = data.Id;
            _this.recalculate = true;
            if (_this.isUseHotKey) {
                _this.kendoGrid.editCell(_this.salaryRecords.length - 1, 0, _this.createFormGroup(dataItem));
                _this.isUseHotKey = false;
            }
        }, function (error) {
            _this.loadSalaryRecords();
        });
    };
    // private createSalaryRecord(salaryRecordView: any): ISalaryRecord {
    //     let endDateConverter: DateTimeConvert = new DateTimeConvert(salaryRecordView.EndDate);
    //     let endDate: Date = endDateConverter.getUTCDate();
    //     let startDateConverter: DateTimeConvert = new DateTimeConvert(salaryRecordView.StartDate);
    //     let startDate: Date = startDateConverter.getUTCDate();
    //     let salaryRecord: ISalaryRecord = {
    //         Id: salaryRecordView.Id ? salaryRecordView.Id : salaryRecordView.id,
    //         Amount: salaryRecordView.Amount,
    //         AmountPerUnit: salaryRecordView.AmountPerUnit,
    //         BaseAmount: salaryRecordView.BaseAmount,
    //         Description: salaryRecordView.Description,
    //         EndDate: endDate ? endDate : salaryRecordView.EndDate,
    //         PersistenceTypeId: salaryRecordView.PersistenceTypeId,
    //         SalaryTypeId: salaryRecordView.SalaryTypeId,
    //         UnitTypeId: salaryRecordView.UnitTypeId,
    //         Dimension1Value: salaryRecordView.Dimension1Value,
    //         Dimension2Value: salaryRecordView.Dimension2Value,
    //         Dimension3Value: salaryRecordView.Dimension3Value,
    //         StartDate: startDate ? startDate : salaryRecordView.StartDate,
    //         Units: salaryRecordView.Units,
    //         UserEmploymentId: this.userEmployment.Id,
    //         IsActive: true
    //     };
    //     let unitTypes: any = Global.UNIT_TYPES.filter((tet: any) => {
    //         return tet.Id === salaryRecordView.UnitTypeId;
    //     });
    //     if (unitTypes) {
    //         salaryRecord.UnitType = unitTypes[0];
    //     }
    //     return salaryRecord;
    // }
    PayrollDataGridComponent.prototype.saveChanges = function (args, field) {
        var _this = this;
        var column = args.column, formGroup = args.formGroup;
        var dataItem = args.dataItem;
        dataItem = dataItem ? dataItem : args;
        var modifiedField = {};
        if (column) {
            field = column.field;
        }
        if (field) {
            modifiedField[field] = dataItem[field];
        }
        this.salaryRecordItemHelper = new SalaryRecordItemHelper(dataItem, this.renderer, this.persistanceTypes, this.salaryTypes, this.settingService);
        var valid = this.salaryRecordItemHelper.validate(modifiedField);
        if (!valid) {
            dataItem[field] = formGroup.value[field];
            return;
        }
        var previousDataItem = JSON.parse(JSON.stringify(dataItem));
        this.salaryRecordItemHelper.updateRelavantFields(modifiedField);
        // this.assignEditableFields(dataItem);
        PayrolCommonl.assignEditableFields(dataItem, this.salaryTypes);
        if (field &&
            (modifiedField[field] === 'AmountPerUnit' || modifiedField[field] === 'Amount') &&
            PayrolCommonl.checkAmountAutoFlip(previousDataItem, dataItem)) {
            // Show in-cell popup message to notify Amount has been auto-flip.
            this.salaryRecordItemHelper.showInCellPopupMessage(dataItem, 'Amount', column ? column.field : undefined);
        }
        if (dataItem.SalaryTypeId) {
            var currentSalaryTypedSelect = this.salaryTypes.find(function (model) { return dataItem.SalaryTypeId === model.SalaryTypeId; });
            if (currentSalaryTypedSelect && currentSalaryTypedSelect.Identifier === 'IllnessCompensation') {
                if ((dataItem.Units || dataItem.Amount) && !dataItem.BaseAmount) {
                    this.salaryRecordItemHelper.showInCellPopupMessage(dataItem, 'BaseAmount', column ? column.field : undefined);
                }
            }
        }
        setTimeout(function () {
            _this.triggerGridComponentUpdate = true;
        }, 5000);
        var salaryRecord = PayrolCommonl.createSalaryRecord(dataItem, this.userEmployment.Id);
        this.updateSalaryRecord(salaryRecord, dataItem);
    };
    PayrollDataGridComponent.prototype.onCellCloseEvent = function (event) {
        if (event && event.dataItem && event.dataItem.UnSaved && !event.sender.isEditing()) {
            this.saveChanges({ dataItem: event.dataItem });
        }
    };
    // private assignEditableFields(item: any): any {
    //     let salaryType: ISalaryTypeView = this.salaryTypes && item.SalaryTypeId ? this.salaryTypes.find((type: any) => { return type.SalaryTypeId === item.SalaryTypeId; }) : undefined;
    //     let unitType: IUnitType = item.UnitTypeId ? Global.UNIT_TYPES.find((type: any) => { return type.Id === item.UnitTypeId; }) : undefined;
    //     item.AllowEditUnitType = salaryType && salaryType.AllowEditUnitType;
    //     item.UnitTypeClass = item.AllowEditUnitType ? "" : "grid-disable-cell";
    //     item.AllowEditBaseAmount = salaryType && salaryType.AllowEditBaseAmount;
    //     item.tBaseAmountClass = item.AllowEditBaseAmount ? "" : "grid-disable-cell";
    //     item.AllowEditAmountPerUnit = unitType && unitType.Id && unitType.Id !== UnitTypeEnum.Percent;
    //     item.AmountPerUnitClass = item.AllowEditAmountPerUnit ? "" : "grid-disable-cell";
    //     item.AllowEditUnits = unitType && unitType.Id;
    //     item.UnitsClass = item.AllowEditUnits ? "" : "grid-disable-cell";
    //     return item;
    // }
    // private checkAmountAutoFlip(previous: ISalaryRecord, dataItem: ISalaryRecord): boolean {
    //     return (!previous.AmountPerUnit || previous.AmountPerUnit > 0 || !previous.Amount || previous.Amount > 0)
    //         && dataItem.Amount < 0 && dataItem.Amount !== previous.Amount;
    // }
    PayrollDataGridComponent.prototype.updateSalaryRecord = function (salaryRecord, dataItem) {
        var _this = this;
        this.update.emit();
        this.dataService.SalaryRecords_UpdateSalaryRecord(salaryRecord).subscribe(function (data) {
            if (dataItem &&
                dataItem.SalaryTypeId === data.SalaryTypeId &&
                dataItem.Units &&
                data.Units &&
                dataItem.Units !== data.Units) {
                // Units is updated by Server.
                dataItem.Amount = data.Amount;
                dataItem.Units = data.Units;
                _this.salaryRecordItemHelper.showInCellPopupMessage(dataItem, 'Units');
                _this.triggerGridComponentUpdate = true;
            }
            _this.recalculate = true;
        }, function (error) {
            _this.loadSalaryRecords();
        });
    };
    PayrollDataGridComponent.prototype.onGridAction = function (action) {
        var _this = this;
        switch (action) {
            case 'recalculate':
                setTimeout(function () {
                    _this.recalculate = true;
                });
                this.update.emit();
                break;
            case 'enableDate':
                if (!this.IsEnableDateAdjustment) {
                    this.showDateSettingConfirmation = true;
                }
                break;
            case 'disableDate':
                if (this.IsEnableDateAdjustment) {
                    this.enableDateAdjustmentSetting(false);
                }
                break;
            case 'correctBalance':
                this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Initial_Balances;
                this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
                break;
            case 'onHidePreviewGrid':
                this.isHidePreviewGrid = !this.isHidePreviewGrid;
                break;
            default:
                break;
        }
    };
    PayrollDataGridComponent.prototype.onConfirmDateAdjustmentSetting = function (action) {
        if (action === 'Enable') {
            this.enableDateAdjustmentSetting(true);
        }
    };
    PayrollDataGridComponent.prototype.onGridButtonClicked = function (args) {
        switch (args.buttonAction) {
            case this.negativeDefaultTranslationText['UsePositiveAmountThisTime']:
                this.usePositiveAmount(args.dataItem, args.field);
                break;
            case this.negativeDefaultTranslationText['MoreInfo']:
                this.onNegativeDefaultMoreInfoClick(args.dataItem, args.field);
                break;
            default:
                break;
        }
    };
    PayrollDataGridComponent.prototype.onSelectedItemChanges = function (event) {
        this.selectedItem = event;
    };
    PayrollDataGridComponent.prototype.onKeyDown = function (event) {
        if (!this.IsReadOnly) {
            if (event.keyCode in this.keyPress) {
                this.keyPress[event.keyCode] = true;
                if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
                    var dataItem = new SalaryRecord();
                    var defaultDataItem = PayrolCommonl.createSalaryRecord(dataItem, this.userEmployment.Id);
                    this.isUseHotKey = true;
                    this.addHandler(defaultDataItem);
                    this.setKeypress();
                    this.salaryRecords.push(defaultDataItem);
                    this.triggerGridComponentUpdate = true;
                }
                else if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
                    if (this.selectedItem) {
                        var deleteArgs = {};
                        deleteArgs.dataItem = this.selectedItem;
                        this.removeHandler(deleteArgs);
                        this.setKeypress();
                        this.triggerGridComponentUpdate = true;
                    }
                }
            }
            else {
                this.setKeypress();
            }
        }
    };
    PayrollDataGridComponent.prototype.setKeypress = function () {
        this.keyPress[17] = false;
        this.keyPress[18] = false;
        this.keyPress[107] = false;
        this.keyPress[109] = false;
    };
    PayrollDataGridComponent.prototype.createFormGroup = function (dataItem) {
        return new FormGroup({
            Id: new FormControl(dataItem ? dataItem.Id : undefined),
            Amount: new FormControl(dataItem ? dataItem.Amount : undefined),
            AmountPerUnit: new FormControl(dataItem ? dataItem.AmountPerUnit : undefined),
            BaseAmount: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
            Description: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
            EndDate: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
            PersistenceTypeId: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
            SalaryTypeId: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
            UnitTypeId: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
            Dimension1Value: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
            Dimension2Value: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
            Dimension3Value: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
            StartDate: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
            Units: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
            UserEmploymentId: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
            IsActive: new FormControl(dataItem ? dataItem.BaseAmount : undefined)
        });
    };
    PayrollDataGridComponent.prototype.onFilterChange = function (filter) {
        if (filter && filter.filters && filter.filters.length > 0) {
            var filterDateTimeGrid = new FilterDateTimeGrid();
            var filtersDate = [];
            // Using FilterDateTimeGrid to filter date before filter another field
            this.filter = filterDateTimeGrid.convertFilterOperator(filter, filtersDate, 'StartDate', 'EndDate');
            this.gridData = filterDateTimeGrid.doFilterDate(this.salaryRecords, filtersDate, 'StartDate', 'EndDate');
            this.gridData = filterBy(this.gridData, this.filter);
            if (filtersDate.length > 0) {
                [].push.apply(this.filter.filters, filtersDate);
            }
        }
        else {
            this.gridData = this.salaryRecords;
        }
    };
    return PayrollDataGridComponent;
}());
export { PayrollDataGridComponent };
