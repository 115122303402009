import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as srcDoc from 'srcdoc-polyfill';
import { SessionService } from '../Session/SessionService';

@Component({
  selector: 'app-i-frame-dialog',
  templateUrl: './IFrameDialog.component.html'
})
export class IFrameDialogComponent {
  @Input()
  public title = ' ';

  public visibleValue = false;

  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      if (value === false) {
        if (this.sessionService.browser.isHybridApp) {
          window.screen.orientation.lock('portrait');
        }
      }
      this.visibleValue = value;
      this.contentLoaded = false;
      this.visibleChange.emit(value);
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public iframeContentValue: string;
  @Input() public set iframeContent(value: string) {
    if (this.iframeContentValue !== value) {
      this.iframeContentValue = value;
      this.contentLoaded = false;
      this.safeContent = this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }

  public safeContent: SafeHtml;
  public helpDownloadVisible = false;

  @ViewChild('iframeInDialog', { static: false }) public iframe: ElementRef;

  public contentLoaded: boolean;

  constructor(
    public sanitizer: DomSanitizer,
    public sessionService: SessionService,
    public renderer: Renderer2,
    public elementRef: ElementRef
  ) {}

  public get isEdge(): boolean {
    return this.sessionService.browser.isEdge;
  }

  public get isFirefox(): boolean {
    return this.sessionService.browser.isFirefox;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public iframeLoaded(): void {
    if (this.isEdge && this.iframeContentValue && !this.contentLoaded) {
      // https://stackoverflow.com/questions/42257549/angular-2-srcdoc-polyfill-doesnt-work
      srcDoc.set(this.iframe.nativeElement, this.iframeContentValue);
      this.contentLoaded = true;
    }
  }
}
