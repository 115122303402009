import { IListDataItem } from '../Model/IListDataItem';
import { SessionService } from '../Services/SharedServices/Session/SessionService';

export class ListHelper {
  public static createObserveArray<T extends object>(
    org: T[],
    onPop: (element: T) => void,
    onPush: (element: T) => void
  ): T[] {
    const proxyHandler: ProxyHandler<T[]> = {
      get: (target: T[], prop: PropertyKey) => {
        switch (prop) {
          case 'push':
            return function(element: T): any {
              onPush(element);
              return Array.prototype[prop].apply(target, arguments as any);
            };
          case 'pop':
            return function(element: T): any {
              onPop(element);
              return Array.prototype[prop].apply(target, arguments as any);
            };
          case 'splice':
            return function(element: T): any {
              onPop(element);
              return Array.prototype[prop].apply(target, arguments as any);
            };
        }

        return target[prop as any];
      }
    };

    return new Proxy<T[]>(org, proxyHandler);
  }

  public static mapDataSource(
    sessionService: SessionService,
    originalDataSource: any[],
    mappedToIdProperty: string,
    mappedToTextProperty: string,
    extraItem?: IListDataItem,
    sortByName?: boolean,
    isConvertTime: boolean = true,
    additionalProperties?: string[],
    mappedToTooltipProperty?: string
  ): IListDataItem[] {
    const result: IListDataItem[] = [];
    if (!originalDataSource || originalDataSource.length === 0) {
      if (extraItem) {
        result.unshift(extraItem);
      }

      return result;
    }

    if (originalDataSource) {
      originalDataSource.forEach((dataItem: any) => {
        if (
          !dataItem ||
          !dataItem.hasOwnProperty(mappedToIdProperty) ||
          !ListHelper.hasDisplayTextProperty(dataItem, mappedToTextProperty)
        ) {
          return;
        }

        const item: any = {}; // dataItem;
        item.Id = dataItem[mappedToIdProperty];
        const itemText: string = ListHelper.getDisplayTextByField(dataItem, mappedToTextProperty);

        // Handle custom combobox Item display text.
        item.Text = itemText
          ? itemText
          : dataItem && dataItem[mappedToTextProperty]
          ? dataItem[mappedToTextProperty]
          : '';
        if (isConvertTime) {
          try {
            const date: any = new Date(item.Text);
            let dateString: string = sessionService.toString(
              item.Text ? sessionService.parseDate(item.Text) : undefined
            );
            if (!isNaN(date.getTime()) && dateString) {
              const timeString: string = sessionService.toString(sessionService.parseDate(item.Text), 'HH:mm');
              dateString += !timeString || timeString === '00:00' ? '' : ' ' + timeString;
              item.Text = dateString;
            }
          } catch (e) {}
        }

        if (mappedToTooltipProperty) {
          item[mappedToTooltipProperty] =
            mappedToTooltipProperty && dataItem && dataItem[mappedToTooltipProperty]
              ? dataItem[mappedToTooltipProperty]
              : '';
        }

        // Assign Display text back to object.
        ListHelper.setDisplayTextByField(dataItem, mappedToTextProperty, item.Text);
        if (additionalProperties) {
          additionalProperties.forEach((extraField: string) => {
            item[extraField] = dataItem[extraField];
          });
        }

        result.push(item);
      });
    }

    if (sortByName && result.length > 1) {
      result.sort((item1: IListDataItem, item2: IListDataItem) => (item1.Text < item2.Text ? -1 : 1));
    }

    if (extraItem) {
      result.unshift(extraItem);
    }

    return result;
  }

  private static getDisplayTextByField(dataItem: any, mappedToTextProperty: string): string {
    const properties: string[] = mappedToTextProperty.split('.');
    if (properties.length > 1) {
      let objectItem: any = dataItem;
      if (properties) {
        properties.forEach((property: string) => {
          objectItem = objectItem && objectItem[property] ? objectItem[property] : '';
        });
      }

      return objectItem;
    } else {
      return dataItem && dataItem[mappedToTextProperty] ? dataItem[mappedToTextProperty].toString() : '';
    }
  }

  private static hasDisplayTextProperty(dataItem: any, mappedToTextProperty: string): boolean {
    const properties: string[] = mappedToTextProperty.split('.');
    if (properties.length > 1) {
      if (!dataItem || !dataItem[properties[0]]) {
        return false;
      }

      return ListHelper.hasDisplayTextProperty(
        dataItem[properties[0]],
        properties.slice(1, properties.length).join('.')
      );
    } else {
      return dataItem.hasOwnProperty(mappedToTextProperty);
    }
  }

  private static setDisplayTextByField(dataItem: any, mappedToTextProperty: string, value: string): any {
    if (!dataItem) {
      return dataItem;
    }

    const properties: string[] = mappedToTextProperty.split('.');
    if (properties.length > 1) {
      dataItem = ListHelper.displayTextWithLevels(dataItem, properties, value);
    } else {
      dataItem[mappedToTextProperty] = value;
    }

    return dataItem;
  }

  private static displayTextWithLevels(dataItem: any, textProperties: string[], value: string): any {
    if (!dataItem) {
      return dataItem;
    }

    if (textProperties.length === 1) {
      dataItem[textProperties[0]] = value;
    } else {
      dataItem[textProperties[0]] = ListHelper.displayTextWithLevels(
        dataItem[textProperties[0]],
        textProperties.slice(1, textProperties.length),
        value
      );
    }

    return dataItem;
  }
}
