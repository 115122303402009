import { EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../Common/Constants';
import { Global } from '../Common/Global';
import { Broadcaster } from '../Services/Broadcaster';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
var EmployeeComponent = /** @class */ (function () {
    function EmployeeComponent(broadcaster, sessionService) {
        var _this = this;
        this.broadcaster = broadcaster;
        this.sessionService = sessionService;
        this.editModeValue = false;
        this.avatarChange = false;
        this.editModeChange = new EventEmitter();
        this.isBlankEmail = false;
        this.ngUnsubscribe = new Subject();
        this.broadcaster
            .on(Constants.GLOBAL_KEY_DOWN)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (keyDownEvent) {
            _this.onKeyDown(keyDownEvent);
        });
    }
    Object.defineProperty(EmployeeComponent.prototype, "editMode", {
        get: function () {
            return this.editModeValue;
        },
        set: function (value) {
            if (this.editModeValue !== value) {
                this.editModeValue = value;
                this.editModeChange.emit(value);
                // setTimeout(() => {
                //     this.editModeChange.emit(value);
                // });
            }
        },
        enumerable: true,
        configurable: true
    });
    EmployeeComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    EmployeeComponent.prototype.setCurrentEmployee = function (employee) {
        this.currentCompanyUser = employee;
        this.sessionService.currentUserEmploymentId = employee.Id;
        Global.EMPLOYEESELECT = employee;
        if (this.currentCompanyUser && !this.currentCompanyUser.PersonalEmail && !this.currentCompanyUser.CompanyEmail) {
            this.isBlankEmail = true;
        }
        else {
            this.isBlankEmail = false;
        }
    };
    EmployeeComponent.prototype.selectNewEmployee = function (newCompanyUser) {
        this.newCompanyUser = newCompanyUser;
    };
    EmployeeComponent.prototype.updateEmployee = function (employee) {
        this.refreshCurrentEmployee = employee;
    };
    EmployeeComponent.prototype.reloadEmployeeList = function (deleted) {
        if (!deleted) {
            return;
        }
        this.deletedEmployeeId = deleted.Id;
    };
    EmployeeComponent.prototype.onAddEmployeeClicked = function () {
        this.addEmployeeFired = true;
    };
    EmployeeComponent.prototype.onKeyDown = function (event) {
        this.globalKeyDown = event;
    };
    EmployeeComponent.prototype.preLoadAvatar = function (event) {
        this.avatarChange = event;
    };
    EmployeeComponent.prototype.onAvatarChanged = function () {
        this.isAvatarChanged = !this.isAvatarChanged;
    };
    return EmployeeComponent;
}());
export { EmployeeComponent };
