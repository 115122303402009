import { ChangeDetectorRef, Component, ContentChildren, ElementRef, Input, QueryList } from '@angular/core';
import { ControlComponentBase } from '../../Common/ControlComponentBase';
import { UniqueId } from '../../Common/UniqueId';
import { RadioEditOptionComponent } from './RadioEditOption.compoenet';

@Component({
  selector: 'app-radio-edit',
  templateUrl: './RadioEdit.component.html'
})
export class RadioEditComponent extends ControlComponentBase<any> {
  @Input() vertical = false;
  @Input() disable: boolean;

  @ContentChildren(RadioEditOptionComponent) public options: QueryList<RadioEditOptionComponent>;

  public controlId: string;

  constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
    this.controlId = 'radioEdit' + UniqueId();
  }

  protected valid(): boolean {
    return true;
  }

  public onClick(value: any): void {
    this.value = value;
    super.onChange();
  }
}
