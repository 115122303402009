<app-dialog
  [overlayClose]="false"
  [(visible)]="visible"
  [title]="'Company.PayrollData' | translate"
  (action)="onGridAction($event)"
  id="salaryTemplateDialog"
>
  <module-check [moduleId]="12">
    <!--[customDeleteRow]="customDeleteRow"-->
    <grid
      [(data)]="gridData"
      [editMode]="editMode"
      class="OvertimeSupplementGrid"
      [addNew]="editMode"
      [addNewDisable]="sessionService.isGetting"
      [deleteRow]="editMode"
      (addNewEvent)="onGridAdd($event)"
      (removeEvent)="onGridRemove($event)"
      (saveChangesEvent)="onGridSave($event)"
      [addNewText]="'Grids.Add New Record' | translate"
      (action)="onGridAction($event)"
      [idColumn]="'GridId'"
      [(selectedItem)]="selectedRecord"
      (selectedItemChange)="onGridSelectionChange($event)"
      [(triggerUpdate)]="refreshData"
    >
      <grid-action
        *ngIf="selectedRecord"
        [action]="'EditDescription'"
        [label]="'EmploymentPayrollData.EditDescription' | translate"
      ></grid-action>

      <gridColumn
        field="SalaryTypeId"
        [comboboxDataSource]="salaryTypes"
        [type]="'combobox'"
        [comboboxValueField]="'SalaryTypeId'"
        [comboboxDisplayField]="'Name'"
        [editable]="true"
        [title]="'EmploymentPayrollData.SalaryType' | translate"
        [width]="150"
      ></gridColumn>

      <gridColumn
        [field]="'Units'"
        [type]="'numeric'"
        [title]="'EmploymentPayrollData.Units' | translate"
        [width]="100"
        [numbericOption]="numericOptions"
        [classField]="'UnitsClass'"
        [editableField]="'UnitsEnabled'"
      ></gridColumn>

      <gridColumn
        [field]="'UnitTypeId'"
        [comboboxDataSource]="unitTypes"
        [type]="'combobox'"
        [comboboxValueField]="'Id'"
        [comboboxDisplayField]="'Name'"
        [editable]="true"
        [title]="''"
        [width]="150"
        [classField]="'UnitTypeClass'"
        [editableField]="'AllowEditUnitType'"
      ></gridColumn>

      <gridColumn
        [field]="'AmountPerUnit'"
        [type]="'numeric'"
        [title]="'EmploymentPayrollData.Per Unit' | translate"
        [width]="100"
        [numbericOption]="numericOptions"
        [classField]="'AmountPerUnitClass'"
        [editableField]="'AmountPerUnitEnabled'"
      ></gridColumn>

      <gridColumn
        [field]="'Amount'"
        [type]="'numeric'"
        [editable]="true"
        [title]="'EmploymentPayrollData.Amount' | translate"
        [width]="100"
        [numbericOption]="numericOptions"
      ></gridColumn>

      <gridColumn
        [field]="'PersistenceTypeId'"
        [comboboxDataSource]="persistanceTypes"
        [type]="'combobox'"
        [comboboxValueField]="'Id'"
        [comboboxDisplayField]="'Name'"
        [editable]="true"
        [title]="'EmploymentPayrollData.After next batch' | translate"
        [width]="150"
      ></gridColumn>
    </grid>
  </module-check>

  <app-dialog-action type="Ok"></app-dialog-action>

  <app-dialog
    [(visible)]="confirmationDialogVisible"
    (action)="onConfirmationDialogAction($event)"
    [leadingText]="'Grids.Confirm Delete' | translate"
  >
    <app-dialog-action type="Close"></app-dialog-action>
    <app-dialog-action type="Delete"></app-dialog-action>
  </app-dialog>

  <app-dialog
    *ngIf="selectedRecord"
    [overlayClose]="false"
    [(visible)]="editDescriptionDialogVisible"
    (action)="onEditDescriptionDialogAction()"
  >
    <div class="Modal-header">
      <h1>
        <strong>
          <combobox-edit-control
            [label]="'SalaryTemplateEditDescriptionTemplate.SalaryTypeNameHeadLine' | translate"
            [editMode]="false"
            [comboboxDataSource]="salaryTypes"
            [textField]="'Name'"
            [idField]="'SalaryTypeId'"
            [(value)]="selectedRecord.SalaryTypeId"
          ></combobox-edit-control>
        </strong>
      </h1>
    </div>

    <div class="FormElement">
      <label class="FormElement-label" for="templateName">
        {{ 'SalaryTemplateEditDescriptionTemplate.Description' | translate }}
      </label>
      <textarea
        class="FormElement-textarea MessageToEmployeesTextArea"
        id="description"
        [(ngModel)]="selectedRecord.Description"
      ></textarea>
    </div>

    <app-dialog-action [type]="'Ok'"></app-dialog-action>
  </app-dialog>
</app-dialog>
