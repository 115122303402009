import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NumericTextBoxOptions } from '../../../../CustomControls/NumericTextBoxOptions';
import { ITaxCardType, IUserTaxInfo } from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'view-tax-card-history-dialog',
  templateUrl: './ViewTaxCardHistoryDialog.component.html'
})
export class ViewTaxCardHistoryDialogComponent implements OnInit, OnDestroy {
  @Output() public taxCardChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public addNewTaxInfo: EventEmitter<IUserTaxInfo> = new EventEmitter<IUserTaxInfo>();
  public refreshData = false;
  public flagselect = 0;
  public defaultRecordValue: any = {};
  public get isFullAdmin(): boolean {
    return this.sessionService.role.IsFullAdmin;
  }

  @Input()
  public get isVisible(): boolean {
    return this.visible;
  }

  public set isVisible(value: boolean) {
    if (this.visible !== value && value) {
      this.getTaxCardHistory();
    }

    this.visible = value;
    this.isVisibleChange.emit(value);
  }

  @Input() public userEmploymentId: number;

  public taxCardTypes: ITaxCardType[];

  public NumericOptions: NumericTextBoxOptions = { format: 'n2', decimals: 2, step: 1, min: 0, spinners: false };
  // IUserTaxInfo
  public taxCardHistory: any[] = [];

  private visible = false;

  constructor(
    private dataService: RxDataService,
    public sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {}

  public ngOnInit(): void {
    //document.addEventListener("keydown", (event: any): void => {
    //    this.onKeyDown(event);
    //});
    this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ITaxCardType[]) => (this.taxCardTypes = data)
    );
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    //document.removeEventListener("keydown", (event: any): void => {
    //});
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  private getTaxCardHistory(): void {
    this.dataService.Employments_GetTaxCards(this.userEmploymentId).subscribe((data: IUserTaxInfo[]) => {
      if (data && data.length > 0) {
        data.forEach((value: any) => {
          value.HightlightedRow = value.IsCurrent;
          value.IsRequest = value.TaxCardRequestId ? true : false;
          value.idColumn = this.flagselect;
          value.Editable = value.IsManual;
          value.DeductionEditable = !value.IsManual || value.TaxCardTypeId !== 1 ? false : true;
          value.FreeAmountEditable = !value.IsManual || value.TaxCardTypeId !== 3 ? false : true;
          this.flagselect += 1;
        });
      }
      // this.state = {
      //     filter: { logic: "and", filters: [] }
      // };

      this.taxCardHistory = data;
      this.gridData = filterBy(this.taxCardHistory, this.filter);
      // this.gridData.forEach((model: IUserTaxInfo) => {
      //   model.ValidFrom = new DateTimeConvert(new Date(model.ValidFrom)).Date;
      //   model.ValidUntil = new DateTimeConvert(new Date(model.ValidUntil)).Date;
      // });
    });
  }

  public addHandler(dataItem: any): void {
    // const defaultDataItem: any = this.createNewDataItem();
    // Object.assign(dataItem, defaultDataItem);
    this.saveChanges(dataItem);
  }

  public onUpdateDefaultRecord(): void {
    this.defaultRecordValue = this.createNewDataItem();
  }

  public removeHandler(dataItem: any): void {
    // To remove item from history.
  }

  public newTaxInfo: IUserTaxInfo;
  public saveChanges(args: CellCloseEvent): void {
    this.flagselect += 1;
    const dataItem: any = args.dataItem ? args.dataItem : args;
    if (!this.taxCardTypes.filter((e: ITaxCardType) => e.Id === dataItem.TaxCardTypeId)) {
      return;
    }

    const userTaxInfo: IUserTaxInfo = this.createUserTaxInfo(dataItem);
    if (dataItem.Id) {
      this.dataService.Employments_UpdateTaxInfo(userTaxInfo).subscribe(
        (taxInfo: IUserTaxInfo) => {
          dataItem.Editable = taxInfo.IsManual;
          dataItem.DeductionEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 1 ? false : true;
          dataItem.FreeAmountEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 3 ? false : true;
          Object.assign(dataItem, taxInfo);
          this.refreshData = true;
          this.taxCardChange.emit();
        },
        () => {
          this.getTaxCardHistory();
        }
      );
    } else {
      this.dataService.Employments_CreateTaxInfo(userTaxInfo).subscribe(
        (taxInfo: IUserTaxInfo) => {
          this.newTaxInfo = taxInfo;
          dataItem.Id = taxInfo.Id;
          dataItem.Editable = taxInfo.IsManual;
          dataItem.DeductionEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 1 ? false : true;
          dataItem.FreeAmountEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 3 ? false : true;
          this.taxCardChange.emit();
          this.refreshData = true;
        },
        () => {
          this.getTaxCardHistory();
        }
      );
    }
  }

  public onTaxCardHistoryAction(action: string): void {
    if (action === 'Ok') {
      this.addNewTaxInfo.emit(this.newTaxInfo);
    }
  }

  private createNewDataItem(): any {
    this.flagselect += 1;
    return {
      Id: undefined,
      TaxCardTypeId: this.taxCardTypes[0] ? this.taxCardTypes[0].Id : undefined,
      IsCurrent: undefined,
      DeductionDay: 0,
      DeductionWeek: 0,
      DeductionMonth: 0,
      FreeAmount: 0,
      ValidFrom: this.getStartEndDate(false),
      ValidUntil: this.getStartEndDate(true),
      TaxRate: 0,
      IsActive: true,
      MinimumRate: undefined,
      MaximumDeductionMonth: undefined,
      MaximumDeductionDay: undefined,
      Created: this.staticDataService.getCurrentdate(),
      IsDummy: undefined,
      IsManual: true,
      Editable: true,
      idColumn: this.flagselect,
      DeductionEditable: true,
      FreeAmountEditable: true
    };
  }

  private createUserTaxInfo(dataItem: any): IUserTaxInfo {
    const userTaxCardInfo: IUserTaxInfo = {
      Created: dataItem.Created instanceof Date ? dataItem.Created : this.sessionService.parseDate(dataItem.Created),
      DeductionDay: dataItem.DeductionDay,
      DeductionMonth: dataItem.DeductionMonth,
      DeductionWeek: dataItem.DeductionWeek,
      FreeAmount: dataItem.FreeAmount,
      Id: dataItem.Id,
      IsActive: dataItem.IsActive,
      IsCurrent: dataItem.IsCurrent,
      IsDummy: dataItem.IsDummy,
      MaximumDeductionDay: dataItem.MaximumDeductionDay,
      MaximumDeductionMonth: dataItem.MaximumDeductionMonth,
      MinimumRate: dataItem.MinimumRate,
      TaxCardTypeId: dataItem.TaxCardTypeId,
      TaxRate: dataItem.TaxRate,
      UserEmploymentId: this.userEmploymentId,
      ValidFrom: dataItem.ValidFrom,
      ValidUntil: dataItem.ValidUntil,
      IsManual: dataItem.IsManual,
      ParsedFromFileName: dataItem.ParsedFromFileName || null,
      TaxCardRequestId: dataItem.TaxCardRequestId || null
    };

    return userTaxCardInfo;
  }

  private getStartEndDate(isEnddate: boolean): Date {
    const today: Date = new Date();
    const yyyy: number = today.getFullYear();
    let mm: number = today.getMonth();
    let dd = 1;
    if (isEnddate === true) {
      dd = 0;
      mm = today.getMonth() + 1;
    }
    let hour = today.getTimezoneOffset() / 60;
    hour = hour === 12 || hour === -12 ? 0 : hour;
    if (hour > 0) {
      return new Date(yyyy, mm, dd, hour, 0, 0);
    }
    return new Date(yyyy, mm, dd, -hour, 0, 0);
  }

  private keyPress: any = { 17: false, 18: false, 107: false };
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: any): void {
    if (!this.IsReadOnly && this.isFullAdmin) {
      if (event.keyCode in this.keyPress) {
        this.keyPress[event.keyCode] = true;
        if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
          const defaultDataItem: any = {};
          this.addHandler(defaultDataItem);
          this.keyPress[17] = false;
          this.keyPress[18] = false;
          this.keyPress[107] = false;
          this.taxCardHistory.push(defaultDataItem);
          this.gridData = filterBy(this.taxCardHistory, this.filter);
          this.refreshData = true;
        }
      } else {
        this.keyPress[17] = false;
        this.keyPress[18] = false;
        this.keyPress[107] = false;
      }
    }
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = filterBy(this.taxCardHistory, this.filter);
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.taxCardHistory, filter);
  }
}
