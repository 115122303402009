import { EventEmitter, OnInit } from '@angular/core';
import { Browser } from '../../../Common/Browser';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
var EmployeePayrollDataComponent = /** @class */ (function () {
    function EmployeePayrollDataComponent(settingService, dataService, sessionService) {
        this.settingService = settingService;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.currentAddnew = false;
        this.createUserEmploymentClick = new EventEmitter();
        this.isAddNewChange = new EventEmitter();
        this.recalculate = false;
        this.newTabBlockedDialogVisible = false;
        this.previewDialogVisible = false;
        this.noPayslipDialogVisible = false;
        this.isHidePreviewGrid = false;
        this.hasEmployment = false;
    }
    Object.defineProperty(EmployeePayrollDataComponent.prototype, "userEmployment", {
        get: function () {
            return this.userEmploymentValue;
        },
        set: function (value) {
            if (!this.userEmploymentValue && !value) {
                return;
            }
            this.userEmploymentValue = value;
            this.hasEmployment = this.userEmployment && this.userEmployment.Id ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataComponent.prototype, "isMobile", {
        get: function () {
            var browser = new Browser();
            return browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataComponent.prototype, "isAddNew", {
        get: function () {
            return this.currentAddnew;
        },
        set: function (value) {
            this.currentAddnew = value;
            this.isAddNewChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataComponent.prototype, "isCordovaApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataComponent.prototype, "hasUserEmployment", {
        get: function () {
            return this.hasEmployment;
        },
        enumerable: true,
        configurable: true
    });
    EmployeePayrollDataComponent.prototype.ngOnInit = function () {
        this.settingService.showModuleMessage('Employee.PayrollData');
    };
    Object.defineProperty(EmployeePayrollDataComponent.prototype, "ClassCardStack", {
        get: function () {
            if (this.isHidePreviewGrid) {
                return '';
            }
            return 'Cards Cards--flex Form--stacked';
        },
        enumerable: true,
        configurable: true
    });
    EmployeePayrollDataComponent.prototype.createUserEmployment = function () {
        this.createUserEmploymentClick.emit();
    };
    EmployeePayrollDataComponent.prototype.viewFullSalaryBatch = function () {
        this.sessionService.NavigateTo('tabs.company.salarybatches');
    };
    EmployeePayrollDataComponent.prototype.viewLastPayslip = function () {
        var _this = this;
        this.dataService
            .SalaryStatements_GetSalaryStatementsByCompanyUser(this.userEmployment.CompanyUserId)
            .subscribe(function (data) {
            if (data.length === 0) {
                _this.noPayslipDialogVisible = true;
            }
            else {
                // Only show the last payslip - which should be the first one in the list
                _this.showPayslipInNewTab(data[0]);
            }
        });
    };
    EmployeePayrollDataComponent.prototype.showPayslipInNewTab = function (payslip) {
        var _this = this;
        this.dataService.SalaryStatements_GetHtmlPreview('html', payslip).subscribe(function (data) {
            if (_this.sessionService.browser.isHybridApp) {
                _this.previewContent = data;
                _this.previewDialogVisible = true;
            }
            else {
                var newWindow = window.open('about:blank');
                if (newWindow) {
                    newWindow.document.write(data);
                    newWindow.document.close();
                }
                else {
                    _this.newTabBlockedDialogVisible = true;
                }
            }
        });
    };
    EmployeePayrollDataComponent.prototype.onUpdate = function () {
        this.isUpdate = true;
    };
    return EmployeePayrollDataComponent;
}());
export { EmployeePayrollDataComponent };
