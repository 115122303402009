import { Renderer2 } from '@angular/core';
import { Global } from '../../../../Common/Global';
import { ReflectionHelper } from '../../../../Common/ReflectionHelper';
import { UnitTypeEnum } from '../../../../Model/Enum';
import {
  ISalaryRecordPersistenceType,
  ISalaryTypeView,
  ISimpleKeyValuePair,
  IUnitType
} from '../../../../Services/ApiModel';
import { SettingService } from '../../../../Services/SharedServices/SettingService';
import { SalaryRecordCalculation } from './SalaryRecordCalculation';

export class SalaryRecordItemHelper {
  constructor(
    public dataItem: any,
    private renderer: Renderer2,
    private salaryRecordPersistanceTypes: ISalaryRecordPersistenceType[],
    private salaryTypes: ISalaryTypeView[],
    private settingService?: SettingService
  ) {}

  // #region Public methods
  public validate(modifiedFields: any): boolean {
    let valid = true;
    for (const fieldName in modifiedFields) {
      if (modifiedFields.hasOwnProperty(fieldName)) {
        switch (fieldName) {
          case 'Id':
            valid = false;
            break;
          case 'SalaryTypeId':
            const salaryTypeId: number = modifiedFields['SalaryTypeId'];
            valid = this.salaryTypes.filter((e: ISalaryTypeView) => e.SalaryTypeId === salaryTypeId).length > 0;
            break;

          case 'PersistenceTypeId':
            const persistanceTypeId: number = modifiedFields['PersistenceTypeId'];
            valid =
              this.salaryRecordPersistanceTypes.filter((e: ISalaryRecordPersistenceType) => e.Id === persistanceTypeId)
                .length > 0;
            break;

          case 'UnitTypeId':
            const unitTypeId: number = modifiedFields['UnitTypeId'];
            valid = Global.UNIT_TYPES.filter((e: IUnitType) => e.Id === unitTypeId).length > 0;
            break;
        }
      }
    }

    return valid;
  }

  public updateRelavantFields(modifiedFields: any, negativeDefault: boolean = true): void {
    let salaryTypeId: number = this.dataItem['SalaryTypeId'];
    let salaryType: ISalaryTypeView = this.getSalaryType(salaryTypeId);
    for (const fieldName in modifiedFields) {
      if (modifiedFields.hasOwnProperty(fieldName)) {
        switch (fieldName) {
          case 'SalaryTypeId':
            salaryTypeId = modifiedFields[fieldName];
            salaryType = this.getSalaryType(salaryTypeId);
            this.updateSalaryType(salaryType, negativeDefault);
            break;
          case 'PersistanceType':
            this.updatePersistanceType(modifiedFields[fieldName]);
            break;
          case 'UnitTypeId':
            this.updateUnitType(modifiedFields[fieldName]);
            break;
          case 'Units':
            this.updateUnits(modifiedFields[fieldName]);
            break;
          case 'AmountPerUnit':
            if (salaryType) {
              modifiedFields[fieldName] = this.updateAmountPerUnit(
                modifiedFields[fieldName],
                salaryType.NegativeDefault
              );
            }
            break;
          case 'BaseAmount':
            this.updateBaseAmount(modifiedFields[fieldName]);
            break;
          case 'Amount':
            if (salaryType) {
              modifiedFields[fieldName] = this.updateAmount(modifiedFields[fieldName], salaryType.NegativeDefault);
            }
            break;
        }
      }
    }

    this.updateDataItem(modifiedFields, this.dataItem);
  }

  private getSalaryType(salaryTypeId: number): ISalaryTypeView {
    return this.salaryTypes
      ? this.salaryTypes.find((e: ISalaryTypeView) => e.SalaryTypeId === salaryTypeId)
      : undefined;
  }

  public usePositiveAmount(fieldName: string): void {
    let value: any = this.dataItem[fieldName];
    if (value && value < 0) {
      value = value * -1;
      this.recalculateBaseOnNegativeDefault(fieldName, value);
    }
  }

  public autoCorrect(fieldName: string): void {
    let value: any = this.dataItem[fieldName];
    if (value > 0) {
      value = value * -1;
      this.recalculateBaseOnNegativeDefault(fieldName, value);
    }
  }

  // #endregion

  // #region Update fields

  private updatePersistanceType(persistanceType: any): void {
    const newPersistanceType: ISalaryRecordPersistenceType[] = this.salaryRecordPersistanceTypes
      ? this.salaryRecordPersistanceTypes.filter(
          (e: ISalaryRecordPersistenceType) => e.Id === (persistanceType ? persistanceType.Id : 1)
        )
      : undefined;
    if (!newPersistanceType || newPersistanceType.length < 1) {
      const previousPersistanceType: any = this.dataItem['PersistanceType'];
      this.setDataItemValue(this.dataItem, 'PersistanceTypeId', previousPersistanceType.Id);
      this.setDataItemValue(this.dataItem, 'PersistanceType', previousPersistanceType);
      persistanceType = previousPersistanceType;
    }
  }

  private updateUnitType(unitTypeId: any): void {
    const calculator: SalaryRecordCalculation = this.createSalaryRecordCalculation();
    calculator.UnitType = unitTypeId;
    calculator.calculateAmount();
    calculator.calculateBaseAmount();
    calculator.calculateUnits();

    let perUnit: number = calculator.AmountPerUnit;
    if (calculator.UnitType === UnitTypeEnum.Percent) {
      perUnit = undefined;
    }

    this.setDataItemValue(this.dataItem, 'Amount', calculator.Amount);
    this.setDataItemValue(this.dataItem, 'BaseAmount', calculator.BaseAmount);
    this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
    this.setDataItemValue(this.dataItem, 'AmountPerUnit', perUnit);
  }

  private updateUnitsWithOtherUnitType(calculator: SalaryRecordCalculation): void {
    if (calculator.UnitType === UnitTypeEnum.Percent) {
      return;
    }

    calculator.calculateAmount();
    this.setDataItemValue(this.dataItem, 'Amount', calculator.Amount);
  }

  private updateUnitsWithUnitTypePercent(calculator: SalaryRecordCalculation): void {
    if (calculator.UnitType !== UnitTypeEnum.Percent) {
      return;
    }

    calculator.calculateAmount();
    calculator.calculateBaseAmount();
    this.setDataItemValue(this.dataItem, 'Amount', calculator.Amount);
    this.setDataItemValue(this.dataItem, 'BaseAmount', calculator.BaseAmount);
  }

  private updateUnits(units: number): number {
    const calculator: SalaryRecordCalculation = this.createSalaryRecordCalculation();
    calculator.UnitType = this.dataItem['UnitTypeId'];
    calculator.Units = units;

    if (calculator.UnitType === UnitTypeEnum.Percent) {
      this.updateUnitsWithUnitTypePercent(calculator);
    } else {
      this.updateUnitsWithOtherUnitType(calculator);
    }

    return calculator.Units;
  }

  private updateAmountPerUnit(amountPerUnit: number, negativeDefault: boolean): number {
    if (negativeDefault) {
      amountPerUnit = this.calculateBaseOnNegativeDefault(amountPerUnit, this.dataItem.uid, 'AmountPerUnit');
    }

    const calculator: SalaryRecordCalculation = this.createSalaryRecordCalculation();
    if (calculator.UnitType === UnitTypeEnum.Percent) {
      return amountPerUnit;
    }

    calculator.AmountPerUnit = amountPerUnit;
    calculator.calculateAmount();
    calculator.calculateUnits();
    this.setDataItemValue(this.dataItem, 'Amount', calculator.Amount);
    this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
    return amountPerUnit;
  }

  private updateBaseAmount(baseAmount: any): number {
    const calculator: SalaryRecordCalculation = this.createSalaryRecordCalculation();
    calculator.BaseAmount = baseAmount;
    if (calculator.UnitType !== UnitTypeEnum.Percent) {
      return baseAmount;
    }

    calculator.calculateAmount();
    calculator.calculateUnits();
    this.setDataItemValue(this.dataItem, 'Amount', calculator.Amount);
    this.setDataItemValue(this.dataItem, 'Units', calculator.Units);

    return calculator.BaseAmount;
  }

  private updateAmountWithUnitTypePercent(calculator: SalaryRecordCalculation): void {
    if (calculator.UnitType !== UnitTypeEnum.Percent) {
      return;
    }

    calculator.calculateUnits();
    calculator.calculateBaseAmount();
    this.setDataItemValue(this.dataItem, 'BaseAmount', calculator.BaseAmount);
    this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
  }

  private updateAmountWithOtherUnitType(calculator: SalaryRecordCalculation): void {
    calculator.calculateUnits();
    this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
  }

  private updateAmount(amount: number, negativeDefault: boolean): any {
    const calculator: SalaryRecordCalculation = this.createSalaryRecordCalculation();
    if (negativeDefault) {
      amount = this.calculateBaseOnNegativeDefault(amount, this.dataItem.uid, 'Amount');
    }

    calculator.Amount = amount;
    if (calculator.UnitType === UnitTypeEnum.Percent) {
      this.updateAmountWithUnitTypePercent(calculator);
    } else {
      this.updateAmountWithOtherUnitType(calculator);
    }

    if (negativeDefault) {
      if (calculator.Units < 0 && calculator.AmountPerUnit > 0) {
        // Only Amount should be negative instead of Units.
        calculator.Units = calculator.Units * -1;
        calculator.AmountPerUnit = calculator.AmountPerUnit * -1;
        this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
        this.setDataItemValue(this.dataItem, 'AmountPerUnit', calculator.AmountPerUnit);
      }
    }

    // if ((!calculator.Units || !calculator.Amount) && !calculator.BaseAmount) {
    //     this.showInCellPopupMessage(this.dataItem, "BaseAmount", "BaseAmount");
    // }

    return amount;
  }

  // #endregion

  // #region Flip negative amount

  private get isAutoFlipAmountsSetting(): boolean {
    let result = true;
    const autoFlipSetting: ISimpleKeyValuePair =
      Global.USER_PREFERENCES && Global.USER_PREFERENCES.length > 0
        ? Global.USER_PREFERENCES.find((user: ISimpleKeyValuePair) => user.Key === 'SalaryRecords.AutoFlipAmounts')
        : undefined;
    if (autoFlipSetting) {
      result = autoFlipSetting.Value === 'true';
    }

    return result;
  }

  private calculateBaseOnNegativeDefault(amount: number, uid: string, fieldName: string): number {
    ////let autoFlipSetting: boolean = ();
    if (amount > 0) {
      if (this.isAutoFlipAmountsSetting) {
        amount = amount * -1;
      }
      if (!this.settingService) {
        return amount;
      }
      this.showInCellPopupMessage(this.dataItem, 'Amount', fieldName);
    }

    return amount;
  }

  private createSalaryRecordCalculation(): SalaryRecordCalculation {
    const unitTypeId: any = this.dataItem['UnitTypeId'];
    const unitType: any = Global.UNIT_TYPES ? Global.UNIT_TYPES.find((e: IUnitType) => e.Id === unitTypeId) : undefined;
    const strAmountPerUnit: string = this.dataItem['AmountPerUnit'];
    const strUint: string = this.dataItem['Units'];
    const strAmount: string = this.dataItem['Amount'];
    const strBaseAmount: string = this.dataItem['BaseAmount'];
    const amountPerUnit: number = strAmountPerUnit ? parseFloat(strAmountPerUnit) : 0;
    const unit: number = strUint ? parseFloat(strUint) : 0;
    const amount: number = strAmount ? parseFloat(strAmount) : 0;
    const baseAmount: number = strBaseAmount ? parseFloat(strBaseAmount) : 0;
    const calculator: SalaryRecordCalculation = new SalaryRecordCalculation();

    calculator.Amount = amount;
    calculator.AmountPerUnit = amountPerUnit;
    calculator.BaseAmount = baseAmount;
    calculator.UnitType = unitType ? unitType.Id : UnitTypeEnum.None;
    calculator.Units = unit;

    return calculator;
  }

  private updateDataItem(updatedProperties: any, salaryRecord: any): any {
    for (const property in updatedProperties) {
      if (updatedProperties.hasOwnProperty(property)) {
        for (const salaryRecordViewProperty in salaryRecord) {
          if (property === salaryRecordViewProperty) {
            if (ReflectionHelper.isObject(property)) {
              this.updateDataItem(property, salaryRecordViewProperty);
            } else {
              salaryRecord[salaryRecordViewProperty] = updatedProperties[property as string];
            }
          }
        }
      }
    }

    return salaryRecord;
  }

  private recalculateBaseOnNegativeDefault(fieldName: string, value: number): void {
    this.setDataItemValue(this.dataItem, fieldName, value);

    const calculator: any = this.createSalaryRecordCalculation();
    if (fieldName === 'Amount') {
      calculator.Amount = value;
      if ((value < 0 && calculator.AmountPerUnit > 0) || (value >= 0 && calculator.AmountPerUnit < 0)) {
        calculator.AmountPerUnit = calculator.AmountPerUnit * -1;
        this.setDataItemValue(this.dataItem, 'AmountPerUnit', calculator.AmountPerUnit);
      }

      calculator.calculateUnits();
      calculator.calculateBaseAmount();
      this.setDataItemValue(this.dataItem, 'BaseAmount', calculator.BaseAmount);
      this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
    }

    if (fieldName === 'AmountPerUnit') {
      calculator.AmountPerUnit = value;
      calculator.calculateAmount();
      calculator.calculateUnits();
      this.setDataItemValue(this.dataItem, 'Amount', calculator.Amount);
      this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
    }
  }

  // #endregion

  // #region Common methods

  private getPersistanceType(persistanceTypeId?: number): any {
    if (!persistanceTypeId || persistanceTypeId === 0) {
      return undefined;
    }

    const persistanceTypes: ISalaryRecordPersistenceType[] = this.salaryRecordPersistanceTypes;
    if (!persistanceTypes || persistanceTypes.length === 0) {
      return undefined;
    }

    const result: ISalaryRecordPersistenceType[] = persistanceTypes.filter(
      (e: ISalaryRecordPersistenceType) => e.Id === persistanceTypeId
    );
    if (result.length < 1) {
      return undefined;
    }

    return { Id: result[0].Id, Name: result[0].Name };
  }

  public showInCellPopupMessage(dataItem: any, field: string, triggerField?: string): void {
    const fieldName: string = field + 'PopupVisible';
    dataItem.triggerField = triggerField ? triggerField : undefined;
    dataItem[fieldName] = true;
    setTimeout(() => {
      dataItem[fieldName] = false;
    }, 4000);
  }

  public updateSalaryType(salaryType: ISalaryTypeView, negativeDefault: boolean = true): void {
    const description: string = salaryType.DefaultPayslipText;
    const unitType: IUnitType = this.getUnitType(salaryType);
    const baseAmount: number = salaryType.BaseAmount;
    const units: number = salaryType.Units;
    const amountPerUnit: number = salaryType.AmountPerUnit;
    let amount: number = salaryType.Amount;
    if (negativeDefault && salaryType.NegativeDefault) {
      amount = this.calculateBaseOnNegativeDefault(amount, this.dataItem.uid, 'Amount');
    }

    const calculator: SalaryRecordCalculation = new SalaryRecordCalculation();
    calculator.UnitType = unitType ? unitType.Id : null;
    calculator.Amount = amount;
    calculator.AmountPerUnit =
      this.isAutoFlipAmountsSetting && negativeDefault && salaryType.NegativeDefault && amountPerUnit > 0
        ? amountPerUnit * -1
        : amountPerUnit;
    calculator.BaseAmount = baseAmount;
    calculator.Units = units;
    ////calculator.calculateAmount();
    calculator.calculateUnits();
    calculator.calculateBaseAmount();
    let persistanceType: any = this.getPersistanceType(salaryType.DefaultPersistanceTypeId);

    if ((this.dataItem as any).SalaryTypeId !== salaryType.SalaryTypeId) {
      (this.dataItem as any).SalaryTypeId = salaryType ? salaryType.SalaryTypeId : null;
      try {
        this.dataItem.trigger('change', { field: 'SalaryTypeId' });
      } catch (e) {}
    }
    // ObservableObject may not be about to set the value correctly.
    if (
      this.dataItem['Description'] !== description ||
      this.dataItem['BaseAmount'] !== calculator.BaseAmount ||
      this.dataItem['AmountPerUnit'] !== calculator.AmountPerUnit ||
      this.dataItem['Amount'] !== calculator.Amount ||
      this.dataItem['Units'] !== calculator.Units ||
      this.dataItem['UnitType'] !== unitType ||
      this.dataItem['PersistanceType'] !== persistanceType
    ) {
      const dataItemObject: any = this.dataItem;
      dataItemObject.Description = description;
      //dataItemObject.BaseAmount = calculator.BaseAmount;
      // let observableUnitType: kendo.data.ObservableObject = new kendo.data.ObservableObject(unitType);
      // dataItemObject.UnitType = unitType ? observableUnitType : undefined;
      dataItemObject.UnitType = unitType;
      dataItemObject.UnitTypeId = unitType ? unitType.Id : null;
      dataItemObject.SortOrder = salaryType.SortOrder;

      this.updateDefaultUndefinedFields(dataItemObject);
      if (calculator.AmountPerUnit) {
        if (calculator.AmountPerUnit !== dataItemObject.AmountPerUnit) {
          dataItemObject.AmountPerUnit = calculator.AmountPerUnit;
        }
      }

      if (calculator.Amount) {
        if (calculator.Amount !== dataItemObject.Amount) {
          dataItemObject.Amount = calculator.Amount;
        }
      }

      if (calculator.Units) {
        if (calculator.Units !== dataItemObject.Units) {
          dataItemObject.Units = calculator.Units;
        }
      }

      if (calculator.BaseAmount) {
        if (calculator.BaseAmount !== dataItemObject.BaseAmount) {
          dataItemObject.BaseAmount = calculator.BaseAmount;
        }
      }

      if (!persistanceType && this.salaryRecordPersistanceTypes) {
        // Get the default one
        const persistanceTypes: ISalaryRecordPersistenceType[] = this.salaryRecordPersistanceTypes.filter(
          (e: ISalaryRecordPersistenceType) => e.Id === 1
        );
        persistanceType = persistanceTypes[0];
      }

      // let observablePersistanceType: kendo.data.ObservableObject = new kendo.data.ObservableObject(persistanceType);
      // dataItemObject.PersistanceType = persistanceType ? observablePersistanceType : undefined;
      dataItemObject.PersistanceType = persistanceType;
      dataItemObject.PersistenceTypeId = persistanceType ? persistanceType.Id : null;

      try {
        this.dataItem.trigger('change', { field: 'Description' });
        this.dataItem.trigger('change', { field: 'BaseAmount' });
        this.dataItem.trigger('change', { field: 'Units' });
        this.dataItem.trigger('change', { field: 'UnitType' });
        this.dataItem.trigger('change', { field: 'UnitTypeId' });
        this.dataItem.trigger('change', { field: 'AmountPerUnit' });
        this.dataItem.trigger('change', { field: 'Amount' });
        this.dataItem.trigger('change', { field: 'PersistanceType' });
        this.dataItem.trigger('change', { field: 'PersistenceTypeId' });
        this.dataItem.trigger('change', { field: 'SortOrder' });
      } catch (e) {}
    }
  }

  private updateDefaultUndefinedFields(dataItem: any): void {
    if (!dataItem.AmountPerUnit && dataItem.AmountPerUnit !== 0) {
      dataItem.AmountPerUnit = null;
    }

    if (!dataItem.Amount && dataItem.Amount !== 0) {
      dataItem.Amount = null;
    }

    if (!dataItem.Units && dataItem.Units !== 0) {
      dataItem.Units = null;
    }

    if (!dataItem.BaseAmount && dataItem.BaseAmount !== 0) {
      dataItem.BaseAmount = null;
    }
  }

  private getUnitType(salaryType: ISalaryTypeView): IUnitType {
    const unitTypes: IUnitType[] = Global.UNIT_TYPES;
    const unitypeId: number = salaryType.UnitTypeId;
    if (unitypeId) {
      const result: IUnitType[] = unitTypes.filter((e: IUnitType) => e.Id === unitypeId);
      if (result) {
        return result[0];
      }
    }

    return null;
  }

  private setDataItemValue(dataItem: any, field: string, value: any): void {
    try {
      dataItem.set(field, value);
    } catch (e) {}

    dataItem[field] = value;
  }

  // #endregion
}
