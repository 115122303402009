import { OnInit } from '@angular/core';
import { distinct, filterBy } from '@progress/kendo-data-query';
import { FilterDateTimeGrid } from '../../Common/FilterDateTimeGrid';
import { Global } from '../../Common/Global';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { AccountantCompaniesService } from './AccountantCompanies.service';
var AccountantCompaniesComponent = /** @class */ (function () {
    function AccountantCompaniesComponent(service, staticData, api, staticDataService, sessionService, dataService) {
        var _this = this;
        this.service = service;
        this.staticData = staticData;
        this.api = api;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.dataService = dataService;
        this.discardDialogVisible = false;
        this.addNewCompanyDialogVisible = false;
        this.createSuccessConfim = false;
        this.accountName = '';
        this.attachexistingcompanyVisable = false;
        this.isUnderStoodTheAbove = false;
        this.isCompleteAttached = false;
        this.isFirstLoad = false;
        this.gridRoleFilterData = filterBy(this.dataRoleList, this.roleFilter);
        this.gridFilterData = filterBy(this.dataList, this.filter);
        this.dataService.Account_GetCurrentAccount().subscribe(function (account) {
            _this.accountName = account.Name;
        });
        this.service.roles.subscribe(function (data) {
            _this.dataRoleList = data;
            _this.dataRoleList.sort(function (a, b) { return a.UserId - b.UserId; });
            _this.gridRoleFilterData = filterBy(_this.dataRoleList, _this.roleFilter);
        });
        this.service.companies.subscribe(function (data) {
            var list = data.map(function (val) { return ({
                Name: val.CountryCode,
                Id: val.CountryId,
                Key: val.CountryCode
            }); });
            _this.countryCodes = distinct(list, 'Name');
            _this.dataList = data;
            // this.dataList.forEach((model: any) => {
            //   model.IdText = model.Id.toString();
            // });
            if (_this.filter && _this.filter.filters && _this.filter.filters.length > 0) {
                var filterDateTimeGrid = new FilterDateTimeGrid();
                var filtersDate = [];
                // Using FilterDateTimeGrid to filter date before filter another field
                _this.filter = filterDateTimeGrid.convertFilterOperator(_this.filter, filtersDate, 'LastFinalized', 'NextDeadline');
                _this.gridFilterData = filterDateTimeGrid.doFilterDate(_this.dataList, filtersDate, 'LastFinalized', 'NextDeadline');
                _this.gridFilterData = filterBy(_this.gridFilterData, _this.filter);
                if (filtersDate.length > 0) {
                    [].push.apply(_this.filter.filters, filtersDate);
                }
            }
            else {
                _this.gridFilterData = _this.dataList;
            }
        });
        this.staticData.companiesSimple.subscribe(function (data) {
            if (data && data.length > 0) {
                _this.userCompanyUserViews = data.filter(function (model) {
                    return model.RoleId === 90 &&
                        (!model.AccountId || (model.AccountId && model.AccountId !== Global.SESSION.SignOnToken.AccountId));
                });
                if (_this.userCompanyUserViews && _this.userCompanyUserViews.length > 0) {
                    _this.userCompanyUserViews.forEach(function (model) {
                        model.Relevant = model.Name + (Global.SESSION.HasBackendAccess ? '(' + model.Id + ')' : '');
                    });
                    _this.selectUserCompanyUserViews = _this.userCompanyUserViews[0].Id;
                }
            }
        });
    }
    AccountantCompaniesComponent.prototype.ngOnInit = function () {
        this.isFirstLoad = true;
    };
    AccountantCompaniesComponent.prototype.changeCompany = function (companyId) {
        var _this = this;
        this.api.Auth_SetCompanyContext(companyId).subscribe(function () {
            Global.COMPANY_ID = companyId;
            _this.staticDataService.loadStaticData().then(function () {
                _this.api.Companies_GetCurrent().subscribe(function (activeCompany) {
                    Global.COMPANY = activeCompany;
                    window.location.reload();
                });
            });
        });
    };
    Object.defineProperty(AccountantCompaniesComponent.prototype, "activeTab", {
        get: function () {
            return this.sessionService.currentState;
        },
        enumerable: true,
        configurable: true
    });
    AccountantCompaniesComponent.prototype.onMoreMenuOptionClick = function (menu) {
        if (menu && menu === 'accountant_attachexistingcompany') {
            this.attachexistingcompanyVisable = true;
        }
    };
    AccountantCompaniesComponent.prototype.onEditModeChange = function () {
        if (!this.service.selectedId) {
            return;
        }
        if (this.service.editMode) {
            this.service.save();
        }
        this.service.editMode = !this.service.editMode;
    };
    AccountantCompaniesComponent.prototype.confirmAndDiscardChanges = function () {
        this.discardDialogVisible = true;
    };
    AccountantCompaniesComponent.prototype.discardDialogAction = function (action) {
        if (action === 'ConfirmDiscard') {
            this.service.discard();
            this.service.editMode = false;
        }
    };
    AccountantCompaniesComponent.prototype.onRoleFilterChange = function (filter) {
        if (filter && filter.filters && filter.filters.length > 0) {
            this.roleFilter = filter;
        }
        this.gridRoleFilterData = filterBy(this.dataRoleList, filter);
    };
    AccountantCompaniesComponent.prototype.onFilterChange = function (filter) {
        if (filter && filter.filters && filter.filters.length > 0) {
            this.filter = filter;
            var filterDateTimeGrid = new FilterDateTimeGrid();
            var filtersDate = [];
            // Using FilterDateTimeGrid to filter date before filter another field
            this.filter = filterDateTimeGrid.convertFilterOperator(this.filter, filtersDate, 'LastFinalized', 'NextDeadline');
            this.gridFilterData = filterDateTimeGrid.doFilterDate(this.dataList, filtersDate, 'LastFinalized', 'NextDeadline');
            this.gridFilterData = filterBy(this.gridFilterData, this.filter);
            if (filtersDate.length > 0) {
                [].push.apply(this.filter.filters, filtersDate);
            }
        }
        else {
            this.gridFilterData = this.dataList;
        }
    };
    AccountantCompaniesComponent.prototype.onSelectedIdChange = function (selectedId) {
        this.service.selectedId = selectedId;
        if (!this.isFirstLoad) {
            this.gridRoleFilterData = [];
        }
        this.isFirstLoad = false;
    };
    AccountantCompaniesComponent.prototype.onCreateSuccess = function (event) {
        if (event) {
            this.newCompanyId = event;
            this.createSuccessConfim = true;
        }
    };
    AccountantCompaniesComponent.prototype.onDoneCreateSuccess = function (event) {
        if (event) {
            if (event === 'Yes') {
                this.changeCompany(this.newCompanyId);
            }
            else {
                window.location.reload();
            }
        }
    };
    AccountantCompaniesComponent.prototype.onAttachingAnExistingCompany = function (event) {
        var _this = this;
        if (event) {
            if (event === 'Submit') {
                this.api.Account_AttachCompany(this.selectUserCompanyUserViews).subscribe(function () {
                    _this.isCompleteAttached = true;
                    _this.isUnderStoodTheAbove = false;
                });
            }
            if (event === 'close') {
                this.isUnderStoodTheAbove = false;
            }
        }
    };
    AccountantCompaniesComponent.prototype.ReplayMoreMenuLabelAttachingAnExistingCompany = function (message) {
        return message.replace(/{AccountName}/g, this.accountName);
    };
    return AccountantCompaniesComponent;
}());
export { AccountantCompaniesComponent };
