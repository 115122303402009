import { EventEmitter } from '@angular/core';
import { Constants } from '../../../Common/Constants';
import { Global } from '../../../Common/Global';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SalaryBatchViewModel } from '../SalaryBatchViewModel';
var RevertFinalizedBatchComponent = /** @class */ (function () {
    function RevertFinalizedBatchComponent(sessionService, dataService) {
        this.sessionService = sessionService;
        this.dataService = dataService;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.revertBatchCompleted = new EventEmitter();
        this.passwordDialogVisible = false;
        this.userName = localStorage.getItem('saveLoginUserName');
    }
    Object.defineProperty(RevertFinalizedBatchComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertFinalizedBatchComponent.prototype, "integrationName", {
        get: function () {
            return this.currentIntegrationName;
        },
        set: function (valuer) {
            this.currentIntegrationName = valuer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertFinalizedBatchComponent.prototype, "isExternalSystemRelevant", {
        get: function () {
            // GS-3436
            if (this.integrationName) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertFinalizedBatchComponent.prototype, "translationParams", {
        get: function () {
            // GS-3436
            if (this.integrationName) {
                return { externalSystem: this.integrationName };
            }
            return { externalSystem: '' };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertFinalizedBatchComponent.prototype, "isGreenland", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    RevertFinalizedBatchComponent.prototype.onDialogAction = function (action) {
        if (action === 'Understood') {
            this.passwordDialogVisible = true;
        }
    };
    RevertFinalizedBatchComponent.prototype.onPasswwordDialogAction = function (action) {
        var _this = this;
        if (action === 'Proceed') {
            var request = { Password: this.password, DoRecalc: false };
            this.dataService.SalaryBatches_RevertSalaryBatch(this.salaryBatch.Id, request).subscribe(function () {
                _this.revertBatchCompleted.emit();
                _this.visible = false;
            });
        }
        else {
            this.visible = false;
        }
    };
    return RevertFinalizedBatchComponent;
}());
export { RevertFinalizedBatchComponent };
