import { Injectable } from '@angular/core';

@Injectable()
export class ModalService {
  public title: string;
  public message: string;
  public alertVisible: boolean;

  public alert(title: string, message: string): void {
    this.title = title;
    this.message = message;
    this.alertVisible = true;
  }
}
