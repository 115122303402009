import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { StateDeclaration, StateService, Transition, TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Browser } from '../../../Common/Browser';
import { Global } from '../../../Common/Global';
import { IRequestLogEntry } from '../../../Model/IRequestLogEntry';
import { RoleFeatures } from './Features';
import { Role } from './Role';

@Injectable()
export class SessionService {
  public static serviceId = 'SessionService';

  public browser: Browser = new Browser();
  public roleKey: string;
  public role: Role = new Role();
  public feature: RoleFeatures = new RoleFeatures(this.role);
  public isSubmitting = false;
  public isGetting = false;
  public currentState = '';
  public menuToggled = false;
  public showUserMenuToggled = false;
  public showMoreMenuToggled = false;
  public showHelpMenuToggled = false;
  public employeeListActive = false;
  public currentStyleMenuTabpanelReponsive = '';
  public currentUserEmploymentId: number;
  public isApprovalMessage = false;
  public employeeTabState = '';
  public companyTabState = '';
  public selfServiceTabState = '';
  public currentSalaryPeriodId: number;
  public currentSalaryCycleId: number;
  public isShowHugeFeaturesAlert = false;
  public nextState = '';
  public checkIsAliveActive = false;
  public isDetectedCookieDisable = false;
  public checkIsAliveError = false;

  private modalTranslationsValue: { [key: string]: string } = {};
  private modalTranslationsChangeSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public get OnModalTranslateChanged(): Observable<any> {
    return this.modalTranslationsChangeSubject.asObservable();
  }
  public set modalTranslations(value: { [key: string]: string }) {
    this.modalTranslationsValue = value;
    this.modalTranslationsChangeSubject.next({});
  }
  public get modalTranslations(): { [key: string]: string } {
    return this.modalTranslationsValue;
  }

  private translateChangeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('unknown');
  public get OnTranslateChanged(): Observable<string> {
    return this.translateChangeSubject.asObservable();
  }

  private locationChangedSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public get OnLocationChanged(): Observable<string> {
    return this.locationChangedSubject.asObservable();
  }

  private locationChangeStartSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public get OnLocationChangeStart(): Observable<any> {
    return this.locationChangeStartSubject.asObservable();
  }

  public errorMessage404: string;

  private isAuthenticationKey = 'isAuthenticationKey';
  private isAliveTimerId: any;
  private isAliveSubject: Subject<{}> = new Subject();
  public get isAlive(): Observable<{}> {
    return this.isAliveSubject.asObservable();
  }
  public dateFormat: string;

  public requestLog: IRequestLogEntry[] = [];
  private requestLogEnabledValue?: boolean = undefined;
  public get requestLogEnabled() {
    if (this.requestLogEnabledValue === undefined) {
      const value: string = localStorage.getItem('requestLogEnabled');
      this.requestLogEnabledValue = value ? JSON.parse(value) : false;
    }

    return this.requestLogEnabledValue;
  }
  public set requestLogEnabled(value: boolean) {
    this.requestLogEnabledValue = value;
    localStorage.setItem('requestLogEnabled', JSON.stringify(value));
  }

  constructor(
    private translateService: TranslateService,
    @Inject(LOCALE_ID) public localeId: string,
    private intlService: IntlService,
    private transitionService: TransitionService,
    private stateService: StateService
  ) {
    this.OnTranslateChanged.subscribe((locale: string) => {
      if (locale === 'unknown') {
        if (!environment.production) {
          console.log('intlService.localeId not changed to', locale);
        }
      } else {
        this.localeId = this.applyLocale(locale);
        // (this.intlService as CldrIntlService).localeId = this.localeId;
        (this.intlService as CldrIntlService).localeId = 'da'; // Apply for GS-4780
        // if (environment.environment === 'DEV') {
        //   console.log('intlService.localeId changed to', locale);
        // }
      }
    });

    this.transitionService.onSuccess({}, (transition: Transition) => {
      this.currentState = transition.to().name;
      if (this.currentState !== 'tabs.employee' && this.currentState.includes('tabs.employee')) {
        this.employeeTabState = this.currentState;
      } else if (this.currentState !== 'tabs.company' && this.currentState.includes('tabs.company')) {
        this.companyTabState = this.currentState;
      } else if (this.currentState !== 'tabs.selfservice' && this.currentState.includes('tabs.selfservice')) {
        this.selfServiceTabState = this.currentState;
      }

      // if (environment.environment === 'DEV') {
      //   console.log('state: ', this.currentState);
      // }

      this.locationChangedSubject.next(this.currentState);
    });

    this.transitionService.onStart({}, (transition: Transition) => {
      this.locationChangeStartSubject.next(transition);
    });

    this.translateService.onTranslationChange.subscribe((event: TranslationChangeEvent) => {
      this.translateChangeSubject.next(event.lang);
      // if (environment.environment === 'DEV') {
      //   console.log('ngx onTranslationChange', event.lang);
      // }
    });

    this.translateService.onLangChange.subscribe((event: TranslationChangeEvent) => {
      this.translateChangeSubject.next(event.lang);
      // if (environment.environment === 'DEV') {
      //   console.log('ngx onLangChange', event.lang);
      // }
    });

    this.translateService.onDefaultLangChange.subscribe((event: TranslationChangeEvent) => {
      this.translateChangeSubject.next(event.lang);
      // if (environment.environment === 'DEV') {
      //   console.log('ngx onDefaultLangChange', event.lang);
      // }
    });
  }

  public logRequest(entry: IRequestLogEntry) {
    this.requestLog.push(entry);
    if (this.requestLog.length > 100) {
      this.requestLog.shift();
    }

    console.log(entry);
  }

  public applyLocale(cultureCode: string): string {
    let locale: string;
    if (cultureCode) {
      cultureCode = cultureCode.substring(0, 2);
      switch (cultureCode) {
        case 'kl':
          locale = 'da-GL';
          this.dateFormat = 'dd/MM/yyyy';
          break;
        case 'en':
          locale = 'en-GB';
          this.dateFormat = 'dd/MM/yyyy';
          break;
        default:
          locale = 'da';
          this.dateFormat = 'dd-MM-yyyy';
          break;
      }
    }

    return locale;
  }

  public toString(value: Date | number, format?: string): string {
    if (typeof value === 'number') {
      return this.intlService.toString(value, format ? format : 'n2', 'da');
    }
    return this.intlService.toString(value, format ? format : this.dateFormat);
  }

  public parseDate(value: string, format?: string): Date {
    return this.intlService.parseDate(value, format);
  }

  public parseFloat(value: string): number {
    return this.intlService.parseNumber(value);
  }

  public parseInt(value: string): number {
    return this.intlService.parseNumber(value, { maximumFractionDigits: 0 });
  }

  public NavigateTo(state: string): any {
    if (Global.SESSION && Global.SESSION.CurrentRole) {
      const currentRoleKey: string = Global.SESSION.CurrentRole.Key;
      if (currentRoleKey === 'Employee') {
        if (state.includes('tabs.company')) {
          if (Global.SESSION.ManagerForUserEmploymentIds.length > 0) {
            this.stateService.go('tabs.company.approval.timeentry');
            return;
          }
          this.stateService.go('tabs.selfservice.payslip');
          return;
        }
        if (state.includes('tabs.employee')) {
          const hasManager: boolean = this.role.EmployeeWithRoleAsManager;
          if (hasManager) {
            this.stateService.go('tabs.employee.time');
            return;
          } else {
            this.stateService.go('tabs.selfservice.payslip');
            return;
          }
        }
      }
    }
    this.stateService.go(state);
  }

  public NavigateToAppUri(uri: string): boolean {
    const stateName: string = this.getStateByUri(uri);
    if (stateName && this.currentState !== stateName) {
      this.NavigateTo(stateName);
      return true;
    }

    return false;
  }

  public getStateByUri(uri: string): string {
    let matchedState: string;
    const allStates: StateDeclaration[] = this.stateService.get();

    if (!uri || !allStates || allStates.length === 0) {
      return undefined;
    }

    for (let index = 0; index < allStates.length; index++) {
      const absState: string = this.stateService.href(allStates[index], undefined, { absolute: true });
      if (absState && absState.indexOf(uri) >= 0) {
        matchedState = allStates[index].name;
        break;
      }
    }

    return matchedState;
  }

  get IsAuthenticated(): boolean {
    try {
      const value: string = sessionStorage.getItem(this.isAuthenticationKey);
      return value === 'true';
    } catch (e) {
      this.isDetectedCookieDisable = true;
      return false;
    }
  }

  set IsAuthenticated(isAuthenticate: boolean) {
    try {
      const value: string = isAuthenticate ? 'true' : 'false';
      sessionStorage.setItem(this.isAuthenticationKey, value);
    } catch (e) {
      this.isDetectedCookieDisable = true;
    }
  }

  public get LanguageCode(): string {
    return Global.SESSION && Global.SESSION.SignOnToken.Language
      ? Global.SESSION.SignOnToken.Language.CultureCode.split('-')[0]
      : undefined;
  }

  public ResetIsAliveTimer(): void {
    if (this.isAliveTimerId) {
      clearInterval(this.isAliveTimerId);
      this.isAliveTimerId = undefined;
    }

    this.isAliveTimerId = setInterval(() => {
      if (this.currentState !== 'login' && this.IsAuthenticated) {
        this.isAliveSubject.next();
      }
    }, 60000);
  }

  public checkNavigateDefaultRole(isEmployeeState: boolean = false, isCompanyState: boolean = false): void {
    const currentRoleKey: string = Global.SESSION.CurrentRole.Key;
    if (currentRoleKey) {
      if (isEmployeeState) {
        switch (currentRoleKey) {
          case 'FullAdmin':
            if (Global.IsEmployeeTaskCompleted) {
              this.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
              this.NavigateTo('tabs.employee.payrolldata');
            } else {
              this.currentStyleMenuTabpanelReponsive = '';
              this.NavigateTo('tabs.employee.general');
            }

            break;
          case 'ReadOnly':
            this.NavigateTo('tabs.employee.payrolldata');
            break;
          case 'Employee':
            if (this.role.EmployeeWithRoleAsManager) {
              this.NavigateTo('tabs.employee.time');
            }

            this.NavigateTo('tabs.selfservice.payslip');
            break;
          case 'SalaryAdmin':
            this.NavigateTo('tabs.employee.payrolldata');
            break;
          default:
            break;
        }
      }
      if (isCompanyState) {
        switch (currentRoleKey) {
          case 'FullAdmin':
            this.NavigateTo('tabs.company.salarybatches');
            break;
          case 'ReadOnly':
            this.NavigateTo('tabs.company.salarybatches');
            break;
          case 'Employee':
            this.NavigateTo('tabs.selfservice.payslip');
            break;
          case 'SalaryAdmin':
            this.NavigateTo('tabs.company.salarybatches');
            break;
          default:
            break;
        }
      }
    }
  }
}
