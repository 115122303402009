<div>
  <div class="Island ConfigurationContent tabs">
    <div class="ConfigNav Navigation-wrapper Navigation Navigation-inner" [ngClass]="{ 'is-active': menuOpen }">
      <div class="Navigation-expandWrapper clearMarginTop">
        <button class="Navigation-link Navigation-expand MainTabButton" type="button" (click)="onToggleNavigation()">
          <div>
            <div *ngIf="approvalTimeEntryIsActive">
              {{ 'CompanySalaryTypes.ApprovalApprovalTimeEntry' | translate }}
            </div>
            <app-icon [icon]="'ChevronDown'" [size]="'xxsmall'"></app-icon>
          </div>
        </button>
      </div>
      <ul class="Navigation-list clearMarginTop">
        <li class="Navigation-item">
          <button
            id="divCompanyOptions"
            class="Navigation-link MainTabButton"
            type="button"
            (click)="gotoTimeEntryApproval()"
            [ngClass]="{ 'is-active': approvalTimeEntryIsActive }"
          >
            {{ 'CompanySalaryTypes.ApprovalApprovalTimeEntry' | translate }}
          </button>
        </li>
      </ul>
    </div>

    <div *ngIf="approvalTimeEntryIsActive">
      <!-- class="tab-content tab active" -->
      <module-check [moduleId]="8">
        <employee-time></employee-time>
      </module-check>
    </div>
  </div>

  <div class="Actions">
    <action-button
      id="ActionButtonReports"
      [buttonClass]="'Action-button Action-buttonReports'"
      [icon]="'File'"
      [label]="'Employee.Reports'"
      (click)="onShowReportsEventClick()"
      [tooltip]="'Employee.Reports'"
    ></action-button>
    <help-button [module]="'company'"></help-button>
  </div>

  <app-dialog
    [(visible)]="isVisible"
    [title]="'CompanyApproval.WelcomeTitle' | translate"
    [leadingText]="'CompanyApproval.WelcomeMessage' | translate"
  >
    <app-dialog-action type="Ok"></app-dialog-action>
  </app-dialog>

  <app-report-dialog
    [(visible)]="reportDialogVisible"
    [key]="'ApprovalTimeEntry'"
    [param]="reportParams"
  ></app-report-dialog>

  <app-dialog [(visible)]="noReportVisible" [leadingText]="'Report.NoReport' | translate">
    <app-dialog-action type="Ok"></app-dialog-action>
  </app-dialog>
</div>
