import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mobile-modal-footer',
  templateUrl: './MobileModalFooter.component.html'
})
export class MobileModalFooterComponent implements OnInit {
  constructor() {}

  private transform = false;
  ngOnInit(): void {}
}
