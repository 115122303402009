import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Global } from '../../../../Common/Global';
import { ISimpleSalaryStatement } from '../../../../Services/ApiModel';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'salary-batch-payslip-icons',
  templateUrl: './SalaryBatchPayslipIcons.component.html'
})
export class SalaryBatchPayslipIconsComponent {
  @Output() public downloadPayslipClick: EventEmitter<string> = new EventEmitter<string>();
  @Input() public selectedSalaryStatement: ISimpleSalaryStatement;
  @Input() public IsFinalizedBatch: boolean;

  public get hideDownloadIcons(): boolean {
    return !this.selectedSalaryStatement;
  }
  public get IsWeb(): boolean {
    return !this.sessionService.browser.isHybridApp;
  }
  public get isPaymentApprover(): boolean {
    return Global.SESSION && Global.SESSION.IsPaymentApprover;
  }
  public get IsAppOrDesktop(): boolean {
    return !this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
  }

  public get isHidden(): string {
    if (!this.sessionService.browser.isHybridApp) {
      return 'Icon--Payslip--hide';
    } else {
      return '';
    }
  }

  constructor(private sessionService: SessionService) {}

  public onPayslipIconClicked(type: string): void {
    if (this.downloadPayslipClick) {
      this.downloadPayslipClick.emit(type);
    }
  }
}
