<div class="Island ConfigurationContent tabs">
  <div
    class="ConfigNav Navigation-wrapper Navigation Navigation-inner extraSmallMarginLeft"
    [ngClass]="{ 'is-active': menuOpen }"
  >
    <div class="Navigation-expandWrapper clearMarginTop">
      <button class="Navigation-link Navigation-expand" type="button" (click)="onToggleNavigation()">
        <div>
          <div *ngIf="companyTemplateTabVisisble">
            {{ 'Company.Employment templates' | translate }}
          </div>
          <div *ngIf="companyintegrationsStateActive">
            {{ 'Company.ExternalIntegrations' | translate }}
          </div>
          <div *ngIf="companyDataImportStateActive">
            {{ 'Company.DataImport' | translate }}
          </div>
          <div *ngIf="companyOptionTabEnabled && companyOptionStateActive">
            {{ 'Preference.CompanyOption' | translate }}
          </div>

          <div *ngIf="companyAdvancedSalaryTypesStateActive">
            {{ 'Company.Advanced Salary Types' | translate }}
          </div>

          <div *ngIf="companyTimeEntryTypesStateActive">
            {{ 'CompanyTimeEntryTypes.TimeEntryTypes' | translate }}
          </div>

          <app-icon [icon]="'ChevronDown'" [size]="'xxsmall'"></app-icon>
        </div>
      </button>
    </div>
    <ul class="Navigation-list clearMarginTop">
      <li class="Navigation-item">
        <button
          id="CompanyConfigurationTemplateTabButton"
          class="Navigation-link MainTabButton"
          type="button"
          (click)="navigateTo('tabs.company.configuration.hiringstatus')"
          [ngClass]="{ 'is-active': companyTemplateTabVisisble }"
        >
          {{ 'Company.Employment templates' | translate }}
        </button>
      </li>
      <li class="Navigation-item">
        <button
          id="CompanyConfigurationIntegrationsTabButton"
          class="Navigation-link MainTabButton"
          type="button"
          (click)="navigateTo('tabs.company.configuration.integrations')"
          [ngClass]="{ 'is-active': companyintegrationsStateActive }"
        >
          {{ 'Company.ExternalIntegrations' | translate }}
        </button>
      </li>
      <li class="Navigation-item">
        <button
          *ngIf="companyDataImportTabEnabled"
          id="CompanyConfigurationDataImportTabButton"
          class="Navigation-link MainTabButton"
          type="button"
          (click)="navigateTo('tabs.company.configuration.dataimport')"
          [ngClass]="{ 'is-active': companyDataImportStateActive }"
        >
          {{ 'Company.DataImport' | translate }}
        </button>
      </li>
      <li *ngIf="companyOptionTabEnabled" class="Navigation-item">
        <button
          id="CompanyConfigurationPreferencesTabButton"
          class="Navigation-link MainTabButton"
          type="button"
          (click)="navigateTo('tabs.company.configuration.settings')"
          [ngClass]="{ 'is-active': companyOptionStateActive }"
        >
          {{ 'Preference.CompanyOption' | translate }}
        </button>
      </li>
      <li class="Navigation-item">
        <button
          id="CompanyConfigurationSalaryTypesTabButton"
          class="Navigation-link MainTabButton"
          type="button"
          (click)="navigateTo('tabs.company.configuration.advancedsalarytypes')"
          [ngClass]="{ 'is-active': companyAdvancedSalaryTypesStateActive }"
        >
          {{ 'Company.Advanced Salary Types' | translate }}
        </button>
      </li>
      <li class="Navigation-item">
        <button
          id="CompanyConfigurationTimeEntryTypesTabButton"
          class="Navigation-link MainTabButton"
          type="button"
          (click)="navigateTo('tabs.company.configuration.timeentrytypes')"
          [ngClass]="{ 'is-active': companyTimeEntryTypesStateActive }"
        >
          {{ 'CompanyTimeEntryTypes.TimeEntryTypes' | translate }}
        </button>
      </li>
    </ul>
  </div>

  <!-- <div class="tab-content"> -->
  <company-templates
    [TriigerEditMode]="editMode"
    [(isDirty)]="isDirty"
    [(editMode)]="editMode"
    *ngIf="companyTemplateTabVisisble"
  ></company-templates>

  <integration-tab
    [(isDirty)]="isDirty"
    [(editMode)]="editMode"
    *ngIf="companyintegrationsStateActive"
  ></integration-tab>

  <div *ngIf="companyDataImportStateActive">
    <companydataimport></companydataimport>
  </div>

  <preference *ngIf="companyOptionTabEnabled && companyOptionStateActive" [owner]="'company'"></preference>

  <app-company-salary-types *ngIf="companyAdvancedSalaryTypesStateActive"></app-company-salary-types>

  <app-time-entry-types *ngIf="companyTimeEntryTypesStateActive"></app-time-entry-types>
</div>
<!-- </div> -->

<app-dialog (action)="onConfimOpenAppinBrower($event)" [(visible)]="openDialogChangeAppToBrower">
  <div [innerHTML]="'Company.AlertAppInProblematicViews' | translate"></div>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="DiscardAndLeave"></app-dialog-action>
</app-dialog>
