import * as tslib_1 from "tslib";
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '@progress/kendo-angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from './Session/SessionService';
var KendoTranslateService = /** @class */ (function (_super) {
    tslib_1.__extends(KendoTranslateService, _super);
    function KendoTranslateService(translateService, sessionService) {
        var _this = _super.call(this) || this;
        _this.translateService = translateService;
        _this.sessionService = sessionService;
        _this.data = {
            rtl: false,
            messages: {
                'kendo.grid.pagerOf': 'of',
                'kendo.grid.pagerItems': 'items'
            }
        };
        _this.ngUnsubscribe = new Subject();
        _this.sessionService.OnTranslateChanged.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (e) {
            return _this.translateText();
        });
        return _this;
    }
    KendoTranslateService.prototype.translateText = function () {
        var _this = this;
        this.translateService.get(['Kendo']).subscribe(function (translations) {
            _this.data.messages = translations['Kendo'];
            _this.notify(_this.data.rtl);
        });
    };
    Object.defineProperty(KendoTranslateService.prototype, "messages", {
        get: function () {
            var lang = this.data;
            if (lang) {
                return lang.messages;
            }
        },
        enumerable: true,
        configurable: true
    });
    KendoTranslateService.prototype.get = function (key) {
        return this.messages[key];
    };
    return KendoTranslateService;
}(MessageService));
export { KendoTranslateService };
