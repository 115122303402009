import { EventEmitter, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SalaryBatchViewModel } from '../SalaryBatchViewModel';
import { SalaryBatchErrorsView } from './SalaryBatchErrorsView';
var SalaryBatchErrorsDialogComponent = /** @class */ (function () {
    function SalaryBatchErrorsDialogComponent(dataService, sessionService, translateService) {
        var _this = this;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.translateService = translateService;
        this.isTab = true;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.refreshSource = new EventEmitter();
        this.resendBatch = new EventEmitter();
        this.gridData = [];
        this.clearErrorsDialogVisible = false;
        this.ngUnsubscribe = new Subject();
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService
                .get([
                'CompanySalaryBatches.OrtherStatusGridErrorIconTooltip',
                'CompanySalaryBatches.OrtherStatusGridWarningIconTooltip'
            ])
                .subscribe(function (translations) {
                _this.warningTooltip = translations['CompanySalaryBatches.OrtherStatusGridWarningIconTooltip'];
                _this.errorTooltip = translations['CompanySalaryBatches.OrtherStatusGridErrorIconTooltip'];
            });
        });
    }
    Object.defineProperty(SalaryBatchErrorsDialogComponent.prototype, "salaryBatch", {
        get: function () {
            return this.salaryBatchValue;
        },
        set: function (value) {
            if (this.salaryBatchValue !== value) {
                this.salaryBatchValue = value;
                if (this.visible && value) {
                    this.loadData();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchErrorsDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.gridData = [];
                this.visibleValue = value;
                this.visibleChange.emit(value);
                if (value) {
                    this.loadData();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchErrorsDialogComponent.prototype, "isFinalized", {
        get: function () {
            return this.salaryBatch && this.salaryBatch.StatusId === 40;
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchErrorsDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SalaryBatchErrorsDialogComponent.prototype.onDialogAction = function (action) {
        switch (action) {
            case 'ClearErrors':
                this.clearErrorsDialogVisible = true;
                break;
            case 'Resend':
                this.resendBatch.emit();
                break;
        }
    };
    SalaryBatchErrorsDialogComponent.prototype.clearErrorsDialogAction = function (action) {
        var _this = this;
        if (action === 'Proceed') {
            this.dataService.SalaryBatches_ClearEIncomeErrors(this.salaryBatch.Id).subscribe(function () {
                _this.refreshSource.emit();
                _this.visible = false;
            });
        }
    };
    SalaryBatchErrorsDialogComponent.prototype.loadData = function () {
        var _this = this;
        if (this.salaryBatch && this.salaryBatch.StatusId === 40) {
            this.dataService
                .SalaryBatches_GetEIncomeResponseLines(this.salaryBatch.Id)
                .subscribe(function (data) {
                _this.gridData = data.map(function (d) { return new SalaryBatchErrorsView(d, _this.errorTooltip, _this.warningTooltip); });
            });
        }
    };
    return SalaryBatchErrorsDialogComponent;
}());
export { SalaryBatchErrorsDialogComponent };
