import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { Global } from '../../../Common/Global';
import { ILanguage, ITimeEntryType, ITimeEntryTypeTranslation } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'company-time-entry-type-translation',
  templateUrl: './TimeEntryTypeTranslation.component.html'
})
export class CompanyTimeEntryTypeTranslationComponent extends FormComponentBase implements OnInit {
  @Input() public timeEntryType: ITimeEntryType;

  public visibleValue = false;
  @Input()
  get visible(): boolean {
    return this.visibleValue;
  }
  set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
      if (value) {
        this.prepareTranslationData(this.timeEntryType);
      }
    }
  }
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public translateComplete: EventEmitter<any> = new EventEmitter<any>();

  public gridData: ITimeEntryTypeTranslation[] = [];
  public languages: ILanguage[];
  private inheritClass = 'grid-inherit-cell';
  public triggerUpdate: boolean;

  constructor(
    private dataService: RxDataService,
    private sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {
    super();
    this.childIsDirty = Array(1).fill(false);
  }

  public ngOnInit(): void {
    this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ILanguage[]) => {
      this.languages = data;
    });
  }
  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public onDialogAction(action: string): void {
    if (action === 'Update') {
      this.updateTimeEntryTypeTranslation();
    }
  }

  public onSaveChange(value: CellCloseEvent): void {
    this.childIsDirty[0] = true;
  }

  private prepareTranslationData(timeEntry: any): void {
    this.gridData = [];
    if (!timeEntry) {
      return;
    }

    this.dataService.TimeEntryTypes_GetTranslations(timeEntry.Id).subscribe((data: ITimeEntryTypeTranslation[]) => {
      this.gridData = data;
    });
  }

  private getFieldTranslation(fieldName: string): string {
    let translationText: string;
    if (
      this.timeEntryType &&
      this.timeEntryType.TimeEntryTypeTranslations &&
      this.timeEntryType.TimeEntryTypeTranslations.length > 0
    ) {
      const translation: any = this.timeEntryType.TimeEntryTypeTranslations.find(
        (t: ITimeEntryTypeTranslation) => t.LanguageId === Global.SESSION.SignOnToken.LanguageId
      );
      translationText = translation ? translation[fieldName] : undefined;
    }

    return translationText;
  }

  private getBaseTranslation(fieldName: string, languageId?: number): string {
    if (!this.timeEntryType || !this.timeEntryType.BaseTimeEntryType) {
      return undefined;
    }

    let translationText: string;
    if (
      this.timeEntryType.BaseTimeEntryType.TimeEntryTypeTranslations &&
      this.timeEntryType.BaseTimeEntryType.TimeEntryTypeTranslations.length > 0
    ) {
      const translation: any = this.timeEntryType.BaseTimeEntryType.TimeEntryTypeTranslations.find(
        (t: ITimeEntryTypeTranslation) => t.LanguageId === languageId
      );
      translationText = translation ? translation[fieldName] : undefined;
    }

    return translationText ? translationText : (this.timeEntryType.BaseTimeEntryType as any)[fieldName];
  }

  private updateTimeEntryTypeTranslation(): void {
    if (!this.gridData) {
      return;
    }

    this.dataService.TimeEntryTypes_SetTranslations(this.gridData).subscribe((data: ITimeEntryTypeTranslation[]) => {
      this.translateComplete.emit(data);
      this.gridData = data;
      this.visible = false;
    });
  }
}
