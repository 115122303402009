import { AfterViewInit, EventEmitter, OnInit } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../Common/Global';
import { RouterStateService } from '../Common/RouterState.service';
import { RxDataService } from '../Services/RxDataService';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../Services/SharedServices/StaticData.service';
import { ReportParametersDefaultValue } from '../SharedComponents/ReportsDialog/ReportParametersDefaultValue';
var SelfServiceComponent = /** @class */ (function () {
    function SelfServiceComponent(sessionService, state, dataService, staticDataService, transitionService) {
        this.sessionService = sessionService;
        this.state = state;
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.transitionService = transitionService;
        this.editModeValue = false;
        this.editModeChange = new EventEmitter();
        this.reportDialogVisible = false;
        this.createNewTimeEntryDialogVisible = false;
        this.isValid = true;
        this.discardDialogVisible = false;
        this.noReportVisible = false;
        this.allowUsersToModifyOwnData = false;
        this.isDirty = false;
        this.showSaveDataConfirmationChangeState = false;
        this.isDisableChangeEditMode = false;
        this.shouldUpdateUserEmployment = false;
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(SelfServiceComponent.prototype, "editMode", {
        get: function () {
            return this.editModeValue;
        },
        set: function (value) {
            if (this.editModeValue !== value) {
                this.editModeValue = value;
                this.editModeChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "reportKey", {
        get: function () {
            if (this.state.currentStateIs('tabs.selfservice.general')) {
                return 'SelfServiceGeneral';
            }
            if (this.state.currentStateIs('tabs.selfservice.payslip')) {
                return 'SelfServicePayslips';
            }
            if (this.state.currentStateIs('tabs.selfservice.time')) {
                return 'SelfServiceTime';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "hasEmployments", {
        get: function () {
            return this.companyUser && this.companyUser.UserEmployments && this.companyUser.UserEmployments.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "isMobileInSelfservicetime", {
        get: function () {
            return this.sessionService.browser.isMobile && this.state.currentStateIs('tabs.selfservice.time');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "reportViewEnabled", {
        get: function () {
            var _this = this;
            var area = this.reportAreas.find(function (a) { return a.Key === _this.reportKey; });
            if (area) {
                return true;
            }
            return false;
            // return this.sessionService.feature.ReportViewEnabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "currentPosition", {
        get: function () {
            return this.userEmployment ? this.userEmployment.Id : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "disabledActionButtonClass", {
        get: function () {
            return this.allowUsersToModifyOwnData ? '' : 'disabledActionButtonClass';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "isFirefox", {
        get: function () {
            return this.sessionService.browser.isFirefox;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "selfServicePlayslipTabEnabled", {
        get: function () {
            return this.hasEmployments;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "selfServiceTimeTabEnabled", {
        get: function () {
            return this.sessionService.feature.hasModuleId(8) && this.hasEmployments;
        },
        enumerable: true,
        configurable: true
    });
    SelfServiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.ReportArea.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.reportAreas = data); });
        if (this.sessionService.selfServiceTabState !== '') {
            this.sessionService.currentState = this.sessionService.selfServiceTabState;
            setTimeout(function () {
                _this.checkSwitchingSelfServiceTab();
            });
        }
        this.loadCompanyUser();
        var allowUsersToModifyOwnData = Global.COMPANY_PREFERENCES.find(function (model) { return model.Key === 'Access.AllowUsersToModifyOwnData'; });
        if (allowUsersToModifyOwnData && allowUsersToModifyOwnData.Value === 'true') {
            this.allowUsersToModifyOwnData = true;
        }
    };
    SelfServiceComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.transitionService.onStart({}, function (transition) {
            var fromState = transition.from().name;
            _this.navigationToStateName = transition.to().name;
            var generalState = 'tabs.selfservice.general';
            if (_this.editMode && fromState && fromState === generalState) {
                if (_this.isDirty) {
                    // If validation fails, alert user and do nothing.
                    // Else show action confirmation.
                    _this.showSaveDataConfirmationChangeState = true;
                    return false;
                }
                else {
                    // Editmode but no changes, we close editmode without doing anything
                    _this.editMode = false;
                    return true;
                }
            }
            else {
                _this.editMode = false;
                _this.editModeChange.emit(false);
                return true;
            }
        });
    };
    SelfServiceComponent.prototype.onTabClick = function () {
        this.sessionService.menuToggled = true;
        this.sessionService.showUserMenuToggled = false;
        this.sessionService.showMoreMenuToggled = false;
        this.sessionService.showHelpMenuToggled = false;
    };
    SelfServiceComponent.prototype.onConfirmSaveDataOnChangeState = function (action) {
        if (action === 'ContinueAndSave') {
            this.editMode = false;
            if (this.navigationToStateName) {
                this.sessionService.NavigateTo(this.navigationToStateName);
            }
        }
        else if (action === 'DiscardAndLeave') {
            this.discardChanges();
            this.isDirty = false;
            if (this.navigationToStateName) {
                this.sessionService.NavigateTo(this.navigationToStateName);
            }
        }
        this.navigationToStateName = undefined;
    };
    SelfServiceComponent.prototype.onUserEmploymentChange = function (newUserEmployment) {
        this.userEmployment = newUserEmployment;
    };
    SelfServiceComponent.prototype.onShowReportsEventClick = function () {
        if (!this.companyUser) {
            return;
        }
        var parameters = new ReportParametersDefaultValue();
        parameters.employeeId = this.companyUser ? this.companyUser.Id : undefined;
        parameters.employmentId = this.userEmployment ? this.userEmployment.Id : undefined;
        this.reportParams = parameters;
        this.reportDialogVisible = true;
    };
    SelfServiceComponent.prototype.onValidated = function (isPassed) {
        this.isValid = isPassed;
        this.shouldUpdateUserEmployment = isPassed ? true : false;
    };
    SelfServiceComponent.prototype.onEmployeeGeneralChanged = function () {
        this.shouldUpdateUserEmployment = true;
    };
    SelfServiceComponent.prototype.onEditModeChange = function (editMode) {
        if (!this.allowUsersToModifyOwnData) {
            return;
        }
        if (!this.isValid && editMode === false) {
            return;
        }
        if (editMode === false && this.shouldUpdateUserEmployment && this.isDirty) {
            this.updateUserEmployment();
        }
        if (editMode && this.companyUser && !this.shouldUpdateUserEmployment) {
            // Save original by creating a deep copy of the object
            this.originalCompanyUserData = JSON.parse(JSON.stringify(this.companyUser));
        }
        this.editMode = editMode;
    };
    SelfServiceComponent.prototype.discardDialogAction = function (action) {
        if (action === 'ConfirmDiscard') {
            this.discardChanges();
        }
    };
    SelfServiceComponent.prototype.confirmAndDiscardChanges = function () {
        if (!this.shouldUpdateUserEmployment) {
            this.editMode = false;
            return;
        }
        this.discardDialogVisible = true;
    };
    SelfServiceComponent.prototype.onDoubleClick = function () {
        if (!this.allowUsersToModifyOwnData) {
            this.isDisableChangeEditMode = true;
            return;
        }
        if (!this.editMode && this.sessionService.currentState === 'tabs.selfservice.general') {
            this.onEditModeChange(true);
        }
    };
    SelfServiceComponent.prototype.loadCompanyUser = function () {
        var _this = this;
        this.dataService
            .CompanyUsers_GetCompanyUserById(Global.SESSION.CurrentCompanyUser.Id)
            .subscribe(function (companyUser) {
            _this.companyUser = companyUser;
            _this.userEmployment =
                companyUser.UserEmployments && companyUser.UserEmployments.length > 0
                    ? companyUser.UserEmployments[0]
                    : undefined;
            if (!_this.hasEmployments && !_this.state.currentStateIs('tabs.selfservice.general')) {
                _this.sessionService.NavigateTo('tabs.selfservice.general');
            }
        });
    };
    SelfServiceComponent.prototype.updateUserEmployment = function () {
        var _this = this;
        this.dataService.CompanyUsers_UpdateCompanyUser(this.companyUser).subscribe(function (updatedCompanyUser) {
            _this.companyUser = updatedCompanyUser;
            // Reset
            _this.shouldUpdateUserEmployment = false;
        }, function (err) {
            if (_this.state.parentStateIs('tabs.selfservice')) {
                _this.editMode = true;
                _this.shouldUpdateUserEmployment = true;
            }
        });
    };
    SelfServiceComponent.prototype.discardChanges = function () {
        this.shouldUpdateUserEmployment = false;
        if (this.companyUser && this.originalCompanyUserData && this.companyUser.Id === this.originalCompanyUserData.Id) {
            this.companyUser = JSON.parse(JSON.stringify(this.originalCompanyUserData));
        }
        this.editMode = false;
    };
    SelfServiceComponent.prototype.onReloadUserEmploymentEvent = function () {
        var _this = this;
        this.dataService.Employments_GetEmployment(this.userEmployment.Id).subscribe(function (data) {
            _this.userEmployment = data;
        });
    };
    SelfServiceComponent.prototype.checkSwitchingSelfServiceTab = function () {
        if (this.sessionService.currentState) {
            switch (this.sessionService.currentState) {
                case 'tabs.selfservice.time':
                    if (!this.selfServiceTimeTabEnabled) {
                        this.sessionService.NavigateTo('tab.selfservice.general');
                    }
                    else {
                        this.sessionService.NavigateTo(this.sessionService.selfServiceTabState);
                    }
                    break;
                case 'tab.selfservice.payslip':
                    if (!this.selfServicePlayslipTabEnabled) {
                        this.sessionService.NavigateTo('tab.selfservice.general');
                    }
                    else {
                        this.sessionService.NavigateTo(this.sessionService.selfServiceTabState);
                    }
                    break;
                default:
                    this.sessionService.NavigateTo('tab.selfservice.general');
                    break;
            }
        }
    };
    return SelfServiceComponent;
}());
export { SelfServiceComponent };
