import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { Global } from '../../Common/Global';
import {
  IStatisticsDiscoCode,
  IStatisticsEmploymentPrinciple,
  IStatisticsEmploymentTerms,
  IStatisticsJobStatus,
  IStatisticsSalaryPrinciple
} from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { EmploymentStatisticsModel } from './EmploymentStatisticsModel';

@Component({
  selector: 'employment-template-statistics',
  templateUrl: './Statistics.component.html'
})
export class StatisticsComponent extends FormComponentBase implements OnInit, OnDestroy {
  private statisticsValue: EmploymentStatisticsModel = {} as any;
  @Input()
  public get statistics(): EmploymentStatisticsModel {
    return this.statisticsValue;
  }
  public set statistics(value: EmploymentStatisticsModel) {
    this.statisticsValue = value || ({} as any);
  }

  public salaryPrinciples: IStatisticsSalaryPrinciple[];
  public statisticsEmploymentPrinciple: IStatisticsEmploymentPrinciple[];
  public statisticsEmploymentTerms: IStatisticsEmploymentTerms[];
  public statisticsJobStatus: IStatisticsJobStatus[];
  public jobFunctionCodeStrings: string[] = [];

  private jobFunctionCodes: IStatisticsDiscoCode[];

  constructor(private dataService: RxDataService, private staticData: StaticDataService) {
    super();
    this.childIsValid = Array(4).fill(true);
    this.childIsDirty = Array(10).fill(false);
  }

  public ngOnInit(): void {
    this.staticData.StatisticsEmploymentPrinciple.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IStatisticsEmploymentPrinciple[]) => {
        this.statisticsEmploymentPrinciple = data;
      }
    );

    this.staticData.StatisticsEmploymentTerms.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IStatisticsEmploymentTerms[]) => {
        this.statisticsEmploymentTerms = data;
      }
    );

    this.dataService.Miscellaneous_GetStatisticsDiscoCodes().subscribe((data: IStatisticsDiscoCode[]) => {
      this.jobFunctionCodes = data;
      this.jobFunctionCodeStrings = this.prepareDataForSearchJobFunctionCodes(this.jobFunctionCodes);
    });

    this.staticData.StatisticsJobStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IStatisticsJobStatus[]) => {
        this.statisticsJobStatus = data;
      }
    );

    this.staticData.StatisticsSalaryPrinciple.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IStatisticsSalaryPrinciple[]) => {
        this.salaryPrinciples = data;
      }
    );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get IsEmployeeEmploymentStatistics(): boolean {
    return this.statistics && (this.statistics as any).HireDate;
  }

  public get JobFunctionName(): string {
    if (
      !this.statistics ||
      !this.statistics.StatisticsJobFunctionCode ||
      !this.jobFunctionCodes ||
      this.jobFunctionCodes.length === 0
    ) {
      return '';
    }

    const code: IStatisticsDiscoCode = this.jobFunctionCodes.find(
      (c: IStatisticsDiscoCode) => c.Code.toString() === this.statistics.StatisticsJobFunctionCode.toString()
    );
    return code ? code.Name : '';
  }

  public onJobFunctionCodeChanged(): void {
    this.setJobFunctionCode(this.statistics.StatisticsJobFunctionCode);
    this.onChange();
  }

  private prepareDataForSearchJobFunctionCodes(jobFunctionCodes: any): string[] {
    const codes: string[] = jobFunctionCodes.map((item: any) => this.formatJobFunctionCodes(item));
    return codes;
  }

  private formatJobFunctionCodes(jobFunctionCode: any): string {
    return jobFunctionCode.Code.toString() + ' - ' + jobFunctionCode.Name;
  }

  private setJobFunctionCode(jobFunctionCodeKey: any): void {
    const jobFunctionCode: any = this.getJobFunctionCode(jobFunctionCodeKey);
    if (!jobFunctionCode) {
      this.statistics.StatisticsJobFunctionCode = undefined;
      return;
    }

    this.statistics.StatisticsJobFunctionCode = jobFunctionCode.Code;
  }

  private getJobFunctionCode(code: any): any {
    if (!code) {
      return undefined;
    }

    const jobFunctionCode: any = this.jobFunctionCodes
      ? this.jobFunctionCodes.find((item: any) => {
          const formattedJobFunctionCode: string = this.formatJobFunctionCodes(item);
          return formattedJobFunctionCode === code;
        })
      : undefined;

    if (jobFunctionCode) {
      return jobFunctionCode;
    }

    const jobFunctionCodes: any = this.jobFunctionCodes
      ? this.jobFunctionCodes.find((item: any) => {
          const formattedJobFunctionCode: string = this.formatJobFunctionCodes(item);
          return formattedJobFunctionCode.indexOf(code) >= 0;
        })
      : undefined;

    return jobFunctionCodes;
  }

  public get isShow(): boolean {
    return Global.COMPANY && Global.COMPANY.StatisticsRecipientId && Global.COMPANY.StatisticsRecipientId === 7;
  }
}
