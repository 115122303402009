<div class="Cards Cards--packed">
  <div class="Card">
    <div class="Card-inner">
      <contact-info
        [(companyUser)]="companyUser"
        [editMode]="editMode"
        [(isValid)]="childIsValid[0]"
        [(isDirty)]="childIsDirty[0]"
        (valueChange)="onComponentChanged()"
      ></contact-info>
    </div>
  </div>
  <div class="Card">
    <div class="Card-inner">
      <bank-info
        [(bankInfo)]="companyUser"
        [editMode]="editMode"
        [(isValid)]="childIsValid[1]"
        [(isDirty)]="childIsDirty[1]"
        (valueChange)="onComponentChanged()"
      ></bank-info>
    </div>
  </div>

  <div class="Card">
    <div class="Card-inner">
      <tax-card
        [taxCard]="taxCardObject"
        [userEmployment]="userEmployment"
        [editMode]="editMode"
        [allowEdit]="allowEdit"
        [(isValid)]="childIsValid[2]"
        [(isDirty)]="childIsDirty[2]"
        [isSelfService]="isSelfService"
        (valueChange)="onComponentChanged()"
        (reloadEmploymentEvent)="onComponentChanged(true)"
        [(refreshTaxCard)]="triggerRefreshTaxCard"
        (requestTaxCard)="showRequestTaxcardDialog()"
        (viewTaxCardHistory)="showViewTaxCardHistory($event)"
        (requestTaxcardConfirm)="showRequestTaxcardConfirm($event)"
        [selfService]="'width100'"
      ></tax-card>
    </div>
  </div>

  <div class="Card">
    <div class="Card-inner">
      <hiring-data
        [editMode]="editMode && allowEdit"
        [(userEmployment)]="userEmployment"
        [(isValid)]="childIsValid[3]"
        [(isDirty)]="childIsDirty[3]"
        (valueChange)="onComponentChanged()"
      ></hiring-data>
    </div>
  </div>

  <div class="Card">
    <div class="Card-inner">
      <employee-benefits></employee-benefits>
    </div>
  </div>
</div>

<app-dialog
  *ngIf="companyUser && companyUser.Id"
  [(visible)]="resendWelcomeEmail"
  (action)="onresendEmployeeWelcomEmailConfirmCompleted($event)"
  [leadingText]="'More.ResendEmailConfirmation' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Proceed" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  *ngIf="companyUser && companyUser.Id"
  [(visible)]="resetPassword"
  (action)="onResetEmployeePasswordConfirmCompleted($event)"
  [title]="'Account.ResetPasswordTitle' | translate"
  [leadingText]="'More.ResetPasswordConfirmation' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Proceed" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="resendEmailSuccessVisible" [leadingText]="'More.ResendEmailSuccessNotification' | translate">
  <app-dialog-action type="Ok" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="resetPasswordSuccessVisible" [leadingText]="'Account.SendNewPasswordMessageAdmin' | translate">
  <app-dialog-action type="Ok" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<!-- taxcard modal dialogs-->
<tax-card-request-dialog
  [(isVisible)]="requestTaxcardDialogVisible"
  [taxcardTypes]="taxCardObject?.taxCardTypes"
  [userEmploymentId]="taxCardObject?.userEmploymentId"
  (change)="onReloadTaxCard()"
></tax-card-request-dialog>

<view-tax-card-history-dialog
  [(isVisible)]="taxcardHistoryDialogVisible"
  [userEmploymentId]="taxCardObject?.userEmploymentId"
  (addNewTaxInfo)="onCreateTaxInfo($event)"
  (taxCardChange)="onReloadTaxCard()"
></view-tax-card-history-dialog>

<app-dialog
  [(visible)]="requestTaxcardWarningDialog"
  (action)="onRequestTaxcardWarningDialogAction($event)"
  [leadingText]="'EmployeeGeneral.RequestTaxCardForAlreadyRequested' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="AlreadyRequestedTaxCardConfirmButton"></app-dialog-action>
</app-dialog>
<!--modal dialogs-->
