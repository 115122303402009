import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';
var ViewTaxCardHistoryDialogComponent = /** @class */ (function () {
    function ViewTaxCardHistoryDialogComponent(dataService, sessionService, staticDataService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.taxCardChange = new EventEmitter();
        this.isVisibleChange = new EventEmitter();
        this.addNewTaxInfo = new EventEmitter();
        this.refreshData = false;
        this.flagselect = 0;
        this.defaultRecordValue = {};
        this.NumericOptions = { format: 'n2', decimals: 2, step: 1, min: 0, spinners: false };
        // IUserTaxInfo
        this.taxCardHistory = [];
        this.visible = false;
        this.ngUnsubscribe = new Subject();
        this.keyPress = { 17: false, 18: false, 107: false };
        this.gridData = filterBy(this.taxCardHistory, this.filter);
    }
    Object.defineProperty(ViewTaxCardHistoryDialogComponent.prototype, "isFullAdmin", {
        get: function () {
            return this.sessionService.role.IsFullAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewTaxCardHistoryDialogComponent.prototype, "isVisible", {
        get: function () {
            return this.visible;
        },
        set: function (value) {
            if (this.visible !== value && value) {
                this.getTaxCardHistory();
            }
            this.visible = value;
            this.isVisibleChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    ViewTaxCardHistoryDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        //document.addEventListener("keydown", (event: any): void => {
        //    this.onKeyDown(event);
        //});
        this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.taxCardTypes = data); });
    };
    ViewTaxCardHistoryDialogComponent.prototype.ngOnDestroy = function () {
        //document.removeEventListener("keydown", (event: any): void => {
        //});
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(ViewTaxCardHistoryDialogComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    ViewTaxCardHistoryDialogComponent.prototype.getTaxCardHistory = function () {
        var _this = this;
        this.dataService.Employments_GetTaxCards(this.userEmploymentId).subscribe(function (data) {
            if (data && data.length > 0) {
                data.forEach(function (value) {
                    value.HightlightedRow = value.IsCurrent;
                    value.IsRequest = value.TaxCardRequestId ? true : false;
                    value.idColumn = _this.flagselect;
                    value.Editable = value.IsManual;
                    value.DeductionEditable = !value.IsManual || value.TaxCardTypeId !== 1 ? false : true;
                    value.FreeAmountEditable = !value.IsManual || value.TaxCardTypeId !== 3 ? false : true;
                    _this.flagselect += 1;
                });
            }
            // this.state = {
            //     filter: { logic: "and", filters: [] }
            // };
            _this.taxCardHistory = data;
            _this.gridData = filterBy(_this.taxCardHistory, _this.filter);
            // this.gridData.forEach((model: IUserTaxInfo) => {
            //   model.ValidFrom = new DateTimeConvert(new Date(model.ValidFrom)).Date;
            //   model.ValidUntil = new DateTimeConvert(new Date(model.ValidUntil)).Date;
            // });
        });
    };
    ViewTaxCardHistoryDialogComponent.prototype.addHandler = function (dataItem) {
        // const defaultDataItem: any = this.createNewDataItem();
        // Object.assign(dataItem, defaultDataItem);
        this.saveChanges(dataItem);
    };
    ViewTaxCardHistoryDialogComponent.prototype.onUpdateDefaultRecord = function () {
        this.defaultRecordValue = this.createNewDataItem();
    };
    ViewTaxCardHistoryDialogComponent.prototype.removeHandler = function (dataItem) {
        // To remove item from history.
    };
    ViewTaxCardHistoryDialogComponent.prototype.saveChanges = function (args) {
        var _this = this;
        this.flagselect += 1;
        var dataItem = args.dataItem ? args.dataItem : args;
        if (!this.taxCardTypes.filter(function (e) { return e.Id === dataItem.TaxCardTypeId; })) {
            return;
        }
        var userTaxInfo = this.createUserTaxInfo(dataItem);
        if (dataItem.Id) {
            this.dataService.Employments_UpdateTaxInfo(userTaxInfo).subscribe(function (taxInfo) {
                dataItem.Editable = taxInfo.IsManual;
                dataItem.DeductionEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 1 ? false : true;
                dataItem.FreeAmountEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 3 ? false : true;
                Object.assign(dataItem, taxInfo);
                _this.refreshData = true;
                _this.taxCardChange.emit();
            }, function () {
                _this.getTaxCardHistory();
            });
        }
        else {
            this.dataService.Employments_CreateTaxInfo(userTaxInfo).subscribe(function (taxInfo) {
                _this.newTaxInfo = taxInfo;
                dataItem.Id = taxInfo.Id;
                dataItem.Editable = taxInfo.IsManual;
                dataItem.DeductionEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 1 ? false : true;
                dataItem.FreeAmountEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 3 ? false : true;
                _this.taxCardChange.emit();
                _this.refreshData = true;
            }, function () {
                _this.getTaxCardHistory();
            });
        }
    };
    ViewTaxCardHistoryDialogComponent.prototype.onTaxCardHistoryAction = function (action) {
        if (action === 'Ok') {
            this.addNewTaxInfo.emit(this.newTaxInfo);
        }
    };
    ViewTaxCardHistoryDialogComponent.prototype.createNewDataItem = function () {
        this.flagselect += 1;
        return {
            Id: undefined,
            TaxCardTypeId: this.taxCardTypes[0] ? this.taxCardTypes[0].Id : undefined,
            IsCurrent: undefined,
            DeductionDay: 0,
            DeductionWeek: 0,
            DeductionMonth: 0,
            FreeAmount: 0,
            ValidFrom: this.getStartEndDate(false),
            ValidUntil: this.getStartEndDate(true),
            TaxRate: 0,
            IsActive: true,
            MinimumRate: undefined,
            MaximumDeductionMonth: undefined,
            MaximumDeductionDay: undefined,
            Created: this.staticDataService.getCurrentdate(),
            IsDummy: undefined,
            IsManual: true,
            Editable: true,
            idColumn: this.flagselect,
            DeductionEditable: true,
            FreeAmountEditable: true
        };
    };
    ViewTaxCardHistoryDialogComponent.prototype.createUserTaxInfo = function (dataItem) {
        var userTaxCardInfo = {
            Created: dataItem.Created instanceof Date ? dataItem.Created : this.sessionService.parseDate(dataItem.Created),
            DeductionDay: dataItem.DeductionDay,
            DeductionMonth: dataItem.DeductionMonth,
            DeductionWeek: dataItem.DeductionWeek,
            FreeAmount: dataItem.FreeAmount,
            Id: dataItem.Id,
            IsActive: dataItem.IsActive,
            IsCurrent: dataItem.IsCurrent,
            IsDummy: dataItem.IsDummy,
            MaximumDeductionDay: dataItem.MaximumDeductionDay,
            MaximumDeductionMonth: dataItem.MaximumDeductionMonth,
            MinimumRate: dataItem.MinimumRate,
            TaxCardTypeId: dataItem.TaxCardTypeId,
            TaxRate: dataItem.TaxRate,
            UserEmploymentId: this.userEmploymentId,
            ValidFrom: dataItem.ValidFrom,
            ValidUntil: dataItem.ValidUntil,
            IsManual: dataItem.IsManual,
            ParsedFromFileName: dataItem.ParsedFromFileName || null,
            TaxCardRequestId: dataItem.TaxCardRequestId || null
        };
        return userTaxCardInfo;
    };
    ViewTaxCardHistoryDialogComponent.prototype.getStartEndDate = function (isEnddate) {
        var today = new Date();
        var yyyy = today.getFullYear();
        var mm = today.getMonth();
        var dd = 1;
        if (isEnddate === true) {
            dd = 0;
            mm = today.getMonth() + 1;
        }
        var hour = today.getTimezoneOffset() / 60;
        hour = hour === 12 || hour === -12 ? 0 : hour;
        if (hour > 0) {
            return new Date(yyyy, mm, dd, hour, 0, 0);
        }
        return new Date(yyyy, mm, dd, -hour, 0, 0);
    };
    ViewTaxCardHistoryDialogComponent.prototype.onKeyDown = function (event) {
        if (!this.IsReadOnly && this.isFullAdmin) {
            if (event.keyCode in this.keyPress) {
                this.keyPress[event.keyCode] = true;
                if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
                    var defaultDataItem = {};
                    this.addHandler(defaultDataItem);
                    this.keyPress[17] = false;
                    this.keyPress[18] = false;
                    this.keyPress[107] = false;
                    this.taxCardHistory.push(defaultDataItem);
                    this.gridData = filterBy(this.taxCardHistory, this.filter);
                    this.refreshData = true;
                }
            }
            else {
                this.keyPress[17] = false;
                this.keyPress[18] = false;
                this.keyPress[107] = false;
            }
        }
    };
    ViewTaxCardHistoryDialogComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridData = filterBy(this.taxCardHistory, filter);
    };
    return ViewTaxCardHistoryDialogComponent;
}());
export { ViewTaxCardHistoryDialogComponent };
