import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'sort-by',
  templateUrl: './SortBy.component.html'
})
export class SortByComponent implements OnInit, OnDestroy {
  @Output() change: EventEmitter<number> = new EventEmitter<number>();

  selectedId = 0;
  sortByDataSource: any;

  constructor(private translateService: TranslateService, private sessionService: SessionService) {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) => {
      this.translateSortByDataSource();
    });
  }

  ngOnInit(): void {
    this.translateSortByDataSource();
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private translateSortByDataSource(): void {
    this.translateService
      .get(['Employee.Sort by First Name', 'Employee.Sort by Last Name'])
      .subscribe((translations: { [key: string]: string }) => {
        this.sortByDataSource = [
          { Id: 1, Text: translations['Employee.Sort by First Name'] },
          { Id: 2, Text: translations['Employee.Sort by Last Name'] }
        ];

        setTimeout(() => {
          this.selectedId = 1;
        });
      });
  }

  public onComboBoxChanged(): void {
    if (this.change && this.selectedId) {
      this.change.emit(this.selectedId);
    }
  }
}
