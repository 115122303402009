import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../../../Common/Global';
import { GridCellClickEvent } from '../../../CustomControls/Grid/CellClickEvent';
import {
  IExternalAccount,
  ISalaryTypeView,
  ITimeEntryType,
  IUnitType,
  TimeEntryType
} from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { TimeEntryTypeView } from './TimeEntryTypeView';

@Component({
  selector: 'app-time-entry-types',
  templateUrl: './TimeEntryTypesNGX.component.html'
})
export class TimeEntryTypesNGXComponent implements OnInit {
  public unitTypes: IUnitType[] = Global.UNIT_TYPES;
  public salaryTypes: ISalaryTypeView[] = [];
  public gridData: any[] = [];
  public selectedTimeEntryType: TimeEntryTypeView;
  public translationDialogVisible = false;
  public newCustomTimeEntryDialogVisible = false;
  public timeEntryTypeConfigurationDialogVisible = false;
  public externalAccounts: IExternalAccount[];
  public TimeEntryTypes: ITimeEntryType[];
  public currentLangId: number;
  private createFromScratch: ITimeEntryType = new TimeEntryType();
  private translationServiceTerms: string[];
  public hasTimeEntryModule = false;
  public get isLanguageModuleEnable(): boolean {
    return this.sessionService.feature.hasModuleId(5);
  }
  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public numberOption: any = { min: 0, step: 1, spinners: false, decimals: 0, format: 'n0' };

  constructor(
    private dataService: RxDataService,
    private staticDataService: StaticDataService,
    public translateService: TranslateService,
    public sessionService: SessionService,
    public settingService: SettingService
  ) {
    this.translationServiceTerms = ['CompanyTimeEntryTypes.SalaryTypeDisableToolTip'];
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public translatetooltipsalarytype = '';

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public enableTranslation(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService.get(this.translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
        this.translatetooltipsalarytype = translations['CompanyTimeEntryTypes.SalaryTypeDisableToolTip'];
      });
    });
  }

  public ngOnInit(): void {
    this.dataService.SalaryTypes_GetSalaryTypeViews().subscribe((data: ISalaryTypeView[]) => {
      this.salaryTypes = data;
      this.staticDataService.moduleCompanyView
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => this.loadTimeEntryTypes());
    });
    this.enableTranslation();
    this.dataService.Integrations_GetAllExternalAccounts().subscribe((externalAccounts: IExternalAccount[]) => {
      this.externalAccounts = externalAccounts;
      if (this.externalAccounts && this.externalAccounts.length > 0) {
        this.externalAccounts.forEach((model: any) => {
          model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
        });
      }
    });
    //this.loadTimeEntryTypes();
    this.createFromScratch.Name = '<Create From Scratch>';
    this.createFromScratch.Id = -1;
    this.dataService.TimeEntryTypes_GetDefaultTimeEntryTypes().subscribe((data: ITimeEntryType[]) => {
      this.TimeEntryTypes = data;
      this.TimeEntryTypes.unshift(this.createFromScratch);
    });

    this.settingService.showModuleMessage('company.timeentrytypes');
    if (this.isMobile) {
      this.sessionService.isShowHugeFeaturesAlert = true;
    }
  }

  public saveChanges(data: CellCloseEvent): void {
    data.dataItem.ExternalReference = this.getExteralReferenceCode(data.dataItem.ExternalReference, true);
    const timeEntryType: any = data.dataItem;
    timeEntryType.ExternalReference = this.getExteralReferenceCode(timeEntryType.ExternalReference);

    this.dataService.TimeEntryTypes_UpdateTimeEntryType(timeEntryType).subscribe(
      (response: ITimeEntryType) => {
        response.ExternalReference = this.getExteralReferenceCode(response.ExternalReference, true);
        this.processGridItemObject(response);
        Object.assign(data.dataItem, new TimeEntryTypeView(response, this.isLanguageModuleEnable));
      },
      (error: any) => {
        this.loadTimeEntryTypes();
      }
    );
  }

  public addNewTimeEntryType(timeEntryType: ITimeEntryType): void {
    const newRecord: TimeEntryTypeView = new TimeEntryTypeView(timeEntryType, this.isLanguageModuleEnable);
    this.processGridItemObject(newRecord);
    this.gridData.push(newRecord);
    this.selectedTimeEntryType = newRecord;
    this.gridFilterData = filterBy(this.gridData, this.filter);
  }

  public onCellClick(event: GridCellClickEvent): void {
    if (['Name', 'Description', 'DefaultPayslipText'].includes(event.field) && this.isLanguageModuleEnable) {
      this.selectedTimeEntryType = event.dataItem;
      this.translationDialogVisible = true;
    }

    if (event.field === 'configurationIcon') {
      this.timeEntryTypeConfigurationDialogVisible = true;
    }
  }

  public loadTimeEntryTypes(event?: any): void {
    if (event) {
      this.selectedTimeEntryType.TimeEntryTypeTranslations = event;
    }
    this.currentLangId = Global.SESSION.SignOnToken.LanguageId;
    this.gridData = [];
    this.gridFilterData = [];
    if (this.isLanguageModuleEnable) {
      this.dataService.TimeEntryTypes_GetRawTimeEntryTypesTranslated().subscribe((timeEntryTypes: ITimeEntryType[]) => {
        const filterTimeEntryTypes: ITimeEntryType[] = timeEntryTypes.filter(
          (type: ITimeEntryType) => !type.BaseTimeEntryType || type.BaseTimeEntryType.IsActive
        );
        const activeTimeEntryTypes: ITimeEntryType[] = filterTimeEntryTypes.filter(
          (type: ITimeEntryType) => type.IsActive !== false
        );
        const inActiveTimeEntryType: ITimeEntryType[] = filterTimeEntryTypes.filter(
          (type: ITimeEntryType) => type.IsActive === false
        );
        const sortedActiveTimeEntryTypes: ITimeEntryType[] = activeTimeEntryTypes.sort(
          (a: ITimeEntryType, b: ITimeEntryType) => this.sortCustomTimeEntryTypes(a, b)
        );
        const sortedInActiveTimeEntryTypes: ITimeEntryType[] = inActiveTimeEntryType.sort(
          (a: ITimeEntryType, b: ITimeEntryType) => this.sortCustomTimeEntryTypes(a, b)
        );
        const sortedTimeEntryTypes: ITimeEntryType[] = sortedActiveTimeEntryTypes.concat(sortedInActiveTimeEntryTypes);
        this.gridData = sortedTimeEntryTypes.map(
          (d: ITimeEntryType) => new TimeEntryTypeView(d, this.isLanguageModuleEnable)
        );
        this.gridData.forEach((model: any) => {
          this.processGridItemObject(model);
        });
        this.convertExternalRefCodeToFriendlyName();
        this.gridFilterData = filterBy(this.gridData, this.filter);
      });
    } else {
      this.dataService.TimeEntryTypes_GetRawTimeEntryTypes().subscribe((timeEntryTypes: ITimeEntryType[]) => {
        const filterTimeEntryTypes: ITimeEntryType[] = timeEntryTypes.filter(
          (type: ITimeEntryType) => !type.BaseTimeEntryType || type.BaseTimeEntryType.IsActive
        );
        const activeTimeEntryTypes: ITimeEntryType[] = filterTimeEntryTypes.filter(
          (type: ITimeEntryType) => type.IsActive !== false
        );
        const inActiveTimeEntryType: ITimeEntryType[] = filterTimeEntryTypes.filter(
          (type: ITimeEntryType) => type.IsActive === false
        );
        const sortedActiveTimeEntryTypes: ITimeEntryType[] = activeTimeEntryTypes.sort(
          (a: ITimeEntryType, b: ITimeEntryType) => this.sortCustomTimeEntryTypes(a, b)
        );
        const sortedInActiveTimeEntryTypes: ITimeEntryType[] = inActiveTimeEntryType.sort(
          (a: ITimeEntryType, b: ITimeEntryType) => this.sortCustomTimeEntryTypes(a, b)
        );
        const sortedTimeEntryTypes: ITimeEntryType[] = sortedActiveTimeEntryTypes.concat(sortedInActiveTimeEntryTypes);
        this.gridData = sortedTimeEntryTypes.map(
          (d: ITimeEntryType) => new TimeEntryTypeView(d, this.isLanguageModuleEnable)
        );
        this.gridData.forEach((model: any) => {
          this.processGridItemObject(model);
        });
        this.convertExternalRefCodeToFriendlyName();
        this.gridFilterData = filterBy(this.gridData, this.filter);
      });
    }
  }

  private processGridItemObject(model: any): void {
    const existingTimeEntryType: any =
      this.gridData && model ? this.gridData.find((s: ITimeEntryType) => s.Id === model.Id) : undefined;
    model.TimeEntryTypeTranslations = model.TimeEntryTypeTranslations
      ? model.TimeEntryTypeTranslations
      : existingTimeEntryType
      ? existingTimeEntryType.TimeEntryTypeTranslations
      : undefined;
    model.BaseTimeEntryType = model.BaseTimeEntryType
      ? model.BaseTimeEntryType
      : existingTimeEntryType
      ? existingTimeEntryType.BaseTimeEntryType
      : undefined;

    if (model.TimeEntryTypeConfigurations) {
      if (model.TimeEntryTypeConfigurations.length > 0) {
        model.customSalaryTypeClass = 'grid-disable-cell';
        model.editSalaryType = false;
        model.ToolTipSalaryType = this.translatetooltipsalarytype;
      } else {
        model.customSalaryTypeClass = '';
        model.editSalaryType = !this.IsReadOnly;
        model.ToolTipSalaryType = '';
      }
    } else {
      model.customSalaryTypeClass = '';
      model.editSalaryType = !this.IsReadOnly;
      model.ToolTipSalaryType = '';
    }
  }

  private convertExternalRefCodeToFriendlyName(): void {
    if (this.gridData && this.gridData.length > 0 && this.externalAccounts && this.externalAccounts.length > 0) {
      this.gridData.forEach((salaryType: any) => {
        salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference, true);
      });
    }
  }

  private sortCustomTimeEntryTypes(a: any, b: any): number {
    const aOrder: number =
      a.SortOrder || a.SortOrder === 0
        ? a.SortOrder
        : a.BaseTimeEntryType && (a.BaseTimeEntryType.SortOrder || a.BaseTimeEntryType.SortOrder === 0)
        ? a.BaseTimeEntryType.SortOrder
        : undefined;
    const bOrder: number =
      b.SortOrder || b.SortOrder === 0
        ? b.SortOrder
        : b.BaseTimeEntryType && (b.BaseTimeEntryType.SortOrder || b.BaseTimeEntryType.SortOrder === 0)
        ? b.BaseTimeEntryType.SortOrder
        : undefined;
    if ((aOrder === undefined && bOrder === undefined) || (aOrder === 0 && bOrder === 0)) {
      return a.Id - b.Id;
    } else if (aOrder === undefined && bOrder !== undefined) {
      return -1;
    } else if (aOrder !== undefined && bOrder === undefined) {
      return 1;
    }

    return aOrder - bOrder;
  }

  private getExteralReferenceCode(refCode: string, fullName?: boolean): any {
    if (!refCode) {
      return null;
    }

    const externalRefCode: any = this.externalAccounts
      ? this.externalAccounts.find((item: any) => {
          return refCode === item.AccountNumber || refCode === item.FriendlyName;
        })
      : null;

    if (externalRefCode) {
      return !fullName ? externalRefCode.AccountNumber : externalRefCode.FriendlyName;
    }

    return refCode;
  }

  public filter: CompositeFilterDescriptor;
  public gridFilterData: any[] = filterBy(this.gridData, this.filter);
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridFilterData = filterBy(this.gridData, filter);
  }
}
