import { ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { UniqueId } from '../Common/UniqueId';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
@Component({
  selector: 'percentage-edit',
  templateUrl: './PercentageEditControl.component.html'
})
export class PercentageEditComponent extends ControlComponentBase<number> implements OnInit {
  @Input() public label: string;
  @Input() public validationMessage: string;
  @Input() public required: boolean;
  @Input() public allowDecimal = false;
  @Input() public minValue: string;
  @Input() public maxValue: string;
  @Input() public disable = false;
  @Input() public percentageSign = true;
  @Input() public rightText: boolean;
  @Input() public step = false;
  @Input() public helpLink: string;
  @Input() public helpTooltip = '';
  @Input() public helpIcon = 'Help';
  @Input() public isOpenLink = true;
  @Input() public isSelfService = '';
  @Input() public maxHeight20 = '';
  @Input() public isValueIcon: boolean;

  public componentId: string;

  constructor(elementRef: ElementRef, private sessionService: SessionService, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
    this.componentId = 'percentDecimalControlIdString' + UniqueId();
  }

  public ngOnInit(): void {
    this.onChange();
  }

  protected valid(): boolean {
    return !this.required || (this.value === 0 || !!this.value);
  }

  public get isInvalid(): boolean {
    return this.editMode && !this.valid();
  }

  public get percentageDisplayText(): string {
    return this.percentValue || this.percentValue === 0
      ? this.sessionService.toString(this.percentValue, this.allowDecimal ? 'n2' : 'n0') + ' %'
      : '';
  }

  public get percentValue(): number {
    return this.value && this.value > 0 ? this.value * 100 : this.value;
  }

  public set percentValue(value: number) {
    setTimeout(() => {
      if (value && value > 1) {
        this.value = this.sessionService.parseFloat(this.sessionService.toString(value / 100, 'n5'));
      } else {
        this.value =
          value || value === 0
            ? this.sessionService.parseFloat(this.sessionService.toString(value / 100, 'n5'))
            : this.value;
      }
    });
  }
}
