import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridCellClickEvent } from '../../../CustomControls/Grid/CellClickEvent';
import { PriceFormatPipe } from '../../../Filters/PriceFormat.pipe';
import {
  IExternalAccount,
  IModuleCompanyView,
  ISalaryType,
  ISalaryTypeView,
  ISimpleSalaryTypeUpdateRequest,
  SalaryType
} from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { SalaryTypeView } from '../SalaryTypes/SalaryTypeView';

@Component({
  selector: 'app-company-standard-salary-types',
  templateUrl: './CompanyStandardSalaryTypes.component.html'
})
export class CompanyStandardSalaryTypesComponent implements OnInit, OnDestroy {
  public salaryTypes: SalaryTypeView[] = [];
  public externalReferences: any[] = [];
  public hasSalaryTypeModule = false;
  public modulePrice: number;
  public moduleName: string;
  private currentModules: IModuleCompanyView[] = [];
  private moduleId = 4;
  public activateModuleDialogVisible = false;
  public get isFullAdmin(): boolean {
    return this.sessionService.role.IsFullAdmin;
  }
  public moduleActivatedDialogVisible = false;
  public moduleActivatedDialogMessage = 'AccountModules.ReloginForChanges';
  private hasModuleValue = false;
  @Output() hasModuleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private dataService: RxDataService,
    public sessionService: SessionService,
    private staticDataService: StaticDataService,
    private priceFormatPipe: PriceFormatPipe
  ) {}

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.staticDataService.moduleCompanyView
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((modules: IModuleCompanyView[]) => {
        if (modules && modules.length > 0 && this.currentModules.length === 0) {
          this.currentModules = modules;
          this.onGetModulesViewComplete();
          this.loadData();
          if (this.isMobile) {
            this.sessionService.isShowHugeFeaturesAlert = true;
          }
        }
      });
  }

  public onGetModulesViewComplete(): void {
    const module: IModuleCompanyView =
      this.currentModules && this.currentModules.length
        ? this.currentModules.find((m: IModuleCompanyView) => m.ModuleId === this.moduleId)
        : undefined;
    if (module) {
      this.hasSalaryTypeModule = module.IsEnabled;
      this.modulePrice = module.ModulePrice;
      this.moduleName = module.ModuleName;
    } else {
      this.hasSalaryTypeModule = false;
    }
    setTimeout(() => {
      this.hasModuleChange.emit(this.hasSalaryTypeModule);
    });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public loadData(): void {
    this.loadSalaryTypes();
    this.dataService.Integrations_GetAllExternalAccounts().subscribe((data: IExternalAccount[]) => {
      this.onGetAllExternalAccountsComplete(data);
    });
  }

  private loadSalaryTypes(): void {
    this.dataService
      .SalaryTypes_GetAllSalaryTypeViews()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: ISalaryTypeView[]) => {
        this.salaryTypes = data.map((s: ISalaryTypeView) => {
          const base: ISalaryTypeView = s.BaseSalaryTypeId
            ? data.find((sType: ISalaryTypeView) => sType.SalaryTypeId === s.BaseSalaryTypeId)
            : undefined;
          s.Name = s.Name ? s.Name : base ? base.Name : '';

          const salaryTypeNew = new SalaryType();
          Object.assign(salaryTypeNew, s);
          salaryTypeNew.Id = s.SalaryTypeId;

          return new SalaryTypeView(salaryTypeNew, false);
        });
        this.salaryTypes.forEach((model: any) => {
          model.IconField = model.IsAdvanced === true && !this.hasSalaryTypeModule ? 'Diamond' : '';
          model.Editable = !(model.IsAdvanced === true && !this.hasSalaryTypeModule);
        });
        //this.salaryTypes = this.salaryTypes.sort((a: ISalaryTypeView, b: ISalaryTypeView) => a.SalaryTypeId > b.SalaryTypeId ? 1 : -1);
        //this.salaryTypes = this.salaryTypes.sort((a: ISalaryTypeView, b: ISalaryTypeView) => !a.IsAdvanced && b.IsAdvanced ? 1 : -1);
        this.gridData = filterBy(this.salaryTypes, this.filter);
        this.convertExternalRefCodeToFriendlyName();
      });
  }

  private onGetAllExternalAccountsComplete(externalReferences: any[]): void {
    this.externalReferences = externalReferences;
    if (this.externalReferences && this.externalReferences.length > 0) {
      externalReferences.forEach((model: any) => {
        model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
      });
      this.convertExternalRefCodeToFriendlyName();
    }
  }

  private convertExternalRefCodeToFriendlyName(): void {
    if (
      this.salaryTypes &&
      this.salaryTypes.length > 0 &&
      this.externalReferences &&
      this.externalReferences.length > 0
    ) {
      this.salaryTypes.forEach((salaryType: SalaryTypeView) => {
        salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference, true);
      });
    }
  }

  public onSave(args: CellCloseEvent): void {
    args.dataItem.ExternalReference = this.getExteralReferenceCode(args.dataItem.ExternalReference, true);
    const salaryType: any = args.dataItem.toSalaryType();
    salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference);

    let request: ISimpleSalaryTypeUpdateRequest = {
      Id: salaryType.Id ? salaryType.Id : salaryType.SalaryTypeId,
      ExternalReference: salaryType.ExternalReference
    };
    request = this.staticDataService.checkModelRecord(request);
    this.dataService.SalaryTypes_UpdateSalaryTypeSimple(request).subscribe(
      (data: ISalaryType) => {
        data.ExternalReference = this.getExteralReferenceCode(data.ExternalReference, true);
        args.dataItem = data;
        args.dataItem.SalaryTypeId = salaryType.SalaryTypeId;
      },
      (error: any) => this.loadSalaryTypes()
    );
  }

  private getExteralReferenceCode(refCode: string, fullName?: boolean): any {
    if (!refCode || !this.externalReferences) {
      return undefined;
    }

    const externalRefCode: any = this.externalReferences.find(
      (item: any) => refCode === item.AccountNumber || refCode === item.FriendlyName
    );
    if (externalRefCode) {
      return !fullName ? externalRefCode.AccountNumber : externalRefCode.FriendlyName;
    }

    return refCode;
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = filterBy(this.salaryTypes, this.filter);
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.salaryTypes, filter);
  }

  public onActivateModuleDialogAction(action: string): void {
    switch (action) {
      case 'ViewPackages':
        this.sessionService.NavigateTo('tabs.company.modules');
        break;
      case 'ActivateModule':
        this.dataService.Modules_EnableModule(this.moduleId).subscribe(
          (message: string) => {
            if (message) {
              this.moduleActivatedDialogMessage = message;
            }
            this.staticDataService.loadStaticData().then(() => {
              this.moduleActivatedDialogVisible = true;
            });
          },
          (): void => {
            // this.reloadPage();
          }
        );
        break;
    }
  }

  public onAddonClickEvent(): void {
    this.activateModuleDialogVisible = true;
  }

  public reloadPage(): void {
    window.location.reload();
  }

  //format the price before it gets passed as a translation param
  public get translationParams(): any {
    return {
      moduleName: this.moduleName,
      modulePrice:
        this.priceFormatPipe.transform(this.modulePrice, true) === 'Price.Free'
          ? '0,-'
          : this.priceFormatPipe.transform(this.modulePrice, true)
    };
  }

  public onCellClick(event: GridCellClickEvent): void {
    if (event.dataItem) {
      if (event.dataItem.IsAdvanced === true && !this.hasSalaryTypeModule) {
        this.activateModuleDialogVisible = true;
      }
    }
  }
}
