import { OnInit, Renderer2 } from '@angular/core';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { ReportParametersDefaultValue } from '../../SharedComponents/ReportsDialog/ReportParametersDefaultValue';
var ApprovalComponent = /** @class */ (function () {
    function ApprovalComponent(sessionService, renderer) {
        this.sessionService = sessionService;
        this.renderer = renderer;
        this.isVisible = false;
        this.reportDialogVisible = false;
        this.noReportVisible = false;
    }
    ApprovalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.renderer.listen(window, 'click', function () {
            if (!_this.isCollapseButtonClick) {
                _this.menuOpen = false;
            }
            else {
                _this.isCollapseButtonClick = false;
            }
        });
        if (this.sessionService.currentState === 'tabs.company.approval') {
            if (this.sessionService.isApprovalMessage) {
                this.sessionService.isApprovalMessage = false;
                this.isVisible = true;
            }
            this.gotoTimeEntryApproval();
        }
    };
    Object.defineProperty(ApprovalComponent.prototype, "approvalTimeEntryIsActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.approval.timeentry';
        },
        enumerable: true,
        configurable: true
    });
    ApprovalComponent.prototype.onToggleNavigation = function () {
        this.isCollapseButtonClick = true;
        this.menuOpen = !this.menuOpen;
    };
    ApprovalComponent.prototype.gotoTimeEntryApproval = function () {
        this.menuOpen = false;
        this.sessionService.NavigateTo('tabs.company.approval.timeentry');
    };
    ApprovalComponent.prototype.onShowReportsEventClick = function () {
        var parameters = new ReportParametersDefaultValue();
        parameters.employeeId = this.sessionService.currentSalaryPeriodId
            ? this.sessionService.currentSalaryPeriodId
            : undefined;
        this.reportParams = parameters;
        if (this.sessionService.currentSalaryPeriodId) {
            this.reportDialogVisible = true;
            this.noReportVisible = false;
        }
        else {
            this.reportDialogVisible = false;
            this.noReportVisible = true;
        }
    };
    return ApprovalComponent;
}());
export { ApprovalComponent };
