<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeEmployment.Vacation' | translate }}</legend>
    <combobox-edit-control
      [editMode]="editMode"
      [label]="'EmployeeEmployment.Vacation type' | translate"
      [(isValid)]="childIsValid[0]"
      [(isDirty)]="childIsDirty[0]"
      [comboboxDataSource]="vacationTypes"
      [disable]="disable"
      [(value)]="model.VacationTypeId"
      (valueChange)="onComboboxVacationTypeChangedEvent()"
      [textField]="'Name'"
      [idField]="'Id'"
    ></combobox-edit-control>

    <combobox-edit-control
      [editMode]="editMode"
      [label]="'EmployeeEmployment.Vacation provider' | translate"
      *ngIf="!IsNoneVacation && !IsGreenlandCompany"
      [disable]="disable"
      [(isValid)]="childIsValid[1]"
      [(isDirty)]="childIsDirty[1]"
      [nullValueLabel]="'DropdownList.None' | translate"
      [comboboxDataSource]="vacationProviders"
      [(value)]="model.VacationProviderId"
      (valueChange)="onComboboxVacationProviderValueChange()"
      [textField]="'Name'"
      [idField]="'Id'"
    ></combobox-edit-control>

    <check-edit
      *ngIf="!IsNoneVacation && !IsGreenlandCompany"
      [label]="'EmployeeEmployment.Spent vacation reduces gross salary' | translate"
      [disable]="disable || IsVacationReduceSalaryDisable"
      [(isValid)]="childIsValid[2]"
      [(isDirty)]="childIsDirty[2]"
      [(value)]="model.VacationReduceSalary"
      [editMode]="editMode"
      (valueChange)="onChange()"
    ></check-edit>

    <combobox-edit-control
      [editMode]="editMode"
      [label]="'EmployeeEmployment.Annual supplement paid out in' | translate"
      *ngIf="IsPaidVacation"
      [disable]="disable"
      [(isValid)]="childIsValid[3]"
      [(isDirty)]="childIsDirty[3]"
      [comboboxDataSource]="months"
      [(value)]="model.VacationSupplementPayoutMonth"
      [textField]="'Name'"
      [idField]="'Id'"
      (valueChange)="onChange()"
    ></combobox-edit-control>

    <percentage-edit
      *ngIf="IsPaidVacation || IsPaidVacation_GreendLand"
      [label]="VacationExtraPercentageLabel | translate"
      [editMode]="editMode"
      [disable]="disable"
      [allowDecimal]="true"
      [required]="false"
      [(isValid)]="childIsValid[4]"
      [(isDirty)]="childIsDirty[4]"
      [(value)]="model.VacationExtraPercentage"
      (valueChange)="onChange()"
    ></percentage-edit>

    <percentage-edit
      *ngIf="IsPaidVacation"
      [label]="'EmployeeEmployment.TerminationVacationOverridePercentage' | translate"
      [editMode]="editMode"
      [disable]="disable"
      [allowDecimal]="true"
      [(isValid)]="childIsValid[5]"
      [(isDirty)]="childIsDirty[5]"
      [(value)]="model.TerminationVacationOverridePercentage"
      [required]="false"
      (valueChange)="onChange()"
    ></percentage-edit>
  </fieldset>
</form>

<app-dialog
  [(visible)]="vacationProviderWarningDialogVisible"
  (action)="onVacationProviderWarningDialogAction($event)"
  [leadingText]="'EmployeeEmployment.OwnCompanyConfirm' | translate"
>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Proceed'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>
