import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { ICompanyUser, ILanguage, IRole, IUserEmployment, IUserEmploymentTemplate } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'app-employee-info',
  templateUrl: './EmployeeInfo.component.html'
})
export class EmployeeInfoComponent extends FormComponentBase implements OnInit {
  @Output() public selectedUserEmploymentChange: EventEmitter<IUserEmployment> = new EventEmitter<IUserEmployment>();
  @Output() public employmentPositionChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() public validated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public isAvatarChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public isEmployeeTab = false;
  @Input()
  public get companyUser(): ICompanyUser {
    if (!this.currentCompanyUser) {
      this.currentCompanyUser = {} as any;
    }

    return this.currentCompanyUser;
  }
  public set companyUser(value: ICompanyUser) {
    this.currentCompanyUser = value;
    this.selectEmploymentByParameter();
  }

  @Input() public get currentPositionId(): number {
    return this.positionId;
  }
  public set currentPositionId(value: number) {
    this.positionId = value;
  }

  @Input() public newEmploymentCreated: IUserEmployment;
  @Input() public deletedEmployment: IUserEmployment;

  @Input() public allowEdit: boolean;
  @Input() public allowEditRole: boolean;
  @Input() public avatarChange: boolean;

  public expandUserInfoDetails = false;
  public languages: ILanguage[];
  public roles: IRole[];

  public hasLanguageModule = false;
  private currentCompanyUser: ICompanyUser;
  private positionId: number;
  public isCPRValid = true;
  public isNameValid = true;
  public isPositionValid = true;
  public istoogledescription = false;
  public createNewEmploymentDialogVisible = false;
  public isNoTemplate = false;

  public get isGeneralTabSelfService(): boolean {
    return this.sessionService.currentState.includes('tabs.selfservice.general');
  }

  constructor(
    private settingService: SettingService,
    private dataService: RxDataService,
    private sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.staticDataService.languages
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: ILanguage[]) => (this.languages = data));
    this.staticDataService.Role.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IRole[]) => (this.roles = data));
    this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: string): void => {
      this.selectEmploymentByParameter();
    });
  }

  private selectEmploymentByParameter(): void {
    if (this.settingService.navigationParameters['EmploymentId']) {
      const userEmploymentByParam: IUserEmployment =
        this.companyUser && this.companyUser.UserEmployments
          ? this.companyUser.UserEmployments.find(
              (employment: IUserEmployment) =>
                employment.Id === this.settingService.navigationParameters['EmploymentId']
            )
          : undefined;

      if (userEmploymentByParam) {
        this.currentPositionId = this.settingService.navigationParameters['EmploymentId'];
        this.settingService.navigationParameters['EmploymentId'] = undefined;

        this.reloadUserEmployment().then((reloadedEmployment: IUserEmployment) => {
          this.onUserEmploymentSelectionChange(reloadedEmployment ? reloadedEmployment : userEmploymentByParam);
        });
      }
    } else {
      this.reloadUserEmployment().then((reloadedEmployment: IUserEmployment) => {
        if (reloadedEmployment) {
          this.onUserEmploymentSelectionChange(reloadedEmployment);
        }
      });
    }
  }

  private reloadUserEmployment(): Promise<IUserEmployment> {
    return new Promise<IUserEmployment>((resolve: (value: any) => void, reject: (value: any) => void) => {
      if (this.settingService.navigationParameters['ReloadEmployment']) {
        this.settingService.navigationParameters['ReloadEmployment'] = false;
        if (!this.currentPositionId) {
          return resolve(undefined);
        }

        this.dataService.Employments_GetEmployment(this.currentPositionId).subscribe(
          (updatedEmployment: IUserEmployment) => {
            const employmentThatSync: IUserEmployment = this.companyUser
              ? this.companyUser.UserEmployments.find(
                  (employment: IUserEmployment) => employment.Id === this.currentPositionId
                )
              : undefined;
            if (employmentThatSync) {
              this.companyUser.UserEmployments[
                this.companyUser.UserEmployments.lastIndexOf(employmentThatSync)
              ] = updatedEmployment;
            }

            return resolve(updatedEmployment);
          },
          () => {
            return resolve(undefined);
          }
        );
      } else {
        return resolve(undefined);
      }
    });
  }

  // #endregion
  public get userId(): number {
    if (this.companyUser) {
      return this.companyUser.UserId;
    }

    return undefined;
  }

  public get isFemale(): boolean {
    if (this.companyUser) {
      return this.companyUser.IsFemale ? true : false;
    }

    return undefined;
  }

  public get showIsPaymentApprover(): boolean {
    return this.companyUser && this.companyUser.RoleId > 20 ? true : false;
  }

  public get EmployeeWithRoleAsManager(): boolean {
    return this.sessionService.role.EmployeeWithRoleAsManager;
  }

  private validate(): void {
    const isValid: boolean = this.isCPRValid && this.isNameValid && this.isPositionValid;
    if (this.validated && this.editMode) {
      this.validated.emit(isValid);
    }
  }

  public onUserEmploymentSelectionChange(userEmployment: any): void {
    this.selectedUserEmploymentChange.emit(userEmployment);
  }

  public onToggleEmployeeInfoDropdown(): void {
    this.expandUserInfoDetails = !this.expandUserInfoDetails;
    this.istoogledescription = !this.istoogledescription;
  }

  public onCprInfoChanged(): void {
    this.validate();
  }

  public onEmployeeNameChanged(): void {
    this.validate();
  }

  public onPositionTextChanged(positionText: any): void {
    this.validate();
    this.employmentPositionChanged.emit(positionText);
  }

  public onComboboxEditChange(): void {
    this.validate();
  }

  public onCheckEditChange(value: string): void {
    this.validate();
  }

  public onAvatarChanged(): void {
    this.isAvatarChanged.emit(true);
  }

  private getTemplates(): void {
    this.dataService
      .EmploymentTemplates_GetEmploymentTemplates(true)
      .subscribe((templates: IUserEmploymentTemplate[]): void => {
        if (templates.length > 0) {
          this.createNewEmploymentDialogVisible = true;
        } else {
          this.isNoTemplate = true;
        }
      });
  }

  public createNewUserEmployment(): void {
    this.getTemplates();
  }

  public onNewEmploymentCreatedEvent(newUserEmployment: IUserEmployment): void {
    this.newEmploymentCreated = newUserEmployment;
    this.selectedUserEmploymentChange.emit(newUserEmployment);
  }
}
