import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../Common/FormComponentBase';
import { IAddress, ICity, ICountry } from '../Services/ApiModel';
import { StaticDataService } from '../Services/SharedServices/StaticData.service';

@Component({
  selector: 'app-address',
  templateUrl: './AddressComponent.html'
})
export class AddressComponent extends FormComponentBase implements OnInit {
  @Input() public address: IAddress;
  public countries: ICountry[];

  private cities: ICity[];

  constructor(private staticDataService: StaticDataService) {
    super();
    this.childIsDirty = Array(5).fill(false);
  }

  public ngOnInit(): void {
    this.staticDataService.Country.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ICountry[]) => (this.countries = data)
    );
    this.staticDataService.City.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ICity[]) => (this.cities = data));
  }

  public onPostalCodeChanged(postalCode: string): void {
    if (this.address.CountryId === 1 && postalCode) {
      const city: ICity = this.cities
        ? this.cities.find((c: ICity) => {
            return c.CountryId === this.address.CountryId && c.PostalCode && postalCode === c.PostalCode.toString();
          })
        : undefined;

      if (city) {
        this.address.City = city.Key;
      }
    }
    this.onChange();
  }
}
