function _window() {
    // Return the global native browser window object
    return window;
}
var WindowReference = /** @class */ (function () {
    function WindowReference() {
    }
    Object.defineProperty(WindowReference.prototype, "nativeWindow", {
        get: function () {
            return _window();
        },
        enumerable: true,
        configurable: true
    });
    return WindowReference;
}());
export { WindowReference };
