<div [ngClass]="editMode ? 'is-editing' : ''">
  <div *ngIf="isEnable" class="Island CompanyConfigTemplate" (dblclick)="onDbClick()">
    <form id="employmentTemplateForm" name="employmentTemplateForm" onsubmit="return false;" novalidate>
      <div class="Cards Cards--flex">
        <div class="Card Card--small">
          <div class="Card-inner Form--stacked">
            <company-template-list
              [templates]="templates"
              [(selectedId)]="selectedTemplateId"
              [locked]="false"
              [refreshList]="refreshTemplateGridList"
              [(isEditMode)]="editMode"
            ></company-template-list>
            <company-template-employee-list
              [selectedTemplateId]="selectedTemplateId"
              [(hasEmployees)]="templateHasEmployees"
            ></company-template-employee-list>
          </div>
        </div>

        <div class="Card Card--large">
          <div class="Cards Cards--packed Cards--packed2col">
            <div class="Card">
              <div class="Card-inner">
                <!--General-->
                <fieldset *ngIf="templateDetail" class="Form--horizontal">
                  <legend class="Card-title">{{ 'EmployeeEmployment.General' | translate }}</legend>
                  <app-text-edit
                    label="{{ 'CompanyHiringStatus.Template name' | translate }}"
                    [required]="true"
                    [editMode]="editMode"
                    [(isDirty)]="childIsDirty[0]"
                    [(isValid)]="childIsValid[0]"
                    (valueChange)="onChange()"
                    [(value)]="templateDetail.Name"
                  ></app-text-edit>
                  <app-text-edit
                    label="{{ 'EmployeeGeneral.External reference' | translate }}"
                    [editMode]="editMode"
                    [(isDirty)]="childIsDirty[17]"
                    (valueChange)="onChange()"
                    [(value)]="templateDetail.TemplateExternalReference"
                  ></app-text-edit>

                  <combobox-edit-control
                    [editMode]="editMode"
                    [label]="'EmployeeEmployment.Salary cycle' | translate"
                    [comboboxDataSource]="salaryCycles"
                    [(isDirty)]="childIsDirty[1]"
                    [(isValid)]="childIsValid[1]"
                    [(value)]="templateDetail.SalaryCycleId"
                    (valueChange)="onChange()"
                    [textField]="'Name'"
                    [idField]="'Id'"
                  ></combobox-edit-control>

                  <module-check [moduleId]="4" [(hasModule)]="hasDepartmentModule">
                    <div
                      class="Control--wrapper"
                      [ngClass]="editMode && !IsDisableCreateDepartment ? 'controlFlex' : ''"
                    >
                      <combobox-edit-control
                        [ngClass]="editMode ? 'customFlexComboAddNew' : ''"
                        [editMode]="editMode"
                        [label]="'EmployeeGeneral.Department' | translate"
                        [disable]="!hasDepartmentModule"
                        [nullValueLabel]="'DropdownList.None' | translate"
                        [(isDirty)]="childIsDirty[2]"
                        [(isValid)]="childIsValid[2]"
                        [comboboxDataSource]="departments"
                        [keepDataSourceOrder]="true"
                        [(value)]="templateDetail.DepartmentId"
                        (valueChange)="onChange()"
                        [textField]="'Name'"
                        [idField]="'Id'"
                      ></combobox-edit-control>
                      <app-icon
                        *ngIf="editMode && !IsDisableCreateDepartment"
                        [icon]="'circleplus'"
                        class="right"
                        (click)="AddDepartmentVisible = true"
                      ></app-icon>
                    </div>
                  </module-check>

                  <app-text-edit
                    label="{{ 'EmploymentPayrollData.CollectiveAgreementCode' | translate }}"
                    [editMode]="editMode"
                    [(isDirty)]="childIsDirty[3]"
                    [(isValid)]="childIsValid[3]"
                    [(value)]="templateDetail.CollectiveBargainingAgreementCode"
                    (valueChange)="onChange()"
                  ></app-text-edit>

                  <check-edit
                    [(isDirty)]="childIsDirty[4]"
                    [(isValid)]="childIsValid[4]"
                    label="{{ 'EmployeeEmployment.DefaultTemplate' | translate }}"
                    [(value)]="templateDetail.IsDefault"
                    [editMode]="editMode"
                    (valueChange)="onChange()"
                  ></check-edit>
                </fieldset>
              </div>
            </div>

            <div class="Card">
              <div class="Card-inner">
                <vacation
                  [(isDirty)]="childIsDirty[5]"
                  [(isValid)]="childIsValid[5]"
                  [editMode]="editMode"
                  [(model)]="templateDetail"
                  (valueChange)="onChange()"
                  [disable]="isVacationDisable"
                ></vacation>
              </div>
            </div>

            <div class="Card">
              <div class="Card-inner">
                <additional-vacation
                  [(isDirty)]="childIsDirty[6]"
                  [(isValid)]="childIsValid[6]"
                  [editMode]="editMode"
                  *ngIf="!IsGreenlandCompany"
                  [disable]="isAdditionalVacationDaysDisable"
                  [(model)]="templateDetail"
                  (valueChange)="onChange()"
                ></additional-vacation>
              </div>
            </div>

            <div class="Card" *ngIf="showMultiPurposeOffDayCompensation && !IsGreenlandCompany">
              <div class="Card-inner">
                <off-day-compensation
                  [(isDirty)]="childIsDirty[7]"
                  [(isValid)]="childIsValid[7]"
                  [editMode]="editMode"
                  [disable]="isMultiPurposeOffDayCompensationDisable"
                  [(model)]="templateDetail"
                  (valueChange)="onChange()"
                ></off-day-compensation>
              </div>
            </div>

            <div class="Card" *ngIf="showStatisticsSection">
              <div class="Card-inner">
                <employment-template-statistics
                  [(isDirty)]="childIsDirty[8]"
                  [(isValid)]="childIsValid[8]"
                  [editMode]="editMode"
                  [statistics]="templateDetail"
                  (valueChange)="onChange()"
                ></employment-template-statistics>
              </div>
            </div>

            <div class="Card">
              <div class="Card-inner">
                <time-and-absence
                  [(isDirty)]="childIsDirty[9]"
                  [(isValid)]="childIsValid[9]"
                  [editMode]="editMode"
                  [(model)]="templateDetail"
                  (valueChange)="onChange()"
                ></time-and-absence>
              </div>
            </div>

            <div class="Card">
              <div class="Card-inner">
                <legend class="Card-title">{{ 'CompanyHiringStatus.PayrollAndSupplements' | translate }}</legend>
                <span class="Control--wrapper">
                  <div class="CompanyTemplate">
                    <button-component
                      id="PayrollDataButton"
                      buttonClass="Button--card"
                      label="{{ 'Company.PayrollData' | translate }}"
                      (click)="isPayrollGridVisible = true"
                    ></button-component>

                    <button-component
                      id="SenioritySupplementsButton"
                      buttonClass="Button--card"
                      label="{{ 'Company.SenioritySupplements' | translate }}"
                      (click)="isSeniorityGridVisible = true"
                    ></button-component>

                    <button-component
                      id="OvertimeSupplementsButton"
                      buttonClass="Button--card"
                      label="{{ 'Company.OvertimeSupplements' | translate }}"
                      (click)="isOvertimeGridVisible = true"
                    ></button-component>
                  </div>
                </span>
              </div>
              <div class="Card-inner"></div>
            </div>

            <div class="Card" *ngIf="!IsGreenlandCompany">
              <div class="Card-inner">
                <tax-eincome
                  [(isDirty)]="childIsDirty[10]"
                  [(isValid)]="childIsValid[10]"
                  [editMode]="editMode"
                  [(model)]="templateDetail"
                  (valueChange)="onChange()"
                ></tax-eincome>
              </div>
            </div>

            <div class="Card" *ngIf="IsGreenlandCompany">
              <div class="Card-inner">
                <tax
                  [(isDirty)]="childIsDirty[10]"
                  [(isValid)]="childIsValid[10]"
                  [editMode]="editMode"
                  [(model)]="templateDetail"
                  (valueChange)="onChange()"
                ></tax>
              </div>
            </div>

            <div
              class="Card"
              *ngIf="!SecondaryIncomeType && !editMode && !has1stPensionProvider && !has2ndPensionProvider"
            >
              <div class="Card-inner">
                <fieldset class="Form--horizontal">
                  <legend class="Card-title">{{ 'EmployeeEmployment.Pension' | translate }}</legend>
                  <div class="FormElement-static">{{ 'EmployeeEmployment.NoPension' | translate }}</div>
                </fieldset>
              </div>
            </div>
            <div
              class="Card"
              *ngIf="has1stPensionProvider || (editMode && (!has1stPensionProvider || has2ndPensionProvider))"
            >
              <div class="Card-inner">
                <pension-provider
                  [(isDirty)]="childIsDirty[11]"
                  [(isValid)]="childIsValid[11]"
                  [(model)]="templateDetail"
                  (valueChange)="onChange()"
                  [disable]="SecondaryIncomeType"
                  [firstPension]="true"
                  [editMode]="editMode"
                ></pension-provider>
              </div>
            </div>
            <div class="Card" *ngIf="has2ndPensionProvider || (editMode && has1stPensionProvider)">
              <div class="Card-inner">
                <pension-provider
                  [(isDirty)]="childIsDirty[12]"
                  [(isValid)]="childIsValid[12]"
                  [(model)]="templateDetail"
                  (valueChange)="onChange()"
                  [disable]="SecondaryIncomeType"
                  [firstPension]="false"
                  [editMode]="editMode"
                ></pension-provider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="Cards" *ngIf="!isEnable">
    <div class="Card">
      <div class="Card-inner">
        <h2 class="Card-title">{{ 'Company.No Template' | translate }}</h2>
        <button-component
          buttonClass="Button--primary"
          (click)="createUserCompanyTemplate()"
          label="{{ 'Company.Add New Template' | translate }}"
        ></button-component>
      </div>
    </div>
  </div>

  <div class="Actions">
    <module-check [moduleId]="12" [(hasModule)]="hasTemplateModule">
      <action-button
        *ngIf="isAddIconVisible"
        id="ActionButtonAdd"
        buttonClass="Action-button Action-buttonAdd"
        [icon]="'Add'"
        label="Company.New"
        [disabled]="!hasTemplateModule"
        (click)="addNewDialogVisible = true"
        tooltip="Company.New"
      ></action-button>
    </module-check>
    <more-menu
      module="company"
      currentTab="tabs.company.configuration.hiringstatus"
      (menuClick)="onDeleteEmploymentTemplate()"
    ></more-menu>
    <help-button module="company" currentTab="tabs.company.configuration.hiringstatus"></help-button>
    <edit-button
      module="company"
      [isVisible]="sessionService.role.IsFullAdmin || sessionService.role.IsSalaryAdmin"
      [editMode]="editMode"
      (change)="onEditClick()"
    ></edit-button>
  </div>
  <div class="EditingInfo" *ngIf="editMode"></div>
  <button-component
    [isUseOneClick]="true"
    buttonClass="EditingInfo Discard"
    (click)="onConfirmAndDiscardChanges()"
    label="{{ 'Employee.DiscardButton' | translate }}"
    *ngIf="editMode"
  ></button-component>
</div>

<add-template
  [(visible)]="addNewDialogVisible"
  (addTemplateComplete)="onAddNewCompanyTemplateCompleted($event)"
></add-template>

<overtime-supplement
  *ngIf="templateDetail"
  [(visible)]="isOvertimeGridVisible"
  [editMode]="editMode"
  [(isDirty)]="childIsDirty[13]"
  [templateId]="templateDetail.Id"
  [(overtimeSupplement)]="templateDetail.OvertimeSupplements"
></overtime-supplement>

<seniority-supplement
  *ngIf="templateDetail"
  [(visible)]="isSeniorityGridVisible"
  [editMode]="editMode"
  [templateId]="templateDetail.Id"
  [(isDirty)]="childIsDirty[14]"
  [seniority]="templateDetail.SenioritySupplements"
></seniority-supplement>

<salary-template
  [(visible)]="isPayrollGridVisible"
  [editMode]="editMode"
  [templateId]="selectedTemplateId"
  [(isDirty)]="childIsDirty[15]"
  [(updatedSalaryTemplate)]="templateDetail.TemplateSalaryRecords"
  (salaryTemplateChange)="onChange()"
></salary-template>

<app-dialog (action)="onDeleteConfirmationDialogAction($event)" [(visible)]="deleteConfirmationDialogVisible">
  <div innerHTML="{{ 'CompanyHiringStatus.DeleteTemplate' | translate }}"></div>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Delete" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onDiscardChangesDialogAction($event)"
  [(visible)]="discardChangesDialogVisible"
  [leadingText]="'Employee.DiscardMessage' | translate"
>
  <app-dialog-action type="CancelDiscard"></app-dialog-action>
  <app-dialog-action type="ConfirmDiscard"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onUpdateTemplateWarningAction($event)"
  [(visible)]="updateTemplateWarningDialogVisible"
  [leadingText]="'CompanyHiringStatus.UpdateTemplateWarning' | translate"
>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="Proceed"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onConfirmUpdateDialogAction($event)"
  [(visible)]="confirmUpdateDialogVisible"
  [leadingText]="'CompanyHiringStatus.TemplateConfirmUpdate' | translate"
>
  <app-dialog-action
    [requiredModuleId]="12"
    action="UpdateAllEmployees"
    [disabled]="sessionService.isSubmitting"
    label="{{ 'CompanyHiringStatus.UpdateAllEmployees' | translate }}"
    [class]="'Button--secondary'"
  ></app-dialog-action>
  <app-dialog-action
    action="UpdateTemplateOnly"
    [disabled]="sessionService.isSubmitting"
    label="{{ 'CompanyHiringStatus.UpdateTemplateOnly' | translate }}"
  ></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="showInvalidFormWarning">
  <div [innerHTML]="'Validation.InvalidForm' | translate"></div>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onConfirmSaveDataOnChangeState($event)" [(visible)]="showSaveDataConfirmationChangeState">
  <div [innerHTML]="'Employee.UnsavedData' | translate"></div>
  <app-dialog-action type="DiscardAndLeave"></app-dialog-action>
  <app-dialog-action type="Stay"></app-dialog-action>
  <app-dialog-action type="Continue"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onCreateNewDepartment($event)"
  [(visible)]="AddDepartmentVisible"
  [title]="'EmployeeGeneral.AddDepartment' | translate"
  [width]="500"
>
  <app-text-edit
    [editMode]="true"
    [(value)]="ModelDepartment.Name"
    [label]="'CompanyGeneral.Department Name' | translate"
  >
  </app-text-edit>
  <combobox-edit-control
    [editMode]="true"
    [textField]="'FullName'"
    [idField]="'Id'"
    [comboboxDataSource]="companyContacts"
    [(value)]="ModelDepartment.ManagerCompanyUserId"
    [label]="'CompanyGeneral.Manager' | translate"
    [nullValueLabel]="'DropdownList.None' | translate"
  >
  </combobox-edit-control>
  <app-text-edit
    [editMode]="true"
    [(value)]="ModelDepartment.ExternalReference"
    [label]="'CompanyGeneral.ERP ref' | translate"
  >
  </app-text-edit>
  <app-text-edit
    [editMode]="true"
    [(value)]="ModelDepartment.EIncomeReference"
    [label]="'CompanyGeneral.EIncomeReference' | translate"
  >
  </app-text-edit>
  <app-dialog-action type="Create"></app-dialog-action>
</app-dialog>
