<legend
  *ngIf="!hasIntegrationModule"
  style="padding-bottom: 8px; padding-left: 1px;font-size: 0.75rem; font-style: italic; opacity: 0.75; padding: .5rem;"
  [innerHTML]="'Integrations.HelpMessage' | translate"
></legend>
<div [ngClass]="editMode ? 'is-editing' : ''" (dblclick)="onDoubleClick()">
  <div *ngIf="false" [innerHTML]="'Company.IntegrationsBanner' | translate"></div>
  <br />

  <div class="Cards Cards--flex">
    <div class="Card Card--large">
      <module-check [moduleId]="15" [(hasModule)]="hasIntegrationModule">
        <div class="Card-inner">
          <form>
            <fieldset>
              <legend class="Card-title">
                {{ 'Company.ExternalIntegrations' | translate }}
                <button
                  *ngIf="!isReadonlyUser"
                  (click)="dialogVisable = true"
                  [disabled]="!hasIntegrationModule"
                  class="right Button--card Button-RunNow"
                >
                  {{ 'Company.ExternalIntegrationsCreate' | translate }}
                </button>
              </legend>
              <div *ngIf="isComboboxIntegrationVisable" class="scrollviewIntegration">
                <div
                  *ngFor="let integrationItem of integrationModel"
                  [ngClass]="{ IntegrationListViewActive: integrationItem.Id === currentIntegrationId }"
                  class="IntegrationListView FormElement-cursorHand"
                  id="{{ integrationItem.Id }}"
                  [ngClass]="{ IntegrationListViewActive: isFistIntegration(integrationItem) }"
                  (click)="SelectIntegrationListView(integrationItem.Id)"
                >
                  <div class="Integrationtitlelistview">
                    <a>{{ integrationItem.Name }}</a>
                  </div>
                  <div class="IntegrationMessagelistview">
                    <ul class="ulControlList">
                      <li class="IntegrationMessage">
                        <a>
                          <app-icon
                            [tooltip]="getStatusMessage(integrationItem.StatusCode)"
                            *ngIf="getStatusMessageColor(integrationItem.StatusCode) === 'Changed'"
                            [icon]="'Warning'"
                          >
                          </app-icon>
                          <app-icon
                            [tooltip]="getStatusMessage(integrationItem.StatusCode)"
                            *ngIf="getStatusMessageColor(integrationItem.StatusCode) === 'Success'"
                            [icon]="'Success'"
                          >
                          </app-icon>
                          <app-icon
                            [tooltip]="getStatusMessage(integrationItem.StatusCode)"
                            *ngIf="getStatusMessageColor(integrationItem.StatusCode) === 'Error'"
                            [icon]="'ErrorExclamation'"
                          >
                          </app-icon>
                          <app-icon
                            [tooltip]="getStatusMessage(integrationItem.StatusCode)"
                            *ngIf="getStatusMessageColor(integrationItem.StatusCode) === 'Inactive'"
                            [icon]="'SuccessGray'"
                          >
                          </app-icon>
                        </a>
                      </li>

                      <li class="ButtonsupporturlLi">
                        <a *ngIf="checkIntegrationSupportUrl(integrationItem)" (click)="applyUrl(integrationItem)">
                          <app-icon [icon]="'Question'"></app-icon>
                        </a>
                      </li>

                      <li class="ButtonsupporturlLi" *ngIf="editMode">
                        <a
                          class="k-button k-button-icontext k-grid-XCustoom deleteDepartmentButton"
                          (click)="onDeleteDialog()"
                          >X</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div *ngIf="!isComboboxIntegrationVisable">
                <p>{{ 'Company.ExternalIntegrationsEmpty' | translate }}</p>
              </div>
            </fieldset>
          </form>
        </div>
        <div class="Card-inner">
          <fieldset class="Form--horizontal">
            <legend class="Card-title">{{ 'Company.ExternalIntegrationsConfiguration' | translate }}</legend>
            <div class="clearboth" *ngFor="let control of configurationControl">
              <a class="left FormElement-cursorHand">
                <app-icon
                  [tooltip]="control.translatemessage"
                  *ngIf="getStatusMessageColorOperation(control.statuscode) === 'Changed'"
                  [icon]="'Warning'"
                >
                </app-icon>
                <app-icon
                  [tooltip]="control.translatemessage"
                  *ngIf="getStatusMessageColorOperation(control.statuscode) === 'Success'"
                  [icon]="'Success'"
                >
                </app-icon>
                <app-icon
                  [tooltip]="control.translatemessage"
                  *ngIf="getStatusMessageColorOperation(control.statuscode) === 'Error'"
                  [icon]="'ErrorExclamation'"
                >
                </app-icon>
                <app-icon
                  [tooltip]="control.translatemessage"
                  *ngIf="getStatusMessageColorOperation(control.statuscode) === 'Inactive'"
                  [icon]="'SuccessGray'"
                >
                </app-icon>
              </a>
              <button
                *ngIf="!isReadonlyUser"
                [disabled]="!control.value || sessionService.isSubmitting"
                (click)="runOperation(control.Id)"
                class="right Button--card Button-RunNow"
              >
                {{ 'Company.ExternalIntegrationsRunNow' | translate }}
              </button>
              <check-edit
                (valueChange)="irDirtyChange(true)"
                [editMode]="editMode"
                [(value)]="control.value"
                [tooltip]="control.Description"
                [label]="control.name"
              >
              </check-edit>
            </div>
            <div *ngFor="let control of integrationConfigurationControl">
              <check-edit
                *ngIf="control.type === 'CheckBox'"
                [(isDirty)]="isDirty"
                [label]="control.name"
                class="integrationConfigurationControl"
                [editMode]="editMode"
                [(value)]="control.value"
                [tooltip]="control.Description"
              >
              </check-edit>

              <app-text-edit
                *ngIf="control.type === 'TextBox'"
                [(isDirty)]="isDirty"
                [label]="control.name"
                [editMode]="editMode"
                [(value)]="control.value"
                [tooltipContent]="control.Description"
              >
              </app-text-edit>

              <combobox-edit-control
                *ngIf="control.type === 'Dropdown'"
                [(isDirty)]="isDirty"
                [label]="control.name"
                [editMode]="editMode"
                [comboboxDataSource]="control.dataSource"
                [(value)]="control.value"
                [nullValueLabel]="'DropdownList.None' | translate"
                [keepDataSourceOrder]="true"
                [idField]="control.idField"
                [textField]="control.textField"
                [tooltipContent]="control.Description"
                class="extraMarginTop"
              >
              </combobox-edit-control>
            </div>
          </fieldset>
        </div>
        <div class="Card-inner">
          <form>
            <fieldset class="Form--horizontal">
              <legend class="Card-title">{{ 'Company.ExternalIntegrationsCredential' | translate }}</legend>
              <div *ngFor="let control of credentialControl">
                <app-text-edit
                  [(isDirty)]="isDirty"
                  [label]="control.name"
                  [editMode]="editMode"
                  [(value)]="control.value"
                  [addonTooltip]="control.Description"
                ></app-text-edit>
              </div>
            </fieldset>
          </form>
        </div>
      </module-check>
    </div>

    <div class="Card Card--large">
      <div class="Card-inner">
        <legend class="Card-title">
          {{ 'Company.Salary Types' | translate }}
        </legend>
        <app-company-standard-salary-types></app-company-standard-salary-types>
      </div>
    </div>
  </div>

  <div class="Actions">
    <module-check [moduleId]="15">
      <action-button
        *ngIf="!isReadonlyUser"
        id="ActionButtonAdd"
        [buttonClass]="'Action-button Action-buttonAdd'"
        [icon]="'Add'"
        [disabled]="!hasIntegrationModule"
        [label]="'Company.New'"
        (click)="dialogVisable = true"
        [tooltip]="'Company.New'"
      >
      </action-button>
    </module-check>
    <help-button [module]="'company'"></help-button>
    <edit-button
      *ngIf="!isReadonlyUser && isComboboxIntegrationVisable && hasIntegrationModule"
      [module]="'company'"
      [editMode]="editMode"
      [isVisible]="true"
      (change)="editMode = !editMode"
    >
    </edit-button>
  </div>
  <div class="EditingInfo" *ngIf="editMode"></div>
  <button-component
    [isUseOneClick]="true"
    [buttonClass]="'EditingInfo Discard'"
    (click)="applyDiscard()"
    [label]="'Employee.DiscardButton' | translate"
    *ngIf="editMode"
  >
  </button-component>
</div>

<app-dialog [overlayClose]="false" [width]="500" [(visible)]="dialogVisable" (action)="onCreateDialogCreate($event)">
  <combobox-edit-control
    [label]="'Integrations.Createlabel' | translate"
    [editMode]="true"
    [textField]="'Name'"
    [idField]="'Id'"
    [comboboxDataSource]="extenalSystem"
    [(value)]="currentExtenalSystemId"
    [placeholder]="'Company.ExternalIntegrationsCreate' | translate"
    (valueChange)="onBaseIntegrationCreateComboboxChanged()"
  >
  </combobox-edit-control>
  <br />
  <!-- <span>{{'Company.ExternalIntegrationsDescription' | translate}}</span>
    <textarea [disabled]="true" class="FormElement-textarea MessageToEmployeesTextArea" [(ngModel)]="descriptionCreateIntegration"></textarea>
    <br /> -->

  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Create'"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="isShowingAlertMessage"
  (action)="onUnSaveWarningDialogAction($event)"
  [leadingText]="'Employee.UnsavedData' | translate"
>
  <app-dialog-action [type]="'CancelDiscard'"></app-dialog-action>
  <app-dialog-action [type]="'ConfirmDiscard'"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="isShowingDeleteMessage"
  (action)="onDeleteWarningDialogAction($event)"
  [leadingText]="'Grids.Confirm Delete' | translate"
>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Delete'"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="isShowingChangeIemMessage"
  (action)="onChangeTab($event)"
  [leadingText]="'Employee.UnsavedData' | translate"
>
  <app-dialog-action [type]="'ConfirmDiscard'"></app-dialog-action>
  <app-dialog-action [type]="'ContinueAndSave'"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="isShowingAutoMap"
  (action)="onAutoMap($event)"
  [leadingText]="'CompanyGeneral.ReplaceExternalReferenceConfirmationMessage' | translate"
>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Proceed'"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="OperationRunSucess" [leadingText]="'Company.OperationRun' | translate">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
