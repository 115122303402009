import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-popup',
  templateUrl: './ButtonPopUp.component.html'
})
export class ButtonPopUpComponent {
  @Input() public label: string;
  @Input() public icon: string;
  @Input() public buttonClass: string;
  @Input() public tooltipContent: string;
  @Input() public popupButtonIcon: any[] = [];
  @Input() public isUseLabelAppIcon = false;
  @Input() public isHidden = false;

  @Output() public IconClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() public ButtonClick: EventEmitter<any> = new EventEmitter<any>();

  public show = false;

  public onToggle(): void {
    this.show = !this.show;
    if (!this.show) {
      this.ButtonClick.emit();
    }
  }

  private preAction: string;
  public appIconClick(action: string): void {
    if (!this.preAction || (this.preAction && this.preAction !== action)) {
      this.IconClick.emit(action);
      this.preAction = action;
    } else {
      this.preAction = '';
    }
  }
}
