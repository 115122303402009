import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { UniqueId } from '../Common/UniqueId';
import { SessionService } from '../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'app-text-edit',
  templateUrl: './TextEditUpdate.component.html'
})
export class TextEditUpdateComponent extends ControlComponentBase<string> implements OnInit {
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public icon: string;
  @Input() public type: string;
  @Input() public required: boolean;
  @Input() public isCPRno = false;
  @Input() public mask: string;
  @Input() public disabled: boolean;
  @Input() public addonIcon: string;
  @Input() public addonLink: string;
  @Input() public addonTooltip: string;
  @Input() public tooltipContent: string;
  @Input() public inputClass: string;
  @Input() public viewClass: string;
  @Input() public readonlyOnNotFocus: boolean;
  @Input() public maxLength: number;
  @Input() public requiredMessageKey: string;
  @Input() public hostClass: string;
  @Input() public addonSize: string;
  @Input() public isTextArea = false;
  @Input() public textAreaLimitChar = 200;
  @Input() public TextAreaRows = 4;
  @Output() public blur: EventEmitter<void> = new EventEmitter<void>();
  @Output() public addonClick: EventEmitter<void> = new EventEmitter<void>();
  @Input() public autocorrect = false;

  public readOnly = false;
  public elementId: string;
  public showPassword = false;

  constructor(private sessionService: SessionService, elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
    this.elementId = 'textEditComponentId' + UniqueId();
    this.setTimeout = false;
  }

  public ngOnInit(): void {
    this.readOnly = this.readonlyOnNotFocus && this.sessionService.browser.isChrome;
  }

  public get TextLenght(): string {
    if (this.value) {
      const res: number = this.textAreaLimitChar - this.value.length;
      return res >= 0 ? res.toString() : '0';
    }
    return this.textAreaLimitChar.toString();
  }

  public get TextAreaClass(): string {
    if (this.value && this.value.length > this.textAreaLimitChar) {
      return ' input-required';
    }
    return '';
  }

  public TextAreakeypress(): void {
    if (this.value && this.value.length > this.textAreaLimitChar) {
      this.value = this.value.substring(0, this.textAreaLimitChar);
    }
  }

  public get requiredValueMissing(): boolean {
    return this.required && (!this.value || this.value.length === 0);
  }
  public get showRequiredMessage(): boolean {
    return this.editMode && this.requiredValueMissing;
  }
  public get showValidationMessage(): boolean {
    return this.editMode && !this.valid() && this.type === 'email';
  }

  public setInputClass(): string {
    return (
      (this.inputClass ? this.inputClass : '') +
      (this.showValidationMessage || this.requiredValueMissing ? ' input-required' : '')
    );
  }

  public get hasMask(): boolean {
    return this.mask && this.mask.length > 0;
  }

  public onBlur(): void {
    if (this.readonlyOnNotFocus && this.sessionService.browser.isChrome) {
      this.readOnly = false;
    }

    this.blur.emit();
  }

  public onFocus(): void {
    if (this.readonlyOnNotFocus && this.sessionService.browser.isChrome) {
      this.readOnly = false;
    }
  }

  protected valid(): boolean {
    if (this.required && (!this.value || this.value === '')) {
      return false;
    }

    switch (this.type) {
      case 'email':
        return !this.value || this.value.length === 0 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.value);
      default:
        return true;
    }
  }
}
