/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../SharedComponents/ModuleCheck/ModuleCheck.component.ngfactory";
import * as i4 from "../../../../SharedComponents/ModuleCheck/ModuleCheck.component";
import * as i5 from "../../../../Services/SharedServices/Session/SessionService";
import * as i6 from "../../../../Services/SharedServices/StaticData.service";
import * as i7 from "../../../../Services/RxDataService";
import * as i8 from "../../../../Services/Authentication/AuthenticationService";
import * as i9 from "../../../../Filters/PriceFormat.pipe";
import * as i10 from "../../../../CustomControls/Checkbox.component.ngfactory";
import * as i11 from "../../../../CustomControls/Checkbox.component";
import * as i12 from "@angular/common";
import * as i13 from "./Benefits.component";
var styles_BenefitsComponent = [];
var RenderType_BenefitsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BenefitsComponent, data: {} });
export { RenderType_BenefitsComponent as RenderType_BenefitsComponent };
function View_BenefitsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "Button--card"], ["href", "https://www.mylogbuy.com/WebPages/Login/Login.aspx?ReturnUrl=L1dlYlBhZ2VzL0RlZmF1bHQuYXNweA%3d%3d"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("EmployeeGeneral.GetYourBenefits")); _ck(_v, 1, 0, currVal_0); }); }
function View_BenefitsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "Button--card"], ["href", "https://menucard.dk/logind"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("EmployeeGeneral.GetYourBenefits")); _ck(_v, 1, 0, currVal_0); }); }
function View_BenefitsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "Button--card"], ["href", "https://support.gratisal.com/hc/da/articles/207668179-Medarbejderfordele"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("GlobalDialog.ReadMore")); _ck(_v, 1, 0, currVal_0); }); }
export function View_BenefitsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 24, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(2, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i0.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 3, "fieldset", [["class", "Form--horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "legend", [["class", "Card-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 13, "module-check", [], null, null, null, i3.View_ModuleCheckComponent_0, i3.RenderType_ModuleCheckComponent)), i0.ɵdid(10, 245760, null, 0, i4.ModuleCheckComponent, [i5.SessionService, i6.StaticDataService, i7.RxDataService, i8.AuthenticationService, i9.PriceFormatPipe], { moduleId: [0, "moduleId"] }, null), (_l()(), i0.ɵeld(11, 0, null, 0, 5, "div", [["class", "Card-footer Card-benefit"], ["style", "margin-top: 0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "span", [["class", "Control--wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "check-edit", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.isEmployeeBenefitModuleActive = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_CheckboxComponent_0, i10.RenderType_CheckboxComponent)), i0.ɵdid(14, 49152, null, 0, i11.CheckboxComponent, [i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], label: [2, "label"] }, { valueChange: "valueChange" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BenefitsComponent_1)), i0.ɵdid(16, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(17, 0, null, 0, 5, "div", [["class", "Card-footer Card-benefit"], ["style", "margin-top: 0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 2, "span", [["class", "Control--wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "check-edit", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.isEmployeeBenefitModuleActive = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_CheckboxComponent_0, i10.RenderType_CheckboxComponent)), i0.ɵdid(20, 49152, null, 0, i11.CheckboxComponent, [i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], label: [2, "label"] }, { valueChange: "valueChange" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BenefitsComponent_2)), i0.ɵdid(22, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BenefitsComponent_3)), i0.ɵdid(24, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = 6; _ck(_v, 10, 0, currVal_8); var currVal_9 = _co.isEmployeeBenefitModuleActive; var currVal_10 = false; var currVal_11 = "LogBuy"; _ck(_v, 14, 0, currVal_9, currVal_10, currVal_11); var currVal_12 = (_co.isEmployeeBenefitModuleActive && !_co.isIOSApp); _ck(_v, 16, 0, currVal_12); var currVal_13 = _co.isEmployeeBenefitModuleActive; var currVal_14 = false; var currVal_15 = "MenuPay"; _ck(_v, 20, 0, currVal_13, currVal_14, currVal_15); var currVal_16 = (_co.isEmployeeBenefitModuleActive && !_co.isIOSApp); _ck(_v, 22, 0, currVal_16); var currVal_17 = (!_co.isEmployeeBenefitModuleActive && !_co.isIOSApp); _ck(_v, 24, 0, currVal_17); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("EmployeeGeneral.EmployeeBenefits")); _ck(_v, 7, 0, currVal_7); }); }
export function View_BenefitsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "employee-benefits", [], null, null, null, View_BenefitsComponent_0, RenderType_BenefitsComponent)), i0.ɵdid(1, 245760, null, 0, i13.BenefitsComponent, [i6.StaticDataService, i5.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BenefitsComponentNgFactory = i0.ɵccf("employee-benefits", i13.BenefitsComponent, View_BenefitsComponent_Host_0, {}, {}, []);
export { BenefitsComponentNgFactory as BenefitsComponentNgFactory };
