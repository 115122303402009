<legend class="Card-title Card-title-inline">
  <span> {{ 'EmployeeEmployment.Statistics' | translate }} </span>
  <app-icon
    *ngIf="IsEmployeeEmploymentStatistics"
    style="position: absolute; padding-left: 5px"
    [icon]="'Info'"
    [tooltip]="'EmployeeEmployment.GetHelp' | translate"
    [link]="'Help.EmployeeEmploymentStatisticsLink' | translate"
  ></app-icon>
  <app-icon
    *ngIf="!IsEmployeeEmploymentStatistics"
    style="position: absolute; padding-left: 5px"
    [icon]="'Info'"
    [tooltip]="'EmployeeEmployment.GetHelp' | translate"
    [link]="'Help.CompanyTemplateStatisticsLink' | translate"
  ></app-icon>
</legend>
<form>
  <fieldset class="Form--horizontal">
    <div>
      <combobox-edit-control
        *ngIf="!isShow"
        [(isDirty)]="childIsDirty[0]"
        [label]="'EmployeeEmployment.SalaryPrinciple' | translate"
        [editMode]="editMode"
        [comboboxDataSource]="salaryPrinciples"
        [(value)]="statistics.StatisticsSalaryPrincipleId"
        [textField]="'Name'"
        [idField]="'Id'"
        (valueChange)="onChange()"
      ></combobox-edit-control>
      <date-picker-control
        *ngIf="IsEmployeeEmploymentStatistics && !isShow"
        [(isDirty)]="childIsDirty[1]"
        [(isValid)]="childIsValid[0]"
        [label]="'EmployeeEmployment.SalaryPrincipleFromDate' | translate"
        [editMode]="editMode"
        [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
        [(value)]="statistics.StatisticsSalaryPrincipleFromDate"
        (valueChange)="onChange()"
      ></date-picker-control>
      <combobox-edit-control
        *ngIf="!isShow"
        [(isDirty)]="childIsDirty[2]"
        [label]="'EmployeeEmployment.EmploymentPrinciple' | translate"
        [editMode]="editMode"
        [comboboxDataSource]="statisticsEmploymentPrinciple"
        [(value)]="statistics.StatisticsEmploymentPrincipleId"
        [textField]="'Name'"
        [idField]="'Id'"
        (valueChange)="onChange()"
      ></combobox-edit-control>
      <combobox-edit-control
        *ngIf="!isShow"
        [(isDirty)]="childIsDirty[3]"
        [label]="'EmployeeEmployment.EmploymentTerms' | translate"
        [editMode]="editMode"
        [comboboxDataSource]="statisticsEmploymentTerms"
        [(value)]="statistics.StatisticsEmploymentTermsId"
        [textField]="'Name'"
        [idField]="'Id'"
        (valueChange)="onChange()"
      ></combobox-edit-control>
      <date-picker-control
        *ngIf="IsEmployeeEmploymentStatistics && !isShow"
        [(isDirty)]="childIsDirty[4]"
        [(isValid)]="childIsValid[1]"
        [label]="'EmployeeEmployment.EmploymentTermsFromDate' | translate"
        [editMode]="editMode"
        [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
        [(value)]="statistics.StatisticsEmploymentTermsFromDate"
        (valueChange)="onChange()"
      ></date-picker-control>
      <combobox-edit-control
        *ngIf="!isShow"
        [(isDirty)]="childIsDirty[5]"
        [label]="'EmployeeEmployment.JobStatus' | translate"
        [editMode]="editMode"
        [comboboxDataSource]="statisticsJobStatus"
        [(value)]="statistics.StatisticsJobStatusId"
        [textField]="'Name'"
        [idField]="'Id'"
        (valueChange)="onChange()"
      ></combobox-edit-control>
      <date-picker-control
        *ngIf="IsEmployeeEmploymentStatistics && !isShow"
        [(isDirty)]="childIsDirty[6]"
        [(isValid)]="childIsValid[2]"
        [label]="'EmployeeEmployment.JobStatusFromDate' | translate"
        [editMode]="editMode"
        [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
        [(value)]="statistics.StatisticsJobStatusFromDate"
        (valueChange)="onChange()"
      ></date-picker-control>
      <auto-complete-textbox
        [(isDirty)]="childIsDirty[7]"
        [label]="'EmployeeEmployment.JobFunctionCode' | translate"
        [editMode]="editMode"
        [autoCompleteDataSource]="jobFunctionCodeStrings"
        [allowCustom]="true"
        [isSuggest]="false"
        [(value)]="statistics.StatisticsJobFunctionCode"
        (valueChange)="onJobFunctionCodeChanged()"
      ></auto-complete-textbox>
      <app-text-edit
        *ngIf="!isShow"
        [(isDirty)]="childIsDirty[8]"
        [label]="'EmployeeEmployment.JobFunctionName' | translate"
        [editMode]="false"
        [disabled]="true"
        [value]="JobFunctionName"
      ></app-text-edit>
      <date-picker-control
        *ngIf="IsEmployeeEmploymentStatistics && !isShow"
        [(isDirty)]="childIsDirty[9]"
        [(isValid)]="childIsValid[3]"
        [label]="'EmployeeEmployment.StatisticsJobFunctionFromDate' | translate"
        [editMode]="editMode"
        [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
        [(value)]="statistics.StatisticsJobFunctionFromDate"
        (valueChange)="onChange()"
      ></date-picker-control>
    </div>
  </fieldset>
</form>
