import { Component, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Global } from '../../../../Common/Global';
import { SalaryBatchStatusEnum } from '../../../../Model/Enum';
import { ISalaryBatch, ISimpleSalaryBatchRecord, ISimpleSalaryStatement } from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { DownloadService } from '../../../../Services/SharedServices/DownloadService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../../Services/SharedServices/SettingService';

@Component({
  selector: 'salary-batch-payslips',
  templateUrl: './SalaryBatchPayslips.component.html'
})
export class SalaryBatchPayslipsComponent implements OnDestroy {
  private selectedSalaryBatchValue: ISalaryBatch;
  @Input()
  public get selectedSalaryBatch(): ISalaryBatch {
    return this.selectedSalaryBatchValue;
  }
  public set selectedSalaryBatch(value: ISalaryBatch) {
    if (this.selectedSalaryBatchValue !== value) {
      this.selectedSalaryBatchValue = value;
      this.filterSalaryStatements();
    }
  }

  private salaryStatementsValue: ISimpleSalaryStatement[] = [];
  @Input()
  public get salaryStatements(): ISimpleSalaryStatement[] {
    return this.salaryStatementsValue;
  }
  public set salaryStatements(value: ISimpleSalaryStatement[]) {
    this.salaryStatementsValue = value;
    this.filterSalaryStatements();
  }

  @Output() public revertBatchCompleted: EventEmitter<void> = new EventEmitter<void>();

  public get IsWeb(): boolean {
    return !this.sessionService.browser.isHybridApp;
  }
  public get IsMobile(): boolean {
    return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
  }
  public get isPaymentApprover(): boolean {
    return Global.SESSION && Global.SESSION.IsPaymentApprover;
  }
  public get IsFinalizedBatch(): boolean {
    return this.selectedSalaryBatchValue && this.selectedSalaryBatchValue.StatusId === SalaryBatchStatusEnum.Finalized;
  }

  public filteredSalaryStatements: ISimpleSalaryStatement[] = [];
  private selectedSalaryStatementValue: ISimpleSalaryStatement;
  public get selectedSalaryStatement(): ISimpleSalaryStatement {
    return this.selectedSalaryStatementValue;
  }
  public set selectedSalaryStatement(value: ISimpleSalaryStatement) {
    if (this.selectedSalaryStatementValue !== value) {
      this.selectedSalaryStatementValue = value;
      this.loadSalaryStatementDetails();
    }
  }

  public get selectedSalaryStatementId(): number {
    return this.selectedSalaryStatement ? this.selectedSalaryStatement.Id : undefined;
  }
  public set selectedSalaryStatementId(value: number) {
    this.selectDefaultSelectedPayslip(value);
  }

  public get isFirefox(): boolean {
    return this.sessionService.browser.isFirefox;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public salaryStatementDetails: ISimpleSalaryBatchRecord[] = [];
  public newTabBlockedDialogVisible = false;
  public previewDialogVisible = false;
  public previewContent: any;

  private searchTextValue: string;
  public get searchText(): string {
    return this.searchTextValue;
  }
  public set searchText(value: string) {
    if (this.searchTextValue !== value) {
      this.searchTextValue = value;
      this.filterSalaryStatements();
    }
  }

  constructor(
    private sessionService: SessionService,
    private dataService: RxDataService,
    private downloadService: DownloadService,
    public renderer: Renderer2,
    private settingService: SettingService
  ) {}

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public navigateToEmployeePayroll(): void {
    // this.settingService.navigationParameters["EmployeeId"] = employeeId;
    // this.settingService.navigationParameters["EmploymentId"] = employmentId;
    this.sessionService.NavigateTo('tabs.employee.payrolldata');
  }

  public loadSalaryStatementDetails(): void {
    if (this.selectedSalaryStatement) {
      this.dataService
        .SalaryStatements_GetSimplifiedBatchRecords(this.selectedSalaryStatement.Id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: ISimpleSalaryBatchRecord[]) => {
          this.salaryStatementDetails = data;
        });
    }
  }

  private flagDownloadSalaryStatement = false;
  public downloadSalaryStatement(type: string): void {
    if (!this.flagDownloadSalaryStatement) {
      if (type === 'pdf') {
        if (
          this.sessionService.browser.isHybridApp ||
          this.sessionService.browser.isIE ||
          this.sessionService.browser.isEdge
        ) {
          this.dataService.SalaryStatements_GetPdf(this.selectedSalaryStatement.Id).subscribe((data: string): void => {
            this.downloadService.download('Gratisal.pdf', data);
          });
        } else {
          this.getPayslipReportByToken(this.selectedSalaryStatement.Id, 'pdf');
        }
      } else {
        if (type === 'htmlSameTab') {
          const types: string = this.IsMobile ? 'html' : 'a4p';
          this.dataService
            .SalaryStatements_GetHtml(this.selectedSalaryStatement.Id, types)
            .subscribe((content: any): void => {
              this.previewDialogVisible = true;
              this.previewContent = content;
            });
        } else {
          this.getPayslipReportByToken(this.selectedSalaryStatement.Id, 'html');
        }
      }
      this.flagDownloadSalaryStatement = true;
    } else {
      this.flagDownloadSalaryStatement = false;
    }
  }

  private filterSalaryStatements(): void {
    if (this.searchText) {
      this.filteredSalaryStatements = this.salaryStatements.filter(
        (s: ISimpleSalaryStatement) =>
          s.UserFullName.toLocaleUpperCase().indexOf(this.searchTextValue.toLocaleUpperCase()) > -1
      );
    } else {
      this.filteredSalaryStatements = this.salaryStatements;
    }

    this.gridData = filterBy(this.filteredSalaryStatements, this.filter);
    this.selectDefaultSelectedPayslip();
  }

  private selectDefaultSelectedPayslip(selectedPayslipId?: number): void {
    selectedPayslipId = selectedPayslipId
      ? selectedPayslipId
      : this.selectedSalaryStatement
      ? this.selectedSalaryStatement.Id
      : undefined;
    if (this.filteredSalaryStatements.length > 0) {
      const existedPayslip: ISimpleSalaryStatement = selectedPayslipId
        ? this.filteredSalaryStatements.find((s: ISimpleSalaryStatement) => s.Id === selectedPayslipId)
        : undefined;
      this.selectedSalaryStatement = existedPayslip ? existedPayslip : this.filteredSalaryStatements[0];
    } else {
      this.selectedSalaryStatement = undefined;
      this.salaryStatementDetails = undefined;
    }
  }

  private getPayslipReportByToken(salaryStatementId: number, format: string): void {
    this.dataService.SalaryStatements_GetPayslipDownloadToken(salaryStatementId).subscribe((token: any): void => {
      const languageCode: string = this.sessionService.LanguageCode;
      const downloadUrl = `/api/salarystatements/${salaryStatementId}/token/${token}/lang/${languageCode}/format/${format}/Payslip`;
      const newWindow: Window = window.open(environment.apiUrl + downloadUrl, '_blank');
      if (!newWindow) {
        this.newTabBlockedDialogVisible = true;
      }
    });
  }

  public onDoubleClick(data: ISimpleSalaryStatement): void {
    if (data && data.UserEmploymentId) {
      this.sessionService.currentUserEmploymentId = data.UserEmploymentId;
      this.sessionService.NavigateTo('tabs.employee.payrolldata');
    }
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = filterBy(this.filteredSalaryStatements, this.filter);
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.filteredSalaryStatements, this.filter);
  }
}
