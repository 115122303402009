import { EventEmitter } from '@angular/core';
var PriceSummaryComponent = /** @class */ (function () {
    function PriceSummaryComponent() {
        this.priceElements = [];
        this.totalCostValue = 0;
        this.usersMonthlyValue = 0;
        this.usersMonthlyChange = new EventEmitter();
        this.users14DayValue = 0;
        this.users14DayChange = new EventEmitter();
        this.usersWeeklyValue = 0;
        this.usersWeeklyChange = new EventEmitter();
        this.change = new EventEmitter();
        this.submit = new EventEmitter();
        this.changing = false;
    }
    Object.defineProperty(PriceSummaryComponent.prototype, "totalCost", {
        get: function () {
            return this.totalCostValue;
        },
        set: function (value) {
            var _this = this;
            if (this.totalCostValue !== value) {
                this.totalCostValue = value;
                this.changing = true;
                clearTimeout(this.costChangeTimeout);
                this.costChangeTimeout = setTimeout(function () {
                    _this.changing = false;
                }, 300);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PriceSummaryComponent.prototype, "usersMonthly", {
        get: function () {
            return this.usersMonthlyValue;
        },
        set: function (value) {
            if (this.usersMonthlyValue !== value) {
                this.usersMonthlyValue = value ? value : 0;
                this.usersMonthlyChange.emit(this.usersMonthlyValue);
            }
            this.change.emit();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PriceSummaryComponent.prototype, "users14Day", {
        get: function () {
            return this.users14DayValue;
        },
        set: function (value) {
            if (this.users14DayValue !== value) {
                this.users14DayValue = value ? value : 0;
                this.users14DayChange.emit(this.users14DayValue);
                this.change.emit();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PriceSummaryComponent.prototype, "usersWeekly", {
        get: function () {
            return this.usersWeeklyValue;
        },
        set: function (value) {
            if (this.usersWeeklyValue !== value) {
                this.usersWeeklyValue = value ? value : 0;
                this.usersWeeklyChange.emit(this.usersWeeklyValue);
                this.change.emit();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PriceSummaryComponent.prototype, "totalEmployees", {
        get: function () {
            return (parseInt(this.usersMonthly ? this.usersMonthly.toString() : '0', 10) +
                parseInt(this.users14Day ? this.users14Day.toString() : '0', 10) +
                parseInt(this.usersWeekly ? this.usersWeekly.toString() : '0', 10));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PriceSummaryComponent.prototype, "totalPayslips", {
        get: function () {
            return (parseInt(this.usersMonthly ? this.usersMonthly.toString() : '0', 10) +
                parseInt(this.users14Day ? this.users14Day.toString() : '0', 10) * 2.17 +
                parseInt(this.usersWeekly ? this.usersWeekly.toString() : '0', 10) * 4.33);
        },
        enumerable: true,
        configurable: true
    });
    PriceSummaryComponent.prototype.submitEvent = function () {
        if (this.submitEnabled) {
            this.submit.emit();
        }
    };
    return PriceSummaryComponent;
}());
export { PriceSummaryComponent };
