import { EventEmitter } from '@angular/core';
import { Global } from '../../../../Common/Global';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
var SalaryBatchPayslipIconsComponent = /** @class */ (function () {
    function SalaryBatchPayslipIconsComponent(sessionService) {
        this.sessionService = sessionService;
        this.downloadPayslipClick = new EventEmitter();
    }
    Object.defineProperty(SalaryBatchPayslipIconsComponent.prototype, "hideDownloadIcons", {
        get: function () {
            return !this.selectedSalaryStatement;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipIconsComponent.prototype, "IsWeb", {
        get: function () {
            return !this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipIconsComponent.prototype, "isPaymentApprover", {
        get: function () {
            return Global.SESSION && Global.SESSION.IsPaymentApprover;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipIconsComponent.prototype, "IsAppOrDesktop", {
        get: function () {
            return !this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipIconsComponent.prototype, "isHidden", {
        get: function () {
            if (!this.sessionService.browser.isHybridApp) {
                return 'Icon--Payslip--hide';
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchPayslipIconsComponent.prototype.onPayslipIconClicked = function (type) {
        if (this.downloadPayslipClick) {
            this.downloadPayslipClick.emit(type);
        }
    };
    return SalaryBatchPayslipIconsComponent;
}());
export { SalaryBatchPayslipIconsComponent };
