import { OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../../Common/Global';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
var HelpComponent = /** @class */ (function () {
    function HelpComponent(translateService, sessionService) {
        this.translateService = translateService;
        this.sessionService = sessionService;
        this.LanguageId = 1;
        this.showdialog = false;
        this.listener = [];
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(HelpComponent.prototype, "isIOSMobileApp", {
        get: function () {
            return this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HelpComponent.prototype, "isGreenlandCompany", {
        get: function () {
            return Global && Global.COMPANY && Global.COMPANY.CountryId === 3 ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    HelpComponent.prototype.ngOnInit = function () {
        this.LanguageId = Global.SESSION ? Global.SESSION.SignOnToken.LanguageId : 1;
        this.setupEventHandlers();
        this.translateView();
    };
    HelpComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    HelpComponent.prototype.onShowWelcomeMessage = function () {
        this.sessionService.showHelpMenuToggled = false;
        this.showdialog = true;
    };
    HelpComponent.prototype.onToggleHelpDropdown = function (event) {
        this.sessionService.showUserMenuToggled = false;
        this.sessionService.showMoreMenuToggled = false;
        this.sessionService.showHelpMenuToggled = !this.sessionService.showHelpMenuToggled;
        // event.preventDefault();
        event.stopPropagation();
    };
    Object.defineProperty(HelpComponent.prototype, "helpLink", {
        get: function () {
            switch (this.sessionService.currentState) {
                // case "tabs.employee.general":
                //     return "http://support.gratisal.com/hc/da/categories/200884395";
                // case "tabs.employee.hiringstatus":
                //     return "http://support.gratisal.com/hc/da/articles/207401959";
                // case "tabs.employee.payrolldata":
                //     return "http://support.gratisal.com/hc/da/articles/207401989";
                // case "tabs.company.salarybatches":
                //     return "http://support.gratisal.com/hc/da/articles/207402029";
                // case "tabs.company.configuration.hiringstatus":
                //     return "http://support.gratisal.com/hc/da/articles/208119495";
                // case "tabs.company.general":
                //     return "http://support.gratisal.com/hc/da/categories/200884365";
                case 'tabs.employee.general':
                    return 'https://support.gratisal.com/hc/da/articles/207563559-Introduktion-til-Gratisal';
                case 'tabs.employee.hiringstatus':
                    return 'https://support.gratisal.com/hc/da/articles/207401959-Ans%C3%A6ttelsesdata';
                case 'tabs.employee.time':
                    return 'https://support.gratisal.com/hc/da/articles/208468529-Timeregistrering-i-Timer';
                case 'tabs.employee.payrolldata':
                    return 'https://support.gratisal.com/hc/da/articles/207401989-Lav-L%C3%B8n-p%C3%A5-Medarbejdere';
                case 'tabs.employee.payslip':
                    return 'https://support.gratisal.com/hc/da/articles/360025211251';
                case 'tabs.company.general':
                    return 'https://support.gratisal.com/hc/da/articles/207563559-Introduktion-til-Gratisal';
                case 'tabs.company.configuration.hiringstatus':
                    return 'https://support.gratisal.com/hc/da/articles/208119495-Medarbejderskabeloner';
                case 'tabs.company.payrolldata':
                    return 'https://support.gratisal.com/hc/da/articles/360015945992-Ret-dine-l%C3%B8ndata';
                case 'tabs.company.salarybatches':
                    return 'https://support.gratisal.com/hc/da/articles/207402029-S%C3%A5dan-k%C3%B8res-l%C3%B8nnen';
                case 'tabs.company.approval.timeentry':
                    return 'https://support.gratisal.com/hc/da/articles/208468529-Timeregistrering-i-Timer#2';
                case 'tabs.company.configuration.dataimport':
                    return 'https://support.gratisal.com/hc/da/articles/360018843711-Dataimport-i-Gratisal';
                case 'tabs.company.configuration.advancedsalarytypes':
                    return 'https://support.gratisal.com/hc/da/articles/360013219131-L%C3%B8narter-i-Gratisal';
                case 'tabs.company.configuration.integrations':
                    return 'https://support.gratisal.com/hc/da/articles/208119325-Integration-med-%C3%98konomisystem';
                case 'tabs.company.configuration.settings':
                    return 'https://support.gratisal.com/hc/da/articles/360025209451';
                case 'tabs.company.configuration.timeentrytypes':
                    return 'https://support.gratisal.com/hc/da/articles/360025214931';
                case 'tabs.company.modules':
                    return 'https://support.gratisal.com/hc/da/articles/360015566252-Gratisal-Moduler-og-Pakker-V3-0';
                case 'tabs.selfservice.payslip':
                    return 'https://support.gratisal.com/hc/da/articles/207669539-Se-dine-l%C3%B8nsedler';
                case 'tabs.selfservice.general':
                    return 'https://support.gratisal.com/hc/da/articles/208394175-Se-og-redig%C3%A9r-mine-personlige-data';
                case 'tabs.selfservice.time':
                    return 'https://support.gratisal.com/hc/da/articles/208468469-Timeregistrering-i-Timer';
                default:
                    return 'https://support.gratisal.com/hc/da';
            }
        },
        enumerable: true,
        configurable: true
    });
    HelpComponent.prototype.setupEventHandlers = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            _this.translateView();
        });
        this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (state) {
            _this.state = state;
            _this.translateView();
        });
    };
    HelpComponent.prototype.translateView = function () {
        switch (this.state) {
            case 'tabs.selfservice':
            case 'tabs.selfservice.general':
            case 'tabs.employee':
            case 'tabs.employee.general':
                this.translateText('EmployeeGeneral');
                break;
            case 'tabs.employee.hiringstatus':
                this.translateText('EmployeeEmployment');
                break;
            case 'tabs.selfservice.time':
            case 'tabs.employee.time':
                this.translateText('EmploymentTime');
                break;
            case 'tabs.employee.payrolldata':
                this.translateText('EmploymentPayrollData');
                break;
            case 'tabs.selfservice.payslip':
                this.translateText('SelfServicePayslip');
                break;
            case 'tabs.employee.payslip':
                this.translateText('EmployeePayslip');
                break;
            case 'tabs.company.general':
                this.translateText('CompanyGeneral');
                break;
            case 'tabs.company.configuration.hiringstatus':
                this.translateText('CompanyHiringStatus');
                break;
            case 'tabs.company.approval.timeentry':
                this.translateText('CompanyApproval');
                break;
            case 'tabs.company.configuration.advancedsalarytypes':
                this.translateText('CompanySalaryTypes');
                break;
            case 'tabs.company.configuration.timeentrytypes':
                this.translateText('Timeentrytypes');
                break;
            case 'tabs.company.configuration.integrations':
                this.translateText('Integrations');
                break;
            case 'tabs.company.salarybatches':
                this.translateText('CompanySalaryBatches');
                break;
            case 'tabs.company.modules':
                this.translateText('AccountModules');
                break;
        }
    };
    HelpComponent.prototype.translateText = function (subTabName) {
        var _this = this;
        this.translateService
            .get([subTabName + '.WelcomeTitle', subTabName + '.WelcomeMessage', 'Help.Title'])
            .subscribe(function (translations) {
            _this.welcomeMessageTitle = translations[subTabName + '.WelcomeTitle'];
            _this.welcomeMessage = translations[subTabName + '.WelcomeMessage'].replace(/<br\s*\/?>/gi, '\r\n');
        });
    };
    HelpComponent.prototype.navigateToState = function (state) {
        this.setResponsiveStyle(state);
        this.sessionService.NavigateTo(state);
    };
    HelpComponent.prototype.setResponsiveStyle = function (state) {
        switch (state) {
            case 'tabs.employee.hiringstatus':
                this.sessionService.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
                break;
            case 'tabs.employee.payrolldata':
                this.sessionService.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
                break;
            default:
                if (this.sessionService.currentState !== 'tabs.employee') {
                    this.sessionService.currentStyleMenuTabpanelReponsive = '';
                }
                break;
        }
    };
    return HelpComponent;
}());
export { HelpComponent };
