import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Constants } from '../../../Common/Constants';
import { Global } from '../../../Common/Global';
import { ISalaryBatchApprovalRequest } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SalaryBatchViewModel } from '../SalaryBatchViewModel';

@Component({
  selector: 'revert-finalized-batch',
  templateUrl: './RevertSalaryBatch.component.html'
})
export class RevertFinalizedBatchComponent {
  @Input() public salaryBatch: SalaryBatchViewModel;

  public visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
  }

  @Input() public get integrationName(): string {
    return this.currentIntegrationName;
  }
  public set integrationName(valuer: string) {
    this.currentIntegrationName = valuer;
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public revertBatchCompleted: EventEmitter<void> = new EventEmitter<void>();

  public get isExternalSystemRelevant(): boolean {
    // GS-3436
    if (this.integrationName) {
      return true;
    }
    return false;
  }

  public get translationParams(): any {
    // GS-3436
    if (this.integrationName) {
      return { externalSystem: this.integrationName };
    }
    return { externalSystem: '' };
  }

  public get isGreenland(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public password: string;
  public passwordDialogVisible = false;
  public currentIntegrationName: string;
  public userName: string;

  constructor(public sessionService: SessionService, private dataService: RxDataService) {
    this.userName = localStorage.getItem('saveLoginUserName');
  }

  public onDialogAction(action: string): void {
    if (action === 'Understood') {
      this.passwordDialogVisible = true;
    }
  }

  public onPasswwordDialogAction(action: string): void {
    if (action === 'Proceed') {
      const request: ISalaryBatchApprovalRequest = { Password: this.password, DoRecalc: false };
      this.dataService.SalaryBatches_RevertSalaryBatch(this.salaryBatch.Id, request).subscribe(() => {
        this.revertBatchCompleted.emit();
        this.visible = false;
      });
    } else {
      this.visible = false;
    }
  }
}
