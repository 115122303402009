import { EventEmitter, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Browser } from '../../Common/Browser';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
var ConfigurationComponent = /** @class */ (function () {
    function ConfigurationComponent(sessionService, renderer) {
        this.sessionService = sessionService;
        this.renderer = renderer;
        this.editModeValue = false;
        this.editModeChange = new EventEmitter();
        this.isDirtyValue = false;
        this.isDirtyChange = new EventEmitter();
        this.showSaveDataConfirmationChangeState = false;
        this.openDialogChangeAppToBrower = false;
    }
    Object.defineProperty(ConfigurationComponent.prototype, "editMode", {
        get: function () {
            return this.editModeValue;
        },
        set: function (value) {
            if (this.editModeValue !== value) {
                this.editModeValue = value;
                this.editModeChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationComponent.prototype, "isDirty", {
        get: function () {
            return this.isDirtyValue;
        },
        set: function (value) {
            if (this.isDirtyValue !== value) {
                this.isDirtyValue = value;
                this.isDirtyChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationComponent.prototype, "companyOptionTabEnabled", {
        get: function () {
            return this.sessionService.role.IsFullAdmin || this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationComponent.prototype, "companyDataImportTabEnabled", {
        get: function () {
            return !this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationComponent.prototype, "companyOptionStateActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.settings';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationComponent.prototype, "companyAdvancedSalaryTypesStateActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.advancedsalarytypes';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationComponent.prototype, "companyTimeEntryTypesStateActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.timeentrytypes';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationComponent.prototype, "companyintegrationsStateActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.integrations';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationComponent.prototype, "companyDataImportStateActive", {
        get: function () {
            return (!this.sessionService.role.IsReadOnly &&
                this.sessionService.currentState === 'tabs.company.configuration.dataimport');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationComponent.prototype, "companyTemplateTabVisisble", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.hiringstatus';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationComponent.prototype, "isHybridApp", {
        get: function () {
            var browser = new Browser();
            return browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    ConfigurationComponent.prototype.onConfimOpenAppinBrower = function (event) { };
    ConfigurationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.renderer.listen(window, 'click', function (event) {
            if (!_this.isCollapseButtonClick) {
                _this.menuOpen = false;
            }
            else {
                _this.isCollapseButtonClick = false;
            }
        });
        if (!environment.production) {
            console.log('state', this.sessionService.currentState);
        }
        if (this.sessionService.currentState === 'tabs.company.configuration') {
            if (this.companyOptionTabEnabled) {
                this.navigateTo('tabs.company.configuration.hiringstatus');
            }
            else {
                this.navigateTo('tabs.company.configuration.dataimport');
            }
        }
    };
    ConfigurationComponent.prototype.onConfirmSaveDataOnChangeState = function (action) {
        if (action === 'Continue') {
            this.editMode = false;
        }
        else if (action === 'DiscardAndLeave') {
            this.editMode = false;
            this.isDirty = false;
            if (this.navigationToStateName) {
                this.sessionService.NavigateTo(this.navigationToStateName);
            }
        }
        this.navigationToStateName = undefined;
    };
    ConfigurationComponent.prototype.navigateTo = function (state) {
        this.menuOpen = false;
        this.editMode = false;
        this.sessionService.NavigateTo(state);
    };
    ConfigurationComponent.prototype.onToggleNavigation = function () {
        this.isCollapseButtonClick = true;
        this.menuOpen = !this.menuOpen;
    };
    return ConfigurationComponent;
}());
export { ConfigurationComponent };
