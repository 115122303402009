import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import {
  Address,
  AddUserToCompanyRequest,
  CompanyUser,
  IAccount,
  ICity,
  ICompanyUser,
  ICountry,
  IDepartment,
  ILanguage,
  IMunicipality,
  IRole,
  ITaxCardType,
  IUserCreationData,
  IUserEmploymentTemplate
} from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { NewUserViewModel } from './NewUserViewModel';

@Component({
  selector: 'new-employee',
  templateUrl: './NewEmployee.component.html'
})
export class NewEmployeeComponent implements OnInit, OnDestroy {
  @Output() public newEmployeeCreated: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();
  @Output() public isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public get isVisible(): boolean {
    return this.visible;
  }

  public set isVisible(value: boolean) {
    if (value === true && this.visible !== value) {
      this.onShowNewUserWindows();
      this.createNewCompanyUser();
    }
    this.visible = value;
    this.isVisibleChange.emit(value);
  }

  public get showAddToCompany(): boolean {
    return this.sessionService.role.IsAccountAdmin && this.viewModel.AddUserCompanyRequest.Details.RoleId >= 30;
  }

  public hasLanguageModule = false;

  public visible = false;
  public nullPositionText: string;
  public defaultTitle: string;
  public createWithoutEmail: boolean;
  defaultLanguageName: string;
  showOptions = true;
  public viewModel: NewUserViewModel;
  public managers: ICompanyUser[];
  public isValidDate = true;
  public Municipality: IMunicipality[];
  public hasDepartmentEnabled: boolean;
  public accountName = '';

  constructor(
    public translateService: TranslateService,
    public dataService: RxDataService,
    public staticDataService: StaticDataService,
    public sessionService: SessionService
  ) {}

  public cities: ICity[];
  public taxCardTypes: ITaxCardType[];
  public ngOnInit(): void {
    this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ITaxCardType[]): void => {
      this.taxCardTypes = data;
      this.initModel();
    });

    this.staticDataService.City.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ICity[]) => (this.cities = data));
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) => {
      this.translateText();
    });

    this.translateText();
    this.staticDataService.Municipality.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (municipalities: IMunicipality[]) => {
        this.Municipality =
          municipalities && municipalities.length
            ? municipalities.filter((muni: any) => {
                return Global.SESSION && muni.CountryId === Global.SESSION.CurrentCountryId;
              })
            : undefined;
      }
    );

    if (this.sessionService.role.IsAccountAdmin) {
      this.staticDataService.currentAccount.subscribe((account: IAccount) => {
        if (account) {
          this.accountName = account.Name;
        }
      });
    }
  }

  public ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public initModel(): void {
    this.viewModel = new NewUserViewModel();
    this.viewModel.AddUserCompanyRequest = new AddUserToCompanyRequest();
    this.viewModel.AddUserCompanyRequest.Details = new CompanyUser();
    this.viewModel.AddUserCompanyRequest.Details.Address = new Address();
  }

  public get employmentTemplateSelected(): boolean {
    return (
      this.viewModel.AddUserCompanyRequest.EmploymentTemplateId &&
      this.viewModel.AddUserCompanyRequest.EmploymentTemplateId !== -1
    );
  }

  public get IsGreenLandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get IsPrimanyTaxCard(): boolean {
    return this.viewModel.AddUserCompanyRequest.TaxCardTypeId === 1;
  }

  public get IsFreeTaxCard(): boolean {
    return this.viewModel.AddUserCompanyRequest.TaxCardTypeId === 3;
  }

  public onShowNewUserWindows(): void {
    this.initModel();

    //this.dataService.Companies_GetCurrent().subscribe((company: ICompany): void => { });
    this.staticDataService.employmentTitle.subscribe((titles: string[]) => {
      this.viewModel.Titles = titles;
    });

    this.staticDataService.companyUsers
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: ICompanyUser[]) => this.setManagerDataSource(JSON.parse(JSON.stringify(data))));
    this.staticDataService.Role.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IRole[]) => (this.viewModel.Roles = data)
    );
    this.staticDataService.Country.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ICountry[]) => (this.viewModel.Countries = data)
    );
    this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ILanguage[]) => {
      this.viewModel.Languages = data;
      const defaultLanguage: any =
        this.viewModel.Languages && this.viewModel.Languages.length
          ? this.viewModel.Languages.find((language: ILanguage) => language.Id === 1)
          : undefined;
      this.defaultLanguageName = defaultLanguage ? defaultLanguage.Name : undefined;
    });

    this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IDepartment[]) => {
      this.viewModel.Departments = JSON.parse(JSON.stringify(data));
    });

    this.viewModel.NoCPRNumber = false;
    this.onNoCPRCheckboxClicked(undefined); // Reset form based on NoCPRNumber condition.
    if (this.sessionService.feature.ShowEmployeeEmploymentTemplate) {
      // Get Templates
      this.dataService
        .EmploymentTemplates_GetEmploymentTemplates()
        .subscribe((templates: IUserEmploymentTemplate[]): void => {
          this.onGetTemplatesCompletedEvent(templates);
        });
    }

    this.createWithoutEmail = false;
  }

  public setManagerDataSource(data: any): void {
    this.managers = data;
  }

  public translateText(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService.get(['NewEmployee.DefaultTitle']).subscribe((translations: { [key: string]: string }) => {
        this.defaultTitle = translations['NewEmployee.DefaultTitle'];
        this.viewModel.TaxCardTypes = this.getTaxCardTypeComboboxDataSource();
      });
    });
  }

  public getEmploymentTemplateComboboxDataSource(templates: IUserEmploymentTemplate[]): IUserEmploymentTemplate[] {
    // let nullableItem: IUserEmploymentTemplate = { Id: -1, Name: this.nullPositionText };
    // templates.unshift(nullableItem);
    return templates;
  }

  public getTaxCardTypeComboboxDataSource(): ITaxCardType[] {
    const taxCardTypes: ITaxCardType[] = this.taxCardTypes;
    return taxCardTypes;
  }

  public onDepartmentComboBoxChanged(): void {
    this.selectDefaultManager(this.viewModel.AddUserCompanyRequest.DepartmentId);
  }

  public selectDefaultManager(departmentId: number): void {
    if (departmentId === -1) {
      this.viewModel.AddUserCompanyRequest.ManagerCompanyUserId = -1;
      return;
    }

    const department: IDepartment = this.viewModel.Departments
      ? this.viewModel.Departments.find((dpm: IDepartment) => dpm.Id === departmentId)
      : undefined;
    if (department) {
      this.viewModel.AddUserCompanyRequest.ManagerCompanyUserId = department.ManagerCompanyUserId
        ? department.ManagerCompanyUserId
        : -1;
    }
  }

  public onPostalCodeChanged(value: string): void {
    this.autoFillCity(value);
  }

  public autoFillCity(postalCode: string): void {
    if (!postalCode) {
      return;
    }

    let city: any = this.cities
      ? this.cities.find((c: any): boolean => {
          return (
            c.CountryId === this.viewModel.AddUserCompanyRequest.Details.Address.CountryId &&
            c.PostalCode &&
            postalCode === c.PostalCode.toString()
          );
        })
      : undefined;

    if (!city) {
      city = this.cities
        ? this.cities.find((c: any): boolean => {
            return c.PostalCode && postalCode === c.PostalCode.toString();
          })
        : undefined;
    }

    if (city) {
      this.viewModel.AddUserCompanyRequest.Details.Address.City = city.Key;
      this.autoFillMunicipality(city.Key);
      if (city.CountryId !== this.viewModel.AddUserCompanyRequest.Details.Address.CountryId) {
        this.viewModel.AddUserCompanyRequest.Details.Address.CountryId = city.CountryId;
        this.setDefaultApplyDanishRulesInGreenland();
      }
    }
  }

  public autoFillPostalCode(cityName: string): void {
    if (!cityName || !this.cities) {
      return;
    }

    const city: any = this.cities.find((c: any): boolean => cityName === c.Key);
    if (city) {
      this.viewModel.AddUserCompanyRequest.Details.Address.PostalCode = city.PostalCode;
    }
  }

  public onCityBlurChange(value: string): void {
    this.autoFillMunicipality(this.viewModel.AddUserCompanyRequest.Details.Address.City);
  }

  public autoFillMunicipality(cityName: string): void {
    if (!cityName || !this.IsGreenLandCompany || !this.cities) {
      return;
    }

    const city: any = this.cities.find(
      (filterCity: any): boolean => filterCity.Key && cityName.toLowerCase() === filterCity.Key.toString().toLowerCase()
    );
    if (
      city &&
      city.MunicipalityId &&
      (this.viewModel.AddUserCompanyRequest.Details as any).MunicipalityId !== city.MunicipalityId
    ) {
      (this.viewModel.AddUserCompanyRequest.Details as any).MunicipalityId = city.MunicipalityId;
      this.updateDefaultTaxRate();
    }
  }

  public onMunicipalityChanged(): void {
    this.updateDefaultTaxRate();
  }

  public onCountryChange(): void {
    this.setDefaultApplyDanishRulesInGreenland();
    const country: any = this.viewModel.AddUserCompanyRequest.Details.Address.CountryId;
    if (country !== Constants.DENMARK_COUNTRY_ID && country !== Constants.GREENLAND_COUNTRY_ID) {
      (this.viewModel.AddUserCompanyRequest.Details as any).MunicipalityId = 8;
      this.updateDefaultTaxRate();
    }
  }

  public isShowForEmploymentTemplateId = true;
  public onEmploymentTemplateChanged(): void {
    if (this.viewModel.AddUserCompanyRequest.EmploymentTemplateId) {
      this.isShowForEmploymentTemplateId = true;
    } else {
      this.isShowForEmploymentTemplateId = false;
    }
    this.updateDefaultTaxRate();
  }

  public updateDefaultTaxRate(): void {
    if (
      this.IsGreenLandCompany ||
      this.employmentTemplateSelected ||
      (this.viewModel.AddUserCompanyRequest.Details as any).MunicipalityId
    ) {
      const muni: any = this.Municipality
        ? this.Municipality.find(
            (mun: any) => mun.Id === (this.viewModel.AddUserCompanyRequest.Details as any).MunicipalityId
          )
        : undefined;
      if (muni) {
        this.viewModel.AddUserCompanyRequest.TaxRate = muni.DefaultTaxRate;
      }
    }
  }

  public createNewCompanyUser(): void {
    this.viewModel.AddUserCompanyRequest.Details.IsActive = true;
    this.viewModel.AddUserCompanyRequest.Details.RoleId = 20;
    this.viewModel.AddUserCompanyRequest.Title = this.defaultTitle;
    this.viewModel.AddUserCompanyRequest.HireDate = this.staticDataService.getCurrentdate();
    this.viewModel.AddUserCompanyRequest.Details.Address.CountryId =
      Global.COMPANY && Global.COMPANY.CountryId ? Global.COMPANY.CountryId : Constants.DENMARK_COUNTRY_ID;
    this.setDefaultApplyDanishRulesInGreenland();
    //this.viewModel.AddUserCompanyRequest.LanguageId = 1;
    this.viewModel.AddUserCompanyRequest.LanguageId = Global.COMPANY ? Global.COMPANY.DefaultLanguageId : 1;
    this.viewModel.AddUserCompanyRequest.DepartmentId =
      this.viewModel.Departments && this.viewModel.Departments.length > 1 ? -1 : undefined; // Department has None by default.
    this.viewModel.AddUserCompanyRequest.ManagerCompanyUserId = -1;

    const taxCardTypes: ITaxCardType[] = this.taxCardTypes;
    this.viewModel.AddUserCompanyRequest.TaxCardTypeId = taxCardTypes.length > 1 ? taxCardTypes[0].Id : undefined;
    if (this.viewModel.Templates && this.viewModel.Templates.length > 1) {
      this.viewModel.AddUserCompanyRequest.EmploymentTemplateId = this.viewModel.Templates[1].Id;
    } else {
      this.viewModel.AddUserCompanyRequest.EmploymentTemplateId = undefined;
    }

    this.viewModel.AddUserCompanyRequest.Details.CompanyId = Global.COMPANY_ID;
  }

  public setDefaultApplyDanishRulesInGreenland(): void {
    if (this.IsGreenLandCompany) {
      this.viewModel.AddUserCompanyRequest.ApplyDanishRulesInGreenland =
        this.viewModel.AddUserCompanyRequest.Details.Address.CountryId === Constants.DENMARK_COUNTRY_ID;
    }
  }

  public onGetTemplatesCompletedEvent(templates: IUserEmploymentTemplate[]): void {
    if (templates && templates.length > 0) {
      this.viewModel.Templates = this.getEmploymentTemplateComboboxDataSource(templates);
    }
    this.createNewCompanyUser();
    this.viewModel.AddUserCompanyRequest.EmploymentTemplateId =
      this.viewModel.Templates && this.viewModel.Templates.length
        ? this.viewModel.Templates.length > 1
          ? this.viewModel.Templates[1].Id
          : this.viewModel.Templates[0].Id
        : undefined;
  }

  public isnoCPRWarning = false;
  public onNoCPRCheckboxClicked(event: any): void {
    if (this.viewModel.NoCPRNumber) {
      this.isnoCPRWarning = true;
      if (this.viewModel.AddUserCompanyRequest) {
        this.viewModel.AddUserCompanyRequest.IdentityNumber = '';
        this.viewModel.AddUserCompanyRequest.EmploymentTemplateId = undefined;
      }
    } else if (this.viewModel.AddUserCompanyRequest) {
      this.viewModel.AddUserCompanyRequest.EmploymentTemplateId = this.viewModel.Templates
        ? this.viewModel.Templates.length > 1
          ? this.viewModel.Templates[1].Id
          : this.viewModel.Templates[0].Id
        : undefined;
    }
  }

  public raiseCreateNewUserCompletedEvent(companyUser?: any): void {
    if (companyUser && companyUser.CompanyUser) {
      this.newEmployeeCreated.emit(companyUser.CompanyUser);
    } else {
      this.newEmployeeCreated.emit(companyUser);
    }
  }

  public isNonCreateMail = false;
  public onCreateNew(): void {
    if (
      !this.viewModel.AddUserCompanyRequest.Details.PersonalEmail &&
      !this.viewModel.AddUserCompanyRequest.Details.CompanyEmail
    ) {
      this.isNonCreateMail = true;
    } else {
      this.createNew();
    }
  }

  public createNew(): void {
    if (!this.validateInput()) {
      return;
    }

    this.viewModel.AddUserCompanyRequest.Details.IsActive = true;
    this.viewModel.AddUserCompanyRequest.Details.CompanyId = Global.COMPANY_ID;
    const hireDate: Date = this.viewModel.AddUserCompanyRequest.HireDate;
    if (
      this.viewModel.AddUserCompanyRequest.EmploymentTemplateId === -1 ||
      !this.viewModel.AddUserCompanyRequest.EmploymentTemplateId
    ) {
      this.viewModel.AddUserCompanyRequest.EmploymentTemplateId = undefined;
      this.viewModel.AddUserCompanyRequest.Title = undefined;
      this.viewModel.AddUserCompanyRequest.DepartmentId = undefined;
      this.viewModel.AddUserCompanyRequest.TaxCardTypeId = undefined;
      this.viewModel.AddUserCompanyRequest.ManagerCompanyUserId = undefined;
    }

    // If the user selects None for department it should be set to null for the api call
    if (this.viewModel.AddUserCompanyRequest.DepartmentId === -1) {
      this.viewModel.AddUserCompanyRequest.DepartmentId = undefined;
    }

    // If the user selects None for department it should be set to null for the api call
    if (this.viewModel.AddUserCompanyRequest.ManagerCompanyUserId === -1) {
      this.viewModel.AddUserCompanyRequest.ManagerCompanyUserId = undefined;
    }

    this.dataService.CompanyUsers_AddUserToCompany(this.viewModel.AddUserCompanyRequest).subscribe(
      (returnedCompanyUser: IUserCreationData): void => {
        this.closeForm('Close');
        this.raiseCreateNewUserCompletedEvent(returnedCompanyUser);
        this.createNewCompanyUser();
      },
      (error: any) => {
        this.raiseCreateNewUserCompletedEvent();
      }
    );
  }

  public isInvalid = false;
  public messageValid: string;
  public validateInput(): boolean {
    if (this.employmentTemplateSelected && !this.isValidDate) {
      return false;
    }

    //let message: string = this.getErrorMessage();
    this.messageValid = this.getErrorMessage();
    if (this.messageValid) {
      this.isInvalid = true;
      return false;
    }

    return true;
  }

  public getErrorMessage(): string {
    if (
      !this.viewModel.AddUserCompanyRequest.Details.FirstName ||
      !this.viewModel.AddUserCompanyRequest.Details.LastName ||
      (!this.viewModel.NoCPRNumber && !this.viewModel.AddUserCompanyRequest.IdentityNumber) ||
      (!this.viewModel.AddUserCompanyRequest.Details.MunicipalityId &&
        this.IsGreenLandCompany &&
        this.employmentTemplateSelected)
    ) {
      return 'NewEmployee.RequiredErrorMessage';
    }

    if (
      !this.viewModel.AddUserCompanyRequest.EmploymentTemplateId ||
      this.viewModel.AddUserCompanyRequest.EmploymentTemplateId.toString() === '-1'
    ) {
      return '';
    }

    if (!this.viewModel.AddUserCompanyRequest.Title) {
      return 'NewEmployee.TitleErrorMessage';
    }

    if (!this.viewModel.AddUserCompanyRequest.HireDate) {
      return 'NewEmployee.HiringErrorMessage';
    }

    return '';
  }

  public onCancel(): void {
    this.closeForm('Close');
    this.createNewCompanyUser();
  }

  public closeForm(action: string): void {
    if (action === 'Close') {
      this.isVisible = false;
      this.createNewCompanyUser();
      return;
    }
    if (action === 'CreateWithoutEmails') {
      this.createNew();
      return;
    }
    if (action === 'Create') {
      this.onCreateNew();
      return;
    }
  }

  public onInvalidDialog(event: string): void {}
  public onNonCreateMailDialog(event: string): void {
    this.createWithoutEmail = true;
  }
}
