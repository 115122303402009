import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RxDataService } from '../../../../Services/RxDataService';

@Component({
  selector: 'company-template-employee-list',
  templateUrl: './CompanyTemplateEmployee.component.html'
})
export class CompanyTemplateEmployeeListComponent {
  private currentSelectedTemplateId: number;
  @Input()
  public get selectedTemplateId(): number {
    return this.currentSelectedTemplateId;
  }
  public set selectedTemplateId(value: number) {
    if (this.currentSelectedTemplateId !== value) {
      this.currentSelectedTemplateId = value;
      this.getEmployees();
    }
  }

  private templateHasEmployees: boolean;
  @Input()
  public get hasEmployees(): boolean {
    return this.templateHasEmployees;
  }
  public set hasEmployees(value: boolean) {
    if (this.templateHasEmployees !== value) {
      this.templateHasEmployees = value;
      setTimeout(() => this.hasEmployeesChange.emit(value));
    }
  }
  @Output() public hasEmployeesChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public employees: any[] = [];

  constructor(private dataService: RxDataService) {}

  private getEmployees(): void {
    if (this.currentSelectedTemplateId) {
      this.dataService
        .EmploymentTemplates_GetCompanyUsersByEmploymentTemplate(this.currentSelectedTemplateId)
        .subscribe((employees: any[]): void => {
          this.employees = employees;
          this.hasEmployees = this.employees && this.employees.length > 0;
        });
    } else {
      this.employees = [];
      this.hasEmployees = false;
    }
  }
}
