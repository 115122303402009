import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
var DialogActionComponent = /** @class */ (function () {
    function DialogActionComponent(sessionService) {
        var _this = this;
        this.sessionService = sessionService;
        this.actionValue = 'Ok';
        this.labelValue = '';
        this.classValue = 'Button--primary';
        this.close = true;
        this.disabled = false;
        this.ngUnsubscribe = new Subject();
        this.sessionService.OnModalTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            _this.translateStandardButton();
        });
    }
    Object.defineProperty(DialogActionComponent.prototype, "action", {
        get: function () {
            return this.actionValue;
        },
        set: function (value) {
            this.actionValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogActionComponent.prototype, "label", {
        get: function () {
            return this.labelValue;
        },
        set: function (value) {
            this.labelValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogActionComponent.prototype, "class", {
        get: function () {
            return this.classValue;
        },
        set: function (value) {
            this.classValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogActionComponent.prototype, "type", {
        set: function (value) {
            this.actionValue = value;
            this.typeValue = value;
            this.translateStandardButton();
        },
        enumerable: true,
        configurable: true
    });
    DialogActionComponent.prototype.translateStandardButton = function () {
        if (this.typeValue && this.sessionService.modalTranslations) {
            switch (this.typeValue) {
                case 'AcceptDPAButton':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.AcceptDPAButton'];
                    this.classValue = 'Button--primary';
                    break;
                case 'MoreInfo':
                    this.labelValue = this.sessionService.modalTranslations['EmploymentPayrollData.MoreInfo'];
                    this.classValue = 'Button--primary';
                    break;
                case 'UsePositiveAmountThisTime':
                    this.labelValue = this.sessionService.modalTranslations['EmploymentPayrollData.UsePositiveAmountThisTime'];
                    this.classValue = 'Button--primary';
                    break;
                case 'AcceptWarnings':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.AcceptWarnings'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Apply':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Apply'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Approve':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Approve'];
                    this.classValue = 'Button--primary';
                    break;
                case 'AlreadyRequestedTaxCardConfirmButton':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.AlreadyRequestedTaxCardConfirmButton'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Cancel':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Cancel'];
                    this.classValue = 'Button--secondary';
                    break;
                case 'CancelDiscard':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.CancelDiscard'];
                    this.classValue = 'Button--secondary';
                    break;
                case 'ChangePassword':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.ChangePassword'];
                    this.classValue = 'Button--primary';
                    break;
                case 'ClearErrors':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.ClearErrors'];
                    this.classValue = 'Button--primary';
                    break;
                case 'ConfirmDiscard':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.ConfirmDiscard'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'Continue':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Continue'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Ignore':
                    this.labelValue = this.sessionService.modalTranslations['Warning.Continue'];
                    this.classValue = 'Button--primary';
                    break;
                case 'ContinueAndSave':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.ContinueAndSaveChange'];
                    this.classValue = 'Button--primary';
                    break;
                case 'CompleteImport':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.CompleteImportText'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Close':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Close'];
                    this.classValue = 'Button--secondary';
                    break;
                case 'Create':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Create'];
                    this.classValue = 'Button--primary';
                    break;
                case 'CreateWithoutEmails':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.CreateWithoutEmails'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Delete':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Delete'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'RevertBatch':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Understood'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'DisableThisBehaviour':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.DisableThisBehaviour'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'DiscardAndLeave':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.DiscardAndLeave'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'Download':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Download'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Edit':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Edit'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Enable':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Enable'];
                    this.classValue = 'Button--primary';
                    break;
                case 'EnableAutoCorrect':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.EnableAutoCorrect'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Import':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Import'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Finalize':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Finalize'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Hide':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Hide'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'HideStartUp':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Hide'];
                    this.classValue = 'Button--primary';
                    break;
                case 'IKnowWhatIamDoing':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.IKnowWhatIamDoing'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Lock':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Lock'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'LockAndGrantAccess':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Lock and notify employees'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'Logout':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Logout'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'MoreHelp':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.MoreHelp'];
                    this.classValue = 'Button--primary';
                    break;
                case 'No':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.No'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'Ok':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Ok'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Proceed':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Proceed'];
                    this.classValue = 'Button--primary';
                    break;
                case 'ReadMore':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.ReadMore'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Reject':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Reject'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'RejectPrimary':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Reject'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Resend':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Re-send'];
                    this.classValue = 'Button--primary';
                    break;
                case 'ResetPassword':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.ResetPassword'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Revert':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Revert'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'RevertToDraft':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.RevertToDraft'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Save':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Save'];
                    this.classValue = 'Button--primary';
                    break;
                case 'SaveDefinition':
                    this.labelValue = this.sessionService.modalTranslations['CompanyDataImport.Save Definition'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Submit':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Submit'];
                    this.classValue = 'Button--primary';
                    break;
                case 'SubmitForApproval':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.SubmitForApproval'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Stay':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Stay'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Test':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Test'];
                    this.classValue = 'Button--primary';
                    break;
                case 'ImportOnce':
                    this.labelValue = this.sessionService.modalTranslations['CompanyDataImport.Import Once'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Understood':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Understood'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'Update':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Update'];
                    this.classValue = 'Button--primary';
                    break;
                case 'UsePositiveAmountThisTime':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.UsePositiveAmountThisTime'];
                    this.classValue = 'Button--primary';
                    break;
                case 'Yes':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.Yes'];
                    this.classValue = 'Button--primary';
                    break;
                case 'DeleteEmployment':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.DeleteEmploymentButtonText'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'DeleteCompanyUser':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.DeleteCompanyUserButtonText'];
                    this.classValue = 'Button--destructive';
                    break;
                case 'YesPlease':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.YesPlease'];
                    this.classValue = 'Button--primary';
                    break;
                case 'NoThankYou':
                    this.labelValue = this.sessionService.modalTranslations['GlobalDialog.NoThankYou'];
                    this.classValue = 'Button--secondary';
                    break;
            }
        }
    };
    DialogActionComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    return DialogActionComponent;
}());
export { DialogActionComponent };
