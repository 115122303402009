// tslint:disable:class-name
// tslint:disable: max-classes-per-file

export interface IAccount {
  Id: number;
  Name?: string;
  StatusId: number;
  AccountTypeId: number;
  Companies?: ICompany[];
  LoginHistories?: ILoginHistory[];
  AccountType?: IAccountType;
  AccountStatus?: IAccountStatus;
}

export interface ICompany {
  Id: number;
  Name?: string;
  StatusId: number;
  VATRegistrationNumber?: string;
  AccountId?: number;
  Email1?: string;
  Email2?: string;
  DefaultLanguageId: number;
  AddressId: number;
  CompanyTypeId: number;
  SecondaryVATRegistrationNumber?: string;
  MainContactCompanyUserId?: number;
  BankRegNo?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  ExternalSystemComment?: string;
  PaymentMethodSalary: number;
  PaymentMethodPension: number;
  PaymentMethodTax: number;
  ExternalSystemId?: number;
  TelMobile?: string;
  TelFixed?: string;
  KnowsGratisalFrom?: string;
  ExternalAuthentication?: string;
  HasNetsAgreement: boolean;
  Created: Date;
  EmployersAssociationNumber?: string;
  StaticsReportsYearly: boolean;
  StatisticsRecipientId?: number;
  SubscribeToNewsLetter: boolean;
  EstablishedDate?: Date;
  EmployeeCountEstimate?: string;
  Preferences?: string;
  InvoiceIdentifier?: string;
  PaymentMethodPublicPension: number;
  PaymentMethodVacationProvider: number;
  InvoiceEmail?: string;
  HasAcceptedTerms: boolean;
  CountryId: number;
  CurrentPackageLevel: number;
  ScheduledPackageId?: number;
  ScheduledPackageDate?: Date;
  IndustryCode?: string;
  AccountComment?: string;
  BankId?: number;
  Language?: ILanguage;
  Address?: IAddress;
  CompanyLogo?: ICompanyLogo;
  Departments?: IDepartment[];
  ExternalAccounts?: IExternalAccount[];
  CompanyType?: ICompanyType;
  CompanyStatus?: ICompanyStatus;
  Country?: ICountry;
}

export interface ILoginHistory {
  Id: number;
  LoginTime: Date;
  UserIp?: string;
  Country?: string;
  City?: string;
  Region?: string;
  PostalCode?: string;
  IsSuccessful: boolean;
  UserName?: string;
  UserId?: number;
  CompanyId?: number;
  UserAgent?: string;
  BrowserName?: string;
  BrowserVersion?: string;
  OSName?: string;
  OSVersion?: string;
  OperationType?: string;
  AccountId?: number;
  Company?: ICompany;
  User?: IUser;
  Account?: IAccount;
}

export interface IAccountType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IAccountStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ILanguage {
  Id: number;
  Name?: string;
  Code?: string;
  IsSupported: boolean;
  CultureCode?: string;
}

export interface IAddress {
  Id: number;
  Line1?: string;
  Line2?: string;
  PostalCode?: string;
  City?: string;
  CountryId?: number;
  Country?: ICountry;
}

export interface ICompanyLogo {
  Id: number;
  Logo?: string;
  Company?: ICompany;
}

export interface IDepartment {
  Id: number;
  CompanyId: number;
  Name?: string;
  Description?: string;
  IsActive: boolean;
  EIncomeReference?: string;
  ExternalReference?: string;
  ManagerCompanyUserId?: number;
}

export interface IExternalAccount {
  Id: number;
  CompanyId: number;
  AccountIdentifier?: string;
  AccountNumber?: string;
  AccountName?: string;
  Description?: string;
  IntegrationId?: number;
  Company?: ICompany;
  Integration?: IIntegration;
}

export interface ICompanyType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ICompanyStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ICountry {
  IsSupported: boolean;
  DefaultLanguageId: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IUser {
  FullName?: string;
  IdentityNumberFormatted?: string;
  HasDummyIdentityNumber: boolean;
  IsFemale: boolean;
  Id: number;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  IdentityNumber?: string;
  BankRegNo?: string;
  BankAccountNo?: string;
  Username?: string;
  LanguageId: number;
  BicSwiftNo?: string;
  IbanNo?: string;
  AddressId: number;
  AccessPhrase?: string;
  DateOfBirth?: Date;
  TelMobile?: string;
  TelFixed?: string;
  Email?: string;
  DefaultInitials?: string;
  DefaultCompanyId?: number;
  UseGlobalEmail: boolean;
  IsFirstLogin: boolean;
  Preferences?: string;
  Created: Date;
  ShowUpdateNotification: boolean;
  ShowUrgentNotification: boolean;
  ShowPayslipTutorial: number;
  AccountId?: number;
  AccountRoleId?: number;
  Address?: IAddress;
  CompanyUsers?: ICompanyUser[];
  UserImage?: IUserImage;
  LoginHistories?: ILoginHistory[];
}

export interface IIntegration {
  Id: number;
  CompanyId: number;
  ExternalSystemId: number;
  Name?: string;
  Created: Date;
  StatusCode: number;
  IsSalaryTypeMappingActive: boolean;
  IntegrationOperations?: IIntegrationOperation[];
  IntegrationConfigurations?: IIntegrationConfiguration[];
  IntegrationCredentials?: IIntegrationCredential[];
}

export interface ICompanyUser {
  Id: number;
  UserId: number;
  CompanyId: number;
  RoleId: number;
  IsActive: boolean;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  BankRegNo?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  AddressId: number;
  IsPaymentApprover: boolean;
  Initials?: string;
  TelMobile?: string;
  TelFixed?: string;
  CompanyEmail?: string;
  PersonalEmail?: string;
  Created: Date;
  DeactivationDate?: Date;
  PreferredCurrencyId?: number;
  AutoDetermineBankAccount: boolean;
  MunicipalityId?: number;
  UserEmployments?: IUserEmployment[];
  Address?: IAddress;
  UserImage?: IUserImage;
  CompanyModules?: ICompanyModule[];
  FullName?: string;
  Role?: IRole;
  UserLanguageId: number;
  UserIdentityNumber?: string;
  IsFemale: boolean;
  UserDateOfBirth: Date;
  HasDummyIdentityNumber: boolean;
}

export interface IUserImage {
  Id: number;
  Image?: string;
}

export interface IIntegrationOperation {
  Id: number;
  IntegrationId: number;
  IsActive: boolean;
  LastRun?: Date;
  StatusCode: number;
  OperationId: number;
  TranslationKey?: string;
  TranslationParametersList?: string;
  Integration?: IIntegration;
  TranslatedStatusMessage?: string;
}

export interface IIntegrationConfiguration {
  Id: number;
  IntegrationId: number;
  Key?: string;
  Value?: string;
  Integration?: IIntegration;
}

export interface IIntegrationCredential {
  Id: number;
  IntegrationId: number;
  Key?: string;
  Value?: string;
  Integration?: IIntegration;
  ExternalSystemCredential?: IExternalSystemCredential;
  IsPrivate: boolean;
}

export interface IUserEmployment {
  VacationType?: IVacationType;
  VacationProvider?: IVacationProvider;
  PensionProvider?: IPensionProvider;
  Pension2Provider?: IPensionProvider;
  PensionBaseCalculationMethod?: IPensionBaseCalculationMethod;
  Pension2BaseCalculationMethod?: IPensionBaseCalculationMethod;
  SalaryCycle?: ISalaryCycle;
  StandardHoursPerWorkDay: number;
  Id: number;
  CompanyUserId: number;
  HireDate: Date;
  ExpireDate?: Date;
  VacationTypeId: number;
  VacationProviderId?: number;
  VacationExtraPercentage?: number;
  BasedOnTemplateId?: number;
  Title?: string;
  SalaryCycleId: number;
  ExternalReference?: string;
  PensionBaseCalculationMethodId: number;
  IsAtpTempWorker: boolean;
  DepartmentId?: number;
  IncomeTypeId: number;
  WorksElsewhere: boolean;
  PensionEmployeeShare?: number;
  PensionCompanyShare?: number;
  PensionProviderId?: number;
  PensionPolicyNumber?: string;
  PensionEmployeeAmount?: number;
  PensionCompanyAmount?: number;
  Pension2BaseCalculationMethodId: number;
  Pension2ProviderId?: number;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeAmount?: number;
  Pension2CompanyAmount?: number;
  LastStatementEndDate?: Date;
  VacationReduceSalary: boolean;
  IsTerminated: boolean;
  VacationSupplementPayoutMonth: number;
  IsInMaternityProgram: boolean;
  FFSeniorityRequirementMonths: number;
  FFHoursEarnedPerYear: number;
  FFAutoPayout: boolean;
  TaxCardRequested?: Date;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  Pension2ProviderBankRegNo?: string;
  Pension2ProviderBankAccountNo?: string;
  Pension2PolicyNumber?: string;
  PreferredTaxCardTypeId: number;
  Created: Date;
  IllnessReducesSalary: boolean;
  ManagerCompanyUserId?: number;
  StandardHoursPerWeek?: number;
  StatisticsSalaryPrincipleId?: number;
  StatisticsEmploymentPrincipleId?: number;
  StatisticsEmploymentTermsId?: number;
  StatisticsJobStatusId?: number;
  StatisticsJobFunctionCode?: number;
  StatisticsSalaryPrincipleFromDate?: Date;
  StatisticsEmploymentPrincipleFromDate?: Date;
  StatisticsEmploymentTermsFromDate?: Date;
  StatisticsJobStatusFromDate?: Date;
  StandardWorkDaysPerWeek?: number;
  StatisticsJobFunctionFromDate?: Date;
  FlexEarningPercentage: number;
  FlexBaseCalculationMethodId: number;
  IsPendingRecalc: boolean;
  CollectiveBargainingAgreementCode?: string;
  TerminationVacationOverridePercentage?: number;
  SeniorityDate?: Date;
  ApplyDanishRulesInGreenland: boolean;
  AnnualTaxComment?: string;
  AllowExtendedTaxCardDeductions: boolean;
  ReduceTaxCardDeduction?: number;
  IncreaseTaxRatePercentage?: number;
  UseTaxDeductionPerWorkDay: boolean;
  CompanyUser?: ICompanyUser;
  UserTaxInfos?: IUserTaxInfo[];
  SalaryRecords?: ISalaryRecord[];
  TimeEntryTypeConfigurations?: ITimeEntryTypeConfiguration[];
}

export interface ICompanyModule {
  Id: number;
  ModuleId: number;
  LastActivated: Date;
  ActivatedCompanyUserId?: number;
  CompanyId: number;
  ScheduledDeactivationDate?: Date;
  ActivatedByCompanyUser?: ICompanyUser;
  Module?: IModule;
}

export interface IRole {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IExternalSystemCredential {
  IsPrivate: boolean;
  BackendKey?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IVacationType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IVacationProvider {
  SENumber?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IPensionProvider {
  NetsNo?: string;
  DefaultBaseCalculationMethodId: number;
  NetsInfoType: number;
  PolicyNumberRequired: boolean;
  AccountNumberRequired: boolean;
  PolicyNumberLabelOverride?: string;
  CountryId: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IPensionBaseCalculationMethod {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ISalaryCycle {
  Months: number;
  Days: number;
  MonthEnd: boolean;
  IsForwardPaid: boolean;
  TaxDeductionFactor: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IUserTaxInfo {
  TaxCardType?: ITaxCardType;
  IsCurrent: boolean;
  Id: number;
  ValidFrom: Date;
  ValidUntil?: Date;
  TaxRate?: number;
  DeductionMonth?: number;
  FreeAmount?: number;
  UserEmploymentId: number;
  IsActive: boolean;
  TaxCardTypeId: number;
  IsDummy: boolean;
  MinimumRate?: number;
  MaximumDeductionMonth?: number;
  MaximumDeductionDay?: number;
  Created: Date;
  DeductionWeek?: number;
  DeductionDay?: number;
  IsManual: boolean;
  ParsedFromFileName?: string;
  TaxCardRequestId?: number;
}

export interface ISalaryRecord {
  UnitType?: IUnitType;
  PersistenceType?: ISalaryRecordPersistenceType;
  Id: number;
  Units?: number;
  IsActive: boolean;
  StartDate?: Date;
  EndDate?: Date;
  Description?: string;
  UserEmploymentId: number;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Amount?: number;
  BaseAmount?: number;
  SalaryTypeId: number;
  SortOrder: number;
  TimeEntryTypeId?: number;
  PersistenceTypeId: number;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  TimeEntryType?: ITimeEntryType;
}

export interface ITimeEntryTypeConfiguration {
  Id: number;
  TimeEntryTypeId: number;
  SalaryTypeId: number;
  Description?: string;
  Amount?: number;
  AmountPerUnit?: number;
  AppliesBefore?: string;
  AppliesAfter?: string;
  AppliesWeekdays: boolean;
  AppliesHolidays: boolean;
  AmountPerKilometer?: number;
  EmploymentTemplateId?: number;
  TargetUnitTypeId?: number;
  ConversionFactor?: number;
  UserEmploymentId?: number;
  AppliesSaturdays: boolean;
  AppliesSundays: boolean;
}

export interface IModule {
  Price: number;
  BillingPrincipleId: number;
  FreeUntilDate?: Date;
  InfoOnly: boolean;
  PackageLevel: number;
  BillingPrinciple?: IBillingPrinciple;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ITaxCardType {
  EIncomeReference: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IUnitType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ISalaryRecordPersistenceType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ITimeEntryType {
  Id: number;
  BaseTimeEntryTypeId?: number;
  CompanyId?: number;
  Name?: string;
  Description?: string;
  IsActive?: boolean;
  UnitTypeId?: number;
  ExternalReference?: string;
  SalaryTypeId?: number;
  SortOrder?: number;
  DefaultPayslipText?: string;
  AllowEditUnitType?: boolean;
  IsIllness?: boolean;
  InternalReferenceId?: number;
  Identifier?: string;
  BaseTimeEntryType?: ITimeEntryType;
  TimeEntryTypeTranslations?: ITimeEntryTypeTranslation[];
  TimeEntryTypeConfigurations?: ITimeEntryTypeConfiguration[];
}

export interface IBillingPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ITimeEntryTypeTranslation {
  Id: number;
  TimeEntryTypeId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  DefaultPayslipText?: string;
  StatusId: number;
  Comment?: string;
}

export interface ICompanyAccountView {
  Id: number;
  Name?: string;
  VATRegistrationNumber?: string;
  AccountId?: number;
  MainContactCompanyUserId?: number;
  MainContactFullName?: string;
  ContactEmail?: string;
  InvoiceEmail?: string;
  AccountComment?: string;
  CountryId: number;
  CountryCode?: string;
  EmployeeCount?: number;
  LastFinalized?: Date;
  LastFinalizedFullName?: string;
  NextDeadline?: Date;
  HasBatchesForApproval: boolean;
}

export interface IAccountCompanyCreationRequest {
  VatRegistrationNumber?: string;
  SecondaryVatRegistrationNumber?: string;
  CompanyName?: string;
  PackageLevel: number;
  CopySalaryAndTimeEntryTypesFromCompanyId?: number;
  CopyTemplatesFromCompanyId?: number;
  DefaultRoleId?: number;
}

export interface IAccountCommentRequest {
  Comment?: string;
}

export interface IUserCompanyAccess {
  CompanyId: number;
  UserId: number;
  CompanyUserId?: number;
  FullName?: string;
  CompanyName?: string;
  RoleId: number;
  RoleName?: string;
  IsPaymentApprover: boolean;
}

export interface IApiUser {
  SignOnToken?: ISignOnToken;
  RefreshToken: string;
  Id: number;
  IdentityNumber?: string;
  HasBackendAccess: boolean;
  ModuleIds?: number[];
  MustChangePassword: boolean;
  DefaultCompanyId?: number;
  PreferredLanguageId: number;
  FullName?: string;
  AccountRoleId?: number;
  IsFirstLogin: boolean;
  ShowUpdateNotification: boolean;
  ShowUrgentNotification: boolean;
  IsGratisalSupportUser: boolean;
  IsAccountant: boolean;
  IsAccountAdmin: boolean;
  CurrentCompany?: ICompany;
  ManagerForUserEmploymentIds?: number[];
  Identity?: IIIdentity;
  CurrentCompanyId: number;
  AccountId?: number;
  CurrentCountryId: number;
  CurrentRole?: IRole;
  CurrentCompanyUser?: ICompanyUser;
  Modules?: IModule[];
  IsPaymentApprover: boolean;
  IsFemale: boolean;
}

export interface ISignOnToken {
  Expires: Date;
  Language?: ILanguage;
  Id: number;
  UserId: number;
  Token?: string;
  Created: Date;
  LastRefreshed: Date;
  LastPersisted: Date;
  Type: number;
  Username?: string;
  CompanyId: number;
  LanguageId: number;
  SessionLength?: number;
  CountryId: number;
  AccountId?: number;
}

export interface IIIdentity {
  Name?: string;
  AuthenticationType?: string;
  IsAuthenticated: boolean;
}

export interface IChangePasswordRequest {
  CurrentPassword?: string;
  NewPassword?: string;
}

export interface IValidatePasswordRequest {
  Password?: string;
}

export interface IResetPasswordRequest {
  IdentityNumber?: string;
  Email?: string;
}

export interface IBillingUsage {
  Id: number;
  AccountId?: number;
  ModuleId?: number;
  BillingDate?: Date;
  Units: number;
  AmountPerUnit: number;
  Amount: number;
  CompanyId?: number;
  SalaryBatchId?: number;
  SalaryStatementId?: number;
  PaymentId?: number;
  UserEmploymentId?: number;
  CompanyUserId?: number;
  Created: Date;
  PackageId?: number;
  BillingPrincipleId: number;
  Description?: string;
}

export interface ISimpleCompany {
  Id: number;
  Name?: string;
  VatRegistrationNumber?: string;
  RoleId: number;
  AccountId?: number;
}

export interface ICompanyCreationRequest {
  VatRegistrationNumber?: string;
  SecondaryVatRegistrationNumber?: string;
  CompanyName?: string;
  ResponsibleUserUsername?: string;
  ResponsibleUserIdentityNumber?: string;
  ResponsibleUserFullName?: string;
  ResponsibleUserEmail?: string;
  PhoneNumber?: string;
  LanguageId: number;
  KnowsGratisalFrom?: string;
  NewUserPassword?: string;
  SubscribeToNewsLetter: boolean;
  AcceptStartupOffer: boolean;
  PackageLevel: number;
  AccountId?: number;
  NewAccountName?: string;
  AccountTypeId?: number;
  RequestingUserId?: number;
}

export interface ICvrCompany {
  VAT?: string;
  Name?: string;
  Address?: string;
  Zipcode?: string;
  City?: string;
  protected?: boolean;
  Phone?: string;
  Email?: string;
  Fax?: string;
  Startdate?: string;
  Enddate?: string;
  Employees?: string;
  Addressco?: string;
  Industrycode?: number;
  Industrydesc?: string;
  Companycode?: number;
  Companydesc?: string;
  Creditstartdate?: string;
  Creditstatus?: number;
  Creditbankrupt?: boolean;
  Owners?: IApiOwners[];
  Productionunits?: any[];
  T?: number;
  Version?: number;
  ExistsInGratisal: boolean;
}

export interface IApiOwners {
  Name?: string;
}

export interface IAcceptTermsRequest {
  Password?: string;
}

export interface IAddUserToCompanyRequest {
  IdentityNumber?: string;
  LanguageId?: number;
  Details?: ICompanyUser;
  EmploymentTemplateId?: number;
  HireDate?: Date;
  Title?: string;
  ExternalReference?: string;
  DepartmentId?: number;
  ExpireDate?: Date;
  PensionProviderId?: number;
  PensionEmployeeSharePercentage?: number;
  PensionEmployeeFixedAmount?: number;
  PensionCompanySharePercentage?: number;
  PensionCompanyFixedAmount?: number;
  PensionPolicyNumber?: string;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  ManagerCompanyUserId?: number;
  SeniorityDate?: Date;
  IsAccountUser: boolean;
  TaxCardTypeId?: number;
  IncomeTypeId?: number;
  TaxRate?: number;
  MonthlyTaxDeduction?: number;
  TaxCardFreeAmount?: number;
  ApplyDanishRulesInGreenland: boolean;
}

export interface IUserCreationData {
  User?: IUser;
  CompanyUser?: ICompanyUser;
  Employment?: IUserEmployment;
  NewUserPassword?: string;
}

export interface ISimpleCompanyUser {
  Id: number;
  IsActive: boolean;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  FullName?: string;
  RoleId: number;
  UserId: number;
}

export interface IBatchOperationResponse_UserCreationData_AddUserToCompanyRequest {
  ProcessedItems?: IUserCreationData[];
  Errors?: any;
  HasErrors: boolean;
}

export interface IBatchOperationResponse_CompanyUser_CompanyUser {
  ProcessedItems?: ICompanyUser[];
  Errors?: any;
  HasErrors: boolean;
}

export interface IUserEmploymentView {
  IdentityNumberFormatted?: string;
  HasDummyIdentityNumber: boolean;
  IsFemale: boolean;
  UserEmploymentId: number;
  IdentityNumber?: string;
  CompanyUserId: number;
  CompanyId: number;
  RoleId: number;
  IsActive: boolean;
  FullName?: string;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  TelMobile?: string;
  TelFixed?: string;
  BankRegNo?: string;
  CompanyEmail?: string;
  PersonalEmail?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  IsPaymentApprover: boolean;
  Initials?: string;
  HireDate: Date;
  ExpireDate?: Date;
  VacationProviderId?: number;
  VacationExtraPercentage?: number;
  BasedOnTemplateId?: number;
  Title?: string;
  SalaryCycleId: number;
  ExternalReference?: string;
  PensionBaseCalculationMethodId: number;
  IsAtpTempWorker: boolean;
  DepartmentId?: number;
  IncomeTypeId: number;
  WorksElsewhere: boolean;
  PensionEmployeeShare?: number;
  PensionCompanyShare?: number;
  PensionProviderId?: number;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  PensionPolicyNumber?: string;
  PensionEmployeeAmount?: number;
  PensionCompanyAmount?: number;
  Pension2BaseCalculationMethodId: number;
  Pension2ProviderId?: number;
  Pension2ProviderBankRegNo?: string;
  Pension2ProviderBankAccountNo?: string;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeAmount?: number;
  Pension2CompanyAmount?: number;
  VacationTypeId: number;
  LastStatementEndDate?: Date;
  VacationReduceSalary: boolean;
  IsTerminated: boolean;
  VacationSupplementPayoutMonth: number;
  AddressId: number;
  AddressLine1?: string;
  AddressLine2?: string;
  PostalCode?: string;
  City?: string;
  CountryId?: number;
  TaxCardRequested?: Date;
  UserId: number;
  Username?: string;
  DefaultCompanyId?: number;
  LanguageId: number;
  CompanyName?: string;
  IllnessReducesSalary: boolean;
  EmploymentCreated: Date;
  CompanyUserCreated: Date;
  UserCreated: Date;
  DepartmentName?: string;
  DepartmentExternalReference?: string;
  DateOfBirth?: Date;
  IsPendingRecalc: boolean;
  ManagerCompanyUserId?: number;
  ManagerName?: string;
  LanguageCode?: string;
  BasedOnTemplateName?: string;
  CountryCode?: string;
  PreferredTaxCardTypeId: number;
  SeniorityDate?: Date;
  AutoDetermineBankAccount: boolean;
}

export interface IBatchOperationResponse_UserEmployment_UserEmployment {
  ProcessedItems?: IUserEmployment[];
  Errors?: any;
  HasErrors: boolean;
}

export interface IUserEmploymentTemplate {
  VacationType?: IVacationType;
  VacationProvider?: IVacationProvider;
  PensionProvider?: IPensionProvider;
  Pension2Provider?: IPensionProvider;
  PensionBaseCalculationMethod?: IPensionBaseCalculationMethod;
  Pension2BaseCalculationMethod?: IPensionBaseCalculationMethod;
  SalaryCycle?: ISalaryCycle;
  Id: number;
  CompanyId: number;
  Name?: string;
  VacationTypeId: number;
  VacationProviderId?: number;
  VacationExtraPercentage?: number;
  PensionBaseCalculationMethodId: number;
  IsAtpTempWorker: boolean;
  WorksElsewhere: boolean;
  PensionEmployeeShare?: number;
  PensionCompanyShare?: number;
  PensionProviderId?: number;
  PensionEmployeeAmount?: number;
  PensionCompanyAmount?: number;
  Pension2BaseCalculationMethodId: number;
  Pension2ProviderId?: number;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeAmount?: number;
  Pension2CompanyAmount?: number;
  IncomeTypeId: number;
  SalaryCycleId: number;
  VacationReduceSalary: boolean;
  VacationSupplementPayoutMonth: number;
  IsInMaternityProgram: boolean;
  FFSeniorityRequirementMonths: number;
  FFHoursEarnedPerYear: number;
  FFAutoPayout: boolean;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  Pension2ProviderBankRegNo?: string;
  Pension2ProviderBankAccountNo?: string;
  IllnessReducesSalary: boolean;
  StandardHoursPerWeek?: number;
  StatisticsSalaryPrincipleId?: number;
  StatisticsEmploymentPrincipleId?: number;
  StatisticsEmploymentTermsId?: number;
  StatisticsJobStatusId?: number;
  StatisticsJobFunctionCode?: number;
  StandardWorkDaysPerWeek?: number;
  DepartmentId?: number;
  FlexEarningPercentage: number;
  FlexBaseCalculationMethodId: number;
  IsDefault: boolean;
  PensionPolicyNumber?: string;
  Pension2PolicyNumber?: string;
  CollectiveBargainingAgreementCode?: string;
  TerminationVacationOverridePercentage?: number;
  SystemDefaultForCountryId?: number;
  TimeEntryRecordAcrossSalaryPeriodStrategy: number;
  AllowExtendedTaxCardDeductions: boolean;
  TemplateExternalReference?: string;
  UseTaxDeductionPerWorkDay: boolean;
  TemplateSalaryRecords?: ITemplateSalaryRecord[];
  SenioritySupplements?: ISenioritySupplement[];
  OvertimeSupplements?: IOvertimeSupplement[];
}

export interface ITemplateSalaryRecord {
  UnitType?: IUnitType;
  PersistenceType?: ISalaryRecordPersistenceType;
  Id: number;
  EmploymentTemplateId: number;
  Units?: number;
  IsActive: boolean;
  Description?: string;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Amount?: number;
  BaseAmount?: number;
  SalaryTypeId: number;
  PersistenceTypeId: number;
  SortOrder: number;
}

export interface ISenioritySupplement {
  Id: number;
  EmploymentTemplateId: number;
  SeniorityMonthsStart?: number;
  SeniorityMonthsEnd?: number;
  SupplementAmount: number;
  SupplementIntervalType: number;
}

export interface IOvertimeSupplement {
  Id: number;
  EmploymentTemplateId: number;
  TotalHoursWorkedFrom?: number;
  TotalHoursWorkedUntil?: number;
  SupplementAmount?: number;
  SupplementPercentage?: number;
  OvertimeSupplementPrinciple: number;
}

export interface ITemplateCreationRequest {
  Name?: string;
  BasedOnEmploymentId: number;
}

export interface IDynamicSalaryRecordImportColumn {
  IsId: boolean;
  Name?: string;
  Value?: string;
}

export interface ISimpleImportMapping {
  Mapping?: string;
  Operation?: string;
  StartRow: number;
  Name?: string;
}

export interface IImportMapping {
  Id: number;
  OperationId: number;
  CompanyId?: number;
  Name?: string;
  StartRow: number;
  ColumnMapping?: string;
}

export interface ISimpleImportResponse {
  Outcome?: string;
  Errors?: IImportErrorMessage[];
  FatalMessage?: string;
}

export interface IImportErrorMessage {
  Error: boolean;
  Message?: string;
}

export interface ISimpleImportRequest {
  MappingId?: number;
  Mapping?: ISimpleImportMapping;
  Options?: ISimpleKeyValuePair[];
}

export interface ISimpleKeyValuePair {
  Key?: string;
  Value?: string;
}

export interface IStringDataWrapper {
  Data?: string[];
}

export interface IDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface INameObject {
  DisplayName?: string;
  Value?: string;
}

export interface IFeedback {
  Message?: string;
}

export interface IStatisticsDiscoCode {
  Id: number;
  Code: number;
  Name?: string;
}

export interface IModuleCompanyView {
  CompanyId: number;
  CompanyName?: string;
  ModuleId: number;
  PackageId?: number;
  ModuleName?: string;
  ModuleDescription?: string;
  PackageName?: string;
  PackageDescription?: string;
  ModulePrice?: number;
  ModuleBillingPrinciple?: string;
  ModuleBillingPrincipleId?: number;
  PackagePrice?: number;
  PackageBillingPrinciple?: string;
  PackageBillingPrincipleId?: number;
  IsEnabled?: boolean;
  LanguageId: number;
  InfoOnly: boolean;
  FreeUntilDate?: Date;
  ScheduledDeactivationDate?: Date;
  CompanyCurrentPackageLevel: number;
  SortKey?: string;
  ModuleKey?: string;
  PackageKey?: string;
}

export interface IReport {
  AreaId: number;
  Parameters?: string;
  SupportedFormats?: string;
  ExcludeColumns?: string[];
  ExcludeColumnsPdf?: string[];
  PortraitOrientation: boolean;
  HaveSums: boolean;
  RequiredRoleId: number;
  SpecificCountryId?: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IReportRequest {
  ReportId: number;
  ParameterValues?: IReportParameterValue[];
  OutputFormat?: string;
}

export interface IReportParameterValue {
  Key?: string;
  Value?: string;
}

export interface ISalaryBatchView {
  Id: number;
  CompanyId: number;
  SalaryPeriodId: number;
  Created: Date;
  StatusId: number;
  Approved?: Date;
  RejectionReason?: string;
  Rejected?: Date;
  EIncomeTransferDate?: Date;
  Message?: string;
  PayoutDate: Date;
  ApprovedCompanyUserId?: number;
  RejectedCompanyUserId?: number;
  CreatedCompanyUserId?: number;
  HasErrors: boolean;
  HasWarnings: boolean;
  NetsPaymentsProcessingDate?: Date;
  CreatedByCompanyUserFullName?: string;
  ApprovedByCompanyUserFullName?: string;
  RejectedByCompanyUserFullName?: string;
  PeriodStartDate: Date;
  PeriodEndDate: Date;
  SalaryCycleId: number;
  LanguageId: number;
  StatusName?: string;
  SalaryBatchNumber: number;
  IsTermination: boolean;
  EIncomeZeroReport: boolean;
  PaymentMethodSalary: number;
  PaymentMethodTax: number;
  PaymentMethodPension: number;
  EIncomeReruns: number;
  PayslipsArchived: boolean;
  LimitToSpecificEmployees: boolean;
  Description?: string;
  PayoutAllFlex: boolean;
  ValidationStatusCode?: string;
  FinalizedByCompanyUserFullName?: string;
}

export interface ISalaryBatchRequest {
  SalaryCycleId?: number;
  SalaryPeriodId?: number;
  UserEmploymentIds?: number[];
  Preview: boolean;
  EIncomeZeroReport: boolean;
  PayoutDate?: Date;
  Message?: string;
  PayoutAllFlex: boolean;
}

export interface ISalaryBatch {
  Status?: ISalaryBatchStatus;
  IsDraft: boolean;
  IsLocked: boolean;
  IsFinalized: boolean;
  Id: number;
  CompanyId: number;
  SalaryPeriodId: number;
  Created: Date;
  StatusId: number;
  Approved?: Date;
  RejectionReason?: string;
  Rejected?: Date;
  EIncomeTransferDate?: Date;
  Message?: string;
  PayoutDate: Date;
  ApprovedCompanyUserId?: number;
  RejectedCompanyUserId?: number;
  CreatedCompanyUserId?: number;
  HasErrors: boolean;
  HasWarnings: boolean;
  NetsPaymentsProcessingDate?: Date;
  IsTermination: boolean;
  EIncomeZeroReport: boolean;
  SalaryBatchNumber: number;
  PaymentMethodSalary: number;
  PaymentMethodTax: number;
  PaymentMethodPension: number;
  EIncomeReruns: number;
  PayslipsArchived: boolean;
  LimitToSpecificEmployees: boolean;
  EBoksDeliveryEnabled: boolean;
  PayoutAllFlex: boolean;
  SpecifiedEmployees?: string;
  HasNemKontoPayments: boolean;
  PaymentMethodPublicPension: number;
  PaymentMethodVacationProvider: number;
  ToArchive: boolean;
  Finalized?: Date;
  FinalizedCompanyUserId?: number;
  AllowPreliminaryPayslips: boolean;
  PackageLevelWhenFinalized?: number;
  IsAutomated: boolean;
  SalaryStatements?: ISalaryStatement[];
  SalaryPeriod?: ISalaryPeriod;
  SalaryBatchRecords?: ISalaryBatchRecord[];
}

export interface ISalaryBatchStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ISalaryStatement {
  PayoutDateDerived?: Date;
  IsFinalized: boolean;
  Description?: string;
  Id: number;
  SalaryBatchId: number;
  PeriodFrom: Date;
  PeriodTo: Date;
  PayoutAmount?: number;
  AtpEmployee: number;
  AtpEmployer: number;
  UserEmploymentId: number;
  TaxAmount: number;
  AmbAmount: number;
  PensionProviderId?: number;
  PensionEmployeeShare?: number;
  PensionEmployeeShareAmount: number;
  PensionCompanyShare?: number;
  PensionCompanyShareAmount: number;
  VacationProviderId?: number;
  VacationMoneyEarnedGross: number;
  VacationMoneyEarnedNet?: number;
  VacationDaysEarned: number;
  UserFullName?: string;
  UserIdentityNumber?: string;
  UserAddressId?: number;
  PensionBaseAmount: number;
  Pension2BaseAmount: number;
  Pension2ProviderId?: number;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeShareAmount: number;
  Pension2CompanyShareAmount: number;
  VacationDaysSpent: number;
  VacationBaseAmount: number;
  PrimaryIncomeAmount: number;
  HoursWorked: number;
  KmEntered: number;
  AppliedTaxDeduction: number;
  TaxCardRemainingFreeAmount?: number;
  TaxCardTypeId: number;
  TaxRate: number;
  VacationDaysSubtracted: number;
  TaxBaseAmount: number;
  AmbBaseAmount: number;
  VacationTaxDeduction?: number;
  TaxCardFreeAmountReduction?: number;
  ActualFromDate: Date;
  ActualEndDate: Date;
  IsTermination: boolean;
  TerminationTotalVacationMoneyGross?: number;
  TerminationTotalVacationMoneyNet?: number;
  TerminationYearBeforeLastVacationMoneyGross?: number;
  TerminationYearBeforeLastVacationMoneyNet?: number;
  TerminationYearBeforeLastVacationRemainingDays?: number;
  TerminationLastYearVacationMoneyGross?: number;
  TerminationLastYearVacationMoneyNet?: number;
  TerminationLastYearVacationRemainingDays?: number;
  TerminationCurrentYearVacationMoneyGross?: number;
  TerminationCurrentYearVacationMoneyNet?: number;
  TerminationCurrentYearVacationRemainingDays?: number;
  VacationTaxReduction: number;
  VacationAmbAmount: number;
  FFHoursEarned: number;
  FFHoursSpent: number;
  RecipientBankRegNo?: string;
  RecipientBankAccountNo?: string;
  RecipientBicSwiftNo?: string;
  RecipientIbanNo?: string;
  IllnessCompensationAtpAmountEmployee: number;
  DepartmentId?: number;
  OvertimeHours: number;
  IllnessHours: number;
  TaxableIncome: number;
  VacationSupplementEarned?: number;
  SummaryAmountSalary?: number;
  SummaryAmountSupplements?: number;
  SummaryAmountTaxes?: number;
  SummaryAmountAdjustments?: number;
  FlexAmountEarned: number;
  FlexAmountReduced: number;
  SummaryAmountPension?: number;
  VacationAmbBaseAmount: number;
  TaxFreeIncome?: number;
  TravelCompensation: number;
  PensionCompanyTaxAmount?: number;
  PensionEmployeeTaxAmount?: number;
  ReportableNonTaxedIncome?: number;
  DownloadToken?: string;
  DownloadTokenExpiryTime?: Date;
  VacationPayoutEarnedLastYear?: number;
  VacationPayoutEarnedCurrentYear?: number;
  SalaryBatch?: ISalaryBatch;
  SalaryBatchRecords?: ISalaryBatchRecord[];
  UserEmployment?: IUserEmployment;
}

export interface ISalaryPeriod {
  SalaryCycle?: ISalaryCycle;
  Id: number;
  StartDate: Date;
  EndDate: Date;
  SalaryCycleId: number;
  SuggestedPayoutDate?: Date;
  FriendlyName?: string;
}

export interface ISalaryBatchRecord {
  Id: number;
  SalaryTypeId: number;
  Amount: number;
  Units?: number;
  Description?: string;
  CompanyUserId: number;
  UnitTypeId?: number;
  UserEmploymentId: number;
  SalaryRecordId?: number;
  SalaryBatchId: number;
  AmountPerUnit?: number;
  BaseAmount?: number;
  SalaryStatementId?: number;
  IncludeInPayment: boolean;
  EIncomeCategory: number;
  EIncomeSubCategory: number;
  SummaryCategoryId?: number;
  PeriodFactor?: number;
  FromDate?: Date;
  ToDate?: Date;
  SortOrder: number;
  IsPrimaryIncome: boolean;
  TriggersAMB: boolean;
  TriggersIncomeTax: boolean;
  TriggersATP: boolean;
  EarnsHolidayCompensation: boolean;
  SpecialIdentifier?: string;
  IsInternal: boolean;
  BaseSalaryTypeId?: number;
  ExternalReferenceSalaryType?: string;
  ExternalReferenceEmployee?: string;
  ExternalReferenceDepartment?: string;
  InformationOnly: boolean;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  SulinalFieldNumber?: string;
  IsReportableIncome: boolean;
}

export interface IEIncomeResponseLine {
  Id: number;
  SalaryBatchId: number;
  IsError: boolean;
  RefLineNo: number;
  RefFieldNo: number;
  ResponseCode: number;
  ResponseText?: string;
  CprOrSeNo?: string;
  ReceivedOn: Date;
  ExportBatchIdentifier?: string;
  RerunNumber: number;
  SalaryBatch?: ISalaryBatch;
}

export interface ISalaryBatchValidationIssue {
  Id: number;
  SalaryBatchId: number;
  UserEmploymentId?: number;
  IsError: boolean;
  Description?: string;
  EmployeeName?: string;
  MessageKey?: string;
  MessageParameters?: string;
}

export interface ISalaryBatchTotalsApiView {
  SalaryBatchId: number;
  SalaryTypeId: number;
  AmountSum?: number;
  LanguageId: number;
  SortOrder: number;
  Description?: string;
  SalaryBatchNumber: number;
}

export interface ISalaryTypeSumsByEmploymentView {
  LanguageId: number;
  SalaryBatchId: number;
  SalaryTypeId: number;
  SalaryTypeName?: string;
  UserEmploymentId: number;
  FullName?: string;
  AmountSum?: number;
}

export interface ISalaryBatchUnitTotal {
  Name?: string;
  Key?: string;
  Amount: number;
}

export interface ISalaryBatchEmployeeTotal {
  UserEmploymentId: number;
  FullName?: string;
  AmountSum: number;
}

export interface ISalaryBatchApprovalRequest {
  Password?: string;
  DoRecalc: boolean;
}

export interface ISalaryBatchApprovalResponse {
  SalaryBatch?: ISalaryBatch;
  Message?: string;
}

export interface ISalaryBatchRejectionRequest {
  Reason?: string;
}

export interface IExternalAccountingRecord {
  Amount: number;
  AccountNumber?: string;
  EntryDate: Date;
  Description?: string;
  SalaryTypeId: number;
  SalaryTypeName?: string;
  DepartmentName?: string;
  DepartmentExternalReference?: string;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  EmployeeName?: string;
  EmployeeExternalReference?: string;
}

export interface ISalaryRecordView {
  Id: number;
  CompanyUserId: number;
  CompanyId: number;
  SalaryTypeId: number;
  AmountPerUnit?: number;
  Units?: number;
  IsActive: boolean;
  StartDate?: Date;
  EndDate?: Date;
  Description?: string;
  UserEmploymentId: number;
  UnitTypeId?: number;
  BaseAmount?: number;
  Amount?: number;
  SortOrder: number;
  PersistenceTypeId: number;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
}

export interface IBatchOperationResponse_SalaryRecord_SalaryRecord {
  ProcessedItems?: ISalaryRecord[];
  Errors?: any;
  HasErrors: boolean;
}

export interface ISimpleSalaryRecordUpdateRequest {
  Id: number;
  Amount: number;
  Units?: number;
  AmountPerUnit?: number;
}

export interface ISimpleSalaryRecordCreationRequest {
  UserEmploymentId: number;
  SalaryTypeId: number;
  Amount?: number;
  Units?: number;
  AmountPerUnit?: number;
}

export interface IGetSalaryRecordsOverview_Result {
  UserEmploymentId: number;
  FullName?: string;
  DepartmentId?: number;
  DepartmentName?: string;
  SalaryCycleId: number;
  SalaryRecord1Id?: number;
  MultipleRecordsExist1?: boolean;
  SalaryRecord1Units?: number;
  SalaryRecord1PerUnit?: number;
  SalaryRecord1Amount?: number;
  SalaryRecord2Id?: number;
  MultipleRecordsExist2?: boolean;
  SalaryRecord2Units?: number;
  SalaryRecord2PerUnit?: number;
  SalaryRecord2Amount?: number;
  SalaryRecord3Id?: number;
  MultipleRecordsExist3?: boolean;
  SalaryRecord3Units?: number;
  SalaryRecord3PerUnit?: number;
  SalaryRecord3Amount?: number;
  SalaryRecord4Id?: number;
  MultipleRecordsExist4?: boolean;
  SalaryRecord4Units?: number;
  SalaryRecord4PerUnit?: number;
  SalaryRecord4Amount?: number;
  SalaryRecord5Id?: number;
  MultipleRecordsExist5?: boolean;
  SalaryRecord5Units?: number;
  SalaryRecord5PerUnit?: number;
  SalaryRecord5Amount?: number;
  SalaryRecord6Id?: number;
  MultipleRecordsExist6?: boolean;
  SalaryRecord6Units?: number;
  SalaryRecord6PerUnit?: number;
  SalaryRecord6Amount?: number;
  SalaryRecord7Id?: number;
  MultipleRecordsExist7?: boolean;
  SalaryRecord7Units?: number;
  SalaryRecord7PerUnit?: number;
  SalaryRecord7Amount?: number;
}

export interface ISalaryRecordsOverviewFilterRequest {
  EmploymentTemplateId?: number;
  SalaryCycleId?: number;
  DepartmentId?: number;
  IncludeEmployeesWithNoData: boolean;
}

export interface ISimpleSalaryStatement {
  Id: number;
  UserEmploymentId: number;
  UserFullName?: string;
  PayoutAmount: number;
}

export interface ISimpleSalaryBatchRecord {
  Id: number;
  SalaryTypeId: number;
  Description?: string;
  Amount: number;
}

export interface ISalaryType {
  SummaryCategory?: ISalarySummaryCategory;
  Id: number;
  CompanyId?: number;
  CategoryId?: number;
  Name?: string;
  Description?: string;
  IsVisible?: boolean;
  IsActive?: boolean;
  SalaryTypeGroupId?: number;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Units?: number;
  BaseAmount?: number;
  Amount?: number;
  ExternalReference?: string;
  BaseSalaryTypeId?: number;
  EIncomeCategory?: number;
  EIncomeSubCategory?: number;
  SummaryCategoryId?: number;
  SortOrder?: number;
  DefaultPayslipText?: string;
  AllowEditUnitType?: boolean;
  AllowEditBaseAmount?: boolean;
  NegativeDefault?: boolean;
  DefaultPersistanceTypeId?: number;
  SumFromIds?: string;
  SumFromReverseSign?: boolean;
  IncludeAlways?: boolean;
  StatisticsOnly?: boolean;
  StatisticsFieldCode?: string;
  RelatesToIncomeType5?: boolean;
  Explanation?: string;
  AccountTypeId?: number;
  Identifier?: string;
  TriggersOvertimeSupplement?: boolean;
  SulinalFieldNumber?: string;
  IsAdvanced: boolean;
  OverviewIndex?: number;
  OverviewDisplayMode?: number;
  InternalReferenceId?: number;
  SalaryTypeTranslations?: ISalaryTypeTranslation[];
  BaseSalaryType?: ISalaryType;
}

export interface ISalarySummaryCategory {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ISalaryTypeTranslation {
  Id: number;
  SalaryTypeId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  DefaultPayslipText?: string;
  StatusId: number;
  Comment?: string;
  Explanation?: string;
}

export interface ISalaryTypeView {
  SalaryTypeId: number;
  LanguageId: number;
  CompanyId: number;
  CategoryId?: number;
  Name?: string;
  Description?: string;
  IsVisible: boolean;
  IsActive: boolean;
  SalaryTypeGroupId?: number;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Units?: number;
  BaseAmount?: number;
  Amount?: number;
  ExternalReference?: string;
  EIncomeCategory: number;
  EIncomeSubCategory: number;
  SummaryCategoryId?: number;
  BaseSalaryTypeId?: number;
  IsPrimaryIncome: boolean;
  TriggersAMB: boolean;
  IncludeInPayment: boolean;
  TriggersIncomeTax: boolean;
  TriggersATP: boolean;
  EarnsHolidayCompensation: boolean;
  SortOrder: number;
  DefaultPayslipText?: string;
  IsInternal: boolean;
  AllowEditUnitType: boolean;
  AllowEditBaseAmount: boolean;
  NegativeDefault: boolean;
  DefaultPersistanceTypeId?: number;
  SumFromIds?: string;
  SumFromReverseSign: boolean;
  IncludeAlways: boolean;
  StatisticsOnly: boolean;
  StatisticsFieldCode?: string;
  RelatesToIncomeType5?: boolean;
  Explanation?: string;
  Identifier?: string;
  TriggersOvertimeSupplement?: boolean;
  SulinalFieldNumber?: string;
  OverviewIndex?: number;
  OverviewDisplayMode?: number;
  IsAdvanced?: boolean;
  AccountTypeId?: number;
}

export interface ISalaryTypeCategoryView {
  StaticDataId: number;
  Id: number;
  LanguageId: number;
  Key?: string;
  Name?: string;
  Description?: string;
  IsPrimaryIncome: boolean;
  TriggersAMB: boolean;
  IncludeInPayment: boolean;
  TriggersIncomeTax: boolean;
  TriggersATP: boolean;
  EarnsHolidayCompensation: boolean;
  SortKey?: string;
}

export interface IInheritSalaryTypeRequest {
  BaseSalaryTypeId: number;
  Name?: string;
}

export interface ISimpleSalaryTypeUpdateRequest {
  Id: number;
  ExternalReference?: string;
}

export interface ISalaryTypeGroup {
  Id: number;
  Name?: string;
  CompanyId: number;
  ExternalReference?: string;
}

export interface IStartupTaskCompanyView {
  Id: number;
  TaskId: number;
  Key?: string;
  SortOrder: number;
  CompanyId: number;
  CompanyName?: string;
  AppUri?: string;
  IsSkippable: boolean;
  LanguageId: number;
  Name?: string;
  Description?: string;
  GuideMessage?: string;
  ExternalLink?: string;
  SkipWarning?: string;
  StatusId: number;
  HelpLink?: string;
}

export interface IStaticData {
  Id: number;
  TypeKey?: string;
  ValueKey?: string;
  ValueId: number;
  Name?: string;
  Description?: string;
  Int1?: number;
  Int2?: number;
  Text1?: string;
  Text2?: string;
  Date1?: Date;
  Date2?: Date;
  Bool1: boolean;
  Bool2: boolean;
  Decimal1?: number;
  Decimal2?: number;
  Long1?: number;
  Long2?: number;
  SortKey?: string;
  Text3?: string;
  Text4?: string;
  Text5?: string;
  StaticDataTranslations?: IStaticDataTranslation[];
}

export interface IStaticDataTranslation {
  Id: number;
  StaticDataId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  Text1?: string;
  Text2?: string;
  StatusId: number;
  Comment?: string;
  Text3?: string;
  Text4?: string;
  Text5?: string;
  Language?: ILanguage;
  StaticData?: IStaticData;
}

export interface IStaticDataEntity {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IBank {
  PaymentExportFormatId: number;
  CountryId: number;
  PaymentExportFormat?: IPaymentExportFormat;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IPaymentExportFormat {
  FileFormat?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IAccountRole {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IWorkBookDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IUnicontaDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IInventioItDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ITimeEntryStatusExtended {
  TimeEntryStatusString?: string;
  TimeEntryStatusIds?: number[];
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IWorkBookVersion {
  VersionNumber: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IExternalSystemConfiguration {
  FormElement?: string;
  IsRequired: boolean;
  ListDataSource?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ICompanyPreferenceCategory {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ISalaryRecordsOverviewDisplayMode {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IExternalSystemOperation {
  IsSingular: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IIntegrationStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IMunicipality {
  CountryId: number;
  DefaultTaxRate?: number;
  OfficialReference?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IFinanceAccountType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IEIncomeGroupByParameter {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IOvertimeSupplementPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ISalaryTypeTotalsMultipleBatchesGroupByParameter {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ICurrency {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ICity {
  CountryId: number;
  PostalCode: number;
  IsRange: boolean;
  PostalCodeTo?: number;
  MunicipalityId?: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IPaymentProvider {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IEmailTemplate {
  Subject?: string;
  PreferPersonalEmail: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IImportList {
  Operations?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IModulePackage {
  Price: number;
  BillingPrincipleId: number;
  BillingPrinciple?: IBillingPrinciple;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IImportSpecification {
  Operation?: string;
  OperationId: number;
  Columns?: string;
  DynamicColumns?: string;
  ImportOptions?: IImportOption[];
  DefaultSingleColumns: boolean;
  DefaultRequiredColumns: boolean;
  Relationships?: IColumnRelationship[];
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IImportOption {
  Type?: string;
  DisplayName?: string;
  Value?: string;
  DefaultKey?: string;
  Options?: ISimpleKeyValuePair[];
}

export interface IColumnRelationship {
  Units?: string;
  SetColumnsRequired: boolean;
  SetColumnsSingle: boolean;
  Min: number;
  Max: number;
  ViolationMessage?: string;
}

export interface IPreference {
  Default?: string;
  ListDataSource?: string;
  HelpUrl?: string;
  UiControlType?: string;
  CategoryId?: number;
  CountryIdList?: string;
  AppliesInAllCountries: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IUserPreference {
  MinimumRoleId?: number;
  Default?: string;
  ListDataSource?: string;
  HelpUrl?: string;
  UiControlType?: string;
  CategoryId?: number;
  CountryIdList?: string;
  AppliesInAllCountries: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ICompanyPreference {
  RequiredModuleId: number;
  RequiredModule?: IModule;
  Default?: string;
  ListDataSource?: string;
  HelpUrl?: string;
  UiControlType?: string;
  CategoryId?: number;
  CountryIdList?: string;
  AppliesInAllCountries: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IStatisticsRecipient {
  Code?: number;
  IsSupported: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ISignupReferral {
  AdditionalInfoRelevant: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IReportParameter {
  UiControlType?: string;
  ListDataSource?: string;
  DefaultValue?: string;
  IsRequired: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IStatisticsSalaryPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IStatisticsEmploymentPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IStatisticsEmploymentTerms {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IStatisticsJobStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IIntervalType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ITermExplanation {
  Term?: string;
  Translation?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ITimeEntryRecordAcrossSalaryPeriodStrategy {
  IsDefault: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ITimeEntryStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IMonth {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IBankHoliday {
  Date: Date;
  CountryId: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IExternalSystem {
  HasSalaryTypeMappings: boolean;
  IsActive: boolean;
  ConfigurationKeys?: string[];
  CredentialKeys?: string[];
  SupportUrl?: string;
  OperationsFlag: number;
  Operations?: IExternalSystemOperation[];
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IPaymentMethod {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IPaymentStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IReportArea {
  AppUri?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IAtpRate {
  SalaryCycleId: number;
  MinHours: number;
  MaxHours: number;
  EmployeeAmount: number;
  CompanyAmount: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IDataType {
  IsNumeric: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IAtpAgreement {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IIncomeType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ISalaryTypeCategory {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface IVacationTypeGL {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export interface ITestClassWithHtmlSanitization {
  SanitizedProperty?: string;
  UnsanitizedProperty?: string;
}

export interface ITimeEntryRecord {
  UnitType?: IUnitType;
  Status?: ITimeEntryStatus;
  EmployeeName?: string;
  Title?: string;
  DepartmentName?: string;
  IsCalculated: boolean;
  Id: number;
  UserEmploymentId: number;
  EntryDate: Date;
  TimeEntryTypeId: number;
  Units: number;
  UnitTypeId: number;
  Description?: string;
  SalaryPeriodId: number;
  StatusId: number;
  ApprovedByCompanyUserId?: number;
  RejectedByCompanyUserId?: number;
  Created: Date;
  RejectionReason?: string;
  SalaryRecordId?: number;
  SalaryStatementId?: number;
  KilometersDriven?: number;
  StartTime?: string;
  EndTime?: string;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  EndDate?: Date;
  CalculatedFromRecordId?: number;
  ExternalIdentifier?: string;
  CalculatedPayout: number;
  SalaryPeriod?: ISalaryPeriod;
  TimeEntryType?: ITimeEntryType;
  CalculatedChildRecords?: ITimeEntryRecord[];
  CalculatedFromParentRecord?: ITimeEntryRecord;
}

export interface IRejectionInfo {
  Reason?: string;
}

export interface ITimeEntryTypeView {
  IsVacation: boolean;
  TimeEntryTypeId: number;
  BaseTimeEntryTypeId?: number;
  CompanyId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  DefaultPayslipText?: string;
  IsActive: boolean;
  UnitTypeId?: number;
  ExternalReference?: string;
  SortOrder: number;
  AllowEditUnitType: boolean;
  IsIllness: boolean;
  SalaryTypeId?: number;
  SalaryTypeName?: string;
  Identifier?: string;
}

export interface IInheritTimeEntryTypeRequest {
  BaseTimeEntryTypeId: number;
  Name?: string;
}

export interface IIdentityCheckResponse {
  ExistsInGratisal: boolean;
  BirthDate?: Date;
  IsWellFormed: boolean;
  RaiseChecksumWarning: boolean;
}

export interface IUsernameCheckResponse {
  ExistsInGratisal: boolean;
  IsWellFormed: boolean;
}

export interface ICompanyUserView {
  UserId: number;
  CompanyUserId: number;
  CompanyId: number;
  CompanyName?: string;
  StatusId: number;
  RoleId: number;
  IsActive: boolean;
  RoleKey?: string;
  IdentityNumber?: string;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  TelMobile?: string;
  TelFixed?: string;
  BankRegNo?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  IsPaymentApprover: boolean;
  Initials?: string;
  CompanyEmail?: string;
  PersonalEmail?: string;
  VATRegistrationNumber?: string;
  FullName?: string;
  UserAccountId?: number;
  CompanyAccountId?: number;
  AccountRoleId?: number;
}

export interface IIdentityNumberRequest {
  IdentityNumber?: string;
}

export class Account implements IAccount {
  Id: number;
  Name?: string;
  StatusId: number;
  AccountTypeId: number;
  Companies?: ICompany[];
  LoginHistories?: ILoginHistory[];
  AccountType?: IAccountType;
  AccountStatus?: IAccountStatus;
}

export class Company implements ICompany {
  Id: number;
  Name?: string;
  StatusId: number;
  VATRegistrationNumber?: string;
  AccountId?: number;
  Email1?: string;
  Email2?: string;
  DefaultLanguageId: number;
  AddressId: number;
  CompanyTypeId: number;
  SecondaryVATRegistrationNumber?: string;
  MainContactCompanyUserId?: number;
  BankRegNo?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  ExternalSystemComment?: string;
  PaymentMethodSalary: number;
  PaymentMethodPension: number;
  PaymentMethodTax: number;
  ExternalSystemId?: number;
  TelMobile?: string;
  TelFixed?: string;
  KnowsGratisalFrom?: string;
  ExternalAuthentication?: string;
  HasNetsAgreement: boolean;
  Created: Date;
  EmployersAssociationNumber?: string;
  StaticsReportsYearly: boolean;
  StatisticsRecipientId?: number;
  SubscribeToNewsLetter: boolean;
  EstablishedDate?: Date;
  EmployeeCountEstimate?: string;
  Preferences?: string;
  InvoiceIdentifier?: string;
  PaymentMethodPublicPension: number;
  PaymentMethodVacationProvider: number;
  InvoiceEmail?: string;
  HasAcceptedTerms: boolean;
  CountryId: number;
  CurrentPackageLevel: number;
  ScheduledPackageId?: number;
  ScheduledPackageDate?: Date;
  IndustryCode?: string;
  AccountComment?: string;
  BankId?: number;
  Language?: ILanguage;
  Address?: IAddress;
  CompanyLogo?: ICompanyLogo;
  Departments?: IDepartment[];
  ExternalAccounts?: IExternalAccount[];
  CompanyType?: ICompanyType;
  CompanyStatus?: ICompanyStatus;
  Country?: ICountry;
}

export class LoginHistory implements ILoginHistory {
  Id: number;
  LoginTime: Date;
  UserIp?: string;
  Country?: string;
  City?: string;
  Region?: string;
  PostalCode?: string;
  IsSuccessful: boolean;
  UserName?: string;
  UserId?: number;
  CompanyId?: number;
  UserAgent?: string;
  BrowserName?: string;
  BrowserVersion?: string;
  OSName?: string;
  OSVersion?: string;
  OperationType?: string;
  AccountId?: number;
  Company?: ICompany;
  User?: IUser;
  Account?: IAccount;
}

export class AccountType implements IAccountType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class AccountStatus implements IAccountStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class Language implements ILanguage {
  Id: number;
  Name?: string;
  Code?: string;
  IsSupported: boolean;
  CultureCode?: string;
}

export class Address implements IAddress {
  Id: number;
  Line1?: string;
  Line2?: string;
  PostalCode?: string;
  City?: string;
  CountryId?: number;
  Country?: ICountry;
}

export class CompanyLogo implements ICompanyLogo {
  Id: number;
  Logo?: string;
  Company?: ICompany;
}

export class Department implements IDepartment {
  Id: number;
  CompanyId: number;
  Name?: string;
  Description?: string;
  IsActive: boolean;
  EIncomeReference?: string;
  ExternalReference?: string;
  ManagerCompanyUserId?: number;
}

export class ExternalAccount implements IExternalAccount {
  Id: number;
  CompanyId: number;
  AccountIdentifier?: string;
  AccountNumber?: string;
  AccountName?: string;
  Description?: string;
  IntegrationId?: number;
  Company?: ICompany;
  Integration?: IIntegration;
}

export class CompanyType implements ICompanyType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class CompanyStatus implements ICompanyStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class Country implements ICountry {
  IsSupported: boolean;
  DefaultLanguageId: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class User implements IUser {
  FullName?: string;
  IdentityNumberFormatted?: string;
  HasDummyIdentityNumber: boolean;
  IsFemale: boolean;
  Id: number;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  IdentityNumber?: string;
  BankRegNo?: string;
  BankAccountNo?: string;
  Username?: string;
  LanguageId: number;
  BicSwiftNo?: string;
  IbanNo?: string;
  AddressId: number;
  AccessPhrase?: string;
  DateOfBirth?: Date;
  TelMobile?: string;
  TelFixed?: string;
  Email?: string;
  DefaultInitials?: string;
  DefaultCompanyId?: number;
  UseGlobalEmail: boolean;
  IsFirstLogin: boolean;
  Preferences?: string;
  Created: Date;
  ShowUpdateNotification: boolean;
  ShowUrgentNotification: boolean;
  ShowPayslipTutorial: number;
  AccountId?: number;
  AccountRoleId?: number;
  Address?: IAddress;
  CompanyUsers?: ICompanyUser[];
  UserImage?: IUserImage;
  LoginHistories?: ILoginHistory[];
}

export class Integration implements IIntegration {
  Id: number;
  CompanyId: number;
  ExternalSystemId: number;
  Name?: string;
  Created: Date;
  StatusCode: number;
  IsSalaryTypeMappingActive: boolean;
  IntegrationOperations?: IIntegrationOperation[];
  IntegrationConfigurations?: IIntegrationConfiguration[];
  IntegrationCredentials?: IIntegrationCredential[];
}

export class CompanyUser implements ICompanyUser {
  Id: number;
  UserId: number;
  CompanyId: number;
  RoleId: number;
  IsActive: boolean;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  BankRegNo?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  AddressId: number;
  IsPaymentApprover: boolean;
  Initials?: string;
  TelMobile?: string;
  TelFixed?: string;
  CompanyEmail?: string;
  PersonalEmail?: string;
  Created: Date;
  DeactivationDate?: Date;
  PreferredCurrencyId?: number;
  AutoDetermineBankAccount: boolean;
  MunicipalityId?: number;
  UserEmployments?: IUserEmployment[];
  Address?: IAddress;
  UserImage?: IUserImage;
  CompanyModules?: ICompanyModule[];
  FullName?: string;
  Role?: IRole;
  UserLanguageId: number;
  UserIdentityNumber?: string;
  IsFemale: boolean;
  UserDateOfBirth: Date;
  HasDummyIdentityNumber: boolean;
}

export class UserImage implements IUserImage {
  Id: number;
  Image?: string;
}

export class IntegrationOperation implements IIntegrationOperation {
  Id: number;
  IntegrationId: number;
  IsActive: boolean;
  LastRun?: Date;
  StatusCode: number;
  OperationId: number;
  TranslationKey?: string;
  TranslationParametersList?: string;
  Integration?: IIntegration;
  TranslatedStatusMessage?: string;
}

export class IntegrationConfiguration implements IIntegrationConfiguration {
  Id: number;
  IntegrationId: number;
  Key?: string;
  Value?: string;
  Integration?: IIntegration;
}

export class IntegrationCredential implements IIntegrationCredential {
  Id: number;
  IntegrationId: number;
  Key?: string;
  Value?: string;
  Integration?: IIntegration;
  ExternalSystemCredential?: IExternalSystemCredential;
  IsPrivate: boolean;
}

export class UserEmployment implements IUserEmployment {
  VacationType?: IVacationType;
  VacationProvider?: IVacationProvider;
  PensionProvider?: IPensionProvider;
  Pension2Provider?: IPensionProvider;
  PensionBaseCalculationMethod?: IPensionBaseCalculationMethod;
  Pension2BaseCalculationMethod?: IPensionBaseCalculationMethod;
  SalaryCycle?: ISalaryCycle;
  StandardHoursPerWorkDay: number;
  Id: number;
  CompanyUserId: number;
  HireDate: Date;
  ExpireDate?: Date;
  VacationTypeId: number;
  VacationProviderId?: number;
  VacationExtraPercentage?: number;
  BasedOnTemplateId?: number;
  Title?: string;
  SalaryCycleId: number;
  ExternalReference?: string;
  PensionBaseCalculationMethodId: number;
  IsAtpTempWorker: boolean;
  DepartmentId?: number;
  IncomeTypeId: number;
  WorksElsewhere: boolean;
  PensionEmployeeShare?: number;
  PensionCompanyShare?: number;
  PensionProviderId?: number;
  PensionPolicyNumber?: string;
  PensionEmployeeAmount?: number;
  PensionCompanyAmount?: number;
  Pension2BaseCalculationMethodId: number;
  Pension2ProviderId?: number;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeAmount?: number;
  Pension2CompanyAmount?: number;
  LastStatementEndDate?: Date;
  VacationReduceSalary: boolean;
  IsTerminated: boolean;
  VacationSupplementPayoutMonth: number;
  IsInMaternityProgram: boolean;
  FFSeniorityRequirementMonths: number;
  FFHoursEarnedPerYear: number;
  FFAutoPayout: boolean;
  TaxCardRequested?: Date;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  Pension2ProviderBankRegNo?: string;
  Pension2ProviderBankAccountNo?: string;
  Pension2PolicyNumber?: string;
  PreferredTaxCardTypeId: number;
  Created: Date;
  IllnessReducesSalary: boolean;
  ManagerCompanyUserId?: number;
  StandardHoursPerWeek?: number;
  StatisticsSalaryPrincipleId?: number;
  StatisticsEmploymentPrincipleId?: number;
  StatisticsEmploymentTermsId?: number;
  StatisticsJobStatusId?: number;
  StatisticsJobFunctionCode?: number;
  StatisticsSalaryPrincipleFromDate?: Date;
  StatisticsEmploymentPrincipleFromDate?: Date;
  StatisticsEmploymentTermsFromDate?: Date;
  StatisticsJobStatusFromDate?: Date;
  StandardWorkDaysPerWeek?: number;
  StatisticsJobFunctionFromDate?: Date;
  FlexEarningPercentage: number;
  FlexBaseCalculationMethodId: number;
  IsPendingRecalc: boolean;
  CollectiveBargainingAgreementCode?: string;
  TerminationVacationOverridePercentage?: number;
  SeniorityDate?: Date;
  ApplyDanishRulesInGreenland: boolean;
  AnnualTaxComment?: string;
  AllowExtendedTaxCardDeductions: boolean;
  ReduceTaxCardDeduction?: number;
  IncreaseTaxRatePercentage?: number;
  UseTaxDeductionPerWorkDay: boolean;
  CompanyUser?: ICompanyUser;
  UserTaxInfos?: IUserTaxInfo[];
  SalaryRecords?: ISalaryRecord[];
  TimeEntryTypeConfigurations?: ITimeEntryTypeConfiguration[];
}

export class CompanyModule implements ICompanyModule {
  Id: number;
  ModuleId: number;
  LastActivated: Date;
  ActivatedCompanyUserId?: number;
  CompanyId: number;
  ScheduledDeactivationDate?: Date;
  ActivatedByCompanyUser?: ICompanyUser;
  Module?: IModule;
}

export class Role implements IRole {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class ExternalSystemCredential implements IExternalSystemCredential {
  IsPrivate: boolean;
  BackendKey?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class VacationType implements IVacationType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class VacationProvider implements IVacationProvider {
  SENumber?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class PensionProvider implements IPensionProvider {
  NetsNo?: string;
  DefaultBaseCalculationMethodId: number;
  NetsInfoType: number;
  PolicyNumberRequired: boolean;
  AccountNumberRequired: boolean;
  PolicyNumberLabelOverride?: string;
  CountryId: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class PensionBaseCalculationMethod implements IPensionBaseCalculationMethod {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class SalaryCycle implements ISalaryCycle {
  Months: number;
  Days: number;
  MonthEnd: boolean;
  IsForwardPaid: boolean;
  TaxDeductionFactor: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class UserTaxInfo implements IUserTaxInfo {
  TaxCardType?: ITaxCardType;
  IsCurrent: boolean;
  Id: number;
  ValidFrom: Date;
  ValidUntil?: Date;
  TaxRate?: number;
  DeductionMonth?: number;
  FreeAmount?: number;
  UserEmploymentId: number;
  IsActive: boolean;
  TaxCardTypeId: number;
  IsDummy: boolean;
  MinimumRate?: number;
  MaximumDeductionMonth?: number;
  MaximumDeductionDay?: number;
  Created: Date;
  DeductionWeek?: number;
  DeductionDay?: number;
  IsManual: boolean;
  ParsedFromFileName?: string;
  TaxCardRequestId?: number;
}

export class SalaryRecord implements ISalaryRecord {
  UnitType?: IUnitType;
  PersistenceType?: ISalaryRecordPersistenceType;
  Id: number;
  Units?: number;
  IsActive: boolean;
  StartDate?: Date;
  EndDate?: Date;
  Description?: string;
  UserEmploymentId: number;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Amount?: number;
  BaseAmount?: number;
  SalaryTypeId: number;
  SortOrder: number;
  TimeEntryTypeId?: number;
  PersistenceTypeId: number;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  TimeEntryType?: ITimeEntryType;
}

export class TimeEntryTypeConfiguration implements ITimeEntryTypeConfiguration {
  Id: number;
  TimeEntryTypeId: number;
  SalaryTypeId: number;
  Description?: string;
  Amount?: number;
  AmountPerUnit?: number;
  AppliesBefore?: string;
  AppliesAfter?: string;
  AppliesWeekdays: boolean;
  AppliesHolidays: boolean;
  AmountPerKilometer?: number;
  EmploymentTemplateId?: number;
  TargetUnitTypeId?: number;
  ConversionFactor?: number;
  UserEmploymentId?: number;
  AppliesSaturdays: boolean;
  AppliesSundays: boolean;
}

export class Module implements IModule {
  Price: number;
  BillingPrincipleId: number;
  FreeUntilDate?: Date;
  InfoOnly: boolean;
  PackageLevel: number;
  BillingPrinciple?: IBillingPrinciple;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class TaxCardType implements ITaxCardType {
  EIncomeReference: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class UnitType implements IUnitType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class SalaryRecordPersistenceType implements ISalaryRecordPersistenceType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class TimeEntryType implements ITimeEntryType {
  Id: number;
  BaseTimeEntryTypeId?: number;
  CompanyId?: number;
  Name?: string;
  Description?: string;
  IsActive?: boolean;
  UnitTypeId?: number;
  ExternalReference?: string;
  SalaryTypeId?: number;
  SortOrder?: number;
  DefaultPayslipText?: string;
  AllowEditUnitType?: boolean;
  IsIllness?: boolean;
  InternalReferenceId?: number;
  Identifier?: string;
  BaseTimeEntryType?: ITimeEntryType;
  TimeEntryTypeTranslations?: ITimeEntryTypeTranslation[];
  TimeEntryTypeConfigurations?: ITimeEntryTypeConfiguration[];
}

export class BillingPrinciple implements IBillingPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class TimeEntryTypeTranslation implements ITimeEntryTypeTranslation {
  Id: number;
  TimeEntryTypeId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  DefaultPayslipText?: string;
  StatusId: number;
  Comment?: string;
}

export class CompanyAccountView implements ICompanyAccountView {
  Id: number;
  Name?: string;
  VATRegistrationNumber?: string;
  AccountId?: number;
  MainContactCompanyUserId?: number;
  MainContactFullName?: string;
  ContactEmail?: string;
  InvoiceEmail?: string;
  AccountComment?: string;
  CountryId: number;
  CountryCode?: string;
  EmployeeCount?: number;
  LastFinalized?: Date;
  LastFinalizedFullName?: string;
  NextDeadline?: Date;
  HasBatchesForApproval: boolean;
}

export class AccountCompanyCreationRequest implements IAccountCompanyCreationRequest {
  VatRegistrationNumber?: string;
  SecondaryVatRegistrationNumber?: string;
  CompanyName?: string;
  PackageLevel: number;
  CopySalaryAndTimeEntryTypesFromCompanyId?: number;
  CopyTemplatesFromCompanyId?: number;
  DefaultRoleId?: number;
}

export class AccountCommentRequest implements IAccountCommentRequest {
  Comment?: string;
}

export class UserCompanyAccess implements IUserCompanyAccess {
  CompanyId: number;
  UserId: number;
  CompanyUserId?: number;
  FullName?: string;
  CompanyName?: string;
  RoleId: number;
  RoleName?: string;
  IsPaymentApprover: boolean;
}

export class ApiUser implements IApiUser {
  SignOnToken?: ISignOnToken;
  RefreshToken: string;
  Id: number;
  IdentityNumber?: string;
  HasBackendAccess: boolean;
  ModuleIds?: number[];
  MustChangePassword: boolean;
  DefaultCompanyId?: number;
  PreferredLanguageId: number;
  FullName?: string;
  AccountRoleId?: number;
  IsFirstLogin: boolean;
  ShowUpdateNotification: boolean;
  ShowUrgentNotification: boolean;
  IsGratisalSupportUser: boolean;
  IsAccountant: boolean;
  IsAccountAdmin: boolean;
  CurrentCompany?: ICompany;
  ManagerForUserEmploymentIds?: number[];
  Identity?: IIIdentity;
  CurrentCompanyId: number;
  AccountId?: number;
  CurrentCountryId: number;
  CurrentRole?: IRole;
  CurrentCompanyUser?: ICompanyUser;
  Modules?: IModule[];
  IsPaymentApprover: boolean;
  IsFemale: boolean;
}

export class SignOnToken implements ISignOnToken {
  Expires: Date;
  Language?: ILanguage;
  Id: number;
  UserId: number;
  Token?: string;
  Created: Date;
  LastRefreshed: Date;
  LastPersisted: Date;
  Type: number;
  Username?: string;
  CompanyId: number;
  LanguageId: number;
  SessionLength?: number;
  CountryId: number;
  AccountId?: number;
}

export class IIdentity implements IIIdentity {
  Name?: string;
  AuthenticationType?: string;
  IsAuthenticated: boolean;
}

export class ChangePasswordRequest implements IChangePasswordRequest {
  CurrentPassword?: string;
  NewPassword?: string;
}

export class ValidatePasswordRequest implements IValidatePasswordRequest {
  Password?: string;
}

export class ResetPasswordRequest implements IResetPasswordRequest {
  IdentityNumber?: string;
  Email?: string;
}

export class BillingUsage implements IBillingUsage {
  Id: number;
  AccountId?: number;
  ModuleId?: number;
  BillingDate?: Date;
  Units: number;
  AmountPerUnit: number;
  Amount: number;
  CompanyId?: number;
  SalaryBatchId?: number;
  SalaryStatementId?: number;
  PaymentId?: number;
  UserEmploymentId?: number;
  CompanyUserId?: number;
  Created: Date;
  PackageId?: number;
  BillingPrincipleId: number;
  Description?: string;
}

export class SimpleCompany implements ISimpleCompany {
  Id: number;
  Name?: string;
  VatRegistrationNumber?: string;
  RoleId: number;
  AccountId?: number;
}

export class CompanyCreationRequest implements ICompanyCreationRequest {
  VatRegistrationNumber?: string;
  SecondaryVatRegistrationNumber?: string;
  CompanyName?: string;
  ResponsibleUserUsername?: string;
  ResponsibleUserIdentityNumber?: string;
  ResponsibleUserFullName?: string;
  ResponsibleUserEmail?: string;
  PhoneNumber?: string;
  LanguageId: number;
  KnowsGratisalFrom?: string;
  NewUserPassword?: string;
  SubscribeToNewsLetter: boolean;
  AcceptStartupOffer: boolean;
  PackageLevel: number;
  AccountId?: number;
  NewAccountName?: string;
  AccountTypeId?: number;
  RequestingUserId?: number;
}

export class CvrCompany implements ICvrCompany {
  VAT?: string;
  Name?: string;
  Address?: string;
  Zipcode?: string;
  City?: string;
  protected?: boolean;
  Phone?: string;
  Email?: string;
  Fax?: string;
  Startdate?: string;
  Enddate?: string;
  Employees?: string;
  Addressco?: string;
  Industrycode?: number;
  Industrydesc?: string;
  Companycode?: number;
  Companydesc?: string;
  Creditstartdate?: string;
  Creditstatus?: number;
  Creditbankrupt?: boolean;
  Owners?: IApiOwners[];
  Productionunits?: any[];
  T?: number;
  Version?: number;
  ExistsInGratisal: boolean;
}

export class ApiOwners implements IApiOwners {
  Name?: string;
}

export class AcceptTermsRequest implements IAcceptTermsRequest {
  Password?: string;
}

export class AddUserToCompanyRequest implements IAddUserToCompanyRequest {
  IdentityNumber?: string;
  LanguageId?: number;
  Details?: ICompanyUser;
  EmploymentTemplateId?: number;
  HireDate?: Date;
  Title?: string;
  ExternalReference?: string;
  DepartmentId?: number;
  ExpireDate?: Date;
  PensionProviderId?: number;
  PensionEmployeeSharePercentage?: number;
  PensionEmployeeFixedAmount?: number;
  PensionCompanySharePercentage?: number;
  PensionCompanyFixedAmount?: number;
  PensionPolicyNumber?: string;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  ManagerCompanyUserId?: number;
  SeniorityDate?: Date;
  IsAccountUser: boolean;
  TaxCardTypeId?: number;
  IncomeTypeId?: number;
  TaxRate?: number;
  MonthlyTaxDeduction?: number;
  TaxCardFreeAmount?: number;
  ApplyDanishRulesInGreenland: boolean;
}

export class UserCreationData implements IUserCreationData {
  User?: IUser;
  CompanyUser?: ICompanyUser;
  Employment?: IUserEmployment;
  NewUserPassword?: string;
}

export class SimpleCompanyUser implements ISimpleCompanyUser {
  Id: number;
  IsActive: boolean;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  FullName?: string;
  RoleId: number;
  UserId: number;
}

export class BatchOperationResponse_UserCreationData_AddUserToCompanyRequest
  implements IBatchOperationResponse_UserCreationData_AddUserToCompanyRequest {
  ProcessedItems?: IUserCreationData[];
  Errors?: any;
  HasErrors: boolean;
}

export class BatchOperationResponse_CompanyUser_CompanyUser implements IBatchOperationResponse_CompanyUser_CompanyUser {
  ProcessedItems?: ICompanyUser[];
  Errors?: any;
  HasErrors: boolean;
}

export class UserEmploymentView implements IUserEmploymentView {
  IdentityNumberFormatted?: string;
  HasDummyIdentityNumber: boolean;
  IsFemale: boolean;
  UserEmploymentId: number;
  IdentityNumber?: string;
  CompanyUserId: number;
  CompanyId: number;
  RoleId: number;
  IsActive: boolean;
  FullName?: string;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  TelMobile?: string;
  TelFixed?: string;
  BankRegNo?: string;
  CompanyEmail?: string;
  PersonalEmail?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  IsPaymentApprover: boolean;
  Initials?: string;
  HireDate: Date;
  ExpireDate?: Date;
  VacationProviderId?: number;
  VacationExtraPercentage?: number;
  BasedOnTemplateId?: number;
  Title?: string;
  SalaryCycleId: number;
  ExternalReference?: string;
  PensionBaseCalculationMethodId: number;
  IsAtpTempWorker: boolean;
  DepartmentId?: number;
  IncomeTypeId: number;
  WorksElsewhere: boolean;
  PensionEmployeeShare?: number;
  PensionCompanyShare?: number;
  PensionProviderId?: number;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  PensionPolicyNumber?: string;
  PensionEmployeeAmount?: number;
  PensionCompanyAmount?: number;
  Pension2BaseCalculationMethodId: number;
  Pension2ProviderId?: number;
  Pension2ProviderBankRegNo?: string;
  Pension2ProviderBankAccountNo?: string;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeAmount?: number;
  Pension2CompanyAmount?: number;
  VacationTypeId: number;
  LastStatementEndDate?: Date;
  VacationReduceSalary: boolean;
  IsTerminated: boolean;
  VacationSupplementPayoutMonth: number;
  AddressId: number;
  AddressLine1?: string;
  AddressLine2?: string;
  PostalCode?: string;
  City?: string;
  CountryId?: number;
  TaxCardRequested?: Date;
  UserId: number;
  Username?: string;
  DefaultCompanyId?: number;
  LanguageId: number;
  CompanyName?: string;
  IllnessReducesSalary: boolean;
  EmploymentCreated: Date;
  CompanyUserCreated: Date;
  UserCreated: Date;
  DepartmentName?: string;
  DepartmentExternalReference?: string;
  DateOfBirth?: Date;
  IsPendingRecalc: boolean;
  ManagerCompanyUserId?: number;
  ManagerName?: string;
  LanguageCode?: string;
  BasedOnTemplateName?: string;
  CountryCode?: string;
  PreferredTaxCardTypeId: number;
  SeniorityDate?: Date;
  AutoDetermineBankAccount: boolean;
}

export class BatchOperationResponse_UserEmployment_UserEmployment
  implements IBatchOperationResponse_UserEmployment_UserEmployment {
  ProcessedItems?: IUserEmployment[];
  Errors?: any;
  HasErrors: boolean;
}

export class UserEmploymentTemplate implements IUserEmploymentTemplate {
  VacationType?: IVacationType;
  VacationProvider?: IVacationProvider;
  PensionProvider?: IPensionProvider;
  Pension2Provider?: IPensionProvider;
  PensionBaseCalculationMethod?: IPensionBaseCalculationMethod;
  Pension2BaseCalculationMethod?: IPensionBaseCalculationMethod;
  SalaryCycle?: ISalaryCycle;
  Id: number;
  CompanyId: number;
  Name?: string;
  VacationTypeId: number;
  VacationProviderId?: number;
  VacationExtraPercentage?: number;
  PensionBaseCalculationMethodId: number;
  IsAtpTempWorker: boolean;
  WorksElsewhere: boolean;
  PensionEmployeeShare?: number;
  PensionCompanyShare?: number;
  PensionProviderId?: number;
  PensionEmployeeAmount?: number;
  PensionCompanyAmount?: number;
  Pension2BaseCalculationMethodId: number;
  Pension2ProviderId?: number;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeAmount?: number;
  Pension2CompanyAmount?: number;
  IncomeTypeId: number;
  SalaryCycleId: number;
  VacationReduceSalary: boolean;
  VacationSupplementPayoutMonth: number;
  IsInMaternityProgram: boolean;
  FFSeniorityRequirementMonths: number;
  FFHoursEarnedPerYear: number;
  FFAutoPayout: boolean;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  Pension2ProviderBankRegNo?: string;
  Pension2ProviderBankAccountNo?: string;
  IllnessReducesSalary: boolean;
  StandardHoursPerWeek?: number;
  StatisticsSalaryPrincipleId?: number;
  StatisticsEmploymentPrincipleId?: number;
  StatisticsEmploymentTermsId?: number;
  StatisticsJobStatusId?: number;
  StatisticsJobFunctionCode?: number;
  StandardWorkDaysPerWeek?: number;
  DepartmentId?: number;
  FlexEarningPercentage: number;
  FlexBaseCalculationMethodId: number;
  IsDefault: boolean;
  PensionPolicyNumber?: string;
  Pension2PolicyNumber?: string;
  CollectiveBargainingAgreementCode?: string;
  TerminationVacationOverridePercentage?: number;
  SystemDefaultForCountryId?: number;
  TimeEntryRecordAcrossSalaryPeriodStrategy: number;
  AllowExtendedTaxCardDeductions: boolean;
  TemplateExternalReference?: string;
  UseTaxDeductionPerWorkDay: boolean;
  TemplateSalaryRecords?: ITemplateSalaryRecord[];
  SenioritySupplements?: ISenioritySupplement[];
  OvertimeSupplements?: IOvertimeSupplement[];
}

export class TemplateSalaryRecord implements ITemplateSalaryRecord {
  UnitType?: IUnitType;
  PersistenceType?: ISalaryRecordPersistenceType;
  Id: number;
  EmploymentTemplateId: number;
  Units?: number;
  IsActive: boolean;
  Description?: string;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Amount?: number;
  BaseAmount?: number;
  SalaryTypeId: number;
  PersistenceTypeId: number;
  SortOrder: number;
}

export class SenioritySupplement implements ISenioritySupplement {
  Id: number;
  EmploymentTemplateId: number;
  SeniorityMonthsStart?: number;
  SeniorityMonthsEnd?: number;
  SupplementAmount: number;
  SupplementIntervalType: number;
}

export class OvertimeSupplement implements IOvertimeSupplement {
  Id: number;
  EmploymentTemplateId: number;
  TotalHoursWorkedFrom?: number;
  TotalHoursWorkedUntil?: number;
  SupplementAmount?: number;
  SupplementPercentage?: number;
  OvertimeSupplementPrinciple: number;
}

export class TemplateCreationRequest implements ITemplateCreationRequest {
  Name?: string;
  BasedOnEmploymentId: number;
}

export class DynamicSalaryRecordImportColumn implements IDynamicSalaryRecordImportColumn {
  IsId: boolean;
  Name?: string;
  Value?: string;
}

export class SimpleImportMapping implements ISimpleImportMapping {
  Mapping?: string;
  Operation?: string;
  StartRow: number;
  Name?: string;
}

export class ImportMapping implements IImportMapping {
  Id: number;
  OperationId: number;
  CompanyId?: number;
  Name?: string;
  StartRow: number;
  ColumnMapping?: string;
}

export class SimpleImportResponse implements ISimpleImportResponse {
  Outcome?: string;
  Errors?: IImportErrorMessage[];
  FatalMessage?: string;
}

export class ImportErrorMessage implements IImportErrorMessage {
  Error: boolean;
  Message?: string;
}

export class SimpleImportRequest implements ISimpleImportRequest {
  MappingId?: number;
  Mapping?: ISimpleImportMapping;
  Options?: ISimpleKeyValuePair[];
}

export class SimpleKeyValuePair implements ISimpleKeyValuePair {
  Key?: string;
  Value?: string;
}

export class StringDataWrapper implements IStringDataWrapper {
  Data?: string[];
}

export class Dimension implements IDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class NameObject implements INameObject {
  DisplayName?: string;
  Value?: string;
}

export class Feedback implements IFeedback {
  Message?: string;
}

export class StatisticsDiscoCode implements IStatisticsDiscoCode {
  Id: number;
  Code: number;
  Name?: string;
}

export class ModuleCompanyView implements IModuleCompanyView {
  CompanyId: number;
  CompanyName?: string;
  ModuleId: number;
  PackageId?: number;
  ModuleName?: string;
  ModuleDescription?: string;
  PackageName?: string;
  PackageDescription?: string;
  ModulePrice?: number;
  ModuleBillingPrinciple?: string;
  ModuleBillingPrincipleId?: number;
  PackagePrice?: number;
  PackageBillingPrinciple?: string;
  PackageBillingPrincipleId?: number;
  IsEnabled?: boolean;
  LanguageId: number;
  InfoOnly: boolean;
  FreeUntilDate?: Date;
  ScheduledDeactivationDate?: Date;
  CompanyCurrentPackageLevel: number;
  SortKey?: string;
  ModuleKey?: string;
  PackageKey?: string;
}

export class Report implements IReport {
  AreaId: number;
  Parameters?: string;
  SupportedFormats?: string;
  ExcludeColumns?: string[];
  ExcludeColumnsPdf?: string[];
  PortraitOrientation: boolean;
  HaveSums: boolean;
  RequiredRoleId: number;
  SpecificCountryId?: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class ReportRequest implements IReportRequest {
  ReportId: number;
  ParameterValues?: IReportParameterValue[];
  OutputFormat?: string;
}

export class ReportParameterValue implements IReportParameterValue {
  Key?: string;
  Value?: string;
}

export class SalaryBatchView implements ISalaryBatchView {
  Id: number;
  CompanyId: number;
  SalaryPeriodId: number;
  Created: Date;
  StatusId: number;
  Approved?: Date;
  RejectionReason?: string;
  Rejected?: Date;
  EIncomeTransferDate?: Date;
  Message?: string;
  PayoutDate: Date;
  ApprovedCompanyUserId?: number;
  RejectedCompanyUserId?: number;
  CreatedCompanyUserId?: number;
  HasErrors: boolean;
  HasWarnings: boolean;
  NetsPaymentsProcessingDate?: Date;
  CreatedByCompanyUserFullName?: string;
  ApprovedByCompanyUserFullName?: string;
  RejectedByCompanyUserFullName?: string;
  PeriodStartDate: Date;
  PeriodEndDate: Date;
  SalaryCycleId: number;
  LanguageId: number;
  StatusName?: string;
  SalaryBatchNumber: number;
  IsTermination: boolean;
  EIncomeZeroReport: boolean;
  PaymentMethodSalary: number;
  PaymentMethodTax: number;
  PaymentMethodPension: number;
  EIncomeReruns: number;
  PayslipsArchived: boolean;
  LimitToSpecificEmployees: boolean;
  Description?: string;
  PayoutAllFlex: boolean;
  ValidationStatusCode?: string;
  FinalizedByCompanyUserFullName?: string;
}

export class SalaryBatchRequest implements ISalaryBatchRequest {
  SalaryCycleId?: number;
  SalaryPeriodId?: number;
  UserEmploymentIds?: number[];
  Preview: boolean;
  EIncomeZeroReport: boolean;
  PayoutDate?: Date;
  Message?: string;
  PayoutAllFlex: boolean;
}

export class SalaryBatch implements ISalaryBatch {
  Status?: ISalaryBatchStatus;
  IsDraft: boolean;
  IsLocked: boolean;
  IsFinalized: boolean;
  Id: number;
  CompanyId: number;
  SalaryPeriodId: number;
  Created: Date;
  StatusId: number;
  Approved?: Date;
  RejectionReason?: string;
  Rejected?: Date;
  EIncomeTransferDate?: Date;
  Message?: string;
  PayoutDate: Date;
  ApprovedCompanyUserId?: number;
  RejectedCompanyUserId?: number;
  CreatedCompanyUserId?: number;
  HasErrors: boolean;
  HasWarnings: boolean;
  NetsPaymentsProcessingDate?: Date;
  IsTermination: boolean;
  EIncomeZeroReport: boolean;
  SalaryBatchNumber: number;
  PaymentMethodSalary: number;
  PaymentMethodTax: number;
  PaymentMethodPension: number;
  EIncomeReruns: number;
  PayslipsArchived: boolean;
  LimitToSpecificEmployees: boolean;
  EBoksDeliveryEnabled: boolean;
  PayoutAllFlex: boolean;
  SpecifiedEmployees?: string;
  HasNemKontoPayments: boolean;
  PaymentMethodPublicPension: number;
  PaymentMethodVacationProvider: number;
  ToArchive: boolean;
  Finalized?: Date;
  FinalizedCompanyUserId?: number;
  AllowPreliminaryPayslips: boolean;
  PackageLevelWhenFinalized?: number;
  IsAutomated: boolean;
  SalaryStatements?: ISalaryStatement[];
  SalaryPeriod?: ISalaryPeriod;
  SalaryBatchRecords?: ISalaryBatchRecord[];
}

export class SalaryBatchStatus implements ISalaryBatchStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class SalaryStatement implements ISalaryStatement {
  PayoutDateDerived?: Date;
  IsFinalized: boolean;
  Description?: string;
  Id: number;
  SalaryBatchId: number;
  PeriodFrom: Date;
  PeriodTo: Date;
  PayoutAmount?: number;
  AtpEmployee: number;
  AtpEmployer: number;
  UserEmploymentId: number;
  TaxAmount: number;
  AmbAmount: number;
  PensionProviderId?: number;
  PensionEmployeeShare?: number;
  PensionEmployeeShareAmount: number;
  PensionCompanyShare?: number;
  PensionCompanyShareAmount: number;
  VacationProviderId?: number;
  VacationMoneyEarnedGross: number;
  VacationMoneyEarnedNet?: number;
  VacationDaysEarned: number;
  UserFullName?: string;
  UserIdentityNumber?: string;
  UserAddressId?: number;
  PensionBaseAmount: number;
  Pension2BaseAmount: number;
  Pension2ProviderId?: number;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeShareAmount: number;
  Pension2CompanyShareAmount: number;
  VacationDaysSpent: number;
  VacationBaseAmount: number;
  PrimaryIncomeAmount: number;
  HoursWorked: number;
  KmEntered: number;
  AppliedTaxDeduction: number;
  TaxCardRemainingFreeAmount?: number;
  TaxCardTypeId: number;
  TaxRate: number;
  VacationDaysSubtracted: number;
  TaxBaseAmount: number;
  AmbBaseAmount: number;
  VacationTaxDeduction?: number;
  TaxCardFreeAmountReduction?: number;
  ActualFromDate: Date;
  ActualEndDate: Date;
  IsTermination: boolean;
  TerminationTotalVacationMoneyGross?: number;
  TerminationTotalVacationMoneyNet?: number;
  TerminationYearBeforeLastVacationMoneyGross?: number;
  TerminationYearBeforeLastVacationMoneyNet?: number;
  TerminationYearBeforeLastVacationRemainingDays?: number;
  TerminationLastYearVacationMoneyGross?: number;
  TerminationLastYearVacationMoneyNet?: number;
  TerminationLastYearVacationRemainingDays?: number;
  TerminationCurrentYearVacationMoneyGross?: number;
  TerminationCurrentYearVacationMoneyNet?: number;
  TerminationCurrentYearVacationRemainingDays?: number;
  VacationTaxReduction: number;
  VacationAmbAmount: number;
  FFHoursEarned: number;
  FFHoursSpent: number;
  RecipientBankRegNo?: string;
  RecipientBankAccountNo?: string;
  RecipientBicSwiftNo?: string;
  RecipientIbanNo?: string;
  IllnessCompensationAtpAmountEmployee: number;
  DepartmentId?: number;
  OvertimeHours: number;
  IllnessHours: number;
  TaxableIncome: number;
  VacationSupplementEarned?: number;
  SummaryAmountSalary?: number;
  SummaryAmountSupplements?: number;
  SummaryAmountTaxes?: number;
  SummaryAmountAdjustments?: number;
  FlexAmountEarned: number;
  FlexAmountReduced: number;
  SummaryAmountPension?: number;
  VacationAmbBaseAmount: number;
  TaxFreeIncome?: number;
  TravelCompensation: number;
  PensionCompanyTaxAmount?: number;
  PensionEmployeeTaxAmount?: number;
  ReportableNonTaxedIncome?: number;
  DownloadToken?: string;
  DownloadTokenExpiryTime?: Date;
  VacationPayoutEarnedLastYear?: number;
  VacationPayoutEarnedCurrentYear?: number;
  SalaryBatch?: ISalaryBatch;
  SalaryBatchRecords?: ISalaryBatchRecord[];
  UserEmployment?: IUserEmployment;
}

export class SalaryPeriod implements ISalaryPeriod {
  SalaryCycle?: ISalaryCycle;
  Id: number;
  StartDate: Date;
  EndDate: Date;
  SalaryCycleId: number;
  SuggestedPayoutDate?: Date;
  FriendlyName?: string;
}

export class SalaryBatchRecord implements ISalaryBatchRecord {
  Id: number;
  SalaryTypeId: number;
  Amount: number;
  Units?: number;
  Description?: string;
  CompanyUserId: number;
  UnitTypeId?: number;
  UserEmploymentId: number;
  SalaryRecordId?: number;
  SalaryBatchId: number;
  AmountPerUnit?: number;
  BaseAmount?: number;
  SalaryStatementId?: number;
  IncludeInPayment: boolean;
  EIncomeCategory: number;
  EIncomeSubCategory: number;
  SummaryCategoryId?: number;
  PeriodFactor?: number;
  FromDate?: Date;
  ToDate?: Date;
  SortOrder: number;
  IsPrimaryIncome: boolean;
  TriggersAMB: boolean;
  TriggersIncomeTax: boolean;
  TriggersATP: boolean;
  EarnsHolidayCompensation: boolean;
  SpecialIdentifier?: string;
  IsInternal: boolean;
  BaseSalaryTypeId?: number;
  ExternalReferenceSalaryType?: string;
  ExternalReferenceEmployee?: string;
  ExternalReferenceDepartment?: string;
  InformationOnly: boolean;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  SulinalFieldNumber?: string;
  IsReportableIncome: boolean;
}

export class EIncomeResponseLine implements IEIncomeResponseLine {
  Id: number;
  SalaryBatchId: number;
  IsError: boolean;
  RefLineNo: number;
  RefFieldNo: number;
  ResponseCode: number;
  ResponseText?: string;
  CprOrSeNo?: string;
  ReceivedOn: Date;
  ExportBatchIdentifier?: string;
  RerunNumber: number;
  SalaryBatch?: ISalaryBatch;
}

export class SalaryBatchValidationIssue implements ISalaryBatchValidationIssue {
  Id: number;
  SalaryBatchId: number;
  UserEmploymentId?: number;
  IsError: boolean;
  Description?: string;
  EmployeeName?: string;
  MessageKey?: string;
  MessageParameters?: string;
}

export class SalaryBatchTotalsApiView implements ISalaryBatchTotalsApiView {
  SalaryBatchId: number;
  SalaryTypeId: number;
  AmountSum?: number;
  LanguageId: number;
  SortOrder: number;
  Description?: string;
  SalaryBatchNumber: number;
}

export class SalaryTypeSumsByEmploymentView implements ISalaryTypeSumsByEmploymentView {
  LanguageId: number;
  SalaryBatchId: number;
  SalaryTypeId: number;
  SalaryTypeName?: string;
  UserEmploymentId: number;
  FullName?: string;
  AmountSum?: number;
}

export class SalaryBatchUnitTotal implements ISalaryBatchUnitTotal {
  Name?: string;
  Key?: string;
  Amount: number;
}

export class SalaryBatchEmployeeTotal implements ISalaryBatchEmployeeTotal {
  UserEmploymentId: number;
  FullName?: string;
  AmountSum: number;
}

export class SalaryBatchApprovalRequest implements ISalaryBatchApprovalRequest {
  Password?: string;
  DoRecalc: boolean;
}

export class SalaryBatchApprovalResponse implements ISalaryBatchApprovalResponse {
  SalaryBatch?: ISalaryBatch;
  Message?: string;
}

export class SalaryBatchRejectionRequest implements ISalaryBatchRejectionRequest {
  Reason?: string;
}

export class ExternalAccountingRecord implements IExternalAccountingRecord {
  Amount: number;
  AccountNumber?: string;
  EntryDate: Date;
  Description?: string;
  SalaryTypeId: number;
  SalaryTypeName?: string;
  DepartmentName?: string;
  DepartmentExternalReference?: string;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  EmployeeName?: string;
  EmployeeExternalReference?: string;
}

export class SalaryRecordView implements ISalaryRecordView {
  Id: number;
  CompanyUserId: number;
  CompanyId: number;
  SalaryTypeId: number;
  AmountPerUnit?: number;
  Units?: number;
  IsActive: boolean;
  StartDate?: Date;
  EndDate?: Date;
  Description?: string;
  UserEmploymentId: number;
  UnitTypeId?: number;
  BaseAmount?: number;
  Amount?: number;
  SortOrder: number;
  PersistenceTypeId: number;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
}

export class BatchOperationResponse_SalaryRecord_SalaryRecord
  implements IBatchOperationResponse_SalaryRecord_SalaryRecord {
  ProcessedItems?: ISalaryRecord[];
  Errors?: any;
  HasErrors: boolean;
}

export class SimpleSalaryRecordUpdateRequest implements ISimpleSalaryRecordUpdateRequest {
  Id: number;
  Amount: number;
  Units?: number;
  AmountPerUnit?: number;
}

export class SimpleSalaryRecordCreationRequest implements ISimpleSalaryRecordCreationRequest {
  UserEmploymentId: number;
  SalaryTypeId: number;
  Amount?: number;
  Units?: number;
  AmountPerUnit?: number;
}

export class GetSalaryRecordsOverview_Result implements IGetSalaryRecordsOverview_Result {
  UserEmploymentId: number;
  FullName?: string;
  DepartmentId?: number;
  DepartmentName?: string;
  SalaryCycleId: number;
  SalaryRecord1Id?: number;
  MultipleRecordsExist1?: boolean;
  SalaryRecord1Units?: number;
  SalaryRecord1PerUnit?: number;
  SalaryRecord1Amount?: number;
  SalaryRecord2Id?: number;
  MultipleRecordsExist2?: boolean;
  SalaryRecord2Units?: number;
  SalaryRecord2PerUnit?: number;
  SalaryRecord2Amount?: number;
  SalaryRecord3Id?: number;
  MultipleRecordsExist3?: boolean;
  SalaryRecord3Units?: number;
  SalaryRecord3PerUnit?: number;
  SalaryRecord3Amount?: number;
  SalaryRecord4Id?: number;
  MultipleRecordsExist4?: boolean;
  SalaryRecord4Units?: number;
  SalaryRecord4PerUnit?: number;
  SalaryRecord4Amount?: number;
  SalaryRecord5Id?: number;
  MultipleRecordsExist5?: boolean;
  SalaryRecord5Units?: number;
  SalaryRecord5PerUnit?: number;
  SalaryRecord5Amount?: number;
  SalaryRecord6Id?: number;
  MultipleRecordsExist6?: boolean;
  SalaryRecord6Units?: number;
  SalaryRecord6PerUnit?: number;
  SalaryRecord6Amount?: number;
  SalaryRecord7Id?: number;
  MultipleRecordsExist7?: boolean;
  SalaryRecord7Units?: number;
  SalaryRecord7PerUnit?: number;
  SalaryRecord7Amount?: number;
}

export class SalaryRecordsOverviewFilterRequest implements ISalaryRecordsOverviewFilterRequest {
  EmploymentTemplateId?: number;
  SalaryCycleId?: number;
  DepartmentId?: number;
  IncludeEmployeesWithNoData: boolean;
}

export class SimpleSalaryStatement implements ISimpleSalaryStatement {
  Id: number;
  UserEmploymentId: number;
  UserFullName?: string;
  PayoutAmount: number;
}

export class SimpleSalaryBatchRecord implements ISimpleSalaryBatchRecord {
  Id: number;
  SalaryTypeId: number;
  Description?: string;
  Amount: number;
}

export class SalaryType implements ISalaryType {
  SummaryCategory?: ISalarySummaryCategory;
  Id: number;
  CompanyId?: number;
  CategoryId?: number;
  Name?: string;
  Description?: string;
  IsVisible?: boolean;
  IsActive?: boolean;
  SalaryTypeGroupId?: number;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Units?: number;
  BaseAmount?: number;
  Amount?: number;
  ExternalReference?: string;
  BaseSalaryTypeId?: number;
  EIncomeCategory?: number;
  EIncomeSubCategory?: number;
  SummaryCategoryId?: number;
  SortOrder?: number;
  DefaultPayslipText?: string;
  AllowEditUnitType?: boolean;
  AllowEditBaseAmount?: boolean;
  NegativeDefault?: boolean;
  DefaultPersistanceTypeId?: number;
  SumFromIds?: string;
  SumFromReverseSign?: boolean;
  IncludeAlways?: boolean;
  StatisticsOnly?: boolean;
  StatisticsFieldCode?: string;
  RelatesToIncomeType5?: boolean;
  Explanation?: string;
  AccountTypeId?: number;
  Identifier?: string;
  TriggersOvertimeSupplement?: boolean;
  SulinalFieldNumber?: string;
  IsAdvanced: boolean;
  OverviewIndex?: number;
  OverviewDisplayMode?: number;
  InternalReferenceId?: number;
  SalaryTypeTranslations?: ISalaryTypeTranslation[];
  BaseSalaryType?: ISalaryType;
}

export class SalarySummaryCategory implements ISalarySummaryCategory {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class SalaryTypeTranslation implements ISalaryTypeTranslation {
  Id: number;
  SalaryTypeId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  DefaultPayslipText?: string;
  StatusId: number;
  Comment?: string;
  Explanation?: string;
}

export class SalaryTypeView implements ISalaryTypeView {
  SalaryTypeId: number;
  LanguageId: number;
  CompanyId: number;
  CategoryId?: number;
  Name?: string;
  Description?: string;
  IsVisible: boolean;
  IsActive: boolean;
  SalaryTypeGroupId?: number;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Units?: number;
  BaseAmount?: number;
  Amount?: number;
  ExternalReference?: string;
  EIncomeCategory: number;
  EIncomeSubCategory: number;
  SummaryCategoryId?: number;
  BaseSalaryTypeId?: number;
  IsPrimaryIncome: boolean;
  TriggersAMB: boolean;
  IncludeInPayment: boolean;
  TriggersIncomeTax: boolean;
  TriggersATP: boolean;
  EarnsHolidayCompensation: boolean;
  SortOrder: number;
  DefaultPayslipText?: string;
  IsInternal: boolean;
  AllowEditUnitType: boolean;
  AllowEditBaseAmount: boolean;
  NegativeDefault: boolean;
  DefaultPersistanceTypeId?: number;
  SumFromIds?: string;
  SumFromReverseSign: boolean;
  IncludeAlways: boolean;
  StatisticsOnly: boolean;
  StatisticsFieldCode?: string;
  RelatesToIncomeType5?: boolean;
  Explanation?: string;
  Identifier?: string;
  TriggersOvertimeSupplement?: boolean;
  SulinalFieldNumber?: string;
  OverviewIndex?: number;
  OverviewDisplayMode?: number;
  IsAdvanced?: boolean;
  AccountTypeId?: number;
}

export class SalaryTypeCategoryView implements ISalaryTypeCategoryView {
  StaticDataId: number;
  Id: number;
  LanguageId: number;
  Key?: string;
  Name?: string;
  Description?: string;
  IsPrimaryIncome: boolean;
  TriggersAMB: boolean;
  IncludeInPayment: boolean;
  TriggersIncomeTax: boolean;
  TriggersATP: boolean;
  EarnsHolidayCompensation: boolean;
  SortKey?: string;
}

export class InheritSalaryTypeRequest implements IInheritSalaryTypeRequest {
  BaseSalaryTypeId: number;
  Name?: string;
}

export class SimpleSalaryTypeUpdateRequest implements ISimpleSalaryTypeUpdateRequest {
  Id: number;
  ExternalReference?: string;
}

export class SalaryTypeGroup implements ISalaryTypeGroup {
  Id: number;
  Name?: string;
  CompanyId: number;
  ExternalReference?: string;
}

export class StartupTaskCompanyView implements IStartupTaskCompanyView {
  Id: number;
  TaskId: number;
  Key?: string;
  SortOrder: number;
  CompanyId: number;
  CompanyName?: string;
  AppUri?: string;
  IsSkippable: boolean;
  LanguageId: number;
  Name?: string;
  Description?: string;
  GuideMessage?: string;
  ExternalLink?: string;
  SkipWarning?: string;
  StatusId: number;
  HelpLink?: string;
}

export class StaticData implements IStaticData {
  Id: number;
  TypeKey?: string;
  ValueKey?: string;
  ValueId: number;
  Name?: string;
  Description?: string;
  Int1?: number;
  Int2?: number;
  Text1?: string;
  Text2?: string;
  Date1?: Date;
  Date2?: Date;
  Bool1: boolean;
  Bool2: boolean;
  Decimal1?: number;
  Decimal2?: number;
  Long1?: number;
  Long2?: number;
  SortKey?: string;
  Text3?: string;
  Text4?: string;
  Text5?: string;
  StaticDataTranslations?: IStaticDataTranslation[];
}

export class StaticDataTranslation implements IStaticDataTranslation {
  Id: number;
  StaticDataId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  Text1?: string;
  Text2?: string;
  StatusId: number;
  Comment?: string;
  Text3?: string;
  Text4?: string;
  Text5?: string;
  Language?: ILanguage;
  StaticData?: IStaticData;
}

export class StaticDataEntity implements IStaticDataEntity {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class Bank implements IBank {
  PaymentExportFormatId: number;
  CountryId: number;
  PaymentExportFormat?: IPaymentExportFormat;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class PaymentExportFormat implements IPaymentExportFormat {
  FileFormat?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class AccountRole implements IAccountRole {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class WorkBookDimension implements IWorkBookDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class UnicontaDimension implements IUnicontaDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class InventioItDimension implements IInventioItDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class TimeEntryStatusExtended implements ITimeEntryStatusExtended {
  TimeEntryStatusString?: string;
  TimeEntryStatusIds?: number[];
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class WorkBookVersion implements IWorkBookVersion {
  VersionNumber: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class ExternalSystemConfiguration implements IExternalSystemConfiguration {
  FormElement?: string;
  IsRequired: boolean;
  ListDataSource?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class CompanyPreferenceCategory implements ICompanyPreferenceCategory {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class SalaryRecordsOverviewDisplayMode implements ISalaryRecordsOverviewDisplayMode {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class ExternalSystemOperation implements IExternalSystemOperation {
  IsSingular: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class IntegrationStatus implements IIntegrationStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class Municipality implements IMunicipality {
  CountryId: number;
  DefaultTaxRate?: number;
  OfficialReference?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class FinanceAccountType implements IFinanceAccountType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class EIncomeGroupByParameter implements IEIncomeGroupByParameter {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class OvertimeSupplementPrinciple implements IOvertimeSupplementPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class SalaryTypeTotalsMultipleBatchesGroupByParameter
  implements ISalaryTypeTotalsMultipleBatchesGroupByParameter {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class Currency implements ICurrency {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class City implements ICity {
  CountryId: number;
  PostalCode: number;
  IsRange: boolean;
  PostalCodeTo?: number;
  MunicipalityId?: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class PaymentProvider implements IPaymentProvider {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class EmailTemplate implements IEmailTemplate {
  Subject?: string;
  PreferPersonalEmail: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class ImportList implements IImportList {
  Operations?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class ModulePackage implements IModulePackage {
  Price: number;
  BillingPrincipleId: number;
  BillingPrinciple?: IBillingPrinciple;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class ImportSpecification implements IImportSpecification {
  Operation?: string;
  OperationId: number;
  Columns?: string;
  DynamicColumns?: string;
  ImportOptions?: IImportOption[];
  DefaultSingleColumns: boolean;
  DefaultRequiredColumns: boolean;
  Relationships?: IColumnRelationship[];
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class ImportOption implements IImportOption {
  Type?: string;
  DisplayName?: string;
  Value?: string;
  DefaultKey?: string;
  Options?: ISimpleKeyValuePair[];
}

export class ColumnRelationship implements IColumnRelationship {
  Units?: string;
  SetColumnsRequired: boolean;
  SetColumnsSingle: boolean;
  Min: number;
  Max: number;
  ViolationMessage?: string;
}

export class Preference implements IPreference {
  Default?: string;
  ListDataSource?: string;
  HelpUrl?: string;
  UiControlType?: string;
  CategoryId?: number;
  CountryIdList?: string;
  AppliesInAllCountries: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class UserPreference implements IUserPreference {
  MinimumRoleId?: number;
  Default?: string;
  ListDataSource?: string;
  HelpUrl?: string;
  UiControlType?: string;
  CategoryId?: number;
  CountryIdList?: string;
  AppliesInAllCountries: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class CompanyPreference implements ICompanyPreference {
  RequiredModuleId: number;
  RequiredModule?: IModule;
  Default?: string;
  ListDataSource?: string;
  HelpUrl?: string;
  UiControlType?: string;
  CategoryId?: number;
  CountryIdList?: string;
  AppliesInAllCountries: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class StatisticsRecipient implements IStatisticsRecipient {
  Code?: number;
  IsSupported: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class SignupReferral implements ISignupReferral {
  AdditionalInfoRelevant: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class ReportParameter implements IReportParameter {
  UiControlType?: string;
  ListDataSource?: string;
  DefaultValue?: string;
  IsRequired: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class StatisticsSalaryPrinciple implements IStatisticsSalaryPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class StatisticsEmploymentPrinciple implements IStatisticsEmploymentPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class StatisticsEmploymentTerms implements IStatisticsEmploymentTerms {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class StatisticsJobStatus implements IStatisticsJobStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class IntervalType implements IIntervalType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class TermExplanation implements ITermExplanation {
  Term?: string;
  Translation?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class TimeEntryRecordAcrossSalaryPeriodStrategy implements ITimeEntryRecordAcrossSalaryPeriodStrategy {
  IsDefault: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class TimeEntryStatus implements ITimeEntryStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class Month implements IMonth {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class BankHoliday implements IBankHoliday {
  Date: Date;
  CountryId: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class ExternalSystem implements IExternalSystem {
  HasSalaryTypeMappings: boolean;
  IsActive: boolean;
  ConfigurationKeys?: string[];
  CredentialKeys?: string[];
  SupportUrl?: string;
  OperationsFlag: number;
  Operations?: IExternalSystemOperation[];
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class PaymentMethod implements IPaymentMethod {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class PaymentStatus implements IPaymentStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class ReportArea implements IReportArea {
  AppUri?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class AtpRate implements IAtpRate {
  SalaryCycleId: number;
  MinHours: number;
  MaxHours: number;
  EmployeeAmount: number;
  CompanyAmount: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class DataType implements IDataType {
  IsNumeric: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class AtpAgreement implements IAtpAgreement {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class IncomeType implements IIncomeType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class SalaryTypeCategory implements ISalaryTypeCategory {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class VacationTypeGL implements IVacationTypeGL {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;
}

export class TestClassWithHtmlSanitization implements ITestClassWithHtmlSanitization {
  SanitizedProperty?: string;
  UnsanitizedProperty?: string;
}

export class TimeEntryRecord implements ITimeEntryRecord {
  UnitType?: IUnitType;
  Status?: ITimeEntryStatus;
  EmployeeName?: string;
  Title?: string;
  DepartmentName?: string;
  IsCalculated: boolean;
  Id: number;
  UserEmploymentId: number;
  EntryDate: Date;
  TimeEntryTypeId: number;
  Units: number;
  UnitTypeId: number;
  Description?: string;
  SalaryPeriodId: number;
  StatusId: number;
  ApprovedByCompanyUserId?: number;
  RejectedByCompanyUserId?: number;
  Created: Date;
  RejectionReason?: string;
  SalaryRecordId?: number;
  SalaryStatementId?: number;
  KilometersDriven?: number;
  StartTime?: string;
  EndTime?: string;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  EndDate?: Date;
  CalculatedFromRecordId?: number;
  ExternalIdentifier?: string;
  CalculatedPayout: number;
  SalaryPeriod?: ISalaryPeriod;
  TimeEntryType?: ITimeEntryType;
  CalculatedChildRecords?: ITimeEntryRecord[];
  CalculatedFromParentRecord?: ITimeEntryRecord;
}

export class RejectionInfo implements IRejectionInfo {
  Reason?: string;
}

export class TimeEntryTypeView implements ITimeEntryTypeView {
  IsVacation: boolean;
  TimeEntryTypeId: number;
  BaseTimeEntryTypeId?: number;
  CompanyId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  DefaultPayslipText?: string;
  IsActive: boolean;
  UnitTypeId?: number;
  ExternalReference?: string;
  SortOrder: number;
  AllowEditUnitType: boolean;
  IsIllness: boolean;
  SalaryTypeId?: number;
  SalaryTypeName?: string;
  Identifier?: string;
}

export class InheritTimeEntryTypeRequest implements IInheritTimeEntryTypeRequest {
  BaseTimeEntryTypeId: number;
  Name?: string;
}

export class IdentityCheckResponse implements IIdentityCheckResponse {
  ExistsInGratisal: boolean;
  BirthDate?: Date;
  IsWellFormed: boolean;
  RaiseChecksumWarning: boolean;
}

export class UsernameCheckResponse implements IUsernameCheckResponse {
  ExistsInGratisal: boolean;
  IsWellFormed: boolean;
}

export class CompanyUserView implements ICompanyUserView {
  UserId: number;
  CompanyUserId: number;
  CompanyId: number;
  CompanyName?: string;
  StatusId: number;
  RoleId: number;
  IsActive: boolean;
  RoleKey?: string;
  IdentityNumber?: string;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  TelMobile?: string;
  TelFixed?: string;
  BankRegNo?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  IsPaymentApprover: boolean;
  Initials?: string;
  CompanyEmail?: string;
  PersonalEmail?: string;
  VATRegistrationNumber?: string;
  FullName?: string;
  UserAccountId?: number;
  CompanyAccountId?: number;
  AccountRoleId?: number;
}

export class IdentityNumberRequest implements IIdentityNumberRequest {
  IdentityNumber?: string;
}
