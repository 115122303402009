import { ICompany, ISalaryBatch, ISalaryCycle, ISimpleKeyValuePair } from '../../Services/ApiModel';
import { SalaryBatchViewModel } from './SalaryBatchViewModel';

export class CompanySalaryBatchesEntityContext {
  public Company: ICompany;
  public SalaryBatches: SalaryBatchViewModel[] = [];
  public SelectedSalaryBatch: ISalaryBatch;
  public SalaryCycles: ISalaryCycle[] = [];
  public AutoRecalDraftPreference: ISimpleKeyValuePair;
  public SalaryCycleId: number;

  public get IsRecalculateDraft(): boolean {
    return this.AutoRecalDraftPreference && this.AutoRecalDraftPreference.Value.toLowerCase() === 'true';
  }
}
