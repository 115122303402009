<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title" *ngIf="isDanishCompany">
      {{ 'EmployeeEmployment.OffDayCompensationDanish' | translate }}
    </legend>
    <legend class="Card-title" *ngIf="!isDanishCompany">
      {{ 'EmployeeEmployment.OffDayCompensation' | translate }}
    </legend>
    <percentage-edit
      [label]="'EmployeeEmployment.EarningPercentage' | translate"
      [editMode]="editMode"
      [disable]="disable"
      [(isDirty)]="childIsDirty[0]"
      [allowDecimal]="true"
      [(value)]="model.FlexEarningPercentage"
      (valueChange)="onChange()"
    ></percentage-edit>

    <combobox-edit-control
      [editMode]="editMode"
      [label]="'EmployeeEmployment.CalculationBase' | translate"
      [comboboxDataSource]="offDayCompensationCalculationMethods"
      [(value)]="model.FlexBaseCalculationMethodId"
      [(isDirty)]="childIsDirty[1]"
      [textField]="'Name'"
      [idField]="'Id'"
      [disable]="disable"
      (valueChange)="onChange()"
    ></combobox-edit-control>
  </fieldset>
</form>
