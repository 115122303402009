import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { orderBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { Global } from '../../../Common/Global';
import { GridHelper } from '../../../Common/GridHelper';
import { SalaryBatchStatusEnum } from '../../../Model/Enum';
import { ExtendSalaryPeriod } from '../../../Model/ExtendSalaryPeriod';
import {
  IBankHoliday,
  ISalaryBatch,
  ISalaryBatchRecord,
  ISalaryCycle,
  ISalaryPeriod,
  ISimpleKeyValuePair,
  ISimpleSalaryStatement,
  IUserEmploymentView
} from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { CompanySalaryBatchesChildFormEntityContext } from '../CompanySalaryBatchesChildFormEntityContext';
import { IExtendUserEmploymentView } from '../ExtendUserEmploymentView';
import { SalaryBatchViewModel } from '../SalaryBatchViewModel';

@Component({
  selector: 'salary-batch-details',
  templateUrl: './SalaryBatchDetails.component.html'
})
export class SalaryBatchDetailsComponent implements OnInit, OnDestroy {
  @Output() public reloadSalaryBatches: EventEmitter<void> = new EventEmitter<void>();
  @Output() public closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() public entityContextChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public selectedEmployeeIdsChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() public get salaryCycles(): ISalaryCycle[] {
    return this.salaryCyclesValue;
  }
  public set salaryCycles(value: ISalaryCycle[]) {
    const isLoadDueToValueChanged: boolean =
      (!this.salaryCyclesValue || this.salaryCyclesValue.length === 0) && value && value.length > 0;
    this.salaryCyclesValue = value;
    if (isLoadDueToValueChanged) {
      this.loadDetailsData();
    }
  }

  @Input() public get salaryBatch(): SalaryBatchViewModel {
    return this.selectedSalaryBatchValue;
  }
  public set salaryBatch(value: SalaryBatchViewModel) {
    if (this.selectedSalaryBatchValue !== value) {
      this.selectedSalaryBatchValue = value;
      if (value && value.Message) {
        value.Message = this.decodeEntities(value.Message);
      }
      this.loadDetailsData();
    } else if (!value) {
      this.loadDetailsData();
    }
  }

  @Input() public get entityContext(): CompanySalaryBatchesChildFormEntityContext {
    return this.entityContextValue ? this.entityContextValue : ({} as any);
  }
  public set entityContext(value: CompanySalaryBatchesChildFormEntityContext) {
    if (this.entityContextValue !== value) {
      this.entityContextValue = value;
      this.entityContextChange.emit(value);
    }
  }

  @Input() public salaryStatements: ISimpleSalaryStatement[];

  private selectedEmployeeIdsValue: number[];
  @Input() public get selectedEmployeeIds(): number[] {
    return this.selectedEmployeeIdsValue;
  }
  public set selectedEmployeeIds(value: number[]) {
    if (this.selectedEmployeeIdsValue !== value) {
      this.selectedEmployeeIdsValue = value;
      this.selectedEmployeeIdsChange.emit(value);
    }
  }

  @Input() public isDialog: boolean;
  @Input() public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      if (value) {
        this.loadDetailsData();
      }
    }
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }
  public get IsExistingSalaryBatch(): boolean {
    return !(this.entityContext.BatchId === undefined || this.entityContext.BatchId < 1);
  }
  public get IsNewBatchWithEmployeeSelection(): boolean {
    return this.entityContext.BatchId === -1 && this.entityContext.EmployeeCategory === 2;
  }
  public get showMultiPurposeOffDayCompensation(): boolean {
    const enableFlexBalanceSetting: ISimpleKeyValuePair = Global.COMPANY_PREFERENCES
      ? Global.COMPANY_PREFERENCES.filter((pref: ISimpleKeyValuePair) => pref.Key === 'Company.EnableFlexBalance')[0]
      : undefined;
    return enableFlexBalanceSetting ? enableFlexBalanceSetting.Value === 'true' : false;
  }

  public get isShowPaymentDate(): boolean {
    return this.entityContext.BatchId === -1
      ? this.entityContext.EmployeeCategory !== 3
      : this.salaryBatch && this.salaryBatch.Id === this.entityContext.BatchId
      ? !this.salaryBatch.EIncomeZeroReport
      : false;
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }
  public get PayoutAllFlexLabel(): string {
    if (this.IsGreenlandCompany) {
      return 'CompanySalaryBatches.PayoutOffDayBalances GreenlandCompany';
    }
    return 'CompanySalaryBatches.PayoutOffDayBalances';
  }

  public bankHoliday: any[];
  public selectEmployeesGridData: IExtendUserEmploymentView[] = [];
  public recalculateSingleUserEmploymentId: number;
  public session: any = Global.SESSION;

  private visibleValue: boolean;
  private salaryCyclesValue: ISalaryCycle[];
  private selectedSalaryBatchValue: SalaryBatchViewModel;
  private entityContextValue: CompanySalaryBatchesChildFormEntityContext;
  private loadingPeriods = false;
  private responsePaymentDate: Date;
  private companypreference: ISimpleKeyValuePair[];

  constructor(
    private dataService: RxDataService,
    public sessionService: SessionService,
    private staticData: StaticDataService
  ) {}

  public ngOnInit(): void {
    this.staticData.BankHoliday.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IBankHoliday[]) => {
      this.bankHoliday = data;
    });
    this.companypreference = Global.COMPANY_PREFERENCES;
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private prepareEntityContext(batch: SalaryBatchViewModel): void {
    this.loadingPeriods = true;
    if (batch) {
      const updateContext: CompanySalaryBatchesChildFormEntityContext = new CompanySalaryBatchesChildFormEntityContext(
        this.sessionService
      );
      updateContext.BatchId = batch.Id;
      updateContext.StatusName = batch.StatusName;
      updateContext.ChangeDate = batch.Rejected ? batch.Rejected : batch.Approved ? batch.Approved : batch.Created;
      updateContext.SalaryBatchNumber = batch.SalaryBatchNumber;
      updateContext.PeriodId = batch.SalaryPeriodId;
      updateContext.IsDraft = batch.StatusId === SalaryBatchStatusEnum.Draft;
      updateContext.IsPendingForApproval = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
      updateContext.IsFinalized = batch.StatusId === SalaryBatchStatusEnum.Finalized;
      updateContext.IsCancelled = batch.StatusId === SalaryBatchStatusEnum.Cancelled;
      updateContext.IsLocked = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
      updateContext.IsPreliminary = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
      updateContext.PaymentDate = new Date(batch.PayoutDate.toString());
      updateContext.PayoutAllFlex = batch.PayoutAllFlex;
      updateContext.IsRecalcBeforeFinalize = !(updateContext.IsLocked || updateContext.IsPreliminary);
      updateContext.Message = batch.Message;
      updateContext.HasErrors = batch.HasErrors;
      updateContext.HasWarnings = batch.HasWarnings;
      updateContext.CycleId = batch.SalaryCycleId;
      updateContext.EmployeeCategory = 1;
      updateContext.HasBatch = false;
      this.entityContext = updateContext;
      this.onEmployeeCategoryChanged();
    } else {
      if (/*!this.salaryBatchesContext || */ !this.salaryCycles || this.salaryCycles.length === 0) {
        return;
      }

      const newContext: CompanySalaryBatchesChildFormEntityContext = new CompanySalaryBatchesChildFormEntityContext(
        this.sessionService
      );
      newContext.BatchId = -1;
      newContext.StatusName = undefined;
      newContext.ChangeDate = undefined;
      newContext.IsDraft = true;
      newContext.CycleId = this.salaryCycles[0].Id;
      newContext.PeriodId = undefined;
      newContext.EmployeeCategory = 1;
      newContext.PayoutAllFlex = false;
      newContext.HasBatch = false;
      this.entityContext = newContext;
      this.onEmployeeCategoryChanged();
    }
  }

  public isPreparingContent: boolean;
  private loadDetailsData(): void {
    this.prepareEntityContext(this.salaryBatch);
    if (!this.visible) {
      return;
    }

    this.isPreparingContent = true;
    if (this.entityContextValue && this.entityContextValue.CycleId) {
      this.getSalaryBatchesPeriods(this.entityContextValue.CycleId);
      this.getSalaryBatchUserEmployments();

      setTimeout(() => {
        this.entityContext.IsDirty = false;
      });
    }

    setTimeout(() => {
      this.isPreparingContent = false;
    }, 1000);
  }

  public onChildWindowSalaryCycleChange(): void {
    // if (this.loadingPeriods) {
    //     return;
    // }

    const cycleId: number = this.getChildWindowCycleId();
    if (cycleId) {
      this.loadingPeriods = true;
      this.getSalaryBatchesPeriods(cycleId);
      this.setSalaryBatchEmployeeGridDataSource();
    }
  }

  public onSalaryPeriodChanged(): void {
    this.setPaymentDateDueToPeriodChange();
    this.checkPeriod();
  }

  // public isValidPaymentDate(): void {
  //   if (this.bankHoliday && this.bankHoliday.length > 0 && this.entityContext && this.entityContext.PaymentDate) {
  //     const objectCheckvalidDate: any[] = this.bankHoliday.filter(
  //       (model: any) =>
  //         new DateTimeConvert(this.entityContext.PaymentDate).getDateString() ===
  //         new DateTimeConvert(new Date(model.Date)).getDateString()
  //     );
  //     if (
  //       (objectCheckvalidDate && objectCheckvalidDate.length > 0) ||
  //       this.entityContext.PaymentDate.getDay() === 6 ||
  //       this.entityContext.PaymentDate.getDay() === 0
  //     ) {
  //       let flagcheck: Date = new DateTimeConvert(
  //         new Date(
  //           (objectCheckvalidDate && objectCheckvalidDate[0] && objectCheckvalidDate[0].Date
  //             ? objectCheckvalidDate[0].Date
  //             : this.entityContext.PaymentDate
  //           ).toString()
  //         )
  //       ).getUTCDate();
  //       while (flagcheck) {
  //         const preflagcheck: Date = new Date(flagcheck.setDate(flagcheck.getDate() - 1));
  //         const nextObjectCheckvalidDate: any[] = this.bankHoliday.filter(
  //           (model: any) =>
  //             new DateTimeConvert(preflagcheck).getDateString() ===
  //             new DateTimeConvert(new Date(model.Date)).getDateString()
  //         );
  //         if (
  //           ((nextObjectCheckvalidDate && nextObjectCheckvalidDate.length === 0) || !nextObjectCheckvalidDate) &&
  //           (preflagcheck.getDay() !== 6 && preflagcheck.getDay() !== 0)
  //         ) {
  //           this.entityContext.PaymentDate = preflagcheck;
  //           flagcheck = undefined;
  //         } else {
  //           flagcheck = preflagcheck;
  //         }
  //       }
  //     }
  //   }
  // }

  public onEmployeeCategoryChanged(category?: number): void {
    this.onEmployeesGridDataSaveChanges();
  }

  public onEmployeeGridAction(action: string): void {
    if (action === 'SelectAll') {
      this.onSelectAllEmployee(true);
    } else {
      this.onSelectAllEmployee(false);
    }

    this.onEmployeesGridDataSaveChanges();
  }

  public onEmployeesGridDataSaveChanges(event?: any): void {
    switch (this.entityContext.EmployeeCategory) {
      case 1: // All employeess
        this.selectedEmployeeIds = undefined;
        break;
      case 3: // No employees
        this.selectedEmployeeIds = [];
        break;
      case 2:
        // Selected employees
        this.selectedEmployeeIds = this.selectEmployeesGridData
          .filter((e: IExtendUserEmploymentView) => e.IsChecked)
          .map((e: IExtendUserEmploymentView) => e.UserEmploymentId);
        break;
      default:
        break;
    }
  }

  public onAllEmployeeSelectionChecked(isChecked: any): void {
    this.entityContext.IsCheckedAllEmployees = !this.entityContext.IsCheckedAllEmployees;
    this.onSelectAllEmployee(this.entityContext.IsCheckedAllEmployees);
  }

  public onSelectAllEmployee(isChecked: boolean): void {
    this.selectEmployeesGridData.forEach((e: IExtendUserEmploymentView) => (e.IsChecked = isChecked));
    this.entityContext.IsCheckedAllEmployees = isChecked;
  }

  private getSalaryBatchUserEmployments(): void {
    this.dataService
      .Employments_GetEmploymentViews()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userEmployments: IUserEmploymentView[]) => {
        this.entityContext.Employees = userEmployments;
        this.setSalaryBatchEmployeeGridDataSource();
      });
  }

  public onRecalculateSingleEmployeeDone(): void {
    if (this.salaryBatch && this.recalculateSingleUserEmploymentId) {
      this.dataService
        .SalaryBatches_RecalculateSalaryBatchSingleEmployment(
          this.salaryBatch.Id,
          this.recalculateSingleUserEmploymentId
        )
        .subscribe((salaryBatch: ISalaryBatchRecord[]) => {
          this.reloadSalaryBatches.emit();
          this.closeDialog.emit();
        });
    }
  }

  private getChildWindowCycleId(): number {
    let cycleId: number;
    const dataItem: any = this.salaryCycles.find((cycle: ISalaryCycle) => cycle.Id === this.entityContext.CycleId);
    if (!dataItem) {
      cycleId = this.entityContext.CycleId =
        this.salaryCycles && this.salaryCycles.length > 0 ? this.salaryCycles[0].Id : undefined;
    } else {
      cycleId = dataItem.Id;
    }

    return cycleId;
  }

  private getSalaryBatchesPeriods(cycleId: number): void {
    this.dataService
      .SalaryBatches_GetSalaryPeriods(cycleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((salaryPeriods: ISalaryPeriod[]) => {
        if (salaryPeriods) {
          if (salaryPeriods.length > 0) {
            this.loadingPeriods = false;
            const defaultPeriod: ISalaryPeriod = salaryPeriods[0];
            const dataSource: ExtendSalaryPeriod[] = [];
            const isUserFriendlyname: boolean = this.companypreference
              ? this.companypreference.some(
                  (p: ISimpleKeyValuePair) => p.Key === 'SalaryPeriods.FriendlyNames' && p.Value === 'true'
                )
              : false;
            salaryPeriods = salaryPeriods.sort((data1: ISalaryPeriod, data2: ISalaryPeriod) =>
              GridHelper.sortByDateValue(data1.StartDate, data2.StartDate)
            );
            if (salaryPeriods) {
              salaryPeriods.forEach((salaryPeriod: ISalaryPeriod, key: number): void => {
                const startDate: string = this.sessionService.toString(
                  salaryPeriod.StartDate ? new Date(salaryPeriod.StartDate) : undefined
                );
                const endDate: string = this.sessionService.toString(
                  salaryPeriod.EndDate ? new Date(salaryPeriod.EndDate) : undefined
                );
                const extendSalaryPeriod = new ExtendSalaryPeriod();
                extendSalaryPeriod.Id = salaryPeriod.Id;
                extendSalaryPeriod.StartDate = salaryPeriod.StartDate;
                extendSalaryPeriod.EndDate = salaryPeriod.EndDate;
                extendSalaryPeriod.SuggestedPayoutDate = salaryPeriod.SuggestedPayoutDate;
                extendSalaryPeriod.Period = startDate + ' - ' + endDate;

                if (isUserFriendlyname) {
                  extendSalaryPeriod.FriendlyName = ' ' + salaryPeriod.FriendlyName;
                } else {
                  extendSalaryPeriod.FriendlyName = startDate + ' - ' + endDate;
                }

                dataSource[key] = extendSalaryPeriod;
              });
            }

            this.entityContext.Periods = dataSource;

            if (this.entityContext && this.entityContext.BatchId && this.entityContext.BatchId < 0) {
              this.entityContext.PeriodId = defaultPeriod.Id;
            }

            if (this.entityContext.BatchId === undefined || this.entityContext.BatchId < 1) {
              this.getSalaryBatchesPeriodsSuggest(this.entityContext.CycleId);
            }
          } else {
            this.entityContext.Periods = [];
            this.entityContext.PaymentDate = undefined;
          }
        }
      });
  }

  private getSalaryBatchesPeriodsSuggest(cycleId: number): void {
    const batchId: number = this.entityContext ? this.entityContext.BatchId : undefined;
    this.dataService.SalaryBatches_SuggestSalaryPeriod(cycleId).subscribe((salaryPeriod: ISalaryPeriod): void => {
      if (cycleId === this.entityContext.CycleId && batchId === this.entityContext.BatchId) {
        this.entityContext.PeriodId = salaryPeriod.Id;
        this.entityContext.PaymentDate = salaryPeriod.SuggestedPayoutDate
          ? salaryPeriod.SuggestedPayoutDate
          : salaryPeriod.EndDate;
      }
    });
  }

  private checkPeriod(): void {
    if (this.loadingPeriods) {
      return;
    }

    const periodItem: ExtendSalaryPeriod = this.entityContext.Periods
      ? this.entityContext.Periods.find((period: ExtendSalaryPeriod) => period.Id === this.entityContext.PeriodId)
      : undefined;
    if (!periodItem) {
      this.entityContext.PeriodId =
        this.entityContext.Periods.length > 0 ? this.entityContext.Periods[0].Id : undefined;
    }

    const selectedSalaryBatch: ISalaryBatch = this.salaryBatch;
    if (selectedSalaryBatch && this.entityContext.PeriodId === selectedSalaryBatch.SalaryPeriodId) {
      this.entityContext.HasBatch = false;
    } else {
      if (this.entityContext.PeriodId) {
        this.dataService
          .SalaryBatches_SalaryPeriodHasBatches(this.entityContext.PeriodId)
          .subscribe((hasBatch: boolean) => (this.entityContext.HasBatch = hasBatch));
      }
    }
  }

  private setPaymentDateDueToPeriodChange(): void {
    const changedID: number = this.entityContext.PeriodId;
    const periods: ExtendSalaryPeriod[] = this.entityContext.Periods;
    if (periods) {
      periods.forEach((salaryPeriod: ExtendSalaryPeriod, key: number): void => {
        if (periods[key].Id === changedID) {
          this.entityContext.PaymentDate = periods[key].SuggestedPayoutDate;
        }
      });
      if (this.responsePaymentDate) {
        this.entityContext.PaymentDate = this.responsePaymentDate;
        this.responsePaymentDate = undefined;
      }
    }
  }

  private setSalaryBatchEmployeeGridDataSource(): void {
    if (this.entityContext.Employees) {
      const cycleId: number = this.getChildWindowCycleId();
      let dataSource: IExtendUserEmploymentView[] = [];
      if (cycleId) {
        dataSource = this.entityContext.Employees.filter(
          (e: IExtendUserEmploymentView) => e.SalaryCycleId === cycleId && e.IsActive
        );
      } else {
        dataSource = this.entityContext.Employees.filter((e: IExtendUserEmploymentView) => e.IsActive);
      }

      dataSource.forEach((e: IExtendUserEmploymentView) => {
        if (e.IsTerminated) {
          e.FullName = '(' + e.FullName + ')';
          e.IsChecked = false;
        } else {
          e.IsChecked = true;
        }
      });

      dataSource = orderBy(dataSource, [{ field: 'IsTerminated', dir: 'asc' }, { field: 'FullName', dir: 'asc' }]);

      this.entityContext.IsCheckedAllEmployees = true;
      this.selectEmployeesGridData = dataSource;
    } else {
      this.selectEmployeesGridData = [];
    }
  }

  private decodeEntities(encodedString: string): string {
    const translatere: RegExp = /&(nbsp|amp|quot|lt|gt);/g;
    const translate: any = {
      nbsp: ' ',
      amp: '&',
      quot: '"',
      lt: '<',
      gt: '>'
    };
    return encodedString
      .replace(translatere, (match: any, entity: any): string => {
        return translate[entity];
      })
      .replace(/&#(\d+);/gi, (match: any, numStr: any): string => {
        const num: number = parseInt(numStr, 10);
        return String.fromCharCode(num);
      });
  }
}
