import { EventEmitter } from '@angular/core';
import { WindowReference } from '../../../../windowReference';
import './Price.component.less';
var TooltippriceComponent = /** @class */ (function () {
    function TooltippriceComponent(windowRef) {
        this.windowRef = windowRef;
        this.show = new EventEmitter();
        this.hide = new EventEmitter();
        this.isHideToolTipValue = false;
    }
    Object.defineProperty(TooltippriceComponent.prototype, "isHideToolTip", {
        get: function () {
            return this.isHideToolTipValue;
        },
        set: function (value) {
            this.tooltip.hide();
            this.hide.emit();
        },
        enumerable: true,
        configurable: true
    });
    TooltippriceComponent.prototype.onClick = function (packet) {
        var _this = this;
        setTimeout(function () {
            if (_this.tooltip) {
                _this.tooltip.show(packet);
                _this.show.emit();
            }
        });
    };
    Object.defineProperty(TooltippriceComponent.prototype, "tooltipPackageWidth", {
        get: function () {
            var width = this.windowRef && this.windowRef.nativeWindow ? this.windowRef.nativeWindow.innerWidth : 800;
            return width > 790 ? 500 : width * 0.9;
        },
        enumerable: true,
        configurable: true
    });
    return TooltippriceComponent;
}());
export { TooltippriceComponent };
