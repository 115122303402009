<div>
  <module-check [moduleId]="8" [(hasModule)]="hasTimeEntryModule">
    <div class="Cards">
      <div class="Card">
        <div class="Card-inner">
          <legend
            style="padding-bottom: 15px; padding-left: 1px;font-size: 0.75rem; font-style: italic; opacity: 0.75;"
            [innerHTML]="'CompanyTimeEntryTypes.TimeEntryTypeGridHint' | translate"
          ></legend>
          <grid
            [(data)]="gridFilterData"
            [editMode]="!IsReadOnly"
            [(selectedItem)]="selectedTimeEntryType"
            [selectedId]="'Id'"
            [idColumn]="'Id'"
            (cellClick)="onCellClick($event)"
            (saveChangesEvent)="saveChanges($event)"
            class="FormElement-grid Company-timeEntryTypesGrid u-leader"
            [filterable]="'menu'"
            [filter]="filter"
            (filterChange)="onFilterChange($event)"
          >
            <gridColumn
              [field]="'configurationIcon'"
              [editable]="!IsReadOnly"
              [iconField]="'configurationIcon'"
              [locked]="true"
              [type]="'icon'"
              [width]="40"
              [title]="''"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [field]="'IsActive'"
              [classField]="'isActiveClass'"
              [editable]="!IsReadOnly"
              [locked]="true"
              [type]="'checkbox'"
              [title]="'Employee.IsActive' | translate"
              [width]="65"
              [headerTooltip]="'CompanyTimeEntryTypes.IsActiveTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [field]="'Id'"
              [locked]="true"
              [editable]="false"
              [type]="'text'"
              [title]="'Id'"
              [width]="70"
              [headerTooltip]="'CompanyTimeEntryTypes.IdHeaderTooltip' | translate"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [field]="'Name'"
              [classField]="'nameClass'"
              [editable]="!isLanguageModuleEnable"
              [locked]="true"
              [type]="'text'"
              [title]="'CompanyTimeEntryTypes.Name' | translate"
              [width]="150"
              [headerTooltip]="'CompanyTimeEntryTypes.NameTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [field]="'inheritsFrom'"
              [editable]="false"
              [type]="'text'"
              [title]="'CompanyTimeEntryTypes.InheritsFrom' | translate"
              [viewClass]="'truncated'"
              [width]="200"
              [headerTooltip]="'CompanyTimeEntryTypes.InheritsFromTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [field]="'Description'"
              [classField]="'descriptionClass'"
              [editable]="!isLanguageModuleEnable"
              [type]="'text'"
              [title]="'CompanyTimeEntryTypes.Description' | translate"
              [viewClass]="'truncated'"
              [width]="150"
              [headerTooltip]="'CompanyTimeEntryTypes.DescriptionTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [field]="'ExternalReference'"
              [editable]="!IsReadOnly"
              [classField]="'externalReferenceClass'"
              [type]="'autocomplete'"
              [comboboxDataSource]="externalAccounts"
              [comboboxDisplayField]="'FriendlyName'"
              [title]="'CompanyTimeEntryTypes.ExternalReference' | translate"
              [headerTooltip]="'CompanyTimeEntryTypes.ExternalReferenceTooltip' | translate"
              [viewClass]="'truncated'"
              [width]="90"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [field]="'SortOrder'"
              [editable]="!IsReadOnly"
              [classField]="'sortOrderClass'"
              [type]="'numeric'"
              [numbericOption]="numberOption"
              [title]="'CompanyTimeEntryTypes.SortOrder' | translate"
              [width]="75"
              [headerTooltip]="'CompanyTimeEntryTypes.SortOrderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [field]="'DefaultPayslipText'"
              [classField]="'defaultPayslipTextClass'"
              [editable]="!isLanguageModuleEnable"
              [type]="'text'"
              [title]="'CompanyTimeEntryTypes.PayslipText' | translate"
              [viewClass]="'truncated'"
              [width]="100"
              [headerTooltip]="'CompanyTimeEntryTypes.PayslipTextTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [field]="'UnitTypeId'"
              [editable]="!IsReadOnly"
              [classField]="'unitTypeClass'"
              [type]="'combobox'"
              [comboboxDataSource]="unitTypes"
              [comboboxValueField]="'Id'"
              [viewClass]="'truncated'"
              [comboboxDisplayField]="'Name'"
              [title]="'CompanyTimeEntryTypes.UnitType' | translate"
              [width]="70"
              [headerTooltip]="'CompanyTimeEntryTypes.UnitTypeTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [field]="'AllowEditUnitType'"
              [classField]="'allowEditUnitTypeClass'"
              [editable]="!IsReadOnly"
              [type]="'checkbox'"
              [title]="'CompanyTimeEntryTypes.AllowEditUnitType' | translate"
              [width]="90"
              [headerTooltip]="'CompanyTimeEntryTypes.AllowEditUnitTypeTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [field]="'IsIllness'"
              [classField]="'isIllnessClass'"
              [editable]="!IsReadOnly"
              [type]="'checkbox'"
              [title]="'CompanyTimeEntryTypes.IsIllness' | translate"
              [width]="90"
              [headerTooltip]="'CompanyTimeEntryTypes.IsIllnessTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [field]="'SalaryTypeId'"
              [classField]="'salaryTypeClass'"
              [editable]="!IsReadOnly"
              [type]="'combobox'"
              [comboboxDataSource]="salaryTypes"
              [comboboxValueField]="'SalaryTypeId'"
              [comboboxDisplayField]="'Name'"
              [title]="'CompanyTimeEntryTypes.SalaryType' | translate"
              [viewClass]="'truncated'"
              [width]="100"
              [tooltipField]="'ToolTipSalaryType'"
              [editableField]="'editSalaryType'"
              [headerTooltip]="'CompanyTimeEntryTypes.SalaryTypeTooltip' | translate"
            >
            </gridColumn>
          </grid>
        </div>
      </div>
    </div>

    <div class="Actions">
      <action-button
        id="ActionButtonAdd"
        [buttonClass]="'Action-button Action-buttonAdd'"
        [icon]="'Add'"
        [label]="'Company.New'"
        (click)="newCustomTimeEntryDialogVisible = true"
        [disabled]="!hasTimeEntryModule"
        [tooltip]="'CompanyTimeEntryTypes.AddTimeEntryTypeText'"
      >
      </action-button>
      <help-button [module]="'company'"></help-button>
    </div>
  </module-check>
</div>

<company-time-entry-type-translation
  [timeEntryType]="selectedTimeEntryType"
  [(visible)]="translationDialogVisible"
  (translateComplete)="loadTimeEntryTypes($event)"
>
</company-time-entry-type-translation>

<new-custom-time-entry-type-dialog
  [(visible)]="newCustomTimeEntryDialogVisible"
  [defaultTimeEntryTypes]="TimeEntryTypes"
  (add)="addNewTimeEntryType($event)"
>
</new-custom-time-entry-type-dialog>

<time-entry-type-configurations
  [(visible)]="timeEntryTypeConfigurationDialogVisible"
  [timeEntryTypeId]="selectedTimeEntryType?.Id"
  [salaryTypes]="salaryTypes"
>
</time-entry-type-configurations>
