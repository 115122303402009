<app-dialog
  [overlayClose]="false"
  [(visible)]="isVisible"
  class="Employment-viewTaxCardHistoryDataGrid"
  title="{{ 'ViewTaxCardHistory.MainHeadline' | translate }}"
  (action)="onTaxCardHistoryAction($event)"
  id="viewTaxCardHistoryDialog"
>
  <div class="FormElement u-trailer" [innerHTML]="'ViewTaxCardHistory.HighlightExplanatory' | translate"></div>

  <grid
    [editMode]="!IsReadOnly"
    [addNew]="!IsReadOnly && isFullAdmin"
    [deleteRow]="false"
    addNewText="{{ 'Grids.Add New Record' | translate }}"
    [addNewDisable]="sessionService.isGetting"
    [(data)]="gridData"
    [(triggerUpdate)]="refreshData"
    (addNewEvent)="addHandler($event)"
    (updateDefaultRecordEvent)="onUpdateDefaultRecord()"
    (removeEvent)="removeHandler($event)"
    (saveChangesEvent)="saveChanges($event)"
    [filterable]="'menu'"
    [filter]="filter"
    (filterChange)="onFilterChange($event)"
    [isEmployeeTime]="true"
    [idColumn]="'idColumn'"
    [columnIndexFocusAfterCreate]="2"
    [defaultNewValue]="defaultRecordValue"
  >
    <gridColumn
      [isFilterable]="true"
      field="IsActive"
      [filter]="'boolean'"
      type="checkbox"
      class="ViewTaxcardHistory"
      [editableField]="'Editable'"
      title="{{ 'ViewTaxCardHistory.IsActiveColumnHeader' | translate }}"
      [width]="60"
    >
    </gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="IsManual"
      [filter]="'boolean'"
      type="checkbox"
      [editable]="false"
      title="{{ 'ViewTaxCardHistory.IsManualColumnHeader' | translate }}"
      [width]="60"
      [headerTooltip]="'ViewTaxCardHistory.IsManualColumnHeaderTooltip' | translate"
    ></gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="IsRequest"
      [filter]="'boolean'"
      type="checkbox"
      [editable]="false"
      title="{{ 'ViewTaxCardHistory.IsRequestColumnHeader' | translate }}"
      [width]="60"
      [headerTooltip]="'ViewTaxCardHistory.IsRequestColumnHeaderTooltip' | translate"
    ></gridColumn>

    <gridColumn
      [isFilterable]="true"
      field="TaxCardTypeId"
      type="combobox"
      class="ViewTaxcardHistory"
      [comboboxDataSource]="taxCardTypes"
      comboboxValueField="Id"
      comboboxDisplayField="Name"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [editableField]="'Editable'"
      title="{{ 'ViewTaxCardHistory.TaxCardTypeColumnHeader' | translate }}"
      [width]="150"
    ></gridColumn>

    <gridColumn
      [isFilterable]="true"
      filter="date"
      field="ValidFrom"
      type="datepicker"
      class="ViewTaxcardHistory"
      [editableField]="'Editable'"
      title="{{ 'ViewTaxCardHistory.ValidFromColumnHeader' | translate }}"
      [width]="70"
    >
    </gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="ValidUntil"
      type="datepicker"
      class="ViewTaxcardHistory"
      [editableField]="'Editable'"
      title="{{ 'ViewTaxCardHistory.ValidUntilColumnHeader' | translate }}"
      [width]="70"
    >
    </gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="TaxRate"
      type="percentage"
      class="ViewTaxcardHistory"
      [editableField]="'Editable'"
      title="{{ 'ViewTaxCardHistory.TaxRateColumnHeader' | translate }}"
      [percentageDecimals]="false"
      [width]="70"
    ></gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="DeductionMonth"
      type="numeric"
      class="ViewTaxcardHistory"
      [editableField]="'DeductionEditable'"
      title="{{ 'ViewTaxCardHistory.DeductionMonthColumnHeader' | translate }}"
      [width]="130"
      [numbericOption]="NumericOptions"
    ></gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="FreeAmount"
      type="numeric"
      class="ViewTaxcardHistory"
      title="{{ 'ViewTaxCardHistory.FreeAmountColumnHeader' | translate }}"
      [width]="90"
      [numbericOption]="NumericOptions"
      [editableField]="'FreeAmountEditable'"
    ></gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="MinimumRate"
      type="percentage"
      [editable]="false"
      title="{{ 'ViewTaxCardHistory.MinimumRateColumnHeader' | translate }}"
      [width]="80"
    ></gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="MaximumDeductionMonth"
      type="numeric"
      [editable]="false"
      title="{{ 'ViewTaxCardHistory.MaximumDeductionMonthColumnHeader' | translate }}"
      [width]="90"
      [numbericOption]="NumericOptions"
    ></gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="Created"
      type="datepicker"
      class="ViewTaxcardHistory"
      [editable]="false"
      title="{{ 'ViewTaxCardHistory.CreatedColumnHeader' | translate }}"
      [width]="70"
    >
    </gridColumn>
  </grid>

  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
