import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../../Common/Constants';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import { Global } from '../../../../Common/Global';
import { IMonth, IVacationProvider, IVacationType, IVacationTypeGL } from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';
import { VacationModel } from './VacationModel';

@Component({
  selector: 'vacation',
  templateUrl: './Vacation.component.html'
})
export class VacationComponent extends FormComponentBase implements OnInit {
  @Input() public disable: boolean;
  @Input() public get model(): VacationModel {
    if (!this.modelValue) {
      this.modelValue = {} as any;
    }

    return this.modelValue;
  }

  public set model(value: VacationModel) {
    this.modelValue = value;
  }

  public vacationTypes: IVacationType[];
  public vacationProviders: IVacationProvider[];
  public ownCompanyKey = 'Self';
  public months: IMonth[];

  private modelValue: VacationModel;
  public originalVal: any;
  public vacationProviderWarningDialogVisible = false;

  constructor(
    private staticDataService: StaticDataService,
    public sessionService: SessionService,
    public dataService: RxDataService
  ) {
    super();
    this.childIsValid = Array(6).fill(true);
    this.childIsDirty = Array(6).fill(false);
  }

  public ngOnInit(): void {
    this.staticDataService.VacationProvider.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IVacationProvider[]) => (this.vacationProviders = data)
    );
    this.staticDataService.Month.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IMonth[]) => {
      const months: IMonth[] = [];
      const april: IMonth = data ? data.find((month: IMonth) => month.Id === 4) : undefined;
      const may: IMonth = data ? data.find((month: IMonth) => month.Id === 5) : undefined;
      months[0] = april;
      months[1] = may;
      this.months = months;
    });

    if (this.IsGreenlandCompany) {
      this.staticDataService.VacationTypeGL.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        (data: IVacationTypeGL[]) => (this.vacationTypes = data)
      );
    } else {
      this.staticDataService.VacationType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        (data: IVacationType[]) => (this.vacationTypes = data)
      );
    }

    this.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((editMode: boolean) => {
      if (editMode) {
        this.originalVal = this.model.VacationProviderId;
      }
    });

    this.isDirtyChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((dirty: boolean) => {
      if (!dirty) {
        this.model.VacationProviderId = this.originalVal;
      }
    });
  }

  public get IsNoneVacation(): boolean {
    return !this.model || this.model.VacationTypeId < 0;
  }

  public get IsPaidVacation(): boolean {
    return this.model && this.model.VacationTypeId === 1;
  }

  public get IsPaidVacation_GreendLand(): boolean {
    if (this.IsGreenlandCompany && this.model.VacationTypeId !== -1) {
      return true;
    }
    return false;
  }

  public get IsVacationReduceSalaryDisable(): boolean {
    return !this.model || !this.model.VacationTypeId || this.model.VacationTypeId === 1;
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get VacationExtraPercentageLabel(): string {
    if (this.IsGreenlandCompany) {
      return 'EmployeeEmployment.EarningPercentage';
    }
    return 'EmployeeEmployment.Annual supplement';
  }

  public onComboboxVacationTypeChangedEvent(): void {
    this.updateDefaultValueForPaidVacationType();
  }

  private updateDefaultValueForPaidVacationType(): void {
    if (this.model && this.model.VacationTypeId === 1) {
      // Paid vacation type.
      this.model.VacationReduceSalary = true;
      this.model.VacationSupplementPayoutMonth = this.model.VacationSupplementPayoutMonth
        ? this.model.VacationSupplementPayoutMonth
        : 5; // 5 - May.
      this.model.VacationExtraPercentage = this.model.VacationExtraPercentage
        ? this.model.VacationExtraPercentage
        : 0.01; // 1 percent.
    }

    if (this.IsGreenlandCompany && this.model && this.model.VacationTypeId === 2) {
      this.model.VacationExtraPercentage = 0.12;
    }

    this.onChange();
  }

  public onComboboxVacationProviderValueChange(): void {
    const vacationProvider: IVacationProvider = this.vacationProviders.find(
      (model: IVacationProvider) => model.Id === this.model.VacationProviderId
    );
    if (vacationProvider && vacationProvider.Key === this.ownCompanyKey) {
      this.vacationProviderWarningDialogVisible = true;
    } else {
      this.originalVal = this.model.VacationProviderId;
      this.onChange();
    }
  }

  public onVacationProviderWarningDialogAction(action: string): void {
    if (action !== 'Proceed') {
      this.model.VacationProviderId = this.originalVal;
    } else {
      this.originalVal = this.model.VacationProviderId;
    }

    this.onChange();
  }
}
