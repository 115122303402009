<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeGeneral.EmployeeBenefits' | translate }}</legend>
  </fieldset>

  <module-check [moduleId]="6">
    <div class="Card-footer Card-benefit" style="margin-top: 0">
      <span class="Control--wrapper">
        <check-edit [label]="'LogBuy'" [editMode]="false" [(value)]="isEmployeeBenefitModuleActive"></check-edit>
      </span>

      <a
        *ngIf="isEmployeeBenefitModuleActive && !isIOSApp"
        target="_blank"
        class="Button--card"
        href="https://www.mylogbuy.com/WebPages/Login/Login.aspx?ReturnUrl=L1dlYlBhZ2VzL0RlZmF1bHQuYXNweA%3d%3d"
        >{{ 'EmployeeGeneral.GetYourBenefits' | translate }}</a
      >
    </div>

    <div class="Card-footer Card-benefit" style="margin-top: 0">
      <span class="Control--wrapper">
        <check-edit [label]="'MenuPay'" [editMode]="false" [(value)]="isEmployeeBenefitModuleActive"></check-edit>
      </span>

      <a
        *ngIf="isEmployeeBenefitModuleActive && !isIOSApp"
        target="_blank"
        class="Button--card"
        href="https://menucard.dk/logind"
        >{{ 'EmployeeGeneral.GetYourBenefits' | translate }}</a
      >
    </div>
  </module-check>

  <a
    *ngIf="!isEmployeeBenefitModuleActive && !isIOSApp"
    target="_blank"
    class="Button--card"
    href="https://support.gratisal.com/hc/da/articles/207668179-Medarbejderfordele"
    >{{ 'GlobalDialog.ReadMore' | translate }}</a
  >
</form>
