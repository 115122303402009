import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../../Common/Global';
import { SessionService } from './Session/SessionService';

export enum LoadingIndicatorTextType {
  RECALCULATE,
  FINALIZE
}

@Injectable()
export class BusyIndicatorService implements OnDestroy {
  public isVisible: boolean;
  public busyIndicatorText: string;

  private recalulatingText: string;
  private recalculatingHint: string;
  private finalizingText: string;
  private isShowHint: boolean;
  private isTextOverride: boolean;
  private textType: LoadingIndicatorTextType;

  constructor(private translateService: TranslateService, private sessionService: SessionService) {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) =>
      this.applyTranslations()
    );
    this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: string) =>
      this.checkState()
    );
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private applyTranslations(): void {
    const langCode =
      Global.SESSION && Global.SESSION.SignOnToken.Language
        ? Global.SESSION.SignOnToken.Language.CultureCode.split('-')[0]
        : undefined;
    if (this.translateService.currentLang !== langCode) {
      this.translateService.use(langCode).subscribe(() => {
        this.translateText();
      });
    } else {
      this.translateText();
    }
  }

  private translateText(): void {
    this.translateService
      .get([
        'CompanySalaryBatches.RecalculatingDraft',
        'CompanySalaryBatches.CalculatingDraftHint',
        'CompanySalaryBatches.Finalizing'
      ])
      .subscribe((translations: { [key: string]: string }) => {
        this.recalulatingText = translations['CompanySalaryBatches.RecalculatingDraft'];
        this.recalculatingHint = translations['CompanySalaryBatches.CalculatingDraftHint'];
        this.finalizingText = translations['CompanySalaryBatches.Finalizing'];

        if (this.isVisible && !this.isTextOverride) {
          const loadingText: string = this.getLoadingText();
          this.busyIndicatorText = loadingText ? loadingText : '';
        }
      });
  }

  private checkState(): void {
    if (this.sessionService.currentState !== 'tabs.company.salarybatches') {
      this.setLoadingIndicatorVisibility(false);
    }
  }

  public setLoadingIndicatorVisibility(
    isVisible: boolean,
    textType?: LoadingIndicatorTextType,
    overrideText?: string,
    showCalculatingHint?: boolean
  ): void {
    if (isVisible) {
      this.isTextOverride = overrideText ? true : false;
      this.isShowHint = showCalculatingHint;
      this.textType = textType;
    }

    if (!overrideText) {
      overrideText = this.getLoadingText();
    }

    setTimeout(() => {
      this.busyIndicatorText = overrideText;
      this.isVisible = isVisible;
    });
  }

  private getLoadingText(): string {
    const calculatingText: string =
      (this.recalulatingText ? this.recalulatingText : '') +
      (this.isShowHint && this.recalculatingHint ? '<br/>' + this.recalculatingHint : '');
    return (this.textType === LoadingIndicatorTextType.RECALCULATE ? calculatingText : this.finalizingText) || '';
  }
}
