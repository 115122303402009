import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Department, ICompany, ICompanyUser, IDepartment } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'app-company-department',
  templateUrl: './CompanyDepartment.component.html'
})
export class CompanyDepartmentComponent implements OnInit, OnDestroy {
  @Input() public company: ICompany;

  private companyContacts: ICompanyUser[];
  @Input()
  public get contacts(): ICompanyUser[] {
    return this.companyContacts;
  }

  public set contacts(value: ICompanyUser[]) {
    if (value && this.companyContacts !== value) {
      this.companyContacts = value;
      this.getDepartments();
    }
  }

  public deleteWarningVisible = false;
  public selectedDepartment: IDepartment;

  private showInactiveDepartmentsValue = false;
  public get showInactiveDepartments(): boolean {
    return this.showInactiveDepartmentsValue;
  }
  public set showInactiveDepartments(value: boolean) {
    this.showInactiveDepartmentsValue = value;
    this.getDepartments();
    ////if (this.allDepartments) {
    ////    this.departments = this.showInactiveDepartmentsValue ? this.allDepartments : this.allDepartments.filter((d: IDepartment) => d.IsActive);
    ////}
  }

  private allDepartments: IDepartment[];
  public departments: IDepartment[] = [];

  public ngOnInit(): void {}

  public ngOnDestroy(): void {}
  constructor(
    private dataService: RxDataService,
    private staticDataService: StaticDataService,
    public sessionService: SessionService
  ) {}

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly || this.sessionService.role.IsSalaryAdmin;
  }

  public onAddNew(newDepartment: IDepartment): void {
    (newDepartment as any).GridId = this.departments.length + 2;
    newDepartment.CompanyId = this.company.Id;
    newDepartment.IsActive = true;
  }

  private gridRemoveRowArgs: any;
  public onRemoveDepartment(e: any): void {
    // if (e && e.Id) {
    this.gridRemoveRowArgs = e;
    this.deleteWarningVisible = true;
    // } else {
    //     // let dataIndex: any = this.departments.indexOf(e);
    //     // this.departments.splice(dataIndex, 1);
    //     let dataIndex: any = this.gridData.data.indexOf(e);
    //     this.gridData.data.splice(dataIndex, 1);
    // }
  }

  public onDeleteWarningAction(action: string): void {
    if (action === 'Delete' && this.gridRemoveRowArgs) {
      if (this.gridRemoveRowArgs.dataItem.Id > 0) {
        this.dataService.Companies_DeleteDepartment(this.gridRemoveRowArgs.dataItem.Id).subscribe(
          () => {
            this.removeDeletedItem();
            this.staticDataService.loadDepartments();
            // if (this.showInactiveDepartments) {
            this.getDepartments();
            // }
          },
          () => {
            this.staticDataService.loadDepartments();
          }
        );
      } else {
        this.removeDeletedItem();
      }
    }
  }

  private removeDeletedItem(): void {
    const indexOfRemovedItem: number = this.departments.indexOf(this.gridRemoveRowArgs.dataItem);
    this.departments.splice(indexOfRemovedItem, 1);
    this.gridRemoveRowArgs = undefined;
    this.staticDataService.loadDepartments();
    this.getDepartments();
  }

  public onSave(args: CellCloseEvent): void {
    const department: any = args.dataItem ? args.dataItem : args;
    this.saveDataItem(department);
  }

  private getDepartments(): void {
    this.dataService.Companies_GetDepartments().subscribe((departments: IDepartment[]): void => {
      let gridIdCount = 1;
      this.allDepartments = departments.map((dep: any) => {
        dep.GridId = gridIdCount++;
        return dep;
      });

      this.departments = this.showInactiveDepartmentsValue
        ? this.allDepartments
        : this.allDepartments.filter((d: IDepartment) => d.IsActive);
      this.gridData = filterBy(this.departments, this.filter);
    });
  }

  public refreshData = false;
  private keyPress: any = { 17: false, 18: false, 107: false, 109: false };
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: any): void {
    if (!this.IsReadOnly) {
      if (event.keyCode in this.keyPress) {
        this.keyPress[event.keyCode] = true;
        if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
          const defaultDataItem: any = { Name: '', CompanyId: this.company.Id, IsActive: true };
          this.onAddNew(defaultDataItem);
          this.setKeypress();
          this.departments.push(defaultDataItem);
          this.saveDataItem(defaultDataItem);
          this.refreshData = true;
          this.gridData = filterBy(this.departments, this.filter);
        } else if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
          if (this.selectedDepartment) {
            const item: any = {};
            item.dataItem = this.selectedDepartment;
            this.onRemoveDepartment(item);
            this.setKeypress();
            this.refreshData = true;
            this.selectedDepartment = undefined;
          }
        }
      } else {
        this.setKeypress();
      }
    }
  }

  private setKeypress(): void {
    this.keyPress[17] = false;
    this.keyPress[18] = false;
    this.keyPress[107] = false;
    this.keyPress[109] = false;
  }

  public saveDataItem(department: IDepartment): void {
    let newDepartmentRecord = new Department();
    newDepartmentRecord = this.createDepartmentModel(department);
    // department = this.staticDataService.checkModelRecord(department);
    // Object.assign(newDepartmentRecord, department);

    if (newDepartmentRecord.Id) {
      this.dataService.Companies_UpdateDepartment(newDepartmentRecord).subscribe(() => {
        this.staticDataService.loadDepartments();
      });
    } else {
      this.dataService.Companies_CreateDepartment(newDepartmentRecord).subscribe((data: any): void => {
        department.Id = data.Id;
        this.staticDataService.loadDepartments();
      });
    }
  }

  public createDepartmentModel(object: IDepartment): IDepartment {
    return {
      Id: object.Id || null,
      CompanyId: object.CompanyId || null,
      Name: object.Name || null,
      Description: object.Description || null,
      IsActive: object.IsActive || false,
      EIncomeReference: object.EIncomeReference || null,
      ExternalReference: object.ExternalReference || null,
      ManagerCompanyUserId: object.ManagerCompanyUserId || null
    };
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = filterBy(this.departments, this.filter);
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.departments, filter);
  }
}
