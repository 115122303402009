import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { Global } from '../../Common/Global';
import { KeyDownHelper } from '../../Common/KeyDownHelper';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { EmployeeList } from './Controls/EmployeeList';
var EmployeeListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeListComponent, _super);
    function EmployeeListComponent(dataService, settingService, sessionService, staticDataService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.selectedEmployeeChange = new EventEmitter();
        _this.preLoadAvatar = new EventEmitter();
        _this.addEmployee = new EventEmitter();
        _this.refreshEmployeesChange = new EventEmitter();
        _this.refreshEmployeeChange = new EventEmitter();
        _this.globalKeyDownChange = new EventEmitter();
        _this.searchKeyWord = '';
        _this.includeInactive = false;
        _this.hasDepartmentFilter = true;
        _this.totalEmployee = 0;
        _this.departmentId = -1;
        _this.sortBy = 1;
        _this.isUpdate = false;
        // IsResettingDataSource: true -> load detail, but don't collapse search filter on mobile screen.
        _this.isResettingDataSource = false;
        // If isSearching is true, then prevent loading detail because of resetting grid datasource.
        _this.isSearching = false;
        _this.disabled = false;
        return _this;
        // TODO: find a way to handle the following events with databinding:
        // 1. Update companyUser event
        // 2. Add companyUser event
        // 3. Delete companyUser event
    }
    Object.defineProperty(EmployeeListComponent.prototype, "isAvatarChanged", {
        get: function () {
            return this.isAvatarChangedValue;
        },
        set: function (value) {
            this.disabled = false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "newEmployee", {
        get: function () {
            return this.newlyCreatedCompanyUser;
        },
        set: function (value) {
            if (value) {
                this.sessionService.currentUserEmploymentId = undefined;
                this.newlyCreatedCompanyUser = value;
                this.addNewCompanyUser(value);
                this.selectEmployee(value.Id);
                setTimeout(function () { return document.querySelector('.k-state-selected').scrollIntoView(false); });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "deletedEmployee", {
        get: function () {
            return this.deletedId;
        },
        set: function (value) {
            if (value) {
                this.removeCompanyUser(value);
                this.setEmployeeDataSource();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "refreshEmployees", {
        get: function () {
            return this.refreshEmployeesEvent;
        },
        set: function (value) {
            var _this = this;
            if (value) {
                this.loadEmployeeList(true);
            }
            this.refreshEmployeesEvent = false;
            setTimeout(function () { return _this.refreshEmployeesChange.emit(_this.refreshEmployeesEvent); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "refreshEmployee", {
        get: function () {
            return this.refreshEmployeeData;
        },
        set: function (employee) {
            var _this = this;
            if (employee) {
                this.onEmployeeUpdated(employee);
            }
            this.refreshEmployeeData = undefined;
            setTimeout(function () { return _this.refreshEmployeeChange.emit(_this.refreshEmployeeData); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "globalKeyDown", {
        get: function () {
            return this.globalKeyDownObject;
        },
        set: function (value) {
            var _this = this;
            if (value) {
                this.onGlobalKeyDown(value);
            }
            this.globalKeyDownObject = undefined;
            setTimeout(function () { return _this.globalKeyDownChange.emit(_this.globalKeyDownObject); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "selectedItem", {
        get: function () {
            return this.selectedItemValue;
        },
        set: function (value) {
            this.selectedItemValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "isMobileApp", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadEmployeeList();
        this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (state) {
            // setTimeout(() => {
            //     if (this.IsActive) {
            //         this.loadEmployeeList();
            //         if (this.settingService.navigationParameters["EmployeeId"] && this.companyUsers && this.companyUsers.length > 0) {
            //             this.selectCompanyUserByParam();
            //         }
            //     }
            // });
            if (_this.IsActive) {
                _this.loadEmployeeList();
                if (_this.settingService.navigationParameters['EmployeeId'] &&
                    _this.companyUsers &&
                    _this.companyUsers.length > 0) {
                    _this.selectCompanyUserByParam();
                }
            }
        });
    };
    Object.defineProperty(EmployeeListComponent.prototype, "IsActive", {
        get: function () {
            return this.sessionService.currentState.indexOf('tabs.employee') >= 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "IsEmployeeRole", {
        get: function () {
            return this.sessionService.role.IsEmployeeRole;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "allowEditUserEmployment", {
        get: function () {
            return (this.sessionService.feature.AllowEditUserEmployment(this.sessionService.currentState) &&
                !this.sessionService.role.IsReadOnly);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "employeeListVisible", {
        get: function () {
            return this.sessionService.feature.ShowEmployeeList;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeListComponent.prototype.onGlobalKeyDown = function ($event) {
        if (!this.IsActive) {
            return;
        }
        if (KeyDownHelper.isFocusEmployeeListShortcutKey($event)) {
            $event.preventDefault();
            this.selectEmployee(this.selectedItem.Id);
            return;
        }
        if (KeyDownHelper.isSelectNextEmployeeShortcutKey($event)) {
            $event.preventDefault();
            this.navigateUpDownCompanyUser(false);
            return;
        }
        if (KeyDownHelper.isSelectPreviousEmployeeShortcutKey($event)) {
            $event.preventDefault();
            this.navigateUpDownCompanyUser(true);
        }
    };
    EmployeeListComponent.prototype.selectCompanyUserByParam = function () {
        var _this = this;
        if (this.companyUsers && this.settingService.navigationParameters['EmployeeId']) {
            var compUser = this.companyUsers.find(function (user) { return user.Id.toString() === _this.settingService.navigationParameters['EmployeeId'].toString(); });
            this.settingService.navigationParameters['EmployeeId'] = undefined;
            if (compUser && compUser.Id) {
                this.selectedItem = compUser;
                this.fireCompanyUserSelectionEvent(compUser.Id);
            }
        }
    };
    EmployeeListComponent.prototype.loadEmployeeList = function (forceReload) {
        if (forceReload === void 0) { forceReload = false; }
        if (this.settingService.navigationParameters['EmployeeId']) {
            this.parameterNavigationEmployeeId = this.settingService.navigationParameters['EmployeeId'];
            this.settingService.navigationParameters['EmployeeId'] = undefined;
        }
        this.initializeEmployeesGrid(forceReload);
        this.initializeHelpers();
    };
    EmployeeListComponent.prototype.onDepartmentChanged = function (value) {
        var _this = this;
        setTimeout(function () {
            _this.departmentId = value;
            _this.onFilterControlChanged();
        });
    };
    EmployeeListComponent.prototype.onSortByChanged = function (value) {
        this.sortBy = value;
        this.onFilterControlChanged();
    };
    EmployeeListComponent.prototype.initializeEmployeesGrid = function (forceReload) {
        if (forceReload === void 0) { forceReload = false; }
        // Populate grid datasource and set data for viewcompanyUsers
        this.getCompanyUsers(undefined, forceReload);
    };
    EmployeeListComponent.prototype.initializeHelpers = function () {
        ////this.GridCompanyUserHelper = new GridHelper();
        ////this.GridCompanyUserHelper.enableKeysShortcuts(this.controls.GridCompanyUser, true, false, false);
        ////ComboBoxHelper.enableAutoCompleteOn(["#comboboxDepartment", "#comboboxSort"]);
    };
    // Use for Update companyUser event
    EmployeeListComponent.prototype.onEmployeeUpdated = function (employee) {
        var companyUser;
        if (this.companyUsers) {
            companyUser = this.companyUsers.find(function (us) {
                return us.Id === employee.Id;
            });
        }
        companyUser.FirstName = employee.FirstName;
        companyUser.MiddleName = employee.MiddleName;
        companyUser.LastName = employee.LastName;
        companyUser.FullName = employee.FullName;
        companyUser.IsActive = employee.IsActive;
        // Select department all; check include inactive checkbox.
        if (!companyUser.IsActive) {
            this.includeInactive = true;
        }
        if (employee.UserEmployments &&
            employee.UserEmployments.length > 0 &&
            companyUser.UserEmployments &&
            companyUser.UserEmployments.length) {
            employee.UserEmployments.forEach(function (updatedEmployment) {
                var employment = companyUser.UserEmployments.find(function (e) { return e.Id === updatedEmployment.Id; });
                if (employment) {
                    companyUser.UserEmployments[companyUser.UserEmployments.indexOf(employment)] = updatedEmployment;
                }
            });
        }
        this.isUpdate = true;
        this.companyUser = companyUser;
        // this.setEmployeeDataSource(employee.Id);
        this.onIncludeActiveEmployeesChecked(this.includeInactive);
    };
    EmployeeListComponent.prototype.selectEmployee = function (employeeId) {
        if (!this.companyUsers) {
            // Return if company users are not loaded yet.
            this.parameterNavigationEmployeeId = employeeId;
            return;
        }
        this.getCompanyUsers(employeeId);
    };
    // Use for Add companyUser event
    EmployeeListComponent.prototype.addNewCompanyUser = function (companyUser) {
        if (companyUser && companyUser.Id) {
            var existUser = this.companyUsers.find(function (tempCompany) {
                return tempCompany.Id === companyUser.Id;
            });
            if (!existUser) {
                this.companyUsers.push(companyUser);
                ////this.companyUsers = this.companyUsers.filter((model: ICompanyUser) => { return model.Id !== -1; });
            }
            else {
                existUser = companyUser;
            }
            this.companyUser = companyUser;
        }
    };
    // Used for Delete companyUser event
    EmployeeListComponent.prototype.removeCompanyUser = function (companyUserId) {
        var _this = this;
        if (this.companyUsers) {
            this.companyUsers.forEach(function (companyUser, index) {
                if (companyUser.Id === companyUserId) {
                    _this.companyUsers.splice(index, 1);
                }
            });
        }
    };
    EmployeeListComponent.prototype.onCloseEmployeeFilterEventClick = function () {
        this.sessionService.employeeListActive = false;
    };
    EmployeeListComponent.prototype.onFilterControlChanged = function () {
        var selectedEmployeeId;
        if (!this.searchKeyWord) {
            selectedEmployeeId = this.companyUser ? this.companyUser.Id : undefined;
        }
        this.setEmployeeDataSource(selectedEmployeeId);
    };
    EmployeeListComponent.prototype.onSearchKeyDown = function (e) {
        if (!e || e.keyCode === undefined) {
            return;
        }
        this.isSearching = true;
        if (e.keyCode === 9 || e.keyCode === 13) {
            // Search completed.
            this.isSearching = false;
            if (this.companyUserListDataSource && this.companyUserListDataSource.length > 0) {
                this.sessionService.employeeListActive = false;
            }
            this.onEmployeeSearchConfirm();
        }
        else if (e.keyCode === 38) {
            // Navigate up between search results.
            this.navigateUpDownCompanyUser(true);
            e.preventDefault();
        }
        else if (e.keyCode === 40) {
            // Navigate down between search results.
            this.navigateUpDownCompanyUser(false);
            e.preventDefault();
        }
        else {
            this.onSearchChanged();
        }
    };
    EmployeeListComponent.prototype.onSearchChanged = function () {
        var selectedEmployeeId;
        if (!this.searchKeyWord) {
            // Reselect the last Employee in case of empty search keyword.
            this.isSearching = true;
            selectedEmployeeId = this.companyUser ? this.companyUser.Id : undefined;
        }
        this.setEmployeeDataSource(selectedEmployeeId);
    };
    EmployeeListComponent.prototype.onSearchBlur = function (e) {
        var _this = this;
        setTimeout(function () {
            _this.onEmployeeSearchConfirm();
            _this.onGridSelectionChange(_this.selectedItem);
        }, 300);
    };
    EmployeeListComponent.prototype.onEmployeeSearchConfirm = function () {
        this.searchKeyWord = '';
        this.isSearching = false;
        this.setEmployeeDataSource(this.selectedItem ? this.selectedItem.Id : undefined);
    };
    EmployeeListComponent.prototype.onIncludeActiveEmployeesChecked = function (includeInactive) {
        if (this.includeInactive === false && this.companyUser.IsActive === false) {
            var companyUserIndex = this.companyUserListDataSource.indexOf(this.companyUser);
            if (companyUserIndex) {
                this.companyUserListDataSource.splice(companyUserIndex, 1);
                this.selectedItem = this.companyUserListDataSource[0];
                this.getCompanyUsers(undefined, true);
            }
        }
        else {
            if (!this.sessionService.currentUserEmploymentId ||
                (this.sessionService.currentUserEmploymentId &&
                    this.sessionService.currentUserEmploymentId !== this.companyUser.Id)) {
                this.sessionService.currentUserEmploymentId = this.companyUser.Id;
            }
            this.getCompanyUsers(undefined, true);
        }
    };
    EmployeeListComponent.prototype.setEmployeeDataSource = function (selectedCompanyUserId) {
        var _this = this;
        setTimeout(function () {
            if (!_this.companyUsers) {
                return;
            }
            _this.isResettingDataSource = true;
            _this.companyUserListDataSource = _this.searchEmployees();
            _this.totalEmployee = _this.companyUserListDataSource.length;
            if (_this.companyUserListDataSource && _this.companyUserListDataSource.length > 0) {
                // Select relevant row
                var companyUser = _this.getDefaultCompanyUser(_this.companyUserListDataSource, selectedCompanyUserId);
                _this.selectedItem = companyUser
                    ? companyUser
                    : _this.selectedItem &&
                        _this.companyUserListDataSource.find(function (model) { return model.Id === _this.selectedItem.Id; })
                        ? _this.selectedItem
                        : _this.companyUserListDataSource[0];
                // if (this.selectedItem && this.selectedItem.Id && this.selectedItem.Id !== this.currentCompanyUserId) {
                //   this.fireCompanyUserSelectionEvent(this.selectedItem.Id);
                // }
                setTimeout(function () {
                    return document.querySelector('.k-state-selected')
                        ? document.querySelector('.k-state-selected').scrollIntoView(false)
                        : null;
                });
            }
        });
    };
    EmployeeListComponent.prototype.getDefaultCompanyUser = function (result, selectedCompanyUserId) {
        var _this = this;
        if (this.sessionService.currentUserEmploymentId) {
            var comUser = this.getCompanyUserByUserEmploymentId(this.sessionService.currentUserEmploymentId, selectedCompanyUserId);
            return comUser;
        }
        else {
            var companyUser = void 0;
            if (Global.EMPLOYEESELECT && Global.EMPLOYEESELECT.CompanyId === Global.COMPANY_ID) {
                if (selectedCompanyUserId) {
                    companyUser = result.find(function (tempSelectedCompanyUser) {
                        return tempSelectedCompanyUser.Id === selectedCompanyUserId;
                    });
                }
                else if (this.parameterNavigationEmployeeId) {
                    companyUser = result.find(function (tempParameterNavigationEmployee) {
                        return tempParameterNavigationEmployee.Id === _this.parameterNavigationEmployeeId;
                    });
                    if (companyUser) {
                        return companyUser;
                    }
                }
                else if (this.companyUser && this.companyUser.Id) {
                    companyUser = result.find(function (tempcompanyUser) {
                        return tempcompanyUser.Id === _this.companyUser.Id;
                    });
                    if (companyUser) {
                        return companyUser;
                    }
                }
                else {
                    companyUser = result.find(function (tempcompanyUser) {
                        return tempcompanyUser.Id === Global.EMPLOYEESELECT.Id;
                    });
                }
                if (companyUser) {
                    return companyUser;
                }
            }
            return result[0];
        }
    };
    EmployeeListComponent.prototype.searchEmployees = function () {
        var employeeList = new EmployeeList(this.companyUsers);
        return employeeList.search(this.searchKeyWord, this.departmentId, this.sortBy.toString());
    };
    EmployeeListComponent.prototype.navigateUpDownCompanyUser = function (up) {
        var dataSourceLength = this.companyUserListDataSource ? this.companyUserListDataSource.length : 0;
        if (dataSourceLength > 0) {
            var nextItemIndex = (this.selectedItem ? this.companyUserListDataSource.indexOf(this.selectedItem) : 0) + (up ? -1 : 1);
            nextItemIndex = this.calculateCompanyUserIndex(dataSourceLength - 1, nextItemIndex);
            this.selectedItem = this.companyUserListDataSource[nextItemIndex];
        }
    };
    EmployeeListComponent.prototype.calculateCompanyUserIndex = function (maxIndex, estimatedIndex) {
        // Zero based index.
        var index = estimatedIndex;
        if (estimatedIndex < 0) {
            index = maxIndex;
        }
        else if (estimatedIndex > maxIndex) {
            index = 0;
        }
        return index;
    };
    EmployeeListComponent.prototype.getCompanyUsers = function (selectedEmployeeId, forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        if (this.includeInactive) {
            this.dataService
                .CompanyUsers_GetAllCompanyUsers()
                .subscribe(function (data) { return _this.onGetCompanyUsersCompleted(data, selectedEmployeeId); });
        }
        else {
            // if (forceReload) {
            //   this.staticDataService.loadCompanyUsers();
            //   return;
            // }
            this.staticDataService.companyUsers
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(function (data) { return _this.onGetCompanyUsersCompleted(data, selectedEmployeeId); });
        }
    };
    EmployeeListComponent.prototype.onGetCompanyUsersCompleted = function (data, companyUserId) {
        var filterData = data;
        if (this.sessionService.role.EmployeeWithRoleAsManager && Global.SESSION) {
            filterData = data.filter(function (companyUser) {
                return companyUser.Id !== Global.SESSION.CurrentCompanyUser.Id;
            });
        }
        this.companyUsers = filterData;
        this.setEmployeeDataSource(companyUserId);
    };
    EmployeeListComponent.prototype.onGridSelectionChange = function (companyUser) {
        if (!this.isSearching) {
            if (companyUser && companyUser.Id && companyUser.Id !== this.currentCompanyUserId) {
                this.currentCompanyUserId = companyUser.Id;
                this.fireCompanyUserSelectionEvent(companyUser.Id);
            }
            this.settingService.stayEmploymentId =
                companyUser && companyUser.StayEmploymentId ? companyUser.StayEmploymentId : undefined;
            if (!this.isResettingDataSource) {
                // Close the employee list if it is active in small resolution
                this.onCloseEmployeeFilterEventClick();
            }
        }
        this.isResettingDataSource = false;
        this.isSearching = false;
    };
    EmployeeListComponent.prototype.onClickSelectEmployee = function () {
        if (this.companyUser) {
            this.onCloseEmployeeFilterEventClick();
        }
    };
    EmployeeListComponent.prototype.fireCompanyUserSelectionEvent = function (companyUserId) {
        var _this = this;
        this.disabled = true;
        this.dataService.CompanyUsers_GetCompanyUserById(companyUserId).subscribe(function (companyUser) {
            _this.companyUser = companyUser;
            _this.preLoadAvatar.emit(true);
            _this.selectedEmployeeChange.emit(companyUser);
            _this.sessionService.currentUserEmploymentId = undefined;
        });
    };
    EmployeeListComponent.prototype.onAddEmployeeClicked = function () {
        this.addEmployee.emit();
    };
    EmployeeListComponent.prototype.getCompanyUserByUserEmploymentId = function (userEmploymentId, selectedCompanyUserId) {
        if (this.isUpdate) {
            var temp = this.companyUsers.find(function (companyUser) { return companyUser.Id === selectedCompanyUserId; });
            return temp;
        }
        else {
            var a_1;
            this.companyUsers.forEach(function (companyUser, index) {
                if (companyUser.UserEmployments) {
                    var b = companyUser.UserEmployments.find(function (us) { return us.Id === userEmploymentId; });
                    if (b) {
                        a_1 = companyUser;
                    }
                }
            });
            return a_1;
        }
    };
    return EmployeeListComponent;
}(FormComponentBase));
export { EmployeeListComponent };
