/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../SharedComponents/ModuleCheck/ModuleCheck.component.ngfactory";
import * as i3 from "../../SharedComponents/ModuleCheck/ModuleCheck.component";
import * as i4 from "../../Services/SharedServices/Session/SessionService";
import * as i5 from "../../Services/SharedServices/StaticData.service";
import * as i6 from "../../Services/RxDataService";
import * as i7 from "../../Services/Authentication/AuthenticationService";
import * as i8 from "../../Filters/PriceFormat.pipe";
import * as i9 from "../Icon/Icon.component.ngfactory";
import * as i10 from "../Icon/Icon.component";
import * as i11 from "@ngx-translate/core";
import * as i12 from "./RadioEdit.component";
var styles_RadioEditComponent = [];
var RenderType_RadioEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RadioEditComponent, data: {} });
export { RenderType_RadioEditComponent as RenderType_RadioEditComponent };
function View_RadioEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "label", [["class", "FormElement-radio"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "input", [["type", "radio"]], [[8, "id", 0], [8, "checked", 0], [8, "name", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "FormElement-radioFaux"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "FormElement-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.value; var currVal_1 = (_co.value === _v.context.$implicit.value); var currVal_2 = _co.controlId; var currVal_3 = (_co.disable || !_co.editMode); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.label; _ck(_v, 4, 0, currVal_4); }); }
function View_RadioEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "FormElement-control"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 2, 0, currVal_0); }, null); }
function View_RadioEditComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "FormElement-static"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "label", [["class", "FormElement-radio"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "input", [["type", "radio"]], [[8, "id", 0], [8, "checked", 0], [8, "name", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.parent.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "FormElement-radioFaux"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "FormElement-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.value; var currVal_1 = (_co.value === _v.parent.context.$implicit.value); var currVal_2 = _co.controlId; var currVal_3 = (_co.disable || !_co.editMode); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.parent.context.$implicit.label; _ck(_v, 5, 0, currVal_4); }); }
function View_RadioEditComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "FormElement-static"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "module-check", [], null, null, null, i2.View_ModuleCheckComponent_0, i2.RenderType_ModuleCheckComponent)), i0.ɵdid(2, 245760, null, 0, i3.ModuleCheckComponent, [i4.SessionService, i5.StaticDataService, i6.RxDataService, i7.AuthenticationService, i8.PriceFormatPipe], { moduleId: [0, "moduleId"], dialogWidth: [1, "dialogWidth"] }, null), (_l()(), i0.ɵeld(3, 0, null, 0, 4, "label", [["class", "FormElement-radio"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "input", [["type", "radio"]], [[8, "id", 0], [8, "checked", 0], [8, "name", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.parent.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "FormElement-radioFaux"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "FormElement-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.ModuleId; var currVal_1 = _v.parent.context.$implicit.moduleCheckWidth; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.parent.context.$implicit.value; var currVal_3 = (_co.value === _v.parent.context.$implicit.value); var currVal_4 = _co.controlId; var currVal_5 = !_co.editMode; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _v.parent.context.$implicit.label; _ck(_v, 7, 0, currVal_6); }); }
function View_RadioEditComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "FormElement-addon FormElement-addonSpace Icon-addOn"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-icon", [], null, null, null, i9.View_IconComponent_0, i9.RenderType_IconComponent)), i0.ɵdid(2, 49152, null, 0, i10.IconComponent, [i0.ElementRef, i4.SessionService, i0.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"], link: [2, "link"] }, null), i0.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "Question"; var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 3).transform("GlobalDialog.ReadMore")); var currVal_2 = _v.parent.context.$implicit.addonLink; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_RadioEditComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "FormElement-control"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_5)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_6)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_7)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.isCheckModule; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.isCheckModule; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.addonLink; _ck(_v, 6, 0, currVal_2); }, null); }
function View_RadioEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_4)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RadioEditComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.vertical; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.vertical; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RadioEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-radio-edit", [], null, null, null, View_RadioEditComponent_0, RenderType_RadioEditComponent)), i0.ɵdid(1, 49152, null, 1, i12.RadioEditComponent, [i0.ElementRef, i0.ChangeDetectorRef], null, null), i0.ɵqud(603979776, 1, { options: 1 })], null, null); }
var RadioEditComponentNgFactory = i0.ɵccf("app-radio-edit", i12.RadioEditComponent, View_RadioEditComponent_Host_0, { defaultnullvalue: "defaultnullvalue", isGrid: "isGrid", preventChange: "preventChange", value: "value", editMode: "editMode", isDirty: "isDirty", isValid: "isValid", setTimeout: "setTimeout", hostClass: "hostClass", vertical: "vertical", disable: "disable" }, { valueChange: "valueChange", editModeChange: "editModeChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange" }, []);
export { RadioEditComponentNgFactory as RadioEditComponentNgFactory };
