import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Global } from '../../Common/Global';
import { NumericTextBoxOptions } from '../../CustomControls/NumericTextBoxOptions';
import {
  ICompanyUser,
  IDepartment,
  IReport,
  IReportArea,
  IReportParameter,
  IReportParameterValue,
  IReportRequest,
  ISalaryCycle
} from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { DownloadService } from '../../Services/SharedServices/DownloadService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { ReportParametersDefaultValue } from './ReportParametersDefaultValue';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './ReportDialog.component.html'
})
export class ReportDialogComponent implements OnInit, OnDestroy {
  @Input() public param: ReportParametersDefaultValue;

  private keyValue: string;
  @Input()
  public get key(): string {
    return this.keyValue;
  }
  public set key(value: string) {
    if (this.keyValue !== value) {
      this.keyValue = value;
      this.reports = undefined;
    }
  }

  private visibleValue = false;
  public noReportVisible = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      if (this.isMobile) {
        this.selectedReport = undefined;
      }
      this.gridVisible = true;
      this.visibleValue = value;
      if (value) {
        if (!this.reports) {
          this.getReports();
        }
        this.getSelectedReport();
      }
      this.visibleChange.emit(value);
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private selectedReportValue: IReport;
  public get selectedReport(): IReport {
    return this.selectedReportValue;
  }
  public set selectedReport(value: IReport) {
    if (value) {
      this.gridVisible = false;
    }
    this.selectedReportValue = value;
    this.selectedFormat = undefined;
    this.createReportControls();
  }

  public reports: IReport[];

  public get formatOptions(): string[] {
    let options: string[] = [];
    if (this.selectedReport) {
      options = this.selectedReport.SupportedFormats.split(',').sort((a: string, b: string) => {
        const order: string[] = ['html', 'pdf', 'xlsx', 'csv', 'bank'];
        return order.indexOf(a) - order.indexOf(b);
      });

      if (!this.selectedFormat && options) {
        this.selectedFormat = options[0];
      }
    }

    return options;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get diaglogWidth(): number {
    if (this.isMobile) {
      return 600;
    }
    return 750;
  }

  public get displayFlex(): string {
    if (this.isMobile) {
      return '';
    }
    return 'displayFlex';
  }

  public gridVisible = true;
  public selectedFormat: string;
  public reportControls: any[];
  public numericTextBoxOption: NumericTextBoxOptions = { min: 0, step: 1, spinners: false };
  public missingRequiredFields = '';
  public validationDialogVisible = false;
  public newTabBlockedDialogVisible = false;
  public safariWarningDialogVisible = false;
  public safariWarning = '';
  public htmlReportDialogVisible = false;
  public htmlReportDialogContent = '';
  private translationServiceTerms: string[];
  private noneItemText: string;

  private multiSelectValues: any[] = [];
  public get MultiSelectValues(): any[] {
    return this.multiSelectValues;
  }

  public set MultiSelectValues(value: any[]) {
    this.multiSelectValues = value || [];
  }

  public get hiddenScroll(): string {
    if (this.isMobile) {
      return '';
    }
    return 'hiddenScroll';
  }

  public get isCordovaApp(): boolean {
    return this.sessionService.browser.isHybridApp;
  }

  private reportAreas: IReportArea[];
  private reportParameters: IReportParameter[];
  private departments: IDepartment[];
  private salaryCycles: ISalaryCycle[];
  public columns: any[];

  constructor(
    private staticDataService: StaticDataService,
    private dataService: RxDataService,
    public sessionService: SessionService,
    private downloadService: DownloadService,
    public translateService: TranslateService
  ) {
    this.translationServiceTerms = ['DropdownList.None'];
    this.staticDataService.ReportParameter.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IReportParameter[]) => (this.reportParameters = data)
    );
    this.staticDataService.ReportArea.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IReportArea[]) => (this.reportAreas = data)
    );
    this.staticDataService.departments
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: IDepartment[]) => (this.departments = data));
    this.staticDataService.SalaryCycle.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalaryCycle[]) => (this.salaryCycles = data)
    );
  }

  public ngOnInit(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService.get(this.translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
        this.noneItemText = translations['DropdownList.None'];
      });
    });
  }

  public onSelectedChanged(values: any[]): void {
    this.MultiSelectValues = values;
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDialogAction(action: string): void {
    if (action === 'Download') {
      if (!this.validate()) {
        return;
      }

      const reportRequest: IReportRequest = {
        ReportId: this.selectedReport.Id,
        ParameterValues: this.getReportParameterValues()
      };

      let request: Observable<any>;
      switch (this.selectedFormat) {
        case 'pdf':
          request = this.dataService.Reports_GetPdfReport(reportRequest);
          this.safariWarning = 'Download.SafariWarning_PDF';
          break;
        case 'html':
          request = this.dataService.Reports_GetHtmlReportDownload(reportRequest);
          break;
        case 'xlsx':
          request = this.dataService.Reports_GetXlsxReportDownload(reportRequest);
          this.safariWarning = 'Download.SafariWarning_XLSX';
          break;
        case 'csv':
          request = this.dataService.Reports_GetCsvReportDownload(reportRequest);
          this.safariWarning = 'Download.SafariWarning_CSV';
          break;
        case 'bank':
          request = this.dataService.Reports_GetBankReportDownload(reportRequest);
          break;
      }

      request.subscribe((response: any) => {
        if (this.selectedFormat === 'html') {
          if (!this.sessionService.browser.isMobile) {
            const newWindow: Window = window.open('about:blank');
            if (newWindow) {
              newWindow.document.write(response as any);
              newWindow.document.close();
              this.visible = false;
              if (!this.isMobile) {
                this.reports = undefined;
              }
            } else {
              this.newTabBlockedDialogVisible = true;
            }
          } else {
            this.htmlReportDialogContent = response;
            this.htmlReportDialogVisible = true;
            this.visible = false;
          }
        } else {
          if (this.sessionService.browser.isMobileSafari) {
            this.safariWarningDialogVisible = true;
          } else if (!this.isMobile) {
            this.reports = undefined;
          }

          this.downloadService.download(this.selectedReport.Name, response);
          this.visible = false;
        }
      });
    }
  }

  public getControlDefaultLabel(control: any): string {
    return control.nullValueLabel ? control.nullValueLabel : 'Report.ComboboxDataSourceNoneFound';
  }

  private staticRoleId: number;
  private getReports(): void {
    if (this.key) {
      this.staticRoleId = Global.SESSION.CurrentRole.Id;
      const area: IReportArea = this.reportAreas.find((a: IReportArea) => a.Key === this.key);
      if (area && this.staticRoleId) {
        this.dataService.Reports_GetReportsByArea(area.Id).subscribe((reports: IReport[]) => {
          if (reports.length > 0) {
            this.reports = reports.sort((a: IReport, b: IReport) => (a.SortKey > b.SortKey ? 1 : -1));
            this.reports = this.reports.filter((z: IReport) => z.RequiredRoleId <= this.staticRoleId);
            this.getSelectedReport();
          } else {
            this.visible = false;
            this.noReportVisible = true;
          }
        });
      }
    }
  }

  private getReportParameterValues(): IReportParameterValue[] {
    const parametersValue: IReportParameterValue[] = [];
    let value: any;
    if (this.reportControls) {
      this.reportControls.forEach((reportControl: any) => {
        value = reportControl.value;
        if (reportControl.type && reportControl.type.toLowerCase() === 'datepicker' && reportControl.value) {
          value = reportControl.value;
        }

        if (reportControl.type && reportControl.type.toLowerCase() === 'checkbox' && !reportControl.value) {
          value = false;
        }

        if (reportControl.type && reportControl.type.toLowerCase() === 'combobox') {
          value = this.getComboboxParameterValue(reportControl);
        }

        if (reportControl.type && reportControl.type.toLowerCase() === 'multiselectlist') {
          value = this.getMultiSelectListParameterValue(reportControl);
        }

        parametersValue.push({ Key: reportControl.key, Value: value });
      });
    }

    return parametersValue;
  }

  private getMultiSelectListParameterValue(multiselectlistControl: any): string {
    let valueString = '';
    const multiSelectValueIds: number[] = this.MultiSelectValues.map((item: any) => {
      let id: number;
      id = item[multiselectlistControl.underlyingId];
      return id;
    });

    valueString = JSON.stringify(multiSelectValueIds);
    return valueString;
  }

  private getComboboxParameterValue(comboboxControl: any): any {
    let value: any;
    if (comboboxControl.value && comboboxControl.dataSource) {
      const comboObject: any = comboboxControl.dataSource.find(
        (data: any) => data[comboboxControl.underlyingId] === comboboxControl.value
      );
      value = comboObject ? comboboxControl.value : undefined;
    }

    return value;
  }

  private createReportControls(): void {
    setTimeout(() => {
      this.reportControls = [];
      if (this.selectedReport && this.selectedReport.Parameters) {
        const parameters: string[] = this.selectedReport.Parameters.split(',');
        if (parameters) {
          parameters.forEach((parameter: string) => {
            const control: any = this.createReportControl(parameter);
            if (control) {
              this.reportControls.push(control);
              if (control.type === 'ComboBox') {
                control.placeholderText = 'Report.NoAvailableOptions';
                this.loadComboboxDataSource(control);
              }
              if (control.type === 'MultiSelectList') {
                control.placeholderText = 'Report.NoAvailableOptions';
                this.loadMultiSelectList(control);
              }
            }
          });
        }
      }
    });
  }

  public displayExp: string;
  private loadMultiSelectList(control: any): void {
    const listDataSourceParts: any = this.getPartsFromListDataSourceStringOfMultiSelect(control.listDataSource);

    const displayColumns: string[] = listDataSourceParts.displayText.split(',');

    this.translateService
      .get(['Report.FullName', 'Report.Title'])
      .subscribe((translations: { [key: string]: string }) => {
        const displayTitleColumns = [translations['Report.FullName'], translations['Report.Title']];
        this.columns = [
          { dataField: displayColumns[0], caption: displayTitleColumns[0] },
          { dataField: displayColumns[1], caption: displayTitleColumns[1] }
        ];

        this.displayExp = displayColumns[0];
      });
    control.underlyingId = listDataSourceParts.underlyingId;

    this.dataService.httpGet(environment.apiUrl + '/' + listDataSourceParts.api).subscribe((data: any) => {
      this.assignControlDataSource(control, listDataSourceParts, data);
    });
  }

  private getPartsFromListDataSourceStringOfMultiSelect(stringListDataSource: string): any {
    stringListDataSource = stringListDataSource.replace(':', ';');
    const parts: string[] = stringListDataSource.split(';');
    // const employmentId: string = this.param && this.param.employmentId ? this.param.employmentId.toString() : '';

    return {
      api: parts[0],
      underlyingId: parts[1],
      displayText: parts[2]
    };
  }

  private loadComboboxDataSource(control: any): void {
    const listDataSourceParts: any = this.getPartsFromListDataSourceStringOfCombobox(control.listDataSource);
    control.underlyingId = listDataSourceParts.underlyingId;
    control.displayText = listDataSourceParts.displayText;
    if (control.nullValueLabel && control.nullValueLabel === 'DropdownList.None') {
      //control.nullValueLabel = nonCombo
    }

    if (control.key === 'CompanyUserId') {
      this.staticDataService.companyUsers
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: ICompanyUser[]) => this.assignControlDataSource(control, listDataSourceParts, data));
    } else {
      this.dataService.httpGet(environment.apiUrl + '/' + listDataSourceParts.api).subscribe((data: any) => {
        this.assignControlDataSource(control, listDataSourceParts, data);
      });
    }
  }

  private assignControlDataSource(control: any, listDataSourceParts: any, data: any): void {
    const sameApiComboboxes: any[] = this.reportControls.filter((ctrl: any) => {
      if (ctrl.listDataSource) {
        const ctrlListDataSourceParts: any = this.getPartsFromListDataSourceStringOfCombobox(ctrl.listDataSource);
        return ctrlListDataSourceParts.api === listDataSourceParts.api;
      } else {
        return false;
      }
    });

    if (!sameApiComboboxes || sameApiComboboxes.length === 0) {
      return;
    }

    if (data && data.length > 0) {
      if (control.defaultValue === '{All}') {
        //control.defaultValue = data;
        //control.value = data;
        this.multiSelectValues = data;
      } else if (control.defaultValue && control.underlyingId) {
        const isExitDefaultValueDataSource: any = (data as any[]).find(
          (model: any) => model[control.underlyingId] === control.defaultValue
        );
        if (!isExitDefaultValueDataSource) {
          const isExitIdValueDataSource: any = (data as any[]).find(
            (model: any) =>
              (model.Id ? model.Id.toString() : model.Id) ===
              (control.defaultValue ? control.defaultValue.toString() : control.defaultValue)
          );
          if (isExitIdValueDataSource) {
            control.value = isExitIdValueDataSource[control.underlyingId];
          }
        }
      }
      control.placeholderText = '';
      (data as any[]).forEach((model: any) => {
        if (!model[control.underlyingId]) {
          control.nullValueLabel = model.Name;
          const indexOf: number = (data as any[]).indexOf(model, 0);
          if (indexOf === 0) {
            control.hasItemNull = true;
          }
          (data as any[]).splice(indexOf, 1);
        }
      });
    }
    control.dataSource = data;

    control.dataSourceEmpty = data === undefined || data === null || data.length < 1;
    if (!control.dataSourceEmpty && control.defaultValue === '{First}') {
      if (control.hasItemNull) {
        control.value = undefined;
      } else {
        control.value = data[0][control.underlyingId];
      }
    }

    if (sameApiComboboxes && sameApiComboboxes.length > 0) {
      sameApiComboboxes.forEach((apiCombo: any) => {
        if (!apiCombo.dataSource || apiCombo.dataSource.length === 0) {
          apiCombo.dataSource = data;
          apiCombo.dataSourceEmpty = data === undefined || data === null || data.length < 1;
        }

        if (!apiCombo.dataSourceEmpty && apiCombo.defaultValue === '{First}') {
          if (control.hasItemNull) {
            apiCombo.value = undefined;
          } else {
            apiCombo.value = data[0][control.underlyingId];
          }
        }
      });
    }

    if (control.value && control.value.length === 1) {
      const indexOfValue: any = (control.dataSource as any[]).find(
        (model: any) => model[control.underlyingId] === control.value
      );
      if (!indexOfValue) {
        control.nullValueLabel = control.value;
        control.value = undefined;
      }
    }
  }

  private getPartsFromListDataSourceStringOfCombobox(stringListDataSource: string): any {
    stringListDataSource = stringListDataSource.replace(':', ';');
    const parts: string[] = stringListDataSource.split(';');
    const employmentId: string = this.param && this.param.employmentId ? this.param.employmentId.toString() : '';

    return {
      api:
        parts[0].indexOf('{CurrentUserEmploymentId}') >= 0
          ? parts[0].replace('{CurrentUserEmploymentId}', employmentId)
          : parts[0],
      underlyingId: parts[1],
      displayText: parts[2]
    };
  }

  private createReportControl(parameter: string): any {
    const reportParameter: IReportParameter = this.reportParameters.find(
      (par: IReportParameter) => par.Key === parameter
    );
    if (!reportParameter) {
      console.log('reportParameter is undefined for parameter: ' + parameter);
      return undefined;
    }

    const key: string = reportParameter['Key'];
    // let defaultValue: any = this.getSelectedReportControlDefaultValueByKey(key);
    let defaultValue: any = this.getSelectedReportControlDefaultValue(reportParameter['DefaultValue'])
      ? this.getSelectedReportControlDefaultValue(reportParameter['DefaultValue'])
      : undefined;
    if (!defaultValue && reportParameter['IsRequired']) {
      defaultValue = this.getSelectedReportControlDefaultValueByKey(key);
    }

    let nonCombo = '';
    if (!reportParameter['IsRequired'] && reportParameter['IsRequired'] === false) {
      nonCombo = 'DropdownList.None';
    }

    return {
      type: reportParameter['UiControlType'],
      defaultValue,
      value: defaultValue !== '{First}' ? defaultValue : undefined,
      listDataSource: reportParameter['ListDataSource'],
      nullValueLabel: nonCombo,
      isRequired: reportParameter['IsRequired'],
      name: reportParameter['Name'],
      key,
      sortKey: reportParameter['SortKey'],
      displayText: '',
      underlyingId: '',
      dataSource: [],
      dataSourceEmpty: false
    };
  }

  private getSelectedReportControlDefaultValueByKey(key: string): any {
    let defaultValue: any;
    switch (key) {
      case 'SalaryBatchId':
        defaultValue = this.param.salaryBatchId;
        break;
      case 'OptionalSalaryBatchId':
        defaultValue = this.param.salaryBatchId;
        break;
      case 'FilterByDepartment':
        if (this.departments.length > 0) {
          defaultValue = this.departments[0];
        }
        break;
      case 'FilterBySalaryCycle':
        if (this.salaryCycles.length > 0) {
          defaultValue = this.salaryCycles[0];
        }
        break;
      case 'CompanyUserId':
        defaultValue = this.param.employeeId;
        break;
      case 'SelfEmploymentId':
        defaultValue = this.param.employmentId;
        break;
      case 'UserEmploymentId':
        defaultValue = this.param.employmentId;
        break;
      case 'FromDate_YearBegin':
        defaultValue = this.getFirstDayOfCurrentYear();
        break;
      case 'ToDate_Today':
        defaultValue = this.staticDataService.getCurrentdate();
        break;
      case 'FromDate_LastMonthStart':
        defaultValue = this.getFirstDayOfPreviousMonth();
        break;
      case 'ToDate_LastMonthEnd':
        defaultValue = this.getLastDateOfPreviousMonth();
        break;
    }

    return defaultValue;
  }

  private getSelectedReportControlDefaultValue(defaultValue: any): any {
    switch (defaultValue) {
      case '{Current SalaryBatchId}':
        defaultValue = this.param.salaryBatchId;
        break;
      case '{Today}':
        defaultValue = this.staticDataService.getCurrentdate();
        break;
      case '{First day of current year}':
        defaultValue = this.getFirstDayOfCurrentYear();
        break;
      case '{First day of previous month}':
        defaultValue = this.getFirstDayOfPreviousMonth();
        break;
      case '{Last day of previous month}':
        defaultValue = this.getLastDateOfPreviousMonth();
        break;
    }
    return defaultValue;
  }

  private getFirstDayOfCurrentYear(): Date {
    const date: Date = new Date();
    return this.staticDataService.getCurrentdate(undefined, new Date(date.getFullYear(), 0, 1));
  }

  private getFirstDayOfPreviousMonth(): Date {
    const date: Date = new Date();
    let currentYear: number = date.getFullYear();
    let previousMonth: number = date.getMonth() - 1;
    if (previousMonth === 0) {
      currentYear -= 1;
      previousMonth = 12;
    }

    return this.staticDataService.getCurrentdate(undefined, new Date(currentYear, previousMonth, 1));
  }

  private getLastDateOfPreviousMonth(): Date {
    const date: Date = new Date();
    return this.staticDataService.getCurrentdate(undefined, new Date(date.getFullYear(), date.getMonth(), 0));
  }

  private validate(): boolean {
    this.missingRequiredFields = this.checkRequiredFields();
    if (this.missingRequiredFields) {
      this.validationDialogVisible = true;
      return false;
    }

    return true;
  }

  private checkRequiredFields(): string {
    let requiredFields = '';
    if (this.reportControls) {
      this.reportControls.forEach((reportControl: any) => {
        if (!this.isReportControlValid(reportControl)) {
          requiredFields += reportControl.name + ' ,';
        }
      });
    }

    if (!requiredFields) {
      return undefined;
    }

    requiredFields = requiredFields.slice(0, requiredFields.length - 2);
    return requiredFields;
  }

  private isReportControlValid(reportControl: any): boolean {
    if (!reportControl.isRequired) {
      return true;
    }

    if (reportControl.type && reportControl.type.toLowerCase() === 'checkbox') {
      return true;
    }

    if (
      reportControl.type &&
      reportControl.type.toLowerCase() === 'datepicker' &&
      (!reportControl.value || (reportControl.value && new Date(reportControl.value) === undefined))
    ) {
      return false;
    }

    if (reportControl.type && reportControl.type.toLowerCase() === 'multiselectlist') {
      return true;
    }

    return reportControl.value;
  }

  public onDoubleClick(): void {
    if (this.sessionService.isGetting) {
      return;
    }
    this.onDialogAction('Download');
  }

  public onBackClick(): void {
    if (this.isMobile) {
      this.selectedReport = undefined;
      this.gridVisible = true;
    }
  }

  private getSelectedReport() {
    if (this.reports && this.reports.length > 0 && !this.isMobile) {
      if (!this.selectedReport) {
        this.selectedReport = this.reports[0];
      } else {
        let existReportIndex = -1;
        const existReport = this.reports.filter((report, index) => {
          if (report.Key === this.selectedReport.Key) {
            existReportIndex = index;
            return true;
          }
          return false;
        });
        if (existReport && existReport.length === 0 && existReportIndex === -1) {
          this.selectedReport = this.reports[0];
        } else {
          this.selectedReport = this.reports[existReportIndex];
        }
      }
    }
  }
}
