import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'dropdown-filter',
  templateUrl: './DropdownFilter.component.html'
})
export class DropDownListFilterComponent extends BaseFilterCellComponent {
  @Input() public selectedValue: any;
  @Input() public data: any[];
  @Input() public fieldName: string;
  @Input() public textField: string;
  @Input() public valueField: string;
  @Input() public defaultItem: string;

  constructor(filterService: FilterService) {
    super(filterService);
  }

  private seletedOperatorValue: string;
  @Input()
  public get selectedOperator(): string {
    return this.seletedOperatorValue;
  }
  public set selectedOperator(value: string) {
    if (value) {
      this.seletedOperatorValue = value;
    }
  }

  @Output() public filterChange: EventEmitter<any> = new EventEmitter<any>();

  public onChange(value: any): void {
    this.filterChange.emit(value);
  }
}
