import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Transition, TransitionService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { version } from '../../../version';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { KeyDownHelper } from '../../Common/KeyDownHelper';
import { ImportTypesEnum } from '../../Model/Enum';
import {
  ICompanyUser,
  IReportArea,
  ITemplateCreationRequest,
  IUserEmployment,
  IUserEmploymentTemplate
} from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { ReportParametersDefaultValue } from '../../SharedComponents/ReportsDialog/ReportParametersDefaultValue';

@Component({
  selector: 'employee-tabs',
  templateUrl: './EmployeeTabs.component.html'
})
export class EmployeeTabsComponent implements OnInit {
  // Input bindings
  @Input()
  public set globalKeyDown(value: any) {
    if (!value) {
      return;
    }

    this.onGlobalKeyDown(value);
  }

  @Input() public isBlankEmail = false;
  // Two way bindings
  private currentEmployee: ICompanyUser;
  @Input() public avatarChange = false;
  @Input()
  public get employee(): ICompanyUser {
    return this.currentEmployee;
  }
  public set employee(value: ICompanyUser) {
    if (this.currentEmployee !== value) {
      const oldEmployeeData: ICompanyUser = this.currentEmployee;
      const oldEmployeeEmployment: IUserEmployment = this.currentUserEmployment;
      this.currentEmployee = value;

      // If a new employee is selected while in edit mode, we update the old data if there are changes
      if (this.editMode && this.isDirty && this.validate()) {
        this.updateUserEmployment(oldEmployeeData, oldEmployeeEmployment);
      }

      this.resetValidation();
      this.isDirty = false;
      // Reset valid because a new employee is selected
      if (this.editMode && this.currentEmployee && oldEmployeeData && oldEmployeeData.Id !== this.currentEmployee.Id) {
        this.editMode = false;
      }

      this.employeeChange.emit(value);
    }
  }
  @Output() public employeeChange: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();
  @Output() public isAvatarChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  private editModeValue: boolean;
  @Input()
  public get editMode(): boolean {
    return this.editModeValue;
  }
  public set editMode(value: boolean) {
    if (this.editModeValue !== value) {
      this.editModeValue = value;
      this.editModeChange.emit(value);
    }
  }
  @Output() public editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private isDirtyValue = false;
  @Input()
  public get isDirty(): boolean {
    return this.isDirtyValue;
  }
  public set isDirty(value: boolean) {
    if (this.isDirtyValue !== value) {
      this.isDirtyValue = value;
      this.isDirtyChange.emit(value);
    }
  }
  @Output() public isDirtyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private addNewEmployeeFiredValue: boolean;
  @Input()
  public get addNewEmployeeFired(): boolean {
    return this.addNewEmployeeFiredValue;
  }
  public set addNewEmployeeFired(value: boolean) {
    if (value) {
      this.createNewEmployeeDialogVisible = true;

      setTimeout(() => {
        this.addNewEmployeeFiredValue = false;
        this.addNewEmployeeFiredChange.emit(false);
      });
    }
  }
  @Output() public addNewEmployeeFiredChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Output bindings
  @Output() public newEmployeeCreated: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();
  @Output() public employeeDeleted: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();
  @Output() public employeeUpdated: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();

  // Template properties
  public createNewtimeEntryDialogVisable = false;
  public createNewPayrolldataDialogVisable = false;
  public isTabsValid = true;
  public newEmployment: IUserEmployment;
  public deletedEmployment: IUserEmployment;
  public createNewEmploymentDialogVisible = false;
  public deleteEmployeeDialogVisible = false;
  public reportDialogVisible = false;
  public reportParams: ReportParametersDefaultValue;
  public resetPasswordFired = false;
  public resendWelcomeEmailFired = false;
  public discardDialogVisible = false;
  public synchTemplateDialogVisible: boolean;
  public createTemplateTemplateCompletedDialogVisible: boolean;
  public createTemplateConfirmationDialogVisible: boolean;
  public templateNameDialogVisible: boolean;
  public newTemplateName: string;
  public unsavedDataDialogVisible: boolean;
  public PaddingBottom = '';
  private touchTime = 0;
  public resendWelcomeEmail = false;
  public resendEmailSuccessVisible: boolean;
  private oldEmployee: ICompanyUser;

  public get isMobileInEmployeepayrollData(): boolean {
    return !this.isMobile || !this.sessionService.currentState.includes('tabs.employee.payrolldata');
  }
  public get isMobileInEmployeetime(): boolean {
    return !this.isMobile || !this.sessionService.currentState.includes('tabs.employee.time');
  }
  public get isMobileInSelfservicetime(): boolean {
    return !this.isMobile || !this.sessionService.currentState.includes('tabs.selfservice.time');
  }
  public get allowEditUserInfo(): boolean {
    return (
      this.sessionService.role.IsSalaryAdminAndHigher && this.sessionService.currentState !== 'tabs.selfservice.general'
    );
  }
  public get allowEditRole(): boolean {
    return this.sessionService.role.IsFullAdmin && this.sessionService.currentState !== 'tabs.selfservice.general';
  }
  public get showEmployeeList(): boolean {
    return this.sessionService.feature.ShowEmployeeList;
  }
  public get employeeGeneralTabVisible(): boolean {
    return this.sessionService.currentState.includes('tabs.employee.general') && !this.EmployeeWithRoleAsManager;
  }
  public get employeeHiringStatusTabVisible(): boolean {
    return this.sessionService.currentState.includes('tabs.employee.hiringstatus') && !this.EmployeeWithRoleAsManager;
  }
  public get employeeTimeTabVisible(): boolean {
    return this.sessionService.currentState.includes('tabs.employee.time');
  }
  public get employeePayrollDataTabVisible(): boolean {
    return this.sessionService.currentState.includes('tabs.employee.payrolldata') && !this.EmployeeWithRoleAsManager;
  }
  public get employeePayslipTabVisible(): boolean {
    return this.sessionService.currentState.includes('tabs.employee.payslip') && !this.EmployeeWithRoleAsManager;
  }
  public get allowEditUserEmployment(): boolean {
    return (
      this.sessionService.feature.AllowEditUserEmployment(this.sessionService.currentState) &&
      !this.sessionService.role.IsReadOnly
    );
  }
  public get reportViewEnabled(): boolean {
    const area: IReportArea = this.reportAreas.find((a: IReportArea) => a.Key === this.reportKey);
    if (area) {
      return true;
    }
    return false;
    //return this.sessionService.feature.ReportViewEnabled;
  }
  public get activeTab(): string {
    return this.sessionService.currentState;
  }
  public get hasEmployment(): boolean {
    return this.currentUserEmployment && this.currentUserEmployment.Id ? true : false;
  }
  public get currentPosition(): number {
    return this.currentUserEmployment ? this.currentUserEmployment.Id : undefined;
  }
  public get employmentNotBaseOnTemplate(): boolean {
    return this.currentUserEmployment && this.currentUserEmployment.BasedOnTemplateId > 0;
  }

  private get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }
  private get isActive(): boolean {
    return this.sessionService.currentState.indexOf('tabs.employee') >= 0;
  }
  private get EmployeeWithRoleAsManager(): boolean {
    return this.sessionService.role.EmployeeWithRoleAsManager;
  }
  private get allowAddNewEmployee(): boolean {
    return this.sessionService.role.IsSalaryAdminAndHigher;
  }

  public get isPayrolldataMobile(): boolean {
    return this.sessionService.browser.isMobile && this.sessionService.currentState === 'tabs.employee.payrolldata';
  }
  public get employeeTimeTabEnabled(): boolean {
    return this.sessionService.feature.EmployeeTimeTabEnabled;
  }

  public get employeePayrollDataTabEnabled(): boolean {
    return this.sessionService.feature.EmployeePayrollDataTabEnabled;
  }

  public get disabledActionButtonClass(): string {
    if (this.sessionService.isGetting) {
      return 'disabledActionButtonClass';
    }
    return '';
  }

  private isEmployeeInfoValid = true;
  private isApiValidationValid = true;
  private originalCompanyUserData: ICompanyUser;
  private originalUserEmployment: IUserEmployment;
  private currentUserEmploymentValue: IUserEmployment;
  private createNewEmployeeDialogVisibleValue = false;
  private oldEmployeeData: ICompanyUser;
  private oldEmployeeEmployment: IUserEmployment;
  private navigateToAfterUpdate: string;
  private updateEmployee: ICompanyUser;

  public showInvalidFormWarning: boolean;
  public showSaveDataConfirmationChangeState: boolean;
  private navigationToStateName: string;
  public isOnDialogSynchronizeEmploymentWithTemplateVisble = false;
  private reportAreas: IReportArea[];

  constructor(
    private dataService: RxDataService,
    private settingService: SettingService,
    public sessionService: SessionService,
    private transitionService: TransitionService,
    private staticDataService: StaticDataService
  ) {}

  public ngOnInit(): void {
    this.staticDataService.ReportArea.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IReportArea[]) => (this.reportAreas = data)
    );
    if (this.sessionService.employeeTabState !== '') {
      this.sessionService.currentState = this.sessionService.employeeTabState;
      this.checkSwitchingEmployeeTab();
    } else {
      console.log(Global.SESSION);
      const currentRoleKey: string = Global.SESSION.CurrentRole.Key;
      if (currentRoleKey) {
        switch (currentRoleKey) {
          case 'FullAdmin':
            if (Global.IsEmployeeTaskCompleted) {
              this.sessionService.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
              this.sessionService.NavigateTo('tabs.employee.payrolldata');
            } else {
              this.sessionService.currentStyleMenuTabpanelReponsive = '';
              this.sessionService.NavigateTo('tabs.employee.general');
            }

            break;
          case 'ReadOnly':
            this.sessionService.NavigateTo('tabs.employee.payrolldata');
            break;
          case 'Employee':
            const hasManager: boolean = this.sessionService.role.EmployeeWithRoleAsManager;
            if (hasManager) {
              this.sessionService.NavigateTo('tabs.employee.time');
            }

            this.sessionService.NavigateTo('tabs.selfservice.payslip');
            break;
          case 'SalaryAdmin':
            this.sessionService.NavigateTo('tabs.employee.payrolldata');
            break;
          default:
            break;
        }
      }
    }

    this.transitionService.onStart({}, (transition: Transition) => {
      const fromState: any = transition.from().name;
      const toState: any = (this.navigationToStateName = transition.to().name);
      const generalState = 'tabs.employee.general';
      const hiringstatusState = 'tabs.employee.hiringstatus';

      if (this.editMode && fromState && (fromState === generalState || fromState === hiringstatusState)) {
        if (this.isDirty) {
          // If validation fails, alert user and do nothing.
          // Else show action confirmation.
          if (!this.validate()) {
            this.showInvalidFormWarning = true;
          } else {
            this.showSaveDataConfirmationChangeState = true;
          }

          return false;
        } else {
          // Editmode but no changes, we close editmode without doing anything
          this.editMode = false;
          return true;
        }
      } else {
        this.editMode = false;
        this.editModeChange.emit(false);
        return true;
      }
    });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public checkSwitchingEmployeeTab(): void {
    if (this.sessionService.currentState) {
      switch (this.sessionService.currentState) {
        case 'tabs.employee.general':
          if (this.EmployeeWithRoleAsManager) {
            this.sessionService.checkNavigateDefaultRole(true, false);
          } else {
            this.sessionService.NavigateTo(this.sessionService.employeeTabState);
          }
          break;
        case 'tabs.employee.hiringstatus':
          if (this.EmployeeWithRoleAsManager) {
            this.sessionService.checkNavigateDefaultRole(true, false);
          } else {
            this.sessionService.NavigateTo(this.sessionService.employeeTabState);
          }
          break;
        case 'tabs.employee.time':
          if (!this.employeeTimeTabEnabled) {
            this.sessionService.checkNavigateDefaultRole(true, false);
          } else {
            this.sessionService.NavigateTo(this.sessionService.employeeTabState);
          }
          break;
        case 'tabs.employee.payrolldata':
          if (!this.employeePayrollDataTabEnabled || this.EmployeeWithRoleAsManager) {
            this.sessionService.checkNavigateDefaultRole(true, false);
          } else {
            this.sessionService.NavigateTo(this.sessionService.employeeTabState);
          }
          break;
        case 'tabs.employee.payslip':
          if (this.EmployeeWithRoleAsManager) {
            this.sessionService.checkNavigateDefaultRole(true, false);
          } else {
            this.sessionService.NavigateTo(this.sessionService.employeeTabState);
          }
          break;
        default:
          this.sessionService.NavigateTo(this.sessionService.employeeTabState);
          break;
      }
    }
  }

  public get moreButtonVisisble(): boolean {
    return (
      (this.sessionService.currentState === 'tabs.company.configuration.hiringstatus' ||
        this.sessionService.currentState === 'tabs.company.salarybatches' ||
        this.sessionService.currentState === 'tabs.company.general' ||
        this.sessionService.currentState === 'tabs.employee.hiringstatus' ||
        this.sessionService.currentState === 'tabs.employee.general' ||
        this.sessionService.currentState === 'tabs.employee.payrolldata') &&
      !this.sessionService.role.IsReadOnly
    );
  }

  public get currentUserEmployment(): IUserEmployment {
    return this.currentUserEmploymentValue;
  }
  public set currentUserEmployment(value: IUserEmployment) {
    this.currentUserEmploymentValue = value;
    this.resetValidation();
    this.isDirty = false;
  }

  public get createNewEmployeeDialogVisible(): boolean {
    if (!this.allowAddNewEmployee) {
      return false;
    }

    return this.createNewEmployeeDialogVisibleValue;
  }
  public set createNewEmployeeDialogVisible(value: boolean) {
    this.createNewEmployeeDialogVisibleValue = value;
  }

  public onConfirmSaveDataOnChangeState(action: string): void {
    if (action === 'ContinueAndSave') {
      this.editMode = false;
      // setTimeout(() => {
      // });
      this.prepareUpdateUserEmployment(undefined, undefined, this.navigationToStateName);
    } else if (action === 'DiscardAndLeave') {
      this.discardChanges();
      this.isDirty = false;
      if (this.navigationToStateName) {
        this.sessionService.NavigateTo(this.navigationToStateName);
      }
    }

    this.navigationToStateName = undefined;
  }

  public get reportKey(): string {
    if (this.employeeGeneralTabVisible) {
      return 'EmployeeGeneral';
    }

    if (this.employeeHiringStatusTabVisible) {
      return 'HiringStatus';
    }

    if (this.employeeTimeTabVisible) {
      return 'EmployeeTime';
    }

    if (this.employeePayrollDataTabVisible) {
      return 'PayrollData';
    }

    if (this.employeePayslipTabVisible) {
      return 'EmployeePayslips';
    }

    return '';
  }

  public clickAreamenuToggledChange(): void {
    this.sessionService.menuToggled = true;
    this.sessionService.showUserMenuToggled = false;
    this.sessionService.showMoreMenuToggled = false;
    this.sessionService.showHelpMenuToggled = false;
    this.checkMobileDoubleClick();
  }

  public checkMobileDoubleClick(): void {
    if (this.touchTime === 0) {
      // set first click
      this.touchTime = new Date().getTime();
    } else {
      // compare first click to this click and see if they occurred within double click threshold
      if (new Date().getTime() - this.touchTime < 800) {
        // double click occurred
        this.onDoubleClick();
        this.touchTime = 0;
      } else {
        // not a double click so set as a new first click
        this.touchTime = new Date().getTime();
      }
    }
  }

  public PreonDoubleClick(): void {
    if (this.editMode === true) {
      return;
    }
    this.onDoubleClick();
  }

  public onDoubleClick(): void {
    if (!this.allowEditUserEmployment) {
      return;
    }

    if (
      !this.editMode &&
      (this.sessionService.currentState === 'tabs.employee.general' ||
        this.sessionService.currentState === 'tabs.employee.hiringstatus')
    ) {
      ////this.editMode = true;
      if (this.editMode === true) {
        return;
      }
      this.onEditModeChange(true);
    }
  }

  public onUserEmploymentChange(newUserEmployment: IUserEmployment): void {
    this.currentUserEmployment = newUserEmployment;
  }

  public onPositionChanged(position: string): void {
    this.currentUserEmployment.Title = position;
  }

  public onAddNewEmployee(): void {
    this.createNewEmployeeDialogVisible = true;
  }

  public onAddNewTimeEntry(): void {
    this.createNewtimeEntryDialogVisable = true;
  }

  public onAddNewPayrollData(): void {
    this.createNewPayrolldataDialogVisable = true;
  }

  public onNewEmployeeCreatedEvent(newCompanyUser: ICompanyUser): void {
    this.newEmployeeCreated.emit(newCompanyUser);
  }

  public onNewEmploymentCreatedEvent(newUserEmployment: IUserEmployment): void {
    this.newEmployment = newUserEmployment;
    this.onUserEmploymentChange(newUserEmployment);
  }

  public noReportVisible = false;
  public onShowReportsEventClick(): void {
    if (!this.employee) {
      return;
    }

    const parameters: any = new ReportParametersDefaultValue();
    parameters.employeeId = this.employee ? this.employee.Id : undefined;
    parameters.employmentId = this.currentUserEmployment ? this.currentUserEmployment.Id : undefined;
    this.reportParams = parameters;
    this.reportDialogVisible = true;
  }

  public deletedEmployee(employee: ICompanyUser): void {
    // Broadcast to Employee View
    this.employeeDeleted.emit(employee);
  }

  public deletedUserEmployment(userEmployment: IUserEmployment): void {
    this.deletedEmployment = userEmployment;
    // When delete user employment happens the this.employee.UserEmployments should not be null, so no null checking here is needed
    const deletedIndex: number = this.employee.UserEmployments.findIndex(
      (value: IUserEmployment): boolean => value.Id === userEmployment.Id
    );
    this.employee.UserEmployments.splice(deletedIndex, 1);
    this.onUserEmploymentChange(
      this.employee.UserEmployments.length > 0 ? this.employee.UserEmployments[0] : undefined
    );
  }

  public onMoreMenuOptionClick(menu: string): void {
    switch (menu) {
      case 'employeeHiringStatus_createTemplate':
        this.createTemplateFromCurrentEmployment();
        break;
      case 'employeeHiringStatus_addEmployment':
        this.getTemplates();
        break;
      case 'employeeGeneral_deleteEmployee':
        this.deleteEmployeeDialogVisible = true;
        break;
      case 'employeeHiringStatus_synchronizeWithTemplate':
        // this.onSynchronizeEmploymentWithTemplate();
        this.isOnDialogSynchronizeEmploymentWithTemplateVisble = true;
        break;
      case 'employeeGeneral_importInitialBalances':
        this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Initial_Balances;
        this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
        break;
      case 'employeeGeneral_importEmployees':
        this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Employees;
        this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
        break;
      case 'companyGeneral_importData':
        this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Payroll;
        this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
        break;

      case 'employeeGeneral_ResendWelcomeEmail':
        this.resendWelcomeEmailFired = true;
        break;
      case 'employeeGeneral_ResetPassword':
        this.resetPasswordFired = true;
        break;
      default:
        break;
    }
  }

  public onDialogSynchronizeEmploymentWithTemplate(event: any): void {
    if (event && event === 'Yes') {
      this.onSynchronizeEmploymentWithTemplate();
    }
  }

  public isNoTemplate = false;
  public createNewUserEmployment(): void {
    this.getTemplates();
  }

  public isShowAction = true;
  public onEditModeChange(editMode: boolean): void {
    // Assume that the user has fixed the api validation and try again
    this.isApiValidationValid = true;
    if (!this.validate() && editMode === false) {
      if ((version as string) === 'Local-build') {
        console.log('Keep editmode open');
      }

      return;
    }

    if (editMode === false && this.isDirty) {
      this.updateUserEmployment();
    }

    if (editMode && this.employee && !this.isDirty) {
      // Save original by creating a deep copy of the object
      this.originalCompanyUserData = JSON.parse(JSON.stringify(this.employee));
      this.originalUserEmployment = this.currentUserEmployment
        ? JSON.parse(JSON.stringify(this.currentUserEmployment))
        : undefined;
      // By the way, this is how you create a shallow copy in Typescript 2.1:
      //// this.originalCompanyUserData = {... this.employee};
    }

    this.isShowAction = false;
    setTimeout(() => {
      this.editMode = editMode;
      this.isShowAction = true;
      this.editModeChange.emit(this.editMode);
    }, 25);

    // let breower: Browser = new Browser();
    // if (breower.isChrome && !breower.isMobile && !breower.isHybridApp) {
    //     this.isShowAction = false;
    //     setTimeout(() => { this.editMode = editMode; this.isShowAction = true; this.editModeChange.emit(this.editMode); }, 25);
    // } else {
    //     this.isShowAction = false;
    //     this.editMode = editMode;
    //     this.editModeChange.emit(this.editMode);
    //     this.isShowAction = true;
    // }
  }

  public onReloadUserEmploymentEvent(): void {
    if (this.currentUserEmployment) {
      this.reloadUserEmployment();
    }
  }

  public onEmployeeInfoValidated(isPassed: boolean): void {
    this.isEmployeeInfoValid = isPassed;
    this.isDirty = true;
  }

  public confirmAndDiscardChanges(): void {
    if (!this.isDirty) {
      this.editMode = false;
      return;
    }

    this.discardDialogVisible = true;
  }

  public discardDialogAction(action: string): void {
    if (action === 'ConfirmDiscard') {
      this.discardChanges();
    }
  }

  public onUnsavedDataDialogAction(action: string): void {
    if (action === 'DiscardAndLeave') {
      this.editMode = false;
    } else {
      this.editMode = false;
      this.prepareUpdateUserEmployment(this.oldEmployeeData, this.oldEmployeeEmployment);
    }
  }

  public onSynchTemplateDialogAction(action: string): void {
    if (action !== 'No') {
      this.performUpdateUserEmployment(
        this.updateEmployee,
        this.oldEmployeeData,
        this.navigateToAfterUpdate,
        this.originalUserEmployment.Id
      );
    } else {
      this.performUpdateUserEmployment(this.updateEmployee, this.oldEmployeeData, this.navigateToAfterUpdate);
    }
  }

  public onTemplateNameDialogAction(action: string): void {
    if (action === 'Create' && this.newTemplateName) {
      const request: ITemplateCreationRequest = {
        BasedOnEmploymentId: this.currentUserEmployment ? this.currentUserEmployment.Id : undefined,
        Name: this.newTemplateName
      };

      this.dataService
        .EmploymentTemplates_CreateTemplateFromEmployment(request)
        .subscribe((template: IUserEmploymentTemplate): void => {
          this.reloadUserEmployment();
          this.createTemplateTemplateCompletedDialogVisible = true;
        });
    }
  }

  public onCreateTemplateConfirmationDialogAction(action: string): void {
    if (action === 'yes') {
      this.newTemplateName = '';
      this.templateNameDialogVisible = true;
    }
  }

  private onGlobalKeyDown($event: any): void {
    if (!this.isActive) {
      return;
    }

    if (KeyDownHelper.isReportShortcutKey($event)) {
      $event.preventDefault();
      this.onShowReportsEventClick();
      return;
    }

    if (KeyDownHelper.isNewShortcutKey($event)) {
      $event.preventDefault();
      this.onAddNewEmployee();
      return;
    }

    if (KeyDownHelper.isEditShortcutKey($event)) {
      $event.preventDefault();
      if (this.allowEditUserEmployment) {
        this.editMode = true;
      }

      return;
    }

    if (!this.editMode) {
      return;
    }

    if (KeyDownHelper.isDiscardingChangesShortcutKey($event)) {
      $event.preventDefault();
      this.confirmAndDiscardChanges();
    }

    if (KeyDownHelper.isSavingChangesShortcutKey($event)) {
      $event.preventDefault();
      if ((version as string) === 'Local-build') {
        console.log('Save');
      }

      this.editMode = false;
    }
  }

  private discardChanges(): void {
    this.isDirty = false;
    if (this.employee && this.originalCompanyUserData && this.employee.Id === this.originalCompanyUserData.Id) {
      this.employee = JSON.parse(JSON.stringify(this.originalCompanyUserData));
      this.currentUserEmployment = this.originalUserEmployment;
    }
    this.editMode = false;
  }

  private updateUserEmployment(oldEmployeeData?: ICompanyUser, oldEmployeeEmployment?: IUserEmployment): void {
    this.oldEmployeeData = oldEmployeeData;
    this.oldEmployeeEmployment = oldEmployeeEmployment;
    // When oldEmployeeData has value, it is because the user switch to a new employee without closing editmode
    // Therefore we try to make sure the oldEmployeeData is updated in the API call, but the current employee is the newly selected employee
    if (oldEmployeeData) {
      // Ask user if they want save or not
      this.unsavedDataDialogVisible = true;
    } else {
      this.prepareUpdateUserEmployment();
    }
  }

  private prepareUpdateUserEmployment(
    oldEmployeeData?: ICompanyUser,
    oldEmployeeEmployment?: IUserEmployment,
    navigateTo?: string
  ): void {
    this.oldEmployeeData = oldEmployeeData;
    this.oldEmployeeEmployment = oldEmployeeEmployment;
    this.navigateToAfterUpdate = navigateTo;
    const employee: ICompanyUser = oldEmployeeData ? oldEmployeeData : this.employee;
    const employment: IUserEmployment = oldEmployeeEmployment ? oldEmployeeEmployment : this.currentUserEmployment;

    // Remove UserEmployments that are not currently active selection
    // Remove check length condition. Since Employment object is not the same if update failed.
    if (employment && employment.CompanyUserId === employee.Id) {
      employee.UserEmployments = [];
      if (employment.IncomeTypeId === 0 || employment.IncomeTypeId === Constants.MAGIC_NUMBER) {
        employment.IncomeTypeId = 0;
      }

      employee.UserEmployments.push(employment);
    }

    //  GS-1798: this.originalUserEmployment will be null when editing an employee without userEmployment.
    // In localhost the next line doesn't cause error but in PROD mode some browsers are very unhappy about this
    if (!this.originalUserEmployment) {
      // Should save normally
      this.performUpdateUserEmployment(employee, oldEmployeeData, navigateTo);
      return;
    }

    let savingEmployment: IUserEmployment;
    if (employee.UserEmployments) {
      savingEmployment = employee.UserEmployments.find(
        (employ: IUserEmployment) => employ.Id === this.originalUserEmployment.Id
      );
    }

    this.updateEmployee = employee;
    if (savingEmployment && this.originalUserEmployment.BasedOnTemplateId !== savingEmployment.BasedOnTemplateId) {
      this.synchTemplateDialogVisible = true;
    } else {
      this.performUpdateUserEmployment(employee, oldEmployeeData, navigateTo);
    }
  }

  private performUpdateUserEmployment(
    employee: ICompanyUser,
    oldEmployeeData: ICompanyUser,
    navigateTo?: string,
    syncTemplateId?: number
  ): void {
    this.dataService.CompanyUsers_UpdateCompanyUser(employee).subscribe(
      (updatedCompanyUser: ICompanyUser): void => {
        if (!oldEmployeeData) {
          // UpdatedCompanyUser is for the old data, so we won't set it to the current
          this.employee = updatedCompanyUser;
          this.employeeUpdated.emit(this.employee);
          if (this.isBlankEmail && (updatedCompanyUser.PersonalEmail || updatedCompanyUser.CompanyEmail)) {
            this.resendWelcomeEmail = true;
          }
        }

        if (syncTemplateId) {
          this.dataService
            .Employments_UpdateEmploymentToCurrentTemplate(syncTemplateId)
            .subscribe((synedEmployment: IUserEmployment) => {
              if (this.currentUserEmployment.Id === synedEmployment.Id) {
                this.currentUserEmployment = synedEmployment;
              }

              let employmentThatSync: IUserEmployment;
              if (this.employee.UserEmployments) {
                employmentThatSync = this.employee.UserEmployments.find(
                  (employment: IUserEmployment) => employment.Id === employee.UserEmployments[0].Id
                );
              }
              if (employmentThatSync) {
                this.employee.UserEmployments[
                  this.employee.UserEmployments.lastIndexOf(employmentThatSync)
                ] = synedEmployment;
              }
            });
        }

        // Reset
        this.isDirty = false;
        if (navigateTo) {
          this.sessionService.NavigateTo(navigateTo);
        }
      },
      (err: any): void => {
        if (oldEmployeeData) {
          // We updated the old data ut is now at the newly selected employee so these properties don't belong to the old data
          this.resetValidation();
          this.isDirty = false;
          this.editMode = false;
        } else {
          this.isApiValidationValid = false;
          if (!oldEmployeeData) {
            this.editMode = true;
          }

          this.isDirty = true;
        }
      }
    );
  }

  private reloadUserEmployment(): void {
    this.dataService.Employments_GetEmployment(this.currentUserEmployment.Id).subscribe((data: IUserEmployment) => {
      this.currentUserEmployment = data;
    });
  }

  private createTemplateFromCurrentEmployment(): void {
    this.createTemplateConfirmationDialogVisible = true;
  }

  private onSynchronizeEmploymentWithTemplate(): void {
    if (this.currentUserEmployment && this.currentUserEmployment.Id) {
      this.dataService
        .Employments_UpdateEmploymentToCurrentTemplate(this.currentUserEmployment.Id)
        .subscribe((synedEmployment: IUserEmployment) => {
          this.newEmployment = synedEmployment;
          this.onUserEmploymentChange(synedEmployment);
        });
    }
  }

  private resetValidation(): void {
    this.isEmployeeInfoValid = true;
    this.isEmployeeInfoValid = true;
    this.isApiValidationValid = true;
  }

  private validate(): boolean {
    return this.isTabsValid && this.isEmployeeInfoValid && this.isApiValidationValid;
  }

  public onresendEmployeeWelcomEmailConfirmCompleted(action: string): void {
    if (action === 'YesPlease') {
      this.dataService.CompanyUsers_ResendUserEmploymentEmail(this.employee.Id).subscribe(() => {
        this.resendEmailSuccessVisible = true;
      });
    }
  }

  public onAvatarChanged(): void {
    this.isAvatarChanged.emit(true);
  }

  private getTemplates(): void {
    this.dataService
      .EmploymentTemplates_GetEmploymentTemplates(true)
      .subscribe((templates: IUserEmploymentTemplate[]): void => {
        if (templates.length > 0) {
          this.createNewEmploymentDialogVisible = true;
        } else {
          this.isNoTemplate = true;
        }
      });
  }
}
