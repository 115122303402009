<div *ngIf="!isapprovaltimeentry && !HasEmployment" class="Cards">
  <div class="Card">
    <div class="Card-inner">
      <module-check [moduleId]="8" [(hasModule)]="hasTimeModule">
        <h2 class="Card-title">{{ 'EmploymentPayrollData.No Employment' | translate }}</h2>
        <button-component
          [buttonClass]="'Button--primary'"
          [isDisabled]="!hasTimeModule"
          (click)="createUserEmployment()"
          [label]="'EmployeeGeneral.Create employment' | translate"
        ></button-component>
      </module-check>
    </div>
  </div>
</div>

<div class="Cards Cards--flex Form--stacked" *ngIf="HasEmployment && !isMobile && !isapprovaltimeentry">
  <div class="Card Employee-timeCard">
    <div class="Card-inner">
      <module-check [moduleId]="8" [(hasModule)]="hasTimeModule">
        <div class="Employee-timeControls">
          <combobox-edit-control
            class="fix-min-width"
            *ngIf="!isapprovaltimeentry"
            [label]="'EmploymentTime.SalaryPeriod' | translate"
            [disable]="!hasTimeModule"
            [editMode]="true"
            [comboboxDataSource]="SalaryPeriods"
            [textField]="'FriendlyName'"
            [idField]="'Id'"
            [isConvertTime]="false"
            [(value)]="selectedSalaryPeriodId"
            [showNavigationButton]="true"
            (valueChange)="onSalaryPeriodChanged($event)"
          ></combobox-edit-control>

          <combobox-edit-control
            class="fix-min-width"
            *ngIf="!isapprovaltimeentry"
            [label]="'EmploymentTime.Status' | translate"
            [disable]="!hasTimeModule"
            [editMode]="true"
            [comboboxDataSource]="TimeEntryStatuses"
            [(value)]="SelectedTimeStatusId"
            [textField]="'Name'"
            [idField]="'Id'"
            (valueChange)="timeStatusChanged()"
          ></combobox-edit-control>

          <button
            *ngIf="!isapprovaltimeentry && !IsReadOnly"
            class="Button--card"
            type="button"
            [disabled]="!hasTimeModule || sessionService.isSubmitting || !isAllowEditGridOption"
            (click)="ApproveEntries()"
          >
            {{ 'EmploymentTime.Approve' | translate }}
          </button>
        </div>

        <div *ngIf="!isapprovaltimeentry" class="FormElement">
          <div class="FormElement-control">
            <grid
              [editMode]="!IsReadOnly && isAllowEditGridOption"
              [addNew]="!IsReadOnly"
              [addNewText]="'+ ' + ('EmploymentTime.AddNewRecord' | translate)"
              [showButDisableAddNewButton]="true"
              [addNewDisable]="sessionService.isGetting || !isAllowEditGridOption"
              [addNewTooltips]="DisbaleTooltipAddNewEmployeetime | translate"
              (disableButtonAddNewClickEvent)="isDisableAddNew = true"
              [deleteRow]="!IsReadOnly"
              [(data)]="TimeEntryRecords"
              [idColumn]="'idColumn'"
              (selectedItemChange)="onSelectRow($event)"
              (gridButtonClick)="onGridButtonClick($event)"
              (updateDefaultRecordEvent)="onUpdateDefaultRecord()"
              (cellCloseEvent)="oncellCloseEvent($event)"
              (saveChangesEvent)="onSaveChangeGrid($event)"
              (addNewEvent)="onAddNewRecord($event)"
              (controlDataChange)="onControlDataChange($event)"
              [defaultNewValue]="defaultRecordValue"
              [customDeleteRow]="true"
              [selectedRows]="selectedRows"
              (removeEvent)="onDeleteRow($event)"
              [isEmployeeTime]="true"
              [(triggerUpdate)]="triggerUpdate"
              #kendoGridComponent
              class="FormElement-grid Employee-timeEntryGrid {{ EmptyEmployeetime() }}"
              [filterable]="'menu'"
              [filter]="filter"
              (filterChange)="onFilterChange($event)"
              [disableDeleteButton]="!isAllowEditGridOption"
              [DeleteTooltips]="DisbaleTooltipAddNewEmployeetime | translate"
            >
              <gridColumn
                [isFilterable]="true"
                [title]="'EmploymentTime.Type' | translate"
                [type]="'combobox'"
                [field]="'TimeEntryTypeId'"
                [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                [editable]="true"
                [comboboxDataSource]="TimeEntryTypes"
                [subEditableField]="'isAllowEdit'"
                [comboboxValueField]="'TimeEntryTypeId'"
                [comboboxDisplayField]="'Name'"
                [width]="120"
                [tooltipField]="'Description'"
                [tooltipFieldNonEdit]="'TimeEntryTypeName'"
              ></gridColumn>
              <gridColumn
                [isFilterable]="true"
                [title]="'EmploymentTime.EntryDate' | translate"
                [type]="'datepicker'"
                [field]="'EntryDate'"
                [editable]="true"
                [subEditableField]="'isAllowEdit'"
                [width]="140"
              ></gridColumn>
              <gridColumn
                [isFilterable]="true"
                [title]="'EmploymentTime.EndDate' | translate"
                [type]="'datepicker'"
                [field]="'EndDate'"
                [minDate]="selectedMinDate"
                [editable]="true"
                [subEditableField]="'isAllowEdit'"
                [width]="140"
              ></gridColumn>
              <gridColumn
                [isFilterable]="true"
                [title]="'EmploymentTime.StartTime' | translate"
                [type]="'timepicker'"
                [field]="'StartTime'"
                [editable]="true"
                [editableField]="'isEditStartAndEndTime'"
                [classField]="'ClassIsEditStartAndEndTime'"
                [subEditableField]="'isAllowEdit'"
                [width]="120"
              >
              </gridColumn>
              <gridColumn
                [isFilterable]="true"
                [title]="'EmploymentTime.EndTime' | translate"
                [type]="'timepicker'"
                [field]="'EndTime'"
                [editable]="true"
                [editableField]="'isEditStartAndEndTime'"
                [classField]="'ClassIsEditStartAndEndTime'"
                [subEditableField]="'isAllowEdit'"
                [width]="120"
              >
              </gridColumn>
              <gridColumn
                [isFilterable]="true"
                [title]="'EmploymentTime.Units' | translate"
                [type]="'numeric'"
                [field]="'Units'"
                [editable]="true"
                [editableField]="'isEditUnits'"
                [classField]="'ClassIsEditUnits'"
                [numbericOption]="numberOption"
                [subEditableField]="'isAllowEdit'"
                [width]="90"
              ></gridColumn>
              <gridColumn
                [isFilterable]="true"
                [title]="'EmploymentTime.UnitType' | translate"
                [type]="'combobox'"
                [field]="'UnitTypeId'"
                [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                [editable]="true"
                [editableField]="'isEditUnitType'"
                [classField]="'ClassIsEditUnitType'"
                [comboboxDataSource]="UnitTypes"
                [comboboxValueField]="'Id'"
                [comboboxDisplayField]="'Name'"
                [subEditableField]="'isAllowEdit'"
                [width]="80"
              ></gridColumn>
              <gridColumn
                [isFilterable]="true"
                [title]="'EmploymentTime.Description' | translate"
                [type]="'text'"
                [field]="'Description'"
                [tooltipField]="'Description'"
                [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                [editable]="true"
                [subEditableField]="'isAllowEdit'"
                [width]="150"
              ></gridColumn>
              <gridColumn
                [title]="'EmploymentTime.Status' | translate"
                [type]="'subProberty'"
                [field]="'Status'"
                [editable]="true"
                [editableField]="true"
                [subProberty]="'Status.Name'"
                [tooltipField]="'RejectionReason'"
                [subEditableField]="'isAllowEdit'"
                [width]="100"
              ></gridColumn>
              <gridColumn
                [isFilterable]="true"
                [title]="'EmploymentTime.KilometersDriven' | translate"
                [type]="'numeric'"
                [numbericOption]="numberOption"
                [field]="'KilometersDriven'"
                [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                [editable]="true"
                [subEditableField]="'isAllowEdit'"
                [width]="90"
              ></gridColumn>
              <gridColumn
                [isFilterable]="true"
                [title]="Dimension1Name"
                [type]="'text'"
                [field]="'Dimension1Value'"
                *ngIf="isHideDimension1"
                [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                [editable]="true"
                [subEditableField]="'isAllowEdit'"
                [width]="90"
              ></gridColumn>
              <gridColumn
                [isFilterable]="true"
                [title]="Dimension2Name"
                [type]="'text'"
                [field]="'Dimension2Value'"
                *ngIf="isHideDimension2"
                [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                [editable]="true"
                [subEditableField]="'isAllowEdit'"
                [width]="90"
              ></gridColumn>
              <gridColumn
                [isFilterable]="true"
                [title]="Dimension3Name"
                [type]="'text'"
                [field]="'Dimension3Value'"
                *ngIf="isHideDimension3"
                [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                [editable]="true"
                [subEditableField]="'isAllowEdit'"
                [width]="90"
              ></gridColumn>
              <gridColumn
                *ngIf="!isSelfService"
                [isFilterable]="true"
                [title]="''"
                [type]="'button'"
                [buttonToottip]="''"
                [buttonLabel]="'EmploymentTime.Reject'"
                [editable]="true"
                [buttonClass]="'Reject k-button'"
                [buttonAction]="'Reject'"
                [width]="90"
              ></gridColumn>
            </grid>
          </div>
        </div>
      </module-check>
    </div>
  </div>

  <div *ngIf="!isapprovaltimeentry && !isMobile" class="Card Employee-timePreviewCard">
    <div class="Card-inner">
      <div class="Card-title">{{ 'EmploymentTime.Preview' | translate }}</div>
      <div class="FormElement Employee-timeEntryPreviewGrid">
        <label class="FormElement-label" for="previewGrid">
          {{ 'EmploymentTime.Sum for this period' | translate }}
        </label>
        <div class="FormElement-control">
          <grid
            [editMode]="false"
            [addNew]="false"
            [deleteRow]="false"
            [data]="newPreviewData"
            (selectedItemChange)="onSelectRow($event)"
            (gridButtonClick)="onGridButtonClick($event)"
            class="FormElement-grid {{ EmptyEmployeePreview() }}"
          >
            <gridColumn [title]="'EmploymentTime.Type' | translate" [type]="'text'" [field]="'Type'" [width]="112">
            </gridColumn>
            <gridColumn [title]="'EmploymentTime.Units' | translate" [type]="'numeric'" [field]="'Units'" [width]="80">
            </gridColumn>
            <gridColumn [title]="''" [type]="'text'" [field]="'UnitName'" [width]="50"> </gridColumn>
          </grid>
        </div>
      </div>
    </div>
  </div>

  <app-dialog [(visible)]="isErrorApiMessage" [width]="'500'" [leadingText]="MessagErrorApi">
    <app-dialog-action type="Ok"></app-dialog-action>
  </app-dialog>

  <app-dialog
    [(visible)]="isDisableAddNew"
    [width]="'500'"
    [leadingText]="DisbaleTooltipAddNewEmployeetime | translate"
    (action)="setKeypress()"
  >
    <app-dialog-action type="Ok"></app-dialog-action>
  </app-dialog>

  <app-dialog [(visible)]="isUpdateWarrningPeriods" [width]="'500'" [leadingText]="MessageUpdateWarrningPeriods">
    <app-dialog-action type="Ok"></app-dialog-action>
  </app-dialog>

  <app-dialog
    [(visible)]="isgetNotMatchPeriodMessageString"
    [width]="'500'"
    [leadingText]="getNotMatchPeriodMessageString | translate"
    (action)="onCloseNotMatchPeriodMessage($event)"
  >
    <app-dialog-action type="Ok"></app-dialog-action>
  </app-dialog>

  <app-dialog
    [overlayClose]="false"
    [(visible)]="isShowRejectDialogNonApproveTimeEntry"
    [width]="'500'"
    (action)="onRejectDialog($event)"
    [leadingText]="'EmploymentTime.RejectionWarning' | translate"
  >
    <app-text-edit [label]="''" [editMode]="true" [(value)]="rejectMessage"></app-text-edit>
    <app-dialog-action type="Close"></app-dialog-action>
    <app-dialog-action type="RejectPrimary" [disabled]="sessionService.isSubmitting"></app-dialog-action>
  </app-dialog>

  <app-dialog
    [(visible)]="isShowDeleteRecord"
    [width]="'500'"
    (action)="onDeleteRecordAction($event)"
    [leadingText]="'Grids.Confirm Delete' | translate"
  >
    <app-dialog-action type="Close"></app-dialog-action>
    <app-dialog-action type="Delete" [disabled]="sessionService.isSubmitting"></app-dialog-action>
  </app-dialog>

  <app-dialog
    [(visible)]="isShowDateErrorInput"
    [width]="'500'"
    [leadingText]="'EmploymentTime.StartDateBeforeEndDate' | translate"
  >
    <!-- missing title -->
    <app-dialog-action type="Ok"></app-dialog-action>
  </app-dialog>
</div>

<app-dialog
  *ngIf="!isapprovaltimeentry"
  [(visible)]="isShowEmployeeTimeApproveDialog"
  [width]="'500'"
  (action)="onEmployeeTimeApproveDialog($event)"
  [leadingText]="'EmploymentTime.ApproveConfirmText' | translate"
>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="Approve" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<div *ngIf="isapprovaltimeentry" class="Cards Cards--flex Form--stacked">
  <div class="Card Employee-timeCard">
    <div class="Card-inner">
      <check-edit
        [editMode]="true"
        [(value)]="isSpecialMode"
        (valueChange)="onModeChange()"
        [label]="'EmploymentTime.Show all open entries across periods' | translate"
        class="integrationConfigurationControl"
      >
      </check-edit>
      <div class="Employee-timeControls Approval-timeCard">
        <combobox-edit-control
          [disable]="isSpecialMode"
          [label]="'EmploymentTime.SalaryPeriod' | translate"
          [editMode]="true"
          [isConvertTime]="false"
          [textField]="'FriendlyName'"
          [idField]="'Id'"
          [comboboxDataSource]="SalaryPeriod"
          [(value)]="selectedSalaryPeriodId"
          [placeholder]="'EmploymentTime.SalaryPeriod' | translate"
          (valueChange)="onBaseSalaryPeriodComboboxChanged($event)"
          [showNavigationButton]="true"
        >
        </combobox-edit-control>
        <combobox-edit-control
          [disable]="isSpecialMode"
          class="marginSmallscreen"
          [label]="'EmploymentTime.Status' | translate"
          [editMode]="true"
          [textField]="'Name'"
          [idField]="'Id'"
          [comboboxDataSource]="TimeEntryStatus"
          [(value)]="selectedTimeEntryStatusId"
          [placeholder]="'EmploymentTime.Status' | translate"
          (valueChange)="onBaseTimeEntryStatusComboboxChanged($event)"
        ></combobox-edit-control>
        <combobox-edit-control
          *ngIf="isapprovaltimeentry && isShowSalaryCycle"
          [label]="'EmploymentTime.Salary Cycle' | translate"
          [editMode]="true"
          [textField]="'Name'"
          [idField]="'Id'"
          [comboboxDataSource]="SalaryCycle"
          [(value)]="selectedSalaryCycleId"
          [placeholder]="'EmploymentTime.Salary Cycle' | translate"
          (valueChange)="onBaseSalaryCycleComboboxChanged($event)"
        ></combobox-edit-control>

        <div class="leftarea" kendoTooltip [title]="allowManagersToApproveTimeEntryMessage | translate">
          <button
            class="Button--card equalbutton"
            type="button"
            [disabled]="
              sessionService.isSubmitting ||
              (IsReadOnly && !IsReadOnlyAndHasSubordinatesAndFilterByEligibleForApproval) ||
              !allowManagersToApproveTimeEntry
            "
            (click)="ApproveEntriesallforselectedemployee()"
          >
            {{ 'EmploymentTime.Approve all for selected employee' | translate }}
          </button>
          <button
            *ngIf="isEnableControlNomalMode"
            class="Button--card equalbutton"
            type="button"
            [disabled]="
              sessionService.isSubmitting ||
              (IsReadOnly && !IsReadOnlyAndHasSubordinatesAndFilterByEligibleForApproval) ||
              !isEnableControlNomalMode ||
              !allowManagersToApproveTimeEntry
            "
            (click)="ApproveEntries()"
          >
            {{ 'EmploymentTime.Approve entire period' | translate }}
          </button>
          <button
            *ngIf="!isEnableControlNomalMode"
            class="Button--card equalbutton"
            type="button"
            [disabled]="
              sessionService.isSubmitting ||
              (IsReadOnly &&
                !IsReadOnlyAndHasSubordinatesAndFilterByEligibleForApproval &&
                !allowManagersToApproveTimeEntry) ||
              isEnableControlNomalMode ||
              !allowManagersToApproveTimeEntry
            "
            (click)="ApproveOpenEntries()"
          >
            {{ 'CompanyApproval.ApproveAllOpenEntries' | translate }}
          </button>
        </div>
      </div>

      <div class="FormElement">
        <div class="FormElement-control">
          <grid
            [editMode]="false"
            [addNew]="false"
            [deleteRow]="false"
            [(data)]="TimeEntryRecord"
            (selectedItemChange)="onSelectRow($event)"
            (gridButtonClick)="onGridButtonClick($event)"
            (clickAppGridIcon)="onClickAppGridIcon($event)"
            class="FormElement-grid Employee-timeEntryGrid {{ EmptyEmployeetimeApproval() }}"
            [filterable]="'menu'"
            [filter]="approvalFilter"
            (filterChange)="onApprovalFilterChange($event)"
          >
            <gridColumn
              [isFilterable]="true"
              [title]="'CompanySalaryBatches.OrtherStatusGrid Employee Name' | translate"
              [type]="'text'"
              [field]="'EmployeeName'"
              [width]="180"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmploymentTime.Type' | translate"
              [type]="'combobox'"
              [field]="'TimeEntryTypeId'"
              [comboboxDataSource]="TimeEntryTypes"
              [comboboxValueField]="'TimeEntryTypeId'"
              [comboboxDisplayField]="'Name'"
              [width]="120"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmploymentTime.EntryDate' | translate"
              [type]="'datepicker'"
              [field]="'EntryDate'"
              [width]="100"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmploymentTime.EndDate' | translate"
              [type]="'datepicker'"
              [field]="'EndDate'"
              [minDate]="selectedMinDate"
              [width]="100"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmploymentTime.StartTime' | translate"
              [type]="'timepicker'"
              [field]="'StartTime'"
              [width]="70"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmploymentTime.EndTime' | translate"
              [type]="'timepicker'"
              [field]="'EndTime'"
              [width]="70"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [isFilterable]="true"
              [title]="'EmploymentTime.Units' | translate"
              [type]="'numeric'"
              [field]="'Units'"
              [numbericOption]="numberOption"
              [width]="50"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmploymentTime.UnitType' | translate"
              [type]="'combobox'"
              [field]="'UnitTypeId'"
              [comboboxDataSource]="UnitTypes"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [width]="80"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmploymentTime.KilometersDriven' | translate"
              [type]="'numeric'"
              [numbericOption]="numberOption"
              [field]="'KilometersDriven'"
              [width]="90"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmploymentTime.Description' | translate"
              [type]="'text'"
              [field]="'Description'"
              [viewClass]="'truncated'"
              [width]="200"
            ></gridColumn>
            <gridColumn
              [title]="'EmploymentTime.Status' | translate"
              [type]="'subProberty'"
              [field]="'Status'"
              [tooltipField]="'RejectionReason'"
              [subProberty]="'Status.Name'"
              [width]="100"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmploymentTime.DepartmentName' | translate"
              [type]="'text'"
              [field]="'DepartmentName'"
              [width]="130"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmploymentTime.Title' | translate"
              [type]="'text'"
              [field]="'Title'"
              [width]="90"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'1'"
              [type]="'text'"
              [field]="'Dimension1Value'"
              *ngIf="isHideDimension1"
              [width]="90"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'2'"
              [type]="'text'"
              [field]="'Dimension2Value'"
              *ngIf="isHideDimension2"
              [width]="90"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'3'"
              [type]="'text'"
              [field]="'Dimension3Value'"
              *ngIf="isHideDimension3"
              [width]="90"
            ></gridColumn>
            <gridColumn
              [title]="''"
              [type]="'icon'"
              [tooltip]="TooltipFroApproButtonAprrovalGrid | translate"
              [iconField]="'iconFeildApprove'"
              [iconAction]="'ApproveSingleRecord'"
              [width]="60"
              [disableButtonFeild]="'isDisbaleSingleApprove'"
              [disableGridButtonValue]="!allowManagersToApproveTimeEntry"
              [disableGridButtonTooltip]="allowManagersToApproveTimeEntryMessage"
            ></gridColumn>
            <gridColumn
              [title]="''"
              [type]="'button'"
              [buttonToottip]="'EmploymentTime.Reject'"
              [buttonLabel]="'X'"
              [buttonClass]="'k-button k-button-icontext deleteButtonColumn k-grid-XCustoom'"
              [buttonAction]="'Reject'"
              [width]="60"
              [disableButtonFeild]="'isDisbaleReject'"
              [disableGridButtonValue]="!allowManagersToApproveTimeEntry"
              [disableGridButtonTooltip]="allowManagersToApproveTimeEntryMessage"
            ></gridColumn>
          </grid>
        </div>
      </div>
    </div>
  </div>

  <app-dialog [overlayClose]="false" [(visible)]="isShowRejectDialog" [width]="'500'" (action)="onRejectDialog($event)">
    <div innerHTML="{{ 'EmploymentTime.RejectionWarning' | translate }}"></div>
    <app-text-edit [label]="''" [editMode]="true" [(value)]="rejectMessage"></app-text-edit>
    <app-dialog-action type="Close"></app-dialog-action>
    <app-dialog-action type="RejectPrimary" [disabled]="sessionService.isSubmitting"></app-dialog-action>
  </app-dialog>

  <app-dialog [(visible)]="isShowApproveForSelectDialog" [width]="'500'" (action)="onApproveForSelectDialog($event)">
    <div>{{ 'EmploymentTime.Approve confim text' | translate }} {{ nameEmployeeSelect }}?</div>
    <app-dialog-action type="Close"></app-dialog-action>
    <app-dialog-action type="Approve"></app-dialog-action>
  </app-dialog>

  <app-dialog [(visible)]="isShowApproveForAllDialog" [width]="'500'" (action)="onApproveForAllDialog($event)">
    <div>{{ 'EmploymentTime.ApproveConfirmText' | translate }}</div>
    <app-dialog-action type="Close"></app-dialog-action>
    <app-dialog-action type="Approve"></app-dialog-action>
  </app-dialog>

  <app-dialog [(visible)]="isShowApprovalOpenEntries" [width]="'500'" (action)="onApprovalOpenEntries($event)">
    <div>{{ 'CompanyApproval.ApproveAllOpenEntriesMessage' | translate }}</div>
    <app-dialog-action type="Close"></app-dialog-action>
    <app-dialog-action type="Understood"></app-dialog-action>
  </app-dialog>
</div>

<div *ngIf="isMobile && !isapprovaltimeentry && HasEmployment">
  <module-check [moduleId]="8">
    <app-employee-time-mobile
      [isReadOnly]="IsReadOnly"
      [salaryPeriods]="SalaryPeriods"
      [timeEntryTypes]="TimeEntryTypes"
      [(selectedSalaryPeriodId)]="selectedSalaryPeriodId"
      (periodsChange)="onSalaryperiodsMobileCentricChange()"
      (approveEntries)="ApproveEntries()"
      [(timeEntryRecords)]="TimeEntryRecords"
      [(isAddNew)]="isAddNew"
      [userEmployment]="userEmployment"
      [unitTypes]="UnitTypes"
      [deleteConfimMessage]="'Grids.Confirm Delete' | translate"
      [rejectionWarningText]="'EmploymentTime.RejectionWarning' | translate"
      [isHideDimension1]="isHideDimension1"
      [isHideDimension2]="isHideDimension2"
      [isHideDimension3]="isHideDimension3"
      [Dimension1Name]="Dimension1Name"
      [Dimension2Name]="Dimension2Name"
      [Dimension3Name]="Dimension3Name"
      [PeriodWarningText]="PeriodWarningText"
    >
    </app-employee-time-mobile>
  </module-check>
</div>
