import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../../Common/Constants';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import { Global } from '../../../../Common/Global';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';
var ContactInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContactInfoComponent, _super);
    function ContactInfoComponent(staticDataService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.companyUserChange = new EventEmitter();
        _this.childIsValid = Array(10).fill(true);
        _this.childIsDirty = Array(10).fill(false);
        return _this;
    }
    Object.defineProperty(ContactInfoComponent.prototype, "companyUser", {
        get: function () {
            if (!this.companyUserValue) {
                this.companyUserValue = {
                    Address: {}
                };
            }
            return this.companyUserValue;
        },
        set: function (value) {
            this.companyUserValue = value;
            this.companyUserChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    ContactInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.City.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.cities = data); });
        this.staticDataService.Country.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.countries = data); });
        this.staticDataService.Municipality.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (municipalities) {
            _this.municipality =
                municipalities && municipalities.length
                    ? municipalities.filter(function (muni) {
                        return Global.SESSION && muni.CountryId === Global.SESSION.CurrentCountryId;
                    })
                    : undefined;
        });
    };
    Object.defineProperty(ContactInfoComponent.prototype, "IsGreenlandCompanyAnduserEmloyment", {
        get: function () {
            return (Global.COMPANY &&
                Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID &&
                this.companyUser &&
                this.companyUser.UserEmployments &&
                this.companyUser.UserEmployments.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    ContactInfoComponent.prototype.onPostalCodeChanged = function () {
        if (this.companyUser.Address.CountryId !== 1) {
            return;
        }
        this.autoFillCity(this.companyUser.Address.PostalCode);
    };
    ContactInfoComponent.prototype.autoFillCity = function (postalCode) {
        var _this = this;
        if (!postalCode) {
            return;
        }
        var city;
        if (this.cities) {
            city = this.cities.find(function (c) {
                return (c.CountryId === _this.companyUser.Address.CountryId && c.PostalCode && postalCode === c.PostalCode.toString());
            });
        }
        if (!city) {
            city = this.cities.find(function (c) {
                return c.PostalCode && postalCode === c.PostalCode.toString();
            });
        }
        if (city) {
            this.companyUser.Address.City = city.Name;
            if (city.CountryId !== this.companyUser.Address.CountryId) {
                this.companyUser.Address.CountryId = city.CountryId;
            }
        }
    };
    ContactInfoComponent.prototype.onComboboxControlChanged = function () {
        if (this.companyUser.Address.CountryId !== Constants.GREENLAND_COUNTRY_ID &&
            this.companyUser.AddressId !== Constants.DENMARK_COUNTRY_ID) {
            this.companyUser.MunicipalityId = 8;
        }
        this.onChange();
    };
    ContactInfoComponent.prototype.onDataChange = function () {
        this.onChange();
    };
    ContactInfoComponent.prototype.onBlur = function () { };
    return ContactInfoComponent;
}(FormComponentBase));
export { ContactInfoComponent };
