<div class="FormElement">
  <span class="FormElement-label" *ngIf="!tooltipContent && label">
    <abbr *ngIf="required" class="FormElement-required" title="required">*</abbr> {{ label }}:
  </span>
  <div class="dx-field">
    <div>
      <dx-drop-down-box
        #dxDropDownBoxControl
        [valueExpr]="valueExp"
        [displayExpr]="displayExp"
        placeholder="Select a value..."
        [showClearButton]="false"
        [dataSource]="gridDataSource"
        [(value)]="SelectValueIds"
      >
        <div *dxTemplate="let data of 'content'">
          <dx-data-grid
            showColumnHeaders="true"
            showColumnLines="false"
            [dataSource]="gridDataSource"
            [selection]="{ mode: 'multiple' }"
            [columns]="columns"
            [hoverStateEnabled]="true"
            [paging]="{ enabled: true, pageSize: 10 }"
            [filterRow]="{ visible: false }"
            [scrolling]="{ mode: 'infinite' }"
            [height]="345"
            [(selectedRowKeys)]="GridBoxValue"
          >
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>
  </div>
</div>
