<app-dialog
  (action)="onDialogAction($event)"
  [overlayClose]="false"
  [(visible)]="visible"
  [leadingText]="'CompanySalaryBatches.LockSalaryBatchDescription' | translate"
>
  <br />
  <check-edit
    [label]="'CompanySalaryBatches.EmployeeViewPreliminaryPayslip' | translate"
    [editMode]="true"
    [(value)]="notifyEmployee"
  >
  </check-edit>
  <div *ngIf="notifyEmployee">
    <p [innerHTML]="'CompanySalaryBatches.PreliminaryPayslipExplanation' | translate"></p>
  </div>
  <app-dialog-action [disabled]="sessionService.isSubmitting" [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action
    [disabled]="sessionService.isSubmitting"
    [type]="'SubmitForApproval'"
    [close]="false"
  ></app-dialog-action>
</app-dialog>
