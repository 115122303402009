import { EventEmitter } from '@angular/core';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
var TestEmailComponent = /** @class */ (function () {
    function TestEmailComponent(dataService, sessionService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.errorDialogVisible = false;
    }
    Object.defineProperty(TestEmailComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            this.visibleValue = value;
            this.visibleChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    TestEmailComponent.prototype.dialogAction = function (action) {
        var _this = this;
        if (action === 'Test') {
            var parameters = this.emails.replace(/\r?\n/g, ';');
            this.dataService.Backend_NotifyAdminsOfFailedEmails(parameters).subscribe(function (responses) {
                if (responses && responses.length > 0) {
                    _this.failedMessage = _this.getResponseMessage(responses);
                    _this.errorDialogVisible = true;
                }
                else {
                    _this.close();
                }
            });
        }
        else {
            this.close();
        }
    };
    TestEmailComponent.prototype.close = function () {
        this.errorDialogVisible = false;
        this.visible = false;
        this.emails = undefined;
    };
    TestEmailComponent.prototype.getResponseMessage = function (responses) {
        var result = '';
        if (responses) {
            responses.forEach(function (message) {
                result += message + '<br/>';
            });
        }
        return result;
    };
    return TestEmailComponent;
}());
export { TestEmailComponent };
