import { RouterStateService } from '../Common/RouterState.service';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
var AccountantComponent = /** @class */ (function () {
    function AccountantComponent(session, state) {
        this.session = session;
        this.state = state;
    }
    Object.defineProperty(AccountantComponent.prototype, "IsMobile", {
        get: function () {
            return this.session.browser.isMobile || this.session.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantComponent.prototype, "isAccountantCompaniesTab", {
        get: function () {
            return this.session.currentState.includes('tabs.accountant.companies');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantComponent.prototype, "isAccountantUserTab", {
        get: function () {
            return this.session.currentState.includes('tabs.accountant.users');
        },
        enumerable: true,
        configurable: true
    });
    AccountantComponent.prototype.clickAreamenuToggledChange = function () {
        this.session.menuToggled = true;
        this.session.showUserMenuToggled = false;
        this.session.showMoreMenuToggled = false;
        this.session.showHelpMenuToggled = false;
    };
    return AccountantComponent;
}());
export { AccountantComponent };
