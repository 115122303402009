<app-dialog
  (action)="onDialogAction($event)"
  [overlayClose]="false"
  [(visible)]="visible"
  class="FinalizeBatchConfirmDialog"
>
  <div class="Modal-header" [innerHTML]="'Finalize.Step2Explanatory' | translate"></div>

  <!--The batch is permanently locked and can no longer be modified-->
  <div class="FormElement-row">
    <div class="FormElement--9of10 padding-right">
      <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
      <span>{{ 'Finalize.Step2Item1' | translate }}</span>
    </div>
    <div class="FormElement--1of10"></div>
  </div>

  <!--Employees are notified and can view their payslips-->
  <div class="FormElement-row">
    <div class="FormElement--9of10 padding-right">
      <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
      <span>{{ 'Finalize.Step2Item2' | translate }}</span>
    </div>
    <div class="FormElement--1of10"></div>
  </div>

  <!--Data is reported to tax authorities (SKAT)-->
  <div *ngIf="!isGreenland" class="FormElement-row">
    <div class="FormElement--9of10 padding-right">
      <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
      <span>{{ 'Finalize.Step2Item3' | translate }}</span>
    </div>
    <div class="FormElement--1of10"></div>
  </div>

  <!--Bank transfers are made to employees-->
  <div class="FormElement-row" *ngIf="salarybatch?.PaymentMethodSalary == 1">
    <div class="FormElement--9of10 padding-right">
      <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
      <span>{{ 'Finalize.Step2Item4' | translate }}</span>
    </div>
    <div class="FormElement--1of10"></div>
  </div>

  <!--Bank transfers are made to pension providers-->
  <div class="FormElement-row" *ngIf="salarybatch?.PaymentMethodPension == 1">
    <div class="FormElement--9of10 padding-right">
      <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
      <span>{{ 'Finalize.Step2Item5' | translate }}</span>
    </div>
    <div class="FormElement--1of10"></div>
  </div>

  <!--Bank transfers are made to tax authorities-->
  <div class="FormElement-row" *ngIf="salarybatch?.PaymentMethodTax == 1">
    <div class="FormElement--9of10 padding-right">
      <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
      <span>{{ 'Finalize.Step2Item6' | translate }}</span>
    </div>
    <div class="FormElement--1of10"></div>
  </div>

  <!--Data is exported to {Company.ExternalSystem.Name}-->
  <!-- <div class="FormElement-row" *ngIf="company?.ExternalAuthentication && company?.ExternalSystemId">
        <div class="FormElement--9of10 padding-right">
            <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
            <span>{{'Finalize.Step2Item7' | translate}} {{ company.ExternalSystem.Name }}</span>
        </div>
        <div class="FormElement--1of10"></div>
    </div> -->

  <!--Payslips are delivered to employees' E-Boks account-->
  <div class="FormElement-row" *ngIf="eBoksModuleEnabled">
    <div class="FormElement--9of10 padding-right">
      <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
      <span>{{ 'Finalize.Step2Item8' | translate }}</span>
    </div>
    <div class="FormElement--1of10"></div>
  </div>

  <!--Bank transfers are NOT made to pension providers-->
  <div class="FormElement-row" *ngIf="isHasIntegrationName && salarybatch?.PaymentMethodPension !== 1">
    <div class="FormElement--9of10 padding-right">
      <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
      <span [innerHTML]="'Finalize.Step2Item7' | translate"></span>
      <span>{{ IntegrationName }}</span>
    </div>
  </div>

  <div class="FormElement-row" *ngIf="!isHasIntegrationName && salarybatch?.PaymentMethodPension !== 1">
    <div class="FormElement--9of10 padding-right">
      <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
      <span [innerHTML]="'Finalize.Step2Item7-Disable' | translate"></span>
    </div>
    <div class="FormElement--1of10" *ngIf="isAccountAdmin">
      <button class="Button--card" type="button" (click)="activateDataExport()">
        {{ 'Finalize.Active' | translate }}
      </button>
    </div>
  </div>

  <!--Bank transfers are NOT made to employees-->
  <div class="FormElement-row" *ngIf="salarybatch?.PaymentMethodSalary !== 1">
    <div class="FormElement--9of10 padding-right">
      <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
      <span [innerHTML]="'Finalize.Step2Item4-Disable' | translate"></span>
    </div>
    <div class="FormElement--1of10" *ngIf="isAccountAdmin">
      <button class="Button--card" type="button" (click)="setStartupTaskDone()">
        {{ 'Finalize.Active' | translate }}
      </button>
    </div>
  </div>

  <!--Bank transfers are NOT made to tax authorities-->
  <div class="FormElement-row" *ngIf="salarybatch?.PaymentMethodTax !== 1">
    <div class="FormElement--9of10 padding-right">
      <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
      <span [innerHTML]="'Finalize.Step2Item6-Disable' | translate"></span>
    </div>
    <div class="FormElement--1of10" *ngIf="isAccountAdmin && !isGreenland">
      <button class="Button--card" type="button" (click)="setStartupTaskDone()">
        {{ 'Finalize.Active' | translate }}
      </button>
    </div>
  </div>

  <div class="FormElement-row" *ngIf="isGreenland">
    <div class="FormElement--9of10 padding-right">
      <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
      <span [innerHTML]="'Finalize.Step2Item9-Disable' | translate"></span>
    </div>
  </div>

  <!--Data is NOT exported to {Company.ExternalSystem.Name}-->
  <!-- <div class="FormElement-row" *ngIf="!company?.ExternalAuthentication && company?.ExternalSystemId">
        <div class="FormElement--9of10 padding-right">
            <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
            <span ng-bind-html="'Finalize.Step2Item7-Disable' | translate"></span>
            <span>{{ company.ExternalSystem.Name }}</span>
        </div>
        <div class="FormElement--1of10" *ngIf="isAccountAdmin && !isIOSMobileApp">
            <button class="Button--card" type="button" (click)="activateDataExport()">{{'Finalize.Active' | translate}}</button>
        </div>
    </div> -->

  <!--Payslips are NOT delivered to employees' E-Boks account-->
  <div class="FormElement-row" *ngIf="!eBoksModuleEnabled">
    <div class="FormElement--9of10 padding-right">
      <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
      <span [innerHTML]="'Finalize.Step2Item8-Disable' | translate"></span>
    </div>
    <div class="FormElement--1of10" *ngIf="isAccountAdmin">
      <button class="Button--card" type="button" (click)="navigateToExtraTab()">
        {{ 'Finalize.Active' | translate }}
      </button>
    </div>
  </div>

  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Understood"></app-dialog-action>
</app-dialog>
