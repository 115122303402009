import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { Position } from './Position';
var PositionInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PositionInfoComponent, _super);
    function PositionInfoComponent(settingService, sessionService) {
        var _this = _super.call(this) || this;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.positionTextChanged = new EventEmitter();
        _this.employmentSelectionChanged = new EventEmitter();
        _this.companyUserChange = new EventEmitter();
        _this.createUserEmploymentClick = new EventEmitter();
        _this.isEmployeeTab = false;
        _this.childIsValid = Array(2).fill(true);
        _this.childIsDirty = Array(2).fill(false);
        return _this;
    }
    Object.defineProperty(PositionInfoComponent.prototype, "companyUser", {
        get: function () {
            return this.companyUserValue;
        },
        set: function (value) {
            var _this = this;
            this.companyUserValue = value;
            this.companyUserChange.emit(value);
            if (this.hasNoEmployment) {
                this.userEmployments = [];
                this.userEmploymentDataSource = [];
            }
            else {
                this.userEmployments = this.companyUserValue.UserEmployments;
                var titles_1 = [];
                if (this.companyUserValue.UserEmployments) {
                    this.companyUserValue.UserEmployments.forEach(function (item) {
                        if (!item) {
                            return;
                        }
                        var position = _this.createPosition(item);
                        titles_1.push(position);
                    });
                }
                this.userEmploymentDataSource = titles_1;
            }
            this.selectDefaultPosition();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "currentPositionId", {
        get: function () {
            return this.currentPositionIdValue;
        },
        set: function (value) {
            this.setcurrentUserEmploymentValue(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "newEmploymentAdded", {
        get: function () {
            return this.newEmployment;
        },
        set: function (value) {
            if (value) {
                var existingEmployment = this.companyUserValue.UserEmployments.find(function (employment) { return employment.Id === value.Id; });
                if (!existingEmployment) {
                    // Update datasource
                    this.companyUserValue.UserEmployments.push(value);
                    var oldPositions = this.positions.slice();
                    var newPosition = this.createPosition(value);
                    oldPositions.push(newPosition);
                    this.userEmploymentDataSource = [];
                    this.userEmploymentDataSource = oldPositions;
                }
                else {
                    var existingPosition = this.userEmploymentDataSource.find(function (employment) { return employment.Id === value.Id; });
                    this.companyUserValue.UserEmployments.splice(this.companyUserValue.UserEmployments.indexOf(existingEmployment), 1, value);
                    this.userEmploymentDataSource.splice(this.userEmploymentDataSource.indexOf(existingPosition), 1, this.createPosition(value));
                }
                this.newEmployment = value;
                this.selectDefaultPosition();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "deletedEmployment", {
        get: function () {
            return this.deletedUserEmployment;
        },
        set: function (value) {
            if (value) {
                // Update datasource
                var deletedIndex = this.positions.findIndex(function (item) { return item.Id === value.Id; });
                if (deletedIndex !== -1) {
                    var oldPositions = this.positions.slice();
                    oldPositions.splice(deletedIndex, 1);
                    this.userEmploymentDataSource = oldPositions;
                    this.setcurrentUserEmploymentValue();
                }
            }
            this.deletedUserEmployment = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "hasMultipleEmployments", {
        get: function () {
            return this.companyUser && this.userEmploymentDataSource && this.userEmploymentDataSource.length > 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "hasSingleEmployment", {
        get: function () {
            return this.companyUser && this.userEmploymentDataSource && this.userEmploymentDataSource.length === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "hasNoEmployment", {
        get: function () {
            return !this.companyUser || !this.companyUser.UserEmployments || this.companyUser.UserEmployments.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "allowEditTitle", {
        get: function () {
            return this.editMode && !this.hasNoEmployment;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "userEmploymentDataSource", {
        get: function () {
            return this.positions;
        },
        set: function (value) {
            if (this.positions !== value) {
                this.positions = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "isTabSelfService", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.selfservice');
        },
        enumerable: true,
        configurable: true
    });
    PositionInfoComponent.prototype.createPosition = function (employment) {
        var item = new Position();
        item.DisplayTitle = employment.Title;
        var expireDate = employment.ExpireDate ? new Date(employment.ExpireDate) : undefined;
        var today = new Date();
        if (expireDate && expireDate < today) {
            item.DisplayTitle = '(' + employment.Title + ')';
        }
        item.Id = employment.Id;
        return item;
    };
    PositionInfoComponent.prototype.selectDefaultPosition = function () {
        var _this = this;
        var currentEmployment;
        if (this.companyUser && this.companyUser.UserEmployments && this.companyUser.UserEmployments.length > 1) {
            currentEmployment = this.companyUserValue.UserEmployments.find(function (item) { return item.Id === _this.currentPositionId; });
        }
        var employmentId = this.newEmployment
            ? this.newEmployment.Id
            : currentEmployment
                ? currentEmployment.Id
                : undefined;
        this.newEmployment = undefined;
        this.setcurrentUserEmploymentValue(employmentId);
        if (!employmentId) {
            this.onPositionChangedEvent();
        }
    };
    PositionInfoComponent.prototype.setcurrentUserEmploymentValue = function (value) {
        var _this = this;
        setTimeout(function () {
            if (!value) {
                if (!_this.positions || _this.positions.length === 0) {
                    _this.currentPositionIdValue = undefined;
                    _this.currentUserEmployment = undefined;
                }
                else {
                    if (_this.settingService && _this.settingService.stayEmploymentId) {
                        _this.currentPositionIdValue = _this.settingService.stayEmploymentId;
                        _this.settingService.stayEmploymentId = undefined;
                    }
                    else {
                        _this.currentPositionIdValue = _this.positions[0].Id;
                    }
                    _this.currentUserEmployment = _this.companyUserValue.UserEmployments.find(function (item) { return item.Id === _this.currentPositionIdValue; });
                }
                return;
            }
            _this.currentPositionIdValue = value;
            _this.currentUserEmployment = _this.companyUserValue.UserEmployments.find(function (item) { return item.Id === _this.currentPositionIdValue; });
        });
    };
    PositionInfoComponent.prototype.onPositionChangedEvent = function () {
        var _this = this;
        setTimeout(function () {
            _this.employmentSelectionChanged.emit(_this.currentUserEmployment);
        });
    };
    PositionInfoComponent.prototype.onPositionTextInputChange = function () {
        this.onChange();
        if (this.isDirty) {
            this.positionTextChanged.emit(this.currentUserEmployment.Title);
        }
    };
    PositionInfoComponent.prototype.onAddonClick = function () {
        this.createUserEmploymentClick.emit();
    };
    return PositionInfoComponent;
}(FormComponentBase));
export { PositionInfoComponent };
