import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CellClickEvent, GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy, GroupDescriptor } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../../Common/Global';
import { GridHelper } from '../../Common/GridHelper';
import { ListHelper } from '../../Common/ListHelper';
import { SalaryRecordCalculation } from '../../Employee/EmployeeTab/Payroll/Controls/SalaryRecordCalculation';
import { OverviewDisplayModeEnum, UnitTypeEnum } from '../../Model/Enum';
import {
  GetSalaryRecordsOverview_Result,
  IDepartment,
  IGetSalaryRecordsOverview_Result,
  ISalaryCycle,
  ISalaryRecord,
  ISalaryRecordsOverviewFilterRequest,
  ISalaryType,
  ISimpleSalaryRecordUpdateRequest,
  IUnitType,
  IUserEmploymentTemplate
} from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';

interface IPayrollSalaryType extends ISalaryType {
  displayName?: string;
  unitTypeTitle?: string;
  IsVisibleValue?: boolean;
  IsActiveValue?: boolean;
}

enum PayrollFilterType {
  SalaryCycle = 1,
  Template = 2,
  Department = 3
}

@Component({
  selector: 'app-company-payroll-data',
  templateUrl: './CompanyPayrollDataNGX.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyPayrollDataNGXComponent implements OnInit, OnDestroy, AfterViewInit {
  public gridData: IGetSalaryRecordsOverview_Result[] = [];
  public salaryTypes: IPayrollSalaryType[] = [];
  public availableSalaryTypes: ISalaryType[] = [];
  public showDepartment: boolean;
  public locked: boolean;
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public groups: GroupDescriptor[] = [{ field: 'DepartmentName' }];
  public selectedRows: number[] = [];
  public filterCategory: number;
  public salaryCycleId: number;
  public templateId: number;
  public departmentId: number;
  public showEmployeeWithNoData: boolean;
  public filterDataSource: any[] = [];
  public salaryCycles: any[] = [];
  public templates: any[] = [];
  public departments: any[] = [];
  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public numericOptions: any = {
    format: 'n2',
    decimals: 2,
    step: 0,
    min: 0,
    spinners: false
  };

  public get hasLanguageModule(): boolean {
    return this.sessionService.feature.hasModuleId(5);
  }

  @ViewChild(GridComponent, { static: true }) private grid: GridComponent;
  @ViewChild('kendoGridComponent', { static: true }) public kendoGrid: GridComponent;
  private formGroup: FormGroup;
  private editedRowIndex: number;
  private original: IGetSalaryRecordsOverview_Result = new GetSalaryRecordsOverview_Result();
  private columnIndex: number;
  private elementdown1: any;
  private elementdown2: any;

  //public mySelection: number[] = [this.sessionService.currentUserEmployeeId];

  constructor(
    private dataService: RxDataService,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private staticDataService: StaticDataService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.translateFilterDataSource();
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateFilterDataSource();
    });

    if (this.isMobile) {
      this.sessionService.isShowHugeFeaturesAlert = true;
    }

    const departmentPromise: any = this.staticDataService.departments
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((departments: IDepartment[]) => {
        this.departments = ListHelper.mapDataSource(this.sessionService, departments, 'Id', 'Name');
        // this.departmentId = this.departments[0] ? this.departments[0].Id : undefined;
      });

    this.dataService
      .EmploymentTemplates_GetEmploymentTemplates(false)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((templates: IUserEmploymentTemplate[]) => {
        this.templates = templates.sort((a: IUserEmploymentTemplate, b: IUserEmploymentTemplate): number => {
          if (a.Name > b.Name) {
            return 1;
          }
          if (a.Name < b.Name) {
            return -1;
          }
          return 0;
        });

        // this.templateId = this.templates[0] ? this.templates[0].Id : undefined;
      });

    this.dataService
      .Miscellaneous_GetUsedSalaryCycles()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: ISalaryCycle[]) => {
        this.salaryCycles = data;
        // this.salaryCycleId = data[0] ? data[0].Id : undefined;

        this.getSalaryTypes();
      });
  }

  private translateFilterDataSource(): void {
    this.filterDataSource = [];
    this.translateService
      .get(['CompanyPayrollData.Salary Cycle', 'CompanyPayrollData.Template', 'CompanyPayrollData.Department'])
      .subscribe((translations: { [key: string]: string }) => {
        const dataSource: any[] = [];
        dataSource.push({ Id: PayrollFilterType.SalaryCycle, Name: translations['CompanyPayrollData.Salary Cycle'] });
        dataSource.push({ Id: PayrollFilterType.Template, Name: translations['CompanyPayrollData.Template'] });
        dataSource.push({ Id: PayrollFilterType.Department, Name: translations['CompanyPayrollData.Department'] });
        this.filterDataSource = dataSource;
      });
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public trackIndex(index: number): any {
    return index;
  }

  public UnitsColumnVisible(salaryType: ISalaryType): boolean {
    return (
      salaryType &&
      (!salaryType.OverviewDisplayMode ||
        salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsAndAmount ||
        salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsOnly)
    );
  }

  public AmountColumnVisible(salaryType: ISalaryType): boolean {
    return (
      salaryType &&
      (!salaryType.OverviewDisplayMode ||
        salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsAndAmount ||
        salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.AmountOnly)
    );
  }

  public PerUnitColumnVisible(salaryType: ISalaryType): boolean {
    return (
      salaryType &&
      (!salaryType.OverviewDisplayMode || salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsAndAmount)
    );
  }

  public onFilterControlChanged(value: number): void {
    if (this.salaryTypes && this.salaryTypes.length > 0) {
      this.getData();
    } else {
      this.getSalaryTypes();
    }
  }

  private selectedCell: CellClickEvent;
  public onKeyDown(event: any): void {
    if (
      (event.key === 'Tab' || event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Escape') &&
      this.selectedCell
    ) {
      const cell: any = this.findNextCell(this.selectedCell, event);
      if (
        cell &&
        (!this.gridView || !this.gridView.data || (cell.rowIndex >= 0 && cell.rowIndex < this.gridView.data.length))
      ) {
        const dataItem: any = this.gridView && this.gridView.data ? this.gridView.data[cell.rowIndex] : undefined;
        Object.assign(this.original, dataItem);
        this.selectedRows = dataItem ? [dataItem.UserEmploymentId] : [];
        if (event.key === 'Escape') {
          setTimeout(() => {
            this.kendoGrid.editCell(
              this.selectedCell.rowIndex,
              this.selectedCell.columnIndex,
              this.createFormGroup(dataItem)
            );
            this.kendoGrid.closeCell();
          });
        } else {
          this.kendoGrid.editCell(cell.rowIndex, cell.columnIndex, this.createFormGroup(dataItem));
          this.kendoGrid.focusCell(cell.rowIndex + 2, cell.columnIndex);

          setTimeout(() => {
            const element = document.querySelector('.k-state-focused input') as HTMLElement;
            if (element) {
              element.focus();
            }
          });
        }
        this.formGroup = this.createFormGroup(dataItem);
      }

      event.preventDefault();
      event.stopPropagation();
    }
  }

  public onSelectionChange(event: any): void {}

  private listColumnCheck: any[] = [];
  private findNextCell(cell: any, event: any): any {
    if (!this.listColumnCheck || this.listColumnCheck.length === 0) {
      this.listColumnCheck = [];
      (this.kendoGrid.columnList as any).columns.forEach((column: any) => {
        if (!column.children) {
          this.listColumnCheck.push(column);
        } else {
          column.children.forEach((childColumn: any) => {
            if (column !== childColumn) {
              this.listColumnCheck.push(childColumn);
            }
          });
        }
      });
    }

    if (event.key === 'ArrowUp') {
      if (cell.rowIndex > 0) {
        cell.rowIndex = cell.rowIndex - 1;
      }
    } else if (event.key === 'ArrowDown') {
      if (cell.rowIndex < this.gridView.data.length - 1) {
        cell.rowIndex = cell.rowIndex + 1;
      }
    } else {
      if (!event.shiftKey) {
        if (cell.columnIndex >= 2 && cell.columnIndex < this.listColumnCheck.length - 2) {
          for (let i: number = cell.columnIndex + 1; i < this.listColumnCheck.length - 1; i++) {
            if (this.listColumnCheck[i] && this.listColumnCheck[i].editable) {
              cell.columnIndex = i;
              break;
            }
          }
        } else {
          cell.columnIndex = 2;
        }
      } else {
        if (cell.columnIndex > 2 && cell.columnIndex < this.listColumnCheck.length - 1) {
          for (let i: number = cell.columnIndex - 1; i >= 2; i--) {
            if (this.listColumnCheck[i] && this.listColumnCheck[i].editable) {
              cell.columnIndex = i;
              break;
            }
          }
        } else {
          cell.columnIndex = this.listColumnCheck.length - 2;
        }
      }
    }

    return cell;
  }

  public onCellClick(e: CellClickEvent): void {
    this.selectedCell = e;
    this.editedRowIndex = e.rowIndex;
    this.selectedRows = e.dataItem ? [e.dataItem.UserEmploymentId] : [];
    if (!this.sessionService.role.IsReadOnly) {
      Object.assign(this.original, e.dataItem);
      this.formGroup = this.createFormGroup(e.dataItem);
      this.editedRowIndex = e.rowIndex;
      e.sender.editCell(e.rowIndex, e.columnIndex, this.createFormGroup(e.dataItem));
    }
    setTimeout(() => {
      const element = document.querySelector('.k-state-focused input') as HTMLElement;
      if (element) {
        element.focus();
      }
    });
  }

  public onCellClose(e: CellCloseEvent): void {
    if (this.isColumnDataChanged(e)) {
      this.saveCurrent(e.dataItem, e.column ? e.column.field : undefined);
    }
    const obj: any = this.addRowTotal();
    this.rowTotal = this.CalculateTotal(obj);
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadView();
  }

  public selectedSalaryTypeForConfiguration: ISalaryType;
  public showSalaryTypeConfiguration: boolean;
  public configurationSalaryTypeDataSource: ISalaryType[];
  public onSalaryTypeConfigrationClicked(salaryType?: ISalaryType): void {
    const dataSource: ISalaryType[] = this.availableSalaryTypes
      ? JSON.parse(JSON.stringify(this.availableSalaryTypes))
      : [];
    if (salaryType) {
      const indexSalaryType: ISalaryType[] = dataSource.filter((model: ISalaryType) => model.Id === salaryType.Id);
      if (indexSalaryType && indexSalaryType.length > 0) {
        dataSource.splice(dataSource.indexOf(indexSalaryType[0]), 1);
      }
      dataSource.unshift(salaryType);
    }

    this.configurationSalaryTypeDataSource = dataSource;
    this.selectedSalaryTypeForConfiguration = salaryType;
    this.showSalaryTypeConfiguration = true;
  }

  public rowTotal: any;
  private loadView(): void {
    setTimeout(() => {
      try {
        this.elementdown1 = document
          .getElementById('companyPayrollDataGrid')
          .getElementsByClassName('k-grid-aria-root')[0]
          .getElementsByClassName('k-grid-container')[0]
          .getElementsByClassName('k-grid-content')[0];
        this.elementdown2 = document
          .getElementById('companyPayrollDataGrid')
          .getElementsByClassName('k-grid-aria-root')[0]
          .getElementsByClassName('k-grid-container')[0]
          .getElementsByClassName('k-grid-content-locked')[0];
      } catch (e) {}
      if (this.elementdown2) {
        this.elementdown2.addEventListener('scroll', (): void => {
          setTimeout(() => {
            this.elementdown1.scrollTop = this.elementdown2.scrollTop;
          }, 100);
        });
      }
    });

    this.gridFilterData = filterBy(this.gridData, this.filter);
    this.gridView = {
      data: this.gridFilterData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridFilterData.length
    };
    const obj: any = this.addRowTotal();
    this.rowTotal = this.CalculateTotal(obj);
  }

  private CalculateTotal(result: any): any {
    this.gridFilterData.forEach((item: any) => {
      result.SalaryRecord1Amount = item.SalaryRecord1Amount
        ? item.SalaryRecord1Amount + result.SalaryRecord1Amount
        : result.SalaryRecord1Amount;
      result.SalaryRecord1Units = item.SalaryRecord1Units
        ? item.SalaryRecord1Units + result.SalaryRecord1Units
        : result.SalaryRecord1Units;
      result.SalaryRecord2Amount = item.SalaryRecord2Amount
        ? item.SalaryRecord2Amount + result.SalaryRecord2Amount
        : result.SalaryRecord2Amount;
      result.SalaryRecord2Units = item.SalaryRecord2Units
        ? item.SalaryRecord2Units + result.SalaryRecord2Units
        : result.SalaryRecord2Units;
      result.SalaryRecord3Amount = item.SalaryRecord3Amount
        ? item.SalaryRecord3Amount + result.SalaryRecord3Amount
        : result.SalaryRecord3Amount;
      result.SalaryRecord3Units = item.SalaryRecord3Units
        ? item.SalaryRecord3Units + result.SalaryRecord3Units
        : result.SalaryRecord3Units;
      result.SalaryRecord4Amount = item.SalaryRecord4Amount
        ? item.SalaryRecord4Amount + result.SalaryRecord4Amount
        : result.SalaryRecord4Amount;
      result.SalaryRecord4Units = item.SalaryRecord4Units
        ? item.SalaryRecord4Units + result.SalaryRecord4Units
        : result.SalaryRecord4Units;
      result.SalaryRecord5Amount = item.SalaryRecord5Amount
        ? item.SalaryRecord5Amount + result.SalaryRecord5Amount
        : result.SalaryRecord5Amount;
      result.SalaryRecord5Units = item.SalaryRecord5Units
        ? item.SalaryRecord5Units + result.SalaryRecord5Units
        : result.SalaryRecord5Units;
      result.SalaryRecord6Amount = item.SalaryRecord6Amount
        ? item.SalaryRecord6Amount + result.SalaryRecord6Amount
        : result.SalaryRecord6Amount;
      result.SalaryRecord6Units = item.SalaryRecord6Units
        ? item.SalaryRecord6Units + result.SalaryRecord6Units
        : result.SalaryRecord6Units;
      result.SalaryRecord7Amount = item.SalaryRecord7Amount
        ? item.SalaryRecord7Amount + result.SalaryRecord7Amount
        : result.SalaryRecord7Amount;
      result.SalaryRecord7Units = item.SalaryRecord7Units
        ? item.SalaryRecord7Units + result.SalaryRecord7Units
        : result.SalaryRecord7Units;
    });

    result.SalaryRecord1Amount = parseFloat((Math.round(result.SalaryRecord1Amount * 100) / 100).toFixed(2));
    result.SalaryRecord1Units = parseFloat((Math.round(result.SalaryRecord1Units * 100) / 100).toFixed(2));
    result.SalaryRecord2Amount = parseFloat((Math.round(result.SalaryRecord2Amount * 100) / 100).toFixed(2));
    result.SalaryRecord2Units = parseFloat((Math.round(result.SalaryRecord2Units * 100) / 100).toFixed(2));
    result.SalaryRecord3Amount = parseFloat((Math.round(result.SalaryRecord3Amount * 100) / 100).toFixed(2));
    result.SalaryRecord3Units = parseFloat((Math.round(result.SalaryRecord3Units * 100) / 100).toFixed(2));
    result.SalaryRecord4Amount = parseFloat((Math.round(result.SalaryRecord4Amount * 100) / 100).toFixed(2));
    result.SalaryRecord4Units = parseFloat((Math.round(result.SalaryRecord4Units * 100) / 100).toFixed(2));
    result.SalaryRecord5Amount = parseFloat((Math.round(result.SalaryRecord5Amount * 100) / 100).toFixed(2));
    result.SalaryRecord5Units = parseFloat((Math.round(result.SalaryRecord5Units * 100) / 100).toFixed(2));
    result.SalaryRecord6Amount = parseFloat((Math.round(result.SalaryRecord6Amount * 100) / 100).toFixed(2));
    result.SalaryRecord6Units = parseFloat((Math.round(result.SalaryRecord6Units * 100) / 100).toFixed(2));
    result.SalaryRecord7Amount = parseFloat((Math.round(result.SalaryRecord7Amount * 100) / 100).toFixed(2));
    result.SalaryRecord7Units = parseFloat((Math.round(result.SalaryRecord7Units * 100) / 100).toFixed(2));

    return result;
  }

  private addRowTotal(): any {
    const obj: any = {
      DepartmentName: 'Total',
      SalaryRecord1Amount: 0,
      SalaryRecord1Id: undefined,
      SalaryRecord1PerUnit: 0,
      SalaryRecord1Units: 0,
      SalaryRecord2Amount: 0,
      SalaryRecord2Id: undefined,
      SalaryRecord2PerUnit: 0,
      SalaryRecord2Units: 0,
      SalaryRecord3Amount: 0,
      SalaryRecord3Id: undefined,
      SalaryRecord3PerUnit: 0,
      SalaryRecord3Units: 0,
      SalaryRecord4Amount: 0,
      SalaryRecord4Id: undefined,
      SalaryRecord4PerUnit: 0,
      SalaryRecord4Units: 0,
      SalaryRecord5Amount: 0,
      SalaryRecord5Id: undefined,
      SalaryRecord5PerUnit: 0,
      SalaryRecord5Units: 0,
      SalaryRecord6Amount: 0,
      SalaryRecord6Id: undefined,
      SalaryRecord6PerUnit: 0,
      SalaryRecord6Units: 0,
      SalaryRecord7Amount: 0,
      SalaryRecord7Id: undefined,
      SalaryRecord7PerUnit: 0,
      SalaryRecord7Units: 0
    };
    return obj;
  }

  private isColumnDataChanged(args: any): boolean {
    let dataChanged: boolean;
    const { column, dataItem, formGroup } = args;
    if (dataItem && formGroup && formGroup.value && column && column.field) {
      dataChanged =
        ((dataItem[column.field] === null || dataItem[column.field] === undefined) &&
          (formGroup.value[column.field] !== null && formGroup.value[column.field] !== undefined)) ||
        (dataItem[column.field] !== null &&
          dataItem[column.field] !== undefined &&
          formGroup.value.hasOwnProperty(column.field) &&
          (formGroup.value[column.field] === null || formGroup.value[column.field] === undefined)) ||
        (dataItem[column.field] !== null &&
          dataItem[column.field] !== undefined &&
          (formGroup.value[column.field] !== null && formGroup.value[column.field] !== undefined) &&
          dataItem[column.field] !== formGroup.value[column.field]);
    }

    return dataChanged;
  }

  private getData(): void {
    // const filterObject: ISalaryRecordsOverviewFilterRequest = {
    //     SalaryCycleId: this.filterCategory === PayrollFilterType.SalaryCycle ? this.salaryCycleId : undefined,
    //     EmploymentTemplateId: this.filterCategory === PayrollFilterType.Template ? this.templateId : undefined,
    //     DepartmentId: this.filterCategory === PayrollFilterType.Department ? this.departmentId : undefined,
    //     IncludeEmployeesWithNoData: this.showEmployeeWithNoData
    // };

    const filterObject: ISalaryRecordsOverviewFilterRequest = {
      SalaryCycleId: this.salaryCycleId ? this.salaryCycleId : undefined,
      EmploymentTemplateId: this.templateId ? this.templateId : undefined,
      DepartmentId: this.departmentId ? this.departmentId : undefined,
      IncludeEmployeesWithNoData: this.showEmployeeWithNoData
    };

    this.dataService
      .SalaryRecords_GetSalaryRecordsOverviewFiltered(filterObject)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: IGetSalaryRecordsOverview_Result[]) => {
        this.gridData = data;
        this.pageChange({ skip: 0, take: this.pageSize });
        this.showDepartment = this.gridData.some((d: IGetSalaryRecordsOverview_Result) => !!d.DepartmentName);
        this.locked = this.salaryTypes.length > 0;
        this.changeDetectorRef.markForCheck();
      });
  }

  public hasAddNewColumn: boolean;
  public overviewMaxIndex: number;
  public getSalaryTypes(): void {
    this.dataService.SalaryTypes_GetRawSalaryTypesTranslated().subscribe((salaryTypes: ISalaryType[]): void => {
      salaryTypes.forEach((salaryType: ISalaryType) => {
        this.assignSalaryTypeText(salaryType as IPayrollSalaryType);
      });
      // this.availableSalaryTypes = salaryTypes.filter((s: IPayrollSalaryType) => s.IsVisibleValue && s.IsActiveValue && !s.OverviewIndex && s.OverviewIndex !== 0);
      this.availableSalaryTypes = (salaryTypes as IPayrollSalaryType[]).filter(
        (s: IPayrollSalaryType) => s.IsVisibleValue && s.IsActiveValue
      );

      this.salaryTypes = salaryTypes.filter((s: ISalaryType) => s.OverviewIndex || s.OverviewIndex === 0);
      this.salaryTypes = this.salaryTypes.sort((a: ISalaryType, b: ISalaryType) => {
        return GridHelper.sortByNumberValue(a.OverviewIndex, b.OverviewIndex);
      });
      this.salaryTypes = this.salaryTypes.slice(0, 7);

      this.overviewMaxIndex = 1;
      this.salaryTypes.forEach((salaryType: IPayrollSalaryType) => {
        let unitType: IUnitType;
        if (Global.UNIT_TYPES) {
          unitType = Global.UNIT_TYPES.find((ut: IUnitType) => ut.Id === salaryType.UnitTypeId);
        }

        if (salaryType.OverviewIndex >= this.overviewMaxIndex) {
          this.overviewMaxIndex = salaryType.OverviewIndex + 1;
        }

        salaryType.unitTypeTitle = unitType ? unitType.Name : '';
        this.assignSalaryTypeText(salaryType);
      });

      this.hasAddNewColumn = !this.salaryTypes || this.salaryTypes.length < 7;
      this.getData();
    });
  }

  private assignSalaryTypeText(salaryType: IPayrollSalaryType): void {
    const existingSalaryType: ISalaryType = this.salaryTypes.find((s: ISalaryType) => s.Id === salaryType.Id);
    salaryType.SalaryTypeTranslations = salaryType.SalaryTypeTranslations
      ? salaryType.SalaryTypeTranslations
      : existingSalaryType
      ? existingSalaryType.SalaryTypeTranslations
      : undefined;
    salaryType.BaseSalaryType = salaryType.BaseSalaryType
      ? salaryType.BaseSalaryType
      : existingSalaryType
      ? existingSalaryType.BaseSalaryType
      : undefined;

    salaryType.IsActiveValue =
      salaryType.IsActive !== null
        ? salaryType.IsActive
        : salaryType.BaseSalaryType
        ? salaryType.BaseSalaryType.IsActive
        : false;
    salaryType.IsVisibleValue =
      salaryType.IsVisible !== null
        ? salaryType.IsVisible
        : salaryType.BaseSalaryType
        ? salaryType.BaseSalaryType.IsVisible
        : false;

    if (!salaryType.SalaryTypeTranslations) {
      return;
    }

    const languageId: number = Global.SESSION.SignOnToken.LanguageId;
    if (Global.SESSION.SignOnToken.LanguageId === 1) {
      salaryType.Name =
        salaryType.SalaryTypeTranslations[0].Name && this.hasLanguageModule
          ? salaryType.SalaryTypeTranslations[0].Name
          : salaryType.Name;
    } else if (Global.SESSION.SignOnToken.LanguageId === 2) {
      salaryType.Name =
        salaryType.SalaryTypeTranslations[1].Name && this.hasLanguageModule
          ? salaryType.SalaryTypeTranslations[1].Name
          : salaryType.Name;
    } else if (Global.SESSION.SignOnToken.LanguageId === 3) {
      salaryType.Name =
        salaryType.SalaryTypeTranslations[2].Name && this.hasLanguageModule
          ? salaryType.SalaryTypeTranslations[2].Name
          : salaryType.Name;
    }

    salaryType.displayName =
      salaryType.Name !== null
        ? salaryType.Name
        : salaryType.BaseSalaryType
        ? salaryType.BaseSalaryType.Name
        : undefined;
  }

  private saveCurrent(dataItem: any, property: string): void {
    if (!dataItem || !property) {
      return;
    }

    let index: any = property
      .replace('SalaryRecord', '')
      .replace('PerUnit', '')
      .replace('Units', '')
      .replace('Amount', '');
    if (index === undefined) {
      return;
    }

    index = parseInt(index, 10);
    const formGroupValue: number = this.formGroup.value[property];
    const updatedValue: number = dataItem[property];

    if (formGroupValue !== updatedValue) {
      const salaryRecordId: number = dataItem[`SalaryRecord${index}Id`];
      const calculator: SalaryRecordCalculation = this.createSalaryRecordCalculation({
        Units: dataItem[`SalaryRecord${index}Units`],
        AmountPerUnit: dataItem[`SalaryRecord${index}PerUnit`],
        Amount: dataItem[`SalaryRecord${index}Amount`]
      });
      const request: any = this.createSalaryRecordUpdateRequest(calculator, property, updatedValue);
      dataItem[`SalaryRecord${index}Units`] = request.Units;
      dataItem[`SalaryRecord${index}PerUnit`] = request.AmountPerUnit;
      dataItem[`SalaryRecord${index}Amount`] = request.Amount;

      if (salaryRecordId) {
        request.Id = salaryRecordId;
        this.dataService.SalaryRecords_UpdateSalaryRecordSimple(request).subscribe(
          () => {},
          () => {
            this.getSalaryTypes();
          }
        );
      } else {
        request.UserEmploymentId = this.original.UserEmploymentId;
        request.SalaryTypeId = this.salaryTypes[index - 1].Id;
        this.dataService.SalaryRecords_CreateSalaryRecordSimple(request).subscribe(
          (data: ISalaryRecord) => {
            dataItem[`SalaryRecord${index}Id`] = data.Id;
          },
          () => {
            this.getSalaryTypes();
          }
        );
      }
    }
  }

  private createSalaryRecordCalculation(dataItem: any): SalaryRecordCalculation {
    const calculator: SalaryRecordCalculation = new SalaryRecordCalculation();
    calculator.Amount = dataItem.Amount;
    calculator.Units = dataItem.Units;
    calculator.AmountPerUnit = dataItem.AmountPerUnit;
    calculator.UnitType = UnitTypeEnum.Days;
    return calculator;
  }

  private createSalaryRecordUpdateRequest(
    calculator: SalaryRecordCalculation,
    property: string,
    value: number
  ): ISimpleSalaryRecordUpdateRequest {
    value = value && value !== 0 ? value : undefined;
    const isAmountPerUnitProperty: boolean = property.indexOf('PerUnit') > -1;
    const isUnitsProperty: boolean = !isAmountPerUnitProperty && property.indexOf('Units') > -1;
    const isAmountProperty: boolean = !isAmountPerUnitProperty && property.indexOf('Amount') > -1;

    let calculationResult: any;
    if (isAmountPerUnitProperty) {
      calculationResult = this.updateAmountPerUnit(calculator, value);
    }

    if (isUnitsProperty) {
      calculationResult = this.updateUnits(calculator, value);
    }

    if (isAmountProperty) {
      calculationResult = this.updateAmount(calculator, value);
    }

    const updateSalaryRecord: ISimpleSalaryRecordUpdateRequest = {
      Id: 0,
      AmountPerUnit: calculationResult.amountPerUnit,
      Amount: calculationResult.amount,
      Units: calculationResult.units
    };

    return updateSalaryRecord;
  }

  private updateAmountPerUnit(calculator: SalaryRecordCalculation, value: number): any {
    calculator.AmountPerUnit = value;
    calculator.calculateAmount();
    calculator.calculateUnits();
    return {
      amountPerUnit: value,
      amount: calculator.Amount,
      units: calculator.Units
    };
  }

  private updateAmount(calculator: SalaryRecordCalculation, value: number): any {
    calculator.Amount = value;
    calculator.calculateUnits();
    return {
      amountPerUnit: calculator.AmountPerUnit,
      amount: calculator.Amount,
      units: calculator.Units
    };
  }

  private updateUnits(calculator: SalaryRecordCalculation, value: number): any {
    calculator.Units = value;
    calculator.calculateAmount();
    return {
      amountPerUnit: calculator.AmountPerUnit,
      amount: calculator.Amount,
      units: calculator.Units
    };
  }

  private createFormGroup(dataItem: IGetSalaryRecordsOverview_Result): FormGroup {
    return new FormGroup({
      FullName: new FormControl(dataItem ? dataItem.FullName : undefined),
      DepartmentName: new FormControl(dataItem ? dataItem.DepartmentName : undefined),
      MultipleRecordsExist1: new FormControl(dataItem ? dataItem.MultipleRecordsExist1 : undefined),
      SalaryRecord1Units: new FormControl(dataItem ? dataItem.SalaryRecord1Units : undefined),
      SalaryRecord1PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord1PerUnit : undefined),
      SalaryRecord1Amount: new FormControl(dataItem ? dataItem.SalaryRecord1Amount : undefined),
      MultipleRecordsExist2: new FormControl(dataItem ? dataItem.MultipleRecordsExist2 : undefined),
      SalaryRecord2Units: new FormControl(dataItem ? dataItem.SalaryRecord2Units : undefined),
      SalaryRecord2PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord2PerUnit : undefined),
      SalaryRecord2Amount: new FormControl(dataItem ? dataItem.SalaryRecord2Amount : undefined),
      MultipleRecordsExist3: new FormControl(dataItem ? dataItem.MultipleRecordsExist3 : undefined),
      SalaryRecord3Units: new FormControl(dataItem ? dataItem.SalaryRecord3Units : undefined),
      SalaryRecord3PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord3PerUnit : undefined),
      SalaryRecord3Amount: new FormControl(dataItem ? dataItem.SalaryRecord3Amount : undefined),
      MultipleRecordsExist4: new FormControl(dataItem ? dataItem.MultipleRecordsExist4 : undefined),
      SalaryRecord4Units: new FormControl(dataItem ? dataItem.SalaryRecord4Units : undefined),
      SalaryRecord4PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord4PerUnit : undefined),
      SalaryRecord4Amount: new FormControl(dataItem ? dataItem.SalaryRecord4Amount : undefined),
      MultipleRecordsExist5: new FormControl(dataItem ? dataItem.MultipleRecordsExist5 : undefined),
      SalaryRecord5Units: new FormControl(dataItem ? dataItem.SalaryRecord5Units : undefined),
      SalaryRecord5PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord5PerUnit : undefined),
      SalaryRecord5Amount: new FormControl(dataItem ? dataItem.SalaryRecord5Amount : undefined),
      MultipleRecordsExist6: new FormControl(dataItem ? dataItem.MultipleRecordsExist6 : undefined),
      SalaryRecord6Units: new FormControl(dataItem ? dataItem.SalaryRecord6Units : undefined),
      SalaryRecord6PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord6PerUnit : undefined),
      SalaryRecord6Amount: new FormControl(dataItem ? dataItem.SalaryRecord6Amount : undefined),
      MultipleRecordsExist7: new FormControl(dataItem ? dataItem.MultipleRecordsExist7 : undefined),
      SalaryRecord7Units: new FormControl(dataItem ? dataItem.SalaryRecord7Units : undefined),
      SalaryRecord7PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord7PerUnit : undefined),
      SalaryRecord7Amount: new FormControl(dataItem ? dataItem.SalaryRecord7Amount : undefined)
    });
  }

  public filter: any;
  public gridFilterData: any[] = filterBy(this.gridData, this.filter);
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridFilterData = filterBy(this.gridData, this.filter);
    this.gridView = {
      data: this.gridFilterData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridFilterData.length
    };
    const obj: any = this.addRowTotal();
    this.rowTotal = this.CalculateTotal(obj);
  }

  public ngAfterViewInit(): void {}
}
