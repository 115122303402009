import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { NumericTextBoxOptions } from '../../CustomControls/NumericTextBoxOptions';
import { AdditionalVacationModel } from './AdditionalVacationModel';

@Component({
  selector: 'additional-vacation',
  templateUrl: './AdditionalVacation.component.html'
})
export class AdditionalVacationComponent extends FormComponentBase {
  @Input() public disable: boolean;

  private currentmodel: any;
  @Input()
  public get model(): AdditionalVacationModel {
    if (!this.currentmodel) {
      this.currentmodel = {};
    }

    return this.currentmodel;
  }
  public set model(value: AdditionalVacationModel) {
    this.currentmodel = value;
    if (this.modelChange) {
      this.modelChange.emit(this.currentmodel);
    }
  }
  @Output() modelChange: EventEmitter<AdditionalVacationModel> = new EventEmitter<AdditionalVacationModel>();

  public numbericTextBoxFFSeniorityRequirementMonthsOptions: NumericTextBoxOptions = {
    min: -120000,
    max: 120000,
    step: 1,
    decimals: 0,
    spinners: false,
    format: '0'
  };

  constructor() {
    super();
    this.childIsValid = Array(3).fill(true);
    this.childIsDirty = Array(3).fill(false);
  }
  public get IsEmptyFFHoursEarnedPerYear(): boolean {
    return !this.model || !this.model.FFHoursEarnedPerYear || this.model.FFHoursEarnedPerYear === 0;
  }

  public NumbericTextBoxFFHoursEarnedPerYearOptions: NumericTextBoxOptions = {
    format: 'n2',
    decimals: 2,
    step: 1,
    spinners: false
  };
}
