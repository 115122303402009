import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { Global } from '../../../Common/Global';
import {
  ICompanyUser,
  IIntegration,
  ISalaryBatchApprovalRequest,
  ISalaryStatement,
  IUserEmployment
} from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'revert-payslip',
  templateUrl: './RevertPayslip.component.html'
})
export class RevertPayslipComponent implements OnInit, OnDestroy {
  @Input() public salaryStatement: ISalaryStatement;
  @Input() public employee: ICompanyUser;

  private currentEmployeeId: number;
  @Input()
  public get employeeId(): number {
    return this.currentEmployeeId;
  }

  public set employeeId(value: number) {
    if (value && this.employeeId !== value) {
      this.currentEmployeeId = value;
      this.reloadEmployee();
    }
  }

  public reloadEmployee(): void {
    this.dataService.Employments_GetEmployment(this.employeeId).subscribe((model: IUserEmployment) => {
      this.employee = model.CompanyUser;
    });
  }

  private isVisibleValue = false;
  @Input()
  public get isVisible(): boolean {
    return this.isVisibleValue;
  }
  public set isVisible(value: boolean) {
    if (this.isVisibleValue !== value) {
      this.isVisibleValue = value;
      this.isVisibleChange.emit(value);
    }
  }
  @Output() public isVisibleChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() public revertCompleted: EventEmitter<any> = new EventEmitter<any>();

  public isVisibleUnderStood = false;
  public resetPasswordModel: string;

  public get noEmail(): boolean {
    return !this.employee || (!this.employee.CompanyEmail && !this.employee.PersonalEmail);
  }

  public get isExternalSystemRelevant(): boolean {
    // GS-3436
    if (this.integrationName) {
      return true;
    }
    return false;
  }

  public get ExternalSystemRevertMessage(): string {
    // GS-3436
    return this.isExternalSystemRelevant && this.revertBatchExtSystemMessage
      ? this.revertBatchExtSystemMessage.replace('{externalSystem.Name}', this.integrationName)
      : '';
  }

  public get isGreenland(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  private revertBatchExtSystemMessage: string;
  public IntergrationOpation: any[];
  private ExportNewPayrollBatchId: number;
  public Integration2Operation: any[];
  public integrationName: string;
  public userName: string;

  constructor(
    private translate: TranslateService,
    private dataService: RxDataService,
    private sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {}

  public ngOnInit(): void {
    this.userName = localStorage.getItem('saveLoginUserName');
    this.translateText();
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) =>
      this.translateText()
    );
    this.staticDataService.ExternalSystemOperation.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any[]) => {
      this.IntergrationOpation = data;
      const objectfillter: any = this.IntergrationOpation.find((model: any) => model.Key === 'ExportNewPayrollBatch');
      if (objectfillter) {
        this.ExportNewPayrollBatchId = objectfillter.Id;
        if (this.ExportNewPayrollBatchId) {
          // this.staticDataService.GetIntegration2Operation(
          //   this.ExportNewPayrollBatchId,
          //   this.Integration2Operation,
          //   this.integrationName
          // );
          this.GetIntegration2Operation();
        }
      }
    });
  }

  // Using static dataService observer to prevent Integrations_GetIntegrationsByOperation run twice
  private GetIntegration2Operation(): void {
    this.dataService
      .Integrations_GetIntegrationsByOperation(this.ExportNewPayrollBatchId)
      .subscribe((model: IIntegration[]) => {
        this.Integration2Operation = model;
        if (this.Integration2Operation && this.Integration2Operation.length > 0) {
          this.integrationName = this.Integration2Operation[0].Name;
        }
      });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public revertModalAction(action: string): void {
    if (action === 'RevertBatch') {
      this.isVisibleUnderStood = true;
    }
  }

  public revertModalUnderStoodAction(action: string): void {
    if (action === 'Proceed') {
      const request: ISalaryBatchApprovalRequest = { Password: this.resetPasswordModel, DoRecalc: false };
      this.dataService.SalaryStatements_RevertSalaryStatement(this.salaryStatement.Id, request).subscribe(
        (): void => {
          this.revertCompleted.emit();
          this.onCancel();
        },
        (e: any) => {
          console.error(e);
        }
      );
    } else {
      this.onCancel();
    }
  }

  private translateText(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translate
        .get(['RevertPayslip.RevertConfirmationResult7'])
        .subscribe((translations: { [key: string]: string }) => {
          this.revertBatchExtSystemMessage = translations['RevertPayslip.RevertConfirmationResult7'];
        });
    });
  }

  private onCancel(): void {
    this.employeeId = undefined;
    this.employee = undefined;
    this.isVisible = false;
    this.isVisibleUnderStood = false;
  }
}
