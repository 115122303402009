<div>
  <kendo-dropdownlist
    [(data)]="data"
    [defaultItem]="defaultItem"
    [value]="selectedValue"
    [valuePrimitive]="true"
    [textField]="textField"
    [valueField]="valueField"
    (valueChange)="onChange($event)"
  >
  </kendo-dropdownlist>
</div>
