import * as i0 from "@angular/core";
var NotTranslatedService = /** @class */ (function () {
    function NotTranslatedService() {
    }
    NotTranslatedService.prototype.notTranslated = function (key) {
        // console.log('Not translated', key);
    };
    NotTranslatedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotTranslatedService_Factory() { return new NotTranslatedService(); }, token: NotTranslatedService, providedIn: "root" });
    return NotTranslatedService;
}());
export { NotTranslatedService };
