import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import {
  IDimension,
  IExternalSystem,
  IExternalSystemConfiguration,
  IExternalSystemCredential,
  IExternalSystemOperation,
  IIntegration,
  IIntegrationConfiguration,
  IIntegrationCredential,
  IIntegrationOperation,
  IIntegrationStatus,
  ISimpleKeyValuePair
} from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { CompanyStandardSalaryTypesComponent } from '../SimpleSalaryTypes/CompanyStandardSalaryTypes.component';

@Component({
  selector: 'integration-tab',
  templateUrl: './Integrations.component.html'
})
export class IntegrationsComponent extends FormComponentBase implements OnInit, OnDestroy {
  public integrationModel: IIntegration[];
  public currentExtenalSystemId: number;
  public descriptionCreateIntegration: string;
  public dialogVisable = false;
  public extenalSystem: IExternalSystem[];
  private isDirtyValues = false;
  @Input()
  public get isDirty(): boolean {
    return this.isDirtyValues;
  }
  public set isDirty(value: boolean) {
    if (this.isDirtyValues !== value) {
      this.isDirtyValues = value;
      this.isDirtyChange.emit(value);
    }
  }

  public hasIntegrationModule = false;
  public configurationControl: any[];
  public credentialControl: any[];
  public integrationConfigurationControl: any[];
  public isShowingAlertMessage = false;
  public isShowingDeleteMessage = false;
  public isReadonlyUser = false;
  public isShowingChangeIemMessage = false;
  public currentIntegrationId: number;
  public OperationRunSucess = false;

  public get isComboboxIntegrationVisable(): boolean {
    return this.integrationModel && this.integrationModel.length > 0;
  }

  @ViewChild(CompanyStandardSalaryTypesComponent, { static: true })
  private salaryTypes: CompanyStandardSalaryTypesComponent;

  private currentIntegrationModel: IIntegration;
  private externalSystemOperation: IExternalSystemOperation[];
  private externalSystemCredential: IExternalSystemCredential[];
  private externalSystemConfiguration: IExternalSystemConfiguration[];
  private statusCode: IIntegrationStatus[];
  private currentExtenalSystem: IExternalSystem;
  private globalExtenalSystem: IExternalSystem;
  private nameCreate = '';
  private nextIntegrationIdChange?: number;
  private ApiDataList: any[] = [];

  constructor(
    private dataService: RxDataService,
    private staticDataService: StaticDataService,
    public sessionService: SessionService,
    public settingService: SettingService
  ) {
    super();
    this.isReadonlyUser = this.sessionService.role.IsReadOnly;
  }

  public ngOnInit(): void {
    this.loadIntegrationMode();
    this.settingService.showModuleMessage('company.integrations');
    this.staticDataService.ExternalSystem.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (externalSystems: IExternalSystem[]) => {
        this.extenalSystem =
          externalSystems && externalSystems.length
            ? externalSystems.filter((model: IExternalSystem) => model.IsActive === true)
            : undefined;
        if (this.extenalSystem && this.extenalSystem.length > 0) {
          this.currentExtenalSystemId = this.extenalSystem[0].Id;
        }

        if (this.extenalSystem) {
          this.extenalSystem = this.extenalSystem.filter((model: IExternalSystem) => model.Id !== -1);
          if (this.extenalSystem && this.extenalSystem.length > 0) {
            this.currentExtenalSystemId = this.extenalSystem[0].Id;
            this.globalExtenalSystem = this.extenalSystem[0];
          }
        }

        this.onBaseIntegrationCreateComboboxChanged();
      }
    );

    this.staticDataService.IntegrationStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IIntegrationStatus[]) => (this.statusCode = data)
    );
    this.staticDataService.ExternalSystemOperation.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IExternalSystemOperation[]) => (this.externalSystemOperation = data)
    );
    this.staticDataService.ExternalSystemCredential.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IExternalSystemCredential[]) => (this.externalSystemCredential = data)
    );
    this.staticDataService.ExternalSystemConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IExternalSystemConfiguration[]) => (this.externalSystemConfiguration = data)
    );

    this.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (!value) {
        this.saveValueControl();
      }
    });
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDoubleClick(): void {
    if (this.hasIntegrationModule) {
      if (!this.isReadonlyUser && this.isComboboxIntegrationVisable) {
        this.editMode = true;
      }
    }
  }

  public applyDiscard(): void {
    if (this.isDirty) {
      this.isShowingAlertMessage = true;
    } else {
      this.loadIntegrationMode();
      this.isDirty = false;
      this.editMode = false;
      this.editModeChange.emit(this.editMode);
    }
  }

  public isShowingAutoMap = false;
  private operationId: number;
  public runOperation(operationId: number): void {
    if (operationId === 16) {
      this.operationId = operationId;
      this.isShowingAutoMap = true;
      return;
    }
    this.dataService.Integrations_RunIntegrationsOperation(this.currentIntegrationId, operationId).subscribe(() => {
      this.OperationRunSucess = true;
      this.loadIntegrationMode();
    });
  }

  public onAutoMap(event: string): void {
    if (event && event === 'Proceed') {
      this.dataService
        .Integrations_RunIntegrationsOperation(this.currentIntegrationId, this.operationId)
        .subscribe(() => this.loadIntegrationMode());
    }
  }

  public onBaseIntegrationCreateComboboxChanged(): void {
    if (this.extenalSystem && this.currentExtenalSystemId) {
      this.globalExtenalSystem = this.extenalSystem.find(
        (exs: IExternalSystem) => exs.Id === this.currentExtenalSystemId
      );
    }

    if (this.globalExtenalSystem && this.globalExtenalSystem.Description) {
      this.descriptionCreateIntegration = this.globalExtenalSystem.Description;
      return;
    }

    this.descriptionCreateIntegration = 'No description available';
  }

  public onCreateDialogCreate(action: string): void {
    if (action === 'Create' && this.globalExtenalSystem) {
      this.dataService.Integrations_CreateIntegration(this.globalExtenalSystem.Id).subscribe((data: IIntegration) => {
        this.staticDataService.loadExternalSystemConfiguration();
        this.staticDataService.loadExternalSystemCredential();
        this.nameCreate = this.globalExtenalSystem.Name;
        this.editMode = true;
        this.onCloseDialogCreate();
      });
    }
  }

  public onDeleteDialog(): void {
    this.isShowingDeleteMessage = true;
  }

  public SelectIntegrationListView(integrationId: number): void {
    if (this.editMode && this.isDirty) {
      this.nextIntegrationIdChange = integrationId;
      this.isShowingChangeIemMessage = true;
      return;
    }

    this.isDirty = false;
    this.editMode = false;
    this.editModeChange.emit(this.editMode);
    this.currentIntegrationId = integrationId;
    this.loadIntegrationById(integrationId);
  }

  public getStatusMessageColor(integrationstatusmessageId: number): string {
    if (integrationstatusmessageId && this.statusCode && this.statusCode.length > 0) {
      return this.statusCode.find((model: IIntegrationStatus) => model.Id === integrationstatusmessageId).Key;
    }

    return '';
  }

  public getStatusMessageColorOperation(statuscode: number): string {
    if (statuscode && this.statusCode && this.statusCode.length > 0) {
      return this.statusCode.find((model: IIntegrationStatus) => model.Id === statuscode).Key;
    }

    return '';
  }

  public getStatusMessage(integrationstatusmessageId: number): string {
    if (integrationstatusmessageId && this.statusCode && this.statusCode.length > 0) {
      return this.statusCode.find((model: IIntegrationStatus) => model.Id === integrationstatusmessageId).Description;
    }

    return '';
  }

  public isFistIntegration(model: IIntegration): boolean {
    if (this.integrationModel && model === this.integrationModel[0]) {
      return true;
    }

    return false;
  }

  public checkIntegrationSupportUrl(model: IIntegration): boolean {
    if (this.extenalSystem) {
      const tempexternalsystem: IExternalSystem = this.extenalSystem.find(
        (z: IExternalSystem) => z.Id === model.ExternalSystemId
      );
      if (tempexternalsystem && tempexternalsystem.SupportUrl) {
        return true;
      }
    }

    return false;
  }

  public applyUrl(model: IIntegration): void {
    if (this.extenalSystem) {
      const tempexternalsystem: IExternalSystem = this.extenalSystem.find(
        (z: IExternalSystem) => z.Id === model.ExternalSystemId
      );
      if (tempexternalsystem && tempexternalsystem.SupportUrl) {
        if (tempexternalsystem.SupportUrl.includes('http') === false) {
          tempexternalsystem.SupportUrl = 'http://' + tempexternalsystem.SupportUrl;
        }

        window.open(tempexternalsystem.SupportUrl, '_blank');
      }
    }
  }

  public irDirtyChange(changeis: boolean): void {
    this.isDirty = changeis;
  }

  public onUnSaveWarningDialogAction(action: string): void {
    if (action === 'CancelDiscard') {
      this.isDirty = true;
      this.editMode = true;
      this.editModeChange.emit(this.editMode);
      this.nextIntegrationIdChange = undefined;
    } else {
      this.loadIntegrationMode();
      this.isDirty = false;
      this.editMode = false;
      this.editModeChange.emit(this.editMode);

      if (this.nextIntegrationIdChange) {
        this.currentIntegrationId = this.nextIntegrationIdChange;
        this.loadIntegrationById(this.nextIntegrationIdChange);
        this.nextIntegrationIdChange = undefined;
      }
    }
  }

  public onChangeTab(event: string): void {
    if (event) {
      if (event === 'ContinueAndSave') {
        this.editMode = false;
        this.editModeChange.emit(this.editMode);
        this.isDirty = false;
        return;
      } else {
        this.isDirty = false;
        this.editMode = false;
        this.editModeChange.emit(this.editMode);
        this.isShowingChangeIemMessage = false;
      }

      if (this.nextIntegrationIdChange) {
        this.currentIntegrationId = this.nextIntegrationIdChange;
        this.loadIntegrationById(this.nextIntegrationIdChange);
        this.nextIntegrationIdChange = undefined;
      }
    }
  }

  public onDeleteWarningDialogAction(action: string): void {
    if (action === 'Delete') {
      if (this.currentIntegrationModel && this.currentIntegrationModel.Id !== -1) {
        this.dataService.Integrations_DeleteIntegration(this.currentIntegrationModel.Id).subscribe((): void => {
          this.currentIntegrationId = undefined;
          this.currentIntegrationModel = undefined;
          this.credentialControl = [];
          this.configurationControl = [];
          this.integrationConfigurationControl = [];
          this.onCloseDialogCreate();
        });
      }
    } else {
      this.onCloseDialogCreate();
    }
  }

  private listControlConfiguration(): void {
    this.configurationControl = [];
    this.credentialControl = [];
    this.integrationConfigurationControl = [];

    if (this.currentExtenalSystem && this.currentIntegrationModel) {
      if (
        (this.currentExtenalSystem.Operations && this.currentExtenalSystem.Operations.length > 0) ||
        (this.currentIntegrationModel.IntegrationOperations &&
          this.currentIntegrationModel.IntegrationOperations.length > 0)
      ) {
        for (let i = 0; i < this.currentExtenalSystem.Operations.length; i++) {
          const control: any = this.createControlOperation(this.currentExtenalSystem.Operations[i]);
          if (control) {
            const filterIntegrationOperations: any = this.currentIntegrationModel.IntegrationOperations.filter(
              (z: IIntegrationOperation) => z.OperationId === control.Id
            );
            if (filterIntegrationOperations && filterIntegrationOperations[0]) {
              control.value = filterIntegrationOperations[0].IsActive;
              control.translatemessage = filterIntegrationOperations[0].TranslatedStatusMessage;
              control.statuscode = filterIntegrationOperations[0].StatusCode;
              control.Description = filterIntegrationOperations[0].Description
                ? filterIntegrationOperations[0].Description
                : '';
            }

            control.integrationID = this.currentExtenalSystem.Id;
            this.configurationControl.push(control);
          }
        }
      }

      if (
        (this.currentExtenalSystem.CredentialKeys && this.currentExtenalSystem.CredentialKeys.length > 0) ||
        (this.currentIntegrationModel.IntegrationCredentials &&
          this.currentIntegrationModel.IntegrationCredentials.length > 0)
      ) {
        for (let i = 0; i < this.currentExtenalSystem.CredentialKeys.length; i++) {
          const control: any = this.createControlCredential(this.currentExtenalSystem.CredentialKeys[i]);
          if (control) {
            const filterIntegrationCredentials: IIntegrationCredential[] = this.currentIntegrationModel.IntegrationCredentials.filter(
              (z: IIntegrationCredential) => z.Key === control.key
            );
            if (filterIntegrationCredentials && filterIntegrationCredentials[0]) {
              control.value = filterIntegrationCredentials[0].Value;
              control.Description = filterIntegrationCredentials[0].ExternalSystemCredential.Description;
            }
            this.credentialControl.push(control);
          }
        }
      }

      if (
        (this.currentExtenalSystem.ConfigurationKeys && this.currentExtenalSystem.ConfigurationKeys.length > 0) ||
        (this.currentIntegrationModel.IntegrationConfigurations &&
          this.currentIntegrationModel.IntegrationConfigurations.length > 0)
      ) {
        for (let i = 0; i < this.currentExtenalSystem.ConfigurationKeys.length; i++) {
          const control: any = this.createControlIntegrationConfiguration(
            this.currentExtenalSystem.ConfigurationKeys[i]
          );
          if (control) {
            const filterIntegrationConfigurations: IIntegrationConfiguration[] = this.currentIntegrationModel.IntegrationConfigurations.filter(
              (z: IIntegrationConfiguration) => z.Key === control.key
            );
            if (filterIntegrationConfigurations && filterIntegrationConfigurations[0]) {
              control.value = filterIntegrationConfigurations[0].Value;
            }

            this.integrationConfigurationControl.push(control);
            if (control.type === 'Dropdown') {
              this.loadComboboxDataSource(control);
            }
          }
        }
      }
    }
  }

  private loadComboboxDataSource(control: any): void {
    const listDataSourceParts: any = this.getPartsFromListDataSourceStringOfCombobox(control.listDataSource);
    control.idField = listDataSourceParts.underlyingId;
    control.textField = listDataSourceParts.displayText;

    const dataModel: any =
      this.ApiDataList && this.ApiDataList.length > 0
        ? this.ApiDataList.find((model: any) => model.api === listDataSourceParts.api)
        : null;
    if (dataModel) {
      this.assignControlDataSource(control, listDataSourceParts, dataModel.data);
    } else {
      this.dataService.httpGet(environment.apiUrl + '/' + listDataSourceParts.api).subscribe((data: any[]) => {
        if (data && data.length > 0) {
          data.forEach((model: any) => {
            model[control.idField] = control.idField && model[control.idField] ? model[control.idField].toString() : '';
          });
        }
        this.assignControlDataSource(control, listDataSourceParts, data);
        const nextModel: any = {};
        nextModel.api = listDataSourceParts.api;
        nextModel.data = data;
        this.ApiDataList.push(nextModel);
      });
    }
  }

  private assignControlDataSource(control: any, listDataSourceParts: any, data: any): void {
    const sameApiComboboxes: any[] = this.integrationConfigurationControl.filter((ctrl: any) => {
      if (ctrl.listDataSource) {
        const ctrlListDataSourceParts: any = this.getPartsFromListDataSourceStringOfCombobox(ctrl.listDataSource);
        return ctrlListDataSourceParts.api === listDataSourceParts.api;
      } else {
        return false;
      }
    });

    if (!sameApiComboboxes || sameApiComboboxes.length === 0) {
      return;
    }

    control.dataSource = data;
    control.dataSourceEmpty = data === undefined || data === null || data.length < 1;

    if (sameApiComboboxes && sameApiComboboxes.length > 0) {
      sameApiComboboxes.forEach((apiCombo: any) => {
        if (!apiCombo.dataSource || apiCombo.dataSource.length === 0) {
          apiCombo.dataSource = data;
          apiCombo.dataSourceEmpty = data === undefined || data === null || data.length < 1;
        }
      });
    }
  }

  private getPartsFromListDataSourceStringOfCombobox(stringListDataSource: string): any {
    stringListDataSource = stringListDataSource.replace(':', ';');
    const parts: string[] = stringListDataSource.split(';');
    const externalSystemName: string = this.currentExtenalSystem
      ? this.currentExtenalSystem.Name
        ? this.currentExtenalSystem.Name
        : this.currentExtenalSystem.Key
      : '';

    if (parts[0].indexOf('{externalSystemName}') >= 0) {
      parts[0] = parts[0].replace('{externalSystemName}', externalSystemName);
    }

    if (parts[0].indexOf('{IntegrationId}') >= 0) {
      parts[0] = parts[0].replace('{IntegrationId}', this.currentIntegrationId.toString());
    }

    return {
      api: parts[0],
      underlyingId: parts[1],
      displayText: parts[2]
    };
  }

  private createControlOperation(extenalsystemOperations: IExternalSystemOperation): any {
    if (extenalsystemOperations) {
      const extenal: IExternalSystemOperation = this.externalSystemOperation.find(
        (model: IExternalSystemOperation) => model.Key === extenalsystemOperations.Key
      );
      return {
        Id: extenalsystemOperations.Id,
        name: extenalsystemOperations.Name,
        key: extenalsystemOperations.Key,
        IsSingular: extenalsystemOperations.IsSingular,
        Description: extenal && extenal.Description ? extenal.Description : ''
      };
    }

    return undefined;
  }

  private createControlCredential(name: string): any {
    if (name) {
      const finResult: IExternalSystemCredential = this.externalSystemCredential.find(
        (model: IExternalSystemCredential) => model.Key === name
      );
      if (finResult) {
        return {
          name: finResult.Name ? finResult.Name : '',
          key: name
        };
      }
    }

    return undefined;
  }

  private createControlIntegrationConfiguration(name: string): any {
    if (name) {
      const configuration: IExternalSystemConfiguration = this.externalSystemConfiguration.find(
        (model: IExternalSystemConfiguration) => model.Key === name
      );
      return {
        name: configuration && configuration.Name ? configuration.Name : '',
        key: name,
        type: configuration && configuration.FormElement ? configuration.FormElement : '',
        listDataSource: configuration && configuration.ListDataSource ? configuration.ListDataSource : undefined,
        Description: configuration && configuration.Description ? configuration.Description : ''
      };
    }

    return undefined;
  }

  private saveValueControl(): void {
    if (this.isDirty) {
      this.saveValueControlParam(
        this.configurationControl,
        this.credentialControl,
        this.integrationConfigurationControl
      );
    }
  }

  private sortIntegrationLoadByDateCreate(model: IIntegration, model2: IIntegration): number {
    return model.Created < model2.Created ? 1 : 0;
  }

  private loadIntegrationMode(): void {
    // this.salaryTypes.loadData();
    this.dataService.Integrations_RetrieveIntegrations().subscribe((integrationLoad: IIntegration[]) => {
      this.integrationModel = integrationLoad;

      if (this.integrationModel.length < 1) {
        this.editMode = false;
        this.editModeChange.emit(this.editMode);
      }

      this.integrationModel = this.integrationModel.sort((a: IIntegration, b: IIntegration) =>
        this.sortIntegrationLoadByDateCreate(a, b)
      );

      if (this.nameCreate && this.integrationModel.find((z: IIntegration) => z.Name === this.nameCreate)) {
        let integrationfilter: IIntegration[] = [];
        integrationfilter = this.integrationModel.filter((z: IIntegration) => z.Name === this.nameCreate);
        if (integrationfilter.length > 1) {
          this.currentIntegrationId = integrationfilter[integrationfilter.length - 1].Id;
        } else if (integrationfilter.length === 1) {
          this.currentIntegrationId = integrationfilter[0].Id;
        }

        this.nameCreate = '';
        this.loadIntegrationById(this.currentIntegrationId);
      } else if (this.currentIntegrationId) {
        this.loadIntegrationById(this.currentIntegrationId);
      } else if (this.integrationModel && this.integrationModel.length > 0) {
        this.loadIntegrationById(this.integrationModel[0].Id);
      }
    });
  }

  private loadIntegrationById(value: number): void {
    this.dataService.Integrations_RetrieveIntegrationWithAllChildren(value).subscribe((data: IIntegration) => {
      this.currentIntegrationModel = data;
      this.currentIntegrationId = this.currentIntegrationModel.Id;
      if (this.extenalSystem && this.currentIntegrationModel) {
        this.currentExtenalSystem = this.extenalSystem.find(
          (z: IExternalSystem) => z.Id === this.currentIntegrationModel.ExternalSystemId
        );
      }

      if (this.currentExtenalSystem) {
        this.listControlConfiguration();
      } else {
        this.configurationControl = [];
        this.credentialControl = [];
        this.integrationConfigurationControl = [];
      }
    });
  }

  private onCloseDialogCreate(): void {
    if (this.extenalSystem && this.extenalSystem.length > 0) {
      this.currentExtenalSystemId = this.extenalSystem[0].Id;
      this.globalExtenalSystem = this.extenalSystem[0];
      this.descriptionCreateIntegration = this.globalExtenalSystem.Description;
    } else {
      this.descriptionCreateIntegration = '';
    }
    this.loadIntegrationMode();
    this.dialogVisable = false;
  }

  private saveValueControlParam(arraycontrol: any[], arraycontrol2: any[], arraycontrol3: any[]): void {
    const iSimpleKeyValuePairArray: ISimpleKeyValuePair[] = [];
    if (arraycontrol) {
      arraycontrol.forEach((control: any) => {
        const simpleKeyValuePairtemp: ISimpleKeyValuePair = {};
        simpleKeyValuePairtemp.Key = 'Operation.' + control.Id;
        simpleKeyValuePairtemp.Value = control.value ? control.value.toString() : 'false';
        iSimpleKeyValuePairArray.push(simpleKeyValuePairtemp);
      });
    }

    if (arraycontrol2) {
      arraycontrol2.forEach((control: any) => {
        const simpleKeyValuePairtemp: ISimpleKeyValuePair = {};
        simpleKeyValuePairtemp.Key = 'Credential.' + control.key;
        simpleKeyValuePairtemp.Value = control.value ? control.value : '';
        iSimpleKeyValuePairArray.push(simpleKeyValuePairtemp);
      });
    }

    if (arraycontrol3) {
      arraycontrol3.forEach((control: any) => {
        const simpleKeyValuePairtemp: ISimpleKeyValuePair = {};
        simpleKeyValuePairtemp.Key = 'Configuration.' + control.key;
        simpleKeyValuePairtemp.Value = control.value ? control.value : '';
        iSimpleKeyValuePairArray.push(simpleKeyValuePairtemp);
      });
    }

    this.dataService
      .Integrations_SetIntegrationValuesByKey(this.currentIntegrationId, iSimpleKeyValuePairArray)
      .subscribe(
        (): void => {
          this.loadIntegrationMode();
          this.ResetFrom();
        },
        () => {
          this.loadIntegrationMode();
          this.ResetFrom();
        }
      );
  }

  private ResetFrom(): void {
    this.isDirty = false;
  }
}
