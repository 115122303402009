export class PreferenceViewModel {
  Id: number;
  CategoryId: number;
  Key: string;
  Name: string;
  Description: string;
  HelpUrl: string;
  RequiredModuleId: number;
  MinimumRoleId: number;
  DataType: string;
  Value: any;
  IsVisible: boolean;
  SortOrder: number;
  UnderlyingId: string;
  DisplayText: string;
  DataSource: any;
  listDataSource: any;
}
