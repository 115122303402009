import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
var AccountantServiceBase = /** @class */ (function () {
    function AccountantServiceBase(api) {
        var _this = this;
        this.api = api;
        this.currentPageValue = 0;
        this.rolesSubject = new BehaviorSubject([]);
        this.filterSubject = new BehaviorSubject('');
        this.loadData();
        this.filterSubject
            .asObservable()
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(function () { return _this.applyFilter(); });
    }
    Object.defineProperty(AccountantServiceBase.prototype, "editMode", {
        get: function () {
            return this.editModeValue;
        },
        set: function (value) {
            this.editModeValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantServiceBase.prototype, "roles", {
        get: function () {
            return this.rolesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantServiceBase.prototype, "filter", {
        get: function () {
            return this.filterValue;
        },
        set: function (value) {
            this.filterValue = value;
            this.filterSubject.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantServiceBase.prototype, "currentPage", {
        get: function () {
            return this.currentPageValue;
        },
        set: function (value) {
            this.currentPageValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantServiceBase.prototype, "selectedId", {
        get: function () {
            return this.selectedIdValue;
        },
        set: function (value) {
            if (this.selectedIdValue !== value) {
                this.selectedIdValue = value;
                this.getRoles();
            }
        },
        enumerable: true,
        configurable: true
    });
    AccountantServiceBase.prototype.save = function (event) {
        var _this = this;
        if (event && event.dataItem) {
            if (!event.dataItem.RoleId) {
                event.dataItem.RoleId = 0;
            }
            this.api
                .Account_AssignUserAccessToCompany(event.dataItem.CompanyId, event.dataItem.UserId, event.dataItem.RoleId, event.dataItem.IsPaymentApprover)
                .subscribe(function () {
                _this.getRoles();
            }, function () {
                _this.getRoles();
            });
        }
    };
    AccountantServiceBase.prototype.discard = function () {
        this.getRoles();
    };
    return AccountantServiceBase;
}());
export { AccountantServiceBase };
