import { Component, Input, OnInit } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { Global } from '../../../Common/Global';
import {
  Department,
  ICompanyUser,
  IDepartment,
  ISalaryCycle,
  ISimpleKeyValuePair,
  IUserEmploymentTemplate
} from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'company-templates',
  templateUrl: './CompanyTemplates.component.html'
})
export class CompanyTemplatesComponent extends FormComponentBase implements OnInit {
  private selectedTemplateIdValue: number;
  public get selectedTemplateId(): number {
    return this.selectedTemplateIdValue;
  }
  public set selectedTemplateId(value: number) {
    if (value && this.selectedTemplateIdValue !== value) {
      this.selectedTemplateIdValue = value;
      this.getEmploymentTemplate();
    }
  }
  private templateDetailValue: IUserEmploymentTemplate;
  public get templateDetail(): IUserEmploymentTemplate {
    if (this.templateDetailValue === undefined) {
      return {} as any;
    }

    return this.templateDetailValue;
  }
  public set templateDetail(value: IUserEmploymentTemplate) {
    this.templateDetailValue = value;
  }

  private currentTriggerEditmodeChange = false;
  @Input()
  public get TriigerEditMode(): boolean {
    return this.currentTriggerEditmodeChange;
  }
  public set TriigerEditMode(value: boolean) {
    if (this.currentTriggerEditmodeChange !== value) {
      this.currentTriggerEditmodeChange = value;
    }
    if (!value && this.isDirty) {
      this.onSave();
    }
  }

  public salaryCycles: ISalaryCycle[];
  public templateHasEmployees: boolean;
  public departments: IDepartment[];
  public companyContacts: ICompanyUser[];
  public templates: IUserEmploymentTemplate[];
  public ModelDepartment: IDepartment = new Department();
  public isEnable = false;

  public hasTemplateModule = false;
  public hasDepartmentModule = false;
  public addNewDialogVisible = false;
  public isPayrollGridVisible = false;
  public targetEventName = '';
  public isSeniorityGridVisible = false;

  public isOvertimeGridVisible = false;
  public deleteConfirmationDialogVisible = false;
  public discardChangesDialogVisible = false;
  public confirmUpdateDialogVisible = false;
  public updateTemplateWarningDialogVisible = false;
  public showInvalidFormWarning = false;
  public showSaveDataConfirmationChangeState = false;
  public AddDepartmentVisible = false;
  private navigationToStateName: string;

  public childIsDirty: boolean[];
  public childIsValid: boolean[];

  constructor(
    private dataService: RxDataService,
    private settingService: SettingService,
    public sessionService: SessionService,
    private staticDataService: StaticDataService,
    private transitionService: TransitionService
  ) {
    super();
    this.childIsValid = Array(14).fill(true);
    this.childIsDirty = Array(17).fill(false);
  }

  public get IsDisableCreateDepartment(): boolean {
    return this.sessionService.role.IsReadOnly || this.sessionService.role.IsSalaryAdmin;
  }

  public ngOnInit(): void {
    this.staticDataService.SalaryCycle.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalaryCycle[]) => (this.salaryCycles = data)
    );
    this.staticDataService.departments
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: IDepartment[]) => (this.departments = data));
    this.getTemplates();
    this.settingService.showModuleMessage('Company.Templates');
    this.getCompanyContacts();
  }

  private getCompanyContacts(): void {
    this.staticDataService.companyUsers
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((companyUsers: ICompanyUser[]): void => {
        this.companyContacts = companyUsers;
      });
  }

  public get has1stPensionProvider(): boolean {
    return this.templateDetail && this.templateDetail.PensionProviderId ? true : false;
  }
  public get has2ndPensionProvider(): boolean {
    return this.templateDetail && this.templateDetail.Pension2ProviderId ? true : false;
  }
  public get isAddIconVisible(): boolean {
    return this.sessionService.role.IsSalaryAdminAndHigher;
  }
  public get isVacationDisable(): boolean {
    return this.SecondaryIncomeType;
  }
  public get isAdditionalVacationDaysDisable(): boolean {
    return this.SecondaryIncomeType;
  }
  public get isMultiPurposeOffDayCompensationDisable(): boolean {
    return this.SecondaryIncomeType;
  }
  public get SecondaryIncomeType(): boolean {
    return this.templateDetail && this.templateDetail.IncomeTypeId === 5;
  }
  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }
  public get showStatisticsSection(): boolean {
    return Global.COMPANY && Global.COMPANY.StatisticsRecipientId && Global.COMPANY.StatisticsRecipientId !== -1;
  }

  public get showMultiPurposeOffDayCompensation(): boolean {
    const enableFlexBalanceSetting: ISimpleKeyValuePair = Global.COMPANY_PREFERENCES
      ? Global.COMPANY_PREFERENCES.filter((pref: ISimpleKeyValuePair) => pref.Key === 'Company.EnableFlexBalance')[0]
      : undefined;
    if (enableFlexBalanceSetting) {
      return enableFlexBalanceSetting.Value === 'true' ? true : false;
    }

    return false;
  }

  public onConfirmSaveDataOnChangeState(action: string): void {
    if (action === 'Continue') {
      this.onSave();
    } else if (action === 'DiscardAndLeave') {
      this.onDiscardChangesDialogAction('ConfirmDiscard');
      if (this.navigationToStateName) {
        setTimeout(() => {
          this.sessionService.NavigateTo(this.navigationToStateName);
        });
      }

      this.navigationToStateName = undefined;
    }
  }

  public onEditClick(): void {
    if (this.editMode) {
      this.onSave();
    } else {
      this.editMode = true;
    }
  }

  public onDbClick(): void {
    if (this.sessionService.role.IsFullAdmin || this.sessionService.role.IsSalaryAdmin) {
      this.editMode = true;
    }
  }

  public onSave(): void {
    const dirty: boolean = this.childIsDirty.find((val: any): boolean => val === true);
    this.isDirty = dirty ? dirty : this.isDirty;
    if (this.isDirty && this.templateDetail) {
      if (this.isValid) {
        if (this.templateHasEmployees) {
          this.confirmUpdateDialogVisible = true;
        } else {
          this.updateTemplateOnly();
        }
      } else {
        this.editMode = true;
      }
    } else {
      this.editMode = false;
    }
  }

  public onAddNewCompanyTemplateCompleted(template: IUserEmploymentTemplate): void {
    this.getTemplates(template.Id);
  }

  public onDeleteEmploymentTemplate(): void {
    if (this.selectedTemplateId) {
      this.deleteConfirmationDialogVisible = true;
    }
  }

  public onDeleteConfirmationDialogAction(action: string): void {
    if (action === 'Delete') {
      this.dataService
        .EmploymentTemplates_DeleteEmploymentTemplate(this.selectedTemplateId)
        .subscribe(() => this.getTemplates());
    }
  }

  public onConfirmUpdateDialogAction(action: string): void {
    if (action === 'UpdateAllEmployees') {
      this.updateTemplateWarningDialogVisible = true;
    }

    if (action === 'UpdateTemplateOnly') {
      this.updateTemplateOnly();
    }
  }

  public onUpdateTemplateWarningAction(action: string): void {
    if (action === 'Proceed') {
      const templateDetail: any = this.templateDetail;
      delete templateDetail['GridId'];
      this.dataService
        .EmploymentTemplates_UpdateEmploymentTemplateAndApplyChanges(templateDetail)
        .subscribe((template: IUserEmploymentTemplate): void => {
          this.editMode = false;
          this.templateDetail = template;
          this.updateTemplateListTemplateName();
          this.resetDirtyForm();
          if (this.sessionService.nextState) {
            setTimeout(() => {
              this.sessionService.NavigateTo(this.sessionService.nextState);
            });
          }
        });
    }
  }

  public onConfirmAndDiscardChanges(): void {
    const dirty: boolean = this.childIsDirty.find((val: any): boolean => val === true);
    this.isDirty = dirty ? dirty : this.isDirty;
    if (!this.isDirty) {
      this.childIsDirty.fill(false);
      this.editMode = false;
      return;
    }

    this.discardChangesDialogVisible = true;
  }

  public onDiscardChangesDialogAction(action: string): void {
    if (action === 'ConfirmDiscard') {
      this.resetDirtyForm();
      this.editMode = false;
      this.getEmploymentTemplate();
    }
  }

  private getTemplates(selectTemplateId?: number): void {
    this.dataService
      .EmploymentTemplates_GetEmploymentTemplates(false)
      .subscribe((templates: IUserEmploymentTemplate[]): void => {
        this.templates = [];
        this.templates = templates.sort((a: IUserEmploymentTemplate, b: IUserEmploymentTemplate): number => {
          if (a.Name > b.Name) {
            return 1;
          }
          if (a.Name < b.Name) {
            return -1;
          }
          return 0;
        });

        if (this.templates.length > 0) {
          this.isEnable = true;
        } else {
          this.isEnable = false;
        }

        this.selectedTemplateId =
          selectTemplateId || (this.templates && this.templates.length > 0 ? this.templates[0].Id : undefined);
        if (selectTemplateId) {
          this.editMode = true;
        }
      });
  }

  private getEmploymentTemplate(): void {
    if (this.selectedTemplateId) {
      this.dataService
        .EmploymentTemplates_GetEmploymentTemplate(this.selectedTemplateId)
        .subscribe((templateDetail: IUserEmploymentTemplate): void => {
          this.templateDetail = templateDetail;
          this.updateTemplateListTemplateName();
          this.resetDirtyForm();
        });
    }
  }

  private updateTemplateOnly(): void {
    const template: any = this.templateDetail;
    delete template['GridId'];
    template.VacationSupplementPayoutMonth =
      template.VacationSupplementPayoutMonth === undefined ? null : template.VacationSupplementPayoutMonth;
    template.StatisticsSalaryPrincipleId =
      template.StatisticsSalaryPrincipleId === undefined ? null : template.StatisticsSalaryPrincipleId;
    template.StatisticsEmploymentPrincipleId =
      template.StatisticsEmploymentPrincipleId === undefined ? null : template.StatisticsEmploymentPrincipleId;
    template.StatisticsEmploymentTermsId =
      template.StatisticsEmploymentTermsId === undefined ? null : template.StatisticsEmploymentTermsId;
    template.StatisticsJobStatusId =
      template.StatisticsJobStatusId === undefined ? null : template.StatisticsJobStatusId;
    template.DepartmentId = template.DepartmentId === undefined ? null : template.DepartmentId;

    this.dataService
      .EmploymentTemplates_UpdateEmploymentTemplate(template)
      .subscribe((templateDetail: IUserEmploymentTemplate) => {
        this.editMode = false;
        this.templateDetail = templateDetail;
        this.updateTemplateListTemplateName();
        this.resetDirtyForm();
        if (this.sessionService.nextState) {
          setTimeout(() => {
            this.sessionService.NavigateTo(this.sessionService.nextState);
          });
        }
      });
  }

  public refreshTemplateGridList = false;
  private updateTemplateListTemplateName(): void {
    if (this.templateDetail && this.templates) {
      const templateInList: IUserEmploymentTemplate = this.templates.find((template: IUserEmploymentTemplate) => {
        return template.Id === this.templateDetail.Id;
      });

      if (templateInList && templateInList.Name !== this.templateDetail.Name) {
        templateInList.Name = this.templateDetail.Name;
        this.refreshTemplateGridList = !this.refreshTemplateGridList;
      }
    }
  }

  private resetDirtyForm(): void {
    this.childIsDirty.fill(false);
    this.isDirty = false;
  }

  public createUserCompanyTemplate(): void {
    this.addNewDialogVisible = true;
  }

  public onCreateNewDepartment(event: any) {
    if (event && event === 'Create') {
      this.ModelDepartment.IsActive = true;
      this.dataService.Companies_CreateDepartment(this.ModelDepartment).subscribe((data: any): void => {
        this.staticDataService.loadDepartments();
        this.onCreateNewDepartmentSuccess(data.Id);
      });
    }
    this.ModelDepartment = new Department();
  }

  public onCreateNewDepartmentSuccess(newDepartmentId: number): void {
    this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IDepartment[]) => {
      this.departments = data;
      this.templateDetail.DepartmentId = newDepartmentId;
    });
  }
}
