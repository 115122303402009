import { EventEmitter, QueryList } from '@angular/core';
import { MobileModalContentComponent } from './MobileModalContent.component';
var MobileModalComponent = /** @class */ (function () {
    function MobileModalComponent() {
        this.close = new EventEmitter();
        this.showChange = new EventEmitter();
        this.currentCustomClass = '';
        this.transform = false;
    }
    Object.defineProperty(MobileModalComponent.prototype, "show", {
        get: function () {
            return this.showDialog;
        },
        set: function (value) {
            var _this = this;
            this.showDialog = value;
            this.showChange.emit(value);
            setTimeout(function () {
                _this.transform = true;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileModalComponent.prototype, "customClass", {
        get: function () {
            return this.currentCustomClass;
        },
        set: function (value) {
            if (value) {
                this.currentCustomClass = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    MobileModalComponent.prototype.raiseError = function () {
        throw new Error('Test Angular 6 error');
    };
    MobileModalComponent.prototype.closeModal = function () {
        this.show = false;
        this.transform = false;
        this.close.emit();
    };
    return MobileModalComponent;
}());
export { MobileModalComponent };
