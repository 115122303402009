<!-- New salary batch dialog -->
<app-dialog
  *ngIf="popup"
  (action)="onSalaryBatchDialogAction($event)"
  [width]="'650'"
  [overlayClose]="false"
  [(visible)]="visible"
>
  <div class="Modal-header">
    <div class="DialogHeaderForExistingSalarybatch" *ngIf="salaryBatchContext?.BatchId > 0">
      <strong>
        {{ 'CompanySalaryBatches.Salary batch no' | translate }} {{ salaryBatchContext?.SalaryBatchNumber }}
      </strong>
      <span class="right">
        {{ salaryBatchContext?.StatusName }}
        ({{ salaryBatchContext?.ChangeDate | DateFormat: undefined:undefined:true }})
      </span>
    </div>
    <div class="DialogHeaderForNewSalaryBatch" *ngIf="salaryBatchContext?.BatchId < 1">
      {{ 'CompanySalaryBatches.Create new Salary Batch' | translate }}
    </div>
  </div>

  <salary-batch-details
    [isDialog]="true"
    [salaryBatch]="salaryBatch"
    [(selectedEmployeeIds)]="selectedEmployeeIds"
    [salaryCycles]="salaryCycles"
    [salaryStatements]="salaryStatements"
    [visible]="visible"
    (entityContextChange)="onDetailContextChange($event)"
    (reloadSalaryBatches)="reloadSalaryBatches.emit()"
    (closeDialog)="closeDialog.emit()"
  ></salary-batch-details>

  <app-dialog-action [type]="'Close'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
  <app-dialog-action
    *ngIf="!IsReadOnly && salaryBatchContext?.BatchId < 1"
    [type]="'Create'"
    [disabled]="sessionService.isSubmitting"
  ></app-dialog-action>
  <app-dialog-action
    *ngIf="!IsReadOnly && !(!salaryBatchContext?.IsDraft || salaryBatchContext?.BatchId < 0)"
    [type]="'Delete'"
    [disabled]="sessionService.isSubmitting"
  >
  </app-dialog-action>
  <app-dialog-action
    [requiredModuleId]="3"
    *ngIf="
      ((IsReadOnly && isPaymentApprover) || sessionService.role.IsSalaryAdminAndHigher) &&
      salaryBatchContext?.BatchId > 0 &&
      salaryBatchContext?.IsDraft
    "
    [type]="'SubmitForApproval'"
    [disabled]="sessionService.isSubmitting"
  >
  </app-dialog-action>
  <app-dialog-action
    [requiredModuleId]="3"
    *ngIf="!IsReadOnly && salaryBatchContext?.BatchId > 0 && salaryBatchContext?.IsPendingForApproval"
    [type]="'Reject'"
    [disabled]="sessionService.isSubmitting"
  >
  </app-dialog-action>
  <app-dialog-action
    *ngIf="
      salaryBatchContext?.BatchId > 0 &&
      (salaryBatchContext?.IsDraft || salaryBatchContext?.IsPendingForApproval || salaryBatchContext?.IsPreliminary)
    "
    [type]="'Finalize'"
    [tooltip]="tooltipFinalizeContent | translate"
    [close]="false"
    [disabled]="sessionService.isSubmitting || !isPaymentApprover"
  >
  </app-dialog-action>
  <app-dialog-action
    *ngIf="!IsReadOnly && salaryBatchContext?.IsFinalized"
    [type]="'Resend'"
    [disabled]="sessionService.isSubmitting || (IsGreenlandCompany && !hasIntegration)"
  ></app-dialog-action>
  <app-dialog-action
    *ngIf="isPaymentApprover && salaryBatchContext?.IsFinalized"
    [type]="'Revert'"
    [disabled]="sessionService.isSubmitting"
  >
  </app-dialog-action>
  <app-dialog-action
    *ngIf="salaryBatchContext?.BatchId > 0 && salaryBatchContext?.IsDraft"
    [type]="'Save'"
    [disabled]="sessionService.isSubmitting"
  >
  </app-dialog-action>
</app-dialog>
<!-- /New salary batch dialog -->

<div *ngIf="!popup">
  <div class="Card" *ngIf="!salaryBatch">
    <div class="Card-inner">
      <button-component
        [buttonClass]="'Button--primary'"
        [label]="'CompanySalaryBatches.SalaryBatchAddNewButton' | translate"
        (click)="onCreatePayrollBatchClicked()"
      >
      </button-component>
    </div>
  </div>

  <div class="Modal-header u-leader" *ngIf="salaryBatch">
    <div class="DialogHeaderForExistingSalarybatch">
      <strong>
        {{ 'CompanySalaryBatches.Salary batch no' | translate }} {{ salaryBatchContext?.SalaryBatchNumber }}
      </strong>
      <span class="right">
        {{ salaryBatchContext?.StatusName }}
        ({{ salaryBatchContext?.ChangeDate | DateFormat: undefined:undefined:true }})
      </span>
    </div>
  </div>

  <salary-batch-details
    *ngIf="salaryBatch"
    [(selectedEmployeeIds)]="selectedEmployeeIds"
    [isDialog]="false"
    [salaryBatch]="salaryBatch"
    [salaryCycles]="salaryCycles"
    [visible]="true"
    [salaryStatements]="salaryStatements"
    (entityContextChange)="onDetailContextChange($event)"
    (reloadSalaryBatches)="reloadSalaryBatches.emit()"
    (closeDialog)="closeDialog.emit()"
  ></salary-batch-details>

  <app-dialog-buttons *ngIf="salaryBatch" (buttonAction)="onSalaryBatchDialogAction($event)">
    <!--<app-dialog-action [type]="'Close'" [disabled]="sessionService.isSubmitting"></app-dialog-action>-->
    <app-dialog-action
      *ngIf="!IsReadOnly && salaryBatchContext?.BatchId < 1"
      [type]="'Create'"
      [disabled]="sessionService.isSubmitting"
    ></app-dialog-action>
    <app-dialog-action
      *ngIf="!IsReadOnly && !(!salaryBatchContext?.IsDraft || salaryBatchContext?.BatchId < 0)"
      [type]="'Delete'"
      [disabled]="sessionService.isSubmitting"
    >
    </app-dialog-action>
    <app-dialog-action
      [requiredModuleId]="3"
      [type]="'SubmitForApproval'"
      [disabled]="sessionService.isSubmitting"
      *ngIf="
        ((IsReadOnly && isPaymentApprover) || sessionService.role.IsSalaryAdminAndHigher) &&
        salaryBatchContext?.BatchId > 0 &&
        salaryBatchContext?.IsDraft
      "
    >
    </app-dialog-action>
    <app-dialog-action
      [requiredModuleId]="3"
      *ngIf="!IsReadOnly && salaryBatchContext?.BatchId > 0 && salaryBatchContext?.IsPendingForApproval"
      [type]="'Reject'"
      [disabled]="sessionService.isSubmitting"
    >
    </app-dialog-action>
    <app-dialog-action
      *ngIf="
        salaryBatchContext?.BatchId > 0 &&
        (salaryBatchContext?.IsDraft || salaryBatchContext?.IsPendingForApproval || salaryBatchContext?.IsPreliminary)
      "
      [type]="'Finalize'"
      [tooltip]="tooltipFinalizeContent | translate"
      [close]="false"
      [disabled]="sessionService.isSubmitting || !isPaymentApprover"
    >
    </app-dialog-action>
    <app-dialog-action
      *ngIf="!IsReadOnly && salaryBatchContext?.IsFinalized"
      [type]="'Resend'"
      [disabled]="sessionService.isSubmitting || (IsGreenlandCompany && !hasIntegration)"
    ></app-dialog-action>
    <app-dialog-action
      *ngIf="isPaymentApprover && salaryBatchContext?.IsFinalized"
      [type]="'Revert'"
      [disabled]="sessionService.isSubmitting"
    >
    </app-dialog-action>
    <app-dialog-action
      *ngIf="salaryBatchContext?.BatchId > 0 && salaryBatchContext?.IsDraft"
      [type]="'Save'"
      [disabled]="sessionService.isSubmitting"
    >
    </app-dialog-action>
  </app-dialog-buttons>
</div>
