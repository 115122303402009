<div class="FormElement">
  <grid
    [data]="templates"
    [editMode]="false"
    [(isDisableSelect)]="isEditMode"
    [companayTemplates]="true"
    class="FormElement-grid Company-templateGrid {{ classDisableSelect }}"
    [disabled]="locked"
    [idColumn]="'Id'"
    [(selectedId)]="selectedId"
    [(triggerUpdate)]="triggerGridRefresh"
    (selectedItemChange)="onSelectionChange($event)"
  >
    <gridColumn
      [classField]="'isActiveFeild'"
      [field]="'Name'"
      [type]="'text'"
      [title]="'CompanyHiringStatus.TemplateGridTitle' | translate"
    ></gridColumn>
  </grid>
</div>
