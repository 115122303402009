import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { ReflectionHelper } from '../Common/ReflectionHelper';
var CheckboxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CheckboxComponent, _super);
    function CheckboxComponent(elementRef, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.tooltip = '';
        _this.helpTooltip = '';
        _this.isOpenLink = true;
        _this.helpIcon = 'Help';
        _this.selfService = '';
        _this.isLink = false;
        return _this;
    }
    Object.defineProperty(CheckboxComponent.prototype, "labelClass", {
        get: function () {
            return this.customClass + ' ' + (this.isGrid ? 'checkBox-label-center' : '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckboxComponent.prototype, "isDisabled", {
        get: function () {
            return this.disable ? this.disable : !this.editMode;
        },
        enumerable: true,
        configurable: true
    });
    CheckboxComponent.prototype.valid = function () {
        return true;
    };
    CheckboxComponent.prototype.convertValue = function (value) {
        if (value && ReflectionHelper.isString(value)) {
            return value.toLowerCase() === 'true';
        }
        else {
            return value;
        }
    };
    return CheckboxComponent;
}(ControlComponentBase));
export { CheckboxComponent };
