<div>
  <module-check [moduleId]="4" [(hasModule)]="hasSalaryTypeModule">
    <div class="Cards">
      <div class="Card">
        <div class="Card-inner">
          <legend
            style="padding-bottom: 8px; padding-left: 1px;font-size: 0.75rem; font-style: italic; opacity: 0.75;"
            [innerHTML]="'CompanySalaryTypes.SalaryTypeGridHint' | translate"
          ></legend>
          <grid
            [editMode]="!IsReadOnly"
            [(data)]="gridFilterData"
            [idColumn]="'Id'"
            [(selectedId)]="selectedId"
            (cellClick)="onCellClick($event)"
            (saveChangesEvent)="saveChanges($event)"
            class="FormElement-grid Company-salaryTypeCustomGrid u-leader"
            [filterable]="'menu'"
            [filter]="filter"
            (filterChange)="onFilterChange($event)"
          >
            <gridColumn
              [isFilterable]="true"
              [locked]="true"
              [width]="50"
              [type]="'checkbox'"
              [editable]="!IsReadOnly"
              [field]="'IsActive'"
              [classField]="'isActiveClass'"
              [title]="'Employee.IsActive' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [locked]="true"
              [width]="50"
              [type]="'text'"
              [editable]="false"
              [field]="'Id'"
              [title]="'Id'"
              [headerTooltip]="'CompanySalaryTypes.IdHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [locked]="true"
              [width]="156"
              [type]="'text'"
              [viewClass]="'truncated'"
              [editable]="!isLanguageModuleEnable"
              [field]="'Name'"
              [headerTooltip]="'CompanySalaryTypes.NameHeaderTooltip' | translate"
              [classField]="'nameClass'"
              [title]="'CompanySalaryTypes.Name' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="250"
              [type]="'text'"
              [viewClass]="'truncated'"
              [editable]="false"
              [field]="'baseSalaryName'"
              [title]="'CompanySalaryTypes.InheritsFrom' | translate"
              [headerTooltip]="'CompanySalaryTypes.BaseSalaryTypeNameHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="250"
              [type]="'text'"
              [viewClass]="'truncated'"
              [editable]="!isLanguageModuleEnable"
              [field]="'Description'"
              [classField]="'descriptionClass'"
              [title]="'CompanySalaryTypes.Description' | translate"
              [headerTooltip]="'CompanySalaryTypes.DescriptionHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="90"
              [type]="'combobox'"
              [viewClass]="'truncated'"
              [editable]="!IsReadOnly"
              [field]="'AccountTypeId'"
              [comboboxDataSource]="accountTypes"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [classField]="'accountTypeIdClass'"
              [title]="'CompanySalaryTypes.AccountType' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="120"
              [type]="'autocomplete'"
              [viewClass]="'truncated'"
              [editable]="!IsReadOnly"
              [field]="'ExternalReference'"
              [classField]="'externalReferenceClass'"
              [comboboxDataSource]="externalReferences"
              [comboboxDisplayField]="'FriendlyName'"
              [title]="'CompanyGeneral.ERP ref' | translate"
              [headerTooltip]="'CompanySalaryTypes.ExternalReferenceHeaderTooltip' | translate"
              [autocompleteby1value]="true"
            >
            </gridColumn>
            <!-- <gridColumn [isFilterable]="true" [width]="70" [type]="'numeric'" [numbericOption]="'n0'" [editable]="!IsReadOnly" [field]="'OverviewIndex'"
                            [classField]="'overviewIndexClass'" [title]="'CompanySalaryTypes.OverviewIndex' | translate">
                        </gridColumn> -->
            <!-- <gridColumn [isFilterable]="true" [width]="130" [type]="'combobox'" [editable]="!IsReadOnly" [field]="'OverviewDisplayMode'"
                            [comboboxDataSource]="overviewDisplayModes" [comboboxValueField]="'Id'" [comboboxDisplayField]="'Name'"
                            [classField]="'OverviewDisplayModeClass'" [title]="'CompanySalaryTypes.OverviewDisplayMode' | translate"
                            [headerTooltip]="'CompanySalaryTypes.OverviewDisplayModeTooltip' | translate">
                        </gridColumn> -->
            <gridColumn
              [isFilterable]="true"
              [width]="70"
              [type]="'numeric'"
              [numbericOption]="'n0'"
              [editable]="!IsReadOnly"
              [field]="'SortOrder'"
              [classField]="'sortOrderClass'"
              [title]="'CompanySalaryTypes.SortOrder' | translate"
              [headerTooltip]="'CompanySalaryTypes.SortOrderHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="156"
              [type]="'text'"
              [viewClass]="'truncated'"
              [editable]="!isLanguageModuleEnable"
              [field]="'DefaultPayslipText'"
              [classField]="'defaultPayslipTextClass'"
              [title]="'CompanySalaryTypes.PayslipText' | translate"
              [headerTooltip]="'CompanySalaryTypes.DefaultPayslipTextHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="100"
              [type]="'combobox'"
              [viewClass]="'truncated'"
              [editable]="!IsReadOnly"
              [field]="'SummaryCategoryId'"
              [comboboxDataSource]="summaryCategories"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [classField]="'summaryCategoryClass'"
              [title]="'CompanySalaryTypes.SummaryCategory' | translate"
              [headerTooltip]="'CompanySalaryTypes.SummaryCategoryIdHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="100"
              [type]="'combobox'"
              [editable]="!IsReadOnly"
              [field]="'UnitTypeId'"
              [comboboxDataSource]="unitTypes"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [classField]="'unitTypeClass'"
              [title]="'CompanySalaryTypes.UnitType' | translate"
              [headerTooltip]="'CompanySalaryTypes.UnitTypeIdHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="80"
              [type]="'checkbox'"
              [editable]="!IsReadOnly"
              [field]="'AllowEditUnitType'"
              [classField]="'allowEditUnitTypeClass'"
              [title]="'CompanySalaryTypes.AllowEditUnitType' | translate"
              [headerTooltip]="'CompanySalaryTypes.AllowEditUnitTypeHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="100"
              [type]="'numeric'"
              [numbericOption]="'n2'"
              [editable]="!IsReadOnly"
              [field]="'AmountPerUnit'"
              [classField]="'amountPerUnitClass'"
              [title]="'CompanySalaryTypes.AmountPerUnit' | translate"
              [headerTooltip]="'CompanySalaryTypes.AmountPerUnitHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="100"
              [type]="'numeric'"
              [numbericOption]="'n2'"
              [editable]="!IsReadOnly"
              [field]="'Units'"
              [classField]="'unitsClass'"
              [title]="'CompanySalaryTypes.Units' | translate"
              [headerTooltip]="'CompanySalaryTypes.UnitsHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="100"
              [type]="'numeric'"
              [numbericOption]="'n2'"
              [editable]="!IsReadOnly"
              [field]="'BaseAmount'"
              [classField]="'baseAmountClass'"
              [title]="'CompanySalaryTypes.BaseAmount' | translate"
              [headerTooltip]="'CompanySalaryTypes.BaseAmountHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="95"
              [type]="'checkbox'"
              [editable]="!IsReadOnly"
              [field]="'AllowEditBaseAmount'"
              [classField]="'allowEditBaseAmountClass'"
              [title]="'CompanySalaryTypes.AllowEditBaseAmount' | translate"
              [headerTooltip]="'CompanySalaryTypes.AllowEditBaseAmountHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="100"
              [type]="'numeric'"
              [numbericOption]="'n2'"
              [editable]="!IsReadOnly"
              [field]="'Amount'"
              [classField]="'amountClass'"
              [title]="'CompanySalaryTypes.FixedAmount' | translate"
              [headerTooltip]="'CompanySalaryTypes.AmountHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="120"
              [type]="'checkbox'"
              [editable]="!IsReadOnly"
              [field]="'NegativeDefault'"
              [classField]="'negativeDefaultClass'"
              [title]="'CompanySalaryTypes.ExpectNegativeAmount' | translate"
              [headerTooltip]="'CompanySalaryTypes.NegativeDefaultHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="100"
              [type]="'combobox'"
              [editable]="!IsReadOnly"
              [field]="'DefaultPersistanceTypeId'"
              [comboboxDataSource]="persistanceTypes"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [classField]="'defaultPersistanceTypeIdClass'"
              [title]="'CompanySalaryTypes.AfterNextbatch' | translate"
              [headerTooltip]="'CompanySalaryTypes.DefaultPersistanceTypeIdHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="200"
              [type]="'combobox'"
              [editable]="!IsReadOnly"
              [field]="'CategoryId'"
              [comboboxDataSource]="categories"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [classField]="'categoryIdClass'"
              [title]="'CompanySalaryTypes.Behavior' | translate"
              [headerTooltip]="'CompanySalaryTypes.CategoryIdHeaderTooltip' | translate"
              [helpLink]="'Help.SalaryTypeBehaviorHelpLink' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              *ngIf="isFromDenmark"
              [width]="100"
              [type]="'numeric'"
              [numbericOption]="'n0'"
              [editable]="!IsReadOnly"
              [field]="'EIncomeCategory'"
              [classField]="'eIncomeCategoryClass'"
              [title]="'CompanySalaryTypes.IncomeCategory' | translate"
              [headerTooltip]="'CompanySalaryTypes.EIncomeCategoryHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              *ngIf="isFromDenmark"
              [width]="100"
              [type]="'numeric'"
              [numbericOption]="'n0'"
              [editable]="!IsReadOnly"
              [field]="'EIncomeSubCategory'"
              [classField]="'eIncomeSubCategoryClass'"
              [title]="'CompanySalaryTypes.EIncomeField' | translate"
              [headerTooltip]="'CompanySalaryTypes.EIncomeSubCategoryHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              *ngIf="isFromGreenland"
              [width]="100"
              [type]="'text'"
              [editable]="!IsReadOnly"
              [field]="'SulinalFieldNumber'"
              [classField]="'sulinalFieldNumberClass'"
              [title]="'CompanySalaryTypes.SulinalFieldNumber' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="100"
              [type]="'text'"
              [editable]="!IsReadOnly"
              [field]="'StatisticsFieldCode'"
              [classField]="'statisticsFieldCodeClass'"
              [title]="'CompanySalaryTypes.StatisticsFieldCode' | translate"
              [headerTooltip]="'CompanySalaryTypes.StatisticsFieldCodeHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="100"
              [type]="'text'"
              [editable]="!IsReadOnly"
              [field]="'SumFromIds'"
              [classField]="'sumFromIdsClass'"
              [title]="'CompanySalaryTypes.SumFromIds' | translate"
              [headerTooltip]="'CompanySalaryTypes.SumFromIdsHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="125"
              [type]="'checkbox'"
              [editable]="!IsReadOnly"
              [field]="'SumFromReverseSign'"
              [classField]="'sumFromReverseSignClass'"
              [title]="'CompanySalaryTypes.SumFromReverseSign' | translate"
              [headerTooltip]="'CompanySalaryTypes.SumFromReverseSignHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="105"
              [type]="'checkbox'"
              [editable]="!IsReadOnly"
              [field]="'IncludeAlways'"
              [classField]="'includeAlwaysClass'"
              [title]="'CompanySalaryTypes.IncludeAlways' | translate"
              [headerTooltip]="'CompanySalaryTypes.IncludeAlwaysHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="250"
              [type]="'text'"
              [editable]="!IsReadOnly"
              [field]="'Explanation'"
              [classField]="'explanationClass'"
              [title]="'CompanySalaryTypes.Explanation' | translate"
              [headerTooltip]="'CompanySalaryTypes.ExplanationHeaderTooltip' | translate"
            >
            </gridColumn>
            <gridColumn
              [isFilterable]="true"
              [width]="105"
              [type]="'checkbox'"
              [editable]="!IsReadOnly"
              [field]="'TriggersOvertimeSupplement'"
              [classField]="'triggersOvertimeSupplementClass'"
              [title]="'CompanySalaryTypes.TriggersOvertimeSupplement' | translate"
              [headerTooltip]="'CompanySalaryTypes.TriggersOvertimeSupplementTooltip' | translate"
            >
            </gridColumn>
          </grid>
        </div>
      </div>
    </div>

    <div class="Actions">
      <action-button
        id="ActionButtonAdd"
        [disabled]="!hasSalaryTypeModule"
        [buttonClass]="'Action-button Action-buttonAdd'"
        [icon]="'Add'"
        [label]="'Company.New'"
        (click)="onAddClick()"
        [tooltip]="'CompanySalaryBatches.AddSalaryTypeText'"
      >
      </action-button>
      <help-button [module]="'company'"></help-button>
    </div>
  </module-check>
</div>

<company-salary-type-translation
  *ngIf="translationDialogVisible"
  [salaryType]="translationSalaryType"
  [(visible)]="translationDialogVisible"
  (translateComplete)="onTranslateCompleted()"
>
</company-salary-type-translation>

<new-custom-salary-type-dialog
  [(visible)]="newSalaryTypeDialogVisible"
  [salaryTypeCategories]="SalaryTypeCategories"
  (add)="onAddSalaryType($event)"
>
</new-custom-salary-type-dialog>
