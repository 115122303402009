import {
  IAddUserToCompanyRequest,
  ICountry,
  IDepartment,
  ILanguage,
  IRole,
  IUserEmploymentTemplate
} from '../../Services/ApiModel';

export class NewUserViewModel {
  public Titles: string[];
  public Countries: ICountry[];
  public Languages: ILanguage[];
  public Departments: IDepartment[];
  public Roles: IRole[];
  public Templates: IUserEmploymentTemplate[];
  public TaxCardTypes: IRole[];
  public AddUserCompanyRequest: IAddUserToCompanyRequest;
  public NoCPRNumber = false;
}
