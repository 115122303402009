<ng-template #iconTooltipTemplate>
  <span [innerHTML]="tooltip"></span>
</ng-template>

<button
  (click)="onIconClick()"
  *ngIf="icon && istooltipHTML"
  kendoTooltip
  [tooltipTemplate]="iconTooltipTemplate"
  [title]="''"
  [disabled]="disable"
  [tooltipWidth]="tooltipWidth"
  class="iconButton {{ disableClass }}"
>
  <span [ngClass]="{ 'FormElement-cursorHand': link }">
    <svg aria-hidden="true" [ngClass]="class">
      <use [attr.xlink:href]="absoluteUrl + '#' + iconSvg"></use>
    </svg>
  </span>
</button>

<button
  (click)="onIconClick()"
  *ngIf="icon && !istooltipHTML"
  kendoTooltip
  [title]="tooltip || ''"
  [disabled]="disable"
  class="iconButton {{ disableClass }} {{ isSelfService }}"
>
  <span class="IconContainer--flex" [ngClass]="{ 'FormElement-cursorHand': link }">
    <svg aria-hidden="true" [ngClass]="class">
      <use [attr.xlink:href]="absoluteUrl + '#' + iconSvg"></use>
    </svg>
  </span>
</button>
