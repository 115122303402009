import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITaxCardType } from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'tax-card-request-dialog',
  templateUrl: './TaxCardRequestDialog.component.html'
})
export class TaxCardRequestDialogComponent {
  @Output() public change: EventEmitter<void> = new EventEmitter<void>();
  @Output() public isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public get isVisible(): boolean {
    return this.visible;
  }

  public set isVisible(value: boolean) {
    if (value === true) {
      // Set default selected value for tax card types combobox
      this.selectedTaxcardTypeId =
        this.taxcardTypes && this.taxcardTypes.length > 0 ? this.taxcardTypes[0].Id : undefined;
    }

    this.visible = value;
    this.isVisibleChange.emit(value);
  }

  @Input() public userEmploymentId: number;
  @Input() public taxcardTypes: ITaxCardType[];
  public selectedTaxcardTypeId: number;
  private visible = false;
  public isShowAlert = false;
  public alertMessage: string;

  constructor(private dataService: RxDataService, public sessionService: SessionService) {}

  public onTaxCardAction(action: string): void {
    if (action === 'Ok') {
      //// TODO: This should not be necessary when the combobox-edit has proper validation implemented
      if (!this.selectedTaxcardTypeId) {
        return;
      }

      this.dataService
        .Employments_RequestTaxInfo(this.userEmploymentId, this.selectedTaxcardTypeId)
        .subscribe((data: string): void => this.requestTaxCardCompleted(data));
    }
  }

  private requestTaxCardCompleted(data: string): void {
    this.isShowAlert = true;
    this.alertMessage = data;
    this.change.emit();
  }
}
