import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { ReportParametersDefaultValue } from '../../SharedComponents/ReportsDialog/ReportParametersDefaultValue';

@Component({
  selector: 'app-approval',
  templateUrl: './Approval.component.html'
})
export class ApprovalComponent implements OnInit {
  public isVisible = false;
  constructor(private sessionService: SessionService, private renderer: Renderer2) {}

  public ngOnInit(): void {
    this.renderer.listen(window, 'click', () => {
      if (!this.isCollapseButtonClick) {
        this.menuOpen = false;
      } else {
        this.isCollapseButtonClick = false;
      }
    });
    if (this.sessionService.currentState === 'tabs.company.approval') {
      if (this.sessionService.isApprovalMessage) {
        this.sessionService.isApprovalMessage = false;
        this.isVisible = true;
      }
      this.gotoTimeEntryApproval();
    }
  }

  public menuOpen: boolean;
  public reportParams: ReportParametersDefaultValue;
  private isCollapseButtonClick: boolean;

  public get approvalTimeEntryIsActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.approval.timeentry';
  }

  public onToggleNavigation(): void {
    this.isCollapseButtonClick = true;
    this.menuOpen = !this.menuOpen;
  }

  public gotoTimeEntryApproval(): void {
    this.menuOpen = false;
    this.sessionService.NavigateTo('tabs.company.approval.timeentry');
  }

  public reportDialogVisible = false;
  public noReportVisible = false;

  public onShowReportsEventClick(): void {
    const parameters = new ReportParametersDefaultValue();
    parameters.employeeId = this.sessionService.currentSalaryPeriodId
      ? this.sessionService.currentSalaryPeriodId
      : undefined;
    this.reportParams = parameters;

    if (this.sessionService.currentSalaryPeriodId) {
      this.reportDialogVisible = true;
      this.noReportVisible = false;
    } else {
      this.reportDialogVisible = false;
      this.noReportVisible = true;
    }
  }
}
