var ImportMappingDataItem = /** @class */ (function () {
    function ImportMappingDataItem() {
        this.gratisalColumnsDropDownDataSource = [];
        this.columnsTypeDropDownDataSource = [];
        this.gratisalColumnsValue = '';
        this.columnstypeValue = undefined;
        // private autoMapColumnType(): void {
        //     if (!this.columnsTypeDropDownDataSource) {
        //         return;
        //     }
        //     let columnType: MappingObject = this.findColumnTypeByName(
        //         this.userFileColumnHeader
        //     );
        //     if (!columnType) {
        //         return;
        //     }
        //     this.columnsTypeValue = columnType.Id;
        // }
        // private findColumnTypeByName(name: string): MappingObject {
        //     return this.columnsTypeDropDownDataSource.find(
        //         (ctype: MappingObject) => {
        //             return (
        //                 this.userFileColumnHeader &&
        //                 ctype.Name &&
        //                 this.userFileColumnHeader.trim().toLowerCase() ===
        //                     ctype.Name.trim().toLowerCase()
        //             );
        //         }
        //     );
        // }
    }
    Object.defineProperty(ImportMappingDataItem.prototype, "IsId", {
        get: function () {
            return this.isId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportMappingDataItem.prototype, "columnsTypeValue", {
        get: function () {
            return this.columnstypeValue;
        },
        set: function (value) {
            this.columnstypeValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportMappingDataItem.prototype, "columnsTypeKey", {
        get: function () {
            var id = this.columnsTypeValue;
            if (!id) {
                return '';
            }
            var columnTypeItem = this.findColumnTypeItem(id);
            if (!columnTypeItem) {
                return '';
            }
            return columnTypeItem.Key;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportMappingDataItem.prototype, "GratisalColumnsValue", {
        get: function () {
            return this.gratisalColumnsValue;
        },
        set: function (value) {
            this.gratisalColumnsValue = value;
            var gratisalColumn = this.findGratisalColumnItem(value);
            this.isId = gratisalColumn && gratisalColumn.IsId;
            if (this.isId) {
                //// set column type value undefined when it's disable
                this.columnsTypeValue = undefined;
            }
        },
        enumerable: true,
        configurable: true
    });
    ImportMappingDataItem.prototype.setGratisalColumnDropDownDataSourceAndAutoMap = function (dataSource, autoMap) {
        this.gratisalColumnsDropDownDataSource = dataSource;
        if (!autoMap) {
            return;
        }
        this.autoMapGratisalColumn();
    };
    ImportMappingDataItem.prototype.setColumnTypeDropDownDataSourceAndAutoMap = function (dataSource) {
        this.columnsTypeDropDownDataSource = dataSource;
        //// this.autoMapColumnType();
    };
    ImportMappingDataItem.prototype.findGratisalColumnItem = function (value) {
        var dataSource = this.gratisalColumnsDropDownDataSource;
        if (!value || !dataSource || dataSource.length === 0) {
            return undefined;
        }
        var result;
        if (dataSource) {
            result = dataSource.find(function (val) {
                return val.Value === value;
            });
        }
        return result;
    };
    ImportMappingDataItem.prototype.findColumnTypeItem = function (id) {
        var dataSource = this.columnsTypeDropDownDataSource;
        if (!id || !dataSource || dataSource.length === 0) {
            return undefined;
        }
        var result;
        if (dataSource) {
            result = dataSource.find(function (val) {
                return val.Id === id;
            });
        }
        return result;
    };
    ImportMappingDataItem.prototype.findColumnTypeItemByKey = function (key) {
        var dataSource = this.columnsTypeDropDownDataSource;
        if (!key || !dataSource || dataSource.length === 0) {
            return undefined;
        }
        var result;
        if (dataSource) {
            result = dataSource.find(function (val) {
                return val.Key === key;
            });
        }
        return result;
    };
    ImportMappingDataItem.prototype.autoMapGratisalColumn = function () {
        if (!this.gratisalColumnsDropDownDataSource) {
            return;
        }
        var gratisalColumn = this.findGratisalColumnByName(this.userFileColumnHeader);
        if (!gratisalColumn) {
            return;
        }
        this.GratisalColumnsValue = gratisalColumn.Value;
    };
    ImportMappingDataItem.prototype.findGratisalColumnByName = function (name) {
        var _this = this;
        if (this.gratisalColumnsDropDownDataSource) {
            return this.gratisalColumnsDropDownDataSource.find(function (ctype) { return _this.userFileColumnHeader === ctype.Name; });
        }
        return undefined;
    };
    return ImportMappingDataItem;
}());
export { ImportMappingDataItem };
