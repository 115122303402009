import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Browser } from '../../../Common/Browser';
import { ISalaryStatement, IUserEmployment } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';

@Component({
  selector: 'employee-payroll-data',
  templateUrl: './EmployeePayrollData.component.html'
})
export class EmployeePayrollDataComponent implements OnInit {
  @Input() public allowEdit: boolean;
  private userEmploymentValue: IUserEmployment;
  @Input()
  public get userEmployment(): IUserEmployment {
    return this.userEmploymentValue;
  }
  public set userEmployment(value: IUserEmployment) {
    if (!this.userEmploymentValue && !value) {
      return;
    }

    this.userEmploymentValue = value;
    this.hasEmployment = this.userEmployment && this.userEmployment.Id ? true : false;
  }

  public get isMobile(): boolean {
    const browser: Browser = new Browser();
    return browser.isMobile;
  }

  private currentAddnew = false;
  @Input()
  public get isAddNew(): boolean {
    return this.currentAddnew;
  }

  public set isAddNew(value: boolean) {
    this.currentAddnew = value;
    this.isAddNewChange.emit(value);
  }

  @Output() public createUserEmploymentClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public isAddNewChange: EventEmitter<any> = new EventEmitter<any>();

  public recalculate = false;
  public newTabBlockedDialogVisible = false;
  public previewDialogVisible = false;
  public previewContent: any;
  public noPayslipDialogVisible = false;
  public isHidePreviewGrid = false;

  public get isCordovaApp(): boolean {
    return this.sessionService.browser.isHybridApp;
  }
  public get hasUserEmployment(): boolean {
    return this.hasEmployment;
  }

  private hasEmployment = false;

  constructor(
    private settingService: SettingService,
    private dataService: RxDataService,
    private sessionService: SessionService
  ) {}

  public ngOnInit(): void {
    this.settingService.showModuleMessage('Employee.PayrollData');
  }

  public get ClassCardStack(): string {
    if (this.isHidePreviewGrid) {
      return '';
    }
    return 'Cards Cards--flex Form--stacked';
  }

  public createUserEmployment(): void {
    this.createUserEmploymentClick.emit();
  }

  public viewFullSalaryBatch(): void {
    this.sessionService.NavigateTo('tabs.company.salarybatches');
  }

  public viewLastPayslip(): void {
    this.dataService
      .SalaryStatements_GetSalaryStatementsByCompanyUser(this.userEmployment.CompanyUserId)
      .subscribe((data: ISalaryStatement[]): void => {
        if (data.length === 0) {
          this.noPayslipDialogVisible = true;
        } else {
          // Only show the last payslip - which should be the first one in the list
          this.showPayslipInNewTab(data[0]);
        }
      });
  }

  private showPayslipInNewTab(payslip: ISalaryStatement): void {
    this.dataService.SalaryStatements_GetHtmlPreview('html', payslip).subscribe((data: string): void => {
      if (this.sessionService.browser.isHybridApp) {
        this.previewContent = data;
        this.previewDialogVisible = true;
      } else {
        const newWindow: Window = window.open('about:blank');
        if (newWindow) {
          newWindow.document.write(data);
          newWindow.document.close();
        } else {
          this.newTabBlockedDialogVisible = true;
        }
      }
    });
  }

  public isUpdate: boolean;
  public onUpdate(): void {
    this.isUpdate = true;
  }
}
