<div>
  <div class="Card">
    <div class="Card--large">
      <grid
        [editMode]="!IsReadOnly"
        [(data)]="gridData"
        class="Company-salaryType"
        (saveChangesEvent)="onSave($event)"
        [filterable]="'menu'"
        [filter]="filter"
        (filterChange)="onFilterChange($event)"
        (cellClick)="onCellClick($event)"
      >
        <gridColumn
          [isFilterable]="true"
          [field]="'Name'"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [editable]="false"
          [type]="'text'"
          [width]="165"
          [title]="'CompanyGeneral.Salary Type' | translate"
          [addOnIconFieldString]="'IconField'"
          (addonClick)="onAddonClickEvent()"
        ></gridColumn>
        <gridColumn
          [isFilterable]="true"
          [field]="'Description'"
          [viewClass]="'wrapped'"
          [editable]="false"
          [type]="'text'"
          [width]="170"
          [title]="'CompanySalaryTypes.Description' | translate"
        ></gridColumn>
        <gridColumn
          [isFilterable]="true"
          [editableField]="'Editable'"
          [field]="'ExternalReference'"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [type]="'autocomplete'"
          [comboboxDataSource]="externalReferences"
          [comboboxDisplayField]="'FriendlyName'"
          [width]="160"
          [title]="'CompanyGeneral.Salary Type ERP ref' | translate"
          [autocompleteby1value]="true"
        >
        </gridColumn>
      </grid>
    </div>
  </div>
</div>
<app-dialog (action)="onActivateModuleDialogAction($event)" [(visible)]="activateModuleDialogVisible" [width]="800">
  <div *ngIf="isFullAdmin" [innerHTML]="'AccountModules.DisabledModuleAdmin' | translate: translationParams"></div>
  <div *ngIf="!isFullAdmin" [innerHTML]="'AccountModules.DisabledModuleEmployee' | translate"></div>
  <app-dialog-action *ngIf="isFullAdmin" [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action *ngIf="!isFullAdmin" [type]="'Ok'"></app-dialog-action>
  <app-dialog-action
    *ngIf="isFullAdmin"
    [action]="'ViewPackages'"
    [label]="'AccountModules.ViewPackages' | translate"
    [class]="'Button--primary'"
  ></app-dialog-action>
  <app-dialog-action
    *ngIf="isFullAdmin"
    [action]="'ActivateModule'"
    [label]="'AccountModules.ActivateModule' | translate"
    [class]="'Button--destructive'"
  ></app-dialog-action>
</app-dialog>
<app-dialog (action)="reloadPage()" [(visible)]="moduleActivatedDialogVisible">
  <div *ngIf="isFullAdmin" [innerHTML]="moduleActivatedDialogMessage | translate"></div>
  <app-dialog-action *ngIf="isFullAdmin" [type]="'Ok'"></app-dialog-action>
</app-dialog>
