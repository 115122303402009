<div class="TabPanel {{ session.currentStyleMenuTabpanelReponsive }}">
  <div (click)="clickAreamenuToggledChange()" class="Tabs-container">
    <!-- Employees tabs -->
    <ng-container *ngIf="parentTab === 'employee'">
      <!-- Employee Open search -->
      <div class="TabPanel-item u-hideMobile" *ngIf="session.feature.ShowEmployeeList">
        <button class="TabPanel-link" type="button" (click)="session.employeeListActive = true">
          <div>
            <app-icon [icon]="'People'"></app-icon>
            <div class="TabPanel-title">
              {{ 'Employee.Open search' | translate }}
            </div>
          </div>
        </button>
      </div>
      <!-- Employee General -->
      <div *ngIf="!session.role.EmployeeWithRoleAsManager">
        <button
          id="EmployeeGeneralTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.employee.general')"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.employee.general') }"
        >
          <div>
            <app-icon [icon]="'User'"></app-icon>
            <div class="TabPanel-title">
              {{ 'Employee.General' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.employee.general') }"
        >
          {{ 'Employee.General' | translate }}
        </div>
      </div>
      <!-- Employee Employment -->
      <div *ngIf="!session.role.EmployeeWithRoleAsManager">
        <button
          id="EmployeeHiringStatusTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.employee.hiringstatus')"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.employee.hiringstatus') }"
        >
          <div>
            <app-icon [icon]="'EditForm'"></app-icon>
            <div class="TabPanel-title">
              {{ 'Employee.Employment' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.employee.hiringstatus') }"
        >
          {{ 'Employee.Employment' | translate }}
        </div>
      </div>
      <!-- Employee Time -->
      <div *ngIf="session.feature.EmployeeTimeTabEnabled">
        <button
          id="EmployeeTimeTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.employee.time')"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.employee.time') }"
        >
          <div>
            <app-icon [icon]="'Clock'"></app-icon>
            <div class="TabPanel-title">
              {{ 'Employee.Time' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.employee.time') }"
        >
          {{ 'Employee.Time' | translate }}
        </div>
      </div>
      <!-- Employee Payroll Data -->
      <div *ngIf="session.feature.EmployeePayrollDataTabEnabled && !session.role.EmployeeWithRoleAsManager">
        <button
          id="EmployeePayrollDataTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.employee.payrolldata')"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.employee.payrolldata') }"
        >
          <div>
            <app-icon [icon]="'Table'"></app-icon>
            <div class="TabPanel-title">
              {{ 'Employee.Payroll Data' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.employee.payrolldata') }"
        >
          {{ 'Employee.Payroll Data' | translate }}
        </div>
      </div>
      <!-- Employee Payslips -->
      <div *ngIf="!session.role.EmployeeWithRoleAsManager">
        <button
          id="EmployeePayslipTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.employee.payslip')"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.employee.payslip') }"
        >
          <div>
            <app-icon [icon]="'Dollar'"></app-icon>
            <div class="TabPanel-title">
              {{ 'EmployeeGeneral.Payslips' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.employee.payslip') }"
        >
          {{ 'EmployeeGeneral.Payslips' | translate }}
        </div>
      </div>
    </ng-container>

    <!-- Company tabs -->
    <ng-container *ngIf="parentTab === 'company'">
      <!-- Company General -->
      <div class="TabPanel-item" *ngIf="!session.role.EmployeeWithRoleAsManager">
        <button
          id="CompanyGeneralTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.company.general')"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.company.general') }"
        >
          <div>
            <app-icon [icon]="'Industry'"></app-icon>
            <div class="TabPanel-title">
              {{ 'Company.General' | translate }}
            </div>
          </div>
        </button>

        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.company.general') }"
        >
          {{ 'Company.General' | translate }}
        </div>
      </div>
      <!-- Company Payroll Data -->
      <div class="TabPanel-item" *ngIf="!session.role.EmployeeWithRoleAsManager">
        <button
          id="CompanyPayrollDataTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.company.payrolldata')"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.company.payrolldata') }"
        >
          <div>
            <app-icon [icon]="'Table'"></app-icon>
            <div class="TabPanel-title">
              {{ 'Company.PayrollData' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.company.payrolldata') }"
        >
          {{ 'Company.PayrollData' | translate }}
        </div>
      </div>
      <!-- Company Salary Batches -->
      <div class="TabPanel-item" *ngIf="!session.role.EmployeeWithRoleAsManager">
        <button
          id="CompanySalaryBatchesTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.company.salarybatches')"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.company.salarybatches') }"
        >
          <div>
            <app-icon [icon]="'Columns'"></app-icon>
            <div class="TabPanel-title">
              {{ 'Company.Salary Batches' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.company.salarybatches') }"
        >
          {{ 'Company.Salary Batches' | translate }}
        </div>
      </div>
      <!-- Company Approval -->
      <div class="TabPanel-item">
        <button
          id="CompanyApprovalTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.company.approval')"
          [ngClass]="{ 'is-active': state.parentStateIs('tabs.company.approval') }"
        >
          <div>
            <app-icon [icon]="'CalendarCheck'"></app-icon>
            <div class="TabPanel-title">
              {{ 'CompanySalaryTypes.Approval' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.parentStateIs('tabs.company.approval') }"
        >
          {{ 'CompanySalaryTypes.Approval' | translate }}
        </div>
      </div>
      <!-- Company Configuration -->
      <div class="TabPanel-item" *ngIf="!session.role.EmployeeWithRoleAsManager">
        <button
          id="CompanyConfigurationTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.company.configuration')"
          [ngClass]="{ 'is-active': state.parentStateIs('tabs.company.configuration') }"
        >
          <div>
            <app-icon [icon]="'Cog'"></app-icon>
            <div class="TabPanel-title">
              {{ 'Company.Configuration' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.parentStateIs('tabs.company.configuration') }"
        >
          {{ 'Company.Configuration' | translate }}
        </div>
      </div>
      <!-- Company Modules -->
      <div
        class="TabPanel-item"
        *ngIf="session.role.IsFullAdmin && !IsGreenlandCompany && !session.role.EmployeeWithRoleAsManager"
      >
        <button
          id="CompanyModulesTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.company.modules')"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.company.modules') }"
        >
          <div>
            <app-icon [icon]="'Diamond'"></app-icon>
            <div class="TabPanel-title">
              {{ 'Account.Modules' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.company.modules') }"
        >
          {{ 'Account.Modules' | translate }}
        </div>
      </div>
    </ng-container>

    <!-- Self Service tabs -->
    <ng-container *ngIf="parentTab === 'selfService'">
      <!-- Selfservice Payslips -->
      <div *ngIf="payslipTabVisible">
        <button
          id="SelfServicePayslipsTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.selfservice.payslip')"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.selfservice.payslip') }"
        >
          <div>
            <app-icon [icon]="'Dollar'"></app-icon>
            <div class="TabPanel-title">
              {{ 'EmployeeGeneral.Payslips' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.selfservice.payslip') }"
        >
          {{ 'EmployeeGeneral.Payslips' | translate }}
        </div>
      </div>
      <!-- SelfService My Details -->
      <div>
        <button
          id="SelfServiceGeneralTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.selfservice.general')"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.selfservice.general') }"
        >
          <div>
            <app-icon [icon]="'User'"></app-icon>
            <div class="TabPanel-title">
              {{ 'SelfService.My details' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.selfservice.general') }"
        >
          {{ 'SelfService.My details' | translate }}
        </div>
      </div>
      <!-- Selfservice Time -->
      <div *ngIf="timeTabVisible">
        <button
          id="SelfServiceTimeTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.selfservice.time')"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.selfservice.time') }"
        >
          <div>
            <app-icon [icon]="'Clock'"></app-icon>
            <div class="TabPanel-title">
              {{ 'Employee.Time' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.selfservice.time') }"
        >
          {{ 'Employee.Time' | translate }}
        </div>
      </div>
    </ng-container>

    <!-- Accountant tabs -->
    <ng-container *ngIf="parentTab === 'accountant'">
      <!-- Accountant Companies -->
      <div class="TabPanel-item">
        <button
          id="AccountantCompaniesTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.accountant.companies')"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.accountant.companies') }"
        >
          <div>
            <app-icon [icon]="'Industry'"></app-icon>
            <div class="TabPanel-title">
              {{ 'Accountant.Companies' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.accountant.companies') }"
        >
          {{ 'Accountant.Companies' | translate }}
        </div>
      </div>
      <!-- Accountant Users -->
      <div class="TabPanel-item">
        <button
          id="AccountantUsersTabButton"
          class="TabPanel-link"
          type="button"
          (click)="navigateToState($event, 'tabs.accountant.users')"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.accountant.users') }"
        >
          <div>
            <app-icon [icon]="'User'"></app-icon>
            <div class="TabPanel-title">
              {{ 'Accountant.Users' | translate }}
            </div>
          </div>
        </button>
        <div
          class="TabPanel-title-small-screen"
          [ngClass]="{ 'is-active': state.currentStateIs('tabs.accountant.users') }"
        >
          {{ 'Accountant.Users' | translate }}
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Top bar toggle -->
  <div class="Menu-toogle-item">
    <button
      class="TabPanel-link"
      type="button"
      [ngClass]="{ 'is-active': session.menuToggled }"
      (click)="toggleMenuButton()"
    >
      <div>
        <app-icon *ngIf="session.menuToggled" [icon]="'LevelDown'"></app-icon>
        <app-icon *ngIf="!session.menuToggled" [icon]="'LevelUp'"></app-icon>
      </div>
    </button>
  </div>
</div>
