import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../Common/Constants';
import { Broadcaster } from '../../Services/Broadcaster';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
var ChangePasswordComponent = /** @class */ (function () {
    function ChangePasswordComponent(dataService, broadcaster, sessionService) {
        this.dataService = dataService;
        this.broadcaster = broadcaster;
        this.sessionService = sessionService;
        this.isChangePassworSuccessDialog = false;
        this.isChangePassworFailDialog = false;
        this.isVisibleValue = false;
        this.isVisibleChange = new EventEmitter();
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(ChangePasswordComponent.prototype, "isVisible", {
        get: function () {
            return this.isVisibleValue;
        },
        set: function (value) {
            if (this.isVisibleValue !== value) {
                if (value) {
                    this.currentPass = '';
                    this.newPass = '';
                }
                this.isVisibleValue = value;
                this.isVisibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    ChangePasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userName = localStorage.getItem('saveLoginUserName');
        this.broadcaster
            .on(Constants.ACCOUNT_CHANGE_PASSWORD)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (event) { return (_this.isVisible = true); });
    };
    ChangePasswordComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    ChangePasswordComponent.prototype.keydown = function (e) {
        if (e.keyCode !== 13) {
            return;
        }
        this.onChangePassword();
    };
    ChangePasswordComponent.prototype.onChangePasswordDialogAction = function (action) {
        if (action === 'ChangePassword') {
            this.onChangePassword();
        }
        else {
            this.isVisible = false;
        }
    };
    ChangePasswordComponent.prototype.onChangePassword = function () {
        var _this = this;
        var CurrentPassword = this.currentPass;
        var NewPassword = this.newPass;
        if (CurrentPassword && NewPassword) {
            var request = { CurrentPassword: CurrentPassword, NewPassword: NewPassword };
            this.dataService.Auth_ChangePassword(request).subscribe(function () {
                _this.isChangePassworSuccessDialog = true;
            });
        }
        else {
            this.isChangePassworFailDialog = true;
        }
    };
    ChangePasswordComponent.prototype.onChangePasswordDialogSuccessAction = function (action) {
        if (action === 'Ok') {
            this.isVisible = false;
        }
    };
    ChangePasswordComponent.prototype.onChangePasswordDialogFailAction = function (action) {
        if (action === 'Ok') {
            this.isChangePassworFailDialog = false;
        }
    };
    return ChangePasswordComponent;
}());
export { ChangePasswordComponent };
