import { IApiUser, ICompany, ICompanyUser, ISimpleKeyValuePair, IUnitType } from '../Services/ApiModel';

export class Global {
  private static sessionEmpty = 'sessionEmpty';
  private static sessionKey = 'sessionKey';
  private static companyIdKey = 'companyIdKey';
  private static companyKey = 'companyKey';
  private static unitTypeKey = 'unitTypeKey';
  private static userPreferencesKey = 'userPreferencesKey';
  private static companyPreferencesKey = 'companyPreferencesKey';
  private static employeeselectKey = 'employeeselectKey';

  private static storeValues(key: string, values: any): void {
    const jsonString: string = JSON.stringify(values);
    try {
      if (typeof sessionStorage === 'object') {
        if (jsonString) {
          sessionStorage.setItem(key, jsonString);
        } else {
          sessionStorage.removeItem(key);
        }
      }
    } catch (e) {
      alert(
        'Bemærk: Din browser tillader ikke cookies, og Gratisal kan derfor ikke fungere optimalt. Vi anbefaler kraftigt at du tillader cookies på domænet app.gratisal.dk. Cookies er nødvendige for at holde styr på væsentlige detaljer om din session, eksempelvis dit sprogvalg.Læs vores fulde cookie - politik.' +
          '\n' +
          'Please note: Your browser is not allowing cookies, which means Gratisal cannot function correctly. We strongly recommend that you allow cookies for the domain app.gratisal.dk. Cookies are necessary to keep track of important details about your session, e.g.your choice of language.You can read our full policy on cookies.'
      );
    }
  }

  private static getValues(key: string): any {
    try {
      const value: string = sessionStorage.getItem(key);
      return value ? JSON.parse(value) : undefined;
    } catch (e) {
      alert(
        'Bemærk: Din browser tillader ikke cookies, og Gratisal kan derfor ikke fungere optimalt. Vi anbefaler kraftigt at du tillader cookies på domænet app.gratisal.dk. Cookies er nødvendige for at holde styr på væsentlige detaljer om din session, eksempelvis dit sprogvalg.Læs vores fulde cookie - politik.' +
          '\n' +
          'Please note: Your browser is not allowing cookies, which means Gratisal cannot function correctly. We strongly recommend that you allow cookies for the domain app.gratisal.dk. Cookies are necessary to keep track of important details about your session, e.g.your choice of language.You can read our full policy on cookies.'
      );
    }
  }

  private static employeeselectKeyValue: ICompanyUser;
  static get EMPLOYEESELECT(): ICompanyUser {
    return this.employeeselectKeyValue || this.getValues(this.employeeselectKey);
  }

  static set EMPLOYEESELECT(usercompany: ICompanyUser) {
    this.employeeselectKeyValue = usercompany;
    this.storeValues(this.employeeselectKey, usercompany);
  }

  private static companyIdValue: number;
  static get COMPANY_ID(): number {
    return this.companyIdValue || this.getValues(this.companyIdKey);
  }

  static set COMPANY_ID(companyId: number) {
    this.companyIdValue = companyId;
    this.storeValues(this.companyIdKey, companyId);
  }

  private static companyValue: ICompany;
  static get COMPANY(): ICompany {
    return this.companyValue || this.getValues(this.companyKey);
  }

  static set COMPANY(company: ICompany) {
    this.companyValue = company;
    this.storeValues(this.companyKey, company);
  }

  private static unitTypeValue: IUnitType[];
  static get UNIT_TYPES(): IUnitType[] {
    return this.unitTypeValue || this.getValues(this.unitTypeKey);
  }

  static set UNIT_TYPES(value: IUnitType[]) {
    this.unitTypeValue = value;
    this.storeValues(this.unitTypeKey, value);
  }

  private static userPreferencesValue: ISimpleKeyValuePair[];
  static get USER_PREFERENCES(): ISimpleKeyValuePair[] {
    return this.userPreferencesValue || this.getValues(this.userPreferencesKey);
  }

  static set USER_PREFERENCES(value: ISimpleKeyValuePair[]) {
    this.userPreferencesValue = value;
    this.storeValues(this.userPreferencesKey, value);
  }

  private static companyPreferencesValue: ISimpleKeyValuePair[];
  static get COMPANY_PREFERENCES(): ISimpleKeyValuePair[] {
    return this.companyPreferencesValue || this.getValues(this.companyPreferencesKey);
  }

  static set COMPANY_PREFERENCES(companyPreferences: ISimpleKeyValuePair[]) {
    this.companyPreferencesValue = companyPreferences;
    this.storeValues(this.companyPreferencesKey, companyPreferences);
  }

  private static sessionValue: IApiUser;
  static get SESSION(): IApiUser {
    if (this.sessionValue) {
      return this.sessionValue;
    }

    const storedSession: any = this.getValues(this.sessionKey);
    if (!storedSession && window.location.href.indexOf('login') < 0) {
      this.storeValues(this.sessionKey, this.sessionEmpty);
      window.location.reload(true);
    }

    this.sessionValue = storedSession !== this.sessionEmpty ? storedSession : undefined;
    return this.sessionValue;
  }

  static set SESSION(session: IApiUser) {
    this.sessionValue = session;
    this.storeValues(this.sessionKey, session);
  }

  private static startupTaskVisibilityKey = 'startupTaskVisibilityKey';
  static get STARTUP_TASKS_VISIBILITY(): boolean {
    return this.getValues(this.startupTaskVisibilityKey);
  }

  static set STARTUP_TASKS_VISIBILITY(value: boolean) {
    this.storeValues(this.startupTaskVisibilityKey, value);
  }

  private static welcomeMessageSettingKey = 'welcomeMessageSettingKey';
  private static welcomeMessageSettingValue: any;
  static get WELCOME_MESSAGE_SETTINGS(): any {
    return this.welcomeMessageSettingValue || this.getValues(this.welcomeMessageSettingKey);
  }

  static set WELCOME_MESSAGE_SETTINGS(value: any) {
    this.welcomeMessageSettingValue = value;
    this.storeValues(this.welcomeMessageSettingKey, value);
  }

  private static betaModuleMessageSettingKey = 'betaModuleMessageSettingKey';
  private static betaModuleMessageSettingValue: any;
  static get BETA_MODULE_MESSAGE_SETTINGS(): any {
    return this.betaModuleMessageSettingValue || this.getValues(this.betaModuleMessageSettingKey);
  }

  static set BETA_MODULE_MESSAGE_SETTINGS(value: any) {
    this.betaModuleMessageSettingValue = value;
    this.storeValues(this.betaModuleMessageSettingKey, value);
  }

  private static startUpTasksKey = 'startUpTasksKey';
  static get STARTUP_TASKS(): any {
    return this.getValues(this.startUpTasksKey);
  }

  static set STARTUP_TASKS(value: any) {
    this.storeValues(this.startUpTasksKey, value);
  }

  private static companyTaskKey = 'companyStartUpTaskCompleted';
  static get IsCompanyDataCompleted(): any {
    return this.getValues(this.companyTaskKey);
  }

  static set IsCompanyDataCompleted(value: any) {
    this.storeValues(this.companyTaskKey, value);
  }

  private static employeeTaskKey = 'employeeStartUpTaskCompleted';
  static get IsEmployeeTaskCompleted(): any {
    return this.getValues(this.employeeTaskKey);
  }

  static set IsEmployeeTaskCompleted(value: any) {
    this.storeValues(this.employeeTaskKey, value);
  }

  private static isDemoKey = 'isDemoKey';
  private static isDemoValue: boolean;
  static get IsDemo(): boolean {
    return this.isDemoValue || this.getValues(this.isDemoKey);
  }

  static set IsDemo(value: boolean) {
    this.isDemoValue = value;
    this.storeValues(this.isDemoKey, value);
  }

  private static statesHistoryKey = 'statesHistoryKey';
  private static statesHistoryValue: string[];
  static get statesHistory(): string[] {
    return this.statesHistoryValue || this.getValues(this.statesHistoryKey);
  }

  static set statesHistory(value: string[]) {
    this.statesHistoryValue = value;
    this.storeValues(this.statesHistoryKey, value);
  }

  private static isFirstCheckMessageAdBlockKey = 'isFirstCheckMessageAdBlockKey';
  private static currentisFirstCheckMessageAdBlock = false;
  static get isFirstCheckMessageAdBlock(): boolean {
    return this.currentisFirstCheckMessageAdBlock || this.getValues(this.isFirstCheckMessageAdBlockKey);
  }
  static set isFirstCheckMessageAdBlock(value: boolean) {
    this.currentisFirstCheckMessageAdBlock = value;
    this.storeValues(this.isFirstCheckMessageAdBlockKey, value);
  }
}
