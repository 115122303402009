/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../CustomControls/PictureEdit.component.ngfactory";
import * as i3 from "../../../CustomControls/PictureEdit.component";
import * as i4 from "../../../Services/SharedServices/Session/SessionService";
import * as i5 from "../../../Services/SharedServices/StaticData.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./EmployeeAvatar.component";
import * as i8 from "../../../Services/RxDataService";
var styles_EmployeeAvatarComponent = [];
var RenderType_EmployeeAvatarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmployeeAvatarComponent, data: {} });
export { RenderType_EmployeeAvatarComponent as RenderType_EmployeeAvatarComponent };
function View_EmployeeAvatarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "Avatar-bg"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("data:image/jpeg;base64," + _co.maleAvatar); _ck(_v, 0, 0, currVal_0); }); }
function View_EmployeeAvatarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "Avatar-bg"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("data:image/jpeg;base64," + _co.femaleAvatar); _ck(_v, 0, 0, currVal_0); }); }
export function View_EmployeeAvatarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeeAvatarComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeeAvatarComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 4, "app-picture-edit", [], null, [[null, "avatarChangeChange"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("avatarChangeChange" === en)) {
        var pd_0 = ((_co.avatarChange = $event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.onAvatarChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_PictureEditComponent_0, i2.RenderType_PictureEditComponent)), i0.ɵdid(5, 49152, null, 0, i3.PictureEditComponent, [i4.SessionService, i5.StaticDataService], { editMode: [0, "editMode"], label: [1, "label"], changeText: [2, "changeText"], takePhotoText: [3, "takePhotoText"], isAvatar: [4, "isAvatar"], avatarChange: [5, "avatarChange"], imageSource: [6, "imageSource"] }, { change: "change" }), i0.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.noAvatar && !_co.isFemale); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.noAvatar && _co.isFemale); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.editMode; var currVal_3 = i0.ɵunv(_v, 5, 1, i0.ɵnov(_v, 6).transform("Employee.Change picture")); var currVal_4 = i0.ɵunv(_v, 5, 2, i0.ɵnov(_v, 7).transform("Employee.Change picture")); var currVal_5 = i0.ɵunv(_v, 5, 3, i0.ɵnov(_v, 8).transform("Employee.Take photo")); var currVal_6 = true; var currVal_7 = _co.avatarChange; var currVal_8 = _co.avatar; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_EmployeeAvatarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-employee-avatar", [], null, null, null, View_EmployeeAvatarComponent_0, RenderType_EmployeeAvatarComponent)), i0.ɵdid(1, 114688, null, 0, i7.EmployeeAvatarComponent, [i8.RxDataService, i4.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeeAvatarComponentNgFactory = i0.ɵccf("app-employee-avatar", i7.EmployeeAvatarComponent, View_EmployeeAvatarComponent_Host_0, { companyUserId: "companyUserId", isFemale: "isFemale", editMode: "editMode", avatarChange: "avatarChange", avatar: "avatar" }, { isAvatarChanged: "isAvatarChanged" }, []);
export { EmployeeAvatarComponentNgFactory as EmployeeAvatarComponentNgFactory };
