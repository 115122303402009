import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { ControlComponentBase } from '../../../Common/ControlComponentBase';
var CprInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CprInfoComponent, _super);
    function CprInfoComponent(elementRef, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.required = true;
        return _this;
    }
    CprInfoComponent.prototype.valid = function () {
        if (this.required && (!this.value || this.value === '')) {
            return false;
        }
        return true;
    };
    return CprInfoComponent;
}(ControlComponentBase));
export { CprInfoComponent };
