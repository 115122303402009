import { SalaryBatch, SalaryBatchView } from '../../Services/ApiModel';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';

export class SalaryBatchViewModel extends SalaryBatch {
  Id: number;
  CompanyId: number;
  SalaryPeriodId: number;
  Created: Date;
  StatusId: number;
  Approved?: Date;
  RejectionReason?: string;
  Rejected?: Date;
  EIncomeTransferDate?: Date;
  Message?: string;
  PayoutDate: Date;
  ApprovedCompanyUserId?: number;
  RejectedCompanyUserId?: number;
  CreatedCompanyUserId?: number;
  HasErrors: boolean;
  HasWarnings: boolean;
  NetsPaymentsProcessingDate?: Date;
  CreatedByCompanyUserFullName?: string;
  ApprovedByCompanyUserFullName?: string;
  RejectedByCompanyUserFullName?: string;
  PeriodStartDate: Date;
  PeriodEndDate: Date;
  SalaryCycleId: number;
  LanguageId: number;
  StatusName?: string;
  SalaryBatchNumber: number;
  IsTermination: boolean;
  EIncomeZeroReport: boolean;
  PaymentMethodSalary: number;
  PaymentMethodTax: number;
  PaymentMethodPension: number;
  EIncomeReruns: number;
  PayslipsArchived: boolean;
  LimitToSpecificEmployees: boolean;
  Description?: string;
  PayoutAllFlex: boolean;
  ValidationStatusCode?: string;
  EditIcon = 'Edit';
  TitleDescription: string;

  public ValidationIcon: string;

  constructor(data: SalaryBatchView, sessionService: SessionService) {
    super();
    Object.assign(this, data);
    this.TitleDescription =
      'No. ' +
      this.SalaryBatchNumber +
      ' - ' +
      sessionService.toString(sessionService.parseDate(this.PeriodStartDate as any)) +
      ' - ' +
      sessionService.toString(sessionService.parseDate(this.PeriodEndDate as any)) +
      ' - ' +
      this.StatusName;
    switch (this.ValidationStatusCode) {
      case 'Error':
        this.ValidationIcon = 'ErrorExclamation';
        break;
      case 'Warning':
        this.ValidationIcon = 'Warning';
        break;
      default:
        this.ValidationIcon = '';
    }
  }
}
