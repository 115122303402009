import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileUploadComponent } from '../../../CustomControls/FileUpload.component';
import { ActionTypesEnum, ImportTypesEnum } from '../../../Model/Enum';
import { RxDataService } from '../../../Services/RxDataService';
import { DownloadService } from '../../../Services/SharedServices/DownloadService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { ImportOptionHelper } from './ImportOptionHelper';

@Component({
  selector: 'companydataimport',
  templateUrl: './CompanyDataImport.component.html'
})
export class CompanyDataImportComponent implements OnInit, OnDestroy {
  public importType: ImportTypesEnum = ImportTypesEnum.Payroll;
  public actionType: ActionTypesEnum = ActionTypesEnum.GetTemplate;
  public importFromExternalSource: any[];
  public importFromExternalSourceEmployee: any[];
  public importFromExternalSourceValue = 1;
  public importPayrollFromExternalSource: any[];
  public importFromExternalSourcePayrollData: any[];
  public importPayrollFromExternalSourceValue = 1;
  public showUploadFileDialog = false;
  public showCreateTemplateDialog = false;
  public payrollOrTimeEntriesDataIdTypesDatasource: any[];
  public payrollOrTimeEntriesDataIdType: any;
  public balanceDateDialogVisible = false;
  public alertDialogVisible = false;
  public alertType: string;
  public balanceDate: Date = new Date();

  public get showDownLoadButton(): boolean {
    return this.actionType === ActionTypesEnum.GetTemplate || this.actionType === 5;
  }
  public get showUploadButton(): boolean {
    return (
      this.actionType === ActionTypesEnum.Upload_File || this.actionType === ActionTypesEnum.Convert_From_Other_System
    );
  }
  public get showCreateCustomFile(): boolean {
    return this.actionType === ActionTypesEnum.CustomImport;
  }
  public get convertFromOtherSystemFieldsVisible(): boolean {
    return this.actionType === ActionTypesEnum.Convert_From_Other_System;
  }
  public get payrollOrTimeTemplateIdTypesVisible(): boolean {
    return (
      (this.importType === ImportTypesEnum.Payroll || this.importType === ImportTypesEnum.TimeEntries) &&
      this.actionType === ActionTypesEnum.GetTemplate
    );
  }
  public get isPayrollDataImport(): boolean {
    return this.importType === ImportTypesEnum.Payroll || this.importType === ImportTypesEnum.TimeEntries;
  }
  public get isTimeEntriesDataImport(): boolean {
    return this.importType === ImportTypesEnum.TimeEntries;
  }
  public get acceptFileType(): string {
    return this.importType === ImportTypesEnum.Payroll ||
      this.importType === ImportTypesEnum.TimeEntries /*|| this.importType === ImportTypesEnum.TimeEntries */
      ? this.importPayrollFromExternalSourceValue === 2
        ? '.csv'
        : '.dat'
      : this.importFromExternalSourceValue === 2
      ? '.csv'
      : '.pdf';
  }
  public get hasTimeModule(): boolean {
    return this.sessionService.feature.hasModuleId(8);
  }
  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }
  public get isShowExportExistingData(): boolean {
    return this.importType === ImportTypesEnum.Employees || this.importType === ImportTypesEnum.Initial_Balances;
  }

  @ViewChild('fileupload', { static: true }) private fileUpload: FileUploadComponent;

  constructor(
    private translateService: TranslateService,
    private dataService: RxDataService,
    private downloadService: DownloadService,
    private sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {
    this.translateText();
  }

  public ngOnInit(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) =>
      this.translateText()
    );
    this.balanceDate = this.getLastDateOfPreviousMonth();
    this.dataService.Import_GetIdTypes().subscribe((results: string[]) => {
      let idTypeIdCount = 0;
      this.payrollOrTimeEntriesDataIdTypesDatasource = [];
      if (results) {
        results.forEach((result: string) => {
          idTypeIdCount++;
          this.payrollOrTimeEntriesDataIdTypesDatasource.push({ Id: idTypeIdCount, Name: result });
        });
      }

      this.payrollOrTimeEntriesDataIdType =
        this.payrollOrTimeEntriesDataIdTypesDatasource && this.payrollOrTimeEntriesDataIdTypesDatasource.length > 0
          ? this.payrollOrTimeEntriesDataIdTypesDatasource[0].Id
          : undefined;
    });

    if (this.isMobile) {
      this.sessionService.isShowHugeFeaturesAlert = true;
    }
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDownLoad(): void {
    switch (this.importType) {
      case ImportTypesEnum.Employees:
      case ImportTypesEnum.Payroll:
      case ImportTypesEnum.TimeEntries:
      case ImportTypesEnum.TimeEntriesRow:
        this.downloadDocument();
        break;
      case ImportTypesEnum.Initial_Balances:
        // Popup date for Balances
        this.showBalancesDatePopup();
        break;
    }
  }

  public onUpload(): void {
    if (this.actionType === ActionTypesEnum.Convert_From_Other_System) {
      this.fileUpload.addFile();
    } else {
      this.showUploadFileDialog = true;
    }
  }

  public onCreate(): void {
    this.showCreateTemplateDialog = true;
  }

  private translateText(): void {
    this.translateService
      .get([
        'CompanyDataImport.ImportFromExternalOption',
        'CompanyDataImport.AOFdatfile',
        'CompanyDataImport.ImportFromPlanDay'
      ])
      .subscribe((translations: { [key: string]: string }) => {
        this.importFromExternalSource = [];
        this.importFromExternalSource.push({ Id: 1, Name: translations['CompanyDataImport.ImportFromExternalOption'] });

        this.importFromExternalSourceEmployee = [];
        this.importFromExternalSourceEmployee.push({
          Id: 1,
          Name: translations['CompanyDataImport.ImportFromExternalOption']
        });
        this.importFromExternalSourceEmployee.push({
          Id: 2,
          Name: translations['CompanyDataImport.ImportFromPlanDay']
        });

        this.importPayrollFromExternalSource = [];
        this.importPayrollFromExternalSource.push({ Id: 1, Name: translations['CompanyDataImport.AOFdatfile'] });

        this.importFromExternalSourcePayrollData = [];
        this.importFromExternalSourcePayrollData.push({ Id: 1, Name: translations['CompanyDataImport.AOFdatfile'] });
        this.importFromExternalSourcePayrollData.push({
          Id: 2,
          Name: translations['CompanyDataImport.ImportFromPlanDay']
        });
      });
  }

  private getLastDateOfPreviousMonth(): Date {
    const date: Date = new Date();
    const currentMonth: number = date.getMonth();
    const currentYear: number = date.getFullYear();
    return new Date(currentYear, currentMonth, 0);
  }

  public onBalanceDateDialogAction(action: string): void {
    if (action === 'Ok' || action === 'Upload') {
      this.downloadDocument();
    }
  }

  private showBalancesDatePopup(): void {
    this.balanceDateDialogVisible = true;
    this.balanceDate = this.getLastDateOfPreviousMonth();
  }

  private downloadDocument(): void {
    const date: string = this.getBalanceDate();
    const anotherDate: string = this.getBalanceDay();
    switch (this.importType) {
      case ImportTypesEnum.Employees:
        // Employees
        if (this.actionType === 5) {
          this.dataService
            .CompanyDataImport_GetEmployeeTemplateFilled()
            .subscribe((fileData: any) => this.onDownloadCompleted(fileData));
          break;
        }
        this.dataService
          .CompanyDataImport_GetEmployeeTemplate()
          .subscribe((fileData: any) => this.onDownloadCompleted(fileData));
        break;
      case ImportTypesEnum.Initial_Balances:
        // Balances
        if (this.actionType === 5) {
          this.dataService
            .CompanyDataImport_GetBalancesImportTemplate(date)
            .subscribe((fileData: any) => this.onDownloadCompleted(fileData));
          break;
        }
        this.dataService
          .CompanyDataImport_GetBalancesTemplate(date)
          .subscribe((fileData: any) => this.onDownloadCompleted(fileData));
        break;
      case ImportTypesEnum.Payroll:
        // Payroll data
        let idType: any;
        if (this.payrollOrTimeEntriesDataIdType) {
          idType = this.payrollOrTimeEntriesDataIdType
            ? this.payrollOrTimeEntriesDataIdTypesDatasource.find(
                (id: any) => id.Id === this.payrollOrTimeEntriesDataIdType
              )
            : undefined;
        }

        if (idType && idType.Name) {
          this.dataService
            .CompanyDataImport_GetPayrollImportTemplate(idType.Name)
            .subscribe((fileData: any) => this.onDownloadCompleted(fileData));
        }

        break;
      case ImportTypesEnum.TimeEntries:
        // Payroll data
        let timeEdType: any;
        if (this.payrollOrTimeEntriesDataIdType) {
          timeEdType = this.payrollOrTimeEntriesDataIdType
            ? this.payrollOrTimeEntriesDataIdTypesDatasource.find(
                (id: any) => id.Id === this.payrollOrTimeEntriesDataIdType
              )
            : undefined;
        }

        if (timeEdType && timeEdType.Name) {
          this.dataService
            .CompanyDataImport_GetTimeEntriesImportTemplate(timeEdType.Name)
            .subscribe((fileData: any) => this.onDownloadCompleted(fileData));
        }

        break;
    }
  }

  private onDownloadCompleted(promiseValue: any): void {
    if (this.sessionService.browser.isMobileSafari) {
      this.alertType = 'SafariWarning';
      this.alertDialogVisible = true;
    }

    this.downloadService.download('Gratisal.XLSX', promiseValue);
  }

  public onFileSelected(file: File): void {
    const maxSize: number = 1024 * 1024 * 64;
    if (file.size > maxSize) {
      this.alertType = 'FileSizeError';
      this.alertDialogVisible = true;
      return;
    }

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    this.uploadImportedFile(formData);
  }

  private uploadImportedFile(formData: FormData): void {
    if (this.actionType === ActionTypesEnum.Convert_From_Other_System) {
      if (this.importType === ImportTypesEnum.Payroll) {
        this.postDataImportFromDatFile(formData);
      } else {
        this.postDataImportFromDatFile(formData);
        //this.postEmployeeFileFromExternalSource(formData);
      }
    }
  }

  private postDataImportFromDatFile(formData: FormData): void {
    let operation: string = ImportOptionHelper.getImportTypeKey(this.importType);
    if (operation === 'ImportSalaryRecords') {
      if (this.importPayrollFromExternalSourceValue === 2) {
        operation = 'PayrollData';
        this.dataService
          .CompanyDataImport_PlanDayToExcel(operation, formData)
          .subscribe((fileData: string) => this.onDownloadCompleted(fileData));
      } else {
        operation = 'SalaryRecords';
        this.dataService
          .CompanyDataImport_DatFileToExcel(operation, formData)
          .subscribe((fileData: string) => this.onDownloadCompleted(fileData));
      }
    } else if (operation === 'ImportTimeEntries') {
      operation = 'TimeEntries';
      this.dataService
        .CompanyDataImport_DatFileToExcel(operation, formData)
        .subscribe((fileData: string) => this.onDownloadCompleted(fileData));
    } else {
      if (this.importFromExternalSourceValue === 2) {
        this.dataService
          .CompanyDataImport_PlanDayToExcel(operation, formData)
          .subscribe((fileData: string) => this.onDownloadCompleted(fileData));
      } else {
        this.dataService
          .CompanyDataImport_DanlonPdfToExcel(operation, formData)
          .subscribe((fileData: string) => this.onDownloadCompleted(fileData));
      }
    }
  }

  private postEmployeeFileFromExternalSource(formData: FormData): void {
    const operation: string = ImportOptionHelper.getImportTypeKey(this.importType);
    this.dataService.CompanyDataImport_DanlonPdfToExcel(operation, formData).subscribe((fileData: string) => {
      this.onDownloadCompleted(fileData);
      if (this.importType === ImportTypesEnum.Employees) {
        this.staticDataService.loadDepartments();
        this.staticDataService.loadCompanyUsers();
        this.alertType = 'DanlonPDFSuccess';
        this.alertDialogVisible = true;
      } else if (this.importType === ImportTypesEnum.Initial_Balances) {
        this.alertType = 'BalancesDanlonPDFSuccess';
        this.alertDialogVisible = true;
      }
    });
  }

  private getBalanceDate(): string {
    if (!this.balanceDate) {
      return undefined;
    }
    const dateTemp: Date = new Date(this.balanceDate);

    return (
      dateTemp.getFullYear().toString() +
      this.sessionService.toString(dateTemp.getMonth() + 1, '00') +
      this.sessionService.toString(dateTemp.getDate(), '00')
    );
  }

  protected getBalanceDay(): string {
    if (!this.balanceDate) {
      return undefined;
    }
    const dateTemp: Date = new Date(this.balanceDate);

    return (
      this.sessionService.toString(dateTemp.getDate(), '00') +
      '-' +
      this.sessionService.toString(dateTemp.getMonth() + 1, '00') +
      '-' +
      dateTemp.getFullYear().toString()
    );
  }

  public onCHeckBoxImportTypeChange(): void {
    // if (this.importType === 5 && this.actionType === 4) {
    //     this.actionType = 1;
    // }
    this.actionType = 1;
    this.importFromExternalSourceValue = 1;
    this.importPayrollFromExternalSourceValue = 1;
  }
}
