<div class="Dropdown-container">
  <action-button
    buttonClass="Action-button Action-buttonHelp"
    [icon]="'Help'"
    label="Help.Title"
    (click)="onToggleHelpDropdown($event)"
    tooltip="Help.Title"
  ></action-button>

  <div
    class="Dropdown Dropdown--bottom helpDropDownDialog{{ module }}"
    [ngClass]="{ 'is-active': sessionService.showHelpMenuToggled }"
  >
    <ul role="menu">
      <li class="Dropdown-item" *ngIf="!isIOSMobileApp">
        <a [href]="helpLink" target="_blank">{{ 'Help.ViewOnlineHelp' | translate }}</a>
      </li>
      <li class="Dropdown-item" *ngIf="!isIOSMobileApp && LanguageId == 1">
        <a *ngIf="!isGreenlandCompany" href="http://www.gratisal.dk/da/kontakt/" target="_blank">
          {{ 'Help.ContactGratisal' | translate }}
        </a>
        <a *ngIf="isGreenlandCompany" href="https://gratisal.gl/" target="_blank">
          {{ 'Help.ContactGratisal' | translate }}
        </a>
      </li>
      <li class="Dropdown-item" *ngIf="!isIOSMobileApp && LanguageId == 2">
        <a *ngIf="!isGreenlandCompany" href="https://www.gratisal.dk/en/contact/" target="_blank">
          {{ 'Help.ContactGratisal' | translate }}
        </a>
        <a *ngIf="isGreenlandCompany" href="https://gratisal.gl/" target="_blank">
          {{ 'Help.ContactGratisal' | translate }}
        </a>
      </li>
      <li class="Dropdown-item" *ngIf="!isIOSMobileApp && LanguageId == 3">
        <a *ngIf="!isGreenlandCompany" href="http://www.gratisal.dk/da/kontakt/" target="_blank">
          {{ 'Help.ContactGratisal' | translate }}
        </a>
        <a *ngIf="isGreenlandCompany" href="https://gratisal.gl/" target="_blank">
          {{ 'Help.ContactGratisal' | translate }}
        </a>
      </li>
      <li class="Dropdown-item">
        <button *ngIf="welcomeMessage" type="button" (click)="onShowWelcomeMessage()">
          {{ 'Help.ShowWelcomeMessage' | translate }}
        </button>
      </li>
      <li class="Dropdown-item" *ngIf="!isIOSMobileApp">
        <a href="{{ 'Login.UpdatedGratisalMessageUrl' | translate }}" target="_blank">
          {{ 'Help.WhatNew' | translate }}
        </a>
      </li>
    </ul>
  </div>
</div>
<app-dialog [(visible)]="showdialog" [title]="welcomeMessageTitle" [leadingText]="'<p>' + welcomeMessage + '</p>'">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
