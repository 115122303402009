import { SessionService } from '../Services/SharedServices/Session/SessionService';
var AccountComponent = /** @class */ (function () {
    function AccountComponent(sessionService) {
        this.sessionService = sessionService;
    }
    Object.defineProperty(AccountComponent.prototype, "isCompanyPreferenceTabActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.account.companypreferences';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountComponent.prototype, "isUserPreferenceTabActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.account.userpreferences';
        },
        enumerable: true,
        configurable: true
    });
    AccountComponent.prototype.toggleMenuButton = function () {
        if (!this.sessionService.menuToggled) {
            this.sessionService.menuToggled = true;
        }
        else {
            this.sessionService.menuToggled = false;
        }
    };
    return AccountComponent;
}());
export { AccountComponent };
