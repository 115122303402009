import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import {
  Department,
  ICompanyUser,
  IDepartment,
  ISalaryCycle,
  IUserEmployment,
  IUserEmploymentTemplate
} from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'hiring-data',
  templateUrl: './HiringData.component.html'
})
export class HiringDataComponent extends FormComponentBase implements OnInit {
  @Input()
  get userEmployment(): IUserEmployment {
    if (!this.userEmploymentValue) {
      this.userEmploymentValue = {} as any;
    }

    return this.userEmploymentValue;
  }

  set userEmployment(value: IUserEmployment) {
    if (this.userEmploymentValue !== value) {
      this.userEmploymentValue = value;
      this.loadEmploymentTemplates();
      this.hasEmployment = value && value.Id ? true : false;
    }
  }

  public hasDepartmentEnabled = false;
  public managers: ICompanyUser[];
  public departments: IDepartment[];
  public cycles: ISalaryCycle[];
  public templates: IUserEmploymentTemplate[];
  public hasEmployment: boolean;
  public AddDepartmentVisible = false;
  public ModelDepartment: IDepartment = new Department();
  public companyContacts: ICompanyUser[];

  private userEmploymentValue: IUserEmployment;

  constructor(
    private dataService: RxDataService,
    private sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {
    super();
    this.childIsValid = Array(10).fill(true);
    this.childIsDirty = Array(10).fill(false);
  }

  public get IsDisableCreateDepartment(): boolean {
    return this.sessionService.role.IsReadOnly || this.sessionService.role.IsSalaryAdmin;
  }

  public ngOnInit(): void {
    this.staticDataService.SalaryCycle.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalaryCycle[]) => (this.cycles = data)
    );
    this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IDepartment[]) => {
      this.departments = data;
    });
    this.loadCompanyUsers();
    this.getCompanyContacts();
  }

  public onCreateNewDepartment(event: any) {
    if (event && event === 'Create') {
      this.ModelDepartment.IsActive = true;
      this.dataService.Companies_CreateDepartment(this.ModelDepartment).subscribe((data: any): void => {
        this.staticDataService.loadDepartments();
        this.onCreateNewDepartmentSuccess(data.Id);
      });
    }

    this.ModelDepartment = new Department();
  }

  public onCreateNewDepartmentSuccess(newDepartmentId: number): void {
    this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IDepartment[]) => {
      this.departments = data;
      this.userEmployment.DepartmentId = newDepartmentId;
    });
  }

  get showDepartmentControl(): boolean {
    return this.departments && this.departments.length > 0;
  }

  get shouldHideTerminationDate(): boolean {
    return (
      !this.editMode &&
      (this.userEmployment && !this.userEmployment.ExpireDate) &&
      this.sessionService.role.IsEmployeeRole
    );
  }

  get showTemplates(): boolean {
    return this.sessionService.feature.ShowEmployeeEmploymentTemplate;
  }

  public onComboboxEmployeeDetailDepartmentSelectionChanged(): void {
    const departmentId: number = this.userEmployment.DepartmentId;
    this.selectDefaultManager(departmentId);
    this.onChange();
  }

  private getCompanyContacts(): void {
    this.staticDataService.companyUsers
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((companyUsers: ICompanyUser[]): void => {
        this.companyContacts = companyUsers;
      });
  }

  private loadCompanyUsers(): void {
    this.staticDataService.companyUsers
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: ICompanyUser[]) => (this.managers = data));
  }

  private loadEmploymentTemplates(): void {
    if (!this.showTemplates) {
      return;
    }
    this.dataService
      .EmploymentTemplates_GetEmploymentTemplates(true)
      .subscribe((templates: IUserEmploymentTemplate[]): void => {
        this.templates = templates;
      });
  }

  private selectDefaultManager(departmentId: number): void {
    let department: IDepartment;
    if (this.departments) {
      department = this.departments.find((dpm: IDepartment) => {
        return dpm.Id === departmentId;
      });
    }

    if (department) {
      this.userEmployment.ManagerCompanyUserId = department.ManagerCompanyUserId
        ? department.ManagerCompanyUserId
        : undefined;
    }
  }
}
