<div>
  <!-- Cycle -->
  <combobox-edit-control
    [label]="'CompanySalaryBatches.Cycle' | translate"
    [textField]="'Name'"
    [idField]="'Id'"
    [preventChange]="isPreparingContent"
    [editMode]="true"
    [disable]="IsExistingSalaryBatch"
    [comboboxDataSource]="salaryCycles"
    [(value)]="entityContext.CycleId"
    (valueChange)="onChildWindowSalaryCycleChange()"
  ></combobox-edit-control>
  <!-- Period -->
  <combobox-edit-control
    [label]="'CompanySalaryBatches.Period' | translate"
    [textField]="'FriendlyName'"
    [idField]="'Id'"
    [isConvertTime]="false"
    [editMode]="true"
    [disable]="IsExistingSalaryBatch"
    [comboboxDataSource]="entityContext.Periods"
    [preventChange]="isPreparingContent"
    [(value)]="entityContext.PeriodId"
    [showNavigationButton]="entityContext.BatchId == null || entityContext.BatchId < 1"
    [hasFeedback]="entityContext.HasBatch"
    [feedbackKey]="'CompanySalaryBatches.BatchExistedForPeriodWarning'"
    (valueChange)="onSalaryPeriodChanged()"
  ></combobox-edit-control>
  <!-- Payment date -->
  <date-picker-control
    class="PaymentDatePicker"
    [label]="'CompanySalaryBatches.Payment date' | translate"
    *ngIf="isShowPaymentDate"
    [editMode]="true"
    [required]="true"
    [requiredMessage]="'EmployeeGeneral.Required' | translate"
    [preventChange]="isPreparingContent"
    [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
    [disable]="IsReadOnly || !entityContext.IsDraft"
    [(value)]="entityContext.PaymentDate"
  ></date-picker-control>
  <fieldset class="FormElement" *ngIf="entityContext.BatchId === -1">
    <app-radio-edit
      [editMode]="true"
      [vertical]="true"
      [(value)]="entityContext.EmployeeCategory"
      (valueChange)="onEmployeeCategoryChanged($event)"
    >
      <app-radio-edit-option
        [value]="1"
        [label]="'CompanySalaryBatches.AllEmployees' | translate"
      ></app-radio-edit-option>
      <app-radio-edit-option
        [value]="2"
        [label]="'CompanySalaryBatches.PickEmployee' | translate"
      ></app-radio-edit-option>
      <app-radio-edit-option
        *ngIf="!IsGreenlandCompany"
        [value]="3"
        [label]="'CompanySalaryBatches.NoEmployee' | translate"
      ></app-radio-edit-option>
    </app-radio-edit>
  </fieldset>

  <!-- Message to employee -->
  <div class="FormElement">
    <label class="FormElement-label" for="Message">{{ 'CompanySalaryBatches.Message to employee' | translate }}</label>
    <div class="FormElement-control">
      <textarea
        class="FormElement-textarea MessageToEmployeesTextArea"
        id="Message"
        [disabled]="IsReadOnly || !entityContext.IsDraft"
        [(ngModel)]="entityContext.Message"
      ></textarea>
    </div>
  </div>

  <div class="FormElement" *ngIf="IsNewBatchWithEmployeeSelection">
    <div>
      <grid
        [data]="selectEmployeesGridData"
        [editMode]="true"
        (action)="onEmployeeGridAction($event)"
        class="u-gridHeight250"
        (saveChangesEvent)="onEmployeesGridDataSaveChanges($event)"
      >
        <grid-action [label]="'CompanySalaryBatches.SelectAll' | translate" [action]="'SelectAll'"></grid-action>
        <grid-action [label]="'CompanySalaryBatches.DeselectAll' | translate" [action]="'DeselectAll'"></grid-action>
        <gridColumn [title]="' '" [field]="'IsChecked'" [type]="'checkbox'" [editable]="true" [width]="40"></gridColumn>
        <gridColumn
          [editable]="false"
          [title]="'CompanySalaryBatches.Name' | translate"
          [field]="'FullName'"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [type]="'text'"
          [width]="150"
        ></gridColumn>
        <gridColumn
          [editable]="false"
          [title]="'CompanySalaryBatches.Department' | translate"
          [field]="'DepartmentName'"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [type]="'text'"
          [width]="150"
        ></gridColumn>
        <gridColumn
          [editable]="false"
          [title]="'CompanySalaryBatches.Title' | translate"
          [field]="'Title'"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [type]="'text'"
          [width]="150"
        ></gridColumn>
      </grid>
    </div>
  </div>

  <check-edit
    *ngIf="showMultiPurposeOffDayCompensation || IsGreenlandCompany"
    class="FormElement u-leader"
    [label]="PayoutAllFlexLabel | translate"
    [editMode]="true"
    [(value)]="entityContext.PayoutAllFlex"
    [disable]="IsReadOnly || !entityContext.IsDraft"
  ></check-edit>

  <div
    class="u-leader"
    *ngIf="
      salaryStatements &&
      salaryStatements.length > 0 &&
      (entityContext.IsPendingForApproval || entityContext.IsPreliminary)
    "
  >
    <div class="u-flex">
      {{ 'CompanySalaryBatches.RecalculateSingleEmployee' | translate }}:
      <app-icon
        class="u-helpColor"
        [icon]="'Info'"
        [tooltip]="'CompanySalaryBatches.RecalculateSingleEmployeeBrief' | translate"
      ></app-icon>
    </div>

    <div class="u-flex">
      <combobox-edit-control
        class="u-flexGrow padding-right"
        [editMode]="true"
        [comboboxDataSource]="salaryStatements"
        [textField]="'UserFullName'"
        [idField]="'UserEmploymentId'"
        [(value)]="recalculateSingleUserEmploymentId"
      ></combobox-edit-control>
      <button-component
        buttonClass="Button--card right"
        (click)="onRecalculateSingleEmployeeDone()"
        [label]="'CompanySalaryBatches.Recalculate' | translate"
      ></button-component>
    </div>
  </div>
</div>
