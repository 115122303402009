import { EventEmitter, OnInit } from '@angular/core';
import { Constants } from '../../../Common/Constants';
import { Global } from '../../../Common/Global';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { CompanySalaryBatchesChildFormEntityContext } from '../CompanySalaryBatchesChildFormEntityContext';
import { SalaryBatchViewModel } from '../SalaryBatchViewModel';
var SalaryBatchInfoComponent = /** @class */ (function () {
    function SalaryBatchInfoComponent(sessionService) {
        this.sessionService = sessionService;
        this.createPayrollBatch = new EventEmitter();
        this.salaryBatchAction = new EventEmitter();
        this.reloadSalaryBatches = new EventEmitter();
        this.closeDialog = new EventEmitter();
        this.visibleChange = new EventEmitter();
        this.selectedEmployeeIdsChange = new EventEmitter();
        this.salaryBatchContextChange = new EventEmitter();
    }
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "salaryBatch", {
        get: function () {
            return this.salaryBatchValue;
        },
        set: function (value) {
            if (this.salaryBatchValue !== value) {
                this.salaryBatchValue = value;
            }
            else if (!this.popup && !value) {
                this.salaryBatchValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "selectedEmployeeIds", {
        get: function () {
            return this.selectedEmployeeIdsValue;
        },
        set: function (value) {
            if (this.selectedEmployeeIdsValue !== value) {
                this.selectedEmployeeIdsValue = value;
                this.selectedEmployeeIdsChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "salaryBatchContext", {
        get: function () {
            return this.salaryBatchContextValue ? this.salaryBatchContextValue : {};
        },
        set: function (value) {
            if (this.salaryBatchContextValue !== value) {
                this.salaryBatchContextValue = value;
                this.salaryBatchContextChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchInfoComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchInfoComponent.prototype.onCreatePayrollBatchClicked = function () {
        this.createPayrollBatch.emit();
    };
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchInfoComponent.prototype.onDetailContextChange = function (value) {
        this.salaryBatchContext = value;
    };
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "isGreenland", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchInfoComponent.prototype.onSalaryBatchDialogAction = function (action) {
        this.salaryBatchAction.emit({ action: action, context: this.salaryBatchContext });
    };
    return SalaryBatchInfoComponent;
}());
export { SalaryBatchInfoComponent };
