/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../CustomControls/Grid/Grid.componnent.ngfactory";
import * as i2 from "../../../../CustomControls/Grid/Grid.componnent";
import * as i3 from "@angular/forms";
import * as i4 from "../../../../Services/SharedServices/Session/SessionService";
import * as i5 from "../../../../CustomControls/Grid/GridColumn.component.ngfactory";
import * as i6 from "../../../../CustomControls/Grid/GridColumn.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./CompanyTemplateList.component";
var styles_CompanyTemplateListComponent = [];
var RenderType_CompanyTemplateListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CompanyTemplateListComponent, data: {} });
export { RenderType_CompanyTemplateListComponent as RenderType_CompanyTemplateListComponent };
export function View_CompanyTemplateListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "FormElement"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "grid", [], [[8, "className", 0]], [[null, "isDisableSelectChange"], [null, "selectedIdChange"], [null, "triggerUpdateChange"], [null, "selectedItemChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isDisableSelectChange" === en)) {
        var pd_0 = ((_co.isEditMode = $event) !== false);
        ad = (pd_0 && ad);
    } if (("selectedIdChange" === en)) {
        var pd_1 = ((_co.selectedId = $event) !== false);
        ad = (pd_1 && ad);
    } if (("triggerUpdateChange" === en)) {
        var pd_2 = ((_co.triggerGridRefresh = $event) !== false);
        ad = (pd_2 && ad);
    } if (("selectedItemChange" === en)) {
        var pd_3 = (_co.onSelectionChange($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_CustomGridComponent_0, i1.RenderType_CustomGridComponent)), i0.ɵdid(2, 4243456, null, 2, i2.CustomGridComponent, [i3.FormBuilder, i0.ChangeDetectorRef, i4.SessionService], { editMode: [0, "editMode"], disabled: [1, "disabled"], idColumn: [2, "idColumn"], companayTemplates: [3, "companayTemplates"], isDisableSelect: [4, "isDisableSelect"], selectedId: [5, "selectedId"], data: [6, "data"], triggerUpdate: [7, "triggerUpdate"] }, { selectedIdChange: "selectedIdChange", selectedItemChange: "selectedItemChange", triggerUpdateChange: "triggerUpdateChange" }), i0.ɵqud(603979776, 1, { columns: 1 }), i0.ɵqud(603979776, 2, { actions: 1 }), (_l()(), i0.ɵeld(5, 0, null, null, 3, "gridColumn", [], null, null, null, i5.View_GridColumnComponent_0, i5.RenderType_GridColumnComponent)), i0.ɵdid(6, 49152, [[3, 4], [1, 4]], 1, i6.GridColumnComponent, [], { title: [0, "title"], field: [1, "field"], classField: [2, "classField"], type: [3, "type"] }, null), i0.ɵqud(603979776, 3, { columns: 1 }), i0.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = false; var currVal_2 = _co.locked; var currVal_3 = "Id"; var currVal_4 = true; var currVal_5 = _co.isEditMode; var currVal_6 = _co.selectedId; var currVal_7 = _co.templates; var currVal_8 = _co.triggerGridRefresh; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 8).transform("CompanyHiringStatus.TemplateGridTitle")); var currVal_10 = "Name"; var currVal_11 = "isActiveFeild"; var currVal_12 = "text"; _ck(_v, 6, 0, currVal_9, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "FormElement-grid Company-templateGrid ", _co.classDisableSelect, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_CompanyTemplateListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "company-template-list", [], null, null, null, View_CompanyTemplateListComponent_0, RenderType_CompanyTemplateListComponent)), i0.ɵdid(1, 49152, null, 0, i8.CompanyTemplateListComponent, [], null, null)], null, null); }
var CompanyTemplateListComponentNgFactory = i0.ɵccf("company-template-list", i8.CompanyTemplateListComponent, View_CompanyTemplateListComponent_Host_0, { templates: "templates", locked: "locked", isEditMode: "isEditMode", selectedId: "selectedId", refreshList: "refreshList" }, { selectedIdChange: "selectedIdChange", selectionChange: "selectionChange" }, []);
export { CompanyTemplateListComponentNgFactory as CompanyTemplateListComponentNgFactory };
