import { EventEmitter, OnInit } from '@angular/core';
import { DefaultAvatar } from '../../../Model/DefaultAvatar';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
var EmployeeAvatarComponent = /** @class */ (function () {
    function EmployeeAvatarComponent(dataService, sessionService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.avatarChange = false;
        this.isAvatarChanged = new EventEmitter();
    }
    Object.defineProperty(EmployeeAvatarComponent.prototype, "companyUserId", {
        get: function () {
            return this.userId;
        },
        set: function (value) {
            if (!this.userId && !value) {
                return;
            }
            if (this.userId !== value) {
                this.setNewUserAvatar(value);
            }
            this.userId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeAvatarComponent.prototype, "noAvatar", {
        get: function () {
            return this.isNoAvatar;
        },
        set: function (value) {
            this.isNoAvatar = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeAvatarComponent.prototype, "maleAvatar", {
        get: function () {
            return DefaultAvatar.imgMaleAvatar;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeAvatarComponent.prototype, "femaleAvatar", {
        get: function () {
            return DefaultAvatar.imgFemaleAvatar;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeAvatarComponent.prototype, "userAvatar", {
        get: function () {
            return this.avatar;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeAvatarComponent.prototype, "isIOSMobileApp", {
        get: function () {
            return this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeAvatarComponent.prototype.ngOnInit = function () {
        this.DefaultAvatar();
    };
    EmployeeAvatarComponent.prototype.DefaultAvatar = function () {
        if (this.isNoAvatar && this.isFemale) {
            this.avatar = DefaultAvatar.imgFemaleAvatar;
        }
        if (this.isNoAvatar && !this.isFemale) {
            this.avatar = DefaultAvatar.imgMaleAvatar;
        }
    };
    EmployeeAvatarComponent.prototype.setNewUserAvatar = function (userId) {
        var _this = this;
        if (!userId) {
            this.isNoAvatar = true;
            this.avatar = '';
            return;
        }
        this.dataService.Users_GetUserImage(userId).subscribe(function (data) {
            if (data && data.length > 0) {
                _this.avatar = data;
                _this.isNoAvatar = false;
            }
            else {
                _this.avatar = '';
                _this.isNoAvatar = true;
            }
            _this.isAvatarChanged.emit(true);
        });
    };
    EmployeeAvatarComponent.prototype.onAvatarChanged = function (formData) {
        var _this = this;
        if (this.sessionService.browser.isHybridApp) {
        }
        if (Object.keys(formData).length !== 0) {
            return;
        }
        this.dataService.User_UploadEmployeeImage(formData, this.userId).subscribe(function (data) {
            _this.avatar = data;
        }, function (error) { return _this.getCurrentAvatar(); });
    };
    EmployeeAvatarComponent.prototype.getCurrentAvatar = function () {
        var _this = this;
        this.dataService.Companies_GetCompanyLogo().subscribe(function (logo) {
            _this.avatar = logo;
        });
    };
    return EmployeeAvatarComponent;
}());
export { EmployeeAvatarComponent };
