import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AddEvent,
  CellClickEvent,
  GridComponent,
  GridDataResult,
  PageChangeEvent,
  PagerSettings,
  RemoveEvent,
  RowClassArgs,
  SelectionEvent
} from '@progress/kendo-angular-grid';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Global } from '../../Common/Global';
import { ListHelper } from '../../Common/ListHelper';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { GridCellClickEvent } from './CellClickEvent';
import { GridActionComponent } from './GridAction.component';
import { GridColumnComponent } from './GridColumn.component';
import { GridNavigationService } from './GridNavigation.service';

@Component({
  selector: 'grid',
  templateUrl: './Grid.component.html',
  styles: ['.disabled { cursor: not-allowed; pointer-events: none; }'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomGridComponent implements AfterViewInit {
  @Input() public editMode = true;
  @Input() public addNew: boolean;
  @Input() public addNewText: string;
  @Input() public addNewTooltips = '';
  @Input() public addNewDisable: boolean;
  @Input() public deleteRow: boolean;
  @Input() public deleteRowField: string;
  @Input() public customDeleteRow = false;
  @Input() public disabled: boolean;
  @Input() public idColumn: string;
  @Input() public defaultNewValue: any;
  @Input() public companayTemplates = false;
  @Input() public isDisableSelect = false;
  @Input() public filterable: any;
  @Input() public filterState: any;
  @Input() public filter: any;
  @Input() public format: any;
  @Input() public isEmployeeTime = false;
  @Input() public showButDisableAddNewButton = false;
  @Input() public disableDeleteButton = false;
  @Input() public DeleteTooltips = '';
  @Input() public height: number;
  @Input() public columnIndexFocusAfterCreate = 0;
  @Input() public selectedRows: any[] = [];

  // Attr PageList
  @Input() public pageSize = 10;
  @Input() public skip = 0;
  @Input() public pageable = false;
  @Input() public hiddenCount = false;
  @Input() public buttonCount = 0;

  public get pageListSetting(): PagerSettings | boolean {
    if (this.buttonCount) {
      return { buttonCount: this.buttonCount, info: !this.hiddenCount };
    }
    return this.pageable;
  }

  @Input() public isReportDialog = false;

  private selectedIdValue: any;
  @Input()
  public get selectedId(): any {
    return this.selectedIdValue;
  }
  public set selectedId(value: any) {
    if (
      (this.selectedIdValue !== value || this.selectedRows.length === 0 || (!this.selectedIdValue && !value)) &&
      !this.isReportDialog
    ) {
      this.selectedIdValue = value;
      this.selectedIdChange.emit(value);
      this.selectedRows = [value];
      if (
        this.data &&
        this.idColumn &&
        (!this.selectedItem || this.selectedItem[this.idColumn] !== this.selectedIdValue)
      ) {
        const selected: any = this.data.find((e: any) => e[this.idColumn] === this.selectedIdValue);
        if (this.selectedItem !== selected) {
          this.selectedItem = selected;
        }
      }
    }
  }
  @Output() public selectedIdChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public skipChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public disableButtonAddNewClickEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public updateDefaultRecordEvent: EventEmitter<any> = new EventEmitter<any>();

  private selectedItemValue: any;
  @Input()
  public get selectedItem(): any {
    return this.selectedItemValue;
  }
  public set selectedItem(value: any) {
    const previousValue: any = this.selectedItemValue;
    this.selectedItemValue = value;
    if (this.idColumn) {
      this.selectedId = value ? value[this.idColumn] : undefined;
    }

    if (this.selectedItemValue !== previousValue) {
      this.selectedItemChange.emit(value);
    }
  }
  @Output() public selectedItemChange: EventEmitter<any> = new EventEmitter<any>();

  public gridData: GridDataResult;
  private originalData: any[];
  @Input()
  public get data(): any[] {
    return this.originalData;
  }
  public set data(value: any[]) {
    if (this.originalData !== value) {
      this.originalData = value || [];
      if (this.originalData.length > 0) {
        this.originalData = ListHelper.createObserveArray(
          this.originalData,
          () => this.triggerComponentChanged(),
          () => this.triggerComponentChanged()
        );
        this.dataChange.emit(this.originalData);
      }

      if (
        !this.selectedItem ||
        !this.originalData ||
        this.originalData.length === 0 ||
        (this.idColumn &&
          !this.originalData.find((item: any) => item[this.idColumn] === this.selectedItem[this.idColumn]))
      ) {
        if (!this.selectedId) {
          this.selectedRows = [];
        }
      }

      this.loadItems();
    }
  }

  private loadItems(): void {
    if (this.pageable) {
      this.gridData = {
        data: this.originalData.slice(this.skip, this.skip + this.pageSize),
        total: this.originalData.length
      };
    } else {
      this.gridData = {
        data: this.originalData,
        total: this.originalData.length
      };
    }
  }

  @Output() public dataChange: EventEmitter<any> = new EventEmitter<any>();

  private triggerUpdateValue: boolean;
  @Input()
  public get triggerUpdate(): boolean {
    return this.triggerUpdateValue;
  }
  public set triggerUpdate(value: boolean) {
    if (value) {
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.triggerUpdateValue = false;
        this.triggerUpdateChange.emit(this.triggerUpdateValue);
      });
    }
  }
  @Output() triggerUpdateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public addNewEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public removeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public cellCloseEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public saveChangesEvent: EventEmitter<CellCloseEvent> = new EventEmitter<CellCloseEvent>();
  @Output() public action: EventEmitter<string> = new EventEmitter<string>();
  @Output() public cellClick: EventEmitter<GridCellClickEvent> = new EventEmitter<GridCellClickEvent>();
  @Output() public gridButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public clickAppGridIcon: EventEmitter<any> = new EventEmitter<any>();
  @Output() public controlDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public dataStateChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public filterChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public ButtonPopUpClick: EventEmitter<any> = new EventEmitter<any>();
  //@Output() public addonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public dblClick: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('kendoGridComponent', { static: true }) public kendoGrid: GridComponent;
  @ContentChildren(GridColumnComponent) public columns: QueryList<GridColumnComponent>;
  @ContentChildren(GridActionComponent) public actions: QueryList<GridActionComponent>;

  private selectedCell: { field: string; rowIndex: number; columnIndex: number };
  private navigationService: GridNavigationService = new GridNavigationService();
  private isDataChanged = false;
  private formGroup: any;

  constructor(
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    public sessionService: SessionService
  ) {}

  public get isAppLoading(): boolean {
    return this.sessionService.isGetting;
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.skipChange.emit(this.skip);
    this.loadItems();
  }

  private triggerComponentChanged(): void {
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public trackIndex(index: number): any {
    return index;
  }

  public rowCallback(context: RowClassArgs): any {
    return { highlightedRow: context.dataItem && context.dataItem.HightlightedRow };
  }

  public cellClass(column: GridColumnComponent): string {
    const res: string = `${column.class} ` + (!column.editableField && !column.editable ? 'grid-disable-cell' : '');
    return res;
  }

  public templateClass(column: GridColumnComponent, dataItem: any): string {
    return column && dataItem && column.classField ? dataItem[column.classField] : undefined;
  }

  public get isSelected(): boolean {
    if (this.isDisableSelect) {
      return false;
    }
    return true;
  }

  public cellClickHandler(args: CellClickEvent): any {
    // this.selectedItemChange.emit(args.dataItem);
    this.selectedCell = { field: args.column.field, rowIndex: args.rowIndex, columnIndex: args.columnIndex };
    const isEdited: boolean = args.isEdited;
    if (this.editMode && !isEdited) {
      args.sender.editCell(args.rowIndex, args.columnIndex, this.createFormGroup(args.dataItem));
      this.isDataChanged = false;
      setTimeout(() => {
        const element = document.querySelector('.k-state-focused input') as HTMLElement;
        if (element) {
          element.focus();
        }
      });
    }

    this.cellClick.emit({ field: args.column.field, dataItem: args.dataItem });
  }

  public onSelectionChange(args: SelectionEvent): void {
    if (args && args.selectedRows && args.selectedRows.length > 0) {
      this.selectedItem = args.selectedRows[0].dataItem;
    }
  }

  public onDbClickChange(args: SelectionEvent): void {
    if (this.kendoGrid && this.kendoGrid.activeRow && this.kendoGrid.activeRow.dataItem) {
      this.dblClick.emit(this.kendoGrid.activeRow.dataItem);
    }
  }

  public addHandler(args: AddEvent): void {
    this.addNewDisable = true;
    if (this.defaultNewValue) {
      this.updateDefaultRecordEvent.emit();
      setTimeout(() => {
        this.addHandlerFunction(args);
      });
    } else {
      this.addHandlerFunction(args);
    }
  }

  public addHandlerFunction(args: AddEvent): void {
    let dataItem: any = {};
    if (this.defaultNewValue) {
      dataItem = this.defaultNewValue;
    } else {
      if (this.columns && this.columns.length > 0) {
        this.columns.forEach((column: GridColumnComponent) => {
          if (column.field) {
            dataItem[column.field] = undefined;
          }
        });
      }
    }

    if (!this.data) {
      this.data = [];
    }

    if (this.isEmployeeTime) {
      this.data.unshift(dataItem);
    } else {
      this.data.push(dataItem);
    }

    this.selectedItem = dataItem;
    this.addNewEvent.emit(dataItem);
    const formGroup: any = this.createFormGroup(dataItem);

    if (this.isEmployeeTime) {
      this.selectedCell = {
        field: this.columns ? this.columns.toArray()[this.columnIndexFocusAfterCreate].field : '',
        rowIndex: 0,
        columnIndex: this.columnIndexFocusAfterCreate
      };
      args.sender.editCell(0, this.columnIndexFocusAfterCreate, formGroup);
    } else {
      this.selectedCell = {
        field: this.columns ? this.columns.toArray()[0].field : '',
        rowIndex: this.data.length - 1,
        columnIndex: this.columnIndexFocusAfterCreate
      };
      args.sender.editCell(this.data.length - 1, this.columnIndexFocusAfterCreate, formGroup);
    }
    //args.sender.editCell(0, 0, formGroup);
    this.isDataChanged = false;
    this.addNewDisable = false;
  }

  private checkModelRecord(record: any) {
    Object.keys(record).forEach((key) => {
      if (record[key] === undefined) {
        record[key] = null;
      }
    });

    return record;
  }

  public cellCloseHandler(args: CellCloseEvent): void {
    setTimeout(() => {
      const dataChanged: boolean = this.isDataChanged;
      if (this.isColumnDataChanged(args) || dataChanged) {
        this.checkModelRecord(args.dataItem);
        this.saveChangesEvent.emit(args);
        this.triggerComponentChanged();
        this.isDataChanged = false;
      }

      this.cellCloseEvent.emit(args);
    });
  }

  private isColumnDataChanged(args: any): boolean {
    let dataChanged: boolean;
    const { column, dataItem, formGroup } = args;
    if (dataItem && formGroup && formGroup.value && column && column.field) {
      dataChanged =
        ((dataItem[column.field] === null || dataItem[column.field] === undefined) &&
          (formGroup.value[column.field] !== null && formGroup.value[column.field] !== undefined)) ||
        (dataItem[column.field] !== null &&
          dataItem[column.field] !== undefined &&
          (!formGroup.value.hasOwnProperty(column.field) ||
            (formGroup.value[column.field] === null || formGroup.value[column.field] === undefined))) ||
        (dataItem[column.field] !== null &&
          dataItem[column.field] !== undefined &&
          (formGroup.value[column.field] !== null && formGroup.value[column.field] !== undefined) &&
          dataItem[column.field] !== formGroup.value[column.field]);
    }

    return dataChanged;
  }

  public removeHandler(args: RemoveEvent): void {
    if (!this.isAppLoading) {
      if (!this.customDeleteRow) {
        // this.selectedItemChange.emit(args.dataItem);
        const indexOfRemovedItem: number = this.data.indexOf(args.dataItem);
        this.data.splice(indexOfRemovedItem, 1);
        args.sender.closeRow(args.rowIndex);
        this.removeEvent.emit(args.dataItem);
      } else {
        this.removeEvent.emit(args);
      }

      this.triggerComponentChanged();
    }
  }

  public onKeyDown(event: any): void {
    if (
      (event.key === 'Tab' || event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Escape') &&
      this.selectedCell
    ) {
      const cell: any = this.navigationService.getNextEditableCell(
        this.getDisplayedColumns(),
        this.data,
        this.selectedCell.columnIndex,
        this.selectedCell.rowIndex,
        event
      );
      if (event.key === 'Escape') {
        setTimeout(() => {
          this.editCell(this.selectedCell.rowIndex, this.selectedCell.columnIndex, true);
          this.kendoGrid.closeCell();
        });
      } else if (cell) {
        this.kendoGrid.focusCell(cell.rowIndex + 1, cell.columnIndex);
        this.editCell(cell.rowIndex, cell.columnIndex);

        setTimeout(() => {
          const element = document.querySelector('.k-state-focused input') as HTMLElement;
          if (element) {
            element.focus();
          }
        });

        this.createFormGroup(this.data ? this.data[cell.rowIndex] : undefined);
      }

      event.preventDefault();
      event.stopPropagation();
    }
    if (event.key === 'Enter' && this.selectedCell) {
      this.isDataChanged = true;
    }
  }

  public onDataChanged(dataItem: any, field: any, editableField?: any, isAllowNullValue: boolean = true): void {
    const args: any = {
      column: { field },
      dataItem,
      formGroup: this.formGroup
    };

    if (
      this.controlDataChange.observers &&
      this.controlDataChange.observers.length > 0 &&
      this.isColumnDataChanged(args)
    ) {
      this.isDataChanged = true;
      if (isAllowNullValue) {
        this.formGroup.value[field] = dataItem[field];
      } else {
        if (dataItem[field]) {
          this.formGroup.value[field] = dataItem[field];
        } else {
          dataItem[field] = this.formGroup.value[field];
        }
      }

      this.controlDataChange.emit({ dataItem, field, editableField });
    }
  }

  public onCheckBoxCheckedChanged(dataItem: any, field: string, isIgnoreCheckData: boolean = false): void {
    const event: CellCloseEvent = new CellCloseEvent({ dataItem });
    const args: any = {
      column: { field },
      dataItem,
      formGroup: this.formGroup
    };

    if (this.isColumnDataChanged(args) || isIgnoreCheckData) {
      this.createFormGroup(dataItem);
      this.checkModelRecord(dataItem);
      this.saveChangesEvent.emit(event);
      this.triggerComponentChanged();
    }
  }

  private getDisplayedColumns(): GridColumnComponent[] {
    const displayedColumns: GridColumnComponent[] = [];
    const arrColumns: GridColumnComponent[] = this.columns.toArray();
    for (let index = 0; index < arrColumns.length; index++) {
      const column: GridColumnComponent = arrColumns[index];
      if (!column.isGroupColumn) {
        displayedColumns.push(column);
      } else {
        const arrSubColumns: GridColumnComponent[] = column.columns.toArray();
        for (let subIndex = 0; subIndex < arrSubColumns.length; subIndex++) {
          const subColumn: GridColumnComponent = arrSubColumns[subIndex];
          if (!subColumn.isGroupColumn) {
            displayedColumns.push(subColumn);
          }
        }
      }
    }

    return displayedColumns;
  }

  private createFormGroup(dataItem: any): FormGroup {
    this.formGroup = this.formBuilder.group(dataItem);
    return this.formGroup;
  }

  private editCell(rowIndex: number, columnIndex: number, isIgnore: boolean = false): void {
    if (!isIgnore && this.selectedCell) {
      this.selectedCell.rowIndex = rowIndex;
      this.selectedCell.columnIndex = columnIndex;
    }

    const dataItem: any = this.data[rowIndex];
    this.selectedItem = dataItem;
    this.kendoGrid.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    if (!this.selectedItem || !this.selectedItem['EntryDate'] || !this.selectedItem['TimeEntryTypeId']) {
      this.isDataChanged = false;
    }
  }

  public getParamSubProberty(dataItem: any, subproperty: string): string {
    if (subproperty) {
      const arraySubproperty: string[] = subproperty.split('.');
      let object: any = dataItem;
      for (let i = 0; i < arraySubproperty.length; ++i) {
        object = object[arraySubproperty[i]];
      }

      return object;
    }

    return '';
  }

  public appIconClick(dataItem: any, iconAction: string): void {
    this.clickAppGridIcon.emit({ dataItem, iconAction });
    this.triggerComponentChanged();
  }

  public onGridButtonClickEvent(dataItem: any, buttonAction: string): void {
    this.gridButtonClick.emit({ dataItem, buttonAction });
    this.triggerComponentChanged();
  }

  public onIncellPopUpClickAction(dataItem: any, field: string, args: any): void {
    const buttonAction: string = args.action;
    this.gridButtonClick.emit({ dataItem, buttonAction, field });
    // dataItem[displayField] = false;
    this.triggerComponentChanged();
  }

  public onDataStateHandler(event: any): void {
    this.dataStateChange.emit(event);
  }

  public sort: SortDescriptor[] = [];

  public onSortChange(sort: SortDescriptor[]): void {
    if (this.data && this.data.length > 0) {
      this.sort = sort;
      const gridView: any = {
        data: orderBy(this.data, this.sort),
        total: this.data.length
      };
      this.data = gridView.data;
    }
  }

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filterChange.emit(filter);
  }

  public ngAfterViewInit(): void {}

  public show = false;

  public onToggle(): void {
    this.show = !this.show;
  }

  public AddNewDisableClick(): void {
    if (this.addNewDisable) {
      //this.disableButtonAddNewClickEvent.emit();
    }
  }

  public onGridToolbarClick(event: any): void {
    this.action.emit(event);
  }
}
