import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { ICompany, ICompanyUser } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';

@Component({
  selector: 'app-company-contact',
  templateUrl: 'CompanyContact.component.html'
})
export class CompanyContactComponent extends FormComponentBase implements OnInit {
  @Input() public company: ICompany;
  @Input() public contacts: ICompanyUser[];
  @Input()
  public set resetChildFrom(value: boolean) {
    if (value) {
      this.childIsDirty.fill(false);
      this.childIsValid.fill(true);
    }
    this.resetChildFromChange.emit(false);
  }

  @Output() public resetChildFromChange: EventEmitter<any> = new EventEmitter<any>();

  public logo: string;

  constructor(private dataService: RxDataService) {
    super();
    this.childIsDirty = Array(7).fill(false);
    this.childIsValid = Array(3).fill(true);
  }

  public ngOnInit(): void {
    this.getCurrentCompanyLogo();
  }

  public onCompanyLogoChanged(formData: any): void {
    this.dataService.Companies_UploadCompanyLogo(formData).subscribe(
      (data: any): void => {
        this.logo = data;
      },
      (error: any): void => this.getCurrentCompanyLogo()
    );
  }

  private getCurrentCompanyLogo(): void {
    this.dataService.Companies_GetCompanyLogo().subscribe((logo: string): void => {
      this.logo = logo;
    });
  }
}
