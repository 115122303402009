import { Injectable } from '@angular/core';
import { HookResult, StateService, Transition, TransitionService } from '@uirouter/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RouterStateService {
  private defaultStates: { [index: string]: string } = {};
  private redirects: { [index: string]: string } = {};
  private currentSubStates: { [index: string]: string } = {};
  private currentState: string;

  constructor(private transitionService: TransitionService, private stateService: StateService) {
    this.transitionService.onBefore(
      {},
      (transition: Transition): HookResult => {
        const toState: string = transition.to().name;

        // Should be removed when supported by all tabs
        if (
          toState.startsWith('tabs.accountant') ||
          toState.startsWith('tabs.company.approval') ||
          toState.startsWith('tabs.company.configuration') ||
          toState.startsWith('tabs.selfservice')
        ) {
          const redirectState: string =
            this.redirects[toState] || this.currentSubStates[toState] || this.defaultStates[toState];

          if (redirectState) {
            this.currentSubStates[toState] = redirectState;
            if (!environment.production) {
              console.log('RouterStateService onBefore - redirect to:', redirectState);
            }

            return transition.router.stateService.target(redirectState);
          }
        }

        return true;
      }
    );

    this.transitionService.onSuccess(
      {},
      (transition: Transition): HookResult => {
        const toState: string = transition.to().name;
        const redirectState: string = this.redirects[toState];
        if (redirectState) {
          if (!environment.production) {
            console.log('RouterStateService onSuccess - redirect to:', redirectState);
          }

          transition.router.stateService.go(redirectState);
        } else {
          const parentState: string = this.getParentState(toState);
          if (parentState) {
            this.currentSubStates[parentState] = toState;
          }

          this.currentState = toState;
        }
      }
    );
  }

  public registerDefault(state: string): void {
    const parentState: string = this.getParentState(state);
    if (parentState) {
      this.defaultStates[parentState] = state;
    }
  }

  public registerRedirect(fromState: string, toState: string): void {
    this.redirects[fromState] = toState;
    if (this.currentStateIs(fromState)) {
      this.stateService.go(toState);
    }
  }

  public clearRedirect(fromState: string): void {
    this.redirects[fromState] = undefined;
  }

  public currentStateIs(state: string | string[]): boolean {
    if (typeof state === 'string') {
      return this.currentState === state;
    } else {
      return state.includes(this.currentState);
    }
  }

  public parentStateIs(state: string): boolean {
    return this.getParentState(this.currentState) === state;
  }

  public currentStateStartsWith(state: string): boolean {
    return this.currentState.startsWith(state);
  }

  public navigateToState(state: string): void {
    this.stateService.go(state);
  }

  private getParentState(state: string): string {
    const stateParts: string[] = state.split('.');
    if (stateParts.length < 3 || stateParts[0] !== 'tabs') {
      return '';
    } else {
      stateParts.pop();
      return stateParts.join('.');
    }
  }
}
