import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'app-test-email',
  templateUrl: './TestEmail.component.html'
})
export class TestEmailComponent {
  public visibleValue = false;
  @Input()
  get visible(): boolean {
    return this.visibleValue;
  }
  set visible(value: boolean) {
    this.visibleValue = value;
    this.visibleChange.emit(value);
  }

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public emails: string;
  public errorDialogVisible = false;
  public failedMessage: string;

  constructor(private dataService: RxDataService, public sessionService: SessionService) {}

  public dialogAction(action: string): void {
    if (action === 'Test') {
      const parameters: string = this.emails.replace(/\r?\n/g, ';');
      this.dataService.Backend_NotifyAdminsOfFailedEmails(parameters).subscribe((responses: string[]) => {
        if (responses && responses.length > 0) {
          this.failedMessage = this.getResponseMessage(responses);
          this.errorDialogVisible = true;
        } else {
          this.close();
        }
      });
    } else {
      this.close();
    }
  }

  public close(): void {
    this.errorDialogVisible = false;
    this.visible = false;
    this.emails = undefined;
  }

  private getResponseMessage(responses: string[]): string {
    let result = '';
    if (responses) {
      responses.forEach((message: string) => {
        result += message + '<br/>';
      });
    }

    return result;
  }
}
