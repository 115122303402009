var Constants = /** @class */ (function () {
    function Constants() {
    }
    Object.defineProperty(Constants, "DENMARK_COUNTRY_ID", {
        get: function () {
            return 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "GREENLAND_COUNTRY_ID", {
        get: function () {
            return 3;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "ACCOUNT_CHANGE_PASSWORD", {
        get: function () {
            return 'ACCOUNT_CHANGE_PASSWORD';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "ACCOUNT_CHANGE_TEMPORARY_PASSWORD", {
        get: function () {
            return 'ACCOUNT_CHANGE_TEMPORARY_PASSWORD';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "SESSION_EXPIRED", {
        get: function () {
            return 'SESSION_EXPIRED';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "GLOBAL_KEY_DOWN", {
        get: function () {
            return 'GLOBAL_KEY_DOWN';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "SHOW_EDIT_HINT", {
        get: function () {
            return 'SHOW_EDIT_HINT';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "MAGIC_NUMBER", {
        get: function () {
            return -2147483648;
        },
        enumerable: true,
        configurable: true
    });
    return Constants;
}());
export { Constants };
