/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./LoadingTips.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../Services/SharedServices/Session/SessionService";
var styles_LoadingTipsComponent = [];
var RenderType_LoadingTipsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoadingTipsComponent, data: {} });
export { RenderType_LoadingTipsComponent as RenderType_LoadingTipsComponent };
function View_LoadingTipsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "loadingTip z-index101"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentTip; _ck(_v, 0, 0, currVal_0); }); }
export function View_LoadingTipsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "BusyIndicator z-index101"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { display: 0, bottom: 1, "background-color": 2 }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "BusyIndicator-spinner"], ["id", "indicatorIcon"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoadingTipsComponent_1)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.visible ? "block" : "none"), 0, "rgba(0, 0, 0, 0.61);"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.visible; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_LoadingTipsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loading-tips", [], null, null, null, View_LoadingTipsComponent_0, RenderType_LoadingTipsComponent)), i0.ɵdid(1, 49152, null, 0, i2.LoadingTipsComponent, [i3.TranslateService, i4.SessionService], null, null)], null, null); }
var LoadingTipsComponentNgFactory = i0.ɵccf("app-loading-tips", i2.LoadingTipsComponent, View_LoadingTipsComponent_Host_0, { visible: "visible", tiptext: "tiptext" }, {}, []);
export { LoadingTipsComponentNgFactory as LoadingTipsComponentNgFactory };
