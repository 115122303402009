<div class="Island ConfigurationContent tabs">
  <div class="Cards">
    <div class="Company-batchesControls Company-payroll">
      <combobox-edit-control
        [label]="'EmployeeGeneral.Salary Cycle' | translate"
        [editMode]="true"
        class="Company-batchesCycles"
        [editMode]="true"
        [idField]="'Id'"
        [textField]="'Name'"
        [nullValueLabel]="'CompanySalaryBatches.SalaryCycle_Item_All_Text' | translate"
        [comboboxDataSource]="salaryCycles"
        [(value)]="salaryCycleId"
        (valueChange)="onFilterControlChanged($event)"
      ></combobox-edit-control>

      <combobox-edit-control
        [label]="'NewEmployeeEmployment.Template' | translate"
        [editMode]="true"
        class="Company-batchesCycles"
        [editMode]="true"
        [idField]="'Id'"
        [textField]="'Name'"
        [nullValueLabel]="'CompanySalaryBatches.SalaryCycle_Item_All_Text' | translate"
        [comboboxDataSource]="templates"
        [(value)]="templateId"
        (valueChange)="onFilterControlChanged($event)"
      ></combobox-edit-control>

      <combobox-edit-control
        [label]="'EmployeeGeneral.Department' | translate"
        [editMode]="true"
        [editMode]="true"
        [idField]="'Id'"
        [textField]="'Text'"
        [nullValueLabel]="'CompanySalaryBatches.SalaryCycle_Item_All_Text' | translate"
        [comboboxDataSource]="departments"
        [(value)]="departmentId"
        (valueChange)="onFilterControlChanged($event)"
      ></combobox-edit-control>

      <div class="Company-batchesControlsButtons">
        <check-edit
          [label]="'CompanyPayrollData.EmployeeWithNoData' | translate"
          [(value)]="showEmployeeWithNoData"
          [editMode]="true"
          (valueChange)="onFilterControlChanged($event)"
        ></check-edit>
      </div>
    </div>
    <div class="Card Company-payrollData" [ngClass]="{ hasAddNewColumn: hasAddNewColumn }">
      <module-check [moduleId]="4">
        <kendo-grid
          id="companyPayrollDataGrid"
          [data]="gridView"
          [pageSize]="pageSize"
          [skip]="skip"
          [pageable]="true"
          (pageChange)="pageChange($event)"
          [height]="600"
          [navigable]="true"
          [selectable]="true"
          (cellClick)="onCellClick($event)"
          [selectedKeys]="selectedRows"
          [kendoGridSelectBy]="'UserEmploymentId'"
          (selectionChange)="onSelectionChange($event)"
          (cellClose)="onCellClose($event)"
          #kendoGridComponent
          (keydown)="onKeyDown($event)"
          [resizable]="true"
          [filterable]="'menu'"
          [filter]="filter"
          (filterChange)="onFilterChange($event)"
        >
          <kendo-grid-messages
            [filterClearButton]="'Filter.Clear' | translate"
            [filterFilterButton]="'Filter.Filter' | translate"
          ></kendo-grid-messages>
          <kendo-grid-column
            [filterable]="true"
            [field]="'FullName'"
            [title]="'CompanySalaryBatches.PaySlipsGridField Employee' | translate"
            [width]="200"
            [editable]="false"
            [locked]="locked"
          >
            <ng-template
              kendoGridFilterMenuTemplate
              let-column="column"
              let-filter="filter"
              let-filterService="filterService"
            >
              <kendo-grid-string-filter-menu
                [column]="column"
                [filter]="filter"
                [extra]="false"
                [filterService]="filterService"
              >
                <kendo-filter-eq-operator [text]="'Filter.Is equal to' | translate"></kendo-filter-eq-operator>
                <kendo-filter-neq-operator [text]="'Filter.Is not equal to' | translate"></kendo-filter-neq-operator>
                <kendo-filter-contains-operator [text]="'Filter.Contains' | translate"></kendo-filter-contains-operator>
                <kendo-filter-not-contains-operator
                  [text]="'Filter.Does not contain' | translate"
                ></kendo-filter-not-contains-operator>
                <kendo-filter-startswith-operator
                  [text]="'Filter.Starts with' | translate"
                ></kendo-filter-startswith-operator>
                <kendo-filter-endswith-operator
                  [text]="'Filter.Ends with' | translate"
                ></kendo-filter-endswith-operator>
                <kendo-filter-isnull-operator [text]="'Filter.Is null' | translate"></kendo-filter-isnull-operator>
                <kendo-filter-isnotnull-operator
                  [text]="'Filter.Is not null' | translate"
                ></kendo-filter-isnotnull-operator>
              </kendo-grid-string-filter-menu>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            [filterable]="true"
            *ngIf="showDepartment"
            [field]="'DepartmentName'"
            [title]="'CompanySalaryBatches.Department' | translate"
            [width]="200"
            [editable]="false"
            [locked]="locked"
            [footerStyle]="{ 'border-left': '0' }"
          >
            <ng-template
              kendoGridFilterMenuTemplate
              let-column="column"
              let-filter="filter"
              let-filterService="filterService"
            >
              <kendo-grid-string-filter-menu
                [column]="column"
                [filter]="filter"
                [extra]="false"
                [filterService]="filterService"
              >
                <kendo-filter-eq-operator [text]="'Filter.Is equal to' | translate"></kendo-filter-eq-operator>
                <kendo-filter-neq-operator [text]="'Filter.Is not equal to' | translate"></kendo-filter-neq-operator>
                <kendo-filter-contains-operator [text]="'Filter.Contains' | translate"></kendo-filter-contains-operator>
                <kendo-filter-not-contains-operator
                  [text]="'Filter.Does not contain' | translate"
                ></kendo-filter-not-contains-operator>
                <kendo-filter-startswith-operator
                  [text]="'Filter.Starts with' | translate"
                ></kendo-filter-startswith-operator>
                <kendo-filter-endswith-operator
                  [text]="'Filter.Ends with' | translate"
                ></kendo-filter-endswith-operator>
                <kendo-filter-isnull-operator [text]="'Filter.Is null' | translate"></kendo-filter-isnull-operator>
                <kendo-filter-isnotnull-operator
                  [text]="'Filter.Is not null' | translate"
                ></kendo-filter-isnotnull-operator>
              </kendo-grid-string-filter-menu>
            </ng-template>
            <ng-template kendoGridFooterTemplate>
              Total :
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column-group
            *ngFor="let salaryType of salaryTypes; trackBy: trackIndex; let i = index"
            [width]="330"
            [locked]="false"
          >
            <ng-template kendoGridHeaderTemplate let-dataItem="dataItem">
              <div *ngIf="!salaryType.IsNew" class="header-configuration">
                <div class="config-name">{{ salaryType?.displayName }}</div>
                <div class="config-icon">
                  <app-icon
                    (click)="onSalaryTypeConfigrationClicked(salaryType)"
                    [icon]="'Cog'"
                    [tooltip]="'CompanyPayrollData.ChangeSalaryTypeTooltip' | translate"
                  >
                  </app-icon>
                </div>
              </div>
            </ng-template>

            <kendo-grid-column
              [filterable]="true"
              *ngIf="!salaryType.IsNew && UnitsColumnVisible(salaryType)"
              [field]="'SalaryRecord' + (i + 1) + 'Units'"
              [footerStyle]="{ 'text-align': 'right' }"
              [editor]="'numeric'"
              [title]="salaryType.unitTypeTitle ? salaryType.unitTypeTitle : ('CompanySalaryTypes.Units' | translate)"
              [width]="100"
            >
              <ng-template
                kendoGridFilterMenuTemplate
                let-column="column"
                let-filter="filter"
                let-filterService="filterService"
              >
                <kendo-grid-numeric-filter-menu
                  [column]="column"
                  [extra]="false"
                  operator="eq"
                  [filter]="filter"
                  [filterService]="filterService"
                >
                  <kendo-filter-eq-operator [text]="'Filter.Is equal to' | translate"></kendo-filter-eq-operator>
                  <kendo-filter-neq-operator [text]="'Filter.Is not equal to' | translate"></kendo-filter-neq-operator>
                  <kendo-filter-gt-operator [text]="'Filter.Is greater than' | translate"></kendo-filter-gt-operator>
                  <kendo-filter-gte-operator
                    [text]="'Filter.Is greater than or equal to' | translate"
                  ></kendo-filter-gte-operator>
                  <kendo-filter-lt-operator [text]="'Filter.Is less than' | translate"></kendo-filter-lt-operator>
                  <kendo-filter-lte-operator
                    [text]="'Filter.Is less than or equal to' | translate"
                  ></kendo-filter-lte-operator>
                  <kendo-filter-isnull-operator [text]="'Filter.Is null' | translate"></kendo-filter-isnull-operator>
                  <kendo-filter-isnotnull-operator
                    [text]="'Filter.Is not null' | translate"
                  ></kendo-filter-isnotnull-operator>
                </kendo-grid-numeric-filter-menu>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <numeric-edit
                  [rightText]="true"
                  [editMode]="false"
                  [(value)]="dataItem['SalaryRecord' + (i + 1) + 'Units']"
                  [option]="numericOptions"
                  [setTimeout]="false"
                ></numeric-edit>
              </ng-template>
              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                <numeric-edit
                  [rightText]="true"
                  [editMode]="true"
                  [(value)]="dataItem['SalaryRecord' + (i + 1) + 'Units']"
                  [option]="numericOptions"
                  [setTimeout]="false"
                ></numeric-edit>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
                {{ rowTotal[column.field] ? (rowTotal[column.field] | kendoNumber: 'n2') : '' }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              [filterable]="true"
              *ngIf="!salaryType.IsNew && PerUnitColumnVisible(salaryType)"
              [field]="'SalaryRecord' + (i + 1) + 'PerUnit'"
              [editor]="'numeric'"
              [title]="'CompanySalaryBatches.SalaryRecordsGridField AmountPerUnit' | translate"
              [width]="100"
              [footerStyle]="{ 'text-align': 'right' }"
            >
              <ng-template
                kendoGridFilterMenuTemplate
                let-column="column"
                let-filter="filter"
                let-filterService="filterService"
              >
                <kendo-grid-numeric-filter-menu
                  [column]="column"
                  [extra]="false"
                  operator="eq"
                  [filter]="filter"
                  [filterService]="filterService"
                >
                  <kendo-filter-eq-operator [text]="'Filter.Is equal to' | translate"></kendo-filter-eq-operator>
                  <kendo-filter-neq-operator [text]="'Filter.Is not equal to' | translate"></kendo-filter-neq-operator>
                  <kendo-filter-gt-operator [text]="'Filter.Is greater than' | translate"></kendo-filter-gt-operator>
                  <kendo-filter-gte-operator
                    [text]="'Filter.Is greater than or equal to' | translate"
                  ></kendo-filter-gte-operator>
                  <kendo-filter-lt-operator [text]="'Filter.Is less than' | translate"></kendo-filter-lt-operator>
                  <kendo-filter-lte-operator
                    [text]="'Filter.Is less than or equal to' | translate"
                  ></kendo-filter-lte-operator>
                  <kendo-filter-isnull-operator [text]="'Filter.Is null' | translate"></kendo-filter-isnull-operator>
                  <kendo-filter-isnotnull-operator
                    [text]="'Filter.Is not null' | translate"
                  ></kendo-filter-isnotnull-operator>
                </kendo-grid-numeric-filter-menu>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <numeric-edit
                  [rightText]="true"
                  [editMode]="false"
                  [(value)]="dataItem['SalaryRecord' + (i + 1) + 'PerUnit']"
                  [option]="numericOptions"
                  [setTimeout]="false"
                >
                </numeric-edit>
              </ng-template>
              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                <numeric-edit
                  [rightText]="true"
                  [editMode]="true"
                  [(value)]="dataItem['SalaryRecord' + (i + 1) + 'PerUnit']"
                  [option]="numericOptions"
                  [setTimeout]="false"
                >
                </numeric-edit>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
                <!--{{ rowTotal[column.field] | kendoNumber:'n2'}}-->
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              [filterable]="true"
              *ngIf="!salaryType.IsNew && AmountColumnVisible(salaryType)"
              [field]="'SalaryRecord' + (i + 1) + 'Amount'"
              [editor]="'numeric'"
              [title]="'CompanySalaryBatches.SalaryRecordsGridField Amount' | translate"
              [width]="100"
              [footerStyle]="{ 'text-align': 'right' }"
            >
              <ng-template
                kendoGridFilterMenuTemplate
                let-column="column"
                let-filter="filter"
                let-filterService="filterService"
              >
                <kendo-grid-numeric-filter-menu
                  [column]="column"
                  [extra]="false"
                  operator="eq"
                  [filter]="filter"
                  [filterService]="filterService"
                >
                  <kendo-filter-eq-operator [text]="'Filter.Is equal to' | translate"></kendo-filter-eq-operator>
                  <kendo-filter-neq-operator [text]="'Filter.Is not equal to' | translate"></kendo-filter-neq-operator>
                  <kendo-filter-gt-operator [text]="'Filter.Is greater than' | translate"></kendo-filter-gt-operator>
                  <kendo-filter-gte-operator
                    [text]="'Filter.Is greater than or equal to' | translate"
                  ></kendo-filter-gte-operator>
                  <kendo-filter-lt-operator [text]="'Filter.Is less than' | translate"></kendo-filter-lt-operator>
                  <kendo-filter-lte-operator
                    [text]="'Filter.Is less than or equal to' | translate"
                  ></kendo-filter-lte-operator>
                  <kendo-filter-isnull-operator [text]="'Filter.Is null' | translate"></kendo-filter-isnull-operator>
                  <kendo-filter-isnotnull-operator
                    [text]="'Filter.Is not null' | translate"
                  ></kendo-filter-isnotnull-operator>
                </kendo-grid-numeric-filter-menu>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <!--<app-icon *ngIf="dataItem['MultipleRecordsExist' + (i + 1)]" [icon]="'Warning'" [tooltip]="'CompanySalaryBatches.SalaryTypeDuplicationWarning' | translate: { name: salaryType?.displayName }"></app-icon>-->
                <numeric-edit
                  [rightText]="true"
                  [editMode]="false"
                  [(value)]="dataItem['SalaryRecord' + (i + 1) + 'Amount']"
                  [option]="numericOptions"
                  [setTimeout]="false"
                ></numeric-edit>
              </ng-template>
              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                <numeric-edit
                  [rightText]="true"
                  [editMode]="true"
                  [(value)]="dataItem['SalaryRecord' + (i + 1) + 'Amount']"
                  [option]="numericOptions"
                  [setTimeout]="false"
                ></numeric-edit>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
                {{ rowTotal[column.field] ? (rowTotal[column.field] | kendoNumber: 'n2') : '' }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              *ngIf="!salaryType.IsNew"
              [title]="''"
              [width]="30"
              [editable]="false"
              [footerStyle]="{ 'text-align': 'right', 'border-width': '0 1px 0 1px' }"
              [style]="{ 'border-width': '0 1px 1px 1px' }"
              [headerStyle]="{ 'border-width': '0 1px 1px 1px' }"
            >
              <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <app-icon
                  class="margin-left"
                  *ngIf="dataItem['MultipleRecordsExist' + (i + 1)]"
                  [icon]="'Warning'"
                  [tooltip]="
                    'CompanySalaryBatches.SalaryTypeDuplicationWarning' | translate: { name: salaryType?.displayName }
                  "
                ></app-icon>
              </ng-template>
              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                <app-icon
                  class="margin-left"
                  *ngIf="dataItem['MultipleRecordsExist' + (i + 1)]"
                  [icon]="'Warning'"
                  [tooltip]="
                    'CompanySalaryBatches.SalaryTypeDuplicationWarning' | translate: { name: salaryType?.displayName }
                  "
                ></app-icon>
              </ng-template>
              <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
                {{ rowTotal[column.field] ? (rowTotal[column.field] | kendoNumber: 'n2') : '' }}
              </ng-template>
            </kendo-grid-column>
          </kendo-grid-column-group>

          <kendo-grid-column
            [filterable]="true"
            *ngIf="hasAddNewColumn"
            [width]="150"
            [locked]="false"
            [footerStyle]="{ 'text-align': 'right' }"
          >
            <ng-template kendoGridHeaderTemplate let-dataItem="dataItem">
              <div class="align-center">
                <button-component
                  class="k-button"
                  (click)="onSalaryTypeConfigrationClicked()"
                  [label]="'CompanySalaryBatches.AddSalaryTypeText' | translate"
                >
                </button-component>
              </div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem"> </ng-template>
            <ng-template kendoGridEditTemplate let-dataItem="dataItem"> </ng-template>
            <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
              {{ rowTotal[column.field] ? (rowTotal[column.field] | kendoNumber: 'n2') : '' }}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-command-column width="1">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem"> </ng-template>
            <ng-template kendoGridEditTemplate let-dataItem="dataItem"> </ng-template>
          </kendo-grid-command-column>
        </kendo-grid>
      </module-check>
    </div>

    <div class="Actions">
      <help-button module="company" currentTab="tabs.company.payrolldata"></help-button>
    </div>
  </div>
</div>
<company-payroll-salarytype-config
  [(visible)]="showSalaryTypeConfiguration"
  [selectedSalaryType]="selectedSalaryTypeForConfiguration"
  [salaryTypes]="configurationSalaryTypeDataSource"
  [maxIndex]="overviewMaxIndex"
  (changeConfigurationCompleted)="getSalaryTypes()"
></company-payroll-salarytype-config>
