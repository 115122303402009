import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
var CompanyModulesComponent = /** @class */ (function () {
    function CompanyModulesComponent(dataService, staticDataService, sessionService) {
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.usersMonthly = 0;
        this.users14Day = 0;
        this.usersWeekly = 0;
        this.modules = [];
        this.confirmDialogVisible = false;
        this.reportDialogVisible = false;
        this.selectedPackageId = 0;
        this.currentCompanyClick = false;
        this.companyClickChange = new EventEmitter();
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(CompanyModulesComponent.prototype, "companyClick", {
        get: function () {
            return this.currentCompanyClick;
        },
        set: function (value) {
            this.currentCompanyClick = value;
            this.companyClickChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    CompanyModulesComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.sessionService.role.IsFullAdmin) {
            this.staticDataService.loadStaticData().then(function () {
                _this.getEmployees();
                _this.staticDataService.moduleCompanyView
                    .pipe(takeUntil(_this.ngUnsubscribe))
                    .subscribe(function (data) {
                    _this.modules = JSON.parse(JSON.stringify(data));
                });
            });
        }
    };
    CompanyModulesComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyModulesComponent.prototype.onSubmit = function (packageId) {
        this.selectedPackageId = packageId;
        this.confirmDialogVisible = true;
    };
    CompanyModulesComponent.prototype.onConfirmDialogAction = function (action) {
        var _this = this;
        if (action === 'Understood') {
            var selectedModules = this.modules
                .filter(function (m) { return m.IsEnabled && !m.InfoOnly; })
                .map(function (m) { return m.ModuleId; });
            this.dataService.Modules_SetEnabledModules(this.selectedPackageId, selectedModules).subscribe(function () {
                _this.staticDataService.loadStaticData().then(function () {
                    window.location.reload();
                });
            });
        }
    };
    CompanyModulesComponent.prototype.getEmployees = function () {
        var _this = this;
        this.staticDataService.SalaryCycle.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            var salaryCycles = data;
            _this.dataService.Employments_GetEmploymentViews().subscribe(function (employementData) {
                var userEmployments = employementData;
                userEmployments = userEmployments.filter(function (item) {
                    var now = new Date();
                    return item.IsActive && (!item.ExpireDate || new Date(item.ExpireDate) > now);
                });
                _this.usersWeekly = 0;
                _this.users14Day = 0;
                _this.usersMonthly = 0;
                var _loop_1 = function (employment) {
                    var employmentCycle = salaryCycles
                        ? salaryCycles.find(function (cycle) { return cycle.Id === employment.SalaryCycleId; })
                        : undefined;
                    if (employmentCycle) {
                        switch (employmentCycle.Key) {
                            case 'Weekly':
                                _this.usersWeekly++;
                                break;
                            case '14Days':
                            case '14Days2':
                                _this.users14Day++;
                                break;
                            default:
                                // GS-2187 : Cycle not Weekly nor 14days is Monthly.
                                _this.usersMonthly++;
                                break;
                        }
                    }
                };
                for (var _i = 0, userEmployments_1 = userEmployments; _i < userEmployments_1.length; _i++) {
                    var employment = userEmployments_1[_i];
                    _loop_1(employment);
                }
                if (_this.usersWeekly === 0 && _this.users14Day === 0 && _this.usersMonthly === 0) {
                    _this.usersMonthly = 1;
                }
            });
        });
    };
    return CompanyModulesComponent;
}());
export { CompanyModulesComponent };
