import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Global } from '../../../../Common/Global';
import { ExtendSalaryPeriod } from '../../../../Model/ExtendSalaryPeriod';
import {
  ISalaryBatchRecord,
  ISalaryPeriod,
  ISalaryStatement,
  ISimpleKeyValuePair,
  IUserEmployment
} from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { DialogService } from '../../../../Services/SharedServices/Dialog/DialogService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'payroll-preview',
  templateUrl: './PayrollPreview.component.html'
})
export class PayrollPreviewComponent {
  private userEmploymentValue: IUserEmployment;
  @Input()
  public get userEmployment(): IUserEmployment {
    return this.userEmploymentValue;
  }
  public set userEmployment(value: IUserEmployment) {
    if (this.userEmploymentValue !== value) {
      this.userEmploymentValue = value;
      this.loadPreviewData(value ? value.Id : undefined, value ? value.SalaryCycleId : undefined);
    }
  }

  private recalculatePreviewValue = false;
  @Input()
  public get recalculatePreview(): boolean {
    return this.recalculatePreviewValue;
  }
  public set recalculatePreview(value: boolean) {
    if (value) {
      this.loadSalaryStatementPreview(this.userEmployment ? this.userEmployment.Id : undefined, this.periodId);
      setTimeout(() => {
        this.recalculatePreviewValue = false;
        this.recalculatePreviewChange.emit(false);
      });
    }
  }

  @Input() isUpdate: boolean;

  @Output() public recalculatePreviewChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private currentperiodfriendlyName: string;
  @Input()
  public get periodsName(): string {
    return this.currentperiodfriendlyName;
  }
  public set periodsName(value: string) {
    this.currentperiodfriendlyName = value;
    this.periodsNameChange.emit(value);
  }
  @Output() public periodsNameChange: EventEmitter<string> = new EventEmitter<string>();

  private currentTotal: string;
  @Input()
  public get totalValue(): string {
    return this.currentTotal;
  }
  public set totalValue(value: string) {
    this.currentTotal = value;
    this.totalValueChange.emit(value);
  }
  @Output() public totalValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public closePreviewArea: EventEmitter<any> = new EventEmitter<any>();

  public onClosePreview(): void {
    this.closePreviewArea.emit();
  }

  @Input() public isMobilePayrollDataSlide = false;
  public get classPayrollDataSlidePayrollDataSlide(): string {
    if (this.isMobilePayrollDataSlide) {
      return 'classPayrollDataSlidePayrollDataSlide';
    }
    return '';
  }

  @Input() public isHidePreviewGrid = false;

  public get hideClass(): string {
    if (this.isHidePreviewGrid) {
      return 'hidePreviewGrid';
    }
    return '';
  }

  public get isHidden(): string {
    if (!this.sessionService.browser.isHybridApp) {
      return 'Icon--Payslip--hide';
    } else {
      return '';
    }
  }

  public isWeb: boolean = isWeb;
  public periodId: number;
  public periods: ExtendSalaryPeriod[];
  public payslipIconsVisible: boolean;
  public gridData: ISalaryBatchRecord[] = [];

  public get IsAppOrDesktop(): boolean {
    return !this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
  }
  public get IsWeb(): boolean {
    return !this.sessionService.browser.isHybridApp;
  }

  private companypreference: ISimpleKeyValuePair[] = Global.COMPANY_PREFERENCES;
  private salaryStatement: ISalaryStatement;

  constructor(
    private dataService: RxDataService,
    private sessionService: SessionService,
    private dialog: DialogService,
    private translate: TranslateService
  ) {
    this.companypreference = Global.COMPANY_PREFERENCES;
  }

  public onPeriodChanged(): void {
    this.loadSalaryStatementPreview(this.userEmploymentValue.Id, this.periodId);
  }

  private flagisDownloading = false;
  public downloadSalaryStatementsPreview(isPopup: boolean): void {
    if (!this.flagisDownloading) {
      const outType: string = isPopup && this.isWeb ? 'a4p' : 'html';
      this.dataService
        .SalaryStatements_GetHtmlPreview(outType, this.salaryStatement)
        .subscribe((data: string): void => {
          if (!isPopup) {
            const newWindow: Window = window.open('about:blank');
            if (newWindow) {
              newWindow.document.write(data);
              newWindow.document.close();
            } else {
              this.dialog.showMessageBox(this.translate.instant('Report.NewTabBlocked'), ['Ok']);
            }
          } else {
            this.dialog.showIFrameDialog(data);
          }
        });
      this.flagisDownloading = true;
    } else {
      this.flagisDownloading = false;
    }
  }

  private loadPreviewData(userEmploymentId: number, salaryCycleId: number): void {
    if (!userEmploymentId || !salaryCycleId) {
      return;
    }

    this.getPeriods(userEmploymentId, salaryCycleId);
  }

  private getPeriods(employmentId: number, cycleId: number): void {
    this.dataService.SalaryBatches_GetSalaryPeriods(cycleId).subscribe((periods: ISalaryPeriod[]) => {
      const dataSource: ExtendSalaryPeriod[] = [];
      if (periods) {
        this.companypreference = Global.COMPANY_PREFERENCES;
        const preference: ISimpleKeyValuePair = this.companypreference.find(
          (model: ISimpleKeyValuePair) => model.Key === 'SalaryPeriods.FriendlyNames'
        );
        const useFriendlyNames: boolean = preference && preference.Value && preference.Value === 'true';
        const isUserFriendlyname: any = this.companypreference
          ? this.companypreference.find((model: ISimpleKeyValuePair) => model.Key === 'SalaryPeriods.FriendlyNames')
          : undefined;

        periods.forEach((period: ISalaryPeriod, key: number) => {
          const startDate: string = this.sessionService.toString(
            period.StartDate ? new Date(period.StartDate) : undefined
          );
          const endDate: string = this.sessionService.toString(period.EndDate ? new Date(period.EndDate) : undefined);
          const item = new ExtendSalaryPeriod();
          item.Id = period.Id;
          item.StartDate = period.StartDate;
          item.EndDate = period.EndDate;
          item.Period = startDate + ' - ' + endDate;
          item.FriendlyName =
            isUserFriendlyname && isUserFriendlyname.Value && isUserFriendlyname.Value === 'true'
              ? ' ' + period.FriendlyName
              : startDate + ' - ' + endDate;

          dataSource[key] = item;
        });
      }

      this.periods = dataSource;
      this.getSuggestPeriod(employmentId, cycleId);
    });
  }

  private getSuggestPeriod(employmentId: number, cycleId: number): void {
    this.dataService.SalaryBatches_SuggestSalaryPeriod(cycleId).subscribe((period: ISalaryPeriod) => {
      if (!period) {
        if (this.periods && this.periods.length > 0) {
          this.periodId = this.periods[0].Id;
        }

        return;
      }

      this.periodId = period.Id;
      if (this.periods && this.periods.length > 0) {
        const objectPeriods: ExtendSalaryPeriod = this.periods.find(
          (model: ExtendSalaryPeriod) => model.Id === this.periodId
        );
        if (objectPeriods && objectPeriods.FriendlyName) {
          this.periodsName = objectPeriods.FriendlyName;
        }
      }

      this.loadSalaryStatementPreview(employmentId, period.Id);
    });
  }

  public currentuserEmploymentId: number;
  public currentuserperiodId: number;
  private loadSalaryStatementPreview(userEmploymentId: number, periodId: number): void {
    this.gridData = [];
    if (userEmploymentId && periodId) {
      if (this.currentuserEmploymentId !== userEmploymentId || this.currentuserperiodId !== periodId || this.isUpdate) {
        this.currentuserEmploymentId = userEmploymentId;
        this.currentuserperiodId = periodId;
      } else {
        return;
      }
      this.dataService
        .SalaryStatements_GetSingleEmploymentPreviewByPeriod(userEmploymentId, periodId)
        .subscribe((salaryStatement: ISalaryStatement) => {
          if (!salaryStatement) {
            this.gridData = [];
            this.payslipIconsVisible = false;
            this.totalValue = '0';
            return;
          }

          this.salaryStatement = salaryStatement;
          this.payslipIconsVisible =
            salaryStatement.SalaryBatchRecords && salaryStatement.SalaryBatchRecords.length > 0;
          this.gridData = salaryStatement.SalaryBatchRecords;
          if (this.gridData && this.gridData.length > 0) {
            const objectTotal: ISalaryBatchRecord[] = this.gridData.filter(
              (model: ISalaryBatchRecord) => model.SpecialIdentifier === 'PAYOUT'
            );
            if (objectTotal && objectTotal.length === 1 && objectTotal[0] && objectTotal[0].Amount) {
              this.totalValue = objectTotal[0].Amount.toString();
            }
          }
        });
    }
  }
}
