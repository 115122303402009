import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../Common/Constants';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { Global } from '../../Common/Global';
import { IPensionBaseCalculationMethod } from '../../Services/ApiModel';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { OffDayCompensationModel } from './OffDayCompensationModel';

@Component({
  selector: 'off-day-compensation',
  templateUrl: './OffDayCompensation.component.html'
})
export class OffDayCompensationComponent extends FormComponentBase implements OnInit {
  @Input() public disable: boolean;

  private modelValue: OffDayCompensationModel;
  @Input()
  public get model(): OffDayCompensationModel {
    if (!this.modelValue) {
      this.modelValue = new OffDayCompensationModel();
    }

    return this.modelValue;
  }
  public set model(value: OffDayCompensationModel) {
    this.modelValue = value;
    if (this.modelChange) {
      this.modelChange.emit(value);
    }
  }
  @Output() public modelChange: EventEmitter<any> = new EventEmitter<any>();

  public offDayCompensationCalculationMethods: IPensionBaseCalculationMethod[];

  constructor(private staticDataService: StaticDataService) {
    super();
    this.childIsDirty = Array(2).fill(false);
  }

  public ngOnInit(): void {
    this.staticDataService.PensionBaseCalculationMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IPensionBaseCalculationMethod[]) => (this.offDayCompensationCalculationMethods = data)
    );
  }

  public get isDanishCompany(): boolean {
    return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
  }
}
