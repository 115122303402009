import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IUser, IUserCompanyAccess } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { AccountantServiceBase } from '../AccountantServiceBase';

@Injectable({
  providedIn: 'root'
})
export class AccountantUsersService extends AccountantServiceBase {
  public get users(): Observable<IUser[]> {
    return this.usersSubject.asObservable();
  }

  private usersSubject: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([]);
  private allUsers: IUser[] = [];

  constructor(protected api: RxDataService, private sessionService: SessionService) {
    super(api);
  }

  public loadData(): void {
    this.api.Account_GetAccountUsers().subscribe((data: IUser[]) => {
      this.allUsers = data;
      this.applyFilter();
    });
  }

  protected getRoles(): void {
    if (this.selectedId) {
      this.api.Account_GetCompanyAccessByUser(this.selectedId).subscribe((data: IUserCompanyAccess[]) => {
        if (data.length > 0) {
          if (data[0].UserId === this.selectedId) {
            this.rolesSubject.next(data);
          } else {
            if (!environment.production) {
              console.error(`UserId ${this.selectedId} doesnt match recieved data`);
            }
          }
        } else {
          if (!environment.production) {
            console.log('No roles');
          }
        }
      });
    }
  }

  protected applyFilter(): void {
    if (this.filter) {
      this.currentPage = 0;
      const searchText: string = this.filter.toLocaleLowerCase();
      const data: IUser[] = this.allUsers.filter(
        (c: IUser) => c.FullName.toLocaleLowerCase().includes(searchText) || c.Id.toString().includes(searchText)
      );

      this.usersSubject.next(data);
    } else {
      this.usersSubject.next(this.allUsers);
    }

    this.selectedId = undefined;
  }
}
