<!--Sub View For "Hiring Data" section-->
<fieldset class="Form--horizontal hiringDataComponent">
  <legend class="Card-title">{{ 'EmployeeGeneral.Hiring Data' | translate }}</legend>
  <div *ngIf="!hasEmployment">
    <p>{{ 'EmploymentPayrollData.No Employment' | translate }}</p>
  </div>

  <div *ngIf="hasEmployment">
    <!--Employment ID-->
    <app-text-edit
      [label]="'EmployeeGeneral.EmployeeID' | translate"
      [editMode]="false"
      [type]="'text'"
      [(isValid)]="childIsValid[0]"
      [(isDirty)]="childIsDirty[0]"
      [(value)]="userEmployment.Id"
      (valueChange)="onChange()"
    ></app-text-edit>
    <!--Employment ID-->
    <!--Departments-->
    <module-check [moduleId]="4" [(hasModule)]="hasDepartmentEnabled">
      <div class="Control--wrapper" [ngClass]="editMode && !IsDisableCreateDepartment ? 'controlFlex' : ''">
        <combobox-edit-control
          [ngClass]="editMode ? 'customFlexComboAddNew' : ''"
          [editMode]="editMode"
          [label]="'EmployeeGeneral.Department' | translate"
          [nullValueLabel]="'DropdownList.None' | translate"
          [disable]="!hasDepartmentEnabled"
          [keepDataSourceOrder]="true"
          [comboboxDataSource]="departments"
          [(isValid)]="childIsValid[1]"
          [(isDirty)]="childIsDirty[1]"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="userEmployment.DepartmentId"
          (valueChange)="onComboboxEmployeeDetailDepartmentSelectionChanged()"
        >
        </combobox-edit-control>
        <app-icon
          *ngIf="editMode && !IsDisableCreateDepartment"
          [icon]="'circleplus'"
          class="iconColor iconPosition"
          (click)="AddDepartmentVisible = true"
        ></app-icon>
      </div>
    </module-check>
    <!--Departments-->
    <!-- Manager -->
    <combobox-edit-control
      [label]="'NewEmployee.Manager' | translate"
      [editMode]="editMode"
      [comboboxDataSource]="managers"
      [nullValueLabel]="'DropdownList.None' | translate"
      [(isValid)]="childIsValid[2]"
      [(isDirty)]="childIsDirty[2]"
      [textField]="'FullName'"
      [idField]="'Id'"
      [(value)]="userEmployment.ManagerCompanyUserId"
      (valueChange)="onChange()"
    ></combobox-edit-control>
    <!-- /Manager -->

    <app-text-edit
      [label]="'EmployeeGeneral.External reference' | translate"
      [editMode]="editMode"
      [type]="'text'"
      [(isValid)]="childIsValid[3]"
      [(isDirty)]="childIsDirty[3]"
      [(value)]="userEmployment.ExternalReference"
      (valueChange)="onChange()"
    ></app-text-edit>
    <!--External Reference-->
    <!--Hiring Date-->
    <date-picker-control
      [label]="'EmployeeGeneral.Hiring date' | translate"
      [editMode]="editMode"
      [required]="true"
      [requiredMessage]="'EmployeeGeneral.Required' | translate"
      [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
      [(value)]="userEmployment.HireDate"
      [(isValid)]="childIsValid[4]"
      [(isDirty)]="childIsDirty[4]"
      (valueChange)="onChange()"
    ></date-picker-control>

    <!--Hiring Date-->
    <!--Seniority Date-->
    <!--required="true" required-message="EmployeeGeneral.Required"-->
    <date-picker-control
      [label]="'EmployeeGeneral.SeniorityDate' | translate"
      [editMode]="editMode"
      [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
      [(isValid)]="childIsValid[5]"
      [(isDirty)]="childIsDirty[5]"
      [(value)]="userEmployment.SeniorityDate"
      (valueChange)="onChange()"
    ></date-picker-control>
    <!-- SeniorityDate -->
    <!--Salary Cycle-->
    <combobox-edit-control
      [label]="'EmployeeGeneral.Salary Cycle' | translate"
      [editMode]="editMode"
      [comboboxDataSource]="cycles"
      [(isValid)]="childIsValid[6]"
      [(isDirty)]="childIsDirty[6]"
      [(value)]="userEmployment.SalaryCycleId"
      [textField]="'Name'"
      [idField]="'Id'"
      (valueChange)="onChange()"
    ></combobox-edit-control>
    <!--Salary Cycle-->
    <!--Termination Date-->
    <date-picker-control
      [label]="'EmployeeGeneral.Termination date' | translate"
      *ngIf="!shouldHideTerminationDate"
      [editMode]="editMode"
      [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
      [(isValid)]="childIsValid[7]"
      [(isDirty)]="childIsDirty[7]"
      [(value)]="userEmployment.ExpireDate"
      (valueChange)="onChange()"
    ></date-picker-control>
    <!--Termination Date-->
    <!--Base on template-->
    <combobox-edit-control
      *ngIf="showTemplates"
      [label]="'EmployeeEmployment.Base on template' | translate"
      [editMode]="editMode"
      [comboboxDataSource]="templates"
      [nullValueLabel]="'DropdownList.None' | translate"
      [(isValid)]="childIsValid[8]"
      [(isDirty)]="childIsDirty[8]"
      [textField]="'Name'"
      [idField]="'Id'"
      [(value)]="userEmployment.BasedOnTemplateId"
      (valueChange)="onChange()"
    ></combobox-edit-control>
    <!--Base on template-->

    <app-text-edit
      [editMode]="editMode"
      [type]="'text'"
      [label]="'EmploymentPayrollData.CollectiveAgreementCode' | translate"
      [(isValid)]="childIsValid[9]"
      [(isDirty)]="childIsDirty[9]"
      [(value)]="userEmployment.CollectiveBargainingAgreementCode"
      (valueChange)="onChange()"
    ></app-text-edit>
  </div>
</fieldset>

<app-dialog
  (action)="onCreateNewDepartment($event)"
  [(visible)]="AddDepartmentVisible"
  [title]="'EmployeeGeneral.AddDepartment' | translate"
  [width]="500"
>
  <app-text-edit
    [editMode]="true"
    [(value)]="ModelDepartment.Name"
    [label]="'CompanyGeneral.Department Name' | translate"
  >
  </app-text-edit>
  <combobox-edit-control
    [editMode]="true"
    [textField]="'FullName'"
    [idField]="'Id'"
    [comboboxDataSource]="companyContacts"
    [(value)]="ModelDepartment.ManagerCompanyUserId"
    [label]="'CompanyGeneral.Manager' | translate"
    [nullValueLabel]="'DropdownList.None' | translate"
  >
  </combobox-edit-control>
  <app-text-edit
    [editMode]="true"
    [(value)]="ModelDepartment.ExternalReference"
    [label]="'CompanyGeneral.ERP ref' | translate"
  >
  </app-text-edit>
  <app-text-edit
    [editMode]="true"
    [(value)]="ModelDepartment.EIncomeReference"
    [label]="'CompanyGeneral.EIncomeReference' | translate"
  >
  </app-text-edit>
  <app-dialog-action type="Create"></app-dialog-action>
</app-dialog>
