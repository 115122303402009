import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../../Common/Global';
import { GridHelper } from '../../Common/GridHelper';
import { ListHelper } from '../../Common/ListHelper';
import { SalaryRecordCalculation } from '../../Employee/EmployeeTab/Payroll/Controls/SalaryRecordCalculation';
import { OverviewDisplayModeEnum, UnitTypeEnum } from '../../Model/Enum';
import { GetSalaryRecordsOverview_Result } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
var PayrollFilterType;
(function (PayrollFilterType) {
    PayrollFilterType[PayrollFilterType["SalaryCycle"] = 1] = "SalaryCycle";
    PayrollFilterType[PayrollFilterType["Template"] = 2] = "Template";
    PayrollFilterType[PayrollFilterType["Department"] = 3] = "Department";
})(PayrollFilterType || (PayrollFilterType = {}));
var CompanyPayrollDataNGXComponent = /** @class */ (function () {
    //public mySelection: number[] = [this.sessionService.currentUserEmployeeId];
    function CompanyPayrollDataNGXComponent(dataService, sessionService, translateService, staticDataService, renderer, changeDetectorRef) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.translateService = translateService;
        this.staticDataService = staticDataService;
        this.renderer = renderer;
        this.changeDetectorRef = changeDetectorRef;
        this.gridData = [];
        this.salaryTypes = [];
        this.availableSalaryTypes = [];
        this.pageSize = 25;
        this.skip = 0;
        this.groups = [{ field: 'DepartmentName' }];
        this.selectedRows = [];
        this.filterDataSource = [];
        this.salaryCycles = [];
        this.templates = [];
        this.departments = [];
        this.numericOptions = {
            format: 'n2',
            decimals: 2,
            step: 0,
            min: 0,
            spinners: false
        };
        this.original = new GetSalaryRecordsOverview_Result();
        this.ngUnsubscribe = new Subject();
        this.listColumnCheck = [];
        this.gridFilterData = filterBy(this.gridData, this.filter);
    }
    Object.defineProperty(CompanyPayrollDataNGXComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyPayrollDataNGXComponent.prototype, "hasLanguageModule", {
        get: function () {
            return this.sessionService.feature.hasModuleId(5);
        },
        enumerable: true,
        configurable: true
    });
    CompanyPayrollDataNGXComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translateFilterDataSource();
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateFilterDataSource();
        });
        if (this.isMobile) {
            this.sessionService.isShowHugeFeaturesAlert = true;
        }
        var departmentPromise = this.staticDataService.departments
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (departments) {
            _this.departments = ListHelper.mapDataSource(_this.sessionService, departments, 'Id', 'Name');
            // this.departmentId = this.departments[0] ? this.departments[0].Id : undefined;
        });
        this.dataService
            .EmploymentTemplates_GetEmploymentTemplates(false)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (templates) {
            _this.templates = templates.sort(function (a, b) {
                if (a.Name > b.Name) {
                    return 1;
                }
                if (a.Name < b.Name) {
                    return -1;
                }
                return 0;
            });
            // this.templateId = this.templates[0] ? this.templates[0].Id : undefined;
        });
        this.dataService
            .Miscellaneous_GetUsedSalaryCycles()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.salaryCycles = data;
            // this.salaryCycleId = data[0] ? data[0].Id : undefined;
            _this.getSalaryTypes();
        });
    };
    CompanyPayrollDataNGXComponent.prototype.translateFilterDataSource = function () {
        var _this = this;
        this.filterDataSource = [];
        this.translateService
            .get(['CompanyPayrollData.Salary Cycle', 'CompanyPayrollData.Template', 'CompanyPayrollData.Department'])
            .subscribe(function (translations) {
            var dataSource = [];
            dataSource.push({ Id: PayrollFilterType.SalaryCycle, Name: translations['CompanyPayrollData.Salary Cycle'] });
            dataSource.push({ Id: PayrollFilterType.Template, Name: translations['CompanyPayrollData.Template'] });
            dataSource.push({ Id: PayrollFilterType.Department, Name: translations['CompanyPayrollData.Department'] });
            _this.filterDataSource = dataSource;
        });
    };
    CompanyPayrollDataNGXComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyPayrollDataNGXComponent.prototype.trackIndex = function (index) {
        return index;
    };
    CompanyPayrollDataNGXComponent.prototype.UnitsColumnVisible = function (salaryType) {
        return (salaryType &&
            (!salaryType.OverviewDisplayMode ||
                salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsAndAmount ||
                salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsOnly));
    };
    CompanyPayrollDataNGXComponent.prototype.AmountColumnVisible = function (salaryType) {
        return (salaryType &&
            (!salaryType.OverviewDisplayMode ||
                salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsAndAmount ||
                salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.AmountOnly));
    };
    CompanyPayrollDataNGXComponent.prototype.PerUnitColumnVisible = function (salaryType) {
        return (salaryType &&
            (!salaryType.OverviewDisplayMode || salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsAndAmount));
    };
    CompanyPayrollDataNGXComponent.prototype.onFilterControlChanged = function (value) {
        if (this.salaryTypes && this.salaryTypes.length > 0) {
            this.getData();
        }
        else {
            this.getSalaryTypes();
        }
    };
    CompanyPayrollDataNGXComponent.prototype.onKeyDown = function (event) {
        var _this = this;
        if ((event.key === 'Tab' || event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Escape') &&
            this.selectedCell) {
            var cell = this.findNextCell(this.selectedCell, event);
            if (cell &&
                (!this.gridView || !this.gridView.data || (cell.rowIndex >= 0 && cell.rowIndex < this.gridView.data.length))) {
                var dataItem_1 = this.gridView && this.gridView.data ? this.gridView.data[cell.rowIndex] : undefined;
                Object.assign(this.original, dataItem_1);
                this.selectedRows = dataItem_1 ? [dataItem_1.UserEmploymentId] : [];
                if (event.key === 'Escape') {
                    setTimeout(function () {
                        _this.kendoGrid.editCell(_this.selectedCell.rowIndex, _this.selectedCell.columnIndex, _this.createFormGroup(dataItem_1));
                        _this.kendoGrid.closeCell();
                    });
                }
                else {
                    this.kendoGrid.editCell(cell.rowIndex, cell.columnIndex, this.createFormGroup(dataItem_1));
                    this.kendoGrid.focusCell(cell.rowIndex + 2, cell.columnIndex);
                    setTimeout(function () {
                        var element = document.querySelector('.k-state-focused input');
                        if (element) {
                            element.focus();
                        }
                    });
                }
                this.formGroup = this.createFormGroup(dataItem_1);
            }
            event.preventDefault();
            event.stopPropagation();
        }
    };
    CompanyPayrollDataNGXComponent.prototype.onSelectionChange = function (event) { };
    CompanyPayrollDataNGXComponent.prototype.findNextCell = function (cell, event) {
        var _this = this;
        if (!this.listColumnCheck || this.listColumnCheck.length === 0) {
            this.listColumnCheck = [];
            this.kendoGrid.columnList.columns.forEach(function (column) {
                if (!column.children) {
                    _this.listColumnCheck.push(column);
                }
                else {
                    column.children.forEach(function (childColumn) {
                        if (column !== childColumn) {
                            _this.listColumnCheck.push(childColumn);
                        }
                    });
                }
            });
        }
        if (event.key === 'ArrowUp') {
            if (cell.rowIndex > 0) {
                cell.rowIndex = cell.rowIndex - 1;
            }
        }
        else if (event.key === 'ArrowDown') {
            if (cell.rowIndex < this.gridView.data.length - 1) {
                cell.rowIndex = cell.rowIndex + 1;
            }
        }
        else {
            if (!event.shiftKey) {
                if (cell.columnIndex >= 2 && cell.columnIndex < this.listColumnCheck.length - 2) {
                    for (var i = cell.columnIndex + 1; i < this.listColumnCheck.length - 1; i++) {
                        if (this.listColumnCheck[i] && this.listColumnCheck[i].editable) {
                            cell.columnIndex = i;
                            break;
                        }
                    }
                }
                else {
                    cell.columnIndex = 2;
                }
            }
            else {
                if (cell.columnIndex > 2 && cell.columnIndex < this.listColumnCheck.length - 1) {
                    for (var i = cell.columnIndex - 1; i >= 2; i--) {
                        if (this.listColumnCheck[i] && this.listColumnCheck[i].editable) {
                            cell.columnIndex = i;
                            break;
                        }
                    }
                }
                else {
                    cell.columnIndex = this.listColumnCheck.length - 2;
                }
            }
        }
        return cell;
    };
    CompanyPayrollDataNGXComponent.prototype.onCellClick = function (e) {
        this.selectedCell = e;
        this.editedRowIndex = e.rowIndex;
        this.selectedRows = e.dataItem ? [e.dataItem.UserEmploymentId] : [];
        if (!this.sessionService.role.IsReadOnly) {
            Object.assign(this.original, e.dataItem);
            this.formGroup = this.createFormGroup(e.dataItem);
            this.editedRowIndex = e.rowIndex;
            e.sender.editCell(e.rowIndex, e.columnIndex, this.createFormGroup(e.dataItem));
        }
        setTimeout(function () {
            var element = document.querySelector('.k-state-focused input');
            if (element) {
                element.focus();
            }
        });
    };
    CompanyPayrollDataNGXComponent.prototype.onCellClose = function (e) {
        if (this.isColumnDataChanged(e)) {
            this.saveCurrent(e.dataItem, e.column ? e.column.field : undefined);
        }
        var obj = this.addRowTotal();
        this.rowTotal = this.CalculateTotal(obj);
    };
    CompanyPayrollDataNGXComponent.prototype.pageChange = function (event) {
        this.skip = event.skip;
        this.loadView();
    };
    CompanyPayrollDataNGXComponent.prototype.onSalaryTypeConfigrationClicked = function (salaryType) {
        var dataSource = this.availableSalaryTypes
            ? JSON.parse(JSON.stringify(this.availableSalaryTypes))
            : [];
        if (salaryType) {
            var indexSalaryType = dataSource.filter(function (model) { return model.Id === salaryType.Id; });
            if (indexSalaryType && indexSalaryType.length > 0) {
                dataSource.splice(dataSource.indexOf(indexSalaryType[0]), 1);
            }
            dataSource.unshift(salaryType);
        }
        this.configurationSalaryTypeDataSource = dataSource;
        this.selectedSalaryTypeForConfiguration = salaryType;
        this.showSalaryTypeConfiguration = true;
    };
    CompanyPayrollDataNGXComponent.prototype.loadView = function () {
        var _this = this;
        setTimeout(function () {
            try {
                _this.elementdown1 = document
                    .getElementById('companyPayrollDataGrid')
                    .getElementsByClassName('k-grid-aria-root')[0]
                    .getElementsByClassName('k-grid-container')[0]
                    .getElementsByClassName('k-grid-content')[0];
                _this.elementdown2 = document
                    .getElementById('companyPayrollDataGrid')
                    .getElementsByClassName('k-grid-aria-root')[0]
                    .getElementsByClassName('k-grid-container')[0]
                    .getElementsByClassName('k-grid-content-locked')[0];
            }
            catch (e) { }
            if (_this.elementdown2) {
                _this.elementdown2.addEventListener('scroll', function () {
                    setTimeout(function () {
                        _this.elementdown1.scrollTop = _this.elementdown2.scrollTop;
                    }, 100);
                });
            }
        });
        this.gridFilterData = filterBy(this.gridData, this.filter);
        this.gridView = {
            data: this.gridFilterData.slice(this.skip, this.skip + this.pageSize),
            total: this.gridFilterData.length
        };
        var obj = this.addRowTotal();
        this.rowTotal = this.CalculateTotal(obj);
    };
    CompanyPayrollDataNGXComponent.prototype.CalculateTotal = function (result) {
        this.gridFilterData.forEach(function (item) {
            result.SalaryRecord1Amount = item.SalaryRecord1Amount
                ? item.SalaryRecord1Amount + result.SalaryRecord1Amount
                : result.SalaryRecord1Amount;
            result.SalaryRecord1Units = item.SalaryRecord1Units
                ? item.SalaryRecord1Units + result.SalaryRecord1Units
                : result.SalaryRecord1Units;
            result.SalaryRecord2Amount = item.SalaryRecord2Amount
                ? item.SalaryRecord2Amount + result.SalaryRecord2Amount
                : result.SalaryRecord2Amount;
            result.SalaryRecord2Units = item.SalaryRecord2Units
                ? item.SalaryRecord2Units + result.SalaryRecord2Units
                : result.SalaryRecord2Units;
            result.SalaryRecord3Amount = item.SalaryRecord3Amount
                ? item.SalaryRecord3Amount + result.SalaryRecord3Amount
                : result.SalaryRecord3Amount;
            result.SalaryRecord3Units = item.SalaryRecord3Units
                ? item.SalaryRecord3Units + result.SalaryRecord3Units
                : result.SalaryRecord3Units;
            result.SalaryRecord4Amount = item.SalaryRecord4Amount
                ? item.SalaryRecord4Amount + result.SalaryRecord4Amount
                : result.SalaryRecord4Amount;
            result.SalaryRecord4Units = item.SalaryRecord4Units
                ? item.SalaryRecord4Units + result.SalaryRecord4Units
                : result.SalaryRecord4Units;
            result.SalaryRecord5Amount = item.SalaryRecord5Amount
                ? item.SalaryRecord5Amount + result.SalaryRecord5Amount
                : result.SalaryRecord5Amount;
            result.SalaryRecord5Units = item.SalaryRecord5Units
                ? item.SalaryRecord5Units + result.SalaryRecord5Units
                : result.SalaryRecord5Units;
            result.SalaryRecord6Amount = item.SalaryRecord6Amount
                ? item.SalaryRecord6Amount + result.SalaryRecord6Amount
                : result.SalaryRecord6Amount;
            result.SalaryRecord6Units = item.SalaryRecord6Units
                ? item.SalaryRecord6Units + result.SalaryRecord6Units
                : result.SalaryRecord6Units;
            result.SalaryRecord7Amount = item.SalaryRecord7Amount
                ? item.SalaryRecord7Amount + result.SalaryRecord7Amount
                : result.SalaryRecord7Amount;
            result.SalaryRecord7Units = item.SalaryRecord7Units
                ? item.SalaryRecord7Units + result.SalaryRecord7Units
                : result.SalaryRecord7Units;
        });
        result.SalaryRecord1Amount = parseFloat((Math.round(result.SalaryRecord1Amount * 100) / 100).toFixed(2));
        result.SalaryRecord1Units = parseFloat((Math.round(result.SalaryRecord1Units * 100) / 100).toFixed(2));
        result.SalaryRecord2Amount = parseFloat((Math.round(result.SalaryRecord2Amount * 100) / 100).toFixed(2));
        result.SalaryRecord2Units = parseFloat((Math.round(result.SalaryRecord2Units * 100) / 100).toFixed(2));
        result.SalaryRecord3Amount = parseFloat((Math.round(result.SalaryRecord3Amount * 100) / 100).toFixed(2));
        result.SalaryRecord3Units = parseFloat((Math.round(result.SalaryRecord3Units * 100) / 100).toFixed(2));
        result.SalaryRecord4Amount = parseFloat((Math.round(result.SalaryRecord4Amount * 100) / 100).toFixed(2));
        result.SalaryRecord4Units = parseFloat((Math.round(result.SalaryRecord4Units * 100) / 100).toFixed(2));
        result.SalaryRecord5Amount = parseFloat((Math.round(result.SalaryRecord5Amount * 100) / 100).toFixed(2));
        result.SalaryRecord5Units = parseFloat((Math.round(result.SalaryRecord5Units * 100) / 100).toFixed(2));
        result.SalaryRecord6Amount = parseFloat((Math.round(result.SalaryRecord6Amount * 100) / 100).toFixed(2));
        result.SalaryRecord6Units = parseFloat((Math.round(result.SalaryRecord6Units * 100) / 100).toFixed(2));
        result.SalaryRecord7Amount = parseFloat((Math.round(result.SalaryRecord7Amount * 100) / 100).toFixed(2));
        result.SalaryRecord7Units = parseFloat((Math.round(result.SalaryRecord7Units * 100) / 100).toFixed(2));
        return result;
    };
    CompanyPayrollDataNGXComponent.prototype.addRowTotal = function () {
        var obj = {
            DepartmentName: 'Total',
            SalaryRecord1Amount: 0,
            SalaryRecord1Id: undefined,
            SalaryRecord1PerUnit: 0,
            SalaryRecord1Units: 0,
            SalaryRecord2Amount: 0,
            SalaryRecord2Id: undefined,
            SalaryRecord2PerUnit: 0,
            SalaryRecord2Units: 0,
            SalaryRecord3Amount: 0,
            SalaryRecord3Id: undefined,
            SalaryRecord3PerUnit: 0,
            SalaryRecord3Units: 0,
            SalaryRecord4Amount: 0,
            SalaryRecord4Id: undefined,
            SalaryRecord4PerUnit: 0,
            SalaryRecord4Units: 0,
            SalaryRecord5Amount: 0,
            SalaryRecord5Id: undefined,
            SalaryRecord5PerUnit: 0,
            SalaryRecord5Units: 0,
            SalaryRecord6Amount: 0,
            SalaryRecord6Id: undefined,
            SalaryRecord6PerUnit: 0,
            SalaryRecord6Units: 0,
            SalaryRecord7Amount: 0,
            SalaryRecord7Id: undefined,
            SalaryRecord7PerUnit: 0,
            SalaryRecord7Units: 0
        };
        return obj;
    };
    CompanyPayrollDataNGXComponent.prototype.isColumnDataChanged = function (args) {
        var dataChanged;
        var column = args.column, dataItem = args.dataItem, formGroup = args.formGroup;
        if (dataItem && formGroup && formGroup.value && column && column.field) {
            dataChanged =
                ((dataItem[column.field] === null || dataItem[column.field] === undefined) &&
                    (formGroup.value[column.field] !== null && formGroup.value[column.field] !== undefined)) ||
                    (dataItem[column.field] !== null &&
                        dataItem[column.field] !== undefined &&
                        formGroup.value.hasOwnProperty(column.field) &&
                        (formGroup.value[column.field] === null || formGroup.value[column.field] === undefined)) ||
                    (dataItem[column.field] !== null &&
                        dataItem[column.field] !== undefined &&
                        (formGroup.value[column.field] !== null && formGroup.value[column.field] !== undefined) &&
                        dataItem[column.field] !== formGroup.value[column.field]);
        }
        return dataChanged;
    };
    CompanyPayrollDataNGXComponent.prototype.getData = function () {
        // const filterObject: ISalaryRecordsOverviewFilterRequest = {
        //     SalaryCycleId: this.filterCategory === PayrollFilterType.SalaryCycle ? this.salaryCycleId : undefined,
        //     EmploymentTemplateId: this.filterCategory === PayrollFilterType.Template ? this.templateId : undefined,
        //     DepartmentId: this.filterCategory === PayrollFilterType.Department ? this.departmentId : undefined,
        //     IncludeEmployeesWithNoData: this.showEmployeeWithNoData
        // };
        var _this = this;
        var filterObject = {
            SalaryCycleId: this.salaryCycleId ? this.salaryCycleId : undefined,
            EmploymentTemplateId: this.templateId ? this.templateId : undefined,
            DepartmentId: this.departmentId ? this.departmentId : undefined,
            IncludeEmployeesWithNoData: this.showEmployeeWithNoData
        };
        this.dataService
            .SalaryRecords_GetSalaryRecordsOverviewFiltered(filterObject)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.gridData = data;
            _this.pageChange({ skip: 0, take: _this.pageSize });
            _this.showDepartment = _this.gridData.some(function (d) { return !!d.DepartmentName; });
            _this.locked = _this.salaryTypes.length > 0;
            _this.changeDetectorRef.markForCheck();
        });
    };
    CompanyPayrollDataNGXComponent.prototype.getSalaryTypes = function () {
        var _this = this;
        this.dataService.SalaryTypes_GetRawSalaryTypesTranslated().subscribe(function (salaryTypes) {
            salaryTypes.forEach(function (salaryType) {
                _this.assignSalaryTypeText(salaryType);
            });
            // this.availableSalaryTypes = salaryTypes.filter((s: IPayrollSalaryType) => s.IsVisibleValue && s.IsActiveValue && !s.OverviewIndex && s.OverviewIndex !== 0);
            _this.availableSalaryTypes = salaryTypes.filter(function (s) { return s.IsVisibleValue && s.IsActiveValue; });
            _this.salaryTypes = salaryTypes.filter(function (s) { return s.OverviewIndex || s.OverviewIndex === 0; });
            _this.salaryTypes = _this.salaryTypes.sort(function (a, b) {
                return GridHelper.sortByNumberValue(a.OverviewIndex, b.OverviewIndex);
            });
            _this.salaryTypes = _this.salaryTypes.slice(0, 7);
            _this.overviewMaxIndex = 1;
            _this.salaryTypes.forEach(function (salaryType) {
                var unitType;
                if (Global.UNIT_TYPES) {
                    unitType = Global.UNIT_TYPES.find(function (ut) { return ut.Id === salaryType.UnitTypeId; });
                }
                if (salaryType.OverviewIndex >= _this.overviewMaxIndex) {
                    _this.overviewMaxIndex = salaryType.OverviewIndex + 1;
                }
                salaryType.unitTypeTitle = unitType ? unitType.Name : '';
                _this.assignSalaryTypeText(salaryType);
            });
            _this.hasAddNewColumn = !_this.salaryTypes || _this.salaryTypes.length < 7;
            _this.getData();
        });
    };
    CompanyPayrollDataNGXComponent.prototype.assignSalaryTypeText = function (salaryType) {
        var existingSalaryType = this.salaryTypes.find(function (s) { return s.Id === salaryType.Id; });
        salaryType.SalaryTypeTranslations = salaryType.SalaryTypeTranslations
            ? salaryType.SalaryTypeTranslations
            : existingSalaryType
                ? existingSalaryType.SalaryTypeTranslations
                : undefined;
        salaryType.BaseSalaryType = salaryType.BaseSalaryType
            ? salaryType.BaseSalaryType
            : existingSalaryType
                ? existingSalaryType.BaseSalaryType
                : undefined;
        salaryType.IsActiveValue =
            salaryType.IsActive !== null
                ? salaryType.IsActive
                : salaryType.BaseSalaryType
                    ? salaryType.BaseSalaryType.IsActive
                    : false;
        salaryType.IsVisibleValue =
            salaryType.IsVisible !== null
                ? salaryType.IsVisible
                : salaryType.BaseSalaryType
                    ? salaryType.BaseSalaryType.IsVisible
                    : false;
        if (!salaryType.SalaryTypeTranslations) {
            return;
        }
        var languageId = Global.SESSION.SignOnToken.LanguageId;
        if (Global.SESSION.SignOnToken.LanguageId === 1) {
            salaryType.Name =
                salaryType.SalaryTypeTranslations[0].Name && this.hasLanguageModule
                    ? salaryType.SalaryTypeTranslations[0].Name
                    : salaryType.Name;
        }
        else if (Global.SESSION.SignOnToken.LanguageId === 2) {
            salaryType.Name =
                salaryType.SalaryTypeTranslations[1].Name && this.hasLanguageModule
                    ? salaryType.SalaryTypeTranslations[1].Name
                    : salaryType.Name;
        }
        else if (Global.SESSION.SignOnToken.LanguageId === 3) {
            salaryType.Name =
                salaryType.SalaryTypeTranslations[2].Name && this.hasLanguageModule
                    ? salaryType.SalaryTypeTranslations[2].Name
                    : salaryType.Name;
        }
        salaryType.displayName =
            salaryType.Name !== null
                ? salaryType.Name
                : salaryType.BaseSalaryType
                    ? salaryType.BaseSalaryType.Name
                    : undefined;
    };
    CompanyPayrollDataNGXComponent.prototype.saveCurrent = function (dataItem, property) {
        var _this = this;
        if (!dataItem || !property) {
            return;
        }
        var index = property
            .replace('SalaryRecord', '')
            .replace('PerUnit', '')
            .replace('Units', '')
            .replace('Amount', '');
        if (index === undefined) {
            return;
        }
        index = parseInt(index, 10);
        var formGroupValue = this.formGroup.value[property];
        var updatedValue = dataItem[property];
        if (formGroupValue !== updatedValue) {
            var salaryRecordId = dataItem["SalaryRecord" + index + "Id"];
            var calculator = this.createSalaryRecordCalculation({
                Units: dataItem["SalaryRecord" + index + "Units"],
                AmountPerUnit: dataItem["SalaryRecord" + index + "PerUnit"],
                Amount: dataItem["SalaryRecord" + index + "Amount"]
            });
            var request = this.createSalaryRecordUpdateRequest(calculator, property, updatedValue);
            dataItem["SalaryRecord" + index + "Units"] = request.Units;
            dataItem["SalaryRecord" + index + "PerUnit"] = request.AmountPerUnit;
            dataItem["SalaryRecord" + index + "Amount"] = request.Amount;
            if (salaryRecordId) {
                request.Id = salaryRecordId;
                this.dataService.SalaryRecords_UpdateSalaryRecordSimple(request).subscribe(function () { }, function () {
                    _this.getSalaryTypes();
                });
            }
            else {
                request.UserEmploymentId = this.original.UserEmploymentId;
                request.SalaryTypeId = this.salaryTypes[index - 1].Id;
                this.dataService.SalaryRecords_CreateSalaryRecordSimple(request).subscribe(function (data) {
                    dataItem["SalaryRecord" + index + "Id"] = data.Id;
                }, function () {
                    _this.getSalaryTypes();
                });
            }
        }
    };
    CompanyPayrollDataNGXComponent.prototype.createSalaryRecordCalculation = function (dataItem) {
        var calculator = new SalaryRecordCalculation();
        calculator.Amount = dataItem.Amount;
        calculator.Units = dataItem.Units;
        calculator.AmountPerUnit = dataItem.AmountPerUnit;
        calculator.UnitType = UnitTypeEnum.Days;
        return calculator;
    };
    CompanyPayrollDataNGXComponent.prototype.createSalaryRecordUpdateRequest = function (calculator, property, value) {
        value = value && value !== 0 ? value : undefined;
        var isAmountPerUnitProperty = property.indexOf('PerUnit') > -1;
        var isUnitsProperty = !isAmountPerUnitProperty && property.indexOf('Units') > -1;
        var isAmountProperty = !isAmountPerUnitProperty && property.indexOf('Amount') > -1;
        var calculationResult;
        if (isAmountPerUnitProperty) {
            calculationResult = this.updateAmountPerUnit(calculator, value);
        }
        if (isUnitsProperty) {
            calculationResult = this.updateUnits(calculator, value);
        }
        if (isAmountProperty) {
            calculationResult = this.updateAmount(calculator, value);
        }
        var updateSalaryRecord = {
            Id: 0,
            AmountPerUnit: calculationResult.amountPerUnit,
            Amount: calculationResult.amount,
            Units: calculationResult.units
        };
        return updateSalaryRecord;
    };
    CompanyPayrollDataNGXComponent.prototype.updateAmountPerUnit = function (calculator, value) {
        calculator.AmountPerUnit = value;
        calculator.calculateAmount();
        calculator.calculateUnits();
        return {
            amountPerUnit: value,
            amount: calculator.Amount,
            units: calculator.Units
        };
    };
    CompanyPayrollDataNGXComponent.prototype.updateAmount = function (calculator, value) {
        calculator.Amount = value;
        calculator.calculateUnits();
        return {
            amountPerUnit: calculator.AmountPerUnit,
            amount: calculator.Amount,
            units: calculator.Units
        };
    };
    CompanyPayrollDataNGXComponent.prototype.updateUnits = function (calculator, value) {
        calculator.Units = value;
        calculator.calculateAmount();
        return {
            amountPerUnit: calculator.AmountPerUnit,
            amount: calculator.Amount,
            units: calculator.Units
        };
    };
    CompanyPayrollDataNGXComponent.prototype.createFormGroup = function (dataItem) {
        return new FormGroup({
            FullName: new FormControl(dataItem ? dataItem.FullName : undefined),
            DepartmentName: new FormControl(dataItem ? dataItem.DepartmentName : undefined),
            MultipleRecordsExist1: new FormControl(dataItem ? dataItem.MultipleRecordsExist1 : undefined),
            SalaryRecord1Units: new FormControl(dataItem ? dataItem.SalaryRecord1Units : undefined),
            SalaryRecord1PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord1PerUnit : undefined),
            SalaryRecord1Amount: new FormControl(dataItem ? dataItem.SalaryRecord1Amount : undefined),
            MultipleRecordsExist2: new FormControl(dataItem ? dataItem.MultipleRecordsExist2 : undefined),
            SalaryRecord2Units: new FormControl(dataItem ? dataItem.SalaryRecord2Units : undefined),
            SalaryRecord2PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord2PerUnit : undefined),
            SalaryRecord2Amount: new FormControl(dataItem ? dataItem.SalaryRecord2Amount : undefined),
            MultipleRecordsExist3: new FormControl(dataItem ? dataItem.MultipleRecordsExist3 : undefined),
            SalaryRecord3Units: new FormControl(dataItem ? dataItem.SalaryRecord3Units : undefined),
            SalaryRecord3PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord3PerUnit : undefined),
            SalaryRecord3Amount: new FormControl(dataItem ? dataItem.SalaryRecord3Amount : undefined),
            MultipleRecordsExist4: new FormControl(dataItem ? dataItem.MultipleRecordsExist4 : undefined),
            SalaryRecord4Units: new FormControl(dataItem ? dataItem.SalaryRecord4Units : undefined),
            SalaryRecord4PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord4PerUnit : undefined),
            SalaryRecord4Amount: new FormControl(dataItem ? dataItem.SalaryRecord4Amount : undefined),
            MultipleRecordsExist5: new FormControl(dataItem ? dataItem.MultipleRecordsExist5 : undefined),
            SalaryRecord5Units: new FormControl(dataItem ? dataItem.SalaryRecord5Units : undefined),
            SalaryRecord5PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord5PerUnit : undefined),
            SalaryRecord5Amount: new FormControl(dataItem ? dataItem.SalaryRecord5Amount : undefined),
            MultipleRecordsExist6: new FormControl(dataItem ? dataItem.MultipleRecordsExist6 : undefined),
            SalaryRecord6Units: new FormControl(dataItem ? dataItem.SalaryRecord6Units : undefined),
            SalaryRecord6PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord6PerUnit : undefined),
            SalaryRecord6Amount: new FormControl(dataItem ? dataItem.SalaryRecord6Amount : undefined),
            MultipleRecordsExist7: new FormControl(dataItem ? dataItem.MultipleRecordsExist7 : undefined),
            SalaryRecord7Units: new FormControl(dataItem ? dataItem.SalaryRecord7Units : undefined),
            SalaryRecord7PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord7PerUnit : undefined),
            SalaryRecord7Amount: new FormControl(dataItem ? dataItem.SalaryRecord7Amount : undefined)
        });
    };
    CompanyPayrollDataNGXComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridFilterData = filterBy(this.gridData, this.filter);
        this.gridView = {
            data: this.gridFilterData.slice(this.skip, this.skip + this.pageSize),
            total: this.gridFilterData.length
        };
        var obj = this.addRowTotal();
        this.rowTotal = this.CalculateTotal(obj);
    };
    CompanyPayrollDataNGXComponent.prototype.ngAfterViewInit = function () { };
    return CompanyPayrollDataNGXComponent;
}());
export { CompanyPayrollDataNGXComponent };
