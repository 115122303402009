import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Global } from '../../../../Common/Global';
import { ISimpleKeyValuePair } from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';

@Component({
  selector: 'negative-default-info',
  templateUrl: './NegativeDefaultMoreInfoDialog.component.html'
})
export class NegativeDefaultMoreInfoDialogComponent {
  @Output() public showDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public usePositiveAmount: EventEmitter<any> = new EventEmitter<any>();
  @Output() public enableAutoCorrect: EventEmitter<any> = new EventEmitter<any>();

  @Input() public setting = true;
  @Input() public fieldName: string;
  @Input() public dataItem: any;

  @Input()
  get showDialog(): boolean {
    return this.showDialogValue;
  }

  set showDialog(value: boolean) {
    this.showDialogValue = value;
    this.showDialogChange.emit(value);
  }

  constructor(public dataService: RxDataService) {}

  onUsePositiveAmount(): void {
    this.usePositiveAmount.emit({ dataItem: this.dataItem, fieldName: this.fieldName });
  }

  onEnableAutoCorrect(): void {
    const userPref: ISimpleKeyValuePair = { Key: 'SalaryRecords.AutoFlipAmounts', Value: 'true' };
    this.dataService.Preferences_SetUserPreference(userPref).subscribe(() => {
      this.updateUserPreference(userPref);
      this.enableAutoCorrect.emit({ dataItem: this.dataItem, fieldName: this.fieldName });
    });
  }

  disableAutoCorrect(): void {
    const userPref: ISimpleKeyValuePair = { Key: 'SalaryRecords.AutoFlipAmounts', Value: 'false' };
    this.dataService.Preferences_SetUserPreference(userPref).subscribe(() => {
      this.updateUserPreference(userPref);
      this.usePositiveAmount.emit({ dataItem: this.dataItem, fieldName: this.fieldName });
    });
  }
  public showDialogValue = false;
  public onDialogAction(action: string): void {
    this.showDialogChange.emit(false);
    if (action === 'UsePositiveAmountThisTime') {
      this.onUsePositiveAmount();
    }
    if (action === 'DisableThisBehaviour') {
      this.disableAutoCorrect();
    }
    if (action === 'EnableAutoCorrect') {
      this.onEnableAutoCorrect();
    }
  }

  public updateUserPreference(userPref: ISimpleKeyValuePair): void {
    const userPreferences: ISimpleKeyValuePair[] = Global.USER_PREFERENCES;
    let updatedUserPref: ISimpleKeyValuePair;
    if (userPreferences) {
      updatedUserPref = userPreferences.find((data: ISimpleKeyValuePair) => {
        return data.Key === userPref.Key;
      });
    }

    if (updatedUserPref) {
      updatedUserPref.Value = userPref.Value;
    } else {
      userPreferences.push(userPref);
    }

    Global.USER_PREFERENCES = userPreferences;
  }
}
