import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'action-button',
  templateUrl: './ActionButton.component.html'
})
export class ActionButtonComponent {
  @Output() public click: EventEmitter<any> = new EventEmitter<any>();

  @Input() public buttonClass: string;
  @Input() public label: string;
  @Input() public icon: string;
  @Input() public iconSize: string;
  @Input() public tooltip: string;
  @Input() public disabled: boolean;
}
