import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../Services/SharedServices/Session/SessionService';

@Pipe({
  name: 'DateFormat'
})
export class DateFormatPipe implements PipeTransform {
  constructor(private sessionService: SessionService) {}

  public transform(value: Date | string, format?: string, yearbreak?: boolean, includeTime: boolean = false): string {
    if (!value) {
      return '';
    }

    const date: Date = new Date(value);
    if (format) {
      return this.sessionService.toString(date, format);
    }

    let dateString: string;
    if (typeof value === 'string') {
      dateString = this.sessionService.toString(value ? this.sessionService.parseDate(value) : undefined);
    } else {
      dateString = this.sessionService.toString(value);
    }

    if (yearbreak) {
      let yearSeparatorIndex: number = dateString.lastIndexOf('-');
      yearSeparatorIndex = yearSeparatorIndex >= 0 ? yearSeparatorIndex : dateString.lastIndexOf('/');

      dateString =
        dateString.slice(0, yearSeparatorIndex) +
        dateString.slice(yearSeparatorIndex, yearSeparatorIndex + 1) +
        '<wbr>' +
        dateString.slice(yearSeparatorIndex + 1, dateString.length);
    }

    if (includeTime) {
      if (typeof value === 'string') {
        dateString += ' ' + this.sessionService.toString(this.sessionService.parseDate(value), 'HH:mm');
      } else {
        dateString += ' ' + this.sessionService.toString(value, 'HH:mm');
      }
    }

    return dateString;
  }
}
