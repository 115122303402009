import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';
var SenioritySupplementComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SenioritySupplementComponent, _super);
    function SenioritySupplementComponent(staticDataService, sessionService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.sessionService = sessionService;
        _this.visibleValue = false;
        _this.visibleChange = new EventEmitter();
        _this.allowCustom = true;
        _this.numericOptions = {
            format: 'n2',
            decimals: 2,
            step: 1,
            min: 0,
            spinners: false
        };
        _this.dirty = false;
        _this.refreshData = false;
        _this.keyPress = { 17: false, 18: false, 107: false, 109: false };
        return _this;
    }
    Object.defineProperty(SenioritySupplementComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            var _this = this;
            if (this.visibleValue !== value) {
                if (value) {
                    document.getElementById('seniorityDialog').addEventListener('keydown', function (event) {
                        _this.onKeyDown(event);
                    });
                }
                else {
                    document.getElementById('seniorityDialog').removeEventListener('keydown', function (event) {
                        //this.onKeyDown(event);
                    });
                }
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    SenioritySupplementComponent.prototype.ngOnInit = function () {
        var _this = this;
        // document.addEventListener("keydown", (event: any): void => {
        //     this.onKeyDown(event);
        // });
        this.staticDataService.IntervalType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.intervalTypes = data); });
        this.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (value) {
            if (value) {
                _this.dirty = false;
            }
        });
    };
    SenioritySupplementComponent.prototype.hasChanges = function () {
        return this.dirty;
    };
    SenioritySupplementComponent.prototype.saveChanges = function (args) {
        ////let dataItem: any = args.dataItem ? args.dataItem : args;
        this.dirty = true;
        this.onChange();
    };
    SenioritySupplementComponent.prototype.addSeniorityHandler = function (dataItem) {
        var defaultDataItem = this.createNewDataItem();
        Object.assign(dataItem, defaultDataItem);
        this.dirty = true;
        this.onChange();
    };
    SenioritySupplementComponent.prototype.removeSeniorityHandler = function (args) {
        // if (args && args.Id) {
        //     this.dirty = true;
        //     this.onChange();
        // } else {
        //    let dataIndex: any = this.seniority.indexOf(args);
        //    this.seniority.splice(dataIndex, 1);
        // }
        this.dirty = true;
        this.onChange();
    };
    SenioritySupplementComponent.prototype.createNewDataItem = function () {
        return {
            Id: null,
            EmploymentTemplateId: this.templateId,
            SeniorityMonthsStart: 0,
            SeniorityMonthsEnd: 0,
            SupplementAmount: 0,
            SupplementIntervalType: this.intervalTypes ? this.intervalTypes[0].Id : null
        };
    };
    SenioritySupplementComponent.prototype.onKeyDown = function (event) {
        if (this.visible) {
            if (this.editMode) {
                if (event.keyCode in this.keyPress) {
                    this.keyPress[event.keyCode] = true;
                    if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
                        var defaultDataItem = {};
                        this.addSeniorityHandler(defaultDataItem);
                        this.setKeypress();
                        this.seniority.push(defaultDataItem);
                        this.refreshData = true;
                    }
                    else if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
                        if (this.selectedItem) {
                            this.removeSeniorityHandler(this.selectedItem);
                            this.setKeypress();
                            this.refreshData = true;
                            this.selectedItem = undefined;
                        }
                    }
                }
                else {
                    this.setKeypress();
                }
            }
        }
    };
    SenioritySupplementComponent.prototype.setKeypress = function () {
        this.keyPress[17] = false;
        this.keyPress[18] = false;
        this.keyPress[107] = false;
        this.keyPress[109] = false;
    };
    return SenioritySupplementComponent;
}(FormComponentBase));
export { SenioritySupplementComponent };
