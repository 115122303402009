/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../CustomControls/Icon/Icon.component.ngfactory";
import * as i2 from "../CustomControls/Icon/Icon.component";
import * as i3 from "../Services/SharedServices/Session/SessionService";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "../Main/Preference.component.ngfactory";
import * as i7 from "../Main/Preference.component";
import * as i8 from "../Services/RxDataService";
import * as i9 from "../Services/SharedServices/StaticData.service";
import * as i10 from "./Account.component";
var styles_AccountComponent = [];
var RenderType_AccountComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AccountComponent, data: {} });
export { RenderType_AccountComponent as RenderType_AccountComponent };
function View_AccountComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-icon", [], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(1, 49152, null, 0, i2.IconComponent, [i0.ElementRef, i3.SessionService, i0.ChangeDetectorRef], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "LevelDown"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AccountComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-icon", [], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(1, 49152, null, 0, i2.IconComponent, [i0.ElementRef, i3.SessionService, i0.ChangeDetectorRef], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "LevelUp"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AccountComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "ul", [["class", "TabPanel"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "button", [["class", "TabPanel-link is-active"], ["type", "button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-icon", [], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(5, 49152, null, 0, i2.IconComponent, [i0.ElementRef, i3.SessionService, i0.ChangeDetectorRef], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "TabPanel-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 10, "li", [["style", "margin-left: auto;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 9, "div", [["class", "Menu-toogle-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 8, "button", [["class", "TabPanel-link"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMenuButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(13, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(14, { "is-active": 0 }), (_l()(), i0.ɵeld(15, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AccountComponent_2)), i0.ɵdid(17, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AccountComponent_3)), i0.ɵdid(19, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "User"; _ck(_v, 5, 0, currVal_0); var currVal_2 = "TabPanel-link"; var currVal_3 = _ck(_v, 14, 0, _co.sessionService.menuToggled); _ck(_v, 13, 0, currVal_2, currVal_3); var currVal_4 = _co.sessionService.menuToggled; _ck(_v, 17, 0, currVal_4); var currVal_5 = !_co.sessionService.menuToggled; _ck(_v, 19, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("Preference.UserOption")); _ck(_v, 7, 0, currVal_1); }); }
function View_AccountComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "Island"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "preference", [["owner", "company"]], null, null, null, i6.View_PreferenceComponent_0, i6.RenderType_PreferenceComponent)), i0.ɵdid(3, 180224, null, 0, i7.PreferenceComponent, [i8.RxDataService, i3.SessionService, i9.StaticDataService], { owner: [0, "owner"] }, null)], function (_ck, _v) { var currVal_0 = "company"; _ck(_v, 3, 0, currVal_0); }, null); }
function View_AccountComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "Island"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "preference", [["owner", "user"]], null, null, null, i6.View_PreferenceComponent_0, i6.RenderType_PreferenceComponent)), i0.ɵdid(3, 180224, null, 0, i7.PreferenceComponent, [i8.RxDataService, i3.SessionService, i9.StaticDataService], { owner: [0, "owner"] }, null)], function (_ck, _v) { var currVal_0 = "user"; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AccountComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AccountComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "Main Scrollable"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AccountComponent_4)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AccountComponent_5)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUserPreferenceTabActive; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isCompanyPreferenceTabActive; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.isUserPreferenceTabActive; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_AccountComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "account", [], null, null, null, View_AccountComponent_0, RenderType_AccountComponent)), i0.ɵdid(1, 49152, null, 0, i10.AccountComponent, [i3.SessionService], null, null)], null, null); }
var AccountComponentNgFactory = i0.ɵccf("account", i10.AccountComponent, View_AccountComponent_Host_0, {}, {}, []);
export { AccountComponentNgFactory as AccountComponentNgFactory };
