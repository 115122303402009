<!-- TabPanel -->
<div [ngClass]="showEmployeeList ? 'with-employeeFilter' : ''">
  <app-tab-panel [parentTab]="'employee'"></app-tab-panel>
  <div [ngClass]="editMode ? 'is-editing' : ''" (dblclick)="PreonDoubleClick()" (click)="clickAreamenuToggledChange()">
    <!-- Main -->
    <div class="Main Scrollable" id="divEmployeeTabs" tabindex="-1">
      <!-- EmployeeView -->
      <div class="EmployeeView">
        <app-employee-info
          [companyUser]="employee"
          [editMode]="editMode"
          [allowEdit]="allowEditUserInfo"
          [allowEditRole]="allowEditRole"
          (isAvatarChanged)="onAvatarChanged()"
          [currentPositionId]="currentPosition"
          [newEmploymentCreated]="newEmployment"
          [deletedEmployment]="deletedEmployment"
          [isEmployeeTab]="true"
          (validated)="onEmployeeInfoValidated($event)"
          (selectedUserEmploymentChange)="onUserEmploymentChange($event)"
          (employmentPositionChanged)="onPositionChanged($event)"
          [(avatarChange)]="avatarChange"
        ></app-employee-info>

        <!-- Tab content -->
        <div class="Island">
          <div *ngIf="employeeGeneralTabVisible">
            <app-employee-general
              [(companyUser)]="employee"
              [(userEmployment)]="currentUserEmployment"
              [editMode]="editMode"
              [allowEdit]="allowEditUserInfo"
              [(resetPassword)]="resetPasswordFired"
              [(resendWelcomeEmail)]="resendWelcomeEmailFired"
              [(isValid)]="isTabsValid"
              [(isDirty)]="isDirty"
              (reloadUserEmployment)="onReloadUserEmploymentEvent()"
            >
            </app-employee-general>
          </div>
          <div *ngIf="employeeHiringStatusTabVisible">
            <app-employee-hiring-status
              [userEmployment]="currentUserEmployment"
              [editMode]="editMode"
              [allowEdit]="allowEditUserInfo"
              [(isValid)]="isTabsValid"
              [(isDirty)]="isDirty"
              [(companyUser)]="employee"
              (createUserEmploymentClick)="createNewUserEmployment()"
              (reloadUserEmployment)="onReloadUserEmploymentEvent()"
            >
            </app-employee-hiring-status>
          </div>
          <div *ngIf="employeeTimeTabVisible">
            <employee-time
              [currentCompanyUser]="employee"
              [userEmployment]="currentUserEmployment"
              (createUserEmploymentClick)="createNewUserEmployment()"
              [(isAddNew)]="createNewtimeEntryDialogVisable"
            >
            </employee-time>
          </div>
          <div class="EmployeePayrollDataWrapper" *ngIf="employeePayrollDataTabVisible">
            <employee-payroll-data
              [userEmployment]="currentUserEmployment"
              [allowEdit]="allowEditUserInfo"
              (createUserEmploymentClick)="createNewUserEmployment()"
              [(isAddNew)]="createNewPayrolldataDialogVisable"
            ></employee-payroll-data>
          </div>
          <div *ngIf="employeePayslipTabVisible" id="employeePayslipView">
            <payslip
              [employee]="employee"
              [allowEdit]="allowEditUserInfo"
              (createUserEmploymentClick)="createNewUserEmployment()"
            ></payslip>
          </div>
        </div>
        <!-- /Tab content -->

        <app-feedback [hidden]="editMode"></app-feedback>
      </div>
      <!-- /EmployeeView -->
      <!-- Actions -->
      <div class="Actions">
        <action-button
          *ngIf="allowEditUserEmployment"
          id="ActionButtonAdd"
          [buttonClass]="'Action-button Action-buttonAdd'"
          [icon]="'AddUser'"
          [label]="'Employee.New'"
          (click)="onAddNewEmployee()"
          [tooltip]="'Employee.NewEmployee'"
        >
        </action-button>

        <action-button
          *ngIf="reportViewEnabled"
          id="ActionButtonReports"
          [buttonClass]="'Action-button Action-buttonReports'"
          [icon]="'File'"
          [label]="'Employee.Reports'"
          (click)="onShowReportsEventClick()"
          [tooltip]="'Employee.Reports'"
        >
        </action-button>

        <more-menu
          [module]="'employee'"
          *ngIf="moreButtonVisisble && !isPayrolldataMobile"
          [showMatchTemplateMenu]="employmentNotBaseOnTemplate"
          [currentTab]="activeTab"
          (menuClick)="onMoreMenuOptionClick($event)"
        >
        </more-menu>

        <help-button [module]="'employee'"></help-button>

        <edit-button
          [module]="'employee'"
          *ngIf="isShowAction"
          [isVisible]="allowEditUserEmployment"
          [editMode]="editMode"
          (change)="onEditModeChange($event)"
        ></edit-button>

        <action-button
          class="{{ disabledActionButtonClass }}"
          *ngIf="(!isMobileInEmployeetime || !isMobileInSelfservicetime) && !sessionService.role.IsReadOnly"
          [buttonClass]="'Action-button Action-buttonAdd'"
          [icon]="'Plus'"
          [label]="'Employee.New'"
          (click)="onAddNewTimeEntry()"
          [tooltip]="'Employee.NewEmployee'"
          [disabled]="sessionService.isGetting"
        >
        </action-button>

        <action-button
          class="{{ disabledActionButtonClass }}"
          *ngIf="!isMobileInEmployeepayrollData && !sessionService.role.IsReadOnly && hasEmployment"
          [buttonClass]="'Action-button Action-buttonAdd'"
          [icon]="'Plus'"
          [label]="'Employee.New'"
          (click)="onAddNewPayrollData()"
          [tooltip]="'Employee.NewEmployee'"
          [disabled]="sessionService.isGetting"
        >
        </action-button>
      </div>
      <div class="EditingInfo" *ngIf="editMode"></div>
      <button-component
        [isUseOneClick]="true"
        [buttonClass]="'EditingInfo Discard'"
        (click)="confirmAndDiscardChanges()"
        [label]="'Employee.DiscardButton' | translate"
        *ngIf="editMode"
      >
      </button-component>
      <!-- /Actions -->
    </div>
  </div>

  <!--dialogs-->
  <new-employee
    [(isVisible)]="createNewEmployeeDialogVisible"
    (newEmployeeCreated)="onNewEmployeeCreatedEvent($event)"
  ></new-employee>

  <new-employment
    [currentCompanyUserId]="employee?.Id"
    [(isVisible)]="createNewEmploymentDialogVisible"
    (newEmploymentCreated)="onNewEmploymentCreatedEvent($event)"
  ></new-employment>

  <app-report-dialog [(visible)]="reportDialogVisible" [key]="reportKey" [param]="reportParams"></app-report-dialog>

  <app-dialog [(visible)]="noReportVisible" [leadingText]="'Report.NoReport' | translate">
    <app-dialog-action type="Ok"></app-dialog-action>
  </app-dialog>

  <delete-employee
    [currentCompanyUser]="employee"
    [currentUserEmployment]="currentUserEmployment"
    [(isVisible)]="deleteEmployeeDialogVisible"
    (employeeDeleted)="deletedEmployee($event)"
    (userEmploymentDeleted)="deletedUserEmployment($event)"
  >
  </delete-employee>
  <!--dialogs-->
</div>

<app-dialog [(visible)]="isNoTemplate" [leadingText]="'NewEmployeeEmployment.NoEmploymentTemplateWarning' | translate">
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="discardDialogAction($event)"
  [(visible)]="discardDialogVisible"
  [leadingText]="'Employee.DiscardMessage' | translate"
>
  <app-dialog-action type="CancelDiscard"></app-dialog-action>
  <app-dialog-action type="ConfirmDiscard"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onUnsavedDataDialogAction($event)"
  [(visible)]="unsavedDataDialogVisible"
  [leadingText]="'Employee.UnsavedData' | translate"
>
  <app-dialog-action type="DiscardAndLeave"></app-dialog-action>
  <app-dialog-action type="Continue"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onSynchTemplateDialogAction($event)"
  [(visible)]="synchTemplateDialogVisible"
  [leadingText]="'Employee.SyncTemplateBaseOnTemplate' | translate"
>
  <app-dialog-action type="No"></app-dialog-action>
  <app-dialog-action type="Yes"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="createTemplateTemplateCompletedDialogVisible"
  [leadingText]="'EmployeeEmployment.Create template completed message' | translate"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onCreateTemplateConfirmationDialogAction($event)"
  [(visible)]="createTemplateConfirmationDialogVisible"
  [leadingText]="'EmployeeEmployment.Confirm create template' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Proceed"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onTemplateNameDialogAction($event)" [(visible)]="templateNameDialogVisible">
  <app-text-edit
    [editMode]="true"
    [(value)]="newTemplateName"
    [label]="'CompanyHiringStatus.Template name' | translate"
  ></app-text-edit>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Create"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="showInvalidFormWarning">
  <div [innerHTML]="'Validation.InvalidForm' | translate"></div>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onConfirmSaveDataOnChangeState($event)" [(visible)]="showSaveDataConfirmationChangeState">
  <div [innerHTML]="'Employee.UnsavedData' | translate"></div>
  <app-dialog-action type="DiscardAndLeave"></app-dialog-action>
  <app-dialog-action type="Stay"></app-dialog-action>
  <app-dialog-action type="ContinueAndSave"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onDialogSynchronizeEmploymentWithTemplate($event)"
  [(visible)]="isOnDialogSynchronizeEmploymentWithTemplateVisble"
  [leadingText]="'Employee.MatchWithTemplateConfirm' | translate"
>
  <app-dialog-action type="No"></app-dialog-action>
  <app-dialog-action type="Yes"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="resendWelcomeEmail"
  (action)="onresendEmployeeWelcomEmailConfirmCompleted($event)"
  [leadingText]="'More.ResendEmailUpdate' | translate"
>
  <app-dialog-action type="NoThankYou"></app-dialog-action>
  <app-dialog-action type="YesPlease" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>
<app-dialog [(visible)]="resendEmailSuccessVisible" [leadingText]="'More.ResendEmailSuccessNotification' | translate">
  <app-dialog-action type="Ok" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>
