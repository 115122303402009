import { Component, Input } from '@angular/core';

@Component({
  selector: 'salary-batch-salary-totals',
  templateUrl: './SalaryBatchSalaryTotals.component.html'
})
export class SalaryBatchSalaryTotalsComponent {
  @Input() public SalaryBatchId: number;
  @Input() public SalaryTypeTotals: any[];
  @Input() public UnitTotals: any[];
  @Input() public showTitle = true;

  public view: 'salarytypes' | 'units' = 'salarytypes';

  constructor() {}
}
