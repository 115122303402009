import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormComponentBase } from '../Common/FormComponentBase';
var SharePercentageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SharePercentageComponent, _super);
    function SharePercentageComponent() {
        var _this = _super.call(this) || this;
        _this.firstValueChange = new EventEmitter();
        _this.secondValueChange = new EventEmitter();
        _this.childIsDirty = Array(2).fill(false);
        return _this;
    }
    Object.defineProperty(SharePercentageComponent.prototype, "firstValue", {
        get: function () {
            return this.currentfirstValue;
        },
        set: function (value) {
            if (this.currentfirstValue !== value) {
                this.currentfirstValue = value;
                this.firstValueChange.emit(this.currentfirstValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SharePercentageComponent.prototype, "secondValue", {
        get: function () {
            return this.currentsecondValue;
        },
        set: function (value) {
            if (this.currentsecondValue !== value) {
                this.currentsecondValue = value;
                this.secondValueChange.emit(this.currentsecondValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    return SharePercentageComponent;
}(FormComponentBase));
export { SharePercentageComponent };
