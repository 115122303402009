import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { RxDataService } from '../../../Services/RxDataService';
var CompanyContactComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyContactComponent, _super);
    function CompanyContactComponent(dataService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.resetChildFromChange = new EventEmitter();
        _this.childIsDirty = Array(7).fill(false);
        _this.childIsValid = Array(3).fill(true);
        return _this;
    }
    Object.defineProperty(CompanyContactComponent.prototype, "resetChildFrom", {
        set: function (value) {
            if (value) {
                this.childIsDirty.fill(false);
                this.childIsValid.fill(true);
            }
            this.resetChildFromChange.emit(false);
        },
        enumerable: true,
        configurable: true
    });
    CompanyContactComponent.prototype.ngOnInit = function () {
        this.getCurrentCompanyLogo();
    };
    CompanyContactComponent.prototype.onCompanyLogoChanged = function (formData) {
        var _this = this;
        this.dataService.Companies_UploadCompanyLogo(formData).subscribe(function (data) {
            _this.logo = data;
        }, function (error) { return _this.getCurrentCompanyLogo(); });
    };
    CompanyContactComponent.prototype.getCurrentCompanyLogo = function () {
        var _this = this;
        this.dataService.Companies_GetCompanyLogo().subscribe(function (logo) {
            _this.logo = logo;
        });
    };
    return CompanyContactComponent;
}(FormComponentBase));
export { CompanyContactComponent };
