import { Injectable } from '@angular/core';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileTransfer, FileTransferObject, FileUploadOptions } from '@ionic-native/file-transfer/ngx';
import * as Raven from 'raven-js';
import { environment } from '../../../environments/environment';
import { Constants } from '../../Common/Constants';
import { Utf8ArrayToStr } from '../../Common/Utf8';
import { Broadcaster } from '../Broadcaster';
import { ModalService } from './ModalService';
import { SessionService } from './Session/SessionService';

@Injectable()
export class UploadService {
  private fileTransfer: FileTransferObject = this.transfer.create();
  private host: string;

  constructor(
    private modalService: ModalService,
    private sessionService: SessionService,
    private broadcaster: Broadcaster,
    private fileChooser: FileChooser,
    private transfer: FileTransfer,
    private filePath: FilePath
  ) {
    this.host = environment.apiUrl;
  }

  public get isCordova(): boolean {
    return this.sessionService.browser.isHybridApp;
  }

  public getFileName(filePath: string): string {
    return filePath ? filePath.split('/').pop() : undefined;
  }

  public openFileChooser(): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (value: any) => void) => {
      if (this.fileChooser) {
        this.fileChooser
          .open()
          .then((uri: string) => {
            this.filePath
              .resolveNativePath(uri)
              .then((resolveUri: any) => {
                return resolve(resolveUri);
              })
              .catch((error: any) => {
                return resolve(uri);
              });
          })
          .catch((err: any) => {
            return reject(err);
          });
      } else {
        return reject('No cordova file chooser found!');
      }
    });
  }

  public upload(api: string, filePath: string, params?: any, trustAllHosts: boolean = true): Promise<any> {
    return new Promise<any>((resolve: (value: any) => void, reject: (value: any) => void) => {
      if (!api || !filePath) {
        console.log('File Transfer ::: No required parameter supllied.');
        return reject('No required parameter supllied.');
      }
      let authorization = '';
      try {
        authorization = sessionStorage.getItem('token');
      } catch (e) {
        this.sessionService.isDetectedCookieDisable = true;
      }
      const server: string = this.host + api;
      const options: FileUploadOptions = { headers: { Authorization: authorization } };
      options.params = params ? params : undefined;

      this.fileTransfer
        .upload(server, filePath, options, trustAllHosts)
        .then((result: any) => {
          const response: any = JSON.parse(result.response);
          return resolve(response);
        })
        .catch((error: any) => {
          this.handleUnexpectedErrors(error);
          return reject(error);
        });
    });
  }

  private handleUnexpectedErrors(error: any): void {
    const errorData: any = JSON.parse(error.body);
    if (!error.http_status) {
      // Network error.
      this.modalService.alert('', this.sessionService.modalTranslations['Error.NetworkError']);
    } else {
      switch (error.http_status) {
        case 400: // Bad request.
          if (errorData.Message) {
            this.modalService.alert('', errorData.Message);
          } else {
            const array: Uint8Array = new Uint8Array(errorData);
            const decodedString: string = Utf8ArrayToStr(array);
            const obj: any = JSON.parse(decodedString);
            this.modalService.alert('', obj.Message);
          }

          break;

        case 401: // Not Authenticated.
          this.sessionService.IsAuthenticated = false;
          this.broadcaster.broadcast(Constants.SESSION_EXPIRED);
          break;

        case 404: // Not found Api.
          this.modalService.alert('', 'UnsuccessfulAPI.404');
          break;

        case 500: // Internal server error.
          let message: string = error.http_status;
          if (error.target) {
            message += ' - ' + error.target;
          }

          Raven.captureException(new Error(message), { extra: error });
          break;

        default:
          // Default error.
          let othermessage: string = error.http_status;
          if (error.target) {
            othermessage += ' - ' + error.target;
          }

          Raven.captureException(new Error(othermessage), { extra: error });
          break;
      }
    }
  }
}
