import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ReflectionHelper } from '../../Common/ReflectionHelper';

// export class BaseGridColumn { }

@Component({
  selector: 'gridColumn',
  template: ''
})
// export class GridColumnComponent extends BaseGridColumn {
export class GridColumnComponent {
  @Input() public title: string;
  @Input() public field: string;
  @Input() public text: string;
  @Input() public width: number;
  @Input() public headerStyle: string;
  @Input() public class: string;
  @Input() public classField: string;
  @Input() public visible = true;
  @Input() public editable = true;
  @Input() public editableField: string;
  @Input() public subEditableField: string;
  @Input() public isGroupColumn = false;
  @Input() public type: string;
  @Input() public comboboxValueField: string;
  @Input() public comboboxDisplayField: string;
  @Input() public comboboxFilterFields: string[];
  @Input() public comboboxDataSource: any;
  @Input() public isFilterable = false;
  @Input() public filter: any;
  @Input() public format: any;
  @Input() public tooltipFieldNonEdit = '';
  @Input() public comboboxFilterValueField: string;
  @Input() public comboboxFilterDisplayField: string;
  @Input() public comboboxFilterDataSource: any;
  @Input() public minDate: Date;

  public selectedFilterDate: any;

  @Input() public get numbericOption(): any {
    return this.numericOptionsValue;
  }
  public set numbericOption(value: any) {
    if (value && ReflectionHelper.isString(value)) {
      this.numericOptionsValue = {
        format: value,
        step: 0,
        min: 0,
        spinners: false
      };
    } else {
      if (value) {
        value.step = 0;
      }

      this.numericOptionsValue = value;
    }
  }

  @Input() public iconField: string;
  @Input() public headerTooltip: string;
  @Input() public helpLink: string;
  @Input() public tooltip: string;
  @Input() public tooltipField: string;
  @Input() public subProberty: string;
  @Input() public iconAction: string;
  @Input() public locked: boolean;
  @Input() public buttonToottip: string;
  @Input() public buttonLabel: string;
  @Input() public buttonClass: string;
  @Input() public buttonAction: string;
  @Input() public viewClass: string;

  @Input() public hasIncellPopup: boolean;
  @Input() public popupActions: string[];
  @Input() public popupContent: string;
  @Input() public popupModel: any;
  @Input() public percentageDecimals = true;

  @Input() public autocompleteby1value = false;
  @Input() public disableButtonFeild: string;
  @Input() public addOnClass: string;
  @Input() public addOnIcon: string;
  @Input() public addOnIconFieldString: string;

  @Input() public popupButtonText: string;
  @Input() public popupButtonClass: string;
  @Input() public popupButtonArrayContentIcon: any[] = [];
  @Input() public isPopupButtonIcon = false;
  @Input() public popupButtonIcon: string;

  @Input() public disableGridButtonValue = false;
  @Input() public disableGridButtonTooltip = '';

  @Input() public dateFilter?: Date = new Date('');
  @Input() public dateFilterOperator = 'eq';
  @Input() public allowComboeditNull = false;
  @Input() public isShowTextGridToolTip = false;
  @Input() public defaultnullvalue: any;

  @Output() public addonClick: EventEmitter<void> = new EventEmitter<void>();

  @ContentChildren(GridColumnComponent) public columns: QueryList<GridColumnComponent>;

  private numericOptionsValue: any;

  public flatten: any = (filter: any) => {
    const filters: any = (filter || {}).filters;
    if (filters) {
      return filters.reduce((acc: any, curr: any) => acc.concat(curr.filters ? this.flatten(curr) : [curr]), []);
    }
    return [];
  };

  public categoryFilters(filter: CompositeFilterDescriptor): FilterDescriptor[] {
    return this.flatten(filter).map((value: any) => {
      return value;
    });
  }

  public onComboboxFilterChange(values: any[], filterService: FilterService): void {
    filterService.filter({
      filters: values.map((value: any) => ({
        field: this.field,
        operator: 'eq',
        value
      })),
      logic: 'or'
    });
  }

  public onPercentageChange(value: any, filterService: FilterService): void {
    filterService.filter({
      filters: [{ field: this.field, operator: 'eq', value }],
      logic: 'or'
    });
  }

  public selectedValue: any;
  public onFilterChange(value: any, filterService: FilterService): void {
    this.selectedValue = value;
    filterService.filter({
      filters: [{ field: this.field, operator: 'eq', value }],
      logic: 'or'
    });
  }
}
