import { EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { RxDataService } from '../../../Services/RxDataService';
import { BusyIndicatorService, LoadingIndicatorTextType } from '../../../Services/SharedServices/BusyIndicatorService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SalaryBatchViewModel } from '../SalaryBatchViewModel';
var BatchFinalizationStep4DialogueComponent = /** @class */ (function () {
    function BatchFinalizationStep4DialogueComponent(busyIndicatorService, dataService, sessionService) {
        this.busyIndicatorService = busyIndicatorService;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.finalizationExit = new EventEmitter();
        this.finalizationDone = new EventEmitter();
        this.responseDialogVisible = false;
        this.userName = localStorage.getItem('saveLoginUserName');
    }
    Object.defineProperty(BatchFinalizationStep4DialogueComponent.prototype, "step1Completed", {
        get: function () {
            return this.visible;
        },
        set: function (value) {
            if (value) {
                if (!this.salaryBatch.EIncomeZeroReport) {
                    return;
                }
            }
            this.visible = false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep4DialogueComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            var _this = this;
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                setTimeout(function () { return _this.visibleChange.emit(value); });
                if (value) {
                    this.password = '';
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    BatchFinalizationStep4DialogueComponent.prototype.onDialogAction = function (action) {
        if (action === 'Finalize' && this.password) {
            this.approveSalaryBatch(this.salaryBatch.Id, { Password: this.password, DoRecalc: this.doRecalculation });
        }
        else {
            this.visible = false;
            this.finalizationExit.emit();
        }
    };
    BatchFinalizationStep4DialogueComponent.prototype.approveSalaryBatch = function (batchId, request) {
        var _this = this;
        this.busyIndicatorService.setLoadingIndicatorVisibility(true, LoadingIndicatorTextType.FINALIZE);
        this.dataService
            .SalaryBatches_FinalizeSalaryBatch(batchId, request)
            .pipe(finalize(function () {
            _this.busyIndicatorService.setLoadingIndicatorVisibility(false);
            _this.finalizationDone.emit();
        }))
            .subscribe(function (salaryBatchResponse) {
            if (salaryBatchResponse.Message) {
                _this.response = salaryBatchResponse.Message;
                _this.responseDialogVisible = true;
            }
        });
    };
    return BatchFinalizationStep4DialogueComponent;
}());
export { BatchFinalizationStep4DialogueComponent };
