import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-radio-edit-option',
  template: ''
})
export class RadioEditOptionComponent {
  @Input() public label: string;
  @Input() public value: string;
  @Input() public addonLink: string;
  @Input() public isCheckModule = false;
  @Input() public ModuleId: number;
  @Input() public moduleCheckWidth: number;
}
