import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
var SalaryBatchTimeAndVacationComponent = /** @class */ (function () {
    function SalaryBatchTimeAndVacationComponent(dataService, sessionService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.unitTotalsPerEmployee = [];
        this.unitTotalsValue = [];
    }
    Object.defineProperty(SalaryBatchTimeAndVacationComponent.prototype, "SalaryBatchId", {
        get: function () {
            return this.salaryBatchIdValue;
        },
        set: function (value) {
            if (this.salaryBatchIdValue !== value) {
                this.salaryBatchIdValue = value;
                if (value) {
                    this.getTotals();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchTimeAndVacationComponent.prototype, "UnitTotals", {
        get: function () {
            return this.unitTotalsValue;
        },
        set: function (value) {
            if (value !== this.unitTotalsValue) {
                this.unitTotalsValue = [];
                this.unitTotalsValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchTimeAndVacationComponent.prototype.getTotals = function () {
        var _this = this;
        this.unitTotalsPerEmployee = [];
        this.dataService
            .SalaryBatches_GetSalaryBatchUnitTotals(this.SalaryBatchId)
            .subscribe(function (data) {
            _this.UnitTotals = data;
            if (data.length > 0) {
                _this.selectedUnitTotals = data[0];
                _this.onSelectedUnitTotalChange(_this.selectedUnitTotals);
            }
        });
    };
    SalaryBatchTimeAndVacationComponent.prototype.onSelectedUnitTotalChange = function (data) {
        var _this = this;
        this.dataService
            .SalaryBatches_GetUnitTotalsByEmployee(this.SalaryBatchId, data.Key)
            .subscribe(function (perEmployee) {
            _this.unitTotalsPerEmployee = perEmployee;
        });
    };
    SalaryBatchTimeAndVacationComponent.prototype.onDoubleClick = function (data) {
        if (data && data.UserEmploymentId) {
            this.sessionService.currentUserEmploymentId = data.UserEmploymentId;
            this.sessionService.NavigateTo('tabs.employee.payrolldata');
        }
    };
    return SalaryBatchTimeAndVacationComponent;
}());
export { SalaryBatchTimeAndVacationComponent };
