import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import { DxDateBoxComponent } from 'devextreme-angular';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { ReflectionHelper } from '../Common/ReflectionHelper';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
var DatePickerControlComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatePickerControlComponent, _super);
    function DatePickerControlComponent(elementRef, sessionService, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.sessionService = sessionService;
        _this.format = 'dd/MM/yyyy';
        _this.customData = new EventEmitter();
        _this.directional = new EventEmitter();
        _this.setTimeout = false;
        return _this;
    }
    DatePickerControlComponent.prototype.ngOnInit = function () {
        if (!this.isGrid) {
            this.setTimeout = true;
        }
    };
    DatePickerControlComponent.prototype.onChange = function (triggerChanged) {
        var _this = this;
        if (triggerChanged === void 0) { triggerChanged = true; }
        if (this.setTimeout) {
            setTimeout(function () {
                _this.isValid = _this.valid();
                _this.isDirty = _this.hasChanges();
                if (triggerChanged) {
                    _this.onEmitValueChange(_this.value);
                }
            });
        }
        else {
            this.isValid = this.valid();
            this.isDirty = this.hasChanges();
            if (triggerChanged) {
                this.onEmitValueChange(this.value);
            }
        }
    };
    DatePickerControlComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.sessionService.dateFormat) {
                _this.format = _this.sessionService.dateFormat;
            }
        });
    };
    DatePickerControlComponent.prototype.onfocus = function (event) {
        var _this = this;
        // if (this.isGrid) {
        //   if (this.kendoDatePickerControl) {
        //     this.kendoDatePickerControl.wrapper.nativeElement.getElementsByClassName('k-input')[0].select();
        //   }
        //   return;
        // }
        setTimeout(function () {
            if (_this.kendoDatePickerControl) {
                _this.kendoDatePickerControl.wrapper.nativeElement.getElementsByClassName('k-input')[0].select();
            }
            else if (_this.dxDatePickerControl && !_this.sessionService.browser.isMobile) {
                _this.dxDatePickerControl.opened = true;
                var element = _this.dxDatePickerControl.element;
                var input_1 = element.nativeElement.getElementsByClassName('dx-texteditor-input')[0];
                if (input_1) {
                    setTimeout(function () {
                        input_1.select();
                    });
                }
            }
        });
    };
    DatePickerControlComponent.prototype.onBlur = function (event) {
        ////console.log("on date picker blur!");
        if (!this.convertSpecialFormat()) {
            this.convertInvalidDxDate();
        }
    };
    DatePickerControlComponent.prototype.onKeyDown = function (event) {
        var _this = this;
        if (this.isGrid) {
            if (event.event.key === 'ArrowDown' ||
                event.event.key === 'ArrowUp' ||
                event.event.key === 'ArrowLeft' ||
                event.event.key === 'ArrowRight') {
                setTimeout(function () {
                    event.event.preventDefault();
                    event.event.stopPropagation();
                    _this.directional.emit(event.event);
                });
                return;
            }
        }
        if (this.dxDatePickerControl.opened) {
            this.dxDatePickerControl.opened = false;
        }
        if (event.event.key === 'Enter' || event.event.key === 'Tab') {
            if (!this.convertSpecialFormat()) {
                var date_1 = this.checkStringToDateCompatible(this.dxDatePickerControl.text)
                    ? this.convertCustomStringToDate(this.dxDatePickerControl.text)
                    : undefined;
                var controlValue = this.dxDatePickerControl.value
                    ? this.dxDatePickerControl.value.toString()
                    : undefined;
                if (date_1 && ((controlValue && controlValue !== date_1.toString()) || !controlValue)) {
                    if (this.isGrid) {
                        this.value = date_1;
                    }
                    else {
                        setTimeout(function () {
                            _this.value = date_1;
                        });
                    }
                }
            }
        }
    };
    DatePickerControlComponent.prototype.onValueChanged = function (event) {
        ////console.log("valueChanged event raised!");
    };
    DatePickerControlComponent.prototype.onDxDatePickerClosed = function (event) {
        ////console.log("popup closed event raised!");
    };
    DatePickerControlComponent.prototype.convertSpecialFormat = function () {
        var result = false;
        if (this.dxDatePickerControl && this.dxDatePickerControl.text) {
            var today = new Date();
            if (/^[Uu][0-9]$/g.test(this.dxDatePickerControl.text) || /^[Uu][0-9]{2}$/g.test(this.dxDatePickerControl.text)) {
                var monthStr = this.dxDatePickerControl.text.substring(1, 3);
                var month = monthStr ? this.sessionService.parseInt(monthStr) : undefined;
                if (month) {
                    result = true;
                    var lastDateOfMonth = new Date(today.getFullYear(), month, 0).getDate();
                    this.value = this.createDate(today.getFullYear(), month - 1, lastDateOfMonth);
                }
            }
            else {
                switch (this.dxDatePickerControl.text) {
                    case 'd':
                        result = true;
                        this.value = this.createDate(today.getFullYear(), today.getMonth(), today.getDate());
                        break;
                    case 'u':
                        result = true;
                        var lastDateOfMonth = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
                        this.value = this.createDate(today.getFullYear(), today.getMonth(), lastDateOfMonth);
                        break;
                    default:
                        break;
                }
            }
        }
        return result;
    };
    DatePickerControlComponent.prototype.convertInvalidDxDate = function () {
        // Dx control has invalid value.
        if (this.dxDatePickerControl && this.dxDatePickerControl.validationError) {
            this.value = this.checkStringToDateCompatible(this.dxDatePickerControl.text)
                ? this.convertCustomStringToDate(this.dxDatePickerControl.text)
                : undefined;
        }
    };
    DatePickerControlComponent.prototype.convertValue = function (value) {
        return this.convertDateValue(value);
    };
    DatePickerControlComponent.prototype.valid = function () {
        return this.validate(this.value);
    };
    Object.defineProperty(DatePickerControlComponent.prototype, "showValidationMessage", {
        get: function () {
            return !this.valid() && !this.disable && this.validationText && this.validationText.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    DatePickerControlComponent.prototype.convertDateValue = function (value) {
        var isValidationPassed = this.validate(ReflectionHelper.isDate(value) ? value.toISOString() : value);
        if (isValidationPassed && value) {
            // This should only run when the user manually changes the date string
            var date = ReflectionHelper.isDate(value) ? value : new Date(value);
            date = !date ? undefined : this.createDate(date.getFullYear(), date.getMonth(), date.getDate());
            return date;
        }
        else {
            return value;
        }
    };
    DatePickerControlComponent.prototype.validate = function (value) {
        if (this.required && !value) {
            this.validationText = this.requiredMessage;
            return false;
        }
        return true;
    };
    DatePickerControlComponent.prototype.checkStringToDateCompatible = function (value) {
        var isCompaitble = false;
        if (value && value.length > 0) {
            if (!isCompaitble) {
                var dateNumberString = value.replace(/[^0-9\.\-\/ ,]/g, '');
                var date = dateNumberString.substring(0, 2)
                    ? this.sessionService.parseInt(dateNumberString.substring(0, 2))
                    : undefined;
                if (!date &&
                    (/[.]/g.test(dateNumberString) || /[-]/g.test(dateNumberString) || /[\/]/g.test(dateNumberString))) {
                    var seperator = /[.]/g.test(dateNumberString)
                        ? '.'
                        : /[-]/g.test(dateNumberString)
                            ? '-'
                            : /[\/]/g.test(dateNumberString)
                                ? '/'
                                : '';
                    date = dateNumberString.split(seperator)[0]
                        ? this.sessionService.parseInt(dateNumberString.split(seperator)[0])
                        : undefined;
                }
                if (date) {
                    isCompaitble = true;
                }
            }
        }
        return isCompaitble;
    };
    DatePickerControlComponent.prototype.convertCustomStringToDate = function (value) {
        if (value && value.length > 0) {
            var dateNumberString = value.replace(/[^0-9\.\-\/ ,]/g, '');
            var today = new Date();
            today = this.createDate(today.getFullYear(), today.getMonth(), today.getDate());
            var date = void 0;
            var month = void 0;
            var year = void 0;
            if (/[.]/g.test(dateNumberString) || /[-]/g.test(dateNumberString) || /[\/]/g.test(dateNumberString)) {
                var seperator = /[.]/g.test(dateNumberString)
                    ? '.'
                    : /[-]/g.test(dateNumberString)
                        ? '-'
                        : /[\/]/g.test(dateNumberString)
                            ? '/'
                            : '';
                date = dateNumberString.split(seperator)[0]
                    ? this.sessionService.parseInt(dateNumberString.split(seperator)[0])
                    : undefined;
                month = dateNumberString.split(seperator)[1]
                    ? this.sessionService.parseInt(dateNumberString.split(seperator)[1]) - 1
                    : today.getMonth();
                year = dateNumberString.split(seperator)[2]
                    ? this.sessionService.parseInt(dateNumberString.split(seperator)[2])
                    : this.isFeauture(month, today.getMonth())
                        ? today.getFullYear() - 1
                        : today.getFullYear();
            }
            else {
                dateNumberString = value.replace(/[^0-9,]/g, '');
                date = dateNumberString.substring(0, 2)
                    ? this.sessionService.parseInt(dateNumberString.substring(0, 2))
                    : undefined;
                month = dateNumberString.substring(2, 4)
                    ? this.sessionService.parseInt(dateNumberString.substring(2, 4)) - 1
                    : today.getMonth();
                year = dateNumberString.substring(4, 8)
                    ? this.sessionService.parseInt(dateNumberString.substring(4, 8))
                    : this.isFeauture(month, today.getMonth())
                        ? today.getFullYear() - 1
                        : today.getFullYear();
            }
            if (!this.isValidDataAfterConvert(month, date, year)) {
                return undefined;
            }
            return this.createDate(year, month, date);
        }
        return undefined;
    };
    DatePickerControlComponent.prototype.isValidDataAfterConvert = function (month, date, year) {
        if ((month && (month > 11 || month < 0)) || month === undefined) {
            return false;
        }
        var lastDateOfMonth = new Date(year, month + 1, 0).getDate();
        if (!lastDateOfMonth || (date && (date > lastDateOfMonth || date < 1)) || !date) {
            return false;
        }
        return true;
    };
    DatePickerControlComponent.prototype.AvailableMonth = function (month) {
        if (month) {
            if (month + 3 > 12) {
                return month + 3 - 12;
            }
            return month + 3;
        }
        return 0;
    };
    DatePickerControlComponent.prototype.isFeauture = function (currentMonth, month) {
        if (currentMonth > this.AvailableMonth(month)) {
            return true;
        }
        return false;
    };
    DatePickerControlComponent.prototype.createDate = function (year, month, date) {
        var today = new Date();
        if (date) {
            year =
                year < 100
                    ? this.sessionService.parseInt(today
                        .getFullYear()
                        .toString()
                        .substr(0, 2) + this.sessionService.toString(year, '00'))
                    : year;
            month = month > 11 ? 11 : month < 0 ? 0 : month;
            var lastDateOfMonth = new Date(year, month + 1, 0).getDate();
            date = date > lastDateOfMonth ? lastDateOfMonth : date < 0 ? 1 : date;
            var hour = today.getTimezoneOffset() / 60;
            hour = hour === 12 || hour === -12 ? 0 : hour;
            if (hour > 0) {
                return new Date(year, month, date, hour, 0, 0);
            }
            return new Date(year, month, date, -hour, 0, 0);
        }
        return undefined;
    };
    return DatePickerControlComponent;
}(ControlComponentBase));
export { DatePickerControlComponent };
