import { TranslateService } from '@ngx-translate/core';
import { Browser } from '../../Common/Browser';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { Broadcaster } from '../Broadcaster';
import { ModalService } from './ModalService';
var SettingService = /** @class */ (function () {
    function SettingService(translateService, modalService, broadcaster) {
        this.translateService = translateService;
        this.modalService = modalService;
        this.broadcaster = broadcaster;
        this.showFadingMessage = {};
        this.navigationParameters = {};
        this.isShowReadMoreWelcomMessage = false;
        this.showCompanySelector = false;
        this.isReload = true;
        this.welcomeTitles = {};
        this.welcomeMessages = {};
        this.moduleMessageQueue = [];
    }
    Object.defineProperty(SettingService.prototype, "isIOSMobileApp", {
        get: function () {
            var browser = new Browser();
            return browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    SettingService.prototype.updateSettingsForEmployeeCompany = function (tasks) {
        var companyTaskCompleted = false;
        var employeeTaskCompleted = false;
        if (tasks.length > 0) {
            var companyTask = tasks
                ? tasks.find(function (com) { return com.Key === 'CompanyData'; })
                : undefined;
            var employeeTask = tasks
                ? tasks.find(function (emp) { return emp.Key === 'CreateEmployees'; })
                : undefined;
            companyTaskCompleted = companyTask && companyTask.StatusId === 1 ? true : false;
            employeeTaskCompleted = employeeTask && employeeTask.StatusId === 1 ? true : false;
        }
        // Save settings.
        Global.IsCompanyDataCompleted = companyTaskCompleted;
        Global.IsEmployeeTaskCompleted = employeeTaskCompleted;
    };
    SettingService.prototype.applyTranslations = function (languageCode) {
        var _this = this;
        var langCode = languageCode
            ? languageCode
            : Global.SESSION && Global.SESSION.SignOnToken.Language
                ? Global.SESSION.SignOnToken.Language.CultureCode.split('-')[0]
                : undefined;
        if (this.translateService.currentLang !== langCode) {
            this.translateService.use(langCode).subscribe(function () {
                if (!languageCode) {
                    _this.translateApplicationText();
                }
            });
        }
        else {
            if (!languageCode) {
                this.translateApplicationText();
            }
        }
    };
    SettingService.prototype.translateApplicationText = function () {
        var _this = this;
        this.translateService
            .get([
            'Beta.BetaTitle',
            'Beta.BetaModuleWarning',
            'EmployeeGeneral.WelcomeTitle',
            'EmployeeGeneral.WelcomeMessage',
            'EmployeeGeneral.WelcomeEmployeeMessage',
            'EmployeeEmployment.WelcomeTitle',
            'EmployeeEmployment.WelcomeMessage',
            'EmploymentPayrollData.WelcomeTitle',
            'EmploymentPayrollData.WelcomeMessage',
            'EmploymentTime.WelcomeTitle',
            'EmploymentTime.WelcomeMessage',
            'EmployeePayslip.WelcomeTitle',
            'EmployeePayslip.WelcomeMessage',
            'AccountModules.WelcomeTitle',
            'AccountModules.WelcomeMessage',
            'CompanyGeneral.WelcomeTitle',
            'CompanyGeneral.WelcomeMessage',
            'CompanyHiringStatus.WelcomeTitle',
            'CompanyHiringStatus.WelcomeMessage',
            'CompanySalaryBatches.WelcomeTitle',
            'CompanySalaryBatches.WelcomeMessage',
            'CompanySalaryTypes.WelcomeTitle',
            'CompanySalaryTypes.WelcomeMessage',
            'CompanyTimeEntryTypes.WelcomeTitle',
            'CompanyTimeEntryTypes.WelcomeMessage',
            'SelfServicePayslip.WelcomeTitle',
            'SelfServicePayslip.WelcomeMessage',
            'Timeentrytypes.WelcomeTitle',
            'Timeentrytypes.WelcomeMessage',
            'Integrations.WelcomeTitle',
            'Integrations.WelcomeMessage',
            'GlobalDialog.Close',
            'GlobalDialog.ReadMore',
            'Login.UpdatedGratisalMessageUrl'
        ])
            .subscribe(function (translations) {
            _this.betaModuleTitle = translations['Beta.BetaTitle'];
            _this.betaModuleMessage = translations['Beta.BetaModuleWarning'];
            _this.welcomeTitles['Employee.General'] = translations['EmployeeGeneral.WelcomeTitle'];
            _this.welcomeMessages['Employee.General'] = translations['EmployeeGeneral.WelcomeMessage'];
            _this.welcomeMessages['EmployeeGeneral.WelcomeEmployeeMessage'] =
                translations['EmployeeGeneral.WelcomeEmployeeMessage'];
            _this.welcomeTitles['Employee.Employment'] = translations['EmployeeEmployment.WelcomeTitle'];
            _this.welcomeMessages['Employee.Employment'] = translations['EmployeeEmployment.WelcomeMessage'];
            _this.welcomeTitles['Employee.Time'] = translations['EmploymentTime.WelcomeTitle'];
            _this.welcomeMessages['Employee.Time'] = translations['EmploymentTime.WelcomeMessage'];
            _this.welcomeTitles['Employee.PayrollData'] = translations['EmploymentPayrollData.WelcomeTitle'];
            _this.welcomeMessages['Employee.PayrollData'] = translations['EmploymentPayrollData.WelcomeMessage'];
            _this.welcomeTitles['Employee.Payslip'] = translations['EmployeePayslip.WelcomeTitle'];
            _this.welcomeMessages['Employee.Payslip'] = translations['EmployeePayslip.WelcomeMessage'];
            _this.welcomeTitles['Company.General'] = translations['CompanyGeneral.WelcomeTitle'];
            _this.welcomeMessages['Company.General'] = translations['CompanyGeneral.WelcomeMessage'];
            _this.welcomeTitles['Company.Templates'] = translations['CompanyHiringStatus.WelcomeTitle'];
            _this.welcomeMessages['Company.Templates'] = translations['CompanyHiringStatus.WelcomeMessage'];
            _this.welcomeTitles['Company.SalaryBatches'] = translations['CompanySalaryBatches.WelcomeTitle'];
            _this.welcomeMessages['Company.SalaryBatches'] = translations['CompanySalaryBatches.WelcomeMessage'];
            _this.welcomeTitles['Company.SalaryTypes'] = translations['CompanySalaryTypes.WelcomeTitle'];
            _this.welcomeMessages['Company.SalaryTypes'] = translations['CompanySalaryTypes.WelcomeMessage'];
            _this.welcomeTitles['Company.TimeEntryTypes'] = translations['CompanyTimeEntryTypes.WelcomeTitle'];
            _this.welcomeMessages['Company.TimeEntryTypes'] = translations['CompanyTimeEntryTypes.WelcomeMessage'];
            _this.welcomeTitles['Account.Modules'] = translations['AccountModules.WelcomeTitle'];
            _this.welcomeMessages['Account.Modules'] = translations['AccountModules.WelcomeMessage'];
            _this.welcomeTitles['SelfService.Payslip'] = translations['SelfServicePayslip.WelcomeTitle'];
            _this.welcomeMessages['SelfService.Payslip'] = translations['SelfServicePayslip.WelcomeMessage'];
            _this.welcomeTitles['company.timeentrytypes'] = translations['Timeentrytypes.WelcomeTitle'];
            _this.welcomeMessages['company.timeentrytypes'] = translations['Timeentrytypes.WelcomeMessage'];
            _this.welcomeTitles['company.integrations'] = translations['Integrations.WelcomeTitle'];
            _this.welcomeMessages['company.integrations'] = translations['Integrations.WelcomeMessage'];
            _this.welcomeTitles['company.advancedsalarytypes'] = translations['CompanySalaryTypes.WelcomeTitle'];
            _this.welcomeMessages['company.advancedsalarytypes'] = translations['CompanySalaryTypes.WelcomeMessage'];
            _this.welcomeMessages['Login.UpdatedGratisalMessageUrl'] = translations['Login.UpdatedGratisalMessageUrl'];
            if (_this.moduleMessageQueue && _this.moduleMessageQueue.length > 0) {
                _this.moduleMessageQueue.forEach(function (moduleMessage) {
                    _this.showModuleMessage(moduleMessage.moduleName, moduleMessage.overideMessage);
                });
                _this.moduleMessageQueue = [];
            }
        });
    };
    SettingService.prototype.showDoubleClickToEditHint = function (show) {
        var snackBar = document.getElementById('divHintForEdit');
        if (!snackBar) {
            return;
        }
        if (show) {
            snackBar.className = 'show';
            setTimeout(function () {
                snackBar.className = snackBar.className.replace('show', '');
            }, 5000);
        }
        else {
            snackBar.className = snackBar.className.replace('show', '');
        }
    };
    SettingService.prototype.onModuleMessageDialogAction = function (action) {
        if (action === 'ReadMore') {
            window.open(this.welcomeMessages['Login.UpdatedGratisalMessageUrl'], '_blank');
        }
        ////this.showFlipScreenHint(this.moduleName);
        this.broadcaster.broadcast(Constants.SHOW_EDIT_HINT);
    };
    SettingService.prototype.showModuleMessage = function (moduleName, overideMessage) {
        var _this = this;
        if (moduleName) {
            if (moduleName === 'ShowUpdateNotification') {
                this.isShowReadMoreWelcomMessage = true;
            }
            else {
                this.isShowReadMoreWelcomMessage = false;
            }
        }
        var welcomeSettings = Global.WELCOME_MESSAGE_SETTINGS;
        if (!this.welcomeMessages[moduleName] && !overideMessage) {
            this.moduleMessageQueue.push({ moduleName: moduleName, overideMessage: overideMessage });
            return;
        }
        var isAlertShown = false;
        var moduleMessage = overideMessage ? overideMessage : this.welcomeMessages[moduleName];
        if (welcomeSettings && welcomeSettings[moduleName] && moduleMessage) {
            if (moduleName === 'Employee.General' && Global.SESSION) {
                if (Global.SESSION.CurrentRole.Key === 'Employee') {
                    moduleMessage = this.welcomeMessages['EmployeeGeneral.WelcomeEmployeeMessage']
                        ? this.welcomeMessages['EmployeeGeneral.WelcomeEmployeeMessage']
                        : moduleMessage;
                }
                else {
                    moduleMessage = this.welcomeMessages[moduleName] ? this.welcomeMessages[moduleName] : moduleMessage;
                }
            }
            isAlertShown = true;
            setTimeout(function () {
                _this.moduleName = moduleName;
                _this.moduleMessageDialogTitle = _this.welcomeTitles[moduleName];
                _this.moduleMessage = moduleMessage;
                _this.moduleMessageDialogVisible = true;
                welcomeSettings[moduleName] = false;
                Global.WELCOME_MESSAGE_SETTINGS = welcomeSettings;
            });
            // Save back the updated setting.
            // Global.WELCOME_MESSAGE_SETTINGS = welcomeSettings;
        }
        isAlertShown = isAlertShown || this.showBetaMessage(moduleName);
        isAlertShown = isAlertShown || this.showNavigationMessage();
        if (!isAlertShown) {
            ////this.showFlipScreenHint(moduleName);
        }
    };
    SettingService.prototype.showBetaMessage = function (moduleName) {
        var isAlertShown = false;
        var betaModuleSettings = Global.BETA_MODULE_MESSAGE_SETTINGS;
        if (betaModuleSettings && betaModuleSettings[moduleName] && this.betaModuleMessage) {
            isAlertShown = true;
            betaModuleSettings[moduleName] = false;
            this.modalService.alert(this.betaModuleTitle, this.betaModuleMessage);
            ////this.showFlipScreenHint(moduleName);
            // Save back the updated setting.
            Global.BETA_MODULE_MESSAGE_SETTINGS = betaModuleSettings;
        }
        return isAlertShown;
    };
    SettingService.prototype.onNavigationMessageDialogAction = function (action) {
        if (action === 'MoreHelp') {
            this.navigateToExternalLink(this.navigationStartUpTask.HelpLink);
        }
    };
    SettingService.prototype.showNavigationMessage = function () {
        var isAlertShown = false;
        if (!this.navigationStartUpTask) {
            return isAlertShown;
        }
        var task = this.navigationStartUpTask;
        if (task.ExternalLink) {
            if (task.GuideMessage) {
                isAlertShown = true;
                this.navigationMessage = task.GuideMessage;
                this.navigationMessageDialogVisible = true;
            }
            else {
                this.navigateToExternalLink(task.ExternalLink);
            }
        }
        else if (task.GuideMessage) {
            isAlertShown = true;
            this.navigationMessage = task.GuideMessage;
            this.navigationMessageDialogVisible = true;
        }
        this.navigationStartUpTask = undefined;
        return isAlertShown;
    };
    SettingService.prototype.navigateToExternalLink = function (link) {
        if (!this.isIOSMobileApp) {
            window.open(link);
        }
    };
    return SettingService;
}());
export { SettingService };
