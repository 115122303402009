import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { Global } from '../../../Common/Global';
import { ICompany, ICompanyType } from '../../../Services/ApiModel';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'app-company-data',
  templateUrl: './CompanyData.component.html'
})
export class CompanyDataComponent extends FormComponentBase implements OnInit {
  @Input() public company: ICompany;
  @Input()
  public set resetChildFrom(value: boolean) {
    if (value) {
      this.childIsDirty.fill(false);
      this.childIsValid.fill(true);
    }
    this.resetChildFromChange.emit(false);
  }

  @Output() public resetChildFromChange: EventEmitter<any> = new EventEmitter<any>();

  public taxCategories: ICompanyType[];
  public isWarningVivible: boolean;

  private originalBankAccountNo: string;
  private warningHasBeenShown = false;

  constructor(private staticDataService: StaticDataService) {
    super();
    this.childIsValid = Array(3).fill(true);
    this.childIsDirty = Array(8).fill(false);
  }

  public ngOnInit(): void {
    this.staticDataService.CompanyType.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ICompanyType[]) => {
      return (this.taxCategories = data);
    });
    this.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => (this.warningHasBeenShown = false));
    this.isDirtyChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((dirty: boolean) => {
      if (this.company && !dirty && !this.editMode) {
        this.originalBankAccountNo = this.company.BankAccountNo;
      }
    });
  }

  public onBankAccountNoChanged(): void {
    if (this.editMode && this.company && this.company.HasNetsAgreement && !this.warningHasBeenShown) {
      this.isWarningVivible = true;
    }
    this.onChange();
  }

  public get isGreenland(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public onWarningAction(action: string): void {
    if (action !== 'Understood') {
      this.company.BankAccountNo = this.originalBankAccountNo;
    } else {
      this.onChange();
    }

    this.warningHasBeenShown = true;
  }
}
