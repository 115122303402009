import { EventEmitter } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { Global } from '../../../../Common/Global';
var SalaryBatchPayslipDetailsComponent = /** @class */ (function () {
    function SalaryBatchPayslipDetailsComponent() {
        this.revertBatchCompleted = new EventEmitter();
        this.revertPayslipDialogVisible = false;
        this.gridData = filterBy(this.salaryStatementDetails, this.filter);
    }
    Object.defineProperty(SalaryBatchPayslipDetailsComponent.prototype, "salaryStatementDetails", {
        get: function () {
            return this.salaryStatementDetailValues;
        },
        set: function (value) {
            this.salaryStatementDetailValues = value;
            this.gridData =
                this.salaryStatementDetailValues && this.filter
                    ? filterBy(this.salaryStatementDetailValues, this.filter)
                    : this.salaryStatementDetailValues;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipDetailsComponent.prototype, "IsFinalizedBatchAndHaveData", {
        get: function () {
            if (this.IsFinalizedBatch && this.salaryStatementDetails && this.salaryStatementDetails.length > 0) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipDetailsComponent.prototype, "isPaymentApprover", {
        get: function () {
            return Global.SESSION && Global.SESSION.IsPaymentApprover;
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchPayslipDetailsComponent.prototype.onRevertPayslip = function () {
        this.revertPayslipDialogVisible = true;
    };
    SalaryBatchPayslipDetailsComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridData = filterBy(this.salaryStatementDetails, this.filter);
    };
    return SalaryBatchPayslipDetailsComponent;
}());
export { SalaryBatchPayslipDetailsComponent };
