<div *ngIf="showTitle" class="Card-title">{{ 'CompanySalaryBatches.Totals' | translate }}</div>
<div *ngIf="!showTitle" class="u-leader"></div>

<div class="FormElement Company-totalsControls">
  <div class="FormElement-legend FormElement-label u-hidden">{{ 'CompanySalaryBatches.Totals' | translate }}</div>
  <div class="FormElement-control">
    <app-radio-edit [editMode]="true" [(value)]="view">
      <app-radio-edit-option
        [value]="'salarytypes'"
        [label]="'CompanySalaryBatches.SalaryTypeRadioButtonText' | translate"
      ></app-radio-edit-option>
      <app-radio-edit-option
        [value]="'units'"
        [label]="'CompanySalaryBatches.TimeRadioButtonText' | translate"
      ></app-radio-edit-option>
    </app-radio-edit>
  </div>
</div>

<salary-batch-salary-type
  *ngIf="view === 'salarytypes'"
  [SalaryBatchId]="SalaryBatchId"
  [SalaryTypeTotals]="SalaryTypeTotals"
></salary-batch-salary-type>
<salary-batch-time-vacation
  *ngIf="view === 'units'"
  [SalaryBatchId]="SalaryBatchId"
  [UnitTotals]="UnitTotals"
></salary-batch-time-vacation>
