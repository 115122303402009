<!-- No Employment -->
<div class="Cards" *ngIf="!hasUserEmployment">
  <div class="Card">
    <div class="Card-inner">
      <h2 class="Card-title">{{ 'EmploymentPayrollData.No Employment' | translate }}</h2>
      <button-component
        buttonClass="Button--primary"
        *ngIf="allowEdit"
        (click)="createUserEmployment()"
        [label]="'EmployeeGeneral.Create employment' | translate"
      ></button-component>
    </div>
  </div>
</div>
<!-- /No Employment -->

<div *ngIf="hasUserEmployment">
  <payslip-mobile
    [groupIconAction]="groupIconAction"
    [salaryStatementsNoneType]="gridData"
    (iconClick)="clickIconAction($event)"
    [(selectedSalaryStatement)]="selectedSalaryStatement"
    (Revert)="onRevert()"
    *ngIf="isMobile"
  ></payslip-mobile>
  <div class="Card" *ngIf="!isMobile">
    <div class="Card-inner">
      <form>
        <fieldset class="Form--horizontal">
          <div class="Card-title">
            <div>{{ 'EmployeeGeneral.Payslips' | translate }}</div>
            <button-component
              [buttonClass]="'Button--card'"
              *ngIf="IsPaymentApprover"
              [isDisabled]="!selectedFirstRow"
              [label]="'CompanySalaryBatches.RevertThisPayslip' | translate"
              (click)="onRevert()"
            ></button-component>
            <button-component
              [buttonClass]="'Button--card'"
              *ngIf="!IsPaymentApprover"
              [isDisabled]="true"
              [tooltip]="'RevertPayslip.NoPermission' | translate"
              [label]="'CompanySalaryBatches.RevertThisPayslip' | translate"
            ></button-component>
          </div>
          <div class="Cards Cards--flex hintPayrollBatches">
            <div class="Card-inner">{{ 'EmployeePayslip.Hint' | translate }}</div>
          </div>
          <grid
            style="z-index: 1000"
            [editMode]="false"
            [addNew]="false"
            [deleteRow]="false"
            [data]="gridData"
            (clickAppGridIcon)="clickIconAction($event)"
            (selectedItemChange)="onSelectRow($event)"
            (dblClick)="onGridCellDbClick($event)"
            class="minHforPaySlop"
            [ngClass]="!isAllfinalize ? 'Icon--payslip' : ''"
            [(selectedItem)]="selectedSalaryStatement"
            [filterable]="'menu'"
            [filter]="filter"
            (filterChange)="onFilterChange($event)"
            [(triggerUpdate)]="triggerUpdate"
          >
            <gridColumn
              *ngIf="!isAllfinalize"
              [type]="'icon'"
              [iconField]="'PreliminaryWarning'"
              [tooltip]="'EmployeePayslip.PreliminaryTooltip' | translate"
              [iconAction]="'PreliminaryWarning'"
              [width]="35"
            ></gridColumn>
            <gridColumn
              [title]="'EmployeePayslip.ShowAndDownload' | translate"
              [popupButtonText]="'GlobalDialog.Download' | translate"
              [type]="'buttonPopUp'"
              [popupButtonClass]="'Button--card dropMargin width100'"
              [width]="180"
              [popupButtonArrayContentIcon]="groupIconAction"
              [isPopupButtonIcon]="true"
              [popupButtonIcon]="'Download'"
            >
            </gridColumn>
            <gridColumn
              [title]="'EmployeePayslip.EmploymentTitle' | translate"
              [type]="'subProberty'"
              [field]="'EmploymentTitle'"
              [subProberty]="'UserEmployment.Title'"
              [width]="80"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmployeeGeneral.PeriodStart' | translate"
              [type]="'datepicker'"
              [field]="'PeriodFrom'"
              [width]="80"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmployeeGeneral.PeriodEnd' | translate"
              [type]="'datepicker'"
              [field]="'PeriodTo'"
              [width]="80"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmployeePayslip.BaseSalary' | translate"
              [type]="'numeric'"
              [field]="'PrimaryIncomeAmount'"
              [width]="95"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmployeePayslip.HoursWorked' | translate"
              [type]="'numeric'"
              [field]="'HoursWorked'"
              [width]="90"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmployeePayslip.TaxDeduction' | translate"
              [type]="'numeric'"
              [field]="'AppliedTaxDeduction'"
              [width]="95"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmployeePayslip.TaxRate' | translate"
              [type]="'percentage'"
              [field]="'TaxRate'"
              [width]="80"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'SelfServicePayslip.Paid out' | translate"
              [type]="'numeric'"
              [field]="'PayoutAmount'"
              [width]="70"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmployeePayslip.SpentVacation' | translate"
              [type]="'numeric'"
              [field]="'VacationDaysSpent'"
              [width]="90"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmployeePayslip.VacationMoneyEarnedGross' | translate"
              [type]="'numeric'"
              [field]="'VacationMoneyEarnedGross'"
              [width]="120"
            ></gridColumn>
            <gridColumn
              [isFilterable]="true"
              [title]="'EmployeePayslip.VacationMoneyEarnedNet' | translate"
              [type]="'numeric'"
              [field]="'VacationMoneyEarnedNet'"
              [width]="110"
            ></gridColumn>
          </grid>
        </fieldset>
      </form>
    </div>
  </div>
</div>

<app-dialog
  id="iframeDialog"
  class="PayslipPopup"
  [(visible)]="previewDialogVisible"
  [iframeContent]="previewContent"
  [overlayClose]="false"
  [isOverlayKeydown]="true"
></app-dialog>

<app-dialog
  *ngIf="IsWeb"
  [(visible)]="newTabBlockedDialogVisible"
  [overlayClose]="true"
  [leadingText]="'Report.NewTabBlocked' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<revert-payslip
  [(isVisible)]="showRevertFinalizedDialog"
  [employee]="currentEmployee"
  [salaryStatement]="selectedSalaryStatement"
  (revertCompleted)="onRevertBatchCompleted()"
></revert-payslip>
