import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
var EmployeeGeneralComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeGeneralComponent, _super);
    function EmployeeGeneralComponent(dataService, settingService, sessionService, staticDataService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.reloadUserEmployment = new EventEmitter();
        _this.companyUserChange = new EventEmitter();
        _this.userEmploymentChange = new EventEmitter();
        _this.isSelfService = false;
        _this.resendWelcomeEmailChange = new EventEmitter();
        _this.resetPasswordChange = new EventEmitter();
        _this.taxCardDataChanged = false;
        _this.isLoadTaxCardType = false;
        _this.childIsValid = Array(4).fill(true);
        _this.childIsDirty = Array(4).fill(false);
        return _this;
    }
    Object.defineProperty(EmployeeGeneralComponent.prototype, "companyUser", {
        get: function () {
            return this.companyUserValue;
        },
        set: function (value) {
            if (!this.companyUserValue && !value) {
                return;
            }
            this.companyUserValue = value;
            this.companyUserChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeGeneralComponent.prototype, "userEmployment", {
        get: function () {
            return this.userEmploymentValue;
        },
        set: function (value) {
            if (!this.userEmploymentValue && !value) {
                return;
            }
            this.taxCardDataChanged = true;
            this.userEmploymentValue = value;
            this.userEmploymentChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeGeneralComponent.prototype, "resendWelcomeEmail", {
        get: function () {
            return this.resendWelcomeEmailValue;
        },
        set: function (value) {
            this.resendWelcomeEmailValue = value;
            this.resendWelcomeEmailChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeGeneralComponent.prototype, "resetPassword", {
        get: function () {
            return this.resetPasswordValue;
        },
        set: function (value) {
            this.resetPasswordValue = value;
            this.resetPasswordChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeGeneralComponent.prototype, "taxCardObject", {
        get: function () {
            var userEmployment = this.userEmployment;
            this.setTaxCardData(userEmployment);
            return this.taxCardData;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeGeneralComponent.prototype.ngOnInit = function () {
        if (this.IsActive) {
            this.settingService.showModuleMessage('Employee.General');
        }
    };
    Object.defineProperty(EmployeeGeneralComponent.prototype, "IsActive", {
        get: function () {
            return (this.sessionService.currentState === 'tabs.employee.general' ||
                this.sessionService.currentState === 'tabs.selfservice.general');
        },
        enumerable: true,
        configurable: true
    });
    EmployeeGeneralComponent.prototype.setTaxCardData = function (value, newtaxInfo) {
        var _this = this;
        if (this.taxCardDataChanged || newtaxInfo) {
            var isSpecialTaxBrief_1;
            if (value && (value.IncomeTypeId === 0 || value.IncomeTypeId === Constants.MAGIC_NUMBER)) {
                isSpecialTaxBrief_1 = false;
            }
            else {
                isSpecialTaxBrief_1 = true;
            }
            if (this.taxCardTypes && this.taxCardTypes.length > 0 && this.isLoadTaxCardType) {
                // Only create a new instance of taxcard if there is changes
                this.taxCardData = {
                    taxInfo: newtaxInfo ? newtaxInfo : this.saveTaxInfo ? this.saveTaxInfo : undefined,
                    taxCardRequestTime: value ? value.TaxCardRequested : undefined,
                    taxCardTypes: this.taxCardTypes,
                    userEmploymentId: value ? value.Id : undefined,
                    specialTaxBrief: isSpecialTaxBrief_1
                };
                this.taxCardDataChanged = false;
            }
            else {
                this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
                    _this.taxCardTypes = data;
                    // Only create a new instance of taxcard if there is changes
                    _this.taxCardData = {
                        taxInfo: undefined,
                        taxCardRequestTime: value ? value.TaxCardRequested : undefined,
                        taxCardTypes: _this.taxCardTypes,
                        userEmploymentId: value ? value.Id : undefined,
                        specialTaxBrief: isSpecialTaxBrief_1
                    };
                    _this.taxCardDataChanged = false;
                    _this.isLoadTaxCardType = true;
                });
            }
        }
    };
    EmployeeGeneralComponent.prototype.onComponentChanged = function (isReload) {
        if (isReload === void 0) { isReload = false; }
        this.onChange();
        if (isReload) {
            this.reloadUserEmployment.emit();
        }
    };
    EmployeeGeneralComponent.prototype.onresendEmployeeWelcomEmailConfirmCompleted = function (action) {
        var _this = this;
        if (action === 'Proceed') {
            this.dataService.CompanyUsers_ResendUserEmploymentEmail(this.companyUser.Id).subscribe(function () {
                _this.resendEmailSuccessVisible = true;
            });
        }
    };
    EmployeeGeneralComponent.prototype.onResetEmployeePasswordConfirmCompleted = function (action) {
        var _this = this;
        if (action === 'Proceed') {
            this.dataService.CompanyUsers_ResetUserEmploymentPassword(this.companyUser.Id).subscribe(function (data) {
                _this.resetPasswordSuccessVisible = true;
            });
        }
    };
    EmployeeGeneralComponent.prototype.onReloadTaxCard = function () {
        this.triggerRefreshTaxCard = !this.triggerRefreshTaxCard;
    };
    EmployeeGeneralComponent.prototype.onRequestTaxcardWarningDialogAction = function (action) {
        if (action === 'AlreadyRequestedTaxCardConfirmButton') {
            this.showRequestTaxcardDialog();
        }
    };
    EmployeeGeneralComponent.prototype.showRequestTaxcardDialog = function () {
        this.requestTaxcardDialogVisible = true;
    };
    EmployeeGeneralComponent.prototype.showViewTaxCardHistory = function (value) {
        this.saveTaxInfo = value;
        this.taxcardHistoryDialogVisible = true;
    };
    EmployeeGeneralComponent.prototype.showRequestTaxcardConfirm = function (value) {
        this.saveTaxInfo = value;
        this.requestTaxcardWarningDialog = true;
    };
    EmployeeGeneralComponent.prototype.onCreateTaxInfo = function (value) {
        if (value) {
            this.setTaxCardData(this.userEmployment, value);
        }
    };
    return EmployeeGeneralComponent;
}(FormComponentBase));
export { EmployeeGeneralComponent };
