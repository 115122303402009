<div>
  <price
    [usersMonthly]="usersMonthly"
    [users14Day]="users14Day"
    [usersWeekly]="usersWeekly"
    [modules]="modules"
    (submit)="onSubmit($event)"
    [(companyClick)]="companyClick"
  >
  </price>

  <!-- <anonymous-price></anonymous-price> -->

  <div class="Actions">
    <action-button
      id="ActionButtonReports"
      [buttonClass]="'Action-button Action-buttonReports'"
      [icon]="'File'"
      label="Company.Reports"
      (click)="reportDialogVisible = true"
      tooltip="Company.Reports"
    >
    </action-button>
    <help-button [module]="'company'"></help-button>
  </div>
</div>
<app-report-dialog [(visible)]="reportDialogVisible" [key]="'CompanyAdministration'"></app-report-dialog>

<app-dialog
  (action)="onConfirmDialogAction($event)"
  [(visible)]="confirmDialogVisible"
  [leadingText]="'Price.SubmitConfirm' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Understood" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>
