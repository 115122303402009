<div class="payroll-mobile-col-container">
  <div *ngFor="let payrollChild of salaryRecords" class="contentPayroll" (click)="onOpenUpdateDialog(payrollChild)">
    <div class="rowContent">
      <div class="payroll-Firstcol-Mobile">
        <div class="payroll-TypeName">
          {{ getNameByTypeId(payrollChild.SalaryTypeId) }}
        </div>
      </div>

      <div
        class="payroll-Lastcol-Mobile payrollDatte"
        *ngIf="getStringStartDateAndEnddate(payrollChild.StartDate, payrollChild.EndDate)"
      >
        <div class="payroll-TypeName">
          {{ getStringStartDateAndEnddate(payrollChild.StartDate, payrollChild.EndDate) }} &nbsp;
        </div>
      </div>
    </div>
    <div class="rowContent">
      <div class="payroll-Firstcol-Mobile">
        <div>
          {{ payrollChild.Description }}
        </div>
      </div>

      <div class="payroll-Lastcol-Mobile">
        <div>
          {{ getUnitAndType(payrollChild) }}
        </div>
      </div>
    </div>
    <div class="rowContent">
      <div class="payroll-Firstcol-Mobile">
        <div>
          {{ getPerunit(payrollChild) }}
        </div>
      </div>

      <div class="payroll-Lastcol-Mobile">
        <div>
          {{ payrollChild.Amount | kendoNumber: 'n2':'da' }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="payrollControlArea">
  <button
    *ngIf="isHasEmployeeTotal && isShowButtonPreview"
    (click)="onShowSelectPeriodDialog()"
    class="k-button k-grid-add-command ng-star-inserted"
  >
    {{ periodsButtonPreviewText }}
    <br />
    {{ ('EmploymentTime.Total' | translate) + ': ' + formatunit(totalButtonPreviewText) }}
  </button>
</div>

<div class="overlayScreen {{ classoverlayScreen }}" (click)="onShowSelectPeriodDialog()"></div>
<div class="PreviewGridMobile {{ classShowSelectPeriodDialog }}">
  <payroll-preview
    (closePreviewArea)="onShowSelectPeriodDialog()"
    [isMobilePayrollDataSlide]="true"
    [(periodsName)]="periodsButtonPreviewText"
    [(totalValue)]="totalButtonPreviewText"
    class="Card Employee-payrollDataPreviewCard PayrollMobilePreviewGrid"
    [userEmployment]="userEmployment"
    [(recalculatePreview)]="recalculate"
  >
  </payroll-preview>
</div>

<mobile-modal
  [(show)]="showInsertOrUpdatesalaryRecords"
  [customClass]="'widthMax'"
  (close)="oncloseInsertOrUpdatesalaryRecords()"
>
  <mobile-modal-header></mobile-modal-header>
  <mobile-modal-content>
    <form>
      <fieldset>
        <!-- SalaryTypeId -->
        <div class="Card-inner">
          <combobox-edit-control
            [label]="'EmploymentPayrollData.SalaryType' | translate"
            [comboboxDataSource]="salaryTypes"
            [editMode]="true"
            [textField]="'Name'"
            [idField]="'SalaryTypeId'"
            [(value)]="salaryRecordModel.SalaryTypeId"
            (valueChange)="onValueControlChange(salaryRecordModel, 'SalaryTypeId')"
          ></combobox-edit-control>
        </div>
        <!-- SalaryTypeId -->

        <!-- Description -->
        <div class="Card-inner">
          <app-text-edit
            [label]="'EmploymentPayrollData.Description' | translate"
            [editMode]="true"
            [(value)]="salaryRecordModel.Description"
            (valueChange)="onValueControlChange(salaryRecordModel, 'Description')"
          ></app-text-edit>
        </div>
        <!-- Description -->

        <!-- BaseAmount -->
        <div class="Card-inner" *ngIf="salaryRecordModel.AllowEditBaseAmount">
          <numeric-edit
            [label]="'EmploymentPayrollData.BaseAmount' | translate"
            [editMode]="salaryRecordModel.AllowEditBaseAmount"
            [(value)]="salaryRecordModel.BaseAmount"
            [option]="NumericOptions"
            (valueChange)="onValueControlChange(salaryRecordModel, 'BaseAmount')"
          >
          </numeric-edit>
        </div>
        <!-- BaseAmount -->

        <!-- Units UnitTypeId -->
        <div class="Card-inner inLineFlex">
          <!-- Units -->
          <numeric-edit
            [label]="'EmploymentPayrollData.Units' | translate"
            [editMode]="true"
            [disable]="!salaryRecordModel.AllowEditUnits"
            [(value)]="salaryRecordModel.Units"
            [option]="NumericOptions"
            [isSpecialLabel]="!isHideUnitTypeControl"
            [customClass]="'margin5Label'"
            [centerText]="!isHideUnitTypeControl"
            (blur)="onValueControlChange(salaryRecordModel, 'Units')"
          >
          </numeric-edit>
          <!-- Units -->

          <!-- UnitTypeId -->
          <combobox-edit-control
            *ngIf="!isHideUnitTypeControl"
            [editMode]="salaryRecordModel.AllowEditUnitType"
            [textField]="'Name'"
            [idField]="'Id'"
            [comboboxDataSource]="unitTypes"
            [(value)]="salaryRecordModel.UnitTypeId"
            (valueChange)="onValueControlChange(salaryRecordModel, 'UnitTypeId')"
            [setTimeout]="false"
            [nullValueLabel]="'DropdownList.None' | translate"
          ></combobox-edit-control>
          <!-- UnitTypeId -->
        </div>
        <!-- Units UnitTypeId -->

        <!-- AmountPerUnit -->
        <div class="Card-inner">
          <numeric-edit
            [label]="'EmploymentPayrollData.Per Unit' | translate"
            [editMode]="true"
            [disable]="!salaryRecordModel.AllowEditAmountPerUnit"
            [(value)]="salaryRecordModel.AmountPerUnit"
            [option]="NumericOptions"
            (blur)="onValueControlChange(salaryRecordModel, 'AmountPerUnit')"
          ></numeric-edit>
        </div>
        <!-- AmountPerUnit -->

        <!-- Amount -->
        <div class="Card-inner">
          <numeric-edit
            [label]="'EmploymentPayrollData.Amount' | translate"
            [editMode]="true"
            [(value)]="salaryRecordModel.Amount"
            [option]="NumericOptions"
            (blur)="onValueControlChange(salaryRecordModel, 'Amount')"
          ></numeric-edit>
        </div>
        <!-- Amount -->

        <!-- PersistenceTypeId -->
        <div class="Card-inner">
          <combobox-edit-control
            [label]="'EmploymentPayrollData.After next batch' | translate"
            [editMode]="true"
            [(value)]="salaryRecordModel.PersistenceTypeId"
            [comboboxDataSource]="persistanceTypes"
            [textField]="'Name'"
            [idField]="'Id'"
            (valueChange)="onValueControlChange(salaryRecordModel, 'PersistenceTypeId')"
          >
          </combobox-edit-control>
        </div>
        <!-- PersistenceTypeId -->

        <!-- StartDate -->
        <div *ngIf="!isHideDateColumns" class="Card-inner">
          <date-picker-control
            [label]="'EmploymentPayrollData.From date' | translate"
            [editMode]="true"
            [(value)]="salaryRecordModel.StartDate"
            (valueChange)="onValueControlChange(salaryRecordModel, 'StartDate')"
          ></date-picker-control>
        </div>
        <!-- StartDate -->

        <!-- EndDate -->
        <div *ngIf="!isHideDateColumns" class="Card-inner">
          <date-picker-control
            [label]="'EmploymentPayrollData.To date' | translate"
            [editMode]="true"
            [(value)]="salaryRecordModel.EndDate"
            (valueChange)="onValueControlChange(salaryRecordModel, 'EndDate')"
          >
          </date-picker-control>
        </div>
        <!-- EndDate -->

        <!-- 1 Dimension1Value -->
        <div *ngIf="Dimension1Preference && Dimension1Preference.Value" class="Card-inner">
          <auto-complete-textbox
            [editMode]="true"
            [autoCompleteDataSource]="DimensionXValue[0]"
            [(value)]="salaryRecordModel.Dimension1Value"
            [label]="Dimension1Preference?.Value"
          ></auto-complete-textbox>
        </div>
        <!-- 1 Dimension1Value -->

        <!-- 2 Dimension2Value -->
        <div *ngIf="Dimension2Preference && Dimension2Preference.Value" class="Card-inner">
          <auto-complete-textbox
            [editMode]="true"
            [autoCompleteDataSource]="DimensionXValue[1]"
            [(value)]="salaryRecordModel.Dimension2Value"
            [label]="Dimension2Preference?.Value"
          ></auto-complete-textbox>
        </div>
        <!-- 2 Dimension2Value -->

        <!-- 3 Dimension3Value -->
        <div *ngIf="Dimension3Preference && Dimension3Preference.Value" class="Card-inner">
          <auto-complete-textbox
            [editMode]="true"
            [autoCompleteDataSource]="DimensionXValue[2]"
            [(value)]="salaryRecordModel.Dimension3Value"
            [label]="Dimension3Preference?.Value"
          ></auto-complete-textbox>
        </div>
        <!-- 3 Dimension3Value -->
      </fieldset>
    </form>
  </mobile-modal-content>
  <mobile-modal-footer>
    <button *ngIf="!flagUpdate" (click)="CreateNewModel()" class="Button--primary" [disabled]="!isValidModel">
      {{ 'GlobalDialog.Create' | translate }}
    </button>
    <button *ngIf="flagUpdate" (click)="UpdateModel()" class="Button--primary" [disabled]="!isValidModel">
      {{ 'GlobalDialog.Update' | translate }}
    </button>
    <button *ngIf="flagUpdate" (click)="confirmDeleteDialogVisible = true" class="Button--destructive">
      {{ 'GlobalDialog.Delete' | translate }}
    </button>
    <button class="Button--primary" (click)="oncloseInsertOrUpdatesalaryRecords()">
      {{ 'GlobalDialog.Close' | translate }}
    </button>
  </mobile-modal-footer>
</mobile-modal>

<negative-default-info
  [setting]="negativeDefaultMoreDialogModel.setting"
  [fieldName]="negativeDefaultMoreDialogModel.fieldName"
  [dataItem]="negativeDefaultMoreDialogModel.dataItem"
  [(showDialog)]="showNegativeDefaultMoreInfoDialog"
  (usePositiveAmount)="onMoreInfoUsePositiveAmount($event)"
  (enableAutoCorrect)="autoCorrect($event)"
></negative-default-info>

<app-dialog
  (action)="onNegativeDefaulChange($event)"
  [(visible)]="showNegativeDefaulChange"
  [leadingText]="'EmploymentPayrollData.NegativeDefaultMessage_EnableSetting' | translate"
>
  <app-dialog-action [type]="'MoreInfo'"></app-dialog-action>
  <app-dialog-action [type]="'UsePositiveAmountThisTime'"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onConfirmDeleteDialogAction($event)" [(visible)]="confirmDeleteDialogVisible">
  <div [innerHTML]="'Grids.Confirm Delete' | translate"></div>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Delete'"></app-dialog-action>
</app-dialog>
