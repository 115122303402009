import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RxApiDataService } from './rx-data.service';
import { SessionService } from './SharedServices/Session/SessionService';
var RxDataService = /** @class */ (function (_super) {
    tslib_1.__extends(RxDataService, _super);
    function RxDataService(httpClient, sessionService) {
        var _this = _super.call(this) || this;
        _this.httpClient = httpClient;
        _this.sessionService = sessionService;
        _this.companyUserListChanged = false;
        _this.employmentTemplatesChanged = false;
        _this.sessionService.isAlive.subscribe(function () {
            if (!_this.sessionService.checkIsAliveError) {
                _this.sessionService.checkIsAliveActive = true;
                _super.prototype.Auth_IsSessionAlive.call(_this).subscribe(function () {
                    _this.sessionService.checkIsAliveActive = false;
                }, function () {
                    /* Ignore errors from IsSessionAlive call */
                    _this.sessionService.checkIsAliveActive = false;
                    _this.sessionService.checkIsAliveError = true;
                });
            }
        });
        return _this;
    }
    Object.defineProperty(RxDataService.prototype, "needToReloadCompanyUsers", {
        get: function () {
            return !this.companyUsers || this.companyUsers.length === 0 || this.companyUserListChanged;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RxDataService.prototype, "needToReloadEmployeeTemplate", {
        get: function () {
            return !this.employmentTemplates || this.employmentTemplatesChanged;
        },
        enumerable: true,
        configurable: true
    });
    RxDataService.prototype.httpGet = function (path, options) {
        return this.httpClient.get(path, options);
    };
    RxDataService.prototype.httpDelete = function (path, options) {
        return this.httpClient.delete(path, options);
    };
    RxDataService.prototype.httpPost = function (path, object, options) {
        return this.httpClient.post(path, object, { headers: { 'Content-Type': 'application/json' } });
    };
    RxDataService.prototype.httpPut = function (path, object, options) {
        return this.httpClient.put(path, object, { headers: { 'Content-Type': 'application/json' } });
    };
    RxDataService.prototype.httpDownload = function (path, options) {
        return this.httpClient
            .get(path, { observe: 'response', responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json' } })
            .pipe(map(function (result) {
            return result;
        }));
    };
    RxDataService.prototype.httpPostDownload = function (path, object, options) {
        return this.httpClient
            .post(path, object, {
            observe: 'response',
            responseType: 'arraybuffer',
            headers: { 'Content-Type': 'application/json' }
        })
            .pipe(map(function (result) {
            return result;
        }));
    };
    RxDataService.prototype.httpPostAttachment = function (path, data) {
        return this.httpClient.post(path, data).pipe(map(function (result) {
            return result;
        }));
    };
    RxDataService.prototype.httpPostAttachmentAndDownload = function (path, data, options) {
        return this.httpClient.post(path, data, { responseType: 'arraybuffer' }).pipe(map(function (result) {
            return result;
        }));
    };
    RxDataService.prototype.roundDecimal = function (value, decimals) {
        if (value) {
            return parseFloat(value.toFixed(decimals));
        }
        return undefined;
    };
    RxDataService.prototype.Users_GetUserImage = function (userId) {
        var path = this.baseUrl + '/api/users/{userId}/image';
        if (userId) {
            path = this.baseUrl + '/api/users/{userId}/image';
            path = path.replace('{userId}', userId.toString());
            return this.httpGet(path);
        }
        else {
            return _super.prototype.Users_GetCurrentUserImage.call(this);
        }
    };
    RxDataService.prototype.SalaryStatements_PostPdfFromClient_Encoded = function (salaryStatement) {
        var path = this.baseUrl + 'api/salarystatements/pdf/fromclient/encoded';
        return this.httpPost(path, salaryStatement);
    };
    RxDataService.prototype.Companies_UploadCompanyLogo = function (formData) {
        var path = this.baseUrl + '/api/companies/current/logo';
        return this.httpPostAttachment(path, formData);
    };
    RxDataService.prototype.Users_CurrentImage = function (formData) {
        var path = this.baseUrl + '/api/users/current/image';
        return this.httpPostAttachment(path, formData);
    };
    RxDataService.prototype.User_UploadEmployeeImage = function (data, userId) {
        var path = this.baseUrl + '/api/users/{userId}/image';
        path = path.replace('{userId}', userId.toString());
        return this.httpPostAttachment(path, data);
    };
    RxDataService.prototype.CompanyUsers_UpdateCompanyUser = function (companyUser) {
        var _this = this;
        return _super.prototype.CompanyUsers_UpdateCompanyUser.call(this, companyUser).pipe(tap(function () { return (_this.companyUserListChanged = true); }));
    };
    RxDataService.prototype.CompanyUsers_AddUserToCompany = function (request) {
        var _this = this;
        return _super.prototype.CompanyUsers_AddUserToCompany.call(this, request).pipe(tap(function () { return (_this.companyUserListChanged = true); }));
    };
    RxDataService.prototype.CompanyUsers_DeleteCompanyUser = function (companyUserId) {
        var _this = this;
        return _super.prototype.CompanyUsers_DeleteCompanyUser.call(this, companyUserId).pipe(tap(function () { return (_this.companyUserListChanged = true); }));
    };
    RxDataService.prototype.loadEmploymentTemplates_GetEmploymentTemplates = function () {
        var _this = this;
        return Observable.create(function (observer) {
            _super.prototype.EmploymentTemplates_GetEmploymentTemplates.call(_this).subscribe(function (data) {
                _this.employmentTemplatesChanged = false;
                _this.employmentTemplates = data;
                observer.next(data.slice());
            }, function (error) {
                throwError(error);
            });
        });
    };
    RxDataService.prototype.EmploymentTemplates_GetEmploymentTemplates = function (forceLoad) {
        var _this = this;
        if (forceLoad === void 0) { forceLoad = false; }
        if (forceLoad || this.needToReloadEmployeeTemplate) {
            this.employmentTemplates = []; //// block new request when waiting the response of the api
            return this.loadEmploymentTemplates_GetEmploymentTemplates();
        }
        return Observable.create(function (observer) {
            if (_this.employmentTemplates.length > 0) {
                observer.next(_this.employmentTemplates.slice());
            }
            else {
                //// wait the api CompanyUsers_GetCompanyUsers response
                setTimeout(function () {
                    observer.next(_this.employmentTemplates.slice());
                }, 4000);
            }
        });
    };
    RxDataService.prototype.EmploymentTemplates_UpdateEmploymentTemplate = function (template) {
        var _this = this;
        return Observable.create(function (observer) {
            _super.prototype.EmploymentTemplates_UpdateEmploymentTemplate.call(_this, template).subscribe(function (data) {
                _this.employmentTemplatesChanged = true;
                observer.next(data);
            }, function (error) {
                throwError(error);
            });
        });
    };
    RxDataService.prototype.EmploymentTemplates_CreateEmploymentTemplate = function (template) {
        var _this = this;
        return Observable.create(function (observer) {
            _super.prototype.EmploymentTemplates_CreateEmploymentTemplate.call(_this, template).subscribe(function (data) {
                _this.employmentTemplatesChanged = true;
                observer.next(data);
            }, function (error) {
                throwError(error);
            });
        });
    };
    RxDataService.prototype.EmploymentTemplates_DeleteEmploymentTemplate = function (templateId) {
        var _this = this;
        return Observable.create(function (observer) {
            _super.prototype.EmploymentTemplates_DeleteEmploymentTemplate.call(_this, templateId).subscribe(function (result) {
                _this.employmentTemplatesChanged = true;
                observer.next(result);
            }, function (error) {
                throwError(error);
            });
        });
    };
    RxDataService.prototype.SalaryBatches_GetSalaryPeriods = function (cycleId) {
        var _this = this;
        return Observable.create(function (observer) {
            if (_this.salaryPeriods && _this.cycleId === cycleId) {
                observer.next(_this.salaryPeriods.slice());
            }
            else {
                _super.prototype.SalaryBatches_GetSalaryPeriods.call(_this, cycleId).subscribe(function (data) {
                    _this.salaryPeriods = data;
                    _this.cycleId = cycleId;
                    observer.next(data.slice());
                }, function (error) {
                    throwError(error);
                });
            }
        });
    };
    RxDataService.prototype.CompanyUsers_GetCurrentTaxInfo = function (userEmploymentId) {
        return _super.prototype.Employments_GetCurrentTaxInfo.call(this, userEmploymentId);
    };
    RxDataService.prototype.EmploymentTemplates_GetEmploymentTemplate = function (templateId) {
        var _this = this;
        return Observable.create(function (observer) {
            _super.prototype.EmploymentTemplates_GetEmploymentTemplate.call(_this, templateId).subscribe(function (template) {
                if (template) {
                    if (template.VacationTypeId === 0) {
                        template.VacationTypeId = undefined;
                    }
                    if (template.PensionProviderId === 0) {
                        template.PensionProviderId = undefined;
                    }
                    if (template.PensionBaseCalculationMethodId === 0) {
                        template.PensionBaseCalculationMethodId = undefined;
                    }
                    if (template.Pension2ProviderId === 0) {
                        template.Pension2ProviderId = undefined;
                    }
                    if (template.Pension2BaseCalculationMethodId === 0) {
                        template.Pension2BaseCalculationMethodId = undefined;
                    }
                    if (template.SalaryCycleId === 0) {
                        template.SalaryCycleId = undefined;
                    }
                    if (template.VacationProviderId === 0) {
                        template.VacationProviderId = undefined;
                    }
                    if (template.VacationSupplementPayoutMonth === 0) {
                        template.VacationSupplementPayoutMonth = undefined;
                    }
                }
                observer.next(template);
            }, function (error) {
                throwError(error);
            });
        });
    };
    RxDataService.prototype.SalaryRecords_GetSalaryRecordsByEmployment = function (userEmploymentId) {
        var _this = this;
        return Observable.create(function (observer) {
            _super.prototype.SalaryRecords_GetSalaryRecordsByEmployment.call(_this, userEmploymentId).subscribe(function (salaryRecords) {
                if (salaryRecords) {
                    salaryRecords.forEach(function (salaryRecord) {
                        if (salaryRecord.Units) {
                            salaryRecord.Units = _this.roundDecimal(salaryRecord.Units, 2);
                        }
                        if (salaryRecord.AmountPerUnit) {
                            salaryRecord.AmountPerUnit = _this.roundDecimal(salaryRecord.AmountPerUnit, 2);
                        }
                        if (salaryRecord.Amount) {
                            salaryRecord.Amount = _this.roundDecimal(salaryRecord.Amount, 2);
                        }
                    });
                }
                observer.next(salaryRecords);
            }, function (error) {
                throwError(error);
            });
        });
    };
    RxDataService.prototype.Users_SetLanguageContext = function (languageId) {
        var path = this.baseUrl + '/api/users/current/language/{languageId}';
        path = path.replace('{languageId}', languageId.toString());
        return this.httpPut(path, undefined);
    };
    RxDataService.prototype.Miscellaneous_FailNotFound = function () {
        var path = this.baseUrl + '/api/fail/notfound';
        return this.httpGet(path);
    };
    RxDataService.prototype.CompanyDataImport_GetEmployeeTemplate = function () {
        var path = this.baseUrl + '/api/import/employees/template';
        return this.httpDownload(path);
    };
    RxDataService.prototype.CompanyDataImport_GetEmployeeTemplateFilled = function () {
        var path = this.baseUrl + '/api/import/employees/template/filled';
        return this.httpDownload(path);
    };
    RxDataService.prototype.CompanyDataImport_GetPayrollImportTemplate = function (idType) {
        var path = this.baseUrl + '/api/import/salaryrecordsrow/template/id/{idType}';
        path = path.replace('{idType}', idType.toString());
        return this.httpDownload(path);
    };
    RxDataService.prototype.CompanyDataImport_GetTimeEntriesImportTemplate = function (idType) {
        var path = this.baseUrl + '/api/import/timeentry/template/id/{idType}';
        path = path.replace('{idType}', idType.toString());
        return this.httpDownload(path);
    };
    RxDataService.prototype.CompanyDataImport_GetBalancesTemplate = function (date) {
        var path = this.baseUrl + '/api/import/balances/template/perdate/{yyyyMMdd}';
        path = path.replace('{yyyyMMdd}', date);
        return this.httpDownload(path);
    };
    RxDataService.prototype.CompanyDataImport_GetBalancesImportTemplate = function (date) {
        var path = this.baseUrl + '/api/import/balances/template/filled/perdate/{yyyyMMdd}';
        path = path.replace('{yyyyMMdd}', date);
        return this.httpDownload(path);
    };
    RxDataService.prototype.CompanyDataImport_GetBalancesTemplateByEmploymentId = function (date, employmentId) {
        var path = this.baseUrl + '/api/import/balances/template/perdate/{yyyyMMdd}/employment/{employmentId}';
        path = path.replace('{yyyyMMdd}', date);
        path = path.replace('{employmentId}', employmentId);
        return this.httpDownload(path);
    };
    RxDataService.prototype.CompanyDataImport_PostEmployeeFilledTemplate = function (data) {
        var path = this.baseUrl + '/api/import/employees/execute';
        return this.httpPostAttachment(path, data);
    };
    RxDataService.prototype.CompanyDataImport_PostEmployeeFilledTemplateWithOptions = function (data, options) {
        var path = this.baseUrl + '/api/import/employees/execute/options/{options}';
        path = path.replace('{options}', options.toString());
        return this.httpPostAttachment(path, data);
    };
    RxDataService.prototype.EmployeeDataImport_PerformBalancesCorrection = function (data) {
        var path = this.baseUrl + '/api/import/balances/correction';
        return this.httpPostAttachment(path, data);
    };
    RxDataService.prototype.CompanyDataImport_PostBalancesFilledTemplate = function (data, options) {
        var path = this.baseUrl + '/api/import/balances/{options}';
        path = path.replace('{options}', options.toString());
        return this.httpPostAttachment(path, data);
    };
    RxDataService.prototype.SalaryStatements_GetHtml = function (salaryStatementId, outputType) {
        if (outputType === void 0) { outputType = 'html'; }
        return _super.prototype.SalaryStatements_GetHtml.call(this, salaryStatementId, outputType);
    };
    RxDataService.prototype.ImportMapping_GetExcelHeadersOfFile = function (data, operation, startrow) {
        var path = this.baseUrl + '/api/import/columnheaders/operation/{operation}/startrow/{startrow}';
        path = path.replace('{operation}', operation.toString());
        path = path.replace('{startrow}', startrow.toString());
        return this.httpPostAttachment(path, data);
    };
    RxDataService.prototype.ImportMapping_CustomImportExcel = function (mappingId, data) {
        var path = this.baseUrl + '/api/import/mappings/{mappingId}/execute';
        path = path.replace('{mappingId}', mappingId.toString());
        return this.httpPostAttachment(path, data);
    };
    RxDataService.prototype.ImportMapping_CustomImportExcelOptions = function (mappingId, options, data) {
        var path = this.baseUrl + '/api/import/mappings/{mappingId}/execute/options/{options}';
        path = path.replace('{mappingId}', mappingId.toString());
        path = path.replace('{options}', options.toString());
        return this.httpPostAttachment(path, data);
    };
    RxDataService.prototype.CompanyDataImport_DanlonPdf = function (operation, data) {
        var path = this.baseUrl + '/api/import/danloenpdf/{operation}';
        path = path.replace('{operation}', operation.toString());
        return this.httpPostAttachment(path, data);
    };
    RxDataService.prototype.CompanyDataImport_DanlonPdfToExcel = function (operation, data) {
        var path = this.baseUrl + '/api/import/danloenpdf/{operation}/Excel';
        path = path.replace('{operation}', operation.toString());
        return this.httpPostAttachmentAndDownload(path, data);
    };
    RxDataService.prototype.CompanyDataImport_PlanDayToExcel = function (operation, data) {
        var path = this.baseUrl + '/api/import/convert/planday/excel/{operation}';
        path = path.replace('{operation}', operation.toString());
        return this.httpPostAttachmentAndDownload(path, data);
    };
    RxDataService.prototype.CompanyDataImport_DatFileAofToExcel = function (operation, data) {
        var path = this.baseUrl + 'api/import/aof/excel';
        return this.httpPostAttachmentAndDownload(path, data);
    };
    RxDataService.prototype.CompanyDataImport_DatFileToExcel = function (operation, data) {
        var path = this.baseUrl + '/api/import/convert/epwin/excel/{dataType}';
        path = path.replace('{dataType}', operation);
        return this.httpPostAttachmentAndDownload(path, data);
    };
    RxDataService.prototype.CompanyDataImport_CustomPreviewOfFile = function (mappingId, size, data) {
        var path = this.baseUrl + '/api/import/preview/mappings/{mappingId}/size/{size}';
        path = path.replace('{mappingId}', mappingId.toString());
        path = path.replace('{size}', size.toString());
        return this.httpPostAttachment(path, data);
    };
    RxDataService.prototype.Import_PayrollDataImport = function (data, options) {
        var path = this.baseUrl + '/api/import/salaryrecords/{options}';
        path = path.replace('{options}', options.toString());
        return this.httpPostAttachment(path, data);
    };
    RxDataService.prototype.SalaryStatements_GetPdf = function (salaryStatementId) {
        var path = this.baseUrl + '/api/salarystatements/{salaryStatementId}/pdf';
        path = path.replace('{salaryStatementId}', salaryStatementId.toString());
        return this.httpDownload(path);
    };
    RxDataService.prototype.SalaryStatements_GetPdfMergedBySalaryBatch = function (salaryBatchId) {
        var path = this.baseUrl + '/api/salarystatements/salarybatch/{salaryBatchId}/pdf/merged';
        path = path.replace('{salaryBatchId}', salaryBatchId.toString());
        return this.httpDownload(path);
    };
    RxDataService.prototype.Reports_GetPdfReport = function (request) {
        var path = this.baseUrl + '/api/reports/pdf';
        return this.httpPostDownload(path, request);
    };
    RxDataService.prototype.Reports_GetXlsxReportDownload = function (request) {
        var path = this.baseUrl + '/api/reports/xlsx';
        return this.httpPostDownload(path, request);
    };
    RxDataService.prototype.Reports_GetCsvReportDownload = function (request) {
        var path = this.baseUrl + '/api/reports/csv';
        return this.httpPostDownload(path, request);
    };
    RxDataService.prototype.Reports_GetBankReportDownload = function (request) {
        var path = this.baseUrl + '/api/reports/bank';
        return this.httpPostDownload(path, request);
    };
    RxDataService.prototype.Reports_GetHtmlReportDownload = function (request) {
        var path = this.baseUrl + '/api/reports/html';
        return this.httpPost(path, request);
    };
    return RxDataService;
}(RxApiDataService));
export { RxDataService };
