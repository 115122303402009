<div class="Login" tabindex="1" (keydown)="keydown($event)">
  <div class="Login-wrapper">
    <div class="Login-logo" (click)="openLink()">
      <img src="images/gratisal-logo-horizontal.svg" alt="Gratisal" />
    </div>

    <form class="Form--stacked Form--space" action="" onsubmit="return false;">
      <div class="FormElement">
        <div>
          <label class="FormElement-label" for="loginUsername">{{ 'Login.Username' | translate }}</label>
          <div class="right">
            <button id="LanguageDropdownToggleButton" tabindex="2" (click)="onToggleLanguageDropdown()">
              <div>
                <div class="Avatar Avatar-square">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGBSURBVHjaYvzIwPCPAQqADIG/f/+9evVBUvIfmIuM/oDVAAQQC5DFUV0NVv4PiBgZGZl4eblLiv99/fb/z5//v38zgEkg+9/v3y83bQIIIBawtv//njxl+Pv3PxABwd+/f+8//PflM0jdr9//f//6/+sXUDWTrCzQdIAALM1BDgBADAFA/f+PSahm9+YwuAc4X3ev7cSiHz0ts0EjEVgBOBpzGwAAEAQS99/WDkgU7e95IWi+NuQ7VE03KHz7KiRykwKvAGIE2g90938wgBj//x/QRob/GICRienjhw8AAcTCAJdjAEOwvv/YACPIqH8AAcTyipmZNyvr7/37IFf9+sW1a9f/jx+/+Pr9+/wJ4h6IB4CyLEpKT86dAwggsA2QgAO6FUhCLPv1k+HnT6ggUMMfYOD+BXoV6AeAAAJ5+v/vP0ySkmBj/oICmZkZGIIMX74wQoL/zx+mv2DVf0GyAAHE+BQchZCIBCKxt2//PHr0xtAQLghJB5BoZmJgAAgwAAauWfWiVmegAAAAAElFTkSuQmCC"
                    *ngIf="language === 'da'"
                    title="{{ 'Login.Changelanguage' | translate }}"
                    alt="{{ 'Login.Changelanguage' | translate }}"
                  />
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAflJREFUeNpinDRzn5qN3uFDt16+YWBg+Pv339+KGN0rbVP+//2rW5tf0Hfy/2+mr99+yKpyOl3Ydt8njEWIn8f9zj639NC7j78eP//8739GVUUhNUNuhl8//ysKeZrJ/v7z10Zb2PTQTIY1XZO2Xmfad+f7XgkXxuUrVB6cjPVXef78JyMjA8PFuwyX7gAZj97+T2e9o3d4BWNp84K1NzubTjAB3fH0+fv6N3qP/ir9bW6ozNQCijB8/8zw/TuQ7r4/ndvN5mZgkpPXiis3Pv34+ZPh5t23//79Rwehof/9/NDEgMrOXHvJcrllgpoRN8PFOwy/fzP8+gUlgZI/f/5xcPj/69e/37//AUX+/mXRkN555gsOG2xt/5hZQMwF4r9///75++f3nz8nr75gSms82jfvQnT6zqvXPjC8e/srJQHo9P9fvwNtAHmG4f8zZ6dDc3bIyM2LTNlsbtfM9OPHH3FhtqUz3eXX9H+cOy9ZMB2o6t/Pn0DHMPz/b+2wXGTvPlPGFxdcD+mZyjP8+8MUE6sa7a/xo6Pykn1s4zdzIZ6///8zMGpKM2pKAB0jqy4UE7/msKat6Jw5mafrsxNtWZ6/fjvNLW29qv25pQd///n+5+/fxDDVbcc//P/zx/36m5Ub9zL8+7t66yEROcHK7q5bldMBAgwADcRBCuVLfoEAAAAASUVORK5CYII="
                    *ngIf="language === 'en'"
                    title="{{ 'Login.Changelanguage' | translate }}"
                    alt="{{ 'Login.Changelanguage' | translate }}"
                  />
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAIAAADkharWAAABVUlEQVR4AWP4/v37H6IBUDHD169f/yOBf7///Pv56/+/f/+xAaBihIa/n79+2n301cTFL3vmv1+149ezV/g0/P32/dWkxfdC8u8G5d4Lyr0bmPukpOvnw2c4NXzaf/KWQ9xNm6ibNtEQdMMi4mX/ArjbEBo+Pnzy68mLV5MX33ZNvO2egkCuSY9ym37cfvDr8Yufj58DEZABVMxwPaH8mobX86apv1+9/f3yDQK9ef9h497rhoHXdPygSNv3RmIFw2Wv1AvcRrecEoCKUNz79+/Tit4LPCYX+c2giM8UqJjhokfyBR7jC3ymj/Pbfj1/DVX89fubuWsuyziApHhN4OiiRwrDBbckoA0gxGt80yoKGDjPaife9cu+JGJ1gcsIqAGOgGqAihnOuSedEzA7J2gOQnwmZ3mNgegcrwlMEIGAIkDFDBfmLj9S13O0vpcgAioDKgYAALeZDJKBxD8AAAAASUVORK5CYII="
                    *ngIf="language === 'kl'"
                    title="{{ 'Login.Changelanguage' | translate }}"
                    alt="{{ 'Login.Changelanguage' | translate }}"
                  />
                </div>
                <app-icon [icon]="'ChevronDown'" [size]="'xxsmall'"></app-icon>
              </div>
            </button>

            <div
              id="LanguageDropdown"
              class="Dropdown Dropdown--top Dropdown-language"
              [ngClass]="{ 'is-active': languageDropdownOpen }"
            >
              <ul role="menu">
                <li class="Dropdown-item" *ngIf="language !== 'da'">
                  <button type="button" title="Dansk" (mousedown)="changeLanguageTo('da')">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGBSURBVHjaYvzIwPCPAQqADIG/f/+9evVBUvIfmIuM/oDVAAQQC5DFUV0NVv4PiBgZGZl4eblLiv99/fb/z5//v38zgEkg+9/v3y83bQIIIBawtv//njxl+Pv3PxABwd+/f+8//PflM0jdr9//f//6/+sXUDWTrCzQdIAALM1BDgBADAFA/f+PSahm9+YwuAc4X3ev7cSiHz0ts0EjEVgBOBpzGwAAEAQS99/WDkgU7e95IWi+NuQ7VE03KHz7KiRykwKvAGIE2g90938wgBj//x/QRob/GICRienjhw8AAcTCAJdjAEOwvv/YACPIqH8AAcTyipmZNyvr7/37IFf9+sW1a9f/jx+/+Pr9+/wJ4h6IB4CyLEpKT86dAwggsA2QgAO6FUhCLPv1k+HnT6ggUMMfYOD+BXoV6AeAAAJ5+v/vP0ySkmBj/oICmZkZGIIMX74wQoL/zx+mv2DVf0GyAAHE+BQchZCIBCKxt2//PHr0xtAQLghJB5BoZmJgAAgwAAauWfWiVmegAAAAAElFTkSuQmCC"
                      title="Dansk"
                      alt="Dansk"
                    />
                  </button>
                </li>
                <li class="Dropdown-item" *ngIf="language !== 'en'">
                  <button type="button" title="English" (mousedown)="changeLanguageTo('en')">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAflJREFUeNpinDRzn5qN3uFDt16+YWBg+Pv339+KGN0rbVP+//2rW5tf0Hfy/2+mr99+yKpyOl3Ydt8njEWIn8f9zj639NC7j78eP//8739GVUUhNUNuhl8//ysKeZrJ/v7z10Zb2PTQTIY1XZO2Xmfad+f7XgkXxuUrVB6cjPVXef78JyMjA8PFuwyX7gAZj97+T2e9o3d4BWNp84K1NzubTjAB3fH0+fv6N3qP/ir9bW6ozNQCijB8/8zw/TuQ7r4/ndvN5mZgkpPXiis3Pv34+ZPh5t23//79Rwehof/9/NDEgMrOXHvJcrllgpoRN8PFOwy/fzP8+gUlgZI/f/5xcPj/69e/37//AUX+/mXRkN555gsOG2xt/5hZQMwF4r9///75++f3nz8nr75gSms82jfvQnT6zqvXPjC8e/srJQHo9P9fvwNtAHmG4f8zZ6dDc3bIyM2LTNlsbtfM9OPHH3FhtqUz3eXX9H+cOy9ZMB2o6t/Pn0DHMPz/b+2wXGTvPlPGFxdcD+mZyjP8+8MUE6sa7a/xo6Pykn1s4zdzIZ6///8zMGpKM2pKAB0jqy4UE7/msKat6Jw5mafrsxNtWZ6/fjvNLW29qv25pQd///n+5+/fxDDVbcc//P/zx/36m5Ub9zL8+7t66yEROcHK7q5bldMBAgwADcRBCuVLfoEAAAAASUVORK5CYII="
                      title="English"
                      alt="English"
                    />
                  </button>
                </li>
                <li class="Dropdown-item" *ngIf="language !== 'kl'">
                  <button type="button" title="Greenlandic" (mousedown)="changeLanguageTo('kl')">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAIAAADkharWAAABVUlEQVR4AWP4/v37H6IBUDHD169f/yOBf7///Pv56/+/f/+xAaBihIa/n79+2n301cTFL3vmv1+149ezV/g0/P32/dWkxfdC8u8G5d4Lyr0bmPukpOvnw2c4NXzaf/KWQ9xNm6ibNtEQdMMi4mX/ArjbEBo+Pnzy68mLV5MX33ZNvO2egkCuSY9ym37cfvDr8Yufj58DEZABVMxwPaH8mobX86apv1+9/f3yDQK9ef9h497rhoHXdPygSNv3RmIFw2Wv1AvcRrecEoCKUNz79+/Tit4LPCYX+c2giM8UqJjhokfyBR7jC3ymj/Pbfj1/DVX89fubuWsuyziApHhN4OiiRwrDBbckoA0gxGt80yoKGDjPaife9cu+JGJ1gcsIqAGOgGqAihnOuSedEzA7J2gOQnwmZ3mNgegcrwlMEIGAIkDFDBfmLj9S13O0vpcgAioDKgYAALeZDJKBxD8AAAAASUVORK5CYII="
                      title="Greenlandic"
                      alt="Greenlandic"
                    />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="FormElement-control">
          <input
            class="FormElement-input"
            autofocus
            #loginUsername
            name="loginUsername"
            id="loginUsername"
            type="text"
            autocorrect="off"
            autocomplete="false"
            spellcheck="false"
            [(ngModel)]="userName"
            placeholder="{{ 'Login.CPR/Email/Phone' | translate }}"
          />
        </div>
      </div>

      <div class="FormElement">
        <label class="FormElement-label" for="loginPassword">{{ 'Login.Password' | translate }}</label>
        <button
          id="LoginForgotPasswordButton"
          class="FormElement-labelHelp"
          type="button"
          tabindex="-1"
          (click)="onResetPassword()"
        >
          {{ 'Login.ForgotPassword' | translate }}
        </button>
        <div class="FormElement-control FormElement-control--iconRight">
          <input
            class="FormElement-input"
            #loginPassword
            name="loginPassword"
            id="loginPassword"
            [type]="showPassword ? 'text' : 'password'"
            [(ngModel)]="password"
            placeholder="{{ 'Login.Password' | translate }}"
            autocorrect="true"
            autocomplete="true"
          />
          <button
            id="showPasswordButton"
            class="FormElement-passwordToggle"
            type="button"
            (click)="showPaswordForMobile()"
            (mousedown)="showPassword = true"
            (mouseup)="showPassword = false"
            (mouseleave)="showPassword = false"
          >
            <app-icon [icon]="'Preview'"></app-icon>
          </button>
        </div>
      </div>

      <div class="FormElement login-checkbox">
        <div class="FormElement-control">
          <label class="FormElement-checkbox">
            <input type="checkbox" name="rememberme-checkbox" id="RememberMeCheckbox" [(ngModel)]="remember" />
            <span class="FormElement-checkboxFaux"></span>
            <span class="FormElement-label">{{ 'Login.Remember me' | translate }}</span>
          </label>
        </div>
      </div>

      <div class="FormElement" *ngIf="Message">
        <div class="Message--warning">{{ Message }}</div>
      </div>

      <div class="FormElement" *ngIf="IsAndroidMobile">
        <a
          id="android-app-btn"
          class="k-button Button--primary Button--Icon"
          href="market://details?id=com.gratisal.mobile"
          target="_top"
          rel="noopener"
        >
          <div class="AdroidAppText u-spacing-right">{{ 'Login.TryAndroidApp' | translate }}</div>
          <img class="Play-button" src="images/googleplay.png" />
        </a>
      </div>

      <!--itms-apps://-->
      <div class="FormElement" *ngIf="IsIOsMobile">
        <a
          id="ios-app-btn"
          class="k-button Button--primary Button--Icon"
          href="https://itunes.apple.com/us/app/gratisal/id1344796373?mt=8"
          target="_top"
          rel="noopener"
        >
          <div class="AdroidAppText u-spacing-right">{{ 'Login.TryIOsApp' | translate }}</div>
          <app-icon [icon]="'AppStore'" class="Play-button"></app-icon>
        </a>
      </div>
      <button [disabled]="disableLogin" id="login-btn" class="Button--primary u-block" type="button" (click)="login()">
        {{ 'Login.Login' | translate }}
      </button>
    </form>
  </div>

  <div *ngIf="!isIOSMobileApp" class="Login-signup">
    <a id="SignUpLink" (click)="signupDialogVisible = true">
      {{ 'Login.Idonthaveanaccount' | translate }}
    </a>
  </div>
</div>

<footer [ngClass]="{ 'u-hidden': hideFooter }" class="Login-footer">
  <ul>
    <li>
      <a id="LoginForgotPasswordLink" (click)="onResetPassword()">
        {{ 'Login.ForgotPassword' | translate }}
      </a>
    </li>
    <li *ngIf="!isIOSMobileApp">
      <a id="TermsLink" href="http://support.gratisal.com/hc/da/articles/208402625" target="_blank" rel="noopener">
        {{ 'Login.TermsConditions' | translate }}
      </a>
    </li>
    <li *ngIf="!isIOSMobileApp">
      <a id="WhatIsGratisalLink" href="http://www.gratisal.com/" target="_blank" rel="noopener">
        {{ 'Login.WhatIsGratisal' | translate }}
      </a>
    </li>
  </ul>
</footer>

<accept-dpa [(isVisible)]="showAcceptDPADialog" (accepted)="onDPATermAccepted($event)"></accept-dpa>

<reset-password [(isVisible)]="showResetPasswordDialog" [email]="preFillEmailResetPassword"></reset-password>

<changetemporarypassword
  [(isVisible)]="changeTemporaryPasswordVisible"
  (temporaryPasswordChanged)="onTemporaryPasswordChanged($event)"
></changetemporarypassword>

<app-dialog [(visible)]="signupDialogVisible">
  <p [innerHTML]="'Login.Idonthaveanaccountmodaltext' | translate"></p>

  <app-dialog-action [type]="'Close'"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onUpgradeWarningDialogAction($event)" [(visible)]="upgradeWarningDialogVisible">
  <div [innerHTML]="'Error.RequireUpdateClientVersion' | translate"></div>

  <app-dialog-action [type]="'Update'"></app-dialog-action>
  <app-dialog-action [type]="'Ignore'"></app-dialog-action>
</app-dialog>

<app-dialog (action)="unsupportedIEDialogAction($event)" [(visible)]="unsupportedIEDialogVisible">
  <div [innerHTML]="'Warning.UnsupportedIE' | translate"></div>
  <app-dialog-action [action]="'Firefox'" [label]="'Warning.No' | translate"></app-dialog-action>
  <app-dialog-action [action]="'Chrome'" [label]="'Warning.Yes' | translate"></app-dialog-action>
  <app-dialog-action
    [action]="'Ignore'"
    [class]="'Button--destructive'"
    [label]="'Warning.Continue' | translate"
  ></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="unsupportedBrowserDialogVisible">
  <div *ngIf="IsIOsMobile || unsupportIOSApp" [innerHTML]="'Login.unsuportedIOS' | translate"></div>
  <div *ngIf="!IsIOsMobile && !unsupportIOSApp">
    <div *ngIf="sessionService.browser.isSupportedBrowser" [innerHTML]="'Login.UnsupportedBrowsers' | translate"></div>
    <div *ngIf="!sessionService.browser.isOutdatedBrowser" [innerHTML]="'Login.OutDatedBrowsers' | translate"></div>
  </div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="localStorageDialogVisible">
  Du benytter en browser som Gratisal ikke officielt understøtter. Det er muligt at systemet stadig virker fint, især
  hvis browseren understøtter moderne standarder for HTML5. I modsat fald bør du overveje at skifte til en anden
  browser.<br />
  <br />
  (Bemærk at du kan få denne besked hvis du benytter en understøttet browser men kører i Private/Incognito mode, eller
  hvis basale funktioner som Javascript eller "local storage" er slået fra.)

  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog [title]="modalService.title" [(visible)]="modalService.alertVisible" [minWidth]="380">
  <div><p [innerHTML]="modalService.message"></p></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
