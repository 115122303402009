<!--<div class="EmployeeInfo-row EmployeeInfo-rowName">-->
<h3 class="EmployeeInfo-name" *ngIf="!editMode">
  {{ companyUser.FirstName }} {{ companyUser.MiddleName }} {{ companyUser.LastName }}
</h3>

<!-- NewEmployee.FirstNameErrorMessage -->
<!---->
<app-text-edit
  *ngIf="editMode"
  [label]="'Employee.First name' | translate"
  [editMode]="editMode"
  [type]="'text'"
  [required]="true"
  [(isValid)]="childIsValid[0]"
  [(isDirty)]="childIsDirty[0]"
  [(value)]="companyUser.FirstName"
  (valueChange)="onChange()"
></app-text-edit>

<app-text-edit
  *ngIf="editMode"
  [label]="'Employee.Middle name' | translate"
  [editMode]="editMode"
  [type]="'text'"
  [(isValid)]="childIsValid[1]"
  [(isDirty)]="childIsDirty[1]"
  [(value)]="companyUser.MiddleName"
  (valueChange)="onChange()"
></app-text-edit>

<app-text-edit
  *ngIf="editMode"
  [label]="'Employee.Last name' | translate"
  [editMode]="editMode"
  [type]="'text'"
  [required]="true"
  [(isValid)]="childIsValid[2]"
  [(isDirty)]="childIsDirty[2]"
  [(value)]="companyUser.LastName"
  (valueChange)="onChange()"
></app-text-edit>
