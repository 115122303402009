import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ListHelper } from '../../../Common/ListHelper';
import { IListDataItem } from '../../../Model/IListDataItem';
import { IDepartment } from '../../../Services/ApiModel';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'department-filter',
  templateUrl: './DepartmentFilter.component.html'
})
export class DepartmentFilterComponent implements OnInit, OnDestroy {
  private hasDepartmentDataValue = true;
  @Input()
  public get hasDepartmentData(): boolean {
    return this.hasDepartmentDataValue;
  }
  public set hasDepartmentData(value: boolean) {
    if (this.hasDepartmentDataValue !== value) {
      this.hasDepartmentDataValue = value;
      setTimeout(() => {
        this.hasDepartmentDataChange.emit(this.hasDepartmentDataValue);
      });
    }
  }
  @Output() hasDepartmentDataChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() change: EventEmitter<number> = new EventEmitter<number>();

  public departmentId = 0;
  public departmentDataSource: IListDataItem[];

  constructor(
    private translateService: TranslateService,
    private sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) => {
      this.translateDepartmentDataSource();
    });

    this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: string): void => {
      this.translateDepartmentDataSource();
    });
  }

  ngOnInit(): void {
    this.translateDepartmentDataSource();
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private translateDepartmentDataSource(): void {
    this.translateService.get(['Employee.All Departments']).subscribe((translations: { [key: string]: string }) => {
      const allDepartmentItem: IListDataItem = {
        Id: -1,
        Text: translations['Employee.All Departments']
      };

      this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe((departments: IDepartment[]) => {
        this.hasDepartmentData = departments && departments.length > 0;
        departments = departments.sort((a: IDepartment, b: IDepartment) => (a.IsActive > b.IsActive ? -1 : 1));
        this.departmentDataSource = ListHelper.mapDataSource(
          this.sessionService,
          departments,
          'Id',
          'Name',
          allDepartmentItem,
          false
        );
        setTimeout(() => {
          this.departmentId = -1;
        });
      });
    });
  }

  public onComboBoxChanged(): void {
    setTimeout(() => {
      this.change.emit(this.departmentId);
    });
  }
}
