import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../Common/Constants';
import { IChangePasswordRequest } from '../../Services/ApiModel';
import { Broadcaster } from '../../Services/Broadcaster';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'changetemporarypassword',
  templateUrl: './ChangeTemporaryPassword.component.html'
})
export class ChangeTemporaryPasswordComponent implements OnInit, OnDestroy {
  @Input() public currentPassword: string;

  private isVisibleValue = false;
  @Input()
  public get isVisible(): boolean {
    return this.isVisibleValue;
  }
  public set isVisible(value: boolean) {
    if (this.isVisibleValue !== value) {
      this.isVisibleValue = value;
      this.isVisibleChange.emit(value);
    }
  }
  @Output() public isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public temporaryPasswordChanged: EventEmitter<string> = new EventEmitter<string>();

  public isChangePassworSuccessDialog = false;
  public newPass: string;
  public showPassword = false;
  public userName: string;

  constructor(
    private dataService: RxDataService,
    private broadcaster: Broadcaster,
    public sessionService: SessionService
  ) {
    this.userName = localStorage.getItem('saveLoginUserName');
  }

  public keydown(e: any): void {
    if (e.keyCode !== 13) {
      return;
    }

    this.onChangePassword();
  }

  public onChangePasswordDialogAction(action: string): void {
    if (action === 'ChangePassword') {
      this.onChangePassword();
    } else {
      this.isVisible = false;
    }
  }

  public onChangePassSuccessNotificationAction(action: string): void {
    this.temporaryPasswordChanged.emit(this.newPass);
    this.currentPassword = '';
    this.newPass = '';
    this.isVisible = false;
  }

  public ngOnInit(): void {
    this.broadcaster
      .on<string>(Constants.ACCOUNT_CHANGE_TEMPORARY_PASSWORD)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((temporaryPassword: string) => {
        this.currentPassword = temporaryPassword;
      });
  }

  public ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private onChangePassword(): void {
    const request: IChangePasswordRequest = { CurrentPassword: this.currentPassword, NewPassword: this.newPass };
    this.dataService.Auth_ChangePassword(request).subscribe(
      () => {
        this.isChangePassworSuccessDialog = true;
      },
      (error: any): any => {
        this.isVisible = true;
      }
    );
  }
}
