import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { IExternalSystem, IExternalSystemConfiguration, IExternalSystemCredential, IExternalSystemOperation, IIntegration, IIntegrationConfiguration, IIntegrationCredential, IIntegrationOperation, IIntegrationStatus, ISimpleKeyValuePair } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { CompanyStandardSalaryTypesComponent } from '../SimpleSalaryTypes/CompanyStandardSalaryTypes.component';
var IntegrationsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(IntegrationsComponent, _super);
    function IntegrationsComponent(dataService, staticDataService, sessionService, settingService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.sessionService = sessionService;
        _this.settingService = settingService;
        _this.dialogVisable = false;
        _this.isDirtyValues = false;
        _this.hasIntegrationModule = false;
        _this.isShowingAlertMessage = false;
        _this.isShowingDeleteMessage = false;
        _this.isReadonlyUser = false;
        _this.isShowingChangeIemMessage = false;
        _this.OperationRunSucess = false;
        _this.nameCreate = '';
        _this.ApiDataList = [];
        _this.ngUnsubscribe = new Subject();
        _this.isShowingAutoMap = false;
        _this.isReadonlyUser = _this.sessionService.role.IsReadOnly;
        return _this;
    }
    Object.defineProperty(IntegrationsComponent.prototype, "isDirty", {
        get: function () {
            return this.isDirtyValues;
        },
        set: function (value) {
            if (this.isDirtyValues !== value) {
                this.isDirtyValues = value;
                this.isDirtyChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationsComponent.prototype, "isComboboxIntegrationVisable", {
        get: function () {
            return this.integrationModel && this.integrationModel.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    IntegrationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadIntegrationMode();
        this.settingService.showModuleMessage('company.integrations');
        this.staticDataService.ExternalSystem.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (externalSystems) {
            _this.extenalSystem =
                externalSystems && externalSystems.length
                    ? externalSystems.filter(function (model) { return model.IsActive === true; })
                    : undefined;
            if (_this.extenalSystem && _this.extenalSystem.length > 0) {
                _this.currentExtenalSystemId = _this.extenalSystem[0].Id;
            }
            if (_this.extenalSystem) {
                _this.extenalSystem = _this.extenalSystem.filter(function (model) { return model.Id !== -1; });
                if (_this.extenalSystem && _this.extenalSystem.length > 0) {
                    _this.currentExtenalSystemId = _this.extenalSystem[0].Id;
                    _this.globalExtenalSystem = _this.extenalSystem[0];
                }
            }
            _this.onBaseIntegrationCreateComboboxChanged();
        });
        this.staticDataService.IntegrationStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.statusCode = data); });
        this.staticDataService.ExternalSystemOperation.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.externalSystemOperation = data); });
        this.staticDataService.ExternalSystemCredential.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.externalSystemCredential = data); });
        this.staticDataService.ExternalSystemConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.externalSystemConfiguration = data); });
        this.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (value) {
            if (!value) {
                _this.saveValueControl();
            }
        });
    };
    IntegrationsComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    IntegrationsComponent.prototype.onDoubleClick = function () {
        if (this.hasIntegrationModule) {
            if (!this.isReadonlyUser && this.isComboboxIntegrationVisable) {
                this.editMode = true;
            }
        }
    };
    IntegrationsComponent.prototype.applyDiscard = function () {
        if (this.isDirty) {
            this.isShowingAlertMessage = true;
        }
        else {
            this.loadIntegrationMode();
            this.isDirty = false;
            this.editMode = false;
            this.editModeChange.emit(this.editMode);
        }
    };
    IntegrationsComponent.prototype.runOperation = function (operationId) {
        var _this = this;
        if (operationId === 16) {
            this.operationId = operationId;
            this.isShowingAutoMap = true;
            return;
        }
        this.dataService.Integrations_RunIntegrationsOperation(this.currentIntegrationId, operationId).subscribe(function () {
            _this.OperationRunSucess = true;
            _this.loadIntegrationMode();
        });
    };
    IntegrationsComponent.prototype.onAutoMap = function (event) {
        var _this = this;
        if (event && event === 'Proceed') {
            this.dataService
                .Integrations_RunIntegrationsOperation(this.currentIntegrationId, this.operationId)
                .subscribe(function () { return _this.loadIntegrationMode(); });
        }
    };
    IntegrationsComponent.prototype.onBaseIntegrationCreateComboboxChanged = function () {
        var _this = this;
        if (this.extenalSystem && this.currentExtenalSystemId) {
            this.globalExtenalSystem = this.extenalSystem.find(function (exs) { return exs.Id === _this.currentExtenalSystemId; });
        }
        if (this.globalExtenalSystem && this.globalExtenalSystem.Description) {
            this.descriptionCreateIntegration = this.globalExtenalSystem.Description;
            return;
        }
        this.descriptionCreateIntegration = 'No description available';
    };
    IntegrationsComponent.prototype.onCreateDialogCreate = function (action) {
        var _this = this;
        if (action === 'Create' && this.globalExtenalSystem) {
            this.dataService.Integrations_CreateIntegration(this.globalExtenalSystem.Id).subscribe(function (data) {
                _this.staticDataService.loadExternalSystemConfiguration();
                _this.staticDataService.loadExternalSystemCredential();
                _this.nameCreate = _this.globalExtenalSystem.Name;
                _this.editMode = true;
                _this.onCloseDialogCreate();
            });
        }
    };
    IntegrationsComponent.prototype.onDeleteDialog = function () {
        this.isShowingDeleteMessage = true;
    };
    IntegrationsComponent.prototype.SelectIntegrationListView = function (integrationId) {
        if (this.editMode && this.isDirty) {
            this.nextIntegrationIdChange = integrationId;
            this.isShowingChangeIemMessage = true;
            return;
        }
        this.isDirty = false;
        this.editMode = false;
        this.editModeChange.emit(this.editMode);
        this.currentIntegrationId = integrationId;
        this.loadIntegrationById(integrationId);
    };
    IntegrationsComponent.prototype.getStatusMessageColor = function (integrationstatusmessageId) {
        if (integrationstatusmessageId && this.statusCode && this.statusCode.length > 0) {
            return this.statusCode.find(function (model) { return model.Id === integrationstatusmessageId; }).Key;
        }
        return '';
    };
    IntegrationsComponent.prototype.getStatusMessageColorOperation = function (statuscode) {
        if (statuscode && this.statusCode && this.statusCode.length > 0) {
            return this.statusCode.find(function (model) { return model.Id === statuscode; }).Key;
        }
        return '';
    };
    IntegrationsComponent.prototype.getStatusMessage = function (integrationstatusmessageId) {
        if (integrationstatusmessageId && this.statusCode && this.statusCode.length > 0) {
            return this.statusCode.find(function (model) { return model.Id === integrationstatusmessageId; }).Description;
        }
        return '';
    };
    IntegrationsComponent.prototype.isFistIntegration = function (model) {
        if (this.integrationModel && model === this.integrationModel[0]) {
            return true;
        }
        return false;
    };
    IntegrationsComponent.prototype.checkIntegrationSupportUrl = function (model) {
        if (this.extenalSystem) {
            var tempexternalsystem = this.extenalSystem.find(function (z) { return z.Id === model.ExternalSystemId; });
            if (tempexternalsystem && tempexternalsystem.SupportUrl) {
                return true;
            }
        }
        return false;
    };
    IntegrationsComponent.prototype.applyUrl = function (model) {
        if (this.extenalSystem) {
            var tempexternalsystem = this.extenalSystem.find(function (z) { return z.Id === model.ExternalSystemId; });
            if (tempexternalsystem && tempexternalsystem.SupportUrl) {
                if (tempexternalsystem.SupportUrl.includes('http') === false) {
                    tempexternalsystem.SupportUrl = 'http://' + tempexternalsystem.SupportUrl;
                }
                window.open(tempexternalsystem.SupportUrl, '_blank');
            }
        }
    };
    IntegrationsComponent.prototype.irDirtyChange = function (changeis) {
        this.isDirty = changeis;
    };
    IntegrationsComponent.prototype.onUnSaveWarningDialogAction = function (action) {
        if (action === 'CancelDiscard') {
            this.isDirty = true;
            this.editMode = true;
            this.editModeChange.emit(this.editMode);
            this.nextIntegrationIdChange = undefined;
        }
        else {
            this.loadIntegrationMode();
            this.isDirty = false;
            this.editMode = false;
            this.editModeChange.emit(this.editMode);
            if (this.nextIntegrationIdChange) {
                this.currentIntegrationId = this.nextIntegrationIdChange;
                this.loadIntegrationById(this.nextIntegrationIdChange);
                this.nextIntegrationIdChange = undefined;
            }
        }
    };
    IntegrationsComponent.prototype.onChangeTab = function (event) {
        if (event) {
            if (event === 'ContinueAndSave') {
                this.editMode = false;
                this.editModeChange.emit(this.editMode);
                this.isDirty = false;
                return;
            }
            else {
                this.isDirty = false;
                this.editMode = false;
                this.editModeChange.emit(this.editMode);
                this.isShowingChangeIemMessage = false;
            }
            if (this.nextIntegrationIdChange) {
                this.currentIntegrationId = this.nextIntegrationIdChange;
                this.loadIntegrationById(this.nextIntegrationIdChange);
                this.nextIntegrationIdChange = undefined;
            }
        }
    };
    IntegrationsComponent.prototype.onDeleteWarningDialogAction = function (action) {
        var _this = this;
        if (action === 'Delete') {
            if (this.currentIntegrationModel && this.currentIntegrationModel.Id !== -1) {
                this.dataService.Integrations_DeleteIntegration(this.currentIntegrationModel.Id).subscribe(function () {
                    _this.currentIntegrationId = undefined;
                    _this.currentIntegrationModel = undefined;
                    _this.credentialControl = [];
                    _this.configurationControl = [];
                    _this.integrationConfigurationControl = [];
                    _this.onCloseDialogCreate();
                });
            }
        }
        else {
            this.onCloseDialogCreate();
        }
    };
    IntegrationsComponent.prototype.listControlConfiguration = function () {
        this.configurationControl = [];
        this.credentialControl = [];
        this.integrationConfigurationControl = [];
        if (this.currentExtenalSystem && this.currentIntegrationModel) {
            if ((this.currentExtenalSystem.Operations && this.currentExtenalSystem.Operations.length > 0) ||
                (this.currentIntegrationModel.IntegrationOperations &&
                    this.currentIntegrationModel.IntegrationOperations.length > 0)) {
                var _loop_1 = function (i) {
                    var control = this_1.createControlOperation(this_1.currentExtenalSystem.Operations[i]);
                    if (control) {
                        var filterIntegrationOperations = this_1.currentIntegrationModel.IntegrationOperations.filter(function (z) { return z.OperationId === control.Id; });
                        if (filterIntegrationOperations && filterIntegrationOperations[0]) {
                            control.value = filterIntegrationOperations[0].IsActive;
                            control.translatemessage = filterIntegrationOperations[0].TranslatedStatusMessage;
                            control.statuscode = filterIntegrationOperations[0].StatusCode;
                            control.Description = filterIntegrationOperations[0].Description
                                ? filterIntegrationOperations[0].Description
                                : '';
                        }
                        control.integrationID = this_1.currentExtenalSystem.Id;
                        this_1.configurationControl.push(control);
                    }
                };
                var this_1 = this;
                for (var i = 0; i < this.currentExtenalSystem.Operations.length; i++) {
                    _loop_1(i);
                }
            }
            if ((this.currentExtenalSystem.CredentialKeys && this.currentExtenalSystem.CredentialKeys.length > 0) ||
                (this.currentIntegrationModel.IntegrationCredentials &&
                    this.currentIntegrationModel.IntegrationCredentials.length > 0)) {
                var _loop_2 = function (i) {
                    var control = this_2.createControlCredential(this_2.currentExtenalSystem.CredentialKeys[i]);
                    if (control) {
                        var filterIntegrationCredentials = this_2.currentIntegrationModel.IntegrationCredentials.filter(function (z) { return z.Key === control.key; });
                        if (filterIntegrationCredentials && filterIntegrationCredentials[0]) {
                            control.value = filterIntegrationCredentials[0].Value;
                            control.Description = filterIntegrationCredentials[0].ExternalSystemCredential.Description;
                        }
                        this_2.credentialControl.push(control);
                    }
                };
                var this_2 = this;
                for (var i = 0; i < this.currentExtenalSystem.CredentialKeys.length; i++) {
                    _loop_2(i);
                }
            }
            if ((this.currentExtenalSystem.ConfigurationKeys && this.currentExtenalSystem.ConfigurationKeys.length > 0) ||
                (this.currentIntegrationModel.IntegrationConfigurations &&
                    this.currentIntegrationModel.IntegrationConfigurations.length > 0)) {
                var _loop_3 = function (i) {
                    var control = this_3.createControlIntegrationConfiguration(this_3.currentExtenalSystem.ConfigurationKeys[i]);
                    if (control) {
                        var filterIntegrationConfigurations = this_3.currentIntegrationModel.IntegrationConfigurations.filter(function (z) { return z.Key === control.key; });
                        if (filterIntegrationConfigurations && filterIntegrationConfigurations[0]) {
                            control.value = filterIntegrationConfigurations[0].Value;
                        }
                        this_3.integrationConfigurationControl.push(control);
                        if (control.type === 'Dropdown') {
                            this_3.loadComboboxDataSource(control);
                        }
                    }
                };
                var this_3 = this;
                for (var i = 0; i < this.currentExtenalSystem.ConfigurationKeys.length; i++) {
                    _loop_3(i);
                }
            }
        }
    };
    IntegrationsComponent.prototype.loadComboboxDataSource = function (control) {
        var _this = this;
        var listDataSourceParts = this.getPartsFromListDataSourceStringOfCombobox(control.listDataSource);
        control.idField = listDataSourceParts.underlyingId;
        control.textField = listDataSourceParts.displayText;
        var dataModel = this.ApiDataList && this.ApiDataList.length > 0
            ? this.ApiDataList.find(function (model) { return model.api === listDataSourceParts.api; })
            : null;
        if (dataModel) {
            this.assignControlDataSource(control, listDataSourceParts, dataModel.data);
        }
        else {
            this.dataService.httpGet(environment.apiUrl + '/' + listDataSourceParts.api).subscribe(function (data) {
                if (data && data.length > 0) {
                    data.forEach(function (model) {
                        model[control.idField] = control.idField && model[control.idField] ? model[control.idField].toString() : '';
                    });
                }
                _this.assignControlDataSource(control, listDataSourceParts, data);
                var nextModel = {};
                nextModel.api = listDataSourceParts.api;
                nextModel.data = data;
                _this.ApiDataList.push(nextModel);
            });
        }
    };
    IntegrationsComponent.prototype.assignControlDataSource = function (control, listDataSourceParts, data) {
        var _this = this;
        var sameApiComboboxes = this.integrationConfigurationControl.filter(function (ctrl) {
            if (ctrl.listDataSource) {
                var ctrlListDataSourceParts = _this.getPartsFromListDataSourceStringOfCombobox(ctrl.listDataSource);
                return ctrlListDataSourceParts.api === listDataSourceParts.api;
            }
            else {
                return false;
            }
        });
        if (!sameApiComboboxes || sameApiComboboxes.length === 0) {
            return;
        }
        control.dataSource = data;
        control.dataSourceEmpty = data === undefined || data === null || data.length < 1;
        if (sameApiComboboxes && sameApiComboboxes.length > 0) {
            sameApiComboboxes.forEach(function (apiCombo) {
                if (!apiCombo.dataSource || apiCombo.dataSource.length === 0) {
                    apiCombo.dataSource = data;
                    apiCombo.dataSourceEmpty = data === undefined || data === null || data.length < 1;
                }
            });
        }
    };
    IntegrationsComponent.prototype.getPartsFromListDataSourceStringOfCombobox = function (stringListDataSource) {
        stringListDataSource = stringListDataSource.replace(':', ';');
        var parts = stringListDataSource.split(';');
        var externalSystemName = this.currentExtenalSystem
            ? this.currentExtenalSystem.Name
                ? this.currentExtenalSystem.Name
                : this.currentExtenalSystem.Key
            : '';
        if (parts[0].indexOf('{externalSystemName}') >= 0) {
            parts[0] = parts[0].replace('{externalSystemName}', externalSystemName);
        }
        if (parts[0].indexOf('{IntegrationId}') >= 0) {
            parts[0] = parts[0].replace('{IntegrationId}', this.currentIntegrationId.toString());
        }
        return {
            api: parts[0],
            underlyingId: parts[1],
            displayText: parts[2]
        };
    };
    IntegrationsComponent.prototype.createControlOperation = function (extenalsystemOperations) {
        if (extenalsystemOperations) {
            var extenal = this.externalSystemOperation.find(function (model) { return model.Key === extenalsystemOperations.Key; });
            return {
                Id: extenalsystemOperations.Id,
                name: extenalsystemOperations.Name,
                key: extenalsystemOperations.Key,
                IsSingular: extenalsystemOperations.IsSingular,
                Description: extenal && extenal.Description ? extenal.Description : ''
            };
        }
        return undefined;
    };
    IntegrationsComponent.prototype.createControlCredential = function (name) {
        if (name) {
            var finResult = this.externalSystemCredential.find(function (model) { return model.Key === name; });
            if (finResult) {
                return {
                    name: finResult.Name ? finResult.Name : '',
                    key: name
                };
            }
        }
        return undefined;
    };
    IntegrationsComponent.prototype.createControlIntegrationConfiguration = function (name) {
        if (name) {
            var configuration = this.externalSystemConfiguration.find(function (model) { return model.Key === name; });
            return {
                name: configuration && configuration.Name ? configuration.Name : '',
                key: name,
                type: configuration && configuration.FormElement ? configuration.FormElement : '',
                listDataSource: configuration && configuration.ListDataSource ? configuration.ListDataSource : undefined,
                Description: configuration && configuration.Description ? configuration.Description : ''
            };
        }
        return undefined;
    };
    IntegrationsComponent.prototype.saveValueControl = function () {
        if (this.isDirty) {
            this.saveValueControlParam(this.configurationControl, this.credentialControl, this.integrationConfigurationControl);
        }
    };
    IntegrationsComponent.prototype.sortIntegrationLoadByDateCreate = function (model, model2) {
        return model.Created < model2.Created ? 1 : 0;
    };
    IntegrationsComponent.prototype.loadIntegrationMode = function () {
        var _this = this;
        // this.salaryTypes.loadData();
        this.dataService.Integrations_RetrieveIntegrations().subscribe(function (integrationLoad) {
            _this.integrationModel = integrationLoad;
            if (_this.integrationModel.length < 1) {
                _this.editMode = false;
                _this.editModeChange.emit(_this.editMode);
            }
            _this.integrationModel = _this.integrationModel.sort(function (a, b) {
                return _this.sortIntegrationLoadByDateCreate(a, b);
            });
            if (_this.nameCreate && _this.integrationModel.find(function (z) { return z.Name === _this.nameCreate; })) {
                var integrationfilter = [];
                integrationfilter = _this.integrationModel.filter(function (z) { return z.Name === _this.nameCreate; });
                if (integrationfilter.length > 1) {
                    _this.currentIntegrationId = integrationfilter[integrationfilter.length - 1].Id;
                }
                else if (integrationfilter.length === 1) {
                    _this.currentIntegrationId = integrationfilter[0].Id;
                }
                _this.nameCreate = '';
                _this.loadIntegrationById(_this.currentIntegrationId);
            }
            else if (_this.currentIntegrationId) {
                _this.loadIntegrationById(_this.currentIntegrationId);
            }
            else if (_this.integrationModel && _this.integrationModel.length > 0) {
                _this.loadIntegrationById(_this.integrationModel[0].Id);
            }
        });
    };
    IntegrationsComponent.prototype.loadIntegrationById = function (value) {
        var _this = this;
        this.dataService.Integrations_RetrieveIntegrationWithAllChildren(value).subscribe(function (data) {
            _this.currentIntegrationModel = data;
            _this.currentIntegrationId = _this.currentIntegrationModel.Id;
            if (_this.extenalSystem && _this.currentIntegrationModel) {
                _this.currentExtenalSystem = _this.extenalSystem.find(function (z) { return z.Id === _this.currentIntegrationModel.ExternalSystemId; });
            }
            if (_this.currentExtenalSystem) {
                _this.listControlConfiguration();
            }
            else {
                _this.configurationControl = [];
                _this.credentialControl = [];
                _this.integrationConfigurationControl = [];
            }
        });
    };
    IntegrationsComponent.prototype.onCloseDialogCreate = function () {
        if (this.extenalSystem && this.extenalSystem.length > 0) {
            this.currentExtenalSystemId = this.extenalSystem[0].Id;
            this.globalExtenalSystem = this.extenalSystem[0];
            this.descriptionCreateIntegration = this.globalExtenalSystem.Description;
        }
        else {
            this.descriptionCreateIntegration = '';
        }
        this.loadIntegrationMode();
        this.dialogVisable = false;
    };
    IntegrationsComponent.prototype.saveValueControlParam = function (arraycontrol, arraycontrol2, arraycontrol3) {
        var _this = this;
        var iSimpleKeyValuePairArray = [];
        if (arraycontrol) {
            arraycontrol.forEach(function (control) {
                var simpleKeyValuePairtemp = {};
                simpleKeyValuePairtemp.Key = 'Operation.' + control.Id;
                simpleKeyValuePairtemp.Value = control.value ? control.value.toString() : 'false';
                iSimpleKeyValuePairArray.push(simpleKeyValuePairtemp);
            });
        }
        if (arraycontrol2) {
            arraycontrol2.forEach(function (control) {
                var simpleKeyValuePairtemp = {};
                simpleKeyValuePairtemp.Key = 'Credential.' + control.key;
                simpleKeyValuePairtemp.Value = control.value ? control.value : '';
                iSimpleKeyValuePairArray.push(simpleKeyValuePairtemp);
            });
        }
        if (arraycontrol3) {
            arraycontrol3.forEach(function (control) {
                var simpleKeyValuePairtemp = {};
                simpleKeyValuePairtemp.Key = 'Configuration.' + control.key;
                simpleKeyValuePairtemp.Value = control.value ? control.value : '';
                iSimpleKeyValuePairArray.push(simpleKeyValuePairtemp);
            });
        }
        this.dataService
            .Integrations_SetIntegrationValuesByKey(this.currentIntegrationId, iSimpleKeyValuePairArray)
            .subscribe(function () {
            _this.loadIntegrationMode();
            _this.ResetFrom();
        }, function () {
            _this.loadIntegrationMode();
            _this.ResetFrom();
        });
    };
    IntegrationsComponent.prototype.ResetFrom = function () {
        this.isDirty = false;
    };
    return IntegrationsComponent;
}(FormComponentBase));
export { IntegrationsComponent };
