import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IEIncomeResponseLine } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SalaryBatchViewModel } from '../SalaryBatchViewModel';
import { SalaryBatchErrorsView } from './SalaryBatchErrorsView';

@Component({
  selector: 'salary-batch-errors-dialog',
  templateUrl: './SalaryBatchErrorsDialog.component.html'
})
export class SalaryBatchErrorsDialogComponent implements OnDestroy {
  @Input() public isTab = true;

  private salaryBatchValue: SalaryBatchViewModel;
  @Input() public get salaryBatch(): SalaryBatchViewModel {
    return this.salaryBatchValue;
  }
  public set salaryBatch(value: SalaryBatchViewModel) {
    if (this.salaryBatchValue !== value) {
      this.salaryBatchValue = value;
      if (this.visible && value) {
        this.loadData();
      }
    }
  }

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.gridData = [];
      this.visibleValue = value;
      this.visibleChange.emit(value);
      if (value) {
        this.loadData();
      }
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public refreshSource: EventEmitter<void> = new EventEmitter<void>();
  @Output() public resendBatch: EventEmitter<number> = new EventEmitter<number>();

  public gridData: SalaryBatchErrorsView[] = [];
  public clearErrorsDialogVisible = false;

  public get isFinalized(): boolean {
    return this.salaryBatch && this.salaryBatch.StatusId === 40;
  }

  private errorTooltip: string;
  private warningTooltip: string;

  constructor(
    private dataService: RxDataService,
    public sessionService: SessionService,
    private translateService: TranslateService
  ) {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService
        .get([
          'CompanySalaryBatches.OrtherStatusGridErrorIconTooltip',
          'CompanySalaryBatches.OrtherStatusGridWarningIconTooltip'
        ])
        .subscribe((translations: { [key: string]: string }) => {
          this.warningTooltip = translations['CompanySalaryBatches.OrtherStatusGridWarningIconTooltip'];
          this.errorTooltip = translations['CompanySalaryBatches.OrtherStatusGridErrorIconTooltip'];
        });
    });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDialogAction(action: string): void {
    switch (action) {
      case 'ClearErrors':
        this.clearErrorsDialogVisible = true;
        break;
      case 'Resend':
        this.resendBatch.emit();
        break;
    }
  }

  public clearErrorsDialogAction(action: string): void {
    if (action === 'Proceed') {
      this.dataService.SalaryBatches_ClearEIncomeErrors(this.salaryBatch.Id).subscribe(() => {
        this.refreshSource.emit();
        this.visible = false;
      });
    }
  }

  private loadData(): void {
    if (this.salaryBatch && this.salaryBatch.StatusId === 40) {
      this.dataService
        .SalaryBatches_GetEIncomeResponseLines(this.salaryBatch.Id)
        .subscribe((data: IEIncomeResponseLine[]): void => {
          this.gridData = data.map(
            (d: IEIncomeResponseLine) => new SalaryBatchErrorsView(d, this.errorTooltip, this.warningTooltip)
          );
        });
    }
  }
}
