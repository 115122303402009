import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Transition, TransitionService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../Common/Global';
import { RouterStateService } from '../Common/RouterState.service';
import { ICompanyUser, IReportArea, ISimpleKeyValuePair, IUserEmployment } from '../Services/ApiModel';
import { RxDataService } from '../Services/RxDataService';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../Services/SharedServices/StaticData.service';
import { ReportParametersDefaultValue } from '../SharedComponents/ReportsDialog/ReportParametersDefaultValue';

@Component({
  selector: 'selfservice',
  templateUrl: './SelfService.component.html'
})
export class SelfServiceComponent implements OnInit, AfterViewInit {
  private reportAreas: IReportArea[];
  private editModeValue = false;
  @Input()
  public get editMode(): boolean {
    return this.editModeValue;
  }
  public set editMode(value: boolean) {
    if (this.editModeValue !== value) {
      this.editModeValue = value;
      this.editModeChange.emit(value);
    }
  }
  @Output() public editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get reportKey(): string {
    if (this.state.currentStateIs('tabs.selfservice.general')) {
      return 'SelfServiceGeneral';
    }

    if (this.state.currentStateIs('tabs.selfservice.payslip')) {
      return 'SelfServicePayslips';
    }

    if (this.state.currentStateIs('tabs.selfservice.time')) {
      return 'SelfServiceTime';
    }

    return '';
  }

  public get hasEmployments(): boolean {
    return this.companyUser && this.companyUser.UserEmployments && this.companyUser.UserEmployments.length > 0;
  }

  public get isMobileInSelfservicetime(): boolean {
    return this.sessionService.browser.isMobile && this.state.currentStateIs('tabs.selfservice.time');
  }

  public get reportViewEnabled(): boolean {
    const area: IReportArea = this.reportAreas.find((a: IReportArea) => a.Key === this.reportKey);
    if (area) {
      return true;
    }
    return false;
    // return this.sessionService.feature.ReportViewEnabled;
  }

  public get currentPosition(): number {
    return this.userEmployment ? this.userEmployment.Id : undefined;
  }

  public get disabledActionButtonClass(): string {
    return this.allowUsersToModifyOwnData ? '' : 'disabledActionButtonClass';
  }

  public get isFirefox(): boolean {
    return this.sessionService.browser.isFirefox;
  }

  private get selfServicePlayslipTabEnabled(): boolean {
    return this.hasEmployments;
  }

  private get selfServiceTimeTabEnabled(): boolean {
    return this.sessionService.feature.hasModuleId(8) && this.hasEmployments;
  }

  public companyUser: ICompanyUser;
  public userEmployment: IUserEmployment;
  public reportParams: ReportParametersDefaultValue;
  public reportDialogVisible = false;
  public createNewTimeEntryDialogVisible = false;
  public isValid = true;
  public discardDialogVisible = false;
  public noReportVisible = false;
  public allowUsersToModifyOwnData = false;
  public isDirty = false;
  public showSaveDataConfirmationChangeState = false;
  public isDisableChangeEditMode = false;

  private shouldUpdateUserEmployment = false;
  private originalCompanyUserData: ICompanyUser;
  private navigationToStateName: string;

  constructor(
    public sessionService: SessionService,
    public state: RouterStateService,
    private dataService: RxDataService,
    private staticDataService: StaticDataService,
    private transitionService: TransitionService
  ) {}

  public ngOnInit(): void {
    this.staticDataService.ReportArea.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IReportArea[]) => (this.reportAreas = data)
    );
    if (this.sessionService.selfServiceTabState !== '') {
      this.sessionService.currentState = this.sessionService.selfServiceTabState;
      setTimeout(() => {
        this.checkSwitchingSelfServiceTab();
      });
    }
    this.loadCompanyUser();
    const allowUsersToModifyOwnData: ISimpleKeyValuePair = Global.COMPANY_PREFERENCES.find(
      (model: ISimpleKeyValuePair) => model.Key === 'Access.AllowUsersToModifyOwnData'
    );
    if (allowUsersToModifyOwnData && allowUsersToModifyOwnData.Value === 'true') {
      this.allowUsersToModifyOwnData = true;
    }
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngAfterViewInit(): void {
    this.transitionService.onStart({}, (transition: Transition) => {
      const fromState: any = transition.from().name;
      this.navigationToStateName = transition.to().name;
      const generalState = 'tabs.selfservice.general';

      if (this.editMode && fromState && fromState === generalState) {
        if (this.isDirty) {
          // If validation fails, alert user and do nothing.
          // Else show action confirmation.
          this.showSaveDataConfirmationChangeState = true;
          return false;
        } else {
          // Editmode but no changes, we close editmode without doing anything
          this.editMode = false;
          return true;
        }
      } else {
        this.editMode = false;
        this.editModeChange.emit(false);
        return true;
      }
    });
  }

  public onTabClick(): void {
    this.sessionService.menuToggled = true;
    this.sessionService.showUserMenuToggled = false;
    this.sessionService.showMoreMenuToggled = false;
    this.sessionService.showHelpMenuToggled = false;
  }

  public onConfirmSaveDataOnChangeState(action: string): void {
    if (action === 'ContinueAndSave') {
      this.editMode = false;
      if (this.navigationToStateName) {
        this.sessionService.NavigateTo(this.navigationToStateName);
      }
    } else if (action === 'DiscardAndLeave') {
      this.discardChanges();
      this.isDirty = false;
      if (this.navigationToStateName) {
        this.sessionService.NavigateTo(this.navigationToStateName);
      }
    }

    this.navigationToStateName = undefined;
  }

  public onUserEmploymentChange(newUserEmployment: IUserEmployment): void {
    this.userEmployment = newUserEmployment;
  }

  public onShowReportsEventClick(): void {
    if (!this.companyUser) {
      return;
    }
    const parameters: ReportParametersDefaultValue = new ReportParametersDefaultValue();
    parameters.employeeId = this.companyUser ? this.companyUser.Id : undefined;
    parameters.employmentId = this.userEmployment ? this.userEmployment.Id : undefined;
    this.reportParams = parameters;
    this.reportDialogVisible = true;
  }

  public onValidated(isPassed: boolean): void {
    this.isValid = isPassed;
    this.shouldUpdateUserEmployment = isPassed ? true : false;
  }

  public onEmployeeGeneralChanged(): void {
    this.shouldUpdateUserEmployment = true;
  }

  public onEditModeChange(editMode: boolean): void {
    if (!this.allowUsersToModifyOwnData) {
      return;
    }

    if (!this.isValid && editMode === false) {
      return;
    }

    if (editMode === false && this.shouldUpdateUserEmployment && this.isDirty) {
      this.updateUserEmployment();
    }

    if (editMode && this.companyUser && !this.shouldUpdateUserEmployment) {
      // Save original by creating a deep copy of the object
      this.originalCompanyUserData = JSON.parse(JSON.stringify(this.companyUser));
    }

    this.editMode = editMode;
  }

  public discardDialogAction(action: string): void {
    if (action === 'ConfirmDiscard') {
      this.discardChanges();
    }
  }

  public confirmAndDiscardChanges(): void {
    if (!this.shouldUpdateUserEmployment) {
      this.editMode = false;
      return;
    }

    this.discardDialogVisible = true;
  }

  public onDoubleClick(): void {
    if (!this.allowUsersToModifyOwnData) {
      this.isDisableChangeEditMode = true;
      return;
    }

    if (!this.editMode && this.sessionService.currentState === 'tabs.selfservice.general') {
      this.onEditModeChange(true);
    }
  }

  private loadCompanyUser(): void {
    this.dataService
      .CompanyUsers_GetCompanyUserById(Global.SESSION.CurrentCompanyUser.Id)
      .subscribe((companyUser: ICompanyUser) => {
        this.companyUser = companyUser;
        this.userEmployment =
          companyUser.UserEmployments && companyUser.UserEmployments.length > 0
            ? companyUser.UserEmployments[0]
            : undefined;
        if (!this.hasEmployments && !this.state.currentStateIs('tabs.selfservice.general')) {
          this.sessionService.NavigateTo('tabs.selfservice.general');
        }
      });
  }

  private updateUserEmployment(): void {
    this.dataService.CompanyUsers_UpdateCompanyUser(this.companyUser).subscribe(
      (updatedCompanyUser: ICompanyUser): void => {
        this.companyUser = updatedCompanyUser;
        // Reset
        this.shouldUpdateUserEmployment = false;
      },
      (err: any): void => {
        if (this.state.parentStateIs('tabs.selfservice')) {
          this.editMode = true;
          this.shouldUpdateUserEmployment = true;
        }
      }
    );
  }

  private discardChanges(): void {
    this.shouldUpdateUserEmployment = false;
    if (this.companyUser && this.originalCompanyUserData && this.companyUser.Id === this.originalCompanyUserData.Id) {
      this.companyUser = JSON.parse(JSON.stringify(this.originalCompanyUserData));
    }

    this.editMode = false;
  }

  public onReloadUserEmploymentEvent(): void {
    this.dataService.Employments_GetEmployment(this.userEmployment.Id).subscribe((data: IUserEmployment) => {
      this.userEmployment = data;
    });
  }

  public checkSwitchingSelfServiceTab(): void {
    if (this.sessionService.currentState) {
      switch (this.sessionService.currentState) {
        case 'tabs.selfservice.time':
          if (!this.selfServiceTimeTabEnabled) {
            this.sessionService.NavigateTo('tab.selfservice.general');
          } else {
            this.sessionService.NavigateTo(this.sessionService.selfServiceTabState);
          }
          break;
        case 'tab.selfservice.payslip':
          if (!this.selfServicePlayslipTabEnabled) {
            this.sessionService.NavigateTo('tab.selfservice.general');
          } else {
            this.sessionService.NavigateTo(this.sessionService.selfServiceTabState);
          }
          break;
        default:
          this.sessionService.NavigateTo('tab.selfservice.general');
          break;
      }
    }
  }
}
