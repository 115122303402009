import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { RxDataService } from '../../Services/RxDataService';
import { ModalService } from '../../Services/SharedServices/ModalService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(modalService, dataService, translateService, sessionService) {
        this.modalService = modalService;
        this.dataService = dataService;
        this.translateService = translateService;
        this.sessionService = sessionService;
        this.isChangePasswordSuccessDialog = false;
        this.isVisibleValue = false;
        this.boolRequestingService = false;
        this.isVisibleChange = new EventEmitter();
        this.isReLoginChange = new EventEmitter();
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(ResetPasswordComponent.prototype, "isVisible", {
        get: function () {
            return this.isVisibleValue;
        },
        set: function (value) {
            if (value) {
            }
            this.isVisibleValue = value;
            this.isVisibleChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResetPasswordComponent.prototype, "isReLogin", {
        get: function () {
            return this.isReLoginValue;
        },
        set: function (value) {
            if (value) {
            }
            this.isReLoginValue = value;
            this.isReLoginChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    ResetPasswordComponent.prototype.ngOnInit = function () {
        //this.isVisible = true;
    };
    ResetPasswordComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    ResetPasswordComponent.prototype.keydown = function (e) {
        if (e.keyCode !== 13) {
            return;
        }
        this.onSendTemporaryPassword();
    };
    ResetPasswordComponent.prototype.onClose = function () {
        // Go to login page in case the current state is not login (fx. when forget password is used with session expired dialog)
        // if (!this.sessionService.IsAuthenticated && this.sessionService.currentState !== "login" && !this.isReLogin) {
        //     this.sessionService.NavigateTo("login");
        // }
    };
    ResetPasswordComponent.prototype.onSendTemporaryPassword = function () {
        var _this = this;
        if (this.boolRequestingService) {
            return;
        }
        this.boolRequestingService = true;
        var requestPasswordRequest = { IdentityNumber: this.cprNo, Email: this.email };
        var rsPasswLanguageCode = this.translateService.currentLang;
        this.dataService
            .Auth_RequestPasswordResetWithLanguage(rsPasswLanguageCode, requestPasswordRequest)
            .pipe(finalize(function () {
            _this.boolRequestingService = false;
        }))
            .subscribe(function (data) {
            _this.boolRequestingService = false;
            _this.isChangePasswordSuccessDialog = true;
            _this.email = '';
            _this.cprNo = '';
        });
    };
    ResetPasswordComponent.prototype.onResetPasswordDialogAction = function (action) {
        if (action === 'ResetPassword') {
            this.onSendTemporaryPassword();
        }
        else {
            this.isVisible = false;
        }
    };
    ResetPasswordComponent.prototype.onChangePasswordSuccessDialogAction = function (action) {
        if (!this.sessionService.IsAuthenticated && !this.sessionService.currentState.includes('login')) {
            this.sessionService.NavigateTo('login');
        }
        else {
            this.isVisible = false;
        }
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
