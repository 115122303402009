import { OnDestroy, OnInit } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { Department } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
var CompanyDepartmentComponent = /** @class */ (function () {
    function CompanyDepartmentComponent(dataService, staticDataService, sessionService) {
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.deleteWarningVisible = false;
        this.showInactiveDepartmentsValue = false;
        this.departments = [];
        this.refreshData = false;
        this.keyPress = { 17: false, 18: false, 107: false, 109: false };
        this.gridData = filterBy(this.departments, this.filter);
    }
    Object.defineProperty(CompanyDepartmentComponent.prototype, "contacts", {
        get: function () {
            return this.companyContacts;
        },
        set: function (value) {
            if (value && this.companyContacts !== value) {
                this.companyContacts = value;
                this.getDepartments();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyDepartmentComponent.prototype, "showInactiveDepartments", {
        get: function () {
            return this.showInactiveDepartmentsValue;
        },
        set: function (value) {
            this.showInactiveDepartmentsValue = value;
            this.getDepartments();
            ////if (this.allDepartments) {
            ////    this.departments = this.showInactiveDepartmentsValue ? this.allDepartments : this.allDepartments.filter((d: IDepartment) => d.IsActive);
            ////}
        },
        enumerable: true,
        configurable: true
    });
    CompanyDepartmentComponent.prototype.ngOnInit = function () { };
    CompanyDepartmentComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(CompanyDepartmentComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly || this.sessionService.role.IsSalaryAdmin;
        },
        enumerable: true,
        configurable: true
    });
    CompanyDepartmentComponent.prototype.onAddNew = function (newDepartment) {
        newDepartment.GridId = this.departments.length + 2;
        newDepartment.CompanyId = this.company.Id;
        newDepartment.IsActive = true;
    };
    CompanyDepartmentComponent.prototype.onRemoveDepartment = function (e) {
        // if (e && e.Id) {
        this.gridRemoveRowArgs = e;
        this.deleteWarningVisible = true;
        // } else {
        //     // let dataIndex: any = this.departments.indexOf(e);
        //     // this.departments.splice(dataIndex, 1);
        //     let dataIndex: any = this.gridData.data.indexOf(e);
        //     this.gridData.data.splice(dataIndex, 1);
        // }
    };
    CompanyDepartmentComponent.prototype.onDeleteWarningAction = function (action) {
        var _this = this;
        if (action === 'Delete' && this.gridRemoveRowArgs) {
            if (this.gridRemoveRowArgs.dataItem.Id > 0) {
                this.dataService.Companies_DeleteDepartment(this.gridRemoveRowArgs.dataItem.Id).subscribe(function () {
                    _this.removeDeletedItem();
                    _this.staticDataService.loadDepartments();
                    // if (this.showInactiveDepartments) {
                    _this.getDepartments();
                    // }
                }, function () {
                    _this.staticDataService.loadDepartments();
                });
            }
            else {
                this.removeDeletedItem();
            }
        }
    };
    CompanyDepartmentComponent.prototype.removeDeletedItem = function () {
        var indexOfRemovedItem = this.departments.indexOf(this.gridRemoveRowArgs.dataItem);
        this.departments.splice(indexOfRemovedItem, 1);
        this.gridRemoveRowArgs = undefined;
        this.staticDataService.loadDepartments();
        this.getDepartments();
    };
    CompanyDepartmentComponent.prototype.onSave = function (args) {
        var department = args.dataItem ? args.dataItem : args;
        this.saveDataItem(department);
    };
    CompanyDepartmentComponent.prototype.getDepartments = function () {
        var _this = this;
        this.dataService.Companies_GetDepartments().subscribe(function (departments) {
            var gridIdCount = 1;
            _this.allDepartments = departments.map(function (dep) {
                dep.GridId = gridIdCount++;
                return dep;
            });
            _this.departments = _this.showInactiveDepartmentsValue
                ? _this.allDepartments
                : _this.allDepartments.filter(function (d) { return d.IsActive; });
            _this.gridData = filterBy(_this.departments, _this.filter);
        });
    };
    CompanyDepartmentComponent.prototype.onKeyDown = function (event) {
        if (!this.IsReadOnly) {
            if (event.keyCode in this.keyPress) {
                this.keyPress[event.keyCode] = true;
                if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
                    var defaultDataItem = { Name: '', CompanyId: this.company.Id, IsActive: true };
                    this.onAddNew(defaultDataItem);
                    this.setKeypress();
                    this.departments.push(defaultDataItem);
                    this.saveDataItem(defaultDataItem);
                    this.refreshData = true;
                    this.gridData = filterBy(this.departments, this.filter);
                }
                else if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
                    if (this.selectedDepartment) {
                        var item = {};
                        item.dataItem = this.selectedDepartment;
                        this.onRemoveDepartment(item);
                        this.setKeypress();
                        this.refreshData = true;
                        this.selectedDepartment = undefined;
                    }
                }
            }
            else {
                this.setKeypress();
            }
        }
    };
    CompanyDepartmentComponent.prototype.setKeypress = function () {
        this.keyPress[17] = false;
        this.keyPress[18] = false;
        this.keyPress[107] = false;
        this.keyPress[109] = false;
    };
    CompanyDepartmentComponent.prototype.saveDataItem = function (department) {
        var _this = this;
        var newDepartmentRecord = new Department();
        newDepartmentRecord = this.createDepartmentModel(department);
        // department = this.staticDataService.checkModelRecord(department);
        // Object.assign(newDepartmentRecord, department);
        if (newDepartmentRecord.Id) {
            this.dataService.Companies_UpdateDepartment(newDepartmentRecord).subscribe(function () {
                _this.staticDataService.loadDepartments();
            });
        }
        else {
            this.dataService.Companies_CreateDepartment(newDepartmentRecord).subscribe(function (data) {
                department.Id = data.Id;
                _this.staticDataService.loadDepartments();
            });
        }
    };
    CompanyDepartmentComponent.prototype.createDepartmentModel = function (object) {
        return {
            Id: object.Id || null,
            CompanyId: object.CompanyId || null,
            Name: object.Name || null,
            Description: object.Description || null,
            IsActive: object.IsActive || false,
            EIncomeReference: object.EIncomeReference || null,
            ExternalReference: object.ExternalReference || null,
            ManagerCompanyUserId: object.ManagerCompanyUserId || null
        };
    };
    CompanyDepartmentComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridData = filterBy(this.departments, filter);
    };
    return CompanyDepartmentComponent;
}());
export { CompanyDepartmentComponent };
