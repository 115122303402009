import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';
var OvertimeSupplementComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OvertimeSupplementComponent, _super);
    function OvertimeSupplementComponent(staticDataService, sessionService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.sessionService = sessionService;
        _this.overtimeSupplementChange = new EventEmitter();
        _this.visibleValue = false;
        _this.visibleChange = new EventEmitter();
        _this.numericOptions = {
            format: 'n2',
            decimals: 2,
            step: 1,
            min: 0,
            spinners: false
        };
        _this.dirty = false;
        _this.refreshData = false;
        _this.keyPress = { 17: false, 18: false, 107: false, 109: false };
        return _this;
    }
    Object.defineProperty(OvertimeSupplementComponent.prototype, "overtimeSupplement", {
        get: function () {
            return this.overtimeSupplementValue;
        },
        set: function (value) {
            this.overtimeSupplementValue = value;
            this.overtimeSupplementChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OvertimeSupplementComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    OvertimeSupplementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.OvertimeSupplementPrinciple.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.intervalTypes = data); });
    };
    OvertimeSupplementComponent.prototype.hasChanges = function () {
        return this.dirty;
    };
    OvertimeSupplementComponent.prototype.addOvertimeHandler = function (dataItem) {
        var defaultDataItem = this.createNewDataItem();
        Object.assign(dataItem, defaultDataItem);
        this.dirty = true;
        this.onChange();
    };
    OvertimeSupplementComponent.prototype.removeOvertimeHandler = function (dataItem) {
        // if (dataItem && dataItem.Id) {
        //     this.dirty = true;
        //     this.onChange();
        // } else {
        //    let dataIndex: any = this.overtimeSupplement.indexOf(dataItem);
        //    this.overtimeSupplement.splice(dataIndex, 1);
        // }
        this.dirty = true;
        this.onChange();
    };
    OvertimeSupplementComponent.prototype.saveChanges = function (args) {
        ////let dataItem: any = args.dataItem ? args.dataItem : args;
        this.dirty = true;
        this.onChange();
    };
    OvertimeSupplementComponent.prototype.createNewDataItem = function () {
        return {
            Id: null,
            EmploymentTemplateId: this.templateId,
            TotalHoursWorkedFrom: 0,
            TotalHoursWorkedUntil: 0,
            SupplementAmount: null,
            SupplementPercentage: 0,
            OvertimeSupplementPrinciple: this.intervalTypes ? this.intervalTypes[0].Id : null
        };
    };
    OvertimeSupplementComponent.prototype.onOverTimeKeyDown = function (event) {
        if (this.visible) {
            if (this.editMode) {
                if (event.keyCode in this.keyPress) {
                    this.keyPress[event.keyCode] = true;
                    if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
                        var defaultDataItem = {};
                        this.addOvertimeHandler(defaultDataItem);
                        this.setKeypress();
                        this.overtimeSupplement.push(defaultDataItem);
                        this.refreshData = true;
                    }
                    else if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
                        if (this.selectedItem) {
                            this.removeOvertimeHandler(this.selectedItem);
                            this.setKeypress();
                            this.refreshData = true;
                            this.selectedItem = null;
                        }
                    }
                }
                else {
                    this.setKeypress();
                }
            }
        }
    };
    OvertimeSupplementComponent.prototype.setKeypress = function () {
        this.keyPress[17] = false;
        this.keyPress[18] = false;
        this.keyPress[107] = false;
        this.keyPress[109] = false;
    };
    return OvertimeSupplementComponent;
}(FormComponentBase));
export { OvertimeSupplementComponent };
