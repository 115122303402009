import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'app-loading-tips',
  templateUrl: 'LoadingTips.component.html'
})
export class LoadingTipsComponent {
  @Input() public visible = false;

  public currentTip = '';
  @Input() public get tiptext(): string {
    return this.currentTip;
  }
  public set tiptext(value: string) {
    if (value) {
      this.currentTip = value;
    }
  }

  private tipIndex: number;
  public tips: string[] = [];

  constructor(translateService: TranslateService, public sessionService: SessionService) {
    switch (translateService.currentLang) {
      case 'en':
        if (!this.sessionService.browser.isMobile) {
          this.currentTip = 'Reloading... <br />Hint: Plug in a network cable to improve the load time significantly.';
        } else {
          this.currentTip = 'Reloading...';
        }
        break;
      default:
        if (!this.sessionService.browser.isMobile) {
          this.currentTip =
            'Indlæser data... <br />Tip: Brug et netværkskabel i stedet for wifi, så går det hurtigere!';
        } else {
          this.currentTip = 'Indlæser data...';
        }
        break;
    }

    // this.tipIndex = Math.floor(Math.random() * this.tips.length);
    // this.changeTip();
  }

  private changeTip(): void {
    this.tipIndex++;
    if (this.tipIndex > this.tips.length - 1) {
      this.tipIndex = 0;
    }

    this.currentTip = this.tips[this.tipIndex];

    setTimeout(() => this.changeTip(), 3000);
  }
}
