<div>
  <app-employee-list
    [editMode]="editMode"
    [newEmployee]="newCompanyUser"
    [deletedEmployee]="deletedEmployeeId"
    [globalKeyDown]="globalKeyDown"
    [isAvatarChanged]="isAvatarChanged"
    [(refreshEmployee)]="refreshCurrentEmployee"
    (selectedEmployeeChange)="setCurrentEmployee($event)"
    (addEmployee)="onAddEmployeeClicked()"
    (preLoadAvatar)="preLoadAvatar($event)"
  >
  </app-employee-list>
  <employee-tabs
    [(employee)]="currentCompanyUser"
    [(editMode)]="editMode"
    (newEmployeeCreated)="selectNewEmployee($event)"
    [isBlankEmail]="isBlankEmail"
    (isAvatarChanged)="onAvatarChanged()"
    (employeeUpdated)="updateEmployee($event)"
    (employeeDeleted)="reloadEmployeeList($event)"
    [globalKeyDown]="globalKeyDown"
    [(addNewEmployeeFired)]="addEmployeeFired"
    [(avatarChange)]="avatarChange"
  >
  </employee-tabs>
</div>
