<ng-template #template let-anchor>
  <span [innerHtml]="Tooltiptitle"></span>
</ng-template>

<span
  kendoTooltip
  [tooltipTemplate]="template"
  #tooltip="kendoTooltip"
  tooltipWidth="{{ tooltipPackageWidth }}"
  showOn="none"
  closable="true"
>
  <p>
    <abbr #anchor></abbr>
  </p>
  <button (click)="onClick(anchor)">
    <svg aria-hidden="true" class="Icon Icon--medium u-middle" ng-reflect-ng-class="Icon u-middle">
      <use xlink:href="#info"></use>
    </svg>
  </button>
</span>
