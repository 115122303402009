import { EventEmitter, OnInit, Renderer2 } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { environment } from '../../../../environments/environment';
import { FilterDateTimeGrid } from '../../../Common/FilterDateTimeGrid';
import { Global } from '../../../Common/Global';
import { RxDataService } from '../../../Services/RxDataService';
import { DownloadService } from '../../../Services/SharedServices/DownloadService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
var PayslipComponent = /** @class */ (function () {
    function PayslipComponent(settingService, dataService, downloadService, renderer, sessionService) {
        this.settingService = settingService;
        this.dataService = dataService;
        this.downloadService = downloadService;
        this.renderer = renderer;
        this.sessionService = sessionService;
        this.createUserEmploymentClick = new EventEmitter();
        this.showRevertFinalizedDialog = false;
        this.salaryStatementsNoneType = [];
        this.isHideWarnningColum = false;
        this.previewDialogVisible = false;
        this.newTabBlockedDialogVisible = false;
        this.groupIconAction = [];
        this.triggerUpdate = false;
        this.gridData = filterBy(this.salaryStatementsNoneType, this.filter);
    }
    Object.defineProperty(PayslipComponent.prototype, "employee", {
        get: function () {
            return this.currentEmployee;
        },
        set: function (value) {
            if (value && this.currentEmployee !== value) {
                this.currentEmployee = value;
                this.gridData = [];
                this.initializeData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "hasUserEmployment", {
        get: function () {
            return (this.currentEmployee && this.currentEmployee.UserEmployments && this.currentEmployee.UserEmployments.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "IsPaymentApprover", {
        get: function () {
            return Global.SESSION && Global.SESSION.IsPaymentApprover;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "IsWeb", {
        get: function () {
            return !this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "IsAppOrDesktop", {
        get: function () {
            return !this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "IsEmployeePayslipActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.employee.payslip';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "IsSelfServicePayslipActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.selfservice.payslip';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "isFirefox", {
        get: function () {
            return this.sessionService.browser.isFirefox;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    PayslipComponent.prototype.ngOnInit = function () {
        if (this.IsEmployeePayslipActive || this.IsSelfServicePayslipActive) {
            this.settingService.showModuleMessage(this.IsEmployeePayslipActive ? 'Employee.Payslip' : 'SelfService.Payslip');
        }
        if (this.IsAppOrDesktop) {
            var IconHtmloulineAction = {};
            IconHtmloulineAction.Icon = 'file-download';
            IconHtmloulineAction.Action = 'iconHtmlouline';
            IconHtmloulineAction.tooltip = 'EmploymentPayrollData.HTMLPopupToolTip';
            IconHtmloulineAction.Active = this.IsAppOrDesktop;
            if (!this.sessionService.browser.isHybridApp) {
                IconHtmloulineAction.isHidden = true;
                IconHtmloulineAction.IconPaysliphide = 'Icon--Payslip--hide';
            }
            else {
                IconHtmloulineAction.isHidden = false;
            }
            this.groupIconAction.push(IconHtmloulineAction);
        }
        if (this.IsWeb) {
            var IconHtmlAction = {};
            IconHtmlAction.Icon = 'ExternalLink';
            IconHtmlAction.Action = 'iconHtml';
            IconHtmlAction.tooltip = 'EmploymentPayrollData.HTMLFileToolTip';
            IconHtmlAction.Active = this.IsWeb;
            this.groupIconAction.push(IconHtmlAction);
        }
        var IconPdfAction = {};
        IconPdfAction.Icon = 'Download';
        IconPdfAction.Action = 'iconPdf';
        IconPdfAction.tooltip = 'CompanySalaryBatches.PaySlipPDFTooltip';
        IconPdfAction.Active = true;
        this.groupIconAction.push(IconPdfAction);
        //this.initializeData(true);
    };
    PayslipComponent.prototype.onRevert = function () {
        this.showRevertFinalizedDialog = true;
    };
    PayslipComponent.prototype.createUserEmployment = function () {
        this.createUserEmploymentClick.emit();
    };
    PayslipComponent.prototype.onRevertBatchCompleted = function () {
        this.gridData = [];
        this.getSalaryStatements(this.currentEmployee.Id);
    };
    PayslipComponent.prototype.clickIconAction = function (event) {
        if (event && event.dataItem && event.iconAction) {
            this.selectedSalaryStatement = this.salaryStatementsNoneType.find(function (model) { return model.Id === event.dataItem.Id; });
            switch (event.iconAction) {
                case 'iconHtml': {
                    this.downloadSalaryStatementNoneEvent(event.dataItem.Id, false);
                    break;
                }
                case 'iconHtmlouline': {
                    this.downloadSalaryStatementNoneEvent(event.dataItem.Id, true);
                    if (this.sessionService.browser.isHybridApp) {
                        window.screen.orientation.unlock();
                    }
                    break;
                }
                case 'iconPdf': {
                    this.downloadSalaryStatementPDFNoneEvent(event.dataItem.Id);
                    break;
                }
                default:
                    break;
            }
        }
    };
    PayslipComponent.prototype.onGridCellDbClick = function (event) {
        if (event && event.Id) {
            this.downloadSalaryStatementNoneEvent(event.Id, true);
        }
    };
    PayslipComponent.prototype.onSelectRow = function (event) {
        this.selectedSalaryStatement = this.salaryStatementsNoneType.find(function (model) { return model.Id === event.Id; });
        if (this.salaryStatementsNoneType.length > 0) {
            this.selectedFirstRow = this.salaryStatementsNoneType[0].Id === this.selectedSalaryStatement.Id;
        }
    };
    PayslipComponent.prototype.initializeData = function (showNavigationMessage) {
        if (showNavigationMessage === void 0) { showNavigationMessage = false; }
        if (showNavigationMessage) {
            this.settingService.showNavigationMessage();
        }
        if (this.currentEmployee) {
            this.getSalaryStatements(this.currentEmployee.Id);
        }
    };
    PayslipComponent.prototype.getSalaryStatements = function (companyUserId) {
        var _this = this;
        this.dataService
            .SalaryStatements_GetSalaryStatementsByCompanyUser(companyUserId)
            .subscribe(function (salaryStatements) {
            if (salaryStatements) {
                salaryStatements = salaryStatements.sort(function (salaryStatement1, salaryStatement2) {
                    var date1 = salaryStatement1.PeriodTo;
                    var date2 = salaryStatement2.PeriodTo;
                    return (new Date(date2).getFullYear() - new Date(date1).getFullYear() ||
                        salaryStatement2.Id - salaryStatement1.Id);
                });
                _this.salaryStatementsNoneType = salaryStatements.map(function (model) {
                    return {
                        iconHtmlouline: 'file-download',
                        iconHtml: 'ExternalLink',
                        iconPdf: 'Download',
                        PreliminaryWarning: model.IsFinalized ? '' : 'warning',
                        Id: model.Id,
                        UserEmployment: model.UserEmployment,
                        PeriodFrom: model.PeriodFrom,
                        PeriodTo: model.PeriodTo,
                        PrimaryIncomeAmount: model.PrimaryIncomeAmount,
                        HoursWorked: model.HoursWorked,
                        AppliedTaxDeduction: model.AppliedTaxDeduction,
                        TaxRate: model.TaxRate,
                        PayoutAmount: model.PayoutAmount,
                        VacationDaysSpent: model.VacationDaysSpent,
                        VacationMoneyEarnedGross: model.VacationMoneyEarnedGross,
                        VacationMoneyEarnedNet: model.VacationMoneyEarnedNet,
                        IsFinalized: model.IsFinalized,
                        PayoutDateDerived: model.PayoutDateDerived
                    };
                });
            }
            _this.selectedFirstRow = false;
            _this.gridData = filterBy(_this.salaryStatementsNoneType, _this.filter);
            _this.triggerUpdate = true;
        });
    };
    Object.defineProperty(PayslipComponent.prototype, "isAllfinalize", {
        get: function () {
            return this.salaryStatementsNoneType &&
                this.salaryStatementsNoneType.length > 0 &&
                this.salaryStatementsNoneType.filter(function (model) { return model.IsFinalized === true; }).length ===
                    this.salaryStatementsNoneType.length
                ? true
                : false;
        },
        enumerable: true,
        configurable: true
    });
    PayslipComponent.prototype.getPayslipReportByToken = function (salaryStatementId, format) {
        var _this = this;
        this.dataService.SalaryStatements_GetPayslipDownloadToken(salaryStatementId).subscribe(function (token) {
            var languageCode = _this.sessionService.LanguageCode;
            var downloadUrl = "/api/salarystatements/" + salaryStatementId + "/token/" + token + "/lang/" + languageCode + "/format/" + format + "/Payslip";
            setTimeout(function () {
                var newWindow = window.open(environment.apiUrl + downloadUrl);
                if (!newWindow) {
                    _this.newTabBlockedDialogVisible = true;
                }
            });
        });
    };
    PayslipComponent.prototype.onDownloadSalaryStatementHTMLCompleted = function (data, isPopup) {
        if (!isPopup) {
            var newWindow = window.open('about:blank');
            if (newWindow) {
                newWindow.document.write(data);
                newWindow.document.close();
            }
            else {
                this.newTabBlockedDialogVisible = true;
            }
        }
        else {
            this.previewContent = data;
            this.previewDialogVisible = true;
        }
    };
    PayslipComponent.prototype.downloadSalaryStatementNoneEvent = function (salaryStatementId, isPopup) {
        var _this = this;
        if (isPopup && this.IsAppOrDesktop) {
            var format = this.IsWeb ? 'a4p' : 'html';
            this.dataService
                .SalaryStatements_GetHtml(salaryStatementId, format)
                .subscribe(function (data) { return _this.onDownloadSalaryStatementHTMLCompleted(data, isPopup); });
        }
        else {
            this.getPayslipReportByToken(salaryStatementId, 'html');
        }
    };
    PayslipComponent.prototype.downloadSalaryStatementPDFNoneEvent = function (salaryStatementId) {
        var _this = this;
        if (this.sessionService.browser.isHybridApp ||
            this.sessionService.browser.isIE ||
            this.sessionService.browser.isEdge) {
            this.dataService.SalaryStatements_GetPdf(salaryStatementId).subscribe(function (data) {
                _this.downloadService.download('Gratisal.pdf', data);
            });
        }
        else {
            this.getPayslipReportByToken(salaryStatementId, 'pdf');
        }
    };
    PayslipComponent.prototype.onFilterChange = function (filter) {
        if (filter && filter.filters && filter.filters.length > 0) {
            var filterDateTimeGrid = new FilterDateTimeGrid();
            var filtersDate = [];
            // Using FilterDateTimeGrid to filter date before filter another field
            this.filter = filterDateTimeGrid.convertFilterOperator(filter, filtersDate, 'PeriodFrom', 'PeriodTo');
            this.gridData = filterDateTimeGrid.doFilterDate(this.salaryStatementsNoneType, filtersDate, 'PeriodFrom', 'PeriodTo');
            this.gridData = filterBy(this.gridData, this.filter);
            if (filtersDate.length > 0) {
                [].push.apply(this.filter.filters, filtersDate);
            }
        }
        else {
            this.gridData = this.salaryStatementsNoneType;
        }
    };
    return PayslipComponent;
}());
export { PayslipComponent };
