import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Global } from '../../../Common/Global';
import { NumericTextBoxOptions } from '../../../CustomControls/NumericTextBoxOptions';
import {
  ISalaryTypeView,
  ITimeEntryTypeConfiguration,
  IUnitType,
  IUserEmploymentTemplate,
  IUserEmploymentView
} from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'time-entry-type-configurations',
  templateUrl: './TimeEntryTypeConfigurations.component.html'
})
export class TimeEntryTypeConfigurationsComponent implements OnInit {
  @Input() public salaryTypes: ISalaryTypeView[];
  @Input()
  public get timeEntryTypeId() {
    return this.timeEntryTypeIdValue;
  }
  public set timeEntryTypeId(value) {
    this.timeEntryTypeIdValue = value;
    this.loadTimeEntryTypeConfigurations();
  }

  public selectedItem: ITimeEntryTypeConfiguration;
  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public employmentTemplates: IUserEmploymentTemplate[];
  public employmentViews: IUserEmploymentView[];
  public timeEntryTypeConfigurations: ITimeEntryTypeConfiguration[] = [];
  public unitTypes: IUnitType[] = Global.UNIT_TYPES;

  private timeEntryTypeIdValue: any;

  constructor(private dataService: RxDataService, public sessionService: SessionService) {}

  public ngOnInit(): void {
    this.dataService
      .EmploymentTemplates_GetEmploymentTemplates()
      .subscribe((templates: IUserEmploymentTemplate[]) => (this.employmentTemplates = templates));
    this.dataService
      .Employments_GetEmploymentViews()
      .subscribe((userEmploymentViews: IUserEmploymentView[]) => (this.employmentViews = userEmploymentViews));
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  private loadTimeEntryTypeConfigurations(): void {
    this.gridData = [];
    if (this.timeEntryTypeId) {
      this.dataService
        .TimeEntryTypes_GetTimeEntryTypeConfigurationsByTimeEntry(this.timeEntryTypeId)
        .subscribe((response: any[]) => {
          if (response && response.length > 0) {
            this.timeEntryTypeConfigurations = response;
            this.timeEntryTypeConfigurations.forEach((modal: any) => {
              modal.IsEmploymentTemplateEditable =
                modal.EmploymentTemplateId || (modal.UserEmploymentId === null && modal.EmploymentTemplateId === null);
              modal.IsUserEmploymentEditable =
                modal.UserEmploymentId || (modal.UserEmploymentId === null && modal.EmploymentTemplateId === null);
            });
            this.gridData = filterBy(this.timeEntryTypeConfigurations, this.filter);
          }
        });
    }
  }

  public NumericOptions: NumericTextBoxOptions = { format: 'n2', decimals: 4, step: 1, min: 0, spinners: false };

  public addHandler(dataItem: any): void {
    const defaultDataItem: any = this.createNewDataItem();
    Object.assign(dataItem, defaultDataItem);
  }

  public removeHandler(dataItem: any): void {
    if (dataItem && dataItem.Id) {
      this.dataService.TimeEntryTypes_DeleteTimeEntryTypeConfiguration(dataItem.Id).subscribe();
    } else {
      const dataIndex: any = this.timeEntryTypeConfigurations.indexOf(dataItem);
      this.timeEntryTypeConfigurations.splice(dataIndex, 1);
      this.gridData = filterBy(this.timeEntryTypeConfigurations, this.filter);
    }
  }

  public saveChanges(args: CellCloseEvent): void {
    const dataItem: any = args.dataItem ? args.dataItem : args;

    if (dataItem) {
      dataItem.IsUserEmploymentEditable =
        dataItem.UserEmploymentId !== null ||
        (dataItem.UserEmploymentId === null && dataItem.EmploymentTemplateId === null);
      dataItem.IsEmploymentTemplateEditable =
        dataItem.EmploymentTemplateId !== null ||
        (dataItem.UserEmploymentId === null && dataItem.EmploymentTemplateId === null);
    }

    if (dataItem.Id) {
      this.dataService.TimeEntryTypes_UpdateTimeEntryTypeConfiguration(dataItem).subscribe((): void => {
        this.loadTimeEntryTypeConfigurations();
      });
    } else {
      this.dataService
        .TimeEntryTypes_CreateTimeEntryTypeConfiguration(dataItem)
        .subscribe((response: ITimeEntryTypeConfiguration) => {
          // dataItem.Id = response.Id;
          Object.assign(dataItem, response);
        });
    }
  }

  public flagCreate = 0;
  private createNewDataItem(): any {
    this.flagCreate++;
    return {
      Id: null,
      TimeEntryTypeId: this.timeEntryTypeId,
      SalaryTypeId: this.salaryTypes && this.salaryTypes.length > 0 ? this.salaryTypes[0].SalaryTypeId : null,
      Description: null,
      Amount: null,
      AmountPerUnit: null,
      AppliesBefore: null,
      AppliesBeforeDate: null,
      AppliesAfter: null,
      AppliesAfterDate: null,
      AppliesWeekdays: true,
      AppliesWeekends: true,
      AppliesHolidays: true,
      AppliesSaturdays: true,
      AppliesSundays: true,
      AmountPerKilometer: null,
      EmploymentTemplateId: null,
      TargetUnitTypeId: null,
      UserEmploymentId: null,
      ConversionFactor: null,
      SalaryTypeName: this.salaryTypes[0].Name,
      flagCreate: this.flagCreate,
      IsEmploymentTemplateEditable: true,
      IsUserEmploymentEditable: true
    };
  }

  public isEmploymentEdit: any;
  public refreshData = false;
  private keyPress: any = { 17: false, 18: false, 107: false, 109: false };
  @ViewChild('kendoGridComponent', { static: true }) public kendoGrid: GridComponent;

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: any): void {
    if (event.code === 'Escape') {
      this.visible = false;
      this.visibleChange.emit(false);
    }

    if (!this.IsReadOnly) {
      if (event.keyCode in this.keyPress) {
        this.keyPress[event.keyCode] = true;
        if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
          const defaultDataItem: any = this.createNewDataItem();
          this.addHandler(defaultDataItem);
          this.saveChanges(defaultDataItem);
          this.setKeypress();
          this.timeEntryTypeConfigurations.push(defaultDataItem);
          this.gridData = filterBy(this.timeEntryTypeConfigurations, this.filter);
          this.refreshData = true;
          this.kendoGrid.editCell(
            this.timeEntryTypeConfigurations.length - 1,
            0,
            this.createFormGroup(defaultDataItem)
          );
        } else if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
          if (this.selectedItem) {
            this.removeHandler(this.selectedItem);
            this.setKeypress();
            this.refreshData = true;
            this.selectedItem = undefined;
          }
        }
      } else {
        this.setKeypress();
      }
    }
  }

  private setKeypress(): void {
    this.keyPress[17] = false;
    this.keyPress[18] = false;
    this.keyPress[107] = false;
    this.keyPress[109] = false;
  }

  private createFormGroup(dataItem: any): FormGroup {
    return new FormGroup({
      Id: new FormControl(dataItem ? dataItem.Id : null),
      TimeEntryTypeId: new FormControl(dataItem ? dataItem.TimeEntryTypeId : null),
      SalaryTypeId: new FormControl(dataItem ? dataItem.SalaryTypeId : null),
      Description: new FormControl(dataItem ? dataItem.Description : null),
      Amount: new FormControl(dataItem ? dataItem.Amount : null),
      AmountPerUnit: new FormControl(dataItem ? dataItem.AmountPerUnit : null),
      AppliesBefore: new FormControl(dataItem ? dataItem.AppliesBefore : null),
      AppliesBeforeDate: new FormControl(dataItem ? dataItem.AppliesBeforeDate : null),
      AppliesAfter: new FormControl(dataItem ? dataItem.AppliesAfter : null),
      AppliesAfterDate: new FormControl(dataItem ? dataItem.AppliesAfterDate : null),
      AppliesWeekdays: new FormControl(dataItem ? dataItem.AppliesWeekdays : null),
      AppliesWeekends: new FormControl(dataItem ? dataItem.AppliesWeekends : null),
      AppliesHolidays: new FormControl(dataItem ? dataItem.AppliesHolidays : null),
      AmountPerKilometer: new FormControl(dataItem ? dataItem.AmountPerKilometer : null),
      SalaryTypeName: new FormControl(dataItem ? dataItem.SalaryTypeName : null)
    });
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = filterBy(this.timeEntryTypeConfigurations, this.filter);
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.timeEntryTypeConfigurations, filter);
  }
}
