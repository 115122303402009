export class Constants {
  public static get DENMARK_COUNTRY_ID(): number {
    return 1;
  }
  public static get GREENLAND_COUNTRY_ID(): number {
    return 3;
  }
  public static get ACCOUNT_CHANGE_PASSWORD(): string {
    return 'ACCOUNT_CHANGE_PASSWORD';
  }
  public static get ACCOUNT_CHANGE_TEMPORARY_PASSWORD(): string {
    return 'ACCOUNT_CHANGE_TEMPORARY_PASSWORD';
  }
  public static get SESSION_EXPIRED(): string {
    return 'SESSION_EXPIRED';
  }
  public static get GLOBAL_KEY_DOWN(): string {
    return 'GLOBAL_KEY_DOWN';
  }
  public static get SHOW_EDIT_HINT(): string {
    return 'SHOW_EDIT_HINT';
  }
  public static get MAGIC_NUMBER(): number {
    return -2147483648;
  }
}
