<div [ngClass]="editMode ? 'is-editing' : ''">
  <div class="Island" (dblclick)="onDoubleClick()">
    <div class="Cards Cards--packed Cards--packed max2col">
      <div class="Card">
        <div class="Card-inner">
          <app-company-contact
            [editMode]="editMode"
            [contacts]="contacts"
            [company]="company"
            (valueChange)="onChange()"
            [(isValid)]="childIsValid[0]"
            [(isDirty)]="childIsDirty[0]"
            [(resetChildFrom)]="resetChildFrom"
          ></app-company-contact>
        </div>
      </div>
      <div class="Card">
        <div class="Card-inner">
          <app-company-data
            [editMode]="editMode"
            [company]="company"
            (valueChange)="onChange()"
            [(isValid)]="childIsValid[1]"
            [(isDirty)]="childIsDirty[1]"
            [(resetChildFrom)]="resetChildFrom"
          ></app-company-data>
        </div>
      </div>
      <div class="Card">
        <div class="Card-inner">
          <app-company-option
            [editMode]="editMode"
            [company]="company"
            [contacts]="contacts"
            (valueChange)="onChange()"
            [(isValid)]="childIsValid[2]"
            [(isDirty)]="childIsDirty[2]"
            [(resetChildFrom)]="resetChildFrom"
          ></app-company-option>
        </div>
      </div>
      <div class="Card">
        <div class="Card-inner">
          <app-company-department [company]="company" [contacts]="contacts"></app-company-department>
        </div>
      </div>
    </div>
  </div>

  <div class="Actions">
    <more-menu
      [module]="'company'"
      [currentTab]="'tabs.company.general'"
      (menuClick)="onMoreMenuOptionClick($event)"
    ></more-menu>
    <help-button [module]="'company'"></help-button>
    <edit-button
      [module]="'company'"
      [isVisible]="sessionService.role.IsFullAdmin"
      [editMode]="editMode"
      (change)="onEditClick()"
    ></edit-button>
  </div>
  <div class="EditingInfo" *ngIf="editMode"></div>
  <button-component
    [isUseOneClick]="true"
    [buttonClass]="'EditingInfo Discard'"
    (click)="confirmDiscardChanges()"
    [label]="'Employee.DiscardButton' | translate"
    *ngIf="editMode"
  ></button-component>
</div>

<app-dialog
  (action)="discardDialogAction($event)"
  [(visible)]="discardDialogVisible"
  [leadingText]="'Employee.DiscardMessage' | translate"
>
  <app-dialog-action [type]="'CancelDiscard'"></app-dialog-action>
  <app-dialog-action [type]="'ConfirmDiscard'"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="invalidDialogVisible"
  (action)="ChangeState()"
  [leadingText]="'Validation.InvalidForm' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="showInvalidFormWarning">
  <div [innerHTML]="'Validation.InvalidForm' | translate"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onConfirmSaveDataOnChangeState($event)" [(visible)]="showSaveDataConfirmationChangeState">
  <div [innerHTML]="'Employee.UnsavedData' | translate"></div>
  <app-dialog-action [type]="'DiscardAndLeave'"></app-dialog-action>
  <app-dialog-action [type]="'Continue'"></app-dialog-action>
  <app-dialog-action [type]="'Stay'"></app-dialog-action>
</app-dialog>

<app-test-email [(visible)]="isShowtestEmailDialog"></app-test-email>
