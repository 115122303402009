<app-dialog
  [overlayClose]="false"
  width="450"
  [(visible)]="visible"
  (action)="onDialogAction($event)"
  [title]="'CompanySalaryTypes.NewCustomSalaryTypeHeader' | translate"
>
  <app-text-edit
    [label]="'CompanySalaryTypes.SalaryTypeName' | translate"
    [editMode]="true"
    [(value)]="salaryTypeName"
  ></app-text-edit>

  <combobox-edit-control
    [label]="'CompanySalaryTypes.Basesalarytype' | translate"
    [editMode]="true"
    [textField]="'Name'"
    [idField]="'Id'"
    [comboboxDataSource]="defaultSalaryTypes"
    [placeholder]="'CompanySalaryTypes.PleaseChoose' | translate"
    [(value)]="baseSalaryTypeId"
    (valueChange)="onInheritChange($event)"
  ></combobox-edit-control>

  <combobox-edit-control
    [hidden]="hiddenBehavior"
    [tooltipField]="'Description'"
    [label]="'CompanySalaryTypes.Category' | translate"
    [editMode]="true"
    [textField]="'Name'"
    [idField]="'Id'"
    [comboboxDataSource]="salaryTypeCategories"
    [(value)]="categoryId"
    (valueChange)="onBehaviorChange($event)"
  ></combobox-edit-control>

  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action
    type="Create"
    [disabled]="
      sessionService.isSubmitting || !salaryTypeName || !baseSalaryTypeId || (baseSalaryTypeId === -1 && !SelectInherit)
    "
    [close]="false"
  ></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="validationWarningVisible"
  [leadingText]="'CompanySalaryTypes.CreateNewFieldsIncomplete' | translate"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
