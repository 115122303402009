import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyAccountView, ICompanyAccountView, IUserCompanyAccess } from 'src/app/Services/ApiModel';
import { RxDataService } from 'src/app/Services/RxDataService';
import { environment } from 'src/environments/environment';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { AccountantServiceBase } from '../AccountantServiceBase';

@Injectable({
  providedIn: 'root'
})
export class AccountantCompaniesService extends AccountantServiceBase {
  public get sourceCompanies(): Observable<ICompanyAccountView[]> {
    return this.sourceCompaniesSubject.asObservable();
  }

  public get companies(): Observable<ICompanyAccountView[]> {
    return this.companiesSubject.asObservable();
  }

  public company: ICompanyAccountView = new CompanyAccountView();

  private companiesSubject: BehaviorSubject<ICompanyAccountView[]> = new BehaviorSubject<ICompanyAccountView[]>([]);
  private allCompanies: ICompanyAccountView[] = [];
  private sourceCompaniesSubject: BehaviorSubject<ICompanyAccountView[]> = new BehaviorSubject<ICompanyAccountView[]>(
    []
  );
  private originalComment: string;
  constructor(protected api: RxDataService, private sessionService: SessionService) {
    super(api);
  }

  public loadData(): void {
    this.api
      .Account_GetAccountOverview()
      .pipe(tap((d: ICompanyAccountView[]) => d.forEach((rec: any) => (rec.GoToIcon = 'sign-in'))))
      .subscribe((data: ICompanyAccountView[]) => {
        this.allCompanies = data;
        this.sourceCompaniesSubject.next(
          data.map((c: ICompanyAccountView) => {
            return { ...c, Name: `${c.Name} [${c.Id}]` };
          })
        );

        this.applyFilter();
      });
  }

  public save(event?: any): void {
    if (this.company && this.company.AccountComment && this.originalComment !== this.company.AccountComment) {
      this.api
        .Account_SetAccountComment(this.selectedId, { Comment: this.company.AccountComment })
        .subscribe(() => (this.originalComment = this.company.AccountComment));
    }

    super.save(event);
  }

  public discard(): void {
    this.company.AccountComment = this.originalComment;
    super.discard();
  }

  protected getRoles(): void {
    if (this.selectedId) {
      this.company = this.companiesSubject.value.find((c: ICompanyAccountView) => c.Id === this.selectedId);
      this.originalComment = this.company ? this.company.AccountComment : undefined;
      this.api.Account_GetUserCompanyAccess(this.selectedId).subscribe((data: IUserCompanyAccess[]) => {
        if (data && data.length > 0) {
          if (data[0].CompanyId === this.selectedId) {
            this.rolesSubject.next(data);
          } else {
            if (!environment.production) {
              console.error(`CompanyId ${this.selectedId} doesnt match recieved data`);
            }
          }
        } else {
          if (!environment.production) {
            console.log('No roles');
          }
        }
      });
    }
  }

  protected applyFilter(): void {
    if (this.filter) {
      this.currentPage = 0;
      const searchText: string = this.filter.toLocaleLowerCase();
      const data: ICompanyAccountView[] = this.allCompanies.filter(
        (c: ICompanyAccountView) =>
          (c.VATRegistrationNumber && c.VATRegistrationNumber.toLocaleLowerCase().includes(searchText)) ||
          c.Name.toLocaleLowerCase().includes(searchText) ||
          c.Id.toString().includes(searchText) ||
          (c.MainContactFullName && c.MainContactFullName.toLocaleLowerCase().includes(searchText)) ||
          (c.InvoiceEmail && c.InvoiceEmail.toLocaleLowerCase().includes(searchText)) ||
          (c.AccountComment && c.AccountComment.toLocaleLowerCase().includes(searchText))
      );

      this.companiesSubject.next(data);
    } else {
      this.companiesSubject.next(this.allCompanies);
    }

    this.selectedId = undefined;
  }
}
