import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICompanyUser, IUserEmployment } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'delete-employee',
  templateUrl: './DeleteEmployee.component.html'
})
export class DeleteEmployeeComponent {
  private isVisibleValue = false;
  @Input()
  public get isVisible(): boolean {
    return this.isVisibleValue;
  }
  public set isVisible(value: boolean) {
    if (value) {
    }

    this.isVisibleValue = value;
    this.isVisibleChange.emit(value);
  }
  @Output() public isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private currentUserEmploymentValue: IUserEmployment;
  @Input()
  public get currentUserEmployment(): IUserEmployment {
    return this.currentUserEmploymentValue;
  }
  public set currentUserEmployment(value: IUserEmployment) {
    if (value) {
    }

    this.currentUserEmploymentValue = value;
    this.currentUserEmploymentChange.emit(value);
  }
  @Output() public currentUserEmploymentChange: EventEmitter<any> = new EventEmitter<any>();

  private currentCompanyUserValue: ICompanyUser;
  @Input()
  public get currentCompanyUser(): ICompanyUser {
    return this.currentCompanyUserValue;
  }
  public set currentCompanyUser(value: ICompanyUser) {
    if (value) {
    }

    this.currentCompanyUserValue = value;
    this.currentCompanyUserChange.emit(value);
  }
  @Output() public currentCompanyUserChange: EventEmitter<any> = new EventEmitter<any>();

  get hasEmployment(): boolean {
    const k: boolean = this.currentUserEmployment && this.currentUserEmployment.Id ? true : false;
    return k;
  }

  constructor(private dataService: RxDataService, public sessionService: SessionService) {}

  deleteEmployment(): void {
    const userEmploymentToGo: IUserEmployment = this.currentUserEmployment;

    if (!userEmploymentToGo) {
      return;
    }
    this.dataService.Employments_DeleteUserEmployment(userEmploymentToGo.Id).subscribe(() => {
      this.onUserEmploymentDeletedEvent(userEmploymentToGo);
    });
  }

  deleteCompanyUser(): void {
    const companyUserToGo: ICompanyUser = this.currentCompanyUser;

    if (!companyUserToGo) {
      return;
    }
    this.dataService.CompanyUsers_DeleteCompanyUser(companyUserToGo.Id).subscribe(() => {
      this.onEmployeeDeletedEvent(companyUserToGo);
    });
  }

  @Output() public employeeDeleted: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();
  public onEmployeeDeletedEvent(deletedEmployee: ICompanyUser): void {
    this.employeeDeleted.emit(deletedEmployee);
  }

  @Output() public userEmploymentDeleted: EventEmitter<IUserEmployment> = new EventEmitter<IUserEmployment>();
  public onUserEmploymentDeletedEvent(deletedUserEmployment: IUserEmployment): void {
    this.userEmploymentDeleted.emit(deletedUserEmployment);
  }

  //@Output() public onEmployeeDeleted: (eventArgs: { deletedEmployee: ICompanyUser }) => void;
  //@Output() public onUserEmploymentDeleted: (eventArgs: { deletedUserEmployment: IUserEmployment }) => void;

  public onDeleteEmployeeAction(action: string): void {
    if (action === 'DeleteEmployment') {
      this.deleteEmployment();
    } else if (action === 'DeleteCompanyUser') {
      this.deleteCompanyUser();
    }
  }
}
