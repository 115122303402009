import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import { DxDateBoxComponent } from 'devextreme-angular';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { ReflectionHelper } from '../Common/ReflectionHelper';
import { SessionService } from '../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'date-picker-control',
  templateUrl: './DatePicker.component.html'
})
export class DatePickerControlComponent extends ControlComponentBase<Date> implements OnInit, AfterViewInit {
  @Input() public label: string;
  @Input() public requiredMessage: string;
  @Input() public validationMessage: string;
  @Input() public isLabelHtml: boolean;
  @Input() public disable: boolean;
  @Input() public required: boolean;
  @Input() public e2e: string;
  @Input() public minDate: Date;

  @ViewChild('kendoDatePickerControl', { static: false }) public kendoDatePickerControl: DatePickerComponent;
  @ViewChild('dxDatePickerControl', { static: false }) public dxDatePickerControl: DxDateBoxComponent;

  public validationText: string;
  public format = 'dd/MM/yyyy';

  constructor(elementRef: ElementRef, private sessionService: SessionService, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
    this.setTimeout = false;
  }

  public ngOnInit(): void {
    if (!this.isGrid) {
      this.setTimeout = true;
    }
  }

  public onChange(triggerChanged: boolean = true): void {
    if (this.setTimeout) {
      setTimeout(() => {
        this.isValid = this.valid();
        this.isDirty = this.hasChanges();
        if (triggerChanged) {
          this.onEmitValueChange(this.value as Date);
        }
      });
    } else {
      this.isValid = this.valid();
      this.isDirty = this.hasChanges();
      if (triggerChanged) {
        this.onEmitValueChange(this.value as Date);
      }
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.sessionService.dateFormat) {
        this.format = this.sessionService.dateFormat;
      }
    });
  }

  public onfocus(event: any): void {
    // if (this.isGrid) {
    //   if (this.kendoDatePickerControl) {
    //     this.kendoDatePickerControl.wrapper.nativeElement.getElementsByClassName('k-input')[0].select();
    //   }
    //   return;
    // }
    setTimeout(() => {
      if (this.kendoDatePickerControl) {
        this.kendoDatePickerControl.wrapper.nativeElement.getElementsByClassName('k-input')[0].select();
      } else if (this.dxDatePickerControl && !this.sessionService.browser.isMobile) {
        this.dxDatePickerControl.opened = true;
        const element: ElementRef = (this.dxDatePickerControl as any).element;
        const input: any = element.nativeElement.getElementsByClassName('dx-texteditor-input')[0];
        if (input) {
          setTimeout(() => {
            input.select();
          });
        }
      }
    });
  }

  public onBlur(event: any): void {
    ////console.log("on date picker blur!");
    if (!this.convertSpecialFormat()) {
      this.convertInvalidDxDate();
    }
  }

  @Output() public customData: EventEmitter<any> = new EventEmitter<any>();
  @Output() public directional: EventEmitter<any> = new EventEmitter<any>();

  public onKeyDown(event: any): void {
    if (this.isGrid) {
      if (
        event.event.key === 'ArrowDown' ||
        event.event.key === 'ArrowUp' ||
        event.event.key === 'ArrowLeft' ||
        event.event.key === 'ArrowRight'
      ) {
        setTimeout(() => {
          event.event.preventDefault();
          event.event.stopPropagation();
          this.directional.emit(event.event);
        });
        return;
      }
    }
    if (this.dxDatePickerControl.opened) {
      this.dxDatePickerControl.opened = false;
    }
    if (event.event.key === 'Enter' || event.event.key === 'Tab') {
      if (!this.convertSpecialFormat()) {
        const date: Date = this.checkStringToDateCompatible(this.dxDatePickerControl.text)
          ? this.convertCustomStringToDate(this.dxDatePickerControl.text)
          : undefined;
        const controlValue: any = this.dxDatePickerControl.value
          ? this.dxDatePickerControl.value.toString()
          : undefined;
        if (date && ((controlValue && controlValue !== date.toString()) || !controlValue)) {
          if (this.isGrid) {
            this.value = date;
          } else {
            setTimeout(() => {
              this.value = date;
            });
          }
        }
      }
    }
  }

  public onValueChanged(event: any): void {
    ////console.log("valueChanged event raised!");
  }

  public onDxDatePickerClosed(event: any): void {
    ////console.log("popup closed event raised!");
  }

  private convertSpecialFormat(): boolean {
    let result = false;
    if (this.dxDatePickerControl && this.dxDatePickerControl.text) {
      const today: Date = new Date();
      if (/^[Uu][0-9]$/g.test(this.dxDatePickerControl.text) || /^[Uu][0-9]{2}$/g.test(this.dxDatePickerControl.text)) {
        const monthStr: string = this.dxDatePickerControl.text.substring(1, 3);
        const month: any = monthStr ? this.sessionService.parseInt(monthStr) : undefined;
        if (month) {
          result = true;
          const lastDateOfMonth: number = new Date(today.getFullYear(), month, 0).getDate();
          this.value = this.createDate(today.getFullYear(), month - 1, lastDateOfMonth);
        }
      } else {
        switch (this.dxDatePickerControl.text) {
          case 'd':
            result = true;
            this.value = this.createDate(today.getFullYear(), today.getMonth(), today.getDate());
            break;
          case 'u':
            result = true;
            const lastDateOfMonth: number = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
            this.value = this.createDate(today.getFullYear(), today.getMonth(), lastDateOfMonth);
            break;
          default:
            break;
        }
      }
    }

    return result;
  }

  private convertInvalidDxDate(): void {
    // Dx control has invalid value.
    if (this.dxDatePickerControl && this.dxDatePickerControl.validationError) {
      this.value = this.checkStringToDateCompatible(this.dxDatePickerControl.text)
        ? this.convertCustomStringToDate(this.dxDatePickerControl.text)
        : undefined;
    }
  }

  protected convertValue(value: any): Date {
    return this.convertDateValue(value);
  }

  protected valid(): boolean {
    return this.validate(this.value as any);
  }

  public get showValidationMessage(): boolean {
    return !this.valid() && !this.disable && this.validationText && this.validationText.length > 0;
  }

  private convertDateValue(value: any): Date {
    const isValidationPassed: boolean = this.validate(
      ReflectionHelper.isDate(value) ? value.toISOString() : (value as any)
    );
    if (isValidationPassed && value) {
      // This should only run when the user manually changes the date string
      let date: Date = ReflectionHelper.isDate(value) ? value : new Date(value as any);
      date = !date ? undefined : this.createDate(date.getFullYear(), date.getMonth(), date.getDate());
      return date;
    } else {
      return value;
    }
  }

  private validate(value: string): boolean {
    if (this.required && !value) {
      this.validationText = this.requiredMessage;
      return false;
    }

    return true;
  }

  private checkStringToDateCompatible(value?: string): boolean {
    let isCompaitble = false;
    if (value && value.length > 0) {
      if (!isCompaitble) {
        const dateNumberString: string = value.replace(/[^0-9\.\-\/ ,]/g, '');
        let date: number = dateNumberString.substring(0, 2)
          ? this.sessionService.parseInt(dateNumberString.substring(0, 2))
          : undefined;
        if (
          !date &&
          (/[.]/g.test(dateNumberString) || /[-]/g.test(dateNumberString) || /[\/]/g.test(dateNumberString))
        ) {
          const seperator: string = /[.]/g.test(dateNumberString)
            ? '.'
            : /[-]/g.test(dateNumberString)
            ? '-'
            : /[\/]/g.test(dateNumberString)
            ? '/'
            : '';
          date = dateNumberString.split(seperator)[0]
            ? this.sessionService.parseInt(dateNumberString.split(seperator)[0])
            : undefined;
        }

        if (date) {
          isCompaitble = true;
        }
      }
    }

    return isCompaitble;
  }

  private convertCustomStringToDate(value: string): Date {
    if (value && value.length > 0) {
      let dateNumberString: string = value.replace(/[^0-9\.\-\/ ,]/g, '');
      let today: Date = new Date();
      today = this.createDate(today.getFullYear(), today.getMonth(), today.getDate());
      let date: number;
      let month: number;
      let year: number;
      if (/[.]/g.test(dateNumberString) || /[-]/g.test(dateNumberString) || /[\/]/g.test(dateNumberString)) {
        const seperator: string = /[.]/g.test(dateNumberString)
          ? '.'
          : /[-]/g.test(dateNumberString)
          ? '-'
          : /[\/]/g.test(dateNumberString)
          ? '/'
          : '';
        date = dateNumberString.split(seperator)[0]
          ? this.sessionService.parseInt(dateNumberString.split(seperator)[0])
          : undefined;
        month = dateNumberString.split(seperator)[1]
          ? this.sessionService.parseInt(dateNumberString.split(seperator)[1]) - 1
          : today.getMonth();
        year = dateNumberString.split(seperator)[2]
          ? this.sessionService.parseInt(dateNumberString.split(seperator)[2])
          : this.isFeauture(month, today.getMonth())
          ? today.getFullYear() - 1
          : today.getFullYear();
      } else {
        dateNumberString = value.replace(/[^0-9,]/g, '');
        date = dateNumberString.substring(0, 2)
          ? this.sessionService.parseInt(dateNumberString.substring(0, 2))
          : undefined;
        month = dateNumberString.substring(2, 4)
          ? this.sessionService.parseInt(dateNumberString.substring(2, 4)) - 1
          : today.getMonth();
        year = dateNumberString.substring(4, 8)
          ? this.sessionService.parseInt(dateNumberString.substring(4, 8))
          : this.isFeauture(month, today.getMonth())
          ? today.getFullYear() - 1
          : today.getFullYear();
      }
      if (!this.isValidDataAfterConvert(month, date, year)) {
        return undefined;
      }
      return this.createDate(year, month, date);
    }

    return undefined;
  }

  public isValidDataAfterConvert(month: number, date: number, year: number): boolean {
    if ((month && (month > 11 || month < 0)) || month === undefined) {
      return false;
    }
    const lastDateOfMonth: number = new Date(year, month + 1, 0).getDate();
    if (!lastDateOfMonth || (date && (date > lastDateOfMonth || date < 1)) || !date) {
      return false;
    }
    return true;
  }

  private AvailableMonth(month: number): number {
    if (month) {
      if (month + 3 > 12) {
        return month + 3 - 12;
      }

      return month + 3;
    }

    return 0;
  }

  private isFeauture(currentMonth: number, month: number): boolean {
    if (currentMonth > this.AvailableMonth(month)) {
      return true;
    }

    return false;
  }

  private createDate(year: number, month: number, date: number): Date {
    const today: Date = new Date();
    if (date) {
      year =
        year < 100
          ? this.sessionService.parseInt(
              today
                .getFullYear()
                .toString()
                .substr(0, 2) + this.sessionService.toString(year, '00')
            )
          : year;
      month = month > 11 ? 11 : month < 0 ? 0 : month;
      const lastDateOfMonth: number = new Date(year, month + 1, 0).getDate();
      date = date > lastDateOfMonth ? lastDateOfMonth : date < 0 ? 1 : date;

      let hour = today.getTimezoneOffset() / 60;
      hour = hour === 12 || hour === -12 ? 0 : hour;
      if (hour > 0) {
        return new Date(year, month, date, hour, 0, 0);
      }
      return new Date(year, month, date, -hour, 0, 0);
    }

    return undefined;
  }
}
