import * as tslib_1 from "tslib";
import { SalaryBatch } from '../../Services/ApiModel';
var SalaryBatchViewModel = /** @class */ (function (_super) {
    tslib_1.__extends(SalaryBatchViewModel, _super);
    function SalaryBatchViewModel(data, sessionService) {
        var _this = _super.call(this) || this;
        _this.EditIcon = 'Edit';
        Object.assign(_this, data);
        _this.TitleDescription =
            'No. ' +
                _this.SalaryBatchNumber +
                ' - ' +
                sessionService.toString(sessionService.parseDate(_this.PeriodStartDate)) +
                ' - ' +
                sessionService.toString(sessionService.parseDate(_this.PeriodEndDate)) +
                ' - ' +
                _this.StatusName;
        switch (_this.ValidationStatusCode) {
            case 'Error':
                _this.ValidationIcon = 'ErrorExclamation';
                break;
            case 'Warning':
                _this.ValidationIcon = 'Warning';
                break;
            default:
                _this.ValidationIcon = '';
        }
        return _this;
    }
    return SalaryBatchViewModel;
}(SalaryBatch));
export { SalaryBatchViewModel };
