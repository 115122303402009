import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Constants } from '../Common/Constants';
import { Global } from '../Common/Global';
import { NumericTextBoxOptions } from '../CustomControls/NumericTextBoxOptions';
import { DefaultAvatar } from '../Model/DefaultAvatar';
import {
  BillingUsage,
  IAccount,
  IAccountType,
  IBillingPrinciple,
  IBillingUsage,
  ICompany,
  ICompanyUser,
  IModuleCompanyView,
  IRole,
  ISimpleCompany
} from '../Services/ApiModel';
import { AuthenticationService } from '../Services/Authentication/AuthenticationService';
import { Broadcaster } from '../Services/Broadcaster';
import { RxDataService } from '../Services/RxDataService';
import { BusyIndicatorService } from '../Services/SharedServices/BusyIndicatorService';
import { ModalService } from '../Services/SharedServices/ModalService';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
import { SettingService } from '../Services/SharedServices/SettingService';
import { StaticDataService } from '../Services/SharedServices/StaticData.service';
import { ModuleCheckComponent } from '../SharedComponents/ModuleCheck/ModuleCheck.component';

@Component({
  selector: 'tabs',
  templateUrl: './Tabs.component.html'
})
export class TabsComponent implements OnInit, OnDestroy, AfterViewInit {
  public selectedCompanyId: number;
  public currentCompanyName = 'Unknow Company';
  public currentCompanyAbbreviation = 'UC';
  public showCompanyAbbreviation: boolean;
  public logo: string;
  public fullName: string;
  public role: string;
  public languageId: number;
  public changeAvatarDialog: boolean;
  public editMode: boolean;
  public firstname: string;
  public lastname: string;
  public isTesting = true;
  public showcontentdialog = false;
  public showLanguageChooserDialog: boolean;
  public showAcceptDPADialog: boolean;
  public avatar: string;
  public navigationExpanded: boolean;
  public showCompanySelector: boolean;
  public showUserMenu: boolean;
  public singleCompany = true;
  public companies: ISimpleCompany[];
  public changeAvatarDialogVisible: boolean;
  public isDetecGoogleTranslate = false;
  public isLongList = false;

  public get isDemo(): boolean {
    return this.sessionService.feature.IsDemoVersion;
  }
  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }
  public get environment(): string {
    return environment.environment;
  }
  public get isIOSMobileApp(): boolean {
    return this.sessionService.browser.iOSMobileDevice;
  }
  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }
  public get isEmployeeTimeTab(): boolean {
    return this.sessionService.currentState === 'tabs.employee.time';
  }
  public get isSelfServiceTimeTab(): boolean {
    return this.sessionService.currentState === 'tabs.selfservice.time';
  }
  public get isDetectedCookieDisable(): boolean {
    return this.sessionService.isDetectedCookieDisable;
  }
  public set isDetectedCookieDisable(value) {
    if (value) {
      this.sessionService.isDetectedCookieDisable = value;
    }
  }
  public get isSingleListcompanis(): boolean {
    if (this.companies && this.companies.length === 1) {
      return true;
    }

    return false;
  }
  public get isEmployeeAndHasmanager(): boolean {
    if (this.sessionService.role.EmployeeWithRoleAsManager) {
      return true;
    }
    return false;
  }

  public get Companytooltip(): string {
    if (Global.SESSION && Global.SESSION.IsAccountant) {
      if (this.logo) {
        return this.currentCompanyName;
      }
    }
    return '';
  }

  private srcImageFemale: string = DefaultAvatar.imgFemaleAvatar;
  private srcImageMale: string = DefaultAvatar.imgMaleAvatar;
  private activeCompany: ICompany;
  private isFemale = false;
  private clientWidth: number;
  private updatedGratisalMessage: string;
  private updatedGratisalMessageUrl: string;
  private isNavigateBack = false;
  private loadingTipsTextChangeCompany: string;
  private limitTimeLoading = 10;

  public isShowAdHocSupport = false;
  public hasBackendAccess = false;
  public moduleAdHocSupport: IModuleCompanyView[] = [];
  public userModule: IModuleCompanyView[] = [];
  public billingUsage: IBillingUsage = new BillingUsage();
  public billingPrinciple: any[] = [];
  public NumericOptions: NumericTextBoxOptions = { format: 'n2', decimals: 4, step: 1, min: 0, spinners: false };
  public hasLanguageModule = false;
  public isShowEmailSupport = false;
  public isLoading = true;
  public loadingTipText = '';
  public isDetectedAdBlock = false;
  public accountantTabName = '';

  public defaultBillingUsage(): void {
    this.billingUsage = new BillingUsage();
    this.billingUsage.Units = 1;
    this.billingUsage.BillingDate = this.staticDataService.getCurrentdate();
    if (this.moduleAdHocSupport && this.moduleAdHocSupport.length > 0) {
      const objectModule: any = this.moduleAdHocSupport.find((model: any) => model.ModuleKey === 'BillableSupport');
      if (objectModule) {
        this.billingUsage.ModuleId = objectModule.ModuleId;
        // this.billingUsage.BillingPrincipleId = objectModule.ModuleBillingPrincipleId;
        this.onBillingPrincipChange();
      }
    }
  }

  public onBillingPrincipChange(): void {
    if (this.moduleAdHocSupport && this.moduleAdHocSupport.length > 0) {
      const objectModule: any = this.moduleAdHocSupport.find(
        (model: any) => model.ModuleId === this.billingUsage.ModuleId
      );
      if (objectModule) {
        this.billingUsage.AmountPerUnit = objectModule.ModulePrice ? objectModule.ModulePrice : 0;
        if (objectModule.ModuleBillingPrincipleId) {
          this.billingUsage.BillingPrincipleId = objectModule.ModuleBillingPrincipleId;
        }
      }
    }

    this.onCaculateAmount();
  }

  public onSaveAdHocSupport(event: string): void {
    if (event && event === 'Save') {
      this.dataService.Backend_RegisterUsage(this.billingUsage).subscribe(() => this.onCaculateAmount());
    }
    this.defaultBillingUsage();
  }

  public onCaculateAmount(): void {
    if (this.billingUsage.Units !== undefined && this.billingUsage.AmountPerUnit !== undefined) {
      this.billingUsage.Amount = this.billingUsage.Units * this.billingUsage.AmountPerUnit;
    }
  }

  constructor(
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private dataService: RxDataService,
    public settingService: SettingService,
    public modalService: ModalService,
    private broadcaster: Broadcaster,
    public sessionService: SessionService,
    private staticDataService: StaticDataService,
    private renderer: Renderer2,
    private meta: Meta,
    public busyIndicatorService: BusyIndicatorService
  ) {
    const url: string = window.location.href;
    if (!this.isMobile && url.indexOf('file://') > -1) {
      window.location.href = 'https://app.gratisal.dk';
    }

    if (!this.sessionService.IsAuthenticated) {
      this.sessionService.NavigateTo('login');
      return;
    }

    if (Global.SESSION && Global.SESSION.SignOnToken.Language.CultureCode) {
      const cultureCode: string = Global.SESSION.SignOnToken.Language.CultureCode;
      const shortCultureCode: string = cultureCode.substr(0, 2);
      this.translate.use(shortCultureCode).subscribe(() => {
        this.settingService.applyTranslations();
        this.translateText();
      });
    }

    if (this.sessionService.role.IsAccountant) {
      this.staticDataService.AccountType.subscribe((accountTypes: IAccountType[]) => {
        if (accountTypes && accountTypes.length > 0 && !this.accountantTabName) {
          this.omGetAccountantTypeCompolete(accountTypes);
        }
      });
    }
  }

  public omGetAccountantTypeCompolete(accountTypes: IAccountType[]): void {
    this.staticDataService.currentAccount.subscribe((account: IAccount) => {
      if (account) {
        const accountType: IAccountType = accountTypes.find((t: IAccountType) => t.Id === account.AccountTypeId);
        if (accountType) {
          this.accountantTabName = accountType.Name;
        }
      }
    });
  }

  public ngAfterViewInit(): void {
    if (this.sessionService.browser.isChrome && !this.isMobile) {
      setTimeout(() => {
        this.renderer.listen(document, 'DOMSubtreeModified', (e: any): void => {
          if (
            e &&
            e.target &&
            e.target.tagName === 'HTML' &&
            e.target.className &&
            e.target.className.match('translated')
          ) {
            setTimeout(() => {
              this.isDetecGoogleTranslate = true;
            });
          }
        });
      });
    }
  }

  // Just using for detect google translate
  // Reload page to prevent google add more html element to web
  public onDisableDetect(event: string) {
    window.location.reload();
  }

  public onWaittingLogOut(): void {
    if (!this.sessionService.isGetting) {
      this.onLogout();
    } else {
      setTimeout(() => {
        this.onWaittingLogOut();
      }, 500);
    }
  }

  public ngOnInit(): void {
    if (this.CheckNonState()) {
      this.onWaittingLogOut();
      return;
    }
    this.renderer.removeClass(document.body, 'Login-bg');
    this.renderer.addClass(document.body, 'main-bg');
    this.setFields();
    this.getCompanies();
    this.getUserImage();
    this.getusercompany();
    this.bindResizeEvent();
    this.useVerticalMenu();
    this.renderer.listen(document, 'keydown', (event: any) =>
      this.broadcaster.broadcast(Constants.GLOBAL_KEY_DOWN, event)
    );
    this.renderer.listen(window, 'click', (event: any) => {
      if (!this.isCollapseButtonClick) {
        this.navigationExpanded = false;
      } else {
        this.isCollapseButtonClick = false;
      }
    });
    this.setupEventHandlers();
    this.editMode = false;
    this.staticDataService.BillingPrinciple.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IBillingPrinciple[]) => (this.billingPrinciple = data)
    );
    this.checkLoading();
  }

  private checkLoading(): void {
    this.limitTimeLoading--;
    if (this.limitTimeLoading === 0) {
      this.afterLoading();
      return;
    }
    if (!this.sessionService.isGetting && !this.sessionService.isSubmitting) {
      this.afterLoading();
    } else {
      setTimeout(() => this.checkLoading(), 1000);
    }
  }

  private afterLoading(): void {
    this.isLoading = false;
    this.settingService.isReload = false;
    setTimeout(() => {
      if (!document.getElementById('jZwhEMqUkoKu')) {
        this.isDetectedAdBlock = false;
      } else {
        if (!Global.isFirstCheckMessageAdBlock) {
          Global.isFirstCheckMessageAdBlock = true;
          this.isDetectedAdBlock = true;
        }
      }
      if (!this.isMobile) {
        try {
          if (document.cookie.indexOf('languageId') !== -1) {
            this.isDetectedCookieDisable = false;
          } else {
            this.isDetectedCookieDisable = true;
          }
        } catch (e) {
          this.isDetectedCookieDisable = true;
        }
      }
      window.scrollTo(0, 1);
    });
  }

  public hasModuleKey(key: string): boolean {
    if (this.moduleAdHocSupport && this.moduleAdHocSupport.length > 0) {
      const objectModule: any = this.moduleAdHocSupport.find((model: any) => model.ModuleKey === key);
      if (objectModule && objectModule.IsEnabled) {
        return true;
      }
    }

    return false;
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @ViewChild('userLanguageModuleCheck', { static: true }) private userLanguageModuleCheck: ModuleCheckComponent;

  public showModuleDialog(): void {
    this.showUserMenu = false;
    this.sessionService.showUserMenuToggled = false;
    this.sessionService.showMoreMenuToggled = false;
    this.sessionService.showHelpMenuToggled = false;
    this.userLanguageModuleCheck.onClick();
  }

  public navigateToState(name: string): void {
    this.navigationExpanded = false;
    this.sessionService.menuToggled = true;
    if (!this.sessionService.currentState.includes(name)) {
      this.sessionService.NavigateTo(name);
    }
    if (this.sessionService.currentState === name) {
      const currentRoleKey: string = Global.SESSION.CurrentRole.Key;
      switch (name) {
        case 'tabs.employee':
          if (currentRoleKey) {
            switch (currentRoleKey) {
              case 'FullAdmin':
                if (Global.IsEmployeeTaskCompleted) {
                  this.sessionService.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
                  this.sessionService.NavigateTo('tabs.employee.payrolldata');
                } else {
                  this.sessionService.currentStyleMenuTabpanelReponsive = '';
                  this.sessionService.NavigateTo('tabs.employee.general');
                }

                break;
              case 'ReadOnly':
                this.sessionService.NavigateTo('tabs.employee.payrolldata');
                break;
              case 'Employee':
                const hasManager: boolean = this.sessionService.role.EmployeeWithRoleAsManager;
                if (hasManager) {
                  this.sessionService.NavigateTo('tabs.employee.time');
                }

                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                break;
              case 'SalaryAdmin':
                this.sessionService.NavigateTo('tabs.employee.payrolldata');
                break;
              default:
                break;
            }
          }
          break;
        case 'tabs.company':
          if (currentRoleKey) {
            switch (currentRoleKey) {
              case 'FullAdmin':
                this.sessionService.NavigateTo('tabs.company.salarybatches');
                break;
              case 'ReadOnly':
                this.sessionService.NavigateTo('tabs.company.salarybatches');
                break;
              case 'Employee':
                if (Global.SESSION.ManagerForUserEmploymentIds.length > 0) {
                  this.sessionService.NavigateTo('tabs.company.approval.timeentry');
                }

                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                break;
              case 'SalaryAdmin':
                this.sessionService.NavigateTo('tabs.company.salarybatches');
                break;
              default:
                break;
            }
          }
          break;
        case 'tabs.selfservice':
          if (currentRoleKey) {
            switch (currentRoleKey) {
              case 'FullAdmin':
                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                break;
              case 'ReadOnly':
                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                break;
              case 'Employee':
                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                break;
              case 'SalaryAdmin':
                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                break;
              default:
                break;
            }
          }
          break;
        default:
          break;
      }
    }
  }

  public get doubleClickToEditHintVisible(): boolean {
    return (
      this.sessionService.currentState === 'tabs.employee.general' ||
      this.sessionService.currentState === 'tabs.employee.hiringstatus' ||
      this.sessionService.currentState === 'tabs.company.general' ||
      this.sessionService.currentState === 'tabs.company.configuration.hiringstatus' ||
      this.sessionService.currentState === 'tabs.selfservice.general'
    );
  }

  public raiseError(): void {
    throw new Error('Test AngularJS error');
  }

  public onTestStatus400(): void {
    this.dataService.Miscellaneous_FailSoft().subscribe();
  }

  public onTestStatus404(): void {
    this.dataService.Miscellaneous_FailNotFound().subscribe();
  }

  public onTestStatus500(): void {
    this.dataService.Miscellaneous_FailHard().subscribe();
  }

  public onTestStatus401(): void {
    this.dataService.Miscellaneous_FailUnauhorized().subscribe();
  }

  public onTestRandomStatus(): void {
    this.dataService.Miscellaneous_Random().subscribe();
  }

  public onTestModalDialog(): void {
    this.showcontentdialog = true;
  }

  public changeLanguage(args: any): void {
    this.setLanguageContext(args.languageId);
  }

  public onLogout(): void {
    this.authenticationService
      .logout()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          this.navigateToLogin();
        },
        () => {
          this.navigateToLogin();
        }
      );
  }

  public onShowAvatarDialog(): void {
    this.showUserMenu = false;
    this.sessionService.showMoreMenuToggled = false;
    this.sessionService.showUserMenuToggled = false;
    this.sessionService.showHelpMenuToggled = false;
    this.changeAvatarDialog = true;
  }

  public onChangePasswordClick(): boolean {
    this.broadcaster.broadcast(Constants.ACCOUNT_CHANGE_PASSWORD);
    return true;
  }

  public onChangeLanguageDialogClick(): void {
    this.showLanguageChooserDialog = true;
  }

  public onCompanyChange(companyId: number): void {
    if (!this.selectedCompanyId) {
      this.selectedCompanyId = -9999;
    }
    if (
      companyId &&
      this.selectedCompanyId &&
      this.selectedCompanyId !== companyId &&
      companyId !== Global.COMPANY_ID
    ) {
      this.isLoading = true;
      this.loadingTipText = this.loadingTipsTextChangeCompany;
      this.setCompanyContext(companyId);
    }
    setTimeout(() => {
      this.settingService.showCompanySelector = false;
    });
  }

  public onDPATermAccepted(isValid: boolean): void {
    if (!isValid) {
      this.authenticationService
        .logout()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          () => {
            this.navigateToLogin();
          },
          () => {
            this.navigateToLogin();
          }
        );
    }
  }

  private isCollapseButtonClick: boolean;
  public onToggleNavigation(): void {
    this.isCollapseButtonClick = true;
    this.navigationExpanded = !this.navigationExpanded;
  }

  public onToggleCompanyDropdown(): void {
    this.sessionService.showHelpMenuToggled = false;
    this.sessionService.showMoreMenuToggled = false;

    if (this.isSingleListcompanis === false) {
      // this.settingService.showCompanySelector = !this.settingService.showCompanySelector;

      if (this.settingService.showCompanySelector === true) {
        this.settingService.showCompanySelector = false;
        return;
      }
      setTimeout(() => {
        this.settingService.showCompanySelector = !this.settingService.showCompanySelector;
      });
    }
  }

  public onToggleUserDropdown(): void {
    this.sessionService.showHelpMenuToggled = false;
    this.sessionService.showMoreMenuToggled = false;

    this.settingService.showCompanySelector = false;
    if (this.sessionService.showUserMenuToggled === true) {
      this.sessionService.showUserMenuToggled = false;
      return;
    }
    setTimeout(() => {
      this.sessionService.showUserMenuToggled = !this.sessionService.showUserMenuToggled;
    });
  }

  public closeallmeudown(): void {
    if (this.sessionService.showUserMenuToggled === true) {
      this.sessionService.showUserMenuToggled = false;
    }
  }

  private reloadStaticData(): void {
    this.staticDataService.loadStaticData().then(() => {
      const currentRoleKey: string = Global.SESSION.CurrentRole.Key;
      if (currentRoleKey && currentRoleKey === 'Employee') {
        const hasManager: boolean = this.sessionService.role.EmployeeWithRoleAsManager;
        if (hasManager && this.sessionService.employeeTabState) {
          this.sessionService.NavigateTo('tabs.employee.time');
        }

        this.sessionService.NavigateTo('tabs.selfservice.payslip');
      }

      this.dataService.Companies_GetCurrent().subscribe((activeCompany: ICompany): void => {
        Global.COMPANY_ID = this.activeCompany && this.activeCompany.Id ? this.activeCompany.Id : null;
        Global.COMPANY = activeCompany;
        window.location.reload();
      });
    });
  }

  private resetViewPort(): void {
    const original: HTMLMetaElement = this.meta.getTag('name=viewport');
    const forceScale: string = original.content + ', user-scalable=no, minimum-scale=1.0, maximum-scale=1.0';
    this.meta.updateTag({ name: 'viewport', content: forceScale }, 'name=viewport');
    setTimeout(() => this.meta.updateTag({ name: 'viewport', content: original.content }, 'name=viewport'));
  }

  private bindResizeEvent(): void {
    this.clientWidth = document.body.clientWidth;
    this.renderer.listen(window, 'resize', () => {
      this.useVerticalMenu();
      const diff: number = document.body.clientWidth - this.clientWidth;
      if (this.isIOSMobileApp && Math.abs(diff) > 10) {
        this.renderer.removeClass(document.body, 'mobileIOSApp');
        this.clientWidth = document.body.clientWidth;
      }
    });
  }

  private useVerticalMenu(): void {
    if (window.matchMedia('(max-height: 500px)').matches || window.matchMedia('(max-width: 1000px)').matches) {
      if (!this.settingService.showCompanySelector) {
        this.sessionService.menuToggled = true;
      }
    } else {
      this.sessionService.menuToggled = false;
    }
  }

  private translateText(): void {
    this.translate
      .get([
        'Login.UpdatedGratisalMessage',
        'UnsuccessfulAPI.404',
        'Tips.ChangeCompany',
        'Tips.MobileChangeCompany',
        'Login.UpdatedGratisalMessageUrl'
      ])
      .subscribe((translations: { [key: string]: string }) => {
        this.updatedGratisalMessage = translations['Login.UpdatedGratisalMessage'];
        this.updatedGratisalMessageUrl = translations['Login.UpdatedGratisalMessageUrl'];
        this.updatedGratisalMessage = this.updatedGratisalMessage.replace(
          '{UrlFeauture}',
          this.updatedGratisalMessageUrl
        );
        this.sessionService.errorMessage404 = translations['UnsuccessfulAPI.404'];
        this.settingService.showModuleMessage('ShowUpdateNotification', this.updatedGratisalMessage);
        if (this.isMobile) {
          this.loadingTipsTextChangeCompany = translations['Tips.MobileChangeCompany'];
        } else {
          this.loadingTipsTextChangeCompany = translations['Tips.ChangeCompany'];
        }
      });

    this.translate
      .get([
        'GlobalDialog.AcceptDPAButton',
        'GlobalDialog.AcceptWarnings',
        'GlobalDialog.Apply',
        'GlobalDialog.Approve',
        'GlobalDialog.AlreadyRequestedTaxCardConfirmButton',
        'GlobalDialog.Cancel',
        'GlobalDialog.CancelDiscard',
        'GlobalDialog.ChangePassword',
        'GlobalDialog.ClearErrors',
        'GlobalDialog.ConfirmDiscard',
        'GlobalDialog.Continue',
        'GlobalDialog.CompleteImportText',
        'GlobalDialog.Close',
        'GlobalDialog.Create',
        'GlobalDialog.CreateWithoutEmails',
        'GlobalDialog.Delete',
        'GlobalDialog.DisableThisBehaviour',
        'GlobalDialog.DiscardAndLeave',
        'GlobalDialog.Download',
        'GlobalDialog.DeleteEmploymentButtonText',
        'GlobalDialog.DeleteCompanyUserButtonText',
        'GlobalDialog.Edit',
        'GlobalDialog.Enable',
        'GlobalDialog.EnableAutoCorrect',
        'GlobalDialog.Finalize',
        'GlobalDialog.Hide',
        'GlobalDialog.Import',
        'GlobalDialog.IKnowWhatIamDoing',
        'GlobalDialog.Lock',
        'GlobalDialog.Lock and notify employees',
        'GlobalDialog.Logout',
        'GlobalDialog.MoreHelp',
        'GlobalDialog.No',
        'GlobalDialog.Ok',
        'GlobalDialog.Proceed',
        'GlobalDialog.ReadMore',
        'GlobalDialog.Reject',
        'GlobalDialog.Re-send',
        'GlobalDialog.ResetPassword',
        'GlobalDialog.Revert',
        'GlobalDialog.RevertToDraft',
        'GlobalDialog.Save',
        'GlobalDialog.Submit',
        'GlobalDialog.SubmitForApproval',
        'GlobalDialog.Stay',
        'GlobalDialog.Test',
        'GlobalDialog.Understood',
        'GlobalDialog.Update',
        'GlobalDialog.UsePositiveAmountThisTime',
        'GlobalDialog.Yes',
        'GlobalDialog.ContinueAndSaveChange',
        'EmploymentPayrollData.MoreInfo',
        'EmploymentPayrollData.UsePositiveAmountThisTime',
        'Error.NetworkError',
        'GlobalDialog.YesPlease',
        'GlobalDialog.NoThankYou',
        'CompanyDataImport.Save Definition',
        'CompanyDataImport.Import Once'
      ])
      .subscribe((translations: { [key: string]: string }) => {
        this.sessionService.modalTranslations = translations;
      });
  }

  private setFields(): void {
    if (Global.SESSION) {
      this.sessionService.roleKey = Global.SESSION ? Global.SESSION.CurrentRole.Key : undefined;
      this.isFemale = Global.SESSION ? Global.SESSION.IsFemale : undefined;
      this.languageId = Global.SESSION ? Global.SESSION.SignOnToken.Language.Id : undefined;
      this.fullName = Global.SESSION ? Global.SESSION.CurrentCompanyUser.FullName : undefined;
      this.role = Global.SESSION ? Global.SESSION.CurrentRole.Name : undefined;
      this.hasBackendAccess = Global.SESSION ? Global.SESSION.HasBackendAccess : undefined;
    }
    this.staticDataService.moduleCompanyView
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: IModuleCompanyView[]) => {
        this.userModule = data;
        this.moduleAdHocSupport = JSON.parse(JSON.stringify(this.userModule));
        if (
          this.moduleAdHocSupport &&
          this.moduleAdHocSupport.length > 0 &&
          (this.hasModuleKey('PhoneSupport') || this.hasModuleKey('EmailSupport'))
        ) {
          this.moduleAdHocSupport.forEach((model: any) => {
            if (model.ModuleKey === 'BillableSupport') {
              model.ModulePrice = 0;
            }
          });
        }
        this.defaultBillingUsage();
      });
  }

  private setLanguageContext(languageId: number): void {
    this.dataService.Users_SetLanguageContext(languageId).subscribe((reponse: any): any => {
      document.cookie = 'languageId=' + languageId;
      this.reloadStaticData();
    });
  }

  private setupEventHandlers(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) =>
      this.translateText()
    );
    document.addEventListener(
      'backbutton',
      (): void => {
        this.onBackButtonClicked();
      },
      false
    );
    this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: string): void => {
      this.handleStatesForBackButton();
      this.resetViewPort();
    });

    this.broadcaster
      .on<any>(Constants.SHOW_EDIT_HINT)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): any => {
        const showToastMessage: boolean = this.doubleClickToEditHintVisible;
        this.settingService.showDoubleClickToEditHint(showToastMessage);
      });
  }

  private onBackButtonClicked(): void {
    const statesHistory: string[] = Global.statesHistory ? Global.statesHistory : [];
    statesHistory.pop();
    if (statesHistory.length > 0) {
      this.isNavigateBack = true;
      this.sessionService.NavigateTo(statesHistory[statesHistory.length - 1]);
      Global.statesHistory = statesHistory;
    }
  }

  private handleStatesForBackButton(): void {
    const statesHistory: string[] = Global.statesHistory ? Global.statesHistory : [];
    if (!this.isNavigateBack) {
      statesHistory.push(this.sessionService.currentState);
      Global.statesHistory = statesHistory;
    }

    this.isNavigateBack = false;
  }

  private navigateToLogin(): void {
    this.sessionService.NavigateTo('login');
  }

  private getUserImage(): void {
    this.staticDataService.currentUserImage.subscribe((image: string): void => {
      if (image !== this.avatar) {
        this.avatar = image || (this.isFemale ? this.srcImageFemale : this.srcImageMale);
      }
    });
  }

  private getusercompany(): void {
    this.dataService
      .CompanyUsers_GetCompanyUserById(Global.SESSION.CurrentCompanyUser.Id)
      .subscribe((data: ICompanyUser): void => {
        this.firstname = data.FirstName;
        this.lastname = data.LastName;
      });
  }

  public onAvatarChanged(formData: any): void {
    // this.getUserImage();
    this.staticDataService.loadcurrentUserImage();
  }

  private getCompanies(): void {
    if (!this.companies || (this.companies && this.companies.length === 0)) {
      this.staticDataService.companiesSimple.subscribe((data: ISimpleCompany[]) => {
        this.staticDataService.Role.subscribe((statisticOptions: IRole[]): void => {
          const roleList: any = {};
          statisticOptions.forEach((option) => {
            roleList[option.Id] = option.Name;
          });

          data.forEach((company: any) => {
            company.RoleName = roleList[company.RoleId];
          });
        });

        this.companies = data;
        this.getCurrentCompany();

        if (this.companies.length <= 1) {
          this.singleCompany = true;
        } else {
          this.singleCompany = false;
        }
      });
    }
  }

  private getCurrentCompany(): void {
    this.staticDataService.currentCompany.subscribe((activeCompany: ICompany): void => {
      if (activeCompany) {
        this.activeCompany = activeCompany;
        this.selectedCompanyId = this.activeCompany.Id;
        Global.COMPANY_ID = this.activeCompany.Id;
        Global.COMPANY = activeCompany;
        this.getCompanyLogo();
        if (this.sessionService.role.IsFullAdmin && !activeCompany.HasAcceptedTerms) {
          this.showAcceptDPADialog = true;
        }
      }
    });
  }

  public get isFullAdmin(): boolean {
    return this.sessionService.role.IsFullAdmin;
  }

  private setCompanyContext(companyId: number): void {
    this.dataService.Auth_SetCompanyContext(companyId).subscribe(() => {
      Global.COMPANY_ID = companyId;
      this.settingService.showCompanySelector = false;
      this.reloadStaticData();
    });
  }

  private getCompanyLogo(): void {
    this.staticDataService.currentLogo.subscribe((image: string) => {
      this.logo = image;
      this.showCompanyAbbreviation = true;
      this.currentCompanyName = this.activeCompany.Name;
      this.currentCompanyAbbreviation = this.currentCompanyName
        ? this.currentCompanyName.toUpperCase().substring(0, 3)
        : '';
    });
  }

  public onShowAdHocSupport(): void {
    this.isShowAdHocSupport = true;
    if (this.hasModuleKey('PhoneSupport')) {
      // this.billingUsage.AmountPerUnit = 0;
      this.isShowEmailSupport = false;
    } else {
      this.isShowEmailSupport = true;
    }
  }

  public onCloseCompanySeach(): void {
    // this.settingService.showCompanySelector = false;
  }

  // @HostListener("click", ["$event"])
  public stopAllProcess($event: any): void {
    $event.preventDefault();
    $event.stopPropagation();
  }

  public CheckNonState(): boolean {
    if (
      !this.sessionService.currentState.includes('tabs.employee') &&
      !this.sessionService.currentState.includes('tabs.company') &&
      !this.sessionService.currentState.includes('tabs.selfservice') &&
      !this.sessionService.currentState.includes('tabs.accountant') &&
      !this.sessionService.currentState.includes('tabs.account')
    ) {
      return true;
    }
    return false;
  }
}
