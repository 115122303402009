<app-dialog
  [overlayClose]="false"
  [(visible)]="isVisible"
  (action)="revertModalAction($event)"
  [title]="'RevertPayslip.Headline' | translate"
>
  <div class="FormElement-row u-lineHeight">
    <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertPayslip.RevertConfirmationResult1' | translate"></span>
  </div>

  <div class="FormElement-row u-lineHeight">
    <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertPayslip.RevertConfirmationResult2' | translate"></span>
  </div>

  <div class="FormElement-row u-lineHeight" *ngIf="!noEmail">
    <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertPayslip.RevertConfirmationResult2_5' | translate"></span>
  </div>

  <div class="FormElement-row u-lineHeight" *ngIf="noEmail">
    <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertPayslip.RevertConfirmationResult3' | translate"></span>
  </div>

  <div *ngIf="!isGreenland" class="FormElement-row u-lineHeight">
    <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertPayslip.RevertConfirmationResult4' | translate"></span>
  </div>

  <div *ngIf="isGreenland" class="FormElement-row u-lineHeight">
    <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertPayslip.RevertConfirmationResult8' | translate"></span>
  </div>

  <div class="FormElement-row u-lineHeight">
    <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertPayslip.RevertConfirmationResult5' | translate"></span>
  </div>

  <div class="FormElement-row u-lineHeight">
    <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertPayslip.RevertConfirmationResult6' | translate"></span>
  </div>
  <div class="FormElement-row u-lineHeight" *ngIf="isExternalSystemRelevant">
    <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
    <span [innerHTML]="ExternalSystemRevertMessage"></span>
  </div>

  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="RevertBatch"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="isVisibleUnderStood"
  (action)="revertModalUnderStoodAction($event)"
  [title]="'RevertPayslip.Headline' | translate"
  [leadingText]="'RevertBatch.PasswordLabel' | translate"
>
  <input type="text" value="{{ userName }}" class="input-no-display" autocomplete="username" />
  <app-text-edit [autocorrect]="true" [editMode]="true" [(value)]="resetPasswordModel" [type]="'password'">
  </app-text-edit>

  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="Proceed"></app-dialog-action>
</app-dialog>
