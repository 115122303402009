import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'button-component',
  templateUrl: './Button.component.html'
})
export class ButtonComponent {
  @Input() public tooltip = '';
  @Input() public label: string;
  @Input() public isDisabled: boolean;
  @Input() public buttonClass: string;
  @Input() public isUseOneClick = false;

  @Output() public click: EventEmitter<void> = new EventEmitter<void>();

  public isFirstClick = false;
  public onClickEvent(): void {
    // this.isFirstClick = true;
    // setTimeout(() => {
    //   this.click.emit();
    //   this.isFirstClick = false;
    // }, 500);
  }
}
