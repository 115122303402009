import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { UniqueId } from '../Common/UniqueId';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
var TextEditUpdateComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TextEditUpdateComponent, _super);
    function TextEditUpdateComponent(sessionService, elementRef, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.sessionService = sessionService;
        _this.isCPRno = false;
        _this.isTextArea = false;
        _this.textAreaLimitChar = 200;
        _this.TextAreaRows = 4;
        _this.blur = new EventEmitter();
        _this.addonClick = new EventEmitter();
        _this.autocorrect = false;
        _this.readOnly = false;
        _this.showPassword = false;
        _this.elementId = 'textEditComponentId' + UniqueId();
        _this.setTimeout = false;
        return _this;
    }
    TextEditUpdateComponent.prototype.ngOnInit = function () {
        this.readOnly = this.readonlyOnNotFocus && this.sessionService.browser.isChrome;
    };
    Object.defineProperty(TextEditUpdateComponent.prototype, "TextLenght", {
        get: function () {
            if (this.value) {
                var res = this.textAreaLimitChar - this.value.length;
                return res >= 0 ? res.toString() : '0';
            }
            return this.textAreaLimitChar.toString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextEditUpdateComponent.prototype, "TextAreaClass", {
        get: function () {
            if (this.value && this.value.length > this.textAreaLimitChar) {
                return ' input-required';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    TextEditUpdateComponent.prototype.TextAreakeypress = function () {
        if (this.value && this.value.length > this.textAreaLimitChar) {
            this.value = this.value.substring(0, this.textAreaLimitChar);
        }
    };
    Object.defineProperty(TextEditUpdateComponent.prototype, "requiredValueMissing", {
        get: function () {
            return this.required && (!this.value || this.value.length === 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextEditUpdateComponent.prototype, "showRequiredMessage", {
        get: function () {
            return this.editMode && this.requiredValueMissing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextEditUpdateComponent.prototype, "showValidationMessage", {
        get: function () {
            return this.editMode && !this.valid() && this.type === 'email';
        },
        enumerable: true,
        configurable: true
    });
    TextEditUpdateComponent.prototype.setInputClass = function () {
        return ((this.inputClass ? this.inputClass : '') +
            (this.showValidationMessage || this.requiredValueMissing ? ' input-required' : ''));
    };
    Object.defineProperty(TextEditUpdateComponent.prototype, "hasMask", {
        get: function () {
            return this.mask && this.mask.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    TextEditUpdateComponent.prototype.onBlur = function () {
        if (this.readonlyOnNotFocus && this.sessionService.browser.isChrome) {
            this.readOnly = false;
        }
        this.blur.emit();
    };
    TextEditUpdateComponent.prototype.onFocus = function () {
        if (this.readonlyOnNotFocus && this.sessionService.browser.isChrome) {
            this.readOnly = false;
        }
    };
    TextEditUpdateComponent.prototype.valid = function () {
        if (this.required && (!this.value || this.value === '')) {
            return false;
        }
        switch (this.type) {
            case 'email':
                return !this.value || this.value.length === 0 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.value);
            default:
                return true;
        }
    };
    return TextEditUpdateComponent;
}(ControlComponentBase));
export { TextEditUpdateComponent };
