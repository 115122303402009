/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../CustomControls/Button.component.ngfactory";
import * as i2 from "../../../CustomControls/Button.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "./Controls/PayrollDataGrid.component.ngfactory";
import * as i6 from "./Controls/PayrollDataGrid.component";
import * as i7 from "../../../Services/RxDataService";
import * as i8 from "../../../Services/SharedServices/SettingService";
import * as i9 from "../../../Services/SharedServices/Session/SessionService";
import * as i10 from "../../../Services/SharedServices/StaticData.service";
import * as i11 from "./Controls/PayrollPreview.component.ngfactory";
import * as i12 from "./Controls/PayrollPreview.component";
import * as i13 from "../../../Services/SharedServices/Dialog/DialogService";
import * as i14 from "./EmployeePayrollDataMobile.component.ngfactory";
import * as i15 from "./EmployeePayrollDataMobile.component";
import * as i16 from "../../../CustomControls/Dialog/Dialog.component.ngfactory";
import * as i17 from "../../../CustomControls/Dialog/Dialog.component";
import * as i18 from "@angular/platform-browser";
import * as i19 from "../../../CustomControls/Dialog/DialogAction.component.ngfactory";
import * as i20 from "../../../CustomControls/Dialog/DialogAction.component";
import * as i21 from "./EmployeePayrollData.component";
var styles_EmployeePayrollDataComponent = [];
var RenderType_EmployeePayrollDataComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmployeePayrollDataComponent, data: {} });
export { RenderType_EmployeePayrollDataComponent as RenderType_EmployeePayrollDataComponent };
function View_EmployeePayrollDataComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button-component", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createUserEmployment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(1, 49152, null, 0, i2.ButtonComponent, [], { label: [0, "label"], buttonClass: [1, "buttonClass"] }, { click: "click" }), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("EmployeeGeneral.Create employment")); var currVal_1 = "Button--primary"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_EmployeePayrollDataComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "Cards"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "Card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "Card-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "h2", [["class", "Card-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeePayrollDataComponent_2)), i0.ɵdid(7, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.allowEdit; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("EmploymentPayrollData.No Employment")); _ck(_v, 4, 0, currVal_0); }); }
function View_EmployeePayrollDataComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-payroll-data-grid", [["class", "Card Employee-payrollDataCard"]], null, [[null, "isHidePreviewGridChange"], [null, "recalculateChange"], [null, "update"], ["document", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("isHidePreviewGridChange" === en)) {
        var pd_1 = ((_co.isHidePreviewGrid = $event) !== false);
        ad = (pd_1 && ad);
    } if (("recalculateChange" === en)) {
        var pd_2 = ((_co.recalculate = $event) !== false);
        ad = (pd_2 && ad);
    } if (("update" === en)) {
        var pd_3 = (_co.onUpdate() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i5.View_PayrollDataGridComponent_0, i5.RenderType_PayrollDataGridComponent)), i0.ɵdid(3, 245760, null, 0, i6.PayrollDataGridComponent, [i3.TranslateService, i7.RxDataService, i8.SettingService, i9.SessionService, i10.StaticDataService, i0.Renderer2], { userEmployment: [0, "userEmployment"], recalculate: [1, "recalculate"], isHidePreviewGrid: [2, "isHidePreviewGrid"] }, { recalculateChange: "recalculateChange", isHidePreviewGridChange: "isHidePreviewGridChange", update: "update" }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "payroll-preview", [["class", "Card Employee-payrollDataPreviewCard"]], null, [[null, "recalculatePreviewChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("recalculatePreviewChange" === en)) {
        var pd_0 = ((_co.recalculate = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_PayrollPreviewComponent_0, i11.RenderType_PayrollPreviewComponent)), i0.ɵdid(5, 49152, null, 0, i12.PayrollPreviewComponent, [i7.RxDataService, i9.SessionService, i13.DialogService, i3.TranslateService], { userEmployment: [0, "userEmployment"], recalculatePreview: [1, "recalculatePreview"], isUpdate: [2, "isUpdate"], isHidePreviewGrid: [3, "isHidePreviewGrid"] }, { recalculatePreviewChange: "recalculatePreviewChange" }), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["style", "position: absolute;left: 30%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "button-component", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewFullSalaryBatch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(8, 49152, null, 0, i2.ButtonComponent, [], { label: [0, "label"], buttonClass: [1, "buttonClass"] }, { click: "click" }), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(10, 0, null, null, 2, "button-component", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewLastPayslip() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(11, 49152, null, 0, i2.ButtonComponent, [], { label: [0, "label"], buttonClass: [1, "buttonClass"] }, { click: "click" }), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.userEmployment; var currVal_2 = _co.recalculate; var currVal_3 = _co.isHidePreviewGrid; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.userEmployment; var currVal_5 = _co.recalculate; var currVal_6 = _co.isUpdate; var currVal_7 = _co.isHidePreviewGrid; _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform("EmploymentPayrollData.View Full Salary Batch")); var currVal_9 = "Button--card"; _ck(_v, 8, 0, currVal_8, currVal_9); var currVal_10 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform("EmploymentPayrollData.ViewLastPayslip")); var currVal_11 = "Button--card"; _ck(_v, 11, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.ClassCardStack, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_EmployeePayrollDataComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-employee-payroll-data-mobile", [], null, [[null, "isAddNewChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isAddNewChange" === en)) {
        var pd_0 = ((_co.isAddNew = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_EmployeePayrollDataMobileComponent_0, i14.RenderType_EmployeePayrollDataMobileComponent)), i0.ɵdid(2, 245760, null, 0, i15.EmployeePayrollDataMobileComponent, [i3.TranslateService, i7.RxDataService, i8.SettingService, i9.SessionService, i0.Renderer2, i10.StaticDataService], { userEmployment: [0, "userEmployment"], isAddNew: [1, "isAddNew"] }, { isAddNewChange: "isAddNewChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userEmployment; var currVal_1 = _co.isAddNew; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_EmployeePayrollDataComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-dialog", [], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.newTabBlockedDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_DialogComponent_0, i16.RenderType_DialogComponent)), i0.ɵdid(1, 49152, null, 1, i17.DialogComponent, [i18.DomSanitizer, i9.SessionService, i0.ElementRef], { leadingText: [0, "leadingText"], visible: [1, "visible"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 1, { buttonElements: 1 }), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i19.View_DialogActionComponent_0, i19.RenderType_DialogActionComponent)), i0.ɵdid(5, 180224, [[1, 4]], 0, i20.DialogActionComponent, [i9.SessionService], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 3).transform("Report.NewTabBlocked")); var currVal_1 = _co.newTabBlockedDialogVisible; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "Ok"; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_EmployeePayrollDataComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeePayrollDataComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeePayrollDataComponent_3)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeePayrollDataComponent_4)), i0.ɵdid(6, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeePayrollDataComponent_5)), i0.ɵdid(8, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 5, "app-dialog", [], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.noPayslipDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_DialogComponent_0, i16.RenderType_DialogComponent)), i0.ɵdid(10, 49152, null, 1, i17.DialogComponent, [i18.DomSanitizer, i9.SessionService, i0.ElementRef], { leadingText: [0, "leadingText"], visible: [1, "visible"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 2, { buttonElements: 1 }), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(13, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i19.View_DialogActionComponent_0, i19.RenderType_DialogActionComponent)), i0.ɵdid(14, 180224, [[2, 4]], 0, i20.DialogActionComponent, [i9.SessionService], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(15, 0, null, null, 2, "app-dialog", [["class", "PayslipPopup"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.previewDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_DialogComponent_0, i16.RenderType_DialogComponent)), i0.ɵdid(16, 49152, null, 1, i17.DialogComponent, [i18.DomSanitizer, i9.SessionService, i0.ElementRef], { visible: [0, "visible"], iframeContent: [1, "iframeContent"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 3, { buttonElements: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hasUserEmployment; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.hasUserEmployment && !_co.isMobile); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.hasUserEmployment && _co.isMobile); _ck(_v, 6, 0, currVal_2); var currVal_3 = !_co.isCordovaApp; _ck(_v, 8, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 12).transform("EmployeePayslip.NoPayslip")); var currVal_5 = _co.noPayslipDialogVisible; _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = "Ok"; _ck(_v, 14, 0, currVal_6); var currVal_7 = _co.previewDialogVisible; var currVal_8 = _co.previewContent; _ck(_v, 16, 0, currVal_7, currVal_8); }, null); }
export function View_EmployeePayrollDataComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "employee-payroll-data", [], null, null, null, View_EmployeePayrollDataComponent_0, RenderType_EmployeePayrollDataComponent)), i0.ɵdid(1, 114688, null, 0, i21.EmployeePayrollDataComponent, [i8.SettingService, i7.RxDataService, i9.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeePayrollDataComponentNgFactory = i0.ɵccf("employee-payroll-data", i21.EmployeePayrollDataComponent, View_EmployeePayrollDataComponent_Host_0, { allowEdit: "allowEdit", userEmployment: "userEmployment", isAddNew: "isAddNew" }, { createUserEmploymentClick: "createUserEmploymentClick", isAddNewChange: "isAddNewChange" }, []);
export { EmployeePayrollDataComponentNgFactory as EmployeePayrollDataComponentNgFactory };
