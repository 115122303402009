<app-dialog
  id="reloginDialog"
  [overlayClose]="false"
  [visible]="visible"
  (action)="onDialogAction($event)"
  [width]="430"
>
  <div (keydown)="keydown($event)">
    <div class="Modal-content">
      <div class="Modal-header">
        <p innerHTML="{{ 'UnsuccessfulAPI.401' | translate }}"></p>
      </div>
      <div class="FormElement" [ngClass]="{ 'has-error': errorMessage }">
        <label class="FormElement-label" for="password">{{ 'Login.Password' | translate }}</label>
        <button class="FormElement-labelHelp" type="button" tabindex="-1" (click)="onResetPassword()">
          {{ 'Login.ForgotPassword' | translate }}
        </button>
        <div class="FormElement-control">
          <input type="text" value="{{ userName }}" class="input-no-display" autocomplete="username" />
          <input
            class="FormElement-input"
            id="reloginPassword"
            [type]="show ? 'text' : 'password'"
            [(ngModel)]="password"
            autocorrect="true"
            autocomplete="true"
          />
          <button
            class="FormElement-passwordToggle"
            type="button"
            (mouseover)="showpassword(true)"
            (mouseleave)="showpassword(false)"
            (click)="showPaswordForMobile()"
          >
            <app-icon [icon]="'Preview'"></app-icon>
          </button>
        </div>
        <div class="FormElement-feedback">{{ errorMessage }}</div>
      </div>
    </div>
  </div>

  <app-dialog-action type="Logout" [close]="false"></app-dialog-action>
  <app-dialog-action [disabled]="!password" type="Continue" [close]="false"></app-dialog-action>
</app-dialog>

<reset-password class="resetpasswordModalContent" [(isVisible)]="showResetPasswordDialog" [isReLogin]="true">
</reset-password>
