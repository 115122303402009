<div class="Card-inner">
  <div class="FormElement">
    <div class="FormElement-control">
      <grid
        class="FormElement-grid Employee-payrollDataGrid"
        [editMode]="!IsReadOnly"
        [deleteRow]="!IsReadOnly"
        [customDeleteRow]="true"
        [addNew]="!IsReadOnly"
        [addNewDisable]="disableGridToolbar"
        [idColumn]="'Id'"
        [addNewText]="'Grids.Add New Record' | translate"
        [(data)]="gridData"
        [(selectedId)]="selectedId"
        (selectedItemChange)="onSelectedItemChanges($event)"
        [(triggerUpdate)]="triggerGridComponentUpdate"
        (addNewEvent)="addHandler($event)"
        (removeEvent)="removeHandler($event)"
        (saveChangesEvent)="saveChanges($event)"
        (cellCloseEvent)="onCellCloseEvent($event)"
        (action)="onGridAction($event)"
        (gridButtonClick)="onGridButtonClicked($event)"
        id="payrollGrid"
        #kendoGridComponent
        [filterable]="'menu'"
        [filter]="filter"
        (filterChange)="onFilterChange($event)"
      >
        <grid-action
          [isHidden]="IsReadOnly"
          [disable]="disableGridToolbar"
          [action]="'recalculate'"
          [label]="'EmploymentPayrollData.Recalculate' | translate"
        ></grid-action>
        <grid-action
          [isHidden]="IsReadOnly || IsEnableDateAdjustment"
          [disable]="disableGridToolbar"
          [action]="'enableDate'"
          [label]="'EmploymentPayrollData.EnableDateAdjustment' | translate"
        ></grid-action>
        <grid-action
          [isHidden]="IsReadOnly || !IsEnableDateAdjustment"
          [disable]="disableGridToolbar"
          [action]="'disableDate'"
          [label]="'EmploymentPayrollData.DisableDateAdjustment' | translate"
        ></grid-action>
        <grid-action
          [isHidden]="IsReadOnly"
          [disable]="disableGridToolbar"
          [action]="'correctBalance'"
          [label]="'EmploymentPayrollData.Correct balances' | translate"
        ></grid-action>
        <grid-action
          [actionsSpanClass]="'btnHidePreviewGrid'"
          [isHidden]="IsReadOnly"
          [disable]="disableGridToolbar"
          action="onHidePreviewGrid"
          icon="{{ IconisHidePreviewGrid }}"
        ></grid-action>

        <gridColumn
          [isFilterable]="true"
          [field]="'SalaryTypeId'"
          [comboboxDataSource]="salaryTypes"
          [type]="'combobox'"
          [width]="150"
          [comboboxValueField]="'SalaryTypeId'"
          [comboboxDisplayField]="'Name'"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [editable]="true"
          [title]="'EmploymentPayrollData.SalaryType' | translate"
          [tooltipField]="'Description'"
          [tooltipFieldNonEdit]="'salaryTypeName'"
        ></gridColumn>

        <gridColumn
          [isFilterable]="true"
          [field]="'Description'"
          [tooltipField]="'Description'"
          [type]="'text'"
          [editable]="true"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [title]="'EmploymentPayrollData.Description' | translate"
          [width]="150"
        ></gridColumn>

        <gridColumn
          [isFilterable]="true"
          [field]="'BaseAmount'"
          [type]="'numeric'"
          [editable]="true"
          [title]="'EmploymentPayrollData.BaseAmount' | translate"
          [width]="75"
          [numbericOption]="NumericOptions"
          [hasIncellPopup]="true"
          [popupContent]="negativeDefaultTranslationText['HandlingOfSygedagpenge']"
          [editableField]="'AllowEditBaseAmount'"
          [classField]="'BaseAmountClass'"
        ></gridColumn>

        <gridColumn
          [isFilterable]="true"
          [field]="'Units'"
          [type]="'numeric'"
          [editable]="true"
          [title]="'EmploymentPayrollData.Units' | translate"
          [hasIncellPopup]="true"
          [popupContent]="negativeDefaultTranslationText['UnitsAutoConfigured']"
          [width]="65"
          [numbericOption]="NumericOptions"
          [editableField]="'AllowEditUnits'"
          [classField]="'UnitsClass'"
        ></gridColumn>

        <gridColumn
          [isFilterable]="true"
          [field]="'UnitTypeId'"
          [comboboxDataSource]="unitTypes"
          [type]="'combobox'"
          [comboboxValueField]="'Id'"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [comboboxDisplayField]="'Name'"
          [editable]="true"
          [width]="80"
          [title]="' '"
          [editableField]="'AllowEditUnitType'"
          [classField]="'UnitTypeClass'"
        ></gridColumn>

        <gridColumn
          [isFilterable]="true"
          [field]="'AmountPerUnit'"
          [type]="'numeric'"
          [editable]="true"
          [title]="'EmploymentPayrollData.Per Unit' | translate"
          [width]="75"
          [numbericOption]="NumericOptions"
          [editableField]="'AllowEditAmountPerUnit'"
          [classField]="'AmountPerUnitClass'"
        ></gridColumn>

        <gridColumn
          [isFilterable]="true"
          [field]="'Amount'"
          [type]="'numeric'"
          [editable]="true"
          [title]="'EmploymentPayrollData.Amount' | translate"
          [hasIncellPopup]="true"
          [popupContent]="AmountFlipMessage"
          [popupActions]="AmountFlipButtons"
          [width]="80"
          [numbericOption]="NumericOptions"
        ></gridColumn>

        <gridColumn
          [isFilterable]="true"
          [field]="'PersistenceTypeId'"
          [comboboxDataSource]="persistanceTypes"
          [type]="'combobox'"
          [comboboxValueField]="'Id'"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [comboboxDisplayField]="'Name'"
          [editable]="true"
          [width]="130"
          [title]="'EmploymentPayrollData.After next batch' | translate"
        ></gridColumn>

        <gridColumn
          [isFilterable]="true"
          *ngIf="!isHideDateColumns"
          [field]="'StartDate'"
          [type]="'datepicker'"
          [editable]="true"
          [title]="'EmploymentPayrollData.From date' | translate"
          [width]="140"
        ></gridColumn>

        <gridColumn
          [isFilterable]="true"
          *ngIf="!isHideDateColumns"
          [field]="'EndDate'"
          [type]="'datepicker'"
          [editable]="true"
          [title]="'EmploymentPayrollData.To date' | translate"
          [width]="140"
        ></gridColumn>

        <gridColumn
          [isFilterable]="true"
          *ngIf="Dimension1Preference && Dimension1Preference.Value"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [type]="'autocomplete'"
          [editable]="true"
          [title]="Dimension1Preference?.Value"
          [width]="100"
          [field]="'Dimension1Value'"
          [comboboxDataSource]="DimensionXValue[0]"
        ></gridColumn>

        <gridColumn
          [isFilterable]="true"
          *ngIf="Dimension2Preference && Dimension2Preference.Value"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [type]="'autocomplete'"
          [editable]="true"
          [title]="Dimension2Preference?.Value"
          [width]="100"
          [field]="'Dimension2Value'"
          [comboboxDataSource]="DimensionXValue[1]"
        ></gridColumn>

        <gridColumn
          [isFilterable]="true"
          *ngIf="Dimension3Preference && Dimension3Preference.Value"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [type]="'autocomplete'"
          [editable]="true"
          [title]="Dimension3Preference?.Value"
          [width]="100"
          [field]="'Dimension3Value'"
          [comboboxDataSource]="DimensionXValue[2]"
        ></gridColumn>
      </grid>
    </div>
  </div>
</div>

<negative-default-info
  [setting]="negativeDefaultMoreDialogModel.setting"
  [fieldName]="negativeDefaultMoreDialogModel.fieldName"
  [dataItem]="negativeDefaultMoreDialogModel.dataItem"
  [(showDialog)]="showNegativeDefaultMoreInfoDialog"
  (usePositiveAmount)="onMoreInfoUsePositiveAmount($event)"
  (enableAutoCorrect)="autoCorrect($event)"
></negative-default-info>

<app-dialog
  (action)="onConfirmDateAdjustmentSetting($event)"
  [(visible)]="showDateSettingConfirmation"
  [leadingText]="'EmploymentPayrollData.EnableDateAdjustmentMessage' | translate"
>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="Enable" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onRemoveRowConfirmed($event)"
  [(visible)]="showDeleteItemConfirmation"
  [leadingText]="'Grids.Confirm Delete' | translate"
>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="Delete" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>
