<div class="FormElement">
  <div class="FormElement-control">
    <grid
      [data]="gridData"
      class="FormElement-grid Company-gridOthersErrorDetails u-gridHeight250"
      [editMode]="false"
      [filterable]="'menu'"
      [filter]="filter"
      (filterChange)="onFilterChange($event)"
    >
      <gridColumn
        [title]="'CompanySalaryBatches.OrtherStatusGrid Is Error' | translate"
        [type]="'icon'"
        [iconField]="'icon'"
        [tooltipField]="'tooltip'"
        [width]="50"
      >
      </gridColumn>

      <gridColumn
        [isFilterable]="true"
        [title]="'CompanySalaryBatches.OrtherStatusGrid Is Error Description' | translate"
        [type]="'text'"
        [field]="'description'"
        [width]="200"
      >
      </gridColumn>

      <gridColumn
        [isFilterable]="true"
        [title]="'CompanySalaryBatches.OrtherStatusGrid Employee Name' | translate"
        [type]="'text'"
        [field]="'employeeName'"
        [width]="100"
      >
      </gridColumn>
    </grid>
  </div>
</div>
