<div class="FormElement has-fixedAddon">
  <span class="FormElement-label">{{ label }}:</span>
  <div class="FormElement-control alignItems">
    <numeric-edit
      [editMode]="editMode"
      [disable]="disable"
      [(isDirty)]="childIsDirty[0]"
      [(value)]="firstValue"
      (valueChange)="onChange()"
      [option]="numericTextBoxOption"
    ></numeric-edit>
    <span class="FormElement-addon">&nbsp;&nbsp;</span>
    <span class="FormElement-addon">/</span>
    <numeric-edit
      [editMode]="editMode"
      [disable]="disable"
      [(isDirty)]="childIsDirty[1]"
      [(value)]="secondValue"
      (valueChange)="onChange()"
      [option]="numericTextBoxOption"
    ></numeric-edit>
    <span class="FormElement-addon">&nbsp;&nbsp;</span>
  </div>
</div>
