import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { RouterStateService } from '../../Common/RouterState.service';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
var TabPanelComponent = /** @class */ (function () {
    function TabPanelComponent(session, state) {
        this.session = session;
        this.state = state;
        this.payslipTabVisible = false;
        this.timeTabVisible = false;
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(TabPanelComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    TabPanelComponent.prototype.ngOnInit = function () {
        this.setResponsiveStyle(this.session.currentState);
    };
    TabPanelComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.unsubscribe();
    };
    TabPanelComponent.prototype.clickAreamenuToggledChange = function () {
        this.session.menuToggled = true;
        this.session.showUserMenuToggled = false;
        this.session.showMoreMenuToggled = false;
        this.session.showHelpMenuToggled = false;
    };
    TabPanelComponent.prototype.toggleMenuButton = function () {
        if (!this.session.menuToggled) {
            this.session.menuToggled = true;
        }
        else {
            this.session.menuToggled = false;
        }
        window.scrollTo(0, 0);
    };
    TabPanelComponent.prototype.navigateToState = function ($event, state) {
        $event.stopPropagation();
        $event.preventDefault();
        this.setResponsiveStyle(state);
        this.session.NavigateTo(state);
    };
    TabPanelComponent.prototype.setResponsiveStyle = function (state) {
        switch (state) {
            case 'tabs.employee.hiringstatus':
                this.session.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
                break;
            case 'tabs.employee.payrolldata':
                this.session.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
                break;
            default:
                if (this.session.currentState !== 'tabs.employee') {
                    this.session.currentStyleMenuTabpanelReponsive = '';
                }
                break;
        }
        if (this.parentTab.toLowerCase() === 'company') {
            this.session.currentStyleMenuTabpanelReponsive = 'companytabPanelLongTitle';
        }
    };
    return TabPanelComponent;
}());
export { TabPanelComponent };
