import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Raven from 'raven-js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Browser } from '../../Common/Browser';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { AuthenticationService } from '../../Services/Authentication/AuthenticationService';
import { Broadcaster } from '../../Services/Broadcaster';
import { ModalService } from '../../Services/SharedServices/ModalService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'relogin',
  templateUrl: './Relogin.component.html'
})
export class ReloginComponent implements OnInit, OnDestroy {
  public message: string;
  public errorMessage: string;
  public password = '';
  public Unauthorized: string;
  public WrongUsernameOrPassword: string;
  public networkErrorMessage: string;
  public visible: boolean;
  public userName: string;

  constructor(
    public translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private broadcaster: Broadcaster,
    private sessionService: SessionService,
    public modalService: ModalService,
    public staticDataService: StaticDataService
  ) {
    this.setupEventHandlers();
  }

  public ngOnInit(): void {
    this.userName = localStorage.getItem('saveLoginUserName');
    this.translateText();
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public translateText(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService
        .get(['Error.NetworkError', 'Login.WrongUserNameOrPassword'])
        .subscribe((translations: { [key: string]: string }) => {
          this.networkErrorMessage = translations['Error.NetworkError'];
          this.WrongUsernameOrPassword = translations['Login.WrongUserNameOrPassword'];
        });
    });
  }

  private setupEventHandlers(): void {
    this.broadcaster
      .on(Constants.SESSION_EXPIRED)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.visible = true;
      });

    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) => {});
  }

  keydown(e: any): void {
    if (e.keyCode === 13) {
      this.login();
    } else {
      this.errorMessage = undefined;
    }
  }

  logout(): void {
    this.authenticationService
      .logout()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          this.navigateToLogin();
        },
        () => {
          this.navigateToLogin();
        }
      );
  }

  private navigateToLogin(): void {
    this.sessionService.NavigateTo('login');
  }

  private reloadStaticData(): void {
    this.staticDataService.loadStaticData().then(() => {
      this.close();
      window.location.reload();
    });
  }

  login(): any {
    this.authenticationService.credentials = {
      Username: Global.SESSION ? Global.SESSION.SignOnToken.Username : '',
      Password: this.password ? this.password : '',
      Remember: false
    };

    let message: string;
    this.authenticationService
      .login()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          this.reloadStaticData();
        },
        (reason: any) => {
          // Failure
          switch (reason.status) {
            case 0:
              message = this.networkErrorMessage;
              break;
            case 401:
              message = this.WrongUsernameOrPassword;
              break;
            case 400:
              message = reason.data.Message;
              break;
            case 500:
              // Internal server error
              let errorMessage: string = reason.statusText;
              if (reason.config && reason.config.url) {
                errorMessage += ' - ' + reason.config.url;
              }

              Raven.captureException(new Error(errorMessage), { extra: reason });
              break;
            default:
              let othermessage: string = reason.statusText;
              if (reason.config && reason.config.url) {
                othermessage += ' - ' + reason.config.url;
              }

              Raven.captureException(new Error(othermessage), { extra: reason });
              break;
          }

          this.errorMessage = message;
          console.error('Login failed: ');
          console.error(reason);
        }
      );
  }

  private close(): void {
    this.visible = false;
  }

  public show: boolean;
  public showpassword(isShow?: boolean): void {
    if (isShow) {
      this.show = isShow;
    } else {
      this.show = false;
    }
  }

  public showResetPasswordDialog = false;
  onResetPassword(): void {
    this.showResetPasswordDialog = true;
  }

  public onDialogAction(action: string): void {
    if (action === 'Continue') {
      this.login();
    } else {
      this.logout();
    }
  }

  public get isMobile(): boolean {
    const browser: Browser = new Browser();
    return browser.isMobile;
  }

  public showPaswordForMobile(): void {
    if (this.isMobile) {
      this.show = true;
    }
  }
}
