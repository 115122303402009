<div class="FormElement has-fixedAddon">
  <span class="FormElement-label" *ngIf="label">
    <abbr *ngIf="required" class="FormElement-required" title="required">*</abbr><span>{{ label }}:</span>
    <app-icon
      *ngIf="helpLink && !isValueIcon"
      class="align-items-center {{ maxHeight20 }}"
      [isSelfService]="isSelfService"
      [icon]="helpIcon"
      [tooltip]="helpTooltip"
      [link]="helpLink"
      [isOpenLink]="isOpenLink"
      [tooltipWidth]="340"
      [istooltipHTML]="true"
    ></app-icon>
  </span>

  <div class="FormElement-control">
    <div class="FormElement-static" *ngIf="!editMode" [ngClass]="{ 'text-right': rightText }">
      {{ percentageDisplayText }}
    </div>

    <kendo-numerictextbox
      id="{{ componentId }}"
      *ngIf="editMode"
      class="FormElement-numericInput"
      [ngClass]="{ disable: disable, 'text-right': rightText }"
      [hidden]="!editMode"
      [step]="step ? 0 : 1"
      [min]="minValue ? minValue : 0"
      [max]="maxValue ? maxValue : 100"
      [decimals]="allowDecimal ? 2 : 0"
      [disabled]="disable"
      [(ngModel)]="percentValue"
    ></kendo-numerictextbox>

    <app-icon
      *ngIf="helpLink && isValueIcon"
      class="align-items-center {{ maxHeight20 }}"
      [isSelfService]="isSelfService"
      [icon]="helpIcon"
      [tooltip]="helpTooltip"
      [link]="helpLink"
      [isOpenLink]="isOpenLink"
      [tooltipWidth]="340"
      [istooltipHTML]="true"
    ></app-icon>

    <div class="FormElement-addon" *ngIf="editMode && percentageSign">&nbsp;%</div>
    <!-- <div class="validation-texteditor k-widget k-tooltip k-tooltip-validation" *ngIf="isInvalid">{{'EmployeeGeneral.Required' | translate}}</div> -->
  </div>
</div>
