import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { Global } from '../../../Common/Global';
import { RxDataService } from '../../../Services/RxDataService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
var EmployeeHiringStatusComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeHiringStatusComponent, _super);
    function EmployeeHiringStatusComponent(settingService, staticDataService, dataService) {
        var _this = _super.call(this) || this;
        _this.settingService = settingService;
        _this.staticDataService = staticDataService;
        _this.dataService = dataService;
        _this.createUserEmploymentClick = new EventEmitter();
        _this.reloadUserEmployment = new EventEmitter();
        _this.companyUserChange = new EventEmitter();
        _this.triggerRefreshTaxCard = false;
        _this.childIsValid = Array(10).fill(true);
        _this.childIsDirty = Array(10).fill(false);
        return _this;
    }
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "companyUser", {
        get: function () {
            return this.companyUserValue;
        },
        set: function (value) {
            if (!this.companyUserValue && !value) {
                return;
            }
            this.companyUserValue = value;
            this.companyUserChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "userEmployment", {
        get: function () {
            ////if (this.userEmploymentValue === undefined) {
            ////    this.userEmploymentValue = {};
            ////}
            return this.userEmploymentValue;
        },
        set: function (value) {
            if (!this.userEmploymentValue && !value) {
                return;
            }
            if (!value || !value.Id) {
                this.hasUserEmployment = false;
            }
            else {
                this.hasUserEmployment = true;
            }
            this.taxCardDataChanged = true;
            this.userEmploymentValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "taxCardObject", {
        get: function () {
            var userEmployment = this.userEmployment;
            this.setTaxCardData(userEmployment);
            return this.taxCardData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "isVacationDisable", {
        get: function () {
            return this.SecondaryIncomeType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "isAdditionalVacationDaysDisable", {
        get: function () {
            return this.SecondaryIncomeType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "isMultiPurposeOffDayCompensationDisable", {
        get: function () {
            return this.SecondaryIncomeType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "SecondaryIncomeType", {
        get: function () {
            if (!this.userEmployment) {
                return true;
            }
            return this.userEmployment.IncomeTypeId === 5;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "firstPensionProviderVisible", {
        get: function () {
            if (this.userEmployment && this.userEmployment.PensionProviderId) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "secondPensionProviderVisible", {
        get: function () {
            if (this.userEmployment && this.userEmployment.Pension2ProviderId) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "showStatisticsSection", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.StatisticsRecipientId && Global.COMPANY.StatisticsRecipientId !== -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "showMultiPurposeOffDayCompensation", {
        get: function () {
            var enableFlexBalanceSetting = Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.filter(function (pref) { return pref.Key === 'Company.EnableFlexBalance'; })[0]
                : undefined;
            if (enableFlexBalanceSetting) {
                return enableFlexBalanceSetting.Value === 'true' ? true : false;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeHiringStatusComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.taxCardTypes = data); });
        this.settingService.showModuleMessage('Employee.Employment');
    };
    EmployeeHiringStatusComponent.prototype.setTaxCardData = function (value, newtaxInfo) {
        if (this.taxCardDataChanged || newtaxInfo) {
            var isSpecialTaxBrief = void 0;
            if (value && (value.IncomeTypeId === 0 || value.IncomeTypeId === Constants.MAGIC_NUMBER)) {
                isSpecialTaxBrief = false;
            }
            else {
                isSpecialTaxBrief = true;
            }
            // Only create a new instance of taxcard if there is changes
            this.taxCardData = {
                taxInfo: newtaxInfo ? newtaxInfo : this.saveTaxInfo ? this.saveTaxInfo : undefined,
                taxCardRequestTime: value ? value.TaxCardRequested : undefined,
                taxCardTypes: this.taxCardTypes,
                userEmploymentId: value ? value.Id : undefined,
                specialTaxBrief: isSpecialTaxBrief
            };
            this.taxCardDataChanged = false;
        }
    };
    EmployeeHiringStatusComponent.prototype.onDataChanged = function (isReload) {
        if (isReload === void 0) { isReload = false; }
        if (this.userEmployment.IncomeTypeId === 5) {
            this.userEmployment.VacationTypeId = -1;
        }
        this.onChange();
        if (isReload) {
            this.reloadUserEmployment.emit();
        }
    };
    EmployeeHiringStatusComponent.prototype.createUserEmployment = function () {
        this.createUserEmploymentClick.emit();
    };
    EmployeeHiringStatusComponent.prototype.onReloadTaxCard = function () {
        this.triggerRefreshTaxCard = !this.triggerRefreshTaxCard;
    };
    EmployeeHiringStatusComponent.prototype.onRequestTaxcardWarningDialogAction = function (action) {
        if (action === 'AlreadyRequestedTaxCardConfirmButton') {
            this.showRequestTaxcardDialog();
        }
    };
    EmployeeHiringStatusComponent.prototype.showRequestTaxcardDialog = function () {
        this.requestTaxcardDialogVisible = true;
    };
    EmployeeHiringStatusComponent.prototype.showViewTaxCardHistory = function () {
        this.taxcardHistoryDialogVisible = true;
    };
    EmployeeHiringStatusComponent.prototype.showRequestTaxcardConfirm = function (value) {
        this.saveTaxInfo = value;
        this.requestTaxcardWarningDialog = true;
    };
    EmployeeHiringStatusComponent.prototype.onCreateTaxInfo = function (value) {
        if (value) {
            this.setTaxCardData(this.userEmployment, value);
        }
    };
    return EmployeeHiringStatusComponent;
}(FormComponentBase));
export { EmployeeHiringStatusComponent };
