import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { UniqueId } from '../Common/UniqueId';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
import { NumericTextBoxOptions } from './NumericTextBoxOptions';

@Component({
  selector: 'numeric-edit',
  templateUrl: './NumericEdit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumericEditComponent extends ControlComponentBase<string> {
  @Input() public label: string;
  @Input() public validationMessage = '';
  @Input() public option: NumericTextBoxOptions = { min: 0, step: 1, spinners: false };
  @Input() public required: boolean;
  @Input() public disable = false;
  @Input() public customModelOptions: any;
  @Input() public centerText: boolean;
  @Input() public rightText: boolean;
  @Input() public isSpecialLabel = false;
  @Input() public customClass: string;
  @Input() public helpLink: string;
  @Input() public helpTooltip = '';
  @Input() public helpIcon = 'Help';
  @Input() public isOpenLink = true;
  @Input() public isNotNegativeValue = false;
  @Input() public maxHeight20 = '';

  public componentId: string;

  constructor(elementRef: ElementRef, private sessionService: SessionService, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
    this.componentId = 'NumericTextBoxComponent' + UniqueId();
  }

  protected valid(): boolean {
    if (this.required && (!this.value || this.value.length === 0)) {
      return false;
    }

    if (this.value && !this.sessionService.parseFloat(this.value)) {
      return false;
    }

    return true;
  }

  @Output() blur: EventEmitter<any> = new EventEmitter<any>();

  public onBlur(): void {
    this.blur.emit();
  }

  public onvalueChange(value: number): void {
    if (value && value < 0 && this.isNotNegativeValue) {
      value = value * -1;
      (this.value as any) = value;
    }
  }
}
