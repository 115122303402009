/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./GridAction.component";
var styles_GridActionComponent = [];
var RenderType_GridActionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GridActionComponent, data: {} });
export { RenderType_GridActionComponent as RenderType_GridActionComponent };
export function View_GridActionComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_GridActionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "grid-action", [], null, null, null, View_GridActionComponent_0, RenderType_GridActionComponent)), i0.ɵdid(1, 49152, null, 0, i1.GridActionComponent, [], null, null)], null, null); }
var GridActionComponentNgFactory = i0.ɵccf("grid-action", i1.GridActionComponent, View_GridActionComponent_Host_0, { action: "action", label: "label", icon: "icon", actionsSpanClass: "actionsSpanClass", disable: "disable", isHidden: "isHidden" }, {}, []);
export { GridActionComponentNgFactory as GridActionComponentNgFactory };
