import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mobile-modal-header',
  templateUrl: './MobileModalHeader.component.html'
})
export class MobileModalHeaderComponent implements OnInit {
  constructor() {}

  private transform = false;
  ngOnInit(): void {}
}
