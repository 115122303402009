import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { ReflectionHelper } from '../Common/ReflectionHelper';
import { UniqueId } from '../Common/UniqueId';

@Component({
  selector: 'auto-complete-textbox',
  templateUrl: './AutoCompleteTextBox.component.html'
})
export class AutoCompleteTextBoxComponent extends ControlComponentBase<string> {
  @ViewChild('autoCompleteTextbox', { static: false }) public autocomplete: any;
  @Input() public label: string;
  @Input() public requiredMessage: string;
  @Input() public textField: string;
  @Input() public disable: boolean;
  @Input() public isRequired = false;
  @Input() public allowCustom = true;
  @Input() public isSuggest = true;
  @Input() public isLabelHtml = false;
  @Input() public autocompleteby1value = false;
  @Input() public viewClass = '';

  private autoCompleteDataSourceValue: string[];
  @Input()
  public get autoCompleteDataSource(): string[] {
    return this.autoCompleteDataSourceValue;
  }
  public set autoCompleteDataSource(value: string[]) {
    if (this.autoCompleteDataSourceValue !== value) {
      this.autoCompleteDataSourceValue = value;
      this.autoCompleteFilterChanged(this.value);
    }
  }

  public componentId: string;
  public dataSource: string[];

  constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
    this.componentId = 'autoCompleteEdit' + UniqueId();
  }

  protected convertValue(value: any): string {
    return !value || ReflectionHelper.isString(value) ? value : (value as any).toString();
  }

  protected valid(): boolean {
    let result = true;
    if (!this.allowCustom && this.autoCompleteDataSource && this.value) {
      // Select Item from Source.
      result =
        this.autoCompleteDataSource.filter((item: any) => this.getValueByDataType(item) === this.value).length > 0;
      if (!result) {
        result = false;
        const suggestedItem: any = this.autoCompleteDataSource.find(
          (item: any) => this.getValueByDataType(item).indexOf(this.value) >= 0
        );
        this.value = this.getValueByDataType(suggestedItem);
      }
    }

    return result;
  }

  public get showValidationMessage(): boolean {
    return (
      this.isRequired &&
      this.editMode &&
      !this.value &&
      (this as any).translatedRequiredMessage &&
      (this as any).translatedRequiredMessage.length !== 0
    );
  }

  private firstTimeNotFilter = true;
  public autoCompleteFilterChanged(value: any): void {
    if (value && this.editMode && (value !== this.original || !this.firstTimeNotFilter)) {
      this.firstTimeNotFilter = false;
      value = value.toString();
      const filterDataSource: any = this.autoCompleteDataSource
        ? this.autoCompleteDataSource.filter((item: any) => {
            return ReflectionHelper.isString(item)
              ? item
                ? item.toLowerCase().indexOf(value.toLowerCase()) !== -1
                : false
              : item && this.textField && item[this.textField]
              ? item[this.textField].toLowerCase().indexOf(value.toLowerCase()) !== -1
              : false;
          })
        : undefined;

      if (!this.autocompleteby1value) {
        if (value.length >= 3 && filterDataSource && filterDataSource.length > 0) {
          this.dataSource = filterDataSource;
        } else {
          if (this.autocomplete) {
            this.autocomplete.toggle(false);
          }
        }
      } else {
        if (value.length >= 1 && filterDataSource && filterDataSource.length > 0) {
          this.dataSource = filterDataSource;
        } else {
          if (this.autocomplete) {
            this.autocomplete.toggle(false);
          }
        }
      }
    } else {
      if (this.autocomplete) {
        this.autocomplete.toggle(false);
      }
    }
  }

  private getValueByDataType(item: any): string {
    return item ? (ReflectionHelper.isString(item) ? item : this.textField ? item[this.textField] : '') : '';
  }
}
