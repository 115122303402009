import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (!window.cordova) {
  bootstrap();
} else {
  document.addEventListener(
    'deviceready',
    (): void => {
      bootstrap();
    },
    false
  );
}

function bootstrap(): void {
  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err: any) => console.log(err));
}
