import { ChangeDetectorRef, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ReflectionHelper } from './ReflectionHelper';

export abstract class ControlComponentBase<T> {
  public original: T;
  private currentValue: T;
  private valueSet: boolean;
  private allowSetOriginal: boolean;
  public nullIdValue: T;
  public isFiltering: boolean;
  @Input() public defaultnullvalue: any;

  @Input() public isGrid: boolean;
  @Input() public preventChange: boolean;

  @Input()
  public get value(): T {
    return this.currentValue;
  }
  public set value(value: T) {
    // if (this.isFiltering) {
    //     return;
    // }

    this.valueSet = true;
    if (
      (this.original === undefined || this.original === this.nullIdValue) &&
      (!this.editMode || this.allowSetOriginal)
    ) {
      this.original = this.convertValue(value);
      this.currentValue = this.convertValue(value);
      if (this.isGrid) {
        this.changeDetectorRef.markForCheck();
      }
    } else if (value instanceof Date) {
      if (
        (!this.currentValue && value) ||
        (this.currentValue && !value) ||
        (this.currentValue && value && this.currentValue.toString() !== this.convertValue(value).toString())
      ) {
        this.setCurrentValueAndTriggerChange(value);
      }
    } else if (this.currentValue !== this.convertValue(value)) {
      this.setCurrentValueAndTriggerChange(value);
    }
  }

  private setCurrentValueAndTriggerChange(value: T): void {
    this.currentValue = this.convertValue(value);
    if (!this.editMode) {
      this.original = this.convertValue(value);
      this.isDirty = false;
    } else {
      this.onChange();
    }
  }

  @Output() public valueChange: EventEmitter<T> = new EventEmitter<T>();

  private editModeValue = false;
  @Input()
  public get editMode(): boolean {
    return this.editModeValue;
  }
  public set editMode(value: boolean) {
    if (this.editModeValue !== value) {
      if (value) {
        if (!this.valueSet) {
          this.allowSetOriginal = true;
        } else {
          this.original = this.currentValue;
        }
      } else {
        this.allowSetOriginal = false;
      }

      this.editModeValue = value;
      this.editModeChange.emit(value);
    }
  }
  @Output() public editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public childIsDirty: boolean[] = [];
  private isDirtyValue = false;
  @Input()
  public get isDirty(): boolean {
    return this.isDirtyValue;
  }
  public set isDirty(value: boolean) {
    if (this.isDirtyValue !== value) {
      this.isDirtyValue = value;
      // setTimeout(() => this.isDirtyChange.emit(value));
      this.isDirtyChange.emit(value);
      if (!value) {
        ////this.value = this.original;
        this.original = this.value;
      }
    }
  }
  @Output() public isDirtyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public childIsValid: boolean[] = [];
  private isValidValue = true;
  @Input()
  public get isValid(): boolean {
    return this.isValidValue;
  }
  public set isValid(value: boolean) {
    if (this.isValidValue !== value) {
      this.isValidValue = value;
      this.isValidChange.emit(value);
    }
  }
  @Output() public isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public setTimeout = true;

  private hostClassValue: string;
  @Input()
  public get hostClass(): string {
    return this.hostClassValue;
  }
  public set hostClass(value: string) {
    if (this.hostClassValue !== value) {
      this.hostClassValue = value;
      this.removePreviousHostClass();
      if (value && value !== '') {
        this.previousHostClass = value;
        this.elementRef.nativeElement.parentElement.classList.add(value);
      }
    }
  }

  private previousHostClass: string;

  constructor(private elementRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) {}

  public onChange(triggerChanged: boolean = true): void {
    if (this.setTimeout) {
      setTimeout(() => {
        this.isValid = this.valid();
        this.isDirty = this.hasChanges();
        if (triggerChanged) {
          this.onEmitValueChange(this.value);
        }
      });
    } else {
      this.isValid = this.valid();
      this.isDirty = this.hasChanges();
      if (triggerChanged) {
        this.onEmitValueChange(this.value);
      }
    }
  }

  public onEmitValueChange(value: T): void {
    if (!this.preventChange) {
      this.valueChange.emit(this.nullIdValue && value === (this.nullIdValue as any) ? undefined : value);
    }
  }

  protected convertValue(value: any): T {
    return this.nullIdValue
      ? (!value && value !== 0) || (!value && value === this.defaultnullvalue)
        ? this.nullIdValue
        : value
      : value;
  }

  protected valid(): boolean {
    return this.childIsValid.every((c: boolean) => c);
  }

  protected hasChanges(): boolean {
    if (this.childIsDirty.some((c: boolean) => c)) {
      return true;
    }

    if (this.original === undefined && this.currentValue === undefined) {
      return false;
    }

    if (
      (this.original !== undefined && this.currentValue === undefined) ||
      (this.original === undefined && this.currentValue !== undefined)
    ) {
      return true;
    }

    if (ReflectionHelper.isObject(this.original) && !ReflectionHelper.isDate(this.original)) {
      return !ReflectionHelper.isEqualShallow(this.original, this.currentValue);
    } else {
      return this.original !== this.currentValue;
    }
  }

  private removePreviousHostClass(): void {
    this.elementRef.nativeElement.parentElement.classList.remove('undefined');
    if (this.previousHostClass) {
      this.elementRef.nativeElement.parentElement.classList.remove(this.previousHostClass);
      this.previousHostClass = '';
    }
  }
}
