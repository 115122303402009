<div class="Card-inner {{ hideClass }} breakHidePreviewGrid">
  <div class="Employee-payrollControlsnonmaxwidth">
    <div class="Card-title-nonborder Employee-payroll-title">{{ 'EmploymentPayrollData.Preview' | translate }}</div>
    <div class="Employee-payrollButtons" *ngIf="payslipIconsVisible">
      <payslip-icon
        *ngIf="IsAppOrDesktop"
        id="ViewPayslipSameTabButton"
        [isHidden]="isHidden"
        [payslipIconType]="'htmlSameTab'"
        (click)="downloadSalaryStatementsPreview(true)"
      ></payslip-icon>
      <payslip-icon
        *ngIf="IsWeb"
        id="ViewPayslipNewTabButton"
        [payslipIconType]="'htmlNewTab'"
        (click)="downloadSalaryStatementsPreview(false)"
      ></payslip-icon>
      <a *ngIf="isMobilePayrollDataSlide" (click)="onClosePreview()">
        <app-icon [icon]="'ChevronDown-small'"></app-icon>
      </a>
    </div>
  </div>

  <div class="Employee-payrollControlsWrapper">
    <div class="Employee-payrollControlsNonBorder">
      <div class="Period-control {{ classPayrollDataSlidePayrollDataSlide }}">
        <combobox-edit-control
          *ngIf="!isMobilePayrollDataSlide"
          [label]="'EmploymentPayrollData.SalaryPeriod' | translate"
          [editMode]="true"
          [comboboxDataSource]="periods"
          [(value)]="periodId"
          [textField]="'FriendlyName'"
          [idField]="'Id'"
          (valueChange)="onPeriodChanged()"
          [showNavigationButton]="true"
          [isConvertTime]="false"
        ></combobox-edit-control>

        <combobox-edit-control
          *ngIf="isMobilePayrollDataSlide"
          [editMode]="false"
          [comboboxDataSource]="periods"
          [textField]="'FriendlyName'"
          [idField]="'Id'"
          [specialShowNavigate]="true"
          [(value)]="periodId"
          [showNavigationButton]="true"
          (valueChange)="onPeriodChanged()"
          [isConvertTime]="false"
        >
        </combobox-edit-control>
      </div>
    </div>

    <div class="FormElement">
      <div class="FormElement-control">
        <grid [data]="gridData" [editMode]="false" class="FormElement-grid Employee-payrollDataPreviewGrid">
          <gridColumn [title]="''" [field]="'Description'" [type]="'text'" [width]="200"></gridColumn>
          <gridColumn [title]="''" [field]="'Amount'" [type]="'numeric'" [width]="90"></gridColumn>
        </grid>
      </div>
    </div>
  </div>
</div>
