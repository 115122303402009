import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { Global } from '../../Common/Global';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { TaxEIncomeModel } from './TaxEIncomeModel';
var TaxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TaxComponent, _super);
    function TaxComponent(staticDataService, changeDetectorRef, sessionService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.sessionService = sessionService;
        _this.warningDialogVisible = false;
        _this.childIsDirty = Array(3).fill(false);
        _this.editModeChange.subscribe(function (value) {
            if (value && _this.model) {
                _this.previousImcomeTypeId = _this.model.IncomeTypeId;
            }
        });
        return _this;
    }
    Object.defineProperty(TaxComponent.prototype, "model", {
        get: function () {
            if (!this.modelValue) {
                this.modelValue = {};
            }
            return this.modelValue;
        },
        set: function (value) {
            this.modelValue = value;
            if (value) {
                this.previousImcomeTypeId = value.IncomeTypeId;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxComponent.prototype, "admin", {
        get: function () {
            if (Global.SESSION.CurrentRole.Key === 'FullAdmin' ||
                Global.SESSION.CurrentRole.Key === 'SalaryAdmin' ||
                this.sessionService.currentState === 'tabs.selfservice.general') {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    TaxComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.IncomeType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.incomeTypes = data;
            _this.changeDetectorRef.markForCheck();
        });
    };
    TaxComponent.prototype.onWarningDialogAction = function (action) {
        if (action === 'Continue') {
            this.previousImcomeTypeId = this.model.IncomeTypeId;
            this.onChange();
        }
        else {
            this.model.IncomeTypeId = this.previousImcomeTypeId;
        }
    };
    TaxComponent.prototype.onIncomeTypeChanged = function () {
        if (this.model.IncomeTypeId === 5) {
            this.warningDialogVisible = true;
            this.changeDetectorRef.markForCheck();
        }
        else {
            this.onChange();
        }
    };
    return TaxComponent;
}(FormComponentBase));
export { TaxComponent };
