import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mobile-modal-content',
  templateUrl: './MobileModalContent.component.html'
})
export class MobileModalContentComponent implements OnInit {
  constructor() {}

  private transform = false;
  ngOnInit(): void {}
}
