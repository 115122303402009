import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../../Common/Constants';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import { Global } from '../../../../Common/Global';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';
import { VacationModel } from './VacationModel';
var VacationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(VacationComponent, _super);
    function VacationComponent(staticDataService, sessionService, dataService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.sessionService = sessionService;
        _this.dataService = dataService;
        _this.ownCompanyKey = 'Self';
        _this.vacationProviderWarningDialogVisible = false;
        _this.childIsValid = Array(6).fill(true);
        _this.childIsDirty = Array(6).fill(false);
        return _this;
    }
    Object.defineProperty(VacationComponent.prototype, "model", {
        get: function () {
            if (!this.modelValue) {
                this.modelValue = {};
            }
            return this.modelValue;
        },
        set: function (value) {
            this.modelValue = value;
        },
        enumerable: true,
        configurable: true
    });
    VacationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.VacationProvider.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.vacationProviders = data); });
        this.staticDataService.Month.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            var months = [];
            var april = data ? data.find(function (month) { return month.Id === 4; }) : undefined;
            var may = data ? data.find(function (month) { return month.Id === 5; }) : undefined;
            months[0] = april;
            months[1] = may;
            _this.months = months;
        });
        if (this.IsGreenlandCompany) {
            this.staticDataService.VacationTypeGL.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.vacationTypes = data); });
        }
        else {
            this.staticDataService.VacationType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.vacationTypes = data); });
        }
        this.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (editMode) {
            if (editMode) {
                _this.originalVal = _this.model.VacationProviderId;
            }
        });
        this.isDirtyChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (dirty) {
            if (!dirty) {
                _this.model.VacationProviderId = _this.originalVal;
            }
        });
    };
    Object.defineProperty(VacationComponent.prototype, "IsNoneVacation", {
        get: function () {
            return !this.model || this.model.VacationTypeId < 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "IsPaidVacation", {
        get: function () {
            return this.model && this.model.VacationTypeId === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "IsPaidVacation_GreendLand", {
        get: function () {
            if (this.IsGreenlandCompany && this.model.VacationTypeId !== -1) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "IsVacationReduceSalaryDisable", {
        get: function () {
            return !this.model || !this.model.VacationTypeId || this.model.VacationTypeId === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "VacationExtraPercentageLabel", {
        get: function () {
            if (this.IsGreenlandCompany) {
                return 'EmployeeEmployment.EarningPercentage';
            }
            return 'EmployeeEmployment.Annual supplement';
        },
        enumerable: true,
        configurable: true
    });
    VacationComponent.prototype.onComboboxVacationTypeChangedEvent = function () {
        this.updateDefaultValueForPaidVacationType();
    };
    VacationComponent.prototype.updateDefaultValueForPaidVacationType = function () {
        if (this.model && this.model.VacationTypeId === 1) {
            // Paid vacation type.
            this.model.VacationReduceSalary = true;
            this.model.VacationSupplementPayoutMonth = this.model.VacationSupplementPayoutMonth
                ? this.model.VacationSupplementPayoutMonth
                : 5; // 5 - May.
            this.model.VacationExtraPercentage = this.model.VacationExtraPercentage
                ? this.model.VacationExtraPercentage
                : 0.01; // 1 percent.
        }
        if (this.IsGreenlandCompany && this.model && this.model.VacationTypeId === 2) {
            this.model.VacationExtraPercentage = 0.12;
        }
        this.onChange();
    };
    VacationComponent.prototype.onComboboxVacationProviderValueChange = function () {
        var _this = this;
        var vacationProvider = this.vacationProviders.find(function (model) { return model.Id === _this.model.VacationProviderId; });
        if (vacationProvider && vacationProvider.Key === this.ownCompanyKey) {
            this.vacationProviderWarningDialogVisible = true;
        }
        else {
            this.originalVal = this.model.VacationProviderId;
            this.onChange();
        }
    };
    VacationComponent.prototype.onVacationProviderWarningDialogAction = function (action) {
        if (action !== 'Proceed') {
            this.model.VacationProviderId = this.originalVal;
        }
        else {
            this.originalVal = this.model.VacationProviderId;
        }
        this.onChange();
    };
    return VacationComponent;
}(FormComponentBase));
export { VacationComponent };
