import { EventEmitter } from '@angular/core';
import { Constants } from '../../../Common/Constants';
import { Global } from '../../../Common/Global';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
var BatchFinalizationStep2DialogueComponent = /** @class */ (function () {
    function BatchFinalizationStep2DialogueComponent(settingService, sessionService) {
        this.settingService = settingService;
        this.sessionService = sessionService;
        this.stepCompletedChange = new EventEmitter();
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.finalizationExit = new EventEmitter();
    }
    Object.defineProperty(BatchFinalizationStep2DialogueComponent.prototype, "stepCompleted", {
        get: function () {
            return this.stepCompletedValue;
        },
        set: function (value) {
            var _this = this;
            if (this.stepCompletedValue !== value) {
                // this.stepCompletedValue = value;
                setTimeout(function () { return _this.stepCompletedChange.emit(value); });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogueComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            var _this = this;
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                setTimeout(function () { return _this.visibleChange.emit(value); });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogueComponent.prototype, "isClose", {
        get: function () {
            return this.visible;
        },
        set: function (value) {
            if (value) {
                this.visible = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogueComponent.prototype, "IntegrationName", {
        get: function () {
            return this.currentIntegrationName;
        },
        set: function (value) {
            this.currentIntegrationName = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogueComponent.prototype, "isHasIntegrationName", {
        get: function () {
            return !!this.IntegrationName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogueComponent.prototype, "isGreenland", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogueComponent.prototype, "eBoksModuleEnabled", {
        get: function () {
            return this.sessionService.feature.hasModuleId(9);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogueComponent.prototype, "isAccountAdmin", {
        get: function () {
            return this.sessionService.role.IsAccountAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogueComponent.prototype, "isIOSMobileApp", {
        get: function () {
            return this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogueComponent.prototype, "company", {
        get: function () {
            return Global.COMPANY;
        },
        enumerable: true,
        configurable: true
    });
    BatchFinalizationStep2DialogueComponent.prototype.onDialogAction = function (action) {
        if (action === 'Understood') {
            this.stepCompleted = true;
            this.finalizationExit.emit();
        }
        else {
            this.onCancel();
        }
    };
    BatchFinalizationStep2DialogueComponent.prototype.onCancel = function () {
        this.finalizationExit.emit();
        this.visible = false;
    };
    BatchFinalizationStep2DialogueComponent.prototype.activateDataExport = function () {
        this.sessionService.NavigateTo('tabs.company.configuration.integrations');
        //window.open("http://support.gratisal.com/hc/da/articles/208119325-Integration-med-%C3%98konomisystem", "blank");
    };
    BatchFinalizationStep2DialogueComponent.prototype.navigateToExtraTab = function () {
        this.settingService.navigationParameters['EnableEboksModule'] = true;
        this.sessionService.NavigateTo('tabs.company.modules');
        this.onCancel();
    };
    BatchFinalizationStep2DialogueComponent.prototype.setStartupTaskDone = function () {
        var task = Global.STARTUP_TASKS.find(function (startupTask) { return startupTask.Key === 'NetsAgreement'; });
        if (!task.StatusId) {
            this.navigateToTask(task);
            this.onCancel();
            return;
        }
        this.sessionService.NavigateTo('tabs.company.general');
        this.onCancel();
    };
    BatchFinalizationStep2DialogueComponent.prototype.openTabLink = function () {
        window.open('http://support.gratisal.com/hc/da/articles/208119325-Integration-med-%C3%98konomisystem', '_blank');
    };
    BatchFinalizationStep2DialogueComponent.prototype.navigateToTask = function (task) {
        if (task) {
            this.settingService.navigationStartUpTask = task;
            if (!this.sessionService.NavigateToAppUri(task.AppUri)) {
                this.settingService.showNavigationMessage();
            }
        }
    };
    return BatchFinalizationStep2DialogueComponent;
}());
export { BatchFinalizationStep2DialogueComponent };
