import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, EventEmitter } from '@angular/core';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { UniqueId } from '../Common/UniqueId';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
import { NumericTextBoxOptions } from './NumericTextBoxOptions';
var NumericEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NumericEditComponent, _super);
    function NumericEditComponent(elementRef, sessionService, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.sessionService = sessionService;
        _this.validationMessage = '';
        _this.option = { min: 0, step: 1, spinners: false };
        _this.disable = false;
        _this.isSpecialLabel = false;
        _this.helpTooltip = '';
        _this.helpIcon = 'Help';
        _this.isOpenLink = true;
        _this.isNotNegativeValue = false;
        _this.maxHeight20 = '';
        _this.blur = new EventEmitter();
        _this.componentId = 'NumericTextBoxComponent' + UniqueId();
        return _this;
    }
    NumericEditComponent.prototype.valid = function () {
        if (this.required && (!this.value || this.value.length === 0)) {
            return false;
        }
        if (this.value && !this.sessionService.parseFloat(this.value)) {
            return false;
        }
        return true;
    };
    NumericEditComponent.prototype.onBlur = function () {
        this.blur.emit();
    };
    NumericEditComponent.prototype.onvalueChange = function (value) {
        if (value && value < 0 && this.isNotNegativeValue) {
            value = value * -1;
            this.value = value;
        }
    };
    return NumericEditComponent;
}(ControlComponentBase));
export { NumericEditComponent };
