import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Browser } from '../../Common/Browser';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { IStartupTaskCompanyView } from '../ApiModel';
import { Broadcaster } from '../Broadcaster';
import { ModalService } from './ModalService';

@Injectable()
export class SettingService {
  public showFadingMessage: {
    [key: string]: {
      showMessage: boolean;
      uid: string;
      fieldName: string;
    };
  } = {};

  public navigationParameters: { [key: string]: any } = {};
  public navigationStartUpTask: IStartupTaskCompanyView;
  public isShowReadMoreWelcomMessage = false;
  public showCompanySelector = false;
  public apiVersion: string;
  public isReload = true;
  public stayEmploymentId: number;

  constructor(
    private translateService: TranslateService,
    private modalService: ModalService,
    private broadcaster: Broadcaster
  ) {}

  private get isIOSMobileApp(): boolean {
    const browser: Browser = new Browser();
    return browser.iOSMobileDevice;
  }

  public updateSettingsForEmployeeCompany(tasks: IStartupTaskCompanyView[]): void {
    let companyTaskCompleted = false;
    let employeeTaskCompleted = false;

    if (tasks.length > 0) {
      const companyTask: IStartupTaskCompanyView = tasks
        ? tasks.find((com: IStartupTaskCompanyView) => com.Key === 'CompanyData')
        : undefined;
      const employeeTask: IStartupTaskCompanyView = tasks
        ? tasks.find((emp: IStartupTaskCompanyView) => emp.Key === 'CreateEmployees')
        : undefined;
      companyTaskCompleted = companyTask && companyTask.StatusId === 1 ? true : false;
      employeeTaskCompleted = employeeTask && employeeTask.StatusId === 1 ? true : false;
    }

    // Save settings.
    Global.IsCompanyDataCompleted = companyTaskCompleted;
    Global.IsEmployeeTaskCompleted = employeeTaskCompleted;
  }

  private betaModuleTitle: string;
  private betaModuleMessage: string;
  private welcomeTitles: { [key: string]: string } = {};
  private welcomeMessages: { [key: string]: string } = {};

  public applyTranslations(languageCode?: string): void {
    const langCode: string = languageCode
      ? languageCode
      : Global.SESSION && Global.SESSION.SignOnToken.Language
      ? Global.SESSION.SignOnToken.Language.CultureCode.split('-')[0]
      : undefined;
    if (this.translateService.currentLang !== langCode) {
      this.translateService.use(langCode).subscribe(() => {
        if (!languageCode) {
          this.translateApplicationText();
        }
      });
    } else {
      if (!languageCode) {
        this.translateApplicationText();
      }
    }
  }

  private translateApplicationText(): void {
    this.translateService
      .get([
        'Beta.BetaTitle',
        'Beta.BetaModuleWarning',
        'EmployeeGeneral.WelcomeTitle',
        'EmployeeGeneral.WelcomeMessage',
        'EmployeeGeneral.WelcomeEmployeeMessage',
        'EmployeeEmployment.WelcomeTitle',
        'EmployeeEmployment.WelcomeMessage',
        'EmploymentPayrollData.WelcomeTitle',
        'EmploymentPayrollData.WelcomeMessage',
        'EmploymentTime.WelcomeTitle',
        'EmploymentTime.WelcomeMessage',
        'EmployeePayslip.WelcomeTitle',
        'EmployeePayslip.WelcomeMessage',
        'AccountModules.WelcomeTitle',
        'AccountModules.WelcomeMessage',
        'CompanyGeneral.WelcomeTitle',
        'CompanyGeneral.WelcomeMessage',
        'CompanyHiringStatus.WelcomeTitle',
        'CompanyHiringStatus.WelcomeMessage',
        'CompanySalaryBatches.WelcomeTitle',
        'CompanySalaryBatches.WelcomeMessage',
        'CompanySalaryTypes.WelcomeTitle',
        'CompanySalaryTypes.WelcomeMessage',
        'CompanyTimeEntryTypes.WelcomeTitle',
        'CompanyTimeEntryTypes.WelcomeMessage',
        'SelfServicePayslip.WelcomeTitle',
        'SelfServicePayslip.WelcomeMessage',
        'Timeentrytypes.WelcomeTitle',
        'Timeentrytypes.WelcomeMessage',
        'Integrations.WelcomeTitle',
        'Integrations.WelcomeMessage',
        'GlobalDialog.Close',
        'GlobalDialog.ReadMore',
        'Login.UpdatedGratisalMessageUrl'
      ])
      .subscribe((translations: { [key: string]: string }) => {
        this.betaModuleTitle = translations['Beta.BetaTitle'];
        this.betaModuleMessage = translations['Beta.BetaModuleWarning'];

        this.welcomeTitles['Employee.General'] = translations['EmployeeGeneral.WelcomeTitle'];
        this.welcomeMessages['Employee.General'] = translations['EmployeeGeneral.WelcomeMessage'];
        this.welcomeMessages['EmployeeGeneral.WelcomeEmployeeMessage'] =
          translations['EmployeeGeneral.WelcomeEmployeeMessage'];
        this.welcomeTitles['Employee.Employment'] = translations['EmployeeEmployment.WelcomeTitle'];
        this.welcomeMessages['Employee.Employment'] = translations['EmployeeEmployment.WelcomeMessage'];
        this.welcomeTitles['Employee.Time'] = translations['EmploymentTime.WelcomeTitle'];
        this.welcomeMessages['Employee.Time'] = translations['EmploymentTime.WelcomeMessage'];
        this.welcomeTitles['Employee.PayrollData'] = translations['EmploymentPayrollData.WelcomeTitle'];
        this.welcomeMessages['Employee.PayrollData'] = translations['EmploymentPayrollData.WelcomeMessage'];
        this.welcomeTitles['Employee.Payslip'] = translations['EmployeePayslip.WelcomeTitle'];
        this.welcomeMessages['Employee.Payslip'] = translations['EmployeePayslip.WelcomeMessage'];

        this.welcomeTitles['Company.General'] = translations['CompanyGeneral.WelcomeTitle'];
        this.welcomeMessages['Company.General'] = translations['CompanyGeneral.WelcomeMessage'];
        this.welcomeTitles['Company.Templates'] = translations['CompanyHiringStatus.WelcomeTitle'];
        this.welcomeMessages['Company.Templates'] = translations['CompanyHiringStatus.WelcomeMessage'];
        this.welcomeTitles['Company.SalaryBatches'] = translations['CompanySalaryBatches.WelcomeTitle'];
        this.welcomeMessages['Company.SalaryBatches'] = translations['CompanySalaryBatches.WelcomeMessage'];
        this.welcomeTitles['Company.SalaryTypes'] = translations['CompanySalaryTypes.WelcomeTitle'];
        this.welcomeMessages['Company.SalaryTypes'] = translations['CompanySalaryTypes.WelcomeMessage'];
        this.welcomeTitles['Company.TimeEntryTypes'] = translations['CompanyTimeEntryTypes.WelcomeTitle'];
        this.welcomeMessages['Company.TimeEntryTypes'] = translations['CompanyTimeEntryTypes.WelcomeMessage'];

        this.welcomeTitles['Account.Modules'] = translations['AccountModules.WelcomeTitle'];
        this.welcomeMessages['Account.Modules'] = translations['AccountModules.WelcomeMessage'];

        this.welcomeTitles['SelfService.Payslip'] = translations['SelfServicePayslip.WelcomeTitle'];
        this.welcomeMessages['SelfService.Payslip'] = translations['SelfServicePayslip.WelcomeMessage'];

        this.welcomeTitles['company.timeentrytypes'] = translations['Timeentrytypes.WelcomeTitle'];
        this.welcomeMessages['company.timeentrytypes'] = translations['Timeentrytypes.WelcomeMessage'];
        this.welcomeTitles['company.integrations'] = translations['Integrations.WelcomeTitle'];
        this.welcomeMessages['company.integrations'] = translations['Integrations.WelcomeMessage'];
        this.welcomeTitles['company.advancedsalarytypes'] = translations['CompanySalaryTypes.WelcomeTitle'];
        this.welcomeMessages['company.advancedsalarytypes'] = translations['CompanySalaryTypes.WelcomeMessage'];
        this.welcomeMessages['Login.UpdatedGratisalMessageUrl'] = translations['Login.UpdatedGratisalMessageUrl'];

        if (this.moduleMessageQueue && this.moduleMessageQueue.length > 0) {
          this.moduleMessageQueue.forEach((moduleMessage: any) => {
            this.showModuleMessage(moduleMessage.moduleName, moduleMessage.overideMessage);
          });

          this.moduleMessageQueue = [];
        }
      });
  }

  showDoubleClickToEditHint(show: boolean): void {
    const snackBar: any = document.getElementById('divHintForEdit');
    if (!snackBar) {
      return;
    }

    if (show) {
      snackBar.className = 'show';
      setTimeout(() => {
        snackBar.className = snackBar.className.replace('show', '');
      }, 5000);
    } else {
      snackBar.className = snackBar.className.replace('show', '');
    }
  }

  private moduleMessageQueue: any[] = [];

  public moduleMessageDialogVisible: boolean;
  public moduleMessageDialogTitle: string;
  public moduleMessage: string;
  private moduleName: string;

  public onModuleMessageDialogAction(action: string): void {
    if (action === 'ReadMore') {
      window.open(this.welcomeMessages['Login.UpdatedGratisalMessageUrl'], '_blank');
    }

    ////this.showFlipScreenHint(this.moduleName);
    this.broadcaster.broadcast(Constants.SHOW_EDIT_HINT);
  }

  public showModuleMessage(moduleName: string, overideMessage?: string): void {
    if (moduleName) {
      if (moduleName === 'ShowUpdateNotification') {
        this.isShowReadMoreWelcomMessage = true;
      } else {
        this.isShowReadMoreWelcomMessage = false;
      }
    }

    const welcomeSettings: any = Global.WELCOME_MESSAGE_SETTINGS;

    if (!this.welcomeMessages[moduleName] && !overideMessage) {
      this.moduleMessageQueue.push({ moduleName, overideMessage });
      return;
    }

    let isAlertShown = false;
    let moduleMessage: string = overideMessage ? overideMessage : this.welcomeMessages[moduleName];
    if (welcomeSettings && welcomeSettings[moduleName] && moduleMessage) {
      if (moduleName === 'Employee.General' && Global.SESSION) {
        if (Global.SESSION.CurrentRole.Key === 'Employee') {
          moduleMessage = this.welcomeMessages['EmployeeGeneral.WelcomeEmployeeMessage']
            ? this.welcomeMessages['EmployeeGeneral.WelcomeEmployeeMessage']
            : moduleMessage;
        } else {
          moduleMessage = this.welcomeMessages[moduleName] ? this.welcomeMessages[moduleName] : moduleMessage;
        }
      }

      isAlertShown = true;

      setTimeout(() => {
        this.moduleName = moduleName;
        this.moduleMessageDialogTitle = this.welcomeTitles[moduleName];
        this.moduleMessage = moduleMessage;
        this.moduleMessageDialogVisible = true;
        welcomeSettings[moduleName] = false;
        Global.WELCOME_MESSAGE_SETTINGS = welcomeSettings;
      });

      // Save back the updated setting.
      // Global.WELCOME_MESSAGE_SETTINGS = welcomeSettings;
    }

    isAlertShown = isAlertShown || this.showBetaMessage(moduleName);
    isAlertShown = isAlertShown || this.showNavigationMessage();
    if (!isAlertShown) {
      ////this.showFlipScreenHint(moduleName);
    }
  }

  private showBetaMessage(moduleName: string): boolean {
    let isAlertShown = false;
    const betaModuleSettings: any = Global.BETA_MODULE_MESSAGE_SETTINGS;
    if (betaModuleSettings && betaModuleSettings[moduleName] && this.betaModuleMessage) {
      isAlertShown = true;
      betaModuleSettings[moduleName] = false;
      this.modalService.alert(this.betaModuleTitle, this.betaModuleMessage);
      ////this.showFlipScreenHint(moduleName);

      // Save back the updated setting.
      Global.BETA_MODULE_MESSAGE_SETTINGS = betaModuleSettings;
    }

    return isAlertShown;
  }

  public navigationMessageDialogVisible: boolean;
  public navigationMessage: string;
  public onNavigationMessageDialogAction(action: string): void {
    if (action === 'MoreHelp') {
      this.navigateToExternalLink(this.navigationStartUpTask.HelpLink);
    }
  }

  showNavigationMessage(): boolean {
    let isAlertShown = false;
    if (!this.navigationStartUpTask) {
      return isAlertShown;
    }

    const task: IStartupTaskCompanyView = this.navigationStartUpTask;
    if (task.ExternalLink) {
      if (task.GuideMessage) {
        isAlertShown = true;
        this.navigationMessage = task.GuideMessage;
        this.navigationMessageDialogVisible = true;
      } else {
        this.navigateToExternalLink(task.ExternalLink);
      }
    } else if (task.GuideMessage) {
      isAlertShown = true;
      this.navigationMessage = task.GuideMessage;
      this.navigationMessageDialogVisible = true;
    }

    this.navigationStartUpTask = undefined;
    return isAlertShown;
  }

  private navigateToExternalLink(link: string): void {
    if (!this.isIOSMobileApp) {
      window.open(link);
    }
  }
}
