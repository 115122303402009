<div [ngClass]="demo ? 'Footer withDemo' : 'Footer'">
  <div class="Footer-info {{ widthDestopFooter }}">
    <div *ngIf="!isPayrolldataMobile" class="Footer-feedback">
      <span *ngIf="demo" class="DemoPanel">
        *** DEMO VERSION ***
      </span>

      <span class="feedback">{{ 'Feedback.Improve?' | translate }}</span>
      <button class="Link" type="button" (click)="feedbackDialogVisible = true">
        {{ 'Feedback.Link' | translate }}
      </button>
      <span *ngIf="!isMobile" class="Footer-version feedback-panel-big-screen">
        API: {{ apiVersion }} - Client: {{ clientVersion }} - Company ID: {{ companyId }} - User ID: {{ userId }} -
        Country: {{ country }} - AccountId: {{ accountId }}
      </span>
      <span class="Footer-version feedback-panel-small-screen">
        API: {{ apiVersion }} - Client: {{ clientVersion }}
      </span>
    </div>
    <div *ngIf="demo" class="DemoPanel hide">
      *** DEMO VERSION ***
    </div>
  </div>
</div>

<app-dialog [overlayClose]="false" [(visible)]="feedbackDialogVisible" (action)="onAction($event)">
  <div class="Modal-content">
    <div class="Modal-header" id="feedbackBrief" innerHTML="{{ 'Feedback.Brief' | translate }}"></div>
    <div class="FormElement">
      <label class="FormElement-label" for="feedbackText">Feedback</label>
      <div class="FormElement-control">
        <textarea id="feedbackText" class="FormElement-textarea" [(ngModel)]="feedbackText"></textarea>
      </div>
    </div>
  </div>
  <app-dialog-action
    [disabled]="sessionService.isSubmitting"
    action="Submit"
    label="{{ 'Feedback.Submit' | translate }}"
  ></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="successDialogVisible">
  {{ 'Feedback.Success' | translate }}
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
