import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Constants } from '../../../Common/Constants';
import { Global } from '../../../Common/Global';
import { ICompany, ISalaryBatch, IStartupTaskCompanyView } from '../../../Services/ApiModel';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';

@Component({
  selector: 'batch-finalization-step-2-dialogue',
  templateUrl: './BatchFinalizationStep2Dialogue.component.html'
})
export class BatchFinalizationStep2DialogueComponent {
  @Input() public salarybatch: ISalaryBatch;

  private stepCompletedValue: boolean;
  @Input()
  public get stepCompleted(): boolean {
    return this.stepCompletedValue;
  }
  public set stepCompleted(value: boolean) {
    if (this.stepCompletedValue !== value) {
      // this.stepCompletedValue = value;
      setTimeout(() => this.stepCompletedChange.emit(value));
    }
  }
  @Output() public stepCompletedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      setTimeout(() => this.visibleChange.emit(value));
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public get isClose(): boolean {
    return this.visible;
  }
  public set isClose(value: boolean) {
    if (value) {
      this.visible = false;
    }
  }

  @Output() public finalizationExit: EventEmitter<void> = new EventEmitter<void>();

  private currentIntegrationName: string;
  @Input() public get IntegrationName(): string {
    return this.currentIntegrationName;
  }

  public set IntegrationName(value: string) {
    this.currentIntegrationName = value;
  }

  public get isHasIntegrationName(): boolean {
    return !!this.IntegrationName;
  }

  public get isGreenland(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get eBoksModuleEnabled(): boolean {
    return this.sessionService.feature.hasModuleId(9);
  }
  public get isAccountAdmin(): boolean {
    return this.sessionService.role.IsAccountAdmin;
  }
  public get isIOSMobileApp(): boolean {
    return this.sessionService.browser.iOSMobileDevice;
  }
  public get company(): ICompany {
    return Global.COMPANY;
  }

  constructor(private settingService: SettingService, private sessionService: SessionService) {}

  public onDialogAction(action: string): void {
    if (action === 'Understood') {
      this.stepCompleted = true;
      this.finalizationExit.emit();
    } else {
      this.onCancel();
    }
  }

  public onCancel(): void {
    this.finalizationExit.emit();
    this.visible = false;
  }

  public activateDataExport(): void {
    this.sessionService.NavigateTo('tabs.company.configuration.integrations');
    //window.open("http://support.gratisal.com/hc/da/articles/208119325-Integration-med-%C3%98konomisystem", "blank");
  }

  public navigateToExtraTab(): void {
    this.settingService.navigationParameters['EnableEboksModule'] = true;
    this.sessionService.NavigateTo('tabs.company.modules');
    this.onCancel();
  }

  public setStartupTaskDone(): void {
    const task: IStartupTaskCompanyView = Global.STARTUP_TASKS.find(
      (startupTask: IStartupTaskCompanyView) => startupTask.Key === 'NetsAgreement'
    );
    if (!task.StatusId) {
      this.navigateToTask(task);
      this.onCancel();
      return;
    }

    this.sessionService.NavigateTo('tabs.company.general');
    this.onCancel();
  }

  public openTabLink(): void {
    window.open('http://support.gratisal.com/hc/da/articles/208119325-Integration-med-%C3%98konomisystem', '_blank');
  }

  private navigateToTask(task: IStartupTaskCompanyView): void {
    if (task) {
      this.settingService.navigationStartUpTask = task;
      if (!this.sessionService.NavigateToAppUri(task.AppUri)) {
        this.settingService.showNavigationMessage();
      }
    }
  }
}
