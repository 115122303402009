import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ISalaryBatchEmployeeTotal,
  ISalaryBatchUnitTotal,
  ISalaryTypeSumsByEmploymentView
} from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'salary-batch-time-vacation',
  templateUrl: './SalaryBatchTimeAndVacation.component.html'
})
export class SalaryBatchTimeAndVacationComponent {
  @Input()
  public get SalaryBatchId(): number {
    return this.salaryBatchIdValue;
  }
  public set SalaryBatchId(value: number) {
    if (this.salaryBatchIdValue !== value) {
      this.salaryBatchIdValue = value;
      if (value) {
        this.getTotals();
      }
    }
  }

  @Input()
  public get UnitTotals(): any[] {
    return this.unitTotalsValue;
  }

  public set UnitTotals(value: any[]) {
    if (value !== this.unitTotalsValue) {
      this.unitTotalsValue = [];
      this.unitTotalsValue = value;
    }
  }

  public selectedUnitTotals: ISalaryBatchUnitTotal;
  public unitTotalsPerEmployee: ISalaryBatchEmployeeTotal[] = [];
  private salaryBatchIdValue: number;
  private unitTotalsValue: ISalaryBatchUnitTotal[] = [];

  constructor(private dataService: RxDataService, private sessionService: SessionService) {}

  private getTotals(): void {
    this.unitTotalsPerEmployee = [];
    this.dataService
      .SalaryBatches_GetSalaryBatchUnitTotals(this.SalaryBatchId)
      .subscribe((data: ISalaryBatchUnitTotal[]) => {
        this.UnitTotals = data;
        if (data.length > 0) {
          this.selectedUnitTotals = data[0];
          this.onSelectedUnitTotalChange(this.selectedUnitTotals);
        }
      });
  }

  public onSelectedUnitTotalChange(data: ISalaryBatchUnitTotal): void {
    this.dataService
      .SalaryBatches_GetUnitTotalsByEmployee(this.SalaryBatchId, data.Key)
      .subscribe((perEmployee: ISalaryBatchEmployeeTotal[]) => {
        this.unitTotalsPerEmployee = perEmployee;
      });
  }

  public onDoubleClick(data: ISalaryTypeSumsByEmploymentView): void {
    if (data && data.UserEmploymentId) {
      this.sessionService.currentUserEmploymentId = data.UserEmploymentId;
      this.sessionService.NavigateTo('tabs.employee.payrolldata');
    }
  }
}
