import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISalaryType } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { OverviewDisplayModeEnum } from '../../Model/Enum';
var SalaryTypeConfigurationDialogComponent = /** @class */ (function () {
    function SalaryTypeConfigurationDialogComponent(dataService, staticDataService) {
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.changeConfigurationCompleted = new EventEmitter();
        this.visibleChange = new EventEmitter();
        this.replaceSalaryType = {};
        this.overviewDisplayModes = [];
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(SalaryTypeConfigurationDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
                this.onSalaryTypeChange(this.selectedSalaryType ? this.selectedSalaryType.Id : undefined);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryTypeConfigurationDialogComponent.prototype, "selectedSalaryType", {
        get: function () {
            return this.selectedSalaryTypeValue;
        },
        set: function (value) {
            this.selectedSalaryTypeValue = value;
            this.onSalaryTypeChange(value ? value.Id : undefined);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryTypeConfigurationDialogComponent.prototype, "salaryTypes", {
        get: function () {
            return this.salaryTypesDataSource;
        },
        set: function (value) {
            this.salaryTypesDataSource = value;
            this.onSalaryTypeChange(this.selectedSalaryType ? this.selectedSalaryType.Id : undefined);
        },
        enumerable: true,
        configurable: true
    });
    SalaryTypeConfigurationDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.SalaryRecordsOverviewDisplayMode.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.overviewDisplayModes = data); });
    };
    SalaryTypeConfigurationDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SalaryTypeConfigurationDialogComponent.prototype.onSalaryTypeChange = function (salaryTypeId) {
        this.replaceSalaryTypeId = salaryTypeId;
        this.replaceSalaryType =
            this.salaryTypes && salaryTypeId ? this.salaryTypes.find(function (s) { return s.Id === salaryTypeId; }) : {};
        if (this.replaceSalaryType) {
            this.replaceSalaryType.OverviewDisplayMode = this.replaceSalaryType.OverviewDisplayMode
                ? this.replaceSalaryType.OverviewDisplayMode
                : OverviewDisplayModeEnum.AmountOnly;
        }
    };
    SalaryTypeConfigurationDialogComponent.prototype.onDialogAction = function (action) {
        var _this = this;
        if (action === 'Ok' &&
            this.salaryTypes &&
            this.replaceSalaryTypeId &&
            this.replaceSalaryType &&
            (!this.selectedSalaryType || this.replaceSalaryTypeId !== this.selectedSalaryType.Id)) {
            this.replaceSalaryType.OverviewIndex = this.selectedSalaryType
                ? this.selectedSalaryType.OverviewIndex
                : this.maxIndex;
            if (!this.selectedSalaryType) {
                this.dataService.SalaryTypes_UpdateSalaryType(this.replaceSalaryType).subscribe(function () {
                    _this.onChangeConfigurationCompleted();
                }, function () {
                    _this.onChangeConfigurationCompleted();
                });
            }
            else {
                // this.selectedSalaryType.OverviewIndex = undefined;
                this.dataService
                    .SalaryTypes_ConfigureOverviewBehavior(this.replaceSalaryTypeId, this.selectedSalaryType.OverviewIndex, this.replaceSalaryType.OverviewDisplayMode)
                    .subscribe(function (data) {
                    _this.onChangeConfigurationCompleted();
                });
                // observableForkJoin(
                //     this.dataService.SalaryTypes_UpdateSalaryType(this.selectedSalaryType),
                //     this.dataService.SalaryTypes_UpdateSalaryType(this.replaceSalaryType)
                // ).subscribe(
                //     () => { this.onChangeConfigurationCompleted(); },
                //     () => { this.onChangeConfigurationCompleted(); }
                // );
            }
        }
        else {
            this.visible = false;
        }
    };
    SalaryTypeConfigurationDialogComponent.prototype.onChangeConfigurationCompleted = function () {
        this.changeConfigurationCompleted.emit();
        this.visible = false;
    };
    return SalaryTypeConfigurationDialogComponent;
}());
export { SalaryTypeConfigurationDialogComponent };
