/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./Icon/Icon.component.ngfactory";
import * as i3 from "./Icon/Icon.component";
import * as i4 from "../Services/SharedServices/Session/SessionService";
import * as i5 from "@progress/kendo-angular-l10n";
import * as i6 from "@progress/kendo-angular-tooltip";
import * as i7 from "@progress/kendo-angular-popup";
import * as i8 from "@angular/common";
import * as i9 from "./PayslipIcon.component";
var styles_PayslipIconComponent = [];
var RenderType_PayslipIconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PayslipIconComponent, data: {} });
export { RenderType_PayslipIconComponent as RenderType_PayslipIconComponent };
function View_PayslipIconComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [], [[8, "title", 0]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(2, 0, null, null, 2, "app-icon", [], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i0.ɵdid(3, 49152, null, 0, i3.IconComponent, [i0.ElementRef, i4.SessionService, i0.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"] }, null), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = "html-outline"; var currVal_2 = i0.ɵunv(_v, 3, 1, i0.ɵnov(_v, 4).transform("EmploymentPayrollData.HTMLPopupToolTip")); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 1).transform("EmploymentPayrollData.HTMLPopupToolTip")), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_PayslipIconComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "button", [["kendoTooltip", ""], ["type", "button"]], [[8, "title", 0], [1, "dir", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(256, null, i5.L10N_PREFIX, "kendo.tooltip", []), i0.ɵprd(131584, null, i5.LocalizationService, i5.LocalizationService, [i5.L10N_PREFIX, [2, i5.MessageService], [2, i5.RTL]]), i0.ɵdid(3, 9125888, null, 0, i6.TooltipDirective, [i0.ElementRef, i0.NgZone, i0.Renderer2, i7.PopupService, i5.LocalizationService, [2, i6.TooltipSettings], [2, i6.TOOLTIP_SETTINGS]], null, null), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 4, "span", [], [[8, "title", 0]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 2, "app-icon", [], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i0.ɵdid(8, 49152, null, 0, i3.IconComponent, [i0.ElementRef, i4.SessionService, i0.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"] }, null), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 3, 0); var currVal_3 = "html"; var currVal_4 = i0.ɵunv(_v, 8, 1, i0.ɵnov(_v, 9).transform("EmploymentPayrollData.HTMLFileToolTip")); _ck(_v, 8, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 4).transform("EmploymentPayrollData.HTMLFileToolTip")), ""); var currVal_1 = i0.ɵnov(_v, 3).direction; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("EmploymentPayrollData.HTMLFileToolTip")), ""); _ck(_v, 5, 0, currVal_2); }); }
function View_PayslipIconComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "button", [["kendoTooltip", ""], ["type", "button"]], [[8, "title", 0], [1, "dir", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(256, null, i5.L10N_PREFIX, "kendo.tooltip", []), i0.ɵprd(131584, null, i5.LocalizationService, i5.LocalizationService, [i5.L10N_PREFIX, [2, i5.MessageService], [2, i5.RTL]]), i0.ɵdid(3, 9125888, null, 0, i6.TooltipDirective, [i0.ElementRef, i0.NgZone, i0.Renderer2, i7.PopupService, i5.LocalizationService, [2, i6.TooltipSettings], [2, i6.TOOLTIP_SETTINGS]], null, null), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 4, "span", [], [[8, "title", 0]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 2, "app-icon", [], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i0.ɵdid(8, 49152, null, 0, i3.IconComponent, [i0.ElementRef, i4.SessionService, i0.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"] }, null), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 3, 0); var currVal_3 = "pdf"; var currVal_4 = i0.ɵunv(_v, 8, 1, i0.ɵnov(_v, 9).transform("CompanySalaryBatches.PaySlipPDFTooltip")); _ck(_v, 8, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 4).transform("CompanySalaryBatches.PaySlipPDFTooltip")), ""); var currVal_1 = i0.ɵnov(_v, 3).direction; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("CompanySalaryBatches.PaySlipPDFTooltip")), ""); _ck(_v, 5, 0, currVal_2); }); }
export function View_PayslipIconComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "span", [], null, null, null, null, null)), i0.ɵprd(512, null, i8.ɵNgClassImpl, i8.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i8.NgClass, [i8.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 6, "button", [["kendoTooltip", ""], ["type", "button"]], [[8, "title", 0], [1, "dir", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(256, null, i5.L10N_PREFIX, "kendo.tooltip", []), i0.ɵprd(131584, null, i5.LocalizationService, i5.LocalizationService, [i5.L10N_PREFIX, [2, i5.MessageService], [2, i5.RTL]]), i0.ɵdid(6, 9125888, null, 0, i6.TooltipDirective, [i0.ElementRef, i0.NgZone, i0.Renderer2, i7.PopupService, i5.LocalizationService, [2, i6.TooltipSettings], [2, i6.TOOLTIP_SETTINGS]], null, null), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PayslipIconComponent_1)), i0.ɵdid(9, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PayslipIconComponent_2)), i0.ɵdid(11, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PayslipIconComponent_3)), i0.ɵdid(13, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isHidden ? "Icon--Payslip--hide" : ""); _ck(_v, 2, 0, currVal_0); _ck(_v, 6, 0); var currVal_3 = _co.showInCurrentTab; _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.showInNewTab && _co.IsWeb); _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.showPDF; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 7).transform("EmploymentPayrollData.HTMLPopupToolTip")), ""); var currVal_2 = i0.ɵnov(_v, 6).direction; _ck(_v, 3, 0, currVal_1, currVal_2); }); }
export function View_PayslipIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "payslip-icon", [], null, null, null, View_PayslipIconComponent_0, RenderType_PayslipIconComponent)), i0.ɵdid(1, 49152, null, 0, i9.PayslipIconComponent, [i4.SessionService], null, null)], null, null); }
var PayslipIconComponentNgFactory = i0.ɵccf("payslip-icon", i9.PayslipIconComponent, View_PayslipIconComponent_Host_0, { payslipIconType: "payslipIconType", isHidden: "isHidden" }, { click: "click" }, []);
export { PayslipIconComponentNgFactory as PayslipIconComponentNgFactory };
