import { EventEmitter } from '@angular/core';
var CompanyTemplateListComponent = /** @class */ (function () {
    function CompanyTemplateListComponent() {
        this.locked = false;
        this.currentEditmode = false;
        this.selectedIdChange = new EventEmitter();
        this.selectionChange = new EventEmitter();
    }
    Object.defineProperty(CompanyTemplateListComponent.prototype, "isEditMode", {
        get: function () {
            return this.currentEditmode;
        },
        set: function (value) {
            var _this = this;
            setTimeout(function () {
                if (_this.templates && _this.templates.length > 0) {
                    if (value) {
                        _this.templates.forEach(function (model) {
                            model.isActiveFeild = model.Id === _this.selectedId ? 'activeCell' : '';
                        });
                        _this.refreshList = true;
                    }
                    else {
                        _this.templates.forEach(function (model) {
                            model.isActiveFeild = '';
                        });
                    }
                }
            });
            this.currentEditmode = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplateListComponent.prototype, "selectedId", {
        get: function () {
            return this.selectedIdValue;
        },
        set: function (value) {
            var _this = this;
            if (this.selectedIdValue !== value) {
                this.selectedIdValue = value;
                setTimeout(function () { return _this.selectedIdChange.emit(value); });
                // if (!this.isEditMode) {
                //     setTimeout(() => this.selectedIdChange.emit(value));
                // }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplateListComponent.prototype, "refreshList", {
        get: function () {
            return this.refreshListValue;
        },
        set: function (value) {
            // if (this.refreshListValue !== value) {
            //     this.refreshListValue = value;
            //     this.triggerGridRefresh = true;
            // }
            if (value) {
                this.triggerGridRefresh = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    CompanyTemplateListComponent.prototype.onSelectionChange = function (template) {
        // setTimeout(() => this.selectedIdChange.emit(template ? template.Id : undefined));
        this.selectedIdChange.emit(template ? template.Id : undefined);
        this.selectionChange.emit(template);
    };
    Object.defineProperty(CompanyTemplateListComponent.prototype, "classDisableSelect", {
        get: function () {
            if (this.isEditMode) {
                return 'disableSelect';
            }
            return 'GridSelect';
        },
        enumerable: true,
        configurable: true
    });
    return CompanyTemplateListComponent;
}());
export { CompanyTemplateListComponent };
