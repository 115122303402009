import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { Global } from '../../../Common/Global';
import { Department } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
var CompanyTemplatesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyTemplatesComponent, _super);
    function CompanyTemplatesComponent(dataService, settingService, sessionService, staticDataService, transitionService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.transitionService = transitionService;
        _this.currentTriggerEditmodeChange = false;
        _this.ModelDepartment = new Department();
        _this.isEnable = false;
        _this.hasTemplateModule = false;
        _this.hasDepartmentModule = false;
        _this.addNewDialogVisible = false;
        _this.isPayrollGridVisible = false;
        _this.targetEventName = '';
        _this.isSeniorityGridVisible = false;
        _this.isOvertimeGridVisible = false;
        _this.deleteConfirmationDialogVisible = false;
        _this.discardChangesDialogVisible = false;
        _this.confirmUpdateDialogVisible = false;
        _this.updateTemplateWarningDialogVisible = false;
        _this.showInvalidFormWarning = false;
        _this.showSaveDataConfirmationChangeState = false;
        _this.AddDepartmentVisible = false;
        _this.refreshTemplateGridList = false;
        _this.childIsValid = Array(14).fill(true);
        _this.childIsDirty = Array(17).fill(false);
        return _this;
    }
    Object.defineProperty(CompanyTemplatesComponent.prototype, "selectedTemplateId", {
        get: function () {
            return this.selectedTemplateIdValue;
        },
        set: function (value) {
            if (value && this.selectedTemplateIdValue !== value) {
                this.selectedTemplateIdValue = value;
                this.getEmploymentTemplate();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplatesComponent.prototype, "templateDetail", {
        get: function () {
            if (this.templateDetailValue === undefined) {
                return {};
            }
            return this.templateDetailValue;
        },
        set: function (value) {
            this.templateDetailValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplatesComponent.prototype, "TriigerEditMode", {
        get: function () {
            return this.currentTriggerEditmodeChange;
        },
        set: function (value) {
            if (this.currentTriggerEditmodeChange !== value) {
                this.currentTriggerEditmodeChange = value;
            }
            if (!value && this.isDirty) {
                this.onSave();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplatesComponent.prototype, "IsDisableCreateDepartment", {
        get: function () {
            return this.sessionService.role.IsReadOnly || this.sessionService.role.IsSalaryAdmin;
        },
        enumerable: true,
        configurable: true
    });
    CompanyTemplatesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.SalaryCycle.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.salaryCycles = data); });
        this.staticDataService.departments
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) { return (_this.departments = data); });
        this.getTemplates();
        this.settingService.showModuleMessage('Company.Templates');
        this.getCompanyContacts();
    };
    CompanyTemplatesComponent.prototype.getCompanyContacts = function () {
        var _this = this;
        this.staticDataService.companyUsers
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (companyUsers) {
            _this.companyContacts = companyUsers;
        });
    };
    Object.defineProperty(CompanyTemplatesComponent.prototype, "has1stPensionProvider", {
        get: function () {
            return this.templateDetail && this.templateDetail.PensionProviderId ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplatesComponent.prototype, "has2ndPensionProvider", {
        get: function () {
            return this.templateDetail && this.templateDetail.Pension2ProviderId ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplatesComponent.prototype, "isAddIconVisible", {
        get: function () {
            return this.sessionService.role.IsSalaryAdminAndHigher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplatesComponent.prototype, "isVacationDisable", {
        get: function () {
            return this.SecondaryIncomeType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplatesComponent.prototype, "isAdditionalVacationDaysDisable", {
        get: function () {
            return this.SecondaryIncomeType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplatesComponent.prototype, "isMultiPurposeOffDayCompensationDisable", {
        get: function () {
            return this.SecondaryIncomeType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplatesComponent.prototype, "SecondaryIncomeType", {
        get: function () {
            return this.templateDetail && this.templateDetail.IncomeTypeId === 5;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplatesComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplatesComponent.prototype, "showStatisticsSection", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.StatisticsRecipientId && Global.COMPANY.StatisticsRecipientId !== -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplatesComponent.prototype, "showMultiPurposeOffDayCompensation", {
        get: function () {
            var enableFlexBalanceSetting = Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.filter(function (pref) { return pref.Key === 'Company.EnableFlexBalance'; })[0]
                : undefined;
            if (enableFlexBalanceSetting) {
                return enableFlexBalanceSetting.Value === 'true' ? true : false;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    CompanyTemplatesComponent.prototype.onConfirmSaveDataOnChangeState = function (action) {
        var _this = this;
        if (action === 'Continue') {
            this.onSave();
        }
        else if (action === 'DiscardAndLeave') {
            this.onDiscardChangesDialogAction('ConfirmDiscard');
            if (this.navigationToStateName) {
                setTimeout(function () {
                    _this.sessionService.NavigateTo(_this.navigationToStateName);
                });
            }
            this.navigationToStateName = undefined;
        }
    };
    CompanyTemplatesComponent.prototype.onEditClick = function () {
        if (this.editMode) {
            this.onSave();
        }
        else {
            this.editMode = true;
        }
    };
    CompanyTemplatesComponent.prototype.onDbClick = function () {
        if (this.sessionService.role.IsFullAdmin || this.sessionService.role.IsSalaryAdmin) {
            this.editMode = true;
        }
    };
    CompanyTemplatesComponent.prototype.onSave = function () {
        var dirty = this.childIsDirty.find(function (val) { return val === true; });
        this.isDirty = dirty ? dirty : this.isDirty;
        if (this.isDirty && this.templateDetail) {
            if (this.isValid) {
                if (this.templateHasEmployees) {
                    this.confirmUpdateDialogVisible = true;
                }
                else {
                    this.updateTemplateOnly();
                }
            }
            else {
                this.editMode = true;
            }
        }
        else {
            this.editMode = false;
        }
    };
    CompanyTemplatesComponent.prototype.onAddNewCompanyTemplateCompleted = function (template) {
        this.getTemplates(template.Id);
    };
    CompanyTemplatesComponent.prototype.onDeleteEmploymentTemplate = function () {
        if (this.selectedTemplateId) {
            this.deleteConfirmationDialogVisible = true;
        }
    };
    CompanyTemplatesComponent.prototype.onDeleteConfirmationDialogAction = function (action) {
        var _this = this;
        if (action === 'Delete') {
            this.dataService
                .EmploymentTemplates_DeleteEmploymentTemplate(this.selectedTemplateId)
                .subscribe(function () { return _this.getTemplates(); });
        }
    };
    CompanyTemplatesComponent.prototype.onConfirmUpdateDialogAction = function (action) {
        if (action === 'UpdateAllEmployees') {
            this.updateTemplateWarningDialogVisible = true;
        }
        if (action === 'UpdateTemplateOnly') {
            this.updateTemplateOnly();
        }
    };
    CompanyTemplatesComponent.prototype.onUpdateTemplateWarningAction = function (action) {
        var _this = this;
        if (action === 'Proceed') {
            var templateDetail = this.templateDetail;
            delete templateDetail['GridId'];
            this.dataService
                .EmploymentTemplates_UpdateEmploymentTemplateAndApplyChanges(templateDetail)
                .subscribe(function (template) {
                _this.editMode = false;
                _this.templateDetail = template;
                _this.updateTemplateListTemplateName();
                _this.resetDirtyForm();
                if (_this.sessionService.nextState) {
                    setTimeout(function () {
                        _this.sessionService.NavigateTo(_this.sessionService.nextState);
                    });
                }
            });
        }
    };
    CompanyTemplatesComponent.prototype.onConfirmAndDiscardChanges = function () {
        var dirty = this.childIsDirty.find(function (val) { return val === true; });
        this.isDirty = dirty ? dirty : this.isDirty;
        if (!this.isDirty) {
            this.childIsDirty.fill(false);
            this.editMode = false;
            return;
        }
        this.discardChangesDialogVisible = true;
    };
    CompanyTemplatesComponent.prototype.onDiscardChangesDialogAction = function (action) {
        if (action === 'ConfirmDiscard') {
            this.resetDirtyForm();
            this.editMode = false;
            this.getEmploymentTemplate();
        }
    };
    CompanyTemplatesComponent.prototype.getTemplates = function (selectTemplateId) {
        var _this = this;
        this.dataService
            .EmploymentTemplates_GetEmploymentTemplates(false)
            .subscribe(function (templates) {
            _this.templates = [];
            _this.templates = templates.sort(function (a, b) {
                if (a.Name > b.Name) {
                    return 1;
                }
                if (a.Name < b.Name) {
                    return -1;
                }
                return 0;
            });
            if (_this.templates.length > 0) {
                _this.isEnable = true;
            }
            else {
                _this.isEnable = false;
            }
            _this.selectedTemplateId =
                selectTemplateId || (_this.templates && _this.templates.length > 0 ? _this.templates[0].Id : undefined);
            if (selectTemplateId) {
                _this.editMode = true;
            }
        });
    };
    CompanyTemplatesComponent.prototype.getEmploymentTemplate = function () {
        var _this = this;
        if (this.selectedTemplateId) {
            this.dataService
                .EmploymentTemplates_GetEmploymentTemplate(this.selectedTemplateId)
                .subscribe(function (templateDetail) {
                _this.templateDetail = templateDetail;
                _this.updateTemplateListTemplateName();
                _this.resetDirtyForm();
            });
        }
    };
    CompanyTemplatesComponent.prototype.updateTemplateOnly = function () {
        var _this = this;
        var template = this.templateDetail;
        delete template['GridId'];
        template.VacationSupplementPayoutMonth =
            template.VacationSupplementPayoutMonth === undefined ? null : template.VacationSupplementPayoutMonth;
        template.StatisticsSalaryPrincipleId =
            template.StatisticsSalaryPrincipleId === undefined ? null : template.StatisticsSalaryPrincipleId;
        template.StatisticsEmploymentPrincipleId =
            template.StatisticsEmploymentPrincipleId === undefined ? null : template.StatisticsEmploymentPrincipleId;
        template.StatisticsEmploymentTermsId =
            template.StatisticsEmploymentTermsId === undefined ? null : template.StatisticsEmploymentTermsId;
        template.StatisticsJobStatusId =
            template.StatisticsJobStatusId === undefined ? null : template.StatisticsJobStatusId;
        template.DepartmentId = template.DepartmentId === undefined ? null : template.DepartmentId;
        this.dataService
            .EmploymentTemplates_UpdateEmploymentTemplate(template)
            .subscribe(function (templateDetail) {
            _this.editMode = false;
            _this.templateDetail = templateDetail;
            _this.updateTemplateListTemplateName();
            _this.resetDirtyForm();
            if (_this.sessionService.nextState) {
                setTimeout(function () {
                    _this.sessionService.NavigateTo(_this.sessionService.nextState);
                });
            }
        });
    };
    CompanyTemplatesComponent.prototype.updateTemplateListTemplateName = function () {
        var _this = this;
        if (this.templateDetail && this.templates) {
            var templateInList = this.templates.find(function (template) {
                return template.Id === _this.templateDetail.Id;
            });
            if (templateInList && templateInList.Name !== this.templateDetail.Name) {
                templateInList.Name = this.templateDetail.Name;
                this.refreshTemplateGridList = !this.refreshTemplateGridList;
            }
        }
    };
    CompanyTemplatesComponent.prototype.resetDirtyForm = function () {
        this.childIsDirty.fill(false);
        this.isDirty = false;
    };
    CompanyTemplatesComponent.prototype.createUserCompanyTemplate = function () {
        this.addNewDialogVisible = true;
    };
    CompanyTemplatesComponent.prototype.onCreateNewDepartment = function (event) {
        var _this = this;
        if (event && event === 'Create') {
            this.ModelDepartment.IsActive = true;
            this.dataService.Companies_CreateDepartment(this.ModelDepartment).subscribe(function (data) {
                _this.staticDataService.loadDepartments();
                _this.onCreateNewDepartmentSuccess(data.Id);
            });
        }
        this.ModelDepartment = new Department();
    };
    CompanyTemplatesComponent.prototype.onCreateNewDepartmentSuccess = function (newDepartmentId) {
        var _this = this;
        this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.departments = data;
            _this.templateDetail.DepartmentId = newDepartmentId;
        });
    };
    return CompanyTemplatesComponent;
}(FormComponentBase));
export { CompanyTemplatesComponent };
