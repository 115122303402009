import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { Global } from '../../../Common/Global';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
var CompanySalaryTypeTranslationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanySalaryTypeTranslationComponent, _super);
    function CompanySalaryTypeTranslationComponent(dataService, sessionService, staticDataService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.visibleValue = false;
        _this.visibleChange = new EventEmitter();
        _this.translateComplete = new EventEmitter();
        _this.gridData = [];
        _this.inheritClass = 'grid-inherit-cell';
        _this.NameClass = '';
        _this.DescriptionClass = '';
        _this.DefaultPayslipTextClass = '';
        _this.ExplanationClass = '';
        _this.childIsDirty = Array(1).fill(false);
        return _this;
    }
    Object.defineProperty(CompanySalaryTypeTranslationComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
                if (value) {
                    this.prepareTranslationData(this.salaryType);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryTypeTranslationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.languages = data;
        });
    };
    Object.defineProperty(CompanySalaryTypeTranslationComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryTypeTranslationComponent.prototype.onDialogAction = function (action) {
        if (action === 'Update') {
            this.updateSalaryTypeTranslation();
        }
    };
    CompanySalaryTypeTranslationComponent.prototype.onSaveChange = function (value) {
        this.childIsDirty[0] = true;
    };
    CompanySalaryTypeTranslationComponent.prototype.prepareTranslationData = function (salaryType) {
        var _this = this;
        this.gridData = [];
        if (!salaryType) {
            return;
        }
        this.dataService.SalaryTypes_GetTranslations(salaryType.Id).subscribe(function (data) {
            _this.gridData = data;
        });
    };
    CompanySalaryTypeTranslationComponent.prototype.getFieldTranslation = function (fieldName) {
        var translationText;
        if (this.salaryType &&
            this.salaryType.SalaryTypeTranslations &&
            this.salaryType.SalaryTypeTranslations.length > 0) {
            var translation = this.salaryType.SalaryTypeTranslations.find(function (t) { return t.LanguageId === Global.SESSION.SignOnToken.LanguageId; });
            translationText = translation ? translation[fieldName] : undefined;
        }
        return translationText;
    };
    CompanySalaryTypeTranslationComponent.prototype.getBaseTranslation = function (fieldName, languageId) {
        if (!this.salaryType || !this.salaryType.BaseSalaryType) {
            return undefined;
        }
        var translationText;
        if (this.salaryType.BaseSalaryType.SalaryTypeTranslations &&
            this.salaryType.BaseSalaryType.SalaryTypeTranslations.length > 0) {
            var translation = this.salaryType.BaseSalaryType.SalaryTypeTranslations.find(function (t) { return t.LanguageId === languageId; });
            translationText = translation ? translation[fieldName] : undefined;
        }
        return translationText ? translationText : this.salaryType.BaseSalaryType[fieldName];
    };
    CompanySalaryTypeTranslationComponent.prototype.updateSalaryTypeTranslation = function () {
        var _this = this;
        if (!this.gridData) {
            return;
        }
        if (this.childIsDirty[0] && this.gridData) {
            this.dataService.SalaryTypes_SetTranslations(this.gridData).subscribe(function (data) {
                _this.translateComplete.emit();
                _this.gridData = data;
                _this.visible = false;
            });
        }
    };
    return CompanySalaryTypeTranslationComponent;
}(FormComponentBase));
export { CompanySalaryTypeTranslationComponent };
