// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// tslint:disable-next-line:typedef
export const environment = {
  environment: 'DEV',
  production: false,
  html5Mode: true,
  sentryDSN: 'https://ffca6d54fac14361a777c68a1f0c891d@sentry.io/99129',
  // apiUrl: "http://gratisalapi.matcao.vn"
  apiUrl: 'https://gsapi.matcao.vn'
};
