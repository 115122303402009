<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeEmployment.IllnessAbsence' | translate }}</legend>

    <combobox-edit-control
      *ngIf="!IsUserEmployment"
      [label]="'EmployeeEmployment.TimeEntryRecordAcrossSalaryPeriodStrategy' | translate"
      [editMode]="editMode"
      [textField]="'Name'"
      [idField]="'Id'"
      [comboboxDataSource]="timeEntrySplitStrategy"
      [(value)]="model.TimeEntryRecordAcrossSalaryPeriodStrategy"
      [(isDirty)]="childIsDirty[3]"
      [(isValid)]="childIsValid[3]"
      (valueChange)="onChange()"
      [addonVisible]="true"
      [addonIcon]="'Info'"
      [addonTooltip]="'EmployeeEmployment.TimeEntryRecordAcrossSalaryPeriodStrategyTooltip' | translate"
    ></combobox-edit-control>

    <check-edit
      [(isDirty)]="childIsDirty[0]"
      [(isValid)]="childIsValid[0]"
      [label]="'EmployeeEmployment.IllnessReducesSalary' | translate"
      [(value)]="model.IllnessReducesSalary"
      [editMode]="editMode"
      (valueChange)="onChange()"
    ></check-edit>

    <numeric-edit
      [(isDirty)]="childIsDirty[1]"
      [(isValid)]="childIsValid[1]"
      [label]="'EmployeeEmployment.StandardWorkHoursPerWeek' | translate"
      [editMode]="editMode"
      [(value)]="model.StandardHoursPerWeek"
      (valueChange)="onChange()"
    ></numeric-edit>

    <numeric-edit
      [(isDirty)]="childIsDirty[2]"
      [(isValid)]="childIsValid[2]"
      [label]="'EmployeeEmployment.StandardWorkDaysPerWeek' | translate"
      [editMode]="editMode"
      [(value)]="model.StandardWorkDaysPerWeek"
      (valueChange)="onChange()"
    ></numeric-edit>
  </fieldset>
</form>
