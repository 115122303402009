import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ReflectionHelper } from '../../../Common/ReflectionHelper';
import { NumericTextBoxOptions } from '../../../CustomControls/NumericTextBoxOptions';
import { ImportButtonAction, ImportResultEnum, ImportTypesEnum } from '../../../Model/Enum';
import {
  IDynamicSalaryRecordImportColumn,
  IImportMapping,
  IImportSpecification,
  ISimpleImportMapping,
  ISimpleKeyValuePair
} from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { ImportMappingDataItem } from './ImportMappingDataItem';
import { ImportMappingDataSource } from './ImportMappingDataSource';
import { ImportOptionHelper } from './ImportOptionHelper';
import { ImportSaveMappingParameter } from './ImportSaveMappingParameter';
import { MappingObject } from './MappingObject';

@Component({
  selector: 'import-mapping-dialog',
  templateUrl: './ImportMappingDialog.component.html'
})
export class ImportMappingDialogComponent implements OnDestroy {
  @Input() public importType: ImportTypesEnum;
  @Input() public isExistingMapping = false;
  @Input() public mappingDetail: IImportMapping;
  @Input() public mappingName: string;

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
      if (value) {
        this.showDataImportDialog();
      } else {
        this.startIndex = undefined;
      }
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private formDataValue: FormData;
  @Input()
  public get formData(): FormData {
    return this.formDataValue;
  }
  public set formData(value: FormData) {
    if (this.formDataValue !== value) {
      this.formDataValue = value;
      this.formDataChange.emit(value);
    }
  }
  @Output() public formDataChange: EventEmitter<FormData> = new EventEmitter<FormData>();

  private startIndexValue: number;
  @Input()
  public get startIndex(): number {
    return this.startIndexValue;
  }
  public set startIndex(value: number) {
    if (this.startIndexValue !== value) {
      this.startIndexValue = value;
      this.startIndexChange.emit(value);
    }
  }
  @Output() public startIndexChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() public closedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public saveMapingEvent: EventEmitter<number> = new EventEmitter<number>();

  // Template properties
  public userColumnsValues: MappingObject[] = [];
  public importSpecificationOptions: any;
  public importMappingDataDataSource: ImportMappingDataSource;
  public importResults: string[];
  public importOneResults: string[];
  public option: NumericTextBoxOptions = { min: 0, step: 1, spinners: false, decimals: 0, format: 'n0' };
  public isButtonDisabled = false;
  public alertDialogVisible = false;
  public alertMessage: string;
  public successDialogVisible = false;
  public importOnceAlertDialogVisible = false;

  // Private properties
  private tempMappingDetail: IImportMapping;
  private mappingData: ISimpleKeyValuePair[] = []; //// Consider to use importMappingDataDataSource instead.
  private importSpecifications: any[] = [];

  constructor(
    private dataService: RxDataService,
    public sessionService: SessionService,
    private staticData: StaticDataService
  ) {}

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDialogAction(action: string): void {
    switch (action) {
      case 'Test':
        this.onTestClicked();
        break;
      case 'ImportOnce':
        this.onImportOnceClicked();
        break;
      case 'SaveDefinition':
        this.onSaveChangesClicked();
        break;
      default:
        this.onCancelClicked();
    }
  }

  public closeDialogAndFireClosedEvent(): void {
    this.closeDialog();
    this.closedEvent.emit();
  }

  public onComboboxChange(value: any, item: MappingObject): void {
    if (value) {
      const previousKey: string = this.getColumnTypeValue(item.Key);
      this.importMappingDataDataSource.data[item.Key].columnsTypeValue = value;
      this.importMappingDataDataSource.addAvailableItemToComboboxDataSources(item.Key, previousKey);

      const selectedMappingObject: MappingObject = this.importMappingDataDataSource.findSelectedColumnTypeItemById(
        value
      );
      if (selectedMappingObject) {
        this.updateKeyValuePairs(item.Key, selectedMappingObject.Key);
        this.importMappingDataDataSource.removeColumnTypeDropDownItem(item.Key, selectedMappingObject.Key);
      }
    }
  }

  public onGratisalComboboxChange(value: any, item: MappingObject): void {
    if (value) {
      this.setAmountAsDefaultOfColumnType(value, item.Key);
    }
  }

  public onStartIndexChanged(): void {
    this.prepareForCustomImport();
  }

  private onCancelClicked(): void {
    if (this.createdTempMappingDetail) {
      this.dataService.Import_DeleteMapping(this.tempMappingDetail.Id).subscribe(() => {
        this.tempMappingDetail = undefined;
        this.mappingDetail = undefined;
        this.closeDialog();
      });

      return;
    }

    this.closeDialog();
  }

  private onTestClicked(): void {
    if (!this.validataMappings()) {
      return;
    }

    const mappingString: any = this.convertToMappingObject(this.mappingData);
    if (!mappingString) {
      this.alertMessage = 'CompanyDataImport.NoMappingYet';
      this.alertDialogVisible = true;
      return;
    }

    if (!this.mappingDetail) {
      this.createImportMappings(mappingString).then(() => {
        this.testImportData(this.tempMappingDetail.Id);
      });

      return;
    }

    this.updateImportMappings(mappingString).then((mapping: IImportMapping) => {
      this.testImportData(this.mappingDetail.Id);
    });
  }

  private onImportOnceClicked(): void {
    if (!this.validataMappings()) {
      return;
    }

    const mappingString: any = this.convertToMappingObject(this.mappingData);
    if (!mappingString) {
      this.alertMessage = 'CompanyDataImport.NoMappingYet';
      this.alertDialogVisible = true;
      return;
    }

    if (!this.mappingDetail) {
      this.createImportMappings(mappingString).then(() => {
        this.ImportOneImportData(this.tempMappingDetail.Id);
      });

      return;
    }

    this.updateImportMappings(mappingString).then((mapping: IImportMapping) => {
      this.ImportOneImportData(this.mappingDetail.Id);
    });
  }

  private onSaveChangesClicked(): void {
    if (!this.validataMappings()) {
      return;
    }

    const mappingString: any = this.convertToMappingObject(this.mappingData);
    if (!mappingString) {
      this.alertMessage = 'CompanyDataImport.NoMappingYet';
      this.alertDialogVisible = true;
      return;
    }

    if (!this.mappingDetail) {
      this.createImportMappings(mappingString).then(() => {
        if (!this.isExistingMapping) {
          this.mappingDetail = undefined;
        }

        this.tempMappingDetail = undefined;
        this.successDialogVisible = true;
      });
      return;
    }

    this.updateImportMappings(mappingString).then((mapping: IImportMapping) => {
      if (!this.isExistingMapping) {
        this.mappingDetail = undefined;
      }

      this.tempMappingDetail = undefined;
      this.fireSaveMapingEvent(mapping.Id);
      this.successDialogVisible = true;
    });
  }

  private showDataImportDialog(): void {
    this.isButtonDisabled = false;
    this.prepareForCustomImport();
  }

  private get createdTempMappingDetail(): boolean {
    return this.tempMappingDetail && !this.isExistingMapping;
  }

  private closeDialog(): void {
    this.isButtonDisabled = true;
    this.visible = false;
    if (this.createdTempMappingDetail) {
      this.dataService.Import_DeleteMapping(this.mappingDetail.Id).subscribe(() => {
        this.tempMappingDetail = undefined;
        this.mappingDetail = undefined;
      });
    }
  }

  private displayExistingPayrollMappings(): void {
    if (!this.mappingDetail.ColumnMapping) {
      return;
    }

    const pairs: any[] = JSON.parse(this.mappingDetail.ColumnMapping);
    if (pairs) {
      pairs.forEach((pair: any, index: number) => {
        const importMappingDataItem: ImportMappingDataItem = this.importMappingDataDataSource.data[pair.Value];
        this.extractGratisalValueAndColumnTypeValue(importMappingDataItem, pair.Key);
      });
    }
  }

  private extractGratisalValueAndColumnTypeValue(importMappingDataItem: ImportMappingDataItem, key: string): void {
    const keyValue: string[] = key.split(';');
    if (!importMappingDataItem || keyValue.length < 1) {
      return;
    }

    if (keyValue.length < 2) {
      importMappingDataItem.GratisalColumnsValue = keyValue[0];
      const amountColumn: MappingObject = importMappingDataItem.findColumnTypeItem(1); //// Amount
      if (!amountColumn) {
        console.warn("Can't find Amount in the ColumnTypeDataSource");
        return;
      }

      importMappingDataItem.columnsTypeValue = amountColumn.Id;
      return;
    }

    if (!keyValue[0]) {
      importMappingDataItem.GratisalColumnsValue = keyValue[1];
      importMappingDataItem.columnsTypeValue = undefined;
      return;
    }

    importMappingDataItem.GratisalColumnsValue = keyValue[0];
    const columnItem: MappingObject = importMappingDataItem.findColumnTypeItemByKey(keyValue[1]);
    importMappingDataItem.columnsTypeValue = columnItem ? columnItem.Id : importMappingDataItem.columnsTypeValue;
  }

  private displayExistingMappings(): void {
    if (!this.mappingDetail.ColumnMapping) {
      return;
    }

    const pairs: string[] = this.mappingDetail.ColumnMapping.split(';');
    if (pairs) {
      pairs.forEach((pair: string, index: number) => {
        const keyValue: string[] = pair.split(':');
        if (keyValue.length > 1) {
          const gratisalKey: string = keyValue[0];
          const userFileId: string = keyValue[1];
          if (this.importMappingDataDataSource.data[userFileId]) {
            const columnItem: MappingObject = this.importMappingDataDataSource.data[userFileId].findColumnTypeItemByKey(
              gratisalKey
            );
            this.importMappingDataDataSource.data[userFileId].columnsTypeValue = columnItem
              ? columnItem.Id
              : this.importMappingDataDataSource.data[userFileId].columnsTypeValue;
            this.updateKeyValuePairs(userFileId, gratisalKey);
            if (columnItem) {
              const selectedMappingObject: MappingObject = this.importMappingDataDataSource.findSelectedColumnTypeItemById(
                columnItem.Id.toString()
              );
              this.importMappingDataDataSource.removeColumnTypeDropDownItem(userFileId, selectedMappingObject.Key);
            }
          }
        }
      });
    }
  }

  private prepareForCustomImport(): void {
    // Check for existing mapping
    if (this.importSpecifications.length === 0) {
      this.staticData.ImportSpecification.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        (importSpecifications: IImportSpecification[]) => {
          this.importSpecifications = importSpecifications;
          this.updateSpecificationOptions();
          this.onStartMapping();
        }
      );
    } else {
      this.updateSpecificationOptions();
      this.onStartMapping();
    }
  }

  private updateSpecificationOptions(): void {
    let specification: any;
    if (this.importSpecifications) {
      specification = this.importSpecifications.find((item: any): boolean => {
        return item.Key === ImportOptionHelper.getImportTypeKey(this.importType);
      });
    }

    if (specification) {
      this.importSpecificationOptions = ImportOptionHelper.createImportOptionObjects(specification.ImportOptions);
    }
  }

  private onStartMapping(): void {
    const startIndex: number = this.startIndex;
    const importType: string = ImportOptionHelper.getImportTypeKey(this.importType);
    if (!this.formData || importType.length === 0 || !startIndex) {
      return;
    }

    // Reset mapping data before start
    this.mappingData = [];
    this.userColumnsValues = [];
    this.dataService
      .ImportMapping_GetExcelHeadersOfFile(this.formData, importType, startIndex)
      .subscribe((result: string) => {
        if (!result) {
          this.showNoColumnsFoundMessage();
          return;
        }

        this.onStartMappingCompleted(result, importType);
      });
  }

  private showNoColumnsFoundMessage(): void {
    this.alertMessage = 'CreateDataImportTemplate.NoColumnsFound';
    this.alertDialogVisible = true;
    this.isButtonDisabled = true;
    this.visible = false;
  }

  private onStartMappingCompleted(result: string, importType: string): void {
    if (result && result.length >= 0) {
      const userColumnArray: string[] = result.split(';');
      this.userColumnsValues = this.splitOperations(userColumnArray);

      // Set default mapping data
      if (this.userColumnsValues && this.userColumnsValues.length > 0) {
        this.importMappingDataDataSource = new ImportMappingDataSource();
        this.userColumnsValues.forEach((col: MappingObject): void => {
          this.importMappingDataDataSource.data[col.Key] = new ImportMappingDataItem();
          this.importMappingDataDataSource.data[col.Key].userFileColumnHeader = col.Name;
          this.importMappingDataDataSource.data[col.Key].userFileId = col.Key;
          const keyValPairItem: ISimpleKeyValuePair = { Key: col.Key, Value: '-1' };
          this.mappingData.push(keyValPairItem);
        });
      }

      // Get object columns based on selectedImportType
      if (this.importType === ImportTypesEnum.Payroll) {
        this.loadGratisalColumnsDropDownDataSource().then((salaryTypes: IDynamicSalaryRecordImportColumn[]) => {
          this.loadComboboxesDataSources(importType);
        });
      } else if (this.importType === ImportTypesEnum.TimeEntries) {
        this.loadGratisalColumnsDropDownDataSource().then((salaryTypes: IDynamicSalaryRecordImportColumn[]) => {
          this.loadComboboxesDataSources(importType);
        });
      } else {
        this.loadComboboxesDataSources(importType);
      }
    }
  }

  private loadGratisalColumnsDropDownDataSource(): Promise<IDynamicSalaryRecordImportColumn[]> {
    return new Promise<IDynamicSalaryRecordImportColumn[]>(
      (resolve: (value: any) => void, reject: (value: any) => void) => {
        this.dataService
          .Import_GetPayrollDataColumnHeaders()
          .subscribe((salaryTypes: IDynamicSalaryRecordImportColumn[]) => {
            const noneDynamicSalaryRecordImportColumn: IDynamicSalaryRecordImportColumn = this.createNoneDynamicSalaryRecordImportColumn();
            salaryTypes.unshift(noneDynamicSalaryRecordImportColumn);
            this.importMappingDataDataSource.setGratisalColumnsDropDownDataSourceAndAutoMap(
              salaryTypes,
              !this.isExistingMapping
            );
            return resolve(salaryTypes);
          });
      }
    );
  }

  private setAmountAsDefaultOfColumnType(gratisalNewValue: string, key: string): void {
    const importMappingDataItem: ImportMappingDataItem = this.importMappingDataDataSource.data[key];
    if (!importMappingDataItem || importMappingDataItem.columnsTypeValue) {
      return;
    }

    const columTypeEditable: boolean = gratisalNewValue && gratisalNewValue !== '-1' && !importMappingDataItem.IsId;
    const columnTypeValueEmpty: boolean =
      !importMappingDataItem.columnsTypeValue || importMappingDataItem.columnsTypeValue === -1;
    let columnTypeValue: number;
    let columnTypeKey: string;

    if (columTypeEditable && columnTypeValueEmpty) {
      columnTypeValue = 1;
      const mappingObject: MappingObject = importMappingDataItem.findColumnTypeItem(1);
      columnTypeKey = mappingObject ? mappingObject.Key : columnTypeKey;
    }

    importMappingDataItem.columnsTypeValue = columnTypeValue;
  }

  private setAmountAsDefaultOfColumnTypes(): void {
    for (const key in this.importMappingDataDataSource.data) {
      if (this.importMappingDataDataSource.data.hasOwnProperty(key)) {
        const gratisalValue: string = this.importMappingDataDataSource.data[key].GratisalColumnsValue;
        this.setAmountAsDefaultOfColumnType(gratisalValue, key);
      }
    }
  }

  private updateKeyValuePairs(userItemKey: string, objectItemKey: string): void {
    const keyValPairItem: ISimpleKeyValuePair = { Key: userItemKey, Value: objectItemKey };
    if (this.mappingData.length === 0) {
      this.mappingData.push(keyValPairItem);
    } else {
      // First look up if it's updating an existing keyValuePair
      const foundIndex: number = this.findExistingItem(userItemKey, this.mappingData);
      if (foundIndex < 0) {
        // No existing item, can add normally
        this.mappingData.push(keyValPairItem);
      } else {
        // Update the object item with the new selection
        this.mappingData[foundIndex].Value = objectItemKey;
      }
    }
  }

  private getColumnTypeValue(userItemKey: string): string {
    let value: string;
    const foundIndex: number = this.findExistingItem(userItemKey, this.mappingData);
    if (foundIndex > -1) {
      value = this.mappingData[foundIndex].Value;
    }

    if (!value) {
      return undefined;
    }

    const columnItem: MappingObject = this.importMappingDataDataSource.data[userItemKey].findColumnTypeItemByKey(value);
    return columnItem ? columnItem.Key.toString() : undefined;
  }

  private findExistingItem(userItemKey: string, collection: ISimpleKeyValuePair[]): number {
    const result: number = collection.findIndex((value: ISimpleKeyValuePair) => value.Key === userItemKey);
    return result;
  }

  private hasError(result: any): boolean {
    return result.Errors && result.Errors.length > 0;
  }

  private validataMappings(): boolean {
    if (this.mappingName.length === 0) {
      this.alertMessage = 'CompanyDataImport.MappingNameEdit';
      this.alertDialogVisible = true;
      return false;
    }
    if (!this.startIndex) {
      this.alertMessage = 'CompanyDataImport.MissingStartRow';
      this.alertDialogVisible = true;
      return false;
    }

    return true;
  }

  private testImportData(mappingId: number): void {
    if (!mappingId || mappingId < 1) {
      return;
    }

    const optionString: string = ImportOptionHelper.getInportOptionsString(
      this.importSpecificationOptions,
      ImportButtonAction.Test
    );
    this.dataService
      .ImportMapping_CustomImportExcelOptions(mappingId, optionString, this.formData)
      .subscribe((result: any) => this.onTestImportCompleted(result));
  }

  private ImportOneImportData(mappingId: number): void {
    if (!mappingId || mappingId < 1) {
      return;
    }

    const optionString: string = ImportOptionHelper.getInportOptionsString(
      this.importSpecificationOptions,
      ImportButtonAction.Import
    );
    this.dataService
      .ImportMapping_CustomImportExcelOptions(mappingId, optionString, this.formData)
      .subscribe((result: any) => (this.importOneResults = result));
  }

  private onTestImportCompleted(result: any): void {
    this.alertMessage =
      result && result.Outcome === ImportResultEnum[ImportResultEnum.FatalError] && result.FatalMessage
        ? result.FatalMessage
        : 'CompanyDataImport.TestImportSuccessMessage';
    if (!this.hasError(result)) {
      this.alertDialogVisible = true;
      return;
    }

    this.importResults = result;
  }

  public onCompleteImportOneEvent(): void {
    this.onCancelClicked();
  }

  private updateImportMappings(mappingString: string): Promise<IImportMapping> {
    return new Promise<IImportMapping>((resolve: (value: any) => void, reject: (value: any) => void) => {
      if (!mappingString) {
        // return deferred.promise;
      }

      this.mappingDetail.ColumnMapping = mappingString;
      this.mappingDetail.StartRow = this.startIndex;
      this.dataService.Import_UpdateMapping(this.mappingDetail).subscribe((mapping: IImportMapping): void => {
        this.mappingDetail = mapping;
        return resolve(mapping);
      });
    });
  }

  private createImportMappings(mappingString: string): Promise<ISimpleImportMapping> {
    return new Promise<ISimpleImportMapping>((resolve: (value: any) => void, reject: (value: any) => void) => {
      if (!mappingString) {
        // return deferred.promise;
      }

      const simpleImportMapping: ISimpleImportMapping = this.createSimpleImportMapping(mappingString);
      this.dataService.Import_SaveMapping(simpleImportMapping).subscribe((mapping: IImportMapping): void => {
        this.tempMappingDetail = mapping;
        this.mappingDetail = mapping;
        return resolve(simpleImportMapping);
      });
    });
  }

  private createSimpleImportMapping(mappingString: string): ISimpleImportMapping {
    const simpleImportMapping: ISimpleImportMapping = {
      Mapping: mappingString,
      Name: this.mappingName,
      StartRow: this.startIndex,
      Operation: ImportOptionHelper.getImportTypeKey(this.importType)
    };
    return simpleImportMapping;
  }

  private fireSaveMapingEvent(mappingId: number): void {
    if (this.saveMapingEvent) {
      this.saveMapingEvent.emit(mappingId);
    }
  }

  private convertToMappingObject(mappingData: ISimpleKeyValuePair[]): string {
    let parameters = '';
    if (this.importType === ImportTypesEnum.Payroll) {
      const data: any = this.importMappingDataDataSource.data;
      let index = 0;
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const parameter: ImportSaveMappingParameter = new ImportSaveMappingParameter();
          parameter.value = data[key].userFileId;
          parameter.selectedColumnTypeValue = data[key].columnsTypeValue;
          parameter.selectedColumnTypeKey = data[key].columnsTypeKey;
          parameter.selectedGratisalColumnValue = data[key].GratisalColumnsValue;
          const strParameter: string = parameter.toString();
          if (strParameter.length > 0) {
            if (parameters.length > 0) {
              parameters += ',';
            }

            parameters += strParameter;
          }

          index++;
        }
      }

      parameters = '[' + parameters + ']';
    } else {
      if (mappingData) {
        mappingData.forEach((element: ISimpleKeyValuePair): void => {
          if (element.Value && element.Value !== '-1') {
            const col: string = element.Value + ':' + element.Key;
            parameters += col + ';';
          }
        });
      }
    }

    return parameters;
  }

  private loadComboboxesDataSources(importType: string): void {
    if (this.importSpecifications.length < 0) {
      this.importMappingDataDataSource.setColumnTypeDropDownDataSource([]);
      return;
    }

    const importSpecification: any = this.getImportSpecification(importType);
    if (!importSpecification) {
      this.importMappingDataDataSource.setColumnTypeDropDownDataSource([]);
      return;
    }

    this.getComboboxDasource(importSpecification).then((dataSource: MappingObject[]) => {
      const noneMappingObject: MappingObject = this.createNoneMappingObject();
      dataSource.unshift(noneMappingObject);
      this.importMappingDataDataSource.setColumnTypeDropDownDataSource(dataSource);
      if (!this.isExistingMapping) {
        this.setAmountAsDefaultOfColumnTypes();
      } else {
        if (this.importType === ImportTypesEnum.Payroll) {
          this.displayExistingPayrollMappings();
        } else {
          this.displayExistingMappings();
        }
      }
    });
  }

  private getImportSpecification(importType: string): any {
    if (this.importSpecifications) {
      return this.importSpecifications.find((value: any): boolean => {
        return value.Key === importType;
      });
    }
  }

  private createNoneDynamicSalaryRecordImportColumn(): IDynamicSalaryRecordImportColumn {
    return {
      IsId: false,
      Name: '',
      Value: '-1'
    };
  }

  private createNoneMappingObject(): MappingObject {
    return {
      Id: -1,
      SortIndex: -1,
      Name: ' ',
      IsSingle: false,
      Key: '-1'
    };
  }

  private getComboboxDataSourceFromColumnsString(
    columnsString: string,
    defaultSingleColumns: boolean
  ): MappingObject[] {
    const columns: string[] = columnsString.split(';');
    const hasColumns: boolean = columns && columns.length > 0;
    if (hasColumns) {
      return this.splitOperations(columns, defaultSingleColumns);
    }

    return [];
  }

  private getComboboxDasource(importSpecification: any): Promise<MappingObject[]> {
    return new Promise<MappingObject[]>((resolve: (value: any) => void, reject: (value: any) => void) => {
      let comboboxDataSource: MappingObject[] = [];
      const columnsHeadersString: string = importSpecification.Columns;
      const dynamicColumnsString: string = importSpecification.DynamicColumns;
      const defaultSingleColumns: boolean = importSpecification.DefaultSingleColumns;
      const isColumnsTypeString: boolean =
        importSpecification.Columns && typeof importSpecification.Columns === 'string';
      const isDynamicColumns: boolean =
        this.importType !== ImportTypesEnum.Payroll &&
        this.importType !== ImportTypesEnum.PayrollRow &&
        this.importType !== ImportTypesEnum.TimeEntries &&
        this.importType !== ImportTypesEnum.TimeEntriesRow &&
        importSpecification.DynamicColumns &&
        importSpecification.DynamicColumns !== 'none';

      if (!isColumnsTypeString) {
        return resolve(comboboxDataSource);
      }

      if (!isDynamicColumns) {
        if (
          this.importType === ImportTypesEnum.Payroll ||
          this.importType === ImportTypesEnum.PayrollRow ||
          this.importType === ImportTypesEnum.TimeEntries ||
          this.importType === ImportTypesEnum.TimeEntriesRow
        ) {
          let dynamicDimensions: any;
          const operationName: string = ImportOptionHelper.getImportTypeKey(this.importType).toLowerCase();
          this.dataService
            .Import_GetEncodedDimensions(operationName)
            .pipe(
              finalize(() => {
                let dimensionString = '';
                if (!ReflectionHelper.isString(dynamicDimensions)) {
                  dynamicDimensions.forEach((item: any) => {
                    dimensionString += item.Name + ':' + item.Value + ';';
                  });
                } else {
                  dimensionString = dynamicDimensions;
                }

                comboboxDataSource = this.getComboboxDataSourceFromColumnsString(
                  columnsHeadersString + ';' + dimensionString,
                  defaultSingleColumns
                );
                // console.log(comboboxDataSource);
                return resolve(comboboxDataSource);
              })
            )
            .subscribe((dimensions: string) => {
              dynamicDimensions = dimensions;
            });
        } else {
          comboboxDataSource = this.getComboboxDataSourceFromColumnsString(columnsHeadersString, defaultSingleColumns);
          return resolve(comboboxDataSource);
        }
      } else {
        this.dataService
          .Import_GetPayrollDataColumnHeaders()
          .subscribe((dynamicColumnHeaders: IDynamicSalaryRecordImportColumn[]): void => {
            comboboxDataSource = this.getComboboxDataSourceFromColumnsString(
              columnsHeadersString,
              defaultSingleColumns
            );
            const id: number = comboboxDataSource.length;
            if (dynamicColumnHeaders) {
              dynamicColumnHeaders.forEach((dynamicColumnHeader: IDynamicSalaryRecordImportColumn) => {
                const mappingObject: MappingObject = new MappingObject();
                mappingObject.Id = id;
                mappingObject.SortIndex = id;
                mappingObject.IsSingle = defaultSingleColumns;
                mappingObject.Name = dynamicColumnHeader.Name;
                mappingObject.Key = dynamicColumnHeader.Value;
                comboboxDataSource.push(mappingObject);
              });
            }

            return resolve(comboboxDataSource);
          });
      }
    });
  }

  private splitOperations(inputArray: string[], defaultSingleColumns: boolean = false): MappingObject[] {
    const resultArray: MappingObject[] = [];
    if (inputArray) {
      inputArray.forEach((value: string, index: number) => {
        const keyValPair: string[] = value.split(':');
        if (keyValPair && keyValPair.length === 2 && keyValPair[0].length > 0) {
          const mappingObj: MappingObject = {
            Id: index + 1, //// "CPR no." has key "0", it will be default value ==> plus 1 to fix that.
            SortIndex: index + 1,
            IsSingle: defaultSingleColumns,
            Name: keyValPair[0],
            Key: keyValPair[1]
          };
          resultArray.push(mappingObj);
        }
      });
    }

    return resultArray;
  }
}
