import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CPRFormat'
})
export class CPRFormatPipe implements PipeTransform {
  public transform(value: string): string {
    if (!value) {
      return '';
    }

    if (value.length < 5) {
      return value;
    }

    const firstPart: string = value.substr(0, 6);
    const secondPart: string = value.substring(6, value.length);
    return firstPart + '-' + secondPart;
  }
}
