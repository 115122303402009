import { UnitTypeEnum } from '../../../../Model/Enum';

export class SalaryRecordCalculation {
  private unitType: UnitTypeEnum;
  private units: number;
  private amount: number;
  private amountPerUnit: number;
  private baseAmount: number;

  get UnitType(): UnitTypeEnum {
    return this.unitType;
  }

  set UnitType(unitType: UnitTypeEnum) {
    this.unitType = unitType;
  }

  get Amount(): number {
    return this.hasValue(this.amount) ? this.amount : null;
  }

  set Amount(amount: number) {
    this.amount = amount;
  }

  get AmountPerUnit(): number {
    return this.hasValue(this.amountPerUnit) ? this.amountPerUnit : null;
  }

  set AmountPerUnit(amountPerUnit: number) {
    this.amountPerUnit = amountPerUnit;
  }

  get Units(): number {
    return this.hasValue(this.units) ? this.units : null;
  }

  set Units(units: number) {
    this.units = units;
  }

  get BaseAmount(): number {
    return this.hasValue(this.baseAmount) ? this.baseAmount : null;
  }

  set BaseAmount(baseAmount: number) {
    this.baseAmount = baseAmount;
  }

  calculateBaseAmount(): void {
    ////if (this.unitType !== UnitTypeEnum.Percent) {
    ////    return;
    ////}
    ////let baseAmount: number;
    ////let percentage: number = this.units / 100;
    ////if (!this.hasValue(percentage) || !this.hasValue(this.amount)) {
    ////    return;
    ////}
    ////baseAmount = (this.amount * 100) / (percentage * 100);
    ////this.baseAmount = this.roundDecimal(baseAmount, 2);
  }

  calculateAmountPerUnit(): void {
    ////let amountPerUnit: number;
    ////if (this.unitType === UnitTypeEnum.Percent) {
    ////    return;
    ////}
    ////if (!this.hasValue(this.amount) || !this.hasValue(this.units)) {
    ////    return;
    ////}
    ////amountPerUnit = this.amount / (this.units * 100) * 100;
    ////this.amountPerUnit = this.roundDecimal(amountPerUnit, 2);
  }

  calculateUnits(): void {
    let unit: number;
    if (this.unitType === UnitTypeEnum.Percent) {
      // Calculate Units base on BaseAmount and Amount field.
      if (!this.hasValue(this.baseAmount)) {
        return;
      }

      if (this.hasValue(this.baseAmount) && this.hasValue(this.amount)) {
        unit = (this.amount * 100) / this.baseAmount;
      }
    } else {
      // Calculate Units base on AmountPerUnit and Amount field.
      if (!this.hasValue(this.amountPerUnit)) {
        return;
      }

      if (this.hasValue(this.amountPerUnit) && this.hasValue(this.amount)) {
        unit = (this.amount * 100) / (this.amountPerUnit * 100);
      }
    }

    this.units = this.hasValue(unit) ? this.roundDecimal(unit, 2) : null;
  }

  calculateAmount(): void {
    let amount: number;
    if (this.unitType === UnitTypeEnum.Percent) {
      if (!this.hasValue(this.baseAmount)) {
        return;
      }

      const percentage: number = this.units / 100;
      if (this.hasValue(percentage) && this.hasValue(this.baseAmount)) {
        amount = (this.baseAmount * 100 * (percentage * 100)) / 10000;
      }
    } else {
      if (!this.hasValue(this.amountPerUnit)) {
        return;
      }

      if (this.hasValue(this.amountPerUnit) && this.hasValue(this.units)) {
        amount = (this.amountPerUnit * 100 * (this.units * 100)) / 10000;
      }
    }

    this.amount = this.hasValue(amount) ? this.roundDecimal(amount, 2) : null;
  }

  hasValue(value: number): boolean {
    return value && value !== 0;
  }

  private roundDecimal(value: number, decimals: number): number {
    if (value) {
      let negative = false;
      if (!decimals) {
        decimals = 0;
      }
      if (value < 0) {
        negative = true;
        value = value * -1;
      }

      const multiplicator: any = Math.pow(10, decimals);
      value = parseFloat((value * multiplicator).toFixed(11));
      value = (Math.round(value) / multiplicator).toFixed(2) as any;
      if (negative) {
        value = (value * -1).toFixed(2) as any;
      }

      return parseFloat(value as any);
      ////return parseFloat((Math.round(value * 100) / 100).toFixed(decimals));
    }

    return null;
  }
}
