import { Component, HostListener } from '@angular/core';
import { RouterStateService } from '../Common/RouterState.service';
import { SessionService } from '../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'app-accountant',
  templateUrl: './Accountant.component.html'
})
export class AccountantComponent {
  public get IsMobile(): boolean {
    return this.session.browser.isMobile || this.session.browser.isHybridApp;
  }

  public get isAccountantCompaniesTab(): boolean {
    return this.session.currentState.includes('tabs.accountant.companies');
  }

  public get isAccountantUserTab(): boolean {
    return this.session.currentState.includes('tabs.accountant.users');
  }

  constructor(public session: SessionService, public state: RouterStateService) {}

  public clickAreamenuToggledChange(): void {
    this.session.menuToggled = true;
    this.session.showUserMenuToggled = false;
    this.session.showMoreMenuToggled = false;
    this.session.showHelpMenuToggled = false;
  }
}
