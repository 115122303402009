import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../Common/Constants';
import { Global } from '../Common/Global';
import { ICompanyUser } from '../Services/ApiModel';
import { Broadcaster } from '../Services/Broadcaster';
import { SessionService } from '../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'employee',
  templateUrl: './Employee.component.html'
})
export class EmployeeComponent implements OnDestroy {
  public globalKeyDown: any;
  public editModeValue = false;
  public avatarChange = false;
  @Input()
  public get editMode(): boolean {
    return this.editModeValue;
  }
  public set editMode(value: boolean) {
    if (this.editModeValue !== value) {
      this.editModeValue = value;
      this.editModeChange.emit(value);
      // setTimeout(() => {
      //     this.editModeChange.emit(value);
      // });
    }
  }
  @Output() public editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public addEmployeeFired: boolean;
  public newCompanyUser: ICompanyUser;
  public currentCompanyUser: ICompanyUser;
  public deletedEmployeeId: number;
  public refreshCurrentEmployee: ICompanyUser;
  public isBlankEmail = false;

  constructor(private broadcaster: Broadcaster, public sessionService: SessionService) {
    this.broadcaster
      .on<any>(Constants.GLOBAL_KEY_DOWN)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((keyDownEvent: any): any => {
        this.onKeyDown(keyDownEvent);
      });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public setCurrentEmployee(employee: ICompanyUser): void {
    this.currentCompanyUser = employee;
    this.sessionService.currentUserEmploymentId = employee.Id;
    Global.EMPLOYEESELECT = employee;
    if (this.currentCompanyUser && !this.currentCompanyUser.PersonalEmail && !this.currentCompanyUser.CompanyEmail) {
      this.isBlankEmail = true;
    } else {
      this.isBlankEmail = false;
    }
  }

  public selectNewEmployee(newCompanyUser: ICompanyUser): void {
    this.newCompanyUser = newCompanyUser;
  }

  public updateEmployee(employee: ICompanyUser): void {
    this.refreshCurrentEmployee = employee;
  }

  public reloadEmployeeList(deleted: ICompanyUser): void {
    if (!deleted) {
      return;
    }

    this.deletedEmployeeId = deleted.Id;
  }

  public onAddEmployeeClicked(): void {
    this.addEmployeeFired = true;
  }

  private onKeyDown(event: any): void {
    this.globalKeyDown = event;
  }

  public preLoadAvatar(event: any): void {
    this.avatarChange = event;
  }

  public isAvatarChanged: boolean;
  public onAvatarChanged(): void {
    this.isAvatarChanged = !this.isAvatarChanged;
  }
}
