import { EventEmitter, OnDestroy, Renderer2 } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Global } from '../../../../Common/Global';
import { SalaryBatchStatusEnum } from '../../../../Model/Enum';
import { RxDataService } from '../../../../Services/RxDataService';
import { DownloadService } from '../../../../Services/SharedServices/DownloadService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../../Services/SharedServices/SettingService';
var SalaryBatchPayslipsComponent = /** @class */ (function () {
    function SalaryBatchPayslipsComponent(sessionService, dataService, downloadService, renderer, settingService) {
        this.sessionService = sessionService;
        this.dataService = dataService;
        this.downloadService = downloadService;
        this.renderer = renderer;
        this.settingService = settingService;
        this.salaryStatementsValue = [];
        this.revertBatchCompleted = new EventEmitter();
        this.filteredSalaryStatements = [];
        this.salaryStatementDetails = [];
        this.newTabBlockedDialogVisible = false;
        this.previewDialogVisible = false;
        this.ngUnsubscribe = new Subject();
        this.flagDownloadSalaryStatement = false;
        this.gridData = filterBy(this.filteredSalaryStatements, this.filter);
    }
    Object.defineProperty(SalaryBatchPayslipsComponent.prototype, "selectedSalaryBatch", {
        get: function () {
            return this.selectedSalaryBatchValue;
        },
        set: function (value) {
            if (this.selectedSalaryBatchValue !== value) {
                this.selectedSalaryBatchValue = value;
                this.filterSalaryStatements();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipsComponent.prototype, "salaryStatements", {
        get: function () {
            return this.salaryStatementsValue;
        },
        set: function (value) {
            this.salaryStatementsValue = value;
            this.filterSalaryStatements();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipsComponent.prototype, "IsWeb", {
        get: function () {
            return !this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipsComponent.prototype, "IsMobile", {
        get: function () {
            return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipsComponent.prototype, "isPaymentApprover", {
        get: function () {
            return Global.SESSION && Global.SESSION.IsPaymentApprover;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipsComponent.prototype, "IsFinalizedBatch", {
        get: function () {
            return this.selectedSalaryBatchValue && this.selectedSalaryBatchValue.StatusId === SalaryBatchStatusEnum.Finalized;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipsComponent.prototype, "selectedSalaryStatement", {
        get: function () {
            return this.selectedSalaryStatementValue;
        },
        set: function (value) {
            if (this.selectedSalaryStatementValue !== value) {
                this.selectedSalaryStatementValue = value;
                this.loadSalaryStatementDetails();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipsComponent.prototype, "selectedSalaryStatementId", {
        get: function () {
            return this.selectedSalaryStatement ? this.selectedSalaryStatement.Id : undefined;
        },
        set: function (value) {
            this.selectDefaultSelectedPayslip(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipsComponent.prototype, "isFirefox", {
        get: function () {
            return this.sessionService.browser.isFirefox;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipsComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchPayslipsComponent.prototype, "searchText", {
        get: function () {
            return this.searchTextValue;
        },
        set: function (value) {
            if (this.searchTextValue !== value) {
                this.searchTextValue = value;
                this.filterSalaryStatements();
            }
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchPayslipsComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SalaryBatchPayslipsComponent.prototype.navigateToEmployeePayroll = function () {
        // this.settingService.navigationParameters["EmployeeId"] = employeeId;
        // this.settingService.navigationParameters["EmploymentId"] = employmentId;
        this.sessionService.NavigateTo('tabs.employee.payrolldata');
    };
    SalaryBatchPayslipsComponent.prototype.loadSalaryStatementDetails = function () {
        var _this = this;
        if (this.selectedSalaryStatement) {
            this.dataService
                .SalaryStatements_GetSimplifiedBatchRecords(this.selectedSalaryStatement.Id)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(function (data) {
                _this.salaryStatementDetails = data;
            });
        }
    };
    SalaryBatchPayslipsComponent.prototype.downloadSalaryStatement = function (type) {
        var _this = this;
        if (!this.flagDownloadSalaryStatement) {
            if (type === 'pdf') {
                if (this.sessionService.browser.isHybridApp ||
                    this.sessionService.browser.isIE ||
                    this.sessionService.browser.isEdge) {
                    this.dataService.SalaryStatements_GetPdf(this.selectedSalaryStatement.Id).subscribe(function (data) {
                        _this.downloadService.download('Gratisal.pdf', data);
                    });
                }
                else {
                    this.getPayslipReportByToken(this.selectedSalaryStatement.Id, 'pdf');
                }
            }
            else {
                if (type === 'htmlSameTab') {
                    var types = this.IsMobile ? 'html' : 'a4p';
                    this.dataService
                        .SalaryStatements_GetHtml(this.selectedSalaryStatement.Id, types)
                        .subscribe(function (content) {
                        _this.previewDialogVisible = true;
                        _this.previewContent = content;
                    });
                }
                else {
                    this.getPayslipReportByToken(this.selectedSalaryStatement.Id, 'html');
                }
            }
            this.flagDownloadSalaryStatement = true;
        }
        else {
            this.flagDownloadSalaryStatement = false;
        }
    };
    SalaryBatchPayslipsComponent.prototype.filterSalaryStatements = function () {
        var _this = this;
        if (this.searchText) {
            this.filteredSalaryStatements = this.salaryStatements.filter(function (s) {
                return s.UserFullName.toLocaleUpperCase().indexOf(_this.searchTextValue.toLocaleUpperCase()) > -1;
            });
        }
        else {
            this.filteredSalaryStatements = this.salaryStatements;
        }
        this.gridData = filterBy(this.filteredSalaryStatements, this.filter);
        this.selectDefaultSelectedPayslip();
    };
    SalaryBatchPayslipsComponent.prototype.selectDefaultSelectedPayslip = function (selectedPayslipId) {
        selectedPayslipId = selectedPayslipId
            ? selectedPayslipId
            : this.selectedSalaryStatement
                ? this.selectedSalaryStatement.Id
                : undefined;
        if (this.filteredSalaryStatements.length > 0) {
            var existedPayslip = selectedPayslipId
                ? this.filteredSalaryStatements.find(function (s) { return s.Id === selectedPayslipId; })
                : undefined;
            this.selectedSalaryStatement = existedPayslip ? existedPayslip : this.filteredSalaryStatements[0];
        }
        else {
            this.selectedSalaryStatement = undefined;
            this.salaryStatementDetails = undefined;
        }
    };
    SalaryBatchPayslipsComponent.prototype.getPayslipReportByToken = function (salaryStatementId, format) {
        var _this = this;
        this.dataService.SalaryStatements_GetPayslipDownloadToken(salaryStatementId).subscribe(function (token) {
            var languageCode = _this.sessionService.LanguageCode;
            var downloadUrl = "/api/salarystatements/" + salaryStatementId + "/token/" + token + "/lang/" + languageCode + "/format/" + format + "/Payslip";
            var newWindow = window.open(environment.apiUrl + downloadUrl, '_blank');
            if (!newWindow) {
                _this.newTabBlockedDialogVisible = true;
            }
        });
    };
    SalaryBatchPayslipsComponent.prototype.onDoubleClick = function (data) {
        if (data && data.UserEmploymentId) {
            this.sessionService.currentUserEmploymentId = data.UserEmploymentId;
            this.sessionService.NavigateTo('tabs.employee.payrolldata');
        }
    };
    SalaryBatchPayslipsComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridData = filterBy(this.filteredSalaryStatements, this.filter);
    };
    return SalaryBatchPayslipsComponent;
}());
export { SalaryBatchPayslipsComponent };
