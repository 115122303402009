import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'PriceFormat'
})
export class PriceFormatPipe implements PipeTransform {
  transform(value: number, useDashOnRoundNumbers?: boolean): string {
    if (useDashOnRoundNumbers) {
      if (value === 0) {
        return 'Price.Free'; //part of translations Local-build-locale-da.json f.ex.
      } else if (value % 1 === 0) {
        return value.toString() + ',-';
      } else if (value === undefined) {
        return 'undefined';
      } else {
        return value.toLocaleString('da-DK', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
    } else {
      if (isNaN(value) || !value) {
        value = 0;
      }
      return value.toLocaleString('da-DK', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
  }
}
