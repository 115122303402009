/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../CustomControls/Dialog/Dialog.component.ngfactory";
import * as i2 from "../../../CustomControls/Dialog/Dialog.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../Services/SharedServices/Session/SessionService";
import * as i5 from "../../../CustomControls/TextEditUpdate.component.ngfactory";
import * as i6 from "../../../CustomControls/TextEditUpdate.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../CustomControls/Dialog/DialogAction.component.ngfactory";
import * as i9 from "../../../CustomControls/Dialog/DialogAction.component";
import * as i10 from "./BatchFinalizationStep4Dialogue.component";
import * as i11 from "../../../Services/SharedServices/BusyIndicatorService";
import * as i12 from "../../../Services/RxDataService";
var styles_BatchFinalizationStep4DialogueComponent = [];
var RenderType_BatchFinalizationStep4DialogueComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BatchFinalizationStep4DialogueComponent, data: {} });
export { RenderType_BatchFinalizationStep4DialogueComponent as RenderType_BatchFinalizationStep4DialogueComponent };
export function View_BatchFinalizationStep4DialogueComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "app-dialog", [], null, [[null, "action"], [null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.onDialogAction($event) !== false);
        ad = (pd_0 && ad);
    } if (("visibleChange" === en)) {
        var pd_1 = ((_co.visible = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_DialogComponent_0, i1.RenderType_DialogComponent)), i0.ɵdid(1, 49152, null, 1, i2.DialogComponent, [i3.DomSanitizer, i4.SessionService, i0.ElementRef], { overlayClose: [0, "overlayClose"], visible: [1, "visible"] }, { visibleChange: "visibleChange", action: "action" }), i0.ɵqud(603979776, 1, { buttonElements: 1 }), (_l()(), i0.ɵeld(3, 0, null, 0, 0, "input", [["autocomplete", "username"], ["class", "input-no-display"], ["type", "text"]], [[8, "value", 0]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, 0, 2, "app-text-edit", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.password = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_TextEditUpdateComponent_0, i5.RenderType_TextEditUpdateComponent)), i0.ɵdid(5, 114688, null, 0, i6.TextEditUpdateComponent, [i4.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], label: [2, "label"], type: [3, "type"], autocorrect: [4, "autocorrect"] }, { valueChange: "valueChange" }), i0.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i8.View_DialogActionComponent_0, i8.RenderType_DialogActionComponent)), i0.ɵdid(8, 180224, [[1, 4]], 0, i9.DialogActionComponent, [i4.SessionService], { disabled: [0, "disabled"], type: [1, "type"] }, null), (_l()(), i0.ɵeld(9, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i8.View_DialogActionComponent_0, i8.RenderType_DialogActionComponent)), i0.ɵdid(10, 180224, [[1, 4]], 0, i9.DialogActionComponent, [i4.SessionService], { disabled: [0, "disabled"], type: [1, "type"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 4, "app-dialog", [], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.responseDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DialogComponent_0, i1.RenderType_DialogComponent)), i0.ɵdid(12, 49152, null, 1, i2.DialogComponent, [i3.DomSanitizer, i4.SessionService, i0.ElementRef], { leadingText: [0, "leadingText"], visible: [1, "visible"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 2, { buttonElements: 1 }), (_l()(), i0.ɵeld(14, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i8.View_DialogActionComponent_0, i8.RenderType_DialogActionComponent)), i0.ɵdid(15, 180224, [[2, 4]], 0, i9.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.visible; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.password; var currVal_4 = true; var currVal_5 = i0.ɵunv(_v, 5, 2, i0.ɵnov(_v, 6).transform("CompanySalaryBatches.PasswordLabel")); var currVal_6 = "password"; var currVal_7 = true; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.sessionService.isSubmitting; var currVal_9 = "Cancel"; _ck(_v, 8, 0, currVal_8, currVal_9); var currVal_10 = (!_co.password || _co.sessionService.isSubmitting); var currVal_11 = "Finalize"; _ck(_v, 10, 0, currVal_10, currVal_11); var currVal_12 = _co.response; var currVal_13 = _co.responseDialogVisible; _ck(_v, 12, 0, currVal_12, currVal_13); var currVal_14 = "Ok"; _ck(_v, 15, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.userName, ""); _ck(_v, 3, 0, currVal_2); }); }
export function View_BatchFinalizationStep4DialogueComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "batch-finalization-step-4-dialogue", [], null, null, null, View_BatchFinalizationStep4DialogueComponent_0, RenderType_BatchFinalizationStep4DialogueComponent)), i0.ɵdid(1, 49152, null, 0, i10.BatchFinalizationStep4DialogueComponent, [i11.BusyIndicatorService, i12.RxDataService, i4.SessionService], null, null)], null, null); }
var BatchFinalizationStep4DialogueComponentNgFactory = i0.ɵccf("batch-finalization-step-4-dialogue", i10.BatchFinalizationStep4DialogueComponent, View_BatchFinalizationStep4DialogueComponent_Host_0, { salaryBatch: "salaryBatch", doRecalculation: "doRecalculation", step1Completed: "step1Completed", visible: "visible" }, { visibleChange: "visibleChange", finalizationExit: "finalizationExit", finalizationDone: "finalizationDone" }, []);
export { BatchFinalizationStep4DialogueComponentNgFactory as BatchFinalizationStep4DialogueComponentNgFactory };
