<div
  *ngIf="!settingService.isReload"
  id="busyIndicator"
  class="BusyIndicator"
  [ngClass]="{ 'u-hidden': !busyIndicatorService.isVisible }"
>
  <div>
    <div id="indicatorIcon" class="BusyIndicator-spinner"></div>
    <div id="busyIndicatorText" class="BusyIndicator-text" [innerHTML]="busyIndicatorService.busyIndicatorText"></div>
  </div>
</div>

<app-loading-tips [tiptext]="loadingTipText" [visible]="isLoading"></app-loading-tips>

<div id="divMain" [ngClass]="{ WithDemoBanner: isDemo, iosAppMainDiv: isIOSMobileApp }">
  <div [ngClass]="{ 'menu-off': sessionService.menuToggled }">
    <!-- Header -->
    <div (click)="closeallmeudown()" id="tabs-header" class="Header background-test">
      <div class="Logo">
        <a *ngIf="!isIOSMobileApp" href="http://www.gratisal.com" target="_blank">
          <img src="images/gratisal-logo-horizontal.svg" alt="Gratisal" />
        </a>

        <img *ngIf="isIOSMobileApp" src="images/gratisal-logo-horizontal.svg" alt="Gratisal" />
      </div>

      <!-- Navigation -->
      <div [ngClass]="{ 'is-active': navigationExpanded }" class="Navigation-wrapper" id="js-navigation">
        <div class="Navigation">
          <div class="Navigation-inner">
            <div class="Navigation-expandWrapper">
              <button
                class="Navigation-link Navigation-expand MainTabButton"
                type="button"
                (click)="onToggleNavigation()"
              >
                <div>
                  <div *ngIf="sessionService.currentState.includes('tabs.employee')">
                    {{ 'Tabs.Employee' | translate }}
                  </div>
                  <div *ngIf="sessionService.currentState.includes('tabs.company')">
                    {{ 'Tabs.Company' | translate }}
                  </div>
                  <div *ngIf="sessionService.currentState.includes('tabs.selfservice')">
                    {{ 'Tabs.Self Service' | translate }}
                  </div>
                  <div *ngIf="sessionService.currentState.includes('tabs.accountant')">
                    {{ accountantTabName }}
                  </div>
                  <app-icon [icon]="'ChevronDown'" [size]="'xxsmall'"></app-icon>
                </div>
              </button>
            </div>
            <ul class="Navigation-list">
              <li class="Navigation-item" *ngIf="sessionService.feature.EmployeeTabEnabled">
                <button
                  id="EmployeeTabButton"
                  class="Navigation-link MainTabButton"
                  type="button"
                  (click)="navigateToState('tabs.employee')"
                  [ngClass]="{ 'is-active': sessionService.currentState.includes('tabs.employee') }"
                >
                  {{ 'Tabs.Employee' | translate }}
                </button>
              </li>

              <li class="Navigation-item" *ngIf="sessionService.feature.CompanyTabEnabled">
                <button
                  id="CompanyTabButton"
                  class="Navigation-link MainTabButton"
                  type="button"
                  (click)="navigateToState('tabs.company')"
                  [ngClass]="{ 'is-active': sessionService.currentState.includes('tabs.company') }"
                >
                  {{ 'Tabs.Company' | translate }}
                </button>
              </li>

              <li class="Navigation-item">
                <button
                  id="SelfServiceTabButton"
                  class="Navigation-link MainTabButton"
                  type="button"
                  (click)="navigateToState('tabs.selfservice')"
                  [ngClass]="{ 'is-active': sessionService.currentState.includes('tabs.selfservice') }"
                >
                  {{ 'Tabs.Self Service' | translate }}
                </button>
              </li>

              <li class="Navigation-item" *ngIf="sessionService.feature.AccountantTabEnabled">
                <button
                  id="AccountantTabButton"
                  class="Navigation-link MainTabButton"
                  type="button"
                  (click)="navigateToState('tabs.accountant')"
                  [ngClass]="{ 'is-active': sessionService.currentState.includes('tabs.accountant') }"
                >
                  {{ accountantTabName }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Navigation -->
      <!-- Settings -->
      <div class="Settings">
        <!-- Settings-company -->
        <div class="Settings-companyContainer">
          <button
            id="CompanySelectorButton"
            kendoTooltip
            [title]="Companytooltip"
            type="button"
            (click)="onToggleCompanyDropdown()"
            [ngClass]="showCompanyAbbreviation ? 'use-abbreviation' : ''"
          >
            <div>
              <img
                class="Settings-companyImage"
                alt="{{ currentCompanyName }}"
                *ngIf="logo"
                [src]="'data:image/jpeg;base64,' + logo"
              />
              <div class="Settings-companyName Display-companyName" *ngIf="!logo">{{ currentCompanyName }}</div>
              <div class="Settings-companyAbbreviation Avatar Avatar--circle Avatar--small">
                {{ currentCompanyAbbreviation }}
              </div>
              <app-icon *ngIf="!isSingleListcompanis" [icon]="'ChevronDown'" [size]="'xxsmall'"></app-icon>
            </div>
          </button>

          <app-company-selector
            [(visible)]="settingService.showCompanySelector"
            [(selectedCompanyId)]="selectedCompanyId"
            [companies]="companies"
            (companyChange)="onCompanyChange($event)"
          >
          </app-company-selector>
        </div>
        <!-- /Settings-company -->
        <!-- Settings-user -->
        <div class="Settings-userContainer">
          <button id="UserMenuButton" type="button" (click)="onToggleUserDropdown()">
            <div>
              <div class="Avatar Avatar--circle Avatar--small">
                <div
                  class="Avatar-bg"
                  *ngIf="avatar"
                  id="imgAvatar"
                  title="{{ fullName }}"
                  [ngStyle]="{ 'background-image': 'url(data:image/png;base64,' + avatar + ')' }"
                ></div>
                <!-- <img class="Avatar-bg" *ngIf="avatar" [title]="fullName" [src]="avatar" /> -->
              </div>
              <app-icon [icon]="'ChevronDown'" [size]="'xxsmall'"></app-icon>
            </div>
          </button>

          <div
            [ngClass]="{ 'is-active': sessionService.showUserMenuToggled }"
            class="Dropdown Dropdown--top Settings-userDropdown"
          >
            <ul (click)="stopAllProcess($event)" role="menu">
              <li class="Dropdown-item Dropdown-itemStatic">
                <div>{{ firstname }} {{ lastname }}</div>
              </li>
              <li class="Dropdown-item Dropdown-itemStatic">
                <div>{{ role }}</div>
              </li>
              <li class="Dropdown-item">
                <button
                  id="UserProfileButton"
                  class="ViewUserProfileBtn"
                  type="button"
                  (click)="navigateToState('tabs.account.userpreferences'); sessionService.showUserMenuToggled = false"
                >
                  {{ 'Preference.Option' | translate }}
                </button>
              </li>
              <li *ngIf="hasBackendAccess" class="Dropdown-item">
                <button
                  id="RegisterUsageButton"
                  class="ViewUserProfileBtn"
                  type="button"
                  (click)="onShowAdHocSupport(); sessionService.showUserMenuToggled = false"
                >
                  {{ 'BillableSupport.Register usage' | translate }}
                </button>
              </li>
              <li class="Dropdown-item">
                <button
                  id="ChangeAvatarButton"
                  class="ShowAvatarBtn"
                  type="button"
                  (click)="onShowAvatarDialog(); sessionService.showUserMenuToggled = false"
                >
                  {{ 'Account.SetPicture' | translate }}
                </button>
              </li>
              <li class="Dropdown-item">
                <button
                  id="ChangePasswordButton"
                  class="ChangePassWordBtn"
                  type="button"
                  (click)="onChangePasswordClick(); sessionService.showUserMenuToggled = false"
                >
                  {{ 'Account.ChangePassword' | translate }}
                </button>
              </li>
              <module-check
                [noPopup]="true"
                (showDialog)="showModuleDialog()"
                [moduleId]="5"
                [(hasModule)]="hasLanguageModule"
              >
                <li class="Dropdown-item">
                  <button
                    id="ChangeLanguageButton"
                    class="ChangeLanguageBtn"
                    type="button"
                    *ngIf="languageId === 1"
                    [disabled]="!hasLanguageModule"
                    (click)="onChangeLanguageDialogClick(); sessionService.showUserMenuToggled = false"
                  >
                    {{ 'Login.Changelanguage' | translate }} &nbsp;
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGBSURBVHjaYvzIwPCPAQqADIG/f/+9evVBUvIfmIuM/oDVAAQQC5DFUV0NVv4PiBgZGZl4eblLiv99/fb/z5//v38zgEkg+9/v3y83bQIIIBawtv//njxl+Pv3PxABwd+/f+8//PflM0jdr9//f//6/+sXUDWTrCzQdIAALM1BDgBADAFA/f+PSahm9+YwuAc4X3ev7cSiHz0ts0EjEVgBOBpzGwAAEAQS99/WDkgU7e95IWi+NuQ7VE03KHz7KiRykwKvAGIE2g90938wgBj//x/QRob/GICRienjhw8AAcTCAJdjAEOwvv/YACPIqH8AAcTyipmZNyvr7/37IFf9+sW1a9f/jx+/+Pr9+/wJ4h6IB4CyLEpKT86dAwggsA2QgAO6FUhCLPv1k+HnT6ggUMMfYOD+BXoV6AeAAAJ5+v/vP0ySkmBj/oICmZkZGIIMX74wQoL/zx+mv2DVf0GyAAHE+BQchZCIBCKxt2//PHr0xtAQLghJB5BoZmJgAAgwAAauWfWiVmegAAAAAElFTkSuQmCC"
                      title="Dansk"
                      alt="Dansk"
                    />
                  </button>
                  <button
                    id="ChangeLanguageButton"
                    class="ChangeLanguageBtn"
                    type="button"
                    *ngIf="languageId === 2"
                    [disabled]="!hasLanguageModule"
                    (click)="onChangeLanguageDialogClick(); sessionService.showUserMenuToggled = false"
                  >
                    {{ 'Login.Changelanguage' | translate }} &nbsp;
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAflJREFUeNpinDRzn5qN3uFDt16+YWBg+Pv339+KGN0rbVP+//2rW5tf0Hfy/2+mr99+yKpyOl3Ydt8njEWIn8f9zj639NC7j78eP//8739GVUUhNUNuhl8//ysKeZrJ/v7z10Zb2PTQTIY1XZO2Xmfad+f7XgkXxuUrVB6cjPVXef78JyMjA8PFuwyX7gAZj97+T2e9o3d4BWNp84K1NzubTjAB3fH0+fv6N3qP/ir9bW6ozNQCijB8/8zw/TuQ7r4/ndvN5mZgkpPXiis3Pv34+ZPh5t23//79Rwehof/9/NDEgMrOXHvJcrllgpoRN8PFOwy/fzP8+gUlgZI/f/5xcPj/69e/37//AUX+/mXRkN555gsOG2xt/5hZQMwF4r9///75++f3nz8nr75gSms82jfvQnT6zqvXPjC8e/srJQHo9P9fvwNtAHmG4f8zZ6dDc3bIyM2LTNlsbtfM9OPHH3FhtqUz3eXX9H+cOy9ZMB2o6t/Pn0DHMPz/b+2wXGTvPlPGFxdcD+mZyjP8+8MUE6sa7a/xo6Pykn1s4zdzIZ6///8zMGpKM2pKAB0jqy4UE7/msKat6Jw5mafrsxNtWZ6/fjvNLW29qv25pQd///n+5+/fxDDVbcc//P/zx/36m5Ub9zL8+7t66yEROcHK7q5bldMBAgwADcRBCuVLfoEAAAAASUVORK5CYII="
                      title="English"
                      alt="English"
                    />
                  </button>
                  <button
                    id="ChangeLanguageButton"
                    class="ChangeLanguageBtn"
                    type="button"
                    *ngIf="languageId === 3"
                    [disabled]="!hasLanguageModule"
                    (click)="onChangeLanguageDialogClick(); sessionService.showUserMenuToggled = false"
                  >
                    {{ 'Login.Changelanguage' | translate }} &nbsp;
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAIAAADkharWAAABVUlEQVR4AWP4/v37H6IBUDHD169f/yOBf7///Pv56/+/f/+xAaBihIa/n79+2n301cTFL3vmv1+149ezV/g0/P32/dWkxfdC8u8G5d4Lyr0bmPukpOvnw2c4NXzaf/KWQ9xNm6ibNtEQdMMi4mX/ArjbEBo+Pnzy68mLV5MX33ZNvO2egkCuSY9ym37cfvDr8Yufj58DEZABVMxwPaH8mobX86apv1+9/f3yDQK9ef9h497rhoHXdPygSNv3RmIFw2Wv1AvcRrecEoCKUNz79+/Tit4LPCYX+c2giM8UqJjhokfyBR7jC3ymj/Pbfj1/DVX89fubuWsuyziApHhN4OiiRwrDBbckoA0gxGt80yoKGDjPaife9cu+JGJ1gcsIqAGOgGqAihnOuSedEzA7J2gOQnwmZ3mNgegcrwlMEIGAIkDFDBfmLj9S13O0vpcgAioDKgYAALeZDJKBxD8AAAAASUVORK5CYII="
                      title="Greenlandic"
                      alt="Greenlandic"
                    />
                  </button>
                </li>
              </module-check>
              <li class="Dropdown-item Dropdown-itemDevider">
                <button id="LogoutButton" class="LogOutbtn" type="button" (click)="onLogout()">
                  {{ 'Account.Logout' | translate }}
                </button>
              </li>

              <li class="Dropdown-item Dropdown-itemDevider" *ngIf="environment === 'DEV'">
                <button type="button" (click)="onTestStatus400(); sessionService.showUserMenuToggled = false">
                  Test GS-104: Status 400
                </button>
              </li>
              <li class="Dropdown-item" *ngIf="environment === 'DEV'">
                <button type="button" (click)="onTestStatus404(); sessionService.showUserMenuToggled = false">
                  Test GS-104: Status 404
                </button>
              </li>
              <li class="Dropdown-item" *ngIf="environment === 'DEV'">
                <button type="button" (click)="onTestStatus500(); sessionService.showUserMenuToggled = false">
                  Test GS-104: Status 500
                </button>
              </li>
              <li class="Dropdown-item" *ngIf="environment === 'DEV'">
                <button type="button" (click)="onTestStatus401(); sessionService.showUserMenuToggled = false">
                  Test GS-104: Status 401
                </button>
              </li>
              <li class="Dropdown-item" *ngIf="environment === 'DEV'">
                <button type="button" (click)="onTestRandomStatus(); sessionService.showUserMenuToggled = false">
                  Test GS-104: Random Status
                </button>
              </li>
              <li class="Dropdown-item" *ngIf="environment === 'DEV'">
                <button type="button" (click)="onTestModalDialog(); sessionService.showUserMenuToggled = false">
                  Modal dialog
                </button>
              </li>
              <li class="Dropdown-item" *ngIf="environment === 'DEV' && !sessionService.requestLogEnabled">
                <button
                  type="button"
                  (click)="sessionService.requestLogEnabled = true; sessionService.showUserMenuToggled = false"
                >
                  Start API request logging
                </button>
              </li>
              <li class="Dropdown-item" *ngIf="environment === 'DEV' && sessionService.requestLogEnabled">
                <button
                  type="button"
                  (click)="sessionService.requestLogEnabled = false; sessionService.showUserMenuToggled = false"
                >
                  Stop API request logging
                </button>
              </li>
            </ul>
          </div>
        </div>
        <!-- /Settings-user -->
      </div>
      <!-- /Settings -->
    </div>
    <!-- /Header -->

    <div
      id="TabViewStateFlipScreenHint"
      class="FlipScreen-Hint AlertBox AlertBox--wrap"
      innerHTML="{{ 'Tabs.FlipScreenHint' | translate }}"
    ></div>

    <div
      (click)="onCloseCompanySeach()"
      *ngIf="
        sessionService.currentState.includes('tabs.account') && !sessionService.currentState.includes('tabs.accountant')
      "
      class="wrapper"
    >
      <account></account>
    </div>

    <div (click)="onCloseCompanySeach()" *ngIf="sessionService.currentState.includes('tabs.employee')" class="wrapper">
      <employee [(editMode)]="editMode"></employee>
    </div>

    <div (click)="onCloseCompanySeach()" *ngIf="sessionService.currentState.includes('tabs.company')" class="wrapper">
      <company [(editMode)]="editMode"></company>
    </div>

    <div
      (click)="onCloseCompanySeach()"
      *ngIf="sessionService.currentState.includes('tabs.accountant')"
      class="wrapper"
    >
      <app-accountant></app-accountant>
    </div>

    <div
      (click)="onCloseCompanySeach()"
      *ngIf="sessionService.currentState.includes('tabs.selfservice')"
      class="wrapper"
    >
      <selfservice [(editMode)]="editMode"></selfservice>
    </div>
  </div>
</div>

<language-chooser [(visible)]="showLanguageChooserDialog" (languageChanged)="changeLanguage($event)"></language-chooser>

<accept-dpa [(isVisible)]="showAcceptDPADialog" (accepted)="onDPATermAccepted($event)"></accept-dpa>

<change-password></change-password>

<changeavatar [(visible)]="changeAvatarDialog" [avatar]="avatar" (avatarChanged)="onAvatarChanged($event)">
</changeavatar>

<getstarted [hidden]="IsGreenlandCompany || !isFullAdmin || editMode" [editMode]="editMode"></getstarted>

<mobile-modal [(show)]="showcontentdialog">
  <mobile-modal-header>{{ 'Account.Logout' | translate }}</mobile-modal-header>
  <mobile-modal-content>
    Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />
    Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />
    Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />
    Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />
    Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />
    Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />Log out<br />
  </mobile-modal-content>
  <mobile-modal-footer>
    <button class="Button--secondary" (click)="raiseError()">Test AngularJS error</button>
    <button class="Button--primary" (click)="isTesting = !isTesting">
      {{ 'NewEmployee.OK' | translate }}
    </button>
  </mobile-modal-footer>
</mobile-modal>

<div id="divHintForEdit" *ngIf="doubleClickToEditHintVisible">
  <div class="inner-div">
    <div *ngIf="!isMobile">
      {{ 'Tips.DoubleClickToEdit' | translate }}
    </div>

    <div *ngIf="isMobile">
      {{ 'Tips.DoubleTapToEdit' | translate }}
    </div>
  </div>
</div>

<app-dialog
  [title]="settingService.moduleMessageDialogTitle"
  (action)="settingService.onModuleMessageDialogAction($event)"
  [(visible)]="settingService.moduleMessageDialogVisible"
>
  <div [innerHTML]="'<p>' + settingService.moduleMessage + '</p>'"></div>
  <app-dialog-action *ngIf="settingService.isShowReadMoreWelcomMessage" [type]="'Close'"></app-dialog-action>
  <app-dialog-action *ngIf="settingService.isShowReadMoreWelcomMessage" [type]="'ReadMore'"></app-dialog-action>
  <app-dialog-action *ngIf="!settingService.isShowReadMoreWelcomMessage" [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="settingService.onNavigationMessageDialogAction($event)"
  [(visible)]="settingService.navigationMessageDialogVisible"
>
  <div [innerHTML]="settingService.navigationMessage"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
  <app-dialog-action [type]="'ReadMore'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onSaveAdHocSupport($event)"
  [overlayClose]="false"
  [(visible)]="isShowAdHocSupport"
  [minWidth]="380"
>
  <div class="FormElement">
    <combobox-edit-control
      [editMode]="true"
      [label]="'BillableSupport.Module' | translate"
      [comboboxDataSource]="moduleAdHocSupport"
      [idField]="'ModuleId'"
      [textField]="'ModuleName'"
      [(value)]="billingUsage.ModuleId"
      (valueChange)="onBillingPrincipChange()"
      (blur)="onBillingPrincipChange()"
    >
    </combobox-edit-control>
  </div>
  <div class="FormElement">
    <combobox-edit-control
      [editMode]="true"
      [label]="'AccountModules.BillingPrinciple' | translate"
      [comboboxDataSource]="billingPrinciple"
      [idField]="'Id'"
      [textField]="'Name'"
      [(value)]="billingUsage.BillingPrincipleId"
    ></combobox-edit-control>
  </div>
  <div class="FormElement">
    <numeric-edit
      [editMode]="true"
      [(value)]="billingUsage.Units"
      [label]="'EmploymentPayrollData.Units' | translate"
      [option]="NumericOptions"
      (blur)="onCaculateAmount()"
    >
    </numeric-edit>
  </div>
  <div class="FormElement">
    <numeric-edit
      [editMode]="true"
      [(value)]="billingUsage.AmountPerUnit"
      [label]="'EmploymentPayrollData.Per Unit' | translate"
      [option]="NumericOptions"
      (blur)="onCaculateAmount()"
    >
    </numeric-edit>
  </div>
  <div class="FormElement">
    <numeric-edit
      [editMode]="true"
      [(value)]="billingUsage.Amount"
      [label]="'EmploymentPayrollData.Amount' | translate"
      [option]="NumericOptions"
      [disable]="true"
    >
    </numeric-edit>
  </div>
  <div class="FormElement">
    <date-picker-control
      [e2e]="'BillingDate'"
      [editMode]="true"
      [label]="'BillableSupport.BillingDate' | translate"
      [(value)]="billingUsage.BillingDate"
    >
    </date-picker-control>
  </div>
  <div class="FormElement">
    <label class="FormElement-label">{{ 'EmploymentTime.Description' | translate }}</label>
    <br />
    <textarea class="FormElement-input" [(ngModel)]="billingUsage.Description"></textarea>
  </div>

  <div
    class="warningText"
    *ngIf="hasModuleKey('PhoneSupport')"
    [innerHTML]="'BillableSupport.PhoneSupport' | translate"
  ></div>
  <div
    class="warningText"
    *ngIf="hasModuleKey('EmailSupport') && isShowEmailSupport"
    [innerHTML]="'BillableSupport.EmailSupport' | translate"
  ></div>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Save'"></app-dialog-action>
</app-dialog>

<module-check #userLanguageModuleCheck [moduleId]="5"></module-check>

<app-dialog [(visible)]="sessionService.isShowHugeFeaturesAlert">
  <div id="HugeFeaturesAlert" [innerHTML]="'GlobalDialog.HugeFeaturesAlert' | translate"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="isDetectedAdBlock">
  <div [innerHTML]="'GlobalDialog.AddblockDeteced' | translate"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="isDetectedCookieDisable">
  <div [innerHTML]="'GlobalDialog.CookieDisableDetected' | translate"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="isDetecGoogleTranslate" (action)="onDisableDetect($event)">
  <div [innerHTML]="'GlobalDialog.DetecedGoogleTranslate' | translate"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<relogin></relogin>

<app-dialog [title]="modalService.title" [(visible)]="modalService.alertVisible" [minWidth]="380">
  <div>
    <p [innerHTML]="modalService.message"></p>
  </div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
