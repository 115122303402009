import { EventEmitter } from '@angular/core';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
var LockSalaryBatchComponent = /** @class */ (function () {
    function LockSalaryBatchComponent(dataService, sessionService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.visibleChange = new EventEmitter();
        this.batchLocked = new EventEmitter();
        this.notifyEmployee = true;
    }
    Object.defineProperty(LockSalaryBatchComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (value !== this.visibleValue) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
                if (value) {
                    this.notifyEmployee = true;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    LockSalaryBatchComponent.prototype.onDialogAction = function (action) {
        var _this = this;
        // switch (action) {
        //     case "Lock":
        //         this.dataService.SalaryBatches_LockSalaryBatch(this.salaryBatchId).subscribe(() => {
        //             this.batchLocked.emit();
        //             this.visible = false;
        //         });
        //         break;
        //     case "LockAndGrantAccess":
        //         this.dataService.SalaryBatches_ApproveSalaryBatchPreliminary(this.salaryBatchId).subscribe(() => {
        //             this.batchLocked.emit();
        //             this.visible = false;
        //         });
        //         break;
        // }
        if (action === 'SubmitForApproval') {
            if (this.notifyEmployee) {
                this.dataService.SalaryBatches_ApproveSalaryBatchWithPreliminaryPayslips(this.salaryBatchId).subscribe(function () {
                    _this.batchLocked.emit();
                    _this.visible = false;
                });
            }
            else {
                this.dataService.SalaryBatches_ApproveSalaryBatch(this.salaryBatchId).subscribe(function () {
                    _this.batchLocked.emit();
                    _this.visible = false;
                });
            }
        }
    };
    return LockSalaryBatchComponent;
}());
export { LockSalaryBatchComponent };
