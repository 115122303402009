<div class="Card-inner">
  <form>
    <fieldset *ngIf="company && company.Address" class="Form--horizontal">
      <legend class="Card-title">{{ 'CompanyGeneral.Contact Info' | translate }}</legend>

      <app-picture-edit
        [label]="'CompanyGeneral.Logo' | translate"
        [hint]="'CompanyGeneral.MaximumSizeOfProfilePictureMessage' | translate"
        [changeText]="'CompanyGeneral.Change logo' | translate"
        [editMode]="editMode"
        [imageSource]="logo"
        (change)="onCompanyLogoChanged($event)"
      ></app-picture-edit>

      <app-text-edit
        [label]="'CompanyGeneral.Name' | translate"
        [editMode]="editMode"
        [(value)]="company.Name"
        [required]="true"
        [(isValid)]="childIsValid[0]"
        [(isDirty)]="childIsDirty[0]"
        (valueChange)="onChange()"
      ></app-text-edit>

      <app-address
        [editMode]="editMode"
        [address]="company.Address"
        (valueChange)="onChange()"
        [(isDirty)]="childIsDirty[1]"
      ></app-address>

      <app-text-edit
        [(isDirty)]="childIsDirty[2]"
        [label]="'CompanyGeneral.TelMobile' | translate"
        [editMode]="editMode"
        [(value)]="company.TelMobile"
        (valueChange)="onChange()"
        [type]="'number'"
      ></app-text-edit>

      <app-text-edit
        [(isDirty)]="childIsDirty[3]"
        [label]="'CompanyGeneral.TelFixed' | translate"
        [editMode]="editMode"
        [(value)]="company.TelFixed"
        (valueChange)="onChange()"
        [type]="'number'"
      ></app-text-edit>

      <app-text-edit
        [(isDirty)]="childIsDirty[4]"
        [label]="'CompanyGeneral.Email' | translate"
        [editMode]="editMode"
        [required]="true"
        type="email"
        [(value)]="company.Email1"
        [(isValid)]="childIsValid[1]"
        (valueChange)="onChange()"
      ></app-text-edit>

      <app-text-edit
        [(isDirty)]="childIsDirty[5]"
        [label]="'CompanyGeneral.EmailInvoice' | translate"
        [editMode]="editMode"
        type="email"
        [(value)]="company.InvoiceEmail"
        (valueChange)="onChange()"
        [(isValid)]="childIsValid[2]"
      ></app-text-edit>

      <combobox-edit-control
        [label]="'CompanyGeneral.Main contact' | translate"
        [(isDirty)]="childIsDirty[6]"
        [editMode]="editMode"
        [comboboxDataSource]="contacts"
        [textField]="'FullName'"
        [idField]="'Id'"
        [nullValueLabel]="'DropdownList.None' | translate"
        [(value)]="company.MainContactCompanyUserId"
        (valueChange)="onChange()"
      ></combobox-edit-control>
    </fieldset>
  </form>
</div>
