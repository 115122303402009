import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Global } from '../../../../Common/Global';
import { ExtendSalaryPeriod } from '../../../../Model/ExtendSalaryPeriod';
import { RxDataService } from '../../../../Services/RxDataService';
import { DialogService } from '../../../../Services/SharedServices/Dialog/DialogService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
var PayrollPreviewComponent = /** @class */ (function () {
    function PayrollPreviewComponent(dataService, sessionService, dialog, translate) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.dialog = dialog;
        this.translate = translate;
        this.recalculatePreviewValue = false;
        this.recalculatePreviewChange = new EventEmitter();
        this.periodsNameChange = new EventEmitter();
        this.totalValueChange = new EventEmitter();
        this.closePreviewArea = new EventEmitter();
        this.isMobilePayrollDataSlide = false;
        this.isHidePreviewGrid = false;
        this.isWeb = isWeb;
        this.gridData = [];
        this.companypreference = Global.COMPANY_PREFERENCES;
        this.flagisDownloading = false;
        this.companypreference = Global.COMPANY_PREFERENCES;
    }
    Object.defineProperty(PayrollPreviewComponent.prototype, "userEmployment", {
        get: function () {
            return this.userEmploymentValue;
        },
        set: function (value) {
            if (this.userEmploymentValue !== value) {
                this.userEmploymentValue = value;
                this.loadPreviewData(value ? value.Id : undefined, value ? value.SalaryCycleId : undefined);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollPreviewComponent.prototype, "recalculatePreview", {
        get: function () {
            return this.recalculatePreviewValue;
        },
        set: function (value) {
            var _this = this;
            if (value) {
                this.loadSalaryStatementPreview(this.userEmployment ? this.userEmployment.Id : undefined, this.periodId);
                setTimeout(function () {
                    _this.recalculatePreviewValue = false;
                    _this.recalculatePreviewChange.emit(false);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollPreviewComponent.prototype, "periodsName", {
        get: function () {
            return this.currentperiodfriendlyName;
        },
        set: function (value) {
            this.currentperiodfriendlyName = value;
            this.periodsNameChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollPreviewComponent.prototype, "totalValue", {
        get: function () {
            return this.currentTotal;
        },
        set: function (value) {
            this.currentTotal = value;
            this.totalValueChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    PayrollPreviewComponent.prototype.onClosePreview = function () {
        this.closePreviewArea.emit();
    };
    Object.defineProperty(PayrollPreviewComponent.prototype, "classPayrollDataSlidePayrollDataSlide", {
        get: function () {
            if (this.isMobilePayrollDataSlide) {
                return 'classPayrollDataSlidePayrollDataSlide';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollPreviewComponent.prototype, "hideClass", {
        get: function () {
            if (this.isHidePreviewGrid) {
                return 'hidePreviewGrid';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollPreviewComponent.prototype, "isHidden", {
        get: function () {
            if (!this.sessionService.browser.isHybridApp) {
                return 'Icon--Payslip--hide';
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollPreviewComponent.prototype, "IsAppOrDesktop", {
        get: function () {
            return !this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollPreviewComponent.prototype, "IsWeb", {
        get: function () {
            return !this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    PayrollPreviewComponent.prototype.onPeriodChanged = function () {
        this.loadSalaryStatementPreview(this.userEmploymentValue.Id, this.periodId);
    };
    PayrollPreviewComponent.prototype.downloadSalaryStatementsPreview = function (isPopup) {
        var _this = this;
        if (!this.flagisDownloading) {
            var outType = isPopup && this.isWeb ? 'a4p' : 'html';
            this.dataService
                .SalaryStatements_GetHtmlPreview(outType, this.salaryStatement)
                .subscribe(function (data) {
                if (!isPopup) {
                    var newWindow = window.open('about:blank');
                    if (newWindow) {
                        newWindow.document.write(data);
                        newWindow.document.close();
                    }
                    else {
                        _this.dialog.showMessageBox(_this.translate.instant('Report.NewTabBlocked'), ['Ok']);
                    }
                }
                else {
                    _this.dialog.showIFrameDialog(data);
                }
            });
            this.flagisDownloading = true;
        }
        else {
            this.flagisDownloading = false;
        }
    };
    PayrollPreviewComponent.prototype.loadPreviewData = function (userEmploymentId, salaryCycleId) {
        if (!userEmploymentId || !salaryCycleId) {
            return;
        }
        this.getPeriods(userEmploymentId, salaryCycleId);
    };
    PayrollPreviewComponent.prototype.getPeriods = function (employmentId, cycleId) {
        var _this = this;
        this.dataService.SalaryBatches_GetSalaryPeriods(cycleId).subscribe(function (periods) {
            var dataSource = [];
            if (periods) {
                _this.companypreference = Global.COMPANY_PREFERENCES;
                var preference = _this.companypreference.find(function (model) { return model.Key === 'SalaryPeriods.FriendlyNames'; });
                var useFriendlyNames = preference && preference.Value && preference.Value === 'true';
                var isUserFriendlyname_1 = _this.companypreference
                    ? _this.companypreference.find(function (model) { return model.Key === 'SalaryPeriods.FriendlyNames'; })
                    : undefined;
                periods.forEach(function (period, key) {
                    var startDate = _this.sessionService.toString(period.StartDate ? new Date(period.StartDate) : undefined);
                    var endDate = _this.sessionService.toString(period.EndDate ? new Date(period.EndDate) : undefined);
                    var item = new ExtendSalaryPeriod();
                    item.Id = period.Id;
                    item.StartDate = period.StartDate;
                    item.EndDate = period.EndDate;
                    item.Period = startDate + ' - ' + endDate;
                    item.FriendlyName =
                        isUserFriendlyname_1 && isUserFriendlyname_1.Value && isUserFriendlyname_1.Value === 'true'
                            ? ' ' + period.FriendlyName
                            : startDate + ' - ' + endDate;
                    dataSource[key] = item;
                });
            }
            _this.periods = dataSource;
            _this.getSuggestPeriod(employmentId, cycleId);
        });
    };
    PayrollPreviewComponent.prototype.getSuggestPeriod = function (employmentId, cycleId) {
        var _this = this;
        this.dataService.SalaryBatches_SuggestSalaryPeriod(cycleId).subscribe(function (period) {
            if (!period) {
                if (_this.periods && _this.periods.length > 0) {
                    _this.periodId = _this.periods[0].Id;
                }
                return;
            }
            _this.periodId = period.Id;
            if (_this.periods && _this.periods.length > 0) {
                var objectPeriods = _this.periods.find(function (model) { return model.Id === _this.periodId; });
                if (objectPeriods && objectPeriods.FriendlyName) {
                    _this.periodsName = objectPeriods.FriendlyName;
                }
            }
            _this.loadSalaryStatementPreview(employmentId, period.Id);
        });
    };
    PayrollPreviewComponent.prototype.loadSalaryStatementPreview = function (userEmploymentId, periodId) {
        var _this = this;
        this.gridData = [];
        if (userEmploymentId && periodId) {
            if (this.currentuserEmploymentId !== userEmploymentId || this.currentuserperiodId !== periodId || this.isUpdate) {
                this.currentuserEmploymentId = userEmploymentId;
                this.currentuserperiodId = periodId;
            }
            else {
                return;
            }
            this.dataService
                .SalaryStatements_GetSingleEmploymentPreviewByPeriod(userEmploymentId, periodId)
                .subscribe(function (salaryStatement) {
                if (!salaryStatement) {
                    _this.gridData = [];
                    _this.payslipIconsVisible = false;
                    _this.totalValue = '0';
                    return;
                }
                _this.salaryStatement = salaryStatement;
                _this.payslipIconsVisible =
                    salaryStatement.SalaryBatchRecords && salaryStatement.SalaryBatchRecords.length > 0;
                _this.gridData = salaryStatement.SalaryBatchRecords;
                if (_this.gridData && _this.gridData.length > 0) {
                    var objectTotal = _this.gridData.filter(function (model) { return model.SpecialIdentifier === 'PAYOUT'; });
                    if (objectTotal && objectTotal.length === 1 && objectTotal[0] && objectTotal[0].Amount) {
                        _this.totalValue = objectTotal[0].Amount.toString();
                    }
                }
            });
        }
    };
    return PayrollPreviewComponent;
}());
export { PayrollPreviewComponent };
