import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { Address, AddUserToCompanyRequest, CompanyUser } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { NewUserViewModel } from './NewUserViewModel';
var NewEmployeeComponent = /** @class */ (function () {
    function NewEmployeeComponent(translateService, dataService, staticDataService, sessionService) {
        this.translateService = translateService;
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.newEmployeeCreated = new EventEmitter();
        this.isVisibleChange = new EventEmitter();
        this.hasLanguageModule = false;
        this.visible = false;
        this.showOptions = true;
        this.isValidDate = true;
        this.accountName = '';
        this.ngUnsubscribe = new Subject();
        this.isShowForEmploymentTemplateId = true;
        this.isnoCPRWarning = false;
        this.isNonCreateMail = false;
        this.isInvalid = false;
    }
    Object.defineProperty(NewEmployeeComponent.prototype, "isVisible", {
        get: function () {
            return this.visible;
        },
        set: function (value) {
            if (value === true && this.visible !== value) {
                this.onShowNewUserWindows();
                this.createNewCompanyUser();
            }
            this.visible = value;
            this.isVisibleChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEmployeeComponent.prototype, "showAddToCompany", {
        get: function () {
            return this.sessionService.role.IsAccountAdmin && this.viewModel.AddUserCompanyRequest.Details.RoleId >= 30;
        },
        enumerable: true,
        configurable: true
    });
    NewEmployeeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.taxCardTypes = data;
            _this.initModel();
        });
        this.staticDataService.City.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.cities = data); });
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            _this.translateText();
        });
        this.translateText();
        this.staticDataService.Municipality.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (municipalities) {
            _this.Municipality =
                municipalities && municipalities.length
                    ? municipalities.filter(function (muni) {
                        return Global.SESSION && muni.CountryId === Global.SESSION.CurrentCountryId;
                    })
                    : undefined;
        });
        if (this.sessionService.role.IsAccountAdmin) {
            this.staticDataService.currentAccount.subscribe(function (account) {
                if (account) {
                    _this.accountName = account.Name;
                }
            });
        }
    };
    NewEmployeeComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    NewEmployeeComponent.prototype.initModel = function () {
        this.viewModel = new NewUserViewModel();
        this.viewModel.AddUserCompanyRequest = new AddUserToCompanyRequest();
        this.viewModel.AddUserCompanyRequest.Details = new CompanyUser();
        this.viewModel.AddUserCompanyRequest.Details.Address = new Address();
    };
    Object.defineProperty(NewEmployeeComponent.prototype, "employmentTemplateSelected", {
        get: function () {
            return (this.viewModel.AddUserCompanyRequest.EmploymentTemplateId &&
                this.viewModel.AddUserCompanyRequest.EmploymentTemplateId !== -1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEmployeeComponent.prototype, "IsGreenLandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEmployeeComponent.prototype, "IsPrimanyTaxCard", {
        get: function () {
            return this.viewModel.AddUserCompanyRequest.TaxCardTypeId === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEmployeeComponent.prototype, "IsFreeTaxCard", {
        get: function () {
            return this.viewModel.AddUserCompanyRequest.TaxCardTypeId === 3;
        },
        enumerable: true,
        configurable: true
    });
    NewEmployeeComponent.prototype.onShowNewUserWindows = function () {
        var _this = this;
        this.initModel();
        //this.dataService.Companies_GetCurrent().subscribe((company: ICompany): void => { });
        this.staticDataService.employmentTitle.subscribe(function (titles) {
            _this.viewModel.Titles = titles;
        });
        this.staticDataService.companyUsers
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) { return _this.setManagerDataSource(JSON.parse(JSON.stringify(data))); });
        this.staticDataService.Role.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.viewModel.Roles = data); });
        this.staticDataService.Country.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.viewModel.Countries = data); });
        this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.viewModel.Languages = data;
            var defaultLanguage = _this.viewModel.Languages && _this.viewModel.Languages.length
                ? _this.viewModel.Languages.find(function (language) { return language.Id === 1; })
                : undefined;
            _this.defaultLanguageName = defaultLanguage ? defaultLanguage.Name : undefined;
        });
        this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.viewModel.Departments = JSON.parse(JSON.stringify(data));
        });
        this.viewModel.NoCPRNumber = false;
        this.onNoCPRCheckboxClicked(undefined); // Reset form based on NoCPRNumber condition.
        if (this.sessionService.feature.ShowEmployeeEmploymentTemplate) {
            // Get Templates
            this.dataService
                .EmploymentTemplates_GetEmploymentTemplates()
                .subscribe(function (templates) {
                _this.onGetTemplatesCompletedEvent(templates);
            });
        }
        this.createWithoutEmail = false;
    };
    NewEmployeeComponent.prototype.setManagerDataSource = function (data) {
        this.managers = data;
    };
    NewEmployeeComponent.prototype.translateText = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService.get(['NewEmployee.DefaultTitle']).subscribe(function (translations) {
                _this.defaultTitle = translations['NewEmployee.DefaultTitle'];
                _this.viewModel.TaxCardTypes = _this.getTaxCardTypeComboboxDataSource();
            });
        });
    };
    NewEmployeeComponent.prototype.getEmploymentTemplateComboboxDataSource = function (templates) {
        // let nullableItem: IUserEmploymentTemplate = { Id: -1, Name: this.nullPositionText };
        // templates.unshift(nullableItem);
        return templates;
    };
    NewEmployeeComponent.prototype.getTaxCardTypeComboboxDataSource = function () {
        var taxCardTypes = this.taxCardTypes;
        return taxCardTypes;
    };
    NewEmployeeComponent.prototype.onDepartmentComboBoxChanged = function () {
        this.selectDefaultManager(this.viewModel.AddUserCompanyRequest.DepartmentId);
    };
    NewEmployeeComponent.prototype.selectDefaultManager = function (departmentId) {
        if (departmentId === -1) {
            this.viewModel.AddUserCompanyRequest.ManagerCompanyUserId = -1;
            return;
        }
        var department = this.viewModel.Departments
            ? this.viewModel.Departments.find(function (dpm) { return dpm.Id === departmentId; })
            : undefined;
        if (department) {
            this.viewModel.AddUserCompanyRequest.ManagerCompanyUserId = department.ManagerCompanyUserId
                ? department.ManagerCompanyUserId
                : -1;
        }
    };
    NewEmployeeComponent.prototype.onPostalCodeChanged = function (value) {
        this.autoFillCity(value);
    };
    NewEmployeeComponent.prototype.autoFillCity = function (postalCode) {
        var _this = this;
        if (!postalCode) {
            return;
        }
        var city = this.cities
            ? this.cities.find(function (c) {
                return (c.CountryId === _this.viewModel.AddUserCompanyRequest.Details.Address.CountryId &&
                    c.PostalCode &&
                    postalCode === c.PostalCode.toString());
            })
            : undefined;
        if (!city) {
            city = this.cities
                ? this.cities.find(function (c) {
                    return c.PostalCode && postalCode === c.PostalCode.toString();
                })
                : undefined;
        }
        if (city) {
            this.viewModel.AddUserCompanyRequest.Details.Address.City = city.Key;
            this.autoFillMunicipality(city.Key);
            if (city.CountryId !== this.viewModel.AddUserCompanyRequest.Details.Address.CountryId) {
                this.viewModel.AddUserCompanyRequest.Details.Address.CountryId = city.CountryId;
                this.setDefaultApplyDanishRulesInGreenland();
            }
        }
    };
    NewEmployeeComponent.prototype.autoFillPostalCode = function (cityName) {
        if (!cityName || !this.cities) {
            return;
        }
        var city = this.cities.find(function (c) { return cityName === c.Key; });
        if (city) {
            this.viewModel.AddUserCompanyRequest.Details.Address.PostalCode = city.PostalCode;
        }
    };
    NewEmployeeComponent.prototype.onCityBlurChange = function (value) {
        this.autoFillMunicipality(this.viewModel.AddUserCompanyRequest.Details.Address.City);
    };
    NewEmployeeComponent.prototype.autoFillMunicipality = function (cityName) {
        if (!cityName || !this.IsGreenLandCompany || !this.cities) {
            return;
        }
        var city = this.cities.find(function (filterCity) { return filterCity.Key && cityName.toLowerCase() === filterCity.Key.toString().toLowerCase(); });
        if (city &&
            city.MunicipalityId &&
            this.viewModel.AddUserCompanyRequest.Details.MunicipalityId !== city.MunicipalityId) {
            this.viewModel.AddUserCompanyRequest.Details.MunicipalityId = city.MunicipalityId;
            this.updateDefaultTaxRate();
        }
    };
    NewEmployeeComponent.prototype.onMunicipalityChanged = function () {
        this.updateDefaultTaxRate();
    };
    NewEmployeeComponent.prototype.onCountryChange = function () {
        this.setDefaultApplyDanishRulesInGreenland();
        var country = this.viewModel.AddUserCompanyRequest.Details.Address.CountryId;
        if (country !== Constants.DENMARK_COUNTRY_ID && country !== Constants.GREENLAND_COUNTRY_ID) {
            this.viewModel.AddUserCompanyRequest.Details.MunicipalityId = 8;
            this.updateDefaultTaxRate();
        }
    };
    NewEmployeeComponent.prototype.onEmploymentTemplateChanged = function () {
        if (this.viewModel.AddUserCompanyRequest.EmploymentTemplateId) {
            this.isShowForEmploymentTemplateId = true;
        }
        else {
            this.isShowForEmploymentTemplateId = false;
        }
        this.updateDefaultTaxRate();
    };
    NewEmployeeComponent.prototype.updateDefaultTaxRate = function () {
        var _this = this;
        if (this.IsGreenLandCompany ||
            this.employmentTemplateSelected ||
            this.viewModel.AddUserCompanyRequest.Details.MunicipalityId) {
            var muni = this.Municipality
                ? this.Municipality.find(function (mun) { return mun.Id === _this.viewModel.AddUserCompanyRequest.Details.MunicipalityId; })
                : undefined;
            if (muni) {
                this.viewModel.AddUserCompanyRequest.TaxRate = muni.DefaultTaxRate;
            }
        }
    };
    NewEmployeeComponent.prototype.createNewCompanyUser = function () {
        this.viewModel.AddUserCompanyRequest.Details.IsActive = true;
        this.viewModel.AddUserCompanyRequest.Details.RoleId = 20;
        this.viewModel.AddUserCompanyRequest.Title = this.defaultTitle;
        this.viewModel.AddUserCompanyRequest.HireDate = this.staticDataService.getCurrentdate();
        this.viewModel.AddUserCompanyRequest.Details.Address.CountryId =
            Global.COMPANY && Global.COMPANY.CountryId ? Global.COMPANY.CountryId : Constants.DENMARK_COUNTRY_ID;
        this.setDefaultApplyDanishRulesInGreenland();
        //this.viewModel.AddUserCompanyRequest.LanguageId = 1;
        this.viewModel.AddUserCompanyRequest.LanguageId = Global.COMPANY ? Global.COMPANY.DefaultLanguageId : 1;
        this.viewModel.AddUserCompanyRequest.DepartmentId =
            this.viewModel.Departments && this.viewModel.Departments.length > 1 ? -1 : undefined; // Department has None by default.
        this.viewModel.AddUserCompanyRequest.ManagerCompanyUserId = -1;
        var taxCardTypes = this.taxCardTypes;
        this.viewModel.AddUserCompanyRequest.TaxCardTypeId = taxCardTypes.length > 1 ? taxCardTypes[0].Id : undefined;
        if (this.viewModel.Templates && this.viewModel.Templates.length > 1) {
            this.viewModel.AddUserCompanyRequest.EmploymentTemplateId = this.viewModel.Templates[1].Id;
        }
        else {
            this.viewModel.AddUserCompanyRequest.EmploymentTemplateId = undefined;
        }
        this.viewModel.AddUserCompanyRequest.Details.CompanyId = Global.COMPANY_ID;
    };
    NewEmployeeComponent.prototype.setDefaultApplyDanishRulesInGreenland = function () {
        if (this.IsGreenLandCompany) {
            this.viewModel.AddUserCompanyRequest.ApplyDanishRulesInGreenland =
                this.viewModel.AddUserCompanyRequest.Details.Address.CountryId === Constants.DENMARK_COUNTRY_ID;
        }
    };
    NewEmployeeComponent.prototype.onGetTemplatesCompletedEvent = function (templates) {
        if (templates && templates.length > 0) {
            this.viewModel.Templates = this.getEmploymentTemplateComboboxDataSource(templates);
        }
        this.createNewCompanyUser();
        this.viewModel.AddUserCompanyRequest.EmploymentTemplateId =
            this.viewModel.Templates && this.viewModel.Templates.length
                ? this.viewModel.Templates.length > 1
                    ? this.viewModel.Templates[1].Id
                    : this.viewModel.Templates[0].Id
                : undefined;
    };
    NewEmployeeComponent.prototype.onNoCPRCheckboxClicked = function (event) {
        if (this.viewModel.NoCPRNumber) {
            this.isnoCPRWarning = true;
            if (this.viewModel.AddUserCompanyRequest) {
                this.viewModel.AddUserCompanyRequest.IdentityNumber = '';
                this.viewModel.AddUserCompanyRequest.EmploymentTemplateId = undefined;
            }
        }
        else if (this.viewModel.AddUserCompanyRequest) {
            this.viewModel.AddUserCompanyRequest.EmploymentTemplateId = this.viewModel.Templates
                ? this.viewModel.Templates.length > 1
                    ? this.viewModel.Templates[1].Id
                    : this.viewModel.Templates[0].Id
                : undefined;
        }
    };
    NewEmployeeComponent.prototype.raiseCreateNewUserCompletedEvent = function (companyUser) {
        if (companyUser && companyUser.CompanyUser) {
            this.newEmployeeCreated.emit(companyUser.CompanyUser);
        }
        else {
            this.newEmployeeCreated.emit(companyUser);
        }
    };
    NewEmployeeComponent.prototype.onCreateNew = function () {
        if (!this.viewModel.AddUserCompanyRequest.Details.PersonalEmail &&
            !this.viewModel.AddUserCompanyRequest.Details.CompanyEmail) {
            this.isNonCreateMail = true;
        }
        else {
            this.createNew();
        }
    };
    NewEmployeeComponent.prototype.createNew = function () {
        var _this = this;
        if (!this.validateInput()) {
            return;
        }
        this.viewModel.AddUserCompanyRequest.Details.IsActive = true;
        this.viewModel.AddUserCompanyRequest.Details.CompanyId = Global.COMPANY_ID;
        var hireDate = this.viewModel.AddUserCompanyRequest.HireDate;
        if (this.viewModel.AddUserCompanyRequest.EmploymentTemplateId === -1 ||
            !this.viewModel.AddUserCompanyRequest.EmploymentTemplateId) {
            this.viewModel.AddUserCompanyRequest.EmploymentTemplateId = undefined;
            this.viewModel.AddUserCompanyRequest.Title = undefined;
            this.viewModel.AddUserCompanyRequest.DepartmentId = undefined;
            this.viewModel.AddUserCompanyRequest.TaxCardTypeId = undefined;
            this.viewModel.AddUserCompanyRequest.ManagerCompanyUserId = undefined;
        }
        // If the user selects None for department it should be set to null for the api call
        if (this.viewModel.AddUserCompanyRequest.DepartmentId === -1) {
            this.viewModel.AddUserCompanyRequest.DepartmentId = undefined;
        }
        // If the user selects None for department it should be set to null for the api call
        if (this.viewModel.AddUserCompanyRequest.ManagerCompanyUserId === -1) {
            this.viewModel.AddUserCompanyRequest.ManagerCompanyUserId = undefined;
        }
        this.dataService.CompanyUsers_AddUserToCompany(this.viewModel.AddUserCompanyRequest).subscribe(function (returnedCompanyUser) {
            _this.closeForm('Close');
            _this.raiseCreateNewUserCompletedEvent(returnedCompanyUser);
            _this.createNewCompanyUser();
        }, function (error) {
            _this.raiseCreateNewUserCompletedEvent();
        });
    };
    NewEmployeeComponent.prototype.validateInput = function () {
        if (this.employmentTemplateSelected && !this.isValidDate) {
            return false;
        }
        //let message: string = this.getErrorMessage();
        this.messageValid = this.getErrorMessage();
        if (this.messageValid) {
            this.isInvalid = true;
            return false;
        }
        return true;
    };
    NewEmployeeComponent.prototype.getErrorMessage = function () {
        if (!this.viewModel.AddUserCompanyRequest.Details.FirstName ||
            !this.viewModel.AddUserCompanyRequest.Details.LastName ||
            (!this.viewModel.NoCPRNumber && !this.viewModel.AddUserCompanyRequest.IdentityNumber) ||
            (!this.viewModel.AddUserCompanyRequest.Details.MunicipalityId &&
                this.IsGreenLandCompany &&
                this.employmentTemplateSelected)) {
            return 'NewEmployee.RequiredErrorMessage';
        }
        if (!this.viewModel.AddUserCompanyRequest.EmploymentTemplateId ||
            this.viewModel.AddUserCompanyRequest.EmploymentTemplateId.toString() === '-1') {
            return '';
        }
        if (!this.viewModel.AddUserCompanyRequest.Title) {
            return 'NewEmployee.TitleErrorMessage';
        }
        if (!this.viewModel.AddUserCompanyRequest.HireDate) {
            return 'NewEmployee.HiringErrorMessage';
        }
        return '';
    };
    NewEmployeeComponent.prototype.onCancel = function () {
        this.closeForm('Close');
        this.createNewCompanyUser();
    };
    NewEmployeeComponent.prototype.closeForm = function (action) {
        if (action === 'Close') {
            this.isVisible = false;
            this.createNewCompanyUser();
            return;
        }
        if (action === 'CreateWithoutEmails') {
            this.createNew();
            return;
        }
        if (action === 'Create') {
            this.onCreateNew();
            return;
        }
    };
    NewEmployeeComponent.prototype.onInvalidDialog = function (event) { };
    NewEmployeeComponent.prototype.onNonCreateMailDialog = function (event) {
        this.createWithoutEmail = true;
    };
    return NewEmployeeComponent;
}());
export { NewEmployeeComponent };
