import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { Global } from '../../../Common/Global';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
var CompanyDataComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyDataComponent, _super);
    function CompanyDataComponent(staticDataService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.resetChildFromChange = new EventEmitter();
        _this.warningHasBeenShown = false;
        _this.childIsValid = Array(3).fill(true);
        _this.childIsDirty = Array(8).fill(false);
        return _this;
    }
    Object.defineProperty(CompanyDataComponent.prototype, "resetChildFrom", {
        set: function (value) {
            if (value) {
                this.childIsDirty.fill(false);
                this.childIsValid.fill(true);
            }
            this.resetChildFromChange.emit(false);
        },
        enumerable: true,
        configurable: true
    });
    CompanyDataComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.CompanyType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            return (_this.taxCategories = data);
        });
        this.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () { return (_this.warningHasBeenShown = false); });
        this.isDirtyChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (dirty) {
            if (_this.company && !dirty && !_this.editMode) {
                _this.originalBankAccountNo = _this.company.BankAccountNo;
            }
        });
    };
    CompanyDataComponent.prototype.onBankAccountNoChanged = function () {
        if (this.editMode && this.company && this.company.HasNetsAgreement && !this.warningHasBeenShown) {
            this.isWarningVivible = true;
        }
        this.onChange();
    };
    Object.defineProperty(CompanyDataComponent.prototype, "isGreenland", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    CompanyDataComponent.prototype.onWarningAction = function (action) {
        if (action !== 'Understood') {
            this.company.BankAccountNo = this.originalBankAccountNo;
        }
        else {
            this.onChange();
        }
        this.warningHasBeenShown = true;
    };
    return CompanyDataComponent;
}(FormComponentBase));
export { CompanyDataComponent };
