import { Component, Input, OnInit } from '@angular/core';
import { SessionService } from '../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'taxcard-addon',
  templateUrl: './TaxCardAddOn.component.html'
})
export class TaxCardAddOnComponent implements OnInit {
  @Input()
  public get taxInfo(): any {
    return this.currentTaxInfo;
  }

  public set taxInfo(value: any) {
    if (this.currentTaxInfo !== value) {
      this.currentTaxInfo = value;
    }
  }

  @Input()
  get requested(): string {
    return this.requestedTime;
  }

  set requested(value: string) {
    this.requestedTime = this.sessionService.toString(this.sessionService.parseDate(value), 'G').replace(/\./g, ':');
  }

  get errorIcon(): boolean {
    return !this.requestedTime && !this.taxInfo;
  }

  get infoIcon(): boolean {
    return this.requestedTime && this.requestedTime.length > 0;
  }

  get warningIcon(): boolean {
    return this.taxInfo && this.taxInfo.IsDummy;
  }

  private currentTaxInfo: any;
  public requestedTime: string;
  private errorIconComponentId: string;
  private warningIconComponentId: string;
  private infoIconComponentId: string;

  constructor(private sessionService: SessionService) {}

  ngOnInit(): void {}

  showIcon(div: any): void {
    // console.log("---");
  }
}
