/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../CustomControls/PayslipIcon.component.ngfactory";
import * as i2 from "../../../../CustomControls/PayslipIcon.component";
import * as i3 from "../../../../Services/SharedServices/Session/SessionService";
import * as i4 from "@angular/common";
import * as i5 from "./SalaryBatchPayslipIcons.component";
var styles_SalaryBatchPayslipIconsComponent = [];
var RenderType_SalaryBatchPayslipIconsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SalaryBatchPayslipIconsComponent, data: {} });
export { RenderType_SalaryBatchPayslipIconsComponent as RenderType_SalaryBatchPayslipIconsComponent };
function View_SalaryBatchPayslipIconsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "payslip-icon", [["id", "ViewPayslipSameTabButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPayslipIconClicked("htmlSameTab") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PayslipIconComponent_0, i1.RenderType_PayslipIconComponent)), i0.ɵdid(1, 49152, null, 0, i2.PayslipIconComponent, [i3.SessionService], { payslipIconType: [0, "payslipIconType"], isHidden: [1, "isHidden"] }, { click: "click" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "htmlSameTab"; var currVal_1 = _co.isHidden; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SalaryBatchPayslipIconsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "payslip-icon", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPayslipIconClicked("htmlNewTab") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PayslipIconComponent_0, i1.RenderType_PayslipIconComponent)), i0.ɵdid(1, 49152, null, 0, i2.PayslipIconComponent, [i3.SessionService], { payslipIconType: [0, "payslipIconType"] }, { click: "click" })], function (_ck, _v) { var currVal_0 = "htmlNewTab"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SalaryBatchPayslipIconsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "payslip-icon", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPayslipIconClicked("pdf") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PayslipIconComponent_0, i1.RenderType_PayslipIconComponent)), i0.ɵdid(1, 49152, null, 0, i2.PayslipIconComponent, [i3.SessionService], { payslipIconType: [0, "payslipIconType"] }, { click: "click" })], function (_ck, _v) { var currVal_0 = "pdf"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SalaryBatchPayslipIconsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "right"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipIconsComponent_2)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipIconsComponent_3)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipIconsComponent_4)), i0.ɵdid(6, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.IsAppOrDesktop; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.IsWeb; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.IsFinalizedBatch; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_SalaryBatchPayslipIconsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "FormEmlement Company-totalsPayslipsSearch Company-payslipLabel"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "FormElement-label"], ["width", "210"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipIconsComponent_1)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.hideDownloadIcons; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.selectedSalaryStatement == null) ? null : _co.selectedSalaryStatement.UserFullName); _ck(_v, 2, 0, currVal_0); }); }
export function View_SalaryBatchPayslipIconsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "salary-batch-payslip-icons", [], null, null, null, View_SalaryBatchPayslipIconsComponent_0, RenderType_SalaryBatchPayslipIconsComponent)), i0.ɵdid(1, 49152, null, 0, i5.SalaryBatchPayslipIconsComponent, [i3.SessionService], null, null)], null, null); }
var SalaryBatchPayslipIconsComponentNgFactory = i0.ɵccf("salary-batch-payslip-icons", i5.SalaryBatchPayslipIconsComponent, View_SalaryBatchPayslipIconsComponent_Host_0, { selectedSalaryStatement: "selectedSalaryStatement", IsFinalizedBatch: "IsFinalizedBatch" }, { downloadPayslipClick: "downloadPayslipClick" }, []);
export { SalaryBatchPayslipIconsComponentNgFactory as SalaryBatchPayslipIconsComponentNgFactory };
