import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '@progress/kendo-angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from './Session/SessionService';

@Injectable()
export class KendoTranslateService extends MessageService {
  private data: any = {
    rtl: false,
    messages: {
      'kendo.grid.pagerOf': 'of',
      'kendo.grid.pagerItems': 'items'
    }
  };

  private ngUnsubscribe: Subject<{}> = new Subject();

  constructor(private translateService: TranslateService, private sessionService: SessionService) {
    super();

    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) =>
      this.translateText()
    );
  }

  private translateText(): void {
    this.translateService.get(['Kendo']).subscribe((translations: { [key: string]: string }) => {
      this.data.messages = translations['Kendo'];
      this.notify(this.data.rtl);
    });
  }

  private get messages(): any {
    const lang = this.data;

    if (lang) {
      return lang.messages;
    }
  }

  public get(key: string): string {
    return this.messages[key];
  }
}
