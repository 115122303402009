<app-dialog [overlayClose]="false" [(visible)]="isVisible" (action)="onTaxCardAction($event)" [minWidth]="300">
  <combobox-edit-control
    [label]="'EmployeeGeneral.Tax card type' | translate"
    [editMode]="true"
    [comboboxDataSource]="taxcardTypes"
    [(value)]="selectedTaxcardTypeId"
    [textField]="'Name'"
    [idField]="'Id'"
  ></combobox-edit-control>

  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="isShowAlert" [leadingText]="alertMessage">
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
