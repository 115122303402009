import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { Global } from '../../../Common/Global';
import { ITaxCardModel } from '../../../Model/ITaxCardModel';
import {
  ICompanyUser,
  ISimpleKeyValuePair,
  ITaxCardType,
  IUserEmployment,
  IUserTaxInfo
} from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'app-employee-hiring-status',
  templateUrl: './EmployeeHiringStatus.component.html'
})
export class EmployeeHiringStatusComponent extends FormComponentBase implements OnInit, OnDestroy {
  @Output() public createUserEmploymentClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public reloadUserEmployment: EventEmitter<void> = new EventEmitter<void>();
  @Output() companyUserChange: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();

  @Input() public allowEdit: boolean;

  @Input()
  public get companyUser(): ICompanyUser {
    return this.companyUserValue;
  }
  public set companyUser(value: ICompanyUser) {
    if (!this.companyUserValue && !value) {
      return;
    }

    this.companyUserValue = value;
    this.companyUserChange.emit(value);
  }

  @Input()
  public get userEmployment(): IUserEmployment {
    ////if (this.userEmploymentValue === undefined) {
    ////    this.userEmploymentValue = {};
    ////}

    return this.userEmploymentValue;
  }

  public set userEmployment(value: IUserEmployment) {
    if (!this.userEmploymentValue && !value) {
      return;
    }

    if (!value || !value.Id) {
      this.hasUserEmployment = false;
    } else {
      this.hasUserEmployment = true;
    }

    this.taxCardDataChanged = true;
    this.userEmploymentValue = value;
  }

  public get taxCardObject(): ITaxCardModel {
    const userEmployment: IUserEmployment = this.userEmployment;
    this.setTaxCardData(userEmployment);
    return this.taxCardData;
  }

  public get isVacationDisable(): boolean {
    return this.SecondaryIncomeType;
  }

  public get isAdditionalVacationDaysDisable(): boolean {
    return this.SecondaryIncomeType;
  }

  public get isMultiPurposeOffDayCompensationDisable(): boolean {
    return this.SecondaryIncomeType;
  }

  public get SecondaryIncomeType(): boolean {
    if (!this.userEmployment) {
      return true;
    }

    return this.userEmployment.IncomeTypeId === 5;
  }

  public get firstPensionProviderVisible(): boolean {
    if (this.userEmployment && this.userEmployment.PensionProviderId) {
      return true;
    }

    return false;
  }

  public get secondPensionProviderVisible(): boolean {
    if (this.userEmployment && this.userEmployment.Pension2ProviderId) {
      return true;
    }

    return false;
  }

  public get showStatisticsSection(): boolean {
    return Global.COMPANY && Global.COMPANY.StatisticsRecipientId && Global.COMPANY.StatisticsRecipientId !== -1;
  }

  public get showMultiPurposeOffDayCompensation(): boolean {
    const enableFlexBalanceSetting: ISimpleKeyValuePair = Global.COMPANY_PREFERENCES
      ? Global.COMPANY_PREFERENCES.filter((pref: ISimpleKeyValuePair) => pref.Key === 'Company.EnableFlexBalance')[0]
      : undefined;
    if (enableFlexBalanceSetting) {
      return enableFlexBalanceSetting.Value === 'true' ? true : false;
    }
    return false;
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public hasUserEmployment: boolean;
  private companyUserValue: ICompanyUser;
  private userEmploymentValue: IUserEmployment;
  private taxCardData: ITaxCardModel;
  private taxCardDataChanged: boolean;
  private taxCardTypes: ITaxCardType[];
  private saveTaxInfo: IUserTaxInfo;

  constructor(
    private settingService: SettingService,
    private staticDataService: StaticDataService,
    private dataService: RxDataService
  ) {
    super();
    this.childIsValid = Array(10).fill(true);
    this.childIsDirty = Array(10).fill(false);
  }

  public ngOnInit(): void {
    this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ITaxCardType[]) => (this.taxCardTypes = data)
    );
    this.settingService.showModuleMessage('Employee.Employment');
  }

  private setTaxCardData(value: IUserEmployment, newtaxInfo?: IUserTaxInfo): void {
    if (this.taxCardDataChanged || newtaxInfo) {
      let isSpecialTaxBrief: boolean;
      if (value && (value.IncomeTypeId === 0 || value.IncomeTypeId === Constants.MAGIC_NUMBER)) {
        isSpecialTaxBrief = false;
      } else {
        isSpecialTaxBrief = true;
      }
      // Only create a new instance of taxcard if there is changes
      this.taxCardData = {
        taxInfo: newtaxInfo ? newtaxInfo : this.saveTaxInfo ? this.saveTaxInfo : undefined,
        taxCardRequestTime: value ? value.TaxCardRequested : undefined,
        taxCardTypes: this.taxCardTypes,
        userEmploymentId: value ? value.Id : undefined,
        specialTaxBrief: isSpecialTaxBrief
      };
      this.taxCardDataChanged = false;
    }
  }

  onDataChanged(isReload: boolean = false): void {
    if (this.userEmployment.IncomeTypeId === 5) {
      this.userEmployment.VacationTypeId = -1;
    }

    this.onChange();
    if (isReload) {
      this.reloadUserEmployment.emit();
    }
  }

  createUserEmployment(): void {
    this.createUserEmploymentClick.emit();
  }

  public requestTaxcardDialogVisible: boolean;
  public taxcardHistoryDialogVisible: boolean;
  public requestTaxcardWarningDialog: boolean;
  public triggerRefreshTaxCard = false;
  public onReloadTaxCard(): void {
    this.triggerRefreshTaxCard = !this.triggerRefreshTaxCard;
  }

  public onRequestTaxcardWarningDialogAction(action: string): void {
    if (action === 'AlreadyRequestedTaxCardConfirmButton') {
      this.showRequestTaxcardDialog();
    }
  }

  public showRequestTaxcardDialog(): void {
    this.requestTaxcardDialogVisible = true;
  }

  public showViewTaxCardHistory(): void {
    this.taxcardHistoryDialogVisible = true;
  }

  public showRequestTaxcardConfirm(value: IUserTaxInfo): void {
    this.saveTaxInfo = value;
    this.requestTaxcardWarningDialog = true;
  }

  public onCreateTaxInfo(value: IUserTaxInfo): void {
    if (value) {
      this.setTaxCardData(this.userEmployment, value);
    }
  }
}
