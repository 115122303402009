import { ImportButtonAction, ImportTypesEnum } from '../../../Model/Enum';
var ImportOptionHelper = /** @class */ (function () {
    function ImportOptionHelper() {
    }
    ImportOptionHelper.getImportTypeKey = function (importType) {
        switch (importType) {
            case ImportTypesEnum.Employees:
                return 'ImportEmployees';
            case ImportTypesEnum.Initial_Balances:
                return 'ImportBalances';
            case ImportTypesEnum.Payroll:
                return 'ImportSalaryRecords';
            case ImportTypesEnum.PayrollRow:
                return 'ImportSalaryRecordsRow';
            case ImportTypesEnum.TimeEntries:
                return 'ImportTimeEntries';
            case ImportTypesEnum.TimeEntriesRow:
                return 'ImportTimeEntriesRow';
        }
        return '';
    };
    ImportOptionHelper.createImportOptionObjects = function (importOptions) {
        var options = [];
        if (!importOptions) {
            return undefined;
        }
        var _loop_1 = function (index) {
            if (importOptions[index].Options.length === 1) {
                options.push({
                    DataType: 'CheckBox',
                    ParameterValue: importOptions[index]['Value'],
                    DisplayText: importOptions[index].DisplayName
                        ? importOptions[index].DisplayName
                        : importOptions[index].Options[0]['Value']
                            ? importOptions[index].Options[0]['Value']
                            : importOptions[index]['Value'],
                    ControlValue: importOptions[index]['DefaultKey']
                });
            }
            else if (importOptions[index].Options.length > 1) {
                for (var subIndex = 0; subIndex < importOptions[index].Options.length; subIndex++) {
                    var existedRadioOption = options.find(function (radio) { return radio.ParameterValue === importOptions[index]['Value']; });
                    if (existedRadioOption) {
                        existedRadioOption.Options.push({
                            DisplayText: importOptions[index].Options[subIndex]['Value'],
                            RadioValue: importOptions[index].Options[subIndex]['Key']
                        });
                    }
                    else {
                        options.push({
                            DataType: 'Radio',
                            ParameterValue: importOptions[index]['Value'],
                            ControlValue: importOptions[index]['DefaultKey'],
                            Options: [
                                {
                                    DisplayText: importOptions[index].Options[subIndex]['Value'],
                                    RadioValue: importOptions[index].Options[subIndex]['Key']
                                }
                            ]
                        });
                    }
                }
            }
        };
        for (var index = 0; index < importOptions.length; index++) {
            _loop_1(index);
        }
        return options;
    };
    ImportOptionHelper.getInportOptionsString = function (importOptions, action) {
        var optionsString = this.getOptionsString(importOptions);
        var saveType;
        switch (action) {
            case ImportButtonAction.Test:
                saveType = 'save=never';
                break;
            case ImportButtonAction.Import:
                saveType = 'save=noerror';
                break;
            case ImportButtonAction.CompleteImport:
                saveType = 'save=always';
                break;
        }
        var result = saveType + (optionsString ? '-' + optionsString : '');
        return result;
    };
    ImportOptionHelper.getOptionsString = function (importOptions) {
        var optionsString = '';
        if (importOptions && importOptions.length > 0) {
            importOptions.forEach(function (option) {
                if (optionsString.indexOf(option.ParameterValue + '=') < 0) {
                    optionsString +=
                        (optionsString ? '-' : '') +
                            option.ParameterValue +
                            '=' +
                            (option.ControlValue ? option.ControlValue : 'false');
                }
            });
        }
        return optionsString;
    };
    return ImportOptionHelper;
}());
export { ImportOptionHelper };
