<app-tab-panel
  [parentTab]="'selfService'"
  [payslipTabVisible]="hasEmployments"
  [timeTabVisible]="hasEmployments && sessionService.feature.hasModuleId(8)"
></app-tab-panel>

<div (click)="onTabClick()" [ngClass]="editMode ? 'is-editing' : ''" (dblclick)="onDoubleClick()">
  <!-- Main -->
  <div id="selfserviceViewHolder" class="Main Scrollable">
    <div class="EmployeeView">
      <app-employee-info
        *ngIf="!state.currentStateIs('tabs.selfservice.payslip')"
        [editMode]="editMode"
        [allowEdit]="false"
        [allowEditRole]="false"
        [companyUser]="companyUser"
        [currentPositionId]="currentPosition"
        (selectedUserEmploymentChange)="onUserEmploymentChange($event)"
        (validated)="onValidated($event)"
      ></app-employee-info>

      <div class="Island">
        <div *ngIf="state.currentStateIs('tabs.selfservice.payslip')" class="wrapper">
          <selfservicepayslip></selfservicepayslip>
        </div>

        <div *ngIf="state.currentStateIs('tabs.selfservice.general')" class="wrapper">
          <app-employee-general
            [isSelfService]="true"
            [(companyUser)]="companyUser"
            [(userEmployment)]="userEmployment"
            [(editMode)]="editMode"
            [allowEdit]="false"
            [(isValid)]="isValid"
            (valueChange)="onEmployeeGeneralChanged()"
            [(isDirty)]="isDirty"
            (reloadUserEmployment)="onReloadUserEmploymentEvent()"
          ></app-employee-general>
        </div>

        <div
          *ngIf="state.currentStateIs('tabs.selfservice.time')"
          class="wrapper"
          [ngClass]="isFirefox ? 'PaddingBottom60' : 'PaddingBottom25'"
        >
          <employee-time
            [currentCompanyUser]="companyUser"
            [userEmployment]="userEmployment"
            [(isAddNew)]="createNewTimeEntryDialogVisible"
          ></employee-time>
        </div>
      </div>
      <app-feedback [hidden]="editMode"></app-feedback>
    </div>
  </div>
  <!-- /Main -->
  <!-- Actions -->
  <div class="Actions">
    <action-button
      *ngIf="reportViewEnabled"
      id="ActionButtonReports"
      [buttonClass]="'Action-button Action-buttonReports'"
      [icon]="'File'"
      [label]="'Employee.Reports'"
      (click)="onShowReportsEventClick()"
      [tooltip]="'Employee.Reports'"
    ></action-button>
    <help-button [module]="'selfservice'"></help-button>
    <edit-button
      *ngIf="state.currentStateIs('tabs.selfservice.general')"
      [module]="'selfservice'"
      [isVisible]="true"
      class="{{ disabledActionButtonClass }}"
      [isCanChangeEditMode]="allowUsersToModifyOwnData"
      (errorChangeEditMode)="isDisableChangeEditMode = true"
      [editMode]="editMode"
      (change)="onEditModeChange($event)"
      [allowCompanyPreference]="allowUsersToModifyOwnData"
    ></edit-button>
    <action-button
      *ngIf="isMobileInSelfservicetime"
      id="ActionButtonAdd"
      [buttonClass]="'Action-button Action-buttonAdd'"
      [icon]="'Plus'"
      [label]="'Employee.New'"
      (click)="createNewTimeEntryDialogVisible = true"
    ></action-button>
  </div>

  <div class="EditingInfo" *ngIf="editMode"></div>
  <button-component
    [isUseOneClick]="true"
    [buttonClass]="'EditingInfo Discard'"
    (click)="confirmAndDiscardChanges()"
    [label]="'Employee.DiscardButton' | translate"
    *ngIf="editMode"
  ></button-component>
  <!-- /Actions -->
</div>

<app-report-dialog [(visible)]="reportDialogVisible" [key]="reportKey" [param]="reportParams"></app-report-dialog>

<app-dialog [(visible)]="noReportVisible" [leadingText]="'Report.NoReport' | translate">
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="discardDialogAction($event)"
  [(visible)]="discardDialogVisible"
  [leadingText]="'Employee.DiscardMessage' | translate"
>
  <app-dialog-action type="CancelDiscard"></app-dialog-action>
  <app-dialog-action type="ConfirmDiscard"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onConfirmSaveDataOnChangeState($event)" [(visible)]="showSaveDataConfirmationChangeState">
  <div [innerHTML]="'Employee.UnsavedData' | translate"></div>
  <app-dialog-action type="DiscardAndLeave"></app-dialog-action>
  <app-dialog-action type="Stay"></app-dialog-action>
  <app-dialog-action type="ContinueAndSave"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="isDisableChangeEditMode"
  [width]="'500'"
  [leadingText]="'EmploymentTime.DisableGridTimeMessage' | translate"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
