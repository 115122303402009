import { Component, OnInit } from '@angular/core';
import * as Raven from 'raven-js';
import { version } from '../../../version';
import { Global } from '../../Common/Global';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';

@Component({
  selector: 'app-feedback',
  templateUrl: './Feedback.component.html'
})
export class FeedbackComponent implements OnInit {
  public apiVersion = '';
  public clientVersion = '';
  public feedbackDialogVisible = false;
  public successDialogVisible = false;
  public feedbackText: string;
  public companyId: number;
  public userId: number;
  public country = '';
  public accountId: number;
  public widthDestopFooter = '';
  public paddingDestopFooter = '';

  public get demo(): boolean {
    return this.sessionService.feature.IsDemoVersion;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  constructor(
    private dataService: RxDataService,
    public sessionService: SessionService,
    public settingService: SettingService
  ) {
    this.clientVersion = version;
    this.getApiVersion();
  }

  public ngOnInit(): void {
    this.userId = Global.SESSION.CurrentCompanyUser.UserId;
    this.accountId = Global.SESSION.AccountId;
    this.companyId = Global.SESSION.CurrentCompanyId;
    this.country = Global.SESSION.CurrentCompany.Country.Name;
    if (!this.isMobile) {
      this.widthDestopFooter = 'widthDestopFooter';
    }
  }

  public get isPayrolldataMobile(): boolean {
    return this.sessionService.browser.isMobile && this.sessionService.currentState === 'tabs.employee.payrolldata';
  }

  public onAction(action: string): void {
    if (action === 'Submit') {
      this.submitFeedback();
    }
  }

  private getApiVersion(): void {
    if (this.settingService.apiVersion) {
      this.apiVersion = this.settingService.apiVersion;
      Raven.setTagsContext({ apiVersion: this.settingService.apiVersion });
      Raven.setTagsContext({ clientVersion: this.clientVersion });
    } else {
      this.dataService.Miscellaneous_GetVersion().subscribe((data: string): void => {
        this.apiVersion = data;
        this.settingService.apiVersion = data;
        Raven.setTagsContext({ apiVersion: data });
        Raven.setTagsContext({ clientVersion: this.clientVersion });
      });
    }
  }

  private submitFeedback(): void {
    if (!this.feedbackText || this.feedbackText.trim() === '') {
      this.feedbackDialogVisible = false;
      return;
    }

    this.dataService
      .Miscellaneous_SubmitFeedback({ Message: this.feedbackText })
      .subscribe(() => (this.successDialogVisible = true));
  }
}
