/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@progress/kendo-angular-l10n";
import * as i3 from "@progress/kendo-angular-tooltip";
import * as i4 from "@progress/kendo-angular-popup";
import * as i5 from "./Button.component";
var styles_ButtonComponent = [];
var RenderType_ButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
function View_ButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["type", "button"]], [[8, "title", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buttonClass; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltip; var currVal_1 = _co.isDisabled; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.label; _ck(_v, 3, 0, currVal_3); }); }
function View_ButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["type", "button"]], [[8, "title", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buttonClass; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltip; var currVal_1 = (_co.isDisabled && _co.isFirstClick); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.label; _ck(_v, 3, 0, currVal_3); }); }
export function View_ButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["componentId", 1]], null, 7, "span", [["class", "FormElement-cursorHand"], ["kendoTooltip", ""]], [[8, "id", 0], [1, "dir", 0]], null, null, null, null)), i0.ɵprd(256, null, i2.L10N_PREFIX, "kendo.tooltip", []), i0.ɵprd(131584, null, i2.LocalizationService, i2.LocalizationService, [i2.L10N_PREFIX, [2, i2.MessageService], [2, i2.RTL]]), i0.ɵdid(3, 9125888, null, 0, i3.TooltipDirective, [i0.ElementRef, i0.NgZone, i0.Renderer2, i4.PopupService, i2.LocalizationService, [2, i3.TooltipSettings], [2, i3.TOOLTIP_SETTINGS]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ButtonComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ButtonComponent_2)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_2 = !_co.isUseOneClick; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.isUseOneClick; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵnov(_v, 0), ""); var currVal_1 = i0.ɵnov(_v, 3).direction; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button-component", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i0.ɵdid(1, 49152, null, 0, i5.ButtonComponent, [], null, null)], null, null); }
var ButtonComponentNgFactory = i0.ɵccf("button-component", i5.ButtonComponent, View_ButtonComponent_Host_0, { tooltip: "tooltip", label: "label", isDisabled: "isDisabled", buttonClass: "buttonClass", isUseOneClick: "isUseOneClick" }, { click: "click" }, []);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
