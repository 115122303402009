import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ListHelper } from '../../../Common/ListHelper';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
var DepartmentFilterComponent = /** @class */ (function () {
    function DepartmentFilterComponent(translateService, sessionService, staticDataService) {
        var _this = this;
        this.translateService = translateService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.hasDepartmentDataValue = true;
        this.hasDepartmentDataChange = new EventEmitter();
        this.change = new EventEmitter();
        this.departmentId = 0;
        this.ngUnsubscribe = new Subject();
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            _this.translateDepartmentDataSource();
        });
        this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (state) {
            _this.translateDepartmentDataSource();
        });
    }
    Object.defineProperty(DepartmentFilterComponent.prototype, "hasDepartmentData", {
        get: function () {
            return this.hasDepartmentDataValue;
        },
        set: function (value) {
            var _this = this;
            if (this.hasDepartmentDataValue !== value) {
                this.hasDepartmentDataValue = value;
                setTimeout(function () {
                    _this.hasDepartmentDataChange.emit(_this.hasDepartmentDataValue);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    DepartmentFilterComponent.prototype.ngOnInit = function () {
        this.translateDepartmentDataSource();
    };
    DepartmentFilterComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    DepartmentFilterComponent.prototype.translateDepartmentDataSource = function () {
        var _this = this;
        this.translateService.get(['Employee.All Departments']).subscribe(function (translations) {
            var allDepartmentItem = {
                Id: -1,
                Text: translations['Employee.All Departments']
            };
            _this.staticDataService.departments.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (departments) {
                _this.hasDepartmentData = departments && departments.length > 0;
                departments = departments.sort(function (a, b) { return (a.IsActive > b.IsActive ? -1 : 1); });
                _this.departmentDataSource = ListHelper.mapDataSource(_this.sessionService, departments, 'Id', 'Name', allDepartmentItem, false);
                setTimeout(function () {
                    _this.departmentId = -1;
                });
            });
        });
    };
    DepartmentFilterComponent.prototype.onComboBoxChanged = function () {
        var _this = this;
        setTimeout(function () {
            _this.change.emit(_this.departmentId);
        });
    };
    return DepartmentFilterComponent;
}());
export { DepartmentFilterComponent };
