import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../../../Common/Global';
import { PayrolCommonl } from '../../../Common/payrolCommonl';
import {
  ISalaryRecord,
  ISalaryRecordPersistenceType,
  ISalaryRecordView,
  ISalaryTypeView,
  ISimpleKeyValuePair,
  IUnitType,
  IUserEmployment
} from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { SalaryRecordItemHelper } from '../Payroll/Controls/SalaryRecordItemHelper';

@Component({
  selector: 'app-employee-payroll-data-mobile',
  templateUrl: './EmployeePayrollDataMobile.component.html'
})
export class EmployeePayrollDataMobileComponent implements OnInit, OnDestroy {
  private userEmploymentValue: IUserEmployment;
  @Input()
  public get userEmployment(): IUserEmployment {
    return this.userEmploymentValue;
  }
  public set userEmployment(value: IUserEmployment) {
    if (this.userEmploymentValue !== value) {
      this.userEmploymentValue = value;
      if (value && value.Id) {
        this.loadSalaryTypes();
      }
    }
  }

  private currentAddnew = false;
  @Input()
  public get isAddNew(): boolean {
    return this.currentAddnew;
  }

  public set isAddNew(value: boolean) {
    this.currentAddnew = value;
    this.isAddNewChange.emit(value);
    if (value) {
      this.onOpenCreateDialog();
    }
  }

  @Output() public isAddNewChange: EventEmitter<any> = new EventEmitter<any>();

  public salaryRecords: ISalaryRecordView[];
  public salaryTypes: ISalaryTypeView[];
  public disableGridToolbar: boolean;
  public DimensionXValue: any[] = [];
  public unitTypes: IUnitType[];
  public showSelectPeriodDialog = false;
  public recalculate = false;
  public showInsertOrUpdatesalaryRecords = false;
  public salaryRecordModel: any = {};
  public salaryRecordItemHelper: SalaryRecordItemHelper;
  public persistanceTypes: ISalaryRecordPersistenceType[];
  public flagUpdate = false;
  public isHideDateColumns: boolean;
  public showNegativeDefaulChange = false;
  public perunitsText: string;
  public unitText: string;
  public amountText: string;
  public classShowSelectPeriodDialog = '';
  public classoverlayScreen = '';
  public isShowButtonPreview = true;
  public confirmDeleteDialogVisible = false;

  public periodsButtonPreviewText: string;
  public totalButtonPreviewText: string;
  public isReloadBytype = false;

  public get Dimension1Preference(): ISimpleKeyValuePair {
    return Global.COMPANY_PREFERENCES
      ? Global.COMPANY_PREFERENCES.find((pref: ISimpleKeyValuePair) => pref.Key === 'Dimensions.Dimension1Name')
      : undefined;
  }

  public get Dimension2Preference(): ISimpleKeyValuePair {
    return Global.COMPANY_PREFERENCES
      ? Global.COMPANY_PREFERENCES.find((pref: ISimpleKeyValuePair) => pref.Key === 'Dimensions.Dimension2Name')
      : undefined;
  }

  public get Dimension3Preference(): ISimpleKeyValuePair {
    return Global.COMPANY_PREFERENCES
      ? Global.COMPANY_PREFERENCES.find((pref: ISimpleKeyValuePair) => pref.Key === 'Dimensions.Dimension3Name')
      : undefined;
  }

  public get isHasEmployeeTotal(): boolean {
    if (this.totalButtonPreviewText) {
      return true;
    }
    return false;
  }

  public get IsEnableDateAdjustment(): boolean {
    let enableDateAdjustment: ISimpleKeyValuePair = PayrolCommonl.getLocalStorageEnableDatePreferenceSetting();
    if (!enableDateAdjustment) {
      enableDateAdjustment = PayrolCommonl.createLocalStorageEnableDatePreferenceSetting(false);
    }

    const value: string = enableDateAdjustment.Value.toLowerCase();
    return value === 'true';
  }

  private runEnableDateAdjustmentsRules(): void {
    const enableDateAdjustment: boolean = this.IsEnableDateAdjustment;
    this.showDateColumns(enableDateAdjustment);
  }

  private showDateColumns(show: boolean): void {
    this.isHideDateColumns = !show;
  }

  private numericOptions: any;
  public get NumericOptions(): any {
    if (this.numericOptions) {
      return this.numericOptions;
    }

    return (this.numericOptions = {
      format: 'n2',
      decimals: 2,
      step: 1,
      min: 0,
      spinners: false
    });
  }

  private translateText(): void {
    this.translateService
      .get(['EmploymentPayrollData.Per Unit', 'EmploymentPayrollData.Amount', 'EmploymentPayrollData.Units'])
      .subscribe((translations: { [key: string]: string }) => {
        this.perunitsText = translations['EmploymentPayrollData.Per Unit'];
        this.amountText = translations['EmploymentPayrollData.Amount'];
        this.unitText = translations['EmploymentPayrollData.Units'];
      });
  }

  private loadSalaryTypes(): void {
    this.salaryRecords = [];
    this.salaryTypes = [];
    this.disableGridToolbar = true;

    this.dataService.SalaryTypes_GetSalaryTypeViews().subscribe((salaryTypes: ISalaryTypeView[]): void => {
      if (this.userEmploymentValue.IncomeTypeId === 5) {
        this.salaryTypes = salaryTypes.filter(
          (salaryType: ISalaryTypeView) => salaryType.IsVisible && salaryType.RelatesToIncomeType5 !== false
        );
      } else {
        this.salaryTypes = salaryTypes.filter(
          (salaryType: ISalaryTypeView) => salaryType.IsVisible && !salaryType.RelatesToIncomeType5
        );
      }

      this.loadSalaryRecords();
    });

    if (this.Dimension1Preference && this.Dimension1Preference.Value) {
      this.dataService.Miscellaneous_GetDimensionValues(1).subscribe((data: any) => {
        this.DimensionXValue[0] = data.filter((value: any) => value);
      });
    }

    if (this.Dimension2Preference && this.Dimension2Preference.Value) {
      this.dataService.Miscellaneous_GetDimensionValues(2).subscribe((data: any) => {
        this.DimensionXValue[1] = data.filter((value: any) => value);
      });
    }

    if (this.Dimension3Preference && this.Dimension3Preference.Value) {
      this.dataService.Miscellaneous_GetDimensionValues(3).subscribe((data: any) => {
        this.DimensionXValue[2] = data.filter((value: any) => value);
      });
    }
  }

  private loadSalaryRecords(): void {
    if (!this.userEmployment) {
      return;
    }

    this.dataService
      .SalaryRecords_GetSalaryRecordsByEmployment(this.userEmployment.Id)
      .subscribe((salaryRecords: ISalaryRecordView[]): void => {
        this.salaryRecords = salaryRecords;
        this.disableGridToolbar = false;
        this.translateText();
      });
  }

  public getNameByTypeId(typeId: number): string {
    if (this.salaryTypes && this.salaryTypes.length > 0) {
      const typeObject: ISalaryTypeView = this.salaryTypes.find((z: ISalaryTypeView) => z.SalaryTypeId === typeId);
      if (typeObject && typeObject.Name) {
        return typeObject.Name;
      }
    }

    return '';
  }

  public convertDateToString(dateconvert: string): string {
    if (dateconvert) {
      const currentdate: Date = new Date(dateconvert);
      return this.sessionService.toString(currentdate);
    }

    return '';
  }

  public getStringStartDateAndEnddate(startdate: string, enddate: string): string {
    if (startdate && enddate) {
      return this.convertDateToString(startdate) + ' - ' + this.convertDateToString(enddate);
    }

    return '';
  }

  public getUnitAndType(model: ISalaryTypeView): string {
    if (this.unitTypes && this.unitTypes.length > 0 && model) {
      const unitObject: IUnitType = this.unitTypes.find((z: IUnitType) => z.Id === model.UnitTypeId);
      if (unitObject) {
        if (unitObject.Key === 'Percent') {
          return model.BaseAmount ? this.formatunit(model.BaseAmount.toString()) + ' ' + unitObject.Name : '';
        } else {
          return model.Units ? this.formatunit(model.Units.toString()) + ' ' + unitObject.Name : '';
        }
      }
    }

    return model && model.Units ? this.formatunit(model.Units.toString()) : '';
  }

  public getPerunit(model: ISalaryTypeView): string {
    if (model) {
      if (this.unitTypes && this.unitTypes.length > 0) {
        const unitObject: IUnitType = this.unitTypes.find((z: IUnitType) => z.Id === model.UnitTypeId);
        if (unitObject) {
          if (unitObject.Key === 'Percent') {
            return model.Units ? this.formatunit(model.Units.toString()) + ' ' + this.unitText : '';
          } else {
            return model.AmountPerUnit ? this.formatunit(model.AmountPerUnit.toString()) + ' ' + this.perunitsText : '';
          }
        }
      }

      return model.AmountPerUnit ? this.formatunit(model.AmountPerUnit.toString()) + ' ' + this.perunitsText : '';
    }

    return '';
  }

  constructor(
    private translateService: TranslateService,
    private dataService: RxDataService,
    private settingService: SettingService,
    private sessionService: SessionService,
    private renderer: Renderer2,
    private staticDataService: StaticDataService
  ) {}

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.unitTypes = Global.UNIT_TYPES;

    this.staticDataService.SalaryRecordPersistenceType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalaryRecordPersistenceType[]) => {
        this.persistanceTypes = data;
      }
    );
  }

  public onCreateNewModel(): void {
    this.salaryRecordItemHelper = new SalaryRecordItemHelper(
      this.salaryRecordModel,
      this.renderer,
      this.persistanceTypes,
      this.salaryTypes,
      this.settingService
    );
    this.salaryRecordItemHelper.updateSalaryType(this.salaryTypes[0]);
    PayrolCommonl.assignEditableFields(this.salaryRecordModel, this.salaryTypes);
  }

  public onOpenCreateDialog(): void {
    this.flagUpdate = false;
    this.salaryRecordModel = {};
    this.onCreateNewModel();
    this.showInsertOrUpdatesalaryRecords = true;
    this.isReloadBytype = false;
  }

  public onOpenUpdateDialog(model: ISalaryRecordView): void {
    this.flagUpdate = true;
    this.salaryRecordModel = model;
    PayrolCommonl.assignEditableFields(this.salaryRecordModel, this.salaryTypes);
    this.showInsertOrUpdatesalaryRecords = true;
    this.isReloadBytype = false;
  }

  public oncloseInsertOrUpdatesalaryRecords(): void {
    this.isAddNew = false;
    this.showInsertOrUpdatesalaryRecords = false;
    this.salaryRecordModel = {};
    this.loadSalaryRecords();
    this.recalculate = true;
    this.isReloadBytype = false;
    // setTimeout(() => {
    //     this.isReloadBytype = false;
    // })
  }

  private feildBlur: string;
  private dataBlur: any;
  public isHaveNavigate = false;
  public isValidModel = false;
  public onValueControlChange(model: any, feildChange: string): void {
    if (feildChange === 'SalaryTypeId' && this.isReloadBytype === false) {
      this.isReloadBytype = true;
      return;
    }
    if (model) {
      if (feildChange === 'SalaryTypeId') {
        const salaryTypesTemp: ISalaryTypeView[] = this.salaryTypes.filter(
          (item: ISalaryTypeView) => item.SalaryTypeId === this.salaryRecordModel.SalaryTypeId
        );
        if (salaryTypesTemp && salaryTypesTemp[0]) {
          this.isHaveNavigate = salaryTypesTemp[0].NegativeDefault;
        }
      }

      const modifiedField: any = {};
      modifiedField[feildChange] = model[feildChange];
      this.salaryRecordItemHelper = new SalaryRecordItemHelper(
        model,
        this.renderer,
        this.persistanceTypes,
        this.salaryTypes,
        this.settingService
      );
      const valid: boolean = this.salaryRecordItemHelper.validate(modifiedField);
      if (!valid) {
        this.isValidModel = false;
        return;
      }

      this.isValidModel = true;
      const previousDataItem: any = JSON.parse(JSON.stringify(model));
      this.salaryRecordItemHelper.updateRelavantFields(modifiedField);
      PayrolCommonl.assignEditableFields(model, this.salaryTypes);
      const checkcheckAmountAutoFlip: boolean = PayrolCommonl.checkAmountAutoFlip(previousDataItem, model);
      if (
        feildChange &&
        (modifiedField[feildChange] === 'AmountPerUnit' || modifiedField[feildChange] === 'Amount') &&
        checkcheckAmountAutoFlip
      ) {
        // this.salaryRecordItemHelper.showInCellPopupMessage(model, "Amount", feildChange);
        this.showNegativeDefaulChange = true;
      }

      this.dataBlur = model;
      switch (feildChange) {
        case 'Amount':
          this.feildBlur = 'Amount';
          if (this.isHaveNavigate) {
            this.showNegativeDefaulChange = true;
          }

          break;
        case 'AmountPerUnit':
          this.feildBlur = 'AmountPerUnit';
          if (this.isHaveNavigate) {
            this.showNegativeDefaulChange = true;
          }

          break;
        default:
          break;
      }
    }
  }

  private get isAutoFlipAmountsSetting(): boolean {
    return PayrolCommonl.isAutoFlipAmountsSetting();
  }

  public negativeDefaultMoreDialogModel: any = {};
  public showNegativeDefaultMoreInfoDialog: boolean;
  public onNegativeDefaultMoreInfoClick(fieldName: string): void {
    this.negativeDefaultMoreDialogModel = {
      setting: this.isAutoFlipAmountsSetting,
      fieldName,
      dataItem: this.salaryRecordModel
    };

    this.showNegativeDefaultMoreInfoDialog = true;
  }

  public onMoreInfoUsePositiveAmount(args: any): void {
    this.usePositiveAmount(args.dataItem, args.fieldName);
  }

  public usePositiveAmount(dataItem: any, fieldName: string): void {
    this.salaryRecordItemHelper.usePositiveAmount(fieldName);
    this.onValueControlChange(dataItem, '');
  }

  public autoCorrect(args: any): void {
    this.salaryRecordItemHelper.autoCorrect(args.fieldName);
    this.onValueControlChange(args.dataItem, '');
  }

  public onNegativeDefaulChange(event: string): void {
    if (event) {
      switch (event) {
        case 'MoreInfo':
          this.onNegativeDefaultMoreInfoClick(this.feildBlur);
          break;
        case 'UsePositiveAmountThisTime':
          this.usePositiveAmount(this.dataBlur, this.feildBlur);
          break;
        default:
          break;
      }
    }
  }

  public CreateNewModel(): void {
    if (this.salaryRecordModel) {
      const salaryRecord: ISalaryRecord = PayrolCommonl.createSalaryRecord(
        this.salaryRecordModel,
        this.userEmployment.Id
      );
      this.dataService.SalaryRecords_CreateSalaryRecord(salaryRecord).subscribe(
        (data: ISalaryRecord): void => {
          this.oncloseInsertOrUpdatesalaryRecords();
        },
        (): void => {
          this.oncloseInsertOrUpdatesalaryRecords();
        }
      );
    }
  }

  public UpdateModel(): void {
    if (this.salaryRecordModel) {
      const salaryRecord: ISalaryRecord = PayrolCommonl.createSalaryRecord(
        this.salaryRecordModel,
        this.userEmployment.Id
      );
      this.dataService.SalaryRecords_UpdateSalaryRecord(salaryRecord).subscribe(
        (data: ISalaryRecord): void => {
          this.oncloseInsertOrUpdatesalaryRecords();
        },
        (): void => {
          this.oncloseInsertOrUpdatesalaryRecords();
        }
      );
    }
  }

  public onShowSelectPeriodDialog(): void {
    if (this.classShowSelectPeriodDialog) {
      this.classShowSelectPeriodDialog = '';
      setTimeout(() => {
        this.classoverlayScreen = '';
        this.isShowButtonPreview = true;
      }, 200);
      return;
    }
    this.classoverlayScreen = 'overlayScreen-enable';
    setTimeout(() => {
      this.classShowSelectPeriodDialog = 'PreviewGridMobileEnable';
    });
    this.isShowButtonPreview = false;
  }

  public formatunit(unitstring: string): string {
    if (unitstring) {
      // if (Global.SESSION.SignOnToken.Language.Code === 'EN') {
      //   return this.sessionService.toString(parseFloat(unitstring), '0.00').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // }
      return this.sessionService.toString(parseFloat(unitstring), '0.00').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      // .replace(/.([^.]*)$/, ',$1');
    }
    return '';
  }

  public onClosePreviewArea(): void {
    this.classShowSelectPeriodDialog = '';
  }

  public DeleteModel(): void {
    if (this.salaryRecordModel && this.salaryRecordModel.Id) {
      this.dataService.SalaryRecords_DeleteSalaryRecord(this.salaryRecordModel.Id).subscribe(
        (): void => {
          this.oncloseInsertOrUpdatesalaryRecords();
        },
        (): void => {
          this.oncloseInsertOrUpdatesalaryRecords();
        }
      );
    }
  }

  public get isHideUnitTypeControl(): boolean {
    if (
      !this.salaryRecordModel.AllowEditUnitType &&
      (!this.salaryRecordModel.UnitTypeId ||
        (this.salaryRecordModel.UnitTypeId && this.salaryRecordModel.UnitTypeId === -9999))
    ) {
      return true;
    }
    return false;
  }

  public onConfirmDeleteDialogAction(event: string): void {
    if (event && event === 'Delete') {
      this.DeleteModel();
    }
  }
}
