<app-dialog
  [(visible)]="visible"
  [title]="'TimeEntryTypeConfigurations.TimeEntryTypeDialogTitle' | translate"
  class="dialog-grid"
>
  <strong>
    <u>{{ 'TimeEntryTypeConfigurations.SalaryBehavior' | translate }}</u>
  </strong>
  <br />
  <div class="mb-15">
    <span>{{ 'TimeEntryTypeConfigurations.SalaryBehaviorDescription' | translate }}</span>
  </div>
  <grid
    [(data)]="gridData"
    class="NewTimeEntryTypeGrid"
    [editMode]="!IsReadOnly"
    [addNew]="!IsReadOnly"
    [addNewDisable]="sessionService.isGetting"
    [deleteRow]="!IsReadOnly"
    addNewText="{{ 'SenioritySupplements.AddNew' | translate }}"
    (removeEvent)="removeHandler($event)"
    (addNewEvent)="addHandler($event)"
    (saveChangesEvent)="saveChanges($event)"
    [(triggerUpdate)]="refreshData"
    [(selectedItem)]="selectedItem"
    #kendoGridComponent
    [filterable]="'menu'"
    [filter]="filter"
    (filterChange)="onFilterChange($event)"
  >
    <gridColumn
      [isFilterable]="true"
      field="SalaryTypeId"
      [comboboxDataSource]="salaryTypes"
      type="combobox"
      comboboxValueField="SalaryTypeId"
      comboboxDisplayField="Name"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.SalaryType' | translate }}"
      [width]="150"
    ></gridColumn>

    <gridColumn
      [isFilterable]="true"
      field="EmploymentTemplateId"
      [comboboxDataSource]="employmentTemplates"
      type="combobox"
      comboboxValueField="Id"
      comboboxDisplayField="Name"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.Limit to template' | translate }}"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [width]="170"
      [editableField]="'IsEmploymentTemplateEditable'"
    ></gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="UserEmploymentId"
      [comboboxDataSource]="employmentViews"
      type="combobox"
      comboboxValueField="UserEmploymentId"
      comboboxDisplayField="FullName"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.FullName' | translate }}"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [width]="170"
      [editableField]="'IsUserEmploymentEditable'"
    ></gridColumn>

    <gridColumn
      [isFilterable]="true"
      field="Description"
      type="text"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.Description' | translate }}"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [width]="200"
    ></gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="Amount"
      type="numeric"
      [numbericOption]="NumericOptions"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.FixedAmount' | translate }}"
      [width]="120"
    ></gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="AmountPerUnit"
      type="numeric"
      [numbericOption]="NumericOptions"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.PerUnit' | translate }}"
      [width]="100"
    ></gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="AmountPerKilometer"
      type="numeric"
      [numbericOption]="NumericOptions"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.PerKm' | translate }}"
      [width]="90"
    ></gridColumn>

    <gridColumn
      [isFilterable]="true"
      field="AppliesAfter"
      type="timepicker"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.AppliesAfter' | translate }}"
      [width]="120"
    ></gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="AppliesBefore"
      type="timepicker"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.AppliesBefore' | translate }}"
      [width]="120"
    ></gridColumn>

    <!-- checkbox -->
    <gridColumn
      [isFilterable]="true"
      field="AppliesWeekdays"
      type="checkbox"
      [class]="{ 'text-center': true }"
      title="{{ 'TimeEntryTypeConfigurations.MondayToFriday' | translate }}"
      [editable]="!IsReadOnly"
      [width]="140"
    ></gridColumn>

    <gridColumn
      [isFilterable]="true"
      field="AppliesSaturdays"
      type="checkbox"
      [class]="{ 'text-center': true }"
      title="{{ 'TimeEntryTypeConfigurations.AppliesSaturday' | translate }}"
      [editable]="!IsReadOnly"
      [width]="140"
    ></gridColumn>
    <gridColumn
      [isFilterable]="true"
      field="AppliesSundays"
      type="checkbox"
      [class]="{ 'text-center': true }"
      title="{{ 'TimeEntryTypeConfigurations.AppliesSundays' | translate }}"
      [editable]="!IsReadOnly"
      [width]="140"
    ></gridColumn>

    <gridColumn
      [isFilterable]="true"
      field="AppliesHolidays"
      type="checkbox"
      [class]="{ 'text-center': true }"
      title="{{ 'TimeEntryTypeConfigurations.Holidays' | translate }}"
      [editable]="!IsReadOnly"
      [width]="90"
    ></gridColumn>

    <gridColumn
      [isFilterable]="true"
      field="ConversionFactor"
      type="numeric"
      [numbericOption]="NumericOptions"
      title="{{ 'TimeEntryTypeConfigurations.ConversionFactor' | translate }}"
      [editable]="true"
      [width]="150"
      [numbericOption]="NumericOptions"
    ></gridColumn>

    <gridColumn
      [isFilterable]="true"
      field="TargetUnitTypeId"
      [comboboxDataSource]="unitTypes"
      type="combobox"
      comboboxValueField="Id"
      comboboxDisplayField="Name"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [editable]="true"
      title="{{ 'CompanyTimeEntryTypes.UnitType' | translate }}"
      [width]="170"
    ></gridColumn>
  </grid>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
