import { Component } from '@angular/core';
import { SessionService } from '../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'account',
  templateUrl: './Account.component.html'
})
export class AccountComponent {
  public get isCompanyPreferenceTabActive(): boolean {
    return this.sessionService.currentState === 'tabs.account.companypreferences';
  }

  public get isUserPreferenceTabActive(): boolean {
    return this.sessionService.currentState === 'tabs.account.userpreferences';
  }

  public toggleMenuButton(): void {
    if (!this.sessionService.menuToggled) {
      this.sessionService.menuToggled = true;
    } else {
      this.sessionService.menuToggled = false;
    }
  }

  constructor(public sessionService: SessionService) {}
}
