import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import { ICurrency } from '../../../../Services/ApiModel';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'bank-info',
  templateUrl: './BankInfo.component.html'
})
export class BankInfoComponent extends FormComponentBase implements OnInit {
  @Output() bankInfoChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() editMode: boolean;
  @Input() get bankInfo(): any {
    if (!this.bankInfoValue) {
      this.bankInfoValue = {};
    }

    return this.bankInfoValue;
  }

  set bankInfo(value: any) {
    this.bankInfoValue = value;
    this.bankInfoChange.emit(value);
  }

  public hasNemKontoEnabled = false;
  public currencies: ICurrency[];
  private bankInfoValue: any;

  constructor(private staticDataService: StaticDataService) {
    super();
    this.childIsValid = Array(6).fill(true);
    this.childIsDirty = Array(6).fill(false);
  }

  public ngOnInit(): void {
    this.staticDataService.Currency.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ICurrency[]) => (this.currencies = data)
    );
  }
}
