import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { PriceFormatPipe } from '../../Filters/PriceFormat.pipe';
import { IModuleCompanyView } from '../../Services/ApiModel';
import { AuthenticationService } from '../../Services/Authentication/AuthenticationService';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'module-check',
  templateUrl: './ModuleCheck.component.html'
})
export class ModuleCheckComponent implements OnInit, OnDestroy {
  @Input() moduleId: number;
  @Input() noPopup: boolean;
  @Input() dialogWidth: number;
  @Input() companyOption = false;
  @Output() showDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() hasModuleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public get hasModule(): boolean {
    return this.hasModuleValue;
  }
  public set hasModule(value: boolean) {
    if (value !== this.hasModuleValue) {
      this.hasModuleValue = value;
      // this.hasModuleChange.emit(value);
    }
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public modulePrice: number;
  public moduleName: string;
  public activateModuleDialogVisible = false;
  public moduleActivatedDialogVisible = false;
  private hasModuleValue = false;
  private currentModules: IModuleCompanyView[] = [];
  public moduleActivatedDialogMessage = 'AccountModules.ReloginForChanges';

  constructor(
    private sessionService: SessionService,
    private staticDataService: StaticDataService,
    private dataService: RxDataService,
    private authenticationService: AuthenticationService,
    private priceFormatPipe: PriceFormatPipe
  ) {}

  public ngOnInit(): void {
    this.staticDataService.moduleCompanyView
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((modules: IModuleCompanyView[]) => {
        if (modules && modules.length > 0 && this.currentModules.length === 0) {
          this.currentModules = modules;
          this.onGetModulesViewComplete();
        }
      });
  }

  public onGetModulesViewComplete(): void {
    const module: IModuleCompanyView =
      this.currentModules && this.currentModules.length
        ? this.currentModules.find((m: IModuleCompanyView) => m.ModuleId === this.moduleId)
        : undefined;
    if (module) {
      this.hasModule = module.IsEnabled;
      this.modulePrice = module.ModulePrice;
      this.moduleName = module.ModuleName;
    } else {
      this.hasModule = false;
    }
    setTimeout(() => {
      this.hasModuleChange.emit(this.hasModule);
    });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public IsGreenlandCompanyactivateModuleDialogVisible = false;

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  //format the price before it gets passed as a translation param
  public get translationParams(): any {
    return {
      moduleName: this.moduleName,
      modulePrice:
        this.priceFormatPipe.transform(this.modulePrice, true) === 'Price.Free'
          ? '0,-'
          : this.priceFormatPipe.transform(this.modulePrice, true)
    };
  }

  public get isFullAdmin(): boolean {
    return this.sessionService.role.IsFullAdmin;
  }

  public onClick(): void {
    if (!this.hasModule) {
      if (!this.IsGreenlandCompany) {
        this.activateModuleDialogVisible = !this.noPopup;
        this.showDialog.emit();
      } else {
        this.IsGreenlandCompanyactivateModuleDialogVisible = true;
      }
    }
  }

  private dialogvisible(): boolean {
    return !this.noPopup && this.IsGreenlandCompany;
  }

  public reloadPage(): void {
    window.location.reload();
  }

  public onActivateModuleDialogAction(action: string): void {
    switch (action) {
      case 'ViewPackages':
        this.sessionService.NavigateTo('tabs.company.modules');
        break;
      case 'ActivateModule':
        this.dataService.Modules_EnableModule(this.moduleId).subscribe(
          (message: string) => {
            if (message) {
              this.moduleActivatedDialogMessage = message;
            }
            this.staticDataService.loadStaticData().then(() => {
              this.moduleActivatedDialogVisible = true;
            });
          },
          (): void => {
            // this.reloadPage();
          }
        );
        break;
    }
  }

  public onIsGreenlandCompanyActivateModuleDialogAction(event: any): void {}
}
