<div class="EmployeeFilter" [ngClass]="{ 'is-active': sessionService.employeeListActive }" *ngIf="employeeListVisible">
  <div class="EmployeeFilter-widgets Form--stacked">
    <div class="EmployeeFilter-closeBtnWrapper">
      <a
        class="EmployeeFilter-closeBtn"
        style="color: #fff;font-size: 26px;"
        (click)="onCloseEmployeeFilterEventClick()"
        >X</a
      >
    </div>
    <div class="FormElement">
      <label class="FormElement-label u-hiddenVisually" for="comboboxDepartment">{{
        'Employee.Find employee' | translate
      }}</label>
      <div class="FormElement-control FormElement-control--iconLeft">
        <app-icon
          [icon]="'Search'"
          [iconClass]="'FormElement-icon'"
          (click)="onCloseEmployeeFilterEventClick()"
        ></app-icon>

        <input
          class="FormElement-input"
          type="search"
          placeholder="{{ 'Employee.Search' | translate }}"
          spellcheck="false"
          autocorrect="off"
          autocapitalize="off"
          [(ngModel)]="searchKeyWord"
          (keydown)="onSearchKeyDown($event)"
          (blur)="onSearchBlur($event)"
        />
      </div>
    </div>

    <department-filter
      [(hasDepartmentData)]="hasDepartmentFilter"
      (change)="onDepartmentChanged($event)"
    ></department-filter>

    <sort-by (change)="onSortByChanged($event)"></sort-by>

    <div class="FormElement EmployeeFilter-includeInactive" id="includeInactive">
      <div class="FormElement-control">
        <label class="FormElement-checkbox">
          <input
            type="checkbox"
            id="checkBoxIsActiveUserCompany"
            [(ngModel)]="includeInactive"
            (change)="onIncludeActiveEmployeesChecked(includeInactive)"
          />
          <span class="FormElement-checkboxFaux"></span>
          <span class="FormElement-label">{{ 'Employee.IncludeInactive' | translate }}</span>
        </label>
      </div>
    </div>

    <div class="EmployeeFilter-countWrapper">
      <div class="EmployeeFilter-count">
        {{ 'Employee.Employees' | translate }} ({{ totalEmployee }})
        <app-icon
          class="EmployeeFilter-addnew"
          *ngIf="allowEditUserEmployment"
          [icon]="'AddUser'"
          [tooltip]="'Employee.NewEmployee' | translate"
          (click)="onAddEmployeeClicked()"
        ></app-icon>
      </div>
    </div>
  </div>

  <grid
    class="FormElement-grid CompanyUsers EmployeeList"
    [ngClass]="{ CompanyUsersWithoutDepartment: !hasDepartmentFilter }"
    [editMode]="false"
    [addNew]="false"
    [deleteRow]="false"
    [data]="companyUserListDataSource"
    [idColumn]="'Id'"
    [(selectedItem)]="selectedItem"
    (selectedItemChange)="onGridSelectionChange($event)"
    (cellClick)="onClickSelectEmployee()"
  >
    <gridColumn [field]="'Name'" [type]="'text'" [editable]="false"></gridColumn>
  </grid>
</div>
