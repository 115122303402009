<h1 class="Company-accountName" [innerHTML]="accountName"></h1>
<div class="Island">
  <div class="Cards Cards--flex">
    <div class="Card Card--large Card-inner max-width1000">
      <div class="Card-title">{{ 'Accountant.Users' | translate }}</div>
      <div class="Employee-timeControls" style="margin-bottom: 12px;">
        <div class="FormElement-control PaddingTop">
          <span class="FormElement-label">{{ 'Accountant.FilterUsers' | translate }}</span>
          <div class="FormElement-control margR20">
            <input kendoTextBox class="FormElement-input" [disabled]="false" [(ngModel)]="service.filter" />
          </div>
          <button type="button" class="Button--card accountantAdNewButton" (click)="addNewUserDialogVisible = true">
            {{ 'Accountant.AddNewUser' | translate }}
          </button>
        </div>
      </div>

      <grid
        class="accountant"
        [data]="gridUserFilterData"
        [editMode]="false"
        [pageable]="true"
        [disabled]="false"
        [(skip)]="service.currentPage"
        [idColumn]="'Id'"
        [pageSize]="100"
        [(selectedId)]="service.selectedId"
        [filterable]="'menu'"
        [filter]="userFilter"
        (filterChange)="onUserFilterChange($event)"
        (selectedIdChange)="onSelectedIdChange($event)"
      >
        <gridColumn
          [field]="'Id'"
          [type]="'numeric'"
          [width]="15"
          [title]="'Accountant.Id' | translate"
          [isFilterable]="true"
          [numbericOption]="'0'"
        ></gridColumn>
        <gridColumn
          [field]="'FullName'"
          [type]="'text'"
          [title]="'Accountant.UserName' | translate"
          [width]="250"
          [isFilterable]="true"
        ></gridColumn>
      </grid>
    </div>
    <div class="Card Card--small Card-inner">
      <div class="Card-title">{{ 'Accountant.Companies' | translate }}</div>
      <grid
        class="accountantemployeecompanies"
        [data]="gridFilterData"
        [editMode]="true"
        [pageable]="true"
        [pageSize]="100"
        (saveChangesEvent)="service.save($event)"
        [filterable]="'menu'"
        [filter]="filter"
        (filterChange)="onFilterChange($event)"
      >
        <gridColumn
          [field]="'CompanyName'"
          [editable]="false"
          [type]="'text'"
          [title]="'Accountant.CompanyName' | translate"
          [width]="180"
          [isFilterable]="true"
        ></gridColumn>
        <gridColumn
          [field]="'RoleId'"
          [type]="'combobox'"
          [comboboxDataSource]="staticData.Role | async"
          [comboboxValueField]="'Id'"
          [comboboxDisplayField]="'Name'"
          [allowComboeditNull]="true"
          [editable]="true"
          [title]="'Accountant.Role' | translate"
          [width]="80"
          [isFilterable]="true"
        ></gridColumn>
        <gridColumn
          [field]="'IsPaymentApprover'"
          [editable]="true"
          [type]="'checkboxgrid'"
          [title]="'Accountant.IsPaymentApprover' | translate"
          [width]="90"
          [isFilterable]="true"
        ></gridColumn>
      </grid>
    </div>
  </div>
</div>

<div [ngClass]="{ 'is-editing': service.editMode }">
  <div class="Actions">
    <action-button
      id="ActionButtonAdd"
      [buttonClass]="'Action-button Action-buttonAdd'"
      [icon]="'Add'"
      [label]="'Accountant.AddNewCompany'"
      (click)="addNewUserDialogVisible = true"
      [tooltip]="'Accountant.AddNewCompany'"
    ></action-button>
  </div>
</div>

<!-- <app-accountant-add-new-user-dialog [visible]="addNewUserDialogVisible"></app-accountant-add-new-user-dialog> -->
<app-dialog [(visible)]="addNewUserDialogVisible" [leadingText]="'Accountant.WarnningAddNewUser' | translate">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
