import { EventEmitter } from '@angular/core';
import { Global } from '../../Common/Global';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
var MoreMenuComponent = /** @class */ (function () {
    function MoreMenuComponent(sessionService) {
        this.sessionService = sessionService;
        this.showMatchTemplateMenu = true;
        this.menuClick = new EventEmitter();
        this.companyTemplateTabActive = false;
        this.companySalaryBatchesTabActive = false;
        this.companyGeneralTabActive = false;
        this.employeeHiringStatusTabActive = false;
        this.employeeGeneralTabActive = false;
        this.employeePayrollDataTabActive = false;
        this.accountantCompanyTabActive = false;
    }
    Object.defineProperty(MoreMenuComponent.prototype, "currentTab", {
        get: function () {
            return this.currentTabText;
        },
        set: function (value) {
            if (!value || value.length === 0) {
                return;
            }
            this.currentTabText = value;
            switch (value) {
                case 'tabs.company.configuration.hiringstatus':
                    this.companyTemplateTabActive = true;
                    this.companySalaryBatchesTabActive = false;
                    this.companyGeneralTabActive = false;
                    this.employeeHiringStatusTabActive = false;
                    this.employeeGeneralTabActive = false;
                    this.employeePayrollDataTabActive = false;
                    this.accountantCompanyTabActive = false;
                    break;
                case 'tabs.company.salarybatches':
                    this.companySalaryBatchesTabActive = true;
                    this.companyTemplateTabActive = false;
                    this.companyGeneralTabActive = false;
                    this.employeeHiringStatusTabActive = false;
                    this.employeeGeneralTabActive = false;
                    this.employeePayrollDataTabActive = false;
                    this.accountantCompanyTabActive = false;
                    break;
                case 'tabs.company.general':
                    this.companyGeneralTabActive = true;
                    this.companyTemplateTabActive = false;
                    this.companySalaryBatchesTabActive = false;
                    this.employeeHiringStatusTabActive = false;
                    this.employeeGeneralTabActive = false;
                    this.employeePayrollDataTabActive = false;
                    this.accountantCompanyTabActive = false;
                    break;
                case 'tabs.employee.hiringstatus':
                    this.employeeHiringStatusTabActive = true;
                    this.companyTemplateTabActive = false;
                    this.companySalaryBatchesTabActive = false;
                    this.companyGeneralTabActive = false;
                    this.employeeGeneralTabActive = false;
                    this.employeePayrollDataTabActive = false;
                    this.accountantCompanyTabActive = false;
                    break;
                case 'tabs.employee.general':
                    this.employeeGeneralTabActive = true;
                    this.employeePayrollDataTabActive = false;
                    this.companyTemplateTabActive = false;
                    this.companySalaryBatchesTabActive = false;
                    this.companyGeneralTabActive = false;
                    this.employeeHiringStatusTabActive = false;
                    this.accountantCompanyTabActive = false;
                    break;
                case 'tabs.employee.payrolldata':
                    this.employeePayrollDataTabActive = true;
                    this.employeeGeneralTabActive = false;
                    this.companyTemplateTabActive = false;
                    this.companySalaryBatchesTabActive = false;
                    this.companyGeneralTabActive = false;
                    this.employeeHiringStatusTabActive = false;
                    this.accountantCompanyTabActive = false;
                    break;
                case 'tabs.accountant.companies':
                    this.employeePayrollDataTabActive = false;
                    this.employeeGeneralTabActive = false;
                    this.companyTemplateTabActive = false;
                    this.companySalaryBatchesTabActive = false;
                    this.companyGeneralTabActive = false;
                    this.employeeHiringStatusTabActive = false;
                    this.accountantCompanyTabActive = true;
                    break;
                default:
                    this.companyTemplateTabActive = false;
                    this.companySalaryBatchesTabActive = false;
                    this.companyGeneralTabActive = false;
                    this.employeeHiringStatusTabActive = false;
                    this.employeeGeneralTabActive = false;
                    this.employeePayrollDataTabActive = false;
                    this.accountantCompanyTabActive = false;
                    break;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MoreMenuComponent.prototype, "IsFullAdmin", {
        get: function () {
            return this.sessionService.role.IsFullAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MoreMenuComponent.prototype, "IsSalaryAdminAndHigher", {
        get: function () {
            return this.sessionService.role.IsSalaryAdminAndHigher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MoreMenuComponent.prototype, "HasBackendAccess", {
        get: function () {
            return Global.SESSION && Global.SESSION.HasBackendAccess;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MoreMenuComponent.prototype, "isCompanyGeneralMobile", {
        get: function () {
            return this.sessionService.browser.isMobile && this.sessionService.currentState === 'tabs.company.general';
        },
        enumerable: true,
        configurable: true
    });
    MoreMenuComponent.prototype.onMenuClickEvent = function (data) {
        this.sessionService.showMoreMenuToggled = false;
        this.menuClick.emit(data);
    };
    // @HostListener("click", ["$event"])
    // public onClick(event: any): void
    // {
    //     event.preventDefault();
    //     event.stopPropagation();
    // }
    MoreMenuComponent.prototype.onToggleMoreDropdown = function (event) {
        this.sessionService.showUserMenuToggled = false;
        this.sessionService.showHelpMenuToggled = false;
        this.sessionService.showMoreMenuToggled = !this.sessionService.showMoreMenuToggled;
        ////event.preventDefault();
        event.stopPropagation();
    };
    MoreMenuComponent.prototype.onkeydown = function (event) {
        if (event.code === 'Escape') {
            this.sessionService.showMoreMenuToggled = false;
            event.stopPropagation();
        }
    };
    return MoreMenuComponent;
}());
export { MoreMenuComponent };
