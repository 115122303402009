import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ITaxCardType, IUserEmployment, IUserEmploymentTemplate, UserEmployment } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'new-employment',
  templateUrl: './NewEmployment.component.html'
})
export class NewEmploymentComponent implements OnInit, OnDestroy {
  @Output() public newEmploymentCreated: EventEmitter<IUserEmployment> = new EventEmitter<IUserEmployment>();
  @Output() public isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  private isVisibleValue = false;
  @Input()
  public get isVisible(): boolean {
    return this.isVisibleValue;
  }
  public set isVisible(value: boolean) {
    if (value && this.isVisible !== value) {
      this.prepareNewEmploymentData();
    }
    this.isVisibleValue = value;
    this.isVisibleChange.emit(value);
  }

  @Input() currentCompanyUserId: number;
  hireDate: Date;
  isHiringDateValid = true;
  public defaultTitle = 'Medarbejder';
  public viewModel: IUserEmployment;
  public templates: IUserEmploymentTemplate[];
  public taxCardTypes: ITaxCardType[];

  constructor(
    private dataService: RxDataService,
    public sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {
    this.viewModel = new UserEmployment();
    this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ITaxCardType[]) => (this.taxCardTypes = data)
    );
    this.getTitles();
  }

  public ngOnInit(): void {}

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public fieldRequiredMessage: string;
  public hiringRequiredMessage: string;
  public hiringInvalidMessage: string;
  public titleErrorMessage: string;
  public jobFunctionCodeStrings: string[];
  public showNoTemplateWarning = false;
  public onDialogAction(action: string): void {
    if (action === 'Create') {
      this.onCreateNew();
    }
  }

  private getTitles(): void {
    this.staticDataService.employmentTitle
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((templates: string[]): void => {
        this.jobFunctionCodeStrings = templates;
      });
  }

  public onJobFunctionCodeChanged(): void {}

  onHiringDatePickerChanged(args: any): void {
    if (args) {
      this.isHiringDateValid = !args.isInvalid;
    }
  }

  onCreateNew(): void {
    if (!this.viewModel.Title || this.viewModel.Title.length === 0 || !this.isHiringDateValid) {
      return;
    }

    const userEmployment: IUserEmployment = this.createNewUserEmploymentObject(
      this.viewModel.HireDate,
      this.viewModel.Title,
      this.viewModel.BasedOnTemplateId,
      this.viewModel.PreferredTaxCardTypeId
    );
    this.createUserEmployment(userEmployment);
  }

  private prepareNewEmploymentData(): void {
    this.getTemplates();
  }

  private showNewUserEmploymentDialog(): void {
    this.viewModel.Title = this.defaultTitle;
    this.viewModel.HireDate = this.staticDataService.getCurrentdate();
    this.viewModel.PreferredTaxCardTypeId =
      this.taxCardTypes && this.taxCardTypes.length > 1 ? this.taxCardTypes[0].Id : undefined;
  }

  private createNewUserEmploymentObject(
    hireDate: Date,
    title: string,
    templateId: number,
    taxCardTypeId: number
  ): IUserEmployment {
    const newUserEmployment: IUserEmployment = new UserEmployment();
    newUserEmployment.CompanyUserId = this.currentCompanyUserId;
    newUserEmployment.Title = title;
    newUserEmployment.BasedOnTemplateId = Number(templateId);
    newUserEmployment.PreferredTaxCardTypeId = Number(taxCardTypeId);
    newUserEmployment.VacationReduceSalary = false;
    newUserEmployment.WorksElsewhere = false;
    newUserEmployment.IsAtpTempWorker = false;
    newUserEmployment.HireDate = hireDate;
    return newUserEmployment;
  }

  private getTemplates(): void {
    this.dataService
      .EmploymentTemplates_GetEmploymentTemplates()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((templates: IUserEmploymentTemplate[]): void => {
        this.templates = templates;
        if (this.templates && this.templates.length > 0) {
          this.viewModel.BasedOnTemplateId = this.templates[0].Id;
          this.showNewUserEmploymentDialog();
        } else {
          this.showNoTemplateWarning = true;
        }
      });
  }

  createUserEmployment(userEmployment: IUserEmployment): void {
    this.dataService
      .Employments_CreateUserEmployment(userEmployment)
      .subscribe((response: IUserEmployment): void => this.createUserEmploymentCompleted(response));
  }

  private createUserEmploymentCompleted(employment: IUserEmployment): void {
    this.newEmploymentCreated.emit(employment);
  }
}
