<kendo-grid
  [class.disabled]="disabled"
  #kendoGridComponent
  [data]="gridData"
  (cellClick)="cellClickHandler($event)"
  (cellClose)="cellCloseHandler($event)"
  (remove)="removeHandler($event)"
  (add)="addHandler($event)"
  [navigable]="true"
  [selectable]="isSelected"
  [rowClass]="rowCallback"
  (selectionChange)="onSelectionChange($event)"
  [kendoGridSelectBy]="idColumn"
  [selectedKeys]="selectedRows"
  (dblclick)="onDbClickChange($event)"
  (keydown)="onKeyDown($event)"
  [resizable]="true"
  [filterable]="filterable"
  [filter]="filter"
  (dataStateChange)="onDataStateHandler($event)"
  (filterChange)="onFilterChange($event)"
  [sortable]="{ allowUnsort: true, mode: 'multiple' }"
  (sortChange)="onSortChange($event)"
  [sort]="sort"
  [pageSize]="pageSize"
  [skip]="skip"
  [pageable]="pageListSetting"
  [height]="height"
  (pageChange)="pageChange($event)"
>
  <kendo-grid-messages
    noRecords=""
    [filterIsTrue]="'Filter.Is true' | translate"
    [filterIsFalse]="'Filter.Is false' | translate"
    [filterClearButton]="'Filter.Clear' | translate"
    [filterFilterButton]="'Filter.Filter' | translate"
  ></kendo-grid-messages>

  <ng-template
    kendoGridToolbarTemplate
    *ngIf="showButDisableAddNewButton || (editMode && (addNew || (actions && actions.length > 0)))"
  >
    <span kendoTooltip [title]="addNewTooltips" (click)="AddNewDisableClick()">
      <button *ngIf="addNew" kendoGridAddCommand [disabled]="addNewDisable">{{ addNewText }}</button>
    </span>
    <span *ngIf="actions && actions.length > 0">
      <span *ngFor="let action of actions" [ngClass]="action.actionsSpanClass">
        <button
          class="k-button"
          *ngIf="!action.isHidden && !action.icon"
          [disabled]="action.disable"
          (click)="onGridToolbarClick(action.action)"
        >
          {{ action.label }}
        </button>
        <button
          class="k-button"
          *ngIf="!action.isHidden && action.icon"
          [disabled]="action.disable"
          (click)="onGridToolbarClick(action.action)"
        >
          <svg aria-hidden="true" [ngClass]="'Icon Icon--xsmall'">
            <use [attr.xlink:href]="'#' + action.icon"></use>
          </svg>
        </button>
      </span>
    </span>
  </ng-template>

  <kendo-grid-column
    *ngFor="let subColumn of columns; trackBy: trackIndex"
    [locked]="subColumn.locked"
    [field]="subColumn.field"
    [title]="subColumn.title"
    [width]="subColumn.width"
    [editable]="
      subColumn.type === 'checkbox' || subColumn.type === 'checkboxgrid' ? false : subColumn.editable && editMode
    "
    [class]="cellClass(subColumn)"
    [headerClass]="subColumn.addOnClass"
    [filterable]="subColumn.isFilterable"
    [filter]="subColumn.filter"
    [format]="subColumn.format"
  >
    <ng-template *ngIf="subColumn.headerTooltip || subColumn.helpLink" kendoGridHeaderTemplate let-column>
      <div
        style="vertical-align: unset; height: 100%; display:inline;"
        kendoTooltip
        showAfter="0"
        [title]="subColumn.headerTooltip"
      >
        {{ column.title }}
      </div>
      <app-icon
        *ngIf="subColumn.helpLink"
        [icon]="'Question'"
        [tooltip]="'GlobalDialog.ReadMore' | translate"
        [link]="subColumn.helpLink"
      >
      </app-icon>
    </ng-template>

    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <ng-container [ngSwitch]="subColumn.type">
        <ng-container *ngSwitchCase="'text'">
          <kendo-grid-string-filter-menu
            [column]="column"
            [filter]="filter"
            [extra]="false"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator [text]="'Filter.Is equal to' | translate"></kendo-filter-eq-operator>
            <kendo-filter-neq-operator [text]="'Filter.Is not equal to' | translate"></kendo-filter-neq-operator>
            <kendo-filter-contains-operator [text]="'Filter.Contains' | translate"></kendo-filter-contains-operator>
            <kendo-filter-not-contains-operator [text]="'Filter.Does not contain' | translate">
            </kendo-filter-not-contains-operator>
            <kendo-filter-startswith-operator [text]="'Filter.Starts with' | translate">
            </kendo-filter-startswith-operator>
            <kendo-filter-endswith-operator [text]="'Filter.Ends with' | translate"></kendo-filter-endswith-operator>
            <kendo-filter-isnull-operator [text]="'Filter.Is null' | translate"></kendo-filter-isnull-operator>
            <kendo-filter-isnotnull-operator [text]="'Filter.Is not null' | translate">
            </kendo-filter-isnotnull-operator>
          </kendo-grid-string-filter-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'numeric'">
          <kendo-grid-numeric-filter-menu
            [column]="column"
            [extra]="false"
            operator="eq"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator [text]="'Filter.Is equal to' | translate"></kendo-filter-eq-operator>
            <kendo-filter-neq-operator [text]="'Filter.Is not equal to' | translate"></kendo-filter-neq-operator>
            <kendo-filter-gt-operator [text]="'Filter.Is greater than' | translate"></kendo-filter-gt-operator>
            <kendo-filter-gte-operator [text]="'Filter.Is greater than or equal to' | translate">
            </kendo-filter-gte-operator>
            <kendo-filter-lt-operator [text]="'Filter.Is less than' | translate"></kendo-filter-lt-operator>
            <kendo-filter-lte-operator [text]="'Filter.Is less than or equal to' | translate">
            </kendo-filter-lte-operator>
            <kendo-filter-isnull-operator [text]="'Filter.Is null' | translate"></kendo-filter-isnull-operator>
            <kendo-filter-isnotnull-operator [text]="'Filter.Is not null' | translate">
            </kendo-filter-isnotnull-operator>
          </kendo-grid-numeric-filter-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'datepicker'">
          <kendo-grid-date-filter-menu
            [column]="column"
            [extra]="false"
            [filter]="filter"
            [filterService]="filterService"
            operator="eq"
          >
            <kendo-filter-eq-operator [text]="'Filter.Is equal to' | translate"></kendo-filter-eq-operator>
            <kendo-filter-neq-operator [text]="'Filter.Is not equal to' | translate"></kendo-filter-neq-operator>
            <kendo-filter-after-operator [text]="'Filter.Is after' | translate"></kendo-filter-after-operator>
            <kendo-filter-after-eq-operator [text]="'Filter.Is after or equal to' | translate">
            </kendo-filter-after-eq-operator>
            <kendo-filter-before-operator [text]="'Filter.Is before' | translate"></kendo-filter-before-operator>
            <kendo-filter-before-eq-operator [text]="'Filter.Is before or equal to' | translate">
            </kendo-filter-before-eq-operator>
            <kendo-filter-isnull-operator [text]="'Filter.Is null' | translate"></kendo-filter-isnull-operator>
            <kendo-filter-isnotnull-operator [text]="'Filter.Is not null' | translate">
            </kendo-filter-isnotnull-operator>
          </kendo-grid-date-filter-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'checkbox'">
          <kendo-grid-boolean-filter-menu [column]="column" [filter]="filter" [filterService]="filterService">
          </kendo-grid-boolean-filter-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'combobox'">
          <!-- <kendo-multiselect
            style="width:220px"
            [(data)]="subColumn.comboboxDataSource"
            [textField]="subColumn.comboboxDisplayField"
            [valueField]="subColumn.comboboxValueField"
            [valuePrimitive]="true"
            (valueChange)="subColumn.onComboboxFilterChange($event, filterService)"
          >
          </kendo-multiselect> -->
          <dropdown-filter
            [(data)]="subColumn.comboboxDataSource"
            [textField]="subColumn.comboboxDisplayField"
            [valueField]="subColumn.comboboxValueField"
            [fieldName]="subColumn.field"
            valuePrimitive="true"
            [selectedValue]="subColumn.selectedValue"
            (filterChange)="subColumn.onFilterChange($event, filterService)"
          >
          </dropdown-filter>
        </ng-container>
        <ng-container *ngSwitchCase="'timepicker'">
          <kendo-grid-string-filter-menu
            [column]="column"
            [filter]="filter"
            [extra]="false"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator [text]="'Filter.Is equal to' | translate"></kendo-filter-eq-operator>
            <kendo-filter-neq-operator [text]="'Filter.Is not equal to' | translate"></kendo-filter-neq-operator>
            <kendo-filter-contains-operator [text]="'Filter.Contains' | translate"></kendo-filter-contains-operator>
            <kendo-filter-not-contains-operator [text]="'Filter.Does not contain' | translate">
            </kendo-filter-not-contains-operator>
            <kendo-filter-startswith-operator [text]="'Filter.Starts with' | translate">
            </kendo-filter-startswith-operator>
            <kendo-filter-endswith-operator [text]="'Filter.Ends with' | translate"></kendo-filter-endswith-operator>
            <kendo-filter-isnull-operator [text]="'Filter.Is null' | translate"></kendo-filter-isnull-operator>
            <kendo-filter-isnotnull-operator [text]="'Filter.Is not null' | translate">
            </kendo-filter-isnotnull-operator>
          </kendo-grid-string-filter-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'autocomplete'">
          <kendo-grid-string-filter-menu
            [column]="column"
            [filter]="filter"
            [extra]="false"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator [text]="'Filter.Is equal to' | translate"></kendo-filter-eq-operator>
            <kendo-filter-neq-operator [text]="'Filter.Is not equal to' | translate"></kendo-filter-neq-operator>
            <kendo-filter-contains-operator [text]="'Filter.Contains' | translate"></kendo-filter-contains-operator>
            <kendo-filter-not-contains-operator [text]="'Filter.Does not contain' | translate">
            </kendo-filter-not-contains-operator>
            <kendo-filter-startswith-operator [text]="'Filter.Starts with' | translate">
            </kendo-filter-startswith-operator>
            <kendo-filter-endswith-operator [text]="'Filter.Ends with' | translate"></kendo-filter-endswith-operator>
            <kendo-filter-isnull-operator [text]="'Filter.Is null' | translate"></kendo-filter-isnull-operator>
            <kendo-filter-isnotnull-operator [text]="'Filter.Is not null' | translate">
            </kendo-filter-isnotnull-operator>
          </kendo-grid-string-filter-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'percentage'">
          <percentage-edit
            *ngSwitchCase="'percentage'"
            [rightText]="true"
            [editMode]="true"
            [step]="true"
            [percentageSign]="false"
            [allowDecimal]="subColumn.percentageDecimals"
            (valueChange)="subColumn.onPercentageChange($event, filterService)"
            [setTimeout]="false"
          >
          </percentage-edit>
        </ng-container>
        <ng-container *ngSwitchCase="'image'">
          <dropdown-filter
            [(data)]="subColumn.comboboxDataSource"
            [textField]="subColumn.comboboxDisplayField"
            [valueField]="subColumn.comboboxValueField"
            [fieldName]="subColumn.field"
            valuePrimitive="true"
            [selectedValue]="subColumn.selectedValue"
            (filterChange)="subColumn.onFilterChange($event, filterService)"
          >
          </dropdown-filter>
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <!-- {{dataItem[subColumn.field]}} -->
      <ng-container [ngSwitch]="subColumn.type">
        <ng-container *ngSwitchCase="'text'">
          <span
            *ngIf="subColumn.text"
            [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          >
            {{ subColumn.text }}
          </span>
          <app-text-edit
            *ngIf="!subColumn.text"
            [viewClass]="subColumn.viewClass"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [editMode]="false"
            [(value)]="dataItem[subColumn.field]"
            [setTimeout]="false"
            kendoTooltip
            [isGrid]="true"
            [addonIcon]="
              subColumn.addOnIcon
                ? subColumn.addOnIcon
                : dataItem && subColumn.addOnIconFieldString
                ? dataItem[subColumn.addOnIconFieldString]
                : ''
            "
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            (addonClick)="subColumn.addonClick.emit()"
          >
          </app-text-edit>
        </ng-container>

        <ng-container *ngSwitchCase="'numeric'">
          <numeric-edit
            [rightText]="true"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [editMode]="false"
            [(value)]="dataItem[subColumn.field]"
            [option]="subColumn.numbericOption"
            [isGrid]="true"
            [setTimeout]="false"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          >
          </numeric-edit>
          <app-grid-in-cell-popup
            *ngIf="subColumn.hasIncellPopup"
            [content]="subColumn.popupContent"
            [actions]="subColumn.popupActions"
            [visible]="dataItem[subColumn.field + 'PopupVisible']"
            (click)="onIncellPopUpClickAction(dataItem, dataItem.triggerField, $event)"
          >
          </app-grid-in-cell-popup>
        </ng-container>

        <check-edit
          *ngSwitchCase="'checkbox'"
          customClass="checkBox-center"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          [editMode]="subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable"
          [(value)]="dataItem[subColumn.field]"
          (valueChange)="onCheckBoxCheckedChanged(dataItem, subColumn.field)"
          [tooltip]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
        >
        </check-edit>

        <checkgrid-edit
          *ngSwitchCase="'checkboxgrid'"
          customClass="checkBox-center"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          [editMode]="subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable"
          [(value)]="dataItem[subColumn.field]"
          (valueChange)="onCheckBoxCheckedChanged(dataItem, subColumn.field, true)"
          [tooltip]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
        >
        </checkgrid-edit>

        <combobox-edit-control
          *ngSwitchCase="'combobox'"
          [viewClass]="subColumn.viewClass"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          [noToggle]="true"
          [editMode]="false"
          [idField]="subColumn.comboboxValueField"
          [filterFields]="subColumn.comboboxFilterFields"
          [textField]="subColumn.comboboxDisplayField"
          [comboboxDataSource]="subColumn.comboboxDataSource"
          [(value)]="dataItem[subColumn.field]"
          [tooltipField]="subColumn.tooltipField ? subColumn.tooltipField : ''"
          kendoTooltip
          [title]="subColumn.tooltipFieldNonEdit ? dataItem[subColumn.tooltipFieldNonEdit] : ''"
          [nullValueLabel]="subColumn.allowComboeditNull ? ('DropdownList.None' | translate) : ''"
          [defaultnullvalue]="subColumn.defaultnullvalue"
        >
        </combobox-edit-control>

        <date-picker-control
          *ngSwitchCase="'datepicker'"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          (customData)="onDataChanged(dataItem, subColumn.field)"
          [isGrid]="true"
          [editMode]="false"
          [minDate]="subColumn.minDate"
          [(value)]="dataItem[subColumn.field]"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
        >
        </date-picker-control>

        <time-picker
          *ngSwitchCase="'timepicker'"
          [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [editMode]="false"
          [setTimeout]="false"
          [(value)]="dataItem[subColumn.field]"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
        >
        </time-picker>

        <percentage-edit
          *ngSwitchCase="'percentage'"
          [rightText]="true"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          [editMode]="false"
          [allowDecimal]="subColumn.percentageDecimals"
          [(value)]="dataItem[subColumn.field]"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
        >
        </percentage-edit>

        <auto-complete-textbox
          *ngSwitchCase="'autocomplete'"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          [editMode]="false"
          [autoCompleteDataSource]="subColumn.comboboxDataSource"
          [viewClass]="subColumn.viewClass"
          [(value)]="dataItem[subColumn.field]"
          [textField]="subColumn.comboboxDisplayField"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          [autocompleteby1value]="subColumn.autocompleteby1value"
        >
        </auto-complete-textbox>

        <app-icon
          *ngSwitchCase="'icon'"
          (click)="appIconClick(dataItem, subColumn.iconAction)"
          [icon]="dataItem[subColumn.iconField]"
          [tooltip]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          [hostClass]="'align-center'"
          [disable]="subColumn.disableButtonFeild ? dataItem[subColumn.disableButtonFeild] : false"
        >
        </app-icon>

        <div class="align-center" *ngSwitchCase="'buttonPopUp'">
          <app-button-popup
            [label]="subColumn.popupButtonText"
            [buttonClass]="subColumn.popupButtonClass"
            [popupButtonIcon]="subColumn.popupButtonArrayContentIcon"
            (IconClick)="appIconClick(dataItem, $event)"
            [isUseLabelAppIcon]="subColumn.isPopupButtonIcon"
            [icon]="subColumn.popupButtonIcon"
            (ButtonClick)="ButtonPopUpClick.emit()"
          >
          </app-button-popup>
        </div>

        <div kendoTooltip [title]="subColumn.disableGridButtonTooltip | translate" *ngSwitchCase="'button'">
          <button-component
            [buttonClass]="subColumn.buttonClass"
            [tooltip]="subColumn.buttonToottip | translate"
            [isDisabled]="
              subColumn.disableButtonFeild ? dataItem[subColumn.disableButtonFeild] : subColumn.disableGridButtonValue
            "
            [label]="subColumn.buttonLabel | translate"
            (click)="onGridButtonClickEvent(dataItem, subColumn.buttonAction)"
          >
          </button-component>
        </div>

        <div class="align-center" *ngSwitchCase="'image'">
          <app-load-image-country [countryCode]="dataItem[subColumn.field]"> </app-load-image-country>
        </div>

        <span
          *ngSwitchCase="'subProberty'"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
        >
          {{ getParamSubProberty(dataItem, subColumn.subProberty) }}
        </span>
      </ng-container>
    </ng-template>

    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <ng-container [ngSwitch]="subColumn.type">
        <ng-container *ngSwitchCase="'text'">
          <span
            *ngIf="subColumn.text"
            [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          >
            {{ subColumn.text }}
          </span>
          <app-text-edit
            *ngIf="!subColumn.text"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [isGrid]="true"
            [editMode]="
              (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
              (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
            "
            [(value)]="dataItem[subColumn.field]"
            (valueChange)="onDataChanged(dataItem, subColumn.field)"
            [setTimeout]="false"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          >
          </app-text-edit>
        </ng-container>

        <ng-container *ngSwitchCase="'numeric'">
          <numeric-edit
            [rightText]="true"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [isGrid]="true"
            [editMode]="
              (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
              (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
            "
            [(value)]="dataItem[subColumn.field]"
            [option]="subColumn.numbericOption"
            (valueChange)="onDataChanged(dataItem, subColumn.field, dataItem[subColumn.editableField])"
            [setTimeout]="false"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          >
          </numeric-edit>
          <!-- <app-grid-in-cell-popup *ngIf="subColumn.hasIncellPopup" [content]="subColumn.popupContent" [actions]="subColumn.popupActions"
                                  [visible]="dataItem[subColumn.field + 'PopupVisible']" (onClick)="onIncellPopUpClickAction(dataItem, dataItem.triggerField, $event)">
                    </app-grid-in-cell-popup> -->
        </ng-container>

        <!--<check-edit *ngSwitchCase="'checkbox'" customClass="checkBox-center" [hostClass]="subColumn.classField ? dataItem[subColumn.classField]: ''"
                        [editMode]="(subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) && (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)"
                        [isGrid]="true" [(value)]="dataItem[subColumn.field]" (valueChange)="onCheckBoxCheckedChanged(dataItem)"
                        [setTimeout]="false" kendoTooltip [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''">
            </check-edit>-->

        <combobox-edit-control
          *ngSwitchCase="'combobox'"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [editMode]="
            (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
            (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
          "
          [isGrid]="true"
          [noToggle]="true"
          [textField]="subColumn.comboboxDisplayField"
          [idField]="subColumn.comboboxValueField"
          [tooltipField]="subColumn.tooltipField ? subColumn.tooltipField : ''"
          [filterFields]="subColumn.comboboxFilterFields"
          [comboboxDataSource]="subColumn.comboboxDataSource"
          [(value)]="dataItem[subColumn.field]"
          (valueChange)="onDataChanged(dataItem, subColumn.field, subColumn.editableField, false)"
          [setTimeout]="false"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          [nullValueLabel]="subColumn.allowComboeditNull ? ('DropdownList.None' | translate) : ''"
          [defaultnullvalue]="subColumn.defaultnullvalue"
        >
        </combobox-edit-control>

        <date-picker-control
          *ngSwitchCase="'datepicker'"
          [editMode]="
            (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
            (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
          "
          [isGrid]="true"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [(value)]="dataItem[subColumn.field]"
          (valueChange)="onDataChanged(dataItem, subColumn.field)"
          kendoTooltip
          [minDate]="subColumn.minDate"
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          (customData)="onDataChanged(dataItem, subColumn.field)"
          (directional)="onKeyDown($event)"
        >
        </date-picker-control>

        <time-picker
          *ngSwitchCase="'timepicker'"
          [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [editMode]="
            (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
            (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
          "
          [setTimeout]="false"
          [(value)]="dataItem[subColumn.field]"
          (valueChange)="onDataChanged(dataItem, subColumn.field, dataItem[subColumn.editableField])"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
        >
        </time-picker>

        <percentage-edit
          *ngSwitchCase="'percentage'"
          [rightText]="true"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          [step]="true"
          [percentageSign]="false"
          [editMode]="
            (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
            (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
          "
          [allowDecimal]="subColumn.percentageDecimals"
          [(value)]="dataItem[subColumn.field]"
          (valueChange)="onDataChanged(dataItem, subColumn.field)"
          [setTimeout]="false"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
        >
        </percentage-edit>

        <auto-complete-textbox
          *ngSwitchCase="'autocomplete'"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          [editMode]="
            (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
            (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
          "
          [autoCompleteDataSource]="subColumn.comboboxDataSource"
          [(value)]="dataItem[subColumn.field]"
          [textField]="subColumn.comboboxDisplayField"
          (valueChange)="onDataChanged(dataItem, subColumn.field)"
          [setTimeout]="false"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          [autocompleteby1value]="subColumn.autocompleteby1value"
        >
        </auto-complete-textbox>

        <app-icon
          *ngSwitchCase="'icon'"
          (click)="appIconClick(dataItem, subColumn.iconAction)"
          [icon]="dataItem[subColumn.iconField]"
          [tooltip]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          [hostClass]="'align-center'"
          [disable]="subColumn.disableButtonFeild ? dataItem[subColumn.disableButtonFeild] : false"
        >
        </app-icon>

        <div class="align-center" *ngSwitchCase="'image'">
          <app-load-image-country [countryCode]="dataItem[subColumn.field]"> </app-load-image-country>
        </div>

        <div *ngSwitchCase="'buttonPopUp'">
          <app-button-popup
            [label]="subColumn.popupButtonText"
            [buttonClass]="subColumn.popupButtonClass"
            [popupButtonIcon]="subColumn.popupButtonArrayContentIcon"
            (IconClick)="appIconClick(dataItem, $event)"
            [isUseLabelAppIcon]="subColumn.isPopupButtonIcon"
            [icon]="subColumn.popupButtonIcon"
          >
          </app-button-popup>
        </div>

        <div kendoTooltip [title]="subColumn.disableGridButtonTooltip | translate" *ngSwitchCase="'button'">
          <button-component
            [buttonClass]="subColumn.buttonClass"
            [tooltip]="subColumn.buttonToottip | translate"
            [isDisabled]="
              subColumn.disableButtonFeild ? dataItem[subColumn.disableButtonFeild] : subColumn.disableGridButtonValue
            "
            [label]="subColumn.buttonLabel | translate"
            (click)="onGridButtonClickEvent(dataItem, subColumn.buttonAction)"
          >
          </button-component>
        </div>

        <span
          *ngSwitchCase="'subProberty'"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
        >
          {{ getParamSubProberty(dataItem, subColumn.subProberty) }}
        </span>
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column *ngIf="deleteRow" title="" width="50">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem="dataItem">
      <span kendoTooltip [title]="DeleteTooltips">
        <a
          *ngIf="!deleteRowField || dataItem[deleteRowField]"
          kendoGridRemoveCommand
          role="button"
          class="k-button k-button-icontext deleteButtonColumn k-grid-X"
          href="#"
          [disabled]="disableDeleteButton"
        >
          <span class=""></span>X
        </a>
      </span>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
