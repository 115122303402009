import { ChangeDetectorRef, ElementRef, EventEmitter } from '@angular/core';
import { ReflectionHelper } from '../Common/ReflectionHelper';
var CheckboxGridComponent = /** @class */ (function () {
    function CheckboxGridComponent(elementRef, changeDetectorRef) {
        this.tooltip = '';
        this.helpTooltip = '';
        this.isOpenLink = true;
        this.helpIcon = 'Help';
        this.selfService = '';
        this.isLink = false;
        this.isGrid = true;
        this.editMode = true;
        this.valueChange = new EventEmitter();
    }
    Object.defineProperty(CheckboxGridComponent.prototype, "labelClass", {
        get: function () {
            return this.customClass + ' ' + (this.isGrid ? 'checkBox-label-center' : '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckboxGridComponent.prototype, "isDisabled", {
        get: function () {
            return this.disable ? this.disable : !this.editMode;
        },
        enumerable: true,
        configurable: true
    });
    CheckboxGridComponent.prototype.valid = function () {
        return true;
    };
    CheckboxGridComponent.prototype.convertValue = function (value) {
        if (value && ReflectionHelper.isString(value)) {
            return value.toLowerCase() === 'true';
        }
        else {
            return value;
        }
    };
    CheckboxGridComponent.prototype.onChange = function (value) {
        this.valueChange.emit(value);
    };
    return CheckboxGridComponent;
}());
export { CheckboxGridComponent };
