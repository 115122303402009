import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Global } from '../../../Common/Global';
import { ISalaryStatement } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { DownloadService } from '../../../Services/SharedServices/DownloadService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';

@Component({
  selector: 'payslip-mobile',
  templateUrl: './PayslipMobile.component.html'
})
export class PayslipMobileComponent implements OnInit {
  @Input()
  public get selectedSalaryStatement(): ISalaryStatement {
    return this.currentSelectedSalaryStatement;
  }
  public set selectedSalaryStatement(value: ISalaryStatement) {
    if (value !== this.currentSelectedSalaryStatement) {
      this.currentSelectedSalaryStatement = value;
      this.selectedSalaryStatementChange.emit(value);
    }
  }

  @Input()
  public get salaryStatementsNoneType(): any[] {
    return this.currentSalaryStatementsNoneType;
  }
  public set salaryStatementsNoneType(value: any[]) {
    this.currentSalaryStatementsNoneType = value;
    this.selectedFirstRow = false;
  }

  @Input() public groupIconAction: any[];

  @Output() public iconClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public selectedSalaryStatementChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public Revert: EventEmitter<any> = new EventEmitter<any>();

  public get IsPaymentApprover(): boolean {
    return Global.SESSION && Global.SESSION.IsPaymentApprover;
  }

  public classoverlayScreen = '';
  public classShowSelectPeriodDialog = '';
  public selectedFirstRow: boolean;
  public detailSalaryStatement: any[] = [];
  public isFinalize = false;
  public currentIdSelect: number;

  private currentSelectedSalaryStatement: ISalaryStatement;
  private currentSalaryStatementsNoneType: any[] = [];
  private translationServiceTerms: string[];
  private labelPeriodFrom: string;
  private labelPeriodTo: string;
  private labelBaseSalary: string;
  private labelHoursWorked: string;
  private labelTaxDeduction: string;
  private labelTaxRate: string;
  private labelPaidOut: string;
  private labelSpentVacation: string;
  private labelVacationMoneyEarnedGross: string;
  private labelVacationMoneyEarnedNet: string;

  public convertDateToString(dateconvert: string): string {
    if (dateconvert) {
      const currentdate: Date = new Date(dateconvert);
      return this.sessionService.toString(currentdate);
    }

    return '';
  }

  constructor(
    private settingService: SettingService,
    private dataService: RxDataService,
    private downloadService: DownloadService,
    public renderer: Renderer2,
    private sessionService: SessionService,
    private translateService: TranslateService
  ) {
    this.translationServiceTerms = [
      'EmployeeGeneral.PeriodStart',
      'EmployeeGeneral.PeriodEnd',
      'EmployeePayslip.BaseSalary',
      'EmployeePayslip.HoursWorked',
      'EmployeePayslip.TaxDeduction',
      'EmployeePayslip.TaxRate',
      'EmployeePayslip.PaidOut',
      'EmployeePayslip.SpentVacation',
      'EmployeePayslip.VacationMoneyEarnedGross',
      'EmployeePayslip.VacationMoneyEarnedNet'
    ];
  }

  public ngOnInit(): void {
    this.translateService.get(this.translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
      this.setTranslationTerms(translations);
    });
  }

  public setTranslationTerms(translations: { [key: string]: string }): void {
    this.labelPeriodFrom = translations['EmployeeGeneral.PeriodStart'];
    this.labelPeriodTo = translations['EmployeeGeneral.PeriodEnd'];
    this.labelBaseSalary = translations['EmployeePayslip.BaseSalary'];
    this.labelHoursWorked = translations['EmployeePayslip.HoursWorked'];
    this.labelTaxDeduction = translations['EmployeePayslip.TaxDeduction'];
    this.labelTaxRate = translations['EmployeePayslip.TaxRate'];
    this.labelPaidOut = translations['EmployeePayslip.PaidOut'];
    this.labelSpentVacation = translations['EmployeePayslip.SpentVacation'];
    this.labelVacationMoneyEarnedGross = translations['EmployeePayslip.VacationMoneyEarnedGross'];
    this.labelVacationMoneyEarnedNet = translations['EmployeePayslip.VacationMoneyEarnedNet'];
  }

  public appIconClick($event: any, salaryStatement: any, action: any): void {
    if ($event) {
      $event.stopPropagation();
      $event.preventDefault();

      const event: any = {};
      event.dataItem = salaryStatement;
      event.iconAction = action;
      this.iconClick.emit(event);
    }
  }

  public get isAllfinalize(): boolean {
    return this.salaryStatementsNoneType &&
      this.salaryStatementsNoneType.length > 0 &&
      this.salaryStatementsNoneType.filter((model: any) => model.IsFinalized === true).length ===
        this.salaryStatementsNoneType.length
      ? true
      : false;
  }

  public onSelectsalaryStatement(salaryStatement: any) {
    this.selectedSalaryStatement = this.salaryStatementsNoneType.find((model: any) => model.Id === salaryStatement.Id);
    this.selectedFirstRow = this.salaryStatementsNoneType[0].Id === this.selectedSalaryStatement.Id;
    this.detailSalaryStatement = this.createDataSourceSalaryStatements();
    this.currentIdSelect = salaryStatement.Id;
    this.isFinalize = salaryStatement.IsFinalized;
    this.onShowSelectPeriodDialog();
  }

  public RevertPayslips(): void {
    this.onShowSelectPeriodDialog();
    setTimeout(() => {
      this.Revert.emit();
    }, 300);
  }

  public onShowSelectPeriodDialog(): void {
    if (this.classShowSelectPeriodDialog) {
      this.classShowSelectPeriodDialog = '';
      setTimeout(() => {
        this.currentIdSelect = null;
        this.isFinalize = false;
        this.classoverlayScreen = '';
      }, 200);
      return;
    }
    this.classoverlayScreen = 'overlayScreen-enable';
    setTimeout(() => {
      this.classShowSelectPeriodDialog = 'PreviewGridMobileEnable';
    });
  }

  public formatNumber(unitstring: number): string {
    if (unitstring) {
      return this.sessionService
        .toString(parseFloat(unitstring.toString()), '0.00')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else if (unitstring === 0) {
      return '0,00';
    }
    return '';
  }

  public createDataSourceSalaryStatements(): any[] {
    return [
      {
        label: this.labelPeriodFrom,
        value: this.convertDateToString(this.selectedSalaryStatement.PeriodFrom.toString())
      },
      {
        label: this.labelPeriodTo,
        value: this.convertDateToString(this.selectedSalaryStatement.PeriodTo.toString())
      },
      {
        label: this.labelBaseSalary,
        value: this.formatNumber(this.selectedSalaryStatement.PrimaryIncomeAmount)
      },
      { label: this.labelHoursWorked, value: this.formatNumber(this.selectedSalaryStatement.HoursWorked) },
      {
        label: this.labelTaxDeduction,
        value: this.formatNumber(this.selectedSalaryStatement.AppliedTaxDeduction)
      },
      { label: this.labelTaxRate, value: this.formatNumber(this.selectedSalaryStatement.TaxRate * 100) + '%' },
      { label: this.labelPaidOut, value: this.formatNumber(this.selectedSalaryStatement.PayoutAmount) },
      {
        label: this.labelSpentVacation,
        value: this.formatNumber(this.selectedSalaryStatement.VacationDaysSpent)
      },
      {
        label: this.labelVacationMoneyEarnedGross,
        value: this.formatNumber(this.selectedSalaryStatement.VacationMoneyEarnedGross)
      },
      {
        label: this.labelVacationMoneyEarnedNet,
        value: this.formatNumber(this.selectedSalaryStatement.VacationMoneyEarnedNet)
      }
    ];
  }
}
