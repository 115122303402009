/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../CustomControls/TextEditUpdate.component.ngfactory";
import * as i2 from "../../../CustomControls/TextEditUpdate.component";
import * as i3 from "../../../Services/SharedServices/Session/SessionService";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./EmployeeName.component";
var styles_EmployeeNameComponent = [];
var RenderType_EmployeeNameComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmployeeNameComponent, data: {} });
export { RenderType_EmployeeNameComponent as RenderType_EmployeeNameComponent };
function View_EmployeeNameComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h3", [["class", "EmployeeInfo-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " ", " ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.companyUser.FirstName; var currVal_1 = _co.companyUser.MiddleName; var currVal_2 = _co.companyUser.LastName; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_EmployeeNameComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-text-edit", [], null, [[null, "isValidChange"], [null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isValidChange" === en)) {
        var pd_0 = ((_co.childIsValid[0] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("isDirtyChange" === en)) {
        var pd_1 = ((_co.childIsDirty[0] = $event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.companyUser.FirstName = $event) !== false);
        ad = (pd_2 && ad);
    } if (("valueChange" === en)) {
        var pd_3 = (_co.onChange() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_TextEditUpdateComponent_0, i1.RenderType_TextEditUpdateComponent)), i0.ɵdid(1, 114688, null, 0, i2.TextEditUpdateComponent, [i3.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], isValid: [3, "isValid"], label: [4, "label"], type: [5, "type"], required: [6, "required"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.companyUser.FirstName; var currVal_1 = _co.editMode; var currVal_2 = _co.childIsDirty[0]; var currVal_3 = _co.childIsValid[0]; var currVal_4 = i0.ɵunv(_v, 1, 4, i0.ɵnov(_v, 2).transform("Employee.First name")); var currVal_5 = "text"; var currVal_6 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_EmployeeNameComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-text-edit", [], null, [[null, "isValidChange"], [null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isValidChange" === en)) {
        var pd_0 = ((_co.childIsValid[1] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("isDirtyChange" === en)) {
        var pd_1 = ((_co.childIsDirty[1] = $event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.companyUser.MiddleName = $event) !== false);
        ad = (pd_2 && ad);
    } if (("valueChange" === en)) {
        var pd_3 = (_co.onChange() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_TextEditUpdateComponent_0, i1.RenderType_TextEditUpdateComponent)), i0.ɵdid(1, 114688, null, 0, i2.TextEditUpdateComponent, [i3.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], isValid: [3, "isValid"], label: [4, "label"], type: [5, "type"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.companyUser.MiddleName; var currVal_1 = _co.editMode; var currVal_2 = _co.childIsDirty[1]; var currVal_3 = _co.childIsValid[1]; var currVal_4 = i0.ɵunv(_v, 1, 4, i0.ɵnov(_v, 2).transform("Employee.Middle name")); var currVal_5 = "text"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_EmployeeNameComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-text-edit", [], null, [[null, "isValidChange"], [null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isValidChange" === en)) {
        var pd_0 = ((_co.childIsValid[2] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("isDirtyChange" === en)) {
        var pd_1 = ((_co.childIsDirty[2] = $event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.companyUser.LastName = $event) !== false);
        ad = (pd_2 && ad);
    } if (("valueChange" === en)) {
        var pd_3 = (_co.onChange() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_TextEditUpdateComponent_0, i1.RenderType_TextEditUpdateComponent)), i0.ɵdid(1, 114688, null, 0, i2.TextEditUpdateComponent, [i3.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], isValid: [3, "isValid"], label: [4, "label"], type: [5, "type"], required: [6, "required"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.companyUser.LastName; var currVal_1 = _co.editMode; var currVal_2 = _co.childIsDirty[2]; var currVal_3 = _co.childIsValid[2]; var currVal_4 = i0.ɵunv(_v, 1, 4, i0.ɵnov(_v, 2).transform("Employee.Last name")); var currVal_5 = "text"; var currVal_6 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_EmployeeNameComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeeNameComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeeNameComponent_2)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeeNameComponent_3)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeeNameComponent_4)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editMode; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.editMode; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.editMode; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.editMode; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_EmployeeNameComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-employee-name", [], null, null, null, View_EmployeeNameComponent_0, RenderType_EmployeeNameComponent)), i0.ɵdid(1, 180224, null, 0, i6.EmployeeNameComponent, [], null, null)], null, null); }
var EmployeeNameComponentNgFactory = i0.ɵccf("app-employee-name", i6.EmployeeNameComponent, View_EmployeeNameComponent_Host_0, { editMode: "editMode", isDirty: "isDirty", isValid: "isValid", companyUser: "companyUser" }, { editModeChange: "editModeChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange", valueChange: "valueChange" }, []);
export { EmployeeNameComponentNgFactory as EmployeeNameComponentNgFactory };
