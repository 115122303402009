import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, Renderer2 } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import { Global } from '../../../../Common/Global';
import { SalaryRecordItemHelper } from '../../../../Employee/EmployeeTab/Payroll/Controls/SalaryRecordItemHelper';
import { UnitTypeEnum } from '../../../../Model/Enum';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';
var SalaryTemplateComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SalaryTemplateComponent, _super);
    function SalaryTemplateComponent(dataService, staticDataService, renderer, sessionService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.renderer = renderer;
        _this.sessionService = sessionService;
        _this.salaryTemplateChange = new EventEmitter();
        _this.visibleValue = false;
        _this.visibleChange = new EventEmitter();
        _this.updatedSalaryTemplateChange = new EventEmitter();
        _this.gridData = [];
        _this.numericOptions = { format: 'n2', decimals: 2, step: 1, min: 0, spinners: false };
        _this.editDescriptionDialogVisible = false;
        _this.confirmationDialogVisible = false;
        _this.dirty = false;
        _this.loadSalaryRecords = true;
        _this.customDeleteRow = false;
        _this.refreshData = false;
        _this.keyPress = { 17: false, 18: false, 107: false, 109: false };
        return _this;
    }
    Object.defineProperty(SalaryTemplateComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            var _this = this;
            if (this.visibleValue !== value) {
                if (value) {
                    document.getElementById('salaryTemplateDialog').addEventListener('keydown', function (event) {
                        _this.onKeyDown(event);
                    });
                }
                else {
                    document.getElementById('salaryTemplateDialog').removeEventListener('keydown', function (event) {
                        //this.onKeyDown(event);
                    });
                }
                this.visibleValue = value;
                this.visibleChange.emit(value);
                if (value && (!this.editMode || this.loadSalaryRecords)) {
                    this.loadSalaryTemplateRecords();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryTemplateComponent.prototype, "templateId", {
        get: function () {
            return this.templateIdValue;
        },
        set: function (value) {
            if (this.templateIdValue !== value) {
                this.templateIdValue = value;
                this.loadSalaryRecords = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryTemplateComponent.prototype, "updatedSalaryTemplate", {
        get: function () {
            return this.updatedSalaryTemplateValue;
        },
        set: function (value) {
            this.updatedSalaryTemplateValue = value;
            this.updatedSalaryTemplateChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    SalaryTemplateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadSalaryTypes();
        this.unitTypes = Global.UNIT_TYPES;
        // document.getElementById("salaryTemplateDialog").addEventListener("keydown", (event: any): void => {
        //     this.onKeyDown(event);
        // });
        this.staticDataService.SalaryRecordPersistenceType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.persistanceTypes = data); });
    };
    SalaryTemplateComponent.prototype.hasChanges = function () {
        return this.dirty;
    };
    SalaryTemplateComponent.prototype.loadSalaryTypes = function () {
        var _this = this;
        this.dataService.SalaryTypes_GetSalaryTypeViews().subscribe(function (salaryTypes) {
            _this.salaryTypes = salaryTypes.filter(function (salaryType) { return salaryType.IsVisible; });
        });
    };
    SalaryTemplateComponent.prototype.loadSalaryTemplateRecords = function () {
        var _this = this;
        this.selectedRecord = undefined;
        this.loadSalaryRecords = false;
        this.dataService
            .EmploymentTemplates_GetTemplateSalaryRecordByEmploymentTemplate(this.templateId)
            .subscribe(function (salaryRecords) {
            _this.gridData = salaryRecords.map(function (data) {
                var view = data;
                view.GridId = view.Id;
                _this.updateView(view);
                return view;
            });
        });
    };
    SalaryTemplateComponent.prototype.onGridSelectionChange = function (record) {
        this.selectedRecord = record;
    };
    SalaryTemplateComponent.prototype.onGridAction = function (action) {
        if (action === 'EditDescription' && this.selectedRecord) {
            this.editDescriptionDialogVisible = true;
        }
    };
    SalaryTemplateComponent.prototype.onEditDescriptionDialogAction = function () {
        this.onGridSave(this.selectedRecord);
    };
    SalaryTemplateComponent.prototype.onGridAdd = function (record) {
        record.GridId = 0 - (this.gridData.length + 1);
        record.Id = null;
        record.Units = null;
        record.AmountPerUnit = null;
        record.Amount = null;
        record.BaseAmount = null;
        record.AllowEditUnitType = true;
        record.AmountPerUnitEnabled = true;
        record.EmploymentTemplateId = this.templateId;
        record.IsActive = true;
        record.SalaryTypeId = this.salaryTypes[0].SalaryTypeId;
        record.PersistenceTypeId = this.persistanceTypes[0].Id;
        this.selectedRecord = record;
        var salaryRecordItemHelper = new SalaryRecordItemHelper(this.selectedRecord, this.renderer, this.persistanceTypes, this.salaryTypes);
        salaryRecordItemHelper.updateRelavantFields({ SalaryTypeId: this.selectedRecord.SalaryTypeId }, false);
        this.updateView(this.selectedRecord);
        this.updatedSalaryTemplate = this.gridData;
        this.setComponentDirty();
    };
    SalaryTemplateComponent.prototype.onGridRemove = function (args) {
        if (this.customDeleteRow) {
            var dataItem = args ? args.dataItem : args;
            if (dataItem && dataItem.Id) {
                this.gridRemoveRowArgs = args;
                this.confirmationDialogVisible = true;
            }
            else if (dataItem) {
                var dataIndex = this.gridData.indexOf(dataItem);
                this.gridData.splice(dataIndex, 1);
                this.updatedSalaryTemplate = this.gridData;
                this.setComponentDirty();
            }
        }
        else {
            this.updatedSalaryTemplate = this.gridData;
            this.setComponentDirty();
        }
    };
    SalaryTemplateComponent.prototype.onConfirmationDialogAction = function (action) {
        if (action === 'Delete' && this.gridRemoveRowArgs) {
            var indexOfRemovedItem = this.gridData.indexOf(this.gridRemoveRowArgs.dataItem);
            this.gridData.splice(indexOfRemovedItem, 1);
            this.gridRemoveRowArgs.sender.closeRow(this.gridRemoveRowArgs.rowIndex);
            this.updatedSalaryTemplate = this.gridData;
            this.setComponentDirty();
        }
        this.gridRemoveRowArgs = undefined;
    };
    SalaryTemplateComponent.prototype.onGridSave = function (args) {
        var dataItem = args.dataItem, column = args.column, formGroup = args.formGroup;
        this.selectedRecord = dataItem ? dataItem : args;
        var modifiedField = {};
        if (column) {
            modifiedField[column.field] = dataItem[column.field];
        }
        var salaryRecordItemHelper = new SalaryRecordItemHelper(this.selectedRecord, this.renderer, this.persistanceTypes, this.salaryTypes);
        var valid = salaryRecordItemHelper.validate(modifiedField);
        if (!valid) {
            if (column && column.field && formGroup && formGroup.value) {
                // Revert invalid values.
                dataItem[column.field] = formGroup.value[column.field];
            }
            return;
        }
        salaryRecordItemHelper.updateRelavantFields(modifiedField, false);
        this.updateView(this.selectedRecord);
        this.updatedSalaryTemplate = this.gridData;
        this.setComponentDirty();
    };
    SalaryTemplateComponent.prototype.updateView = function (view) {
        var salaryType = this.getSalaryType(view.SalaryTypeId);
        if (salaryType) {
            view.AllowEditUnitType = salaryType.AllowEditUnitType;
            view.UnitTypeClass = view.AllowEditUnitType ? '' : 'grid-disable-cell';
        }
        view.UnitsEnabled = !!view.UnitTypeId;
        view.UnitsClass = view.UnitsEnabled ? '' : 'grid-disable-cell';
        view.AmountPerUnitEnabled = !!view.UnitTypeId && view.UnitTypeId !== UnitTypeEnum.Percent;
        view.AmountPerUnitClass = view.AmountPerUnitEnabled ? '' : 'grid-disable-cell';
    };
    SalaryTemplateComponent.prototype.getSalaryType = function (salaryTypeId) {
        if (!salaryTypeId || !this.salaryTypes || this.salaryTypes.length === 0) {
            return undefined;
        }
        return this.salaryTypes.find(function (e) { return e.SalaryTypeId === salaryTypeId; });
    };
    SalaryTemplateComponent.prototype.setComponentDirty = function () {
        this.dirty = true;
        this.onChange();
        this.salaryTemplateChange.emit();
    };
    SalaryTemplateComponent.prototype.onKeyDown = function (event) {
        if (this.visible) {
            if (this.editMode) {
                if (event.keyCode in this.keyPress) {
                    this.keyPress[event.keyCode] = true;
                    if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
                        var defaultDataItem = {};
                        this.onGridAdd(defaultDataItem);
                        this.setKeypress();
                        this.gridData.push(defaultDataItem);
                        this.refreshData = true;
                    }
                    else if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
                        if (this.selectedRecord) {
                            this.onGridRemove(this.selectedRecord);
                            this.setKeypress();
                            this.refreshData = true;
                            this.selectedRecord = undefined;
                        }
                    }
                }
                else {
                    this.setKeypress();
                }
            }
        }
    };
    SalaryTemplateComponent.prototype.setKeypress = function () {
        this.keyPress[17] = false;
        this.keyPress[18] = false;
        this.keyPress[107] = false;
        this.keyPress[109] = false;
    };
    return SalaryTemplateComponent;
}(FormComponentBase));
export { SalaryTemplateComponent };
