import { ICompanyUser, IUserEmployment } from '../../../Services/ApiModel';

export class EmployeeList {
  private employees: ICompanyUser[];

  constructor(employeesSource: ICompanyUser[]) {
    this.employees = employeesSource;
  }

  public search(keyword: string, departmentId: number, sortBy: string): any {
    const employees: Array<{ Id: number; Initial: string; Name: string; IsActive: boolean }> = [];
    let index = 0;
    for (let i = 0; i < this.employees.length; i++) {
      const employeeSource: ICompanyUser = this.employees[i];
      if (this.filterEmployee(employeeSource, keyword, departmentId)) {
        employees[index] = this.createGridDataItem(employeeSource, sortBy);
        index++;
      }
    }

    if (employees) {
      employees.forEach((employee: any) => {
        if (!employee.IsActive) {
          employee.Name = '(' + employee.Name + ')';
        }
      });
    }

    // let result: any = employees.sort(this.compareByName);

    return employees;
  }

  private compareByName(object1: any, object2: any): number {
    if (!object1 || !object1.Name) {
      return -1;
    } else if (!object2 || !object2.Name) {
      return 1;
    }

    return object1.Name.toLowerCase().localeCompare(object2.Name.toLowerCase());
  }

  private filterEmployee(employeeSource: ICompanyUser, keyword: string, departmentId: number): boolean {
    let matchEmployment = false;
    if (employeeSource.UserEmployments && employeeSource.UserEmployments.length > 0) {
      employeeSource.UserEmployments.forEach((employment: IUserEmployment) => {
        if (
          !matchEmployment &&
          employment &&
          employment.ExternalReference &&
          employment.ExternalReference.toLowerCase().indexOf(keyword.toLowerCase()) > -1
        ) {
          matchEmployment = true;
        }
        if (!matchEmployment && employment && employment.Id && employment.Id.toString().indexOf(keyword) > -1) {
          matchEmployment = true;
          if (employment.Id.toString() === keyword) {
            (employeeSource as any).StayEmploymentId = employment.Id;
          }
        }
      });
    }

    return (
      (employeeSource.FullName.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
        (employeeSource.CompanyEmail &&
          employeeSource.CompanyEmail.toLowerCase().indexOf(keyword.toLowerCase()) > -1) ||
        (employeeSource.PersonalEmail &&
          employeeSource.PersonalEmail.toLowerCase().indexOf(keyword.toLowerCase()) > -1) ||
        (employeeSource.UserIdentityNumber &&
          employeeSource.UserIdentityNumber.toLowerCase().indexOf(keyword.toLowerCase()) > -1) ||
        this.cprFilter(employeeSource.UserIdentityNumber)
          .toLowerCase()
          .indexOf(keyword.toLowerCase()) > -1 ||
        matchEmployment) &&
      (departmentId === -1 ||
        (employeeSource.UserEmployments !== undefined &&
          employeeSource.UserEmployments.length > 0 &&
          this.findDepartmentId(employeeSource, departmentId)))
    );
  }

  private findDepartmentId(employeeSource: ICompanyUser, departmentId: number): boolean {
    let finded = false;
    employeeSource.UserEmployments.forEach((employment: IUserEmployment) => {
      if (employment.DepartmentId === departmentId) {
        finded = true;
      }
    });

    if (finded) {
      return true;
    }
    return false;
  }

  private cprFilter(value: string): string {
    if (!value) {
      return '';
    }

    if (value.length < 5) {
      return value;
    }

    const firstPart: string = value.substr(0, 6);
    const secondPart: string = value.substring(6, value.length);
    return firstPart + '-' + secondPart;
  }

  private createGridDataItem(employeeSource: ICompanyUser, sortBy: string = '0'): any {
    const name: string = this.getEmployeeName(sortBy, employeeSource);
    return {
      Id: employeeSource.Id,
      Initial: employeeSource.FullName.substr(0, 1),
      Name: name,
      IsActive: employeeSource.IsActive,
      StayEmploymentId: (employeeSource as any).StayEmploymentId ? (employeeSource as any).StayEmploymentId : ''
    };
  }

  private getEmployeeName(sortBy: string, employee: ICompanyUser): string {
    let name: string;
    if (!employee.FirstName || !employee.LastName) {
      return employee.FullName.trim();
    }

    switch (sortBy) {
      case '1':
        name = employee.FirstName.trim() + ' ' + employee.LastName.trim();
        break;
      case '2':
        name = employee.LastName.trim() + ' ' + employee.FirstName.trim();
        break;
      default:
        name = employee.FullName.trim();
        break;
    }

    return name;
  }
}
