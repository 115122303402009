import { AfterViewInit, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { ImportTypesEnum, SalaryBatchStatusEnum } from '../../Model/Enum';
import { SalaryBatch, SalaryPeriod } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { BusyIndicatorService, LoadingIndicatorTextType } from '../../Services/SharedServices/BusyIndicatorService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { ReportParametersDefaultValue } from '../../SharedComponents/ReportsDialog/ReportParametersDefaultValue';
import { CompanySalaryBatchesEntityContext } from './CompanySalaryBatchesEntityContext';
import { SalaryBatchViewModel } from './SalaryBatchViewModel';
var CompanySalaryBatchesComponent = /** @class */ (function () {
    function CompanySalaryBatchesComponent(busyIndicatorService, settingService, dataService, staticDataService, sessionService, renderer) {
        var _this = this;
        this.busyIndicatorService = busyIndicatorService;
        this.settingService = settingService;
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.renderer = renderer;
        this.entityContext = new CompanySalaryBatchesEntityContext();
        this.errorListDetailsDialogVisible = false;
        this.lockSalaryBatchDialogVisible = false;
        this.salaryBatchFinalizationStep1Completed = false;
        this.salaryBatchFinalizationStep2Completed = false;
        this.salaryBatchFinalizationStep3Completed = false;
        this.salaryBatchFinalizationStep3RecalculationConfirm = false;
        this.salaryBatchFinalizationCloseAll = false;
        this.showRevertSalaryBatchDialog = false;
        this.session = Global.SESSION;
        this.salaryStatements = [];
        this.reportDialogVisible = false;
        this.alertDialogVisible = false;
        this.salaryBatchDialogVisible = false;
        this.confirmPasswordDialogVisible = false;
        this.confirmDeleteDialogVisible = false;
        this.confirmRevertLockDialogVisible = false;
        this.confirmRejectDialogVisible = false;
        this.resendBatchExtCompletedDialogVisible = false;
        this.resendBatchToEIncomeDialogVisible = false;
        this.emptySalaryBatchDialogVisible = false;
        this.isEmptyEmployeeFromCycle = true;
        this.noEmployeeActiveVisible = false;
        //public get autoRecalculate(): boolean { return this.entityContext.AutoRecalDraftPreference.Value === "true"; }
        this.tooltipFinalizeContent = '';
        this.menuToggle = false;
        this.ngUnsubscribe = new Subject();
        this.spMouseDown = function (e) {
            _this.splitter.removeEventListener('mousedown', _this.spMouseDown);
            window.addEventListener('mousemove', _this.spMouseMove);
            window.addEventListener('mouseup', _this.spMouseUp);
            _this.lastX = e.clientX;
        };
        this.spMouseUp = function (e) {
            window.removeEventListener('mousemove', _this.spMouseMove);
            window.removeEventListener('mouseup', _this.spMouseUp);
            _this.splitter.addEventListener('mousedown', _this.spMouseDown);
            _this.resetPosition(_this.lastX);
        };
        this.spMouseMove = function (e) {
            _this.resetPosition(e.clientX);
        };
        this.spMouseDownY = function (e) {
            _this.splitterY.removeEventListener('mousedown', _this.spMouseDownY);
            window.addEventListener('mousemove', _this.spMouseMoveY);
            window.addEventListener('mouseup', _this.spMouseUpY);
            _this.lastY = e.clientY;
        };
        this.spMouseUpY = function (e) {
            window.removeEventListener('mousemove', _this.spMouseMoveY);
            window.removeEventListener('mouseup', _this.spMouseUpY);
            _this.splitterY.addEventListener('mousedown', _this.spMouseDownY);
            _this.resetPositionY(_this.lastY);
        };
        this.spMouseMoveY = function (e) {
            _this.resetPositionY(e.clientY);
        };
        ////this.salaryBatchEntityContext = {} as any;
    }
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "IsMobile", {
        get: function () {
            return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryBatchesComponent.prototype.tooltipFinalizeButton = function () {
        if (this.sessionService.role.IsReadOnly) {
            return 'CompanySalaryBatches.DenyFinalize_Text';
        }
        else {
            if (!this.session.IsPaymentApprover) {
                return 'CompanySalaryBatches.DenyFinalize_AdminText';
            }
        }
        return '';
    };
    CompanySalaryBatchesComponent.prototype.onsalaryBatchFinalizationStep2Completed = function () {
        this.salaryBatchFinalizationStep2Completed = true;
    };
    CompanySalaryBatchesComponent.prototype.onsalaryBatchFinalizationStep3Completed = function () {
        this.salaryBatchFinalizationStep3Completed = true;
    };
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "selectedComboboxSalaryBatchId", {
        get: function () {
            return this.selectedComboboxSalaryBatchIdValue;
        },
        set: function (value) {
            if (this.selectedComboboxSalaryBatchIdValue !== value) {
                this.selectedComboboxSalaryBatchIdValue = value;
                var selectedBatch = value
                    ? this.entityContext.SalaryBatches.find(function (item) { return item.Id === value; })
                    : undefined;
                var jsonSelectedBatch = JSON.stringify(selectedBatch);
                var jsonEntityContextSelectedBatch = JSON.stringify(this.entityContext.SelectedSalaryBatch);
                if (this.isMobile && jsonSelectedBatch !== jsonEntityContextSelectedBatch) {
                    this.selectedSalaryBatchChanged(selectedBatch);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "salaryCycleId", {
        get: function () {
            return this.salaryCycleIdValue;
        },
        set: function (value) {
            if (this.salaryCycleIdValue !== value) {
                this.salaryCycleIdValue = value;
                this.filtersalaryBatchesDataSource();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryBatchesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subTab = 'Basic';
        this.menuToggle = false;
        this.dataService.Miscellaneous_GetUsedSalaryCycles().subscribe(function (data) {
            _this.entityContext.SalaryCycles = data;
            // if (data && data.length > 0) {
            //     this.isEmptyEmployeeFromCycle = false;
            // }
        });
        this.dataService
            .Employments_GetEmploymentViews()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (userEmployments) {
            if (userEmployments && userEmployments.length > 0) {
                _this.isEmptyEmployeeFromCycle = false;
            }
        });
        this.staticDataService.ExternalSystemOperation.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.IntergrationOpation = data;
            var objectfillter = _this.IntergrationOpation.find(function (model) { return model.Key === 'ExportNewPayrollBatch'; });
            if (objectfillter) {
                _this.exportNewPayrollBatchId = objectfillter.Id;
                if (_this.exportNewPayrollBatchId) {
                    // this.staticDataService.GetIntegration2Operation(
                    //   this.exportNewPayrollBatchId,
                    //   this.Integration2Operation,
                    //   this.integrationName
                    // );
                    _this.GetIntegration2Operation();
                }
            }
        });
        this.initializeComponent();
        this.tooltipFinalizeContent = this.tooltipFinalizeButton();
        if (this.IsActive) {
            this.settingService.showModuleMessage('Company.SalaryBatches');
        }
        this.settingService.showNavigationMessage();
    };
    CompanySalaryBatchesComponent.prototype.ngAfterViewInit = function () {
        // this.unsubscribeResize = this.renderer.listen(window, 'resize', () => {
        //   if (window.innerWidth > 1330) {
        //     this.initSplitterY();
        //     this.initSplitter();
        //   } else {
        //     this.resetSplitter();
        //   }
        // });
        // if (window.innerWidth > 1330) {
        //   setTimeout(() => {
        //     this.initSplitterY();
        //     this.initSplitter();
        //   }, 2000);
        // }
    };
    CompanySalaryBatchesComponent.prototype.ngOnDestroy = function () {
        // this.ngUnsubscribe.next();
        // this.ngUnsubscribe.complete();
        this.ngUnsubscribe.unsubscribe();
    };
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryBatchesComponent.prototype.onMoreMenuOptionClick = function (action) {
        switch (action) {
            case 'salaryBatches_recalculateDraft':
                this.onRecalculate();
                break;
            case 'salaryBatches_importData':
                this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Payroll;
                this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
                break;
        }
    };
    CompanySalaryBatchesComponent.prototype.showErrorModal = function (data) {
        this.errorListDetailsDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.selectedSalaryBatchChanged = function (data) {
        var previous = this.entityContext.SelectedSalaryBatch;
        this.entityContext.SelectedSalaryBatch = undefined;
        this.entityContext.SelectedSalaryBatch = data;
        this.basicSalaryBatch = data ? JSON.parse(JSON.stringify(data)) : undefined;
        this.childWindowSalaryBatch = data ? JSON.parse(JSON.stringify(data)) : undefined;
        this.preDataLoad = data ? JSON.parse(JSON.stringify(data)) : undefined;
        this.getPayslips(data ? data.Id : undefined);
        this.selectDefaultBasicTab();
    };
    CompanySalaryBatchesComponent.prototype.reloadcaculate = function (model) {
        var _this = this;
        if (model && model.Id) {
            this.dataService.SalaryBatches_GetSalaryBatchTotalsBySalaryType(model.Id).subscribe(function (data) {
                _this.salaryTypeTotals = [];
                _this.salaryTypeTotals = data;
            });
            this.dataService.SalaryBatches_GetSalaryBatchUnitTotals(model.Id).subscribe(function (data) {
                _this.unitTotals = [];
                _this.unitTotals = data;
            });
            this.dataService
                .SalaryStatements_GetSalaryStatementsByBatchSimplified(model.Id)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(function (data) {
                _this.salaryStatements = [];
                _this.salaryStatements = data;
            });
        }
    };
    CompanySalaryBatchesComponent.prototype.onRecalculate = function () {
        var _this = this;
        if (this.entityContext.SelectedSalaryBatch) {
            this.busyIndicatorService.setLoadingIndicatorVisibility(true, LoadingIndicatorTextType.RECALCULATE);
            this.dataService.SalaryBatches_RecalculateSalaryBatch(this.entityContext.SelectedSalaryBatch.Id).subscribe(function () {
                _this.getSalaryBatches();
                _this.closeDialog();
                _this.busyIndicatorService.setLoadingIndicatorVisibility(false);
                _this.reloadcaculate(_this.preDataLoad);
            }, function () {
                _this.busyIndicatorService.setLoadingIndicatorVisibility(false);
            }, function () {
                _this.busyIndicatorService.setLoadingIndicatorVisibility(false);
            });
        }
    };
    CompanySalaryBatchesComponent.prototype.prepareReportDialogParams = function () {
        if (this.entityContext.SelectedSalaryBatch) {
            this.reportParams = new ReportParametersDefaultValue();
            this.reportParams.salaryBatchId = this.entityContext.SelectedSalaryBatch.Id;
            this.reportDialogVisible = true;
        }
        else {
            this.alertDialogType = 'noBatchSelectedWarning';
            this.alertDialogVisible = true;
        }
    };
    CompanySalaryBatchesComponent.prototype.showNewSalaryBatchDialog = function () {
        if (this.isEmptyEmployeeFromCycle) {
            this.noEmployeeActiveVisible = true;
            return;
        }
        this.prechildWindowSalaryBatch = this.childWindowSalaryBatch;
        this.childWindowSalaryBatch = undefined;
        this.salaryBatchDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.onEditSalaryBatch = function (batch) {
        if (!batch) {
            return;
        }
        this.basicSalaryBatch = JSON.parse(JSON.stringify(batch));
        this.childWindowSalaryBatch = JSON.parse(JSON.stringify(batch));
        this.salaryBatchDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.onSalaryBatchDialogAction = function (event) {
        this.salaryBatchEntityContext = event.context;
        switch (event.action) {
            case 'Create':
            case 'Save':
                this.onDone();
                break;
            case 'Delete':
                this.onDelete();
                break;
            case 'SubmitForApproval':
                this.onLockSalaryBatch();
                break;
            case 'Reject':
                this.onRejectSalaryBatch();
                break;
            case 'RevertToDraft':
                this.onLockSalaryBatch();
                break;
            case 'Finalize':
                this.onFinalize();
                break;
            case 'Resend':
                this.resendBatch(this.salaryBatchEntityContext.BatchId);
                break;
            case 'Revert':
                this.showRevertSalaryBatchDialog = true;
                break;
            // case "Close":
            //     if (this.IsReadOnly || !this.salaryBatchEntityContext.IsDraft) {
            //         this.closeDialog();
            //     }
            //     break;
            default:
                this.onDone(true);
        }
    };
    CompanySalaryBatchesComponent.prototype.onConfirmDeleteDialogAction = function (action) {
        if (action === 'Delete') {
            this.deleteSalaryBatch(this.salaryBatchEntityContext.BatchId);
        }
    };
    CompanySalaryBatchesComponent.prototype.onConfirmRevertLockDialogAction = function (action) {
        var _this = this;
        if (action === 'Ok') {
            this.dataService
                .SalaryBatches_ResetBatchToDraft(this.salaryBatchEntityContext.BatchId)
                .subscribe(function () { return _this.reloadSalaryBatches(); });
        }
    };
    CompanySalaryBatchesComponent.prototype.reloadSalaryBatches = function () {
        this.closeDialog();
        this.getSalaryBatches();
    };
    CompanySalaryBatchesComponent.prototype.onChooseResendTargetDialogAction = function (action) {
        if (action === 'eincome') {
            this.resendBatchToEIncome(this.resendBatchId);
        }
        else if (action === 'external') {
            this.apiResendToIntegration(this.resendBatchId);
        }
    };
    CompanySalaryBatchesComponent.prototype.resendBatch = function (batchId) {
        if (this.IsGreenlandCompany) {
            if (this.integrationName) {
                this.externalSystemName = this.integrationName;
                this.resendBatchToIntegrationConfirmation(batchId);
            }
        }
        else {
            if (this.integrationName) {
                this.resendBatchId = batchId;
                this.externalSystemName = this.integrationName;
                this.chooseResendTargetDialogVisible = true;
            }
            else {
                this.resendBatchToEIncome(batchId);
            }
        }
    };
    CompanySalaryBatchesComponent.prototype.resendBatchToEIncome = function (batchId) {
        this.resendBatchToEIncomeId = batchId;
        this.resendBatchToEIncomeDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.onResendBatchToEIncomeDialogAction = function (action) {
        if (action === 'Resend') {
            this.apiResendToEIncome(this.resendBatchToEIncomeId);
        }
    };
    CompanySalaryBatchesComponent.prototype.resendBatchToIntegrationConfirmation = function (batchId) {
        this.resendBatchToIntegrationId = batchId;
        this.resendBatchToIntegrationConfirmationDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.onResendBatchToIntegrationDialogAction = function (action) {
        if (action === 'Resend') {
            this.apiResendToIntegration(this.resendBatchToIntegrationId);
        }
    };
    CompanySalaryBatchesComponent.prototype.closeFinalizationWizardDialogs = function () {
        this.salaryBatchFinalizationCloseAll = true;
        this.closeDialog();
        this.confirmPasswordDialogVisible = false;
    };
    CompanySalaryBatchesComponent.prototype.onFinalizationProcessDone = function () {
        this.closeFinalizationWizardDialogs();
        this.getSalaryBatches();
    };
    CompanySalaryBatchesComponent.prototype.onConfirmPasswordDialogAction = function (action) {
        switch (action) {
            case 'Cancel':
                this.closeFinalizationWizardDialogs();
                break;
            case 'AcceptWarnings':
                this.salaryBatchFinalizationStep1Completed = true;
                break;
            default:
                this.closeDialog();
        }
    };
    CompanySalaryBatchesComponent.prototype.onEmptySalaryBatchDialogAction = function (action) {
        if (action === 'Yes') {
            this.emptySalaryBatchShown = true;
            this.showNewSalaryBatchDialog();
        }
    };
    CompanySalaryBatchesComponent.prototype.getSalaryBatches = function (salaryBatchId) {
        var _this = this;
        this.busyIndicatorService.setLoadingIndicatorVisibility(true, LoadingIndicatorTextType.RECALCULATE);
        this.dataService.SalaryBatches_GetSalaryBatches().subscribe(function (salaryBatches) {
            _this.entityContext.SalaryBatches =
                salaryBatches.length > 0
                    ? salaryBatches.map(function (s) { return new SalaryBatchViewModel(s, _this.sessionService); })
                    : [];
            _this.filtersalaryBatchesDataSource(salaryBatchId);
            _this.basicSalaryBatch = _this.entityContext.SelectedSalaryBatch
                ? JSON.parse(JSON.stringify(_this.entityContext.SelectedSalaryBatch))
                : undefined;
            _this.emptySalaryBatchShown = salaryBatches.length > 0 ? true : _this.emptySalaryBatchShown;
            if (_this.sessionService.role.IsSalaryAdminAndHigher &&
                salaryBatches.length <= 0 &&
                !_this.emptySalaryBatchShown) {
                _this.emptySalaryBatchDialogVisible = true;
            }
            _this.busyIndicatorService.setLoadingIndicatorVisibility(false);
        }, function () {
            _this.busyIndicatorService.setLoadingIndicatorVisibility(false);
        });
    };
    CompanySalaryBatchesComponent.prototype.setDefaultSalaryBatchForMobileDevice = function () {
        var _this = this;
        if (this.IsMobile) {
            if (this.entityContext.SalaryBatches && this.entityContext.SalaryBatches.length > 0) {
                var hasItemSelected = this.entityContext.SalaryBatches.some(function (b) { return b.Id === _this.selectedComboboxSalaryBatchId; });
                if (!hasItemSelected) {
                    this.selectedComboboxSalaryBatchId = this.entityContext.SalaryBatches[0].Id;
                }
            }
            else {
                this.entityContext.SelectedSalaryBatch = undefined;
                this.selectedComboboxSalaryBatchId = undefined;
            }
        }
    };
    CompanySalaryBatchesComponent.prototype.closeDialog = function () {
        this.salaryBatchDialogVisible = false;
        this.salaryBatchFinalizationStep1Completed = false;
        this.salaryBatchFinalizationStep2Completed = false;
        this.salaryBatchFinalizationStep3Completed = false;
        if (!this.childWindowSalaryBatch) {
            this.basicSalaryBatch = this.prechildWindowSalaryBatch
                ? JSON.parse(JSON.stringify(this.prechildWindowSalaryBatch))
                : undefined;
            this.childWindowSalaryBatch = this.prechildWindowSalaryBatch
                ? JSON.parse(JSON.stringify(this.prechildWindowSalaryBatch))
                : undefined;
        }
    };
    // Using static dataService observer to prevent Integrations_GetIntegrationsByOperation run twice
    CompanySalaryBatchesComponent.prototype.GetIntegration2Operation = function () {
        var _this = this;
        this.dataService
            .Integrations_GetIntegrationsByOperation(this.exportNewPayrollBatchId)
            .subscribe(function (model) {
            _this.Integration2Operation = model;
            if (_this.Integration2Operation && _this.Integration2Operation.length > 0) {
                _this.integrationName = _this.Integration2Operation[0].Name;
            }
        });
    };
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "IsActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.salarybatches';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "hasManyOpenDrafts", {
        get: function () {
            return (this.entityContext.SalaryBatches &&
                this.entityContext.SalaryBatches.filter(function (batch) { return batch.StatusId === SalaryBatchStatusEnum.Draft; }).length >= 3);
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryBatchesComponent.prototype.initializeComponent = function () {
        this.entityContext.Company = Global.COMPANY;
        // if (Global.USER_PREFERENCES) {
        //     this.entityContext.AutoRecalDraftPreference = Global.USER_PREFERENCES.find((p: ISimpleKeyValuePair) => p.Key === "SalaryBatches.AutoRecalcDrafts");
        // }
        // if (!this.entityContext.AutoRecalDraftPreference) {
        //     this.entityContext.AutoRecalDraftPreference = {
        //         Key: "SalaryBatches.AutoRecalcDrafts",
        //         Value: "true"
        //     };
        // }
        if (!this.IsReadOnly /*&& this.entityContext.IsRecalculateDraft*/) {
            this.getSalaryBatches();
            // this.recalculateDrafts();
        }
        else {
            this.getSalaryBatches();
        }
    };
    CompanySalaryBatchesComponent.prototype.onShowConfirmPasswordWindow = function () {
        this.salaryBatchDialogVisible = false;
        this.salaryBatchEntityContext.Password = '';
        this.confirmPasswordDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.onRevertBatchCompleted = function () {
        this.getSalaryBatches();
        this.closeDialog();
    };
    CompanySalaryBatchesComponent.prototype.onFinalize = function () {
        var _this = this;
        if (this.entityContext.SelectedSalaryBatch) {
            var payoutDate = new Date(this.entityContext.SelectedSalaryBatch.PayoutDate);
            payoutDate = this.staticDataService.getCurrentdate(undefined, payoutDate);
            if ((this.salaryBatchEntityContext.Message !== this.entityContext.SelectedSalaryBatch.Message ||
                this.salaryBatchEntityContext.PaymentDate !== payoutDate) &&
                this.entityContext.SelectedSalaryBatch.StatusId <= 10 &&
                Global.SESSION.CurrentRole.Id >= 60) {
                this.saveSalaryBatchChanges(true).then(function (salaryRecord) {
                    if (_this.salaryBatchEntityContext && salaryRecord) {
                        _this.salaryBatchEntityContext.HasErrors = salaryRecord.HasErrors;
                        _this.salaryBatchEntityContext.HasWarnings = salaryRecord.HasWarnings;
                        _this.beginFinalization();
                    }
                });
            }
            else {
                this.beginFinalization();
            }
        }
        else {
            this.alertDialogType = 'noBatchSelectedWarning';
            this.alertDialogVisible = true;
        }
    };
    CompanySalaryBatchesComponent.prototype.beginFinalization = function () {
        if (Global.SESSION && !Global.SESSION.IsPaymentApprover) {
            if (Global.SESSION.CurrentRole.Id === 90) {
                // Administrator (FullAdmin)
                this.alertDialogType = 'denyFinalizeAdminText';
                this.alertDialogVisible = true;
            }
            else {
                this.alertDialogType = 'denyFinalizeText';
                this.alertDialogVisible = true;
            }
            return;
        }
        if (this.salaryBatchEntityContext.HasErrors) {
            this.alertDialogType = 'finalizeErrorText';
            this.alertDialogVisible = true;
            return;
        }
        if (!this.salaryBatchEntityContext.HasWarnings) {
            this.salaryBatchFinalizationStep1Completed = true;
            return;
        }
        this.onShowConfirmPasswordWindow();
    };
    CompanySalaryBatchesComponent.prototype.afterFinalizeError = function () {
        if (this.alertDialogType && this.alertDialogType === 'finalizeErrorText') {
            this.closeDialog();
            this.errorListDetailsDialogVisible = true;
        }
    };
    CompanySalaryBatchesComponent.prototype.onDelete = function () {
        this.confirmDeleteDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.onDone = function (forceClose) {
        if (forceClose === void 0) { forceClose = false; }
        if (this.IsReadOnly || forceClose || !this.salaryBatchEntityContext.IsDraft) {
            this.closeDialog();
            return;
        }
        this.saveSalaryBatchChanges();
    };
    CompanySalaryBatchesComponent.prototype.saveSalaryBatchChanges = function (isFinalized) {
        var _this = this;
        if (isFinalized === void 0) { isFinalized = false; }
        return new Promise(function (resolve, reject) {
            if (!_this.salaryBatchEntityContext.PaymentDate) {
                _this.alertDialogType = 'invalidDateMessage';
                _this.alertDialogVisible = true;
                return resolve(undefined);
            }
            var payoutDate = _this.staticDataService.getCurrentdate(undefined, _this.salaryBatchEntityContext.PaymentDate);
            if (_this.salaryBatchEntityContext.BatchId < 1) {
                var salaryBatchRequest = {
                    PayoutDate: payoutDate,
                    Preview: false,
                    SalaryCycleId: _this.salaryBatchEntityContext.CycleId,
                    SalaryPeriodId: _this.salaryBatchEntityContext.PeriodId,
                    Message: _this.salaryBatchEntityContext.Message === undefined ? undefined : _this.salaryBatchEntityContext.Message,
                    UserEmploymentIds: _this.modalSelectedEmployeeIds,
                    EIncomeZeroReport: _this.salaryBatchEntityContext.EmployeeCategory === 3,
                    PayoutAllFlex: _this.salaryBatchEntityContext.PayoutAllFlex
                };
                return _this.createSalaryBatch(salaryBatchRequest).then(function (salaryBatch) { return resolve(salaryBatch); });
            }
            else {
                var selectedSalaryBatch = _this.entityContext.SelectedSalaryBatch;
                var salaryPeriod = new SalaryPeriod();
                salaryPeriod.Id = _this.salaryBatchEntityContext.PeriodId;
                salaryPeriod.StartDate = null;
                salaryPeriod.EndDate = null;
                salaryPeriod.SuggestedPayoutDate = selectedSalaryBatch.PayoutDate || null;
                salaryPeriod.FriendlyName = null;
                salaryPeriod.SalaryCycleId = _this.salaryBatchEntityContext.CycleId || null;
                if (selectedSalaryBatch && selectedSalaryBatch.PeriodStartDate) {
                    salaryPeriod.StartDate = _this.staticDataService.getCurrentdate(false, selectedSalaryBatch.PeriodStartDate);
                }
                if (selectedSalaryBatch && selectedSalaryBatch.PeriodEndDate) {
                    salaryPeriod.EndDate = _this.staticDataService.getCurrentdate(selectedSalaryBatch.PeriodEndDate);
                }
                selectedSalaryBatch.SalaryPeriod = salaryPeriod;
                selectedSalaryBatch.PayoutAllFlex = _this.salaryBatchEntityContext.PayoutAllFlex;
                selectedSalaryBatch.PayoutDate = payoutDate;
                selectedSalaryBatch.Message = _this.salaryBatchEntityContext.Message;
                selectedSalaryBatch.EBoksDeliveryEnabled = selectedSalaryBatch.EBoksDeliveryEnabled;
                selectedSalaryBatch.SpecifiedEmployees = selectedSalaryBatch.SpecifiedEmployees;
                selectedSalaryBatch.HasNemKontoPayments = selectedSalaryBatch.HasNemKontoPayments;
                selectedSalaryBatch.PaymentMethodPublicPension = selectedSalaryBatch.PaymentMethodPublicPension;
                selectedSalaryBatch.PaymentMethodVacationProvider = selectedSalaryBatch.PaymentMethodVacationProvider;
                selectedSalaryBatch.ToArchive = selectedSalaryBatch.ToArchive;
                selectedSalaryBatch.Finalized = selectedSalaryBatch.Finalized;
                selectedSalaryBatch.FinalizedCompanyUserId = selectedSalaryBatch.FinalizedCompanyUserId;
                selectedSalaryBatch.AllowPreliminaryPayslips = selectedSalaryBatch.AllowPreliminaryPayslips;
                selectedSalaryBatch.PackageLevelWhenFinalized = selectedSalaryBatch.PackageLevelWhenFinalized;
                selectedSalaryBatch.IsAutomated = selectedSalaryBatch.IsAutomated;
                var selectedSalaryBatchRecord = new SalaryBatch();
                selectedSalaryBatch = _this.staticDataService.checkModelRecord(selectedSalaryBatch);
                Object.assign(selectedSalaryBatchRecord, selectedSalaryBatch);
                return _this.updateSalaryBatch(selectedSalaryBatchRecord, isFinalized).then(function (salaryBatch) {
                    return resolve(salaryBatch);
                });
            }
        });
    };
    CompanySalaryBatchesComponent.prototype.onLockSalaryBatch = function () {
        var _this = this;
        if ((this.salaryBatchEntityContext.Message !== this.entityContext.SelectedSalaryBatch.Message ||
            this.salaryBatchEntityContext.PaymentDate !== this.entityContext.SelectedSalaryBatch.PayoutDate) &&
            this.entityContext.SelectedSalaryBatch.StatusId <= 10 &&
            Global.SESSION.CurrentRole.Id >= 60) {
            this.saveSalaryBatchChanges(true).then(function (salaryRecord) {
                if (_this.salaryBatchEntityContext && salaryRecord) {
                    _this.salaryBatchEntityContext.HasErrors = salaryRecord.HasErrors;
                    _this.salaryBatchEntityContext.HasWarnings = salaryRecord.HasWarnings;
                    if (_this.salaryBatchEntityContext.IsDraft) {
                        _this.lockSalaryBatchDialogVisible = true;
                    }
                    else if (_this.salaryBatchEntityContext.IsLocked || _this.salaryBatchEntityContext.IsPreliminary) {
                        _this.confirmRevertLockDialogVisible = true;
                    }
                }
            });
        }
        else {
            if (this.salaryBatchEntityContext.IsDraft) {
                this.lockSalaryBatchDialogVisible = true;
            }
            else if (this.salaryBatchEntityContext.IsLocked || this.salaryBatchEntityContext.IsPreliminary) {
                this.confirmRevertLockDialogVisible = true;
            }
        }
    };
    CompanySalaryBatchesComponent.prototype.onRejectSalaryBatch = function () {
        this.confirmRejectDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.recalculateDrafts = function () {
        this.getSalaryBatches();
    };
    CompanySalaryBatchesComponent.prototype.apiResendToEIncome = function (batchId) {
        var _this = this;
        this.dataService.SalaryBatches_ResendEIncome(batchId).subscribe(function () {
            _this.alertDialogType = 'resendBatchCompletedMessage';
            _this.alertDialogVisible = true;
            _this.getSalaryBatches();
            _this.closeDialog();
        });
    };
    CompanySalaryBatchesComponent.prototype.apiResendToIntegration = function (batchId) {
        var _this = this;
        this.dataService.SalaryBatches_ResendToDefaultIntegration(batchId).subscribe(function () {
            _this.resendBatchExtCompletedDialogVisible = true;
            _this.getSalaryBatches();
            _this.closeDialog();
        });
    };
    CompanySalaryBatchesComponent.prototype.createSalaryBatch = function (salaryBatch) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.dataService.SalaryBatches_CreateSalaryBatch(salaryBatch).subscribe(function (salaryRecord) {
                _this.getSalaryBatches(salaryRecord.Id);
                _this.closeDialog();
                return resolve(salaryRecord);
            }, function () {
                _this.closeDialog();
                return resolve(undefined);
            });
        });
    };
    CompanySalaryBatchesComponent.prototype.updateSalaryBatch = function (salaryBatch, isFinalize) {
        var _this = this;
        if (isFinalize === void 0) { isFinalize = false; }
        return new Promise(function (resolve, reject) {
            _this.dataService.SalaryBatches_UpdateSalaryBatch(salaryBatch).subscribe(function (salaryRecord) {
                _this.getSalaryBatches();
                if (!isFinalize) {
                    _this.closeDialog();
                }
                return resolve(salaryRecord);
            }, function () {
                return resolve(salaryBatch);
            });
        });
    };
    CompanySalaryBatchesComponent.prototype.deleteSalaryBatch = function (batchId) {
        var _this = this;
        this.dataService.SalaryBatches_DeleteSalaryBatch(batchId).subscribe(function () {
            _this.getSalaryBatches();
            _this.closeDialog();
        });
    };
    CompanySalaryBatchesComponent.prototype.getPayslips = function (batchId) {
        var _this = this;
        this.salaryStatements = [];
        if (batchId) {
            this.dataService
                .SalaryStatements_GetSalaryStatementsByBatchSimplified(batchId)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(function (data) {
                _this.salaryStatements = data;
            });
        }
    };
    CompanySalaryBatchesComponent.prototype.filtersalaryBatchesDataSource = function (salaryBatchId) {
        var _this = this;
        if (this.salaryCycleId) {
            this.salaryBatchesDataSource = this.entityContext.SalaryBatches.filter(function (s) { return s.SalaryCycleId === _this.salaryCycleId; });
        }
        else {
            this.salaryBatchesDataSource = this.entityContext.SalaryBatches;
        }
        if (this.salaryBatchesDataSource && this.salaryBatchesDataSource.length > 0) {
            salaryBatchId = salaryBatchId
                ? salaryBatchId
                : this.entityContext.SelectedSalaryBatch
                    ? this.entityContext.SelectedSalaryBatch.Id
                    : undefined;
            var selectedItem = salaryBatchId
                ? this.salaryBatchesDataSource.find(function (d) { return d.Id === salaryBatchId; })
                : undefined;
            if (selectedItem) {
                this.selectedComboboxSalaryBatchId = selectedItem.Id;
                this.entityContext.SelectedSalaryBatch = selectedItem;
            }
            else {
                this.selectedComboboxSalaryBatchId = this.salaryBatchesDataSource[0].Id;
            }
        }
        else {
            this.selectedComboboxSalaryBatchId = undefined;
        }
    };
    CompanySalaryBatchesComponent.prototype.onSalaryBatchTabSelected = function (event) {
        this.errorTabSelected = event.index === 1; // Error.
    };
    CompanySalaryBatchesComponent.prototype.selectDefaultBasicTab = function () {
        if (this.IsMobile) {
            var salaryBatch = this.entityContext.SelectedSalaryBatch;
            var basicTab = this.errorTabSelected && salaryBatch && !salaryBatch.ValidationIcon;
            if (basicTab && this.salaryBatchTab) {
                this.salaryBatchTab.selectTab(0);
            }
        }
    };
    CompanySalaryBatchesComponent.prototype.initSplitter = function () {
        this.splitter = document.getElementById('splitter');
        this.cont1 = document.getElementById('Card-splitter1');
        this.cont2 = document.getElementById('Card-splitter2');
        var leftside = document.getElementById('Card-Leftside');
        if (!this.splitter || !this.cont1 || !this.cont2) {
            return;
        }
        if (leftside) {
            this.windowWidth = leftside.clientWidth;
        }
        var dx = this.cont1.clientWidth;
        this.splitter.style.marginLeft = dx + 'px';
        dx += this.splitter.clientWidth;
        this.cont2.style.marginLeft = dx + 'px';
        dx = this.windowWidth - dx;
        this.cont2.style.width = dx + 'px';
        this.splitter.addEventListener('mousedown', this.spMouseDown);
        if (this.cont2Y) {
            var cont2YHeight = this.windowHeight - (this.cont1Y.clientHeight + 3 + 110);
            this.splitter.style.height = cont2YHeight + 'px';
            this.cont1.style.height = cont2YHeight + 'px';
            this.cont2.style.height = cont2YHeight + 'px';
        }
    };
    CompanySalaryBatchesComponent.prototype.resetPosition = function (nowX) {
        var dx = nowX - this.lastX;
        dx += this.cont1.clientWidth;
        this.cont1.style.width = dx + 'px';
        this.splitter.style.marginLeft = dx + 'px';
        dx += this.splitter.clientWidth;
        this.cont2.style.marginLeft = dx + 'px';
        dx = this.windowWidth - dx;
        this.cont2.style.width = dx + 'px';
        this.lastX = nowX;
    };
    CompanySalaryBatchesComponent.prototype.initSplitterY = function () {
        this.splitterY = document.getElementById('splitterY');
        this.cont1Y = document.getElementById('Card-splitter1Y');
        this.cont2Y = document.getElementById('Card-splitter2Y');
        this.cont1Y1 = $('#Card-splitter1Y kendo-grid-list')[0];
        this.cont2Y1 = $('#Card-splitter2Y .k-grid-content.k-virtual-content')[0];
        this.cont2Y2 = $('#Card-splitter2Y .k-grid-content.k-virtual-content')[1];
        if (!this.splitterY || !this.cont1Y || !this.cont2Y || !this.cont1Y1 || !this.cont2Y1 || !this.cont2Y2) {
            return;
        }
        var rightside = document.getElementById('Card-Rightside');
        if (rightside) {
            this.windowHeight = rightside.clientHeight;
        }
        else {
            this.windowHeight = window.innerHeight - 160;
        }
        var minHeight1 = $('.Company-payslipsGrid .k-grid-content')[0];
        var minHeight2 = $('.Company-payslipsGrid .k-grid-content')[1];
        if (minHeight1 && minHeight2) {
            minHeight1.style.minHeight = '';
            minHeight2.style.minHeight = '';
        }
        var dx = this.cont1Y.clientHeight + 10;
        this.splitterY.style.marginTop = dx + 'px';
        this.cont1Y1.style.cssText = 'height: ' + (dx - 120) + 'px !important; max-height: 1000px !important';
        dx += this.splitterY.clientHeight;
        this.cont2Y.style.marginTop = dx + 'px';
        dx = this.windowHeight - dx;
        this.cont2Y.style.height = dx + 'px';
        this.cont2Y1.style.cssText = 'height: ' + (dx - 150) + 'px !important';
        this.cont2Y2.style.cssText = 'height: ' + (dx - 150) + 'px !important';
        this.splitterY.addEventListener('mousedown', this.spMouseDownY);
    };
    CompanySalaryBatchesComponent.prototype.resetPositionY = function (nowY) {
        var dx = nowY - this.lastY;
        dx += this.cont1Y.clientHeight;
        this.cont1Y.style.height = dx + 'px';
        this.cont1Y1.style.cssText = 'height: ' + (dx - 120) + 'px !important; max-height: 1000px !important';
        this.splitterY.style.marginTop = dx + 'px';
        dx += this.splitterY.clientHeight;
        this.cont2Y.style.marginTop = dx + 'px';
        dx = this.windowHeight - dx;
        this.cont2Y.style.height = dx + 'px';
        this.cont2Y1.style.cssText = 'height: ' + (dx - 150) + 'px !important';
        this.cont2Y2.style.cssText = 'height: ' + (dx - 150) + 'px !important';
        this.lastY = nowY;
        if (this.cont2Y) {
            var cont2YHeight = this.windowHeight - (this.cont1Y.clientHeight + 3 + 110);
            this.splitter.style.height = cont2YHeight + 'px';
            this.cont1.style.height = cont2YHeight + 'px';
            this.cont2.style.height = cont2YHeight + 'px';
        }
    };
    CompanySalaryBatchesComponent.prototype.resetSplitter = function () {
        if (!this.splitter ||
            !this.splitterY ||
            !this.cont1 ||
            !this.cont2 ||
            !this.cont1Y ||
            !this.cont1Y1 ||
            !this.cont2Y ||
            !this.cont2Y1 ||
            !this.cont2Y2) {
            return;
        }
        // Remove height
        this.splitter.style.height = '';
        this.splitterY.style.height = '';
        this.cont1.style.height = '';
        this.cont2.style.height = '';
        this.cont1Y.style.height = '';
        this.cont1Y1.style.height = '';
        this.cont2Y.style.height = '';
        this.cont2Y1.style.height = '';
        this.cont2Y2.style.height = '';
        // Remove margin
        this.cont2.style.marginLeft = '';
        this.splitter.style.marginLeft = '';
        this.cont2Y.style.marginTop = '';
        this.splitterY.style.marginTop = '';
        var minHeight1 = $('.Company-payslipsGrid .k-grid-content')[0];
        var minHeight2 = $('.Company-payslipsGrid .k-grid-content')[1];
        if (minHeight1 && minHeight2) {
            minHeight1.style.minHeight = '170px';
            minHeight2.style.minHeight = '170px';
        }
    };
    return CompanySalaryBatchesComponent;
}());
export { CompanySalaryBatchesComponent };
