import { Component, EventEmitter, Input, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ISalaryBatchApprovalRequest, ISalaryBatchApprovalResponse } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { BusyIndicatorService, LoadingIndicatorTextType } from '../../../Services/SharedServices/BusyIndicatorService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SalaryBatchViewModel } from '../SalaryBatchViewModel';

@Component({
  selector: 'batch-finalization-step-4-dialogue',
  templateUrl: './BatchFinalizationStep4Dialogue.component.html'
})
export class BatchFinalizationStep4DialogueComponent {
  @Input() public salaryBatch: SalaryBatchViewModel;
  @Input() public doRecalculation: boolean;

  @Input()
  public get step1Completed(): boolean {
    return this.visible;
  }
  public set step1Completed(value: boolean) {
    if (value) {
      if (!this.salaryBatch.EIncomeZeroReport) {
        return;
      }
    }

    this.visible = false;
  }

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      setTimeout(() => this.visibleChange.emit(value));
      if (value) {
        this.password = '';
      }
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public finalizationExit: EventEmitter<void> = new EventEmitter<void>();
  @Output() public finalizationDone: EventEmitter<void> = new EventEmitter<void>();

  public password: string;
  public responseDialogVisible = false;
  public response: string;
  public userName: string;

  constructor(
    private busyIndicatorService: BusyIndicatorService,
    private dataService: RxDataService,
    public sessionService: SessionService
  ) {
    this.userName = localStorage.getItem('saveLoginUserName');
  }

  public onDialogAction(action: string): void {
    if (action === 'Finalize' && this.password) {
      this.approveSalaryBatch(this.salaryBatch.Id, { Password: this.password, DoRecalc: this.doRecalculation });
    } else {
      this.visible = false;
      this.finalizationExit.emit();
    }
  }

  private approveSalaryBatch(batchId: number, request: ISalaryBatchApprovalRequest): void {
    this.busyIndicatorService.setLoadingIndicatorVisibility(true, LoadingIndicatorTextType.FINALIZE);
    this.dataService
      .SalaryBatches_FinalizeSalaryBatch(batchId, request)
      .pipe(
        finalize(() => {
          this.busyIndicatorService.setLoadingIndicatorVisibility(false);
          this.finalizationDone.emit();
        })
      )
      .subscribe((salaryBatchResponse: ISalaryBatchApprovalResponse) => {
        if (salaryBatchResponse.Message) {
          this.response = salaryBatchResponse.Message;
          this.responseDialogVisible = true;
        }
      });
  }
}
