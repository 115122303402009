var CompanySalaryBatchesChildFormEntityContext = /** @class */ (function () {
    function CompanySalaryBatchesChildFormEntityContext(sessionService) {
        this.sessionService = sessionService;
        this.IsDirty = false;
        this.HasBatch = false;
        this.Periods = [];
        this.Employees = [];
    }
    Object.defineProperty(CompanySalaryBatchesChildFormEntityContext.prototype, "ChangeDate", {
        get: function () {
            return this.changeDate;
        },
        set: function (value) {
            this.changeDate = value ? this.sessionService.parseDate(value.toString()) : value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchesChildFormEntityContext.prototype, "PaymentDate", {
        get: function () {
            return this.paymentDate;
        },
        set: function (paymentDate) {
            this.paymentDate = paymentDate;
            this.IsDirty = true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchesChildFormEntityContext.prototype, "Message", {
        get: function () {
            return this.message;
        },
        set: function (message) {
            this.message = message;
            this.IsDirty = true;
        },
        enumerable: true,
        configurable: true
    });
    return CompanySalaryBatchesChildFormEntityContext;
}());
export { CompanySalaryBatchesChildFormEntityContext };
