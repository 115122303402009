<app-dialog
  [overlayClose]="false"
  [(visible)]="visible"
  title="{{ 'Company.OvertimeSupplements' | translate }}"
  id="overtimeDialog"
>
  <module-check [moduleId]="4">
    <grid
      [(data)]="overtimeSupplement"
      [editMode]="editMode"
      class="OvertimeSupplementGrid"
      [addNew]="editMode"
      [deleteRow]="editMode"
      [addNewDisable]="sessionService.isGetting"
      (addNewEvent)="addOvertimeHandler($event)"
      (removeEvent)="removeOvertimeHandler($event)"
      (saveChangesEvent)="saveChanges($event)"
      addNewText="{{ 'SenioritySupplements.AddNew' | translate }}"
      [(triggerUpdate)]="refreshData"
      [(selectedItem)]="selectedItem"
    >
      <!-- <gridColumn [isGroupColumn]="true" title="{{'OvertimeSupplement.TotalHours' | translate}}" [width]="320"> -->
      <!-- <gridColumn field="Between" [editable]="false" type="text" [width]="80" class="grid-disable-cell" text="{{'OvertimeSupplement.Between' | translate}}"
                    title="{{'OvertimeSupplement.Between' | translate}}">
                </gridColumn> -->
      <gridColumn
        field="Between"
        [editable]="false"
        type="text"
        [width]="100"
        class="grid-disable-cell"
        text="{{ 'OvertimeSupplement.Between' | translate }}"
        title="{{ 'OvertimeSupplement.TotalHours' | translate }}"
        addOnClass="headerGroup titleGroup"
      >
      </gridColumn>

      <gridColumn
        field="TotalHoursWorkedFrom"
        type="numeric"
        [width]="100"
        [numbericOption]="numericOptions"
        title=""
        addOnClass="headerGroup"
      ></gridColumn>

      <!-- <gridColumn field="And" [editable]="false" type="text" [width]="80" class="grid-disable-cell" text="{{'OvertimeSupplement.And' | translate}}"
                    title="{{'OvertimeSupplement.And' | translate}}">
                </gridColumn> -->

      <gridColumn
        field="And"
        [editable]="false"
        type="text"
        [width]="80"
        class="grid-disable-cell"
        text="{{ 'OvertimeSupplement.And' | translate }}"
        title=""
        addOnClass="headerGroup"
      >
      </gridColumn>

      <gridColumn
        field="TotalHoursWorkedUntil"
        type="numeric"
        [editable]="true"
        [width]="120"
        [numbericOption]="numericOptions"
        title=""
        addOnClass="headerGroup"
      >
      </gridColumn>
      <!-- </gridColumn> -->

      <gridColumn
        field="SupplementAmount"
        type="numeric"
        [editable]="true"
        title="{{ 'OvertimeSupplement.ExtraperHour' | translate }}"
        [width]="100"
        [numbericOption]="numericOptions"
      >
      </gridColumn>

      <gridColumn
        field="SupplementPercentage"
        type="percentage"
        [editable]="true"
        title="{{ 'OvertimeSupplement.ExtraPercentage' | translate }}"
        [width]="100"
      >
      </gridColumn>

      <gridColumn
        field="OvertimeSupplementPrinciple"
        [comboboxDataSource]="intervalTypes"
        type="combobox"
        comboboxValueField="Id"
        comboboxDisplayField="Name"
        [editable]="true"
        title="{{ 'SenioritySupplements.Principle' | translate }}"
        [width]="150"
      >
      </gridColumn>
    </grid>
  </module-check>

  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
