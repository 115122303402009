import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  IAddUserToCompanyRequest,
  ICompanyUser,
  IReportRequest,
  ISalaryPeriod,
  ISalaryRecordView,
  ISalaryStatement,
  ISimpleImportResponse,
  IUserCreationData,
  IUserEmploymentTemplate,
  IUserTaxInfo
} from './ApiModel';
import { RxApiDataService } from './rx-data.service';
import { SessionService } from './SharedServices/Session/SessionService';

@Injectable()
export class RxDataService extends RxApiDataService {
  private cycleId: number;
  private salaryPeriods: ISalaryPeriod[];
  private companyUsers: ICompanyUser[];
  private companyUserListChanged = false;
  private employmentTemplates: IUserEmploymentTemplate[];
  private employmentTemplatesChanged = false;

  constructor(private httpClient: HttpClient, private sessionService: SessionService) {
    super();
    this.sessionService.isAlive.subscribe(() => {
      if (!this.sessionService.checkIsAliveError) {
        this.sessionService.checkIsAliveActive = true;
        super.Auth_IsSessionAlive().subscribe(
          () => {
            this.sessionService.checkIsAliveActive = false;
          },
          () => {
            /* Ignore errors from IsSessionAlive call */
            this.sessionService.checkIsAliveActive = false;
            this.sessionService.checkIsAliveError = true;
          }
        );
      }
    });
  }

  get needToReloadCompanyUsers(): boolean {
    return !this.companyUsers || this.companyUsers.length === 0 || this.companyUserListChanged;
  }

  get needToReloadEmployeeTemplate(): boolean {
    return !this.employmentTemplates || this.employmentTemplatesChanged;
  }

  httpGet(path: string, options?: any): Observable<any> {
    return this.httpClient.get<any>(path, options);
  }

  httpDelete(path: string, options?: any): Observable<any> {
    return this.httpClient.delete<any>(path, options);
  }

  httpPost(path: string, object: any, options?: any): Observable<any> {
    return this.httpClient.post(path, object, { headers: { 'Content-Type': 'application/json' } });
  }

  httpPut(path: string, object: any, options?: any): Observable<any> {
    return this.httpClient.put(path, object, { headers: { 'Content-Type': 'application/json' } });
  }

  httpDownload(path: string, options?: any): Observable<any> {
    return this.httpClient
      .get(path, { observe: 'response', responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json' } })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  httpPostDownload(path: string, object: any, options?: any): Observable<any> {
    return this.httpClient
      .post(path, object, {
        observe: 'response',
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  httpPostAttachment(path: string, data: any): Observable<any> {
    return this.httpClient.post(path, data).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  httpPostAttachmentAndDownload(path: string, data: any, options?: any): Observable<any> {
    return this.httpClient.post(path, data, { responseType: 'arraybuffer' }).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  private roundDecimal(value: number, decimals: number): number {
    if (value) {
      return parseFloat(value.toFixed(decimals));
    }

    return undefined;
  }

  Users_GetUserImage(userId?: number): Observable<string> {
    let path: string = this.baseUrl + '/api/users/{userId}/image';
    if (userId) {
      path = this.baseUrl + '/api/users/{userId}/image';
      path = path.replace('{userId}', userId.toString());
      return this.httpGet(path);
    } else {
      return super.Users_GetCurrentUserImage();
    }
  }

  SalaryStatements_PostPdfFromClient_Encoded(salaryStatement: ISalaryStatement): Observable<any> {
    const path: string = this.baseUrl + 'api/salarystatements/pdf/fromclient/encoded';
    return this.httpPost(path, salaryStatement);
  }

  Companies_UploadCompanyLogo(formData: any): Observable<string> {
    const path: string = this.baseUrl + '/api/companies/current/logo';
    return this.httpPostAttachment(path, formData);
  }

  Users_CurrentImage(formData: any): Observable<string> {
    const path: string = this.baseUrl + '/api/users/current/image';
    return this.httpPostAttachment(path, formData);
  }

  User_UploadEmployeeImage(data: any, userId: any): Observable<string> {
    let path: string = this.baseUrl + '/api/users/{userId}/image';
    path = path.replace('{userId}', userId.toString());
    return this.httpPostAttachment(path, data);
  }

  CompanyUsers_UpdateCompanyUser(companyUser: ICompanyUser): Observable<ICompanyUser> {
    return super.CompanyUsers_UpdateCompanyUser(companyUser).pipe(tap(() => (this.companyUserListChanged = true)));
  }

  CompanyUsers_AddUserToCompany(request: IAddUserToCompanyRequest): Observable<IUserCreationData> {
    return super.CompanyUsers_AddUserToCompany(request).pipe(tap(() => (this.companyUserListChanged = true)));
  }

  CompanyUsers_DeleteCompanyUser(companyUserId: number): Observable<any> {
    return super.CompanyUsers_DeleteCompanyUser(companyUserId).pipe(tap(() => (this.companyUserListChanged = true)));
  }

  private loadEmploymentTemplates_GetEmploymentTemplates(): Observable<IUserEmploymentTemplate[]> {
    return Observable.create((observer: any) => {
      super.EmploymentTemplates_GetEmploymentTemplates().subscribe(
        (data: IUserEmploymentTemplate[]) => {
          this.employmentTemplatesChanged = false;
          this.employmentTemplates = data;
          observer.next(data.slice());
        },
        (error: any) => {
          throwError(error);
        }
      );
    });
  }

  EmploymentTemplates_GetEmploymentTemplates(forceLoad: boolean = false): Observable<IUserEmploymentTemplate[]> {
    if (forceLoad || this.needToReloadEmployeeTemplate) {
      this.employmentTemplates = []; //// block new request when waiting the response of the api
      return this.loadEmploymentTemplates_GetEmploymentTemplates();
    }

    return Observable.create((observer: any) => {
      if (this.employmentTemplates.length > 0) {
        observer.next(this.employmentTemplates.slice());
      } else {
        //// wait the api CompanyUsers_GetCompanyUsers response
        setTimeout(() => {
          observer.next(this.employmentTemplates.slice());
        }, 4000);
      }
    });
  }

  EmploymentTemplates_UpdateEmploymentTemplate(template: IUserEmploymentTemplate): Observable<IUserEmploymentTemplate> {
    return Observable.create((observer: any) => {
      super.EmploymentTemplates_UpdateEmploymentTemplate(template).subscribe(
        (data: IUserEmploymentTemplate) => {
          this.employmentTemplatesChanged = true;
          observer.next(data);
        },
        (error: any) => {
          throwError(error);
        }
      );
    });
  }

  EmploymentTemplates_CreateEmploymentTemplate(template: IUserEmploymentTemplate): Observable<IUserEmploymentTemplate> {
    return Observable.create((observer: any) => {
      super.EmploymentTemplates_CreateEmploymentTemplate(template).subscribe(
        (data: IUserEmploymentTemplate) => {
          this.employmentTemplatesChanged = true;
          observer.next(data);
        },
        (error: any) => {
          throwError(error);
        }
      );
    });
  }

  EmploymentTemplates_DeleteEmploymentTemplate(templateId: number): Observable<any> {
    return Observable.create((observer: any) => {
      super.EmploymentTemplates_DeleteEmploymentTemplate(templateId).subscribe(
        (result: any) => {
          this.employmentTemplatesChanged = true;
          observer.next(result);
        },
        (error: any) => {
          throwError(error);
        }
      );
    });
  }

  SalaryBatches_GetSalaryPeriods(cycleId: number): Observable<ISalaryPeriod[]> {
    return Observable.create((observer: any) => {
      if (this.salaryPeriods && this.cycleId === cycleId) {
        observer.next(this.salaryPeriods.slice());
      } else {
        super.SalaryBatches_GetSalaryPeriods(cycleId).subscribe(
          (data: ISalaryPeriod[]) => {
            this.salaryPeriods = data;
            this.cycleId = cycleId;
            observer.next(data.slice());
          },
          (error: any) => {
            throwError(error);
          }
        );
      }
    });
  }

  CompanyUsers_GetCurrentTaxInfo(userEmploymentId: number): Observable<IUserTaxInfo> {
    return super.Employments_GetCurrentTaxInfo(userEmploymentId);
  }

  EmploymentTemplates_GetEmploymentTemplate(templateId: number): Observable<any> {
    return Observable.create((observer: any) => {
      super.EmploymentTemplates_GetEmploymentTemplate(templateId).subscribe(
        (template: IUserEmploymentTemplate) => {
          if (template) {
            if (template.VacationTypeId === 0) {
              template.VacationTypeId = undefined;
            }

            if (template.PensionProviderId === 0) {
              template.PensionProviderId = undefined;
            }

            if (template.PensionBaseCalculationMethodId === 0) {
              template.PensionBaseCalculationMethodId = undefined;
            }

            if (template.Pension2ProviderId === 0) {
              template.Pension2ProviderId = undefined;
            }

            if (template.Pension2BaseCalculationMethodId === 0) {
              template.Pension2BaseCalculationMethodId = undefined;
            }

            if (template.SalaryCycleId === 0) {
              template.SalaryCycleId = undefined;
            }

            if (template.VacationProviderId === 0) {
              template.VacationProviderId = undefined;
            }

            if (template.VacationSupplementPayoutMonth === 0) {
              template.VacationSupplementPayoutMonth = undefined;
            }
          }

          observer.next(template);
        },
        (error: any) => {
          throwError(error);
        }
      );
    });
  }

  SalaryRecords_GetSalaryRecordsByEmployment(userEmploymentId: number): Observable<ISalaryRecordView[]> {
    return Observable.create((observer: any) => {
      super.SalaryRecords_GetSalaryRecordsByEmployment(userEmploymentId).subscribe(
        (salaryRecords: ISalaryRecordView[]) => {
          if (salaryRecords) {
            salaryRecords.forEach((salaryRecord: ISalaryRecordView) => {
              if (salaryRecord.Units) {
                salaryRecord.Units = this.roundDecimal(salaryRecord.Units, 2);
              }

              if (salaryRecord.AmountPerUnit) {
                salaryRecord.AmountPerUnit = this.roundDecimal(salaryRecord.AmountPerUnit, 2);
              }

              if (salaryRecord.Amount) {
                salaryRecord.Amount = this.roundDecimal(salaryRecord.Amount, 2);
              }
            });
          }

          observer.next(salaryRecords);
        },
        (error: any) => {
          throwError(error);
        }
      );
    });
  }

  Users_SetLanguageContext(languageId: number): Observable<any> {
    let path: string = this.baseUrl + '/api/users/current/language/{languageId}';
    path = path.replace('{languageId}', languageId.toString());
    return this.httpPut(path, undefined);
  }

  Miscellaneous_FailNotFound(): Observable<any> {
    const path: string = this.baseUrl + '/api/fail/notfound';
    return this.httpGet(path);
  }

  CompanyDataImport_GetEmployeeTemplate(): Observable<any> {
    const path: string = this.baseUrl + '/api/import/employees/template';
    return this.httpDownload(path);
  }

  CompanyDataImport_GetEmployeeTemplateFilled(): Observable<any> {
    const path: string = this.baseUrl + '/api/import/employees/template/filled';
    return this.httpDownload(path);
  }

  CompanyDataImport_GetPayrollImportTemplate(idType: string): Observable<any> {
    let path: string = this.baseUrl + '/api/import/salaryrecordsrow/template/id/{idType}';
    path = path.replace('{idType}', idType.toString());
    return this.httpDownload(path);
  }

  CompanyDataImport_GetTimeEntriesImportTemplate(idType: string): Observable<any> {
    let path: string = this.baseUrl + '/api/import/timeentry/template/id/{idType}';
    path = path.replace('{idType}', idType.toString());
    return this.httpDownload(path);
  }

  CompanyDataImport_GetBalancesTemplate(date: string): Observable<any> {
    let path: string = this.baseUrl + '/api/import/balances/template/perdate/{yyyyMMdd}';
    path = path.replace('{yyyyMMdd}', date);
    return this.httpDownload(path);
  }

  CompanyDataImport_GetBalancesImportTemplate(date: string): Observable<any> {
    let path: string = this.baseUrl + '/api/import/balances/template/filled/perdate/{yyyyMMdd}';
    path = path.replace('{yyyyMMdd}', date);
    return this.httpDownload(path);
  }

  CompanyDataImport_GetBalancesTemplateByEmploymentId(date: string, employmentId: string): Observable<any> {
    let path: string = this.baseUrl + '/api/import/balances/template/perdate/{yyyyMMdd}/employment/{employmentId}';
    path = path.replace('{yyyyMMdd}', date);
    path = path.replace('{employmentId}', employmentId);
    return this.httpDownload(path);
  }

  CompanyDataImport_PostEmployeeFilledTemplate(data: any): Observable<any> {
    const path: string = this.baseUrl + '/api/import/employees/execute';
    return this.httpPostAttachment(path, data);
  }

  CompanyDataImport_PostEmployeeFilledTemplateWithOptions(
    data: any,
    options: string
  ): Observable<ISimpleImportResponse> {
    let path: string = this.baseUrl + '/api/import/employees/execute/options/{options}';
    path = path.replace('{options}', options.toString());
    return this.httpPostAttachment(path, data);
  }

  EmployeeDataImport_PerformBalancesCorrection(data: any): Observable<any> {
    const path: string = this.baseUrl + '/api/import/balances/correction';
    return this.httpPostAttachment(path, data);
  }

  CompanyDataImport_PostBalancesFilledTemplate(data: any, options: string): Observable<any> {
    let path: string = this.baseUrl + '/api/import/balances/{options}';
    path = path.replace('{options}', options.toString());
    return this.httpPostAttachment(path, data);
  }

  SalaryStatements_GetHtml(salaryStatementId: number, outputType: string = 'html'): Observable<any> {
    return super.SalaryStatements_GetHtml(salaryStatementId, outputType);
  }

  ImportMapping_GetExcelHeadersOfFile(data: any, operation: string, startrow: number): Observable<string> {
    let path: string = this.baseUrl + '/api/import/columnheaders/operation/{operation}/startrow/{startrow}';
    path = path.replace('{operation}', operation.toString());
    path = path.replace('{startrow}', startrow.toString());
    return this.httpPostAttachment(path, data);
  }

  ImportMapping_CustomImportExcel(mappingId: number, data: any): Observable<any> {
    let path: string = this.baseUrl + '/api/import/mappings/{mappingId}/execute';
    path = path.replace('{mappingId}', mappingId.toString());
    return this.httpPostAttachment(path, data);
  }

  ImportMapping_CustomImportExcelOptions(mappingId: number, options: string, data: any): Observable<void> {
    let path: string = this.baseUrl + '/api/import/mappings/{mappingId}/execute/options/{options}';
    path = path.replace('{mappingId}', mappingId.toString());
    path = path.replace('{options}', options.toString());
    return this.httpPostAttachment(path, data);
  }

  CompanyDataImport_DanlonPdf(operation: string, data: any): Observable<void> {
    let path: string = this.baseUrl + '/api/import/danloenpdf/{operation}';
    path = path.replace('{operation}', operation.toString());
    return this.httpPostAttachment(path, data);
  }

  CompanyDataImport_DanlonPdfToExcel(operation: string, data: any): Observable<string> {
    let path: string = this.baseUrl + '/api/import/danloenpdf/{operation}/Excel';
    path = path.replace('{operation}', operation.toString());
    return this.httpPostAttachmentAndDownload(path, data);
  }

  CompanyDataImport_PlanDayToExcel(operation: string, data: any): Observable<string> {
    let path: string = this.baseUrl + '/api/import/convert/planday/excel/{operation}';
    path = path.replace('{operation}', operation.toString());
    return this.httpPostAttachmentAndDownload(path, data);
  }

  CompanyDataImport_DatFileAofToExcel(operation: string, data: any): Observable<string> {
    const path: string = this.baseUrl + 'api/import/aof/excel';
    return this.httpPostAttachmentAndDownload(path, data);
  }

  CompanyDataImport_DatFileToExcel(operation: string, data: any): Observable<string> {
    let path: string = this.baseUrl + '/api/import/convert/epwin/excel/{dataType}';
    path = path.replace('{dataType}', operation);
    return this.httpPostAttachmentAndDownload(path, data);
  }

  CompanyDataImport_CustomPreviewOfFile(mappingId: number, size: number, data: any): Observable<string[]> {
    let path: string = this.baseUrl + '/api/import/preview/mappings/{mappingId}/size/{size}';
    path = path.replace('{mappingId}', mappingId.toString());
    path = path.replace('{size}', size.toString());
    return this.httpPostAttachment(path, data);
  }

  Import_PayrollDataImport(data: any, options: string): Observable<void> {
    let path: string = this.baseUrl + '/api/import/salaryrecords/{options}';
    path = path.replace('{options}', options.toString());
    return this.httpPostAttachment(path, data);
  }

  SalaryStatements_GetPdf(salaryStatementId: number): Observable<any> {
    let path: string = this.baseUrl + '/api/salarystatements/{salaryStatementId}/pdf';
    path = path.replace('{salaryStatementId}', salaryStatementId.toString());
    return this.httpDownload(path);
  }

  SalaryStatements_GetPdfMergedBySalaryBatch(salaryBatchId: number): Observable<any> {
    let path: string = this.baseUrl + '/api/salarystatements/salarybatch/{salaryBatchId}/pdf/merged';
    path = path.replace('{salaryBatchId}', salaryBatchId.toString());
    return this.httpDownload(path);
  }

  Reports_GetPdfReport(request: IReportRequest): Observable<any> {
    const path: string = this.baseUrl + '/api/reports/pdf';
    return this.httpPostDownload(path, request);
  }

  Reports_GetXlsxReportDownload(request: IReportRequest): Observable<any> {
    const path: string = this.baseUrl + '/api/reports/xlsx';
    return this.httpPostDownload(path, request);
  }

  Reports_GetCsvReportDownload(request: IReportRequest): Observable<any> {
    const path: string = this.baseUrl + '/api/reports/csv';
    return this.httpPostDownload(path, request);
  }

  Reports_GetBankReportDownload(request: IReportRequest): Observable<any> {
    const path: string = this.baseUrl + '/api/reports/bank';
    return this.httpPostDownload(path, request);
  }

  Reports_GetHtmlReportDownload(request: IReportRequest): Observable<any> {
    const path: string = this.baseUrl + '/api/reports/html';
    return this.httpPost(path, request);
  }
}
