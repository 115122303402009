<div class="FormEmlement Company-totalsPayslipsSearch Company-payslipLabel">
  <span class="FormElement-label" width="210">{{ selectedSalaryStatement?.UserFullName }}</span>
  <div class="right" *ngIf="!hideDownloadIcons">
    <payslip-icon
      *ngIf="IsAppOrDesktop"
      id="ViewPayslipSameTabButton"
      [isHidden]="isHidden"
      [payslipIconType]="'htmlSameTab'"
      (click)="onPayslipIconClicked('htmlSameTab')"
    ></payslip-icon>
    <payslip-icon
      *ngIf="IsWeb"
      [payslipIconType]="'htmlNewTab'"
      (click)="onPayslipIconClicked('htmlNewTab')"
    ></payslip-icon>
    <payslip-icon
      *ngIf="IsFinalizedBatch"
      [payslipIconType]="'pdf'"
      (click)="onPayslipIconClicked('pdf')"
    ></payslip-icon>
  </div>
</div>
