import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { Global } from '../../Common/Global';
import { KeyDownHelper } from '../../Common/KeyDownHelper';
import { ICompanyUser, IUserEmployment } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { EmployeeList } from './Controls/EmployeeList';

@Component({
  selector: 'app-employee-list',
  templateUrl: './EmployeeList.component.html'
})
export class EmployeeListComponent extends FormComponentBase implements OnInit {
  @Output() public selectedEmployeeChange: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();
  @Output() public preLoadAvatar: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public addEmployee: EventEmitter<void> = new EventEmitter<void>();
  @Output() public refreshEmployeesChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public refreshEmployeeChange: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();
  @Output() public globalKeyDownChange: EventEmitter<any> = new EventEmitter<any>();

  private isAvatarChangedValue: boolean;
  @Input()
  public get isAvatarChanged(): boolean {
    return this.isAvatarChangedValue;
  }

  public set isAvatarChanged(value: boolean) {
    this.disabled = false;
  }

  @Input()
  public get newEmployee(): ICompanyUser {
    return this.newlyCreatedCompanyUser;
  }

  public set newEmployee(value: ICompanyUser) {
    if (value) {
      this.sessionService.currentUserEmploymentId = undefined;
      this.newlyCreatedCompanyUser = value;
      this.addNewCompanyUser(value);
      this.selectEmployee(value.Id);
      setTimeout(() => document.querySelector('.k-state-selected').scrollIntoView(false));
    }
  }

  @Input()
  public get deletedEmployee(): number {
    return this.deletedId;
  }

  public set deletedEmployee(value: number) {
    if (value) {
      this.removeCompanyUser(value);
      this.setEmployeeDataSource();
    }
  }

  @Input()
  public get refreshEmployees(): boolean {
    return this.refreshEmployeesEvent;
  }
  public set refreshEmployees(value: boolean) {
    if (value) {
      this.loadEmployeeList(true);
    }

    this.refreshEmployeesEvent = false;
    setTimeout(() => this.refreshEmployeesChange.emit(this.refreshEmployeesEvent));
  }

  @Input()
  public get refreshEmployee(): ICompanyUser {
    return this.refreshEmployeeData;
  }
  public set refreshEmployee(employee: ICompanyUser) {
    if (employee) {
      this.onEmployeeUpdated(employee);
    }

    this.refreshEmployeeData = undefined;
    setTimeout(() => this.refreshEmployeeChange.emit(this.refreshEmployeeData));
  }

  @Input()
  public get globalKeyDown(): any {
    return this.globalKeyDownObject;
  }
  public set globalKeyDown(value: any) {
    if (value) {
      this.onGlobalKeyDown(value);
    }

    this.globalKeyDownObject = undefined;
    setTimeout(() => this.globalKeyDownChange.emit(this.globalKeyDownObject));
  }

  private selectedItemValue: any;
  public get selectedItem(): any {
    return this.selectedItemValue;
  }
  public set selectedItem(value: any) {
    this.selectedItemValue = value;
  }
  public get isMobileApp(): boolean {
    return this.sessionService.browser.isMobile;
  }
  public searchKeyWord = '';
  public includeInactive = false;
  public hasDepartmentFilter = true;
  public totalEmployee = 0;

  private newlyCreatedCompanyUser: ICompanyUser;
  private deletedId: number;
  private departmentId = -1;
  private sortBy = 1;
  private refreshEmployeesEvent: boolean;
  private refreshEmployeeData: ICompanyUser;
  private globalKeyDownObject: any;
  private isUpdate = false;

  private companyUsers: ICompanyUser[];
  private companyUser: ICompanyUser;
  private parameterNavigationEmployeeId: number;

  // IsResettingDataSource: true -> load detail, but don't collapse search filter on mobile screen.
  private isResettingDataSource = false;

  // If isSearching is true, then prevent loading detail because of resetting grid datasource.
  private isSearching = false;

  constructor(
    private dataService: RxDataService,
    private settingService: SettingService,
    public sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {
    super();
    // TODO: find a way to handle the following events with databinding:
    // 1. Update companyUser event
    // 2. Add companyUser event
    // 3. Delete companyUser event
  }

  public ngOnInit(): void {
    this.loadEmployeeList();
    this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: string): void => {
      // setTimeout(() => {
      //     if (this.IsActive) {
      //         this.loadEmployeeList();
      //         if (this.settingService.navigationParameters["EmployeeId"] && this.companyUsers && this.companyUsers.length > 0) {
      //             this.selectCompanyUserByParam();
      //         }
      //     }
      // });
      if (this.IsActive) {
        this.loadEmployeeList();
        if (
          this.settingService.navigationParameters['EmployeeId'] &&
          this.companyUsers &&
          this.companyUsers.length > 0
        ) {
          this.selectCompanyUserByParam();
        }
      }
    });
  }

  public get IsActive(): boolean {
    return this.sessionService.currentState.indexOf('tabs.employee') >= 0;
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public get IsEmployeeRole(): boolean {
    return this.sessionService.role.IsEmployeeRole;
  }

  public get allowEditUserEmployment(): boolean {
    return (
      this.sessionService.feature.AllowEditUserEmployment(this.sessionService.currentState) &&
      !this.sessionService.role.IsReadOnly
    );
  }

  public get employeeListVisible(): boolean {
    return this.sessionService.feature.ShowEmployeeList;
  }

  private onGlobalKeyDown($event: any): void {
    if (!this.IsActive) {
      return;
    }

    if (KeyDownHelper.isFocusEmployeeListShortcutKey($event)) {
      $event.preventDefault();
      this.selectEmployee(this.selectedItem.Id);
      return;
    }

    if (KeyDownHelper.isSelectNextEmployeeShortcutKey($event)) {
      $event.preventDefault();
      this.navigateUpDownCompanyUser(false);
      return;
    }

    if (KeyDownHelper.isSelectPreviousEmployeeShortcutKey($event)) {
      $event.preventDefault();
      this.navigateUpDownCompanyUser(true);
    }
  }

  private selectCompanyUserByParam(): void {
    if (this.companyUsers && this.settingService.navigationParameters['EmployeeId']) {
      const compUser: ICompanyUser = this.companyUsers.find(
        (user: ICompanyUser) => user.Id.toString() === this.settingService.navigationParameters['EmployeeId'].toString()
      );
      this.settingService.navigationParameters['EmployeeId'] = undefined;
      if (compUser && compUser.Id) {
        this.selectedItem = compUser;
        this.fireCompanyUserSelectionEvent(compUser.Id);
      }
    }
  }

  private loadEmployeeList(forceReload: boolean = false): void {
    if (this.settingService.navigationParameters['EmployeeId']) {
      this.parameterNavigationEmployeeId = this.settingService.navigationParameters['EmployeeId'];
      this.settingService.navigationParameters['EmployeeId'] = undefined;
    }

    this.initializeEmployeesGrid(forceReload);
    this.initializeHelpers();
  }

  public onDepartmentChanged(value: number): void {
    setTimeout(() => {
      this.departmentId = value;
      this.onFilterControlChanged();
    });
  }

  public onSortByChanged(value: number): void {
    this.sortBy = value;
    this.onFilterControlChanged();
  }

  private initializeEmployeesGrid(forceReload: boolean = false): void {
    // Populate grid datasource and set data for viewcompanyUsers
    this.getCompanyUsers(undefined, forceReload);
  }

  private initializeHelpers(): void {
    ////this.GridCompanyUserHelper = new GridHelper();
    ////this.GridCompanyUserHelper.enableKeysShortcuts(this.controls.GridCompanyUser, true, false, false);
    ////ComboBoxHelper.enableAutoCompleteOn(["#comboboxDepartment", "#comboboxSort"]);
  }

  // Use for Update companyUser event
  private onEmployeeUpdated(employee: ICompanyUser): void {
    let companyUser: ICompanyUser;
    if (this.companyUsers) {
      companyUser = this.companyUsers.find((us: ICompanyUser) => {
        return us.Id === employee.Id;
      });
    }
    companyUser.FirstName = employee.FirstName;
    companyUser.MiddleName = employee.MiddleName;
    companyUser.LastName = employee.LastName;
    companyUser.FullName = employee.FullName;
    companyUser.IsActive = employee.IsActive;

    // Select department all; check include inactive checkbox.
    if (!companyUser.IsActive) {
      this.includeInactive = true;
    }

    if (
      employee.UserEmployments &&
      employee.UserEmployments.length > 0 &&
      companyUser.UserEmployments &&
      companyUser.UserEmployments.length
    ) {
      employee.UserEmployments.forEach((updatedEmployment: IUserEmployment) => {
        const employment: IUserEmployment = companyUser.UserEmployments.find(
          (e: IUserEmployment) => e.Id === updatedEmployment.Id
        );
        if (employment) {
          companyUser.UserEmployments[companyUser.UserEmployments.indexOf(employment)] = updatedEmployment;
        }
      });
    }

    this.isUpdate = true;
    this.companyUser = companyUser;
    // this.setEmployeeDataSource(employee.Id);
    this.onIncludeActiveEmployeesChecked(this.includeInactive);
  }

  private selectEmployee(employeeId: number): void {
    if (!this.companyUsers) {
      // Return if company users are not loaded yet.
      this.parameterNavigationEmployeeId = employeeId;
      return;
    }

    this.getCompanyUsers(employeeId);
  }

  // Use for Add companyUser event
  private addNewCompanyUser(companyUser: ICompanyUser): void {
    if (companyUser && companyUser.Id) {
      let existUser: ICompanyUser = this.companyUsers.find((tempCompany: ICompanyUser) => {
        return tempCompany.Id === companyUser.Id;
      });
      if (!existUser) {
        this.companyUsers.push(companyUser);
        ////this.companyUsers = this.companyUsers.filter((model: ICompanyUser) => { return model.Id !== -1; });
      } else {
        existUser = companyUser;
      }

      this.companyUser = companyUser;
    }
  }

  // Used for Delete companyUser event
  private removeCompanyUser(companyUserId: number): void {
    if (this.companyUsers) {
      this.companyUsers.forEach((companyUser: ICompanyUser, index: number) => {
        if (companyUser.Id === companyUserId) {
          this.companyUsers.splice(index, 1);
        }
      });
    }
  }

  public onCloseEmployeeFilterEventClick(): void {
    this.sessionService.employeeListActive = false;
  }

  public onFilterControlChanged(): void {
    let selectedEmployeeId: number;
    if (!this.searchKeyWord) {
      selectedEmployeeId = this.companyUser ? this.companyUser.Id : undefined;
    }

    this.setEmployeeDataSource(selectedEmployeeId);
  }

  public onSearchKeyDown(e: any): void {
    if (!e || e.keyCode === undefined) {
      return;
    }
    this.isSearching = true;
    if (e.keyCode === 9 || e.keyCode === 13) {
      // Search completed.
      this.isSearching = false;
      if (this.companyUserListDataSource && this.companyUserListDataSource.length > 0) {
        this.sessionService.employeeListActive = false;
      }
      this.onEmployeeSearchConfirm();
    } else if (e.keyCode === 38) {
      // Navigate up between search results.
      this.navigateUpDownCompanyUser(true);
      e.preventDefault();
    } else if (e.keyCode === 40) {
      // Navigate down between search results.
      this.navigateUpDownCompanyUser(false);
      e.preventDefault();
    } else {
      this.onSearchChanged();
    }
  }

  public onSearchChanged(): void {
    let selectedEmployeeId: number;
    if (!this.searchKeyWord) {
      // Reselect the last Employee in case of empty search keyword.
      this.isSearching = true;
      selectedEmployeeId = this.companyUser ? this.companyUser.Id : undefined;
    }

    this.setEmployeeDataSource(selectedEmployeeId);
  }

  public onSearchBlur(e: any): void {
    setTimeout(() => {
      this.onEmployeeSearchConfirm();
      this.onGridSelectionChange(this.selectedItem);
    }, 300);
  }

  private onEmployeeSearchConfirm(): void {
    this.searchKeyWord = '';
    this.isSearching = false;
    this.setEmployeeDataSource(this.selectedItem ? this.selectedItem.Id : undefined);
  }

  public onIncludeActiveEmployeesChecked(includeInactive: boolean): void {
    if (this.includeInactive === false && this.companyUser.IsActive === false) {
      const companyUserIndex: number = this.companyUserListDataSource.indexOf(this.companyUser);
      if (companyUserIndex) {
        (this.companyUserListDataSource as any[]).splice(companyUserIndex, 1);
        this.selectedItem = this.companyUserListDataSource[0];
        this.getCompanyUsers(undefined, true);
      }
    } else {
      if (
        !this.sessionService.currentUserEmploymentId ||
        (this.sessionService.currentUserEmploymentId &&
          this.sessionService.currentUserEmploymentId !== this.companyUser.Id)
      ) {
        this.sessionService.currentUserEmploymentId = this.companyUser.Id;
      }
      this.getCompanyUsers(undefined, true);
    }
  }

  public companyUserListDataSource: any;
  private setEmployeeDataSource(selectedCompanyUserId?: number): void {
    setTimeout(() => {
      if (!this.companyUsers) {
        return;
      }

      this.isResettingDataSource = true;
      this.companyUserListDataSource = this.searchEmployees();
      this.totalEmployee = this.companyUserListDataSource.length;
      if (this.companyUserListDataSource && this.companyUserListDataSource.length > 0) {
        // Select relevant row
        const companyUser: any = this.getDefaultCompanyUser(this.companyUserListDataSource, selectedCompanyUserId);
        this.selectedItem = companyUser
          ? companyUser
          : this.selectedItem &&
            this.companyUserListDataSource.find((model: ICompanyUser) => model.Id === this.selectedItem.Id)
          ? this.selectedItem
          : this.companyUserListDataSource[0];
        // if (this.selectedItem && this.selectedItem.Id && this.selectedItem.Id !== this.currentCompanyUserId) {
        //   this.fireCompanyUserSelectionEvent(this.selectedItem.Id);
        // }
        setTimeout(() =>
          document.querySelector('.k-state-selected')
            ? document.querySelector('.k-state-selected').scrollIntoView(false)
            : null
        );
      }
    });
  }

  private getDefaultCompanyUser(result: any, selectedCompanyUserId: number): any {
    if (this.sessionService.currentUserEmploymentId) {
      const comUser: ICompanyUser = this.getCompanyUserByUserEmploymentId(
        this.sessionService.currentUserEmploymentId,
        selectedCompanyUserId
      );
      return comUser;
    } else {
      let companyUser: any;
      if (Global.EMPLOYEESELECT && Global.EMPLOYEESELECT.CompanyId === Global.COMPANY_ID) {
        if (selectedCompanyUserId) {
          companyUser = result.find((tempSelectedCompanyUser: ICompanyUser) => {
            return tempSelectedCompanyUser.Id === selectedCompanyUserId;
          });
        } else if (this.parameterNavigationEmployeeId) {
          companyUser = result.find((tempParameterNavigationEmployee: ICompanyUser) => {
            return tempParameterNavigationEmployee.Id === this.parameterNavigationEmployeeId;
          });
          if (companyUser) {
            return companyUser;
          }
        } else if (this.companyUser && this.companyUser.Id) {
          companyUser = result.find((tempcompanyUser: ICompanyUser) => {
            return tempcompanyUser.Id === this.companyUser.Id;
          });
          if (companyUser) {
            return companyUser;
          }
        } else {
          companyUser = result.find((tempcompanyUser: ICompanyUser) => {
            return tempcompanyUser.Id === Global.EMPLOYEESELECT.Id;
          });
        }
        if (companyUser) {
          return companyUser;
        }
      }

      return result[0];
    }
  }

  private searchEmployees(): any {
    const employeeList: EmployeeList = new EmployeeList(this.companyUsers);
    return employeeList.search(this.searchKeyWord, this.departmentId, this.sortBy.toString());
  }

  private navigateUpDownCompanyUser(up: boolean): void {
    const dataSourceLength: number = this.companyUserListDataSource ? this.companyUserListDataSource.length : 0;
    if (dataSourceLength > 0) {
      let nextItemIndex: number =
        (this.selectedItem ? this.companyUserListDataSource.indexOf(this.selectedItem) : 0) + (up ? -1 : 1);
      nextItemIndex = this.calculateCompanyUserIndex(dataSourceLength - 1, nextItemIndex);
      this.selectedItem = this.companyUserListDataSource[nextItemIndex];
    }
  }

  private calculateCompanyUserIndex(maxIndex: number, estimatedIndex: number): number {
    // Zero based index.
    let index: number = estimatedIndex;
    if (estimatedIndex < 0) {
      index = maxIndex;
    } else if (estimatedIndex > maxIndex) {
      index = 0;
    }

    return index;
  }

  private getCompanyUsers(selectedEmployeeId?: number, forceReload: boolean = false): void {
    if (this.includeInactive) {
      this.dataService
        .CompanyUsers_GetAllCompanyUsers()
        .subscribe((data: ICompanyUser[]) => this.onGetCompanyUsersCompleted(data, selectedEmployeeId));
    } else {
      // if (forceReload) {
      //   this.staticDataService.loadCompanyUsers();
      //   return;
      // }

      this.staticDataService.companyUsers
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: ICompanyUser[]) => this.onGetCompanyUsersCompleted(data, selectedEmployeeId));
    }
  }

  private onGetCompanyUsersCompleted(data: ICompanyUser[], companyUserId?: number): void {
    let filterData: ICompanyUser[] = data;
    if (this.sessionService.role.EmployeeWithRoleAsManager && Global.SESSION) {
      filterData = data.filter((companyUser: ICompanyUser) => {
        return companyUser.Id !== Global.SESSION.CurrentCompanyUser.Id;
      });
    }

    this.companyUsers = filterData;
    this.setEmployeeDataSource(companyUserId);
  }

  public currentCompanyUserId: number;
  public onGridSelectionChange(companyUser: any): void {
    if (!this.isSearching) {
      if (companyUser && companyUser.Id && companyUser.Id !== this.currentCompanyUserId) {
        this.currentCompanyUserId = companyUser.Id;
        this.fireCompanyUserSelectionEvent(companyUser.Id);
      }

      this.settingService.stayEmploymentId =
        companyUser && companyUser.StayEmploymentId ? companyUser.StayEmploymentId : undefined;

      if (!this.isResettingDataSource) {
        // Close the employee list if it is active in small resolution
        this.onCloseEmployeeFilterEventClick();
      }
    }

    this.isResettingDataSource = false;
    this.isSearching = false;
  }

  public onClickSelectEmployee(): void {
    if (this.companyUser) {
      this.onCloseEmployeeFilterEventClick();
    }
  }

  public disabled = false;
  private fireCompanyUserSelectionEvent(companyUserId: number): void {
    this.disabled = true;
    this.dataService.CompanyUsers_GetCompanyUserById(companyUserId).subscribe((companyUser: ICompanyUser) => {
      this.companyUser = companyUser;
      this.preLoadAvatar.emit(true);
      this.selectedEmployeeChange.emit(companyUser);
      this.sessionService.currentUserEmploymentId = undefined;
    });
  }

  public onAddEmployeeClicked(): void {
    this.addEmployee.emit();
  }

  private getCompanyUserByUserEmploymentId(userEmploymentId: number, selectedCompanyUserId: number): ICompanyUser {
    if (this.isUpdate) {
      const temp = this.companyUsers.find((companyUser: ICompanyUser) => companyUser.Id === selectedCompanyUserId);
      return temp;
    } else {
      let a: ICompanyUser;
      this.companyUsers.forEach((companyUser: ICompanyUser, index: number) => {
        if (companyUser.UserEmployments) {
          const b: any = companyUser.UserEmployments.find((us: IUserEmployment) => us.Id === userEmploymentId);
          if (b) {
            a = companyUser;
          }
        }
      });
      return a;
    }
  }
}
