import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Raven from 'raven-js';
import { BehaviorSubject, Observable } from 'rxjs';
import 'rxjs/add/observable/of'; //proper way to import the 'of' operator
import { map } from 'rxjs/operators';
import { version } from '../../../version';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { RouterStateService } from '../../Common/RouterState.service';
import {
  IAccount,
  IAccountType,
  IApiUser,
  IBank,
  IBankHoliday,
  IBillingPrinciple,
  ICity,
  ICompany,
  ICompanyType,
  ICompanyUser,
  ICompanyUserView,
  ICountry,
  ICurrency,
  IDepartment,
  IExternalSystem,
  IExternalSystemConfiguration,
  IExternalSystemCredential,
  IExternalSystemOperation,
  IFinanceAccountType,
  IImportSpecification,
  IIncomeType,
  IIntegration,
  IIntegrationStatus,
  IIntervalType,
  ILanguage,
  IModule,
  IModuleCompanyView,
  IModulePackage,
  IMonth,
  IMunicipality,
  IOvertimeSupplementPrinciple,
  IPaymentMethod,
  IPensionBaseCalculationMethod,
  IPensionProvider,
  IReportArea,
  IReportParameter,
  IRole,
  ISalaryCycle,
  ISalaryRecordPersistenceType,
  ISalaryRecordsOverviewDisplayMode,
  ISalarySummaryCategory,
  ISalaryTypeCategory,
  ISimpleCompany,
  ISimpleKeyValuePair,
  IStartupTaskCompanyView,
  IStatisticsEmploymentPrinciple,
  IStatisticsEmploymentTerms,
  IStatisticsJobStatus,
  IStatisticsRecipient,
  IStatisticsSalaryPrinciple,
  ITaxCardType,
  ITimeEntryRecordAcrossSalaryPeriodStrategy,
  ITimeEntryStatus,
  IUnitType,
  IVacationProvider,
  IVacationType,
  IVacationTypeGL
} from '../ApiModel';
import { RxDataService } from '../RxDataService';
import { ModalService } from './ModalService';
import { SessionService } from './Session/SessionService';
import { SettingService } from './SettingService';

@Injectable()
export class StaticDataService {
  constructor(
    private dataService: RxDataService,
    private translateService: TranslateService,
    private settingsService: SettingService,
    private sessionService: SessionService,
    private modalService: ModalService,
    private state: RouterStateService
  ) {
    this.translateErrorMessages();
    this.configureRaven();
    this.sessionService.OnTranslateChanged.subscribe(() => this.translateErrorMessages());
  }

  public async loadStaticData(): Promise<void> {
    await this.loadSessionInfo();
    if (!this.moduleCompanyViewSubject) {
      this.loadModuleCompanyView();
    }
    await this.loadUnitTypes();
  }

  private async loadSessionInfo(): Promise<void> {
    const data: IApiUser = await this.dataService.Auth_GetSessionInfo().toPromise();
    Global.SESSION = data;
    Raven.setExtraContext({
      AccountId: data.AccountId,
      AccountRoleId: data.AccountRoleId,
      CurrentCompanyId: data.CurrentCompany.Id,
      CurrentCompanyName: data.CurrentCompany.Name,
      CurrentRoleId: data.CurrentRole.Id,
      UserId: data.Id,
      FullName: data.FullName,
      HasBackendAccess: data.HasBackendAccess,
      IsAccountAdmin: data.IsAccountAdmin,
      IsAccountant: data.IsAccountant,
      IsGratisalSupportUser: data.IsGratisalSupportUser,
      IsPaymentApprover: data.IsPaymentApprover
    });

    if (!this.enabledModulesSubject) {
      this.enabledModulesSubject = new BehaviorSubject<IModule[]>([]);
    }

    this.enabledModulesSubject.next(data.Modules);
    const shortCultureCode: string = data.SignOnToken.Language.CultureCode.substr(0, 2);
    await this.translateService.use(shortCultureCode).toPromise();
    this.sessionService.applyLocale(shortCultureCode);

    await this.getStartupTasks();
    await this.translateErrorMessages();
    await this.loadCompanyPreferences().toPromise();
    await this.loadUserPreferences().toPromise();
    await this.checkProduction().toPromise();

    if (Global.SESSION && Global.SESSION.SignOnToken.LanguageId) {
      try {
        document.cookie = 'languageId=' + Global.SESSION.SignOnToken.LanguageId;
      } catch (e) {
        this.sessionService.isDetectedCookieDisable = true;
      }
    }
  }

  private async getStartupTasks(): Promise<void> {
    if (
      Global.SESSION.CurrentRole.Key === 'FullAdmin' &&
      Global.SESSION.CurrentCompanyUser.IsActive &&
      Global.SESSION.CurrentCompany.CountryId !== Constants.GREENLAND_COUNTRY_ID
    ) {
      const tasks: IStartupTaskCompanyView[] = await this.dataService
        .Startuptasks_GetStartupTasksWithStatus()
        .toPromise();
      Global.STARTUP_TASKS = tasks;
      this.settingsService.updateSettingsForEmployeeCompany(tasks);
    } else {
      Global.IsCompanyDataCompleted = true;
      Global.IsEmployeeTaskCompleted = true;
    }
  }

  private translations: { [key: string]: string };
  private async translateErrorMessages(): Promise<void> {
    this.translations = await this.translateService
      .get(['Error.UnExpectedErrorTitle', 'Error.UnExpectedErrorMessage'])
      .toPromise();
  }

  private configureRaven(): void {
    Raven.setDataCallback((info: any) => {
      const extra: any = info.extra;
      // We dont show unexpected for network error that already catched.
      const isConnectionError: boolean =
        info.exception &&
        info.exception.values &&
        info.exception.values.some((ex: any) => ex.value === 'GSNetworkError');
      if (
        isConnectionError ||
        (extra && extra.__serialized__ && (extra.__serialized__.status || extra.__serialized__.status === 0))
      ) {
        return info;
      }

      if (this.translations['Error.UnExpectedErrorTitle'] || this.translations['Error.UnExpectedErrorMessage']) {
        this.modalService.alert(
          this.translations['Error.UnExpectedErrorTitle'],
          this.translations['Error.UnExpectedErrorMessage']
        );
      }

      return info;
    });

    Raven.setShouldSendCallback((info: any) => {
      const extra: any = info.extra;
      if (
        extra &&
        extra.__serialized__ &&
        (extra.__serialized__.status === 400 ||
          extra.__serialized__.status === 401 ||
          extra.__serialized__.status === 404)
      ) {
        return false;
      }

      if (info.message && info.message.indexOf('a[b].target.className.indexOf') >= 0) {
        return false;
      }

      const noneExceptionMessage = 'Non-Error exception captured with keys: error, headers, message';
      if (
        info.message &&
        info.message.indexOf(noneExceptionMessage) >= 0 &&
        extra &&
        extra.__serialized__ &&
        (extra.__serialized__.status || extra.__serialized__.status === 0)
      ) {
        return false;
      }

      return (version as string) !== 'Local-build';
    });
  }

  // Setup default routes and redirects depending on enabled modules and user role.
  private registerDefaultRoutes(): void {
    this.moduleCompanyView.subscribe(() => {
      // Accountant
      this.state.registerDefault('tabs.accountant.companies');

      // Company - Approval
      this.state.registerDefault('tabs.company.approval.timeentry');

      // Company - Configuration
      this.state.registerDefault('tabs.company.configuration.hiringstatus');

      // Self Service
      this.state.registerDefault('tabs.selfservice.payslip');
      if (!this.sessionService.feature.hasModuleId(8)) {
        this.state.registerRedirect('tabs.selfservice.time', 'tabs.selfservice.payslip');
      } else {
        this.state.clearRedirect('tabs.selfservice.time');
      }
    });
  }

  // Company Preferences
  private loadCompanyPreferences(): Observable<void> {
    return this.dataService.Preferences_GetCompanyPreferences().pipe(
      map((data: ISimpleKeyValuePair[]): void => {
        Global.COMPANY_PREFERENCES = data;
      })
    );
  }

  // User Preferences
  private loadUserPreferences(): Observable<void> {
    return this.dataService.Preferences_GetUserPreferences().pipe(
      map((data: ISimpleKeyValuePair[]): void => {
        Global.USER_PREFERENCES = data;
      })
    );
  }

  private checkProduction(): Observable<void> {
    return this.dataService.Miscellaneous_IsProduction().pipe(
      map((isProduction: boolean): void => {
        Global.IsDemo = !isProduction;
      })
    );
  }

  // Modules
  private enabledModulesSubject: BehaviorSubject<IModule[]>;
  public get enabledModules(): Observable<IModule[]> {
    if (!this.enabledModulesSubject) {
      this.enabledModulesSubject = new BehaviorSubject<IModule[]>([]);
      this.loadSessionInfo();
    }

    return this.enabledModulesSubject.asObservable();
  }

  // current Logo
  private currentLogoSubject: BehaviorSubject<string>;
  public get currentLogo(): Observable<string> {
    if (!this.currentLogoSubject) {
      this.loadCurrentLogo();
    }
    return this.currentLogoSubject.asObservable();
  }

  private loadCurrentLogo(): void {
    if (!this.currentLogoSubject) {
      this.currentLogoSubject = new BehaviorSubject<string>(undefined);
    }
    this.dataService.Companies_GetCompanyLogo().subscribe((image: string): void => {
      this.currentLogoSubject.next(image);
    });
  }

  // current User Image
  private currentUserImageSubject: BehaviorSubject<string>;
  public get currentUserImage(): Observable<string> {
    if (!this.currentUserImageSubject) {
      this.loadcurrentUserImage();
    }
    return this.currentUserImageSubject.asObservable();
  }

  public loadcurrentUserImage(): void {
    if (!this.currentUserImageSubject) {
      this.currentUserImageSubject = new BehaviorSubject<string>(undefined);
    }
    this.dataService.Users_GetUserImage().subscribe((image: string): void => {
      this.currentUserImageSubject.next(image);
    });
  }

  // current Company
  private currentCompanySubject: BehaviorSubject<ICompany>;
  public get currentCompany(): Observable<ICompany> {
    if (!this.currentCompanySubject) {
      this.loadCurrentCompany();
    }
    return this.currentCompanySubject.asObservable();
  }

  private loadCurrentCompany(): void {
    if (!this.currentCompanySubject) {
      this.currentCompanySubject = new BehaviorSubject<ICompany>(undefined);
    }
    this.dataService.Companies_GetCurrent().subscribe((model: ICompany): void => {
      this.currentCompanySubject.next(model);
    });
  }

  // current Account
  private currentAccountSubject: BehaviorSubject<IAccount>;
  public get currentAccount(): Observable<IAccount> {
    if (!this.currentAccountSubject) {
      this.loadCurrentAccount();
    }
    return this.currentAccountSubject.asObservable();
  }

  private loadCurrentAccount(): void {
    if (!this.currentAccountSubject) {
      this.currentAccountSubject = new BehaviorSubject<IAccount>(undefined);
    }
    this.dataService.Account_GetCurrentAccount().subscribe((model: IAccount): void => {
      this.currentAccountSubject.next(model);
    });
  }

  // ModuleCompanyView
  private moduleCompanyViewSubject: BehaviorSubject<IModuleCompanyView[]>;
  public get moduleCompanyView(): Observable<IModuleCompanyView[]> {
    if (!this.moduleCompanyViewSubject) {
      this.loadModuleCompanyView();
    }

    return this.moduleCompanyViewSubject.asObservable();
  }

  private loadModuleCompanyView(): void {
    if (!this.moduleCompanyViewSubject) {
      this.moduleCompanyViewSubject = new BehaviorSubject<IModuleCompanyView[]>([]);
    }

    this.dataService.Modules_GetModulesForCurrentCompany().subscribe((data: IModuleCompanyView[]) => {
      this.sessionService.feature.modules = data;
      this.moduleCompanyViewSubject.next(data);
      this.registerDefaultRoutes();
    });
  }

  // Company Users
  private companyUsersSubject: BehaviorSubject<ICompanyUser[]>;
  public get companyUsers(): Observable<ICompanyUser[]> {
    if (!this.companyUsersSubject) {
      this.loadCompanyUsers();
    }

    return this.companyUsersSubject.asObservable();
  }

  public loadCompanyUsers(): void {
    if (!this.companyUsersSubject) {
      this.companyUsersSubject = new BehaviorSubject<ICompanyUser[]>([]);
    }

    this.dataService.CompanyUsers_GetCompanyUsers().subscribe((companyUsers: ICompanyUser[]): void => {
      this.companyUsersSubject.next(companyUsers);
    });
  }

  // User Companies
  private usersCompaniesSubject: BehaviorSubject<ICompanyUserView[]>;
  public get usersCompanies(): Observable<ICompanyUserView[]> {
    if (!this.usersCompaniesSubject) {
      this.loadUsersCompanies();
    }

    return this.usersCompaniesSubject.asObservable();
  }

  public loadUsersCompanies(): void {
    if (!this.usersCompaniesSubject) {
      this.usersCompaniesSubject = new BehaviorSubject<ICompanyUserView[]>([]);
    }

    this.dataService.Users_GetCompanies().subscribe((usersCompanies: ICompanyUserView[]): void => {
      this.usersCompaniesSubject.next(usersCompanies);
    });
  }

  // User Companies Simple
  private companiesSimpleSubject: BehaviorSubject<ISimpleCompany[]>;
  public get companiesSimple(): Observable<ISimpleCompany[]> {
    if (!this.companiesSimpleSubject) {
      this.loadCompaniesSimple();
    }

    return this.companiesSimpleSubject.asObservable();
  }

  public loadCompaniesSimple(): void {
    if (!this.usersCompaniesSubject) {
      this.companiesSimpleSubject = new BehaviorSubject<ISimpleCompany[]>([]);
    }

    this.dataService.Companies_GetAllSimple().subscribe((usersCompanies: ISimpleCompany[]): void => {
      this.companiesSimpleSubject.next(usersCompanies);
    });
  }

  // Bank
  private bankSubject: BehaviorSubject<IBank[]>;
  public get Bank(): Observable<IBank[]> {
    if (!this.bankSubject) {
      this.loadBank();
    }

    return this.bankSubject.asObservable();
  }

  public loadBank(): void {
    if (!this.bankSubject) {
      this.bankSubject = new BehaviorSubject<IBank[]>([]);
    }

    this.dataService.StaticData_GetBanks().subscribe((data: IBank[]): void => {
      this.bankSubject.next(data);
    });
  }

  // BankHoliday
  private bankHolidaySubject: BehaviorSubject<IBankHoliday[]>;
  public get BankHoliday(): Observable<IBankHoliday[]> {
    if (!this.bankHolidaySubject) {
      this.loadBankHoliday();
    }

    return this.bankHolidaySubject.asObservable();
  }

  public loadBankHoliday(): void {
    if (!this.bankHolidaySubject) {
      this.bankHolidaySubject = new BehaviorSubject<IBankHoliday[]>([]);
    }

    this.dataService.StaticData_GetBankHolidaies().subscribe((data: IBankHoliday[]): void => {
      this.bankHolidaySubject.next(data);
    });
  }

  // BillingPrinciple
  private billingPrincipleSubject: BehaviorSubject<IBillingPrinciple[]>;
  public get BillingPrinciple(): Observable<IBillingPrinciple[]> {
    if (!this.billingPrincipleSubject) {
      this.loadBillingPrinciple();
    }

    return this.billingPrincipleSubject.asObservable();
  }

  public loadBillingPrinciple(): void {
    if (!this.billingPrincipleSubject) {
      this.billingPrincipleSubject = new BehaviorSubject<IBillingPrinciple[]>([]);
    }

    this.dataService.StaticData_GetBillingPrinciples().subscribe((data: IBillingPrinciple[]): void => {
      this.billingPrincipleSubject.next(data);
    });
  }

  // Departments
  private departmenstSubject: BehaviorSubject<IDepartment[]>;
  public get departments(): Observable<IDepartment[]> {
    if (!this.departmenstSubject) {
      this.loadDepartments();
    }

    return this.departmenstSubject.asObservable();
  }

  public loadDepartments(): void {
    if (!this.departmenstSubject) {
      this.departmenstSubject = new BehaviorSubject<IDepartment[]>([]);
    }

    this.dataService.Companies_GetDepartments().subscribe((departments: IDepartment[]): void => {
      departments.sort((a: IDepartment, b: IDepartment) => {
        if (a.IsActive === b.IsActive) {
          if (a.Name > b.Name) {
            return 1;
          }
          if (a.Name < b.Name) {
            return -1;
          }

          return 0;
        }

        return a.IsActive ? 1 : 0;
      });

      departments.sort((a, b) => a.Name.localeCompare(b.Name));
      this.departmenstSubject.next(departments);
    });
  }

  // Languages
  private languagesSubject: BehaviorSubject<ILanguage[]>;
  public get languages(): Observable<ILanguage[]> {
    if (!this.languagesSubject) {
      this.loadLanguages();
    }

    return this.languagesSubject.asObservable();
  }

  private loadLanguages(): void {
    if (!this.languagesSubject) {
      this.languagesSubject = new BehaviorSubject<ILanguage[]>([]);
    }

    this.dataService.Miscellaneous_GetLanguages().subscribe((data: ILanguage[]): void => {
      this.languagesSubject.next(data);
    });
  }

  //
  // Static data
  //

  // AccountType
  private accountTypeSubject: BehaviorSubject<IAccountType[]>;
  public get AccountType(): Observable<IAccountType[]> {
    if (!this.accountTypeSubject) {
      this.loadAccountType();
    }

    return this.accountTypeSubject.asObservable();
  }

  private loadAccountType(): void {
    if (!this.accountTypeSubject) {
      this.accountTypeSubject = new BehaviorSubject<IAccountType[]>([]);
    }

    this.dataService.StaticData_GetAccountTypes().subscribe((data: IAccountType[]): void => {
      this.accountTypeSubject.next(data);
    });
  }

  // City
  private citySubject: BehaviorSubject<ICity[]>;
  public get City(): Observable<ICity[]> {
    if (!this.citySubject) {
      this.loadCity();
    }

    return this.citySubject.asObservable();
  }

  private loadCity(): void {
    if (!this.citySubject) {
      this.citySubject = new BehaviorSubject<ICity[]>([]);
    }

    this.dataService.StaticData_GetCities().subscribe((data: ICity[]): void => {
      this.citySubject.next(data);
    });
  }

  // Employement User Title
  private employmentTitleSubject: BehaviorSubject<string[]>;
  public get employmentTitle(): Observable<string[]> {
    if (!this.employmentTitleSubject) {
      this.loadEmploymentTitle();
    }
    return this.employmentTitleSubject.asObservable();
  }

  private loadEmploymentTitle(): void {
    if (!this.employmentTitleSubject) {
      this.employmentTitleSubject = new BehaviorSubject<string[]>([]);
    }

    this.dataService.Employments_GetUsedTitles().subscribe((model: string[]): void => {
      this.employmentTitleSubject.next(model);
    });
  }

  // CompanyType
  private companyTypeSubject: BehaviorSubject<ICompanyType[]>;
  public get CompanyType(): Observable<ICompanyType[]> {
    if (!this.companyTypeSubject) {
      this.loadCompanyType();
    }

    return this.companyTypeSubject.asObservable();
  }

  private loadCompanyType(): void {
    if (!this.companyTypeSubject) {
      this.companyTypeSubject = new BehaviorSubject<ICompanyType[]>([]);
    }

    this.dataService.StaticData_GetCompanyTypes().subscribe((data: ICompanyType[]): void => {
      this.companyTypeSubject.next(data);
    });
  }

  // Country
  private countrySubject: BehaviorSubject<ICountry[]>;
  public get Country(): Observable<ICountry[]> {
    if (!this.countrySubject) {
      this.loadCountry();
    }

    return this.countrySubject.asObservable();
  }

  private loadCountry(): void {
    if (!this.countrySubject) {
      this.countrySubject = new BehaviorSubject<ICountry[]>([]);
    }

    this.dataService.StaticData_GetCountries().subscribe((data: ICountry[]): void => {
      this.countrySubject.next(data);
    });
  }

  // Currency
  private currencySubject: BehaviorSubject<ICurrency[]>;
  public get Currency(): Observable<ICurrency[]> {
    if (!this.currencySubject) {
      this.loadCurrency();
    }

    return this.currencySubject.asObservable();
  }

  private loadCurrency(): void {
    if (!this.currencySubject) {
      this.currencySubject = new BehaviorSubject<ICurrency[]>([]);
    }

    this.dataService.StaticData_GetCurrencies().subscribe((data: ICurrency[]): void => {
      this.currencySubject.next(data);
    });
  }

  // ExternalSystem
  private externalSystemSubject: BehaviorSubject<IExternalSystem[]>;
  public get ExternalSystem(): Observable<IExternalSystem[]> {
    if (!this.externalSystemSubject) {
      this.loadExternalSystem();
    }

    return this.externalSystemSubject.asObservable();
  }

  private loadExternalSystem(): void {
    if (!this.externalSystemSubject) {
      this.externalSystemSubject = new BehaviorSubject<IExternalSystem[]>([]);
    }

    this.dataService.StaticData_GetExternalSystems().subscribe((data: IExternalSystem[]): void => {
      this.externalSystemSubject.next(data);
    });
  }

  // ExternalSystemConfiguration
  private externalSystemConfigurationSubject: BehaviorSubject<IExternalSystemConfiguration[]>;
  public get ExternalSystemConfiguration(): Observable<IExternalSystemConfiguration[]> {
    if (!this.externalSystemConfigurationSubject) {
      this.loadExternalSystemConfiguration();
    }

    return this.externalSystemConfigurationSubject.asObservable();
  }

  public loadExternalSystemConfiguration(): void {
    if (!this.externalSystemConfigurationSubject) {
      this.externalSystemConfigurationSubject = new BehaviorSubject<IExternalSystemConfiguration[]>([]);
    }

    this.dataService
      .StaticData_GetExternalSystemConfigurations()
      .subscribe((data: IExternalSystemConfiguration[]): void => {
        this.externalSystemConfigurationSubject.next(data);
      });
  }

  // ExternalSystemCredential
  private externalSystemCredentialSubject: BehaviorSubject<IExternalSystemCredential[]>;
  public get ExternalSystemCredential(): Observable<IExternalSystemCredential[]> {
    if (!this.externalSystemCredentialSubject) {
      this.loadExternalSystemCredential();
    }

    return this.externalSystemCredentialSubject.asObservable();
  }

  public loadExternalSystemCredential(): void {
    if (!this.externalSystemCredentialSubject) {
      this.externalSystemCredentialSubject = new BehaviorSubject<IExternalSystemCredential[]>([]);
    }

    this.dataService.StaticData_GetExternalSystemCredentials().subscribe((data: IExternalSystemCredential[]): void => {
      this.externalSystemCredentialSubject.next(data);
    });
  }

  // ExternalSystemOperation
  private externalSystemOperationSubject: BehaviorSubject<IExternalSystemOperation[]>;
  public get ExternalSystemOperation(): Observable<IExternalSystemOperation[]> {
    if (!this.externalSystemOperationSubject) {
      this.loadExternalSystemOperation();
    }

    return this.externalSystemOperationSubject.asObservable();
  }

  private loadExternalSystemOperation(): void {
    if (!this.externalSystemOperationSubject) {
      this.externalSystemOperationSubject = new BehaviorSubject<IExternalSystemOperation[]>([]);
    }

    this.dataService.StaticData_GetExternalSystemOperations().subscribe((data: IExternalSystemOperation[]): void => {
      this.externalSystemOperationSubject.next(data);
    });
  }

  // FinanceAccountType
  private financeAccountTypeSubject: BehaviorSubject<IFinanceAccountType[]>;
  public get FinanceAccountType(): Observable<IFinanceAccountType[]> {
    if (!this.financeAccountTypeSubject) {
      this.loadFinanceAccountType();
    }

    return this.financeAccountTypeSubject.asObservable();
  }

  private loadFinanceAccountType(): void {
    if (!this.financeAccountTypeSubject) {
      this.financeAccountTypeSubject = new BehaviorSubject<IFinanceAccountType[]>([]);
    }

    this.dataService.StaticData_GetFinanceAccountTypes().subscribe((data: IFinanceAccountType[]): void => {
      this.financeAccountTypeSubject.next(data);
    });
  }

  // ImportSpecification
  private importSpecificationSubject: BehaviorSubject<IImportSpecification[]>;
  public get ImportSpecification(): Observable<IImportSpecification[]> {
    if (!this.importSpecificationSubject) {
      this.loadImportSpecification();
    }

    return this.importSpecificationSubject.asObservable();
  }

  private loadImportSpecification(): void {
    if (!this.importSpecificationSubject) {
      this.importSpecificationSubject = new BehaviorSubject<IImportSpecification[]>([]);
    }

    this.dataService.StaticData_GetImportSpecifications().subscribe((data: IImportSpecification[]): void => {
      this.importSpecificationSubject.next(data);
    });
  }

  // IncomeType
  private incomeTypeSubject: BehaviorSubject<IIncomeType[]>;
  public get IncomeType(): Observable<IIncomeType[]> {
    if (!this.incomeTypeSubject) {
      this.loadIncomeType();
    }

    return this.incomeTypeSubject.asObservable();
  }

  private loadIncomeType(): void {
    if (!this.incomeTypeSubject) {
      this.incomeTypeSubject = new BehaviorSubject<IIncomeType[]>([]);
    }

    this.dataService.StaticData_GetIncomeTypes().subscribe((data: IIncomeType[]): void => {
      this.incomeTypeSubject.next(data);
    });
  }

  // IntervalType
  private intervalTypeSubject: BehaviorSubject<IIntervalType[]>;
  public get IntervalType(): Observable<IIntervalType[]> {
    if (!this.intervalTypeSubject) {
      this.loadIntervalType();
    }

    return this.intervalTypeSubject.asObservable();
  }

  private loadIntervalType(): void {
    if (!this.intervalTypeSubject) {
      this.intervalTypeSubject = new BehaviorSubject<IIntervalType[]>([]);
    }

    this.dataService.StaticData_GetIntervalTypes().subscribe((data: IIntervalType[]): void => {
      this.intervalTypeSubject.next(data);
    });
  }

  // IntegrationStatus
  private integrationStatusSubject: BehaviorSubject<IIntegrationStatus[]>;
  public get IntegrationStatus(): Observable<IIntegrationStatus[]> {
    if (!this.integrationStatusSubject) {
      this.loadIntegrationStatus();
    }

    return this.integrationStatusSubject.asObservable();
  }

  private loadIntegrationStatus(): void {
    if (!this.integrationStatusSubject) {
      this.integrationStatusSubject = new BehaviorSubject<IIntegrationStatus[]>([]);
    }

    this.dataService.StaticData_GetIntegrationStatuses().subscribe((data: IIntegrationStatus[]): void => {
      this.integrationStatusSubject.next(data);
    });
  }

  // ModulePackage
  private modulePackageSubject: BehaviorSubject<IModulePackage[]>;
  public get ModulePackage(): Observable<IModulePackage[]> {
    if (!this.modulePackageSubject) {
      this.loadModulePackage();
    }

    return this.modulePackageSubject.asObservable();
  }

  private loadModulePackage(): void {
    if (!this.modulePackageSubject) {
      this.modulePackageSubject = new BehaviorSubject<IModulePackage[]>([]);
    }

    this.dataService.StaticData_GetModulePackages().subscribe((data: IModulePackage[]): void => {
      this.modulePackageSubject.next(data);
    });
  }

  // Month
  private monthSubject: BehaviorSubject<IMonth[]>;
  public get Month(): Observable<IMonth[]> {
    if (!this.monthSubject) {
      this.loadMonth();
    }

    return this.monthSubject.asObservable();
  }

  private loadMonth(): void {
    if (!this.monthSubject) {
      this.monthSubject = new BehaviorSubject<IMonth[]>([]);
    }

    this.dataService.StaticData_GetMonths().subscribe((data: IMonth[]): void => {
      this.monthSubject.next(data);
    });
  }

  // Municipality
  private municipalitySubject: BehaviorSubject<IMunicipality[]>;
  public get Municipality(): Observable<IMunicipality[]> {
    if (!this.municipalitySubject) {
      this.loadMunicipality();
    }

    return this.municipalitySubject.asObservable();
  }

  private loadMunicipality(): void {
    if (!this.municipalitySubject) {
      this.municipalitySubject = new BehaviorSubject<IMunicipality[]>([]);
    }

    this.dataService.StaticData_GetMunicipalities().subscribe((data: IMunicipality[]): void => {
      this.municipalitySubject.next(data);
    });
  }

  // OvertimeSupplementPrinciple
  private overtimeSupplementPrincipleSubject: BehaviorSubject<IOvertimeSupplementPrinciple[]>;
  public get OvertimeSupplementPrinciple(): Observable<IOvertimeSupplementPrinciple[]> {
    if (!this.overtimeSupplementPrincipleSubject) {
      this.loadOvertimeSupplementPrinciple();
    }

    return this.overtimeSupplementPrincipleSubject.asObservable();
  }

  private loadOvertimeSupplementPrinciple(): void {
    if (!this.overtimeSupplementPrincipleSubject) {
      this.overtimeSupplementPrincipleSubject = new BehaviorSubject<IOvertimeSupplementPrinciple[]>([]);
    }

    this.dataService
      .StaticData_GetOvertimeSupplementPrinciples()
      .subscribe((data: IOvertimeSupplementPrinciple[]): void => {
        this.overtimeSupplementPrincipleSubject.next(data);
      });
  }

  // PaymentMethod
  private paymentMethodSubject: BehaviorSubject<IPaymentMethod[]>;
  public get PaymentMethod(): Observable<IPaymentMethod[]> {
    if (!this.paymentMethodSubject) {
      this.loadPaymentMethod();
    }

    return this.paymentMethodSubject.asObservable();
  }

  private loadPaymentMethod(): void {
    if (!this.paymentMethodSubject) {
      this.paymentMethodSubject = new BehaviorSubject<IPaymentMethod[]>([]);
    }

    this.dataService.StaticData_GetPaymentMethods().subscribe((data: IPaymentMethod[]): void => {
      this.paymentMethodSubject.next(data);
    });
  }

  // PensionBaseCalculationMethod
  private pensionBaseCalculationMethodSubject: BehaviorSubject<IPensionBaseCalculationMethod[]>;
  public get PensionBaseCalculationMethod(): Observable<IPensionBaseCalculationMethod[]> {
    if (!this.pensionBaseCalculationMethodSubject) {
      this.loadPensionBaseCalculationMethod();
    }

    return this.pensionBaseCalculationMethodSubject.asObservable();
  }

  private loadPensionBaseCalculationMethod(): void {
    if (!this.pensionBaseCalculationMethodSubject) {
      this.pensionBaseCalculationMethodSubject = new BehaviorSubject<IPensionBaseCalculationMethod[]>([]);
    }

    this.dataService
      .StaticData_GetPensionBaseCalculationMethods()
      .subscribe((data: IPensionBaseCalculationMethod[]): void => {
        this.pensionBaseCalculationMethodSubject.next(data);
      });
  }

  // PensionProvider
  private pensionProviderSubject: BehaviorSubject<IPensionProvider[]>;
  public get PensionProvider(): Observable<IPensionProvider[]> {
    if (!this.pensionProviderSubject) {
      this.loadPensionProvider();
    }

    return this.pensionProviderSubject.asObservable();
  }

  private loadPensionProvider(): void {
    if (!this.pensionProviderSubject) {
      this.pensionProviderSubject = new BehaviorSubject<IPensionProvider[]>([]);
    }

    this.dataService.StaticData_GetPensionProviders().subscribe((data: IPensionProvider[]): void => {
      this.pensionProviderSubject.next(data);
    });
  }

  // ReportArea
  private reportAreaSubject: BehaviorSubject<IReportArea[]>;
  public get ReportArea(): Observable<IReportArea[]> {
    if (!this.reportAreaSubject) {
      this.loadReportArea();
    }

    return this.reportAreaSubject.asObservable();
  }

  private loadReportArea(): void {
    if (!this.reportAreaSubject) {
      this.reportAreaSubject = new BehaviorSubject<IReportArea[]>([]);
    }

    this.dataService.StaticData_GetReportAreas().subscribe((data: IReportArea[]): void => {
      this.reportAreaSubject.next(data);
    });
  }

  // ReportParameter
  private reportParameterSubject: BehaviorSubject<IReportParameter[]>;
  public get ReportParameter(): Observable<IReportParameter[]> {
    if (!this.reportParameterSubject) {
      this.loadReportParameter();
    }

    return this.reportParameterSubject.asObservable();
  }

  private loadReportParameter(): void {
    if (!this.reportParameterSubject) {
      this.reportParameterSubject = new BehaviorSubject<IReportParameter[]>([]);
    }

    this.dataService.StaticData_GetReportParameters().subscribe((data: IReportParameter[]): void => {
      this.reportParameterSubject.next(data);
    });
  }

  // Role
  private roleSubject: BehaviorSubject<IRole[]>;
  public get Role(): Observable<IRole[]> {
    if (!this.roleSubject) {
      this.loadRole();
    }

    return this.roleSubject.asObservable();
  }

  private loadRole(): void {
    if (!this.roleSubject) {
      this.roleSubject = new BehaviorSubject<IRole[]>([]);
    }

    this.dataService.StaticData_GetRoles().subscribe((data: IRole[]): void => {
      this.roleSubject.next(data);
    });
  }

  // SalaryCycle
  private salaryCycleSubject: BehaviorSubject<ISalaryCycle[]>;
  public get SalaryCycle(): Observable<ISalaryCycle[]> {
    if (!this.salaryCycleSubject) {
      this.loadSalaryCycle();
    }

    return this.salaryCycleSubject.asObservable();
  }

  private loadSalaryCycle(): void {
    if (!this.salaryCycleSubject) {
      this.salaryCycleSubject = new BehaviorSubject<ISalaryCycle[]>([]);
    }

    this.dataService.StaticData_GetSalaryCycles().subscribe((data: ISalaryCycle[]): void => {
      this.salaryCycleSubject.next(data);
    });
  }

  // SalarySummaryCategory
  private salarySummaryCategorySubject: BehaviorSubject<ISalarySummaryCategory[]>;
  public get SalarySummaryCategory(): Observable<ISalarySummaryCategory[]> {
    if (!this.salarySummaryCategorySubject) {
      this.loadSalarySummaryCategory();
    }

    return this.salarySummaryCategorySubject.asObservable();
  }

  private loadSalarySummaryCategory(): void {
    if (!this.salarySummaryCategorySubject) {
      this.salarySummaryCategorySubject = new BehaviorSubject<ISalarySummaryCategory[]>([]);
    }

    this.dataService.StaticData_GetSalarySummaryCategories().subscribe((data: ISalarySummaryCategory[]): void => {
      this.salarySummaryCategorySubject.next(data);
    });
  }

  // SalaryRecordPersistenceType
  private salaryRecordPersistenceTypeSubject: BehaviorSubject<ISalaryRecordPersistenceType[]>;
  public get SalaryRecordPersistenceType(): Observable<ISalaryRecordPersistenceType[]> {
    if (!this.salaryRecordPersistenceTypeSubject) {
      this.loadSalaryRecordPersistenceType();
    }

    return this.salaryRecordPersistenceTypeSubject.asObservable();
  }

  private loadSalaryRecordPersistenceType(): void {
    if (!this.salaryRecordPersistenceTypeSubject) {
      this.salaryRecordPersistenceTypeSubject = new BehaviorSubject<ISalaryRecordPersistenceType[]>([]);
    }

    this.dataService
      .StaticData_GetSalaryRecordPersistenceTypes()
      .subscribe((data: ISalaryRecordPersistenceType[]): void => {
        this.salaryRecordPersistenceTypeSubject.next(data);
      });
  }

  // SalaryTypeCategory
  private salaryTypeCategorySubject: BehaviorSubject<ISalaryTypeCategory[]>;
  public get SalaryTypeCategory(): Observable<ISalaryTypeCategory[]> {
    if (!this.salaryTypeCategorySubject) {
      this.loadSalaryTypeCategory();
    }

    return this.salaryTypeCategorySubject.asObservable();
  }

  private loadSalaryTypeCategory(): void {
    if (!this.salaryTypeCategorySubject) {
      this.salaryTypeCategorySubject = new BehaviorSubject<ISalaryTypeCategory[]>([]);
    }

    this.dataService.StaticData_GetSalaryTypeCategories().subscribe((data: ISalaryTypeCategory[]): void => {
      this.salaryTypeCategorySubject.next(data);
    });
  }

  // StatisticsEmploymentPrinciple
  private statisticsEmploymentPrincipleSubject: BehaviorSubject<IStatisticsEmploymentPrinciple[]>;
  public get StatisticsEmploymentPrinciple(): Observable<IStatisticsEmploymentPrinciple[]> {
    if (!this.statisticsEmploymentPrincipleSubject) {
      this.loadStatisticsEmploymentPrinciple();
    }

    return this.statisticsEmploymentPrincipleSubject.asObservable();
  }

  private loadStatisticsEmploymentPrinciple(): void {
    if (!this.statisticsEmploymentPrincipleSubject) {
      this.statisticsEmploymentPrincipleSubject = new BehaviorSubject<IStatisticsEmploymentPrinciple[]>([]);
    }

    this.dataService
      .StaticData_GetStatisticsEmploymentPrinciples()
      .subscribe((data: IStatisticsEmploymentPrinciple[]): void => {
        this.statisticsEmploymentPrincipleSubject.next(data);
      });
  }

  // StatisticsEmploymentTerms
  private statisticsEmploymentTermsSubject: BehaviorSubject<IStatisticsEmploymentTerms[]>;
  public get StatisticsEmploymentTerms(): Observable<IStatisticsEmploymentTerms[]> {
    if (!this.statisticsEmploymentTermsSubject) {
      this.loadStatisticsEmploymentTerms();
    }

    return this.statisticsEmploymentTermsSubject.asObservable();
  }

  private loadStatisticsEmploymentTerms(): void {
    if (!this.statisticsEmploymentTermsSubject) {
      this.statisticsEmploymentTermsSubject = new BehaviorSubject<IStatisticsEmploymentTerms[]>([]);
    }

    this.dataService
      .StaticData_GetStatisticsEmploymentTermses()
      .subscribe((data: IStatisticsEmploymentTerms[]): void => {
        this.statisticsEmploymentTermsSubject.next(data);
      });
  }

  // StatisticsJobStatus
  private statisticsJobStatusSubject: BehaviorSubject<IStatisticsJobStatus[]>;
  public get StatisticsJobStatus(): Observable<IStatisticsJobStatus[]> {
    if (!this.statisticsJobStatusSubject) {
      this.loadStatisticsJobStatus();
    }

    return this.statisticsJobStatusSubject.asObservable();
  }

  private loadStatisticsJobStatus(): void {
    if (!this.statisticsJobStatusSubject) {
      this.statisticsJobStatusSubject = new BehaviorSubject<IStatisticsJobStatus[]>([]);
    }

    this.dataService.StaticData_GetStatisticsJobStatuses().subscribe((data: IStatisticsJobStatus[]): void => {
      this.statisticsJobStatusSubject.next(data);
    });
  }

  // StatisticsRecipient
  private statisticsRecipientSubject: BehaviorSubject<IStatisticsRecipient[]>;
  public get StatisticsRecipient(): Observable<IStatisticsRecipient[]> {
    if (!this.statisticsRecipientSubject) {
      this.loadStatisticsRecipient();
    }

    return this.statisticsRecipientSubject.asObservable();
  }

  private loadStatisticsRecipient(): void {
    if (!this.statisticsRecipientSubject) {
      this.statisticsRecipientSubject = new BehaviorSubject<IStatisticsRecipient[]>([]);
    }

    this.dataService.StaticData_GetStatisticsRecipients().subscribe((data: IStatisticsRecipient[]): void => {
      this.statisticsRecipientSubject.next(data);
    });
  }

  // StatisticsSalaryPrinciple
  private statisticsSalaryPrincipleSubject: BehaviorSubject<IStatisticsSalaryPrinciple[]>;
  public get StatisticsSalaryPrinciple(): Observable<IStatisticsSalaryPrinciple[]> {
    if (!this.statisticsSalaryPrincipleSubject) {
      this.loadStatisticsSalaryPrinciple();
    }

    return this.statisticsSalaryPrincipleSubject.asObservable();
  }

  private loadStatisticsSalaryPrinciple(): void {
    if (!this.statisticsSalaryPrincipleSubject) {
      this.statisticsSalaryPrincipleSubject = new BehaviorSubject<IStatisticsSalaryPrinciple[]>([]);
    }

    this.dataService
      .StaticData_GetStatisticsSalaryPrinciples()
      .subscribe((data: IStatisticsSalaryPrinciple[]): void => {
        this.statisticsSalaryPrincipleSubject.next(data);
      });
  }

  // TaxCardType
  private taxCardTypeSubject: BehaviorSubject<ITaxCardType[]>;
  public get TaxCardType(): Observable<ITaxCardType[]> {
    if (!this.taxCardTypeSubject) {
      this.loadTaxCardType();
    }

    return this.taxCardTypeSubject.asObservable();
  }

  private loadTaxCardType(): void {
    if (!this.taxCardTypeSubject) {
      this.taxCardTypeSubject = new BehaviorSubject<ITaxCardType[]>([]);
    }

    this.dataService.StaticData_GetTaxCardTypes().subscribe((data: ITaxCardType[]): void => {
      this.taxCardTypeSubject.next(data);
    });
  }

  // TimeEntryRecordAcrossSalaryPeriodStrategy
  private timeEntryRecordAcrossSalaryPeriodStrategySubject: BehaviorSubject<
    ITimeEntryRecordAcrossSalaryPeriodStrategy[]
  >;
  public get TimeEntryRecordAcrossSalaryPeriodStrategy(): Observable<ITimeEntryRecordAcrossSalaryPeriodStrategy[]> {
    if (!this.timeEntryRecordAcrossSalaryPeriodStrategySubject) {
      this.loadTimeEntryRecordAcrossSalaryPeriodStrategy();
    }

    return this.timeEntryRecordAcrossSalaryPeriodStrategySubject.asObservable();
  }

  private loadTimeEntryRecordAcrossSalaryPeriodStrategy(): void {
    if (!this.timeEntryRecordAcrossSalaryPeriodStrategySubject) {
      this.timeEntryRecordAcrossSalaryPeriodStrategySubject = new BehaviorSubject<
        ITimeEntryRecordAcrossSalaryPeriodStrategy[]
      >([]);
    }

    this.dataService
      .StaticData_GetTimeEntryRecordAcrossSalaryPeriodStrategies()
      .subscribe((data: ITimeEntryRecordAcrossSalaryPeriodStrategy[]): void => {
        this.timeEntryRecordAcrossSalaryPeriodStrategySubject.next(data);
      });
  }

  // TimeEntryStatus
  private timeEntryStatusSubject: BehaviorSubject<ITimeEntryStatus[]>;
  public get TimeEntryStatus(): Observable<ITimeEntryStatus[]> {
    if (!this.timeEntryStatusSubject) {
      this.loadTimeEntryStatus();
    }

    return this.timeEntryStatusSubject.asObservable();
  }

  private loadTimeEntryStatus(): void {
    if (!this.timeEntryStatusSubject) {
      this.timeEntryStatusSubject = new BehaviorSubject<ITimeEntryStatus[]>([]);
    }

    this.dataService.StaticData_GetTimeEntryStatuses().subscribe((data: ITimeEntryStatus[]): void => {
      this.timeEntryStatusSubject.next(data);
    });
  }

  // SalaryRecordsOverviewDisplayMode
  private salaryRecordsOverviewDisplayModeSubject: BehaviorSubject<ISalaryRecordsOverviewDisplayMode[]>;
  public get SalaryRecordsOverviewDisplayMode(): Observable<ISalaryRecordsOverviewDisplayMode[]> {
    if (!this.salaryRecordsOverviewDisplayModeSubject) {
      this.loadSalaryRecordsOverviewDisplayMode();
    }

    return this.salaryRecordsOverviewDisplayModeSubject.asObservable();
  }

  private loadSalaryRecordsOverviewDisplayMode(): void {
    if (!this.salaryRecordsOverviewDisplayModeSubject) {
      this.salaryRecordsOverviewDisplayModeSubject = new BehaviorSubject<ISalaryRecordsOverviewDisplayMode[]>([]);
    }

    this.dataService
      .StaticData_GetSalaryRecordsOverviewDisplayModes()
      .subscribe((data: ISalaryRecordsOverviewDisplayMode[]): void => {
        this.salaryRecordsOverviewDisplayModeSubject.next(data);
      });
  }

  // UnitTypes
  private unitTypesSubject: BehaviorSubject<IUnitType[]>;
  public get UnitTypes(): Observable<IUnitType[]> {
    if (!this.unitTypesSubject) {
      this.loadUnitTypes();
    }

    return this.unitTypesSubject.asObservable();
  }

  private loadUnitTypes(): void {
    if (!this.unitTypesSubject) {
      this.unitTypesSubject = new BehaviorSubject<IUnitType[]>([]);
    }

    this.dataService.StaticData_GetUnitTypes().subscribe((data: IUnitType[]): void => {
      this.unitTypesSubject.next(data);
      Global.UNIT_TYPES = data;
    });
  }

  // VacationProvider
  private vacationProviderSubject: BehaviorSubject<IVacationProvider[]>;
  public get VacationProvider(): Observable<IVacationProvider[]> {
    if (!this.vacationProviderSubject) {
      this.loadVacationProvider();
    }

    return this.vacationProviderSubject.asObservable();
  }

  private loadVacationProvider(): void {
    if (!this.vacationProviderSubject) {
      this.vacationProviderSubject = new BehaviorSubject<IVacationProvider[]>([]);
    }

    this.dataService.StaticData_GetVacationProviders().subscribe((data: IVacationProvider[]): void => {
      this.vacationProviderSubject.next(data);
    });
  }

  // VacationType
  private vacationTypeSubject: BehaviorSubject<IVacationType[]>;
  public get VacationType(): Observable<IVacationType[]> {
    if (!this.vacationTypeSubject) {
      this.loadVacationType();
    }

    return this.vacationTypeSubject.asObservable();
  }

  private loadVacationType(): void {
    if (!this.vacationTypeSubject) {
      this.vacationTypeSubject = new BehaviorSubject<IVacationType[]>([]);
    }

    this.dataService.StaticData_GetVacationTypes().subscribe((data: IVacationType[]): void => {
      this.vacationTypeSubject.next(data);
    });
  }

  // VacationTypeGL
  private vacationTypeSubjectGL: BehaviorSubject<IVacationTypeGL[]>;
  public get VacationTypeGL(): Observable<IVacationTypeGL[]> {
    if (!this.vacationTypeSubjectGL) {
      this.loadVacationTypeGL();
    }

    return this.vacationTypeSubjectGL.asObservable();
  }

  private loadVacationTypeGL(): void {
    if (!this.vacationTypeSubjectGL) {
      this.vacationTypeSubjectGL = new BehaviorSubject<IVacationTypeGL[]>([]);
    }

    this.dataService.StaticData_GetVacationTypeGLs().subscribe((data: IVacationTypeGL[]): void => {
      this.vacationTypeSubjectGL.next(data);
    });
  }

  public getCurrentdate(isEnddate?: boolean, selectedDate?: Date): Date {
    let today: Date = new Date();

    if (selectedDate !== undefined) {
      today = selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
    }
    const yyyy: number = today.getFullYear();
    let mm: number = today.getMonth();
    let dd = today.getDate();
    if (isEnddate === true) {
      dd = 0;
      mm = today.getMonth() + 1;
    }

    let hour = today.getTimezoneOffset() / 60;
    hour = hour === 12 || hour === -12 ? 0 : hour;
    if (hour > 0) {
      return new Date(yyyy, mm, dd, hour, 0, 0);
    }
    return new Date(yyyy, mm, dd, -hour, 0, 0);
  }

  public dataURItoBlob(dataURI: string, type: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type });
    return blob;
  }

  public checkModelRecord(record: any) {
    Object.keys(record).forEach((key) => {
      if (record[key] === undefined) {
        record[key] = null;
      }
    });

    return record;
  }

  private observable: Observable<any>;

  public GetIntegration2Operation(
    exportNewPayrollBatchId: any,
    Integration2Operation: IIntegration[],
    integrationName: string
  ) {
    if (this.observable) {
      // if `this.observable` is set then the request is in progress
      // return the `Observable` for the ongoing request
      return this.observable;
    } else {
      // create the request, store the `Observable` for subsequent subscribers
      this.observable = this.dataService.Integrations_GetIntegrationsByOperation(exportNewPayrollBatchId).pipe(
        map((model: IIntegration[]): void => {
          Integration2Operation = model;
          if (Integration2Operation && Integration2Operation.length > 0) {
            integrationName = Integration2Operation[0].Name;
            setTimeout(() => {
              this.observable = undefined;
            }, 1000);
          }
        })
      );
      return this.observable.subscribe();
    }
  }
}
