import { TranslateService } from '@ngx-translate/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { StateService, TransitionService } from '@uirouter/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Browser } from '../../../Common/Browser';
import { Global } from '../../../Common/Global';
import { RoleFeatures } from './Features';
import { Role } from './Role';
var SessionService = /** @class */ (function () {
    function SessionService(translateService, localeId, intlService, transitionService, stateService) {
        var _this = this;
        this.translateService = translateService;
        this.localeId = localeId;
        this.intlService = intlService;
        this.transitionService = transitionService;
        this.stateService = stateService;
        this.browser = new Browser();
        this.role = new Role();
        this.feature = new RoleFeatures(this.role);
        this.isSubmitting = false;
        this.isGetting = false;
        this.currentState = '';
        this.menuToggled = false;
        this.showUserMenuToggled = false;
        this.showMoreMenuToggled = false;
        this.showHelpMenuToggled = false;
        this.employeeListActive = false;
        this.currentStyleMenuTabpanelReponsive = '';
        this.isApprovalMessage = false;
        this.employeeTabState = '';
        this.companyTabState = '';
        this.selfServiceTabState = '';
        this.isShowHugeFeaturesAlert = false;
        this.nextState = '';
        this.checkIsAliveActive = false;
        this.isDetectedCookieDisable = false;
        this.checkIsAliveError = false;
        this.modalTranslationsValue = {};
        this.modalTranslationsChangeSubject = new BehaviorSubject({});
        this.translateChangeSubject = new BehaviorSubject('unknown');
        this.locationChangedSubject = new BehaviorSubject('');
        this.locationChangeStartSubject = new BehaviorSubject({});
        this.isAuthenticationKey = 'isAuthenticationKey';
        this.isAliveSubject = new Subject();
        this.requestLog = [];
        this.requestLogEnabledValue = undefined;
        this.OnTranslateChanged.subscribe(function (locale) {
            if (locale === 'unknown') {
                if (!environment.production) {
                    console.log('intlService.localeId not changed to', locale);
                }
            }
            else {
                _this.localeId = _this.applyLocale(locale);
                // (this.intlService as CldrIntlService).localeId = this.localeId;
                _this.intlService.localeId = 'da'; // Apply for GS-4780
                // if (environment.environment === 'DEV') {
                //   console.log('intlService.localeId changed to', locale);
                // }
            }
        });
        this.transitionService.onSuccess({}, function (transition) {
            _this.currentState = transition.to().name;
            if (_this.currentState !== 'tabs.employee' && _this.currentState.includes('tabs.employee')) {
                _this.employeeTabState = _this.currentState;
            }
            else if (_this.currentState !== 'tabs.company' && _this.currentState.includes('tabs.company')) {
                _this.companyTabState = _this.currentState;
            }
            else if (_this.currentState !== 'tabs.selfservice' && _this.currentState.includes('tabs.selfservice')) {
                _this.selfServiceTabState = _this.currentState;
            }
            // if (environment.environment === 'DEV') {
            //   console.log('state: ', this.currentState);
            // }
            _this.locationChangedSubject.next(_this.currentState);
        });
        this.transitionService.onStart({}, function (transition) {
            _this.locationChangeStartSubject.next(transition);
        });
        this.translateService.onTranslationChange.subscribe(function (event) {
            _this.translateChangeSubject.next(event.lang);
            // if (environment.environment === 'DEV') {
            //   console.log('ngx onTranslationChange', event.lang);
            // }
        });
        this.translateService.onLangChange.subscribe(function (event) {
            _this.translateChangeSubject.next(event.lang);
            // if (environment.environment === 'DEV') {
            //   console.log('ngx onLangChange', event.lang);
            // }
        });
        this.translateService.onDefaultLangChange.subscribe(function (event) {
            _this.translateChangeSubject.next(event.lang);
            // if (environment.environment === 'DEV') {
            //   console.log('ngx onDefaultLangChange', event.lang);
            // }
        });
    }
    Object.defineProperty(SessionService.prototype, "OnModalTranslateChanged", {
        get: function () {
            return this.modalTranslationsChangeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "modalTranslations", {
        get: function () {
            return this.modalTranslationsValue;
        },
        set: function (value) {
            this.modalTranslationsValue = value;
            this.modalTranslationsChangeSubject.next({});
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "OnTranslateChanged", {
        get: function () {
            return this.translateChangeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "OnLocationChanged", {
        get: function () {
            return this.locationChangedSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "OnLocationChangeStart", {
        get: function () {
            return this.locationChangeStartSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "isAlive", {
        get: function () {
            return this.isAliveSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "requestLogEnabled", {
        get: function () {
            if (this.requestLogEnabledValue === undefined) {
                var value = localStorage.getItem('requestLogEnabled');
                this.requestLogEnabledValue = value ? JSON.parse(value) : false;
            }
            return this.requestLogEnabledValue;
        },
        set: function (value) {
            this.requestLogEnabledValue = value;
            localStorage.setItem('requestLogEnabled', JSON.stringify(value));
        },
        enumerable: true,
        configurable: true
    });
    SessionService.prototype.logRequest = function (entry) {
        this.requestLog.push(entry);
        if (this.requestLog.length > 100) {
            this.requestLog.shift();
        }
        console.log(entry);
    };
    SessionService.prototype.applyLocale = function (cultureCode) {
        var locale;
        if (cultureCode) {
            cultureCode = cultureCode.substring(0, 2);
            switch (cultureCode) {
                case 'kl':
                    locale = 'da-GL';
                    this.dateFormat = 'dd/MM/yyyy';
                    break;
                case 'en':
                    locale = 'en-GB';
                    this.dateFormat = 'dd/MM/yyyy';
                    break;
                default:
                    locale = 'da';
                    this.dateFormat = 'dd-MM-yyyy';
                    break;
            }
        }
        return locale;
    };
    SessionService.prototype.toString = function (value, format) {
        if (typeof value === 'number') {
            return this.intlService.toString(value, format ? format : 'n2', 'da');
        }
        return this.intlService.toString(value, format ? format : this.dateFormat);
    };
    SessionService.prototype.parseDate = function (value, format) {
        return this.intlService.parseDate(value, format);
    };
    SessionService.prototype.parseFloat = function (value) {
        return this.intlService.parseNumber(value);
    };
    SessionService.prototype.parseInt = function (value) {
        return this.intlService.parseNumber(value, { maximumFractionDigits: 0 });
    };
    SessionService.prototype.NavigateTo = function (state) {
        if (Global.SESSION && Global.SESSION.CurrentRole) {
            var currentRoleKey = Global.SESSION.CurrentRole.Key;
            if (currentRoleKey === 'Employee') {
                if (state.includes('tabs.company')) {
                    if (Global.SESSION.ManagerForUserEmploymentIds.length > 0) {
                        this.stateService.go('tabs.company.approval.timeentry');
                        return;
                    }
                    this.stateService.go('tabs.selfservice.payslip');
                    return;
                }
                if (state.includes('tabs.employee')) {
                    var hasManager = this.role.EmployeeWithRoleAsManager;
                    if (hasManager) {
                        this.stateService.go('tabs.employee.time');
                        return;
                    }
                    else {
                        this.stateService.go('tabs.selfservice.payslip');
                        return;
                    }
                }
            }
        }
        this.stateService.go(state);
    };
    SessionService.prototype.NavigateToAppUri = function (uri) {
        var stateName = this.getStateByUri(uri);
        if (stateName && this.currentState !== stateName) {
            this.NavigateTo(stateName);
            return true;
        }
        return false;
    };
    SessionService.prototype.getStateByUri = function (uri) {
        var matchedState;
        var allStates = this.stateService.get();
        if (!uri || !allStates || allStates.length === 0) {
            return undefined;
        }
        for (var index = 0; index < allStates.length; index++) {
            var absState = this.stateService.href(allStates[index], undefined, { absolute: true });
            if (absState && absState.indexOf(uri) >= 0) {
                matchedState = allStates[index].name;
                break;
            }
        }
        return matchedState;
    };
    Object.defineProperty(SessionService.prototype, "IsAuthenticated", {
        get: function () {
            try {
                var value = sessionStorage.getItem(this.isAuthenticationKey);
                return value === 'true';
            }
            catch (e) {
                this.isDetectedCookieDisable = true;
                return false;
            }
        },
        set: function (isAuthenticate) {
            try {
                var value = isAuthenticate ? 'true' : 'false';
                sessionStorage.setItem(this.isAuthenticationKey, value);
            }
            catch (e) {
                this.isDetectedCookieDisable = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "LanguageCode", {
        get: function () {
            return Global.SESSION && Global.SESSION.SignOnToken.Language
                ? Global.SESSION.SignOnToken.Language.CultureCode.split('-')[0]
                : undefined;
        },
        enumerable: true,
        configurable: true
    });
    SessionService.prototype.ResetIsAliveTimer = function () {
        var _this = this;
        if (this.isAliveTimerId) {
            clearInterval(this.isAliveTimerId);
            this.isAliveTimerId = undefined;
        }
        this.isAliveTimerId = setInterval(function () {
            if (_this.currentState !== 'login' && _this.IsAuthenticated) {
                _this.isAliveSubject.next();
            }
        }, 60000);
    };
    SessionService.prototype.checkNavigateDefaultRole = function (isEmployeeState, isCompanyState) {
        if (isEmployeeState === void 0) { isEmployeeState = false; }
        if (isCompanyState === void 0) { isCompanyState = false; }
        var currentRoleKey = Global.SESSION.CurrentRole.Key;
        if (currentRoleKey) {
            if (isEmployeeState) {
                switch (currentRoleKey) {
                    case 'FullAdmin':
                        if (Global.IsEmployeeTaskCompleted) {
                            this.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
                            this.NavigateTo('tabs.employee.payrolldata');
                        }
                        else {
                            this.currentStyleMenuTabpanelReponsive = '';
                            this.NavigateTo('tabs.employee.general');
                        }
                        break;
                    case 'ReadOnly':
                        this.NavigateTo('tabs.employee.payrolldata');
                        break;
                    case 'Employee':
                        if (this.role.EmployeeWithRoleAsManager) {
                            this.NavigateTo('tabs.employee.time');
                        }
                        this.NavigateTo('tabs.selfservice.payslip');
                        break;
                    case 'SalaryAdmin':
                        this.NavigateTo('tabs.employee.payrolldata');
                        break;
                    default:
                        break;
                }
            }
            if (isCompanyState) {
                switch (currentRoleKey) {
                    case 'FullAdmin':
                        this.NavigateTo('tabs.company.salarybatches');
                        break;
                    case 'ReadOnly':
                        this.NavigateTo('tabs.company.salarybatches');
                        break;
                    case 'Employee':
                        this.NavigateTo('tabs.selfservice.payslip');
                        break;
                    case 'SalaryAdmin':
                        this.NavigateTo('tabs.company.salarybatches');
                        break;
                    default:
                        break;
                }
            }
        }
    };
    SessionService.serviceId = 'SessionService';
    return SessionService;
}());
export { SessionService };
