import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UniqueId } from '../../../Common/UniqueId';
import { ImportButtonAction, ImportTypesEnum } from '../../../Model/Enum';
import { IImportMapping, IImportSpecification, ImportMapping, ISimpleImportResponse } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { ImportOptionHelper } from './ImportOptionHelper';

@Component({
  selector: 'upload-import-file-dialog',
  templateUrl: './UploadImportFileDialog.component.html'
})
export class UploadImportFileDialogComponent implements OnInit, OnDestroy {
  @Input() public importType: ImportTypesEnum;

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
      if (value) {
        this.IsPreviewExpanded = false;
        this.showDataImportDialog();
      }
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public showEmptyMessage = false;
  public previewDataHeader: any;
  public previewDataResponse: any;
  public mappingDetail: IImportMapping = new ImportMapping();
  public mappingName: string;
  public formData: FormData;
  public optionsRadioButtonName: string;
  public importResults: any;
  public importOptions: any;
  public selectedMappingId: number;
  public existingMappingsForCurrentImportType: IImportMapping[] = [];
  public startIndex: number;
  public showImportMappingDialog: boolean;
  public uploadFileName: string;
  public confirmDeleteDialogVisible = false;
  public confirmReplaceDialogVisible = false;
  public successDialogVisible = false;
  public resultfotalMessage: string;
  public successDialogFotalVisible = false;

  public get addonReadMoreVisibility(): boolean {
    return this.importType === ImportTypesEnum.Payroll && this.selectedMappingId < 0;
  }
  public get gratisalStandardTemplateColumnPreviewUnavailable(): boolean {
    return this.importType === ImportTypesEnum.Payroll && this.gratisalStandardTemplateColumnSelected;
  }
  public get gratisalStandardTemplateSelected(): boolean {
    return !this.selectedMappingId || this.selectedMappingId < 0;
  }
  public get importPayrollDataOptionsVisible(): boolean {
    return false;
  }
  public get disableEditDefinitionButton(): boolean {
    return this.gratisalStandardTemplateSelected || !this.formData;
  }
  public get importButtonEnable(): boolean {
    return !!this.formData;
  }

  private get gratisalStandardTemplateColumnSelected(): boolean {
    return this.selectedMappingId === -1;
  }

  private payrollImportColumnOperationId = 1;
  private payrollImportRowOperationId = 5;
  private timeEntriesImportRowOperationId = 6;
  private balanceImportRowOperationId: any = 0;
  private allCustomMappings: IImportMapping[];
  private allImportSpecifications: any[];
  private previousMode: any;
  private gratisalStandardTemplateText: string;
  private gratisalStandardTemplateColumnText: string;
  private gratisalStandardTemplateRowText: string;
  private addonLinkEmployees = 'http://support.gratisal.com/hc/da/articles/115003261669';
  private addonLinkBalances = 'http://support.gratisal.com/hc/da/articles/115003280345 ';
  private addonLinkPayroll =
    'https://support.gratisal.com/hc/da/articles/115003178565-Import-af-l%C3%B8ndata-fra-Excel';
  private confirmReplaceParameter: any;
  private confirmReplaceValue: any;

  constructor(
    private translateService: TranslateService,
    private dataService: RxDataService,
    public sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {}

  public ngOnInit(): void {
    this.staticDataService.ImportSpecification.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (importSpecifications: IImportSpecification[]) => (this.allImportSpecifications = importSpecifications)
    );
    this.translateText();
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) =>
      this.translateText()
    );
    this.visibleChange.subscribe(() => (this.uploadFileName = ''));
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDialogAction(action: string): void {
    switch (action) {
      case 'Edit':
        this.onEditClicked();
        break;
      case 'Delete':
        this.confirmDeleteDialogVisible = true;
        break;
      case 'Import':
        this.importMapping(ImportButtonAction.Import);
        break;
    }
  }

  public get ImportTypeForMappingEditor(): ImportTypesEnum {
    if (this.importType === ImportTypesEnum.Payroll) {
      if (this.SelectedMapping && this.SelectedMapping.OperationId === this.payrollImportRowOperationId) {
        return ImportTypesEnum.PayrollRow;
      }
    } else if (this.importType === ImportTypesEnum.TimeEntries) {
      if (this.SelectedMapping && this.SelectedMapping.OperationId === this.timeEntriesImportRowOperationId) {
        return ImportTypesEnum.TimeEntriesRow;
      }
    }

    return this.importType;
  }

  public get SelectedMapping(): IImportMapping {
    if (
      this.selectedMappingId > 0 &&
      this.existingMappingsForCurrentImportType &&
      this.existingMappingsForCurrentImportType.length > 0
    ) {
      return this.existingMappingsForCurrentImportType.find((mapp: IImportMapping) => {
        return mapp.Id === this.selectedMappingId;
      });
    }

    return undefined;
  }

  public get addonLinks(): string {
    switch (this.importType) {
      case ImportTypesEnum.Employees:
        return this.addonLinkEmployees;
      case ImportTypesEnum.Initial_Balances:
        return this.addonLinkBalances;
      case ImportTypesEnum.Payroll:
        return this.addonLinkPayroll;
      default:
        return this.addonLinkEmployees;
    }
  }

  public get OptionsRadioButtonName(): string {
    if (!this.optionsRadioButtonName) {
      this.optionsRadioButtonName = 'optionsRadioButtonName_' + UniqueId();
    }

    return this.optionsRadioButtonName;
  }

  public onSaveMapingEvent(): void {
    this.loadExistingMappingComboboxDataSource();
  }

  public onCompleteImportEvent(): void {
    this.importMapping(ImportButtonAction.CompleteImport);
  }

  public closeDialogAndFireClosedEvent(): void {
    this.visible = false;
  }

  public onComboboxSelectMappingChanged(): void {
    this.showPreviewFile();
  }

  public onConfirmDeleteDialogAction(action: string): void {
    if (action === 'Delete') {
      this.dataService
        .Import_DeleteMapping(this.selectedMappingId)
        .subscribe(() => this.onDeleteMapping(this.selectedMappingId));
    }
  }

  public onConfirmReplaceDialogAction(action: string): void {
    if (action === 'Understood') {
      if (this.confirmReplaceParameter === 'mode') {
        this.previousMode = this.confirmReplaceValue;
      }
    } else {
      const mode: any = this.importOptions.find((option: any) => option.ParameterValue === 'mode');
      const index: number = this.importOptions.indexOf(mode);
      mode.ControlValue = this.previousMode
        ? this.previousMode
        : mode.Options && mode.Options[0]
        ? mode.Options[0].RadioValue
        : undefined;
      this.importOptions[index] = mode;
    }
  }

  public onImportOptionsChanged(parameter: any, value: any): void {
    if (ImportOptionHelper.getOptionsString(this.importOptions) === 'mode=replaceall') {
      this.confirmReplaceParameter = parameter;
      this.confirmReplaceValue = value;
      this.confirmReplaceDialogVisible = true;
    } else {
      if (parameter === 'mode') {
        this.previousMode = value;
      }
    }
  }

  public onFileSelected(file: File): void {
    if (file) {
      this.formData = new FormData();
      this.formData.append('file', file, file.name);
      this.showPreviewFile();
    }
  }

  private translateText(): void {
    this.translateService
      .get([
        'CompanyDataImport.GratisalStandardTemplate',
        'CompanyDataImport.GratisalStandardTemplateColumn',
        'CompanyDataImport.GratisalStandardTemplateRow'
      ])
      .subscribe((translations: { [key: string]: string }) => {
        this.gratisalStandardTemplateText = translations['CompanyDataImport.GratisalStandardTemplate'];
        this.gratisalStandardTemplateColumnText = translations['CompanyDataImport.GratisalStandardTemplateColumn'];
        this.gratisalStandardTemplateRowText = translations['CompanyDataImport.GratisalStandardTemplateRow'];
      });
  }

  private onEditClicked(): void {
    this.dataService.Import_LoadMapping(this.selectedMappingId).subscribe((mapping: IImportMapping) => {
      this.mappingName = mapping.Name;
      this.startIndex = mapping.StartRow;
      this.mappingDetail = mapping;
      this.showImportMappingDialog = true;
    });
  }

  private loadExistingMappingComboboxDataSource(defaultMappingId?: number): void {
    this.getExistingMappingDataSource().then((importMappings: IImportMapping[]) => {
      const standardTemplateItem: IImportMapping[] = this.createStandardTemplateItem();
      importMappings = importMappings.concat(standardTemplateItem);
      this.setExistingMappingDataSource(importMappings, defaultMappingId);
      this.showPreviewFile();

      const optionKey: string = ImportOptionHelper.getImportTypeKey(this.importType);
      if (optionKey && optionKey === 'ImportEmployees') {
        // reload employee
        this.staticDataService.loadDepartments();
        this.staticDataService.loadCompanyUsers();
        this.reloadEmployeeListContent();
      }
    });
  }

  private getExistingMappingDataSource(): Promise<IImportMapping[]> {
    return new Promise<IImportMapping[]>((resolve: (value: any) => void, reject: (value: any) => void) => {
      const optionKey: string = ImportOptionHelper.getImportTypeKey(this.importType);
      // Check for existing mapping
      let concateResult: IImportMapping[] = [];
      if (this.importType === ImportTypesEnum.TimeEntries) {
        //// Split to this logic since ImportTimeEntries does not has value.
        const standardTemplateRowName: string = ImportOptionHelper.getImportTypeKey(this.importType) + 'Row';
        if (standardTemplateRowName) {
          this.getMappingsForImportType(standardTemplateRowName, false).then((result: IImportMapping[]) => {
            concateResult = concateResult.concat(result);
            return resolve(concateResult);
          });
        } else {
          return resolve(concateResult);
        }
      } else if (this.importType === ImportTypesEnum.Payroll /* || this.importType === ImportTypesEnum.TimeEntries*/) {
        this.getMappingsForImportType(optionKey)
          .then((result: IImportMapping[]) => {
            concateResult = result;
          })
          .then(() => {
            const standardTemplateRowName: string = ImportOptionHelper.getImportTypeKey(this.importType) + 'Row';
            if (standardTemplateRowName) {
              this.getMappingsForImportType(standardTemplateRowName, false).then((result: IImportMapping[]) => {
                concateResult = concateResult.concat(result);
                return resolve(concateResult);
              });
            } else {
              return resolve(concateResult);
            }
          });
      } else {
        this.getMappingsForImportType(optionKey).then((result: IImportMapping[]) => {
          return resolve(result);
        });
      }
    });
  }

  private getMappingsForImportType(operationName: string, isSetOptions: boolean = true): Promise<IImportMapping[]> {
    return new Promise<IImportMapping[]>((resolve: (value: any) => void, reject: (value: any) => void) => {
      this.dataService
        .Import_CompanyOperationMappings(operationName)
        .subscribe((existingMappings: IImportMapping[]): void => {
          this.allCustomMappings = existingMappings;
          let result: IImportMapping[] = [];
          if (existingMappings) {
            const specification: any = this.allImportSpecifications.find((item: any) => item.Key === operationName);
            if (isSetOptions) {
              this.importOptions = ImportOptionHelper.createImportOptionObjects(specification.ImportOptions);
            }

            if (specification) {
              result = existingMappings.filter(
                (item: IImportMapping) => item.CompanyId && item.OperationId === specification.Id
              );
            }
          }

          return resolve(result);
        });
    });
  }

  private getMappingStandardTemplateRow(customTemplate: boolean, action?: ImportButtonAction): void {
    const standardTemplateRowName: string = ImportOptionHelper.getImportTypeKey(this.importType) + 'Row';
    const importSpec: any = this.allImportSpecifications.find((item: any) => item.Key === standardTemplateRowName);
    let mapping: IImportMapping;
    this.dataService
      .Import_CompanyOperationMappings(standardTemplateRowName)
      .subscribe((standardRowMappings: IImportMapping[]) => {
        mapping = standardRowMappings.find(
          (item: IImportMapping) => item.OperationId === importSpec.Id && !item.CompanyId
        );
        const mappingId: number = mapping.Id;
        if (!mappingId) {
          return;
        }

        if (customTemplate) {
          this.performImportCustomTemplate(action, mappingId);
        } else {
          this.getPreviewOfFile(mappingId);
        }
      });
  }

  private getStandardTemplateMappingId(): number {
    const importMapping: IImportMapping = this.allCustomMappings.find(
      (item: IImportMapping): boolean => !item.CompanyId
    );
    return importMapping ? importMapping.Id : -1;
  }

  private setExistingMappingDataSource(importMappings: IImportMapping[], defaultMappingId?: number): void {
    this.existingMappingsForCurrentImportType = [];
    this.selectedMappingId = undefined;
    if (importMappings.length === 0) {
      return;
    }

    this.existingMappingsForCurrentImportType = importMappings;
    this.selectedMappingId = defaultMappingId ? defaultMappingId : this.existingMappingsForCurrentImportType[0].Id;
  }

  private showDataImportDialog(): void {
    this.mappingName = undefined;
    this.formData = undefined;
    this.previewDataResponse = this.previewDataHeader = undefined;
    this.showEmptyMessage = false;
    this.importOptions = undefined;
    this.loadExistingMappingComboboxDataSource();
  }

  private createStandardTemplateItem(): IImportMapping[] {
    if (this.importType === ImportTypesEnum.Payroll /* || this.importType === ImportTypesEnum.TimeEntries*/) {
      return [
        {
          Id: -2,
          Name: this.gratisalStandardTemplateRowText,
          StartRow: 1,
          OperationId: this.payrollImportRowOperationId
        },
        {
          Id: -1,
          Name: this.gratisalStandardTemplateColumnText,
          StartRow: 1,
          OperationId: this.payrollImportColumnOperationId
        }
      ];
    } else if (this.importType === ImportTypesEnum.TimeEntries) {
      //// Temporarily enable only row template.
      return [
        {
          Id: -2,
          Name: this.gratisalStandardTemplateRowText,
          StartRow: 1,
          OperationId: this.timeEntriesImportRowOperationId
        }
      ];
    } else {
      return [
        { Id: -1, Name: this.gratisalStandardTemplateText, StartRow: 1, OperationId: this.balanceImportRowOperationId }
      ];
    }
  }

  private importMapping(action: ImportButtonAction): void {
    if (this.selectedMappingId === -1) {
      this.importStandardTemplate(action);
    } else {
      this.importCustomTemplate(action);
    }
  }

  private importCustomTemplate(action: ImportButtonAction): void {
    const mappingId: number = this.selectedMappingId;
    if (mappingId === -2) {
      this.getMappingStandardTemplateRow(true, action);
    } else {
      this.performImportCustomTemplate(action, mappingId);
    }
  }

  private performImportCustomTemplate(action: ImportButtonAction, mappingId: number): void {
    const optionString: string = ImportOptionHelper.getInportOptionsString(this.importOptions, action);
    this.dataService
      .ImportMapping_CustomImportExcelOptions(mappingId, optionString, this.formData)
      .subscribe((result: any) => this.onImportDataCompleted(result, action));
  }

  private onDeleteMapping(mappingId: number): void {
    this.loadExistingMappingComboboxDataSource();
  }

  private importStandardTemplate(action: ImportButtonAction): void {
    switch (this.importType) {
      case ImportTypesEnum.Employees:
        this.postEmployeeFilledTemplate(action);
        break;

      case ImportTypesEnum.Initial_Balances:
        this.postBalanceFilledTemplate(action);
        break;

      case ImportTypesEnum.Payroll:
        this.postPayrollDataTemplate(action);
        break;
    }
  }

  private postEmployeeFilledTemplate(action: ImportButtonAction): void {
    const optionString: string = ImportOptionHelper.getInportOptionsString(this.importOptions, action);
    this.dataService
      .CompanyDataImport_PostEmployeeFilledTemplateWithOptions(this.formData, optionString)
      .subscribe((data: ISimpleImportResponse) => this.onImportDataCompleted(data, action));
  }

  private postBalanceFilledTemplate(action: ImportButtonAction): void {
    const optionString: string = ImportOptionHelper.getInportOptionsString(this.importOptions, action);
    this.dataService
      .CompanyDataImport_PostBalancesFilledTemplate(this.formData, optionString)
      .subscribe((result: any) => this.onImportDataCompleted(result, action));
  }

  private postPayrollDataTemplate(action: ImportButtonAction): void {
    const optionString: string = ImportOptionHelper.getInportOptionsString(this.importOptions, action);
    this.dataService
      .Import_PayrollDataImport(this.formData, optionString)
      .subscribe((result: any) => this.onImportDataCompleted(result, action));
  }

  private onImportDataCompleted(result: any, action: ImportButtonAction): void {
    if (this.importType === ImportTypesEnum.Employees) {
      this.staticDataService.loadDepartments();
      this.staticDataService.loadCompanyUsers();
      this.reloadEmployeeListContent();
    }

    if (action === ImportButtonAction.CompleteImport) {
      this.visible = false;
      if (result.FatalMessage) {
        this.resultfotalMessage = result.FatalMessage;
        this.successDialogFotalVisible = true;
        return;
      }
      this.successDialogVisible = true;
      return; //// don't show errors or warnings for complete import
    }

    if (!this.hasError) {
      this.successDialogVisible = true;
      return;
    }

    this.importResults = result;
  }

  private hasError(result: any): boolean {
    return result.Errors && result.Errors.length > 0;
  }

  private reloadEmployeeListContent(): void {
    this.staticDataService.loadCompanyUsers();
  }

  private showPreviewFile(): void {
    if (!this.formData) {
      this.resetPreivewOfFile();
      return;
    }

    switch (this.selectedMappingId) {
      case -1:
        if (this.importType !== ImportTypesEnum.Payroll && this.importType !== ImportTypesEnum.TimeEntries) {
          this.getPreviewOfFile(this.getStandardTemplateMappingId());
        } else {
          this.resetPreivewOfFile();
        }

        break;
      case -2:
        this.getMappingStandardTemplateRow(false);
        break;
      default:
        this.getPreviewOfFile(this.selectedMappingId);
        break;
    }
  }

  public IsPreviewExpanded = false;

  private getPreviewOfFile(mappingId: number): void {
    this.dataService.CompanyDataImport_CustomPreviewOfFile(mappingId, 4, this.formData).subscribe((response: any) => {
      if (!response || response.length <= 0) {
        this.showEmptyMessage = true;
        return;
      }

      this.showEmptyMessage = false;
      this.previewDataHeader = response.shift();
      this.previewDataResponse = response;
      if (this.previewDataHeader && this.previewDataHeader.length > 4) {
        this.IsPreviewExpanded = true;
      }
    });
  }

  private resetPreivewOfFile(): void {
    this.IsPreviewExpanded = false;
    this.previewDataResponse = this.previewDataHeader = undefined;
  }
}
