import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyAccountView } from 'src/app/Services/ApiModel';
import { RxDataService } from 'src/app/Services/RxDataService';
import { environment } from 'src/environments/environment';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { AccountantServiceBase } from '../AccountantServiceBase';
import * as i0 from "@angular/core";
import * as i1 from "../../Services/RxDataService";
import * as i2 from "../../Services/SharedServices/Session/SessionService";
var AccountantCompaniesService = /** @class */ (function (_super) {
    tslib_1.__extends(AccountantCompaniesService, _super);
    function AccountantCompaniesService(api, sessionService) {
        var _this = _super.call(this, api) || this;
        _this.api = api;
        _this.sessionService = sessionService;
        _this.company = new CompanyAccountView();
        _this.companiesSubject = new BehaviorSubject([]);
        _this.allCompanies = [];
        _this.sourceCompaniesSubject = new BehaviorSubject([]);
        return _this;
    }
    Object.defineProperty(AccountantCompaniesService.prototype, "sourceCompanies", {
        get: function () {
            return this.sourceCompaniesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantCompaniesService.prototype, "companies", {
        get: function () {
            return this.companiesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    AccountantCompaniesService.prototype.loadData = function () {
        var _this = this;
        this.api
            .Account_GetAccountOverview()
            .pipe(tap(function (d) { return d.forEach(function (rec) { return (rec.GoToIcon = 'sign-in'); }); }))
            .subscribe(function (data) {
            _this.allCompanies = data;
            _this.sourceCompaniesSubject.next(data.map(function (c) {
                return tslib_1.__assign({}, c, { Name: c.Name + " [" + c.Id + "]" });
            }));
            _this.applyFilter();
        });
    };
    AccountantCompaniesService.prototype.save = function (event) {
        var _this = this;
        if (this.company && this.company.AccountComment && this.originalComment !== this.company.AccountComment) {
            this.api
                .Account_SetAccountComment(this.selectedId, { Comment: this.company.AccountComment })
                .subscribe(function () { return (_this.originalComment = _this.company.AccountComment); });
        }
        _super.prototype.save.call(this, event);
    };
    AccountantCompaniesService.prototype.discard = function () {
        this.company.AccountComment = this.originalComment;
        _super.prototype.discard.call(this);
    };
    AccountantCompaniesService.prototype.getRoles = function () {
        var _this = this;
        if (this.selectedId) {
            this.company = this.companiesSubject.value.find(function (c) { return c.Id === _this.selectedId; });
            this.originalComment = this.company ? this.company.AccountComment : undefined;
            this.api.Account_GetUserCompanyAccess(this.selectedId).subscribe(function (data) {
                if (data && data.length > 0) {
                    if (data[0].CompanyId === _this.selectedId) {
                        _this.rolesSubject.next(data);
                    }
                    else {
                        if (!environment.production) {
                            console.error("CompanyId " + _this.selectedId + " doesnt match recieved data");
                        }
                    }
                }
                else {
                    if (!environment.production) {
                        console.log('No roles');
                    }
                }
            });
        }
    };
    AccountantCompaniesService.prototype.applyFilter = function () {
        if (this.filter) {
            this.currentPage = 0;
            var searchText_1 = this.filter.toLocaleLowerCase();
            var data = this.allCompanies.filter(function (c) {
                return (c.VATRegistrationNumber && c.VATRegistrationNumber.toLocaleLowerCase().includes(searchText_1)) ||
                    c.Name.toLocaleLowerCase().includes(searchText_1) ||
                    c.Id.toString().includes(searchText_1) ||
                    (c.MainContactFullName && c.MainContactFullName.toLocaleLowerCase().includes(searchText_1)) ||
                    (c.InvoiceEmail && c.InvoiceEmail.toLocaleLowerCase().includes(searchText_1)) ||
                    (c.AccountComment && c.AccountComment.toLocaleLowerCase().includes(searchText_1));
            });
            this.companiesSubject.next(data);
        }
        else {
            this.companiesSubject.next(this.allCompanies);
        }
        this.selectedId = undefined;
    };
    AccountantCompaniesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountantCompaniesService_Factory() { return new AccountantCompaniesService(i0.ɵɵinject(i1.RxDataService), i0.ɵɵinject(i2.SessionService)); }, token: AccountantCompaniesService, providedIn: "root" });
    return AccountantCompaniesService;
}(AccountantServiceBase));
export { AccountantCompaniesService };
