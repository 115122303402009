import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { Global } from '../../../Common/Global';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
var CompanyTimeEntryTypeTranslationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyTimeEntryTypeTranslationComponent, _super);
    function CompanyTimeEntryTypeTranslationComponent(dataService, sessionService, staticDataService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.visibleValue = false;
        _this.visibleChange = new EventEmitter();
        _this.translateComplete = new EventEmitter();
        _this.gridData = [];
        _this.inheritClass = 'grid-inherit-cell';
        _this.childIsDirty = Array(1).fill(false);
        return _this;
    }
    Object.defineProperty(CompanyTimeEntryTypeTranslationComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
                if (value) {
                    this.prepareTranslationData(this.timeEntryType);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    CompanyTimeEntryTypeTranslationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.languages = data;
        });
    };
    Object.defineProperty(CompanyTimeEntryTypeTranslationComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    CompanyTimeEntryTypeTranslationComponent.prototype.onDialogAction = function (action) {
        if (action === 'Update') {
            this.updateTimeEntryTypeTranslation();
        }
    };
    CompanyTimeEntryTypeTranslationComponent.prototype.onSaveChange = function (value) {
        this.childIsDirty[0] = true;
    };
    CompanyTimeEntryTypeTranslationComponent.prototype.prepareTranslationData = function (timeEntry) {
        var _this = this;
        this.gridData = [];
        if (!timeEntry) {
            return;
        }
        this.dataService.TimeEntryTypes_GetTranslations(timeEntry.Id).subscribe(function (data) {
            _this.gridData = data;
        });
    };
    CompanyTimeEntryTypeTranslationComponent.prototype.getFieldTranslation = function (fieldName) {
        var translationText;
        if (this.timeEntryType &&
            this.timeEntryType.TimeEntryTypeTranslations &&
            this.timeEntryType.TimeEntryTypeTranslations.length > 0) {
            var translation = this.timeEntryType.TimeEntryTypeTranslations.find(function (t) { return t.LanguageId === Global.SESSION.SignOnToken.LanguageId; });
            translationText = translation ? translation[fieldName] : undefined;
        }
        return translationText;
    };
    CompanyTimeEntryTypeTranslationComponent.prototype.getBaseTranslation = function (fieldName, languageId) {
        if (!this.timeEntryType || !this.timeEntryType.BaseTimeEntryType) {
            return undefined;
        }
        var translationText;
        if (this.timeEntryType.BaseTimeEntryType.TimeEntryTypeTranslations &&
            this.timeEntryType.BaseTimeEntryType.TimeEntryTypeTranslations.length > 0) {
            var translation = this.timeEntryType.BaseTimeEntryType.TimeEntryTypeTranslations.find(function (t) { return t.LanguageId === languageId; });
            translationText = translation ? translation[fieldName] : undefined;
        }
        return translationText ? translationText : this.timeEntryType.BaseTimeEntryType[fieldName];
    };
    CompanyTimeEntryTypeTranslationComponent.prototype.updateTimeEntryTypeTranslation = function () {
        var _this = this;
        if (!this.gridData) {
            return;
        }
        this.dataService.TimeEntryTypes_SetTranslations(this.gridData).subscribe(function (data) {
            _this.translateComplete.emit(data);
            _this.gridData = data;
            _this.visible = false;
        });
    };
    return CompanyTimeEntryTypeTranslationComponent;
}(FormComponentBase));
export { CompanyTimeEntryTypeTranslationComponent };
