<div class="FormElement">
  <grid
    [data]="gridData"
    [editMode]="false"
    [filterable]="'menu'"
    [filter]="filter"
    (filterChange)="onFilterChange($event)"
    [ngClass]="
      IsFinalizedBatchAndHaveData
        ? 'FormElement-grid Company-payslipDetailsGrid margin-bottom-1'
        : 'FormElement-grid Company-payslipsGrid margin-bottom-1'
    "
  >
    <gridColumn
      [isFilterable]="true"
      [field]="'Description'"
      [title]="'EmployeePayslip.Description' | translate"
      [width]="190"
      [type]="'text'"
    ></gridColumn>
    <gridColumn
      [isFilterable]="true"
      [field]="'Amount'"
      [title]="'EmployeePayslip.Amount' | translate"
      [width]="100"
      [type]="'numeric'"
    ></gridColumn>
  </grid>

  <button-component
    class="payslip-revert"
    [buttonClass]="'Button--card width-100'"
    *ngIf="IsFinalizedBatch && isPaymentApprover && selectedSalaryStatement"
    [label]="'CompanySalaryBatches.RevertThisPayslip' | translate"
    (click)="onRevertPayslip()"
  ></button-component>
  <button-component
    class="payslip-revert"
    [buttonClass]="'Button--card width-100'"
    *ngIf="IsFinalizedBatch && !isPaymentApprover && selectedSalaryStatement"
    [isDisabled]="true"
    [tooltip]="'RevertPayslip.NoPermission' | translate"
    [label]="'CompanySalaryBatches.RevertThisPayslip' | translate"
  ></button-component>
</div>

<revert-payslip
  [(isVisible)]="revertPayslipDialogVisible"
  [employeeId]="selectedSalaryStatement?.UserEmploymentId"
  [salaryStatement]="selectedSalaryStatement"
  (revertCompleted)="revertBatchCompleted.emit()"
></revert-payslip>
