import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DefaultAvatar } from '../../../Model/DefaultAvatar';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'app-employee-avatar',
  templateUrl: './EmployeeAvatar.component.html'
})
export class EmployeeAvatarComponent implements OnInit {
  @Input()
  public get companyUserId(): number {
    return this.userId;
  }
  public set companyUserId(value: number) {
    if (!this.userId && !value) {
      return;
    }
    if (this.userId !== value) {
      this.setNewUserAvatar(value);
    }
    this.userId = value;
  }

  @Input() public isFemale: boolean;
  @Input() public editMode: boolean;
  @Input() public avatarChange = false;

  @Output() public isAvatarChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get noAvatar(): boolean {
    return this.isNoAvatar;
  }
  public set noAvatar(value: boolean) {
    this.isNoAvatar = value;
  }

  public get maleAvatar(): string {
    return DefaultAvatar.imgMaleAvatar;
  }
  public get femaleAvatar(): string {
    return DefaultAvatar.imgFemaleAvatar;
  }

  public get userAvatar(): string {
    return this.avatar;
  }
  public get isIOSMobileApp(): boolean {
    return this.sessionService.browser.iOSMobileDevice;
  }
  public showFileSizeAlert: boolean;

  private isNoAvatar: boolean;
  @Input() public avatar: string;
  private userId: number;

  constructor(private dataService: RxDataService, private sessionService: SessionService) {}

  public ngOnInit(): void {
    this.DefaultAvatar();
  }

  public DefaultAvatar(): void {
    if (this.isNoAvatar && this.isFemale) {
      this.avatar = DefaultAvatar.imgFemaleAvatar;
    }
    if (this.isNoAvatar && !this.isFemale) {
      this.avatar = DefaultAvatar.imgMaleAvatar;
    }
  }
  private setNewUserAvatar(userId: number): void {
    if (!userId) {
      this.isNoAvatar = true;
      this.avatar = '';
      return;
    }
    this.dataService.Users_GetUserImage(userId).subscribe((data: string) => {
      if (data && data.length > 0) {
        this.avatar = data;
        this.isNoAvatar = false;
      } else {
        this.avatar = '';
        this.isNoAvatar = true;
      }
      this.isAvatarChanged.emit(true);
    });
  }

  public onAvatarChanged(formData: any): void {
    if (this.sessionService.browser.isHybridApp) {
    }
    if (Object.keys(formData).length !== 0) {
      return;
    }
    this.dataService.User_UploadEmployeeImage(formData, this.userId).subscribe(
      (data: any): void => {
        this.avatar = data;
      },
      (error: any): void => this.getCurrentAvatar()
    );
  }

  private getCurrentAvatar(): void {
    this.dataService.Companies_GetCompanyLogo().subscribe((logo: string): void => {
      this.avatar = logo;
    });
  }
}
