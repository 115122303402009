<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeGeneral.Tax Card' | translate }}</legend>

    <div *ngIf="!hasEmployment">
      <p>{{ 'EmploymentPayrollData.No Employment' | translate }}</p>
    </div>

    <div *ngIf="hasSpecialTaxCardRule">
      <p>{{ 'EmployeeGeneral.SpecialTaxBrief' | translate }}</p>
    </div>

    <div *ngIf="showTaxCardInfo">
      <div class="TaxCardTypeSection">
        <div class="TaxCardTypeSection-control" style="width: 100%;">
          <percentage-edit
            *ngIf="taxCard.taxInfo.Id"
            [ngClass]="taxCard.taxInfo.IsDummy || taxCard.taxCardRequestTime ? 'padding-left' : ''"
            [label]="'EmployeeGeneral.Tax rate' | translate"
            [editMode]="false"
            [(value)]="taxCard.taxInfo.TaxRate"
          ></percentage-edit>
        </div>
        <div *ngIf="!IsGreenlandCompany && taxCard.taxInfo.Id" class="infoIcon">
          <taxcard-addon [taxInfo]="taxCard.taxInfo" [requested]="taxCard.taxCardRequestTime"></taxcard-addon>
        </div>
      </div>

      <div class="TaxCardTypeSection">
        <div class="TaxCardTypeSection-control" [ngClass]="!!taxCard.taxInfo.Id ? 'is-wrapdiv' : ''">
          <combobox-edit-control
            [editMode]="false"
            [label]="'EmployeeGeneral.Tax card type' | translate"
            [comboboxDataSource]="taxCard.taxCardTypes"
            [textField]="'Name'"
            [idField]="'Id'"
            [nullValueLabel]="'EmployeeGeneral.No tax card' | translate"
            [(value)]="taxCard.taxInfo.TaxCardTypeId"
            [isValueIcon]="true"
          ></combobox-edit-control>
        </div>
        <div *ngIf="!IsGreenlandCompany && !taxCard.taxInfo.Id" class="infoIcon">
          <taxcard-addon [taxInfo]="taxCard.taxInfo" [requested]="taxCard.taxCardRequestTime"></taxcard-addon>
        </div>
      </div>

      <numeric-edit
        *ngIf="showFreeAmount"
        [label]="'EmployeeGeneral.Free Amount' | translate"
        [editMode]="false"
        [(value)]="taxCard.taxInfo.FreeAmount"
      ></numeric-edit>

      <numeric-edit
        *ngIf="showDeductionPerMonth"
        [label]="'EmployeeGeneral.Deduction per month' | translate"
        [editMode]="false"
        [(value)]="taxCard.taxInfo.DeductionMonth"
      ></numeric-edit>
    </div>

    <check-edit
      *ngIf="IsGreenlandCompany && taxCard.userEmploymentId"
      [label]="'NewEmployee.CalculateDanishATPandAMcontribution' | translate"
      [editMode]="editMode"
      [disable]="!isAllowEditTaxCard"
      [(isValid)]="childIsValid[0]"
      [(isDirty)]="childIsDirty[0]"
      [(value)]="userEmployment.ApplyDanishRulesInGreenland"
      (valueChange)="onControlChanged()"
    ></check-edit>

    <app-text-edit
      *ngIf="IsGreenlandCompany && taxCard.userEmploymentId"
      [label]="'EmployeeEmployment.Annual tax statement comment' | translate"
      [editMode]="editMode"
      [disabled]="!isAllowEditTaxCard"
      [type]="'text'"
      [addonIcon]="'Info'"
      [maxLength]="'165'"
      [addonTooltip]="'EmployeeEmployment.AnnualTaxCommentTooltip' | translate"
      [(isValid)]="childIsValid[1]"
      [(isDirty)]="childIsDirty[1]"
      [(value)]="userEmployment.AnnualTaxComment"
      (valueChange)="onControlChanged()"
    ></app-text-edit>

    <check-edit
      *ngIf="taxCard.userEmploymentId && !isSelfService"
      [label]="'EmployeeGeneral.AllowExtendedTaxCardDeductions' | translate"
      [editMode]="editMode && admin"
      [(isValid)]="childIsValid[2]"
      [(isDirty)]="childIsDirty[2]"
      [(value)]="userEmployment.AllowExtendedTaxCardDeductions"
      (valueChange)="onControlChanged()"
      [helpIcon]="'Info'"
      [helpLink]="'Info'"
      [selfService]="selfService"
      [isOpenLink]="false"
      [helpTooltip]="'EmployeeGeneral.helpTooltipAllowExtendedTaxCardDeductions' | translate"
    ></check-edit>

    <div [ngClass]="editMode ? 'DeductionClass' : ''">
      <numeric-edit
        *ngIf="taxCard.userEmploymentId"
        [label]="'EmployeeGeneral.ReduceTaxCardDeduction' | translate"
        [editMode]="editMode && admin"
        [(value)]="userEmployment.ReduceTaxCardDeduction"
        [(isValid)]="childIsValid[3]"
        [(isDirty)]="childIsDirty[3]"
        (valueChange)="onControlChanged()"
        [isNotNegativeValue]="true"
        [helpIcon]="'Info'"
        [helpLink]="'Info'"
        [helpTooltip]="'EmployeeGeneral.DeductionAndPercentageTooltip' | translate"
        [isOpenLink]="false"
      ></numeric-edit>

      <percentage-edit
        *ngIf="taxCard.userEmploymentId"
        [label]="'EmployeeGeneral.IncreaseTaxRatePercentage' | translate"
        [editMode]="editMode && admin"
        [(value)]="userEmployment.IncreaseTaxRatePercentage"
        [(isValid)]="childIsValid[4]"
        [(isDirty)]="childIsDirty[4]"
        (valueChange)="onControlChanged()"
        [allowDecimal]="false"
        [isSelfService]="top8px"
      ></percentage-edit>
    </div>

    <module-check [moduleId]="8" [hasModule]="false" *ngIf="hasEmployment && !IsSelfServiceGenneral">
      <check-edit
        [label]="'EmployeeGeneral.UseDailyDeductionBasedOnTimeEntries' | translate"
        [editMode]="editMode && admin"
        [(isDirty)]="childIsDirty[5]"
        [(value)]="userEmployment.UseTaxDeductionPerWorkDay"
        (valueChange)="onControlChanged()"
        [helpIcon]="'Info'"
        [helpLink]="'Info'"
        [isOpenLink]="false"
        [helpTooltip]="'EmployeeGeneral.UseDailyDeductionBasedOnTimeEntriesToolTip' | translate"
      ></check-edit>
    </module-check>

    <div class="Card-footer" *ngIf="isAllowEditTaxCard || isDanishCompanyAndInSelfServiceGenneral">
      <span *ngIf="!isDanishCompanyAndInSelfServiceGenneral" class="Control--wrapper">
        <button-component
          id="ViewTaxCardButton"
          [buttonClass]="'Button--card'"
          [isDisabled]="hasSpecialTaxCardRule"
          (click)="onViewTaxCardHistoryButtonClickEvent()"
          [label]="'EmployeeGeneral.View tax card history' | translate"
        ></button-component>
      </span>
      <span
        class="Control--wrapper"
        *ngIf="(!IsGreenlandCompany || isDanishCompanyAndInSelfServiceGenneral) && taxCard.userEmploymentId"
      >
        <button-component
          id="RequestTaxCardButton"
          [buttonClass]="'Button--card'"
          [isDisabled]="hasSpecialTaxCardRule"
          (click)="onRequestTaxCardClickEvent()"
          [label]="'EmployeeGeneral.Request tax card' | translate"
        ></button-component>
      </span>
    </div>
  </fieldset>
</form>
