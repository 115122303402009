import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../Common/FormComponentBase';
import { Global } from '../Common/Global';
import { ILanguage } from '../Services/ApiModel';
import { StaticDataService } from '../Services/SharedServices/StaticData.service';

@Component({
  selector: 'language-chooser',
  templateUrl: './LanguageChooser.component.html'
})
export class LanguageChooserComponent extends FormComponentBase implements OnInit {
  private visibleValue: boolean;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public languageChanged: EventEmitter<{ languageId: number }> = new EventEmitter<{ languageId: number }>();

  public languages: any;
  public languageId: number;

  constructor(private staticDataService: StaticDataService) {
    super();
  }

  public ngOnInit(): void {
    this.languageId = Global.SESSION ? Global.SESSION.SignOnToken.LanguageId : undefined;
    this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe((languages: ILanguage[]) => {
      this.languages =
        languages && languages.length
          ? languages.filter((language: ILanguage) => language.Id !== this.languageId)
          : undefined;
    });
  }

  public raiseLanguageChange(languageId: number): void {
    this.languageChanged.emit({ languageId });
    this.visible = false;
  }
}
