<div class="FormElement" [ngClass]="{ 'FormElement-ViewMode': editMode }">
  <span class="FormElement-label" *ngIf="isLabelHtml && label" [innerHTML]="label"></span>
  <span class="FormElement-label" *ngIf="!isLabelHtml && label">{{ label }}:</span>
  <div class="FormElement-control">
    <div class="FormElement-static" *ngIf="!editMode">{{ value | DateFormat }}</div>
    <!--<kendo-datepicker *ngIf="editMode" class="FormElement-datePicker" #kendoDatePickerControl [disabled]="disable" [(ngModel)]="value"
                          [popupSettings]="{ popupClass: 'setWidthKendoDatepicker' }" (valueChange)="onChange()" (focus)="onfocus()"
                          [format]="format">
        </kendo-datepicker>-->
    <!--
            GS-4662: Add this and disable keyboard
            [pickerType]="'calendar'"
        -->
    <dx-date-box
      [id]="e2e + '_DatePicker'"
      #dxDatePickerControl
      *ngIf="editMode"
      type="date"
      class="FormElement-datePicker"
      [(value)]="value"
      [min]="minDate"
      (onValueChanged)="onValueChanged($event)"
      [disabled]="disable"
      [displayFormat]="format"
      (onFocusIn)="onfocus($event)"
      (onFocusOut)="onBlur($event)"
      (onKeyDown)="onKeyDown($event)"
      (onClosed)="onDxDatePickerClosed($event)"
    >
    </dx-date-box>
    <div class="validation-texteditor k-widget k-tooltip k-tooltip-validation" *ngIf="showValidationMessage">
      {{ validationText }}
    </div>
  </div>
</div>
