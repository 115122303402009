import { Component, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ICompany, ISimpleCompany } from '../../Services/ApiModel';
import { ISalaryStatement } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { DownloadService } from '../../Services/SharedServices/DownloadService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'selfservicepayslip',
  templateUrl: './SelfServicePayslip.component.html'
})
export class SelfServicePayslipComponent implements OnInit {
  public salaryStatements: any[] = [];
  public previewClicked = false;
  public htmlContent = '';
  public previousBtnDisabled = false;
  public nextBtnDisabled = false;
  public paySlipPreviewDialog = false;
  public newTabBlockedDialogVisible = false;
  public currentCompany: ICompany;
  public companies: ISimpleCompany[];
  public ngUnsubscribe: Subject<{}> = new Subject();
  public isHidden = false;

  public safeContent: SafeHtml;

  public get hideColumn(): string {
    return this.previewClicked ? 'Payslip-hide-column-preview' : 'Payslip-hide-column';
  }
  public get hideColumn2(): string {
    return this.previewClicked ? 'Payslip-hide-column-2-preview' : 'Payslip-hide-column-2';
  }
  public get isCordovaApp(): boolean {
    return this.sessionService.browser.isHybridApp;
  }
  public get isMoreOneCompany(): boolean {
    if (this.companies && this.companies.length > 1) {
      return true;
    }
    return false;
  }

  public get isFirefox(): boolean {
    return this.sessionService.browser.isFirefox;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  // public get safeContent(): SafeHtml { return this.sanitizer.bypassSecurityTrustHtml(this.htmlContent); }

  private currentIndex = -1;
  private currentId: number;
  private translationServiceTerms: string[];

  private get isActive(): boolean {
    return this.sessionService.currentState === 'tabs.selfservice.payslip';
  }
  private get isAboveXLargeScreen(): boolean {
    return window.innerWidth && window.innerWidth >= 1200;
  }
  private get isUntilXLargeScreen(): boolean {
    return window.innerWidth && window.innerWidth < 1200;
  }

  constructor(
    private dataService: RxDataService,
    private settingService: SettingService,
    private downloadService: DownloadService,
    private sessionService: SessionService,
    public translateService: TranslateService,
    public renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private staticDataService: StaticDataService
  ) {
    this.translationServiceTerms = ['SelfServicePayslip.HelpSwitchCompanies'];
  }

  public ngOnInit(): void {
    if (this.isActive) {
      this.settingService.showModuleMessage('SelfService.Payslip');
      this.loadSalaryStatements();
      this.getCompanies();
    }
  }

  public onClickOutside(event: any): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      if (
        event.target &&
        event.target.classList &&
        event.target.classList.length === 3 &&
        event.target.classList[0] === 'SelfServicePayslipContainer'
      ) {
        this.removeItemSelection();
        this.previewClicked = false;
      }

      if (this.sessionService.browser.isHybridApp) {
        window.screen.orientation.unlock();
      }
    }
  }

  public onTableKeydown(event: any): void {
    if (!this.salaryStatements || this.salaryStatements.length === 0) {
      return;
    }

    if (event && event.keyCode === 38) {
      // Up arrow
      if (this.currentIndex === 0) {
        return;
      }
      this.onViewPreviousPayslipClicked();
    }

    if (event && event.keyCode === 40) {
      // Down arrow
      if (this.currentIndex === this.salaryStatements.length - 1) {
        return;
      }

      this.onViewNextPayslipClicked();
    }
  }

  public downloadSalaryStatementsPreview(salaryStatement: ISalaryStatement): void {
    const format: string = !this.isCordovaApp ? 'a4p' : 'html';
    this.dataService.SalaryStatements_GetHtml(salaryStatement.Id, format).subscribe((html: any) => {
      this.showSalaryStatementsPreviewDialog(html);
    });
  }

  public onPayslipSelected(item: any, index: number): void {
    if (this.isCordovaApp) {
      this.dataService.SalaryStatements_GetHtml(item.salaryStatement.Id, 'html').subscribe((html: any) => {
        this.showSalaryStatementsPreviewDialog(html);
      });

      return;
    }

    this.currentIndex = index;
    if (item.isSelected) {
      // Close preview
      item.isSelected = false;
      this.previewClicked = false;
    }

    this.currentId = item.salaryStatement.Id;

    // Opened on the side and auto closed when resize to isUntilXlargeScreen
    if (this.isUntilXLargeScreen && this.previewClicked) {
      // Close payslip review
      this.previewClicked = true;
      item.isSelected = true;
      this.dataService.SalaryStatements_GetHtml(item.salaryStatement.Id, 'a4p').subscribe((html: any) => {
        this.onOpenPayslipNewTab(undefined, item.salaryStatement.Id);
      });
    }

    this.htmlContent = '';
    this.removeItemSelection();

    if (this.isUntilXLargeScreen) {
      this.dataService.SalaryStatements_GetHtml(item.salaryStatement.Id, 'a4p').subscribe((html: any) => {
        this.onOpenPayslipNewTab(undefined, item.salaryStatement.Id);
      });
    } else {
      item.isSelected = true;
      this.showSlip(item, true);
    }
  }

  public onClosePayslipClicked(): void {
    this.haveSalaryStatements = false;
    this.removeItemSelection();
    this.previewClicked = false;
  }

  public onViewNextPayslipClicked(): void {
    this.htmlContent = '';
    if (this.salaryStatements[this.currentIndex + 1]) {
      this.salaryStatements[this.currentIndex].isSelected = false;
      this.currentIndex++;
      this.salaryStatements[this.currentIndex].isSelected = true;
      this.showSlip(this.salaryStatements[this.currentIndex], true);
    }

    this.resetNavigationButtons();
  }

  public onViewPreviousPayslipClicked(): void {
    this.htmlContent = '';
    if (this.salaryStatements[this.currentIndex - 1]) {
      this.salaryStatements[this.currentIndex].isSelected = false;
      this.currentIndex--;
      this.salaryStatements[this.currentIndex].isSelected = true;
      this.showSlip(this.salaryStatements[this.currentIndex], true);
    }

    this.resetNavigationButtons();
  }

  public onOpenPayslipNewTab(event?: any, id?: number): void {
    if (!id) {
      id = this.currentId;
    }

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.getPayslipReportByToken(id, 'html');
  }

  public onDowloadShownPayslip(): void {
    const current: any = this.salaryStatements[this.currentIndex];
    const currentStatementId: number = current ? current.salaryStatement.Id : undefined;
    this.onDownloadPayslip(undefined, currentStatementId);
  }

  public onDownloadPayslip(event: any, salaryStatementId: number): void {
    if (salaryStatementId) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (this.isCordovaApp || this.sessionService.browser.isIE || this.sessionService.browser.isEdge) {
        this.dataService.SalaryStatements_GetPdf(salaryStatementId).subscribe((promiseValue: any): void => {
          this.downloadService.download('Gratisal.pdf', promiseValue);
        });
      } else {
        this.getPayslipReportByToken(salaryStatementId, 'pdf');
      }
    }
  }

  private showSalaryStatementsPreviewDialog(data: any): void {
    this.htmlContent = data;
    this.safeContent = this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
    this.paySlipPreviewDialog = true;
  }

  private getPayslipReportByToken(salaryStatementId: number, format: string): void {
    this.dataService.SalaryStatements_GetPayslipDownloadToken(salaryStatementId).subscribe((token: any): void => {
      const languageCode: string = this.sessionService.LanguageCode;
      const downloadUrl = `/api/salarystatements/${salaryStatementId}/token/${token}/lang/${languageCode}/format/${format}/Payslip`;
      const newWindow: Window = window.open(environment.apiUrl + downloadUrl);
      if (!newWindow) {
        this.newTabBlockedDialogVisible = true;
      }
    });
  }

  private removeItemSelection(): void {
    if (this.salaryStatements) {
      this.salaryStatements.forEach((value: any): void => {
        if (value.isSelected === true) {
          value.isSelected = false;
        }
      });
    }
  }

  private showSlip(item: any, clicked: boolean): void {
    this.previewClicked = true;
    this.dataService.SalaryStatements_GetHtml(item.salaryStatement.Id, 'a4p').subscribe((html: any) => {
      this.htmlContent = html;
      this.safeContent = this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
    });
    this.resetNavigationButtons();
  }

  private resetNavigationButtons(): void {
    this.previousBtnDisabled = this.salaryStatements[this.currentIndex - 1] ? false : true;
    this.nextBtnDisabled = this.salaryStatements[this.currentIndex + 1] ? false : true;
    this.haveSalaryStatements = true;
  }

  public haveSalaryStatements = false;
  private loadSalaryStatements(): void {
    this.dataService
      .SalaryStatements_GetSalaryStatementsCurrentCompanyUser()
      .subscribe((statements: ISalaryStatement[]): void => {
        if (statements) {
          statements.forEach((item: ISalaryStatement): void => {
            const extendedItem: any = { salaryStatement: item, isSelected: false };
            this.salaryStatements.push(extendedItem);
            this.haveSalaryStatements = true;
          });
        }

        this.showFirstSalaryStatement();
      });
  }

  private showFirstSalaryStatement(): void {
    if (this.salaryStatements.length > 0 && this.isAboveXLargeScreen) {
      this.onPayslipSelected(this.salaryStatements[0], 0);
    }
  }

  private getCompanies(): void {
    this.staticDataService.companiesSimple.subscribe((data: ISimpleCompany[]) => {
      this.companies = data;
      this.getCurrentCompany();
    });
  }

  companiesState: Subscription;
  private getCurrentCompany(): void {
    if (this.companiesState) {
      this.companiesState.unsubscribe();
    }
    this.companiesState = this.dataService.Companies_GetCurrent().subscribe((activeCompany: ICompany): void => {
      this.currentCompany = activeCompany;
      this.enableTranslation();
    });
  }

  public enableTranslation(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService.get(this.translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
        this.setTranslationTerms(translations);
      });
    });
  }

  public selfservicepayslipNote: string;
  public setTranslationTerms(translations: { [key: string]: string }): void {
    this.selfservicepayslipNote = translations['SelfServicePayslip.HelpSwitchCompanies'];
    if (this.currentCompany) {
      this.selfservicepayslipNote = this.selfservicepayslipNote.replace('$var$', this.currentCompany.Name);
    }
  }
}
