import { AfterViewInit, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Constants } from '../Common/Constants';
import { Global } from '../Common/Global';
import { DefaultAvatar } from '../Model/DefaultAvatar';
import { BillingUsage } from '../Services/ApiModel';
import { AuthenticationService } from '../Services/Authentication/AuthenticationService';
import { Broadcaster } from '../Services/Broadcaster';
import { RxDataService } from '../Services/RxDataService';
import { BusyIndicatorService } from '../Services/SharedServices/BusyIndicatorService';
import { ModalService } from '../Services/SharedServices/ModalService';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
import { SettingService } from '../Services/SharedServices/SettingService';
import { StaticDataService } from '../Services/SharedServices/StaticData.service';
import { ModuleCheckComponent } from '../SharedComponents/ModuleCheck/ModuleCheck.component';
var TabsComponent = /** @class */ (function () {
    function TabsComponent(translate, authenticationService, dataService, settingService, modalService, broadcaster, sessionService, staticDataService, renderer, meta, busyIndicatorService) {
        var _this = this;
        this.translate = translate;
        this.authenticationService = authenticationService;
        this.dataService = dataService;
        this.settingService = settingService;
        this.modalService = modalService;
        this.broadcaster = broadcaster;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.renderer = renderer;
        this.meta = meta;
        this.busyIndicatorService = busyIndicatorService;
        this.currentCompanyName = 'Unknow Company';
        this.currentCompanyAbbreviation = 'UC';
        this.isTesting = true;
        this.showcontentdialog = false;
        this.singleCompany = true;
        this.isDetecGoogleTranslate = false;
        this.isLongList = false;
        this.srcImageFemale = DefaultAvatar.imgFemaleAvatar;
        this.srcImageMale = DefaultAvatar.imgMaleAvatar;
        this.isFemale = false;
        this.isNavigateBack = false;
        this.limitTimeLoading = 10;
        this.isShowAdHocSupport = false;
        this.hasBackendAccess = false;
        this.moduleAdHocSupport = [];
        this.userModule = [];
        this.billingUsage = new BillingUsage();
        this.billingPrinciple = [];
        this.NumericOptions = { format: 'n2', decimals: 4, step: 1, min: 0, spinners: false };
        this.hasLanguageModule = false;
        this.isShowEmailSupport = false;
        this.isLoading = true;
        this.loadingTipText = '';
        this.isDetectedAdBlock = false;
        this.accountantTabName = '';
        this.ngUnsubscribe = new Subject();
        var url = window.location.href;
        if (!this.isMobile && url.indexOf('file://') > -1) {
            window.location.href = 'https://app.gratisal.dk';
        }
        if (!this.sessionService.IsAuthenticated) {
            this.sessionService.NavigateTo('login');
            return;
        }
        if (Global.SESSION && Global.SESSION.SignOnToken.Language.CultureCode) {
            var cultureCode = Global.SESSION.SignOnToken.Language.CultureCode;
            var shortCultureCode = cultureCode.substr(0, 2);
            this.translate.use(shortCultureCode).subscribe(function () {
                _this.settingService.applyTranslations();
                _this.translateText();
            });
        }
        if (this.sessionService.role.IsAccountant) {
            this.staticDataService.AccountType.subscribe(function (accountTypes) {
                if (accountTypes && accountTypes.length > 0 && !_this.accountantTabName) {
                    _this.omGetAccountantTypeCompolete(accountTypes);
                }
            });
        }
    }
    Object.defineProperty(TabsComponent.prototype, "isDemo", {
        get: function () {
            return this.sessionService.feature.IsDemoVersion;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "environment", {
        get: function () {
            return environment.environment;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "isIOSMobileApp", {
        get: function () {
            return this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "isEmployeeTimeTab", {
        get: function () {
            return this.sessionService.currentState === 'tabs.employee.time';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "isSelfServiceTimeTab", {
        get: function () {
            return this.sessionService.currentState === 'tabs.selfservice.time';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "isDetectedCookieDisable", {
        get: function () {
            return this.sessionService.isDetectedCookieDisable;
        },
        set: function (value) {
            if (value) {
                this.sessionService.isDetectedCookieDisable = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "isSingleListcompanis", {
        get: function () {
            if (this.companies && this.companies.length === 1) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "isEmployeeAndHasmanager", {
        get: function () {
            if (this.sessionService.role.EmployeeWithRoleAsManager) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "Companytooltip", {
        get: function () {
            if (Global.SESSION && Global.SESSION.IsAccountant) {
                if (this.logo) {
                    return this.currentCompanyName;
                }
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    TabsComponent.prototype.defaultBillingUsage = function () {
        this.billingUsage = new BillingUsage();
        this.billingUsage.Units = 1;
        this.billingUsage.BillingDate = this.staticDataService.getCurrentdate();
        if (this.moduleAdHocSupport && this.moduleAdHocSupport.length > 0) {
            var objectModule = this.moduleAdHocSupport.find(function (model) { return model.ModuleKey === 'BillableSupport'; });
            if (objectModule) {
                this.billingUsage.ModuleId = objectModule.ModuleId;
                // this.billingUsage.BillingPrincipleId = objectModule.ModuleBillingPrincipleId;
                this.onBillingPrincipChange();
            }
        }
    };
    TabsComponent.prototype.onBillingPrincipChange = function () {
        var _this = this;
        if (this.moduleAdHocSupport && this.moduleAdHocSupport.length > 0) {
            var objectModule = this.moduleAdHocSupport.find(function (model) { return model.ModuleId === _this.billingUsage.ModuleId; });
            if (objectModule) {
                this.billingUsage.AmountPerUnit = objectModule.ModulePrice ? objectModule.ModulePrice : 0;
                if (objectModule.ModuleBillingPrincipleId) {
                    this.billingUsage.BillingPrincipleId = objectModule.ModuleBillingPrincipleId;
                }
            }
        }
        this.onCaculateAmount();
    };
    TabsComponent.prototype.onSaveAdHocSupport = function (event) {
        var _this = this;
        if (event && event === 'Save') {
            this.dataService.Backend_RegisterUsage(this.billingUsage).subscribe(function () { return _this.onCaculateAmount(); });
        }
        this.defaultBillingUsage();
    };
    TabsComponent.prototype.onCaculateAmount = function () {
        if (this.billingUsage.Units !== undefined && this.billingUsage.AmountPerUnit !== undefined) {
            this.billingUsage.Amount = this.billingUsage.Units * this.billingUsage.AmountPerUnit;
        }
    };
    TabsComponent.prototype.omGetAccountantTypeCompolete = function (accountTypes) {
        var _this = this;
        this.staticDataService.currentAccount.subscribe(function (account) {
            if (account) {
                var accountType = accountTypes.find(function (t) { return t.Id === account.AccountTypeId; });
                if (accountType) {
                    _this.accountantTabName = accountType.Name;
                }
            }
        });
    };
    TabsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.sessionService.browser.isChrome && !this.isMobile) {
            setTimeout(function () {
                _this.renderer.listen(document, 'DOMSubtreeModified', function (e) {
                    if (e &&
                        e.target &&
                        e.target.tagName === 'HTML' &&
                        e.target.className &&
                        e.target.className.match('translated')) {
                        setTimeout(function () {
                            _this.isDetecGoogleTranslate = true;
                        });
                    }
                });
            });
        }
    };
    // Just using for detect google translate
    // Reload page to prevent google add more html element to web
    TabsComponent.prototype.onDisableDetect = function (event) {
        window.location.reload();
    };
    TabsComponent.prototype.onWaittingLogOut = function () {
        var _this = this;
        if (!this.sessionService.isGetting) {
            this.onLogout();
        }
        else {
            setTimeout(function () {
                _this.onWaittingLogOut();
            }, 500);
        }
    };
    TabsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.CheckNonState()) {
            this.onWaittingLogOut();
            return;
        }
        this.renderer.removeClass(document.body, 'Login-bg');
        this.renderer.addClass(document.body, 'main-bg');
        this.setFields();
        this.getCompanies();
        this.getUserImage();
        this.getusercompany();
        this.bindResizeEvent();
        this.useVerticalMenu();
        this.renderer.listen(document, 'keydown', function (event) {
            return _this.broadcaster.broadcast(Constants.GLOBAL_KEY_DOWN, event);
        });
        this.renderer.listen(window, 'click', function (event) {
            if (!_this.isCollapseButtonClick) {
                _this.navigationExpanded = false;
            }
            else {
                _this.isCollapseButtonClick = false;
            }
        });
        this.setupEventHandlers();
        this.editMode = false;
        this.staticDataService.BillingPrinciple.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.billingPrinciple = data); });
        this.checkLoading();
    };
    TabsComponent.prototype.checkLoading = function () {
        var _this = this;
        this.limitTimeLoading--;
        if (this.limitTimeLoading === 0) {
            this.afterLoading();
            return;
        }
        if (!this.sessionService.isGetting && !this.sessionService.isSubmitting) {
            this.afterLoading();
        }
        else {
            setTimeout(function () { return _this.checkLoading(); }, 1000);
        }
    };
    TabsComponent.prototype.afterLoading = function () {
        var _this = this;
        this.isLoading = false;
        this.settingService.isReload = false;
        setTimeout(function () {
            if (!document.getElementById('jZwhEMqUkoKu')) {
                _this.isDetectedAdBlock = false;
            }
            else {
                if (!Global.isFirstCheckMessageAdBlock) {
                    Global.isFirstCheckMessageAdBlock = true;
                    _this.isDetectedAdBlock = true;
                }
            }
            if (!_this.isMobile) {
                try {
                    if (document.cookie.indexOf('languageId') !== -1) {
                        _this.isDetectedCookieDisable = false;
                    }
                    else {
                        _this.isDetectedCookieDisable = true;
                    }
                }
                catch (e) {
                    _this.isDetectedCookieDisable = true;
                }
            }
            window.scrollTo(0, 1);
        });
    };
    TabsComponent.prototype.hasModuleKey = function (key) {
        if (this.moduleAdHocSupport && this.moduleAdHocSupport.length > 0) {
            var objectModule = this.moduleAdHocSupport.find(function (model) { return model.ModuleKey === key; });
            if (objectModule && objectModule.IsEnabled) {
                return true;
            }
        }
        return false;
    };
    TabsComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    TabsComponent.prototype.showModuleDialog = function () {
        this.showUserMenu = false;
        this.sessionService.showUserMenuToggled = false;
        this.sessionService.showMoreMenuToggled = false;
        this.sessionService.showHelpMenuToggled = false;
        this.userLanguageModuleCheck.onClick();
    };
    TabsComponent.prototype.navigateToState = function (name) {
        this.navigationExpanded = false;
        this.sessionService.menuToggled = true;
        if (!this.sessionService.currentState.includes(name)) {
            this.sessionService.NavigateTo(name);
        }
        if (this.sessionService.currentState === name) {
            var currentRoleKey = Global.SESSION.CurrentRole.Key;
            switch (name) {
                case 'tabs.employee':
                    if (currentRoleKey) {
                        switch (currentRoleKey) {
                            case 'FullAdmin':
                                if (Global.IsEmployeeTaskCompleted) {
                                    this.sessionService.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
                                    this.sessionService.NavigateTo('tabs.employee.payrolldata');
                                }
                                else {
                                    this.sessionService.currentStyleMenuTabpanelReponsive = '';
                                    this.sessionService.NavigateTo('tabs.employee.general');
                                }
                                break;
                            case 'ReadOnly':
                                this.sessionService.NavigateTo('tabs.employee.payrolldata');
                                break;
                            case 'Employee':
                                var hasManager = this.sessionService.role.EmployeeWithRoleAsManager;
                                if (hasManager) {
                                    this.sessionService.NavigateTo('tabs.employee.time');
                                }
                                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                                break;
                            case 'SalaryAdmin':
                                this.sessionService.NavigateTo('tabs.employee.payrolldata');
                                break;
                            default:
                                break;
                        }
                    }
                    break;
                case 'tabs.company':
                    if (currentRoleKey) {
                        switch (currentRoleKey) {
                            case 'FullAdmin':
                                this.sessionService.NavigateTo('tabs.company.salarybatches');
                                break;
                            case 'ReadOnly':
                                this.sessionService.NavigateTo('tabs.company.salarybatches');
                                break;
                            case 'Employee':
                                if (Global.SESSION.ManagerForUserEmploymentIds.length > 0) {
                                    this.sessionService.NavigateTo('tabs.company.approval.timeentry');
                                }
                                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                                break;
                            case 'SalaryAdmin':
                                this.sessionService.NavigateTo('tabs.company.salarybatches');
                                break;
                            default:
                                break;
                        }
                    }
                    break;
                case 'tabs.selfservice':
                    if (currentRoleKey) {
                        switch (currentRoleKey) {
                            case 'FullAdmin':
                                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                                break;
                            case 'ReadOnly':
                                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                                break;
                            case 'Employee':
                                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                                break;
                            case 'SalaryAdmin':
                                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                                break;
                            default:
                                break;
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    };
    Object.defineProperty(TabsComponent.prototype, "doubleClickToEditHintVisible", {
        get: function () {
            return (this.sessionService.currentState === 'tabs.employee.general' ||
                this.sessionService.currentState === 'tabs.employee.hiringstatus' ||
                this.sessionService.currentState === 'tabs.company.general' ||
                this.sessionService.currentState === 'tabs.company.configuration.hiringstatus' ||
                this.sessionService.currentState === 'tabs.selfservice.general');
        },
        enumerable: true,
        configurable: true
    });
    TabsComponent.prototype.raiseError = function () {
        throw new Error('Test AngularJS error');
    };
    TabsComponent.prototype.onTestStatus400 = function () {
        this.dataService.Miscellaneous_FailSoft().subscribe();
    };
    TabsComponent.prototype.onTestStatus404 = function () {
        this.dataService.Miscellaneous_FailNotFound().subscribe();
    };
    TabsComponent.prototype.onTestStatus500 = function () {
        this.dataService.Miscellaneous_FailHard().subscribe();
    };
    TabsComponent.prototype.onTestStatus401 = function () {
        this.dataService.Miscellaneous_FailUnauhorized().subscribe();
    };
    TabsComponent.prototype.onTestRandomStatus = function () {
        this.dataService.Miscellaneous_Random().subscribe();
    };
    TabsComponent.prototype.onTestModalDialog = function () {
        this.showcontentdialog = true;
    };
    TabsComponent.prototype.changeLanguage = function (args) {
        this.setLanguageContext(args.languageId);
    };
    TabsComponent.prototype.onLogout = function () {
        var _this = this;
        this.authenticationService
            .logout()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function () {
            _this.navigateToLogin();
        }, function () {
            _this.navigateToLogin();
        });
    };
    TabsComponent.prototype.onShowAvatarDialog = function () {
        this.showUserMenu = false;
        this.sessionService.showMoreMenuToggled = false;
        this.sessionService.showUserMenuToggled = false;
        this.sessionService.showHelpMenuToggled = false;
        this.changeAvatarDialog = true;
    };
    TabsComponent.prototype.onChangePasswordClick = function () {
        this.broadcaster.broadcast(Constants.ACCOUNT_CHANGE_PASSWORD);
        return true;
    };
    TabsComponent.prototype.onChangeLanguageDialogClick = function () {
        this.showLanguageChooserDialog = true;
    };
    TabsComponent.prototype.onCompanyChange = function (companyId) {
        var _this = this;
        if (!this.selectedCompanyId) {
            this.selectedCompanyId = -9999;
        }
        if (companyId &&
            this.selectedCompanyId &&
            this.selectedCompanyId !== companyId &&
            companyId !== Global.COMPANY_ID) {
            this.isLoading = true;
            this.loadingTipText = this.loadingTipsTextChangeCompany;
            this.setCompanyContext(companyId);
        }
        setTimeout(function () {
            _this.settingService.showCompanySelector = false;
        });
    };
    TabsComponent.prototype.onDPATermAccepted = function (isValid) {
        var _this = this;
        if (!isValid) {
            this.authenticationService
                .logout()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(function () {
                _this.navigateToLogin();
            }, function () {
                _this.navigateToLogin();
            });
        }
    };
    TabsComponent.prototype.onToggleNavigation = function () {
        this.isCollapseButtonClick = true;
        this.navigationExpanded = !this.navigationExpanded;
    };
    TabsComponent.prototype.onToggleCompanyDropdown = function () {
        var _this = this;
        this.sessionService.showHelpMenuToggled = false;
        this.sessionService.showMoreMenuToggled = false;
        if (this.isSingleListcompanis === false) {
            // this.settingService.showCompanySelector = !this.settingService.showCompanySelector;
            if (this.settingService.showCompanySelector === true) {
                this.settingService.showCompanySelector = false;
                return;
            }
            setTimeout(function () {
                _this.settingService.showCompanySelector = !_this.settingService.showCompanySelector;
            });
        }
    };
    TabsComponent.prototype.onToggleUserDropdown = function () {
        var _this = this;
        this.sessionService.showHelpMenuToggled = false;
        this.sessionService.showMoreMenuToggled = false;
        this.settingService.showCompanySelector = false;
        if (this.sessionService.showUserMenuToggled === true) {
            this.sessionService.showUserMenuToggled = false;
            return;
        }
        setTimeout(function () {
            _this.sessionService.showUserMenuToggled = !_this.sessionService.showUserMenuToggled;
        });
    };
    TabsComponent.prototype.closeallmeudown = function () {
        if (this.sessionService.showUserMenuToggled === true) {
            this.sessionService.showUserMenuToggled = false;
        }
    };
    TabsComponent.prototype.reloadStaticData = function () {
        var _this = this;
        this.staticDataService.loadStaticData().then(function () {
            var currentRoleKey = Global.SESSION.CurrentRole.Key;
            if (currentRoleKey && currentRoleKey === 'Employee') {
                var hasManager = _this.sessionService.role.EmployeeWithRoleAsManager;
                if (hasManager && _this.sessionService.employeeTabState) {
                    _this.sessionService.NavigateTo('tabs.employee.time');
                }
                _this.sessionService.NavigateTo('tabs.selfservice.payslip');
            }
            _this.dataService.Companies_GetCurrent().subscribe(function (activeCompany) {
                Global.COMPANY_ID = _this.activeCompany && _this.activeCompany.Id ? _this.activeCompany.Id : null;
                Global.COMPANY = activeCompany;
                window.location.reload();
            });
        });
    };
    TabsComponent.prototype.resetViewPort = function () {
        var _this = this;
        var original = this.meta.getTag('name=viewport');
        var forceScale = original.content + ', user-scalable=no, minimum-scale=1.0, maximum-scale=1.0';
        this.meta.updateTag({ name: 'viewport', content: forceScale }, 'name=viewport');
        setTimeout(function () { return _this.meta.updateTag({ name: 'viewport', content: original.content }, 'name=viewport'); });
    };
    TabsComponent.prototype.bindResizeEvent = function () {
        var _this = this;
        this.clientWidth = document.body.clientWidth;
        this.renderer.listen(window, 'resize', function () {
            _this.useVerticalMenu();
            var diff = document.body.clientWidth - _this.clientWidth;
            if (_this.isIOSMobileApp && Math.abs(diff) > 10) {
                _this.renderer.removeClass(document.body, 'mobileIOSApp');
                _this.clientWidth = document.body.clientWidth;
            }
        });
    };
    TabsComponent.prototype.useVerticalMenu = function () {
        if (window.matchMedia('(max-height: 500px)').matches || window.matchMedia('(max-width: 1000px)').matches) {
            if (!this.settingService.showCompanySelector) {
                this.sessionService.menuToggled = true;
            }
        }
        else {
            this.sessionService.menuToggled = false;
        }
    };
    TabsComponent.prototype.translateText = function () {
        var _this = this;
        this.translate
            .get([
            'Login.UpdatedGratisalMessage',
            'UnsuccessfulAPI.404',
            'Tips.ChangeCompany',
            'Tips.MobileChangeCompany',
            'Login.UpdatedGratisalMessageUrl'
        ])
            .subscribe(function (translations) {
            _this.updatedGratisalMessage = translations['Login.UpdatedGratisalMessage'];
            _this.updatedGratisalMessageUrl = translations['Login.UpdatedGratisalMessageUrl'];
            _this.updatedGratisalMessage = _this.updatedGratisalMessage.replace('{UrlFeauture}', _this.updatedGratisalMessageUrl);
            _this.sessionService.errorMessage404 = translations['UnsuccessfulAPI.404'];
            _this.settingService.showModuleMessage('ShowUpdateNotification', _this.updatedGratisalMessage);
            if (_this.isMobile) {
                _this.loadingTipsTextChangeCompany = translations['Tips.MobileChangeCompany'];
            }
            else {
                _this.loadingTipsTextChangeCompany = translations['Tips.ChangeCompany'];
            }
        });
        this.translate
            .get([
            'GlobalDialog.AcceptDPAButton',
            'GlobalDialog.AcceptWarnings',
            'GlobalDialog.Apply',
            'GlobalDialog.Approve',
            'GlobalDialog.AlreadyRequestedTaxCardConfirmButton',
            'GlobalDialog.Cancel',
            'GlobalDialog.CancelDiscard',
            'GlobalDialog.ChangePassword',
            'GlobalDialog.ClearErrors',
            'GlobalDialog.ConfirmDiscard',
            'GlobalDialog.Continue',
            'GlobalDialog.CompleteImportText',
            'GlobalDialog.Close',
            'GlobalDialog.Create',
            'GlobalDialog.CreateWithoutEmails',
            'GlobalDialog.Delete',
            'GlobalDialog.DisableThisBehaviour',
            'GlobalDialog.DiscardAndLeave',
            'GlobalDialog.Download',
            'GlobalDialog.DeleteEmploymentButtonText',
            'GlobalDialog.DeleteCompanyUserButtonText',
            'GlobalDialog.Edit',
            'GlobalDialog.Enable',
            'GlobalDialog.EnableAutoCorrect',
            'GlobalDialog.Finalize',
            'GlobalDialog.Hide',
            'GlobalDialog.Import',
            'GlobalDialog.IKnowWhatIamDoing',
            'GlobalDialog.Lock',
            'GlobalDialog.Lock and notify employees',
            'GlobalDialog.Logout',
            'GlobalDialog.MoreHelp',
            'GlobalDialog.No',
            'GlobalDialog.Ok',
            'GlobalDialog.Proceed',
            'GlobalDialog.ReadMore',
            'GlobalDialog.Reject',
            'GlobalDialog.Re-send',
            'GlobalDialog.ResetPassword',
            'GlobalDialog.Revert',
            'GlobalDialog.RevertToDraft',
            'GlobalDialog.Save',
            'GlobalDialog.Submit',
            'GlobalDialog.SubmitForApproval',
            'GlobalDialog.Stay',
            'GlobalDialog.Test',
            'GlobalDialog.Understood',
            'GlobalDialog.Update',
            'GlobalDialog.UsePositiveAmountThisTime',
            'GlobalDialog.Yes',
            'GlobalDialog.ContinueAndSaveChange',
            'EmploymentPayrollData.MoreInfo',
            'EmploymentPayrollData.UsePositiveAmountThisTime',
            'Error.NetworkError',
            'GlobalDialog.YesPlease',
            'GlobalDialog.NoThankYou',
            'CompanyDataImport.Save Definition',
            'CompanyDataImport.Import Once'
        ])
            .subscribe(function (translations) {
            _this.sessionService.modalTranslations = translations;
        });
    };
    TabsComponent.prototype.setFields = function () {
        var _this = this;
        if (Global.SESSION) {
            this.sessionService.roleKey = Global.SESSION ? Global.SESSION.CurrentRole.Key : undefined;
            this.isFemale = Global.SESSION ? Global.SESSION.IsFemale : undefined;
            this.languageId = Global.SESSION ? Global.SESSION.SignOnToken.Language.Id : undefined;
            this.fullName = Global.SESSION ? Global.SESSION.CurrentCompanyUser.FullName : undefined;
            this.role = Global.SESSION ? Global.SESSION.CurrentRole.Name : undefined;
            this.hasBackendAccess = Global.SESSION ? Global.SESSION.HasBackendAccess : undefined;
        }
        this.staticDataService.moduleCompanyView
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.userModule = data;
            _this.moduleAdHocSupport = JSON.parse(JSON.stringify(_this.userModule));
            if (_this.moduleAdHocSupport &&
                _this.moduleAdHocSupport.length > 0 &&
                (_this.hasModuleKey('PhoneSupport') || _this.hasModuleKey('EmailSupport'))) {
                _this.moduleAdHocSupport.forEach(function (model) {
                    if (model.ModuleKey === 'BillableSupport') {
                        model.ModulePrice = 0;
                    }
                });
            }
            _this.defaultBillingUsage();
        });
    };
    TabsComponent.prototype.setLanguageContext = function (languageId) {
        var _this = this;
        this.dataService.Users_SetLanguageContext(languageId).subscribe(function (reponse) {
            document.cookie = 'languageId=' + languageId;
            _this.reloadStaticData();
        });
    };
    TabsComponent.prototype.setupEventHandlers = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            return _this.translateText();
        });
        document.addEventListener('backbutton', function () {
            _this.onBackButtonClicked();
        }, false);
        this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (state) {
            _this.handleStatesForBackButton();
            _this.resetViewPort();
        });
        this.broadcaster
            .on(Constants.SHOW_EDIT_HINT)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function () {
            var showToastMessage = _this.doubleClickToEditHintVisible;
            _this.settingService.showDoubleClickToEditHint(showToastMessage);
        });
    };
    TabsComponent.prototype.onBackButtonClicked = function () {
        var statesHistory = Global.statesHistory ? Global.statesHistory : [];
        statesHistory.pop();
        if (statesHistory.length > 0) {
            this.isNavigateBack = true;
            this.sessionService.NavigateTo(statesHistory[statesHistory.length - 1]);
            Global.statesHistory = statesHistory;
        }
    };
    TabsComponent.prototype.handleStatesForBackButton = function () {
        var statesHistory = Global.statesHistory ? Global.statesHistory : [];
        if (!this.isNavigateBack) {
            statesHistory.push(this.sessionService.currentState);
            Global.statesHistory = statesHistory;
        }
        this.isNavigateBack = false;
    };
    TabsComponent.prototype.navigateToLogin = function () {
        this.sessionService.NavigateTo('login');
    };
    TabsComponent.prototype.getUserImage = function () {
        var _this = this;
        this.staticDataService.currentUserImage.subscribe(function (image) {
            if (image !== _this.avatar) {
                _this.avatar = image || (_this.isFemale ? _this.srcImageFemale : _this.srcImageMale);
            }
        });
    };
    TabsComponent.prototype.getusercompany = function () {
        var _this = this;
        this.dataService
            .CompanyUsers_GetCompanyUserById(Global.SESSION.CurrentCompanyUser.Id)
            .subscribe(function (data) {
            _this.firstname = data.FirstName;
            _this.lastname = data.LastName;
        });
    };
    TabsComponent.prototype.onAvatarChanged = function (formData) {
        // this.getUserImage();
        this.staticDataService.loadcurrentUserImage();
    };
    TabsComponent.prototype.getCompanies = function () {
        var _this = this;
        if (!this.companies || (this.companies && this.companies.length === 0)) {
            this.staticDataService.companiesSimple.subscribe(function (data) {
                _this.staticDataService.Role.subscribe(function (statisticOptions) {
                    var roleList = {};
                    statisticOptions.forEach(function (option) {
                        roleList[option.Id] = option.Name;
                    });
                    data.forEach(function (company) {
                        company.RoleName = roleList[company.RoleId];
                    });
                });
                _this.companies = data;
                _this.getCurrentCompany();
                if (_this.companies.length <= 1) {
                    _this.singleCompany = true;
                }
                else {
                    _this.singleCompany = false;
                }
            });
        }
    };
    TabsComponent.prototype.getCurrentCompany = function () {
        var _this = this;
        this.staticDataService.currentCompany.subscribe(function (activeCompany) {
            if (activeCompany) {
                _this.activeCompany = activeCompany;
                _this.selectedCompanyId = _this.activeCompany.Id;
                Global.COMPANY_ID = _this.activeCompany.Id;
                Global.COMPANY = activeCompany;
                _this.getCompanyLogo();
                if (_this.sessionService.role.IsFullAdmin && !activeCompany.HasAcceptedTerms) {
                    _this.showAcceptDPADialog = true;
                }
            }
        });
    };
    Object.defineProperty(TabsComponent.prototype, "isFullAdmin", {
        get: function () {
            return this.sessionService.role.IsFullAdmin;
        },
        enumerable: true,
        configurable: true
    });
    TabsComponent.prototype.setCompanyContext = function (companyId) {
        var _this = this;
        this.dataService.Auth_SetCompanyContext(companyId).subscribe(function () {
            Global.COMPANY_ID = companyId;
            _this.settingService.showCompanySelector = false;
            _this.reloadStaticData();
        });
    };
    TabsComponent.prototype.getCompanyLogo = function () {
        var _this = this;
        this.staticDataService.currentLogo.subscribe(function (image) {
            _this.logo = image;
            _this.showCompanyAbbreviation = true;
            _this.currentCompanyName = _this.activeCompany.Name;
            _this.currentCompanyAbbreviation = _this.currentCompanyName
                ? _this.currentCompanyName.toUpperCase().substring(0, 3)
                : '';
        });
    };
    TabsComponent.prototype.onShowAdHocSupport = function () {
        this.isShowAdHocSupport = true;
        if (this.hasModuleKey('PhoneSupport')) {
            // this.billingUsage.AmountPerUnit = 0;
            this.isShowEmailSupport = false;
        }
        else {
            this.isShowEmailSupport = true;
        }
    };
    TabsComponent.prototype.onCloseCompanySeach = function () {
        // this.settingService.showCompanySelector = false;
    };
    // @HostListener("click", ["$event"])
    TabsComponent.prototype.stopAllProcess = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
    };
    TabsComponent.prototype.CheckNonState = function () {
        if (!this.sessionService.currentState.includes('tabs.employee') &&
            !this.sessionService.currentState.includes('tabs.company') &&
            !this.sessionService.currentState.includes('tabs.selfservice') &&
            !this.sessionService.currentState.includes('tabs.accountant') &&
            !this.sessionService.currentState.includes('tabs.account')) {
            return true;
        }
        return false;
    };
    return TabsComponent;
}());
export { TabsComponent };
