import { Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { TimeAndAbsenceModel } from './TimeAndAbsenceModel';

@Component({
  selector: 'time-and-absence',
  templateUrl: './TimeAndAbsence.component.html'
})
export class TimeAndAbsenceComponent extends FormComponentBase {
  private currentmodel: any;
  @Input()
  public get model(): TimeAndAbsenceModel {
    if (!this.currentmodel) {
      this.currentmodel = {};
    }

    return this.currentmodel;
  }
  public set model(value: TimeAndAbsenceModel) {
    this.currentmodel = value;
    this.modelChange.emit(this.currentmodel);
  }
  @Output() public modelChange: EventEmitter<TimeAndAbsenceModel> = new EventEmitter<TimeAndAbsenceModel>();

  public timeEntrySplitStrategy: any;

  constructor(private staticDataService: StaticDataService) {
    super();
    this.childIsValid = Array(4).fill(true);
    this.childIsDirty = Array(4).fill(false);
    this.staticDataService.TimeEntryRecordAcrossSalaryPeriodStrategy.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any[]) => (this.timeEntrySplitStrategy = data)
    );
  }

  get IsUserEmployment(): boolean {
    return this.model && (this.model as any).HireDate;
  }
}
