/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@progress/kendo-angular-l10n";
import * as i2 from "@progress/kendo-angular-tooltip";
import * as i3 from "@progress/kendo-angular-popup";
import * as i4 from "@angular/common";
import * as i5 from "./Icon.component";
import * as i6 from "../../Services/SharedServices/Session/SessionService";
var styles_IconComponent = [];
var RenderType_IconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IconComponent, data: {} });
export { RenderType_IconComponent as RenderType_IconComponent };
function View_IconComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltip; _ck(_v, 0, 0, currVal_0); }); }
function View_IconComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "button", [["kendoTooltip", ""]], [[8, "title", 0], [8, "disabled", 0], [8, "className", 0], [1, "dir", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onIconClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(256, null, i1.L10N_PREFIX, "kendo.tooltip", []), i0.ɵprd(131584, null, i1.LocalizationService, i1.LocalizationService, [i1.L10N_PREFIX, [2, i1.MessageService], [2, i1.RTL]]), i0.ɵdid(3, 9125888, null, 0, i2.TooltipDirective, [i0.ElementRef, i0.NgZone, i0.Renderer2, i3.PopupService, i1.LocalizationService, [2, i2.TooltipSettings], [2, i2.TOOLTIP_SETTINGS]], { tooltipWidth: [0, "tooltipWidth"], tooltipTemplate: [1, "tooltipTemplate"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 7, "span", [], null, null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(6, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(7, { "FormElement-cursorHand": 0 }), (_l()(), i0.ɵeld(8, 0, null, null, 3, ":svg:svg", [["aria-hidden", "true"]], null, null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(10, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.tooltipWidth; var currVal_5 = i0.ɵnov(_v.parent, 0); _ck(_v, 3, 0, currVal_4, currVal_5); var currVal_6 = _ck(_v, 7, 0, _co.link); _ck(_v, 6, 0, currVal_6); var currVal_7 = _co.class; _ck(_v, 10, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = _co.disable; var currVal_2 = i0.ɵinlineInterpolate(1, "iconButton ", _co.disableClass, ""); var currVal_3 = i0.ɵnov(_v, 3).direction; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_8 = ((_co.absoluteUrl + "#") + _co.iconSvg); _ck(_v, 11, 0, currVal_8); }); }
function View_IconComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "button", [["kendoTooltip", ""]], [[8, "title", 0], [8, "disabled", 0], [8, "className", 0], [1, "dir", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onIconClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(256, null, i1.L10N_PREFIX, "kendo.tooltip", []), i0.ɵprd(131584, null, i1.LocalizationService, i1.LocalizationService, [i1.L10N_PREFIX, [2, i1.MessageService], [2, i1.RTL]]), i0.ɵdid(3, 9125888, null, 0, i2.TooltipDirective, [i0.ElementRef, i0.NgZone, i0.Renderer2, i3.PopupService, i1.LocalizationService, [2, i2.TooltipSettings], [2, i2.TOOLTIP_SETTINGS]], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 7, "span", [["class", "IconContainer--flex"]], null, null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(6, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(7, { "FormElement-cursorHand": 0 }), (_l()(), i0.ɵeld(8, 0, null, null, 3, ":svg:svg", [["aria-hidden", "true"]], null, null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(10, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_4 = "IconContainer--flex"; var currVal_5 = _ck(_v, 7, 0, _co.link); _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_6 = _co.class; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.tooltip || ""); var currVal_1 = _co.disable; var currVal_2 = i0.ɵinlineInterpolate(2, "iconButton ", _co.disableClass, " ", _co.isSelfService, ""); var currVal_3 = i0.ɵnov(_v, 3).direction; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_7 = ((_co.absoluteUrl + "#") + _co.iconSvg); _ck(_v, 11, 0, currVal_7); }); }
export function View_IconComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(0, [["iconTooltipTemplate", 2]], null, 0, null, View_IconComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconComponent_2)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconComponent_3)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.icon && _co.istooltipHTML); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.icon && !_co.istooltipHTML); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_IconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-icon", [], null, null, null, View_IconComponent_0, RenderType_IconComponent)), i0.ɵdid(1, 49152, null, 0, i5.IconComponent, [i0.ElementRef, i6.SessionService, i0.ChangeDetectorRef], null, null)], null, null); }
var IconComponentNgFactory = i0.ɵccf("app-icon", i5.IconComponent, View_IconComponent_Host_0, { defaultnullvalue: "defaultnullvalue", isGrid: "isGrid", preventChange: "preventChange", value: "value", editMode: "editMode", isDirty: "isDirty", isValid: "isValid", setTimeout: "setTimeout", hostClass: "hostClass", icon: "icon", size: "size", tooltip: "tooltip", link: "link", iconClass: "iconClass", istooltipHTML: "istooltipHTML", disable: "disable", isSelfService: "isSelfService", isOpenLink: "isOpenLink", tooltipWidth: "tooltipWidth" }, { valueChange: "valueChange", editModeChange: "editModeChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange", click: "click" }, []);
export { IconComponentNgFactory as IconComponentNgFactory };
