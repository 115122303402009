import { AfterViewInit, ChangeDetectorRef, EventEmitter, QueryList } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { orderBy } from '@progress/kendo-data-query';
import { ListHelper } from '../../Common/ListHelper';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { GridActionComponent } from './GridAction.component';
import { GridColumnComponent } from './GridColumn.component';
import { GridNavigationService } from './GridNavigation.service';
var CustomGridComponent = /** @class */ (function () {
    function CustomGridComponent(formBuilder, changeDetectorRef, sessionService) {
        this.formBuilder = formBuilder;
        this.changeDetectorRef = changeDetectorRef;
        this.sessionService = sessionService;
        this.editMode = true;
        this.addNewTooltips = '';
        this.customDeleteRow = false;
        this.companayTemplates = false;
        this.isDisableSelect = false;
        this.isEmployeeTime = false;
        this.showButDisableAddNewButton = false;
        this.disableDeleteButton = false;
        this.DeleteTooltips = '';
        this.columnIndexFocusAfterCreate = 0;
        this.selectedRows = [];
        // Attr PageList
        this.pageSize = 10;
        this.skip = 0;
        this.pageable = false;
        this.hiddenCount = false;
        this.buttonCount = 0;
        this.isReportDialog = false;
        this.selectedIdChange = new EventEmitter();
        this.skipChange = new EventEmitter();
        this.disableButtonAddNewClickEvent = new EventEmitter();
        this.updateDefaultRecordEvent = new EventEmitter();
        this.selectedItemChange = new EventEmitter();
        this.dataChange = new EventEmitter();
        this.triggerUpdateChange = new EventEmitter();
        this.addNewEvent = new EventEmitter();
        this.removeEvent = new EventEmitter();
        this.cellCloseEvent = new EventEmitter();
        this.saveChangesEvent = new EventEmitter();
        this.action = new EventEmitter();
        this.cellClick = new EventEmitter();
        this.gridButtonClick = new EventEmitter();
        this.clickAppGridIcon = new EventEmitter();
        this.controlDataChange = new EventEmitter();
        this.dataStateChange = new EventEmitter();
        this.filterChange = new EventEmitter();
        this.ButtonPopUpClick = new EventEmitter();
        //@Output() public addonClick: EventEmitter<void> = new EventEmitter<void>();
        this.dblClick = new EventEmitter();
        this.navigationService = new GridNavigationService();
        this.isDataChanged = false;
        this.sort = [];
        this.show = false;
    }
    Object.defineProperty(CustomGridComponent.prototype, "pageListSetting", {
        get: function () {
            if (this.buttonCount) {
                return { buttonCount: this.buttonCount, info: !this.hiddenCount };
            }
            return this.pageable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomGridComponent.prototype, "selectedId", {
        get: function () {
            return this.selectedIdValue;
        },
        set: function (value) {
            var _this = this;
            if ((this.selectedIdValue !== value || this.selectedRows.length === 0 || (!this.selectedIdValue && !value)) &&
                !this.isReportDialog) {
                this.selectedIdValue = value;
                this.selectedIdChange.emit(value);
                this.selectedRows = [value];
                if (this.data &&
                    this.idColumn &&
                    (!this.selectedItem || this.selectedItem[this.idColumn] !== this.selectedIdValue)) {
                    var selected = this.data.find(function (e) { return e[_this.idColumn] === _this.selectedIdValue; });
                    if (this.selectedItem !== selected) {
                        this.selectedItem = selected;
                    }
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomGridComponent.prototype, "selectedItem", {
        get: function () {
            return this.selectedItemValue;
        },
        set: function (value) {
            var previousValue = this.selectedItemValue;
            this.selectedItemValue = value;
            if (this.idColumn) {
                this.selectedId = value ? value[this.idColumn] : undefined;
            }
            if (this.selectedItemValue !== previousValue) {
                this.selectedItemChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomGridComponent.prototype, "data", {
        get: function () {
            return this.originalData;
        },
        set: function (value) {
            var _this = this;
            if (this.originalData !== value) {
                this.originalData = value || [];
                if (this.originalData.length > 0) {
                    this.originalData = ListHelper.createObserveArray(this.originalData, function () { return _this.triggerComponentChanged(); }, function () { return _this.triggerComponentChanged(); });
                    this.dataChange.emit(this.originalData);
                }
                if (!this.selectedItem ||
                    !this.originalData ||
                    this.originalData.length === 0 ||
                    (this.idColumn &&
                        !this.originalData.find(function (item) { return item[_this.idColumn] === _this.selectedItem[_this.idColumn]; }))) {
                    if (!this.selectedId) {
                        this.selectedRows = [];
                    }
                }
                this.loadItems();
            }
        },
        enumerable: true,
        configurable: true
    });
    CustomGridComponent.prototype.loadItems = function () {
        if (this.pageable) {
            this.gridData = {
                data: this.originalData.slice(this.skip, this.skip + this.pageSize),
                total: this.originalData.length
            };
        }
        else {
            this.gridData = {
                data: this.originalData,
                total: this.originalData.length
            };
        }
    };
    Object.defineProperty(CustomGridComponent.prototype, "triggerUpdate", {
        get: function () {
            return this.triggerUpdateValue;
        },
        set: function (value) {
            var _this = this;
            if (value) {
                this.changeDetectorRef.markForCheck();
                this.changeDetectorRef.detectChanges();
                setTimeout(function () {
                    _this.triggerUpdateValue = false;
                    _this.triggerUpdateChange.emit(_this.triggerUpdateValue);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomGridComponent.prototype, "isAppLoading", {
        get: function () {
            return this.sessionService.isGetting;
        },
        enumerable: true,
        configurable: true
    });
    CustomGridComponent.prototype.pageChange = function (event) {
        this.skip = event.skip;
        this.skipChange.emit(this.skip);
        this.loadItems();
    };
    CustomGridComponent.prototype.triggerComponentChanged = function () {
        var _this = this;
        setTimeout(function () {
            _this.changeDetectorRef.markForCheck();
        });
    };
    Object.defineProperty(CustomGridComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    CustomGridComponent.prototype.trackIndex = function (index) {
        return index;
    };
    CustomGridComponent.prototype.rowCallback = function (context) {
        return { highlightedRow: context.dataItem && context.dataItem.HightlightedRow };
    };
    CustomGridComponent.prototype.cellClass = function (column) {
        var res = column.class + " " + (!column.editableField && !column.editable ? 'grid-disable-cell' : '');
        return res;
    };
    CustomGridComponent.prototype.templateClass = function (column, dataItem) {
        return column && dataItem && column.classField ? dataItem[column.classField] : undefined;
    };
    Object.defineProperty(CustomGridComponent.prototype, "isSelected", {
        get: function () {
            if (this.isDisableSelect) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    CustomGridComponent.prototype.cellClickHandler = function (args) {
        // this.selectedItemChange.emit(args.dataItem);
        this.selectedCell = { field: args.column.field, rowIndex: args.rowIndex, columnIndex: args.columnIndex };
        var isEdited = args.isEdited;
        if (this.editMode && !isEdited) {
            args.sender.editCell(args.rowIndex, args.columnIndex, this.createFormGroup(args.dataItem));
            this.isDataChanged = false;
            setTimeout(function () {
                var element = document.querySelector('.k-state-focused input');
                if (element) {
                    element.focus();
                }
            });
        }
        this.cellClick.emit({ field: args.column.field, dataItem: args.dataItem });
    };
    CustomGridComponent.prototype.onSelectionChange = function (args) {
        if (args && args.selectedRows && args.selectedRows.length > 0) {
            this.selectedItem = args.selectedRows[0].dataItem;
        }
    };
    CustomGridComponent.prototype.onDbClickChange = function (args) {
        if (this.kendoGrid && this.kendoGrid.activeRow && this.kendoGrid.activeRow.dataItem) {
            this.dblClick.emit(this.kendoGrid.activeRow.dataItem);
        }
    };
    CustomGridComponent.prototype.addHandler = function (args) {
        var _this = this;
        this.addNewDisable = true;
        if (this.defaultNewValue) {
            this.updateDefaultRecordEvent.emit();
            setTimeout(function () {
                _this.addHandlerFunction(args);
            });
        }
        else {
            this.addHandlerFunction(args);
        }
    };
    CustomGridComponent.prototype.addHandlerFunction = function (args) {
        var dataItem = {};
        if (this.defaultNewValue) {
            dataItem = this.defaultNewValue;
        }
        else {
            if (this.columns && this.columns.length > 0) {
                this.columns.forEach(function (column) {
                    if (column.field) {
                        dataItem[column.field] = undefined;
                    }
                });
            }
        }
        if (!this.data) {
            this.data = [];
        }
        if (this.isEmployeeTime) {
            this.data.unshift(dataItem);
        }
        else {
            this.data.push(dataItem);
        }
        this.selectedItem = dataItem;
        this.addNewEvent.emit(dataItem);
        var formGroup = this.createFormGroup(dataItem);
        if (this.isEmployeeTime) {
            this.selectedCell = {
                field: this.columns ? this.columns.toArray()[this.columnIndexFocusAfterCreate].field : '',
                rowIndex: 0,
                columnIndex: this.columnIndexFocusAfterCreate
            };
            args.sender.editCell(0, this.columnIndexFocusAfterCreate, formGroup);
        }
        else {
            this.selectedCell = {
                field: this.columns ? this.columns.toArray()[0].field : '',
                rowIndex: this.data.length - 1,
                columnIndex: this.columnIndexFocusAfterCreate
            };
            args.sender.editCell(this.data.length - 1, this.columnIndexFocusAfterCreate, formGroup);
        }
        //args.sender.editCell(0, 0, formGroup);
        this.isDataChanged = false;
        this.addNewDisable = false;
    };
    CustomGridComponent.prototype.checkModelRecord = function (record) {
        Object.keys(record).forEach(function (key) {
            if (record[key] === undefined) {
                record[key] = null;
            }
        });
        return record;
    };
    CustomGridComponent.prototype.cellCloseHandler = function (args) {
        var _this = this;
        setTimeout(function () {
            var dataChanged = _this.isDataChanged;
            if (_this.isColumnDataChanged(args) || dataChanged) {
                _this.checkModelRecord(args.dataItem);
                _this.saveChangesEvent.emit(args);
                _this.triggerComponentChanged();
                _this.isDataChanged = false;
            }
            _this.cellCloseEvent.emit(args);
        });
    };
    CustomGridComponent.prototype.isColumnDataChanged = function (args) {
        var dataChanged;
        var column = args.column, dataItem = args.dataItem, formGroup = args.formGroup;
        if (dataItem && formGroup && formGroup.value && column && column.field) {
            dataChanged =
                ((dataItem[column.field] === null || dataItem[column.field] === undefined) &&
                    (formGroup.value[column.field] !== null && formGroup.value[column.field] !== undefined)) ||
                    (dataItem[column.field] !== null &&
                        dataItem[column.field] !== undefined &&
                        (!formGroup.value.hasOwnProperty(column.field) ||
                            (formGroup.value[column.field] === null || formGroup.value[column.field] === undefined))) ||
                    (dataItem[column.field] !== null &&
                        dataItem[column.field] !== undefined &&
                        (formGroup.value[column.field] !== null && formGroup.value[column.field] !== undefined) &&
                        dataItem[column.field] !== formGroup.value[column.field]);
        }
        return dataChanged;
    };
    CustomGridComponent.prototype.removeHandler = function (args) {
        if (!this.isAppLoading) {
            if (!this.customDeleteRow) {
                // this.selectedItemChange.emit(args.dataItem);
                var indexOfRemovedItem = this.data.indexOf(args.dataItem);
                this.data.splice(indexOfRemovedItem, 1);
                args.sender.closeRow(args.rowIndex);
                this.removeEvent.emit(args.dataItem);
            }
            else {
                this.removeEvent.emit(args);
            }
            this.triggerComponentChanged();
        }
    };
    CustomGridComponent.prototype.onKeyDown = function (event) {
        var _this = this;
        if ((event.key === 'Tab' || event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Escape') &&
            this.selectedCell) {
            var cell = this.navigationService.getNextEditableCell(this.getDisplayedColumns(), this.data, this.selectedCell.columnIndex, this.selectedCell.rowIndex, event);
            if (event.key === 'Escape') {
                setTimeout(function () {
                    _this.editCell(_this.selectedCell.rowIndex, _this.selectedCell.columnIndex, true);
                    _this.kendoGrid.closeCell();
                });
            }
            else if (cell) {
                this.kendoGrid.focusCell(cell.rowIndex + 1, cell.columnIndex);
                this.editCell(cell.rowIndex, cell.columnIndex);
                setTimeout(function () {
                    var element = document.querySelector('.k-state-focused input');
                    if (element) {
                        element.focus();
                    }
                });
                this.createFormGroup(this.data ? this.data[cell.rowIndex] : undefined);
            }
            event.preventDefault();
            event.stopPropagation();
        }
        if (event.key === 'Enter' && this.selectedCell) {
            this.isDataChanged = true;
        }
    };
    CustomGridComponent.prototype.onDataChanged = function (dataItem, field, editableField, isAllowNullValue) {
        if (isAllowNullValue === void 0) { isAllowNullValue = true; }
        var args = {
            column: { field: field },
            dataItem: dataItem,
            formGroup: this.formGroup
        };
        if (this.controlDataChange.observers &&
            this.controlDataChange.observers.length > 0 &&
            this.isColumnDataChanged(args)) {
            this.isDataChanged = true;
            if (isAllowNullValue) {
                this.formGroup.value[field] = dataItem[field];
            }
            else {
                if (dataItem[field]) {
                    this.formGroup.value[field] = dataItem[field];
                }
                else {
                    dataItem[field] = this.formGroup.value[field];
                }
            }
            this.controlDataChange.emit({ dataItem: dataItem, field: field, editableField: editableField });
        }
    };
    CustomGridComponent.prototype.onCheckBoxCheckedChanged = function (dataItem, field, isIgnoreCheckData) {
        if (isIgnoreCheckData === void 0) { isIgnoreCheckData = false; }
        var event = new CellCloseEvent({ dataItem: dataItem });
        var args = {
            column: { field: field },
            dataItem: dataItem,
            formGroup: this.formGroup
        };
        if (this.isColumnDataChanged(args) || isIgnoreCheckData) {
            this.createFormGroup(dataItem);
            this.checkModelRecord(dataItem);
            this.saveChangesEvent.emit(event);
            this.triggerComponentChanged();
        }
    };
    CustomGridComponent.prototype.getDisplayedColumns = function () {
        var displayedColumns = [];
        var arrColumns = this.columns.toArray();
        for (var index = 0; index < arrColumns.length; index++) {
            var column = arrColumns[index];
            if (!column.isGroupColumn) {
                displayedColumns.push(column);
            }
            else {
                var arrSubColumns = column.columns.toArray();
                for (var subIndex = 0; subIndex < arrSubColumns.length; subIndex++) {
                    var subColumn = arrSubColumns[subIndex];
                    if (!subColumn.isGroupColumn) {
                        displayedColumns.push(subColumn);
                    }
                }
            }
        }
        return displayedColumns;
    };
    CustomGridComponent.prototype.createFormGroup = function (dataItem) {
        this.formGroup = this.formBuilder.group(dataItem);
        return this.formGroup;
    };
    CustomGridComponent.prototype.editCell = function (rowIndex, columnIndex, isIgnore) {
        if (isIgnore === void 0) { isIgnore = false; }
        if (!isIgnore && this.selectedCell) {
            this.selectedCell.rowIndex = rowIndex;
            this.selectedCell.columnIndex = columnIndex;
        }
        var dataItem = this.data[rowIndex];
        this.selectedItem = dataItem;
        this.kendoGrid.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
        if (!this.selectedItem || !this.selectedItem['EntryDate'] || !this.selectedItem['TimeEntryTypeId']) {
            this.isDataChanged = false;
        }
    };
    CustomGridComponent.prototype.getParamSubProberty = function (dataItem, subproperty) {
        if (subproperty) {
            var arraySubproperty = subproperty.split('.');
            var object = dataItem;
            for (var i = 0; i < arraySubproperty.length; ++i) {
                object = object[arraySubproperty[i]];
            }
            return object;
        }
        return '';
    };
    CustomGridComponent.prototype.appIconClick = function (dataItem, iconAction) {
        this.clickAppGridIcon.emit({ dataItem: dataItem, iconAction: iconAction });
        this.triggerComponentChanged();
    };
    CustomGridComponent.prototype.onGridButtonClickEvent = function (dataItem, buttonAction) {
        this.gridButtonClick.emit({ dataItem: dataItem, buttonAction: buttonAction });
        this.triggerComponentChanged();
    };
    CustomGridComponent.prototype.onIncellPopUpClickAction = function (dataItem, field, args) {
        var buttonAction = args.action;
        this.gridButtonClick.emit({ dataItem: dataItem, buttonAction: buttonAction, field: field });
        // dataItem[displayField] = false;
        this.triggerComponentChanged();
    };
    CustomGridComponent.prototype.onDataStateHandler = function (event) {
        this.dataStateChange.emit(event);
    };
    CustomGridComponent.prototype.onSortChange = function (sort) {
        if (this.data && this.data.length > 0) {
            this.sort = sort;
            var gridView = {
                data: orderBy(this.data, this.sort),
                total: this.data.length
            };
            this.data = gridView.data;
        }
    };
    CustomGridComponent.prototype.onFilterChange = function (filter) {
        this.filterChange.emit(filter);
    };
    CustomGridComponent.prototype.ngAfterViewInit = function () { };
    CustomGridComponent.prototype.onToggle = function () {
        this.show = !this.show;
    };
    CustomGridComponent.prototype.AddNewDisableClick = function () {
        if (this.addNewDisable) {
            //this.disableButtonAddNewClickEvent.emit();
        }
    };
    CustomGridComponent.prototype.onGridToolbarClick = function (event) {
        this.action.emit(event);
    };
    return CustomGridComponent;
}());
export { CustomGridComponent };
