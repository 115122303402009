import { Component, OnInit } from '@angular/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { IAccount, IUser, IUserCompanyAccess } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { AccountantUsersService } from './AccountantUsers.service';

@Component({
  selector: 'app-accountant-users',
  templateUrl: './AccountantUsers.component.html'
})
export class AccountantUsersComponent implements OnInit {
  public addNewUserDialogVisible = false;
  public dataUserList: IUser[];
  public dataRoleList: IUserCompanyAccess[];
  public accountName = '';
  public isFirstLoad = false;

  constructor(
    public service: AccountantUsersService,
    public staticData: StaticDataService,
    private dataService: RxDataService
  ) {
    this.dataService.Account_GetCurrentAccount().subscribe((account: IAccount) => {
      this.accountName = account.Name;
    });
    this.service.users.subscribe((data) => {
      this.dataUserList = data;
      this.gridUserFilterData = filterBy(this.dataUserList, this.userFilter);
    });
    this.service.roles.subscribe((data: any) => {
      this.dataRoleList = data;
      this.dataRoleList.sort((a, b) => {
        if (a.CompanyName && b.CompanyName) {
          return a.CompanyName.localeCompare(b.CompanyName);
        } else {
          return a.CompanyId - b.CompanyId;
        }
      });
      this.gridFilterData = filterBy(this.dataRoleList, this.filter);
    });
  }

  public ngOnInit() {
    this.isFirstLoad = true;
  }

  public userFilter: CompositeFilterDescriptor;
  public gridUserFilterData: IUser[] = filterBy(this.dataUserList, this.userFilter);

  public onUserFilterChange(filter: CompositeFilterDescriptor): void {
    if (filter && filter.filters && filter.filters.length > 0) {
      this.userFilter = filter;
    }
    this.gridUserFilterData = filterBy(this.dataUserList, filter);
  }

  public filter: CompositeFilterDescriptor;
  public gridFilterData: IUserCompanyAccess[] = filterBy(this.dataRoleList, this.filter);

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    if (filter && filter.filters && filter.filters.length > 0) {
      this.filter = filter;
    }
    this.gridFilterData = filterBy(this.dataRoleList, filter);
  }

  public onSelectedIdChange(selectedId: any): void {
    this.service.selectedId = selectedId;
    if (!this.isFirstLoad) {
      this.gridFilterData = [];
    }
    this.isFirstLoad = false;
  }
}
