import { EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
var FormComponentBase = /** @class */ (function () {
    function FormComponentBase() {
        this.editModeValue = false;
        this.editModeChange = new EventEmitter();
        this.childIsDirty = [];
        this.isDirtyValue = false;
        this.isDirtyChange = new EventEmitter();
        this.childIsValid = [];
        this.isValidValue = true;
        this.isValidChange = new EventEmitter();
        this.valueChange = new EventEmitter();
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(FormComponentBase.prototype, "editMode", {
        get: function () {
            return this.editModeValue;
        },
        set: function (value) {
            if (this.editModeValue !== value) {
                this.editModeValue = value;
                this.editModeChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormComponentBase.prototype, "isDirty", {
        get: function () {
            return this.isDirtyValue;
        },
        set: function (value) {
            var _this = this;
            if (this.isDirtyValue !== value) {
                this.isDirtyValue = value;
                if (!value && !this.editMode) {
                    setTimeout(function () {
                        _this.childIsDirty.forEach(function (c) { return (c = false); });
                    });
                }
                this.isDirtyChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormComponentBase.prototype, "isValid", {
        get: function () {
            return this.isValidValue;
        },
        set: function (value) {
            if (this.isValidValue !== value) {
                this.isValidValue = value;
                this.isValidChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    FormComponentBase.prototype.onChange = function () {
        // setTimeout(() => {
        //   this.isValid = this.valid();
        //   this.isDirty = this.hasChanges();
        //   this.valueChange.emit();
        // });
        this.isValid = this.valid();
        this.isDirty = this.hasChanges();
        this.valueChange.emit();
    };
    FormComponentBase.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    FormComponentBase.prototype.valid = function () {
        return this.childIsValid.every(function (c) { return c; });
    };
    FormComponentBase.prototype.hasChanges = function () {
        return this.childIsDirty.some(function (c) { return c; });
    };
    return FormComponentBase;
}());
export { FormComponentBase };
