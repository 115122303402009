import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SalaryBatchViewModel } from '../SalaryBatchViewModel';

@Component({
  selector: 'batch-finalization-step-3-dialogue',
  templateUrl: './BatchFinalizationStep3Dialogue.component.html'
})
export class BatchFinalizationStep3DialogueComponent {
  @Input() public salaryBatch: SalaryBatchViewModel;
  @Input() public autorecalculate = false;

  @Input()
  public get isClose(): boolean {
    return this.visible;
  }
  public set isClose(value: boolean) {
    if (value) {
      this.visible = false;
    }
  }

  private doRecalculationValue: boolean;
  @Input()
  public get doRecalculation(): boolean {
    return this.doRecalculationValue;
  }
  public set doRecalculation(value: boolean) {
    if (this.doRecalculationValue !== value) {
      this.doRecalculationValue = value;
      setTimeout(() => this.doRecalculationChange.emit(value));
    }
  }
  @Output() public doRecalculationChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      setTimeout(() => this.visibleChange.emit(value));
      if (value) {
        const draftSalaryBatch: boolean = this.salaryBatch.StatusId === 10;
        if (/*this.autorecalculate &&*/ draftSalaryBatch) {
          this.doRecalculation = true;
          this.stepCompleted = true;
          this.visibleValue = false;
          // this.stepCompletedChange.emit(value);
        } else {
          this.recalculateBeforeFinalizing = draftSalaryBatch;
          this.doRecalculation = false;
        }
      }
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private stepCompletedValue: boolean;
  // @Input()
  public get stepCompleted(): boolean {
    return this.stepCompletedValue;
  }
  public set stepCompleted(value: boolean) {
    if (this.stepCompletedValue !== value) {
      // this.stepCompletedValue = value;
      setTimeout(() => this.stepCompletedChange.emit(value));
    }
  }
  @Output() public stepCompletedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public finalizationExit: EventEmitter<void> = new EventEmitter<void>();

  public recalculateBeforeFinalizing = false;

  public onDialogAction(action: string): void {
    if (action === 'Proceed') {
      this.doRecalculation = this.recalculateBeforeFinalizing;
      this.stepCompleted = true;
    } else {
      this.finalizationExit.emit();
    }
  }
}
