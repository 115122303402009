import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ComboBoxComponent, DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
var TimePickerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TimePickerComponent, _super);
    function TimePickerComponent(translateService, sessionService, elementRef, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.translateService = translateService;
        _this.sessionService = sessionService;
        _this.isLabelTranslated = false;
        _this.disable = false;
        _this.addonClick = new EventEmitter();
        _this.timeValueChange = new EventEmitter();
        _this.hideLabel = true;
        _this.translatedLabelPropertyName = 'translatedLabel';
        _this.translatedPlaceHolderPropertyName = 'translatedPlaceHolder';
        _this.ngUnsubscribe = new Subject();
        return _this;
    }
    Object.defineProperty(TimePickerComponent.prototype, "label", {
        set: function (value) {
            if (!value) {
                // If label is not set, we hide the label
                this.hideLabel = true;
                return;
            }
            this.labelTranslationKey = value;
            if (!this.isLabelTranslated) {
                this.translateProperty(this.translatedLabelPropertyName, value);
            }
            else {
                this[this.translatedLabelPropertyName] = value;
            }
            this.hideLabel = false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimePickerComponent.prototype, "comboboxPlaceHolder", {
        set: function (value) {
            if (value) {
                this.placeHolderTranslationKey = value;
                this.translateProperty(this.translatedPlaceHolderPropertyName, value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimePickerComponent.prototype, "displayTimeValue", {
        get: function () {
            return this.convertTimeEnteredToDesireFormat(this.value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimePickerComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    TimePickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            _this.translate();
        });
        this.dataSource = this.getTimeDataSource();
    };
    TimePickerComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    TimePickerComponent.prototype.convertValue = function (value) {
        return this.convertTimeEnteredToDesireFormat(value);
    };
    Object.defineProperty(TimePickerComponent.prototype, "timeDataSource", {
        get: function () {
            if (!this.timeDataSourceValue) {
                return this.dataSource;
            }
            return this.timeDataSourceValue;
        },
        enumerable: true,
        configurable: true
    });
    TimePickerComponent.prototype.onfocus = function (event) {
        var _this = this;
        setTimeout(function () {
            if (_this.comboBox) {
                _this.comboBox.wrapper.getElementsByClassName('k-input')[0].select();
            }
        });
    };
    TimePickerComponent.prototype.onComboboxChange = function (value) {
        var existedItem;
        if (this.dataSource && value) {
            // SentryId: 691325038.
            existedItem = this.dataSource.find(function (s) {
                return s.toLowerCase() === value.toLowerCase();
            });
        }
        if (!existedItem) {
            var formattedTime = this.convertTimeEnteredToDesireFormat(value);
            this.value = formattedTime;
            this.comboboxObject.writeValue(formattedTime);
        }
        ////this.onChange.emit();
    };
    TimePickerComponent.prototype.onComboBoxFiltering = function (value) {
        if (this.dropdownList) {
            if (value && value.length >= 1 && this.dataSource) {
                this.timeDataSourceValue = this.dataSource.filter(function (s) { return s.toLowerCase().indexOf(value.toLowerCase()) !== -1; });
                if (this.timeDataSourceValue.length === 0 && value.length > 3) {
                    value = this.convertTimeEnteredToDesireFormat(value);
                    this.timeDataSourceValue.push(value);
                }
            }
            else {
                this.timeDataSourceValue = undefined;
            }
        }
    };
    TimePickerComponent.prototype.onAddonButtonClick = function () {
        this.addonClick.emit();
    };
    TimePickerComponent.prototype.getTimeDataSource = function () {
        var timeArray = [];
        var date = new Date();
        for (var count = 0; count < 96; count++) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
            date.setMinutes(count * 15);
            timeArray.push(this.sessionService.toString(date, 'HH:mm'));
        }
        timeArray.push('24:00');
        return timeArray;
    };
    TimePickerComponent.prototype.translate = function () {
        if (!this.isLabelTranslated) {
            this.translateProperty(this.translatedLabelPropertyName, this.labelTranslationKey);
        }
        else {
            this[this.translatedLabelPropertyName] = this.labelTranslationKey;
        }
        this.translateProperty(this.translatedPlaceHolderPropertyName, this.placeHolderTranslationKey);
    };
    TimePickerComponent.prototype.translateProperty = function (propertyName, translationKey) {
        var _this = this;
        if (this.translateService && translationKey) {
            this.translateService.get(translationKey).subscribe(function (translated) {
                _this[propertyName] = translated;
            });
        }
        else {
            this[propertyName] = '';
        }
    };
    Object.defineProperty(TimePickerComponent.prototype, "comboboxObject", {
        get: function () {
            return this.isMobile ? this.dropdownList : this.comboBox;
        },
        enumerable: true,
        configurable: true
    });
    TimePickerComponent.prototype.onkeydown = function (event) {
        if (event && event.keyCode === 9) {
            if (event.target) {
                var currentValue = this.convertTimeEnteredToDesireFormat(event.target.value || '');
                if (currentValue !== this.value) {
                    this.value = currentValue;
                }
            }
        }
    };
    TimePickerComponent.prototype.convertTimeEnteredToDesireFormat = function (timeString) {
        if (!timeString) {
            return undefined;
        }
        var hour;
        var minute;
        if (/[.]/g.test(timeString) || /[-]/g.test(timeString) || /[:]/g.test(timeString)) {
            var seperator = /[.]/g.test(timeString)
                ? '.'
                : /[-]/g.test(timeString)
                    ? '-'
                    : /[:]/g.test(timeString)
                        ? ':'
                        : '';
            hour = timeString.split(seperator)[0] ? this.sessionService.parseInt(timeString.split(seperator)[0]) : undefined;
            minute = timeString.split(seperator)[1]
                ? this.sessionService.parseInt(timeString.split(seperator)[1])
                : undefined;
        }
        else {
            timeString = timeString.replace(/[^0-9,]/g, '');
            hour = timeString.substring(0, 2) ? this.sessionService.parseInt(timeString.substring(0, 2)) : undefined;
            minute = timeString.substring(2, 4) ? this.sessionService.parseInt(timeString.substring(2, 4)) : undefined;
        }
        minute = hour && hour >= 24 ? 0 : minute;
        hour = hour || hour === 0 ? (hour <= 0 || hour > 24 ? 0 : hour) : undefined;
        // minute = minute || minute === 0 ? (minute <= 0 || minute === 60 ? 0 : (minute > 60 ? 0 : minute)) : undefined;
        if (minute || minute === 0) {
            if (minute <= 0 || minute === 60) {
                minute = 0;
            }
            else {
                if (minute > 60) {
                    minute = 0;
                    hour = hour + 1;
                }
            }
        }
        else {
            minute = undefined;
        }
        return hour || hour === 0 ? this.zeroPad(hour, 2) + ':' + this.zeroPad(minute, 2) : undefined;
    };
    TimePickerComponent.prototype.zeroPad = function (num, places) {
        num = num ? num : 0;
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join('0') + num;
    };
    return TimePickerComponent;
}(ControlComponentBase));
export { TimePickerComponent };
