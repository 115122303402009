<div
  [ngClass]="{ 'clickable-diamond': !hasModule }"
  kendoTooltip
  [title]="hasModule ? '' : ('AccountModules.ClickToActivateTooltip' | translate)"
  (click)="onClick()"
>
  <div [ngClass]="{ 'module-disabled': !hasModule }">
    <ng-content></ng-content>
  </div>
  <div class="diamond-outter-wrap" [ngClass]="{ 'icon-diamond-language': companyOption }" *ngIf="!hasModule">
    <div class="diamond-inner-wrap" [ngClass]="{ 'icon-diamond-language': companyOption }"></div>
  </div>
</div>

<app-dialog
  (action)="onActivateModuleDialogAction($event)"
  [(visible)]="activateModuleDialogVisible"
  [width]="dialogWidth"
>
  <div *ngIf="isFullAdmin" [innerHTML]="'AccountModules.DisabledModuleAdmin' | translate: translationParams"></div>
  <div *ngIf="!isFullAdmin" [innerHTML]="'AccountModules.DisabledModuleEmployee' | translate"></div>
  <app-dialog-action *ngIf="isFullAdmin" [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action *ngIf="!isFullAdmin" [type]="'Ok'"></app-dialog-action>
  <app-dialog-action
    *ngIf="isFullAdmin"
    [action]="'ViewPackages'"
    [label]="'AccountModules.ViewPackages' | translate"
    [class]="'Button--primary'"
  ></app-dialog-action>
  <app-dialog-action
    *ngIf="isFullAdmin"
    [action]="'ActivateModule'"
    [label]="'AccountModules.ActivateModule' | translate"
    [class]="'Button--destructive'"
  ></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onIsGreenlandCompanyActivateModuleDialogAction($event)"
  [(visible)]="IsGreenlandCompanyactivateModuleDialogVisible"
  [width]="dialogWidth"
>
  <div [innerHTML]="'AccountModules.ExtraTabVisible' | translate"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog (action)="reloadPage()" [(visible)]="moduleActivatedDialogVisible">
  <div *ngIf="isFullAdmin" [innerHTML]="moduleActivatedDialogMessage | translate"></div>
  <app-dialog-action *ngIf="isFullAdmin" [type]="'Ok'"></app-dialog-action>
</app-dialog>
