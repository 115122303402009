import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { version } from '../../version';

export class CustomLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}
  getTranslation(lang: string = 'da'): Observable<any> {
    if (lang !== 'da' && lang !== 'en' && lang !== 'kl') {
      lang = 'da';
    }
    return this.http.get('translation/' + version + `-locale.${lang}.json`);
  }
}
