import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from '@ngx-translate/core';
import { MessageService } from '@progress/kendo-angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotTranslatedService } from './NotTranslatedService';
import { SessionService } from './Session/SessionService';

@Injectable()
export class MissingTranslationService implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): void {
    this.notTranslatedService.notTranslated(params.key);
  }

  constructor(private notTranslatedService: NotTranslatedService) {}
}
