import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, OnInit } from '@angular/core';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { UniqueId } from '../Common/UniqueId';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
var PercentageEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PercentageEditComponent, _super);
    function PercentageEditComponent(elementRef, sessionService, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.sessionService = sessionService;
        _this.allowDecimal = false;
        _this.disable = false;
        _this.percentageSign = true;
        _this.step = false;
        _this.helpTooltip = '';
        _this.helpIcon = 'Help';
        _this.isOpenLink = true;
        _this.isSelfService = '';
        _this.maxHeight20 = '';
        _this.componentId = 'percentDecimalControlIdString' + UniqueId();
        return _this;
    }
    PercentageEditComponent.prototype.ngOnInit = function () {
        this.onChange();
    };
    PercentageEditComponent.prototype.valid = function () {
        return !this.required || (this.value === 0 || !!this.value);
    };
    Object.defineProperty(PercentageEditComponent.prototype, "isInvalid", {
        get: function () {
            return this.editMode && !this.valid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PercentageEditComponent.prototype, "percentageDisplayText", {
        get: function () {
            return this.percentValue || this.percentValue === 0
                ? this.sessionService.toString(this.percentValue, this.allowDecimal ? 'n2' : 'n0') + ' %'
                : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PercentageEditComponent.prototype, "percentValue", {
        get: function () {
            return this.value && this.value > 0 ? this.value * 100 : this.value;
        },
        set: function (value) {
            var _this = this;
            setTimeout(function () {
                if (value && value > 1) {
                    _this.value = _this.sessionService.parseFloat(_this.sessionService.toString(value / 100, 'n5'));
                }
                else {
                    _this.value =
                        value || value === 0
                            ? _this.sessionService.parseFloat(_this.sessionService.toString(value / 100, 'n5'))
                            : _this.value;
                }
            });
        },
        enumerable: true,
        configurable: true
    });
    return PercentageEditComponent;
}(ControlComponentBase));
export { PercentageEditComponent };
