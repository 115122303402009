import { OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileUploadComponent } from '../../../CustomControls/FileUpload.component';
import { ActionTypesEnum, ImportTypesEnum } from '../../../Model/Enum';
import { RxDataService } from '../../../Services/RxDataService';
import { DownloadService } from '../../../Services/SharedServices/DownloadService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { ImportOptionHelper } from './ImportOptionHelper';
var CompanyDataImportComponent = /** @class */ (function () {
    function CompanyDataImportComponent(translateService, dataService, downloadService, sessionService, staticDataService) {
        this.translateService = translateService;
        this.dataService = dataService;
        this.downloadService = downloadService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.importType = ImportTypesEnum.Payroll;
        this.actionType = ActionTypesEnum.GetTemplate;
        this.importFromExternalSourceValue = 1;
        this.importPayrollFromExternalSourceValue = 1;
        this.showUploadFileDialog = false;
        this.showCreateTemplateDialog = false;
        this.balanceDateDialogVisible = false;
        this.alertDialogVisible = false;
        this.balanceDate = new Date();
        this.ngUnsubscribe = new Subject();
        this.translateText();
    }
    Object.defineProperty(CompanyDataImportComponent.prototype, "showDownLoadButton", {
        get: function () {
            return this.actionType === ActionTypesEnum.GetTemplate || this.actionType === 5;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyDataImportComponent.prototype, "showUploadButton", {
        get: function () {
            return (this.actionType === ActionTypesEnum.Upload_File || this.actionType === ActionTypesEnum.Convert_From_Other_System);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyDataImportComponent.prototype, "showCreateCustomFile", {
        get: function () {
            return this.actionType === ActionTypesEnum.CustomImport;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyDataImportComponent.prototype, "convertFromOtherSystemFieldsVisible", {
        get: function () {
            return this.actionType === ActionTypesEnum.Convert_From_Other_System;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyDataImportComponent.prototype, "payrollOrTimeTemplateIdTypesVisible", {
        get: function () {
            return ((this.importType === ImportTypesEnum.Payroll || this.importType === ImportTypesEnum.TimeEntries) &&
                this.actionType === ActionTypesEnum.GetTemplate);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyDataImportComponent.prototype, "isPayrollDataImport", {
        get: function () {
            return this.importType === ImportTypesEnum.Payroll || this.importType === ImportTypesEnum.TimeEntries;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyDataImportComponent.prototype, "isTimeEntriesDataImport", {
        get: function () {
            return this.importType === ImportTypesEnum.TimeEntries;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyDataImportComponent.prototype, "acceptFileType", {
        get: function () {
            return this.importType === ImportTypesEnum.Payroll ||
                this.importType === ImportTypesEnum.TimeEntries /*|| this.importType === ImportTypesEnum.TimeEntries */
                ? this.importPayrollFromExternalSourceValue === 2
                    ? '.csv'
                    : '.dat'
                : this.importFromExternalSourceValue === 2
                    ? '.csv'
                    : '.pdf';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyDataImportComponent.prototype, "hasTimeModule", {
        get: function () {
            return this.sessionService.feature.hasModuleId(8);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyDataImportComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyDataImportComponent.prototype, "isShowExportExistingData", {
        get: function () {
            return this.importType === ImportTypesEnum.Employees || this.importType === ImportTypesEnum.Initial_Balances;
        },
        enumerable: true,
        configurable: true
    });
    CompanyDataImportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            return _this.translateText();
        });
        this.balanceDate = this.getLastDateOfPreviousMonth();
        this.dataService.Import_GetIdTypes().subscribe(function (results) {
            var idTypeIdCount = 0;
            _this.payrollOrTimeEntriesDataIdTypesDatasource = [];
            if (results) {
                results.forEach(function (result) {
                    idTypeIdCount++;
                    _this.payrollOrTimeEntriesDataIdTypesDatasource.push({ Id: idTypeIdCount, Name: result });
                });
            }
            _this.payrollOrTimeEntriesDataIdType =
                _this.payrollOrTimeEntriesDataIdTypesDatasource && _this.payrollOrTimeEntriesDataIdTypesDatasource.length > 0
                    ? _this.payrollOrTimeEntriesDataIdTypesDatasource[0].Id
                    : undefined;
        });
        if (this.isMobile) {
            this.sessionService.isShowHugeFeaturesAlert = true;
        }
    };
    CompanyDataImportComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyDataImportComponent.prototype.onDownLoad = function () {
        switch (this.importType) {
            case ImportTypesEnum.Employees:
            case ImportTypesEnum.Payroll:
            case ImportTypesEnum.TimeEntries:
            case ImportTypesEnum.TimeEntriesRow:
                this.downloadDocument();
                break;
            case ImportTypesEnum.Initial_Balances:
                // Popup date for Balances
                this.showBalancesDatePopup();
                break;
        }
    };
    CompanyDataImportComponent.prototype.onUpload = function () {
        if (this.actionType === ActionTypesEnum.Convert_From_Other_System) {
            this.fileUpload.addFile();
        }
        else {
            this.showUploadFileDialog = true;
        }
    };
    CompanyDataImportComponent.prototype.onCreate = function () {
        this.showCreateTemplateDialog = true;
    };
    CompanyDataImportComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService
            .get([
            'CompanyDataImport.ImportFromExternalOption',
            'CompanyDataImport.AOFdatfile',
            'CompanyDataImport.ImportFromPlanDay'
        ])
            .subscribe(function (translations) {
            _this.importFromExternalSource = [];
            _this.importFromExternalSource.push({ Id: 1, Name: translations['CompanyDataImport.ImportFromExternalOption'] });
            _this.importFromExternalSourceEmployee = [];
            _this.importFromExternalSourceEmployee.push({
                Id: 1,
                Name: translations['CompanyDataImport.ImportFromExternalOption']
            });
            _this.importFromExternalSourceEmployee.push({
                Id: 2,
                Name: translations['CompanyDataImport.ImportFromPlanDay']
            });
            _this.importPayrollFromExternalSource = [];
            _this.importPayrollFromExternalSource.push({ Id: 1, Name: translations['CompanyDataImport.AOFdatfile'] });
            _this.importFromExternalSourcePayrollData = [];
            _this.importFromExternalSourcePayrollData.push({ Id: 1, Name: translations['CompanyDataImport.AOFdatfile'] });
            _this.importFromExternalSourcePayrollData.push({
                Id: 2,
                Name: translations['CompanyDataImport.ImportFromPlanDay']
            });
        });
    };
    CompanyDataImportComponent.prototype.getLastDateOfPreviousMonth = function () {
        var date = new Date();
        var currentMonth = date.getMonth();
        var currentYear = date.getFullYear();
        return new Date(currentYear, currentMonth, 0);
    };
    CompanyDataImportComponent.prototype.onBalanceDateDialogAction = function (action) {
        if (action === 'Ok' || action === 'Upload') {
            this.downloadDocument();
        }
    };
    CompanyDataImportComponent.prototype.showBalancesDatePopup = function () {
        this.balanceDateDialogVisible = true;
        this.balanceDate = this.getLastDateOfPreviousMonth();
    };
    CompanyDataImportComponent.prototype.downloadDocument = function () {
        var _this = this;
        var date = this.getBalanceDate();
        var anotherDate = this.getBalanceDay();
        switch (this.importType) {
            case ImportTypesEnum.Employees:
                // Employees
                if (this.actionType === 5) {
                    this.dataService
                        .CompanyDataImport_GetEmployeeTemplateFilled()
                        .subscribe(function (fileData) { return _this.onDownloadCompleted(fileData); });
                    break;
                }
                this.dataService
                    .CompanyDataImport_GetEmployeeTemplate()
                    .subscribe(function (fileData) { return _this.onDownloadCompleted(fileData); });
                break;
            case ImportTypesEnum.Initial_Balances:
                // Balances
                if (this.actionType === 5) {
                    this.dataService
                        .CompanyDataImport_GetBalancesImportTemplate(date)
                        .subscribe(function (fileData) { return _this.onDownloadCompleted(fileData); });
                    break;
                }
                this.dataService
                    .CompanyDataImport_GetBalancesTemplate(date)
                    .subscribe(function (fileData) { return _this.onDownloadCompleted(fileData); });
                break;
            case ImportTypesEnum.Payroll:
                // Payroll data
                var idType = void 0;
                if (this.payrollOrTimeEntriesDataIdType) {
                    idType = this.payrollOrTimeEntriesDataIdType
                        ? this.payrollOrTimeEntriesDataIdTypesDatasource.find(function (id) { return id.Id === _this.payrollOrTimeEntriesDataIdType; })
                        : undefined;
                }
                if (idType && idType.Name) {
                    this.dataService
                        .CompanyDataImport_GetPayrollImportTemplate(idType.Name)
                        .subscribe(function (fileData) { return _this.onDownloadCompleted(fileData); });
                }
                break;
            case ImportTypesEnum.TimeEntries:
                // Payroll data
                var timeEdType = void 0;
                if (this.payrollOrTimeEntriesDataIdType) {
                    timeEdType = this.payrollOrTimeEntriesDataIdType
                        ? this.payrollOrTimeEntriesDataIdTypesDatasource.find(function (id) { return id.Id === _this.payrollOrTimeEntriesDataIdType; })
                        : undefined;
                }
                if (timeEdType && timeEdType.Name) {
                    this.dataService
                        .CompanyDataImport_GetTimeEntriesImportTemplate(timeEdType.Name)
                        .subscribe(function (fileData) { return _this.onDownloadCompleted(fileData); });
                }
                break;
        }
    };
    CompanyDataImportComponent.prototype.onDownloadCompleted = function (promiseValue) {
        if (this.sessionService.browser.isMobileSafari) {
            this.alertType = 'SafariWarning';
            this.alertDialogVisible = true;
        }
        this.downloadService.download('Gratisal.XLSX', promiseValue);
    };
    CompanyDataImportComponent.prototype.onFileSelected = function (file) {
        var maxSize = 1024 * 1024 * 64;
        if (file.size > maxSize) {
            this.alertType = 'FileSizeError';
            this.alertDialogVisible = true;
            return;
        }
        var formData = new FormData();
        formData.append('file', file, file.name);
        this.uploadImportedFile(formData);
    };
    CompanyDataImportComponent.prototype.uploadImportedFile = function (formData) {
        if (this.actionType === ActionTypesEnum.Convert_From_Other_System) {
            if (this.importType === ImportTypesEnum.Payroll) {
                this.postDataImportFromDatFile(formData);
            }
            else {
                this.postDataImportFromDatFile(formData);
                //this.postEmployeeFileFromExternalSource(formData);
            }
        }
    };
    CompanyDataImportComponent.prototype.postDataImportFromDatFile = function (formData) {
        var _this = this;
        var operation = ImportOptionHelper.getImportTypeKey(this.importType);
        if (operation === 'ImportSalaryRecords') {
            if (this.importPayrollFromExternalSourceValue === 2) {
                operation = 'PayrollData';
                this.dataService
                    .CompanyDataImport_PlanDayToExcel(operation, formData)
                    .subscribe(function (fileData) { return _this.onDownloadCompleted(fileData); });
            }
            else {
                operation = 'SalaryRecords';
                this.dataService
                    .CompanyDataImport_DatFileToExcel(operation, formData)
                    .subscribe(function (fileData) { return _this.onDownloadCompleted(fileData); });
            }
        }
        else if (operation === 'ImportTimeEntries') {
            operation = 'TimeEntries';
            this.dataService
                .CompanyDataImport_DatFileToExcel(operation, formData)
                .subscribe(function (fileData) { return _this.onDownloadCompleted(fileData); });
        }
        else {
            if (this.importFromExternalSourceValue === 2) {
                this.dataService
                    .CompanyDataImport_PlanDayToExcel(operation, formData)
                    .subscribe(function (fileData) { return _this.onDownloadCompleted(fileData); });
            }
            else {
                this.dataService
                    .CompanyDataImport_DanlonPdfToExcel(operation, formData)
                    .subscribe(function (fileData) { return _this.onDownloadCompleted(fileData); });
            }
        }
    };
    CompanyDataImportComponent.prototype.postEmployeeFileFromExternalSource = function (formData) {
        var _this = this;
        var operation = ImportOptionHelper.getImportTypeKey(this.importType);
        this.dataService.CompanyDataImport_DanlonPdfToExcel(operation, formData).subscribe(function (fileData) {
            _this.onDownloadCompleted(fileData);
            if (_this.importType === ImportTypesEnum.Employees) {
                _this.staticDataService.loadDepartments();
                _this.staticDataService.loadCompanyUsers();
                _this.alertType = 'DanlonPDFSuccess';
                _this.alertDialogVisible = true;
            }
            else if (_this.importType === ImportTypesEnum.Initial_Balances) {
                _this.alertType = 'BalancesDanlonPDFSuccess';
                _this.alertDialogVisible = true;
            }
        });
    };
    CompanyDataImportComponent.prototype.getBalanceDate = function () {
        if (!this.balanceDate) {
            return undefined;
        }
        var dateTemp = new Date(this.balanceDate);
        return (dateTemp.getFullYear().toString() +
            this.sessionService.toString(dateTemp.getMonth() + 1, '00') +
            this.sessionService.toString(dateTemp.getDate(), '00'));
    };
    CompanyDataImportComponent.prototype.getBalanceDay = function () {
        if (!this.balanceDate) {
            return undefined;
        }
        var dateTemp = new Date(this.balanceDate);
        return (this.sessionService.toString(dateTemp.getDate(), '00') +
            '-' +
            this.sessionService.toString(dateTemp.getMonth() + 1, '00') +
            '-' +
            dateTemp.getFullYear().toString());
    };
    CompanyDataImportComponent.prototype.onCHeckBoxImportTypeChange = function () {
        // if (this.importType === 5 && this.actionType === 4) {
        //     this.actionType = 1;
        // }
        this.actionType = 1;
        this.importFromExternalSourceValue = 1;
        this.importPayrollFromExternalSourceValue = 1;
    };
    return CompanyDataImportComponent;
}());
export { CompanyDataImportComponent };
