import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from '@ngx-translate/core';
import { MessageService } from '@progress/kendo-angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from './Session/SessionService';

@Injectable({
  providedIn: 'root'
})
export class NotTranslatedService {
  public notTranslated(key: string): void {
    // console.log('Not translated', key);
  }
}
