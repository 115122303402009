<div class="Dropdown-container" (keydown)="onkeydown($event)">
  <action-button
    buttonClass="Action-button Action-buttonMore"
    [icon]="'ThreeDots'"
    label="More.Title"
    (click)="onToggleMoreDropdown($event)"
    tooltip="More.Title"
  ></action-button>

  <div
    class="Dropdown Dropdown--bottom Dropdown--more moreDropDownDialog{{ module }}"
    [ngClass]="{ 'is-active': sessionService.showMoreMenuToggled }"
  >
    <ul role="menu" *ngIf="companyGeneralTabActive">
      <li *ngIf="!isCompanyGeneralMobile" class="Dropdown-item">
        <button type="button" (click)="onMenuClickEvent('companyGeneral_importData')">
          {{ 'CompanyGeneral.Import data' | translate }}
        </button>
      </li>

      <li class="Dropdown-item" *ngIf="HasBackendAccess">
        <button type="button" (click)="onMenuClickEvent('companyGeneral_testMail')">
          {{ 'More.TestEmails' | translate }}
        </button>
      </li>
    </ul>

    <ul role="menu" *ngIf="companyTemplateTabActive">
      <!--UNIMPLETMENTED Apply template to existing employees -->
      <!--<li class="Dropdown-item">
                <button type="button" (click)="onMenuClickEvent('companyTemplate_applyTemplateToExistingEmployee')">{{'More.Apply template to existing employees' | translate}}</button>
            </li>-->
      <li class="Dropdown-item">
        <button type="button" (click)="onMenuClickEvent('companyTemplate_deleteTemplate')">
          {{ 'More.Delete template' | translate }}
        </button>
      </li>
    </ul>

    <ul role="menu" *ngIf="companySalaryBatchesTabActive">
      <li class="Dropdown-item">
        <button type="button" (click)="onMenuClickEvent('salaryBatches_recalculateDraft')">
          {{ 'More.Recalculate drafts' | translate }}
        </button>
      </li>

      <li class="Dropdown-item">
        <button type="button" (click)="onMenuClickEvent('salaryBatches_importData')">
          {{ 'CompanySalaryBatches.Import data' | translate }}
        </button>
      </li>
    </ul>

    <ul role="menu" *ngIf="employeeHiringStatusTabActive">
      <li class="Dropdown-item">
        <button type="button" (click)="onMenuClickEvent('employeeHiringStatus_createTemplate')">
          {{ 'More.Create template from this employment' | translate }}
        </button>
      </li>
      <li class="Dropdown-item">
        <button id="AddEmploymentButton" type="button" (click)="onMenuClickEvent('employeeHiringStatus_addEmployment')">
          {{ 'More.Add second employment' | translate }}
        </button>
      </li>
      <li class="Dropdown-item" *ngIf="IsFullAdmin && showMatchTemplateMenu">
        <button type="button" (click)="onMenuClickEvent('employeeHiringStatus_synchronizeWithTemplate')">
          {{ 'More.SynchronizeWithTemplate' | translate }}
        </button>
      </li>
      <li class="Dropdown-item">
        <button type="button" (click)="onMenuClickEvent('employeeGeneral_deleteEmployee')">
          {{ 'More.Delete employee' | translate }}
        </button>
      </li>
    </ul>

    <ul role="menu" *ngIf="employeeGeneralTabActive">
      <li class="Dropdown-item">
        <button type="button" (click)="onMenuClickEvent('employeeGeneral_deleteEmployee')">
          {{ 'More.Delete employee' | translate }}
        </button>
      </li>
      <li class="Dropdown-item">
        <button type="button" (click)="onMenuClickEvent('employeeGeneral_importEmployees')">
          {{ 'More.ImportEmployees' | translate }}
        </button>
      </li>
      <li class="Dropdown-item" *ngIf="IsSalaryAdminAndHigher">
        <button type="button" (click)="onMenuClickEvent('employeeGeneral_ResendWelcomeEmail')">
          {{ 'More.ResendWelcomeEmail' | translate }}
        </button>
      </li>
      <li class="Dropdown-item" *ngIf="IsSalaryAdminAndHigher">
        <button type="button" (click)="onMenuClickEvent('employeeGeneral_ResetPassword')">
          {{ 'More.ResetPassword' | translate }}
        </button>
      </li>
    </ul>

    <ul role="menu" *ngIf="employeePayrollDataTabActive">
      <li class="Dropdown-item">
        <button type="button" (click)="onMenuClickEvent('employeeGeneral_importInitialBalances')">
          {{ 'More.ImportInitialBalances' | translate }}
        </button>
      </li>

      <li class="Dropdown-item">
        <button type="button" (click)="onMenuClickEvent('companyGeneral_importData')">
          {{ 'CompanySalaryBatches.Import data' | translate }}
        </button>
      </li>
    </ul>

    <ul role="menu" *ngIf="accountantCompanyTabActive">
      <li class="Dropdown-item">
        <button type="button" (click)="onMenuClickEvent('accountant_attachexistingcompany')">
          {{ 'Accountant.AttachExistingCompany' | translate }}
        </button>
      </li>
    </ul>
  </div>
</div>
