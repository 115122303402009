import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PriceFormatPipe } from '../../../Filters/PriceFormat.pipe';
import { SalaryType } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { SalaryTypeView } from '../SalaryTypes/SalaryTypeView';
var CompanyStandardSalaryTypesComponent = /** @class */ (function () {
    function CompanyStandardSalaryTypesComponent(dataService, sessionService, staticDataService, priceFormatPipe) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.priceFormatPipe = priceFormatPipe;
        this.salaryTypes = [];
        this.externalReferences = [];
        this.hasSalaryTypeModule = false;
        this.currentModules = [];
        this.moduleId = 4;
        this.activateModuleDialogVisible = false;
        this.moduleActivatedDialogVisible = false;
        this.moduleActivatedDialogMessage = 'AccountModules.ReloginForChanges';
        this.hasModuleValue = false;
        this.hasModuleChange = new EventEmitter();
        this.ngUnsubscribe = new Subject();
        this.gridData = filterBy(this.salaryTypes, this.filter);
    }
    Object.defineProperty(CompanyStandardSalaryTypesComponent.prototype, "isFullAdmin", {
        get: function () {
            return this.sessionService.role.IsFullAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyStandardSalaryTypesComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    CompanyStandardSalaryTypesComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyStandardSalaryTypesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.moduleCompanyView
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (modules) {
            if (modules && modules.length > 0 && _this.currentModules.length === 0) {
                _this.currentModules = modules;
                _this.onGetModulesViewComplete();
                _this.loadData();
                if (_this.isMobile) {
                    _this.sessionService.isShowHugeFeaturesAlert = true;
                }
            }
        });
    };
    CompanyStandardSalaryTypesComponent.prototype.onGetModulesViewComplete = function () {
        var _this = this;
        var module = this.currentModules && this.currentModules.length
            ? this.currentModules.find(function (m) { return m.ModuleId === _this.moduleId; })
            : undefined;
        if (module) {
            this.hasSalaryTypeModule = module.IsEnabled;
            this.modulePrice = module.ModulePrice;
            this.moduleName = module.ModuleName;
        }
        else {
            this.hasSalaryTypeModule = false;
        }
        setTimeout(function () {
            _this.hasModuleChange.emit(_this.hasSalaryTypeModule);
        });
    };
    Object.defineProperty(CompanyStandardSalaryTypesComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    CompanyStandardSalaryTypesComponent.prototype.loadData = function () {
        var _this = this;
        this.loadSalaryTypes();
        this.dataService.Integrations_GetAllExternalAccounts().subscribe(function (data) {
            _this.onGetAllExternalAccountsComplete(data);
        });
    };
    CompanyStandardSalaryTypesComponent.prototype.loadSalaryTypes = function () {
        var _this = this;
        this.dataService
            .SalaryTypes_GetAllSalaryTypeViews()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.salaryTypes = data.map(function (s) {
                var base = s.BaseSalaryTypeId
                    ? data.find(function (sType) { return sType.SalaryTypeId === s.BaseSalaryTypeId; })
                    : undefined;
                s.Name = s.Name ? s.Name : base ? base.Name : '';
                var salaryTypeNew = new SalaryType();
                Object.assign(salaryTypeNew, s);
                salaryTypeNew.Id = s.SalaryTypeId;
                return new SalaryTypeView(salaryTypeNew, false);
            });
            _this.salaryTypes.forEach(function (model) {
                model.IconField = model.IsAdvanced === true && !_this.hasSalaryTypeModule ? 'Diamond' : '';
                model.Editable = !(model.IsAdvanced === true && !_this.hasSalaryTypeModule);
            });
            //this.salaryTypes = this.salaryTypes.sort((a: ISalaryTypeView, b: ISalaryTypeView) => a.SalaryTypeId > b.SalaryTypeId ? 1 : -1);
            //this.salaryTypes = this.salaryTypes.sort((a: ISalaryTypeView, b: ISalaryTypeView) => !a.IsAdvanced && b.IsAdvanced ? 1 : -1);
            _this.gridData = filterBy(_this.salaryTypes, _this.filter);
            _this.convertExternalRefCodeToFriendlyName();
        });
    };
    CompanyStandardSalaryTypesComponent.prototype.onGetAllExternalAccountsComplete = function (externalReferences) {
        this.externalReferences = externalReferences;
        if (this.externalReferences && this.externalReferences.length > 0) {
            externalReferences.forEach(function (model) {
                model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
            });
            this.convertExternalRefCodeToFriendlyName();
        }
    };
    CompanyStandardSalaryTypesComponent.prototype.convertExternalRefCodeToFriendlyName = function () {
        var _this = this;
        if (this.salaryTypes &&
            this.salaryTypes.length > 0 &&
            this.externalReferences &&
            this.externalReferences.length > 0) {
            this.salaryTypes.forEach(function (salaryType) {
                salaryType.ExternalReference = _this.getExteralReferenceCode(salaryType.ExternalReference, true);
            });
        }
    };
    CompanyStandardSalaryTypesComponent.prototype.onSave = function (args) {
        var _this = this;
        args.dataItem.ExternalReference = this.getExteralReferenceCode(args.dataItem.ExternalReference, true);
        var salaryType = args.dataItem.toSalaryType();
        salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference);
        var request = {
            Id: salaryType.Id ? salaryType.Id : salaryType.SalaryTypeId,
            ExternalReference: salaryType.ExternalReference
        };
        request = this.staticDataService.checkModelRecord(request);
        this.dataService.SalaryTypes_UpdateSalaryTypeSimple(request).subscribe(function (data) {
            data.ExternalReference = _this.getExteralReferenceCode(data.ExternalReference, true);
            args.dataItem = data;
            args.dataItem.SalaryTypeId = salaryType.SalaryTypeId;
        }, function (error) { return _this.loadSalaryTypes(); });
    };
    CompanyStandardSalaryTypesComponent.prototype.getExteralReferenceCode = function (refCode, fullName) {
        if (!refCode || !this.externalReferences) {
            return undefined;
        }
        var externalRefCode = this.externalReferences.find(function (item) { return refCode === item.AccountNumber || refCode === item.FriendlyName; });
        if (externalRefCode) {
            return !fullName ? externalRefCode.AccountNumber : externalRefCode.FriendlyName;
        }
        return refCode;
    };
    CompanyStandardSalaryTypesComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridData = filterBy(this.salaryTypes, filter);
    };
    CompanyStandardSalaryTypesComponent.prototype.onActivateModuleDialogAction = function (action) {
        var _this = this;
        switch (action) {
            case 'ViewPackages':
                this.sessionService.NavigateTo('tabs.company.modules');
                break;
            case 'ActivateModule':
                this.dataService.Modules_EnableModule(this.moduleId).subscribe(function (message) {
                    if (message) {
                        _this.moduleActivatedDialogMessage = message;
                    }
                    _this.staticDataService.loadStaticData().then(function () {
                        _this.moduleActivatedDialogVisible = true;
                    });
                }, function () {
                    // this.reloadPage();
                });
                break;
        }
    };
    CompanyStandardSalaryTypesComponent.prototype.onAddonClickEvent = function () {
        this.activateModuleDialogVisible = true;
    };
    CompanyStandardSalaryTypesComponent.prototype.reloadPage = function () {
        window.location.reload();
    };
    Object.defineProperty(CompanyStandardSalaryTypesComponent.prototype, "translationParams", {
        //format the price before it gets passed as a translation param
        get: function () {
            return {
                moduleName: this.moduleName,
                modulePrice: this.priceFormatPipe.transform(this.modulePrice, true) === 'Price.Free'
                    ? '0,-'
                    : this.priceFormatPipe.transform(this.modulePrice, true)
            };
        },
        enumerable: true,
        configurable: true
    });
    CompanyStandardSalaryTypesComponent.prototype.onCellClick = function (event) {
        if (event.dataItem) {
            if (event.dataItem.IsAdvanced === true && !this.hasSalaryTypeModule) {
                this.activateModuleDialogVisible = true;
            }
        }
    };
    return CompanyStandardSalaryTypesComponent;
}());
export { CompanyStandardSalaryTypesComponent };
