import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Global } from '../../../../Common/Global';
import { ISimpleSalaryBatchRecord, ISimpleSalaryStatement } from '../../../../Services/ApiModel';

@Component({
  selector: 'salary-batch-payslip-details',
  templateUrl: './SalaryBatchPayslipDetails.component.html'
})
export class SalaryBatchPayslipDetailsComponent {
  @Output() public revertBatchCompleted: EventEmitter<void> = new EventEmitter<void>();

  @Input() public selectedSalaryStatement: ISimpleSalaryStatement;
  private salaryStatementDetailValues: ISimpleSalaryBatchRecord[];

  @Input()
  public get salaryStatementDetails(): ISimpleSalaryBatchRecord[] {
    return this.salaryStatementDetailValues;
  }
  public set salaryStatementDetails(value: ISimpleSalaryBatchRecord[]) {
    this.salaryStatementDetailValues = value;
    this.gridData =
      this.salaryStatementDetailValues && this.filter
        ? filterBy(this.salaryStatementDetailValues, this.filter)
        : this.salaryStatementDetailValues;
  }
  @Input() public IsFinalizedBatch: boolean;

  public get IsFinalizedBatchAndHaveData(): boolean {
    if (this.IsFinalizedBatch && this.salaryStatementDetails && this.salaryStatementDetails.length > 0) {
      return true;
    }
    return false;
  }

  public get isPaymentApprover(): boolean {
    return Global.SESSION && Global.SESSION.IsPaymentApprover;
  }

  public revertPayslipDialogVisible = false;

  constructor() {}

  public onRevertPayslip(): void {
    this.revertPayslipDialogVisible = true;
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = filterBy(this.salaryStatementDetails, this.filter);
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.salaryStatementDetails, this.filter);
  }
}
