<div [ngClass]="{ 'is-editing': editMode }">
  <div class="EmployeeInfo Form--compact">
    <!-- EmployeeInfo-avatar -->
    <div class="Avatar Avatar--circle EmployeeInfo-avatar">
      <app-employee-avatar
        [(avatarChange)]="avatarChange"
        [companyUserId]="userId"
        [isFemale]="isFemale"
        [editMode]="editMode"
        (isAvatarChanged)="onAvatarChanged()"
      ></app-employee-avatar>
    </div>
    <div class="EmployeeInfoDetails">
      <!--[(isValid)]="childIsValid[0]" [(isDirty)]="childIsDirty[0]"-->
      <app-employee-name
        class="EmployeeInfo-row"
        [companyUser]="companyUser"
        [editMode]="editMode"
        [(isValid)]="isNameValid"
        (valueChange)="onEmployeeNameChanged()"
      ></app-employee-name>

      <div class="EmployeeInfo-row EmployeeInfo-rowDetails" [ngClass]="{ 'is-active': expandUserInfoDetails }">
        <!-- CPR -->
        <cpr-info
          [editMode]="editMode && allowEdit"
          *ngIf="!EmployeeWithRoleAsManager"
          [dummyIdentityNumber]="companyUser.HasDummyIdentityNumber"
          [(value)]="companyUser.UserIdentityNumber"
          [(isValid)]="isCPRValid"
          (valueChange)="onCprInfoChanged()"
        ></cpr-info>

        <!-- Role -->
        <combobox-edit-control
          [label]="'Employee.Role' | translate"
          [editMode]="editMode && allowEditRole"
          [comboboxDataSource]="roles"
          [(value)]="companyUser.RoleId"
          [textField]="'Name'"
          [idField]="'Id'"
          (valueChange)="onComboboxEditChange()"
        ></combobox-edit-control>

        <!-- Title -->
        <!--[(isValid)]="childIsValid[3]" [(isDirty)]="childIsDirty[3]"-->
        <position-info
          [editMode]="editMode && allowEdit"
          [(companyUser)]="companyUser"
          [currentPositionId]="currentPositionId"
          [(isValid)]="isPositionValid"
          [newEmploymentAdded]="newEmploymentCreated"
          [deletedEmployment]="deletedEmployment"
          [isEmployeeTab]="isEmployeeTab"
          (employmentSelectionChanged)="onUserEmploymentSelectionChange($event)"
          (positionTextChanged)="onPositionTextChanged($event)"
          (createUserEmploymentClick)="createNewUserEmployment()"
        ></position-info>

        <!-- Language -->
        <module-check [moduleId]="5" [(hasModule)]="hasLanguageModule">
          <combobox-edit-control
            [label]="'NewEmployee.Language' | translate"
            [disable]="!hasLanguageModule"
            [editMode]="editMode && (allowEdit || isGeneralTabSelfService)"
            [comboboxDataSource]="languages"
            [(value)]="companyUser.UserLanguageId"
            [textField]="'Name'"
            [idField]="'Id'"
            (valueChange)="onComboboxEditChange()"
          ></combobox-edit-control>
        </module-check>

        <!-- Payment approver -->
        <check-edit
          *ngIf="showIsPaymentApprover"
          [label]="'Employee.IsPaymentApprover' | translate"
          [(value)]="companyUser.IsPaymentApprover"
          [editMode]="editMode && allowEdit"
          (valueChange)="onCheckEditChange($event)"
        ></check-edit>

        <!-- Active -->
        <check-edit
          [label]="'Employee.IsActive' | translate"
          [(value)]="companyUser.IsActive"
          [editMode]="editMode && allowEdit"
          (valueChange)="onCheckEditChange($event)"
        ></check-edit>
      </div>

      <button class="EmployeeInfo-toggleDetails" type="button" (click)="onToggleEmployeeInfoDropdown()">
        <div>
          <app-icon *ngIf="!istoogledescription" [icon]="'ChevronDown'" [size]="'xxsmall'"></app-icon>
          <app-icon *ngIf="istoogledescription" [icon]="'ChevronUp'" [size]="'xxsmall'"></app-icon>
        </div>
      </button>
    </div>
  </div>
</div>

<new-employment
  [currentCompanyUserId]="companyUser?.Id"
  [(isVisible)]="createNewEmploymentDialogVisible"
  (newEmploymentCreated)="onNewEmploymentCreatedEvent($event)"
></new-employment>
<app-dialog [(visible)]="isNoTemplate" [leadingText]="'NewEmployeeEmployment.NoEmploymentTemplateWarning' | translate">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
