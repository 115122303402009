import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { RouterStateService } from '../../Common/RouterState.service';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'app-tab-panel',
  templateUrl: './TabPanel.component.html'
})
export class TabPanelComponent implements OnInit, OnDestroy {
  @Input() public parentTab: string;
  @Input() public payslipTabVisible = false;
  @Input() public timeTabVisible = false;

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  constructor(public session: SessionService, public state: RouterStateService) {}

  public ngOnInit(): void {
    this.setResponsiveStyle(this.session.currentState);
  }

  private ngUnsubscribe: Subject<{}> = new Subject<{}>();
  public ngOnDestroy() {
    this.ngUnsubscribe.unsubscribe();
  }

  public clickAreamenuToggledChange(): void {
    this.session.menuToggled = true;
    this.session.showUserMenuToggled = false;
    this.session.showMoreMenuToggled = false;
    this.session.showHelpMenuToggled = false;
  }

  public toggleMenuButton(): void {
    if (!this.session.menuToggled) {
      this.session.menuToggled = true;
    } else {
      this.session.menuToggled = false;
    }
    window.scrollTo(0, 0);
  }

  public navigateToState($event: any, state: string): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.setResponsiveStyle(state);
    this.session.NavigateTo(state);
  }

  private setResponsiveStyle(state: string): void {
    switch (state) {
      case 'tabs.employee.hiringstatus':
        this.session.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
        break;
      case 'tabs.employee.payrolldata':
        this.session.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
        break;
      default:
        if (this.session.currentState !== 'tabs.employee') {
          this.session.currentStyleMenuTabpanelReponsive = '';
        }
        break;
    }

    if (this.parentTab.toLowerCase() === 'company') {
      this.session.currentStyleMenuTabpanelReponsive = 'companytabPanelLongTitle';
    }
  }
}
