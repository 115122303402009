import { Component, OnInit } from '@angular/core';
import { CompositeFilterDescriptor, distinct, filterBy } from '@progress/kendo-data-query';
import { FilterDateTimeGrid } from '../../Common/FilterDateTimeGrid';
import { Global } from '../../Common/Global';
import { IAccount, ICompany, ICompanyAccountView, ISimpleCompany, IUserCompanyAccess } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { AccountantCompaniesService } from './AccountantCompanies.service';

@Component({
  selector: 'app-accountant-companies',
  templateUrl: './AccountantCompanies.component.html'
})
export class AccountantCompaniesComponent implements OnInit {
  public discardDialogVisible = false;
  public addNewCompanyDialogVisible = false;
  public createSuccessConfim = false;
  public newCompanyId: number;
  public dataRoleList: IUserCompanyAccess[];
  public dataList: ICompanyAccountView[];
  public countryCodes: any[];
  public accountName = '';
  public attachexistingcompanyVisable = false;
  public userCompanyUserViews: any[];
  public selectUserCompanyUserViews: number;
  public isUnderStoodTheAbove = false;
  public isCompleteAttached = false;
  public isFirstLoad = false;

  constructor(
    public service: AccountantCompaniesService,
    public staticData: StaticDataService,
    private api: RxDataService,
    private staticDataService: StaticDataService,
    private sessionService: SessionService,
    private dataService: RxDataService
  ) {
    this.dataService.Account_GetCurrentAccount().subscribe((account: IAccount) => {
      this.accountName = account.Name;
    });
    this.service.roles.subscribe((data) => {
      this.dataRoleList = data;
      this.dataRoleList.sort((a, b) => a.UserId - b.UserId);
      this.gridRoleFilterData = filterBy(this.dataRoleList, this.roleFilter);
    });
    this.service.companies.subscribe((data) => {
      const list = data.map((val: ICompanyAccountView) => ({
        Name: val.CountryCode,
        Id: val.CountryId,
        Key: val.CountryCode
      }));

      this.countryCodes = distinct(list, 'Name');
      this.dataList = data;
      // this.dataList.forEach((model: any) => {
      //   model.IdText = model.Id.toString();
      // });
      if (this.filter && this.filter.filters && this.filter.filters.length > 0) {
        const filterDateTimeGrid = new FilterDateTimeGrid();
        const filtersDate: any = [];
        // Using FilterDateTimeGrid to filter date before filter another field
        this.filter = filterDateTimeGrid.convertFilterOperator(
          this.filter,
          filtersDate,
          'LastFinalized',
          'NextDeadline'
        );
        this.gridFilterData = filterDateTimeGrid.doFilterDate(
          this.dataList,
          filtersDate,
          'LastFinalized',
          'NextDeadline'
        );
        this.gridFilterData = filterBy(this.gridFilterData, this.filter);
        if (filtersDate.length > 0) {
          [].push.apply(this.filter.filters, filtersDate);
        }
      } else {
        this.gridFilterData = this.dataList;
      }
    });

    this.staticData.companiesSimple.subscribe((data: ISimpleCompany[]) => {
      if (data && data.length > 0) {
        this.userCompanyUserViews = data.filter(
          (model: any) =>
            model.RoleId === 90 &&
            (!model.AccountId || (model.AccountId && model.AccountId !== Global.SESSION.SignOnToken.AccountId))
        );

        if (this.userCompanyUserViews && this.userCompanyUserViews.length > 0) {
          this.userCompanyUserViews.forEach((model: any) => {
            model.Relevant = model.Name + (Global.SESSION.HasBackendAccess ? '(' + model.Id + ')' : '');
          });
          this.selectUserCompanyUserViews = this.userCompanyUserViews[0].Id;
        }
      }
    });
  }

  public ngOnInit() {
    this.isFirstLoad = true;
  }

  public changeCompany(companyId: number): void {
    this.api.Auth_SetCompanyContext(companyId).subscribe(() => {
      Global.COMPANY_ID = companyId;
      this.staticDataService.loadStaticData().then(() => {
        this.api.Companies_GetCurrent().subscribe((activeCompany: ICompany): void => {
          Global.COMPANY = activeCompany;
          window.location.reload();
        });
      });
    });
  }

  public get activeTab(): string {
    return this.sessionService.currentState;
  }

  public onMoreMenuOptionClick(menu: string): void {
    if (menu && menu === 'accountant_attachexistingcompany') {
      this.attachexistingcompanyVisable = true;
    }
  }

  public onEditModeChange(): void {
    if (!this.service.selectedId) {
      return;
    }
    if (this.service.editMode) {
      this.service.save();
    }

    this.service.editMode = !this.service.editMode;
  }

  public confirmAndDiscardChanges(): void {
    this.discardDialogVisible = true;
  }

  public discardDialogAction(action: string): void {
    if (action === 'ConfirmDiscard') {
      this.service.discard();
      this.service.editMode = false;
    }
  }

  public roleFilter: CompositeFilterDescriptor;
  public gridRoleFilterData: IUserCompanyAccess[] = filterBy(this.dataRoleList, this.roleFilter);
  public onRoleFilterChange(filter: CompositeFilterDescriptor): void {
    if (filter && filter.filters && filter.filters.length > 0) {
      this.roleFilter = filter;
    }
    this.gridRoleFilterData = filterBy(this.dataRoleList, filter);
  }

  public filter: CompositeFilterDescriptor;
  public gridFilterData: ICompanyAccountView[] = filterBy(this.dataList, this.filter);
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    if (filter && filter.filters && filter.filters.length > 0) {
      this.filter = filter;
      const filterDateTimeGrid = new FilterDateTimeGrid();
      const filtersDate: any = [];
      // Using FilterDateTimeGrid to filter date before filter another field
      this.filter = filterDateTimeGrid.convertFilterOperator(this.filter, filtersDate, 'LastFinalized', 'NextDeadline');
      this.gridFilterData = filterDateTimeGrid.doFilterDate(
        this.dataList,
        filtersDate,
        'LastFinalized',
        'NextDeadline'
      );
      this.gridFilterData = filterBy(this.gridFilterData, this.filter);
      if (filtersDate.length > 0) {
        [].push.apply(this.filter.filters, filtersDate);
      }
    } else {
      this.gridFilterData = this.dataList;
    }
  }

  public onSelectedIdChange(selectedId: any): void {
    this.service.selectedId = selectedId;
    if (!this.isFirstLoad) {
      this.gridRoleFilterData = [];
    }
    this.isFirstLoad = false;
  }

  public onCreateSuccess(event: any): void {
    if (event) {
      this.newCompanyId = event;
      this.createSuccessConfim = true;
    }
  }

  public onDoneCreateSuccess(event: any): void {
    if (event) {
      if (event === 'Yes') {
        this.changeCompany(this.newCompanyId);
      } else {
        window.location.reload();
      }
    }
  }

  public onAttachingAnExistingCompany(event: any): void {
    if (event) {
      if (event === 'Submit') {
        this.api.Account_AttachCompany(this.selectUserCompanyUserViews).subscribe(() => {
          this.isCompleteAttached = true;
          this.isUnderStoodTheAbove = false;
        });
      }

      if (event === 'close') {
        this.isUnderStoodTheAbove = false;
      }
    }
  }

  public ReplayMoreMenuLabelAttachingAnExistingCompany(message: string): string {
    return message.replace(/{AccountName}/g, this.accountName);
  }
}
