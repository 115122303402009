import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
var EmployeeInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeInfoComponent, _super);
    function EmployeeInfoComponent(settingService, dataService, sessionService, staticDataService) {
        var _this = _super.call(this) || this;
        _this.settingService = settingService;
        _this.dataService = dataService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.selectedUserEmploymentChange = new EventEmitter();
        _this.employmentPositionChanged = new EventEmitter();
        _this.validated = new EventEmitter();
        _this.isAvatarChanged = new EventEmitter();
        _this.isEmployeeTab = false;
        _this.expandUserInfoDetails = false;
        _this.hasLanguageModule = false;
        _this.isCPRValid = true;
        _this.isNameValid = true;
        _this.isPositionValid = true;
        _this.istoogledescription = false;
        _this.createNewEmploymentDialogVisible = false;
        _this.isNoTemplate = false;
        return _this;
    }
    Object.defineProperty(EmployeeInfoComponent.prototype, "companyUser", {
        get: function () {
            if (!this.currentCompanyUser) {
                this.currentCompanyUser = {};
            }
            return this.currentCompanyUser;
        },
        set: function (value) {
            this.currentCompanyUser = value;
            this.selectEmploymentByParameter();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeInfoComponent.prototype, "currentPositionId", {
        get: function () {
            return this.positionId;
        },
        set: function (value) {
            this.positionId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeInfoComponent.prototype, "isGeneralTabSelfService", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.selfservice.general');
        },
        enumerable: true,
        configurable: true
    });
    EmployeeInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.languages
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) { return (_this.languages = data); });
        this.staticDataService.Role.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.roles = data); });
        this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (state) {
            _this.selectEmploymentByParameter();
        });
    };
    EmployeeInfoComponent.prototype.selectEmploymentByParameter = function () {
        var _this = this;
        if (this.settingService.navigationParameters['EmploymentId']) {
            var userEmploymentByParam_1 = this.companyUser && this.companyUser.UserEmployments
                ? this.companyUser.UserEmployments.find(function (employment) {
                    return employment.Id === _this.settingService.navigationParameters['EmploymentId'];
                })
                : undefined;
            if (userEmploymentByParam_1) {
                this.currentPositionId = this.settingService.navigationParameters['EmploymentId'];
                this.settingService.navigationParameters['EmploymentId'] = undefined;
                this.reloadUserEmployment().then(function (reloadedEmployment) {
                    _this.onUserEmploymentSelectionChange(reloadedEmployment ? reloadedEmployment : userEmploymentByParam_1);
                });
            }
        }
        else {
            this.reloadUserEmployment().then(function (reloadedEmployment) {
                if (reloadedEmployment) {
                    _this.onUserEmploymentSelectionChange(reloadedEmployment);
                }
            });
        }
    };
    EmployeeInfoComponent.prototype.reloadUserEmployment = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.settingService.navigationParameters['ReloadEmployment']) {
                _this.settingService.navigationParameters['ReloadEmployment'] = false;
                if (!_this.currentPositionId) {
                    return resolve(undefined);
                }
                _this.dataService.Employments_GetEmployment(_this.currentPositionId).subscribe(function (updatedEmployment) {
                    var employmentThatSync = _this.companyUser
                        ? _this.companyUser.UserEmployments.find(function (employment) { return employment.Id === _this.currentPositionId; })
                        : undefined;
                    if (employmentThatSync) {
                        _this.companyUser.UserEmployments[_this.companyUser.UserEmployments.lastIndexOf(employmentThatSync)] = updatedEmployment;
                    }
                    return resolve(updatedEmployment);
                }, function () {
                    return resolve(undefined);
                });
            }
            else {
                return resolve(undefined);
            }
        });
    };
    Object.defineProperty(EmployeeInfoComponent.prototype, "userId", {
        // #endregion
        get: function () {
            if (this.companyUser) {
                return this.companyUser.UserId;
            }
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeInfoComponent.prototype, "isFemale", {
        get: function () {
            if (this.companyUser) {
                return this.companyUser.IsFemale ? true : false;
            }
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeInfoComponent.prototype, "showIsPaymentApprover", {
        get: function () {
            return this.companyUser && this.companyUser.RoleId > 20 ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeInfoComponent.prototype, "EmployeeWithRoleAsManager", {
        get: function () {
            return this.sessionService.role.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeInfoComponent.prototype.validate = function () {
        var isValid = this.isCPRValid && this.isNameValid && this.isPositionValid;
        if (this.validated && this.editMode) {
            this.validated.emit(isValid);
        }
    };
    EmployeeInfoComponent.prototype.onUserEmploymentSelectionChange = function (userEmployment) {
        this.selectedUserEmploymentChange.emit(userEmployment);
    };
    EmployeeInfoComponent.prototype.onToggleEmployeeInfoDropdown = function () {
        this.expandUserInfoDetails = !this.expandUserInfoDetails;
        this.istoogledescription = !this.istoogledescription;
    };
    EmployeeInfoComponent.prototype.onCprInfoChanged = function () {
        this.validate();
    };
    EmployeeInfoComponent.prototype.onEmployeeNameChanged = function () {
        this.validate();
    };
    EmployeeInfoComponent.prototype.onPositionTextChanged = function (positionText) {
        this.validate();
        this.employmentPositionChanged.emit(positionText);
    };
    EmployeeInfoComponent.prototype.onComboboxEditChange = function () {
        this.validate();
    };
    EmployeeInfoComponent.prototype.onCheckEditChange = function (value) {
        this.validate();
    };
    EmployeeInfoComponent.prototype.onAvatarChanged = function () {
        this.isAvatarChanged.emit(true);
    };
    EmployeeInfoComponent.prototype.getTemplates = function () {
        var _this = this;
        this.dataService
            .EmploymentTemplates_GetEmploymentTemplates(true)
            .subscribe(function (templates) {
            if (templates.length > 0) {
                _this.createNewEmploymentDialogVisible = true;
            }
            else {
                _this.isNoTemplate = true;
            }
        });
    };
    EmployeeInfoComponent.prototype.createNewUserEmployment = function () {
        this.getTemplates();
    };
    EmployeeInfoComponent.prototype.onNewEmploymentCreatedEvent = function (newUserEmployment) {
        this.newEmploymentCreated = newUserEmployment;
        this.selectedUserEmploymentChange.emit(newUserEmployment);
    };
    return EmployeeInfoComponent;
}(FormComponentBase));
export { EmployeeInfoComponent };
