import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Browser } from '../../Common/Browser';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'configuration',
  templateUrl: './Configuration.component.html'
})
export class ConfigurationComponent implements OnInit {
  public menuOpen: boolean;

  private editModeValue = false;
  @Input()
  public get editMode(): boolean {
    return this.editModeValue;
  }
  public set editMode(value: boolean) {
    if (this.editModeValue !== value) {
      this.editModeValue = value;
      this.editModeChange.emit(value);
    }
  }
  @Output() public editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private isDirtyValue = false;
  @Input()
  public get isDirty(): boolean {
    return this.isDirtyValue;
  }
  public set isDirty(value: boolean) {
    if (this.isDirtyValue !== value) {
      this.isDirtyValue = value;
      this.isDirtyChange.emit(value);
    }
  }
  @Output() public isDirtyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get companyOptionTabEnabled(): boolean {
    return this.sessionService.role.IsFullAdmin || this.sessionService.role.IsReadOnly;
  }
  public get companyDataImportTabEnabled(): boolean {
    return !this.sessionService.role.IsReadOnly;
  }
  public get companyOptionStateActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.settings';
  }
  public get companyAdvancedSalaryTypesStateActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.advancedsalarytypes';
  }
  public get companyTimeEntryTypesStateActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.timeentrytypes';
  }
  public get companyintegrationsStateActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.integrations';
  }
  public get companyDataImportStateActive(): boolean {
    return (
      !this.sessionService.role.IsReadOnly &&
      this.sessionService.currentState === 'tabs.company.configuration.dataimport'
    );
  }
  public get companyTemplateTabVisisble(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.hiringstatus';
  }

  constructor(private sessionService: SessionService, private renderer: Renderer2) {}

  private navigationToStateName: string;
  private isCollapseButtonClick: boolean;
  public showSaveDataConfirmationChangeState = false;

  public get isHybridApp(): boolean {
    const browser: Browser = new Browser();
    return browser.isHybridApp;
  }

  public openDialogChangeAppToBrower = false;
  public onConfimOpenAppinBrower(event: string): void {}
  public ngOnInit(): void {
    this.renderer.listen(window, 'click', (event: any) => {
      if (!this.isCollapseButtonClick) {
        this.menuOpen = false;
      } else {
        this.isCollapseButtonClick = false;
      }
    });
    if (!environment.production) {
      console.log('state', this.sessionService.currentState);
    }

    if (this.sessionService.currentState === 'tabs.company.configuration') {
      if (this.companyOptionTabEnabled) {
        this.navigateTo('tabs.company.configuration.hiringstatus');
      } else {
        this.navigateTo('tabs.company.configuration.dataimport');
      }
    }
  }

  public onConfirmSaveDataOnChangeState(action: string): void {
    if (action === 'Continue') {
      this.editMode = false;
    } else if (action === 'DiscardAndLeave') {
      this.editMode = false;
      this.isDirty = false;
      if (this.navigationToStateName) {
        this.sessionService.NavigateTo(this.navigationToStateName);
      }
    }
    this.navigationToStateName = undefined;
  }

  public navigateTo(state: string): void {
    this.menuOpen = false;
    this.editMode = false;
    this.sessionService.NavigateTo(state);
  }

  public onToggleNavigation(): void {
    this.isCollapseButtonClick = true;
    this.menuOpen = !this.menuOpen;
  }
}
