import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../Services/SharedServices/StaticData.service';
import { FileUploadComponent } from './FileUpload.component';

declare var navigator: any;

@Component({
  selector: 'app-picture-edit',
  templateUrl: './PictureEdit.component.html'
})
export class PictureEditComponent {
  @Input() public editMode: boolean;
  @Input() public label: string;
  @Input() public hint: string;
  @Input() public changeText: string;
  @Input() public takePhotoText = 'Take photo';
  @Input() public isAvatar: boolean;
  @Input() public isEmployeeAvatar: boolean;

  @Input()
  public set avatarChange(value: boolean) {
    // this.currentimageSource = "";
  }

  @Input()
  public get imageSource(): string {
    return this.currentimageSource;
  }

  public set imageSource(value: string) {
    this.currentimageSource = value;
    this.imageSourceChange.emit(this.currentimageSource);
  }

  public get isIOSMobileApp(): boolean {
    return this.sessionService.browser.iOSMobileDevice;
  }

  public get isSafari(): boolean {
    return this.sessionService.browser.isSafari;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get isHybridApp(): boolean {
    return this.sessionService.browser.isHybridApp;
  }

  public get isAndroidDevice(): boolean {
    return this.sessionService.browser.isAndroidDevice;
  }

  @Output() public change: EventEmitter<any> = new EventEmitter<any>();
  @Output() public imageSourceChange: EventEmitter<string> = new EventEmitter<string>();

  public errorDialogVisible = false;

  @ViewChild('fileupload', { static: true }) private fileUpload: FileUploadComponent;

  private currentimageSource: string;

  constructor(private sessionService: SessionService, private staticDataService: StaticDataService) {}

  public onChangePictureButtonClick(): void {
    this.fileUpload.addFile();
  }

  public onTakePhotoButtonClick(): void {
    if (this.isHybridApp && this.isAndroidDevice) {
      const otps = {};
      navigator.camera.getPicture(this.onPictureSuccess, this.onPictureFail, otps);
    }
  }

  private onPictureSuccess(imageData: string) {
    const name = new Date().getTime().toString();
    const type = 'image/jpeg';
    const blob = this.staticDataService.dataURItoBlob(imageData, type);
    const file = new File([blob], 'image_'.concat(name), { type });

    const formData: FormData = new FormData();
    formData.append('file', file, 'image_'.concat(name));
    this.change.emit(formData);
  }

  private onPictureFail() {}

  public onFileSelected(file: File): void {
    const maxSize: number = 1024 * 1024 * 4;
    if (file.size > maxSize) {
      this.errorDialogVisible = true;
      return;
    }

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    this.change.emit(formData);
  }
}
