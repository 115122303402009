<div
  class="FormElement CheckBoxPadding"
  [ngClass]="{ 'FormElement-ViewMode': !editMode, 'FormElement-noHeight ': isGrid }"
>
  <div class="FormElement-control" [ngClass]="customClass">
    <label kendoTooltip [title]="tooltip" class="FormElement-checkbox {{ selfService }}" [ngClass]="labelClass">
      <input type="checkbox" [(ngModel)]="value" (change)="onChange(value)" [disabled]="isDisabled" />
      <span class="FormElement-checkboxFaux"></span>
      <span *ngIf="label && !isLink" class="FormElement-label"
        >{{ label }}
        <app-icon
          *ngIf="helpLink"
          class="infoIcon"
          [icon]="helpIcon"
          [tooltip]="helpTooltip"
          [link]="helpLink"
          [isOpenLink]="isOpenLink"
          [tooltipWidth]="340"
          [istooltipHTML]="true"
        ></app-icon>
      </span>
      <span *ngIf="label && isLink" [innerHTML]="label | translate">
        <app-icon
          *ngIf="helpLink"
          class="infoIcon"
          [icon]="helpIcon"
          [tooltip]="helpTooltip"
          [link]="helpLink"
          [isOpenLink]="isOpenLink"
          [tooltipWidth]="340"
          [istooltipHTML]="true"
        ></app-icon>
      </span>
    </label>
  </div>
</div>
