<app-dialog
  [overlayClose]="false"
  [(visible)]="isVisible"
  width="460"
  [customVisible]="true"
  (keydown)="keydown($event)"
  [title]="'Account.ResetPasswordTitle' | translate"
  (action)="onResetPasswordDialogAction($event)"
>
  <div [ngClass]="isReLogin ? 'Modal-content' : ''">
    <div innerHTML="{{ 'Account.CPRExplain' | translate }}"></div>
    <app-text-edit [label]="'Account.CPR' | translate" [editMode]="true" [(value)]="cprNo"></app-text-edit>
    <app-text-edit
      [label]="'Account.Email' | translate"
      [type]="'email'"
      [editMode]="true"
      [(value)]="email"
    ></app-text-edit>
  </div>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'ResetPassword'"></app-dialog-action>
</app-dialog>
<app-dialog
  [(visible)]="isChangePasswordSuccessDialog"
  (action)="onChangePasswordSuccessDialogAction($event)"
  [title]="'Account.ResetPasswordTitle' | translate"
  [leadingText]="'Account.SendNewPasswordMessage' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
