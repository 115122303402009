import { OnDestroy } from '@angular/core';
import { PanelBarComponent } from '@progress/kendo-angular-layout';
import { forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Global } from '../Common/Global';
import { GridHelper } from '../Common/GridHelper';
import { RxDataService } from '../Services/RxDataService';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../Services/SharedServices/StaticData.service';
import { PreferenceViewModel } from './PreferenceViewModel';
var PreferenceComponent = /** @class */ (function () {
    function PreferenceComponent(dataService, sessionService, staticData) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.staticData = staticData;
        this.companyPreferences = [];
        this.userPreferences = [];
        this.numericTextBoxOption = { min: 0, step: 1, spinners: false, decimals: 0, format: 'n0' };
        this.isDataReady = false;
        this.apiReponses = {};
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(PreferenceComponent.prototype, "owner", {
        get: function () {
            return this.ownerValue;
        },
        set: function (value) {
            if (value && value !== this.ownerValue) {
                this.ownerValue = value;
                if (this.isDataReady) {
                    return;
                }
                if (value === 'company') {
                    this.GetCurrentSettings();
                }
                if (value === 'user') {
                    this.GetUserPreferences();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreferenceComponent.prototype, "isIOSMobileApp", {
        get: function () {
            return this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreferenceComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    PreferenceComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    PreferenceComponent.prototype.isDataTypeBoolean = function (dataType) {
        return dataType.includes('CheckBox') || dataType.includes('Checkbox');
    };
    PreferenceComponent.prototype.isDataTypeNumber = function (dataType) {
        return dataType.includes('Integer');
    };
    PreferenceComponent.prototype.isDataTypeTextBox = function (dataType) {
        return dataType.includes('TextBox');
    };
    PreferenceComponent.prototype.isDataTypeCombobox = function (dataType) {
        return dataType.includes('ComboBox') || dataType.includes('Combobox');
    };
    PreferenceComponent.prototype.submitValueChange = function (value, item) {
        if (!this.isDataReady) {
            return;
        }
        item.Value = value;
        var itemToSubmit = { Key: item.Key, Value: '' + item.Value };
        this.updateSetting(itemToSubmit);
    };
    PreferenceComponent.prototype.onCompanyPanelChanged = function (event) {
        console.log(event);
    };
    PreferenceComponent.prototype.GetCurrentSettings = function () {
        var _this = this;
        observableForkJoin(this.loadCompanyReferenceInfo(), this.loadCompanyReferencesCategory(), this.loadCompanyReferencesStructure()).subscribe(function () {
            if (_this.companyReferencesCategory && _this.companyReferencesInfo && _this.companyReferencesStructure) {
                _this.createPreferences(_this.companyReferencesInfo, _this.companyReferencesStructure).then(function (preferences) {
                    _this.companyPreferences = preferences.sort(function (a, b) { return a.SortOrder - b.SortOrder; });
                    _this.companyReferencesCategory.sort(function (a, b) { return a.SortOrder - b.SortOrder; });
                    _this.companyReferencesCategory.forEach(function (category) {
                        category.Preferences = _this.companyPreferences.filter(function (pref) { return pref.CategoryId === category.Id; });
                    });
                    // Select the first Item in panel bar.
                    setTimeout(function () {
                        if (_this.companyPanelBar &&
                            _this.companyPanelBar.allItems &&
                            _this.companyPanelBar.allItems[0]) {
                            _this.companyPanelBar.allItems[0].selected = true;
                            _this.companyPanelBar.allItems[0].expanded = true;
                        }
                    });
                });
            }
            else {
                _this.companyPreferences = [];
            }
            _this.isDataReady = true;
        });
    };
    PreferenceComponent.prototype.loadCompanyReferenceInfo = function () {
        var _this = this;
        if (Global.COMPANY_PREFERENCES && Global.COMPANY_PREFERENCES.length > 0) {
            this.companyReferencesInfo = Global.COMPANY_PREFERENCES;
            return Observable.create(function (observer) {
                observer.next(_this.companyReferencesInfo);
                observer.complete();
            });
        }
        else {
            return this.dataService.Preferences_GetCompanyPreferences().pipe(map(function (data) {
                _this.companyReferencesInfo = Global.COMPANY_PREFERENCES = data;
            }));
        }
    };
    PreferenceComponent.prototype.loadCompanyReferencesCategory = function () {
        var _this = this;
        return this.dataService.StaticData_GetCompanyPreferenceCategories().pipe(map(function (data) {
            _this.companyReferencesCategory = data;
        }));
    };
    PreferenceComponent.prototype.loadCompanyReferencesStructure = function () {
        var _this = this;
        return this.dataService.StaticData_GetCompanyPreferences().pipe(map(function (staticData) {
            _this.companyReferencesStructure = staticData;
        }));
    };
    PreferenceComponent.prototype.GetUserPreferences = function () {
        var _this = this;
        if (Global.USER_PREFERENCES && Global.USER_PREFERENCES.length > 0) {
            this.dataService.StaticData_GetUserPreferences().subscribe(function (staticData) {
                _this.createPreferences(Global.USER_PREFERENCES, staticData).then(function (preferences) {
                    _this.userPreferences = preferences;
                    _this.isDataReady = true;
                });
            });
        }
        else {
            this.dataService.Preferences_GetUserPreferences().subscribe(function (keyValuePairs) {
                _this.dataService.StaticData_GetUserPreferences().subscribe(function (staticData) {
                    _this.createPreferences(keyValuePairs, staticData).then(function (preferences) {
                        _this.userPreferences = preferences;
                        _this.isDataReady = true;
                    });
                });
            });
        }
    };
    PreferenceComponent.prototype.createPreferences = function (keyValuePairs, staticData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var results = [];
            var dataSourceCount = staticData.filter(function (data) { return _this.isDataTypeCombobox(data.UiControlType); })
                .length;
            var dataSourceLoaded = 0;
            var processedPref = 0;
            var _loop_1 = function (data) {
                var pref = new PreferenceViewModel();
                if (!data.UiControlType) {
                    return "continue";
                }
                pref.Id = data.Id;
                pref.CategoryId = data.CategoryId;
                pref.Key = data.Key;
                pref.DataType = data.UiControlType;
                pref.Name = data.Name;
                pref.Description = data.Description;
                pref.HelpUrl = data.HelpUrl;
                pref.RequiredModuleId = data.RequiredModuleId;
                pref.MinimumRoleId = data.MinimumRoleId;
                pref.SortOrder = data.SortKey ? _this.sessionService.parseInt(data.SortKey) : undefined;
                pref.listDataSource = data.listDataSource;
                if (!pref.MinimumRoleId || pref.MinimumRoleId <= Global.SESSION.CurrentRole.Id) {
                    pref.IsVisible = true;
                }
                else {
                    pref.IsVisible = false;
                }
                var foundSetting = _this.foundSetting(pref.Key, keyValuePairs);
                if (foundSetting) {
                    if (_this.isDataTypeBoolean(pref.DataType)) {
                        pref.Value = foundSetting.Value === 'true' ? true : false;
                    }
                    else if (_this.isDataTypeNumber(pref.DataType)) {
                        pref.Value = parseInt(foundSetting.Value, 10);
                    }
                    else {
                        pref.Value = foundSetting.Value;
                    }
                }
                else {
                    // Setting not found - get default value
                    if (_this.isDataTypeBoolean(pref.DataType)) {
                        pref.Value = data.Default === 'true' ? true : false;
                    }
                    else if (_this.isDataTypeNumber(pref.DataType)) {
                        pref.Value = parseInt(data.Default, 10);
                    }
                    else {
                        pref.Value = data.Default;
                    }
                }
                if (_this.isDataTypeCombobox(pref.DataType)) {
                    _this.loadComboboxDataSource(results, pref).then(function () {
                        dataSourceLoaded += 1;
                        processedPref += 1;
                        results.push(pref);
                        if (dataSourceCount === dataSourceLoaded && processedPref === staticData.length) {
                            results = results.sort(function (data1, data2) {
                                return GridHelper.sortByNumberValue(data1.SortOrder, data2.SortOrder);
                            });
                            return resolve(results);
                        }
                    });
                }
                else {
                    processedPref += 1;
                    results.push(pref);
                }
            };
            for (var _i = 0, staticData_1 = staticData; _i < staticData_1.length; _i++) {
                var data = staticData_1[_i];
                _loop_1(data);
            }
            if (dataSourceCount === 0) {
                results = results.sort(function (data1, data2) {
                    return GridHelper.sortByNumberValue(data1.SortOrder, data2.SortOrder);
                });
                return resolve(results);
            }
        });
    };
    PreferenceComponent.prototype.hasDataOnClient = function (api) {
        return this.apiReponses[api];
    };
    PreferenceComponent.prototype.loadComboboxDataSource = function (preferences, control) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var listDataSourceParts = _this.getPartsFromListDataSourceStringOfCombobox(control.listDataSource);
            control.UnderlyingId = listDataSourceParts.UnderlyingId;
            control.DisplayText = listDataSourceParts.DisplayText;
            if (_this.hasDataOnClient(listDataSourceParts.api)) {
                control.DataSource = _this.apiReponses[listDataSourceParts.api];
                return resolve(control);
            }
            _this.apiReponses[listDataSourceParts.api] = []; // Block request with the same api route.
            _this.getComboboxDataSource(listDataSourceParts.api).subscribe(function (data) {
                _this.apiReponses[listDataSourceParts.api] = data;
                control.DataSource = data;
                // Update other controls.
                if (preferences && preferences.length > 0) {
                    preferences.forEach(function (pref) {
                        var prefSourceParts = _this.getPartsFromListDataSourceStringOfCombobox(pref.listDataSource);
                        if (prefSourceParts.api === listDataSourceParts.api) {
                            pref.DataSource = data;
                        }
                    });
                }
                return resolve(control);
            });
        });
    };
    PreferenceComponent.prototype.getComboboxDataSource = function (api) {
        var path = environment.apiUrl + '/' + api;
        return this.dataService.httpGet(path);
    };
    PreferenceComponent.prototype.getPartsFromListDataSourceStringOfCombobox = function (stringListDataSource) {
        stringListDataSource = stringListDataSource.replace(':', ';');
        var parts = stringListDataSource.split(';');
        return {
            api: parts[0],
            UnderlyingId: parts[1],
            DisplayText: parts[2]
        };
    };
    PreferenceComponent.prototype.foundSetting = function (key, pairs) {
        for (var _i = 0, pairs_1 = pairs; _i < pairs_1.length; _i++) {
            var pair = pairs_1[_i];
            if (pair.Key === key) {
                return pair;
            }
        }
        return undefined;
    };
    PreferenceComponent.prototype.updateSetting = function (item) {
        var _this = this;
        if (this.owner === 'company') {
            this.dataService.Preferences_SetCompanyPreference(item).subscribe(function (response) {
                var currentCompanySettings = JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES));
                Global.COMPANY_PREFERENCES = _this.updateLocalStorageSetting(response, currentCompanySettings);
            }, function (complete) {
                if (complete.status) {
                    _this.GetCurrentSettings();
                }
            });
        }
        if (this.owner === 'user') {
            this.dataService.Preferences_SetUserPreference(item).subscribe(function (response) {
                var currentUserSettings = JSON.parse(JSON.stringify(Global.USER_PREFERENCES));
                Global.USER_PREFERENCES = _this.updateLocalStorageSetting(response, currentUserSettings);
            }, function (complete) {
                if (complete.status) {
                    _this.GetCurrentSettings();
                }
            });
        }
    };
    PreferenceComponent.prototype.updateLocalStorageSetting = function (newSetting, oldSettings) {
        var existingSetting = oldSettings.find(function (pref) { return pref.Key === newSetting.Key; });
        if (existingSetting) {
            existingSetting.Value = newSetting.Value;
        }
        else {
            oldSettings.push(newSetting);
        }
        return oldSettings;
    };
    return PreferenceComponent;
}());
export { PreferenceComponent };
