import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserEmployment } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
var NewEmploymentComponent = /** @class */ (function () {
    function NewEmploymentComponent(dataService, sessionService, staticDataService) {
        var _this = this;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.newEmploymentCreated = new EventEmitter();
        this.isVisibleChange = new EventEmitter();
        this.isVisibleValue = false;
        this.isHiringDateValid = true;
        this.defaultTitle = 'Medarbejder';
        this.ngUnsubscribe = new Subject();
        this.showNoTemplateWarning = false;
        this.viewModel = new UserEmployment();
        this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.taxCardTypes = data); });
        this.getTitles();
    }
    Object.defineProperty(NewEmploymentComponent.prototype, "isVisible", {
        get: function () {
            return this.isVisibleValue;
        },
        set: function (value) {
            if (value && this.isVisible !== value) {
                this.prepareNewEmploymentData();
            }
            this.isVisibleValue = value;
            this.isVisibleChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    NewEmploymentComponent.prototype.ngOnInit = function () { };
    NewEmploymentComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    NewEmploymentComponent.prototype.onDialogAction = function (action) {
        if (action === 'Create') {
            this.onCreateNew();
        }
    };
    NewEmploymentComponent.prototype.getTitles = function () {
        var _this = this;
        this.staticDataService.employmentTitle
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (templates) {
            _this.jobFunctionCodeStrings = templates;
        });
    };
    NewEmploymentComponent.prototype.onJobFunctionCodeChanged = function () { };
    NewEmploymentComponent.prototype.onHiringDatePickerChanged = function (args) {
        if (args) {
            this.isHiringDateValid = !args.isInvalid;
        }
    };
    NewEmploymentComponent.prototype.onCreateNew = function () {
        if (!this.viewModel.Title || this.viewModel.Title.length === 0 || !this.isHiringDateValid) {
            return;
        }
        var userEmployment = this.createNewUserEmploymentObject(this.viewModel.HireDate, this.viewModel.Title, this.viewModel.BasedOnTemplateId, this.viewModel.PreferredTaxCardTypeId);
        this.createUserEmployment(userEmployment);
    };
    NewEmploymentComponent.prototype.prepareNewEmploymentData = function () {
        this.getTemplates();
    };
    NewEmploymentComponent.prototype.showNewUserEmploymentDialog = function () {
        this.viewModel.Title = this.defaultTitle;
        this.viewModel.HireDate = this.staticDataService.getCurrentdate();
        this.viewModel.PreferredTaxCardTypeId =
            this.taxCardTypes && this.taxCardTypes.length > 1 ? this.taxCardTypes[0].Id : undefined;
    };
    NewEmploymentComponent.prototype.createNewUserEmploymentObject = function (hireDate, title, templateId, taxCardTypeId) {
        var newUserEmployment = new UserEmployment();
        newUserEmployment.CompanyUserId = this.currentCompanyUserId;
        newUserEmployment.Title = title;
        newUserEmployment.BasedOnTemplateId = Number(templateId);
        newUserEmployment.PreferredTaxCardTypeId = Number(taxCardTypeId);
        newUserEmployment.VacationReduceSalary = false;
        newUserEmployment.WorksElsewhere = false;
        newUserEmployment.IsAtpTempWorker = false;
        newUserEmployment.HireDate = hireDate;
        return newUserEmployment;
    };
    NewEmploymentComponent.prototype.getTemplates = function () {
        var _this = this;
        this.dataService
            .EmploymentTemplates_GetEmploymentTemplates()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (templates) {
            _this.templates = templates;
            if (_this.templates && _this.templates.length > 0) {
                _this.viewModel.BasedOnTemplateId = _this.templates[0].Id;
                _this.showNewUserEmploymentDialog();
            }
            else {
                _this.showNoTemplateWarning = true;
            }
        });
    };
    NewEmploymentComponent.prototype.createUserEmployment = function (userEmployment) {
        var _this = this;
        this.dataService
            .Employments_CreateUserEmployment(userEmployment)
            .subscribe(function (response) { return _this.createUserEmploymentCompleted(response); });
    };
    NewEmploymentComponent.prototype.createUserEmploymentCompleted = function (employment) {
        this.newEmploymentCreated.emit(employment);
    };
    return NewEmploymentComponent;
}());
export { NewEmploymentComponent };
