import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type
} from '@angular/core';
import { DialogComponent } from '../../../CustomControls/Dialog/Dialog.component';
import { DialogActionComponent } from '../../../CustomControls/Dialog/DialogAction.component';
import { SessionService } from '../Session/SessionService';
import { IFrameDialogComponent } from './IFrameDialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private session: SessionService
  ) {}

  public showMessageBox(content: string, actions: string[] = ['Ok'], title: string = ' '): void {
    let buttons: DialogActionComponent[] = [];
    if (actions) {
      buttons = actions.map((action: string) => {
        const button: DialogActionComponent = new DialogActionComponent(this.session);
        button.type = action;
        return button;
      });
    }

    const hostDialogRef: ComponentRef<DialogComponent> = this.appendDialogComponentToBody(DialogComponent);
    hostDialogRef.instance.title = title;
    hostDialogRef.instance.leadingText = content;
    hostDialogRef.instance.actions = buttons;
    hostDialogRef.instance.visible = true;
    hostDialogRef.instance.visibleChange.subscribe((visible: boolean) => {
      if (!visible) {
        buttons.forEach((b: DialogActionComponent) => b.ngOnDestroy());
        this.removeDialogComponentFromBody(hostDialogRef);
      }
    });
  }

  public showIFrameDialog(content: string, title: string = ' '): void {
    const hostDialogRef: ComponentRef<IFrameDialogComponent> = this.appendDialogComponentToBody(IFrameDialogComponent);
    hostDialogRef.instance.title = title;
    hostDialogRef.instance.visible = true;
    hostDialogRef.instance.iframeContent = content;
    hostDialogRef.instance.visibleChange.subscribe((visible: boolean) => {
      if (!visible) {
        this.removeDialogComponentFromBody(hostDialogRef);
      }
    });
  }

  private appendDialogComponentToBody<T>(component: Type<T>): ComponentRef<T> {
    const componentFactory: ComponentFactory<T> = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef: ComponentRef<T> = componentFactory.create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    const domElem: HTMLElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    return componentRef;
  }

  private removeDialogComponentFromBody(componentRef: ComponentRef<any>): void {
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }
}
