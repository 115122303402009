import { EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ReflectionHelper } from '../../../Common/ReflectionHelper';
import { ImportButtonAction, ImportResultEnum, ImportTypesEnum } from '../../../Model/Enum';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { ImportMappingDataItem } from './ImportMappingDataItem';
import { ImportMappingDataSource } from './ImportMappingDataSource';
import { ImportOptionHelper } from './ImportOptionHelper';
import { ImportSaveMappingParameter } from './ImportSaveMappingParameter';
import { MappingObject } from './MappingObject';
var ImportMappingDialogComponent = /** @class */ (function () {
    function ImportMappingDialogComponent(dataService, sessionService, staticData) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.staticData = staticData;
        this.isExistingMapping = false;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.formDataChange = new EventEmitter();
        this.startIndexChange = new EventEmitter();
        this.closedEvent = new EventEmitter();
        this.saveMapingEvent = new EventEmitter();
        // Template properties
        this.userColumnsValues = [];
        this.option = { min: 0, step: 1, spinners: false, decimals: 0, format: 'n0' };
        this.isButtonDisabled = false;
        this.alertDialogVisible = false;
        this.successDialogVisible = false;
        this.importOnceAlertDialogVisible = false;
        this.mappingData = []; //// Consider to use importMappingDataDataSource instead.
        this.importSpecifications = [];
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(ImportMappingDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
                if (value) {
                    this.showDataImportDialog();
                }
                else {
                    this.startIndex = undefined;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportMappingDialogComponent.prototype, "formData", {
        get: function () {
            return this.formDataValue;
        },
        set: function (value) {
            if (this.formDataValue !== value) {
                this.formDataValue = value;
                this.formDataChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportMappingDialogComponent.prototype, "startIndex", {
        get: function () {
            return this.startIndexValue;
        },
        set: function (value) {
            if (this.startIndexValue !== value) {
                this.startIndexValue = value;
                this.startIndexChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    ImportMappingDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    ImportMappingDialogComponent.prototype.onDialogAction = function (action) {
        switch (action) {
            case 'Test':
                this.onTestClicked();
                break;
            case 'ImportOnce':
                this.onImportOnceClicked();
                break;
            case 'SaveDefinition':
                this.onSaveChangesClicked();
                break;
            default:
                this.onCancelClicked();
        }
    };
    ImportMappingDialogComponent.prototype.closeDialogAndFireClosedEvent = function () {
        this.closeDialog();
        this.closedEvent.emit();
    };
    ImportMappingDialogComponent.prototype.onComboboxChange = function (value, item) {
        if (value) {
            var previousKey = this.getColumnTypeValue(item.Key);
            this.importMappingDataDataSource.data[item.Key].columnsTypeValue = value;
            this.importMappingDataDataSource.addAvailableItemToComboboxDataSources(item.Key, previousKey);
            var selectedMappingObject = this.importMappingDataDataSource.findSelectedColumnTypeItemById(value);
            if (selectedMappingObject) {
                this.updateKeyValuePairs(item.Key, selectedMappingObject.Key);
                this.importMappingDataDataSource.removeColumnTypeDropDownItem(item.Key, selectedMappingObject.Key);
            }
        }
    };
    ImportMappingDialogComponent.prototype.onGratisalComboboxChange = function (value, item) {
        if (value) {
            this.setAmountAsDefaultOfColumnType(value, item.Key);
        }
    };
    ImportMappingDialogComponent.prototype.onStartIndexChanged = function () {
        this.prepareForCustomImport();
    };
    ImportMappingDialogComponent.prototype.onCancelClicked = function () {
        var _this = this;
        if (this.createdTempMappingDetail) {
            this.dataService.Import_DeleteMapping(this.tempMappingDetail.Id).subscribe(function () {
                _this.tempMappingDetail = undefined;
                _this.mappingDetail = undefined;
                _this.closeDialog();
            });
            return;
        }
        this.closeDialog();
    };
    ImportMappingDialogComponent.prototype.onTestClicked = function () {
        var _this = this;
        if (!this.validataMappings()) {
            return;
        }
        var mappingString = this.convertToMappingObject(this.mappingData);
        if (!mappingString) {
            this.alertMessage = 'CompanyDataImport.NoMappingYet';
            this.alertDialogVisible = true;
            return;
        }
        if (!this.mappingDetail) {
            this.createImportMappings(mappingString).then(function () {
                _this.testImportData(_this.tempMappingDetail.Id);
            });
            return;
        }
        this.updateImportMappings(mappingString).then(function (mapping) {
            _this.testImportData(_this.mappingDetail.Id);
        });
    };
    ImportMappingDialogComponent.prototype.onImportOnceClicked = function () {
        var _this = this;
        if (!this.validataMappings()) {
            return;
        }
        var mappingString = this.convertToMappingObject(this.mappingData);
        if (!mappingString) {
            this.alertMessage = 'CompanyDataImport.NoMappingYet';
            this.alertDialogVisible = true;
            return;
        }
        if (!this.mappingDetail) {
            this.createImportMappings(mappingString).then(function () {
                _this.ImportOneImportData(_this.tempMappingDetail.Id);
            });
            return;
        }
        this.updateImportMappings(mappingString).then(function (mapping) {
            _this.ImportOneImportData(_this.mappingDetail.Id);
        });
    };
    ImportMappingDialogComponent.prototype.onSaveChangesClicked = function () {
        var _this = this;
        if (!this.validataMappings()) {
            return;
        }
        var mappingString = this.convertToMappingObject(this.mappingData);
        if (!mappingString) {
            this.alertMessage = 'CompanyDataImport.NoMappingYet';
            this.alertDialogVisible = true;
            return;
        }
        if (!this.mappingDetail) {
            this.createImportMappings(mappingString).then(function () {
                if (!_this.isExistingMapping) {
                    _this.mappingDetail = undefined;
                }
                _this.tempMappingDetail = undefined;
                _this.successDialogVisible = true;
            });
            return;
        }
        this.updateImportMappings(mappingString).then(function (mapping) {
            if (!_this.isExistingMapping) {
                _this.mappingDetail = undefined;
            }
            _this.tempMappingDetail = undefined;
            _this.fireSaveMapingEvent(mapping.Id);
            _this.successDialogVisible = true;
        });
    };
    ImportMappingDialogComponent.prototype.showDataImportDialog = function () {
        this.isButtonDisabled = false;
        this.prepareForCustomImport();
    };
    Object.defineProperty(ImportMappingDialogComponent.prototype, "createdTempMappingDetail", {
        get: function () {
            return this.tempMappingDetail && !this.isExistingMapping;
        },
        enumerable: true,
        configurable: true
    });
    ImportMappingDialogComponent.prototype.closeDialog = function () {
        var _this = this;
        this.isButtonDisabled = true;
        this.visible = false;
        if (this.createdTempMappingDetail) {
            this.dataService.Import_DeleteMapping(this.mappingDetail.Id).subscribe(function () {
                _this.tempMappingDetail = undefined;
                _this.mappingDetail = undefined;
            });
        }
    };
    ImportMappingDialogComponent.prototype.displayExistingPayrollMappings = function () {
        var _this = this;
        if (!this.mappingDetail.ColumnMapping) {
            return;
        }
        var pairs = JSON.parse(this.mappingDetail.ColumnMapping);
        if (pairs) {
            pairs.forEach(function (pair, index) {
                var importMappingDataItem = _this.importMappingDataDataSource.data[pair.Value];
                _this.extractGratisalValueAndColumnTypeValue(importMappingDataItem, pair.Key);
            });
        }
    };
    ImportMappingDialogComponent.prototype.extractGratisalValueAndColumnTypeValue = function (importMappingDataItem, key) {
        var keyValue = key.split(';');
        if (!importMappingDataItem || keyValue.length < 1) {
            return;
        }
        if (keyValue.length < 2) {
            importMappingDataItem.GratisalColumnsValue = keyValue[0];
            var amountColumn = importMappingDataItem.findColumnTypeItem(1); //// Amount
            if (!amountColumn) {
                console.warn("Can't find Amount in the ColumnTypeDataSource");
                return;
            }
            importMappingDataItem.columnsTypeValue = amountColumn.Id;
            return;
        }
        if (!keyValue[0]) {
            importMappingDataItem.GratisalColumnsValue = keyValue[1];
            importMappingDataItem.columnsTypeValue = undefined;
            return;
        }
        importMappingDataItem.GratisalColumnsValue = keyValue[0];
        var columnItem = importMappingDataItem.findColumnTypeItemByKey(keyValue[1]);
        importMappingDataItem.columnsTypeValue = columnItem ? columnItem.Id : importMappingDataItem.columnsTypeValue;
    };
    ImportMappingDialogComponent.prototype.displayExistingMappings = function () {
        var _this = this;
        if (!this.mappingDetail.ColumnMapping) {
            return;
        }
        var pairs = this.mappingDetail.ColumnMapping.split(';');
        if (pairs) {
            pairs.forEach(function (pair, index) {
                var keyValue = pair.split(':');
                if (keyValue.length > 1) {
                    var gratisalKey = keyValue[0];
                    var userFileId = keyValue[1];
                    if (_this.importMappingDataDataSource.data[userFileId]) {
                        var columnItem = _this.importMappingDataDataSource.data[userFileId].findColumnTypeItemByKey(gratisalKey);
                        _this.importMappingDataDataSource.data[userFileId].columnsTypeValue = columnItem
                            ? columnItem.Id
                            : _this.importMappingDataDataSource.data[userFileId].columnsTypeValue;
                        _this.updateKeyValuePairs(userFileId, gratisalKey);
                        if (columnItem) {
                            var selectedMappingObject = _this.importMappingDataDataSource.findSelectedColumnTypeItemById(columnItem.Id.toString());
                            _this.importMappingDataDataSource.removeColumnTypeDropDownItem(userFileId, selectedMappingObject.Key);
                        }
                    }
                }
            });
        }
    };
    ImportMappingDialogComponent.prototype.prepareForCustomImport = function () {
        var _this = this;
        // Check for existing mapping
        if (this.importSpecifications.length === 0) {
            this.staticData.ImportSpecification.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (importSpecifications) {
                _this.importSpecifications = importSpecifications;
                _this.updateSpecificationOptions();
                _this.onStartMapping();
            });
        }
        else {
            this.updateSpecificationOptions();
            this.onStartMapping();
        }
    };
    ImportMappingDialogComponent.prototype.updateSpecificationOptions = function () {
        var _this = this;
        var specification;
        if (this.importSpecifications) {
            specification = this.importSpecifications.find(function (item) {
                return item.Key === ImportOptionHelper.getImportTypeKey(_this.importType);
            });
        }
        if (specification) {
            this.importSpecificationOptions = ImportOptionHelper.createImportOptionObjects(specification.ImportOptions);
        }
    };
    ImportMappingDialogComponent.prototype.onStartMapping = function () {
        var _this = this;
        var startIndex = this.startIndex;
        var importType = ImportOptionHelper.getImportTypeKey(this.importType);
        if (!this.formData || importType.length === 0 || !startIndex) {
            return;
        }
        // Reset mapping data before start
        this.mappingData = [];
        this.userColumnsValues = [];
        this.dataService
            .ImportMapping_GetExcelHeadersOfFile(this.formData, importType, startIndex)
            .subscribe(function (result) {
            if (!result) {
                _this.showNoColumnsFoundMessage();
                return;
            }
            _this.onStartMappingCompleted(result, importType);
        });
    };
    ImportMappingDialogComponent.prototype.showNoColumnsFoundMessage = function () {
        this.alertMessage = 'CreateDataImportTemplate.NoColumnsFound';
        this.alertDialogVisible = true;
        this.isButtonDisabled = true;
        this.visible = false;
    };
    ImportMappingDialogComponent.prototype.onStartMappingCompleted = function (result, importType) {
        var _this = this;
        if (result && result.length >= 0) {
            var userColumnArray = result.split(';');
            this.userColumnsValues = this.splitOperations(userColumnArray);
            // Set default mapping data
            if (this.userColumnsValues && this.userColumnsValues.length > 0) {
                this.importMappingDataDataSource = new ImportMappingDataSource();
                this.userColumnsValues.forEach(function (col) {
                    _this.importMappingDataDataSource.data[col.Key] = new ImportMappingDataItem();
                    _this.importMappingDataDataSource.data[col.Key].userFileColumnHeader = col.Name;
                    _this.importMappingDataDataSource.data[col.Key].userFileId = col.Key;
                    var keyValPairItem = { Key: col.Key, Value: '-1' };
                    _this.mappingData.push(keyValPairItem);
                });
            }
            // Get object columns based on selectedImportType
            if (this.importType === ImportTypesEnum.Payroll) {
                this.loadGratisalColumnsDropDownDataSource().then(function (salaryTypes) {
                    _this.loadComboboxesDataSources(importType);
                });
            }
            else if (this.importType === ImportTypesEnum.TimeEntries) {
                this.loadGratisalColumnsDropDownDataSource().then(function (salaryTypes) {
                    _this.loadComboboxesDataSources(importType);
                });
            }
            else {
                this.loadComboboxesDataSources(importType);
            }
        }
    };
    ImportMappingDialogComponent.prototype.loadGratisalColumnsDropDownDataSource = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.dataService
                .Import_GetPayrollDataColumnHeaders()
                .subscribe(function (salaryTypes) {
                var noneDynamicSalaryRecordImportColumn = _this.createNoneDynamicSalaryRecordImportColumn();
                salaryTypes.unshift(noneDynamicSalaryRecordImportColumn);
                _this.importMappingDataDataSource.setGratisalColumnsDropDownDataSourceAndAutoMap(salaryTypes, !_this.isExistingMapping);
                return resolve(salaryTypes);
            });
        });
    };
    ImportMappingDialogComponent.prototype.setAmountAsDefaultOfColumnType = function (gratisalNewValue, key) {
        var importMappingDataItem = this.importMappingDataDataSource.data[key];
        if (!importMappingDataItem || importMappingDataItem.columnsTypeValue) {
            return;
        }
        var columTypeEditable = gratisalNewValue && gratisalNewValue !== '-1' && !importMappingDataItem.IsId;
        var columnTypeValueEmpty = !importMappingDataItem.columnsTypeValue || importMappingDataItem.columnsTypeValue === -1;
        var columnTypeValue;
        var columnTypeKey;
        if (columTypeEditable && columnTypeValueEmpty) {
            columnTypeValue = 1;
            var mappingObject = importMappingDataItem.findColumnTypeItem(1);
            columnTypeKey = mappingObject ? mappingObject.Key : columnTypeKey;
        }
        importMappingDataItem.columnsTypeValue = columnTypeValue;
    };
    ImportMappingDialogComponent.prototype.setAmountAsDefaultOfColumnTypes = function () {
        for (var key in this.importMappingDataDataSource.data) {
            if (this.importMappingDataDataSource.data.hasOwnProperty(key)) {
                var gratisalValue = this.importMappingDataDataSource.data[key].GratisalColumnsValue;
                this.setAmountAsDefaultOfColumnType(gratisalValue, key);
            }
        }
    };
    ImportMappingDialogComponent.prototype.updateKeyValuePairs = function (userItemKey, objectItemKey) {
        var keyValPairItem = { Key: userItemKey, Value: objectItemKey };
        if (this.mappingData.length === 0) {
            this.mappingData.push(keyValPairItem);
        }
        else {
            // First look up if it's updating an existing keyValuePair
            var foundIndex = this.findExistingItem(userItemKey, this.mappingData);
            if (foundIndex < 0) {
                // No existing item, can add normally
                this.mappingData.push(keyValPairItem);
            }
            else {
                // Update the object item with the new selection
                this.mappingData[foundIndex].Value = objectItemKey;
            }
        }
    };
    ImportMappingDialogComponent.prototype.getColumnTypeValue = function (userItemKey) {
        var value;
        var foundIndex = this.findExistingItem(userItemKey, this.mappingData);
        if (foundIndex > -1) {
            value = this.mappingData[foundIndex].Value;
        }
        if (!value) {
            return undefined;
        }
        var columnItem = this.importMappingDataDataSource.data[userItemKey].findColumnTypeItemByKey(value);
        return columnItem ? columnItem.Key.toString() : undefined;
    };
    ImportMappingDialogComponent.prototype.findExistingItem = function (userItemKey, collection) {
        var result = collection.findIndex(function (value) { return value.Key === userItemKey; });
        return result;
    };
    ImportMappingDialogComponent.prototype.hasError = function (result) {
        return result.Errors && result.Errors.length > 0;
    };
    ImportMappingDialogComponent.prototype.validataMappings = function () {
        if (this.mappingName.length === 0) {
            this.alertMessage = 'CompanyDataImport.MappingNameEdit';
            this.alertDialogVisible = true;
            return false;
        }
        if (!this.startIndex) {
            this.alertMessage = 'CompanyDataImport.MissingStartRow';
            this.alertDialogVisible = true;
            return false;
        }
        return true;
    };
    ImportMappingDialogComponent.prototype.testImportData = function (mappingId) {
        var _this = this;
        if (!mappingId || mappingId < 1) {
            return;
        }
        var optionString = ImportOptionHelper.getInportOptionsString(this.importSpecificationOptions, ImportButtonAction.Test);
        this.dataService
            .ImportMapping_CustomImportExcelOptions(mappingId, optionString, this.formData)
            .subscribe(function (result) { return _this.onTestImportCompleted(result); });
    };
    ImportMappingDialogComponent.prototype.ImportOneImportData = function (mappingId) {
        var _this = this;
        if (!mappingId || mappingId < 1) {
            return;
        }
        var optionString = ImportOptionHelper.getInportOptionsString(this.importSpecificationOptions, ImportButtonAction.Import);
        this.dataService
            .ImportMapping_CustomImportExcelOptions(mappingId, optionString, this.formData)
            .subscribe(function (result) { return (_this.importOneResults = result); });
    };
    ImportMappingDialogComponent.prototype.onTestImportCompleted = function (result) {
        this.alertMessage =
            result && result.Outcome === ImportResultEnum[ImportResultEnum.FatalError] && result.FatalMessage
                ? result.FatalMessage
                : 'CompanyDataImport.TestImportSuccessMessage';
        if (!this.hasError(result)) {
            this.alertDialogVisible = true;
            return;
        }
        this.importResults = result;
    };
    ImportMappingDialogComponent.prototype.onCompleteImportOneEvent = function () {
        this.onCancelClicked();
    };
    ImportMappingDialogComponent.prototype.updateImportMappings = function (mappingString) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (!mappingString) {
                // return deferred.promise;
            }
            _this.mappingDetail.ColumnMapping = mappingString;
            _this.mappingDetail.StartRow = _this.startIndex;
            _this.dataService.Import_UpdateMapping(_this.mappingDetail).subscribe(function (mapping) {
                _this.mappingDetail = mapping;
                return resolve(mapping);
            });
        });
    };
    ImportMappingDialogComponent.prototype.createImportMappings = function (mappingString) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (!mappingString) {
                // return deferred.promise;
            }
            var simpleImportMapping = _this.createSimpleImportMapping(mappingString);
            _this.dataService.Import_SaveMapping(simpleImportMapping).subscribe(function (mapping) {
                _this.tempMappingDetail = mapping;
                _this.mappingDetail = mapping;
                return resolve(simpleImportMapping);
            });
        });
    };
    ImportMappingDialogComponent.prototype.createSimpleImportMapping = function (mappingString) {
        var simpleImportMapping = {
            Mapping: mappingString,
            Name: this.mappingName,
            StartRow: this.startIndex,
            Operation: ImportOptionHelper.getImportTypeKey(this.importType)
        };
        return simpleImportMapping;
    };
    ImportMappingDialogComponent.prototype.fireSaveMapingEvent = function (mappingId) {
        if (this.saveMapingEvent) {
            this.saveMapingEvent.emit(mappingId);
        }
    };
    ImportMappingDialogComponent.prototype.convertToMappingObject = function (mappingData) {
        var parameters = '';
        if (this.importType === ImportTypesEnum.Payroll) {
            var data = this.importMappingDataDataSource.data;
            var index = 0;
            for (var key in data) {
                if (data.hasOwnProperty(key)) {
                    var parameter = new ImportSaveMappingParameter();
                    parameter.value = data[key].userFileId;
                    parameter.selectedColumnTypeValue = data[key].columnsTypeValue;
                    parameter.selectedColumnTypeKey = data[key].columnsTypeKey;
                    parameter.selectedGratisalColumnValue = data[key].GratisalColumnsValue;
                    var strParameter = parameter.toString();
                    if (strParameter.length > 0) {
                        if (parameters.length > 0) {
                            parameters += ',';
                        }
                        parameters += strParameter;
                    }
                    index++;
                }
            }
            parameters = '[' + parameters + ']';
        }
        else {
            if (mappingData) {
                mappingData.forEach(function (element) {
                    if (element.Value && element.Value !== '-1') {
                        var col = element.Value + ':' + element.Key;
                        parameters += col + ';';
                    }
                });
            }
        }
        return parameters;
    };
    ImportMappingDialogComponent.prototype.loadComboboxesDataSources = function (importType) {
        var _this = this;
        if (this.importSpecifications.length < 0) {
            this.importMappingDataDataSource.setColumnTypeDropDownDataSource([]);
            return;
        }
        var importSpecification = this.getImportSpecification(importType);
        if (!importSpecification) {
            this.importMappingDataDataSource.setColumnTypeDropDownDataSource([]);
            return;
        }
        this.getComboboxDasource(importSpecification).then(function (dataSource) {
            var noneMappingObject = _this.createNoneMappingObject();
            dataSource.unshift(noneMappingObject);
            _this.importMappingDataDataSource.setColumnTypeDropDownDataSource(dataSource);
            if (!_this.isExistingMapping) {
                _this.setAmountAsDefaultOfColumnTypes();
            }
            else {
                if (_this.importType === ImportTypesEnum.Payroll) {
                    _this.displayExistingPayrollMappings();
                }
                else {
                    _this.displayExistingMappings();
                }
            }
        });
    };
    ImportMappingDialogComponent.prototype.getImportSpecification = function (importType) {
        if (this.importSpecifications) {
            return this.importSpecifications.find(function (value) {
                return value.Key === importType;
            });
        }
    };
    ImportMappingDialogComponent.prototype.createNoneDynamicSalaryRecordImportColumn = function () {
        return {
            IsId: false,
            Name: '',
            Value: '-1'
        };
    };
    ImportMappingDialogComponent.prototype.createNoneMappingObject = function () {
        return {
            Id: -1,
            SortIndex: -1,
            Name: ' ',
            IsSingle: false,
            Key: '-1'
        };
    };
    ImportMappingDialogComponent.prototype.getComboboxDataSourceFromColumnsString = function (columnsString, defaultSingleColumns) {
        var columns = columnsString.split(';');
        var hasColumns = columns && columns.length > 0;
        if (hasColumns) {
            return this.splitOperations(columns, defaultSingleColumns);
        }
        return [];
    };
    ImportMappingDialogComponent.prototype.getComboboxDasource = function (importSpecification) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var comboboxDataSource = [];
            var columnsHeadersString = importSpecification.Columns;
            var dynamicColumnsString = importSpecification.DynamicColumns;
            var defaultSingleColumns = importSpecification.DefaultSingleColumns;
            var isColumnsTypeString = importSpecification.Columns && typeof importSpecification.Columns === 'string';
            var isDynamicColumns = _this.importType !== ImportTypesEnum.Payroll &&
                _this.importType !== ImportTypesEnum.PayrollRow &&
                _this.importType !== ImportTypesEnum.TimeEntries &&
                _this.importType !== ImportTypesEnum.TimeEntriesRow &&
                importSpecification.DynamicColumns &&
                importSpecification.DynamicColumns !== 'none';
            if (!isColumnsTypeString) {
                return resolve(comboboxDataSource);
            }
            if (!isDynamicColumns) {
                if (_this.importType === ImportTypesEnum.Payroll ||
                    _this.importType === ImportTypesEnum.PayrollRow ||
                    _this.importType === ImportTypesEnum.TimeEntries ||
                    _this.importType === ImportTypesEnum.TimeEntriesRow) {
                    var dynamicDimensions_1;
                    var operationName = ImportOptionHelper.getImportTypeKey(_this.importType).toLowerCase();
                    _this.dataService
                        .Import_GetEncodedDimensions(operationName)
                        .pipe(finalize(function () {
                        var dimensionString = '';
                        if (!ReflectionHelper.isString(dynamicDimensions_1)) {
                            dynamicDimensions_1.forEach(function (item) {
                                dimensionString += item.Name + ':' + item.Value + ';';
                            });
                        }
                        else {
                            dimensionString = dynamicDimensions_1;
                        }
                        comboboxDataSource = _this.getComboboxDataSourceFromColumnsString(columnsHeadersString + ';' + dimensionString, defaultSingleColumns);
                        // console.log(comboboxDataSource);
                        return resolve(comboboxDataSource);
                    }))
                        .subscribe(function (dimensions) {
                        dynamicDimensions_1 = dimensions;
                    });
                }
                else {
                    comboboxDataSource = _this.getComboboxDataSourceFromColumnsString(columnsHeadersString, defaultSingleColumns);
                    return resolve(comboboxDataSource);
                }
            }
            else {
                _this.dataService
                    .Import_GetPayrollDataColumnHeaders()
                    .subscribe(function (dynamicColumnHeaders) {
                    comboboxDataSource = _this.getComboboxDataSourceFromColumnsString(columnsHeadersString, defaultSingleColumns);
                    var id = comboboxDataSource.length;
                    if (dynamicColumnHeaders) {
                        dynamicColumnHeaders.forEach(function (dynamicColumnHeader) {
                            var mappingObject = new MappingObject();
                            mappingObject.Id = id;
                            mappingObject.SortIndex = id;
                            mappingObject.IsSingle = defaultSingleColumns;
                            mappingObject.Name = dynamicColumnHeader.Name;
                            mappingObject.Key = dynamicColumnHeader.Value;
                            comboboxDataSource.push(mappingObject);
                        });
                    }
                    return resolve(comboboxDataSource);
                });
            }
        });
    };
    ImportMappingDialogComponent.prototype.splitOperations = function (inputArray, defaultSingleColumns) {
        if (defaultSingleColumns === void 0) { defaultSingleColumns = false; }
        var resultArray = [];
        if (inputArray) {
            inputArray.forEach(function (value, index) {
                var keyValPair = value.split(':');
                if (keyValPair && keyValPair.length === 2 && keyValPair[0].length > 0) {
                    var mappingObj = {
                        Id: index + 1,
                        SortIndex: index + 1,
                        IsSingle: defaultSingleColumns,
                        Name: keyValPair[0],
                        Key: keyValPair[1]
                    };
                    resultArray.push(mappingObj);
                }
            });
        }
        return resultArray;
    };
    return ImportMappingDialogComponent;
}());
export { ImportMappingDialogComponent };
