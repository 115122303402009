<div>
  <div class="Cards" *ngIf="!hasUserEmployment">
    <div class="Card">
      <div class="Card-inner">
        <h2 class="Card-title">{{ 'EmploymentPayrollData.No Employment' | translate }}</h2>
        <button-component
          [buttonClass]="'Button--primary'"
          *ngIf="allowEdit"
          (click)="createUserEmployment()"
          [label]="'EmployeeGeneral.Create employment' | translate"
        ></button-component>
      </div>
    </div>
  </div>

  <div *ngIf="hasUserEmployment && !isMobile">
    <div class="{{ ClassCardStack }}">
      <app-payroll-data-grid
        [(isHidePreviewGrid)]="isHidePreviewGrid"
        class="Card Employee-payrollDataCard"
        [(recalculate)]="recalculate"
        [userEmployment]="userEmployment"
        (update)="onUpdate()"
      ></app-payroll-data-grid>
      <payroll-preview
        [isHidePreviewGrid]="isHidePreviewGrid"
        class="Card Employee-payrollDataPreviewCard"
        [userEmployment]="userEmployment"
        [(recalculatePreview)]="recalculate"
        [isUpdate]="isUpdate"
      ></payroll-preview>
    </div>

    <div style="position: absolute;left: 30%;">
      <button-component
        [buttonClass]="'Button--card'"
        (click)="viewFullSalaryBatch()"
        [label]="'EmploymentPayrollData.View Full Salary Batch' | translate"
      ></button-component>
      <button-component
        [buttonClass]="'Button--card'"
        (click)="viewLastPayslip()"
        [label]="'EmploymentPayrollData.ViewLastPayslip' | translate"
      ></button-component>
    </div>
  </div>

  <div *ngIf="hasUserEmployment && isMobile">
    <app-employee-payroll-data-mobile
      [userEmployment]="userEmployment"
      [(isAddNew)]="isAddNew"
    ></app-employee-payroll-data-mobile>
  </div>

  <app-dialog
    *ngIf="!isCordovaApp"
    [(visible)]="newTabBlockedDialogVisible"
    [leadingText]="'Report.NewTabBlocked' | translate"
  >
    <app-dialog-action [type]="'Ok'"></app-dialog-action>
  </app-dialog>

  <app-dialog [(visible)]="noPayslipDialogVisible" [leadingText]="'EmployeePayslip.NoPayslip' | translate">
    <app-dialog-action [type]="'Ok'"></app-dialog-action>
  </app-dialog>

  <app-dialog class="PayslipPopup" [(visible)]="previewDialogVisible" [iframeContent]="previewContent"></app-dialog>
</div>
