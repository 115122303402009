<div class="Card">
  <form class="Card-inner">
    <fieldset class="Form--horizontal">
      <module-check [moduleId]="4">
        <h2 class="Card-title">{{ 'CompanyGeneral.Departments' | translate }}</h2>

        <check-edit
          [label]="'CompanyGeneral.ShowInactiveDepartments' | translate"
          [editMode]="true"
          [(value)]="showInactiveDepartments"
        ></check-edit>

        <grid
          [(data)]="gridData"
          class="FormElement-grid Company-DepartmentGrid"
          [editMode]="!IsReadOnly"
          [addNew]="!IsReadOnly"
          [deleteRow]="!IsReadOnly"
          [deleteRowField]="'IsActive'"
          [addNewDisable]="sessionService.isGetting"
          [addNewText]="'CompanyGeneral.AddNewDepartment' | translate"
          [(selectedItem)]="selectedDepartment"
          [idColumn]="'GridId'"
          [customDeleteRow]="true"
          [deleteRowField]="'IsActive'"
          (removeEvent)="onRemoveDepartment($event)"
          (addNewEvent)="onAddNew($event)"
          (saveChangesEvent)="onSave($event)"
          [(triggerUpdate)]="refreshData"
          [filterable]="'menu'"
          [filter]="filter"
          (filterChange)="onFilterChange($event)"
        >
          <gridColumn
            [isFilterable]="true"
            [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
            [field]="'Name'"
            [type]="'text'"
            [editable]="true"
            [title]="'CompanyGeneral.Department Name' | translate"
            [width]="120"
          ></gridColumn>
          <gridColumn
            [isFilterable]="true"
            [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
            [field]="'ManagerCompanyUserId'"
            [type]="'combobox'"
            [comboboxDataSource]="contacts"
            [type]="'combobox'"
            [comboboxValueField]="'Id'"
            [comboboxDisplayField]="'FullName'"
            [editable]="true"
            [title]="'CompanyGeneral.Manager' | translate"
            [width]="100"
          ></gridColumn>
          <gridColumn
            [isFilterable]="true"
            [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
            [field]="'ExternalReference'"
            [type]="'text'"
            [editable]="true"
            [title]="'CompanyGeneral.ERP ref' | translate"
            [width]="95"
          ></gridColumn>
          <gridColumn
            [isFilterable]="true"
            [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
            [field]="'EIncomeReference'"
            [type]="'text'"
            [editable]="true"
            [title]="'CompanyGeneral.EIncomeReference' | translate"
            [width]="95"
          ></gridColumn>
        </grid>
      </module-check>
    </fieldset>
  </form>
</div>

<app-dialog
  [(visible)]="deleteWarningVisible"
  (action)="onDeleteWarningAction($event)"
  [leadingText]="'Grids.Confirm Delete' | translate"
>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Delete'"></app-dialog-action>
</app-dialog>
