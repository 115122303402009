import { EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { filterBy } from '@progress/kendo-data-query';
import { Global } from '../../../Common/Global';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
var TimeEntryTypeConfigurationsComponent = /** @class */ (function () {
    function TimeEntryTypeConfigurationsComponent(dataService, sessionService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.timeEntryTypeConfigurations = [];
        this.unitTypes = Global.UNIT_TYPES;
        this.NumericOptions = { format: 'n2', decimals: 4, step: 1, min: 0, spinners: false };
        this.flagCreate = 0;
        this.refreshData = false;
        this.keyPress = { 17: false, 18: false, 107: false, 109: false };
        this.gridData = filterBy(this.timeEntryTypeConfigurations, this.filter);
    }
    Object.defineProperty(TimeEntryTypeConfigurationsComponent.prototype, "timeEntryTypeId", {
        get: function () {
            return this.timeEntryTypeIdValue;
        },
        set: function (value) {
            this.timeEntryTypeIdValue = value;
            this.loadTimeEntryTypeConfigurations();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypeConfigurationsComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    TimeEntryTypeConfigurationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataService
            .EmploymentTemplates_GetEmploymentTemplates()
            .subscribe(function (templates) { return (_this.employmentTemplates = templates); });
        this.dataService
            .Employments_GetEmploymentViews()
            .subscribe(function (userEmploymentViews) { return (_this.employmentViews = userEmploymentViews); });
    };
    Object.defineProperty(TimeEntryTypeConfigurationsComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    TimeEntryTypeConfigurationsComponent.prototype.loadTimeEntryTypeConfigurations = function () {
        var _this = this;
        this.gridData = [];
        if (this.timeEntryTypeId) {
            this.dataService
                .TimeEntryTypes_GetTimeEntryTypeConfigurationsByTimeEntry(this.timeEntryTypeId)
                .subscribe(function (response) {
                if (response && response.length > 0) {
                    _this.timeEntryTypeConfigurations = response;
                    _this.timeEntryTypeConfigurations.forEach(function (modal) {
                        modal.IsEmploymentTemplateEditable =
                            modal.EmploymentTemplateId || (modal.UserEmploymentId === null && modal.EmploymentTemplateId === null);
                        modal.IsUserEmploymentEditable =
                            modal.UserEmploymentId || (modal.UserEmploymentId === null && modal.EmploymentTemplateId === null);
                    });
                    _this.gridData = filterBy(_this.timeEntryTypeConfigurations, _this.filter);
                }
            });
        }
    };
    TimeEntryTypeConfigurationsComponent.prototype.addHandler = function (dataItem) {
        var defaultDataItem = this.createNewDataItem();
        Object.assign(dataItem, defaultDataItem);
    };
    TimeEntryTypeConfigurationsComponent.prototype.removeHandler = function (dataItem) {
        if (dataItem && dataItem.Id) {
            this.dataService.TimeEntryTypes_DeleteTimeEntryTypeConfiguration(dataItem.Id).subscribe();
        }
        else {
            var dataIndex = this.timeEntryTypeConfigurations.indexOf(dataItem);
            this.timeEntryTypeConfigurations.splice(dataIndex, 1);
            this.gridData = filterBy(this.timeEntryTypeConfigurations, this.filter);
        }
    };
    TimeEntryTypeConfigurationsComponent.prototype.saveChanges = function (args) {
        var _this = this;
        var dataItem = args.dataItem ? args.dataItem : args;
        if (dataItem) {
            dataItem.IsUserEmploymentEditable =
                dataItem.UserEmploymentId !== null ||
                    (dataItem.UserEmploymentId === null && dataItem.EmploymentTemplateId === null);
            dataItem.IsEmploymentTemplateEditable =
                dataItem.EmploymentTemplateId !== null ||
                    (dataItem.UserEmploymentId === null && dataItem.EmploymentTemplateId === null);
        }
        if (dataItem.Id) {
            this.dataService.TimeEntryTypes_UpdateTimeEntryTypeConfiguration(dataItem).subscribe(function () {
                _this.loadTimeEntryTypeConfigurations();
            });
        }
        else {
            this.dataService
                .TimeEntryTypes_CreateTimeEntryTypeConfiguration(dataItem)
                .subscribe(function (response) {
                // dataItem.Id = response.Id;
                Object.assign(dataItem, response);
            });
        }
    };
    TimeEntryTypeConfigurationsComponent.prototype.createNewDataItem = function () {
        this.flagCreate++;
        return {
            Id: null,
            TimeEntryTypeId: this.timeEntryTypeId,
            SalaryTypeId: this.salaryTypes && this.salaryTypes.length > 0 ? this.salaryTypes[0].SalaryTypeId : null,
            Description: null,
            Amount: null,
            AmountPerUnit: null,
            AppliesBefore: null,
            AppliesBeforeDate: null,
            AppliesAfter: null,
            AppliesAfterDate: null,
            AppliesWeekdays: true,
            AppliesWeekends: true,
            AppliesHolidays: true,
            AppliesSaturdays: true,
            AppliesSundays: true,
            AmountPerKilometer: null,
            EmploymentTemplateId: null,
            TargetUnitTypeId: null,
            UserEmploymentId: null,
            ConversionFactor: null,
            SalaryTypeName: this.salaryTypes[0].Name,
            flagCreate: this.flagCreate,
            IsEmploymentTemplateEditable: true,
            IsUserEmploymentEditable: true
        };
    };
    TimeEntryTypeConfigurationsComponent.prototype.onKeyDown = function (event) {
        if (event.code === 'Escape') {
            this.visible = false;
            this.visibleChange.emit(false);
        }
        if (!this.IsReadOnly) {
            if (event.keyCode in this.keyPress) {
                this.keyPress[event.keyCode] = true;
                if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
                    var defaultDataItem = this.createNewDataItem();
                    this.addHandler(defaultDataItem);
                    this.saveChanges(defaultDataItem);
                    this.setKeypress();
                    this.timeEntryTypeConfigurations.push(defaultDataItem);
                    this.gridData = filterBy(this.timeEntryTypeConfigurations, this.filter);
                    this.refreshData = true;
                    this.kendoGrid.editCell(this.timeEntryTypeConfigurations.length - 1, 0, this.createFormGroup(defaultDataItem));
                }
                else if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
                    if (this.selectedItem) {
                        this.removeHandler(this.selectedItem);
                        this.setKeypress();
                        this.refreshData = true;
                        this.selectedItem = undefined;
                    }
                }
            }
            else {
                this.setKeypress();
            }
        }
    };
    TimeEntryTypeConfigurationsComponent.prototype.setKeypress = function () {
        this.keyPress[17] = false;
        this.keyPress[18] = false;
        this.keyPress[107] = false;
        this.keyPress[109] = false;
    };
    TimeEntryTypeConfigurationsComponent.prototype.createFormGroup = function (dataItem) {
        return new FormGroup({
            Id: new FormControl(dataItem ? dataItem.Id : null),
            TimeEntryTypeId: new FormControl(dataItem ? dataItem.TimeEntryTypeId : null),
            SalaryTypeId: new FormControl(dataItem ? dataItem.SalaryTypeId : null),
            Description: new FormControl(dataItem ? dataItem.Description : null),
            Amount: new FormControl(dataItem ? dataItem.Amount : null),
            AmountPerUnit: new FormControl(dataItem ? dataItem.AmountPerUnit : null),
            AppliesBefore: new FormControl(dataItem ? dataItem.AppliesBefore : null),
            AppliesBeforeDate: new FormControl(dataItem ? dataItem.AppliesBeforeDate : null),
            AppliesAfter: new FormControl(dataItem ? dataItem.AppliesAfter : null),
            AppliesAfterDate: new FormControl(dataItem ? dataItem.AppliesAfterDate : null),
            AppliesWeekdays: new FormControl(dataItem ? dataItem.AppliesWeekdays : null),
            AppliesWeekends: new FormControl(dataItem ? dataItem.AppliesWeekends : null),
            AppliesHolidays: new FormControl(dataItem ? dataItem.AppliesHolidays : null),
            AmountPerKilometer: new FormControl(dataItem ? dataItem.AmountPerKilometer : null),
            SalaryTypeName: new FormControl(dataItem ? dataItem.SalaryTypeName : null)
        });
    };
    TimeEntryTypeConfigurationsComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridData = filterBy(this.timeEntryTypeConfigurations, filter);
    };
    return TimeEntryTypeConfigurationsComponent;
}());
export { TimeEntryTypeConfigurationsComponent };
