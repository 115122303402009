import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { ImportTypesEnum, SalaryBatchStatusEnum } from '../../Model/Enum';
import {
  IApiUser,
  IExternalSystemOperation,
  IIntegration,
  ISalaryBatch,
  ISalaryBatchRequest,
  ISalaryBatchView,
  ISalaryCycle,
  ISimpleSalaryStatement,
  IUserEmploymentView,
  SalaryBatch,
  SalaryPeriod
} from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { BusyIndicatorService, LoadingIndicatorTextType } from '../../Services/SharedServices/BusyIndicatorService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { ReportParametersDefaultValue } from '../../SharedComponents/ReportsDialog/ReportParametersDefaultValue';
import { CompanySalaryBatchesChildFormEntityContext } from './CompanySalaryBatchesChildFormEntityContext';
import { CompanySalaryBatchesEntityContext } from './CompanySalaryBatchesEntityContext';
import { SalaryBatchViewModel } from './SalaryBatchViewModel';

declare var $: any;

@Component({
  selector: 'company-salary-batches',
  templateUrl: './CompanySalaryBatches.component.html'
})
export class CompanySalaryBatchesComponent implements OnInit, OnDestroy, AfterViewInit {
  public entityContext: CompanySalaryBatchesEntityContext = new CompanySalaryBatchesEntityContext();
  public errorListDetailsDialogVisible = false;
  public lockSalaryBatchDialogVisible = false;
  public salaryBatchFinalizationStep1Completed = false;
  public salaryBatchFinalizationStep2Completed = false;
  public salaryBatchFinalizationStep3Completed = false;
  public salaryBatchFinalizationStep3RecalculationConfirm = false;
  public salaryBatchFinalizationCloseAll = false;
  public showRevertSalaryBatchDialog = false;
  public salaryBatchEntityContext: CompanySalaryBatchesChildFormEntityContext;
  public session: IApiUser = Global.SESSION;
  public Integration2Operation: IIntegration[];
  public IntergrationOpation: IExternalSystemOperation[];
  public salaryStatements: ISimpleSalaryStatement[] = [];
  public integrationName: string;
  public reportParams: ReportParametersDefaultValue;
  public reportDialogVisible = false;
  public alertDialogVisible = false;
  public alertDialogType: string;
  public salaryBatchDialogVisible = false;
  public confirmPasswordDialogVisible = false;
  public confirmDeleteDialogVisible = false;
  public confirmRevertLockDialogVisible = false;
  public confirmRejectDialogVisible = false;
  public externalSystemName: string;
  public chooseResendTargetDialogVisible: boolean;
  public resendBatchExtCompletedDialogVisible = false;
  public resendBatchToEIncomeDialogVisible = false;
  public resendBatchToIntegrationConfirmationDialogVisible: boolean;
  public emptySalaryBatchDialogVisible = false;
  public isEmptyEmployeeFromCycle = true;
  public noEmployeeActiveVisible = false;

  // Parameter for splitter X
  private splitter: any;
  private cont1: any;
  private cont2: any;
  private lastX: any;
  private windowWidth: any;

  // Parameter for splitter Y
  private splitterY: any;
  private cont1Y: any;
  private cont1Y1: any;
  private cont2Y: any;
  private cont2Y1: any;
  private cont2Y2: any;
  private lastY: any;
  private windowHeight: any;

  public get IsMobile(): boolean {
    return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
  }
  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }
  //public get autoRecalculate(): boolean { return this.entityContext.AutoRecalDraftPreference.Value === "true"; }

  public tooltipFinalizeContent = '';
  public tooltipFinalizeButton(): string {
    if (this.sessionService.role.IsReadOnly) {
      return 'CompanySalaryBatches.DenyFinalize_Text';
    } else {
      if (!this.session.IsPaymentApprover) {
        return 'CompanySalaryBatches.DenyFinalize_AdminText';
      }
    }
    return '';
  }

  public onsalaryBatchFinalizationStep2Completed(): void {
    this.salaryBatchFinalizationStep2Completed = true;
  }
  public onsalaryBatchFinalizationStep3Completed(): void {
    this.salaryBatchFinalizationStep3Completed = true;
  }

  private selectedComboboxSalaryBatchIdValue: number;
  public get selectedComboboxSalaryBatchId(): number {
    return this.selectedComboboxSalaryBatchIdValue;
  }
  public set selectedComboboxSalaryBatchId(value: number) {
    if (this.selectedComboboxSalaryBatchIdValue !== value) {
      this.selectedComboboxSalaryBatchIdValue = value;
      const selectedBatch: SalaryBatchViewModel = value
        ? this.entityContext.SalaryBatches.find((item: SalaryBatchViewModel) => item.Id === value)
        : undefined;
      const jsonSelectedBatch = JSON.stringify(selectedBatch);
      const jsonEntityContextSelectedBatch = JSON.stringify(this.entityContext.SelectedSalaryBatch);
      if (this.isMobile && jsonSelectedBatch !== jsonEntityContextSelectedBatch) {
        this.selectedSalaryBatchChanged(selectedBatch);
      }
    }
  }

  private salaryCycleIdValue: number;
  public get salaryCycleId(): number {
    return this.salaryCycleIdValue;
  }
  public set salaryCycleId(value: number) {
    if (this.salaryCycleIdValue !== value) {
      this.salaryCycleIdValue = value;
      this.filtersalaryBatchesDataSource();
    }
  }

  public subTab: string;
  public menuToggle = false;
  public basicSalaryBatch: SalaryBatchViewModel;
  public childWindowSalaryBatch: SalaryBatchViewModel;

  private exportNewPayrollBatchId: number;
  private emptySalaryBatchShown: boolean;
  private resendBatchId: number;
  private resendBatchToEIncomeId: number;
  private resendBatchToIntegrationId: number;
  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  constructor(
    private busyIndicatorService: BusyIndicatorService,
    private settingService: SettingService,
    private dataService: RxDataService,
    private staticDataService: StaticDataService,
    public sessionService: SessionService,
    private renderer: Renderer2
  ) {
    ////this.salaryBatchEntityContext = {} as any;
  }

  public ngOnInit(): void {
    this.subTab = 'Basic';
    this.menuToggle = false;
    this.dataService.Miscellaneous_GetUsedSalaryCycles().subscribe((data: ISalaryCycle[]) => {
      this.entityContext.SalaryCycles = data;
      // if (data && data.length > 0) {
      //     this.isEmptyEmployeeFromCycle = false;
      // }
    });

    this.dataService
      .Employments_GetEmploymentViews()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userEmployments: IUserEmploymentView[]) => {
        if (userEmployments && userEmployments.length > 0) {
          this.isEmptyEmployeeFromCycle = false;
        }
      });

    this.staticDataService.ExternalSystemOperation.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IExternalSystemOperation[]) => {
        this.IntergrationOpation = data;
        const objectfillter: IExternalSystemOperation = this.IntergrationOpation.find(
          (model: IExternalSystemOperation) => model.Key === 'ExportNewPayrollBatch'
        );
        if (objectfillter) {
          this.exportNewPayrollBatchId = objectfillter.Id;
          if (this.exportNewPayrollBatchId) {
            // this.staticDataService.GetIntegration2Operation(
            //   this.exportNewPayrollBatchId,
            //   this.Integration2Operation,
            //   this.integrationName
            // );
            this.GetIntegration2Operation();
          }
        }
      }
    );

    this.initializeComponent();
    this.tooltipFinalizeContent = this.tooltipFinalizeButton();

    if (this.IsActive) {
      this.settingService.showModuleMessage('Company.SalaryBatches');
    }

    this.settingService.showNavigationMessage();
  }

  private unsubscribeResize: () => void;

  public ngAfterViewInit(): void {
    // this.unsubscribeResize = this.renderer.listen(window, 'resize', () => {
    //   if (window.innerWidth > 1330) {
    //     this.initSplitterY();
    //     this.initSplitter();
    //   } else {
    //     this.resetSplitter();
    //   }
    // });
    // if (window.innerWidth > 1330) {
    //   setTimeout(() => {
    //     this.initSplitterY();
    //     this.initSplitter();
    //   }, 2000);
    // }
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    // this.ngUnsubscribe.next();
    // this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public onMoreMenuOptionClick(action: string): void {
    switch (action) {
      case 'salaryBatches_recalculateDraft':
        this.onRecalculate();
        break;
      case 'salaryBatches_importData':
        this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Payroll;
        this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
        break;
    }
  }

  public showErrorModal(data: SalaryBatchViewModel): void {
    this.errorListDetailsDialogVisible = true;
  }

  public preDataLoad: SalaryBatchViewModel;
  public selectedSalaryBatchChanged(data: SalaryBatchViewModel): void {
    const previous: any = this.entityContext.SelectedSalaryBatch;
    this.entityContext.SelectedSalaryBatch = undefined;
    this.entityContext.SelectedSalaryBatch = data;
    this.basicSalaryBatch = data ? JSON.parse(JSON.stringify(data)) : undefined;
    this.childWindowSalaryBatch = data ? JSON.parse(JSON.stringify(data)) : undefined;
    this.preDataLoad = data ? JSON.parse(JSON.stringify(data)) : undefined;
    this.getPayslips(data ? data.Id : undefined);
    this.selectDefaultBasicTab();
  }

  public salaryTypeTotals: any[];
  public unitTotals: any[];
  public reloadcaculate(model: SalaryBatchViewModel): void {
    if (model && model.Id) {
      this.dataService.SalaryBatches_GetSalaryBatchTotalsBySalaryType(model.Id).subscribe((data: any[]) => {
        this.salaryTypeTotals = [];
        this.salaryTypeTotals = data;
      });

      this.dataService.SalaryBatches_GetSalaryBatchUnitTotals(model.Id).subscribe((data: any[]) => {
        this.unitTotals = [];
        this.unitTotals = data;
      });

      this.dataService
        .SalaryStatements_GetSalaryStatementsByBatchSimplified(model.Id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: ISimpleSalaryStatement[]) => {
          this.salaryStatements = [];
          this.salaryStatements = data;
        });
    }
  }

  public onRecalculate(): void {
    if (this.entityContext.SelectedSalaryBatch) {
      this.busyIndicatorService.setLoadingIndicatorVisibility(true, LoadingIndicatorTextType.RECALCULATE);
      this.dataService.SalaryBatches_RecalculateSalaryBatch(this.entityContext.SelectedSalaryBatch.Id).subscribe(
        () => {
          this.getSalaryBatches();
          this.closeDialog();
          this.busyIndicatorService.setLoadingIndicatorVisibility(false);
          this.reloadcaculate(this.preDataLoad);
        },
        () => {
          this.busyIndicatorService.setLoadingIndicatorVisibility(false);
        },
        () => {
          this.busyIndicatorService.setLoadingIndicatorVisibility(false);
        }
      );
    }
  }

  public prepareReportDialogParams(): void {
    if (this.entityContext.SelectedSalaryBatch) {
      this.reportParams = new ReportParametersDefaultValue();
      this.reportParams.salaryBatchId = this.entityContext.SelectedSalaryBatch.Id;
      this.reportDialogVisible = true;
    } else {
      this.alertDialogType = 'noBatchSelectedWarning';
      this.alertDialogVisible = true;
    }
  }

  private prechildWindowSalaryBatch: SalaryBatchViewModel;
  public showNewSalaryBatchDialog(): void {
    if (this.isEmptyEmployeeFromCycle) {
      this.noEmployeeActiveVisible = true;
      return;
    }
    this.prechildWindowSalaryBatch = this.childWindowSalaryBatch;
    this.childWindowSalaryBatch = undefined;
    this.salaryBatchDialogVisible = true;
  }

  public onEditSalaryBatch(batch: SalaryBatchViewModel): void {
    if (!batch) {
      return;
    }

    this.basicSalaryBatch = JSON.parse(JSON.stringify(batch));
    this.childWindowSalaryBatch = JSON.parse(JSON.stringify(batch));
    this.salaryBatchDialogVisible = true;
  }

  public onSalaryBatchDialogAction(event: { action: string; context: any }): void {
    this.salaryBatchEntityContext = event.context;
    switch (event.action) {
      case 'Create':
      case 'Save':
        this.onDone();
        break;
      case 'Delete':
        this.onDelete();
        break;
      case 'SubmitForApproval':
        this.onLockSalaryBatch();
        break;
      case 'Reject':
        this.onRejectSalaryBatch();
        break;
      case 'RevertToDraft':
        this.onLockSalaryBatch();
        break;
      case 'Finalize':
        this.onFinalize();
        break;
      case 'Resend':
        this.resendBatch(this.salaryBatchEntityContext.BatchId);
        break;
      case 'Revert':
        this.showRevertSalaryBatchDialog = true;
        break;
      // case "Close":
      //     if (this.IsReadOnly || !this.salaryBatchEntityContext.IsDraft) {
      //         this.closeDialog();
      //     }
      //     break;
      default:
        this.onDone(true);
    }
  }

  public onConfirmDeleteDialogAction(action: string): void {
    if (action === 'Delete') {
      this.deleteSalaryBatch(this.salaryBatchEntityContext.BatchId);
    }
  }

  public onConfirmRevertLockDialogAction(action: string): void {
    if (action === 'Ok') {
      this.dataService
        .SalaryBatches_ResetBatchToDraft(this.salaryBatchEntityContext.BatchId)
        .subscribe(() => this.reloadSalaryBatches());
    }
  }

  public reloadSalaryBatches(): void {
    this.closeDialog();
    this.getSalaryBatches();
  }

  public onChooseResendTargetDialogAction(action: string): void {
    if (action === 'eincome') {
      this.resendBatchToEIncome(this.resendBatchId);
    } else if (action === 'external') {
      this.apiResendToIntegration(this.resendBatchId);
    }
  }

  public resendBatch(batchId: number): void {
    if (this.IsGreenlandCompany) {
      if (this.integrationName) {
        this.externalSystemName = this.integrationName;
        this.resendBatchToIntegrationConfirmation(batchId);
      }
    } else {
      if (this.integrationName) {
        this.resendBatchId = batchId;
        this.externalSystemName = this.integrationName;
        this.chooseResendTargetDialogVisible = true;
      } else {
        this.resendBatchToEIncome(batchId);
      }
    }
  }

  private resendBatchToEIncome(batchId: number): void {
    this.resendBatchToEIncomeId = batchId;
    this.resendBatchToEIncomeDialogVisible = true;
  }

  public onResendBatchToEIncomeDialogAction(action: string): void {
    if (action === 'Resend') {
      this.apiResendToEIncome(this.resendBatchToEIncomeId);
    }
  }

  private resendBatchToIntegrationConfirmation(batchId: number): void {
    this.resendBatchToIntegrationId = batchId;
    this.resendBatchToIntegrationConfirmationDialogVisible = true;
  }

  public onResendBatchToIntegrationDialogAction(action: string): void {
    if (action === 'Resend') {
      this.apiResendToIntegration(this.resendBatchToIntegrationId);
    }
  }

  public closeFinalizationWizardDialogs(): void {
    this.salaryBatchFinalizationCloseAll = true;
    this.closeDialog();
    this.confirmPasswordDialogVisible = false;
  }

  public onFinalizationProcessDone(): void {
    this.closeFinalizationWizardDialogs();
    this.getSalaryBatches();
  }

  public onConfirmPasswordDialogAction(action: string): void {
    switch (action) {
      case 'Cancel':
        this.closeFinalizationWizardDialogs();
        break;
      case 'AcceptWarnings':
        this.salaryBatchFinalizationStep1Completed = true;
        break;
      default:
        this.closeDialog();
    }
  }

  public onEmptySalaryBatchDialogAction(action: string): void {
    if (action === 'Yes') {
      this.emptySalaryBatchShown = true;
      this.showNewSalaryBatchDialog();
    }
  }

  public getSalaryBatches(salaryBatchId?: number): void {
    this.busyIndicatorService.setLoadingIndicatorVisibility(true, LoadingIndicatorTextType.RECALCULATE);
    this.dataService.SalaryBatches_GetSalaryBatches().subscribe(
      (salaryBatches: ISalaryBatchView[]) => {
        this.entityContext.SalaryBatches =
          salaryBatches.length > 0
            ? salaryBatches.map((s: ISalaryBatchView) => new SalaryBatchViewModel(s, this.sessionService))
            : [];
        this.filtersalaryBatchesDataSource(salaryBatchId);
        this.basicSalaryBatch = this.entityContext.SelectedSalaryBatch
          ? JSON.parse(JSON.stringify(this.entityContext.SelectedSalaryBatch))
          : undefined;

        this.emptySalaryBatchShown = salaryBatches.length > 0 ? true : this.emptySalaryBatchShown;
        if (
          this.sessionService.role.IsSalaryAdminAndHigher &&
          salaryBatches.length <= 0 &&
          !this.emptySalaryBatchShown
        ) {
          this.emptySalaryBatchDialogVisible = true;
        }
        this.busyIndicatorService.setLoadingIndicatorVisibility(false);
      },
      () => {
        this.busyIndicatorService.setLoadingIndicatorVisibility(false);
      }
    );
  }

  private setDefaultSalaryBatchForMobileDevice(): void {
    if (this.IsMobile) {
      if (this.entityContext.SalaryBatches && this.entityContext.SalaryBatches.length > 0) {
        const hasItemSelected: boolean = this.entityContext.SalaryBatches.some(
          (b: SalaryBatchViewModel) => b.Id === this.selectedComboboxSalaryBatchId
        );
        if (!hasItemSelected) {
          this.selectedComboboxSalaryBatchId = this.entityContext.SalaryBatches[0].Id;
        }
      } else {
        this.entityContext.SelectedSalaryBatch = undefined;
        this.selectedComboboxSalaryBatchId = undefined;
      }
    }
  }

  public closeDialog(): void {
    this.salaryBatchDialogVisible = false;
    this.salaryBatchFinalizationStep1Completed = false;
    this.salaryBatchFinalizationStep2Completed = false;
    this.salaryBatchFinalizationStep3Completed = false;
    if (!this.childWindowSalaryBatch) {
      this.basicSalaryBatch = this.prechildWindowSalaryBatch
        ? JSON.parse(JSON.stringify(this.prechildWindowSalaryBatch))
        : undefined;
      this.childWindowSalaryBatch = this.prechildWindowSalaryBatch
        ? JSON.parse(JSON.stringify(this.prechildWindowSalaryBatch))
        : undefined;
    }
  }

  // Using static dataService observer to prevent Integrations_GetIntegrationsByOperation run twice
  private GetIntegration2Operation(): void {
    this.dataService
      .Integrations_GetIntegrationsByOperation(this.exportNewPayrollBatchId)
      .subscribe((model: IIntegration[]) => {
        this.Integration2Operation = model;
        if (this.Integration2Operation && this.Integration2Operation.length > 0) {
          this.integrationName = this.Integration2Operation[0].Name;
        }
      });
  }

  private get IsActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.salarybatches';
  }

  private get hasManyOpenDrafts(): boolean {
    return (
      this.entityContext.SalaryBatches &&
      this.entityContext.SalaryBatches.filter(
        (batch: ISalaryBatchView) => batch.StatusId === SalaryBatchStatusEnum.Draft
      ).length >= 3
    );
  }

  private initializeComponent(): void {
    this.entityContext.Company = Global.COMPANY;
    // if (Global.USER_PREFERENCES) {
    //     this.entityContext.AutoRecalDraftPreference = Global.USER_PREFERENCES.find((p: ISimpleKeyValuePair) => p.Key === "SalaryBatches.AutoRecalcDrafts");
    // }

    // if (!this.entityContext.AutoRecalDraftPreference) {
    //     this.entityContext.AutoRecalDraftPreference = {
    //         Key: "SalaryBatches.AutoRecalcDrafts",
    //         Value: "true"
    //     };
    // }

    if (!this.IsReadOnly /*&& this.entityContext.IsRecalculateDraft*/) {
      this.getSalaryBatches();
      // this.recalculateDrafts();
    } else {
      this.getSalaryBatches();
    }
  }

  private onShowConfirmPasswordWindow(): void {
    this.salaryBatchDialogVisible = false;
    this.salaryBatchEntityContext.Password = '';
    this.confirmPasswordDialogVisible = true;
  }

  public onRevertBatchCompleted(): void {
    this.getSalaryBatches();
    this.closeDialog();
  }

  public onFinalize(): void {
    if (this.entityContext.SelectedSalaryBatch) {
      let payoutDate: Date = new Date(this.entityContext.SelectedSalaryBatch.PayoutDate);
      payoutDate = this.staticDataService.getCurrentdate(undefined, payoutDate);
      if (
        (this.salaryBatchEntityContext.Message !== this.entityContext.SelectedSalaryBatch.Message ||
          this.salaryBatchEntityContext.PaymentDate !== payoutDate) &&
        this.entityContext.SelectedSalaryBatch.StatusId <= 10 &&
        Global.SESSION.CurrentRole.Id >= 60
      ) {
        this.saveSalaryBatchChanges(true).then((salaryRecord: ISalaryBatch) => {
          if (this.salaryBatchEntityContext && salaryRecord) {
            this.salaryBatchEntityContext.HasErrors = salaryRecord.HasErrors;
            this.salaryBatchEntityContext.HasWarnings = salaryRecord.HasWarnings;
            this.beginFinalization();
          }
        });
      } else {
        this.beginFinalization();
      }
    } else {
      this.alertDialogType = 'noBatchSelectedWarning';
      this.alertDialogVisible = true;
    }
  }

  private beginFinalization(): void {
    if (Global.SESSION && !Global.SESSION.IsPaymentApprover) {
      if (Global.SESSION.CurrentRole.Id === 90) {
        // Administrator (FullAdmin)
        this.alertDialogType = 'denyFinalizeAdminText';
        this.alertDialogVisible = true;
      } else {
        this.alertDialogType = 'denyFinalizeText';
        this.alertDialogVisible = true;
      }

      return;
    }

    if (this.salaryBatchEntityContext.HasErrors) {
      this.alertDialogType = 'finalizeErrorText';
      this.alertDialogVisible = true;
      return;
    }

    if (!this.salaryBatchEntityContext.HasWarnings) {
      this.salaryBatchFinalizationStep1Completed = true;
      return;
    }

    this.onShowConfirmPasswordWindow();
  }

  public afterFinalizeError(): void {
    if (this.alertDialogType && this.alertDialogType === 'finalizeErrorText') {
      this.closeDialog();
      this.errorListDetailsDialogVisible = true;
    }
  }

  private onDelete(): void {
    this.confirmDeleteDialogVisible = true;
  }

  private onDone(forceClose: boolean = false): void {
    if (this.IsReadOnly || forceClose || !this.salaryBatchEntityContext.IsDraft) {
      this.closeDialog();
      return;
    }

    this.saveSalaryBatchChanges();
  }

  public modalSelectedEmployeeIds: number[];
  private saveSalaryBatchChanges(isFinalized: boolean = false): Promise<ISalaryBatch> {
    return new Promise<ISalaryBatch>(
      (resolve: (value: ISalaryBatch) => void, reject: (value: ISalaryBatch) => void) => {
        if (!this.salaryBatchEntityContext.PaymentDate) {
          this.alertDialogType = 'invalidDateMessage';
          this.alertDialogVisible = true;
          return resolve(undefined);
        }

        const payoutDate = this.staticDataService.getCurrentdate(undefined, this.salaryBatchEntityContext.PaymentDate);

        if (this.salaryBatchEntityContext.BatchId < 1) {
          const salaryBatchRequest: ISalaryBatchRequest = {
            PayoutDate: payoutDate,
            Preview: false,
            SalaryCycleId: this.salaryBatchEntityContext.CycleId,
            SalaryPeriodId: this.salaryBatchEntityContext.PeriodId,
            Message:
              this.salaryBatchEntityContext.Message === undefined ? undefined : this.salaryBatchEntityContext.Message,
            UserEmploymentIds: this.modalSelectedEmployeeIds,
            EIncomeZeroReport: this.salaryBatchEntityContext.EmployeeCategory === 3,
            PayoutAllFlex: this.salaryBatchEntityContext.PayoutAllFlex
          };
          return this.createSalaryBatch(salaryBatchRequest).then((salaryBatch: ISalaryBatch) => resolve(salaryBatch));
        } else {
          let selectedSalaryBatch: ISalaryBatch = this.entityContext.SelectedSalaryBatch;
          const salaryPeriod = new SalaryPeriod();
          salaryPeriod.Id = this.salaryBatchEntityContext.PeriodId;
          salaryPeriod.StartDate = null;
          salaryPeriod.EndDate = null;
          salaryPeriod.SuggestedPayoutDate = selectedSalaryBatch.PayoutDate || null;
          salaryPeriod.FriendlyName = null;
          salaryPeriod.SalaryCycleId = this.salaryBatchEntityContext.CycleId || null;

          if (selectedSalaryBatch && (selectedSalaryBatch as any).PeriodStartDate) {
            salaryPeriod.StartDate = this.staticDataService.getCurrentdate(
              false,
              (selectedSalaryBatch as any).PeriodStartDate
            );
          }
          if (selectedSalaryBatch && (selectedSalaryBatch as any).PeriodEndDate) {
            salaryPeriod.EndDate = this.staticDataService.getCurrentdate((selectedSalaryBatch as any).PeriodEndDate);
          }

          selectedSalaryBatch.SalaryPeriod = salaryPeriod;
          selectedSalaryBatch.PayoutAllFlex = this.salaryBatchEntityContext.PayoutAllFlex;
          selectedSalaryBatch.PayoutDate = payoutDate;
          selectedSalaryBatch.Message = this.salaryBatchEntityContext.Message;
          selectedSalaryBatch.EBoksDeliveryEnabled = selectedSalaryBatch.EBoksDeliveryEnabled;
          selectedSalaryBatch.SpecifiedEmployees = selectedSalaryBatch.SpecifiedEmployees;
          selectedSalaryBatch.HasNemKontoPayments = selectedSalaryBatch.HasNemKontoPayments;
          selectedSalaryBatch.PaymentMethodPublicPension = selectedSalaryBatch.PaymentMethodPublicPension;
          selectedSalaryBatch.PaymentMethodVacationProvider = selectedSalaryBatch.PaymentMethodVacationProvider;
          selectedSalaryBatch.ToArchive = selectedSalaryBatch.ToArchive;
          selectedSalaryBatch.Finalized = selectedSalaryBatch.Finalized;
          selectedSalaryBatch.FinalizedCompanyUserId = selectedSalaryBatch.FinalizedCompanyUserId;
          selectedSalaryBatch.AllowPreliminaryPayslips = selectedSalaryBatch.AllowPreliminaryPayslips;
          selectedSalaryBatch.PackageLevelWhenFinalized = selectedSalaryBatch.PackageLevelWhenFinalized;
          selectedSalaryBatch.IsAutomated = selectedSalaryBatch.IsAutomated;

          const selectedSalaryBatchRecord = new SalaryBatch();
          selectedSalaryBatch = this.staticDataService.checkModelRecord(selectedSalaryBatch);
          Object.assign(selectedSalaryBatchRecord, selectedSalaryBatch);

          return this.updateSalaryBatch(selectedSalaryBatchRecord, isFinalized).then((salaryBatch: ISalaryBatch) =>
            resolve(salaryBatch)
          );
        }
      }
    );
  }

  private onLockSalaryBatch(): void {
    if (
      (this.salaryBatchEntityContext.Message !== this.entityContext.SelectedSalaryBatch.Message ||
        this.salaryBatchEntityContext.PaymentDate !== this.entityContext.SelectedSalaryBatch.PayoutDate) &&
      this.entityContext.SelectedSalaryBatch.StatusId <= 10 &&
      Global.SESSION.CurrentRole.Id >= 60
    ) {
      this.saveSalaryBatchChanges(true).then((salaryRecord: ISalaryBatch) => {
        if (this.salaryBatchEntityContext && salaryRecord) {
          this.salaryBatchEntityContext.HasErrors = salaryRecord.HasErrors;
          this.salaryBatchEntityContext.HasWarnings = salaryRecord.HasWarnings;

          if (this.salaryBatchEntityContext.IsDraft) {
            this.lockSalaryBatchDialogVisible = true;
          } else if (this.salaryBatchEntityContext.IsLocked || this.salaryBatchEntityContext.IsPreliminary) {
            this.confirmRevertLockDialogVisible = true;
          }
        }
      });
    } else {
      if (this.salaryBatchEntityContext.IsDraft) {
        this.lockSalaryBatchDialogVisible = true;
      } else if (this.salaryBatchEntityContext.IsLocked || this.salaryBatchEntityContext.IsPreliminary) {
        this.confirmRevertLockDialogVisible = true;
      }
    }
  }

  private onRejectSalaryBatch(): void {
    this.confirmRejectDialogVisible = true;
  }

  private recalculateDrafts(): void {
    this.getSalaryBatches();
  }

  private apiResendToEIncome(batchId: number): void {
    this.dataService.SalaryBatches_ResendEIncome(batchId).subscribe(() => {
      this.alertDialogType = 'resendBatchCompletedMessage';
      this.alertDialogVisible = true;
      this.getSalaryBatches();
      this.closeDialog();
    });
  }

  private apiResendToIntegration(batchId: number): void {
    this.dataService.SalaryBatches_ResendToDefaultIntegration(batchId).subscribe(() => {
      this.resendBatchExtCompletedDialogVisible = true;
      this.getSalaryBatches();
      this.closeDialog();
    });
  }

  private createSalaryBatch(salaryBatch: ISalaryBatchRequest): Promise<ISalaryBatch> {
    return new Promise<ISalaryBatch>(
      (resolve: (value: ISalaryBatch) => void, reject: (value: ISalaryBatch) => void) => {
        this.dataService.SalaryBatches_CreateSalaryBatch(salaryBatch).subscribe(
          (salaryRecord: ISalaryBatch): void => {
            this.getSalaryBatches(salaryRecord.Id);
            this.closeDialog();
            return resolve(salaryRecord);
          },
          () => {
            this.closeDialog();
            return resolve(undefined);
          }
        );
      }
    );
  }

  private updateSalaryBatch(salaryBatch: ISalaryBatch, isFinalize: boolean = false): Promise<ISalaryBatch> {
    return new Promise<ISalaryBatch>(
      (resolve: (value: ISalaryBatch) => void, reject: (value: ISalaryBatch) => void) => {
        this.dataService.SalaryBatches_UpdateSalaryBatch(salaryBatch).subscribe(
          (salaryRecord: ISalaryBatch): void => {
            this.getSalaryBatches();
            if (!isFinalize) {
              this.closeDialog();
            }

            return resolve(salaryRecord);
          },
          () => {
            return resolve(salaryBatch);
          }
        );
      }
    );
  }

  private deleteSalaryBatch(batchId: number): void {
    this.dataService.SalaryBatches_DeleteSalaryBatch(batchId).subscribe((): void => {
      this.getSalaryBatches();
      this.closeDialog();
    });
  }

  private getPayslips(batchId: number): void {
    this.salaryStatements = [];
    if (batchId) {
      this.dataService
        .SalaryStatements_GetSalaryStatementsByBatchSimplified(batchId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: ISimpleSalaryStatement[]) => {
          this.salaryStatements = data;
        });
    }
  }

  public salaryBatchesDataSource: ISalaryBatchView[];
  private filtersalaryBatchesDataSource(salaryBatchId?: number): void {
    if (this.salaryCycleId) {
      this.salaryBatchesDataSource = this.entityContext.SalaryBatches.filter(
        (s: SalaryBatchViewModel) => s.SalaryCycleId === this.salaryCycleId
      );
    } else {
      this.salaryBatchesDataSource = this.entityContext.SalaryBatches;
    }

    if (this.salaryBatchesDataSource && this.salaryBatchesDataSource.length > 0) {
      salaryBatchId = salaryBatchId
        ? salaryBatchId
        : this.entityContext.SelectedSalaryBatch
        ? this.entityContext.SelectedSalaryBatch.Id
        : undefined;
      const selectedItem: any = salaryBatchId
        ? this.salaryBatchesDataSource.find((d: ISalaryBatchView) => d.Id === salaryBatchId)
        : undefined;
      if (selectedItem) {
        this.selectedComboboxSalaryBatchId = selectedItem.Id;
        this.entityContext.SelectedSalaryBatch = selectedItem;
      } else {
        this.selectedComboboxSalaryBatchId = this.salaryBatchesDataSource[0].Id;
      }
    } else {
      this.selectedComboboxSalaryBatchId = undefined;
    }
  }

  @ViewChild('salaryBatchTab', { static: false }) public salaryBatchTab: TabStripComponent;

  private errorTabSelected: boolean;
  public onSalaryBatchTabSelected(event: any): void {
    this.errorTabSelected = event.index === 1; // Error.
  }

  private selectDefaultBasicTab(): void {
    if (this.IsMobile) {
      const salaryBatch: any = this.entityContext.SelectedSalaryBatch;
      const basicTab: boolean = this.errorTabSelected && salaryBatch && !salaryBatch.ValidationIcon;
      if (basicTab && this.salaryBatchTab) {
        this.salaryBatchTab.selectTab(0);
      }
    }
  }

  private initSplitter() {
    this.splitter = document.getElementById('splitter');
    this.cont1 = document.getElementById('Card-splitter1');
    this.cont2 = document.getElementById('Card-splitter2');
    const leftside: any = document.getElementById('Card-Leftside');
    if (!this.splitter || !this.cont1 || !this.cont2) {
      return;
    }
    if (leftside) {
      this.windowWidth = leftside.clientWidth;
    }
    let dx = this.cont1.clientWidth;
    this.splitter.style.marginLeft = dx + 'px';
    dx += this.splitter.clientWidth;
    this.cont2.style.marginLeft = dx + 'px';
    dx = this.windowWidth - dx;
    this.cont2.style.width = dx + 'px';
    this.splitter.addEventListener('mousedown', this.spMouseDown);

    if (this.cont2Y) {
      const cont2YHeight = this.windowHeight - (this.cont1Y.clientHeight + 3 + 110);
      this.splitter.style.height = cont2YHeight + 'px';
      this.cont1.style.height = cont2YHeight + 'px';
      this.cont2.style.height = cont2YHeight + 'px';
    }
  }

  private spMouseDown = (e: any) => {
    this.splitter.removeEventListener('mousedown', this.spMouseDown);
    window.addEventListener('mousemove', this.spMouseMove);
    window.addEventListener('mouseup', this.spMouseUp);
    this.lastX = e.clientX;
  };

  private spMouseUp = (e: any) => {
    window.removeEventListener('mousemove', this.spMouseMove);
    window.removeEventListener('mouseup', this.spMouseUp);
    this.splitter.addEventListener('mousedown', this.spMouseDown);
    this.resetPosition(this.lastX);
  };

  private spMouseMove = (e: any) => {
    this.resetPosition(e.clientX);
  };

  private resetPosition(nowX: any) {
    let dx = nowX - this.lastX;
    dx += this.cont1.clientWidth;
    this.cont1.style.width = dx + 'px';
    this.splitter.style.marginLeft = dx + 'px';
    dx += this.splitter.clientWidth;
    this.cont2.style.marginLeft = dx + 'px';
    dx = this.windowWidth - dx;
    this.cont2.style.width = dx + 'px';
    this.lastX = nowX;
  }

  private initSplitterY() {
    this.splitterY = document.getElementById('splitterY');
    this.cont1Y = document.getElementById('Card-splitter1Y');
    this.cont2Y = document.getElementById('Card-splitter2Y');
    this.cont1Y1 = $('#Card-splitter1Y kendo-grid-list')[0];
    this.cont2Y1 = $('#Card-splitter2Y .k-grid-content.k-virtual-content')[0];
    this.cont2Y2 = $('#Card-splitter2Y .k-grid-content.k-virtual-content')[1];
    if (!this.splitterY || !this.cont1Y || !this.cont2Y || !this.cont1Y1 || !this.cont2Y1 || !this.cont2Y2) {
      return;
    }
    const rightside: any = document.getElementById('Card-Rightside');
    if (rightside) {
      this.windowHeight = rightside.clientHeight;
    } else {
      this.windowHeight = window.innerHeight - 160;
    }
    const minHeight1 = $('.Company-payslipsGrid .k-grid-content')[0];
    const minHeight2 = $('.Company-payslipsGrid .k-grid-content')[1];
    if (minHeight1 && minHeight2) {
      minHeight1.style.minHeight = '';
      minHeight2.style.minHeight = '';
    }
    let dx = this.cont1Y.clientHeight + 10;
    this.splitterY.style.marginTop = dx + 'px';
    this.cont1Y1.style.cssText = 'height: ' + (dx - 120) + 'px !important; max-height: 1000px !important';
    dx += this.splitterY.clientHeight;
    this.cont2Y.style.marginTop = dx + 'px';
    dx = this.windowHeight - dx;
    this.cont2Y.style.height = dx + 'px';
    this.cont2Y1.style.cssText = 'height: ' + (dx - 150) + 'px !important';
    this.cont2Y2.style.cssText = 'height: ' + (dx - 150) + 'px !important';
    this.splitterY.addEventListener('mousedown', this.spMouseDownY);
  }

  private spMouseDownY = (e: any) => {
    this.splitterY.removeEventListener('mousedown', this.spMouseDownY);
    window.addEventListener('mousemove', this.spMouseMoveY);
    window.addEventListener('mouseup', this.spMouseUpY);
    this.lastY = e.clientY;
  };

  private spMouseUpY = (e: any) => {
    window.removeEventListener('mousemove', this.spMouseMoveY);
    window.removeEventListener('mouseup', this.spMouseUpY);
    this.splitterY.addEventListener('mousedown', this.spMouseDownY);
    this.resetPositionY(this.lastY);
  };

  private spMouseMoveY = (e: any) => {
    this.resetPositionY(e.clientY);
  };

  private resetPositionY(nowY: any) {
    let dx = nowY - this.lastY;
    dx += this.cont1Y.clientHeight;
    this.cont1Y.style.height = dx + 'px';
    this.cont1Y1.style.cssText = 'height: ' + (dx - 120) + 'px !important; max-height: 1000px !important';
    this.splitterY.style.marginTop = dx + 'px';
    dx += this.splitterY.clientHeight;
    this.cont2Y.style.marginTop = dx + 'px';
    dx = this.windowHeight - dx;
    this.cont2Y.style.height = dx + 'px';
    this.cont2Y1.style.cssText = 'height: ' + (dx - 150) + 'px !important';
    this.cont2Y2.style.cssText = 'height: ' + (dx - 150) + 'px !important';
    this.lastY = nowY;

    if (this.cont2Y) {
      const cont2YHeight = this.windowHeight - (this.cont1Y.clientHeight + 3 + 110);
      this.splitter.style.height = cont2YHeight + 'px';
      this.cont1.style.height = cont2YHeight + 'px';
      this.cont2.style.height = cont2YHeight + 'px';
    }
  }

  private resetSplitter() {
    if (
      !this.splitter ||
      !this.splitterY ||
      !this.cont1 ||
      !this.cont2 ||
      !this.cont1Y ||
      !this.cont1Y1 ||
      !this.cont2Y ||
      !this.cont2Y1 ||
      !this.cont2Y2
    ) {
      return;
    }
    // Remove height
    this.splitter.style.height = '';
    this.splitterY.style.height = '';
    this.cont1.style.height = '';
    this.cont2.style.height = '';
    this.cont1Y.style.height = '';
    this.cont1Y1.style.height = '';
    this.cont2Y.style.height = '';
    this.cont2Y1.style.height = '';
    this.cont2Y2.style.height = '';
    // Remove margin
    this.cont2.style.marginLeft = '';
    this.splitter.style.marginLeft = '';
    this.cont2Y.style.marginTop = '';
    this.splitterY.style.marginTop = '';

    const minHeight1 = $('.Company-payslipsGrid .k-grid-content')[0];
    const minHeight2 = $('.Company-payslipsGrid .k-grid-content')[1];
    if (minHeight1 && minHeight2) {
      minHeight1.style.minHeight = '170px';
      minHeight2.style.minHeight = '170px';
    }
  }
}
