import { OnDestroy, OnInit } from '@angular/core';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { TranslateService } from '@ngx-translate/core';
import { saveAs as SaveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from './ModalService';
import { SessionService } from './Session/SessionService';
var DownloadService = /** @class */ (function () {
    function DownloadService(translateService, modalService, sessionService, file, fileOpener) {
        this.translateService = translateService;
        this.modalService = modalService;
        this.sessionService = sessionService;
        this.file = file;
        this.fileOpener = fileOpener;
        this.ngUnsubscribe = new Subject();
    }
    DownloadService.prototype.ngOnInit = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            return _this.translateText();
        });
    };
    DownloadService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(DownloadService.prototype, "folderPath", {
        get: function () {
            return /*this.sessionService.browser.iOSMobileDevice ? this.file.syncedDataDirectory : */ this.file.dataDirectory;
        },
        enumerable: true,
        configurable: true
    });
    DownloadService.prototype.translateText = function () {
        var _this = this;
        this.translateService
            .get(['CompanyDataImport.TroubleOpenFile'])
            .subscribe(function (translations) {
            _this.openFileErrorMessage = translations['CompanyDataImport.TroubleOpenFile'];
        });
    };
    DownloadService.prototype.download = function (defaultFileName, responseData) {
        var _this = this;
        if (!responseData) {
            return;
        }
        try {
            var isFileSaverSupported = !!new Blob();
        }
        catch (e) {
            console.error("Browser doesn't support FileSaver:");
            console.error(e);
        }
        var fileName = this.getFileNameFromContentDisposition(defaultFileName, responseData).replace(/\s/g, '');
        // let byteArray: Uint8Array = new Uint8Array(responseData.body ? responseData.body : responseData.data);
        var data = responseData.body ? responseData.body : responseData.data ? responseData.data : responseData;
        var byteArray = new Uint8Array(data);
        var blob = new Blob([byteArray], { type: 'application/octet-stream' });
        if (!this.sessionService.browser.isHybridApp) {
            // Use File Saver
            SaveAs(blob, fileName);
        }
        else {
            // Use Cordova File plugin to download into device folder.
            setTimeout(function () {
                _this.createFile(fileName, data);
            });
        }
    };
    DownloadService.prototype.createFile = function (fileName, arrayBuffer) {
        var _this = this;
        if (!this.folderPath || !fileName) {
            return;
        }
        this.file.createFile(this.folderPath, fileName, true).then(function (fileEntry) {
            setTimeout(function () {
                ////this.modalService.alert("", fileEntry.toInternalURL());
                _this.file
                    .writeFile(_this.folderPath, fileName, arrayBuffer, { replace: true })
                    .then(function () {
                    _this.openFile(fileName);
                })
                    .catch(function (err) {
                    _this.modalService.alert('', err.message);
                });
            });
        });
    };
    DownloadService.prototype.openFile = function (fileName) {
        var _this = this;
        setTimeout(function () {
            ////this.modalService.alert("", "Try to open file");
            _this.file
                .checkFile(_this.folderPath, fileName)
                .then(function (fileSystem) {
                if (fileSystem) {
                    var fileLocation = _this.folderPath + fileName;
                    _this.fileOpener
                        .open(fileLocation, 'application/' + _this.getFileExtension(fileName))
                        .then()
                        .catch(function (err) {
                        _this.modalService.alert('', _this.openFileErrorMessage + '<br />' + _this.folderPath);
                    });
                }
            })
                .catch(function (err) {
                _this.modalService.alert('', err.message);
            });
        });
    };
    DownloadService.prototype.getFileNameFromContentDisposition = function (defaultFileName, responseData) {
        var contentDisposition;
        if (responseData.headers) {
            contentDisposition = responseData.headers.get('Content-Disposition');
        }
        else {
            try {
                contentDisposition = responseData.headers('Content-Disposition');
            }
            catch (e) {
                return defaultFileName.replace(/[^a-zA-Z0-9.\- ,]/g, '');
            }
        }
        if (!contentDisposition) {
            return defaultFileName.replace(/[^a-zA-Z0-9.\- ,]/g, '');
        }
        var filenameOffset = contentDisposition.lastIndexOf('filename=');
        var startOffset = filenameOffset + 'filename='.length;
        var result = contentDisposition.substring(startOffset);
        return result.replace(/[^a-zA-Z0-9.\- ,]/g, '');
    };
    DownloadService.prototype.getFileExtension = function (fileName) {
        var extension = fileName.split('.').pop();
        extension = extension ? extension.toLowerCase() : extension;
        switch (extension) {
            case 'xlsx':
                return 'vnd.ms-excel';
            case 'csv':
                return 'vnd.ms-excel';
            default:
                return extension;
        }
    };
    return DownloadService;
}());
export { DownloadService };
