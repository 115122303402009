import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { DxDropDownBoxComponent } from 'devextreme-angular';
import { ControlComponentBase } from '../Common/ControlComponentBase';
var MultiSelectComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MultiSelectComponent, _super);
    function MultiSelectComponent(elementRef, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.gridBoxValue = [];
        _this.columns = [];
        _this.OnSelectedChangeEvent = new EventEmitter();
        _this.selectValueIds = [];
        _this.setTimeout = false;
        return _this;
    }
    Object.defineProperty(MultiSelectComponent.prototype, "GridBoxValue", {
        get: function () {
            return this.gridBoxValue;
        },
        set: function (value) {
            this.gridBoxValue = value || [];
            this.OnSelectedChangeEvent.emit(this.gridBoxValue);
        },
        enumerable: true,
        configurable: true
    });
    MultiSelectComponent.prototype.ngOnInit = function () {
        this.onChange();
    };
    Object.defineProperty(MultiSelectComponent.prototype, "SelectValueIds", {
        get: function () {
            var _this = this;
            if (this.gridBoxValue && this.gridBoxValue.length > 0) {
                this.selectValueIds = this.gridBoxValue.map(function (item) {
                    var id;
                    id = item[_this.valueExp];
                    return id;
                });
            }
            else {
                this.selectValueIds = [];
            }
            return this.selectValueIds;
        },
        set: function (values) {
            this.selectValueIds = values;
        },
        enumerable: true,
        configurable: true
    });
    return MultiSelectComponent;
}(ControlComponentBase));
export { MultiSelectComponent };
