import { environment } from '../../../../environments/environment';
import { Global } from '../../../Common/Global';
var RoleFeatures = /** @class */ (function () {
    function RoleFeatures(role) {
        this.role = role;
        this.modules = [];
    }
    Object.defineProperty(RoleFeatures.prototype, "AllowEditGlobal", {
        // Global
        get: function () {
            return this.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleFeatures.prototype, "SelfServiceTabEnabled", {
        // Self Service
        get: function () {
            return !environment.production;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleFeatures.prototype, "AccountTabEnabled", {
        // Account
        get: function () {
            return !environment.production;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleFeatures.prototype, "AccountantTabEnabled", {
        // Accountant
        get: function () {
            return this.role.IsAccountant;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleFeatures.prototype, "ShowEmployeeOverview", {
        // Employee
        get: function () {
            return !environment.production;
        },
        enumerable: true,
        configurable: true
    });
    RoleFeatures.prototype.AllowEditUserEmployment = function (currentState) {
        return (this.role.IsSalaryAdminAndHigher &&
            (currentState === 'tabs.employee.hiringstatus' ||
                currentState === 'tabs.employee' ||
                currentState === 'tabs.employee.general' ||
                currentState === 'tabs.selfservice' ||
                currentState === 'tabs.selfservice.general'));
    };
    Object.defineProperty(RoleFeatures.prototype, "ShowEmployeeList", {
        get: function () {
            return this.role.IsSalaryAdminAndHigher || this.role.IsReadOnly || this.role.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleFeatures.prototype, "ShowEmployeeEmploymentTemplate", {
        get: function () {
            return this.role.IsSalaryAdminAndHigher || this.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleFeatures.prototype, "EmployeeTimeTabEnabled", {
        get: function () {
            return (this.role.IsSalaryAdminAndHigher ||
                this.role.IsEmployeeRole ||
                this.role.IsReadOnly ||
                this.role.EmployeeWithRoleAsManager);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleFeatures.prototype, "EmployeePayrollDataTabEnabled", {
        get: function () {
            return this.role.IsSalaryAdminAndHigher || this.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleFeatures.prototype, "CompanyTabEnabled", {
        // Company
        get: function () {
            return this.role.IsSalaryAdminAndHigher || this.role.IsReadOnly || this.role.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleFeatures.prototype, "EmployeeTabEnabled", {
        get: function () {
            return this.role.IsSalaryAdminAndHigher || this.role.IsReadOnly || this.role.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleFeatures.prototype, "CompanyTimeTabEnabled", {
        get: function () {
            return !environment.production;
        },
        enumerable: true,
        configurable: true
    });
    RoleFeatures.prototype.AllowEditCompanyData = function (currentState) {
        if (currentState === 'tabs.company.modules') {
            return false;
        }
        return this.role.IsSalaryAdminAndHigher;
    };
    Object.defineProperty(RoleFeatures.prototype, "ReportViewEnabled", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    RoleFeatures.prototype.hasModuleId = function (moduleId) {
        return this.modules.some(function (m) { return m.ModuleId === moduleId && m.IsEnabled; });
    };
    Object.defineProperty(RoleFeatures.prototype, "IsDemoVersion", {
        get: function () {
            // Pause GS-942, and start GS-1149
            return Global.IsDemo;
        },
        enumerable: true,
        configurable: true
    });
    return RoleFeatures;
}());
export { RoleFeatures };
