import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterDateTimeGrid } from '../../../../Common/FilterDateTimeGrid';
import { Global } from '../../../../Common/Global';
import { PayrolCommonl } from '../../../../Common/payrolCommonl';
import { ImportTypesEnum } from '../../../../Model/Enum';
import {
  ISalaryRecord,
  ISalaryRecordPersistenceType,
  ISalaryRecordView,
  ISalaryTypeView,
  ISimpleKeyValuePair,
  IUnitType,
  IUserEmployment,
  SalaryRecord
} from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';
import { SalaryRecordItemHelper } from './SalaryRecordItemHelper';

@Component({
  selector: 'app-payroll-data-grid',
  templateUrl: './PayrollDataGrid.component.html'
})
export class PayrollDataGridComponent implements OnInit, OnDestroy {
  public triggerGridComponentUpdate = false;
  private userEmploymentValue: IUserEmployment;
  @Input()
  public get userEmployment(): IUserEmployment {
    return this.userEmploymentValue;
  }
  public set userEmployment(value: IUserEmployment) {
    if (this.userEmploymentValue !== value) {
      this.userEmploymentValue = value;
      if (value && value.Id) {
        this.loadSalaryTypes();
      }
    }
  }

  private recalculateValue = false;
  @Input()
  public get recalculate(): boolean {
    return this.recalculateValue;
  }
  public set recalculate(value: boolean) {
    this.recalculateValue = value;
    this.recalculateChange.emit(value);
  }
  @Output() public recalculateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private currentIsHidePreviewGrid = false;
  @Input()
  public get isHidePreviewGrid(): boolean {
    return this.currentIsHidePreviewGrid;
  }
  public set isHidePreviewGrid(value: boolean) {
    if (this.currentIsHidePreviewGrid !== value) {
      this.currentIsHidePreviewGrid = value;
      this.isHidePreviewGridChange.emit(value);
    }
  }

  @Output() public isHidePreviewGridChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public update: EventEmitter<any> = new EventEmitter<any>();

  public selectedId: number;
  @ViewChild('kendoGridComponent', { static: true }) public kendoGrid: GridComponent;

  private numericOptions: any;
  public get NumericOptions(): any {
    if (this.numericOptions) {
      return this.numericOptions;
    }

    return (this.numericOptions = {
      format: 'n2',
      decimals: 2,
      step: 1,
      min: 0,
      spinners: false
    });
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public get IconisHidePreviewGrid(): string {
    if (!this.isHidePreviewGrid) {
      return 'angle-double-right';
    }
    return 'angle-double-left';
  }

  public IsEnableDateAdjustment = false;

  // public get IsEnableDateAdjustment(): boolean {
  //     let enableDateAdjustment: ISimpleKeyValuePair = PayrolCommonl.getLocalStorageEnableDatePreferenceSetting();
  //     if (!enableDateAdjustment) {
  //         enableDateAdjustment = PayrolCommonl.createLocalStorageEnableDatePreferenceSetting(false);
  //     }

  //     let value: string = enableDateAdjustment.Value.toLowerCase();
  //     return value === "true";
  // }

  private getIsEnableDateAdjustment(): boolean {
    let enableDateAdjustment: ISimpleKeyValuePair = PayrolCommonl.getLocalStorageEnableDatePreferenceSetting();
    if (!enableDateAdjustment) {
      enableDateAdjustment = PayrolCommonl.createLocalStorageEnableDatePreferenceSetting(false);
    }

    const value: string = enableDateAdjustment.Value.toLowerCase();
    return value === 'true';
  }

  public get Dimension1Preference(): ISimpleKeyValuePair {
    return Global.COMPANY_PREFERENCES
      ? Global.COMPANY_PREFERENCES.find((pref: ISimpleKeyValuePair) => pref.Key === 'Dimensions.Dimension1Name')
      : undefined;
  }

  public get Dimension2Preference(): ISimpleKeyValuePair {
    return Global.COMPANY_PREFERENCES
      ? Global.COMPANY_PREFERENCES.find((pref: ISimpleKeyValuePair) => pref.Key === 'Dimensions.Dimension2Name')
      : undefined;
  }

  public get Dimension3Preference(): ISimpleKeyValuePair {
    return Global.COMPANY_PREFERENCES
      ? Global.COMPANY_PREFERENCES.find((pref: ISimpleKeyValuePair) => pref.Key === 'Dimensions.Dimension3Name')
      : undefined;
  }

  public get AmountFlipMessage(): string {
    return this.isAutoFlipAmountsSetting
      ? this.negativeDefaultTranslationText['EnableSetting']
      : this.negativeDefaultTranslationText['DisableSetting'];
  }

  public get AmountFlipButtons(): string[] {
    return !this.isAutoFlipAmountsSetting
      ? [this.negativeDefaultTranslationText['MoreInfo']]
      : [
          this.negativeDefaultTranslationText['MoreInfo'],
          this.negativeDefaultTranslationText['UsePositiveAmountThisTime']
        ];
  }

  private get isAutoFlipAmountsSetting(): boolean {
    return PayrolCommonl.isAutoFlipAmountsSetting();
  }

  public salaryTypes: ISalaryTypeView[];
  public unitTypes: IUnitType[];
  public persistanceTypes: ISalaryRecordPersistenceType[];
  public DimensionXValue: any[] = [];
  public salaryRecords: ISalaryRecordView[] = [];

  public disableGridToolbar: boolean;
  public isHideDateColumns: boolean;
  public showDateSettingConfirmation: boolean;
  public showDeleteItemConfirmation: boolean;
  public salaryTypeFilterFields: string[] = ['Name', 'Description'];

  // private enableDateAdjustmentPreferenceKey: string = "PayrollData.EnableDateAdjustments";

  private registerRecalculateClickedEvent = false;
  private deleteRowTooltipContent: string;
  private enableDateAdjustmentMessage: string;
  private salaryRecordItemHelper: SalaryRecordItemHelper;

  constructor(
    private translateService: TranslateService,
    private dataService: RxDataService,
    private settingService: SettingService,
    public sessionService: SessionService,
    private staticDataService: StaticDataService,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.disableGridToolbar = true;
    this.unitTypes = Global.UNIT_TYPES;
    this.salaryRecordItemHelper = undefined;
    this.translateText();
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) => {
      this.translateText();
    });

    this.staticDataService.SalaryRecordPersistenceType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalaryRecordPersistenceType[]) => {
        this.persistanceTypes = data;
      }
    );

    this.IsEnableDateAdjustment = this.getIsEnableDateAdjustment();
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    //document.removeEventListener("keydown", this.onKeyDown);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public negativeDefaultTranslationText: { [key: string]: string } = {};
  private translateText(): void {
    this.translateService
      .get([
        'EmploymentPayrollData.NegativeDefaultMessage_EnableSetting',
        'EmploymentPayrollData.NegativeDefaultMessage_DisableSetting',
        'EmploymentPayrollData.UsePositiveAmountThisTime',
        'EmploymentPayrollData.MoreInfo',
        'EmploymentPayrollData.UnitsAutoConfigured',
        'EmploymentPayrollData.HandlingOfSygedagpenge'
      ])
      .subscribe((translations: { [key: string]: string }) => {
        this.negativeDefaultTranslationText['EnableSetting'] =
          translations['EmploymentPayrollData.NegativeDefaultMessage_EnableSetting'];
        this.negativeDefaultTranslationText['DisableSetting'] =
          translations['EmploymentPayrollData.NegativeDefaultMessage_DisableSetting'];
        this.negativeDefaultTranslationText['UsePositiveAmountThisTime'] =
          translations['EmploymentPayrollData.UsePositiveAmountThisTime'];
        this.negativeDefaultTranslationText['MoreInfo'] = translations['EmploymentPayrollData.MoreInfo'];

        this.negativeDefaultTranslationText['UnitsAutoConfigured'] =
          translations['EmploymentPayrollData.UnitsAutoConfigured'];
        this.negativeDefaultTranslationText['HandlingOfSygedagpenge'] =
          translations['EmploymentPayrollData.HandlingOfSygedagpenge'];
      });
  }

  private runEnableDateAdjustmentsRules(): void {
    const enableDateAdjustment: boolean = this.IsEnableDateAdjustment;
    this.showDateColumns(enableDateAdjustment);
  }

  private showDateColumns(show: boolean): void {
    this.isHideDateColumns = !show;
  }

  private enableDateAdjustmentSetting(enable: boolean): void {
    this.updateEnableDatePreferenceSetting(enable);
  }

  private updateEnableDatePreferenceSetting(enableSetting: boolean): void {
    const companyPreference: ISimpleKeyValuePair = {
      Key: PayrolCommonl.enableDateAdjustmentPreferenceKey,
      Value: enableSetting ? 'true' : 'false'
    };
    this.dataService.Preferences_SetCompanyPreference(companyPreference).subscribe(
      (enableDatePref: ISimpleKeyValuePair) => {
        this.updateLocalStorageEnableDatePreferenceSetting(enableDatePref);
        this.IsEnableDateAdjustment = this.getIsEnableDateAdjustment();
        this.showDateColumns(enableSetting);
        this.triggerGridComponentUpdate = true;
      },
      (error: any) => {
        this.showDateColumns(!enableSetting);
      }
    );
  }

  private updateLocalStorageEnableDatePreferenceSetting(enableDatePref?: ISimpleKeyValuePair): ISimpleKeyValuePair {
    const preferences: ISimpleKeyValuePair[] = JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES));
    const enableDateAdjustment: ISimpleKeyValuePair = preferences.filter(
      (pref: any) => pref.Key === PayrolCommonl.enableDateAdjustmentPreferenceKey
    )[0];
    if (enableDateAdjustment) {
      enableDateAdjustment.Value = enableDatePref.Value;
      Global.COMPANY_PREFERENCES = preferences;
    }

    return enableDateAdjustment;
  }

  // private createLocalStorageEnableDatePreferenceSetting(defaultValue: boolean): ISimpleKeyValuePair {
  //     let preferences: ISimpleKeyValuePair[] = Global.COMPANY_PREFERENCES ? JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES)) : [];
  //     // Add a default setting = true.
  //     let companyPreference: ISimpleKeyValuePair = { Key: PayrolCommonl.enableDateAdjustmentPreferenceKey, Value: defaultValue ? "true" : "false" };
  //     preferences.push(companyPreference);
  //     Global.COMPANY_PREFERENCES = preferences;
  //     return companyPreference;
  // }

  // private getLocalStorageEnableDatePreferenceSetting(): ISimpleKeyValuePair {
  //     let preferences: ISimpleKeyValuePair[] = JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES));
  //     if (preferences) {
  //         return preferences.filter((pref: any) => pref.Key === PayrolCommonl.enableDateAdjustmentPreferenceKey)[0];
  //     }

  //     return undefined;
  // }

  private loadSalaryTypes(): void {
    this.salaryRecords = undefined;
    this.salaryTypes = undefined;
    this.disableGridToolbar = true;

    this.dataService.SalaryTypes_GetSalaryTypeViews().subscribe((salaryTypes: ISalaryTypeView[]): void => {
      if (this.userEmploymentValue.IncomeTypeId === 5) {
        this.salaryTypes = salaryTypes.filter(
          (salaryType: ISalaryTypeView) => salaryType.IsVisible && salaryType.RelatesToIncomeType5 !== false
        );
      } else {
        this.salaryTypes = salaryTypes.filter(
          (salaryType: ISalaryTypeView) => salaryType.IsVisible && !salaryType.RelatesToIncomeType5
        );
      }

      this.loadSalaryRecords();
    });

    if (this.Dimension1Preference && this.Dimension1Preference.Value) {
      this.dataService.Miscellaneous_GetDimensionValues(1).subscribe((data: any) => {
        this.DimensionXValue[0] = data.filter((value: any) => value);
      });
    }

    if (this.Dimension2Preference && this.Dimension2Preference.Value) {
      this.dataService.Miscellaneous_GetDimensionValues(2).subscribe((data: any) => {
        this.DimensionXValue[1] = data.filter((value: any) => value);
      });
    }

    if (this.Dimension3Preference && this.Dimension3Preference.Value) {
      this.dataService.Miscellaneous_GetDimensionValues(3).subscribe((data: any) => {
        this.DimensionXValue[2] = data.filter((value: any) => value);
      });
    }
  }

  private loadSalaryRecords(): void {
    if (!this.userEmployment) {
      return;
    }

    this.dataService
      .SalaryRecords_GetSalaryRecordsByEmployment(this.userEmployment.Id)
      .subscribe((salaryRecords: ISalaryRecordView[]): void => {
        this.salaryRecords = salaryRecords.map((data: ISalaryRecordView) => {
          return PayrolCommonl.assignEditableFields(data, this.salaryTypes);
        });

        this.gridData = filterBy(this.salaryRecords, this.filter);
        this.runEnableDateAdjustmentsRules();
        this.disableGridToolbar = false;
      });
  }

  public negativeDefaultMoreDialogModel: any = {};
  public showNegativeDefaultMoreInfoDialog: boolean;
  public onNegativeDefaultMoreInfoClick(dataItem: any, fieldName: string): void {
    this.negativeDefaultMoreDialogModel = {
      setting: this.isAutoFlipAmountsSetting,
      fieldName,
      dataItem
    };

    this.showNegativeDefaultMoreInfoDialog = true;
  }

  public onMoreInfoUsePositiveAmount(args: any): void {
    this.usePositiveAmount(args.dataItem, args.fieldName);
  }

  public usePositiveAmount(dataItem: any, fieldName: string): void {
    this.salaryRecordItemHelper.usePositiveAmount(fieldName);
    this.saveChanges(dataItem);
    this.triggerGridComponentUpdate = true;
  }

  public autoCorrect(args: any): void {
    this.salaryRecordItemHelper.autoCorrect(args.fieldName);
    this.saveChanges(args.dataItem);
    this.triggerGridComponentUpdate = true;
  }

  private gridRemoveRowArgs: any;
  public removeHandler(e: any): void {
    this.gridRemoveRowArgs = e;
    this.showDeleteItemConfirmation = true;
    this.update.emit();
  }

  public onRemoveRowConfirmed(action: string): void {
    if (action === 'Delete' && this.gridRemoveRowArgs) {
      if (this.gridRemoveRowArgs.dataItem.Id > 0) {
        this.deleteSalaryRecord(this.gridRemoveRowArgs.dataItem.Id);
      } else {
        const indexOfRemovedItem: number = this.salaryRecords.indexOf(this.gridRemoveRowArgs.dataItem);
        this.salaryRecords.splice(indexOfRemovedItem, 1);
        this.gridRemoveRowArgs = undefined;
      }
    }
  }

  private deleteSalaryRecord(salaryRecordId: number): void {
    if (salaryRecordId && salaryRecordId > 0) {
      this.dataService.SalaryRecords_DeleteSalaryRecord(salaryRecordId).subscribe(
        (data: any): void => {
          this.recalculate = true;
          const indexOfRemovedItem: number = this.salaryRecords.indexOf(this.gridRemoveRowArgs.dataItem);
          this.salaryRecords.splice(indexOfRemovedItem, 1);
          if (this.salaryRecords.length > 0) {
            const index: number = indexOfRemovedItem > this.salaryRecords.length - 1 ? 0 : indexOfRemovedItem;
            this.kendoGrid.editCell(
              index,
              this.kendoGrid.columns.length,
              this.createFormGroup(this.salaryRecords[index])
            );
          }

          this.gridRemoveRowArgs = undefined;
          this.gridData = filterBy(this.salaryRecords, this.filter);
        },
        (error: any): void => {
          this.gridRemoveRowArgs = undefined;
          this.loadSalaryRecords();
        }
      );
    }
  }

  public addHandler(dataItem: any): void {
    this.update.emit();
    this.salaryRecordItemHelper = new SalaryRecordItemHelper(
      dataItem,
      this.renderer,
      this.persistanceTypes,
      this.salaryTypes,
      this.settingService
    );
    this.salaryRecordItemHelper.updateSalaryType(this.salaryTypes[0]);
    // this.assignEditableFields(dataItem);
    PayrolCommonl.assignEditableFields(dataItem, this.salaryTypes);
    const salaryRecord: ISalaryRecord = PayrolCommonl.createSalaryRecord(dataItem, this.userEmployment.Id);
    this.dataService.SalaryRecords_CreateSalaryRecord(salaryRecord).subscribe(
      (data: ISalaryRecord): void => {
        this.gridData = filterBy(this.salaryRecords, this.filter);
        dataItem.Id = data.Id;
        // Object.assign(dataItem, data);
        this.selectedId = data.Id;
        this.recalculate = true;
        if (this.isUseHotKey) {
          this.kendoGrid.editCell(this.salaryRecords.length - 1, 0, this.createFormGroup(dataItem));
          this.isUseHotKey = false;
        }
      },
      (error: any): void => {
        this.loadSalaryRecords();
      }
    );
  }

  // private createSalaryRecord(salaryRecordView: any): ISalaryRecord {
  //     let endDateConverter: DateTimeConvert = new DateTimeConvert(salaryRecordView.EndDate);
  //     let endDate: Date = endDateConverter.getUTCDate();
  //     let startDateConverter: DateTimeConvert = new DateTimeConvert(salaryRecordView.StartDate);
  //     let startDate: Date = startDateConverter.getUTCDate();

  //     let salaryRecord: ISalaryRecord = {
  //         Id: salaryRecordView.Id ? salaryRecordView.Id : salaryRecordView.id,
  //         Amount: salaryRecordView.Amount,
  //         AmountPerUnit: salaryRecordView.AmountPerUnit,
  //         BaseAmount: salaryRecordView.BaseAmount,
  //         Description: salaryRecordView.Description,
  //         EndDate: endDate ? endDate : salaryRecordView.EndDate,
  //         PersistenceTypeId: salaryRecordView.PersistenceTypeId,
  //         SalaryTypeId: salaryRecordView.SalaryTypeId,
  //         UnitTypeId: salaryRecordView.UnitTypeId,
  //         Dimension1Value: salaryRecordView.Dimension1Value,
  //         Dimension2Value: salaryRecordView.Dimension2Value,
  //         Dimension3Value: salaryRecordView.Dimension3Value,
  //         StartDate: startDate ? startDate : salaryRecordView.StartDate,
  //         Units: salaryRecordView.Units,
  //         UserEmploymentId: this.userEmployment.Id,
  //         IsActive: true
  //     };

  //     let unitTypes: any = Global.UNIT_TYPES.filter((tet: any) => {
  //         return tet.Id === salaryRecordView.UnitTypeId;
  //     });

  //     if (unitTypes) {
  //         salaryRecord.UnitType = unitTypes[0];
  //     }

  //     return salaryRecord;
  // }

  public saveChanges(args: CellCloseEvent, field?: string): void {
    const { column, formGroup } = args;
    let dataItem: any = args.dataItem;
    dataItem = dataItem ? dataItem : args;
    const modifiedField: any = {};
    if (column) {
      field = column.field;
    }

    if (field) {
      modifiedField[field] = dataItem[field];
    }

    this.salaryRecordItemHelper = new SalaryRecordItemHelper(
      dataItem,
      this.renderer,
      this.persistanceTypes,
      this.salaryTypes,
      this.settingService
    );
    const valid: boolean = this.salaryRecordItemHelper.validate(modifiedField);
    if (!valid) {
      dataItem[field] = formGroup.value[field];
      return;
    }

    const previousDataItem: any = JSON.parse(JSON.stringify(dataItem));
    this.salaryRecordItemHelper.updateRelavantFields(modifiedField);
    // this.assignEditableFields(dataItem);
    PayrolCommonl.assignEditableFields(dataItem, this.salaryTypes);
    if (
      field &&
      (modifiedField[field] === 'AmountPerUnit' || modifiedField[field] === 'Amount') &&
      PayrolCommonl.checkAmountAutoFlip(previousDataItem, dataItem)
    ) {
      // Show in-cell popup message to notify Amount has been auto-flip.
      this.salaryRecordItemHelper.showInCellPopupMessage(dataItem, 'Amount', column ? column.field : undefined);
    }

    if (dataItem.SalaryTypeId) {
      const currentSalaryTypedSelect: ISalaryTypeView = this.salaryTypes.find(
        (model: ISalaryTypeView) => dataItem.SalaryTypeId === model.SalaryTypeId
      );
      if (currentSalaryTypedSelect && currentSalaryTypedSelect.Identifier === 'IllnessCompensation') {
        if ((dataItem.Units || dataItem.Amount) && !dataItem.BaseAmount) {
          this.salaryRecordItemHelper.showInCellPopupMessage(dataItem, 'BaseAmount', column ? column.field : undefined);
        }
      }
    }

    setTimeout(() => {
      this.triggerGridComponentUpdate = true;
    }, 5000);
    const salaryRecord: ISalaryRecord = PayrolCommonl.createSalaryRecord(dataItem, this.userEmployment.Id);
    this.updateSalaryRecord(salaryRecord, dataItem);
  }

  public onCellCloseEvent(event: CellCloseEvent): void {
    if (event && event.dataItem && event.dataItem.UnSaved && !event.sender.isEditing()) {
      this.saveChanges({ dataItem: event.dataItem } as any);
    }
  }

  // private assignEditableFields(item: any): any {
  //     let salaryType: ISalaryTypeView = this.salaryTypes && item.SalaryTypeId ? this.salaryTypes.find((type: any) => { return type.SalaryTypeId === item.SalaryTypeId; }) : undefined;
  //     let unitType: IUnitType = item.UnitTypeId ? Global.UNIT_TYPES.find((type: any) => { return type.Id === item.UnitTypeId; }) : undefined;

  //     item.AllowEditUnitType = salaryType && salaryType.AllowEditUnitType;
  //     item.UnitTypeClass = item.AllowEditUnitType ? "" : "grid-disable-cell";
  //     item.AllowEditBaseAmount = salaryType && salaryType.AllowEditBaseAmount;
  //     item.tBaseAmountClass = item.AllowEditBaseAmount ? "" : "grid-disable-cell";
  //     item.AllowEditAmountPerUnit = unitType && unitType.Id && unitType.Id !== UnitTypeEnum.Percent;
  //     item.AmountPerUnitClass = item.AllowEditAmountPerUnit ? "" : "grid-disable-cell";
  //     item.AllowEditUnits = unitType && unitType.Id;
  //     item.UnitsClass = item.AllowEditUnits ? "" : "grid-disable-cell";

  //     return item;
  // }

  // private checkAmountAutoFlip(previous: ISalaryRecord, dataItem: ISalaryRecord): boolean {
  //     return (!previous.AmountPerUnit || previous.AmountPerUnit > 0 || !previous.Amount || previous.Amount > 0)
  //         && dataItem.Amount < 0 && dataItem.Amount !== previous.Amount;
  // }

  private updateSalaryRecord(salaryRecord: ISalaryRecord, dataItem: any): void {
    this.update.emit();
    this.dataService.SalaryRecords_UpdateSalaryRecord(salaryRecord).subscribe(
      (data: ISalaryRecord): void => {
        if (
          dataItem &&
          dataItem.SalaryTypeId === data.SalaryTypeId &&
          dataItem.Units &&
          data.Units &&
          dataItem.Units !== data.Units
        ) {
          // Units is updated by Server.
          dataItem.Amount = data.Amount;
          dataItem.Units = data.Units;
          this.salaryRecordItemHelper.showInCellPopupMessage(dataItem, 'Units');
          this.triggerGridComponentUpdate = true;
        }

        this.recalculate = true;
      },
      (error: any): void => {
        this.loadSalaryRecords();
      }
    );
  }

  public onGridAction(action: string): void {
    switch (action) {
      case 'recalculate':
        setTimeout(() => {
          this.recalculate = true;
        });
        this.update.emit();
        break;
      case 'enableDate':
        if (!this.IsEnableDateAdjustment) {
          this.showDateSettingConfirmation = true;
        }
        break;
      case 'disableDate':
        if (this.IsEnableDateAdjustment) {
          this.enableDateAdjustmentSetting(false);
        }
        break;
      case 'correctBalance':
        this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Initial_Balances;
        this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
        break;
      case 'onHidePreviewGrid':
        this.isHidePreviewGrid = !this.isHidePreviewGrid;
        break;
      default:
        break;
    }
  }

  public onConfirmDateAdjustmentSetting(action: string): void {
    if (action === 'Enable') {
      this.enableDateAdjustmentSetting(true);
    }
  }

  public onGridButtonClicked(args: any): void {
    switch (args.buttonAction) {
      case this.negativeDefaultTranslationText['UsePositiveAmountThisTime']:
        this.usePositiveAmount(args.dataItem, args.field);
        break;
      case this.negativeDefaultTranslationText['MoreInfo']:
        this.onNegativeDefaultMoreInfoClick(args.dataItem, args.field);
        break;
      default:
        break;
    }
  }

  public onSelectedItemChanges(event: any): void {
    this.selectedItem = event;
  }

  public selectedItem: any;
  private keyPress: any = { 17: false, 18: false, 107: false, 109: false };
  public isUseHotKey = false;

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: any): void {
    if (!this.IsReadOnly) {
      if (event.keyCode in this.keyPress) {
        this.keyPress[event.keyCode] = true;
        if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
          const dataItem: ISalaryRecord = new SalaryRecord();
          const defaultDataItem: any = PayrolCommonl.createSalaryRecord(dataItem, this.userEmployment.Id);
          this.isUseHotKey = true;
          this.addHandler(defaultDataItem);
          this.setKeypress();
          this.salaryRecords.push(defaultDataItem);
          this.triggerGridComponentUpdate = true;
        } else if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
          if (this.selectedItem) {
            const deleteArgs: any = {};
            deleteArgs.dataItem = this.selectedItem;
            this.removeHandler(deleteArgs);
            this.setKeypress();
            this.triggerGridComponentUpdate = true;
          }
        }
      } else {
        this.setKeypress();
      }
    }
  }

  private setKeypress(): void {
    this.keyPress[17] = false;
    this.keyPress[18] = false;
    this.keyPress[107] = false;
    this.keyPress[109] = false;
  }

  private createFormGroup(dataItem: ISalaryRecord): FormGroup {
    return new FormGroup({
      Id: new FormControl(dataItem ? dataItem.Id : undefined),
      Amount: new FormControl(dataItem ? dataItem.Amount : undefined),
      AmountPerUnit: new FormControl(dataItem ? dataItem.AmountPerUnit : undefined),
      BaseAmount: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
      Description: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
      EndDate: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
      PersistenceTypeId: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
      SalaryTypeId: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
      UnitTypeId: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
      Dimension1Value: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
      Dimension2Value: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
      Dimension3Value: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
      StartDate: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
      Units: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
      UserEmploymentId: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
      IsActive: new FormControl(dataItem ? dataItem.BaseAmount : undefined)
    });
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = filterBy(this.salaryRecords, this.filter);
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    if (filter && filter.filters && filter.filters.length > 0) {
      const filterDateTimeGrid = new FilterDateTimeGrid();
      const filtersDate: any = [];
      // Using FilterDateTimeGrid to filter date before filter another field
      this.filter = filterDateTimeGrid.convertFilterOperator(filter, filtersDate, 'StartDate', 'EndDate');
      this.gridData = filterDateTimeGrid.doFilterDate(this.salaryRecords, filtersDate, 'StartDate', 'EndDate');
      this.gridData = filterBy(this.gridData, this.filter);
      if (filtersDate.length > 0) {
        [].push.apply(this.filter.filters, filtersDate);
      }
    } else {
      this.gridData = this.salaryRecords;
    }
  }
}
