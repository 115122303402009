<app-dialog *ngIf="!isTab && visible" (action)="onDialogAction($event)" [(visible)]="visible">
  <div *ngIf="isFinalized">
    <grid [editMode]="false" [data]="gridData" class="FinalizedErrorsDetailsGrid u-gridHeight350">
      <gridColumn
        [iconField]="'icon'"
        [tooltipField]="'iconTooltip'"
        [title]="'CompanySalaryBatches.FinalizedGridField Is Error' | translate"
        [type]="'icon'"
        [width]="50"
      >
      </gridColumn>
      <gridColumn
        [field]="'RefLineNo'"
        [title]="'CompanySalaryBatches.FinalizedGridField Ref Line No' | translate"
        [type]="'text'"
        [width]="90"
      >
      </gridColumn>
      <gridColumn
        [field]="'RefFieldNo'"
        [title]="'CompanySalaryBatches.FinalizedGridField Ref Field No' | translate"
        [type]="'text'"
        [width]="90"
      >
      </gridColumn>
      <gridColumn
        [field]="'ResponseCode'"
        [title]="'CompanySalaryBatches.FinalizedGridField Response Code' | translate"
        [type]="'text'"
        [width]="120"
      >
      </gridColumn>
      <gridColumn
        [field]="'ResponseText'"
        [title]="'CompanySalaryBatches.FinalizedGridField Response Text' | translate"
        [type]="'text'"
        [width]="160"
      >
      </gridColumn>
      <gridColumn
        [field]="'CprOrSeNo'"
        [title]="'CompanySalaryBatches.FinalizedGridField Cpr Or Se No' | translate"
        [type]="'text'"
        [width]="100"
      >
      </gridColumn>
      <gridColumn
        [field]="'ReceivedOn'"
        [title]="'CompanySalaryBatches.FinalizedGridField Received On' | translate"
        [type]="'datepicker'"
        [width]="100"
      >
      </gridColumn>
    </grid>
  </div>

  <div *ngIf="!isFinalized">
    <salary-batch-other-error-grid
      [visible]="visible"
      class="SalaryBatchOtherErrorsGrid"
      [salaryBatch]="salaryBatch"
    ></salary-batch-other-error-grid>
  </div>

  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action
    *ngIf="isFinalized && sessionService.role.IsFullAdmin"
    [close]="false"
    [type]="'ClearErrors'"
  ></app-dialog-action>
  <app-dialog-action *ngIf="isFinalized && sessionService.role.IsFullAdmin" [type]="'Resend'"></app-dialog-action>
</app-dialog>

<div *ngIf="isTab">
  <div *ngIf="isFinalized">
    <grid [editMode]="false" [data]="gridData" class="u-gridHeight350">
      <gridColumn
        [iconField]="'icon'"
        [tooltipField]="'iconTooltip'"
        [title]="'CompanySalaryBatches.FinalizedGridField Is Error' | translate"
        [type]="'icon'"
        [width]="50"
      >
      </gridColumn>
      <gridColumn
        [field]="'RefLineNo'"
        [title]="'CompanySalaryBatches.FinalizedGridField Ref Line No' | translate"
        [type]="'text'"
        [width]="90"
      >
      </gridColumn>
      <gridColumn
        [field]="'RefFieldNo'"
        [title]="'CompanySalaryBatches.FinalizedGridField Ref Field No' | translate"
        [type]="'text'"
        [width]="90"
      >
      </gridColumn>
      <gridColumn
        [field]="'ResponseCode'"
        [title]="'CompanySalaryBatches.FinalizedGridField Response Code' | translate"
        [type]="'text'"
        [width]="120"
      >
      </gridColumn>
      <gridColumn
        [field]="'ResponseText'"
        [title]="'CompanySalaryBatches.FinalizedGridField Response Text' | translate"
        [type]="'text'"
        [width]="160"
      >
      </gridColumn>
      <gridColumn
        [field]="'CprOrSeNo'"
        [title]="'CompanySalaryBatches.FinalizedGridField Cpr Or Se No' | translate"
        [type]="'text'"
        [width]="100"
      >
      </gridColumn>
      <gridColumn
        [field]="'ReceivedOn'"
        [title]="'CompanySalaryBatches.FinalizedGridField Received On' | translate"
        [type]="'datepicker'"
        [width]="100"
      >
      </gridColumn>
    </grid>
  </div>

  <div *ngIf="!isFinalized">
    <salary-batch-other-error-grid [visible]="visible" [salaryBatch]="salaryBatch"></salary-batch-other-error-grid>
  </div>

  <app-dialog-buttons (buttonAction)="onDialogAction($event)" *ngIf="isFinalized && sessionService.role.IsFullAdmin">
    <!--<app-dialog-action [type]="'Close'"></app-dialog-action>-->
    <app-dialog-action [close]="false" [type]="'ClearErrors'"></app-dialog-action>
    <app-dialog-action [type]="'Resend'"></app-dialog-action>
  </app-dialog-buttons>
</div>

<app-dialog
  [(visible)]="clearErrorsDialogVisible"
  (action)="clearErrorsDialogAction($event)"
  [leadingText]="'CompanySalaryBatches.ClearErrorsMessage' | translate"
>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Proceed'"></app-dialog-action>
</app-dialog>
