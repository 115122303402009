import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RxDataService } from '../Services/RxDataService';
import { SessionService } from '../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'changeavatar',
  templateUrl: './ChangeAvatar.component.html'
})
export class ChangeAvatarComponent {
  private firstLoad = true;
  private visibleValue: boolean;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private avatarValue: string;
  @Input()
  public get avatar(): string {
    return this.avatarValue;
  }
  public set avatar(value: string) {
    if (this.avatarValue !== value) {
      this.avatarValue = value;
      if (this.firstLoad) {
        this.firstLoad = false;
      } else {
        this.avatarChanged.emit(value);
      }
    }
  }
  @Output() public avatarChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private dataService: RxDataService, private sessionService: SessionService) {}

  public onAvatarChanged(formData: any): void {
    if (this.sessionService.browser.isHybridApp) {
    }
    if (Object.keys(formData).length !== 0) {
      return;
    }
    this.dataService.Users_CurrentImage(formData).subscribe(
      (data: any): void => {
        if (!data) {
          this.avatar = '';
          this.visible = false;
        } else {
          this.avatar = data;
          this.visible = false;
        }
      },
      (error: any): void => {}
    );
  }
}
