<app-dialog
  [overlayClose]="false"
  [(visible)]="visible"
  [title]="'Company.SenioritySupplements' | translate"
  id="seniorityDialog"
>
  <module-check [moduleId]="4">
    <grid
      [(data)]="seniority"
      [editMode]="editMode"
      (saveChangesEvent)="saveChanges($event)"
      class="SenioritySupplementGrid"
      [deleteRow]="editMode"
      (removeEvent)="removeSeniorityHandler($event)"
      (addNewEvent)="addSeniorityHandler($event)"
      [addNew]="editMode"
      [addNewText]="'SenioritySupplements.AddNew' | translate"
      [(triggerUpdate)]="refreshData"
      [(selectedItem)]="selectedItem"
      [addNewDisable]="sessionService.isGetting"
    >
      <gridColumn
        [title]="''"
        [field]="'Between'"
        [type]="'text'"
        [editable]="false"
        [width]="100"
        class="grid-disable-cell"
        [text]="'SenioritySupplements.Between' | translate"
        [title]="'SenioritySupplements.SeniorityInMonths' | translate"
        [addOnClass]="'headerGroup titleGroup'"
      ></gridColumn>
      <gridColumn
        [field]="'SeniorityMonthsStart'"
        [title]="''"
        [type]="'numeric'"
        [width]="100"
        [numbericOption]="numericOptions"
        [addOnClass]="'headerGroup'"
      ></gridColumn>
      <gridColumn
        [field]="'And'"
        [type]="'text'"
        [editable]="false"
        [width]="80"
        class="grid-disable-cell"
        [text]="'SenioritySupplements.And' | translate"
        [title]="''"
        [addOnClass]="'headerGroup'"
      ></gridColumn>
      <gridColumn
        [field]="'SeniorityMonthsEnd'"
        [title]="''"
        [type]="'numeric'"
        [editable]="true"
        [width]="120"
        [addOnClass]="'headerGroup'"
      ></gridColumn>
      <gridColumn
        [field]="'SupplementAmount'"
        [type]="'numeric'"
        [editable]="true"
        [title]="'SenioritySupplements.Supplement' | translate"
        [width]="150"
      ></gridColumn>
      <gridColumn
        [field]="'SupplementIntervalType'"
        [comboboxDataSource]="intervalTypes"
        [type]="'combobox'"
        [comboboxValueField]="'Id'"
        [comboboxDisplayField]="'Name'"
        [editable]="true"
        [title]="'SenioritySupplements.Principle' | translate"
        [width]="180"
      ></gridColumn>
    </grid>
  </module-check>

  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
