import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { TimeAndAbsenceModel } from './TimeAndAbsenceModel';
var TimeAndAbsenceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TimeAndAbsenceComponent, _super);
    function TimeAndAbsenceComponent(staticDataService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.modelChange = new EventEmitter();
        _this.childIsValid = Array(4).fill(true);
        _this.childIsDirty = Array(4).fill(false);
        _this.staticDataService.TimeEntryRecordAcrossSalaryPeriodStrategy.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (data) { return (_this.timeEntrySplitStrategy = data); });
        return _this;
    }
    Object.defineProperty(TimeAndAbsenceComponent.prototype, "model", {
        get: function () {
            if (!this.currentmodel) {
                this.currentmodel = {};
            }
            return this.currentmodel;
        },
        set: function (value) {
            this.currentmodel = value;
            this.modelChange.emit(this.currentmodel);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeAndAbsenceComponent.prototype, "IsUserEmployment", {
        get: function () {
            return this.model && this.model.HireDate;
        },
        enumerable: true,
        configurable: true
    });
    return TimeAndAbsenceComponent;
}(FormComponentBase));
export { TimeAndAbsenceComponent };
