import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
var DropDownListFilterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DropDownListFilterComponent, _super);
    function DropDownListFilterComponent(filterService) {
        var _this = _super.call(this, filterService) || this;
        _this.filterChange = new EventEmitter();
        return _this;
    }
    Object.defineProperty(DropDownListFilterComponent.prototype, "selectedOperator", {
        get: function () {
            return this.seletedOperatorValue;
        },
        set: function (value) {
            if (value) {
                this.seletedOperatorValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    DropDownListFilterComponent.prototype.onChange = function (value) {
        this.filterChange.emit(value);
    };
    return DropDownListFilterComponent;
}(BaseFilterCellComponent));
export { DropDownListFilterComponent };
