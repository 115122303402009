<app-dialog
  id="deleteUserDialog"
  [(visible)]="isVisible"
  width="700"
  (action)="onDeleteEmployeeAction($event)"
  title="{{ 'EmployeeGeneral.DeleteDialogTitle' | translate }}"
>
  <div>
    <div *ngIf="currentUserEmployment">
      <div innerHTML="{{ 'EmployeeGeneral.DeleteEmploymentMessage' | translate }}"></div>
    </div>

    <div *ngIf="!currentUserEmployment">
      <div innerHTML="{{ 'EmployeeGeneral.DeleteEmployeeMessage' | translate }}"></div>
    </div>
  </div>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action
    type="DeleteEmployment"
    [disabled]="sessionService.isSubmitting"
    *ngIf="hasEmployment"
  ></app-dialog-action>
  <app-dialog-action type="DeleteCompanyUser" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>
