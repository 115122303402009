import { OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RxDataService } from '../../Services/RxDataService';
import { DownloadService } from '../../Services/SharedServices/DownloadService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
var SelfServicePayslipComponent = /** @class */ (function () {
    function SelfServicePayslipComponent(dataService, settingService, downloadService, sessionService, translateService, renderer, sanitizer, staticDataService) {
        this.dataService = dataService;
        this.settingService = settingService;
        this.downloadService = downloadService;
        this.sessionService = sessionService;
        this.translateService = translateService;
        this.renderer = renderer;
        this.sanitizer = sanitizer;
        this.staticDataService = staticDataService;
        this.salaryStatements = [];
        this.previewClicked = false;
        this.htmlContent = '';
        this.previousBtnDisabled = false;
        this.nextBtnDisabled = false;
        this.paySlipPreviewDialog = false;
        this.newTabBlockedDialogVisible = false;
        this.ngUnsubscribe = new Subject();
        this.isHidden = false;
        // public get safeContent(): SafeHtml { return this.sanitizer.bypassSecurityTrustHtml(this.htmlContent); }
        this.currentIndex = -1;
        this.haveSalaryStatements = false;
        this.translationServiceTerms = ['SelfServicePayslip.HelpSwitchCompanies'];
    }
    Object.defineProperty(SelfServicePayslipComponent.prototype, "hideColumn", {
        get: function () {
            return this.previewClicked ? 'Payslip-hide-column-preview' : 'Payslip-hide-column';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServicePayslipComponent.prototype, "hideColumn2", {
        get: function () {
            return this.previewClicked ? 'Payslip-hide-column-2-preview' : 'Payslip-hide-column-2';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServicePayslipComponent.prototype, "isCordovaApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServicePayslipComponent.prototype, "isMoreOneCompany", {
        get: function () {
            if (this.companies && this.companies.length > 1) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServicePayslipComponent.prototype, "isFirefox", {
        get: function () {
            return this.sessionService.browser.isFirefox;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServicePayslipComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServicePayslipComponent.prototype, "isActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.selfservice.payslip';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServicePayslipComponent.prototype, "isAboveXLargeScreen", {
        get: function () {
            return window.innerWidth && window.innerWidth >= 1200;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServicePayslipComponent.prototype, "isUntilXLargeScreen", {
        get: function () {
            return window.innerWidth && window.innerWidth < 1200;
        },
        enumerable: true,
        configurable: true
    });
    SelfServicePayslipComponent.prototype.ngOnInit = function () {
        if (this.isActive) {
            this.settingService.showModuleMessage('SelfService.Payslip');
            this.loadSalaryStatements();
            this.getCompanies();
        }
    };
    SelfServicePayslipComponent.prototype.onClickOutside = function (event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            if (event.target &&
                event.target.classList &&
                event.target.classList.length === 3 &&
                event.target.classList[0] === 'SelfServicePayslipContainer') {
                this.removeItemSelection();
                this.previewClicked = false;
            }
            if (this.sessionService.browser.isHybridApp) {
                window.screen.orientation.unlock();
            }
        }
    };
    SelfServicePayslipComponent.prototype.onTableKeydown = function (event) {
        if (!this.salaryStatements || this.salaryStatements.length === 0) {
            return;
        }
        if (event && event.keyCode === 38) {
            // Up arrow
            if (this.currentIndex === 0) {
                return;
            }
            this.onViewPreviousPayslipClicked();
        }
        if (event && event.keyCode === 40) {
            // Down arrow
            if (this.currentIndex === this.salaryStatements.length - 1) {
                return;
            }
            this.onViewNextPayslipClicked();
        }
    };
    SelfServicePayslipComponent.prototype.downloadSalaryStatementsPreview = function (salaryStatement) {
        var _this = this;
        var format = !this.isCordovaApp ? 'a4p' : 'html';
        this.dataService.SalaryStatements_GetHtml(salaryStatement.Id, format).subscribe(function (html) {
            _this.showSalaryStatementsPreviewDialog(html);
        });
    };
    SelfServicePayslipComponent.prototype.onPayslipSelected = function (item, index) {
        var _this = this;
        if (this.isCordovaApp) {
            this.dataService.SalaryStatements_GetHtml(item.salaryStatement.Id, 'html').subscribe(function (html) {
                _this.showSalaryStatementsPreviewDialog(html);
            });
            return;
        }
        this.currentIndex = index;
        if (item.isSelected) {
            // Close preview
            item.isSelected = false;
            this.previewClicked = false;
        }
        this.currentId = item.salaryStatement.Id;
        // Opened on the side and auto closed when resize to isUntilXlargeScreen
        if (this.isUntilXLargeScreen && this.previewClicked) {
            // Close payslip review
            this.previewClicked = true;
            item.isSelected = true;
            this.dataService.SalaryStatements_GetHtml(item.salaryStatement.Id, 'a4p').subscribe(function (html) {
                _this.onOpenPayslipNewTab(undefined, item.salaryStatement.Id);
            });
        }
        this.htmlContent = '';
        this.removeItemSelection();
        if (this.isUntilXLargeScreen) {
            this.dataService.SalaryStatements_GetHtml(item.salaryStatement.Id, 'a4p').subscribe(function (html) {
                _this.onOpenPayslipNewTab(undefined, item.salaryStatement.Id);
            });
        }
        else {
            item.isSelected = true;
            this.showSlip(item, true);
        }
    };
    SelfServicePayslipComponent.prototype.onClosePayslipClicked = function () {
        this.haveSalaryStatements = false;
        this.removeItemSelection();
        this.previewClicked = false;
    };
    SelfServicePayslipComponent.prototype.onViewNextPayslipClicked = function () {
        this.htmlContent = '';
        if (this.salaryStatements[this.currentIndex + 1]) {
            this.salaryStatements[this.currentIndex].isSelected = false;
            this.currentIndex++;
            this.salaryStatements[this.currentIndex].isSelected = true;
            this.showSlip(this.salaryStatements[this.currentIndex], true);
        }
        this.resetNavigationButtons();
    };
    SelfServicePayslipComponent.prototype.onViewPreviousPayslipClicked = function () {
        this.htmlContent = '';
        if (this.salaryStatements[this.currentIndex - 1]) {
            this.salaryStatements[this.currentIndex].isSelected = false;
            this.currentIndex--;
            this.salaryStatements[this.currentIndex].isSelected = true;
            this.showSlip(this.salaryStatements[this.currentIndex], true);
        }
        this.resetNavigationButtons();
    };
    SelfServicePayslipComponent.prototype.onOpenPayslipNewTab = function (event, id) {
        if (!id) {
            id = this.currentId;
        }
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.getPayslipReportByToken(id, 'html');
    };
    SelfServicePayslipComponent.prototype.onDowloadShownPayslip = function () {
        var current = this.salaryStatements[this.currentIndex];
        var currentStatementId = current ? current.salaryStatement.Id : undefined;
        this.onDownloadPayslip(undefined, currentStatementId);
    };
    SelfServicePayslipComponent.prototype.onDownloadPayslip = function (event, salaryStatementId) {
        var _this = this;
        if (salaryStatementId) {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            if (this.isCordovaApp || this.sessionService.browser.isIE || this.sessionService.browser.isEdge) {
                this.dataService.SalaryStatements_GetPdf(salaryStatementId).subscribe(function (promiseValue) {
                    _this.downloadService.download('Gratisal.pdf', promiseValue);
                });
            }
            else {
                this.getPayslipReportByToken(salaryStatementId, 'pdf');
            }
        }
    };
    SelfServicePayslipComponent.prototype.showSalaryStatementsPreviewDialog = function (data) {
        this.htmlContent = data;
        this.safeContent = this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
        this.paySlipPreviewDialog = true;
    };
    SelfServicePayslipComponent.prototype.getPayslipReportByToken = function (salaryStatementId, format) {
        var _this = this;
        this.dataService.SalaryStatements_GetPayslipDownloadToken(salaryStatementId).subscribe(function (token) {
            var languageCode = _this.sessionService.LanguageCode;
            var downloadUrl = "/api/salarystatements/" + salaryStatementId + "/token/" + token + "/lang/" + languageCode + "/format/" + format + "/Payslip";
            var newWindow = window.open(environment.apiUrl + downloadUrl);
            if (!newWindow) {
                _this.newTabBlockedDialogVisible = true;
            }
        });
    };
    SelfServicePayslipComponent.prototype.removeItemSelection = function () {
        if (this.salaryStatements) {
            this.salaryStatements.forEach(function (value) {
                if (value.isSelected === true) {
                    value.isSelected = false;
                }
            });
        }
    };
    SelfServicePayslipComponent.prototype.showSlip = function (item, clicked) {
        var _this = this;
        this.previewClicked = true;
        this.dataService.SalaryStatements_GetHtml(item.salaryStatement.Id, 'a4p').subscribe(function (html) {
            _this.htmlContent = html;
            _this.safeContent = _this.sanitizer.bypassSecurityTrustHtml(_this.htmlContent);
        });
        this.resetNavigationButtons();
    };
    SelfServicePayslipComponent.prototype.resetNavigationButtons = function () {
        this.previousBtnDisabled = this.salaryStatements[this.currentIndex - 1] ? false : true;
        this.nextBtnDisabled = this.salaryStatements[this.currentIndex + 1] ? false : true;
        this.haveSalaryStatements = true;
    };
    SelfServicePayslipComponent.prototype.loadSalaryStatements = function () {
        var _this = this;
        this.dataService
            .SalaryStatements_GetSalaryStatementsCurrentCompanyUser()
            .subscribe(function (statements) {
            if (statements) {
                statements.forEach(function (item) {
                    var extendedItem = { salaryStatement: item, isSelected: false };
                    _this.salaryStatements.push(extendedItem);
                    _this.haveSalaryStatements = true;
                });
            }
            _this.showFirstSalaryStatement();
        });
    };
    SelfServicePayslipComponent.prototype.showFirstSalaryStatement = function () {
        if (this.salaryStatements.length > 0 && this.isAboveXLargeScreen) {
            this.onPayslipSelected(this.salaryStatements[0], 0);
        }
    };
    SelfServicePayslipComponent.prototype.getCompanies = function () {
        var _this = this;
        this.staticDataService.companiesSimple.subscribe(function (data) {
            _this.companies = data;
            _this.getCurrentCompany();
        });
    };
    SelfServicePayslipComponent.prototype.getCurrentCompany = function () {
        var _this = this;
        if (this.companiesState) {
            this.companiesState.unsubscribe();
        }
        this.companiesState = this.dataService.Companies_GetCurrent().subscribe(function (activeCompany) {
            _this.currentCompany = activeCompany;
            _this.enableTranslation();
        });
    };
    SelfServicePayslipComponent.prototype.enableTranslation = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService.get(_this.translationServiceTerms).subscribe(function (translations) {
                _this.setTranslationTerms(translations);
            });
        });
    };
    SelfServicePayslipComponent.prototype.setTranslationTerms = function (translations) {
        this.selfservicepayslipNote = translations['SelfServicePayslip.HelpSwitchCompanies'];
        if (this.currentCompany) {
            this.selfservicepayslipNote = this.selfservicepayslipNote.replace('$var$', this.currentCompany.Name);
        }
    };
    return SelfServicePayslipComponent;
}());
export { SelfServicePayslipComponent };
