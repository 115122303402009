import { Browser } from './Browser';

export class KeyDownHelper {
  static isFocusEmployeeListShortcutKey($event: any): boolean {
    const brower: Browser = new Browser();
    if (brower.isSafari) {
      return $event.metaKey && $event.altKey && $event.key === 'PageUp';
    }

    return $event.ctrlKey && $event.altKey && $event.key === 'Home';
  }

  static isSelectPreviousEmployeeShortcutKey($event: any): boolean {
    const key: any = $event.keyCode || $event.charCode || 0;
    //// Ctrl + Alt + "U"
    return $event.ctrlKey && $event.altKey && key === 85;
  }

  static isSelectNextEmployeeShortcutKey($event: any): boolean {
    const key: any = $event.keyCode || $event.charCode || 0;
    //// Ctrl + Alt + "D"
    return $event.ctrlKey && $event.altKey && key === 68;
  }

  static isReportShortcutKey($event: any): boolean {
    const key: any = $event.keyCode || $event.charCode || 0;
    //// Ctrl + Alt + "R"
    return $event.ctrlKey && $event.altKey && key === 82;
  }

  static isEditShortcutKey($event: any): boolean {
    const key: any = $event.keyCode || $event.charCode || 0;
    //// Ctrl + Alt + "E"
    const test: boolean = $event.ctrlKey && $event.altKey && key === 69;
    return test;
  }

  static isNewShortcutKey($event: any): boolean {
    const key: any = $event.keyCode || $event.charCode || 0;
    //// Ctrl + Alt + "N"
    return $event.ctrlKey && $event.altKey && key === 78;
  }

  static isNewLineShortcutKey($event: any): boolean {
    const key: any = $event.keyCode || $event.charCode || 0;
    //// Ctrl + Alt + "+"
    return $event.ctrlKey && $event.altKey && key === 107;
  }

  static isRemoveLineShortcutKey($event: any): boolean {
    const key: any = $event.keyCode || $event.charCode || 0;
    //// Ctrl + Alt + "-"
    return $event.ctrlKey && $event.altKey && key === 109;
  }

  static isSavingChangesShortcutKey($event: any): boolean {
    const key: any = $event.keyCode || $event.charCode || 0;
    //// Ctrl + Alt + "S"
    const test: boolean = $event.ctrlKey && $event.altKey && key === 83;
    return test;
  }

  static isDiscardingChangesShortcutKey($event: any): boolean {
    const key: any = $event.keyCode || $event.charCode || 0;
    //// Ctrl + Alt + "C"
    return $event.ctrlKey && $event.altKey && key === 67;
  }

  private static getZIndexOfElement(e: any): number {
    const index: any = document.defaultView.getComputedStyle(e).getPropertyValue('z-index');
    if (isNaN(index)) {
      return KeyDownHelper.getZIndexOfElement(e.parentNode);
    }

    return parseInt(index, 10);
  }
}
