<app-dialog
  class="{{ hiddenScroll }}"
  (action)="onDialogAction($event)"
  [title]="'Report.ChooseReport' | translate"
  [(visible)]="visible"
  [minWidth]="400"
  [width]="diaglogWidth"
>
  <div [ngClass]="displayFlex ? displayFlex : 'height55vh'" style="width: 100%;">
    <span *ngIf="gridVisible || !isMobile" class="listReports" (dblclick)="onDoubleClick()">
      <grid
        class="FormElement-grid u-gridHeight75vh GridSelect ReportDialog-grid"
        [idColumn]="'Id'"
        [data]="reports"
        [(selectedItem)]="selectedReport"
        [editMode]="false"
        [isReportDialog]="isMobile"
      >
        <gridColumn
          [type]="'text'"
          [field]="'Name'"
          [title]="'Report.Name' | translate"
          [tooltipField]="'Description'"
        ></gridColumn>
      </grid>
    </span>

    <span *ngIf="selectedReport" class="parameters">
      <div *ngIf="formatOptions.length > 0">
        <div>{{ 'Report.ChooseFormat' | translate }}:</div>
        <app-radio-edit [editMode]="true" [(value)]="selectedFormat">
          <app-radio-edit-option
            *ngFor="let option of formatOptions"
            [label]="option.toLocaleUpperCase()"
            [value]="option"
          ></app-radio-edit-option>
        </app-radio-edit>
      </div>

      <div *ngFor="let control of reportControls" [ngClass]="control.type === 'Checkbox' ? 'u-leader1-5' : 'u-leader'">
        <check-edit
          *ngIf="control.type === 'CheckBox' || control.type === 'Checkbox'"
          [label]="control.name"
          [(value)]="control.value"
          [editMode]="true"
        ></check-edit>

        <date-picker-control
          [e2e]="'ReportParameter_' + control.key"
          *ngIf="control.type === 'DatePicker'"
          [label]="control.name"
          [required]="true"
          [requiredMessage]="'EmployeeGeneral.Required' | translate"
          [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
          [(value)]="control.value"
          [editMode]="true"
        ></date-picker-control>

        <combobox-edit-control
          *ngIf="control.type === 'ComboBox'"
          [label]="control.name"
          [editMode]="true"
          [textField]="control.displayText"
          [idField]="control.underlyingId"
          [placeholder]="control.placeholderText | translate"
          [comboboxDataSource]="control.dataSource"
          [(value)]="control.value"
          [nullValueLabel]="control.nullValueLabel | translate"
        ></combobox-edit-control>

        <multi-select
          *ngIf="control.type === 'MultiSelectList'"
          [editMode]="true"
          [gridDataSource]="control.dataSource"
          [columns]="columns"
          [displayExp]="displayExp"
          [label]="control.name"
          [placeHolder]="control.placeholderText | translate"
          [valueExp]="control.underlyingId"
          [required]="false"
          [(GridBoxValue)]="MultiSelectValues"
          (OnSelectedChangeEvent)="onSelectedChanged($event)"
        ></multi-select>

        <app-text-edit
          *ngIf="control.type === 'TextBox'"
          [label]="control.name"
          [(value)]="control.value"
          [editMode]="true"
        ></app-text-edit>

        <numeric-edit
          *ngIf="control.type === 'Integer'"
          [label]="control.name"
          [editMode]="true"
          [(value)]="control.value"
          [option]="numericTextBoxOption"
        ></numeric-edit>
      </div>

      <button *ngIf="isMobile" (click)="onBackClick()" class="k-button k-grid-add-command ng-star-inserted back-button">
        {{ 'CompanySalaryBatches.Back' | translate }}
      </button>
    </span>
  </div>

  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action
    [close]="false"
    [type]="'Download'"
    [disabled]="sessionService.isSubmitting || !selectedReport"
  ></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="validationDialogVisible">
  <p>{{ 'Report.RequiredFieldsMessage' | translate }} {{ missingRequiredFields }}.</p>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  *ngIf="!isCordovaApp"
  [(visible)]="newTabBlockedDialogVisible"
  [leadingText]="'Report.NewTabBlocked' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="safariWarningDialogVisible">
  <p>{{ safariWarning | translate }}</p>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="htmlReportDialogVisible" class="PayslipPopup" [iframeContent]="htmlReportDialogContent">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="noReportVisible" [leadingText]="'Report.NoReport' | translate">
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
