<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeEmployment.Pension' | translate }}</legend>

    <div class="FormElement-static" *ngIf="!isNoPensionTextHidden">
      {{ 'EmployeeEmployment.NoPension' | translate }}
    </div>
    <div *ngIf="showPensionProvider">
      <combobox-edit-control
        [(isDirty)]="childIsDirty[0]"
        [editMode]="editMode"
        [label]="'EmployeeEmployment.Pension provider' | translate"
        [comboboxDataSource]="pensionProviders"
        [nullValueLabel]="'DropdownList.None' | translate"
        [disable]="disable"
        [(value)]="pensionProviderId"
        [textField]="'Name'"
        [idField]="'Id'"
        (valueChange)="onChange()"
      ></combobox-edit-control>
      <div class="FormElement">
        <span class="FormElement-label">{{ 'EmployeeEmployment.NetsNo' | translate }}:</span>
        <div class="FormElement-control">
          <div class="FormElement-static">{{ PensionProviderNetsNo }}</div>
        </div>
      </div>
      <div *ngIf="isShowBankInfo">
        <app-text-edit
          [(isDirty)]="childIsDirty[1]"
          [(isValid)]="childIsValid[0]"
          [label]="'EmployeeEmployment.Reg no' | translate"
          [editMode]="editMode"
          [disabled]="disable"
          [(value)]="PensionProviderBankRegNo"
          (valueChange)="onChange()"
        ></app-text-edit>
        <app-text-edit
          [(isDirty)]="childIsDirty[2]"
          [(isValid)]="childIsValid[1]"
          [label]="'EmployeeEmployment.Account no' | translate"
          [editMode]="editMode"
          [disabled]="disable"
          [(value)]="PensionProviderBankAccountNo"
          (valueChange)="onChange()"
        ></app-text-edit>
      </div>
      <combobox-edit-control
        [(isDirty)]="childIsDirty[3]"
        [editMode]="editMode"
        [label]="'EmployeeEmployment.Base on' | translate"
        [disable]="!SelectedPensionProvider || disable"
        [comboboxDataSource]="pensionBaseOnCalculationMethods"
        [(value)]="PensionBaseCalculationMethodId"
        [textField]="'Name'"
        [idField]="'Id'"
        (valueChange)="onChange()"
      ></combobox-edit-control>

      <share-percentage
        [(isDirty)]="childIsDirty[4]"
        [label]="'EmployeeEmployment.Percentage (own company)' | translate"
        [(firstValue)]="PensionEmployeeShare"
        [(secondValue)]="PensionCompanyShare"
        [editMode]="editMode"
        [disable]="!SelectedPensionProvider || disable"
        (valueChange)="onChange()"
      ></share-percentage>

      <share-amount
        [(isDirty)]="childIsDirty[5]"
        [label]="'EmployeeEmployment.Fixed amount (own company)' | translate"
        [(firstValue)]="PensionEmployeeAmount"
        [(secondValue)]="PensionCompanyAmount"
        [editMode]="editMode"
        [disable]="!SelectedPensionProvider || disable"
        (valueChange)="onChange()"
      ></share-amount>

      <app-text-edit
        [(isDirty)]="childIsDirty[6]"
        [label]="
          isOverridePolicyNo
            ? SelectedPensionProvider.PolicyNumberLabelOverride
            : ('EmployeeEmployment.PolicyNo' | translate)
        "
        [editMode]="editMode"
        [(value)]="PensionPolicyNumber"
        [disabled]="!SelectedPensionProvider || disable"
        (valueChange)="onChange()"
      ></app-text-edit>
    </div>

    <div class="FormElement" *ngIf="!isAddButtonHidden">
      <button-component
        buttonClass="Button--card"
        (click)="onAddFirstPensionProvider()"
        [isDisabled]="disable"
        [label]="'EmployeeEmployment.Add pension provider' | translate"
      ></button-component>
    </div>
  </fieldset>
</form>
