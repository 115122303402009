<app-dialog (action)="onDialogAction($event)" [overlayClose]="false" [(visible)]="visible">
  <input type="text" value="{{ userName }}" class="input-no-display" autocomplete="username" />
  <app-text-edit
    [label]="'CompanySalaryBatches.PasswordLabel' | translate"
    [(value)]="password"
    [editMode]="true"
    [type]="'password'"
    [autocorrect]="true"
  >
  </app-text-edit>

  <app-dialog-action [type]="'Cancel'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
  <app-dialog-action [type]="'Finalize'" [disabled]="!password || sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="responseDialogVisible" [leadingText]="response">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
