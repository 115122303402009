/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./Icon/Icon.component.ngfactory";
import * as i2 from "./Icon/Icon.component";
import * as i3 from "../Services/SharedServices/Session/SessionService";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./TaxCardAddOn.component";
var styles_TaxCardAddOnComponent = [];
var RenderType_TaxCardAddOnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TaxCardAddOnComponent, data: {} });
export { RenderType_TaxCardAddOnComponent as RenderType_TaxCardAddOnComponent };
function View_TaxCardAddOnComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.nativeElement.dataset.title; _ck(_v, 0, 0, currVal_0); }); }
function View_TaxCardAddOnComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-icon", [], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(2, 49152, null, 0, i2.IconComponent, [i0.ElementRef, i3.SessionService, i0.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"], istooltipHTML: [2, "istooltipHTML"], tooltipWidth: [3, "tooltipWidth"] }, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "ErrorExclamation"; var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 3).transform("EmployeeGeneral.TaxCardRedWarning")); var currVal_2 = true; var currVal_3 = 340; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_TaxCardAddOnComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "app-icon", [], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(2, 49152, null, 0, i2.IconComponent, [i0.ElementRef, i3.SessionService, i0.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"], istooltipHTML: [2, "istooltipHTML"], tooltipWidth: [3, "tooltipWidth"] }, null), i0.ɵpod(3, { time: 0 }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Info"; var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 4).transform("EmployeeGeneral.TaxCardQuestionWarning", _ck(_v, 3, 0, _co.requestedTime))); var currVal_2 = true; var currVal_3 = 340; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_TaxCardAddOnComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-icon", [], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(2, 49152, null, 0, i2.IconComponent, [i0.ElementRef, i3.SessionService, i0.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"], istooltipHTML: [2, "istooltipHTML"], tooltipWidth: [3, "tooltipWidth"] }, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "Warning"; var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 3).transform("EmployeeGeneral.TaxCardYellowWarning")); var currVal_2 = true; var currVal_3 = 340; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_TaxCardAddOnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, [["template", 2]], null, 0, null, View_TaxCardAddOnComponent_1)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "FormElement-addon FormElement-addonSpace FormElement-cursorHand"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TaxCardAddOnComponent_2)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TaxCardAddOnComponent_3)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TaxCardAddOnComponent_4)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorIcon; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.infoIcon; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.warningIcon; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_TaxCardAddOnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "taxcard-addon", [], null, null, null, View_TaxCardAddOnComponent_0, RenderType_TaxCardAddOnComponent)), i0.ɵdid(1, 114688, null, 0, i6.TaxCardAddOnComponent, [i3.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaxCardAddOnComponentNgFactory = i0.ɵccf("taxcard-addon", i6.TaxCardAddOnComponent, View_TaxCardAddOnComponent_Host_0, { taxInfo: "taxInfo", requested: "requested" }, {}, []);
export { TaxCardAddOnComponentNgFactory as TaxCardAddOnComponentNgFactory };
