import { EventEmitter, OnInit } from '@angular/core';
import { Global } from '../../../Common/Global';
import { SalaryType } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
var NewCustomSalaryTypeDialogComponent = /** @class */ (function () {
    function NewCustomSalaryTypeDialogComponent(dataService, sessionService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.add = new EventEmitter();
        this.validationWarningVisible = false;
        this.createFromScratch = new SalaryType();
        this.hiddenBehavior = true;
        this.SelectInherit = false;
    }
    Object.defineProperty(NewCustomSalaryTypeDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                if (value) {
                    this.salaryTypeName = undefined;
                    this.baseSalaryTypeId = undefined;
                    this.categoryId = undefined;
                }
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    NewCustomSalaryTypeDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (Global.SESSION.SignOnToken.LanguageId === 2) {
            this.createFromScratch.Name = '<Create From Scratch>';
        }
        else {
            this.createFromScratch.Name = '<Opret fra bunden>';
        }
        this.createFromScratch.Id = -1;
        this.dataService.SalaryTypes_GetDefaultSalaryTypes().subscribe(function (salaryTypes) {
            _this.defaultSalaryTypes = salaryTypes.filter(function (item) {
                return item.IsVisible && item.IsActive;
                ////return (item.IsVisible || (item.IsVisible !== false && item.BaseSalaryType && item.BaseSalaryType.IsVisible))
                ////    && (item.IsActive || (item.IsActive !== false && item.BaseSalaryType && item.BaseSalaryType.IsActive));
            });
            _this.defaultSalaryTypes.unshift(_this.createFromScratch);
        });
    };
    NewCustomSalaryTypeDialogComponent.prototype.onDialogAction = function (action) {
        var _this = this;
        if (action === 'Create') {
            if (this.isCreateFormInvalid) {
                this.validationWarningVisible = true;
                return;
            }
            var request = void 0;
            if (this.baseSalaryTypeId === -1) {
                var customSalaryType = {
                    CategoryId: this.categoryId,
                    Name: this.salaryTypeName,
                    Id: this.baseSalaryTypeId,
                    IsAdvanced: false
                };
                request = this.dataService.SalaryTypes_CreateCustomSalaryType(customSalaryType);
            }
            else {
                var inheritSalaryTypeRequest = {
                    BaseSalaryTypeId: this.baseSalaryTypeId,
                    Name: this.salaryTypeName
                };
                request = this.dataService.SalaryTypes_InheritBaseSalaryType(inheritSalaryTypeRequest);
            }
            request.subscribe(function (salaryType) {
                if (Global.SESSION.SignOnToken.LanguageId === 1) {
                    salaryType.SalaryTypeTranslations[0].Name = salaryType.Name;
                }
                else if (Global.SESSION.SignOnToken.LanguageId === 2) {
                    salaryType.SalaryTypeTranslations[1].Name = salaryType.Name;
                }
                else {
                    salaryType.SalaryTypeTranslations[2].Name = salaryType.Name;
                }
                _this.add.emit(salaryType);
                _this.SelectInherit = false;
                _this.hiddenBehavior = true;
                _this.visible = false;
            });
        }
    };
    Object.defineProperty(NewCustomSalaryTypeDialogComponent.prototype, "isCreateFormInvalid", {
        get: function () {
            return !this.salaryTypeName || !this.baseSalaryTypeId || (this.baseSalaryTypeId === -1 && !this.categoryId);
        },
        enumerable: true,
        configurable: true
    });
    NewCustomSalaryTypeDialogComponent.prototype.onInheritChange = function (value) {
        this.baseSalaryTypeId = value;
        if (value === -1) {
            this.hiddenBehavior = false;
        }
        else {
            this.hiddenBehavior = true;
            this.SelectInherit = false;
            this.categoryId = undefined;
        }
    };
    NewCustomSalaryTypeDialogComponent.prototype.onBehaviorChange = function (value) {
        this.categoryId = value;
        this.SelectInherit = true;
    };
    return NewCustomSalaryTypeDialogComponent;
}());
export { NewCustomSalaryTypeDialogComponent };
