/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../CustomControls/TextEditUpdate.component.ngfactory";
import * as i4 from "../../../CustomControls/TextEditUpdate.component";
import * as i5 from "../../../Services/SharedServices/Session/SessionService";
import * as i6 from "../../../Filters/CPRFormat.pipe";
import * as i7 from "./CprInfo.component";
var styles_CprInfoComponent = [];
var RenderType_CprInfoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CprInfoComponent, data: {} });
export { RenderType_CprInfoComponent as RenderType_CprInfoComponent };
function View_CprInfoComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "FormElement-control"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["class", "FormElement-static"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), i0.ɵppd(3, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), _co.value)); _ck(_v, 2, 0, currVal_0); }); }
function View_CprInfoComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "FormElement-feedback"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Employee.Missing")); _ck(_v, 1, 0, currVal_0); }); }
function View_CprInfoComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "FormElement FormElement-ViewMode"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["class", "FormElement-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ":"])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CprInfoComponent_2)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CprInfoComponent_3)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.dummyIdentityNumber; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.dummyIdentityNumber; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Employee.CPR no")); _ck(_v, 2, 0, currVal_0); }); }
function View_CprInfoComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-text-edit", [], null, [[null, "isValidChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isValidChange" === en)) {
        var pd_0 = ((_co.isValid = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.value = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_TextEditUpdateComponent_0, i3.RenderType_TextEditUpdateComponent)), i0.ɵdid(1, 114688, null, 0, i4.TextEditUpdateComponent, [i5.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isValid: [2, "isValid"], label: [3, "label"], type: [4, "type"], required: [5, "required"], requiredMessageKey: [6, "requiredMessageKey"] }, { valueChange: "valueChange", isValidChange: "isValidChange" }), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; var currVal_1 = _co.editMode; var currVal_2 = _co.isValid; var currVal_3 = i0.ɵunv(_v, 1, 3, i0.ɵnov(_v, 2).transform("Employee.CPR no")); var currVal_4 = "text"; var currVal_5 = true; var currVal_6 = "NewEmployee.CPRErrorMessage"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_CprInfoComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i6.CPRFormatPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CprInfoComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CprInfoComponent_4)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editMode; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.editMode; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_CprInfoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cpr-info", [], null, null, null, View_CprInfoComponent_0, RenderType_CprInfoComponent)), i0.ɵdid(1, 49152, null, 0, i7.CprInfoComponent, [i0.ElementRef, i0.ChangeDetectorRef], null, null)], null, null); }
var CprInfoComponentNgFactory = i0.ɵccf("cpr-info", i7.CprInfoComponent, View_CprInfoComponent_Host_0, { defaultnullvalue: "defaultnullvalue", isGrid: "isGrid", preventChange: "preventChange", value: "value", editMode: "editMode", isDirty: "isDirty", isValid: "isValid", setTimeout: "setTimeout", hostClass: "hostClass", dummyIdentityNumber: "dummyIdentityNumber", required: "required" }, { valueChange: "valueChange", editModeChange: "editModeChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange" }, []);
export { CprInfoComponentNgFactory as CprInfoComponentNgFactory };
