import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { PanelBarComponent } from '@progress/kendo-angular-layout';
import { forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Global } from '../Common/Global';
import { GridHelper } from '../Common/GridHelper';
import { NumericTextBoxOptions } from '../CustomControls/NumericTextBoxOptions';
import {
  ICompanyPreference,
  ICompanyPreferenceCategory,
  ISimpleKeyValuePair,
  IUserPreference
} from '../Services/ApiModel';
import { RxDataService } from '../Services/RxDataService';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../Services/SharedServices/StaticData.service';
import { PreferenceViewModel } from './PreferenceViewModel';

@Component({
  selector: 'preference',
  templateUrl: './Preference.component.html'
})
export class PreferenceComponent implements OnDestroy {
  private ownerValue: string;
  @Input()
  public get owner(): string {
    return this.ownerValue;
  }
  public set owner(value: string) {
    if (value && value !== this.ownerValue) {
      this.ownerValue = value;
      if (this.isDataReady) {
        return;
      }

      if (value === 'company') {
        this.GetCurrentSettings();
      }

      if (value === 'user') {
        this.GetUserPreferences();
      }
    }
  }

  public companyReferencesCategory: any[];
  public companyPreferences: PreferenceViewModel[] = [];

  public userPreferences: PreferenceViewModel[] = [];
  public numericTextBoxOption: NumericTextBoxOptions = { min: 0, step: 1, spinners: false, decimals: 0, format: 'n0' };

  public get isIOSMobileApp(): boolean {
    return this.sessionService.browser.iOSMobileDevice;
  }
  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  @ViewChild('companyPanelBar', { static: false }) public companyPanelBar: PanelBarComponent;

  private isDataReady = false;
  private apiReponses: { [api: string]: any } = {};
  private companyReferencesInfo: ISimpleKeyValuePair[];
  private companyReferencesStructure: any[];

  constructor(
    private dataService: RxDataService,
    private sessionService: SessionService,
    private staticData: StaticDataService
  ) {}

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public isDataTypeBoolean(dataType: string): boolean {
    return dataType.includes('CheckBox') || dataType.includes('Checkbox');
  }

  public isDataTypeNumber(dataType: string): boolean {
    return dataType.includes('Integer');
  }

  public isDataTypeTextBox(dataType: string): boolean {
    return dataType.includes('TextBox');
  }

  public isDataTypeCombobox(dataType: string): boolean {
    return dataType.includes('ComboBox') || dataType.includes('Combobox');
  }

  public submitValueChange(value: any, item: PreferenceViewModel): void {
    if (!this.isDataReady) {
      return;
    }

    item.Value = value;
    const itemToSubmit: ISimpleKeyValuePair = { Key: item.Key, Value: '' + item.Value };
    this.updateSetting(itemToSubmit);
  }

  public onCompanyPanelChanged(event: any): void {
    console.log(event);
  }

  private GetCurrentSettings(): void {
    observableForkJoin(
      this.loadCompanyReferenceInfo(),
      this.loadCompanyReferencesCategory(),
      this.loadCompanyReferencesStructure()
    ).subscribe(() => {
      if (this.companyReferencesCategory && this.companyReferencesInfo && this.companyReferencesStructure) {
        this.createPreferences(this.companyReferencesInfo, this.companyReferencesStructure).then(
          (preferences: any[]) => {
            this.companyPreferences = preferences.sort((a: any, b: any) => a.SortOrder - b.SortOrder);
            this.companyReferencesCategory.sort((a: any, b: any) => a.SortOrder - b.SortOrder);

            this.companyReferencesCategory.forEach((category: any) => {
              category.Preferences = this.companyPreferences.filter((pref: any) => pref.CategoryId === category.Id);
            });

            // Select the first Item in panel bar.
            setTimeout(() => {
              if (
                this.companyPanelBar &&
                (this.companyPanelBar as any).allItems &&
                (this.companyPanelBar as any).allItems[0]
              ) {
                (this.companyPanelBar as any).allItems[0].selected = true;
                (this.companyPanelBar as any).allItems[0].expanded = true;
              }
            });
          }
        );
      } else {
        this.companyPreferences = [];
      }

      this.isDataReady = true;
    });
  }
  private loadCompanyReferenceInfo(): Observable<void> {
    if (Global.COMPANY_PREFERENCES && Global.COMPANY_PREFERENCES.length > 0) {
      this.companyReferencesInfo = Global.COMPANY_PREFERENCES;
      return Observable.create((observer: any) => {
        observer.next(this.companyReferencesInfo);
        observer.complete();
      });
    } else {
      return this.dataService.Preferences_GetCompanyPreferences().pipe(
        map((data: ISimpleKeyValuePair[]): void => {
          this.companyReferencesInfo = Global.COMPANY_PREFERENCES = data;
        })
      );
    }
  }

  private loadCompanyReferencesCategory(): Observable<void> {
    return this.dataService.StaticData_GetCompanyPreferenceCategories().pipe(
      map((data: ICompanyPreferenceCategory[]): void => {
        this.companyReferencesCategory = data;
      })
    );
  }

  private loadCompanyReferencesStructure(): Observable<void> {
    return this.dataService.StaticData_GetCompanyPreferences().pipe(
      map((staticData: ICompanyPreference[]): void => {
        this.companyReferencesStructure = staticData;
      })
    );
  }

  private GetUserPreferences(): void {
    if (Global.USER_PREFERENCES && Global.USER_PREFERENCES.length > 0) {
      this.dataService.StaticData_GetUserPreferences().subscribe((staticData: IUserPreference[]): void => {
        this.createPreferences(Global.USER_PREFERENCES, staticData).then((preferences: any) => {
          this.userPreferences = preferences;
          this.isDataReady = true;
        });
      });
    } else {
      this.dataService.Preferences_GetUserPreferences().subscribe((keyValuePairs: ISimpleKeyValuePair[]): void => {
        this.dataService.StaticData_GetUserPreferences().subscribe((staticData: IUserPreference[]): void => {
          this.createPreferences(keyValuePairs, staticData).then((preferences: any) => {
            this.userPreferences = preferences;
            this.isDataReady = true;
          });
        });
      });
    }
  }

  private createPreferences(keyValuePairs: ISimpleKeyValuePair[], staticData: any[]): Promise<PreferenceViewModel[]> {
    return new Promise<PreferenceViewModel[]>((resolve: (value: any) => void, reject: (value: any) => void) => {
      let results: PreferenceViewModel[] = [];
      const dataSourceCount: number = staticData.filter((data: any) => this.isDataTypeCombobox(data.UiControlType))
        .length;
      let dataSourceLoaded = 0;
      let processedPref = 0;
      for (const data of staticData) {
        const pref: PreferenceViewModel = new PreferenceViewModel();
        if (!data.UiControlType) {
          continue;
        }

        pref.Id = data.Id;
        pref.CategoryId = data.CategoryId;
        pref.Key = data.Key;
        pref.DataType = data.UiControlType;
        pref.Name = data.Name;
        pref.Description = data.Description;
        pref.HelpUrl = data.HelpUrl;
        pref.RequiredModuleId = data.RequiredModuleId;
        pref.MinimumRoleId = data.MinimumRoleId;
        pref.SortOrder = data.SortKey ? this.sessionService.parseInt(data.SortKey) : undefined;
        pref.listDataSource = data.listDataSource;

        if (!pref.MinimumRoleId || pref.MinimumRoleId <= Global.SESSION.CurrentRole.Id) {
          pref.IsVisible = true;
        } else {
          pref.IsVisible = false;
        }

        const foundSetting: ISimpleKeyValuePair = this.foundSetting(pref.Key, keyValuePairs);
        if (foundSetting) {
          if (this.isDataTypeBoolean(pref.DataType)) {
            pref.Value = foundSetting.Value === 'true' ? true : false;
          } else if (this.isDataTypeNumber(pref.DataType)) {
            pref.Value = parseInt(foundSetting.Value, 10);
          } else {
            pref.Value = foundSetting.Value;
          }
        } else {
          // Setting not found - get default value
          if (this.isDataTypeBoolean(pref.DataType)) {
            pref.Value = data.Default === 'true' ? true : false;
          } else if (this.isDataTypeNumber(pref.DataType)) {
            pref.Value = parseInt(data.Default, 10);
          } else {
            pref.Value = data.Default;
          }
        }

        if (this.isDataTypeCombobox(pref.DataType)) {
          this.loadComboboxDataSource(results, pref).then(() => {
            dataSourceLoaded += 1;
            processedPref += 1;
            results.push(pref);
            if (dataSourceCount === dataSourceLoaded && processedPref === staticData.length) {
              results = results.sort((data1: any, data2: any) =>
                GridHelper.sortByNumberValue(data1.SortOrder, data2.SortOrder)
              );
              return resolve(results);
            }
          });
        } else {
          processedPref += 1;
          results.push(pref);
        }
      }

      if (dataSourceCount === 0) {
        results = results.sort((data1: any, data2: any) =>
          GridHelper.sortByNumberValue(data1.SortOrder, data2.SortOrder)
        );
        return resolve(results);
      }
    });
  }

  private hasDataOnClient(api: string): boolean {
    return this.apiReponses[api];
  }

  private loadComboboxDataSource(preferences: PreferenceViewModel[], control: PreferenceViewModel): Promise<any> {
    return new Promise<PreferenceViewModel[]>((resolve: (value: any) => void, reject: (value: any) => void) => {
      const listDataSourceParts: any = this.getPartsFromListDataSourceStringOfCombobox(control.listDataSource);
      control.UnderlyingId = listDataSourceParts.UnderlyingId;
      control.DisplayText = listDataSourceParts.DisplayText;

      if (this.hasDataOnClient(listDataSourceParts.api)) {
        control.DataSource = this.apiReponses[listDataSourceParts.api];
        return resolve(control);
      }

      this.apiReponses[listDataSourceParts.api] = []; // Block request with the same api route.
      this.getComboboxDataSource(listDataSourceParts.api).subscribe((data: any) => {
        this.apiReponses[listDataSourceParts.api] = data;
        control.DataSource = data;

        // Update other controls.
        if (preferences && preferences.length > 0) {
          preferences.forEach((pref: PreferenceViewModel) => {
            const prefSourceParts: any = this.getPartsFromListDataSourceStringOfCombobox(pref.listDataSource);
            if (prefSourceParts.api === listDataSourceParts.api) {
              pref.DataSource = data;
            }
          });
        }

        return resolve(control);
      });
    });
  }

  private getComboboxDataSource(api: string): Observable<any> {
    const path: string = environment.apiUrl + '/' + api;
    return this.dataService.httpGet(path);
  }

  private getPartsFromListDataSourceStringOfCombobox(stringListDataSource: string): any {
    stringListDataSource = stringListDataSource.replace(':', ';');
    const parts: string[] = stringListDataSource.split(';');
    return {
      api: parts[0],
      UnderlyingId: parts[1],
      DisplayText: parts[2]
    };
  }

  private foundSetting(key: string, pairs: ISimpleKeyValuePair[]): ISimpleKeyValuePair {
    for (const pair of pairs) {
      if (pair.Key === key) {
        return pair;
      }
    }

    return undefined;
  }

  private updateSetting(item: ISimpleKeyValuePair): void {
    if (this.owner === 'company') {
      this.dataService.Preferences_SetCompanyPreference(item).subscribe(
        (response: ISimpleKeyValuePair): void => {
          const currentCompanySettings: ISimpleKeyValuePair[] = JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES));
          Global.COMPANY_PREFERENCES = this.updateLocalStorageSetting(response, currentCompanySettings);
        },
        (complete: any): void => {
          if (complete.status) {
            this.GetCurrentSettings();
          }
        }
      );
    }

    if (this.owner === 'user') {
      this.dataService.Preferences_SetUserPreference(item).subscribe(
        (response: ISimpleKeyValuePair): void => {
          const currentUserSettings: ISimpleKeyValuePair[] = JSON.parse(JSON.stringify(Global.USER_PREFERENCES));
          Global.USER_PREFERENCES = this.updateLocalStorageSetting(response, currentUserSettings);
        },
        (complete: any): void => {
          if (complete.status) {
            this.GetCurrentSettings();
          }
        }
      );
    }
  }

  private updateLocalStorageSetting(
    newSetting: ISimpleKeyValuePair,
    oldSettings: ISimpleKeyValuePair[]
  ): ISimpleKeyValuePair[] {
    const existingSetting: ISimpleKeyValuePair = oldSettings.find((pref: any) => pref.Key === newSetting.Key);
    if (existingSetting) {
      existingSetting.Value = newSetting.Value;
    } else {
      oldSettings.push(newSetting);
    }

    return oldSettings;
  }
}
