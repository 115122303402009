<app-dialog
  id="avatarDialog"
  class="Form--stacked ModalDialogInfo"
  [title]="'Account.ChooseUploadFile' | translate"
  [overlayClose]="false"
  [(visible)]="visible"
  [isAvatar]="true"
  [width]="350"
>
  <app-picture-edit
    [label]="'Employee.Change picture' | translate"
    [isAvatar]="true"
    [isEmployeeAvatar]="true"
    [changeText]="'Employee.Change picture' | translate"
    [takePhotoText]="'Employee.Take photo' | translate"
    [editMode]="true"
    [imageSource]="avatar"
    (change)="onAvatarChanged($event)"
  >
  </app-picture-edit>
  <div>{{ 'Account.MaximumSizeOfProfilePictureMessage' | translate }}</div>
</app-dialog>
