<span [ngClass]="isHidden ? 'Icon--Payslip--hide' : ''">
  <button
    kendoTooltip
    title="{{ 'EmploymentPayrollData.HTMLPopupToolTip' | translate }}"
    type="button"
    (click)="onButtonClick()"
  >
    <span *ngIf="showInCurrentTab" title="{{ 'EmploymentPayrollData.HTMLPopupToolTip' | translate }}">
      <app-icon [tooltip]="'EmploymentPayrollData.HTMLPopupToolTip' | translate" [icon]="'html-outline'"> </app-icon>
    </span>
  </button>
  <button
    *ngIf="showInNewTab && IsWeb"
    kendoTooltip
    title="{{ 'EmploymentPayrollData.HTMLFileToolTip' | translate }}"
    type="button"
    (click)="onButtonClick()"
  >
    <span title="{{ 'EmploymentPayrollData.HTMLFileToolTip' | translate }}">
      <app-icon [tooltip]="'EmploymentPayrollData.HTMLFileToolTip' | translate" [icon]="'html'"> </app-icon>
    </span>
  </button>
  <button
    *ngIf="showPDF"
    kendoTooltip
    title="{{ 'CompanySalaryBatches.PaySlipPDFTooltip' | translate }}"
    type="button"
    (click)="onButtonClick()"
  >
    <span title="{{ 'CompanySalaryBatches.PaySlipPDFTooltip' | translate }}">
      <app-icon [tooltip]="'CompanySalaryBatches.PaySlipPDFTooltip' | translate" [icon]="'pdf'"> </app-icon>
    </span>
  </button>
</span>
