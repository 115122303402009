import { EventEmitter } from '@angular/core';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
var DeleteEmployeeComponent = /** @class */ (function () {
    function DeleteEmployeeComponent(dataService, sessionService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.isVisibleValue = false;
        this.isVisibleChange = new EventEmitter();
        this.currentUserEmploymentChange = new EventEmitter();
        this.currentCompanyUserChange = new EventEmitter();
        this.employeeDeleted = new EventEmitter();
        this.userEmploymentDeleted = new EventEmitter();
    }
    Object.defineProperty(DeleteEmployeeComponent.prototype, "isVisible", {
        get: function () {
            return this.isVisibleValue;
        },
        set: function (value) {
            if (value) {
            }
            this.isVisibleValue = value;
            this.isVisibleChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeleteEmployeeComponent.prototype, "currentUserEmployment", {
        get: function () {
            return this.currentUserEmploymentValue;
        },
        set: function (value) {
            if (value) {
            }
            this.currentUserEmploymentValue = value;
            this.currentUserEmploymentChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeleteEmployeeComponent.prototype, "currentCompanyUser", {
        get: function () {
            return this.currentCompanyUserValue;
        },
        set: function (value) {
            if (value) {
            }
            this.currentCompanyUserValue = value;
            this.currentCompanyUserChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeleteEmployeeComponent.prototype, "hasEmployment", {
        get: function () {
            var k = this.currentUserEmployment && this.currentUserEmployment.Id ? true : false;
            return k;
        },
        enumerable: true,
        configurable: true
    });
    DeleteEmployeeComponent.prototype.deleteEmployment = function () {
        var _this = this;
        var userEmploymentToGo = this.currentUserEmployment;
        if (!userEmploymentToGo) {
            return;
        }
        this.dataService.Employments_DeleteUserEmployment(userEmploymentToGo.Id).subscribe(function () {
            _this.onUserEmploymentDeletedEvent(userEmploymentToGo);
        });
    };
    DeleteEmployeeComponent.prototype.deleteCompanyUser = function () {
        var _this = this;
        var companyUserToGo = this.currentCompanyUser;
        if (!companyUserToGo) {
            return;
        }
        this.dataService.CompanyUsers_DeleteCompanyUser(companyUserToGo.Id).subscribe(function () {
            _this.onEmployeeDeletedEvent(companyUserToGo);
        });
    };
    DeleteEmployeeComponent.prototype.onEmployeeDeletedEvent = function (deletedEmployee) {
        this.employeeDeleted.emit(deletedEmployee);
    };
    DeleteEmployeeComponent.prototype.onUserEmploymentDeletedEvent = function (deletedUserEmployment) {
        this.userEmploymentDeleted.emit(deletedUserEmployment);
    };
    //@Output() public onEmployeeDeleted: (eventArgs: { deletedEmployee: ICompanyUser }) => void;
    //@Output() public onUserEmploymentDeleted: (eventArgs: { deletedUserEmployment: IUserEmployment }) => void;
    DeleteEmployeeComponent.prototype.onDeleteEmployeeAction = function (action) {
        if (action === 'DeleteEmployment') {
            this.deleteEmployment();
        }
        else if (action === 'DeleteCompanyUser') {
            this.deleteCompanyUser();
        }
    };
    return DeleteEmployeeComponent;
}());
export { DeleteEmployeeComponent };
