import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { ReflectionHelper } from '../Common/ReflectionHelper';
import { UniqueId } from '../Common/UniqueId';
var AutoCompleteTextBoxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AutoCompleteTextBoxComponent, _super);
    function AutoCompleteTextBoxComponent(elementRef, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.isRequired = false;
        _this.allowCustom = true;
        _this.isSuggest = true;
        _this.isLabelHtml = false;
        _this.autocompleteby1value = false;
        _this.viewClass = '';
        _this.firstTimeNotFilter = true;
        _this.componentId = 'autoCompleteEdit' + UniqueId();
        return _this;
    }
    Object.defineProperty(AutoCompleteTextBoxComponent.prototype, "autoCompleteDataSource", {
        get: function () {
            return this.autoCompleteDataSourceValue;
        },
        set: function (value) {
            if (this.autoCompleteDataSourceValue !== value) {
                this.autoCompleteDataSourceValue = value;
                this.autoCompleteFilterChanged(this.value);
            }
        },
        enumerable: true,
        configurable: true
    });
    AutoCompleteTextBoxComponent.prototype.convertValue = function (value) {
        return !value || ReflectionHelper.isString(value) ? value : value.toString();
    };
    AutoCompleteTextBoxComponent.prototype.valid = function () {
        var _this = this;
        var result = true;
        if (!this.allowCustom && this.autoCompleteDataSource && this.value) {
            // Select Item from Source.
            result =
                this.autoCompleteDataSource.filter(function (item) { return _this.getValueByDataType(item) === _this.value; }).length > 0;
            if (!result) {
                result = false;
                var suggestedItem = this.autoCompleteDataSource.find(function (item) { return _this.getValueByDataType(item).indexOf(_this.value) >= 0; });
                this.value = this.getValueByDataType(suggestedItem);
            }
        }
        return result;
    };
    Object.defineProperty(AutoCompleteTextBoxComponent.prototype, "showValidationMessage", {
        get: function () {
            return (this.isRequired &&
                this.editMode &&
                !this.value &&
                this.translatedRequiredMessage &&
                this.translatedRequiredMessage.length !== 0);
        },
        enumerable: true,
        configurable: true
    });
    AutoCompleteTextBoxComponent.prototype.autoCompleteFilterChanged = function (value) {
        var _this = this;
        if (value && this.editMode && (value !== this.original || !this.firstTimeNotFilter)) {
            this.firstTimeNotFilter = false;
            value = value.toString();
            var filterDataSource = this.autoCompleteDataSource
                ? this.autoCompleteDataSource.filter(function (item) {
                    return ReflectionHelper.isString(item)
                        ? item
                            ? item.toLowerCase().indexOf(value.toLowerCase()) !== -1
                            : false
                        : item && _this.textField && item[_this.textField]
                            ? item[_this.textField].toLowerCase().indexOf(value.toLowerCase()) !== -1
                            : false;
                })
                : undefined;
            if (!this.autocompleteby1value) {
                if (value.length >= 3 && filterDataSource && filterDataSource.length > 0) {
                    this.dataSource = filterDataSource;
                }
                else {
                    if (this.autocomplete) {
                        this.autocomplete.toggle(false);
                    }
                }
            }
            else {
                if (value.length >= 1 && filterDataSource && filterDataSource.length > 0) {
                    this.dataSource = filterDataSource;
                }
                else {
                    if (this.autocomplete) {
                        this.autocomplete.toggle(false);
                    }
                }
            }
        }
        else {
            if (this.autocomplete) {
                this.autocomplete.toggle(false);
            }
        }
    };
    AutoCompleteTextBoxComponent.prototype.getValueByDataType = function (item) {
        return item ? (ReflectionHelper.isString(item) ? item : this.textField ? item[this.textField] : '') : '';
    };
    return AutoCompleteTextBoxComponent;
}(ControlComponentBase));
export { AutoCompleteTextBoxComponent };
