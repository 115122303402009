<span *ngIf="isMoreOneCompany">
  <div class="PayslipsNote" [ngClass]="{ Width900: haveSalaryStatements }">{{ selfservicepayslipNote }}</div>
</span>
<div
  class="{{ previewClicked ? 'viewSlip loaded SelfServicePayslipContainer' : 'SelfServicePayslipContainer' }}"
  (click)="onClickOutside($event)"
  tabindex="-1"
  (keypress)="onTableKeydown($event)"
>
  <div class="SelfServicePayslip">
    <table class="Payslips-list" [ngClass]="{ 'Payslips-list-narrower': previewClicked }">
      <thead>
        <tr>
          <th>{{ 'SelfServicePayslip.Payout date' | translate }}</th>
          <th>
            {{ 'SelfServicePayslip.Salary' | translate }}
          </th>
          <th class="show-html-payslip">
            {{ 'SelfServicePayslip.Supplements' | translate }}
          </th>
          <th class="show-html-payslip">
            {{ 'SelfServicePayslip.Pension' | translate }}
          </th>
          <th>
            {{ 'SelfServicePayslip.Taxes' | translate }}
          </th>
          <th class="show-html-payslip">
            {{ 'SelfServicePayslip.Adjustments' | translate }}
          </th>
          <th>{{ 'SelfServicePayslip.Paid out' | translate }}</th>
          <th class="icons"></th>
          <th class="icons open-icon" *ngIf="!isCordovaApp"></th>
          <th class="icons open-icon" *ngIf="isCordovaApp"></th>
          <th *ngIf="previewClicked"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of salaryStatements; let i = index"
          data-id="{{ item.salaryStatement.Id }}"
          (click)="onPayslipSelected(item, i)"
          [ngClass]="{ active: item.isSelected }"
        >
          <td [innerHTML]="item.salaryStatement.SalaryBatch.PayoutDate | DateFormat: undefined:true"></td>
          <td>
            {{ item.salaryStatement.SummaryAmountSalary | NumericFormat }}
          </td>
          <td class="show-html-payslip">
            {{ item.salaryStatement.SummaryAmountSupplements | NumericFormat }}
          </td>
          <td class="show-html-payslip">
            {{ item.salaryStatement.SummaryAmountPension | NumericFormat }}
          </td>
          <td>
            {{ item.salaryStatement.SummaryAmountTaxes | NumericFormat }}
          </td>
          <td class="show-html-payslip">
            {{ item.salaryStatement.SummaryAmountAdjustments | NumericFormat }}
          </td>
          <td>{{ item.salaryStatement.PayoutAmount | NumericFormat }}</td>
          <td *ngIf="!isCordovaApp" class="icons ">
            <action-button
              [icon]="'Download'"
              (click)="onDownloadPayslip($event, item.salaryStatement.Id)"
              [tooltip]="'SelfServicePayslip.PaySlipViewPdf' | translate"
            >
            </action-button>
          </td>
          <td *ngIf="isCordovaApp" class="icons ">
            <action-button [icon]="'Download'" (click)="onDownloadPayslip($event, item.salaryStatement.Id)">
            </action-button>
          </td>
          <td *ngIf="!isCordovaApp" class="icons  open-icon">
            <action-button
              [icon]="'ExternalLink'"
              *ngIf="!isCordovaApp"
              (click)="onOpenPayslipNewTab($event, item.salaryStatement.Id)"
              [tooltip]="'SelfServicePayslip.PaySlipViewLink' | translate"
            >
            </action-button>
          </td>

          <td *ngIf="isCordovaApp" class="icons  open-icon">
            <action-button
              [icon]="'ExternalLink'"
              *ngIf="isCordovaApp"
              (click)="downloadSalaryStatementsPreview(item.salaryStatement)"
              [tooltip]="'EmploymentPayrollData.HTMLPopupToolTip' | translate"
            >
            </action-button>
          </td>
          <td *ngIf="previewClicked"></td>
        </tr>
      </tbody>
    </table>

    <div class="Payslip-view" *ngIf="!isCordovaApp">
      <div class="Payslip-container">
        <div class="Payslip-navigation" *ngIf="previewClicked">
          <action-button
            [icon]="'Times'"
            [buttonClass]="'Payslip-navigationItem'"
            (click)="onClosePayslipClicked()"
            [tooltip]="'SelfServicePayslip.PaySlipViewClose' | translate"
          ></action-button>
          <action-button
            [icon]="'ExternalLink'"
            [buttonClass]="'Payslip-navigationItem'"
            (click)="onOpenPayslipNewTab()"
            [tooltip]="'SelfServicePayslip.PaySlipViewLink' | translate"
          >
          </action-button>
          <action-button
            [icon]="'Download'"
            [buttonClass]="'Payslip-navigationItem'"
            (click)="onDowloadShownPayslip()"
            [tooltip]="'SelfServicePayslip.PaySlipViewPdf' | translate"
          ></action-button>
          <action-button
            [icon]="'CaretUp'"
            [disabled]="previousBtnDisabled"
            [buttonClass]="'Payslip-navigationItem'"
            (click)="onViewPreviousPayslipClicked()"
            [tooltip]="'SelfServicePayslip.PaySlipViewPrevious' | translate"
          >
          </action-button>
          <action-button
            [icon]="'CaretDown'"
            [disabled]="nextBtnDisabled"
            [buttonClass]="'Payslip-navigationItem'"
            (click)="onViewNextPayslipClicked()"
            [tooltip]="'SelfServicePayslip.PaySlipViewNext' | translate"
          >
          </action-button>
        </div>

        <div class="Payslip">
          <div class="Payslip-iframe">
            <div *ngIf="htmlContent.length === 0" class="Payslip-loader"><span>Fetching payslip&hellip;</span></div>
            <div class="Payslip-iframe" *ngIf="htmlContent.length > 0">
              <iframe [srcdoc]="safeContent" src="about:blank"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-dialog class="PayslipPopup" [(visible)]="paySlipPreviewDialog" [iframeContent]="htmlContent"> </app-dialog>

<app-dialog
  *ngIf="!isCordovaApp"
  [(visible)]="newTabBlockedDialogVisible"
  [leadingText]="'Report.NewTabBlocked' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
