import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { version } from '../../../version';
import { SessionService } from '../SharedServices/Session/SessionService';

@Injectable()
export class AuthenticationTokenInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      const authorizationToken: any = sessionStorage.getItem('token');
      if (authorizationToken) {
        const client: string = this.sessionService.browser.isHybridApp ? 'MobileClient' : 'WebClient';
        const headers: HttpHeaders = request.headers
          .set('Authorization', authorizationToken)
          .set('Gratisal-Client', client)
          .set('Gratisal-Version', version);
        request = request.clone({ headers });
      }

      return next.handle(request);
    } catch (e) {
      this.sessionService.isDetectedCookieDisable = true;
      return undefined;
    }
  }
}
