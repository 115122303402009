import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImportResultEnum, ImportTypesEnum } from '../../../Model/Enum';

@Component({
  selector: 'import-errors-dialog',
  templateUrl: './ImportErrorsDialog.component.html'
})
export class ImportErrorsDialogComponent {
  @Input() public testImport = true;
  @Input() public importType: ImportTypesEnum;

  private importResultValue: any;
  @Input()
  public get importResult(): any {
    return this.importResultValue;
  }
  public set importResult(value: any) {
    this.importResultValue = value;
    if (value) {
      this.InitialErrorGrid();
    }
  }

  @Output() public closedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public completeImportEvent: EventEmitter<void> = new EventEmitter<void>();

  public visible = false;
  public successDialogVisible = false;
  public fatalDialogVisible = false;

  public get showTestImportError(): boolean {
    return this.hasError && this.testImport;
  }
  public get hasError(): boolean {
    return this.importResult && this.importResult.Errors && this.importResult.Errors.length > 0;
  }
  public get IsFatal(): boolean {
    return this.importResult && this.importResult.Outcome === ImportResultEnum[ImportResultEnum.FatalError];
  }
  public get IsError(): boolean {
    return this.importResult && this.importResult.Outcome === ImportResultEnum[ImportResultEnum.Errors];
  }
  public get IsWarning(): boolean {
    return this.importResult && this.importResult.Outcome === ImportResultEnum[ImportResultEnum.Warnings];
  }
  public get isBalanceImport(): boolean {
    return this.importType === ImportTypesEnum.Initial_Balances;
  }

  public onDialogAction(action: string): void {
    if (action === 'Complete') {
      this.completeImportEvent.emit();
    }

    this.closedEvent.emit();
  }

  private InitialErrorGrid(): void {
    if (this.importResult && this.importResult.Outcome) {
      switch (this.importResult.Outcome) {
        case ImportResultEnum[ImportResultEnum.Success]:
          this.successDialogVisible = true;
          break;
        case ImportResultEnum[ImportResultEnum.FatalError]:
          if (this.importResult.Errors && this.importResult.Errors.length > 0) {
            this.visible = true;
          } else {
            this.fatalDialogVisible = true;
          }

          break;
        default:
          this.visible = true;
      }
    }
  }
}
