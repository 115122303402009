<app-dialog
  width="794"
  [overlayClose]="false"
  [(visible)]="isVisible"
  (action)="closeForm($event)"
  [title]="'NewEmployee.Manual details input' | translate"
  class="Form--newEmployeeModal ModalDialogInfo"
  [customVisible]="true"
>
  <div class="Modal-content CustoomEmployeeContent">
    <div class="FormElement">
      <div class="FormElement-legend FormElement-label">
        <span> <abbr class="FormElement-required" title="required">*</abbr>{{ 'NewEmployee.Name' | translate }} </span>
      </div>
      <div class="FormElement-row">
        <div class="FormElement ">
          <label class="FormElement-label u-hideMobile" for="firstname">
            {{ ('NewEmployee.First name' | translate) + ': ' }}
          </label>
          <input
            class="FormElement-input"
            type="text"
            id="firstname"
            placeholder="{{ 'NewEmployee.First name' | translate }}"
            [(ngModel)]="viewModel.AddUserCompanyRequest.Details.FirstName"
          />
        </div>
        <div class="FormElement ">
          <label class="FormElement-label u-hideMobile" for="middlename">
            {{ ('NewEmployee.Middle name' | translate) + ': ' }}
          </label>
          <input
            class="FormElement-input"
            type="text"
            id="middlename"
            placeholder="{{ 'NewEmployee.Middle name' | translate }}"
            [(ngModel)]="viewModel.AddUserCompanyRequest.Details.MiddleName"
          />
        </div>
        <div class="FormElement ">
          <label class="FormElement-label u-hideMobile" for="lastname">
            {{ ('NewEmployee.Last name' | translate) + ': ' }}
          </label>
          <input
            class="FormElement-input"
            type="text"
            id="lastname"
            placeholder="{{ 'NewEmployee.Last name' | translate }}"
            [(ngModel)]="viewModel.AddUserCompanyRequest.Details.LastName"
          />
        </div>
      </div>
    </div>

    <div class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control ">
          <app-text-edit
            [label]="'NewEmployee.CPR no' | translate"
            [editMode]="true"
            style="width: 100%"
            [disabled]="viewModel.NoCPRNumber"
            [isCPRno]="!viewModel.NoCPRNumber"
            [(value)]="viewModel.AddUserCompanyRequest.IdentityNumber"
          ></app-text-edit>
        </div>

        <div class="FormElement-control ">
          <label class="FormElement-checkbox">
            <input
              type="checkbox"
              [(ngModel)]="viewModel.NoCPRNumber"
              (ngModelChange)="onNoCPRCheckboxClicked($event)"
            />
            <span class="FormElement-checkboxFaux"></span>
            <span class="FormElement-label">{{ 'NewEmployee.No CPR number' | translate }}</span>
          </label>
        </div>
      </div>
    </div>

    <div class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control ">
          <app-text-edit
            [label]="'NewEmployee.Email' | translate"
            [editMode]="true"
            [type]="'email'"
            style="width: 100%"
            [(value)]="viewModel.AddUserCompanyRequest.Details.CompanyEmail"
          ></app-text-edit>
        </div>

        <div class="FormElement-control ">
          <app-text-edit
            [label]="'NewEmployee.Email private' | translate"
            [editMode]="true"
            [type]="'email'"
            style="width: 100%"
            [(value)]="viewModel.AddUserCompanyRequest.Details.PersonalEmail"
          ></app-text-edit>
        </div>
      </div>
    </div>

    <div class="FormElement">
      <div class="FormElement-control">
        <combobox-edit-control
          style="width: 100%"
          [label]="'NewEmployee.Role' | translate"
          [editMode]="true"
          [required]="true"
          [comboboxDataSource]="viewModel.Roles"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="viewModel.AddUserCompanyRequest.Details.RoleId"
        ></combobox-edit-control>
      </div>
    </div>

    <div class="FormElement">
      <div class="FormElement-control">
        <combobox-edit-control
          style="width: 100%"
          [label]="'NewEmployee.Template' | translate"
          [editMode]="true"
          [disable]="viewModel.NoCPRNumber"
          [comboboxDataSource]="viewModel.Templates"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="viewModel.AddUserCompanyRequest.EmploymentTemplateId"
          (valueChange)="onEmploymentTemplateChanged()"
          [nullValueLabel]="'NewEmployee.Choose existing or add new position' | translate"
        ></combobox-edit-control>
      </div>
    </div>

    <div *ngIf="employmentTemplateSelected">
      <div class="FormElement-control">
        <auto-complete-textbox
          style="width: 100%"
          id="aucompleteNewEmployee_Title"
          class="FormElement-autoComplete"
          [autoCompleteDataSource]="viewModel.Titles"
          [isLabelHtml]="true"
          [label]="('NewEmployee.Title' | translate) + ': '"
          [editMode]="true"
          [(value)]="viewModel.AddUserCompanyRequest.Title"
        >
        </auto-complete-textbox>
      </div>

      <module-check
        *ngIf="!viewModel.Departments || !(viewModel.Departments.length === 0)"
        [moduleId]="4"
        [(hasModule)]="hasDepartmentEnabled"
      >
        <div class="FormElement">
          <div class="FormElement-control">
            <combobox-edit-control
              style="width: 100%"
              [disable]="!hasDepartmentEnabled"
              [label]="'EmployeeGeneral.Department' | translate"
              [editMode]="true"
              [comboboxDataSource]="viewModel.Departments"
              [textField]="'Name'"
              [idField]="'Id'"
              [(value)]="viewModel.AddUserCompanyRequest.DepartmentId"
              (valueChange)="onDepartmentComboBoxChanged()"
              [nullValueLabel]="'DropdownList.None' | translate"
            ></combobox-edit-control>
          </div>
        </div>
      </module-check>

      <div class="FormElement" *ngIf="isShowForEmploymentTemplateId">
        <div class="FormElement-control">
          <combobox-edit-control
            style="width: 100%"
            [label]="'NewEmployee.Manager' | translate"
            [editMode]="true"
            [comboboxDataSource]="managers"
            [textField]="'FullName'"
            [idField]="'Id'"
            [(value)]="viewModel.AddUserCompanyRequest.ManagerCompanyUserId"
            [nullValueLabel]="'DropdownList.None' | translate"
          ></combobox-edit-control>
        </div>
      </div>

      <div class="FormElement">
        <div class="FormElement-control">
          <combobox-edit-control
            style="width: 100%"
            [label]="'NewEmployee.TaxCardType' | translate"
            [editMode]="true"
            [comboboxDataSource]="taxCardTypes"
            [textField]="'Name'"
            [idField]="'Id'"
            [(value)]="viewModel.AddUserCompanyRequest.TaxCardTypeId"
          ></combobox-edit-control>
        </div>
      </div>

      <div *ngIf="employmentTemplateSelected">
        <percentage-edit
          *ngIf="IsGreenLandCompany"
          [label]="'NewEmployee.TaxRate' | translate"
          [editMode]="true"
          [(value)]="viewModel.AddUserCompanyRequest.TaxRate"
        ></percentage-edit>

        <numeric-edit
          *ngIf="IsGreenLandCompany && IsPrimanyTaxCard"
          [label]="'NewEmployee.MonthlyDeduction' | translate"
          [editMode]="true"
          [(value)]="viewModel.AddUserCompanyRequest.MonthlyTaxDeduction"
        ></numeric-edit>

        <numeric-edit
          *ngIf="IsGreenLandCompany && IsFreeTaxCard"
          [label]="'NewEmployee.RemainingFreeAmount' | translate"
          [editMode]="true"
          [(value)]="viewModel.AddUserCompanyRequest.TaxCardFreeAmount"
        ></numeric-edit>
      </div>

      <div class="FormElement">
        <div class="FormElement-control">
          <date-picker-control
            [e2e]="'HiringDate'"
            style="width: 100%"
            [isLabelHtml]="true"
            [label]="('NewEmployee.Hiring date' | translate) + ': '"
            [editMode]="true"
            [required]="employmentTemplateSelected"
            [requiredMessage]="'EmployeeGeneral.Required' | translate"
            [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
            [(value)]="viewModel.AddUserCompanyRequest.HireDate"
            [(isValid)]="isValidDate"
          ></date-picker-control>
        </div>
      </div>
    </div>

    <module-check [moduleId]="5" [(hasModule)]="hasLanguageModule">
      <div class="FormElement">
        <div class="FormElement-control">
          <combobox-edit-control
            style="width: 100%"
            [disable]="!hasLanguageModule"
            [label]="'NewEmployee.Language' | translate"
            [editMode]="true"
            [comboboxDataSource]="viewModel.Languages"
            [textField]="'Name'"
            [idField]="'Id'"
            [(value)]="viewModel.AddUserCompanyRequest.LanguageId"
          ></combobox-edit-control>
        </div>
      </div>
    </module-check>

    <div>
      <div class="">
        <div class="FormElement-control">
          <app-text-edit
            [label]="'NewEmployee.Address' | translate"
            [editMode]="true"
            style="width: 100%"
            [(value)]="viewModel.AddUserCompanyRequest.Details.Address.Line1"
          ></app-text-edit>
        </div>
      </div>

      <div class="">
        <div class="FormElement-row">
          <div class="FormElement-control">
            <app-text-edit
              [label]="'NewEmployee.Postal Code' | translate"
              [editMode]="true"
              style="width: 100%"
              (blur)="onPostalCodeChanged(viewModel.AddUserCompanyRequest.Details.Address.PostalCode)"
              [(value)]="viewModel.AddUserCompanyRequest.Details.Address.PostalCode"
            ></app-text-edit>
          </div>

          <div class="FormElement-control">
            <app-text-edit
              [label]="'NewEmployee.City' | translate"
              [editMode]="true"
              style="width: 100%"
              [(value)]="viewModel.AddUserCompanyRequest.Details.Address.City"
              (blur)="onCityBlurChange(viewModel.AddUserCompanyRequest.Details.Address.City)"
            ></app-text-edit>
          </div>
        </div>
      </div>

      <div class="FormElement" *ngIf="IsGreenLandCompany && employmentTemplateSelected">
        <div class="FormElement-control">
          <combobox-edit-control
            style="width: 100%"
            [label]="'NewEmployee.Municipality' | translate"
            [editMode]="true"
            [textField]="'Name'"
            [idField]="'Id'"
            [required]="true"
            [comboboxDataSource]="Municipality"
            [(value)]="viewModel.AddUserCompanyRequest.Details.MunicipalityId"
            (valueChange)="onMunicipalityChanged()"
          ></combobox-edit-control>
        </div>
      </div>

      <div class="FormElement">
        <div class="FormElement-row">
          <div class="FormElement-control">
            <combobox-edit-control
              style="width: 100%"
              [label]="'NewEmployee.Country' | translate"
              [editMode]="true"
              [comboboxDataSource]="viewModel.Countries"
              [textField]="'Name'"
              [idField]="'Id'"
              [(value)]="viewModel.AddUserCompanyRequest.Details.Address.CountryId"
              (valueChange)="onCountryChange()"
            ></combobox-edit-control>
          </div>
        </div>
      </div>

      <div *ngIf="IsGreenLandCompany && employmentTemplateSelected">
        <div class="FormElement">
          <div class="FormElement-row">
            <div class="FormElement ">
              <span class="FormElement-label"></span>
              <div class="FormElement-control">
                <label class="FormElement-checkbox" style="margin-left: 0">
                  <input type="checkbox" [(ngModel)]="viewModel.AddUserCompanyRequest.ApplyDanishRulesInGreenland" />
                  <span class="FormElement-checkboxFaux"></span>
                  <span class="FormElement-label">
                    {{ 'NewEmployee.CalculateDanishATPandAMcontribution' | translate }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control ">
          <app-text-edit
            [label]="'NewEmployee.Phone no' | translate"
            [editMode]="true"
            [type]="'number'"
            style="width: 100%"
            [(value)]="viewModel.AddUserCompanyRequest.Details.TelMobile"
          ></app-text-edit>
        </div>

        <div class="FormElement-control ">
          <app-text-edit
            [label]="'NewEmployee.Phone no private' | translate"
            [editMode]="true"
            [type]="'number'"
            style="width: 100%"
            [(value)]="viewModel.AddUserCompanyRequest.Details.TelFixed"
          ></app-text-edit>
        </div>
      </div>
    </div>

    <div *ngIf="isShowForEmploymentTemplateId">
      <div class="FormElement">
        <div class="FormElement-row">
          <div class="FormElement-control ">
            <app-text-edit
              [label]="'NewEmployee.Register no' | translate"
              [editMode]="true"
              style="width: 100%"
              [(value)]="viewModel.AddUserCompanyRequest.Details.BankRegNo"
            ></app-text-edit>
          </div>

          <div class="FormElement-control ">
            <app-text-edit
              [label]="'NewEmployee.Bank account no' | translate"
              [editMode]="true"
              style="width: 100%"
              [(value)]="viewModel.AddUserCompanyRequest.Details.BankAccountNo"
            ></app-text-edit>
          </div>
        </div>
      </div>

      <div class="FormElement">
        <div class="FormElement-row">
          <div class="FormElement-control ">
            <app-text-edit
              [label]="'NewEmployee.BIC' | translate"
              [editMode]="true"
              style="width: 100%"
              [(value)]="viewModel.AddUserCompanyRequest.Details.BicSwiftNo"
            ></app-text-edit>
          </div>

          <div class="FormElement-control ">
            <app-text-edit
              [label]="'NewEmployee.IBAN' | translate"
              [editMode]="true"
              style="width: 100%"
              [(value)]="viewModel.AddUserCompanyRequest.Details.IbanNo"
            ></app-text-edit>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showAddToCompany" class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control FormElement">
          <span class="FormElement-label"></span>
          <label class="FormElement-control FormElement-checkbox" style="margin-left: 0;">
            <input type="checkbox" [(ngModel)]="viewModel.AddUserCompanyRequest.IsAccountUser" />
            <span class="FormElement-checkboxFaux"></span>
            <span class="FormElement-label">
              {{ 'NewEmployee.AddToCompany' | translate: { company: accountName } }}
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action
    *ngIf="
      createWithoutEmail &&
      !viewModel.AddUserCompanyRequest.Details.CompanyEmail &&
      !viewModel.AddUserCompanyRequest.Details.PersonalEmail
    "
    [disabled]="sessionService.isSubmitting"
    [type]="'CreateWithoutEmails'"
  ></app-dialog-action>
  <app-dialog-action
    *ngIf="
      !(
        createWithoutEmail &&
        !viewModel.AddUserCompanyRequest.Details.CompanyEmail &&
        !viewModel.AddUserCompanyRequest.Details.PersonalEmail
      )
    "
    [disabled]="sessionService.isSubmitting"
    [type]="'Create'"
  ></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="isInvalid"
  (action)="onInvalidDialog($event)"
  [width]="'400'"
  [leadingText]="messageValid | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="isnoCPRWarning"
  (action)="onInvalidDialog($event)"
  [width]="'400'"
  [leadingText]="'NewEmployee.noCPRWarning' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="isNonCreateMail"
  (action)="onNonCreateMailDialog($event)"
  [width]="'400'"
  [leadingText]="'NewEmployee.EmailWarning' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
