import { Component, Input } from '@angular/core';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { ICompanyUser } from '../../../Services/ApiModel';

@Component({
  selector: 'app-employee-name',
  templateUrl: './EmployeeName.component.html'
})
export class EmployeeNameComponent extends FormComponentBase {
  @Input()
  public set companyUser(value: ICompanyUser) {
    this.companyUserValue = value;
  }
  public get companyUser(): ICompanyUser {
    if (!this.companyUserValue) {
      this.companyUserValue = {} as any;
    }

    return this.companyUserValue;
  }

  private companyUserValue: ICompanyUser;
  constructor() {
    super();
    this.childIsValid = Array(3).fill(true);
    this.childIsDirty = Array(3).fill(false);
  }
}
