<div class="Card Form--stacked">
  <div class="Card-inner">
    <div class="Card-title">{{ 'CompanySalaryBatches.Batches' | translate }}</div>
    <div class="Company-batchesControls">
      <combobox-edit-control
        class="Company-batchesCycles"
        [label]="'CompanySalaryBatches.Cycle' | translate"
        [editMode]="true"
        [comboboxDataSource]="salaryCycles"
        [(value)]="salaryCycleId"
        [textField]="'Name'"
        [nullValueLabel]="'CompanySalaryBatches.SalaryCycle_Item_All_Text' | translate"
        [idField]="'Id'"
      ></combobox-edit-control>

      <div class="Company-batchesControlsButtons">
        <button class="Button--card" type="button" [disabled]="!isDraft" (click)="recalculateDraft.emit()">
          {{ 'CompanySalaryBatches.Recalculate' | translate }}
        </button>
        <button
          *ngIf="sessionService.role.IsPaymentApprover && selectedBatch && (isDraft || isLocked || isPreliminary)"
          class="Button--card"
          type="button"
          (click)="finalizeDraft.emit()"
        >
          {{ 'GlobalDialog.Finalize' | translate }}
        </button>
        <button class="Button--card" type="button" *ngIf="isAddNewBatchButtonVisible" (click)="newSalaryBatch.emit()">
          {{ 'CompanySalaryBatches.SalaryBatchAddNewButton' | translate }}
        </button>
      </div>
    </div>
    <div class="FormElement">
      <grid
        [data]="gridFilterData"
        [idColumn]="'Id'"
        [filterable]="'menu'"
        [filter]="filter"
        (filterChange)="onFilterChange($event)"
        [(selectedItem)]="selectedBatch"
        (selectedItemChange)="onSalaryBatchSelectionChanged($event)"
        (clickAppGridIcon)="onGridIconClick($event)"
        [editMode]="false"
        class="FormElement-grid Company-salaryBatchesGrid"
        (dblClick)="onDoubleClick($event)"
      >
        <gridColumn
          [isFilterable]="true"
          [field]="'SalaryBatchNumber'"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField No' | translate"
          [width]="40"
          [type]="'numeric'"
          [numbericOption]="'n0'"
        ></gridColumn>
        <gridColumn
          [isFilterable]="true"
          [field]="'StatusName'"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField Status' | translate"
          [width]="90"
          [viewClass]="truncatedOrWrapped()"
          [type]="'text'"
        ></gridColumn>
        <gridColumn
          [isFilterable]="true"
          [field]="'PayoutDate'"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField Payout Date' | translate"
          [width]="80"
          [type]="'datepicker'"
        ></gridColumn>
        <gridColumn
          [isFilterable]="true"
          [iconField]="'ValidationIcon'"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField Validation' | translate"
          [width]="80"
          [type]="'icon'"
          [iconAction]="'ShowErrors'"
        ></gridColumn>
        <gridColumn
          [isFilterable]="true"
          [field]="'PeriodStartDate'"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField Start' | translate"
          [width]="80"
          [type]="'datepicker'"
        ></gridColumn>
        <gridColumn
          [isFilterable]="true"
          [field]="'PeriodEndDate'"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField End' | translate"
          [width]="80"
          [type]="'datepicker'"
        ></gridColumn>
        <gridColumn
          [isFilterable]="true"
          [class]="'u-nowrap'"
          [field]="'ApprovedByCompanyUserFullName'"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField Approver' | translate"
          [viewClass]="truncatedOrWrapped()"
          [width]="80"
          [type]="'text'"
        ></gridColumn>
        <gridColumn
          [isFilterable]="true"
          [field]="'EIncomeZeroReport'"
          [editable]="false"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField Empty' | translate"
          [width]="110"
          [type]="'checkbox'"
        ></gridColumn>
        <gridColumn
          [isFilterable]="true"
          [field]="'LimitToSpecificEmployees'"
          [editable]="false"
          [tooltip]="'CompanySalaryBatches.LimitedNotification' | translate"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField Limited' | translate"
          [width]="70"
          [type]="'checkbox'"
        ></gridColumn>
        <gridColumn
          [isFilterable]="true"
          *ngIf="!isReadOnly"
          [iconField]="'EditIcon'"
          [iconAction]="'Edit'"
          [title]="''"
          [tooltip]="'CompanySalaryBatches.SalaryBatchGridEditButtonTooltipText' | translate"
          [width]="40"
          [type]="'icon'"
        ></gridColumn>
      </grid>
    </div>
  </div>
</div>
