import { OnInit, Renderer2 } from '@angular/core';
import { Global } from '../../Common/Global';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
export var StartUpTaskSTatus;
(function (StartUpTaskSTatus) {
    StartUpTaskSTatus[StartUpTaskSTatus["Skipped"] = -1] = "Skipped";
    StartUpTaskSTatus[StartUpTaskSTatus["Todo"] = 0] = "Todo";
    StartUpTaskSTatus[StartUpTaskSTatus["Completed"] = 1] = "Completed";
})(StartUpTaskSTatus || (StartUpTaskSTatus = {}));
var GetStartedComponent = /** @class */ (function () {
    function GetStartedComponent(dataService, settingService, sessionService, renderer) {
        this.dataService = dataService;
        this.settingService = settingService;
        this.sessionService = sessionService;
        this.renderer = renderer;
        this.isTaskListShown = false;
        this.startupTasks = [];
        this.isShowTaskMess = false;
        this.isShowmoreHelp = true;
        this.isShowFianlDialog = false;
        this.isCompleteTask = false;
        this.isShowingHiddenStartUp = false;
        this.startUpProgress = 0;
        this.startUpFadeout = false;
        this.startUpIndicatorIsActive = true;
        this.isVisibleStartupTask = true;
    }
    Object.defineProperty(GetStartedComponent.prototype, "isAllTasksDone", {
        get: function () {
            return this.startUpProgress === 100;
        },
        enumerable: true,
        configurable: true
    });
    GetStartedComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadData(true);
        this.unsubscribeResize = this.renderer.listen(window, 'resize', function () { return _this.updateTaskListMaxHeight(); });
        this.isVisibleStartupTask = Global.STARTUP_TASKS_VISIBILITY;
    };
    // public ngOnDestroy(): void {
    //   this.unsubscribeResize();
    // }
    GetStartedComponent.prototype.openTaskList = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!this.isAllTasksDone && !this.hideTaskList()) {
            this.showTaskList(true);
        }
        else {
            this.hideTaskList();
        }
    };
    GetStartedComponent.prototype.navigateToTask = function (task) {
        if (task) {
            this.TaskClick = task;
            var stateName = this.sessionService.getStateByUri(task.AppUri);
            if (stateName && this.sessionService.currentState !== stateName) {
                this.sessionService.NavigateTo(stateName);
                if (this.TaskClick.GuideMessage) {
                    this.isShowTaskMess = true;
                }
            }
            else {
                if (task.ExternalLink) {
                    if (task.GuideMessage) {
                        this.isShowTaskMess = true;
                    }
                    else {
                        this.navigateToExternalLink(task.ExternalLink);
                    }
                }
                else {
                    if (this.TaskClick.GuideMessage) {
                        this.isShowTaskMess = true;
                    }
                }
            }
        }
    };
    Object.defineProperty(GetStartedComponent.prototype, "isExitLink", {
        get: function () {
            if (this.TaskClick && (this.TaskClick.ExternalLink || this.TaskClick.HelpLink)) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    GetStartedComponent.prototype.onTaskDialogAction = function (event) {
        if (event && this.TaskClick) {
            switch (event) {
                case 'MoreHelp': {
                    if (this.TaskClick.ExternalLink) {
                        this.navigateToExternalLink(this.TaskClick.ExternalLink);
                    }
                    else if (this.TaskClick.HelpLink) {
                        this.navigateToExternalLink(this.TaskClick.HelpLink);
                    }
                    this.TaskClick = undefined;
                    break;
                }
                case 'Ok': {
                    if (this.TaskClick.ExternalLink && this.TaskClick.GuideMessage) {
                        this.navigateToExternalLink(this.TaskClick.ExternalLink);
                        this.isShowTaskMess = false;
                    }
                    this.isShowTaskMess = false;
                    this.TaskClick = undefined;
                    break;
                }
                default: {
                    this.isShowTaskMess = false;
                    this.TaskClick = undefined;
                    break;
                }
            }
        }
    };
    GetStartedComponent.prototype.skipTask = function (task) {
        var _this = this;
        if (task) {
            switch (task.StatusId) {
                case StartUpTaskSTatus.Skipped:
                    this.resetTask(task);
                    break;
                default:
                    if (task.SkipWarning) {
                        this.isShowFianlDialog = true;
                        this.finalTask = task;
                    }
                    else {
                        this.dataService.Startuptasks_SkipTask(task.TaskId).subscribe(function (startUpTask) {
                            _this.onUpdateTaskCompleted(startUpTask);
                        });
                    }
                    break;
            }
        }
    };
    GetStartedComponent.prototype.finalDialogAction = function (action) {
        var _this = this;
        if (action === 'Yes') {
            this.dataService
                .Startuptasks_SkipTask(this.finalTask.TaskId)
                .subscribe(function (startUpTask) {
                _this.onUpdateTaskCompleted(startUpTask);
            });
        }
    };
    GetStartedComponent.prototype.completeTask = function (task) {
        var _this = this;
        if (task) {
            switch (task.StatusId) {
                case StartUpTaskSTatus.Completed:
                    this.resetTask(task);
                    break;
                default:
                    this.dataService.Startuptasks_CompleteTask(task.TaskId).subscribe(function (startUpTask) {
                        _this.onUpdateTaskCompleted(startUpTask);
                    });
                    break;
            }
        }
    };
    GetStartedComponent.prototype.hideStartupTasks = function (event) {
        this.isShowingHiddenStartUp = true;
    };
    GetStartedComponent.prototype.onCompleteTask = function (event) {
        this.hideProgress(true);
        this.hideTaskList();
    };
    GetStartedComponent.prototype.onShowingHiddenStartUp = function (action) {
        if (action === 'HideStartUp') {
            this.hideProgress(true);
            this.hideTaskList();
            Global.STARTUP_TASKS_VISIBILITY = false;
        }
    };
    GetStartedComponent.prototype.loadData = function (defaultOpen) {
        if (this.sessionService.role.IsFullAdmin && Global.STARTUP_TASKS_VISIBILITY) {
            // this.dataService.Startuptasks_GetStartupTasksWithStatus().subscribe((tasks: IStartupTaskCompanyView[]) => {
            //   Global.STARTUP_TASKS = this.startupTasks = tasks.sort(
            //     (first: IStartupTaskCompanyView, second: IStartupTaskCompanyView) => first.SortOrder - second.SortOrder
            //   );
            //   if (this.startupTasks.filter((data: IStartupTaskCompanyView) => data.StatusId === 0).length > 0) {
            //     this.calculateStartupProgress(defaultOpen);
            //   } else {
            //     this.hideProgress();
            //   }
            //   this.settingService.updateSettingsForEmployeeCompany(this.startupTasks);
            // });
            if (Global.STARTUP_TASKS) {
                this.startupTasks = Global.STARTUP_TASKS = Global.STARTUP_TASKS.sort(function (first, second) { return first.SortOrder - second.SortOrder; });
                if (this.startupTasks.filter(function (data) { return data.StatusId === 0; }).length > 0) {
                    this.calculateStartupProgress(defaultOpen);
                }
                else {
                    this.hideProgress();
                }
                this.settingService.updateSettingsForEmployeeCompany(this.startupTasks);
            }
        }
    };
    GetStartedComponent.prototype.onUpdateTaskCompleted = function (updatedTask) {
        var task = this.startupTasks
            ? this.startupTasks.find(function (ta) { return ta.Id === updatedTask.Id; })
            : undefined;
        if (task && updatedTask) {
            task.StatusId = updatedTask.StatusId;
        }
        this.calculateStartupProgress();
    };
    GetStartedComponent.prototype.navigateToExternalLink = function (link) {
        if (!this.sessionService.browser.iOSMobileDevice) {
            window.open(link);
        }
    };
    GetStartedComponent.prototype.resetTask = function (task) {
        var _this = this;
        this.dataService.Startuptasks_ResetTask(task.TaskId).subscribe(function (startUpTask) {
            _this.onUpdateTaskCompleted(startUpTask);
        });
    };
    GetStartedComponent.prototype.showProgressContainer = function () {
        if (this.sessionService.role.IsFullAdmin && Global.STARTUP_TASKS_VISIBILITY && Global.STARTUP_TASKS) {
            this.startUpIndicatorIsActive = true;
        }
    };
    GetStartedComponent.prototype.showProgress = function () {
        if (this.isAllTasksDone) {
            this.isCompleteTask = true;
        }
    };
    GetStartedComponent.prototype.hideProgress = function (fadeOut) {
        if (fadeOut && fadeOut === true) {
            this.startUpFadeout = true;
        }
        else {
            this.startUpIndicatorIsActive = false;
        }
    };
    GetStartedComponent.prototype.showTaskList = function (isLoadData) {
        if (isLoadData === void 0) { isLoadData = false; }
        if (!this.startUpStepsIsActive) {
            this.startUpStepsIsActive = true;
            this.startUpStepsMaxHeight = window.innerHeight - 250;
            this.updateTaskListMaxHeight();
            this.isTaskListShown = true;
            if (isLoadData) {
                this.loadData();
            }
        }
    };
    GetStartedComponent.prototype.hideTaskList = function () {
        if (this.startUpStepsIsActive) {
            this.startUpStepsIsActive = false;
            this.startUpStepsMaxHeight = 0;
            this.isTaskListShown = false;
            return true;
        }
        return false;
    };
    GetStartedComponent.prototype.updateTaskListMaxHeight = function () {
        this.startUpStepsMaxHeight = window.innerHeight - 250;
    };
    GetStartedComponent.prototype.calculateStartupProgress = function (defaultOpen) {
        var _this = this;
        if (this.startupTasks && this.startupTasks.length > 0) {
            var todoTasks = this.startupTasks.filter(function (data) { return data.StatusId === 0; });
            var completeTasks = this.startupTasks.filter(function (data) { return data.StatusId === 1; });
            this.startUpProgress = parseInt(((completeTasks.length * 100) / (todoTasks.length + completeTasks.length)).toString(), 10);
            this.showProgressContainer();
            if (window.innerWidth >= 500 && defaultOpen && defaultOpen === true) {
                setTimeout(function () {
                    _this.showTaskList();
                }, 500);
            }
            if (defaultOpen && defaultOpen === true) {
                setTimeout(function () {
                    _this.showProgress();
                }, 1000);
            }
            else {
                this.showProgress();
            }
        }
        else {
            this.hideProgress();
            this.hideTaskList();
        }
    };
    return GetStartedComponent;
}());
export { GetStartedComponent };
