<div class="FormElement has-fixedAddon">
  <span class="FormElement-label">{{ label }}:</span>
  <div class="FormElement-control">
    <div class="FormElement-control alignItems">
      <percentage-edit
        [(isDirty)]="childIsDirty[0]"
        (valueChange)="onChange()"
        [editMode]="editMode"
        [disable]="disable"
        [allowDecimal]="true"
        [(value)]="firstValue"
      ></percentage-edit>
      <span class="FormElement-addon">/</span>
      <percentage-edit
        [(isDirty)]="childIsDirty[1]"
        (valueChange)="onChange()"
        [editMode]="editMode"
        [disable]="disable"
        [allowDecimal]="true"
        [(value)]="secondValue"
      ></percentage-edit>
    </div>
  </div>
</div>
