<app-dialog
  [overlayClose]="false"
  (action)="onDialogAction($event)"
  [(visible)]="visible"
  width="800"
  [ngClass]="{ 'dialog-grid': IsPreviewExpanded }"
>
  <combobox-edit-control
    [label]="'UploadFileImportDialog.SelectExistingMapping' | translate"
    [editMode]="true"
    [textField]="'Name'"
    [idField]="'Id'"
    [comboboxDataSource]="existingMappingsForCurrentImportType"
    [(value)]="selectedMappingId"
    (valueChange)="onComboboxSelectMappingChanged()"
    [addonVisible]="addonReadMoreVisibility"
    [addonIcon]="'Question'"
    [addonLink]="addonLinks"
    [addonTooltip]="'CompanyDataImport.ReadMore' | translate"
  >
  </combobox-edit-control>

  <fieldset class="FormElement" *ngIf="importPayrollDataOptionsVisible">
    <div class="FormElement-legend FormElement-label"></div>
  </fieldset>

  <div class="u-leader2" *ngIf="importOptions && importOptions.length > 0">
    <div *ngFor="let option of importOptions">
      <div *ngIf="option.DataType === 'Radio'">
        <fieldset class="FormElement" *ngFor="let radioOption of option.Options">
          <div class="FormElement-control">
            <div class="FormElement-static">
              <label class="FormElement-radio">
                <input
                  type="radio"
                  name="{{ OptionsRadioButtonName }}_{{ radioOption.RadioValue }}"
                  [(ngModel)]="option.ControlValue"
                  value="{{ radioOption.RadioValue }}"
                  (change)="onImportOptionsChanged(option.ParameterValue, radioOption.RadioValue)"
                />
                <span class="FormElement-radioFaux"></span>
                <span class="FormElement-label">{{ radioOption.DisplayText }}</span>
              </label>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>

  <div class="CompanyDataImportViewButtonSection">
    <app-file-upload
      [accept]="'.xlsx'"
      (fileSelected)="onFileSelected($event)"
      [label]="'CreateDataImportTemplate.UploadFile' | translate"
      [(shortFileName)]="uploadFileName"
    ></app-file-upload>
    <div class="fileName">
      <span class="FormElement-label">{{ uploadFileName }}</span>
    </div>
  </div>

  <div class="FormElement">
    <div class="PreferencesView ImportMapping">
      <div>
        {{ 'CompanyDataImport.Example' | translate }}
      </div>
      <div id="previewImportMappingTable" class="ImportMappingTable FormElement-grid k-grid k-widget">
        <table *ngIf="!gratisalStandardTemplateColumnPreviewUnavailable" id="previewTable" class="Preferences-list">
          <thead *ngIf="!showEmptyMessage">
            <tr *ngIf="previewDataResponse && previewDataResponse.length > 0">
              <th *ngFor="let td of this.previewDataHeader">{{ td }}</th>
            </tr>
          </thead>

          <tbody *ngIf="showEmptyMessage">
            <tr>
              <td>
                <p class="text-center preview-empty-data">
                  {{ 'CompanyDataImport.EmptyImportPreview' | translate }}
                </p>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!showEmptyMessage">
            <tr *ngFor="let row of previewDataResponse">
              <td *ngFor="let td of row">
                {{ td }}
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="gratisalStandardTemplateColumnPreviewUnavailable" class="PreviewUnavailable">
          {{ 'CompanyDataImport.PreviewUnavailable' | translate }}
        </div>
      </div>
    </div>
  </div>

  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Edit'" [disabled]="disableEditDefinitionButton"></app-dialog-action>
  <app-dialog-action
    *ngIf="!gratisalStandardTemplateSelected"
    [type]="'Delete'"
    [disabled]="sessionService.isSubmitting"
  ></app-dialog-action>
  <app-dialog-action
    [type]="'Import'"
    [disabled]="!importButtonEnable || sessionService.isSubmitting"
  ></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onConfirmDeleteDialogAction($event)"
  [(visible)]="confirmDeleteDialogVisible"
  [leadingText]="'CompanyDataImport.DeleteTemplateConfirm' | translate"
>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Delete'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onConfirmReplaceDialogAction($event)"
  [(visible)]="confirmReplaceDialogVisible"
  [leadingText]="'CompanyDataImport.ReplaceExistingRecordsWarningText' | translate"
>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Understood'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="successDialogVisible" [leadingText]="'CompanyDataImport.ImportSuccessMessage' | translate">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="successDialogFotalVisible" [leadingText]="resultfotalMessage">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<import-errors-dialog
  [importType]="importType"
  [importResult]="importResults"
  (closedEvent)="closeDialogAndFireClosedEvent()"
  (completeImportEvent)="onCompleteImportEvent()"
  [testImport]="false"
>
</import-errors-dialog>

<import-mapping-dialog
  [(visible)]="showImportMappingDialog"
  [importType]="ImportTypeForMappingEditor"
  [(formData)]="formData"
  [isExistingMapping]="true"
  [mappingDetail]="mappingDetail"
  [mappingName]="mappingName"
  (saveMapingEvent)="onSaveMapingEvent()"
  [(startIndex)]="startIndex"
>
</import-mapping-dialog>
