import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { IPensionBaseCalculationMethod, IPensionProvider } from '../../Services/ApiModel';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { PensionProviderModel } from './PensionProviderModel';

@Component({
  selector: 'pension-provider',
  templateUrl: './PensionProvider.component.html'
})
export class PensionProviderComponent extends FormComponentBase implements OnInit {
  @Input() firstPension: boolean;
  @Input() disable = false;

  private modelValue: PensionProviderModel;
  @Input()
  public get model(): PensionProviderModel {
    if (!this.modelValue) {
      this.modelValue = {} as any;
    }

    return this.modelValue;
  }
  public set model(value: PensionProviderModel) {
    this.modelValue = value;
    this.updateDefaultComponentValue();
    this.modelChange.emit(value);
  }
  @Output() modelChange: EventEmitter<PensionProviderModel> = new EventEmitter<PensionProviderModel>();

  public pensionProviders: IPensionProvider[];
  public pensionBaseOnCalculationMethods: IPensionBaseCalculationMethod[];
  public showPensionProvider = false;
  public PensionProviderNetsNo: string;

  constructor(private sessionService: SessionService, private staticDataService: StaticDataService) {
    super();
    this.childIsDirty = Array(7).fill(false);
    this.childIsValid = Array(2).fill(true);
    this.editModeChange.subscribe((value: boolean) => {
      if (this.model) {
        this.updateDefaultComponentValue();
      }
    });
  }

  public ngOnInit(): void {
    this.staticDataService.PensionBaseCalculationMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IPensionBaseCalculationMethod[]) => (this.pensionBaseOnCalculationMethods = data)
    );
    this.staticDataService.PensionProvider.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IPensionProvider[]) => {
      this.pensionProviders = data;
      this.updateDefaultComponentValue();
    });
  }

  private get isEmployeePension(): boolean {
    return this.model && (this.model as any).HireDate;
  }

  public get isNoPensionTextHidden(): boolean {
    return this.editMode || this.showPensionProvider;
  }

  public get isShowBankInfo(): boolean {
    return this.SelectedPensionProvider && this.SelectedPensionProvider.AccountNumberRequired;
  }

  public get isOverridePolicyNo(): boolean {
    return this.SelectedPensionProvider && this.SelectedPensionProvider.PolicyNumberLabelOverride;
  }

  public get isAddButtonHidden(): boolean {
    return (
      !this.editMode ||
      this.showPensionProvider ||
      (this.isEmployeePension && !this.sessionService.feature.AllowEditUserEmployment(this.sessionService.currentState))
    );
  }

  public get SelectedPensionProvider(): any {
    const pensionProvider: any = this.pensionProviders
      ? this.pensionProviders.find((ppd: any) => {
          return ppd.Id === this.pensionProviderId;
        })
      : undefined;

    return pensionProvider;
  }

  public get pensionProviderId(): number {
    return this.firstPension ? this.model.PensionProviderId : this.model.Pension2ProviderId;
  }
  public set pensionProviderId(value: number) {
    value = value ? this.sessionService.parseInt(value.toString()) : undefined;
    if (this.firstPension) {
      this.model.PensionProviderId = value;
    } else {
      this.model.Pension2ProviderId = value;
    }
  }

  public get PensionProviderBankRegNo(): string {
    return this.firstPension ? this.model.PensionProviderBankRegNo : this.model.Pension2ProviderBankRegNo;
  }
  public set PensionProviderBankRegNo(value: string) {
    if (this.firstPension) {
      this.model.PensionProviderBankRegNo = value;
    } else {
      this.model.Pension2ProviderBankRegNo = value;
    }
  }

  public get PensionProviderBankAccountNo(): string {
    return this.firstPension ? this.model.PensionProviderBankAccountNo : this.model.Pension2ProviderBankAccountNo;
  }
  public set PensionProviderBankAccountNo(value: string) {
    if (this.firstPension) {
      this.model.PensionProviderBankAccountNo = value;
    } else {
      this.model.Pension2ProviderBankAccountNo = value;
    }
  }

  public get PensionBaseCalculationMethodId(): number {
    return this.firstPension ? this.model.PensionBaseCalculationMethodId : this.model.Pension2BaseCalculationMethodId;
  }
  public set PensionBaseCalculationMethodId(value: number) {
    value = value ? this.sessionService.parseInt(value.toString()) : undefined;
    if (this.firstPension) {
      this.model.PensionBaseCalculationMethodId = value;
    } else {
      this.model.Pension2BaseCalculationMethodId = value;
    }
  }

  public get PensionEmployeeShare(): number {
    return this.firstPension ? this.model.PensionEmployeeShare : this.model.Pension2EmployeeShare;
  }
  public set PensionEmployeeShare(value: number) {
    if (this.firstPension) {
      this.model.PensionEmployeeShare = value;
    } else {
      this.model.Pension2EmployeeShare = value;
    }
  }

  public get PensionCompanyShare(): number {
    return this.firstPension ? this.model.PensionCompanyShare : this.model.Pension2CompanyShare;
  }
  public set PensionCompanyShare(value: number) {
    if (this.firstPension) {
      this.model.PensionCompanyShare = value;
    } else {
      this.model.Pension2CompanyShare = value;
    }
  }

  public get PensionEmployeeAmount(): number {
    return this.firstPension ? this.model.PensionEmployeeAmount : this.model.Pension2EmployeeAmount;
  }
  public set PensionEmployeeAmount(value: number) {
    if (this.firstPension) {
      this.model.PensionEmployeeAmount = value;
    } else {
      this.model.Pension2EmployeeAmount = value;
    }
  }

  public get PensionCompanyAmount(): number {
    return this.firstPension ? this.model.PensionCompanyAmount : this.model.Pension2CompanyAmount;
  }
  public set PensionCompanyAmount(value: number) {
    if (this.firstPension) {
      this.model.PensionCompanyAmount = value;
    } else {
      this.model.Pension2CompanyAmount = value;
    }
  }

  public get PensionPolicyNumber(): string {
    return this.firstPension ? this.model.PensionPolicyNumber : this.model.Pension2PolicyNumber;
  }
  public set PensionPolicyNumber(value: string) {
    if (this.firstPension) {
      this.model.PensionPolicyNumber = value;
    } else {
      this.model.Pension2PolicyNumber = value;
    }
  }

  private updateDefaultComponentValue(): void {
    this.showPensionProvider = this.model && !!this.pensionProviderId;
    const pensionProvider: any = this.pensionProviders
      ? this.pensionProviders.find((pension: any) => pension.Id === this.pensionProviderId)
      : undefined;
    this.PensionProviderNetsNo = pensionProvider ? pensionProvider.NetsNo : undefined;
  }

  public onPensionProviderChanged(): void {
    this.enablePensionProviderValues();
    this.onChange();
  }

  private enablePensionProviderValues(): void {
    setTimeout(() => {
      const pensionProvider: any = this.pensionProviders
        ? this.pensionProviders.find((pension: any) => pension.Id === this.pensionProviderId)
        : undefined;
      this.PensionProviderNetsNo = pensionProvider ? pensionProvider.NetsNo : undefined;
      if (pensionProvider) {
        if (this.firstPension) {
          this.model.PensionBaseCalculationMethodId = pensionProvider.DefaultBaseCalculationMethodId;
        } else {
          this.model.Pension2BaseCalculationMethodId = pensionProvider.DefaultBaseCalculationMethodId;
        }
      }
    });
  }

  public onAddFirstPensionProvider(): void {
    if (!this.editMode) {
      return;
    }

    this.showPensionProvider = true;
  }
}
