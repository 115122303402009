import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DxDropDownBoxComponent } from 'devextreme-angular';
import { ControlComponentBase } from '../Common/ControlComponentBase';

@Component({
  selector: 'multi-select',
  templateUrl: './MultiSelect.component.html'
})
export class MultiSelectComponent extends ControlComponentBase<any> implements OnInit {
  @Input() public gridDataSource: any[];
  @Input() public displayExp: string;
  @Input() public valueExp: string;
  @Input() public placeHolder: string;
  @Input() public label: string;
  @Input() public required: boolean;
  private gridBoxValue: any[] = [];
  @Input() public nullValueLabel: any;
  @Input() public tooltipContent: string;
  @Input() public columns: string[] = [];

  @Input() public get GridBoxValue(): any[] {
    return this.gridBoxValue;
  }

  public set GridBoxValue(value: any[]) {
    this.gridBoxValue = value || [];
    this.OnSelectedChangeEvent.emit(this.gridBoxValue);
  }

  public ngOnInit(): void {
    this.onChange();
  }

  @Output() public OnSelectedChangeEvent: EventEmitter<any[]> = new EventEmitter<any[]>();

  private selectValueIds: number[] = [];
  public get SelectValueIds(): number[] {
    if (this.gridBoxValue && this.gridBoxValue.length > 0) {
      this.selectValueIds = this.gridBoxValue.map((item: any) => {
        let id: number;
        id = item[this.valueExp];
        return id;
      });
    } else {
      this.selectValueIds = [];
    }
    return this.selectValueIds;
  }
  public set SelectValueIds(values: number[]) {
    this.selectValueIds = values;
  }

  @ViewChild('dxDropDownBoxControl', { static: true }) public dxDropDownBoxControl: DxDropDownBoxComponent;
  constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
    this.setTimeout = false;
  }
}
