<div *ngIf="company" class="Card-inner">
  <form>
    <fieldset class="Form--horizontal">
      <legend class="Card-title">{{ 'CompanyGeneral.Company Data' | translate }}</legend>

      <app-text-edit
        [label]="'CompanyGeneral.Bank reg no' | translate"
        [required]="true"
        [editMode]="editMode"
        [type]="'number'"
        [(isDirty)]="childIsDirty[0]"
        [(value)]="company.BankRegNo"
        [(isValid)]="childIsValid[0]"
        (valueChange)="onChange()"
      ></app-text-edit>

      <app-text-edit
        [label]="'CompanyGeneral.Bank account no' | translate"
        [editMode]="editMode"
        [type]="'number'"
        [required]="true"
        [(value)]="company.BankAccountNo"
        [(isDirty)]="childIsDirty[1]"
        [(isValid)]="childIsValid[1]"
        (valueChange)="onBankAccountNoChanged()"
      ></app-text-edit>

      <app-text-edit
        [(isDirty)]="childIsDirty[2]"
        [label]="'CompanyGeneral.IBAN' | translate"
        [editMode]="editMode"
        [(value)]="company.IbanNo"
        (valueChange)="onChange()"
      ></app-text-edit>

      <app-text-edit
        [(isDirty)]="childIsDirty[3]"
        [label]="'CompanyGeneral.BIC SWIFT' | translate"
        [editMode]="editMode"
        [(value)]="company.BicSwiftNo"
        (valueChange)="onChange()"
      ></app-text-edit>

      <app-text-edit
        [(isDirty)]="childIsDirty[4]"
        [label]="'CompanyGeneral.CVR no' | translate"
        [required]="true"
        [editMode]="editMode"
        [type]="'number'"
        [(isValid)]="childIsValid[2]"
        [(value)]="company.VATRegistrationNumber"
        (valueChange)="onChange()"
      ></app-text-edit>

      <app-text-edit
        [(isDirty)]="childIsDirty[5]"
        [label]="'CompanyGeneral.SENo' | translate"
        [editMode]="editMode"
        [type]="'number'"
        [(value)]="company.SecondaryVATRegistrationNumber"
        [addonIcon]="'Info'"
        [addonTooltip]="'CompanyGeneral.SENoTooltip' | translate"
        (valueChange)="onChange()"
      ></app-text-edit>

      <app-text-edit
        [(isDirty)]="childIsDirty[6]"
        [label]="'CompanyGeneral.EAN' | translate"
        [editMode]="editMode"
        [type]="'number'"
        [(value)]="company.InvoiceIdentifier"
        (valueChange)="onChange()"
      ></app-text-edit>

      <combobox-edit-control
        [(isDirty)]="childIsDirty[7]"
        [label]="'CompanyGeneral.Tax category' | translate"
        *ngIf="!isGreenland"
        [editMode]="editMode"
        [comboboxDataSource]="taxCategories"
        [textField]="'Name'"
        [idField]="'Id'"
        [(value)]="company.CompanyTypeId"
        (valueChange)="onChange()"
      ></combobox-edit-control>
    </fieldset>
  </form>

  <app-dialog
    [(visible)]="isWarningVivible"
    (action)="onWarningAction($event)"
    [leadingText]="'CompanyGeneral.EditBankAccountNoWarning' | translate"
  >
    <app-dialog-action [type]="'Cancel'"></app-dialog-action>
    <app-dialog-action [type]="'Understood'"></app-dialog-action>
  </app-dialog>
</div>
