import { EventEmitter, OnInit } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { version } from '../../../version';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { KeyDownHelper } from '../../Common/KeyDownHelper';
import { ImportTypesEnum } from '../../Model/Enum';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { ReportParametersDefaultValue } from '../../SharedComponents/ReportsDialog/ReportParametersDefaultValue';
var EmployeeTabsComponent = /** @class */ (function () {
    function EmployeeTabsComponent(dataService, settingService, sessionService, transitionService, staticDataService) {
        this.dataService = dataService;
        this.settingService = settingService;
        this.sessionService = sessionService;
        this.transitionService = transitionService;
        this.staticDataService = staticDataService;
        this.isBlankEmail = false;
        this.avatarChange = false;
        this.employeeChange = new EventEmitter();
        this.isAvatarChanged = new EventEmitter();
        this.editModeChange = new EventEmitter();
        this.isDirtyValue = false;
        this.isDirtyChange = new EventEmitter();
        this.addNewEmployeeFiredChange = new EventEmitter();
        // Output bindings
        this.newEmployeeCreated = new EventEmitter();
        this.employeeDeleted = new EventEmitter();
        this.employeeUpdated = new EventEmitter();
        // Template properties
        this.createNewtimeEntryDialogVisable = false;
        this.createNewPayrolldataDialogVisable = false;
        this.isTabsValid = true;
        this.createNewEmploymentDialogVisible = false;
        this.deleteEmployeeDialogVisible = false;
        this.reportDialogVisible = false;
        this.resetPasswordFired = false;
        this.resendWelcomeEmailFired = false;
        this.discardDialogVisible = false;
        this.PaddingBottom = '';
        this.touchTime = 0;
        this.resendWelcomeEmail = false;
        this.isEmployeeInfoValid = true;
        this.isApiValidationValid = true;
        this.createNewEmployeeDialogVisibleValue = false;
        this.isOnDialogSynchronizeEmploymentWithTemplateVisble = false;
        this.ngUnsubscribe = new Subject();
        this.noReportVisible = false;
        this.isNoTemplate = false;
        this.isShowAction = true;
    }
    Object.defineProperty(EmployeeTabsComponent.prototype, "globalKeyDown", {
        // Input bindings
        set: function (value) {
            if (!value) {
                return;
            }
            this.onGlobalKeyDown(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employee", {
        get: function () {
            return this.currentEmployee;
        },
        set: function (value) {
            if (this.currentEmployee !== value) {
                var oldEmployeeData = this.currentEmployee;
                var oldEmployeeEmployment = this.currentUserEmployment;
                this.currentEmployee = value;
                // If a new employee is selected while in edit mode, we update the old data if there are changes
                if (this.editMode && this.isDirty && this.validate()) {
                    this.updateUserEmployment(oldEmployeeData, oldEmployeeEmployment);
                }
                this.resetValidation();
                this.isDirty = false;
                // Reset valid because a new employee is selected
                if (this.editMode && this.currentEmployee && oldEmployeeData && oldEmployeeData.Id !== this.currentEmployee.Id) {
                    this.editMode = false;
                }
                this.employeeChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "editMode", {
        get: function () {
            return this.editModeValue;
        },
        set: function (value) {
            if (this.editModeValue !== value) {
                this.editModeValue = value;
                this.editModeChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "isDirty", {
        get: function () {
            return this.isDirtyValue;
        },
        set: function (value) {
            if (this.isDirtyValue !== value) {
                this.isDirtyValue = value;
                this.isDirtyChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "addNewEmployeeFired", {
        get: function () {
            return this.addNewEmployeeFiredValue;
        },
        set: function (value) {
            var _this = this;
            if (value) {
                this.createNewEmployeeDialogVisible = true;
                setTimeout(function () {
                    _this.addNewEmployeeFiredValue = false;
                    _this.addNewEmployeeFiredChange.emit(false);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "isMobileInEmployeepayrollData", {
        get: function () {
            return !this.isMobile || !this.sessionService.currentState.includes('tabs.employee.payrolldata');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "isMobileInEmployeetime", {
        get: function () {
            return !this.isMobile || !this.sessionService.currentState.includes('tabs.employee.time');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "isMobileInSelfservicetime", {
        get: function () {
            return !this.isMobile || !this.sessionService.currentState.includes('tabs.selfservice.time');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "allowEditUserInfo", {
        get: function () {
            return (this.sessionService.role.IsSalaryAdminAndHigher && this.sessionService.currentState !== 'tabs.selfservice.general');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "allowEditRole", {
        get: function () {
            return this.sessionService.role.IsFullAdmin && this.sessionService.currentState !== 'tabs.selfservice.general';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "showEmployeeList", {
        get: function () {
            return this.sessionService.feature.ShowEmployeeList;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employeeGeneralTabVisible", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.employee.general') && !this.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employeeHiringStatusTabVisible", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.employee.hiringstatus') && !this.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employeeTimeTabVisible", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.employee.time');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employeePayrollDataTabVisible", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.employee.payrolldata') && !this.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employeePayslipTabVisible", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.employee.payslip') && !this.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "allowEditUserEmployment", {
        get: function () {
            return (this.sessionService.feature.AllowEditUserEmployment(this.sessionService.currentState) &&
                !this.sessionService.role.IsReadOnly);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "reportViewEnabled", {
        get: function () {
            var _this = this;
            var area = this.reportAreas.find(function (a) { return a.Key === _this.reportKey; });
            if (area) {
                return true;
            }
            return false;
            //return this.sessionService.feature.ReportViewEnabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "activeTab", {
        get: function () {
            return this.sessionService.currentState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "hasEmployment", {
        get: function () {
            return this.currentUserEmployment && this.currentUserEmployment.Id ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "currentPosition", {
        get: function () {
            return this.currentUserEmployment ? this.currentUserEmployment.Id : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employmentNotBaseOnTemplate", {
        get: function () {
            return this.currentUserEmployment && this.currentUserEmployment.BasedOnTemplateId > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "isActive", {
        get: function () {
            return this.sessionService.currentState.indexOf('tabs.employee') >= 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "EmployeeWithRoleAsManager", {
        get: function () {
            return this.sessionService.role.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "allowAddNewEmployee", {
        get: function () {
            return this.sessionService.role.IsSalaryAdminAndHigher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "isPayrolldataMobile", {
        get: function () {
            return this.sessionService.browser.isMobile && this.sessionService.currentState === 'tabs.employee.payrolldata';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employeeTimeTabEnabled", {
        get: function () {
            return this.sessionService.feature.EmployeeTimeTabEnabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employeePayrollDataTabEnabled", {
        get: function () {
            return this.sessionService.feature.EmployeePayrollDataTabEnabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "disabledActionButtonClass", {
        get: function () {
            if (this.sessionService.isGetting) {
                return 'disabledActionButtonClass';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTabsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.ReportArea.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.reportAreas = data); });
        if (this.sessionService.employeeTabState !== '') {
            this.sessionService.currentState = this.sessionService.employeeTabState;
            this.checkSwitchingEmployeeTab();
        }
        else {
            console.log(Global.SESSION);
            var currentRoleKey = Global.SESSION.CurrentRole.Key;
            if (currentRoleKey) {
                switch (currentRoleKey) {
                    case 'FullAdmin':
                        if (Global.IsEmployeeTaskCompleted) {
                            this.sessionService.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
                            this.sessionService.NavigateTo('tabs.employee.payrolldata');
                        }
                        else {
                            this.sessionService.currentStyleMenuTabpanelReponsive = '';
                            this.sessionService.NavigateTo('tabs.employee.general');
                        }
                        break;
                    case 'ReadOnly':
                        this.sessionService.NavigateTo('tabs.employee.payrolldata');
                        break;
                    case 'Employee':
                        var hasManager = this.sessionService.role.EmployeeWithRoleAsManager;
                        if (hasManager) {
                            this.sessionService.NavigateTo('tabs.employee.time');
                        }
                        this.sessionService.NavigateTo('tabs.selfservice.payslip');
                        break;
                    case 'SalaryAdmin':
                        this.sessionService.NavigateTo('tabs.employee.payrolldata');
                        break;
                    default:
                        break;
                }
            }
        }
        this.transitionService.onStart({}, function (transition) {
            var fromState = transition.from().name;
            var toState = (_this.navigationToStateName = transition.to().name);
            var generalState = 'tabs.employee.general';
            var hiringstatusState = 'tabs.employee.hiringstatus';
            if (_this.editMode && fromState && (fromState === generalState || fromState === hiringstatusState)) {
                if (_this.isDirty) {
                    // If validation fails, alert user and do nothing.
                    // Else show action confirmation.
                    if (!_this.validate()) {
                        _this.showInvalidFormWarning = true;
                    }
                    else {
                        _this.showSaveDataConfirmationChangeState = true;
                    }
                    return false;
                }
                else {
                    // Editmode but no changes, we close editmode without doing anything
                    _this.editMode = false;
                    return true;
                }
            }
            else {
                _this.editMode = false;
                _this.editModeChange.emit(false);
                return true;
            }
        });
    };
    EmployeeTabsComponent.prototype.checkSwitchingEmployeeTab = function () {
        if (this.sessionService.currentState) {
            switch (this.sessionService.currentState) {
                case 'tabs.employee.general':
                    if (this.EmployeeWithRoleAsManager) {
                        this.sessionService.checkNavigateDefaultRole(true, false);
                    }
                    else {
                        this.sessionService.NavigateTo(this.sessionService.employeeTabState);
                    }
                    break;
                case 'tabs.employee.hiringstatus':
                    if (this.EmployeeWithRoleAsManager) {
                        this.sessionService.checkNavigateDefaultRole(true, false);
                    }
                    else {
                        this.sessionService.NavigateTo(this.sessionService.employeeTabState);
                    }
                    break;
                case 'tabs.employee.time':
                    if (!this.employeeTimeTabEnabled) {
                        this.sessionService.checkNavigateDefaultRole(true, false);
                    }
                    else {
                        this.sessionService.NavigateTo(this.sessionService.employeeTabState);
                    }
                    break;
                case 'tabs.employee.payrolldata':
                    if (!this.employeePayrollDataTabEnabled || this.EmployeeWithRoleAsManager) {
                        this.sessionService.checkNavigateDefaultRole(true, false);
                    }
                    else {
                        this.sessionService.NavigateTo(this.sessionService.employeeTabState);
                    }
                    break;
                case 'tabs.employee.payslip':
                    if (this.EmployeeWithRoleAsManager) {
                        this.sessionService.checkNavigateDefaultRole(true, false);
                    }
                    else {
                        this.sessionService.NavigateTo(this.sessionService.employeeTabState);
                    }
                    break;
                default:
                    this.sessionService.NavigateTo(this.sessionService.employeeTabState);
                    break;
            }
        }
    };
    Object.defineProperty(EmployeeTabsComponent.prototype, "moreButtonVisisble", {
        get: function () {
            return ((this.sessionService.currentState === 'tabs.company.configuration.hiringstatus' ||
                this.sessionService.currentState === 'tabs.company.salarybatches' ||
                this.sessionService.currentState === 'tabs.company.general' ||
                this.sessionService.currentState === 'tabs.employee.hiringstatus' ||
                this.sessionService.currentState === 'tabs.employee.general' ||
                this.sessionService.currentState === 'tabs.employee.payrolldata') &&
                !this.sessionService.role.IsReadOnly);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "currentUserEmployment", {
        get: function () {
            return this.currentUserEmploymentValue;
        },
        set: function (value) {
            this.currentUserEmploymentValue = value;
            this.resetValidation();
            this.isDirty = false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "createNewEmployeeDialogVisible", {
        get: function () {
            if (!this.allowAddNewEmployee) {
                return false;
            }
            return this.createNewEmployeeDialogVisibleValue;
        },
        set: function (value) {
            this.createNewEmployeeDialogVisibleValue = value;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTabsComponent.prototype.onConfirmSaveDataOnChangeState = function (action) {
        if (action === 'ContinueAndSave') {
            this.editMode = false;
            // setTimeout(() => {
            // });
            this.prepareUpdateUserEmployment(undefined, undefined, this.navigationToStateName);
        }
        else if (action === 'DiscardAndLeave') {
            this.discardChanges();
            this.isDirty = false;
            if (this.navigationToStateName) {
                this.sessionService.NavigateTo(this.navigationToStateName);
            }
        }
        this.navigationToStateName = undefined;
    };
    Object.defineProperty(EmployeeTabsComponent.prototype, "reportKey", {
        get: function () {
            if (this.employeeGeneralTabVisible) {
                return 'EmployeeGeneral';
            }
            if (this.employeeHiringStatusTabVisible) {
                return 'HiringStatus';
            }
            if (this.employeeTimeTabVisible) {
                return 'EmployeeTime';
            }
            if (this.employeePayrollDataTabVisible) {
                return 'PayrollData';
            }
            if (this.employeePayslipTabVisible) {
                return 'EmployeePayslips';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTabsComponent.prototype.clickAreamenuToggledChange = function () {
        this.sessionService.menuToggled = true;
        this.sessionService.showUserMenuToggled = false;
        this.sessionService.showMoreMenuToggled = false;
        this.sessionService.showHelpMenuToggled = false;
        this.checkMobileDoubleClick();
    };
    EmployeeTabsComponent.prototype.checkMobileDoubleClick = function () {
        if (this.touchTime === 0) {
            // set first click
            this.touchTime = new Date().getTime();
        }
        else {
            // compare first click to this click and see if they occurred within double click threshold
            if (new Date().getTime() - this.touchTime < 800) {
                // double click occurred
                this.onDoubleClick();
                this.touchTime = 0;
            }
            else {
                // not a double click so set as a new first click
                this.touchTime = new Date().getTime();
            }
        }
    };
    EmployeeTabsComponent.prototype.PreonDoubleClick = function () {
        if (this.editMode === true) {
            return;
        }
        this.onDoubleClick();
    };
    EmployeeTabsComponent.prototype.onDoubleClick = function () {
        if (!this.allowEditUserEmployment) {
            return;
        }
        if (!this.editMode &&
            (this.sessionService.currentState === 'tabs.employee.general' ||
                this.sessionService.currentState === 'tabs.employee.hiringstatus')) {
            ////this.editMode = true;
            if (this.editMode === true) {
                return;
            }
            this.onEditModeChange(true);
        }
    };
    EmployeeTabsComponent.prototype.onUserEmploymentChange = function (newUserEmployment) {
        this.currentUserEmployment = newUserEmployment;
    };
    EmployeeTabsComponent.prototype.onPositionChanged = function (position) {
        this.currentUserEmployment.Title = position;
    };
    EmployeeTabsComponent.prototype.onAddNewEmployee = function () {
        this.createNewEmployeeDialogVisible = true;
    };
    EmployeeTabsComponent.prototype.onAddNewTimeEntry = function () {
        this.createNewtimeEntryDialogVisable = true;
    };
    EmployeeTabsComponent.prototype.onAddNewPayrollData = function () {
        this.createNewPayrolldataDialogVisable = true;
    };
    EmployeeTabsComponent.prototype.onNewEmployeeCreatedEvent = function (newCompanyUser) {
        this.newEmployeeCreated.emit(newCompanyUser);
    };
    EmployeeTabsComponent.prototype.onNewEmploymentCreatedEvent = function (newUserEmployment) {
        this.newEmployment = newUserEmployment;
        this.onUserEmploymentChange(newUserEmployment);
    };
    EmployeeTabsComponent.prototype.onShowReportsEventClick = function () {
        if (!this.employee) {
            return;
        }
        var parameters = new ReportParametersDefaultValue();
        parameters.employeeId = this.employee ? this.employee.Id : undefined;
        parameters.employmentId = this.currentUserEmployment ? this.currentUserEmployment.Id : undefined;
        this.reportParams = parameters;
        this.reportDialogVisible = true;
    };
    EmployeeTabsComponent.prototype.deletedEmployee = function (employee) {
        // Broadcast to Employee View
        this.employeeDeleted.emit(employee);
    };
    EmployeeTabsComponent.prototype.deletedUserEmployment = function (userEmployment) {
        this.deletedEmployment = userEmployment;
        // When delete user employment happens the this.employee.UserEmployments should not be null, so no null checking here is needed
        var deletedIndex = this.employee.UserEmployments.findIndex(function (value) { return value.Id === userEmployment.Id; });
        this.employee.UserEmployments.splice(deletedIndex, 1);
        this.onUserEmploymentChange(this.employee.UserEmployments.length > 0 ? this.employee.UserEmployments[0] : undefined);
    };
    EmployeeTabsComponent.prototype.onMoreMenuOptionClick = function (menu) {
        switch (menu) {
            case 'employeeHiringStatus_createTemplate':
                this.createTemplateFromCurrentEmployment();
                break;
            case 'employeeHiringStatus_addEmployment':
                this.getTemplates();
                break;
            case 'employeeGeneral_deleteEmployee':
                this.deleteEmployeeDialogVisible = true;
                break;
            case 'employeeHiringStatus_synchronizeWithTemplate':
                // this.onSynchronizeEmploymentWithTemplate();
                this.isOnDialogSynchronizeEmploymentWithTemplateVisble = true;
                break;
            case 'employeeGeneral_importInitialBalances':
                this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Initial_Balances;
                this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
                break;
            case 'employeeGeneral_importEmployees':
                this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Employees;
                this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
                break;
            case 'companyGeneral_importData':
                this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Payroll;
                this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
                break;
            case 'employeeGeneral_ResendWelcomeEmail':
                this.resendWelcomeEmailFired = true;
                break;
            case 'employeeGeneral_ResetPassword':
                this.resetPasswordFired = true;
                break;
            default:
                break;
        }
    };
    EmployeeTabsComponent.prototype.onDialogSynchronizeEmploymentWithTemplate = function (event) {
        if (event && event === 'Yes') {
            this.onSynchronizeEmploymentWithTemplate();
        }
    };
    EmployeeTabsComponent.prototype.createNewUserEmployment = function () {
        this.getTemplates();
    };
    EmployeeTabsComponent.prototype.onEditModeChange = function (editMode) {
        var _this = this;
        // Assume that the user has fixed the api validation and try again
        this.isApiValidationValid = true;
        if (!this.validate() && editMode === false) {
            if (version === 'Local-build') {
                console.log('Keep editmode open');
            }
            return;
        }
        if (editMode === false && this.isDirty) {
            this.updateUserEmployment();
        }
        if (editMode && this.employee && !this.isDirty) {
            // Save original by creating a deep copy of the object
            this.originalCompanyUserData = JSON.parse(JSON.stringify(this.employee));
            this.originalUserEmployment = this.currentUserEmployment
                ? JSON.parse(JSON.stringify(this.currentUserEmployment))
                : undefined;
            // By the way, this is how you create a shallow copy in Typescript 2.1:
            //// this.originalCompanyUserData = {... this.employee};
        }
        this.isShowAction = false;
        setTimeout(function () {
            _this.editMode = editMode;
            _this.isShowAction = true;
            _this.editModeChange.emit(_this.editMode);
        }, 25);
        // let breower: Browser = new Browser();
        // if (breower.isChrome && !breower.isMobile && !breower.isHybridApp) {
        //     this.isShowAction = false;
        //     setTimeout(() => { this.editMode = editMode; this.isShowAction = true; this.editModeChange.emit(this.editMode); }, 25);
        // } else {
        //     this.isShowAction = false;
        //     this.editMode = editMode;
        //     this.editModeChange.emit(this.editMode);
        //     this.isShowAction = true;
        // }
    };
    EmployeeTabsComponent.prototype.onReloadUserEmploymentEvent = function () {
        if (this.currentUserEmployment) {
            this.reloadUserEmployment();
        }
    };
    EmployeeTabsComponent.prototype.onEmployeeInfoValidated = function (isPassed) {
        this.isEmployeeInfoValid = isPassed;
        this.isDirty = true;
    };
    EmployeeTabsComponent.prototype.confirmAndDiscardChanges = function () {
        if (!this.isDirty) {
            this.editMode = false;
            return;
        }
        this.discardDialogVisible = true;
    };
    EmployeeTabsComponent.prototype.discardDialogAction = function (action) {
        if (action === 'ConfirmDiscard') {
            this.discardChanges();
        }
    };
    EmployeeTabsComponent.prototype.onUnsavedDataDialogAction = function (action) {
        if (action === 'DiscardAndLeave') {
            this.editMode = false;
        }
        else {
            this.editMode = false;
            this.prepareUpdateUserEmployment(this.oldEmployeeData, this.oldEmployeeEmployment);
        }
    };
    EmployeeTabsComponent.prototype.onSynchTemplateDialogAction = function (action) {
        if (action !== 'No') {
            this.performUpdateUserEmployment(this.updateEmployee, this.oldEmployeeData, this.navigateToAfterUpdate, this.originalUserEmployment.Id);
        }
        else {
            this.performUpdateUserEmployment(this.updateEmployee, this.oldEmployeeData, this.navigateToAfterUpdate);
        }
    };
    EmployeeTabsComponent.prototype.onTemplateNameDialogAction = function (action) {
        var _this = this;
        if (action === 'Create' && this.newTemplateName) {
            var request = {
                BasedOnEmploymentId: this.currentUserEmployment ? this.currentUserEmployment.Id : undefined,
                Name: this.newTemplateName
            };
            this.dataService
                .EmploymentTemplates_CreateTemplateFromEmployment(request)
                .subscribe(function (template) {
                _this.reloadUserEmployment();
                _this.createTemplateTemplateCompletedDialogVisible = true;
            });
        }
    };
    EmployeeTabsComponent.prototype.onCreateTemplateConfirmationDialogAction = function (action) {
        if (action === 'yes') {
            this.newTemplateName = '';
            this.templateNameDialogVisible = true;
        }
    };
    EmployeeTabsComponent.prototype.onGlobalKeyDown = function ($event) {
        if (!this.isActive) {
            return;
        }
        if (KeyDownHelper.isReportShortcutKey($event)) {
            $event.preventDefault();
            this.onShowReportsEventClick();
            return;
        }
        if (KeyDownHelper.isNewShortcutKey($event)) {
            $event.preventDefault();
            this.onAddNewEmployee();
            return;
        }
        if (KeyDownHelper.isEditShortcutKey($event)) {
            $event.preventDefault();
            if (this.allowEditUserEmployment) {
                this.editMode = true;
            }
            return;
        }
        if (!this.editMode) {
            return;
        }
        if (KeyDownHelper.isDiscardingChangesShortcutKey($event)) {
            $event.preventDefault();
            this.confirmAndDiscardChanges();
        }
        if (KeyDownHelper.isSavingChangesShortcutKey($event)) {
            $event.preventDefault();
            if (version === 'Local-build') {
                console.log('Save');
            }
            this.editMode = false;
        }
    };
    EmployeeTabsComponent.prototype.discardChanges = function () {
        this.isDirty = false;
        if (this.employee && this.originalCompanyUserData && this.employee.Id === this.originalCompanyUserData.Id) {
            this.employee = JSON.parse(JSON.stringify(this.originalCompanyUserData));
            this.currentUserEmployment = this.originalUserEmployment;
        }
        this.editMode = false;
    };
    EmployeeTabsComponent.prototype.updateUserEmployment = function (oldEmployeeData, oldEmployeeEmployment) {
        this.oldEmployeeData = oldEmployeeData;
        this.oldEmployeeEmployment = oldEmployeeEmployment;
        // When oldEmployeeData has value, it is because the user switch to a new employee without closing editmode
        // Therefore we try to make sure the oldEmployeeData is updated in the API call, but the current employee is the newly selected employee
        if (oldEmployeeData) {
            // Ask user if they want save or not
            this.unsavedDataDialogVisible = true;
        }
        else {
            this.prepareUpdateUserEmployment();
        }
    };
    EmployeeTabsComponent.prototype.prepareUpdateUserEmployment = function (oldEmployeeData, oldEmployeeEmployment, navigateTo) {
        var _this = this;
        this.oldEmployeeData = oldEmployeeData;
        this.oldEmployeeEmployment = oldEmployeeEmployment;
        this.navigateToAfterUpdate = navigateTo;
        var employee = oldEmployeeData ? oldEmployeeData : this.employee;
        var employment = oldEmployeeEmployment ? oldEmployeeEmployment : this.currentUserEmployment;
        // Remove UserEmployments that are not currently active selection
        // Remove check length condition. Since Employment object is not the same if update failed.
        if (employment && employment.CompanyUserId === employee.Id) {
            employee.UserEmployments = [];
            if (employment.IncomeTypeId === 0 || employment.IncomeTypeId === Constants.MAGIC_NUMBER) {
                employment.IncomeTypeId = 0;
            }
            employee.UserEmployments.push(employment);
        }
        //  GS-1798: this.originalUserEmployment will be null when editing an employee without userEmployment.
        // In localhost the next line doesn't cause error but in PROD mode some browsers are very unhappy about this
        if (!this.originalUserEmployment) {
            // Should save normally
            this.performUpdateUserEmployment(employee, oldEmployeeData, navigateTo);
            return;
        }
        var savingEmployment;
        if (employee.UserEmployments) {
            savingEmployment = employee.UserEmployments.find(function (employ) { return employ.Id === _this.originalUserEmployment.Id; });
        }
        this.updateEmployee = employee;
        if (savingEmployment && this.originalUserEmployment.BasedOnTemplateId !== savingEmployment.BasedOnTemplateId) {
            this.synchTemplateDialogVisible = true;
        }
        else {
            this.performUpdateUserEmployment(employee, oldEmployeeData, navigateTo);
        }
    };
    EmployeeTabsComponent.prototype.performUpdateUserEmployment = function (employee, oldEmployeeData, navigateTo, syncTemplateId) {
        var _this = this;
        this.dataService.CompanyUsers_UpdateCompanyUser(employee).subscribe(function (updatedCompanyUser) {
            if (!oldEmployeeData) {
                // UpdatedCompanyUser is for the old data, so we won't set it to the current
                _this.employee = updatedCompanyUser;
                _this.employeeUpdated.emit(_this.employee);
                if (_this.isBlankEmail && (updatedCompanyUser.PersonalEmail || updatedCompanyUser.CompanyEmail)) {
                    _this.resendWelcomeEmail = true;
                }
            }
            if (syncTemplateId) {
                _this.dataService
                    .Employments_UpdateEmploymentToCurrentTemplate(syncTemplateId)
                    .subscribe(function (synedEmployment) {
                    if (_this.currentUserEmployment.Id === synedEmployment.Id) {
                        _this.currentUserEmployment = synedEmployment;
                    }
                    var employmentThatSync;
                    if (_this.employee.UserEmployments) {
                        employmentThatSync = _this.employee.UserEmployments.find(function (employment) { return employment.Id === employee.UserEmployments[0].Id; });
                    }
                    if (employmentThatSync) {
                        _this.employee.UserEmployments[_this.employee.UserEmployments.lastIndexOf(employmentThatSync)] = synedEmployment;
                    }
                });
            }
            // Reset
            _this.isDirty = false;
            if (navigateTo) {
                _this.sessionService.NavigateTo(navigateTo);
            }
        }, function (err) {
            if (oldEmployeeData) {
                // We updated the old data ut is now at the newly selected employee so these properties don't belong to the old data
                _this.resetValidation();
                _this.isDirty = false;
                _this.editMode = false;
            }
            else {
                _this.isApiValidationValid = false;
                if (!oldEmployeeData) {
                    _this.editMode = true;
                }
                _this.isDirty = true;
            }
        });
    };
    EmployeeTabsComponent.prototype.reloadUserEmployment = function () {
        var _this = this;
        this.dataService.Employments_GetEmployment(this.currentUserEmployment.Id).subscribe(function (data) {
            _this.currentUserEmployment = data;
        });
    };
    EmployeeTabsComponent.prototype.createTemplateFromCurrentEmployment = function () {
        this.createTemplateConfirmationDialogVisible = true;
    };
    EmployeeTabsComponent.prototype.onSynchronizeEmploymentWithTemplate = function () {
        var _this = this;
        if (this.currentUserEmployment && this.currentUserEmployment.Id) {
            this.dataService
                .Employments_UpdateEmploymentToCurrentTemplate(this.currentUserEmployment.Id)
                .subscribe(function (synedEmployment) {
                _this.newEmployment = synedEmployment;
                _this.onUserEmploymentChange(synedEmployment);
            });
        }
    };
    EmployeeTabsComponent.prototype.resetValidation = function () {
        this.isEmployeeInfoValid = true;
        this.isEmployeeInfoValid = true;
        this.isApiValidationValid = true;
    };
    EmployeeTabsComponent.prototype.validate = function () {
        return this.isTabsValid && this.isEmployeeInfoValid && this.isApiValidationValid;
    };
    EmployeeTabsComponent.prototype.onresendEmployeeWelcomEmailConfirmCompleted = function (action) {
        var _this = this;
        if (action === 'YesPlease') {
            this.dataService.CompanyUsers_ResendUserEmploymentEmail(this.employee.Id).subscribe(function () {
                _this.resendEmailSuccessVisible = true;
            });
        }
    };
    EmployeeTabsComponent.prototype.onAvatarChanged = function () {
        this.isAvatarChanged.emit(true);
    };
    EmployeeTabsComponent.prototype.getTemplates = function () {
        var _this = this;
        this.dataService
            .EmploymentTemplates_GetEmploymentTemplates(true)
            .subscribe(function (templates) {
            if (templates.length > 0) {
                _this.createNewEmploymentDialogVisible = true;
            }
            else {
                _this.isNoTemplate = true;
            }
        });
    };
    return EmployeeTabsComponent;
}());
export { EmployeeTabsComponent };
