import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../Common/FormComponentBase';
import { Global } from '../Common/Global';
import { StaticDataService } from '../Services/SharedServices/StaticData.service';
var LanguageChooserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LanguageChooserComponent, _super);
    function LanguageChooserComponent(staticDataService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.visibleChange = new EventEmitter();
        _this.languageChanged = new EventEmitter();
        return _this;
    }
    Object.defineProperty(LanguageChooserComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    LanguageChooserComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.languageId = Global.SESSION ? Global.SESSION.SignOnToken.LanguageId : undefined;
        this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (languages) {
            _this.languages =
                languages && languages.length
                    ? languages.filter(function (language) { return language.Id !== _this.languageId; })
                    : undefined;
        });
    };
    LanguageChooserComponent.prototype.raiseLanguageChange = function (languageId) {
        this.languageChanged.emit({ languageId: languageId });
        this.visible = false;
    };
    return LanguageChooserComponent;
}(FormComponentBase));
export { LanguageChooserComponent };
