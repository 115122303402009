import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-grid-in-cell-popup',
  templateUrl: './GridInCellPopup.component.html'
})
export class GridInCellPopupComponent {
  private currentVisable = false;

  @Input() public content = '';
  @Input() public actions: string[];
  @Input() public model: any;
  @Input()
  public get visible(): boolean {
    return this.currentVisable;
  }
  public set visible(value: boolean) {
    this.currentVisable = value;
    if (value) {
      setTimeout(() => {
        this.visible = false;
      }, 2000);
    }
  }

  @Output() public click: EventEmitter<{}> = new EventEmitter<{}>();

  public onActionClicked(action: string): void {
    this.visible = false;
    this.click.emit({ action, value: this.model });
  }

  public onHidePopup(): void {
    this.visible = false;
  }
}
