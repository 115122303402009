import { EventEmitter } from '@angular/core';
var GridInCellPopupComponent = /** @class */ (function () {
    function GridInCellPopupComponent() {
        this.currentVisable = false;
        this.content = '';
        this.click = new EventEmitter();
    }
    Object.defineProperty(GridInCellPopupComponent.prototype, "visible", {
        get: function () {
            return this.currentVisable;
        },
        set: function (value) {
            var _this = this;
            this.currentVisable = value;
            if (value) {
                setTimeout(function () {
                    _this.visible = false;
                }, 2000);
            }
        },
        enumerable: true,
        configurable: true
    });
    GridInCellPopupComponent.prototype.onActionClicked = function (action) {
        this.visible = false;
        this.click.emit({ action: action, value: this.model });
    };
    GridInCellPopupComponent.prototype.onHidePopup = function () {
        this.visible = false;
    };
    return GridInCellPopupComponent;
}());
export { GridInCellPopupComponent };
