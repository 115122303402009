<app-dialog [overlayClose]="false" (action)="onDialogAction($event)" [(visible)]="visible">
  <form>
    <fieldset class="FormElement" *ngIf="IsPayrollDataImport">
      <div class="FormElement-control">
        <div class="FormElement-static">
          <label class="FormElement-radio">
            <input
              type="radio"
              name="importType"
              [(ngModel)]="payrollMappingType"
              [value]="3"
              id="importPayrollMappingColumn"
            />
            <span class="FormElement-radioFaux"></span>
            <span class="FormElement-label">{{ 'CompanyDataImport.PayrollMappingColumn' | translate }}</span>
          </label>
        </div>
      </div>

      <div class="FormElement-control">
        <div class="FormElement-static">
          <label class="FormElement-radio">
            <input
              type="radio"
              name="importType"
              [(ngModel)]="payrollMappingType"
              [value]="4"
              id="importPayrollMappingRow"
            />
            <span class="FormElement-radioFaux"></span>
            <span class="FormElement-label">{{ 'CompanyDataImport.PayrollMappingRow' | translate }}</span>
          </label>
        </div>
      </div>
    </fieldset>
  </form>

  <app-text-edit
    [editMode]="true"
    [(value)]="mappingName"
    [label]="'CreateDataImportTemplate.MappingName' | translate"
  ></app-text-edit>

  <numeric-edit
    [editMode]="true"
    [(value)]="startIndex"
    [option]="option"
    [label]="'CreateDataImportTemplate.HeaderStartIndex' | translate"
  ></numeric-edit>

  <div class="CompanyDataImportViewButtonSection">
    <app-file-upload
      [accept]="'.xlsx'"
      (fileSelected)="onFileSelected($event)"
      [label]="'CreateDataImportTemplate.UploadFile' | translate"
      [(shortFileName)]="uploadFileName"
    ></app-file-upload>
    <div class="fileName">
      <span class="FormElement-label">{{ uploadFileName }}</span>
    </div>
  </div>

  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Create'" [disabled]="!createTemplateButtonEnable"></app-dialog-action>
</app-dialog>

<app-dialog [visible]="fileSizeDialogVisible" [leadingText]="'CompanyGeneral.FileSizeErrorMessage' | translate">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<import-mapping-dialog
  [(visible)]="showImportMappingDialog"
  [importType]="ImportTypeForMapping"
  [(formData)]="formData"
  [mappingName]="mappingName"
  (closedEvent)="visible = false"
  [isExistingMapping]="false"
  [(startIndex)]="startIndex"
>
</import-mapping-dialog>
