<label class="FormElement-label u-hiddenVisually" for="comboboxSort">
  {{ 'Employee.Sort by first name/surname' | translate }}
</label>

<combobox-edit-control
  [editMode]="true"
  class="FormElement-select"
  id="comboboxSort"
  [comboboxDataSource]="sortByDataSource"
  [(value)]="selectedId"
  (valueChange)="onComboBoxChanged()"
  [textField]="'Text'"
  [idField]="'Id'"
></combobox-edit-control>
