import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Global } from '../../Common/Global';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'more-menu',
  templateUrl: './MoreMenu.component.html'
})
export class MoreMenuComponent {
  @Input()
  get currentTab(): string {
    return this.currentTabText;
  }
  set currentTab(value: string) {
    if (!value || value.length === 0) {
      return;
    }
    this.currentTabText = value;
    switch (value) {
      case 'tabs.company.configuration.hiringstatus':
        this.companyTemplateTabActive = true;
        this.companySalaryBatchesTabActive = false;
        this.companyGeneralTabActive = false;
        this.employeeHiringStatusTabActive = false;
        this.employeeGeneralTabActive = false;
        this.employeePayrollDataTabActive = false;
        this.accountantCompanyTabActive = false;
        break;
      case 'tabs.company.salarybatches':
        this.companySalaryBatchesTabActive = true;
        this.companyTemplateTabActive = false;
        this.companyGeneralTabActive = false;
        this.employeeHiringStatusTabActive = false;
        this.employeeGeneralTabActive = false;
        this.employeePayrollDataTabActive = false;
        this.accountantCompanyTabActive = false;
        break;
      case 'tabs.company.general':
        this.companyGeneralTabActive = true;
        this.companyTemplateTabActive = false;
        this.companySalaryBatchesTabActive = false;
        this.employeeHiringStatusTabActive = false;
        this.employeeGeneralTabActive = false;
        this.employeePayrollDataTabActive = false;
        this.accountantCompanyTabActive = false;
        break;
      case 'tabs.employee.hiringstatus':
        this.employeeHiringStatusTabActive = true;
        this.companyTemplateTabActive = false;
        this.companySalaryBatchesTabActive = false;
        this.companyGeneralTabActive = false;
        this.employeeGeneralTabActive = false;
        this.employeePayrollDataTabActive = false;
        this.accountantCompanyTabActive = false;
        break;
      case 'tabs.employee.general':
        this.employeeGeneralTabActive = true;
        this.employeePayrollDataTabActive = false;
        this.companyTemplateTabActive = false;
        this.companySalaryBatchesTabActive = false;
        this.companyGeneralTabActive = false;
        this.employeeHiringStatusTabActive = false;
        this.accountantCompanyTabActive = false;
        break;
      case 'tabs.employee.payrolldata':
        this.employeePayrollDataTabActive = true;
        this.employeeGeneralTabActive = false;
        this.companyTemplateTabActive = false;
        this.companySalaryBatchesTabActive = false;
        this.companyGeneralTabActive = false;
        this.employeeHiringStatusTabActive = false;
        this.accountantCompanyTabActive = false;
        break;
      case 'tabs.accountant.companies':
        this.employeePayrollDataTabActive = false;
        this.employeeGeneralTabActive = false;
        this.companyTemplateTabActive = false;
        this.companySalaryBatchesTabActive = false;
        this.companyGeneralTabActive = false;
        this.employeeHiringStatusTabActive = false;
        this.accountantCompanyTabActive = true;
        break;
      default:
        this.companyTemplateTabActive = false;
        this.companySalaryBatchesTabActive = false;
        this.companyGeneralTabActive = false;
        this.employeeHiringStatusTabActive = false;
        this.employeeGeneralTabActive = false;
        this.employeePayrollDataTabActive = false;
        this.accountantCompanyTabActive = false;
        break;
    }
  }

  @Input() public module: string;
  @Input() public showMatchTemplateMenu = true;

  @Output() public menuClick: EventEmitter<string> = new EventEmitter<string>();

  public get IsFullAdmin(): boolean {
    return this.sessionService.role.IsFullAdmin;
  }
  public get IsSalaryAdminAndHigher(): boolean {
    return this.sessionService.role.IsSalaryAdminAndHigher;
  }
  public get HasBackendAccess(): boolean {
    return Global.SESSION && Global.SESSION.HasBackendAccess;
  }
  public get isCompanyGeneralMobile(): boolean {
    return this.sessionService.browser.isMobile && this.sessionService.currentState === 'tabs.company.general';
  }

  public companyTemplateTabActive = false;
  public companySalaryBatchesTabActive = false;
  public companyGeneralTabActive = false;
  public employeeHiringStatusTabActive = false;
  public employeeGeneralTabActive = false;
  public employeePayrollDataTabActive = false;
  public accountantCompanyTabActive = false;
  // public isOpen: boolean;

  private currentTabText: string;

  constructor(public sessionService: SessionService) {}

  public onMenuClickEvent(data: string): void {
    this.sessionService.showMoreMenuToggled = false;
    this.menuClick.emit(data);
  }

  // @HostListener("click", ["$event"])
  // public onClick(event: any): void
  // {
  //     event.preventDefault();
  //     event.stopPropagation();
  // }

  @HostListener('click', ['$event'])
  public onToggleMoreDropdown(event: any): void {
    this.sessionService.showUserMenuToggled = false;
    this.sessionService.showHelpMenuToggled = false;
    this.sessionService.showMoreMenuToggled = !this.sessionService.showMoreMenuToggled;
    ////event.preventDefault();
    event.stopPropagation();
  }
  public onkeydown(event: any): void {
    if (event.code === 'Escape') {
      this.sessionService.showMoreMenuToggled = false;
      event.stopPropagation();
    }
  }
}
