import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Browser } from '../../../Common/Browser';
import { FilterDateTimeGrid } from '../../../Common/FilterDateTimeGrid';
import { Global } from '../../../Common/Global';
import { GridHelper } from '../../../Common/GridHelper';
import { ExtendSalaryPeriod } from '../../../Model/ExtendSalaryPeriod';
import { ITimeEntryPreview } from '../../../Model/TimeEntryModel';
import { TimeEntryRecord, TimeEntryStatus } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
var EmployeeTimeComponent = /** @class */ (function () {
    function EmployeeTimeComponent(translateService, dataService, settingService, staticDataService, sessionService) {
        this.translateService = translateService;
        this.dataService = dataService;
        this.settingService = settingService;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.createUserEmploymentClick = new EventEmitter();
        this.selectedRows = [];
        this.defaultRecordValue = {};
        this.hasTimeModule = false;
        this.HasEmployment = false;
        this.TimeEntryTypes = [];
        this.TimeEntryRecords = [];
        this.TimeEntryStatuses = [];
        this.allowUsersToModifyOwnData = false;
        this.allowSelfServiceTimeEntry = false;
        this.ApprovalSubodinates = [];
        this.allowManagersToApproveTimeEntry = false;
        this.isDisableAddNew = false;
        this.option = { min: 0, step: 1, spinners: false };
        this.dimensionXValues = [];
        this.AllText = 'All';
        this.isSlect1020default = true;
        this.TimeEntryRecord = [];
        this.numberOption = { min: 0, step: 1, spinners: false, decimals: 2, format: 'n2' };
        /*end variable of approval timeentry*/
        this.preSalaryCycleId = 0;
        this.preSalaryPeriodId = 0;
        this.preStatus = 0;
        this.isErrorApiMessage = false;
        this.ngUnsubscribe = new Subject();
        this.previousAddedTimeEntryUid = [];
        this.isgetNotMatchPeriodMessageString = false;
        /*mobile centric Area Function*/
        this.isAddNewChange = new EventEmitter();
        this.currentisaddnew = false;
        this.isShowRejectDialog = false;
        this.isShowRejectDialogNonApproveTimeEntry = false;
        this.isShowApproveForSelectDialog = false;
        this.isShowApproveForAllDialog = false;
        this.triggerUpdate = false;
        this.preData = new TimeEntryRecord();
        this.isShowDateErrorInput = false;
        this.isUpdateWarrningPeriods = false;
        this.flagselect = 0;
        this.isShowDeleteRecord = false;
        this.isShowEmployeeTimeApproveDialog = false;
        this.keyPress = { 17: false, 18: false, 107: false, 109: false };
        this.isShowApprovalOpenEntries = false;
        this.gridData = filterBy(this.TimeEntryRecords, this.filter);
        this.gridApprovalData = filterBy(this.TimeEntryRecord, this.approvalFilter);
        this.translationServiceTerms = [
            'EmploymentTime.PeriodWarning',
            'CompanySalaryBatches.SalaryCycle_Item_All_Text',
            'EmploymentTime.Eligible for approval'
        ];
        this.UnitTypes = Global.UNIT_TYPES;
        this.companypreference = Global.COMPANY_PREFERENCES;
    }
    Object.defineProperty(EmployeeTimeComponent.prototype, "userEmployment", {
        get: function () {
            return this.privateUserEmployment;
        },
        set: function (value) {
            var _this = this;
            var preUserId;
            if (this.privateUserEmployment) {
                preUserId = this.privateUserEmployment.Id;
            }
            this.updateUnsavedRecords();
            this.privateUserEmployment = value;
            if (!this.privateUserEmployment || !this.privateUserEmployment.Id) {
                this.HasEmployment = false;
                return;
            }
            this.HasEmployment = true;
            if (!preUserId || preUserId !== value.Id) {
                this.tempselectedSalaryPeriodId = undefined;
                this.TimeEntryStatuses = [];
                this.SalaryPeriod = [];
                this.TimeEntryRecords = [];
                this.DefaultTimeEntryRecord = [];
                this.newPreviewData = [];
                this.gridData = [];
                this.dataService
                    .TimeEntryTypes_GetTimeEntryTypeViews()
                    .subscribe(function (data) { return _this.onGetTimeEntryTypesCompleted(data); });
                // this.populateData(this.privateUserEmployment);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "currentCompanyUser", {
        get: function () {
            return this.privateCurrentCompanyUser;
        },
        set: function (value) {
            this.privateCurrentCompanyUser = value;
            this.resetStatusFilter();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "isEmployeeTimeKeydown", {
        get: function () {
            return this.isEmployeeTimeKeydownValue;
        },
        set: function (value) {
            this.isEmployeeTimeKeydownValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "isMobile", {
        get: function () {
            var browser = new Browser();
            return browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "isapprovaltimeentry", {
        /*variable of approval timeentry*/
        get: function () {
            return this.sessionService.currentState === 'tabs.company.approval.timeentry';
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTimeComponent.prototype.loaddataspurceSalaryCycle = function () {
        var _this = this;
        this.dataService.Miscellaneous_GetUsedSalaryCycles().subscribe(function (data) {
            _this.SalaryCycle = data;
            if (_this.SalaryCycle && _this.SalaryCycle.length > 0) {
                if (_this.sessionService.currentSalaryCycleId) {
                    _this.selectedSalaryCycleId = _this.sessionService.currentSalaryCycleId;
                    _this.sessionService.currentSalaryCycleId = undefined;
                }
                else {
                    _this.selectedSalaryCycleId = _this.SalaryCycle[0].Id;
                }
                _this.populateDataApprovalTimeEntry();
                // if (this.selectedSalaryCycleId) {
                //     this.populateDataApprovalTimeEntry();
                // }
            }
        });
    };
    EmployeeTimeComponent.prototype.populateDataApprovalTimeEntry = function () {
        var _this = this;
        this.dataService
            .SalaryBatches_GetSalaryPeriods(this.selectedSalaryCycleId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (salaryPeriods) {
            if (salaryPeriods && salaryPeriods.length > 0) {
                _this.SalaryPeriod = [];
                salaryPeriods = salaryPeriods.sort(function (data1, data2) {
                    return GridHelper.sortByDateValue(data1.StartDate, data2.StartDate);
                });
                salaryPeriods.forEach(function (salaryPeriod, key) {
                    var startDate = _this.sessionService.toString(salaryPeriod.StartDate ? new Date(salaryPeriod.StartDate) : undefined);
                    var endDate = _this.sessionService.toString(salaryPeriod.EndDate ? new Date(salaryPeriod.EndDate) : undefined);
                    var preference = _this.companypreference.find(function (model) { return model.Key === 'SalaryPeriods.FriendlyNames'; });
                    if ((preference && preference.Value && preference.Value === 'true') || _this.isMobile) {
                        var extendSalaryPeriod = new ExtendSalaryPeriod();
                        (extendSalaryPeriod.Id = salaryPeriod.Id), (extendSalaryPeriod.StartDate = salaryPeriod.StartDate);
                        extendSalaryPeriod.EndDate = salaryPeriod.EndDate;
                        extendSalaryPeriod.Period = startDate + ' - ' + endDate;
                        extendSalaryPeriod.FriendlyName = ' ' + salaryPeriod.FriendlyName;
                        _this.SalaryPeriod[key] = extendSalaryPeriod;
                    }
                    else {
                        var extendSalaryPeriod = new ExtendSalaryPeriod();
                        extendSalaryPeriod.Id = salaryPeriod.Id;
                        extendSalaryPeriod.StartDate = salaryPeriod.StartDate;
                        extendSalaryPeriod.EndDate = salaryPeriod.EndDate;
                        extendSalaryPeriod.Period = startDate + ' - ' + endDate;
                        extendSalaryPeriod.FriendlyName = startDate + ' - ' + endDate;
                        _this.SalaryPeriod[key] = extendSalaryPeriod;
                    }
                });
                if (_this.sessionService.currentSalaryPeriodId) {
                    if (_this.SalaryPeriod && _this.SalaryPeriod.length > 0) {
                        var periodChek = _this.SalaryPeriod.filter(function (model) { return model.Id === _this.selectedSalaryPeriodId; });
                        if (periodChek && periodChek.length > 0) {
                            _this.selectedSalaryPeriodId = _this.sessionService.currentSalaryPeriodId;
                            // this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
                            _this.sessionService.currentSalaryPeriodId = _this.selectedSalaryPeriodId;
                        }
                        else {
                            _this.sessionService.currentSalaryPeriodId = undefined;
                            if (_this.SalaryPeriod && _this.SalaryPeriod[0]) {
                                var today_1 = _this.staticDataService.getCurrentdate();
                                today_1.setHours(0, 0, 0, 0);
                                var selectedSalaryPeriod = _this.SalaryPeriod.find(function (salaryPeriod) {
                                    var startDate = new Date(salaryPeriod.StartDate.toString());
                                    var endDate = new Date(salaryPeriod.EndDate.toString());
                                    startDate.setHours(0, 0, 0, 0);
                                    endDate.setHours(0, 0, 0, 0);
                                    return startDate <= today_1 && endDate >= today_1;
                                });
                                _this.selectedSalaryPeriodId = selectedSalaryPeriod.Id;
                                // this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
                                _this.sessionService.currentSalaryPeriodId = _this.selectedSalaryPeriodId;
                            }
                        }
                    }
                }
                else {
                    if (_this.SalaryPeriod && _this.SalaryPeriod[0]) {
                        var today_2 = _this.staticDataService.getCurrentdate();
                        today_2.setHours(0, 0, 0, 0);
                        var selectedSalaryPeriod = _this.SalaryPeriod.find(function (salaryPeriod) {
                            var startDate = new Date(salaryPeriod.StartDate.toString());
                            var endDate = new Date(salaryPeriod.EndDate.toString());
                            startDate.setHours(0, 0, 0, 0);
                            endDate.setHours(0, 0, 0, 0);
                            return startDate <= today_2 && endDate >= today_2;
                        });
                        _this.selectedSalaryPeriodId = selectedSalaryPeriod.Id;
                        // this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
                        _this.sessionService.currentSalaryPeriodId = _this.selectedSalaryPeriodId;
                    }
                }
            }
            else {
                _this.SalaryPeriod = [];
            }
        });
    };
    Object.defineProperty(EmployeeTimeComponent.prototype, "isHideDimension1", {
        get: function () {
            return this.Dimension1Preference && !!this.Dimension1Preference.Value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "isHideDimension2", {
        get: function () {
            return this.Dimension2Preference && !!this.Dimension2Preference.Value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "isHideDimension3", {
        get: function () {
            return this.Dimension3Preference && !!this.Dimension3Preference.Value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "Dimension1Name", {
        get: function () {
            if (!this.isHideDimension1) {
                return '';
            }
            else if (this.companypreference && this.companypreference.length > 0) {
                var dimension1Object = this.companypreference.find(function (model) { return model.Key === 'Dimensions.Dimension1Name'; });
                if (dimension1Object && dimension1Object.Value) {
                    return dimension1Object.Value;
                }
            }
            return '1';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "Dimension2Name", {
        get: function () {
            if (!this.isHideDimension2) {
                return '';
            }
            else if (this.companypreference && this.companypreference.length > 0) {
                var dimension2Object = this.companypreference.find(function (model) { return model.Key === 'Dimensions.Dimension2Name'; });
                if (dimension2Object && dimension2Object.Value) {
                    return dimension2Object.Value;
                }
            }
            return '2';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "Dimension3Name", {
        get: function () {
            if (!this.isHideDimension3) {
                return '';
            }
            else if (this.companypreference && this.companypreference.length > 0) {
                var dimension3Object = this.companypreference.find(function (model) { return model.Key === 'Dimensions.Dimension3Name'; });
                if (dimension3Object && dimension3Object.Value) {
                    return dimension3Object.Value;
                }
            }
            return '3';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "buttonApproveEntriesisviable", {
        get: function () {
            if (this.TimeEntryRecord && this.TimeEntryRecord.length > 0) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "IsActive", {
        get: function () {
            return (this.sessionService.currentState === 'tabs.employee.time' ||
                this.sessionService.currentState === 'tabs.selfservice.time');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "isSelfService", {
        get: function () {
            return this.sessionService.currentState === 'tabs.selfservice.time';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "IsReadOnlyAndHasSubordinates", {
        get: function () {
            if (this.IsReadOnly && this.ApprovalSubodinates && this.ApprovalSubodinates.length > 0) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "IsReadOnlyAndHasSubordinatesAndFilterByEligibleForApproval", {
        get: function () {
            if (this.IsReadOnlyAndHasSubordinates && this.selectedTimeEntryStatusId === -2 && !this.isSpecialMode) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "allowToApprove", {
        get: function () {
            return (this.currentCompanyUser &&
                !(this.sessionService.role.IsEmployeeRole &&
                    Global.SESSION &&
                    Global.SESSION.CurrentCompanyUser.Id === this.currentCompanyUser.Id));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "SelectedSalaryPeriod", {
        get: function () {
            var _this = this;
            if (!this.selectedSalaryPeriodId || !this.SalaryPeriods || this.SalaryPeriods.length <= 0) {
                return undefined;
            }
            return this.SalaryPeriods.filter(function (period) {
                return period.Id === _this.selectedSalaryPeriodId;
            })[0];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "Dimension1Preference", {
        get: function () {
            var dimension = Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.find(function (pref) { return pref.Key === 'Dimensions.Dimension1Name'; })
                : undefined;
            return dimension;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "Dimension2Preference", {
        get: function () {
            var dimension = Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.find(function (pref) { return pref.Key === 'Dimensions.Dimension2Name'; })
                : undefined;
            return dimension;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "Dimension3Preference", {
        get: function () {
            var dimension = Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.find(function (pref) { return pref.Key === 'Dimensions.Dimension3Name'; })
                : undefined;
            return dimension;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTimeComponent.prototype.timeStatusChanged = function () {
        this.filterTimeEntriesByStatus();
    };
    Object.defineProperty(EmployeeTimeComponent.prototype, "allowManagersToApproveTimeEntryMessage", {
        get: function () {
            if (!this.allowManagersToApproveTimeEntry) {
                return 'EmploymentTime.DisableGridTimeMessage';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "isAllowEditGridOption", {
        get: function () {
            var currentState = this.sessionService.currentState;
            switch (currentState) {
                case 'tabs.employee.time':
                    // if (this.currentCompanyUser.UserId === Global.SESSION.UserId) {
                    //     if (this.allowUsersToModifyOwnData) {
                    //         return true;
                    //     } else {
                    //         return false;
                    //     }
                    // } else {
                    //     return true;
                    // }
                    return true;
                case 'tabs.selfservice.time':
                    if (this.allowSelfServiceTimeEntry) {
                        return true;
                    }
                    break;
                default:
                    break;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeComponent.prototype, "DisbaleTooltipAddNewEmployeetime", {
        get: function () {
            if (!this.isAllowEditGridOption) {
                return 'EmploymentTime.DisableGridTimeMessage';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTimeComponent.prototype.ngOnInit = function () {
        var _this = this;
        var currentState = this.sessionService.currentState;
        switch (currentState) {
            case 'tabs.employee.time':
                var objectAllowUsersToModifyOwnData = this.companypreference.filter(function (model) { return model.Key === 'Access.AllowUsersToModifyOwnData'; });
                if (objectAllowUsersToModifyOwnData &&
                    objectAllowUsersToModifyOwnData[0] &&
                    objectAllowUsersToModifyOwnData[0].Value === 'true') {
                    this.allowUsersToModifyOwnData = true;
                }
                break;
            case 'tabs.selfservice.time':
                var objectAllowSelfServiceTimeEntry = this.companypreference.filter(function (model) { return model.Key === 'Access.AllowSelfServiceTimeEntry'; });
                if (objectAllowSelfServiceTimeEntry &&
                    objectAllowSelfServiceTimeEntry[0] &&
                    objectAllowSelfServiceTimeEntry[0].Value === 'true') {
                    this.allowSelfServiceTimeEntry = true;
                }
                break;
            default:
                break;
        }
        this.sessionService.OnLocationChangeStart.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (transition) {
            if (_this.IsActive) {
                _this.updateUnsavedRecords();
            }
        });
        this.staticDataService.TimeEntryStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (timeEntryStatus) {
            _this.timeEntryStatus = timeEntryStatus;
            if (!_this.timeEntryStatus || (_this.timeEntryStatus && _this.timeEntryStatus.length === 0)) {
                return;
            }
            _this.enableTranslation();
            if (_this.isapprovaltimeentry) {
                var objectAllowManagersToApproveTimeEntry = _this.companypreference.filter(function (model) { return model.Key === 'Access.AllowManagersToApproveTimeEntry'; });
                if (objectAllowManagersToApproveTimeEntry &&
                    objectAllowManagersToApproveTimeEntry[0] &&
                    objectAllowManagersToApproveTimeEntry[0].Value === 'true') {
                    _this.allowManagersToApproveTimeEntry = true;
                }
                else {
                    if (_this.sessionService.role.IsSalaryAdminAndHigher) {
                        _this.allowManagersToApproveTimeEntry = true;
                    }
                }
                _this.ApprovalSubodinates = Global.SESSION.ManagerForUserEmploymentIds;
                _this.loaddataspurceSalaryCycle();
                _this.dataService
                    .TimeEntryTypes_GetTimeEntryTypeViews()
                    .subscribe(function (data) { return _this.onGetTimeEntryTypesCompleted(data); });
                // this.loaddatasourceTimeEntryStatus(this.TimeEntryRecord);
                return;
            }
            if (_this.IsActive) {
                _this.settingService.showModuleMessage('Employee.Time');
            }
            if (_this.Dimension1Preference && _this.Dimension1Preference.Value) {
                _this.dataService.Miscellaneous_GetDimensionValues(1).subscribe(function (data) {
                    _this.dimensionXValues[0] = data.filter(function (value) { return value; });
                });
            }
            if (_this.Dimension2Preference && _this.Dimension2Preference.Value) {
                _this.dataService.Miscellaneous_GetDimensionValues(2).subscribe(function (data) {
                    _this.dimensionXValues[1] = data.filter(function (value) { return value; });
                });
            }
            if (_this.Dimension3Preference && _this.Dimension3Preference.Value) {
                _this.dataService.Miscellaneous_GetDimensionValues(3).subscribe(function (data) {
                    _this.dimensionXValues[2] = data.filter(function (value) { return value; });
                });
            }
        });
    };
    EmployeeTimeComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    EmployeeTimeComponent.prototype.loadTimeEntryRecord = function (periodId) {
        var _this = this;
        this.TimeEntryRecord = [];
        this.dataService
            .TimeEntry_GetTimeEntryRecordsByPeriod(periodId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            data.forEach(function (model) {
                model.RejectionReason = model.Status && model.Status.Key !== 'Rejected' ? '' : model.RejectionReason;
                model.iconFeildApprove = 'SuccessNonGreen';
                // Check Edit
                model.isCanReject =
                    (!_this.IsReadOnly || (_this.IsReadOnly && _this.isSubordinatesModel(model.UserEmploymentId))) &&
                        (model.Status &&
                            (model.Status.Key === 'Rejected' ||
                                model.Status.Key === 'Open' ||
                                model.Status.Key === 'AdminApproved' ||
                                model.Status.Key === 'EmployeeApproved'))
                        ? true
                        : false;
                model.isDisbaleReject = !model.isCanReject || !_this.allowManagersToApproveTimeEntry;
                model.isCanSingleApprove =
                    (!_this.IsReadOnly || (_this.IsReadOnly && _this.isSubordinatesModel(model.UserEmploymentId))) &&
                        (model.Status && (model.Status.Key === 'Open' || model.Status.Key === 'EmployeeApproved'))
                        ? true
                        : false;
                model.isDisbaleSingleApprove = !model.isCanSingleApprove || !_this.allowManagersToApproveTimeEntry;
            });
            // this.TimeEntryRecord = data;
            _this.DefaultTimeEntryRecord = data;
            _this.loaddatasourceTimeEntryStatus(_this.DefaultTimeEntryRecord);
        });
    };
    Object.defineProperty(EmployeeTimeComponent.prototype, "TooltipFroApproButtonAprrovalGrid", {
        get: function () {
            if (!this.allowManagersToApproveTimeEntry) {
                return this.allowManagersToApproveTimeEntryMessage;
            }
            return 'EmploymentTime.Approve this record';
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTimeComponent.prototype.isSubordinatesModel = function (numberEmployment) {
        if (this.IsReadOnly && this.ApprovalSubodinates && this.ApprovalSubodinates.length > 0) {
            return this.ApprovalSubodinates.filter(function (dataId) { return numberEmployment === dataId; }).length > 0;
        }
        return false;
    };
    EmployeeTimeComponent.prototype.onBaseSalaryCycleComboboxChanged = function (value) {
        var _this = this;
        if (this.selectedSalaryCycleId) {
            if (this.isSpecialMode) {
                this.TimeEntryRecord = [];
                this.loadDataSourceForSpecialMode();
            }
            else {
                if (this.preSalaryCycleId !== this.selectedSalaryCycleId) {
                    // this.sessionService.currentSalaryCycleId = this.selectedSalaryCycleId;
                    this.TimeEntryRecord = [];
                    this.TimeEntryStatus = [];
                    this.currentSalaryCycle = this.SalaryCycle.find(function (model) { return model.Id === _this.selectedSalaryCycleId; });
                    this.populateDataApprovalTimeEntry();
                    this.preSalaryCycleId = this.selectedSalaryCycleId;
                    this.preSalaryPeriodId = 0;
                    this.preStatus = 0;
                    this.preSalaryPeriodId = undefined;
                    this.preStatus = undefined;
                    this.stillSelectstatus = undefined;
                    this.isSlect1020default = true;
                }
            }
        }
    };
    EmployeeTimeComponent.prototype.onBaseSalaryPeriodComboboxChanged = function (value) {
        var _this = this;
        if (this.selectedSalaryPeriodId && this.preSalaryPeriodId !== this.selectedSalaryPeriodId) {
            this.sessionService.currentSalaryCycleId = this.selectedSalaryCycleId;
            this.sessionService.currentSalaryPeriodId = this.selectedSalaryPeriodId;
            this.currentSalaryPeriod = this.SalaryPeriod.find(function (model) { return model.Id === _this.selectedSalaryPeriodId; });
            this.stillSelectstatus = undefined;
            this.selectedTimeEntryStatusId = undefined;
            this.isSlect1020default = true;
            this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
            this.preSalaryPeriodId = this.selectedSalaryPeriodId;
        }
    };
    EmployeeTimeComponent.prototype.onBaseTimeEntryStatusComboboxChanged = function (value) {
        var _this = this;
        if (this.selectedTimeEntryStatusId && this.preStatus !== this.selectedTimeEntryStatusId) {
            setTimeout(function () {
                _this.currentTimeEntryStatus = _this.TimeEntryStatus.filter(function (model) {
                    return model.Id === _this.selectedTimeEntryStatusId;
                })[0];
                _this.preStatus = _this.selectedTimeEntryStatusId;
                _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                _this.filterTimeEntriesByStatus();
            });
        }
    };
    EmployeeTimeComponent.prototype.loaddatasourceTimeEntryStatus = function (timeEntryRecord) {
        var _this = this;
        if (timeEntryRecord) {
            this.TimeEntryStatus = [];
            timeEntryRecord.forEach(function (item) {
                if (item && item.Status) {
                    var isStatusExist = _this.TimeEntryStatus.filter(function (timeEntryStatus) { return timeEntryStatus.Name === item.Status.Name; }).length > 0;
                    if (!isStatusExist) {
                        _this.TimeEntryStatus.push(item.Status);
                    }
                }
            });
            if (this.TimeEntryStatus && this.TimeEntryStatus.length >= 1) {
                var filter1020 = new TimeEntryStatus();
                filter1020.Id = -2;
                filter1020.Name = this.filterEligibleforapproval;
                this.TimeEntryStatus.push(filter1020);
                var allStatus = { Id: -1, Name: this.AllText };
                this.TimeEntryStatus.unshift(allStatus);
                if (this.isSlect1020default && this.isSlect1020default === true) {
                    this.currentTimeEntryStatus = filter1020;
                    this.selectedTimeEntryStatusId = this.currentTimeEntryStatus.Id;
                }
                else {
                    var tempstatus = this.TimeEntryStatus.filter(function (model) { return model.Id === _this.stillSelectstatus; });
                    if (tempstatus && tempstatus.length > 0) {
                        this.currentTimeEntryStatus = tempstatus[0];
                        this.selectedTimeEntryStatusId = this.stillSelectstatus;
                    }
                    else {
                        this.currentTimeEntryStatus = allStatus;
                        this.selectedTimeEntryStatusId = this.currentTimeEntryStatus.Id;
                    }
                }
            }
            else {
                this.TimeEntryStatus = [];
                var allStatus = { Id: -1, Name: this.AllText };
                this.TimeEntryStatus.unshift(allStatus);
                this.currentTimeEntryStatus = allStatus;
                this.selectedTimeEntryStatusId = this.currentTimeEntryStatus.Id;
            }
        }
        else {
            this.TimeEntryStatus = [];
            var allStatus = { Id: -1, Name: this.AllText };
            this.TimeEntryStatus.unshift(allStatus);
            this.currentTimeEntryStatus = allStatus;
            this.selectedTimeEntryStatusId = this.currentTimeEntryStatus.Id;
        }
        this.preStatus = undefined;
        this.onBaseTimeEntryStatusComboboxChanged(undefined);
    };
    EmployeeTimeComponent.prototype.enableTranslation = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService.get(_this.translationServiceTerms).subscribe(function (translations) {
                _this.setTranslationTerms(translations);
            });
        });
    };
    EmployeeTimeComponent.prototype.setTranslationTerms = function (translations) {
        this.PeriodWarningText = translations['EmploymentTime.PeriodWarning'];
        this.AllText = translations['CompanySalaryBatches.SalaryCycle_Item_All_Text'];
        this.filterEligibleforapproval = translations['EmploymentTime.Eligible for approval'];
    };
    EmployeeTimeComponent.prototype.onGetTimeEntryTypesCompleted = function (timeEntryTypes) {
        if (timeEntryTypes && timeEntryTypes.length > 0) {
            this.flagselect += 1;
            this.TimeEntryTypes = timeEntryTypes;
            this.TimeEntryTypes.forEach(function (model) {
                model.Id = model.Id || model.BaseTimeEntryTypeId || null;
                model.InternalReferenceId = model.InternalReferenceId ? model.InternalReferenceId : null;
            });
            this.defaultTimeEntryType = timeEntryTypes.find(function (mode) { return mode.Identifier === 'Work'; });
            if (!this.defaultTimeEntryType) {
                this.defaultTimeEntryType = timeEntryTypes[0];
            }
        }
        this.flagselect += 1;
        if (this.TimeEntryTypes && this.TimeEntryTypes.length > 0 && !this.isapprovaltimeentry) {
            this.populateData(this.privateUserEmployment);
        }
    };
    EmployeeTimeComponent.prototype.filterTimeEntriesByStatus = function (dataSource) {
        var _this = this;
        if (!this.isapprovaltimeentry) {
            if (this.TimeEntryRecords) {
                if (this.SelectedTimeStatusId >= 0) {
                    this.TimeEntryRecords = this.DefaultTimeEntryRecord.filter(function (model) { return model.Status.Id === _this.SelectedTimeStatusId; });
                }
                else {
                    this.TimeEntryRecords = this.DefaultTimeEntryRecord;
                }
                if (this.filter && this.filter.filters && this.filter.filters.length > 0) {
                    this.onFilterChange(this.filter);
                }
                // this.TimeEntryRecords = filterBy(this.TimeEntryRecords, this.filter);
            }
        }
        else {
            if (this.TimeEntryRecord) {
                if (this.selectedTimeEntryStatusId >= 0) {
                    this.TimeEntryRecord = this.DefaultTimeEntryRecord.filter(function (model) { return model.Status.Name === _this.currentTimeEntryStatus.Name; });
                }
                else if (this.selectedTimeEntryStatusId === -2) {
                    this.TimeEntryRecord = this.DefaultTimeEntryRecord.filter(function (model) { return model.StatusId === 10 || model.StatusId === 20; });
                    if (this.IsReadOnly) {
                        if (this.ApprovalSubodinates && this.ApprovalSubodinates.length > 0) {
                            this.TimeEntryRecord = this.TimeEntryRecord.filter(function (model) {
                                return (_this.ApprovalSubodinates.filter(function (dataId) {
                                    return model.UserEmploymentId === dataId;
                                }).length > 0);
                            });
                        }
                        else {
                            this.TimeEntryRecord = [];
                        }
                    }
                }
                else {
                    this.TimeEntryRecord = this.DefaultTimeEntryRecord;
                }
                if (this.approvalFilter && this.approvalFilter.filters && this.approvalFilter.filters.length > 0) {
                    this.onApprovalFilterChange(this.approvalFilter);
                }
            }
        }
    };
    Object.defineProperty(EmployeeTimeComponent.prototype, "SelectedTimeStatus", {
        get: function () {
            var _this = this;
            return this.TimeEntryStatuses.filter(function (value) { return value.Id === _this.SelectedTimeStatusId; })[0];
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTimeComponent.prototype.resetStatusFilter = function () {
        if (this.TimeEntryStatuses[0]) {
            this.SelectedTimeStatusId = this.TimeEntryStatuses[0].Id;
        }
        this.filterTimeEntriesByStatus();
    };
    EmployeeTimeComponent.prototype.populateTimeEntryGridDataSource = function (userEmploymentId, salaryPeriodId) {
        var _this = this;
        this.dataService
            .TimeEntry_GetTimeEntryRecordsByEmployment(userEmploymentId, salaryPeriodId)
            .subscribe(function (records) {
            if (records && records.length > 0) {
                records.forEach(function (model) {
                    _this.flagselect += 1;
                    _this.updateEditableFieldsAndClasses(model);
                    if (model.EndTime && model.EndTime === '23:59:59.9999999') {
                        model.EndTime = '24:00:00';
                    }
                    model.TimeEntryType =
                        _this.TimeEntryTypes && _this.TimeEntryTypes.length > 0
                            ? _this.TimeEntryTypes.find(function (item) { return item.TimeEntryTypeId === model.TimeEntryTypeId; })
                            : {};
                    model.isAllowEdit =
                        model.Status && model.Status.Key && (model.Status.Key === 'Open' || model.Status.Key === 'Rejected')
                            ? true
                            : false;
                    if (!model.TimeEntryType || model.TimeEntryType === undefined) {
                        model.TimeEntryType = {};
                    }
                    model.TimeEntryType.Id = model.TimeEntryType ? model.TimeEntryType.TimeEntryTypeId : -1;
                    model.TimeEntryTypeName = model.TimeEntryType && model.TimeEntryType.Name ? model.TimeEntryType.Name : '';
                    model.idColumn = _this.flagselect;
                    model.RejectionReason = model.Status && model.Status.Key !== 'Rejected' ? '' : model.RejectionReason;
                });
                _this.newPreviewData = [];
                _this.TimeEntryRecords = [];
                _this.DefaultTimeEntryRecord = [];
                _this.TimeEntryRecords = JSON.parse(JSON.stringify(records));
                // this.TimeEntryRecords = filterBy(this.TimeEntryRecords, this.filter);
                _this.DefaultTimeEntryRecord = JSON.parse(JSON.stringify(records));
                // push data preview grid
                _this.populateGridPreview();
                // push data preview grid
                if (!_this.isMobile) {
                    _this.TimeEntryStatuses = [];
                    _this.DefaultTimeEntryRecord.forEach(function (item) {
                        if (item && item.Status) {
                            var isStatusExist = _this.TimeEntryStatuses.filter(function (timeEntryStatus) { return timeEntryStatus.Name === item.Status.Name; }).length > 0;
                            if (!isStatusExist) {
                                _this.TimeEntryStatuses.push(item.Status);
                            }
                        }
                    });
                    _this.TimeEntryStatuses = _this.TimeEntryStatuses.sort(function (s) {
                        return s.Name;
                    });
                    var allStatus = { Id: -1, Name: _this.AllText };
                    _this.TimeEntryStatuses.unshift(allStatus);
                    if (_this.SelectedTimeStatus) {
                        var selectedStatus = _this.TimeEntryStatuses.filter(function (timeEntryStatus) { return timeEntryStatus.Name === _this.SelectedTimeStatus.Name; })[0];
                        _this.SelectedTimeStatusId = selectedStatus ? selectedStatus.Id : allStatus.Id;
                        if (!selectedStatus) {
                            _this.filterTimeEntriesByStatus();
                        }
                    }
                    else {
                        _this.SelectedTimeStatusId = allStatus.Id;
                    }
                    _this.initTimeEntryStatusDataSource();
                }
                // this.DefaultTimeEntryRecord.forEach((item: ITimeEntryRecord) => {
                //   if (item && item.Status) {
                //     const entryddate: Date = item.EntryDate
                //       ? new DateTimeConvert(new Date(item.EntryDate)).Date
                //       : item.EntryDate;
                //     item.EntryDate = entryddate;
                //     const enddate: Date = item.EndDate ? new DateTimeConvert(new Date(item.EndDate)).Date : item.EndDate;
                //     item.EndDate = enddate;
                //   }
                // });
            }
            else {
                _this.newPreviewData = [];
                _this.TimeEntryRecords = [];
                _this.gridData = [];
                _this.DefaultTimeEntryRecord = [];
                if (!_this.isMobile) {
                    _this.TimeEntryStatuses = [];
                    var allStatus = { Id: -1, Name: _this.AllText };
                    _this.TimeEntryStatuses.unshift(allStatus);
                    _this.SelectedTimeStatusId = allStatus.Id;
                }
            }
            _this.originalTimeEntryRecords = JSON.parse(JSON.stringify(_this.TimeEntryRecords));
        });
    };
    EmployeeTimeComponent.prototype.populateGridPreview = function () {
        var _this = this;
        var previewResultWithKm = [];
        var kmType = Global.UNIT_TYPES
            ? Global.UNIT_TYPES.find(function (type) { return type.Key === 'Kilometers'; })
            : undefined;
        if (this.TimeEntryRecords && this.TimeEntryRecords.length > 0) {
            var previewcheckTimeentryRecore = this.TimeEntryRecords;
            var array = void 0;
            var hasUndefinedItem_1 = false;
            var newArray = void 0;
            array = previewcheckTimeentryRecore.map(function (item, index) {
                if (item.TimeEntryTypeId &&
                    _this.TimeEntryTypes &&
                    _this.TimeEntryTypes.length > 0 &&
                    (!item.TimeEntryType || item.TimeEntryType.Id !== item.TimeEntryTypeId)) {
                    item.TimeEntryType = _this.TimeEntryTypes.find(function (tet) { return tet.TimeEntryTypeId === item.TimeEntryTypeId; });
                }
                if (!item.TimeEntryType) {
                    hasUndefinedItem_1 = true;
                    return undefined;
                }
                var unitType = _this.UnitTypes.find(function (type) { return type.Id === item.UnitTypeId; });
                var previewItem = {
                    Type: item.TimeEntryType.Name,
                    Units: item.Units,
                    UnitName: unitType ? unitType.Name : undefined,
                    KilometersDriven: item.KilometersDriven ? item.KilometersDriven : 0,
                    KilometersDrivenUnitName: kmType ? kmType.Name : ''
                };
                return previewItem;
            });
            if (hasUndefinedItem_1) {
                newArray = array.filter(function (value) {
                    return value !== undefined;
                });
            }
            else {
                newArray = array;
            }
            var previewResult_1 = [];
            newArray.reduce(function (res, value) {
                if (!res[value.Type + value.UnitName]) {
                    res[value.Type + value.UnitName] = {
                        Type: value.Type,
                        UnitName: value.UnitName,
                        Units: value.Units,
                        KilometersDriven: value.KilometersDriven,
                        KilometersDrivenUnitName: value.KilometersDrivenUnitName
                    };
                    previewResult_1.push(res[value.Type + value.UnitName]);
                }
                else {
                    res[value.Type + value.UnitName].Units += value.Units;
                    res[value.Type + value.UnitName].KilometersDriven += value.KilometersDriven;
                }
                return res;
            }, {});
            previewResult_1.reduce(function (res, value) {
                if (value.Units) {
                    previewResultWithKm.push({
                        Type: value.Type,
                        UnitName: value.UnitName,
                        Units: value.Units
                    });
                }
                if (value.KilometersDriven) {
                    var existedByType = previewResultWithKm
                        ? previewResultWithKm.find(function (km) { return km.KmType === true && km.Type === value.Type; })
                        : undefined;
                    if (existedByType) {
                        previewResultWithKm[previewResultWithKm.indexOf(existedByType)].Units += value.KilometersDriven;
                    }
                    else {
                        previewResultWithKm.push({
                            Type: value.Type,
                            Units: value.KilometersDriven,
                            UnitName: value.KilometersDrivenUnitName,
                            KmType: true
                        });
                    }
                }
                return res;
            }, {});
            previewResultWithKm = previewResultWithKm
                .sort(function (first, next) {
                GridHelper.sortByString(first, next, 'KmType');
            })
                .reverse()
                .sort(function (aPreview, bPreview) { return GridHelper.sortByStringValue(aPreview.Type, bPreview.Type); });
            this.newPreviewData = previewResultWithKm;
        }
        else {
            this.newPreviewData = [];
        }
    };
    EmployeeTimeComponent.prototype.initTimeEntryStatusDataSource = function () {
        var _this = this;
        // this.TimeEntryStatuses = [];
        if (this.TimeEntryRecords) {
            this.TimeEntryRecords.forEach(function (item) {
                if (item && item.Status) {
                    var isStatusExist = _this.TimeEntryStatuses.filter(function (timeEntryStatus) { return timeEntryStatus.Name === item.Status.Name; }).length > 0;
                    if (!isStatusExist) {
                        _this.TimeEntryStatuses.push(item.Status);
                    }
                }
            });
        }
        this.TimeEntryStatuses = this.TimeEntryStatuses.sort(function (s) {
            return s.Name;
        });
        var allStatus = { Id: -1, Name: this.AllText };
        //this.TimeEntryStatuses.unshift(allStatus);
        if (this.SelectedTimeStatus) {
            var selectedStatus = this.TimeEntryStatuses.find(function (timeEntryStatus) { return timeEntryStatus.Name === _this.SelectedTimeStatus.Name; });
            this.SelectedTimeStatusId = selectedStatus ? selectedStatus.Id : allStatus.Id;
            // this.filterTimeEntriesByStatus();
            // if (!selectedStatus) {
            //     this.filterTimeEntriesByStatus();
            // }
        }
        else {
            this.SelectedTimeStatusId = allStatus.Id;
        }
        this.filterTimeEntriesByStatus();
    };
    EmployeeTimeComponent.prototype.getTimeEntryType = function (id) {
        var timeEntryTypes = this.TimeEntryTypes.filter(function (timeEntryType) { return timeEntryType.Id === id; });
        return timeEntryTypes.length > 0 ? timeEntryTypes[0] : undefined;
    };
    EmployeeTimeComponent.prototype.validateTimeEntryType = function (timeEntryTypeId) {
        var newTimeEntryTypeId = timeEntryTypeId;
        var newTimeEntryType = this.TimeEntryTypes.filter(function (tet) { return tet.TimeEntryTypeId === newTimeEntryTypeId; });
        return newTimeEntryType.length > 0;
    };
    EmployeeTimeComponent.prototype.getNotMatchPeriodMessage = function (record) {
        if (record && record.EntryDate) {
            var outOfPeriodDate = void 0;
            var entryDate = record.EntryDate;
            var startDate = new Date(this.SelectedSalaryPeriod.StartDate.toString());
            var endDate = new Date(this.SelectedSalaryPeriod.EndDate.toString());
            endDate.setHours(23, 59, 59);
            if (entryDate < startDate || entryDate > endDate) {
                outOfPeriodDate = record.EntryDate;
            }
            else {
                outOfPeriodDate = record.EndDate;
            }
            return this.PeriodWarningText.replace('$$$', this.sessionService.toString(outOfPeriodDate));
        }
        return '';
    };
    EmployeeTimeComponent.prototype.populateData = function (userEmployment) {
        var _this = this;
        if (userEmployment) {
            this.dataService
                .SalaryBatches_GetSalaryPeriods(userEmployment.SalaryCycleId)
                .subscribe(function (salaryPeriods) {
                if (salaryPeriods.length <= 0) {
                    _this.SalaryPeriods = [];
                    _this.TimeEntryRecords = [];
                    _this.gridData = [];
                    _this.DefaultTimeEntryRecord = [];
                    _this.newPreviewData = [];
                    return;
                }
                _this.SalaryPeriods = [];
                salaryPeriods = salaryPeriods.sort(function (data1, data2) {
                    return GridHelper.sortByDateValue(data1.StartDate, data2.StartDate);
                });
                if (salaryPeriods) {
                    salaryPeriods.forEach(function (salaryPeriod, key) {
                        var startDate = _this.sessionService.toString(salaryPeriod.StartDate ? new Date(salaryPeriod.StartDate) : undefined);
                        var endDate = _this.sessionService.toString(salaryPeriod.EndDate ? new Date(salaryPeriod.EndDate) : undefined);
                        var isUserFriendlyname = _this.companypreference
                            ? _this.companypreference.find(function (model) { return model.Key === 'SalaryPeriods.FriendlyNames'; })
                            : undefined;
                        if (isUserFriendlyname && isUserFriendlyname.Value === 'true') {
                            var extendSalaryPeriod = new ExtendSalaryPeriod();
                            extendSalaryPeriod.Id = salaryPeriod.Id;
                            extendSalaryPeriod.StartDate = salaryPeriod.StartDate;
                            extendSalaryPeriod.EndDate = salaryPeriod.EndDate;
                            extendSalaryPeriod.Period = startDate + ' - ' + endDate;
                            extendSalaryPeriod.FriendlyName = ' ' + salaryPeriod.FriendlyName;
                            _this.SalaryPeriods[key] = extendSalaryPeriod;
                        }
                        else {
                            var extendSalaryPeriod = new ExtendSalaryPeriod();
                            extendSalaryPeriod.Id = salaryPeriod.Id;
                            extendSalaryPeriod.StartDate = salaryPeriod.StartDate;
                            extendSalaryPeriod.EndDate = salaryPeriod.EndDate;
                            extendSalaryPeriod.Period = startDate + ' - ' + endDate;
                            extendSalaryPeriod.FriendlyName = startDate + ' - ' + endDate;
                            _this.SalaryPeriods[key] = extendSalaryPeriod;
                        }
                    });
                }
                var roleId = _this.currentCompanyUser ? _this.currentCompanyUser.RoleId : undefined;
                _this.setDefaultSalaryPeriod(roleId, userEmployment);
            });
        }
    };
    EmployeeTimeComponent.prototype.ApproveEntries = function () {
        if (!this.isapprovaltimeentry) {
            this.isShowEmployeeTimeApproveDialog = true;
        }
        else {
            if (this.selectedSalaryPeriodId) {
                this.isShowApproveForAllDialog = true;
            }
        }
    };
    EmployeeTimeComponent.prototype.ApproveEntriesallforselectedemployee = function () {
        if (this.selectedemployeeIdinGrid && this.selectedSalaryPeriodId && this.nameEmployeeSelect) {
            this.isShowApproveForSelectDialog = true;
        }
    };
    EmployeeTimeComponent.prototype.setDefaultSalaryPeriod = function (roleId, userEmployment) {
        var _this = this;
        if (this.sessionService.currentSalaryCycleId &&
            this.sessionService.currentSalaryPeriodId &&
            this.sessionService.currentSalaryCycleId === this.privateUserEmployment.SalaryCycleId) {
            this.selectedSalaryPeriodId = this.sessionService.currentSalaryPeriodId;
            this.onSalaryPeriodChanged('');
        }
        else {
            var employeeRoleId = 20;
            if (roleId > employeeRoleId) {
                this.dataService
                    .SalaryBatches_SuggestSalaryPeriod(userEmployment.SalaryCycleId)
                    .pipe(finalize(function () {
                    _this.onSalaryPeriodChanged('');
                }))
                    .subscribe(function (salaryPeriod) {
                    if (!salaryPeriod) {
                        return;
                    }
                    _this.selectedSalaryPeriodId = salaryPeriod.Id;
                    if (_this.newRecordDefault) {
                        _this.newRecordDefault.EntryDate = new Date(salaryPeriod.StartDate.toString());
                    }
                });
            }
            else {
                var today_3 = this.staticDataService.getCurrentdate();
                today_3.setHours(0, 0, 0, 0);
                var selectedSalaryPeriod = this.SalaryPeriods.find(function (salaryPeriod) {
                    var startDate = new Date(salaryPeriod.StartDate.toString());
                    var endDate = new Date(salaryPeriod.EndDate.toString());
                    startDate.setHours(0, 0, 0, 0);
                    endDate.setHours(0, 0, 0, 0);
                    return startDate <= today_3 && endDate >= today_3;
                });
                this.selectedSalaryPeriodId = selectedSalaryPeriod.Id;
                this.onSalaryPeriodChanged('');
            }
        }
    };
    EmployeeTimeComponent.prototype.onSalaryPeriodChanged = function (value) {
        if (this.selectedSalaryPeriodId && this.selectedSalaryPeriodId !== this.tempselectedSalaryPeriodId) {
            this.sessionService.currentSalaryCycleId = this.privateUserEmployment.SalaryCycleId;
            this.sessionService.currentSalaryPeriodId = this.selectedSalaryPeriodId;
            this.tempselectedSalaryPeriodId = this.selectedSalaryPeriodId;
            this.populateTimeEntryGridDataSource(this.userEmployment.Id, this.selectedSalaryPeriodId);
        }
    };
    Object.defineProperty(EmployeeTimeComponent.prototype, "isButtonApproveTimeEntriesVible", {
        get: function () {
            if (this.TimeEntryRecord && this.TimeEntryRecord.length > 0) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTimeComponent.prototype.createUserEmployment = function () {
        if (this.createUserEmploymentClick) {
            this.createUserEmploymentClick.emit();
        }
    };
    Object.defineProperty(EmployeeTimeComponent.prototype, "isAddNew", {
        get: function () {
            return this.currentisaddnew;
        },
        set: function (value) {
            this.currentisaddnew = value;
            this.isAddNewChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTimeComponent.prototype.updateUnitsByDateAndTime = function (model) {
        var uniType = model.UnitTypeId && this.UnitTypes ? this.UnitTypes.find(function (type) { return type.Id === model.UnitTypeId; }) : undefined;
        var isHoursUnitType = uniType && uniType.Key === 'Hours';
        var isDaysUnitType = uniType && uniType.Key === 'Days';
        var start;
        if (model.StartTime) {
            start = this.staticDataService.getCurrentdate();
            start.setHours(this.sessionService.parseInt(model.StartTime.split(':')[0]), this.sessionService.parseInt(model.StartTime.split(':')[1]), 0, 0);
        }
        var end;
        if (model.EndTime) {
            end = this.staticDataService.getCurrentdate();
            end.setHours(this.sessionService.parseInt(model.EndTime.split(':')[0]), this.sessionService.parseInt(model.EndTime.split(':')[1]), 0, 0);
        }
        model.StartTime = start ? this.sessionService.toString(start, 'HH:mm:ss') : '';
        model.EndTime = end
            ? model.EndTime.indexOf('24:00') === 0
                ? '24:00:00'
                : this.sessionService.toString(end, 'HH:mm:ss')
            : '';
        if (isHoursUnitType) {
            if (start && end) {
                start.setHours(start.getHours(), start.getMinutes(), 0, 0);
                var startTime = start.getTime();
                var endTime = void 0;
                if (model.EntryDate && model.EndDate) {
                    var entryDate = new Date(model.EntryDate);
                    var endDate = new Date(model.EndDate);
                    startTime = entryDate.setHours(start.getHours(), start.getMinutes(), 0, 0);
                    endTime = endDate.setHours(end.getHours(), end.getMinutes(), 0, 0);
                }
                else {
                    end.setHours(end.getHours(), end.getMinutes(), 0, 0);
                    endTime = end;
                    endTime = start > end ? endTime.setDate(end.getDate() + 1) : endTime.getTime();
                }
                var timeDiff = endTime - startTime;
                model.Units = Math.abs(Math.floor(timeDiff / 3600) / 1000);
            }
            ////else {
            ////    this.calculateUnitByStartAndEndDate(model, isHoursUnitType);
            ////}
        }
    };
    EmployeeTimeComponent.prototype.onSelectRow = function (event) {
        if (!event || event === undefined) {
            return;
        }
        this.selectedItem = event;
        this.selectedMinDate = this.staticDataService.getCurrentdate(false, event.EntryDate);
        this.selectedEntryDate = event.EntryDate;
        if (event && event.Id && event.EmployeeName && event.UserEmploymentId) {
            this.selectedemployeeIdinGrid = event.UserEmploymentId;
            this.nameEmployeeSelect = event.EmployeeName;
        }
    };
    EmployeeTimeComponent.prototype.onGridButtonClick = function (event) {
        if (event && event.buttonAction && event.buttonAction === 'Reject') {
            if (event.dataItem) {
                if (this.isapprovaltimeentry && event.dataItem.isCanReject) {
                    this.isShowRejectDialog = true;
                    this.selectedemployeeIdinGrid = event.dataItem.Id;
                    this.preUserEmploymentId = event.dataItem.UserEmploymentId;
                }
                else {
                    this.selectedemployeeIdinGrid = event.dataItem.Id;
                    this.preUserEmploymentId = event.dataItem.UserEmploymentId;
                    this.isShowRejectDialogNonApproveTimeEntry = true;
                }
            }
        }
    };
    EmployeeTimeComponent.prototype.onCloseNotMatchPeriodMessage = function (event) {
        var _this = this;
        if (event) {
            if (this.indexRemovePeriod > -1) {
                setTimeout(function () {
                    _this.TimeEntryRecords.splice(_this.indexRemovePeriod, 1);
                    _this.indexRemovePeriod = -1;
                });
            }
        }
    };
    EmployeeTimeComponent.prototype.onRejectDialog = function (event) {
        var _this = this;
        if (event) {
            if (event === 'RejectPrimary') {
                var rejectionInfo = { Reason: this.rejectMessage };
                if (this.isapprovaltimeentry) {
                    this.dataService.TimeEntry_RejectTimeEntryRecord(this.selectedemployeeIdinGrid, rejectionInfo).subscribe(function () {
                        if (_this.isSpecialMode) {
                            _this.TimeEntryRecord = [];
                            _this.loadDataSourceForSpecialMode();
                            return;
                        }
                        _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                        _this.isSlect1020default = false;
                        _this.loadTimeEntryRecord(_this.selectedSalaryPeriodId);
                        _this.selectedemployeeIdinGrid = undefined;
                        _this.rejectMessage = undefined;
                    }, function () {
                        if (_this.isSpecialMode) {
                            _this.TimeEntryRecord = [];
                            _this.loadDataSourceForSpecialMode();
                            return;
                        }
                        _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                        _this.isSlect1020default = false;
                        _this.loadTimeEntryRecord(_this.selectedSalaryPeriodId);
                        _this.selectedemployeeIdinGrid = undefined;
                        _this.rejectMessage = undefined;
                    });
                }
                else {
                    this.dataService.TimeEntry_RejectTimeEntryRecord(this.selectedemployeeIdinGrid, rejectionInfo).subscribe(function () {
                        _this.rejectMessage = undefined;
                        _this.populateTimeEntryGridDataSource(_this.userEmployment.Id, _this.SelectedSalaryPeriod.Id);
                    }, function () {
                        _this.rejectMessage = undefined;
                    });
                }
            }
            else {
                this.rejectMessage = undefined;
                this.selectedemployeeIdinGrid = this.preUserEmploymentId;
            }
        }
    };
    EmployeeTimeComponent.prototype.onApproveForSelectDialog = function (event) {
        var _this = this;
        if (event && event === 'Approve') {
            var periodPUT = this.isSpecialMode ? 0 : this.selectedSalaryPeriodId;
            this.dataService.TimeEntry_ApprovePeriodForEmployee(this.selectedemployeeIdinGrid, periodPUT).subscribe(function () {
                _this.selectedemployeeIdinGrid = undefined;
                _this.nameEmployeeSelect = undefined;
                if (_this.isSpecialMode) {
                    _this.TimeEntryRecord = [];
                    _this.loadDataSourceForSpecialMode();
                    return;
                }
                _this.isSlect1020default = false;
                _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                _this.loadTimeEntryRecord(_this.selectedSalaryPeriodId);
            }, function () {
                _this.selectedemployeeIdinGrid = undefined;
                _this.nameEmployeeSelect = undefined;
                if (_this.isSpecialMode) {
                    _this.TimeEntryRecord = [];
                    _this.loadDataSourceForSpecialMode();
                    return;
                }
                _this.isSlect1020default = false;
                _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                _this.loadTimeEntryRecord(_this.selectedSalaryPeriodId);
            });
        }
    };
    EmployeeTimeComponent.prototype.onApproveForAllDialog = function (event) {
        var _this = this;
        if (event && event === 'Approve') {
            this.dataService.TimeEntry_ApproveEntirePeriod(this.selectedSalaryPeriodId).subscribe(function () {
                _this.isSlect1020default = false;
                _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                _this.loadTimeEntryRecord(_this.selectedSalaryPeriodId);
            }, function () {
                _this.isSlect1020default = false;
                _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                _this.loadTimeEntryRecord(_this.selectedSalaryPeriodId);
            });
        }
    };
    EmployeeTimeComponent.prototype.updateUnsavedRecords = function (dataItem) {
        var _this = this;
        var unSavedTimeEntry = this.TimeEntryRecords
            ? this.TimeEntryRecords.filter(function (entry) { return entry.UnSaved; })
            : undefined;
        if (unSavedTimeEntry && unSavedTimeEntry.length > 0) {
            unSavedTimeEntry.forEach(function (entry) {
                if (!dataItem || dataItem.idColumn !== entry.idColumn) {
                    _this.onSaveChangeGrid({ dataItem: entry }, true);
                }
            });
        }
    };
    EmployeeTimeComponent.prototype.oncellCloseEvent = function (event) {
        if (this.preventCellChangeEvent) {
            this.preventCellChangeEvent = false;
            return;
        }
        if (event && event.dataItem && event.dataItem.UnSaved && !event.sender.isEditing()) {
            this.onSaveChangeGrid({ dataItem: event.dataItem }, true);
        }
    };
    EmployeeTimeComponent.prototype.sort = function () {
        this.TimeEntryRecords = this.TimeEntryRecords.sort(function (data1, data2) {
            return GridHelper.sortDescendingByDateValue(new Date(data1.EntryDate).getTime(), new Date(data2.EntryDate).getTime());
        });
        this.gridData = filterBy(this.TimeEntryRecords, this.filter);
        this.triggerUpdate = true;
    };
    EmployeeTimeComponent.prototype.onSaveChangeGrid = function (event, force) {
        var _this = this;
        if (this.isNotChange) {
            this.isNotChange = false;
            return;
        }
        if (event && event.dataItem) {
            var startdate = event.dataItem.EntryDate
                ? new Date(new Date(event.dataItem.EntryDate).setHours(0)).getTime()
                : undefined;
            var enddate = event.dataItem.EndDate
                ? new Date(new Date(event.dataItem.EndDate).setHours(0)).getTime()
                : undefined;
            // if (startdate && enddate && startdate > enddate) {
            //   try {
            //     event.dataItem.EndDate = undefined;
            //   } catch (e) {
            //     console.log(e);
            //   }
            //   this.isShowDateErrorInput = true;
            //   return;
            // }
            if (!event.dataItem.TimeEntryTypeId || !event.dataItem.EntryDate) {
                return;
            }
            else if (!force &&
                event.dataItem.UnitTypeId === 1 &&
                !event.dataItem.EndDate &&
                (event.dataItem.Units === null || event.dataItem.Units === undefined)) {
                // Days Unit type must require either Units or EndDate.
                event.dataItem.UnSaved = true;
                return;
            }
            else if (!force &&
                event.dataItem.UnitTypeId !== 1 &&
                (event.dataItem.Units === null || event.dataItem.Units === undefined)) {
                // Not Days Unit type must require Units.
                event.dataItem.UnSaved = true;
                return;
            }
            event.dataItem.UnSaved = false;
            if (!event.dataItem.Id) {
                if (event.dataItem && event.dataItem.EntryDate) {
                    var entryDate = event.dataItem.EntryDate;
                    var modelEndDate = event.dataItem.EndDate;
                    var startDate = new Date(this.SelectedSalaryPeriod.StartDate.toString());
                    var endDate = new Date(this.SelectedSalaryPeriod.EndDate.toString());
                    endDate.setHours(23, 59, 59);
                    if (entryDate < startDate || entryDate >= endDate) {
                        this.getNotMatchPeriodMessageString = this.PeriodWarningText.replace('$$$', this.sessionService.toString(entryDate));
                        this.isgetNotMatchPeriodMessageString = true;
                        this.indexRemovePeriod = this.TimeEntryRecords.indexOf(event.dataItem, 0);
                    }
                }
                if (!event.dataItem.SalaryPeriodId) {
                    event.dataItem.SalaryPeriodId = this.selectedSalaryPeriodId;
                }
                if (!event.dataItem.UserEmploymentId) {
                    event.dataItem.UserEmploymentId = this.userEmployment.Id;
                }
                if (this.preData !== event.dataItem) {
                    this.preData = event.dataItem;
                    var uniType_1 = this.UnitTypes
                        ? this.UnitTypes.find(function (type) { return type.Id === event.dataItem.UnitTypeId; })
                        : undefined;
                    if (uniType_1 && uniType_1.Key === 'Days' && !event.dataItem.EndDate && !event.dataItem.Units) {
                        event.dataItem.Units = 1;
                    }
                    var newRecord_1 = Object.assign({}, event.dataItem);
                    newRecord_1.StartTime =
                        newRecord_1.StartTime && newRecord_1.StartTime.indexOf('24:00') === 0 ? '1.00:00:00' : newRecord_1.StartTime;
                    newRecord_1.EndTime =
                        newRecord_1.EndTime && newRecord_1.EndTime.indexOf('24:00') === 0 ? '1.00:00:00' : newRecord_1.EndTime;
                    this.dataService.TimeEntry_CreateTimeEntryRecord(newRecord_1).subscribe(function (updatedRecord) {
                        event.dataItem.Id = updatedRecord.Id;
                        event.dataItem.UnSaved = false;
                        _this.flagselect += 1;
                        if (updatedRecord.SalaryPeriodId !== _this.selectedSalaryPeriodId) {
                            _this.getNotMatchPeriodMessageString = _this.getNotMatchPeriodMessage(newRecord_1);
                            _this.isgetNotMatchPeriodMessageString = true;
                            _this.indexRemovePeriod = _this.TimeEntryRecords.indexOf(event.dataItem, 0);
                            // Prevent change event 1 time when got dialog error
                            _this.preventCellChangeEvent = true;
                        }
                        else {
                            if (!_this.DefaultTimeEntryRecord.find(function (item) { return item.Id === event.dataItem.Id; })) {
                                _this.DefaultTimeEntryRecord.push(event.dataItem);
                            }
                            if ((uniType_1.Key === 'Days' && updatedRecord.Units) || uniType_1.Key !== 'Days') {
                                event.dataItem.Units = updatedRecord.Units;
                            }
                            _this.assignToOriginalObject(event.dataItem);
                            _this.populateGridPreview();
                            _this.triggerUpdate = true;
                        }
                    }, function (error) {
                        console.error('Create request failed:' + error);
                        _this.populateTimeEntryGridDataSource(_this.userEmployment.Id, _this.SelectedSalaryPeriod.Id);
                    });
                }
            }
            else {
                var uniType = this.UnitTypes
                    ? this.UnitTypes.find(function (type) { return type.Id === event.dataItem.UnitTypeId; })
                    : undefined;
                if (uniType && uniType.Key === 'Days' && !event.dataItem.EndDate && !event.dataItem.Units) {
                    event.dataItem.Units = 1;
                }
                var updateRecord = Object.assign({}, event.dataItem);
                updateRecord.StartTime =
                    updateRecord.StartTime && updateRecord.StartTime.indexOf('24:00') === 0
                        ? '1.00:00:00'
                        : updateRecord.StartTime;
                updateRecord.EndTime =
                    updateRecord.EndTime && updateRecord.EndTime.indexOf('24:00') === 0 ? '1.00:00:00' : updateRecord.EndTime;
                var modelrequest = this.createTimeEntryRecord(updateRecord);
                this.dataService.TimeEntry_UpdateTimeEntryRecord(modelrequest).subscribe(function (updatedRecord) {
                    _this.flagselect += 1;
                    var record = updatedRecord.length > 0 ? updatedRecord[0] : updatedRecord; //// updatedRecords[0];
                    if (record && record.SalaryPeriodId !== event.dataItem.SalaryPeriodId) {
                        _this.getNotMatchPeriodMessageString = _this.getNotMatchPeriodMessage(event.dataItem);
                        _this.isgetNotMatchPeriodMessageString = true;
                        _this.populateTimeEntryGridDataSource(_this.userEmployment.Id, _this.SelectedSalaryPeriod.Id);
                    }
                    Object.assign(event.dataItem, record);
                    event.dataItem.RejectionReason = record.RejectionReason =
                        event.dataItem.Status && event.dataItem.Status.Key !== 'Rejected' ? '' : event.dataItem.RejectionReason;
                    _this.filterTimeEntriesByStatus();
                    _this.assignToOriginalObject(record);
                    _this.populateGridPreview();
                    _this.triggerUpdate = true;
                }, function (error) {
                    console.error('Update request failed:' + error);
                    var original = _this.getOriginalObject(event.dataItem.Id);
                    if (original) {
                        Object.assign(event.dataItem, original);
                        event.dataItem = _this.updateEditableFieldsAndClasses(event.dataItem);
                        _this.triggerUpdate = true;
                    }
                    // this.populateTimeEntryGridDataSource(this.userEmployment.Id, this.SelectedSalaryPeriod.Id);
                });
            }
        }
    };
    EmployeeTimeComponent.prototype.RightValue = function (value) {
        if (value) {
            return value;
        }
        return null;
    };
    EmployeeTimeComponent.prototype.createTimeEntryRecord = function (model) {
        return {
            UnitType: this.RightValue(model.UnitType),
            Status: this.RightValue(model.Status),
            EmployeeName: this.RightValue(model.EmployeeName),
            Title: this.RightValue(model.Title),
            DepartmentName: this.RightValue(model.DepartmentName),
            IsCalculated: this.RightValue(model.IsCalculated),
            CalculatedPayout: this.RightValue(model.CalculatedPayout),
            Id: this.RightValue(model.Id),
            UserEmploymentId: this.RightValue(model.UserEmploymentId),
            EntryDate: this.RightValue(model.EntryDate),
            TimeEntryTypeId: this.RightValue(model.TimeEntryTypeId),
            Units: this.RightValue(model.Units),
            UnitTypeId: this.RightValue(model.UnitTypeId),
            Description: this.RightValue(model.Description),
            SalaryPeriodId: this.RightValue(model.SalaryPeriodId),
            StatusId: this.RightValue(model.StatusId),
            ApprovedByCompanyUserId: this.RightValue(model.ApprovedByCompanyUserId),
            RejectedByCompanyUserId: this.RightValue(model.RejectedByCompanyUserId),
            Created: this.RightValue(model.Created),
            RejectionReason: this.RightValue(model.RejectionReason),
            SalaryRecordId: this.RightValue(model.SalaryRecordId),
            SalaryStatementId: this.RightValue(model.SalaryStatementId),
            KilometersDriven: this.RightValue(model.KilometersDriven),
            StartTime: this.RightValue(model.StartTime),
            EndTime: this.RightValue(model.EndTime),
            Dimension1Value: this.RightValue(model.Dimension1Value),
            Dimension2Value: this.RightValue(model.Dimension2Value),
            Dimension3Value: this.RightValue(model.Dimension3Value),
            EndDate: this.RightValue(model.EndDate),
            CalculatedFromRecordId: this.RightValue(model.CalculatedFromRecordId),
            ExternalIdentifier: this.RightValue(model.ExternalIdentifier),
            SalaryPeriod: this.RightValue(model.SalaryPeriod),
            TimeEntryType: this.RightValue(model.TimeEntryType),
            CalculatedChildRecords: this.RightValue(model.CalculatedChildRecords),
            CalculatedFromParentRecord: this.RightValue(model.CalculatedFromParentRecord)
        };
    };
    EmployeeTimeComponent.prototype.assignToOriginalObject = function (timeEntry) {
        if (!timeEntry) {
            return;
        }
        var original = this.originalTimeEntryRecords
            ? this.originalTimeEntryRecords.find(function (item) { return item.Id === timeEntry.Id; })
            : undefined;
        if (original) {
            Object.assign(original, JSON.parse(JSON.stringify(timeEntry)));
        }
        else {
            this.originalTimeEntryRecords.push(JSON.parse(JSON.stringify(timeEntry)));
        }
    };
    EmployeeTimeComponent.prototype.getOriginalObject = function (id) {
        return this.originalTimeEntryRecords
            ? this.originalTimeEntryRecords.find(function (item) { return item.Id === id; })
            : undefined;
    };
    EmployeeTimeComponent.prototype.onControlDataChange = function (event) {
        if (event && event.dataItem && event.field) {
            switch (event.field) {
                case 'TimeEntryTypeId':
                    if (event.dataItem.TimeEntryTypeId) {
                        var timeEntryType = this.TimeEntryTypes
                            ? this.TimeEntryTypes.find(function (e) { return e.TimeEntryTypeId === event.dataItem.TimeEntryTypeId; })
                            : undefined;
                        if (timeEntryType) {
                            event.dataItem.TimeEntryType = timeEntryType;
                            event.dataItem.TimeEntryType.Id = timeEntryType.TimeEntryTypeId;
                        }
                        if (this.TimeEntryTypes && this.TimeEntryTypes.length > 0 && event.dataItem.TimeEntryTypeId) {
                            var newTimeEntryType_1 = this.TimeEntryTypes.find(function (tet) { return tet.TimeEntryTypeId === event.dataItem.TimeEntryTypeId; });
                            event.dataItem.Description = newTimeEntryType_1.Description;
                            event.dataItem.UnitTypeId = newTimeEntryType_1.UnitTypeId
                                ? newTimeEntryType_1.UnitTypeId
                                : this.UnitTypes && this.UnitTypes.length > 0
                                    ? this.UnitTypes[0].Id
                                    : undefined;
                            if (newTimeEntryType_1.UnitTypeId) {
                                event.dataItem.UnitType = this.UnitTypes.find(function (type) { return type.Id === newTimeEntryType_1.UnitTypeId; });
                            }
                        }
                    }
                    ////else {
                    ////    this.populateTimeEntryGridDataSource(this.userEmployment.Id, this.SelectedSalaryPeriod.Id);
                    ////    return;
                    ////}
                    break;
                case 'EntryDate':
                    if (event && event.dataItem && !event.dataItem.EntryDate) {
                        event.dataItem.EntryDate = this.selectedEntryDate;
                        this.isNotChange = true;
                    }
                    break;
                case 'UnitTypeId':
                    var uniType = this.UnitTypes
                        ? this.UnitTypes.find(function (type) { return type.Id === event.dataItem.UnitTypeId; })
                        : undefined;
                    if (uniType && uniType.Key !== 'Hours') {
                        event.dataItem.StartTime = undefined;
                        event.dataItem.EndTime = undefined;
                    }
                    // if (uniType && uniType.Key === 'Days' && !event.dataItem.EndDate) {
                    //   event.dataItem.Units = 1;
                    // }
                    break;
                case 'EndDate':
                    var startDate = event.dataItem.EntryDate
                        ? new Date(new Date(event.dataItem.EntryDate).setHours(0)).getTime()
                        : undefined;
                    var endDate = event.dataItem.EndDate
                        ? new Date(new Date(event.dataItem.EndDate).setHours(0)).getTime()
                        : undefined;
                    if (startDate && endDate && startDate > endDate) {
                        try {
                            event.dataItem.EndDate = undefined;
                        }
                        catch (e) {
                            console.log(e);
                        }
                        this.isShowDateErrorInput = true;
                        return;
                    }
                    break;
                default:
                    break;
            }
            if (event.dataItem.EndTime === '24:00') {
                event.dataItem.EndTime = '24:00:00';
            }
            this.updateEditableFieldsAndClasses(event.dataItem);
            var startdate = new Date(event.dataItem.EntryDate).getTime();
            var enddate = new Date(event.dataItem.EndDate).getTime();
            if (startdate && enddate && startdate > enddate) {
                return;
            }
            this.updateUnitsByDateAndTime(event.dataItem);
        }
    };
    EmployeeTimeComponent.prototype.defaultRecord = function () {
        var _this = this;
        var today = this.staticDataService.getCurrentdate();
        this.flagselect += 1;
        var defaultTypeEntryType = this.defaultTimeEntryType ? this.defaultTimeEntryType : null;
        var defaultUnitType = defaultTypeEntryType
            ? this.UnitTypes
                ? this.UnitTypes.find(function (type) { return type.Id === defaultTypeEntryType.UnitTypeId; })
                : null
            : null;
        defaultUnitType = defaultUnitType ? defaultUnitType : this.UnitTypes ? this.UnitTypes[0] : null;
        this.newRecordDefault = {
            Id: null,
            isAllowEdit: true,
            TimeEntryTypeId: defaultTypeEntryType ? defaultTypeEntryType.TimeEntryTypeId : null,
            EntryDate: today,
            EndDate: null,
            Units: null,
            StartTime: null,
            EndTime: null,
            Description: null,
            DepartmentName: null,
            UnitTypeId: defaultUnitType ? defaultUnitType.Id : null,
            UnitType: defaultUnitType ? defaultUnitType : null,
            Created: today,
            SalaryPeriodId: this.selectedSalaryPeriodId,
            UserEmploymentId: this.userEmployment ? this.userEmployment.Id : null,
            Status: this.timeEntryStatus ? this.timeEntryStatus.find(function (model) { return model.Id === 10; }) : null,
            idColumn: this.flagselect,
            UnSaved: true,
            KilometersDriven: null,
            TimeEntryTypeName: defaultTypeEntryType && defaultTypeEntryType.Name ? defaultTypeEntryType.Name : '',
            TimeEntryType: defaultTypeEntryType
        };
        if (this.SalaryPeriods && this.SalaryPeriods.length > 0 && this.selectedSalaryPeriodId) {
            var salaryPeriod = this.SalaryPeriods.find(function (model) { return model.Id === _this.selectedSalaryPeriodId; });
            if (salaryPeriod) {
                if (new Date(this.newRecordDefault.EntryDate) < new Date(salaryPeriod.StartDate) ||
                    new Date(this.newRecordDefault.EntryDate) > new Date(salaryPeriod.EndDate)) {
                    this.newRecordDefault.EntryDate = salaryPeriod.StartDate;
                }
            }
        }
        if (this.isHideDimension1) {
            this.newRecordDefault.Dimension1Value = undefined;
        }
        if (this.isHideDimension2) {
            this.newRecordDefault.Dimension2Value = undefined;
        }
        if (this.isHideDimension3) {
            this.newRecordDefault.Dimension3Value = undefined;
        }
        this.newRecordDefault.TimeEntryType =
            this.TimeEntryTypes && this.TimeEntryTypes.length > 0
                ? this.TimeEntryTypes.find(function (item) { return item.TimeEntryTypeId === _this.newRecordDefault.TimeEntryTypeId; })
                : undefined;
        this.updateEditableFieldsAndClasses(this.newRecordDefault);
        return this.newRecordDefault;
    };
    EmployeeTimeComponent.prototype.onUpdateDefaultRecord = function () {
        this.defaultRecordValue = this.defaultRecord();
    };
    EmployeeTimeComponent.prototype.onAddNewRecord = function (event) {
        this.updateUnsavedRecords(event);
    };
    EmployeeTimeComponent.prototype.onDeleteRow = function (event) {
        if (event && event.dataItem) {
            if (event.dataItem.Id) {
                this.IdDelete = event.dataItem.Id;
                this.itemremmove = event.dataItem;
                this.isShowDeleteRecord = true;
            }
            else {
                var indexRemove = this.TimeEntryRecords.indexOf(event.dataItem);
                if (indexRemove !== -1) {
                    this.TimeEntryRecords.splice(indexRemove, 1);
                }
            }
        }
    };
    EmployeeTimeComponent.prototype.onDeleteRecordAction = function (event) {
        var _this = this;
        if (event && event === 'Delete') {
            this.dataService.TimeEntry_DeleteTimeEntryRecord(this.IdDelete).subscribe(function () {
                var indexRemove = _this.TimeEntryRecords.indexOf(_this.itemremmove);
                if (indexRemove !== -1) {
                    _this.TimeEntryRecords.splice(indexRemove, 1);
                }
                if (_this.TimeEntryRecords.length > 0) {
                    var index = indexRemove > _this.TimeEntryRecords.length - 1 ? 0 : indexRemove;
                    _this.kendoGrid.editCell(index, 0, _this.createFormGroup(_this.itemremmove));
                }
                _this.populateGridPreview();
            }, function (error) {
                console.error('Delete request failed:' + error);
                _this.populateTimeEntryGridDataSource(_this.userEmployment.Id, _this.SelectedSalaryPeriod.Id);
            });
        }
    };
    EmployeeTimeComponent.prototype.onEmployeeTimeApproveDialog = function (event) {
        var _this = this;
        if (event && event === 'Approve') {
            this.dataService
                .TimeEntry_ApprovePeriodForEmployee(this.userEmployment.Id, this.selectedSalaryPeriodId)
                .subscribe(function () {
                _this.isSlect1020default = false;
                _this.populateTimeEntryGridDataSource(_this.userEmployment.Id, _this.selectedSalaryPeriodId);
            });
        }
    };
    EmployeeTimeComponent.prototype.EmptyEmployeetime = function () {
        if (this.TimeEntryRecords && this.TimeEntryRecords.length > 0) {
            return '';
        }
        else {
            return 'EmptydataGrid';
        }
    };
    EmployeeTimeComponent.prototype.EmptyEmployeePreview = function () {
        if (this.newPreviewData && this.newPreviewData.length > 0) {
            return '';
        }
        else {
            return 'EmptydataGrid';
        }
    };
    EmployeeTimeComponent.prototype.EmptyEmployeetimeApproval = function () {
        if (this.TimeEntryRecord && this.TimeEntryRecord.length > 0) {
            return '';
        }
        else {
            return 'EmptydataGrid';
        }
    };
    EmployeeTimeComponent.prototype.onSalaryperiodsMobileCentricChange = function () {
        this.tempselectedSalaryPeriodId = undefined;
        this.onSalaryPeriodChanged('');
    };
    EmployeeTimeComponent.prototype.updateEditableFieldsAndClasses = function (dataItem) {
        var timeEntryType = this.TimeEntryTypes && this.TimeEntryTypes.length > 0
            ? this.TimeEntryTypes.find(function (e) { return e.TimeEntryTypeId === dataItem.TimeEntryTypeId; })
            : undefined;
        dataItem.isEditUnitType = timeEntryType ? timeEntryType.AllowEditUnitType : false;
        dataItem.ClassIsEditUnitType = dataItem.isEditUnitType ? '' : 'grid-disable-cell';
        var unitType = this.UnitTypes && this.UnitTypes.length > 0
            ? this.UnitTypes.find(function (type) { return type.Id === dataItem.UnitTypeId; })
            : undefined;
        dataItem.isEditStartAndEndTime = unitType ? unitType.Key === 'Hours' : false;
        dataItem.ClassIsEditStartAndEndTime = dataItem.isEditStartAndEndTime ? '' : 'grid-disable-cell';
        if (dataItem.isEditStartAndEndTime) {
            dataItem.isEditUnits = dataItem.StartTime && dataItem.EndTime ? false : true;
        }
        else {
            dataItem.isEditUnits = dataItem.EntryDate && dataItem.EndDate ? false : true;
        }
        dataItem.ClassIsEditUnits = dataItem.isEditUnits ? '' : 'grid-disable-cell';
        dataItem.TimeEntryTypeName = timeEntryType && timeEntryType.Name ? timeEntryType.Name : '';
    };
    EmployeeTimeComponent.prototype.onClickAppGridIcon = function (event) {
        var _this = this;
        if (event &&
            event.iconAction === 'ApproveSingleRecord' &&
            event.dataItem &&
            event.dataItem.Id &&
            event.dataItem.isCanSingleApprove) {
            this.dataService.TimeEntry_ApproveTimeEntryRecord(event.dataItem.Id).subscribe(function (model) {
                if (_this.isSpecialMode) {
                    _this.TimeEntryRecord = [];
                    _this.loadDataSourceForSpecialMode();
                    return;
                }
                _this.isSlect1020default = false;
                _this.loadTimeEntryRecord(_this.selectedSalaryPeriodId);
            });
        }
    };
    EmployeeTimeComponent.prototype.loadDataSourceForSpecialMode = function () {
        var _this = this;
        var cycleId = this.selectedSalaryCycleId ? this.selectedSalaryCycleId : 0;
        this.dataService.TimeEntry_GetOpenTimeEntryRecordsAcrossPeriods(cycleId).subscribe(function (data) {
            if (data && data.length > 0) {
                data.forEach(function (model) {
                    model.RejectionReason = model.Status && model.Status.Key !== 'Rejected' ? '' : model.RejectionReason;
                    model.iconFeildApprove = 'SuccessNonGreen';
                    // Check Edit
                    model.isCanReject =
                        (!_this.IsReadOnly || (_this.IsReadOnly && _this.isSubordinatesModel(model.UserEmploymentId))) &&
                            (model.Status &&
                                (model.Status.Key === 'Open' ||
                                    model.Status.Key === 'AdminApproved' ||
                                    model.Status.Key === 'EmployeeApproved'))
                            ? true
                            : false;
                    model.isDisbaleReject = !model.isCanReject || !_this.allowManagersToApproveTimeEntry;
                    model.isCanSingleApprove =
                        (!_this.IsReadOnly || (_this.IsReadOnly && _this.isSubordinatesModel(model.UserEmploymentId))) &&
                            (model.Status && (model.Status.Key === 'Open' || model.Status.Key === 'EmployeeApproved'))
                            ? true
                            : false;
                    model.isDisbaleSingleApprove = !model.isCanSingleApprove || !_this.allowManagersToApproveTimeEntry;
                });
                _this.TimeEntryRecord = JSON.parse(JSON.stringify(data));
                _this.DefaultTimeEntryRecord = JSON.parse(JSON.stringify(data));
            }
            else {
                _this.DefaultTimeEntryRecord = [];
            }
        });
    };
    Object.defineProperty(EmployeeTimeComponent.prototype, "isShowSalaryCycle", {
        get: function () {
            if (this.isSpecialMode && this.SalaryCycle && this.SalaryCycle.length === 1) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTimeComponent.prototype.onModeChange = function () {
        this.TimeEntryRecord = [];
        this.selectedemployeeIdinGrid = undefined;
        this.nameEmployeeSelect = undefined;
        if (this.isSpecialMode) {
            this.loadDataSourceForSpecialMode();
        }
        else {
            this.SalaryPeriod = [];
            this.SalaryCycle = [];
            this.selectedSalaryPeriodId = undefined;
            this.selectedTimeEntryStatusId = undefined;
            this.preSalaryPeriodId = undefined;
            this.loaddataspurceSalaryCycle();
        }
    };
    Object.defineProperty(EmployeeTimeComponent.prototype, "isEnableControlNomalMode", {
        get: function () {
            if (this.isSpecialMode) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTimeComponent.prototype.onKeyDown = function (event) {
        if (!this.IsReadOnly) {
            if (event.keyCode in this.keyPress) {
                this.keyPress[event.keyCode] = true;
                if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
                    if (this.isAllowEditGridOption) {
                        var defaultDataItem = this.defaultRecord;
                        this.onAddNewRecord(defaultDataItem);
                        this.setKeypress();
                        this.TimeEntryRecords.unshift(defaultDataItem);
                        this.triggerUpdate = true;
                        this.kendoGrid.editCell(0, 0, this.createFormGroup(this.itemremmove));
                        this.gridData = filterBy(this.TimeEntryRecords, this.filter);
                    }
                    else {
                        this.isDisableAddNew = true;
                    }
                }
                else if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
                    if (this.isAllowEditGridOption) {
                        if (this.selectedItem) {
                            var deleteArgs = {};
                            deleteArgs.dataItem = this.selectedItem;
                            this.onDeleteRow(deleteArgs);
                            this.setKeypress();
                            this.triggerUpdate = true;
                        }
                    }
                    else {
                        this.isDisableAddNew = true;
                    }
                }
            }
            else {
                this.setKeypress();
            }
        }
    };
    EmployeeTimeComponent.prototype.setKeypress = function () {
        this.keyPress[17] = false;
        this.keyPress[18] = false;
        this.keyPress[107] = false;
        this.keyPress[109] = false;
    };
    EmployeeTimeComponent.prototype.ApproveOpenEntries = function () {
        this.isShowApprovalOpenEntries = true;
    };
    EmployeeTimeComponent.prototype.onApprovalOpenEntries = function (event) {
        var _this = this;
        if (event && event === 'Understood') {
            this.dataService.TimeEntry_ApproveEntireCycle(this.selectedSalaryCycleId).subscribe(function () {
                _this.TimeEntryRecord = [];
                _this.loadDataSourceForSpecialMode();
            }, function () {
                _this.TimeEntryRecord = [];
                _this.loadDataSourceForSpecialMode();
            });
        }
    };
    EmployeeTimeComponent.prototype.createFormGroup = function (dataItem) {
        return new FormGroup({
            Id: new FormControl(dataItem ? dataItem.Id : undefined),
            TimeEntryTypeId: new FormControl(dataItem ? dataItem.TimeEntryTypeId : undefined),
            EntryDate: new FormControl(dataItem ? dataItem.EntryDate : undefined),
            EndDate: new FormControl(dataItem ? dataItem.EndDate : undefined),
            Units: new FormControl(dataItem ? dataItem.Units : undefined),
            StartTime: new FormControl(dataItem ? dataItem.StartTime : undefined),
            EndTime: new FormControl(dataItem ? dataItem.EndTime : undefined),
            Description: new FormControl(dataItem ? dataItem.Description : undefined),
            DepartmentName: new FormControl(dataItem ? dataItem.DepartmentName : undefined),
            UnitTypeId: new FormControl(dataItem ? dataItem.UnitTypeId : undefined),
            UnitType: new FormControl(dataItem ? dataItem.UnitType : undefined),
            Created: new FormControl(dataItem ? dataItem.Created : undefined),
            SalaryPeriodId: new FormControl(dataItem ? dataItem.SalaryPeriodId : undefined),
            UserEmploymentId: new FormControl(dataItem ? dataItem.UserEmploymentId : undefined),
            Status: new FormControl(dataItem ? dataItem.Status : undefined),
            KilometersDriven: new FormControl(dataItem ? dataItem.KilometersDriven : undefined)
        });
    };
    EmployeeTimeComponent.prototype.onFilterChange = function (filter) {
        if (filter && filter.filters && filter.filters.length > 0) {
            var filterDateTimeGrid = new FilterDateTimeGrid();
            var filtersDate = [];
            // Using FilterDateTimeGrid to filter date before filter another field
            this.filter = filterDateTimeGrid.convertFilterOperator(filter, filtersDate, 'EntryDate', 'EndDate');
            this.TimeEntryRecords = filterDateTimeGrid.doFilterDate(this.DefaultTimeEntryRecord, filtersDate, 'EntryDate', 'EndDate');
            this.TimeEntryRecords = filterBy(this.TimeEntryRecords, this.filter);
            if (filtersDate.length > 0) {
                [].push.apply(this.filter.filters, filtersDate);
            }
        }
        else {
            this.TimeEntryRecords = this.DefaultTimeEntryRecord;
            this.SelectedTimeStatusId = -1;
            this.timeStatusChanged();
        }
    };
    EmployeeTimeComponent.prototype.onApprovalFilterChange = function (approvalFilter) {
        if (approvalFilter && approvalFilter.filters && approvalFilter.filters.length > 0) {
            var filterDateTimeGrid = new FilterDateTimeGrid();
            var filtersDate = [];
            // Using FilterDateTimeGrid to filter date before filter another field
            this.approvalFilter = filterDateTimeGrid.convertFilterOperator(approvalFilter, filtersDate, 'EntryDate', 'EndDate');
            this.TimeEntryRecord = filterDateTimeGrid.doFilterDate(this.DefaultTimeEntryRecord, filtersDate, 'EntryDate', 'EndDate');
            this.TimeEntryRecord = filterBy(this.TimeEntryRecord, this.filter);
            if (filtersDate.length > 0) {
                [].push.apply(this.approvalFilter.filters, filtersDate);
            }
        }
        else {
            this.TimeEntryRecord = this.DefaultTimeEntryRecord;
            if (!this.isSpecialMode) {
                this.selectedTimeEntryStatusId = -1;
                this.onBaseTimeEntryStatusComboboxChanged(undefined);
            }
        }
    };
    return EmployeeTimeComponent;
}());
export { EmployeeTimeComponent };
