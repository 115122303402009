<app-dialog (action)="onDialogAction($event)" [overlayClose]="false" [(visible)]="visible" [minWidth]="500">
  <div class="Modal-content">
    <app-text-edit
      [label]="'CompanyHiringStatus.Template name' | translate"
      [(value)]="templateName"
      [editMode]="true"
    ></app-text-edit>
    <combobox-edit-control
      [label]="'CompanySalaryBatches.Cycle' | translate"
      [editMode]="true"
      [comboboxDataSource]="salaryCycles"
      [textField]="'Name'"
      [idField]="'Id'"
      [(value)]="salaryCycleId"
      (valueChange)="onSalaryCycleChanged()"
    ></combobox-edit-control>
    <combobox-edit-control
      [label]="'CompanyHiringStatus.BaseOnEmployee' | translate"
      [editMode]="true"
      [comboboxDataSource]="userEmployements"
      [textField]="'FullName'"
      [idField]="'UserEmploymentId'"
      [(value)]="userEmploymentId"
      [nullValueLabel]="'DropdownList.None' | translate"
    ></combobox-edit-control>
  </div>

  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Create" [disabled]="sessionService.isSubmitting" [close]="false"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="warningDialogVisible" [leadingText]="'Please enter template name'" [minWidth]="500">
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
