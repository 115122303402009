import { ChangeDetectorRef, Component, ElementRef, Input, Output } from '@angular/core';
import { ControlComponentBase } from '../../../Common/ControlComponentBase';

@Component({
  selector: 'cpr-info',
  templateUrl: './CprInfo.component.html'
})
export class CprInfoComponent extends ControlComponentBase<string> {
  @Input() public dummyIdentityNumber: boolean;
  @Input() public required = true;

  constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
  }

  protected valid(): boolean {
    if (this.required && (!this.value || this.value === '')) {
      return false;
    }

    return true;
  }
}
