<div *ngIf="!IsMobile" class="Card-inner">
  <div class="Card-title">{{ 'CompanySalaryBatches.Payslips' | translate }}</div>
</div>

<div *ngIf="!IsMobile" class="Cards Cards--flex">
  <div class="Card Form--stacked Company-payslipStacked">
    <div class="Card-inner">
      <app-text-edit
        class="FormEmlement Company-totalsPayslipsSearch"
        [label]="'CompanySalaryBatches.Search' | translate"
        [icon]="'Search'"
        [placeholder]="'CompanySalaryBatches.Search' | translate"
        [editMode]="true"
        [(value)]="searchText"
        [readonlyOnNotFocus]="true"
      ></app-text-edit>
    </div>
  </div>

  <div class="Card Form--stacked Company-payslipStacked">
    <div class="Card-inner">
      <salary-batch-payslip-icons
        [IsFinalizedBatch]="IsFinalizedBatch"
        [selectedSalaryStatement]="selectedSalaryStatement"
        (downloadPayslipClick)="downloadSalaryStatement($event)"
      >
      </salary-batch-payslip-icons>
    </div>
  </div>
</div>

<div *ngIf="!IsMobile" class="Cards Cards--flex hintPayrollBatches">
  <div class="Card-inner">{{ 'CompanySalaryBatches.HintDoubleClickViewEmployeeData' | translate }}</div>
</div>

<div *ngIf="!IsMobile" class="Cards Cards--flex">
  <div class="Card Form--stacked">
    <div class="Card-inner">
      <div class="FormElement">
        <grid
          [data]="gridData"
          [idColumn]="'Id'"
          [(selectedItem)]="selectedSalaryStatement"
          [editMode]="false"
          class="FormElement-grid Company-payslipsGrid"
          (dblClick)="onDoubleClick($event)"
          [filterable]="'menu'"
          [filter]="filter"
          (filterChange)="onFilterChange($event)"
        >
          <gridColumn
            [isFilterable]="true"
            [field]="'UserFullName'"
            [title]="'EmployeePayslip.Employee' | translate"
            [width]="200"
            [type]="'text'"
          ></gridColumn>
          <gridColumn
            [isFilterable]="true"
            [field]="'PayoutAmount'"
            [title]="'EmployeePayslip.PaidOut' | translate"
            [width]="100"
            [type]="'numeric'"
          ></gridColumn>
        </grid>
      </div>
    </div>
  </div>
  <div class="Card Form--stacked">
    <div class="Card-inner">
      <salary-batch-payslip-details
        [IsFinalizedBatch]="IsFinalizedBatch"
        [selectedSalaryStatement]="selectedSalaryStatement"
        [salaryStatementDetails]="salaryStatementDetails"
        (revertBatchCompleted)="revertBatchCompleted.emit()"
      ></salary-batch-payslip-details>
    </div>
  </div>
</div>

<div *ngIf="IsMobile" class="Cards Cards--flex">
  <div class="Card Form--stacked">
    <div class="Card-inner">
      <!-- Payslip combobox -->
      <combobox-edit-control
        [disable]="filteredSalaryStatements && filteredSalaryStatements.length === 1"
        [editMode]="true"
        [idField]="'Id'"
        [textField]="'UserFullName'"
        [comboboxDataSource]="filteredSalaryStatements"
        [(value)]="selectedSalaryStatementId"
      ></combobox-edit-control>

      <!-- Payslip download icons. -->
      <salary-batch-payslip-icons
        [IsFinalizedBatch]="IsFinalizedBatch"
        [selectedSalaryStatement]="selectedSalaryStatement"
        (downloadPayslipClick)="downloadSalaryStatement($event)"
      >
      </salary-batch-payslip-icons>

      <salary-batch-payslip-details
        [IsFinalizedBatch]="IsFinalizedBatch"
        [selectedSalaryStatement]="selectedSalaryStatement"
        [salaryStatementDetails]="salaryStatementDetails"
        (revertBatchCompleted)="revertBatchCompleted.emit()"
      ></salary-batch-payslip-details>
    </div>
  </div>
</div>

<app-dialog *ngIf="IsWeb" [(visible)]="newTabBlockedDialogVisible" [leadingText]="'Report.NewTabBlocked' | translate">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="PayslipPopup"
  [overlayClose]="false"
  [isOverlayKeydown]="true"
  [(visible)]="previewDialogVisible"
  [iframeContent]="previewContent"
>
</app-dialog>
