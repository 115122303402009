import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  ISalaryBatchEmployeeTotal,
  ISalaryBatchTotalsApiView,
  ISalaryTypeSumsByEmploymentView
} from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'salary-batch-salary-type',
  templateUrl: './SalaryBatchSalaryTypes.component.html'
})
export class SalaryBatchSalaryTypesComponent implements OnDestroy {
  @Input()
  public get SalaryBatchId(): number {
    return this.salaryBatchIdValue;
  }
  public set SalaryBatchId(value: number) {
    if (this.salaryBatchIdValue !== value) {
      this.salaryBatchIdValue = value;
      if (value) {
        this.getTotals();
      }
    }
  }

  @Input()
  public get SalaryTypeTotals(): any[] {
    return this.salaryTypeTotalsValue;
  }

  public set SalaryTypeTotals(value: any[]) {
    if (value !== this.salaryTypeTotalsValue) {
      this.salaryTypeTotalsValue = [];
      this.salaryTypeTotalsValue = value;
    }
  }

  public selectedSalaryTypeTotal: ISalaryBatchTotalsApiView;
  public salaryTypePerEmployee: ISalaryTypeSumsByEmploymentView[] = [];
  private salaryBatchIdValue: number;
  private salaryTypeTotalsValue: ISalaryBatchTotalsApiView[] = [];

  constructor(private dataService: RxDataService, private sessionService: SessionService) {}

  private getTotals(): void {
    this.salaryTypePerEmployee = [];
    this.dataService
      .SalaryBatches_GetSalaryBatchTotalsBySalaryType(this.SalaryBatchId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: ISalaryBatchTotalsApiView[]) => {
        this.SalaryTypeTotals = data;
        if (data.length > 0) {
          this.selectedSalaryTypeTotal = data[0];
          // this.onSelectedSalaryTypeTotalChange(this.selectedSalaryTypeTotal);
        }
      });
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onSelectedSalaryTypeTotalChange(data: ISalaryBatchTotalsApiView): void {
    this.dataService
      .SalaryBatches_GetSalaryTypeTotalsByEmployee(this.SalaryBatchId, data.SalaryTypeId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((salaryTypeSum: ISalaryTypeSumsByEmploymentView[]) => {
        this.salaryTypePerEmployee = salaryTypeSum;
      });
  }

  public onDoubleClick(data: ISalaryTypeSumsByEmploymentView): void {
    if (data && data.UserEmploymentId) {
      this.sessionService.currentUserEmploymentId = data.UserEmploymentId;
      this.sessionService.NavigateTo('tabs.employee.payrolldata');
    }
  }
}
