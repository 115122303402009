import { IDynamicSalaryRecordImportColumn } from '../../../Services/ApiModel';
import { ImportMappingDataItem } from './ImportMappingDataItem';
import { MappingObject } from './MappingObject';

export class ImportMappingDataSource {
  public data: { [key: string]: ImportMappingDataItem } = {};
  private orginalColumnTypeDropDownDataSource: MappingObject[];

  public setGratisalColumnsDropDownDataSourceAndAutoMap(
    dataSource: IDynamicSalaryRecordImportColumn[],
    autoMap: boolean = true
  ): void {
    for (const key in this.data) {
      if (this.data.hasOwnProperty(key)) {
        this.data[key].setGratisalColumnDropDownDataSourceAndAutoMap(dataSource, autoMap);
      }
    }
  }

  public setColumnTypeDropDownDataSource(dataSource: MappingObject[]): void {
    this.orginalColumnTypeDropDownDataSource = dataSource;
    for (const key in this.data) {
      if (this.data.hasOwnProperty(key)) {
        this.data[key].setColumnTypeDropDownDataSourceAndAutoMap(dataSource);
      }
    }
  }

  public removeColumnTypeDropDownItem(key: string, value: string): void {
    if (!this.isSingleKey(value)) {
      return;
    }

    for (const index in this.data) {
      if (this.data.hasOwnProperty(index) && index !== key) {
        const dataSource: MappingObject[] = this.data[index].columnsTypeDropDownDataSource;

        // The combobox-edit only supports bind its datasource by setting it directly.
        this.data[index].columnsTypeDropDownDataSource = this.removeValueFromComboboxDataSource(dataSource, value);
      }
    }
  }

  private removeValueFromComboboxDataSource(
    mappingObjects: MappingObject[],
    removeMatchingKey: string
  ): MappingObject[] {
    return mappingObjects.filter((item: MappingObject) => {
      return removeMatchingKey.indexOf('.') < 0
        ? item.Key !== removeMatchingKey
        : removeMatchingKey.split('.')[0] !== item.Key.split('.')[0];
    });
  }

  private isSingleKey(keyValue: string): boolean {
    if (!keyValue || keyValue === '-1') {
      return false;
    }

    let mappingObject: MappingObject;
    if (this.orginalColumnTypeDropDownDataSource) {
      mappingObject = this.orginalColumnTypeDropDownDataSource.find((item: MappingObject) => {
        return item.Key === keyValue;
      });
    }

    if (!mappingObject) {
      console.warn('Column Type value does not exist: ' + keyValue);
      return false;
    }

    return mappingObject.IsSingle;
  }

  public findSelectedColumnTypeItemById(value: string): MappingObject {
    const id: number = parseInt(value, 10);
    const dataSource: MappingObject[] = this.orginalColumnTypeDropDownDataSource;
    if (isNaN(id) || !dataSource || dataSource.length === 0) {
      return undefined;
    }

    let result: MappingObject;
    if (dataSource) {
      result = dataSource.find((val: MappingObject): boolean => {
        return val.Id === id;
      });
    }

    return result;
  }

  private findSelectedColumnTypeItemByKey(value: string): MappingObject[] {
    const key: string = value;
    const dataSource: MappingObject[] = this.orginalColumnTypeDropDownDataSource;
    if ((key !== '0' && !key) || !dataSource || dataSource.length === 0) {
      return undefined;
    }

    const result: MappingObject[] = dataSource.filter((val: MappingObject): boolean => {
      return key.indexOf('.') < 0 ? val.Key === key : key.split('.')[0] === val.Key.split('.')[0];
    });

    return result;
  }

  public addAvailableItemToComboboxDataSources(key: string, value: string): void {
    if (!this.isSingleKey(value)) {
      return;
    }

    const items: MappingObject[] = this.findSelectedColumnTypeItemByKey(value);
    for (const index in this.data) {
      if (index !== key) {
        this.addAvailableItemToComboboxDataSource(index, items);
      }
    }
  }

  private addAvailableItemToComboboxDataSource(key: string, items: MappingObject[]): void {
    const dataSource: MappingObject[] = this.data[key].columnsTypeDropDownDataSource.slice();
    if (items) {
      items.forEach((item: MappingObject) => {
        dataSource.push(item);
      });
    }
    dataSource.sort((item1: MappingObject, item2: MappingObject) => {
      return item1.SortIndex - item2.SortIndex;
    });

    this.data[key].columnsTypeDropDownDataSource = dataSource;
  }
}
