<app-dialog [overlayClose]="false" [(visible)]="isVisible" (action)="onDialogAction($event)" [width]="500">
  <div class="u-trailer" innerHTML="{{ 'NewEmployeeEmployment.Note' | translate }}"></div>
  <div class="u-trailer">
    <date-picker-control
      [e2e]="'HiringDate'"
      [label]="'NewEmployeeEmployment.Hiring date' | translate"
      [editMode]="true"
      [required]="true"
      [requiredMessage]="'EmployeeGeneral.Required' | translate"
      [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
      [(value)]="viewModel.HireDate"
      (valueChange)="onHiringDatePickerChanged($event)"
    ></date-picker-control>
  </div>
  <div class="u-trailer">
    <auto-complete-textbox
      [label]="'NewEmployeeEmployment.Title' | translate"
      [editMode]="true"
      [isRequired]="true"
      [requiredMessage]="'NewEmployeeEmployment.TitleErrorMessage' | translate"
      [autoCompleteDataSource]="jobFunctionCodeStrings"
      [allowCustom]="true"
      [(value)]="viewModel.Title"
      (valueChange)="onJobFunctionCodeChanged()"
    ></auto-complete-textbox>
  </div>
  <div class="u-trailer">
    <combobox-edit-control
      *ngIf="templates && templates.length > 0"
      [label]="'NewEmployeeEmployment.Template' | translate"
      [editMode]="true"
      [comboboxDataSource]="templates"
      [textField]="'Name'"
      [idField]="'Id'"
      [(value)]="viewModel.BasedOnTemplateId"
    ></combobox-edit-control>
  </div>
  <div class="u-trailer">
    <combobox-edit-control
      [label]="'NewEmployeeEmployment.TaxCardType' | translate"
      [editMode]="true"
      [comboboxDataSource]="taxCardTypes"
      [textField]="'Name'"
      [idField]="'Id'"
      [(value)]="viewModel.PreferredTaxCardTypeId"
    ></combobox-edit-control>
  </div>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action [disabled]="sessionService.isSubmitting" type="Create"></app-dialog-action>
</app-dialog>
