import { EventEmitter } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { FilterDateTimeGrid } from '../../../Common/FilterDateTimeGrid';
import { SalaryBatchStatusEnum } from '../../../Model/Enum';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SalaryBatchViewModel } from '../SalaryBatchViewModel';
var SalaryBatchesComponent = /** @class */ (function () {
    function SalaryBatchesComponent(sessionService) {
        this.sessionService = sessionService;
        this.salaryCyclesValue = [];
        this.salaryBatchesValue = [];
        this.selectionChanged = new EventEmitter();
        this.editSalaryBatch = new EventEmitter();
        this.showErrors = new EventEmitter();
        this.recalculateDraft = new EventEmitter();
        this.newSalaryBatch = new EventEmitter();
        this.finalizeDraft = new EventEmitter();
        this.gridFilterData = filterBy(this.gridData, this.filter);
    }
    Object.defineProperty(SalaryBatchesComponent.prototype, "salaryCycles", {
        get: function () {
            return this.salaryCyclesValue;
        },
        set: function (value) {
            if (this.salaryCyclesValue !== value) {
                this.salaryCyclesValue = value;
                //this.salaryCycleId = this.salaryCycles.length > 0 ? this.salaryCycles[0].Id : undefined;
                this.filterData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "salaryBatches", {
        get: function () {
            return this.salaryBatchesValue;
        },
        set: function (value) {
            if ( /*value && value.length > 0 && */this.salaryBatchesValue !== value) {
                this.salaryBatchesValue = value;
                this.filterData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "selectedBatch", {
        get: function () {
            return this.salaryBatchValue;
        },
        set: function (value) {
            if (value && this.salaryBatchValue !== value) {
                this.salaryBatchValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "salaryCycleId", {
        get: function () {
            return this.salaryCycleIdValue;
        },
        set: function (value) {
            if (this.salaryCycleIdValue !== value) {
                this.salaryCycleIdValue = value;
                this.filterData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "isReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "isAddNewBatchButtonVisible", {
        get: function () {
            return this.sessionService.feature.AllowEditCompanyData(this.sessionService.currentState) && !this.isReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "isDraft", {
        get: function () {
            return this.selectedBatch && this.selectedBatch.StatusId === SalaryBatchStatusEnum.Draft;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "isLocked", {
        get: function () {
            return this.selectedBatch && this.selectedBatch.StatusId === SalaryBatchStatusEnum.ForApproval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "isPreliminary", {
        get: function () {
            return this.selectedBatch && this.selectedBatch.StatusId === SalaryBatchStatusEnum.ForApproval;
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchesComponent.prototype.onSalaryBatchSelectionChanged = function (data) {
        this.selectionChanged.emit(data);
    };
    SalaryBatchesComponent.prototype.onDoubleClick = function (dataItem) {
        if (dataItem) {
            this.selectedBatch = dataItem;
            this.selectionChanged.emit(dataItem);
            this.editSalaryBatch.emit(dataItem);
        }
    };
    SalaryBatchesComponent.prototype.onGridIconClick = function (event) {
        switch (event.iconAction) {
            case 'ShowErrors':
                if (event.dataItem.HasErrors || event.dataItem.HasWarnings) {
                    this.selectionChanged.emit(event.dataItem);
                    this.showErrors.emit(event.dataItem);
                }
                break;
            case 'Edit':
                this.selectedBatch = event.dataItem;
                this.selectionChanged.emit(event.dataItem);
                this.editSalaryBatch.emit(event.dataItem);
                break;
        }
    };
    SalaryBatchesComponent.prototype.truncatedOrWrapped = function () {
        return !this.sessionService.role.IsReadOnly ? 'truncated' : 'wrapped';
    };
    SalaryBatchesComponent.prototype.filterData = function () {
        var _this = this;
        if (this.salaryCycleId) {
            this.gridData = this.salaryBatches.filter(function (s) { return s.SalaryCycleId === _this.salaryCycleId; });
        }
        else {
            this.gridData = this.salaryBatches;
        }
        this.gridFilterData = filterBy(this.gridData, this.filter);
        if (this.gridData && this.gridData.length > 0) {
            if (!this.selectedBatch) {
                this.selectedBatch = this.gridData[0];
            }
            else {
                if (!this.gridData.some(function (d) { return d.Id === _this.selectedBatch.Id; })) {
                    this.selectedBatch = this.gridData[0];
                }
            }
        }
    };
    SalaryBatchesComponent.prototype.onFilterChange = function (filter) {
        if (filter && filter.filters && filter.filters.length > 0) {
            this.filter = filter;
            var filterDateTimeGrid = new FilterDateTimeGrid();
            var filtersDate = [];
            // Using FilterDateTimeGrid to filter date before filter another field
            this.filter = filterDateTimeGrid.convertFilterOperator(filter, filtersDate, 'PeriodStartDate', 'PeriodEndDate', 'PayoutDate');
            this.gridFilterData = filterDateTimeGrid.doFilterDate(this.gridData, filtersDate, 'PeriodStartDate', 'PeriodEndDate', 'PayoutDate');
            this.gridFilterData = filterBy(this.gridFilterData, this.filter);
            if (filtersDate.length > 0) {
                [].push.apply(this.filter.filters, filtersDate);
            }
        }
        else {
            this.gridFilterData = this.gridData;
        }
    };
    return SalaryBatchesComponent;
}());
export { SalaryBatchesComponent };
