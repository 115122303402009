<div class="Cards Cards--flex">
  <div class="Card Form--stacked">
    <div class="Card-inner">
      <div class="FormElement">
        <div class="grid-header Company-salaryTotalsGrid">
          <grid
            (selectedItemChange)="onSelectedSalaryTypeTotalChange($event)"
            [data]="SalaryTypeTotals"
            [idColumn]="'SalaryTypeId'"
            [(selectedItem)]="selectedSalaryTypeTotal"
            [editMode]="false"
          >
            <gridColumn
              [field]="'Description'"
              [width]="200"
              [title]="'CompanySalaryBatches.TotalsGridField Type' | translate"
              [type]="'text'"
            ></gridColumn>
            <gridColumn
              [field]="'AmountSum'"
              [width]="100"
              [title]="'CompanySalaryBatches.TotalsGridField Amount' | translate"
              [type]="'numeric'"
            ></gridColumn>
          </grid>
        </div>
      </div>
    </div>
  </div>

  <div class="Card Form--stacked">
    <div class="Card-inner">
      <div class="FormElement">
        <div class="FormElement-grid Company-salaryTotalsGrid Custoomlinewqual">
          <grid [data]="salaryTypePerEmployee" [editMode]="false" (dblClick)="onDoubleClick($event)">
            <gridColumn
              [field]="'FullName'"
              [width]="120"
              [title]="'CompanySalaryBatches.ReimbursementsGridField Employee' | translate"
              [type]="'text'"
            ></gridColumn>
            <gridColumn
              [field]="'AmountSum'"
              [width]="80"
              [title]="'CompanySalaryBatches.ReimbursementsGridField Amount' | translate"
              [type]="'numeric'"
            ></gridColumn>
            <gridColumn
              [field]="'SalaryTypeName'"
              [width]="100"
              [title]="'CompanySalaryBatches.ReimbursementsGridField Description' | translate"
              [type]="'text'"
            ></gridColumn>
          </grid>
        </div>
      </div>
    </div>
  </div>
</div>
