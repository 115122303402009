import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { FilterDateTimeGrid } from '../../../Common/FilterDateTimeGrid';
import { SalaryBatchStatusEnum } from '../../../Model/Enum';
import { ISalaryCycle } from '../../../Services/ApiModel';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SalaryBatchViewModel } from '../SalaryBatchViewModel';

@Component({
  selector: 'salary-batches',
  templateUrl: './SalaryBatches.component.html'
})
export class SalaryBatchesComponent {
  private salaryCyclesValue: ISalaryCycle[] = [];
  @Input()
  public get salaryCycles(): ISalaryCycle[] {
    return this.salaryCyclesValue;
  }
  public set salaryCycles(value: ISalaryCycle[]) {
    if (this.salaryCyclesValue !== value) {
      this.salaryCyclesValue = value;
      //this.salaryCycleId = this.salaryCycles.length > 0 ? this.salaryCycles[0].Id : undefined;
      this.filterData();
    }
  }

  private salaryBatchesValue: SalaryBatchViewModel[] = [];
  @Input()
  public get salaryBatches(): SalaryBatchViewModel[] {
    return this.salaryBatchesValue;
  }
  public set salaryBatches(value: SalaryBatchViewModel[]) {
    if (/*value && value.length > 0 && */ this.salaryBatchesValue !== value) {
      this.salaryBatchesValue = value;
      this.filterData();
    }
  }

  private salaryBatchValue: SalaryBatchViewModel;
  @Input()
  public get selectedBatch(): SalaryBatchViewModel {
    return this.salaryBatchValue;
  }
  public set selectedBatch(value: SalaryBatchViewModel) {
    if (value && this.salaryBatchValue !== value) {
      this.salaryBatchValue = value;
    }
  }

  @Output() public selectionChanged: EventEmitter<SalaryBatchViewModel> = new EventEmitter<SalaryBatchViewModel>();
  @Output() public editSalaryBatch: EventEmitter<SalaryBatchViewModel> = new EventEmitter<SalaryBatchViewModel>();
  @Output() public showErrors: EventEmitter<SalaryBatchViewModel> = new EventEmitter<SalaryBatchViewModel>();
  @Output() public recalculateDraft: EventEmitter<void> = new EventEmitter<void>();
  @Output() public newSalaryBatch: EventEmitter<void> = new EventEmitter<void>();
  @Output() public finalizeDraft: EventEmitter<void> = new EventEmitter<void>();

  public gridData: SalaryBatchViewModel[];
  // public selectedBatch: SalaryBatchViewModel;

  private salaryCycleIdValue: number;
  public get salaryCycleId(): number {
    return this.salaryCycleIdValue;
  }
  public set salaryCycleId(value: number) {
    if (this.salaryCycleIdValue !== value) {
      this.salaryCycleIdValue = value;
      this.filterData();
    }
  }

  public get isReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }
  public get isAddNewBatchButtonVisible(): boolean {
    return this.sessionService.feature.AllowEditCompanyData(this.sessionService.currentState) && !this.isReadOnly;
  }
  public get isDraft(): boolean {
    return this.selectedBatch && this.selectedBatch.StatusId === SalaryBatchStatusEnum.Draft;
  }
  public get isLocked(): boolean {
    return this.selectedBatch && this.selectedBatch.StatusId === SalaryBatchStatusEnum.ForApproval;
  }
  public get isPreliminary(): boolean {
    return this.selectedBatch && this.selectedBatch.StatusId === SalaryBatchStatusEnum.ForApproval;
  }

  constructor(public sessionService: SessionService) {}

  public onSalaryBatchSelectionChanged(data: SalaryBatchViewModel): void {
    this.selectionChanged.emit(data);
  }

  public onDoubleClick(dataItem: SalaryBatchViewModel): void {
    if (dataItem) {
      this.selectedBatch = dataItem;
      this.selectionChanged.emit(dataItem);
      this.editSalaryBatch.emit(dataItem);
    }
  }

  public onGridIconClick(event: { dataItem: SalaryBatchViewModel; iconAction: string }): void {
    switch (event.iconAction) {
      case 'ShowErrors':
        if (event.dataItem.HasErrors || event.dataItem.HasWarnings) {
          this.selectionChanged.emit(event.dataItem);
          this.showErrors.emit(event.dataItem);
        }

        break;
      case 'Edit':
        this.selectedBatch = event.dataItem;
        this.selectionChanged.emit(event.dataItem);
        this.editSalaryBatch.emit(event.dataItem);
        break;
    }
  }

  public truncatedOrWrapped(): string {
    return !this.sessionService.role.IsReadOnly ? 'truncated' : 'wrapped';
  }

  private filterData(): void {
    if (this.salaryCycleId) {
      this.gridData = this.salaryBatches.filter((s: SalaryBatchViewModel) => s.SalaryCycleId === this.salaryCycleId);
    } else {
      this.gridData = this.salaryBatches;
    }

    this.gridFilterData = filterBy(this.gridData, this.filter);
    if (this.gridData && this.gridData.length > 0) {
      if (!this.selectedBatch) {
        this.selectedBatch = this.gridData[0];
      } else {
        if (!this.gridData.some((d: SalaryBatchViewModel) => d.Id === this.selectedBatch.Id)) {
          this.selectedBatch = this.gridData[0];
        }
      }
    }
  }

  public filter: CompositeFilterDescriptor;
  public gridFilterData: any[] = filterBy(this.gridData, this.filter);
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    if (filter && filter.filters && filter.filters.length > 0) {
      this.filter = filter;

      const filterDateTimeGrid = new FilterDateTimeGrid();
      const filtersDate: any = [];
      // Using FilterDateTimeGrid to filter date before filter another field
      this.filter = filterDateTimeGrid.convertFilterOperator(
        filter,
        filtersDate,
        'PeriodStartDate',
        'PeriodEndDate',
        'PayoutDate'
      );
      this.gridFilterData = filterDateTimeGrid.doFilterDate(
        this.gridData,
        filtersDate,
        'PeriodStartDate',
        'PeriodEndDate',
        'PayoutDate'
      );
      this.gridFilterData = filterBy(this.gridFilterData, this.filter);
      if (filtersDate.length > 0) {
        [].push.apply(this.filter.filters, filtersDate);
      }
    } else {
      this.gridFilterData = this.gridData;
    }
  }
}
