<img class="Avatar-bg" *ngIf="noAvatar && !isFemale" [src]="'data:image/jpeg;base64,' + maleAvatar" />
<img class="Avatar-bg" *ngIf="noAvatar && isFemale" [src]="'data:image/jpeg;base64,' + femaleAvatar" />
<app-picture-edit
  [label]="'Employee.Change picture' | translate"
  [isAvatar]="true"
  [changeText]="'Employee.Change picture' | translate"
  [takePhotoText]="'Employee.Take photo' | translate"
  [editMode]="editMode"
  [imageSource]="avatar"
  [(avatarChange)]="avatarChange"
  (change)="onAvatarChanged($event)"
>
</app-picture-edit>
