import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import { Department } from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';
var HiringDataComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HiringDataComponent, _super);
    function HiringDataComponent(dataService, sessionService, staticDataService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.hasDepartmentEnabled = false;
        _this.AddDepartmentVisible = false;
        _this.ModelDepartment = new Department();
        _this.childIsValid = Array(10).fill(true);
        _this.childIsDirty = Array(10).fill(false);
        return _this;
    }
    Object.defineProperty(HiringDataComponent.prototype, "userEmployment", {
        get: function () {
            if (!this.userEmploymentValue) {
                this.userEmploymentValue = {};
            }
            return this.userEmploymentValue;
        },
        set: function (value) {
            if (this.userEmploymentValue !== value) {
                this.userEmploymentValue = value;
                this.loadEmploymentTemplates();
                this.hasEmployment = value && value.Id ? true : false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HiringDataComponent.prototype, "IsDisableCreateDepartment", {
        get: function () {
            return this.sessionService.role.IsReadOnly || this.sessionService.role.IsSalaryAdmin;
        },
        enumerable: true,
        configurable: true
    });
    HiringDataComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.SalaryCycle.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.cycles = data); });
        this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.departments = data;
        });
        this.loadCompanyUsers();
        this.getCompanyContacts();
    };
    HiringDataComponent.prototype.onCreateNewDepartment = function (event) {
        var _this = this;
        if (event && event === 'Create') {
            this.ModelDepartment.IsActive = true;
            this.dataService.Companies_CreateDepartment(this.ModelDepartment).subscribe(function (data) {
                _this.staticDataService.loadDepartments();
                _this.onCreateNewDepartmentSuccess(data.Id);
            });
        }
        this.ModelDepartment = new Department();
    };
    HiringDataComponent.prototype.onCreateNewDepartmentSuccess = function (newDepartmentId) {
        var _this = this;
        this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.departments = data;
            _this.userEmployment.DepartmentId = newDepartmentId;
        });
    };
    Object.defineProperty(HiringDataComponent.prototype, "showDepartmentControl", {
        get: function () {
            return this.departments && this.departments.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HiringDataComponent.prototype, "shouldHideTerminationDate", {
        get: function () {
            return (!this.editMode &&
                (this.userEmployment && !this.userEmployment.ExpireDate) &&
                this.sessionService.role.IsEmployeeRole);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HiringDataComponent.prototype, "showTemplates", {
        get: function () {
            return this.sessionService.feature.ShowEmployeeEmploymentTemplate;
        },
        enumerable: true,
        configurable: true
    });
    HiringDataComponent.prototype.onComboboxEmployeeDetailDepartmentSelectionChanged = function () {
        var departmentId = this.userEmployment.DepartmentId;
        this.selectDefaultManager(departmentId);
        this.onChange();
    };
    HiringDataComponent.prototype.getCompanyContacts = function () {
        var _this = this;
        this.staticDataService.companyUsers
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (companyUsers) {
            _this.companyContacts = companyUsers;
        });
    };
    HiringDataComponent.prototype.loadCompanyUsers = function () {
        var _this = this;
        this.staticDataService.companyUsers
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) { return (_this.managers = data); });
    };
    HiringDataComponent.prototype.loadEmploymentTemplates = function () {
        var _this = this;
        if (!this.showTemplates) {
            return;
        }
        this.dataService
            .EmploymentTemplates_GetEmploymentTemplates(true)
            .subscribe(function (templates) {
            _this.templates = templates;
        });
    };
    HiringDataComponent.prototype.selectDefaultManager = function (departmentId) {
        var department;
        if (this.departments) {
            department = this.departments.find(function (dpm) {
                return dpm.Id === departmentId;
            });
        }
        if (department) {
            this.userEmployment.ManagerCompanyUserId = department.ManagerCompanyUserId
                ? department.ManagerCompanyUserId
                : undefined;
        }
    };
    return HiringDataComponent;
}(FormComponentBase));
export { HiringDataComponent };
