<div class="FormElement" [ngClass]="{ 'has-fixedAddon': hasFeedback, 'FormElement-ViewMode': !editMode }">
  <ng-template #iconTooltipTemplate class="FormElement-required">
    <span [innerHTML]="tooltipContent | translate"></span>
  </ng-template>

  <span
    kendoTooltip
    [tooltipTemplate]="iconTooltipTemplate"
    [title]="''"
    class="FormElement-label"
    *ngIf="tooltipContent && label"
  >
    <abbr *ngIf="required" class="FormElement-required" title="required">*</abbr> {{ label }}:
    <span *ngIf="addonVisible && addonIcon && !addonSize && !isValueIcon" class="infoIcon">
      <app-icon
        [icon]="addonIcon"
        [tooltip]="addonTooltip"
        [link]="addonLink"
        (click)="onAddonButtonClick()"
      ></app-icon>
    </span>
    <span *ngIf="addonVisible && addonIcon && addonSize && !isValueIcon" class="infoIcon">
      <app-icon
        [icon]="addonIcon"
        [tooltip]="addonTooltip"
        [size]="addonSize"
        [link]="addonLink"
        (click)="onAddonButtonClick()"
      ></app-icon>
    </span>
  </span>

  <span class="FormElement-label" *ngIf="!tooltipContent && label">
    <abbr *ngIf="required" class="FormElement-required" title="required">*</abbr> {{ label }}:
    <span *ngIf="addonVisible && addonIcon && !addonSize && !isValueIcon" class="infoIcon">
      <app-icon
        [icon]="addonIcon"
        [tooltip]="addonTooltip"
        [link]="addonLink"
        (click)="onAddonButtonClick()"
      ></app-icon>
    </span>
    <span *ngIf="addonVisible && addonIcon && addonSize && !isValueIcon" class="infoIcon">
      <app-icon
        [icon]="addonIcon"
        [tooltip]="addonTooltip"
        [size]="addonSize"
        [link]="addonLink"
        (click)="onAddonButtonClick()"
      ></app-icon>
    </span>
  </span>

  <div class="FormElement-control ComboboxEditA4Custom">
    <div class="FormElement-static {{ viewClass }}" *ngIf="!editMode && !specialShowNavigate">
      {{ currentSelectedValueText }}
    </div>

    <button
      *ngIf="(editMode && showNavigationButton && !disable) || specialShowNavigate"
      class="FormElement-addon"
      type="button"
      [ngClass]="{ disable: previousButtonDisable }"
      [disabled]="previousButtonDisable"
      (click)="onPeviousButtonClick($event)"
    >
      <app-icon [icon]="'ChevronLeft'" [size]="'xsmall'"></app-icon>
    </button>

    <div class="FormElement-static specialShowNavigate" *ngIf="!editMode && specialShowNavigate">
      {{ currentSelectedValueText }}
    </div>

    <kendo-combobox
      #kendoComboboxControl
      id="{{ componentId }}"
      class="FormElement-select"
      [popupSettings]="{ width: 'auto' }"
      [placeholder]="placeholder"
      [disabled]="disable || !comboboxDataSource || comboboxDataSource.length === 0"
      [ngClass]="{ disable: disable }"
      *ngIf="!isMobile && editMode"
      [suggest]="true"
      [valuePrimitive]="true"
      [textField]="componentTextField"
      [valueField]="componentIdField"
      [filterable]="true"
      (filterChange)="onSmallDatahandleFilter($event)"
      [data]="displayDataSource"
      [(ngModel)]="value"
      (selectionChange)="onComboboxSelectionChanged($event)"
      (blur)="onComboboxBlur()"
      (focus)="onfocus()"
      (close)="onClose()"
      (click)="onComboboxClicked($event)"
      (keydown)="onkeydown($event)"
    >
      <ng-template kendoComboBoxItemTemplate let-dataItem>
        <div style="width:100%;" kendoTooltip title="{{ tooltipField ? dataItem[tooltipField] : '' }}">
          <div>
            {{ dataItem.Text }}
          </div>
        </div>
      </ng-template>
    </kendo-combobox>

    <kendo-dropdownlist
      #kendoDropdownListControl
      id="{{ componentId }}"
      class="FormElement-select"
      *ngIf="isMobile && editMode"
      [disabled]="disable || !comboboxDataSource || comboboxDataSource.length === 0"
      [ngClass]="{ disable: disable }"
      [valuePrimitive]="true"
      [textField]="componentTextField"
      [valueField]="componentIdField"
      [data]="displayDataSource"
      [(ngModel)]="value"
      [filterable]="checkFilterable"
      (filterChange)="onComboBoxFiltering($event)"
      (close)="onClose()"
      [popupSettings]="{ popupClass: isMobile ? 'ComboboxDropDown' : '' }"
      (focus)="onfocus()"
      (open)="onOpen()"
      (blur)="onComboboxBlur()"
      (click)="addClassAndEventForDropdown()"
    >
    </kendo-dropdownlist>

    <button
      *ngIf="(editMode && showNavigationButton && !disable) || specialShowNavigate"
      class="FormElement-addon"
      type="button"
      id="nextButton"
      [ngClass]="{ disable: nextButtonDisable }"
      [disabled]="nextButtonDisable"
      (click)="onNextButtonClick($event)"
    >
      <app-icon [icon]="'ChevronRight'" [size]="'xsmall'"></app-icon>
    </button>
  </div>

  <span *ngIf="addonVisible && addonIcon && !addonSize && isValueIcon" class="infoIcon">
    <app-icon [icon]="addonIcon" [tooltip]="addonTooltip" [link]="addonLink" (click)="onAddonButtonClick()"></app-icon>
  </span>
  <span *ngIf="addonVisible && addonIcon && addonSize && isValueIcon" class="infoIcon">
    <app-icon
      [icon]="addonIcon"
      [tooltip]="addonTooltip"
      [size]="addonSize"
      [link]="addonLink"
      (click)="onAddonButtonClick()"
    ></app-icon>
  </span>

  <div class="FormElement-feedback" *ngIf="hasFeedback">{{ feedbackKey | translate }}</div>
</div>

<app-dialog>
  <div [innerHTML]="changeConfirmMessage"></div>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Proceed"></app-dialog-action>
</app-dialog>
