import { environment } from '../../../../environments/environment';
import { Global } from '../../../Common/Global';
import { IModuleCompanyView } from '../../ApiModel';
import { Role } from './Role';

export class RoleFeatures {
  constructor(private role: Role) {}

  public modules: IModuleCompanyView[] = [];

  // Global
  public get AllowEditGlobal(): boolean {
    return this.role.IsReadOnly;
  }

  // Self Service
  public get SelfServiceTabEnabled(): boolean {
    return !environment.production;
  }

  // Account
  public get AccountTabEnabled(): boolean {
    return !environment.production;
  }

  // Accountant
  public get AccountantTabEnabled(): boolean {
    return this.role.IsAccountant;
  }

  // Employee
  public get ShowEmployeeOverview(): boolean {
    return !environment.production;
  }

  public AllowEditUserEmployment(currentState: string): boolean {
    return (
      this.role.IsSalaryAdminAndHigher &&
      (currentState === 'tabs.employee.hiringstatus' ||
        currentState === 'tabs.employee' ||
        currentState === 'tabs.employee.general' ||
        currentState === 'tabs.selfservice' ||
        currentState === 'tabs.selfservice.general')
    );
  }

  public get ShowEmployeeList(): boolean {
    return this.role.IsSalaryAdminAndHigher || this.role.IsReadOnly || this.role.EmployeeWithRoleAsManager;
  }

  public get ShowEmployeeEmploymentTemplate(): boolean {
    return this.role.IsSalaryAdminAndHigher || this.role.IsReadOnly;
  }

  public get EmployeeTimeTabEnabled(): boolean {
    return (
      this.role.IsSalaryAdminAndHigher ||
      this.role.IsEmployeeRole ||
      this.role.IsReadOnly ||
      this.role.EmployeeWithRoleAsManager
    );
  }

  public get EmployeePayrollDataTabEnabled(): boolean {
    return this.role.IsSalaryAdminAndHigher || this.role.IsReadOnly;
  }

  // Company
  public get CompanyTabEnabled(): boolean {
    return this.role.IsSalaryAdminAndHigher || this.role.IsReadOnly || this.role.EmployeeWithRoleAsManager;
  }

  public get EmployeeTabEnabled(): boolean {
    return this.role.IsSalaryAdminAndHigher || this.role.IsReadOnly || this.role.EmployeeWithRoleAsManager;
  }

  public get CompanyTimeTabEnabled(): boolean {
    return !environment.production;
  }

  public AllowEditCompanyData(currentState: string): boolean {
    if (currentState === 'tabs.company.modules') {
      return false;
    }

    return this.role.IsSalaryAdminAndHigher;
  }

  public get ReportViewEnabled(): boolean {
    return true;
  }

  public hasModuleId(moduleId: number): boolean {
    return this.modules.some((m: IModuleCompanyView) => m.ModuleId === moduleId && m.IsEnabled);
  }

  public get IsDemoVersion(): boolean {
    // Pause GS-942, and start GS-1149
    return Global.IsDemo;
  }
}
