import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../../../Common/Global';
import { TimeEntryType } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { TimeEntryTypeView } from './TimeEntryTypeView';
var TimeEntryTypesNGXComponent = /** @class */ (function () {
    function TimeEntryTypesNGXComponent(dataService, staticDataService, translateService, sessionService, settingService) {
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.translateService = translateService;
        this.sessionService = sessionService;
        this.settingService = settingService;
        this.unitTypes = Global.UNIT_TYPES;
        this.salaryTypes = [];
        this.gridData = [];
        this.translationDialogVisible = false;
        this.newCustomTimeEntryDialogVisible = false;
        this.timeEntryTypeConfigurationDialogVisible = false;
        this.createFromScratch = new TimeEntryType();
        this.hasTimeEntryModule = false;
        this.numberOption = { min: 0, step: 1, spinners: false, decimals: 0, format: 'n0' };
        this.ngUnsubscribe = new Subject();
        this.translatetooltipsalarytype = '';
        this.gridFilterData = filterBy(this.gridData, this.filter);
        this.translationServiceTerms = ['CompanyTimeEntryTypes.SalaryTypeDisableToolTip'];
    }
    Object.defineProperty(TimeEntryTypesNGXComponent.prototype, "isLanguageModuleEnable", {
        get: function () {
            return this.sessionService.feature.hasModuleId(5);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypesNGXComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypesNGXComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    TimeEntryTypesNGXComponent.prototype.enableTranslation = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService.get(_this.translationServiceTerms).subscribe(function (translations) {
                _this.translatetooltipsalarytype = translations['CompanyTimeEntryTypes.SalaryTypeDisableToolTip'];
            });
        });
    };
    TimeEntryTypesNGXComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataService.SalaryTypes_GetSalaryTypeViews().subscribe(function (data) {
            _this.salaryTypes = data;
            _this.staticDataService.moduleCompanyView
                .pipe(takeUntil(_this.ngUnsubscribe))
                .subscribe(function () { return _this.loadTimeEntryTypes(); });
        });
        this.enableTranslation();
        this.dataService.Integrations_GetAllExternalAccounts().subscribe(function (externalAccounts) {
            _this.externalAccounts = externalAccounts;
            if (_this.externalAccounts && _this.externalAccounts.length > 0) {
                _this.externalAccounts.forEach(function (model) {
                    model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
                });
            }
        });
        //this.loadTimeEntryTypes();
        this.createFromScratch.Name = '<Create From Scratch>';
        this.createFromScratch.Id = -1;
        this.dataService.TimeEntryTypes_GetDefaultTimeEntryTypes().subscribe(function (data) {
            _this.TimeEntryTypes = data;
            _this.TimeEntryTypes.unshift(_this.createFromScratch);
        });
        this.settingService.showModuleMessage('company.timeentrytypes');
        if (this.isMobile) {
            this.sessionService.isShowHugeFeaturesAlert = true;
        }
    };
    TimeEntryTypesNGXComponent.prototype.saveChanges = function (data) {
        var _this = this;
        data.dataItem.ExternalReference = this.getExteralReferenceCode(data.dataItem.ExternalReference, true);
        var timeEntryType = data.dataItem;
        timeEntryType.ExternalReference = this.getExteralReferenceCode(timeEntryType.ExternalReference);
        this.dataService.TimeEntryTypes_UpdateTimeEntryType(timeEntryType).subscribe(function (response) {
            response.ExternalReference = _this.getExteralReferenceCode(response.ExternalReference, true);
            _this.processGridItemObject(response);
            Object.assign(data.dataItem, new TimeEntryTypeView(response, _this.isLanguageModuleEnable));
        }, function (error) {
            _this.loadTimeEntryTypes();
        });
    };
    TimeEntryTypesNGXComponent.prototype.addNewTimeEntryType = function (timeEntryType) {
        var newRecord = new TimeEntryTypeView(timeEntryType, this.isLanguageModuleEnable);
        this.processGridItemObject(newRecord);
        this.gridData.push(newRecord);
        this.selectedTimeEntryType = newRecord;
        this.gridFilterData = filterBy(this.gridData, this.filter);
    };
    TimeEntryTypesNGXComponent.prototype.onCellClick = function (event) {
        if (['Name', 'Description', 'DefaultPayslipText'].includes(event.field) && this.isLanguageModuleEnable) {
            this.selectedTimeEntryType = event.dataItem;
            this.translationDialogVisible = true;
        }
        if (event.field === 'configurationIcon') {
            this.timeEntryTypeConfigurationDialogVisible = true;
        }
    };
    TimeEntryTypesNGXComponent.prototype.loadTimeEntryTypes = function (event) {
        var _this = this;
        if (event) {
            this.selectedTimeEntryType.TimeEntryTypeTranslations = event;
        }
        this.currentLangId = Global.SESSION.SignOnToken.LanguageId;
        this.gridData = [];
        this.gridFilterData = [];
        if (this.isLanguageModuleEnable) {
            this.dataService.TimeEntryTypes_GetRawTimeEntryTypesTranslated().subscribe(function (timeEntryTypes) {
                var filterTimeEntryTypes = timeEntryTypes.filter(function (type) { return !type.BaseTimeEntryType || type.BaseTimeEntryType.IsActive; });
                var activeTimeEntryTypes = filterTimeEntryTypes.filter(function (type) { return type.IsActive !== false; });
                var inActiveTimeEntryType = filterTimeEntryTypes.filter(function (type) { return type.IsActive === false; });
                var sortedActiveTimeEntryTypes = activeTimeEntryTypes.sort(function (a, b) { return _this.sortCustomTimeEntryTypes(a, b); });
                var sortedInActiveTimeEntryTypes = inActiveTimeEntryType.sort(function (a, b) { return _this.sortCustomTimeEntryTypes(a, b); });
                var sortedTimeEntryTypes = sortedActiveTimeEntryTypes.concat(sortedInActiveTimeEntryTypes);
                _this.gridData = sortedTimeEntryTypes.map(function (d) { return new TimeEntryTypeView(d, _this.isLanguageModuleEnable); });
                _this.gridData.forEach(function (model) {
                    _this.processGridItemObject(model);
                });
                _this.convertExternalRefCodeToFriendlyName();
                _this.gridFilterData = filterBy(_this.gridData, _this.filter);
            });
        }
        else {
            this.dataService.TimeEntryTypes_GetRawTimeEntryTypes().subscribe(function (timeEntryTypes) {
                var filterTimeEntryTypes = timeEntryTypes.filter(function (type) { return !type.BaseTimeEntryType || type.BaseTimeEntryType.IsActive; });
                var activeTimeEntryTypes = filterTimeEntryTypes.filter(function (type) { return type.IsActive !== false; });
                var inActiveTimeEntryType = filterTimeEntryTypes.filter(function (type) { return type.IsActive === false; });
                var sortedActiveTimeEntryTypes = activeTimeEntryTypes.sort(function (a, b) { return _this.sortCustomTimeEntryTypes(a, b); });
                var sortedInActiveTimeEntryTypes = inActiveTimeEntryType.sort(function (a, b) { return _this.sortCustomTimeEntryTypes(a, b); });
                var sortedTimeEntryTypes = sortedActiveTimeEntryTypes.concat(sortedInActiveTimeEntryTypes);
                _this.gridData = sortedTimeEntryTypes.map(function (d) { return new TimeEntryTypeView(d, _this.isLanguageModuleEnable); });
                _this.gridData.forEach(function (model) {
                    _this.processGridItemObject(model);
                });
                _this.convertExternalRefCodeToFriendlyName();
                _this.gridFilterData = filterBy(_this.gridData, _this.filter);
            });
        }
    };
    TimeEntryTypesNGXComponent.prototype.processGridItemObject = function (model) {
        var existingTimeEntryType = this.gridData && model ? this.gridData.find(function (s) { return s.Id === model.Id; }) : undefined;
        model.TimeEntryTypeTranslations = model.TimeEntryTypeTranslations
            ? model.TimeEntryTypeTranslations
            : existingTimeEntryType
                ? existingTimeEntryType.TimeEntryTypeTranslations
                : undefined;
        model.BaseTimeEntryType = model.BaseTimeEntryType
            ? model.BaseTimeEntryType
            : existingTimeEntryType
                ? existingTimeEntryType.BaseTimeEntryType
                : undefined;
        if (model.TimeEntryTypeConfigurations) {
            if (model.TimeEntryTypeConfigurations.length > 0) {
                model.customSalaryTypeClass = 'grid-disable-cell';
                model.editSalaryType = false;
                model.ToolTipSalaryType = this.translatetooltipsalarytype;
            }
            else {
                model.customSalaryTypeClass = '';
                model.editSalaryType = !this.IsReadOnly;
                model.ToolTipSalaryType = '';
            }
        }
        else {
            model.customSalaryTypeClass = '';
            model.editSalaryType = !this.IsReadOnly;
            model.ToolTipSalaryType = '';
        }
    };
    TimeEntryTypesNGXComponent.prototype.convertExternalRefCodeToFriendlyName = function () {
        var _this = this;
        if (this.gridData && this.gridData.length > 0 && this.externalAccounts && this.externalAccounts.length > 0) {
            this.gridData.forEach(function (salaryType) {
                salaryType.ExternalReference = _this.getExteralReferenceCode(salaryType.ExternalReference, true);
            });
        }
    };
    TimeEntryTypesNGXComponent.prototype.sortCustomTimeEntryTypes = function (a, b) {
        var aOrder = a.SortOrder || a.SortOrder === 0
            ? a.SortOrder
            : a.BaseTimeEntryType && (a.BaseTimeEntryType.SortOrder || a.BaseTimeEntryType.SortOrder === 0)
                ? a.BaseTimeEntryType.SortOrder
                : undefined;
        var bOrder = b.SortOrder || b.SortOrder === 0
            ? b.SortOrder
            : b.BaseTimeEntryType && (b.BaseTimeEntryType.SortOrder || b.BaseTimeEntryType.SortOrder === 0)
                ? b.BaseTimeEntryType.SortOrder
                : undefined;
        if ((aOrder === undefined && bOrder === undefined) || (aOrder === 0 && bOrder === 0)) {
            return a.Id - b.Id;
        }
        else if (aOrder === undefined && bOrder !== undefined) {
            return -1;
        }
        else if (aOrder !== undefined && bOrder === undefined) {
            return 1;
        }
        return aOrder - bOrder;
    };
    TimeEntryTypesNGXComponent.prototype.getExteralReferenceCode = function (refCode, fullName) {
        if (!refCode) {
            return null;
        }
        var externalRefCode = this.externalAccounts
            ? this.externalAccounts.find(function (item) {
                return refCode === item.AccountNumber || refCode === item.FriendlyName;
            })
            : null;
        if (externalRefCode) {
            return !fullName ? externalRefCode.AccountNumber : externalRefCode.FriendlyName;
        }
        return refCode;
    };
    TimeEntryTypesNGXComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridFilterData = filterBy(this.gridData, filter);
    };
    return TimeEntryTypesNGXComponent;
}());
export { TimeEntryTypesNGXComponent };
