import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../../Common/Global';
import { IGetSalaryRecordsOverview_Result, ISalaryType } from '../../Services/ApiModel';
import { RxDataService } from '../../Services/RxDataService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';

import { OverviewDisplayModeEnum } from '../../Model/Enum';

@Component({
  selector: 'company-payroll-salarytype-config',
  templateUrl: './SalaryTypeConfigurationDialog.component.html'
})
export class SalaryTypeConfigurationDialogComponent implements OnInit, OnDestroy {
  @Output() public changeConfigurationCompleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);

      this.onSalaryTypeChange(this.selectedSalaryType ? this.selectedSalaryType.Id : undefined);
    }
  }

  @Input() public get selectedSalaryType(): ISalaryType {
    return this.selectedSalaryTypeValue;
  }
  public set selectedSalaryType(value: ISalaryType) {
    this.selectedSalaryTypeValue = value;
    this.onSalaryTypeChange(value ? value.Id : undefined);
  }

  @Input() public get salaryTypes(): ISalaryType[] {
    return this.salaryTypesDataSource;
  }
  public set salaryTypes(value: ISalaryType[]) {
    this.salaryTypesDataSource = value;
    this.onSalaryTypeChange(this.selectedSalaryType ? this.selectedSalaryType.Id : undefined);
  }

  @Input() public maxIndex: number;

  public replaceSalaryTypeId: any;
  public replaceSalaryType: any = {};
  public overviewDisplayModes: any[] = [];

  private visibleValue: boolean;
  private selectedSalaryTypeValue: ISalaryType;
  private salaryTypesDataSource: ISalaryType[];

  constructor(private dataService: RxDataService, private staticDataService: StaticDataService) {}

  public ngOnInit(): void {
    this.staticDataService.SalaryRecordsOverviewDisplayMode.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any[]) => (this.overviewDisplayModes = data)
    );
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onSalaryTypeChange(salaryTypeId: number): void {
    this.replaceSalaryTypeId = salaryTypeId;
    this.replaceSalaryType =
      this.salaryTypes && salaryTypeId ? this.salaryTypes.find((s: ISalaryType) => s.Id === salaryTypeId) : {};
    if (this.replaceSalaryType) {
      this.replaceSalaryType.OverviewDisplayMode = this.replaceSalaryType.OverviewDisplayMode
        ? this.replaceSalaryType.OverviewDisplayMode
        : OverviewDisplayModeEnum.AmountOnly;
    }
  }

  public onDialogAction(action: string): void {
    if (
      action === 'Ok' &&
      this.salaryTypes &&
      this.replaceSalaryTypeId &&
      this.replaceSalaryType &&
      (!this.selectedSalaryType || this.replaceSalaryTypeId !== this.selectedSalaryType.Id)
    ) {
      this.replaceSalaryType.OverviewIndex = this.selectedSalaryType
        ? this.selectedSalaryType.OverviewIndex
        : this.maxIndex;
      if (!this.selectedSalaryType) {
        this.dataService.SalaryTypes_UpdateSalaryType(this.replaceSalaryType).subscribe(
          () => {
            this.onChangeConfigurationCompleted();
          },
          () => {
            this.onChangeConfigurationCompleted();
          }
        );
      } else {
        // this.selectedSalaryType.OverviewIndex = undefined;
        this.dataService
          .SalaryTypes_ConfigureOverviewBehavior(
            this.replaceSalaryTypeId,
            this.selectedSalaryType.OverviewIndex,
            this.replaceSalaryType.OverviewDisplayMode
          )
          .subscribe((data: any): void => {
            this.onChangeConfigurationCompleted();
          });
        // observableForkJoin(
        //     this.dataService.SalaryTypes_UpdateSalaryType(this.selectedSalaryType),
        //     this.dataService.SalaryTypes_UpdateSalaryType(this.replaceSalaryType)
        // ).subscribe(
        //     () => { this.onChangeConfigurationCompleted(); },
        //     () => { this.onChangeConfigurationCompleted(); }
        // );
      }
    } else {
      this.visible = false;
    }
  }

  private onChangeConfigurationCompleted(): void {
    this.changeConfigurationCompleted.emit();
    this.visible = false;
  }
}
