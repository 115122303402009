/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./DialogAction.component";
import * as i2 from "../../Services/SharedServices/Session/SessionService";
var styles_DialogActionComponent = [];
var RenderType_DialogActionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogActionComponent, data: {} });
export { RenderType_DialogActionComponent as RenderType_DialogActionComponent };
export function View_DialogActionComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_DialogActionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dialog-action", [], null, null, null, View_DialogActionComponent_0, RenderType_DialogActionComponent)), i0.ɵdid(1, 180224, null, 0, i1.DialogActionComponent, [i2.SessionService], null, null)], null, null); }
var DialogActionComponentNgFactory = i0.ɵccf("app-dialog-action", i1.DialogActionComponent, View_DialogActionComponent_Host_0, { action: "action", label: "label", class: "class", close: "close", disabled: "disabled", requiredModuleId: "requiredModuleId", tooltip: "tooltip", type: "type" }, {}, []);
export { DialogActionComponentNgFactory as DialogActionComponentNgFactory };
