import { Component } from '@angular/core';

@Component({
  selector: 'icon-definitions',
  templateUrl: './IconDefinitions.component.html'
})
export class IconDefinitionsComponent {
  public static icons: { [key: string]: { class: string; svg: string } } = {
    ['AddUser']: { class: '', svg: 'adduser' },
    ['Add']: { class: '', svg: 'add' },
    ['AppStore']: { class: '', svg: 'appstore' },
    ['Bars']: { class: '', svg: 'bars' },
    ['Calendar']: { class: '', svg: 'calendar' },
    ['CalendarCheck']: { class: '', svg: 'calendarcheck' },
    ['CaretDown']: { class: '', svg: 'caret-down' },
    ['CaretUp']: { class: '', svg: 'caret-up' },
    ['Check']: { class: '', svg: 'check' },
    ['ChevronDown-small']: { class: 'Icon', svg: 'chevrondown' },
    ['ChevronDown']: { class: '', svg: 'chevrondown' },
    ['ChevronLeft']: { class: '', svg: 'chevronleft' },
    ['ChevronRight']: { class: '', svg: 'chevronright' },
    ['ChevronUp']: { class: '', svg: 'chevronup' },
    ['Circle']: { class: '', svg: 'circle-outline' },
    ['Clear']: { class: '', svg: 'clear' },
    ['Clock']: { class: '', svg: 'clock' },
    ['Close']: { class: '', svg: 'close' },
    ['Cog']: { class: '', svg: 'cog' },
    ['Columns']: { class: '', svg: 'columns' },
    ['Diamond']: { class: '', svg: 'diamond' },
    ['Dollar']: { class: '', svg: 'dollar' },
    ['Download']: { class: '', svg: 'download' },
    ['Edit']: { class: '', svg: 'edit' },
    ['EditForm']: { class: '', svg: 'edit-form' },
    ['Error']: { class: 'Icon--error', svg: 'error' },
    ['ErrorExclamation']: { class: 'Icon--error', svg: 'error-exclamation' },
    ['ExternalLink']: { class: '', svg: 'ext-link' },
    ['File']: { class: '', svg: 'file' },
    ['Help']: { class: '', svg: 'question' },
    ['Industry']: { class: '', svg: 'industry' },
    ['Info']: { class: '', svg: 'info' },
    ['LevelDown']: { class: '', svg: 'leveldown' },
    ['LevelUp']: { class: '', svg: 'levelup' },
    ['People']: { class: '', svg: 'people' },
    ['Plus']: { class: '', svg: 'iplus' },
    ['Preview']: { class: '', svg: 'preview' },
    ['Question']: { class: '', svg: 'question-circle' },
    ['Search']: { class: '', svg: 'search' },
    ['ShareBox']: { class: '', svg: 'share-box' },
    ['Success']: { class: 'Icon--success', svg: 'success' },
    ['SuccessWhite']: { class: 'Icon--success--white', svg: 'success' },
    ['SuccessGray']: { class: 'Icon--grey', svg: 'success' },
    ['SuccessNonGreen']: { class: '', svg: 'success' },
    ['Table']: { class: '', svg: 'table' },
    ['ThreeDots']: { class: '', svg: 'threedots' },
    ['Times']: { class: '', svg: 'times' },
    ['User']: { class: '', svg: 'user' },
    ['Warning']: { class: 'Icon--warning', svg: 'warning' },
    ['html-outline']: { class: 'Icon--medium', svg: 'html-outline' },
    ['html']: { class: 'Icon--medium', svg: 'html' },
    ['pdf']: { class: 'Icon--medium', svg: 'pdf' },
    ['warning']: { class: 'Icon--warning', svg: 'warning' },
    ['file-download']: { class: '', svg: 'file-download' },
    ['sign-in']: { class: '', svg: 'sign-in' },
    ['circleplus']: { class: '', svg: 'circleplus' },
    ['circleplusWhite']: { class: 'Icon--circleplus--white', svg: 'circleplus' }
  };
}
