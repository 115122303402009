import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../../Common/Constants';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import { Global } from '../../../../Common/Global';
import { ICity, ICompanyUser, ICountry, IMunicipality } from '../../../../Services/ApiModel';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'contact-info',
  templateUrl: './ContactInfo.component.html'
})
export class ContactInfoComponent extends FormComponentBase implements OnInit {
  @Output() companyUserChange: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();

  @Input()
  public get companyUser(): ICompanyUser {
    if (!this.companyUserValue) {
      this.companyUserValue = {
        Address: {}
      } as any;
    }

    return this.companyUserValue;
  }

  public set companyUser(value: ICompanyUser) {
    this.companyUserValue = value;
    this.companyUserChange.emit(value);
  }

  public countries: ICountry[];
  public municipality: IMunicipality[];
  private companyUserValue: ICompanyUser;
  private cities: ICity[];

  constructor(private staticDataService: StaticDataService) {
    super();
    this.childIsValid = Array(10).fill(true);
    this.childIsDirty = Array(10).fill(false);
  }

  public ngOnInit(): void {
    this.staticDataService.City.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ICity[]) => (this.cities = data));
    this.staticDataService.Country.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ICountry[]) => (this.countries = data)
    );
    this.staticDataService.Municipality.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (municipalities: IMunicipality[]) => {
        this.municipality =
          municipalities && municipalities.length
            ? municipalities.filter((muni: any) => {
                return Global.SESSION && muni.CountryId === Global.SESSION.CurrentCountryId;
              })
            : undefined;
      }
    );
  }

  public get IsGreenlandCompanyAnduserEmloyment(): boolean {
    return (
      Global.COMPANY &&
      Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID &&
      this.companyUser &&
      this.companyUser.UserEmployments &&
      this.companyUser.UserEmployments.length > 0
    );
  }

  public onPostalCodeChanged(): void {
    if (this.companyUser.Address.CountryId !== 1) {
      return;
    }

    this.autoFillCity(this.companyUser.Address.PostalCode);
  }

  public autoFillCity(postalCode: string): void {
    if (!postalCode) {
      return;
    }

    let city: any;
    if (this.cities) {
      city = this.cities.find((c: any): boolean => {
        return (
          c.CountryId === this.companyUser.Address.CountryId && c.PostalCode && postalCode === c.PostalCode.toString()
        );
      });
    }

    if (!city) {
      city = this.cities.find((c: any): boolean => {
        return c.PostalCode && postalCode === c.PostalCode.toString();
      });
    }

    if (city) {
      this.companyUser.Address.City = city.Name;
      if (city.CountryId !== this.companyUser.Address.CountryId) {
        this.companyUser.Address.CountryId = city.CountryId;
      }
    }
  }

  public onComboboxControlChanged(): void {
    if (
      this.companyUser.Address.CountryId !== Constants.GREENLAND_COUNTRY_ID &&
      this.companyUser.AddressId !== Constants.DENMARK_COUNTRY_ID
    ) {
      this.companyUser.MunicipalityId = 8;
    }

    this.onChange();
  }

  public onDataChange(): void {
    this.onChange();
  }

  public onBlur(): void {}
}
