import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ComboBoxComponent, DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { ListHelper } from '../Common/ListHelper';
import { UniqueId } from '../Common/UniqueId';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
var ComboboxEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ComboboxEditComponent, _super);
    function ComboboxEditComponent(sessionService, elementRef, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.sessionService = sessionService;
        _this.viewClass = '';
        _this.checkFilterable = true;
        _this.noToggle = false;
        _this.resetDropdownOnNullDS = false;
        _this.keepDataSourceOrder = true;
        _this.disable = false;
        _this.specialShowNavigate = false;
        _this.addonClick = new EventEmitter();
        _this.blur = new EventEmitter();
        _this.confirmDialogVisible = false;
        _this.componentIdField = 'Id';
        _this.componentTextField = 'Text';
        _this.ngUnsubscribe = new Subject();
        _this.isBigdataSource = false;
        _this.isConvertTime = true;
        _this.nullIdValue = -9999;
        _this.componentId = 'comboboxEditComponentId' + UniqueId();
        return _this;
    }
    Object.defineProperty(ComboboxEditComponent.prototype, "nullValueLabel", {
        get: function () {
            return this.nullValueLabelValue;
        },
        set: function (value) {
            this.nullValueLabelValue = value;
            this.addNullItem();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ComboboxEditComponent.prototype, "comboboxDataSource", {
        get: function () {
            return this.dataSource;
        },
        set: function (value) {
            if (value && this.dataSource !== value) {
                this.dataSource = JSON.parse(JSON.stringify(value));
                this.addNullItem();
            }
        },
        enumerable: true,
        configurable: true
    });
    ComboboxEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (value) {
            if (value) {
                _this.selectNullValue();
            }
        });
    };
    ComboboxEditComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        if (this.kendoComboboxControl) {
            setTimeout(function () {
                _this.kendoComboboxControl.toggle(false);
            });
        }
        if (this.kendoDropdownListControl) {
            setTimeout(function () {
                _this.kendoDropdownListControl.toggle(false);
            });
        }
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    ComboboxEditComponent.prototype.confirmDialogAction = function (action) {
        if (action !== 'Proceed') {
            this.isDirty = false;
        }
    };
    ComboboxEditComponent.prototype.onAddonButtonClick = function () {
        if (this.addonClick) {
            this.addonClick.emit();
        }
    };
    Object.defineProperty(ComboboxEditComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ComboboxEditComponent.prototype, "currentSelectedValueText", {
        get: function () {
            var _this = this;
            var currentSelectedValue;
            var value = this.value || this.value === 0 ? this.value : this.nullIdValue;
            if (this.translatedComboBoxDataSource && (value || value === 0)) {
                currentSelectedValue = this.translatedComboBoxDataSource.find(function (data) {
                    return ((data[_this.componentIdField] || data[_this.componentIdField] === 0) &&
                        data[_this.componentIdField].toString() === value.toString());
                });
            }
            var result = '';
            if (this.specialShowNavigate &&
                currentSelectedValue &&
                currentSelectedValue.hasOwnProperty(this.componentTextField)) {
                result = currentSelectedValue[this.componentTextField];
            }
            else if (currentSelectedValue && currentSelectedValue.hasOwnProperty('Text')) {
                result = currentSelectedValue['Text'];
            }
            return result;
        },
        enumerable: true,
        configurable: true
    });
    ComboboxEditComponent.prototype.onPeviousButtonClick = function (e) {
        if (!this.translatedComboBoxDataSource ||
            (this.translatedComboBoxDataSource && this.translatedComboBoxDataSource.length === 0)) {
            return;
        }
        var currentIndex = this.getSelectedComboboxIndex();
        if (currentIndex === 0) {
            return;
        }
        this.value = this.translatedComboBoxDataSource[currentIndex - 1][this.componentIdField];
        if (this.specialShowNavigate) {
            this.onEmitValueChange(this.value);
        }
        this.enablePreviousAndNextButtons();
    };
    ComboboxEditComponent.prototype.onNextButtonClick = function (e) {
        if (!this.translatedComboBoxDataSource ||
            (this.translatedComboBoxDataSource && this.translatedComboBoxDataSource.length === 0)) {
            return;
        }
        var currentIndex = this.getSelectedComboboxIndex();
        if (currentIndex === this.translatedComboBoxDataSource.length - 1) {
            return;
        }
        this.value = this.translatedComboBoxDataSource[currentIndex + 1][this.componentIdField];
        if (this.specialShowNavigate) {
            this.onEmitValueChange(this.value);
        }
        this.enablePreviousAndNextButtons();
    };
    ComboboxEditComponent.prototype.onSmallDatahandleFilter = function (event) {
        if (!this.isBigdataSource) {
            if (event && event.length >= 1) {
                this.onComboBoxFiltering(event);
            }
            else {
                this.displayDataSourceValue = this.translatedComboBoxDataSource;
            }
        }
    };
    ComboboxEditComponent.prototype.onComboBoxFiltering = function (value) {
        var _this = this;
        if (value && value.length >= 1 && this.translatedComboBoxDataSource) {
            this.isFiltering = true;
            this.comboboxFilteringSelectedId = undefined;
            if (this.filterFields && this.filterFields.length > 0) {
                this.displayDataSourceValue = this.translatedComboBoxDataSource.filter(function (item) {
                    var result;
                    _this.filterFields.forEach(function (field) {
                        result = result
                            ? result
                            : field && item[field] && item[field].toLowerCase().indexOf(value.toString().toLowerCase()) !== -1;
                    });
                    return result;
                });
                if (this.displayDataSourceValue.length === 1 &&
                    this.kendoComboboxControl &&
                    !this.kendoComboboxControl.text &&
                    this.isBigdataSource) {
                    this.kendoComboboxControl.wrapper.getElementsByClassName('k-input')[0].value = this.displayDataSourceValue[0][this.textField];
                    this.kendoComboboxControl.wrapper.getElementsByClassName('k-input')[0].select();
                }
            }
            else {
                this.displayDataSourceValue = this.translatedComboBoxDataSource.filter(function (item) {
                    return (item[_this.componentTextField] &&
                        item[_this.componentTextField].toLowerCase().indexOf(value.toString().toLowerCase()) !== -1);
                });
            }
        }
        else {
            this.isFiltering = false;
            this.displayDataSourceValue = this.translatedComboBoxDataSource;
        }
    };
    ComboboxEditComponent.prototype.onComboboxSelectionChanged = function (dataItem) {
        this.comboboxFilteringSelectedId = dataItem ? dataItem[this.componentIdField] : dataItem;
    };
    ComboboxEditComponent.prototype.onkeydown = function (event) {
        var _this = this;
        this.preEnvent = event;
        if (this.isBigdataSource && event.key === 'ArrowUp' && event.key === 'ArrowDown') {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown' && event.keyCode !== 9 && this.isBigdataSource) {
            this.inputEvent.subscribe(function (x) {
                if (x && x.length > 0) {
                    if (_this.preEnvent.key !== 'ArrowUp' && _this.preEnvent.key !== 'ArrowDown') {
                        _this.onComboBoxFiltering(x);
                    }
                }
                else {
                    _this.displayDataSourceValue = _this.translatedComboBoxDataSource;
                }
            });
        }
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            //this.value = this.valueOnFocus;
            if (this.kendoComboboxControl && this.kendoComboboxControl.isOpen) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
        else if (event.keyCode === 9 && this.isGrid) {
            this.dataSource.forEach(function (data) {
                if (_this.comboboxFilteringSelectedId === data.TimeEntryTypeId) {
                    _this.value = data.TimeEntryTypeId;
                    return;
                }
            });
        }
        else if (event.keyCode === 13) {
            this.selectFirstFilteringItem();
        }
    };
    ComboboxEditComponent.prototype.onComboboxClicked = function (event) {
        event.stopPropagation();
    };
    ComboboxEditComponent.prototype.onComboboxBlur = function () {
        var _this = this;
        this.selectFirstFilteringItem();
        this.displayDataSourceValue = this.translatedComboBoxDataSource;
        if ((!this.isBigdataSource && this.value === undefined) ||
            (this.value === this.nullIdValue && !this.nullValueLabel)) {
            setTimeout(function () {
                var filtervalueText = _this.displayDataSource.find(function (model) { return model[_this.componentIdField] === _this.valueOnFocus; });
                if (_this.kendoComboboxControl &&
                    _this.kendoComboboxControl.wrapper.getElementsByClassName('k-input')[0]) {
                    _this.kendoComboboxControl.wrapper.getElementsByClassName('k-input')[0].value =
                        filtervalueText && filtervalueText[_this.componentTextField] ? filtervalueText[_this.componentTextField] : '';
                    _this.value = _this.nullIdValue;
                    setTimeout(function () {
                        _this.value =
                            filtervalueText && filtervalueText[_this.componentIdField]
                                ? filtervalueText[_this.componentIdField]
                                : _this.nullIdValue;
                        _this.blur.emit();
                    });
                }
            });
        }
        else {
            this.blur.emit();
        }
    };
    ComboboxEditComponent.prototype.selectFirstFilteringItem = function () {
        var _this = this;
        if (this.isFiltering) {
            this.isFiltering = false;
            if (this.displayDataSource &&
                this.displayDataSource.length > 0 &&
                this.displayDataSource.length !== this.translatedComboBoxDataSource.length) {
                var selectedItem = this.comboboxFilteringSelectedId
                    ? this.displayDataSource.find(function (item) { return item[_this.componentIdField] === _this.comboboxFilteringSelectedId; })
                    : undefined;
                this.value = selectedItem
                    ? selectedItem[this.componentIdField]
                    : this.displayDataSource[0][this.componentIdField];
                if (this.value === this.valueOnFocus) {
                    setTimeout(function () {
                        var filtervalueText = _this.displayDataSource.find(function (model) { return model[_this.componentIdField] === _this.valueOnFocus; });
                        if (_this.kendoComboboxControl &&
                            _this.kendoComboboxControl.wrapper.getElementsByClassName('k-input')[0]) {
                            _this.kendoComboboxControl.wrapper.getElementsByClassName('k-input')[0].value =
                                filtervalueText && filtervalueText[_this.componentTextField]
                                    ? filtervalueText[_this.componentTextField]
                                    : '';
                            _this.value = _this.nullIdValue;
                            setTimeout(function () {
                                _this.value =
                                    filtervalueText && filtervalueText[_this.componentIdField]
                                        ? filtervalueText[_this.componentIdField]
                                        : _this.nullIdValue;
                            });
                        }
                    });
                }
            }
            else {
                setTimeout(function () {
                    var filtervalueText = _this.displayDataSource.find(function (model) { return model[_this.componentIdField] === _this.valueOnFocus; });
                    if (_this.kendoComboboxControl &&
                        _this.kendoComboboxControl.wrapper.getElementsByClassName('k-input')[0]) {
                        _this.kendoComboboxControl.wrapper.getElementsByClassName('k-input')[0].value =
                            filtervalueText && filtervalueText[_this.componentTextField]
                                ? filtervalueText[_this.componentTextField]
                                : '';
                        _this.value = _this.nullIdValue;
                        setTimeout(function () {
                            _this.value =
                                filtervalueText && filtervalueText[_this.componentIdField]
                                    ? filtervalueText[_this.componentIdField]
                                    : _this.nullIdValue;
                        });
                    }
                });
            }
        }
    };
    Object.defineProperty(ComboboxEditComponent.prototype, "displayDataSource", {
        get: function () {
            if (!this.displayDataSourceValue) {
                return this.translatedComboBoxDataSource;
            }
            return this.displayDataSourceValue;
        },
        enumerable: true,
        configurable: true
    });
    ComboboxEditComponent.prototype.getSelectedComboboxIndex = function () {
        var _this = this;
        return this.translatedComboBoxDataSource.findIndex(function (sourceItem) {
            return sourceItem[_this.componentIdField] === _this.value;
        });
    };
    ComboboxEditComponent.prototype.enablePreviousAndNextButtons = function () {
        var currentIndex = this.getSelectedComboboxIndex();
        this.nextButtonDisable = currentIndex === this.translatedComboBoxDataSource.length - 1;
        this.previousButtonDisable = currentIndex === 0;
    };
    ComboboxEditComponent.prototype.selectNullValue = function () {
        if ((this.value === undefined || this.value === 0 || this.value === null) && this.nullValueLabel) {
            this.value = this.nullIdValue;
        }
        if (this.resetDropdownOnNullDS &&
            this.kendoDropdownListControl &&
            (!this.displayDataSource || this.displayDataSourceValue.length === 0)) {
            this.kendoDropdownListControl.dataItem = undefined;
            this.kendoDropdownListControl.writeValue(undefined);
        }
    };
    ComboboxEditComponent.prototype.addNullItem = function () {
        if (this.nullValueLabel && this.nullValueLabel.length > 0) {
            var noneItem = { Id: undefined, Text: this.nullValueLabel };
            noneItem[this.idField] = this.nullIdValue;
            noneItem[this.componentIdField] = this.nullIdValue;
            noneItem[this.textField] = this.nullValueLabel;
            noneItem[this.componentTextField] = this.nullValueLabel;
            this.NoneValueItem = noneItem;
            this.translatedComboBoxDataSource = ListHelper.mapDataSource(this.sessionService, this.dataSource, this.idField, this.textField, noneItem, this.keepDataSourceOrder ? false : true, this.isConvertTime, this.filterFields, this.tooltipField);
        }
        else {
            this.translatedComboBoxDataSource = ListHelper.mapDataSource(this.sessionService, this.dataSource, this.idField, this.textField, undefined, false, this.isConvertTime, this.filterFields, this.tooltipField);
        }
        this.displayDataSourceValue = this.translatedComboBoxDataSource;
        this.selectNullValue();
    };
    Object.defineProperty(ComboboxEditComponent.prototype, "isFocus", {
        get: function () {
            return this.isFocusValue;
        },
        set: function (value) {
            var _this = this;
            if (this.isFocusValue !== value) {
                this.isFocusValue = value;
                if (value) {
                    setTimeout(function () {
                        _this.onfocus();
                    });
                }
                else {
                    if (this.kendoComboboxControl) {
                        setTimeout(function () {
                            _this.kendoComboboxControl.toggle(false);
                        });
                    }
                    if (this.kendoDropdownListControl) {
                        setTimeout(function () {
                            _this.kendoDropdownListControl.toggle(false);
                        });
                    }
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ComboboxEditComponent.prototype.ngAfterViewInit = function () {
        if (this.isFocus && !this.isMobile) {
            this.onfocus();
        }
    };
    ComboboxEditComponent.prototype.onClose = function () {
        var _this = this;
        setTimeout(function () {
            _this.onComboBoxFiltering();
        });
    };
    ComboboxEditComponent.prototype.onfocus = function () {
        var _this = this;
        this.addClassAndEventForDropdown();
        if (this.dataSource && this.dataSource.length > 0) {
            if (this.kendoComboboxControl) {
                setTimeout(function () {
                    var input = _this.kendoComboboxControl.wrapper.getElementsByClassName('k-input')[0];
                    if (input) {
                        input.focus();
                    }
                });
                if (this.isBigdataSource) {
                    var input = this.kendoComboboxControl.wrapper.getElementsByClassName('k-input')[0];
                    this.inputEvent = fromEvent(input, 'keyup').pipe(map(function (x) {
                        return x.target.value;
                    }), debounceTime(400));
                }
                this.kendoComboboxControl.toggle(true);
                if (this.kendoComboboxControl.wrapper.getElementsByClassName('k-input')[0]) {
                    this.kendoComboboxControl.wrapper.getElementsByClassName('k-input')[0].select();
                }
                if (this.value !== undefined && this.value !== this.nullIdValue) {
                    this.valueOnFocus = this.value;
                }
            }
            if (this.kendoDropdownListControl) {
                this.kendoDropdownListControl.toggle(true);
            }
        }
    };
    ComboboxEditComponent.prototype.onOpen = function () {
        this.addClassAndEventForDropdown();
    };
    ComboboxEditComponent.prototype.addClassAndEventForDropdown = function () {
        if (this.kendoDropdownListControl) {
            this.kendoDropdownListControl.toggle(true);
            setTimeout(function () {
                Array.from(document.getElementsByClassName('k-animation-container')).forEach(function (model) {
                    if (model) {
                        model.classList.add('MobiDropdownPopup');
                        model.addEventListener('click', function () {
                            model.style.display = 'none';
                            model.classList.remove('MobiDropdownPopup');
                        });
                    }
                });
                var elementdown1 = document.getElementsByClassName('EditingInfo Discard')[0];
                if (elementdown1) {
                    elementdown1.classList.add('setZIndex');
                    elementdown1.addEventListener('click', function () {
                        elementdown1.classList.remove('setZIndex');
                    });
                }
            });
        }
        if (this.comboboxDataSource && this.comboboxDataSource.length < 8 && !this.isFiltering) {
            this.checkFilterable = false;
        }
    };
    return ComboboxEditComponent;
}(ControlComponentBase));
export { ComboboxEditComponent };
