import { OnInit } from '@angular/core';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
var TaxCardAddOnComponent = /** @class */ (function () {
    function TaxCardAddOnComponent(sessionService) {
        this.sessionService = sessionService;
    }
    Object.defineProperty(TaxCardAddOnComponent.prototype, "taxInfo", {
        get: function () {
            return this.currentTaxInfo;
        },
        set: function (value) {
            if (this.currentTaxInfo !== value) {
                this.currentTaxInfo = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardAddOnComponent.prototype, "requested", {
        get: function () {
            return this.requestedTime;
        },
        set: function (value) {
            this.requestedTime = this.sessionService.toString(this.sessionService.parseDate(value), 'G').replace(/\./g, ':');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardAddOnComponent.prototype, "errorIcon", {
        get: function () {
            return !this.requestedTime && !this.taxInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardAddOnComponent.prototype, "infoIcon", {
        get: function () {
            return this.requestedTime && this.requestedTime.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardAddOnComponent.prototype, "warningIcon", {
        get: function () {
            return this.taxInfo && this.taxInfo.IsDummy;
        },
        enumerable: true,
        configurable: true
    });
    TaxCardAddOnComponent.prototype.ngOnInit = function () { };
    TaxCardAddOnComponent.prototype.showIcon = function (div) {
        // console.log("---");
    };
    return TaxCardAddOnComponent;
}());
export { TaxCardAddOnComponent };
