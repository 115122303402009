import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../Common/FormComponentBase';
import { Global } from '../../Common/Global';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
var CompanyGeneralComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyGeneralComponent, _super);
    function CompanyGeneralComponent(dataService, settingService, staticDataService, sessionService, transitionService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.settingService = settingService;
        _this.staticDataService = staticDataService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.currentTriggerEditmodeChange = false;
        _this.discardDialogVisible = false;
        _this.invalidDialogVisible = false;
        _this.isShowtestEmailDialog = false;
        _this.showInvalidFormWarning = false;
        _this.showSaveDataConfirmationChangeState = false;
        _this.resetChildFrom = false;
        _this.childIsValid = Array(3).fill(true);
        _this.childIsDirty = Array(3).fill(false);
        return _this;
    }
    CompanyGeneralComponent.prototype.onDoubleClick = function () {
        if (this.sessionService.role.IsFullAdmin) {
            this.editMode = true;
        }
    };
    Object.defineProperty(CompanyGeneralComponent.prototype, "TriigerEditMode", {
        get: function () {
            return this.currentTriggerEditmodeChange;
        },
        set: function (value) {
            if (this.currentTriggerEditmodeChange !== value) {
                this.currentTriggerEditmodeChange = value;
            }
            if (!value && this.isDirty) {
                this.onSave(this.sessionService.nextState);
            }
        },
        enumerable: true,
        configurable: true
    });
    CompanyGeneralComponent.prototype.ngOnInit = function () {
        this.getCompanyContacts();
        this.getCurrentCompany();
        this.company = Global.COMPANY;
        // if (!this.company) {
        //   this.getCurrentCompany();
        // }
        this.settingService.showModuleMessage('Company.General');
        this.sessionService.nextState = undefined;
        // this.transitionService.onStart({}, (trans: Transition): any => {
        //     let fromState: any = trans.from().name;
        //     let toState: any = this.navigationToStateName = trans.to().name;
        //     let generalState: string = "tabs.company.general";
        //     if (this.editMode && fromState && fromState === generalState) {
        //         if (this.isDirty) {
        //             // If validation fails, alert user and do nothing.
        //             // If validation passes, ask user to confirm before navigating
        //             if (!this.isValid) {
        //                 this.showInvalidFormWarning = true;
        //             } else {
        //                 this.showSaveDataConfirmationChangeState = true;
        //             }
        //             return false;
        //         } else {
        //             // Editmode but no changes, we close editmode without doing anything
        //             this.editMode = false;
        //             return true;
        //         }
        //     }
        // });
    };
    CompanyGeneralComponent.prototype.onConfirmSaveDataOnChangeState = function (action) {
        var _this = this;
        if (action === 'Continue') {
            this.onSave(this.sessionService.nextState);
        }
        else if (action === 'DiscardAndLeave') {
            this.discardDialogAction('ConfirmDiscard');
            if (this.navigationToStateName) {
                setTimeout(function () {
                    _this.sessionService.NavigateTo(_this.navigationToStateName);
                });
            }
        }
        this.navigationToStateName = undefined;
    };
    CompanyGeneralComponent.prototype.onMoreMenuOptionClick = function (menu) {
        switch (menu) {
            case 'companyGeneral_importData':
                this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
                break;
            case 'companyGeneral_testMail':
                this.isShowtestEmailDialog = true;
                break;
            default:
                break;
        }
    };
    CompanyGeneralComponent.prototype.onEditClick = function () {
        this.editMode = !this.editMode;
        // if (!this.editMode) {
        //     this.onSave(this.sessionService.nextState);
        // }
        // setTimeout(() => {
        //     if (this.editMode) {
        //         this.onSave();
        //         this.editMode = false;
        //     } else {
        //         this.editMode = true;
        //     }
        // });
    };
    CompanyGeneralComponent.prototype.onSave = function (navigationState) {
        var _this = this;
        if (this.isValid && this.isDirty && this.company) {
            this.checkCompanyInformation(this.company);
            this.dataService.Companies_UpdateCurrent(this.company).subscribe(function (responseCompany) {
                _this.editMode = false;
                _this.company = responseCompany;
                Global.COMPANY = responseCompany;
                _this.resetForm();
                if (navigationState) {
                    setTimeout(function () {
                        _this.sessionService.NavigateTo(navigationState);
                    });
                }
            }, function (error) {
                _this.editMode = true;
                _this.getCurrentCompany();
            });
        }
        else if (this.isDirty && !this.isValid) {
            this.invalidDialogVisible = true;
            if (navigationState) {
                this.resetForm();
                this.sessionService.nextState = navigationState;
                // setTimeout(() => { this.sessionService.NavigateTo(navigationState); });
            }
            else {
                this.getCurrentCompany();
            }
        }
    };
    CompanyGeneralComponent.prototype.checkCompanyInformation = function (company) {
        if (company.StatisticsRecipientId === undefined) {
            this.company.StatisticsRecipientId = null;
        }
    };
    CompanyGeneralComponent.prototype.ChangeState = function () {
        if (this.sessionService.nextState) {
            this.sessionService.NavigateTo(this.sessionService.nextState);
        }
    };
    CompanyGeneralComponent.prototype.confirmDiscardChanges = function () {
        if (this.isDirty) {
            this.discardDialogVisible = true;
        }
        else {
            this.editMode = false;
        }
    };
    CompanyGeneralComponent.prototype.discardDialogAction = function (action) {
        if (action === 'ConfirmDiscard') {
            this.isDirty = false;
            this.editMode = false;
            this.getCurrentCompany();
        }
    };
    CompanyGeneralComponent.prototype.getCompanyContacts = function () {
        var _this = this;
        this.staticDataService.companyUsers
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (companyUsers) {
            _this.contacts = companyUsers;
        });
    };
    CompanyGeneralComponent.prototype.getCurrentCompany = function () {
        var _this = this;
        this.dataService.Companies_GetCurrent().subscribe(function (company) {
            _this.company = company;
            _this.resetForm();
            Global.COMPANY = company;
        });
    };
    CompanyGeneralComponent.prototype.resetForm = function () {
        this.childIsDirty.fill(false);
        this.isDirty = false;
        this.resetChildFrom = true;
    };
    return CompanyGeneralComponent;
}(FormComponentBase));
export { CompanyGeneralComponent };
