import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../Common/Constants';
import { Broadcaster } from '../../Services/Broadcaster';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
var ChangeTemporaryPasswordComponent = /** @class */ (function () {
    function ChangeTemporaryPasswordComponent(dataService, broadcaster, sessionService) {
        this.dataService = dataService;
        this.broadcaster = broadcaster;
        this.sessionService = sessionService;
        this.isVisibleValue = false;
        this.isVisibleChange = new EventEmitter();
        this.temporaryPasswordChanged = new EventEmitter();
        this.isChangePassworSuccessDialog = false;
        this.showPassword = false;
        this.ngUnsubscribe = new Subject();
        this.userName = localStorage.getItem('saveLoginUserName');
    }
    Object.defineProperty(ChangeTemporaryPasswordComponent.prototype, "isVisible", {
        get: function () {
            return this.isVisibleValue;
        },
        set: function (value) {
            if (this.isVisibleValue !== value) {
                this.isVisibleValue = value;
                this.isVisibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    ChangeTemporaryPasswordComponent.prototype.keydown = function (e) {
        if (e.keyCode !== 13) {
            return;
        }
        this.onChangePassword();
    };
    ChangeTemporaryPasswordComponent.prototype.onChangePasswordDialogAction = function (action) {
        if (action === 'ChangePassword') {
            this.onChangePassword();
        }
        else {
            this.isVisible = false;
        }
    };
    ChangeTemporaryPasswordComponent.prototype.onChangePassSuccessNotificationAction = function (action) {
        this.temporaryPasswordChanged.emit(this.newPass);
        this.currentPassword = '';
        this.newPass = '';
        this.isVisible = false;
    };
    ChangeTemporaryPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.broadcaster
            .on(Constants.ACCOUNT_CHANGE_TEMPORARY_PASSWORD)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (temporaryPassword) {
            _this.currentPassword = temporaryPassword;
        });
    };
    ChangeTemporaryPasswordComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    ChangeTemporaryPasswordComponent.prototype.onChangePassword = function () {
        var _this = this;
        var request = { CurrentPassword: this.currentPassword, NewPassword: this.newPass };
        this.dataService.Auth_ChangePassword(request).subscribe(function () {
            _this.isChangePassworSuccessDialog = true;
        }, function (error) {
            _this.isVisible = true;
        });
    };
    return ChangeTemporaryPasswordComponent;
}());
export { ChangeTemporaryPasswordComponent };
