import { Component, OnDestroy, OnInit } from '@angular/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { Global } from '../../../Common/Global';
import { GridCellClickEvent } from '../../../CustomControls/Grid/CellClickEvent';
import {
  IExternalAccount,
  IFinanceAccountType,
  ILanguage,
  ISalaryRecordPersistenceType,
  ISalaryRecordsOverviewDisplayMode,
  ISalarySummaryCategory,
  ISalaryType,
  ISalaryTypeCategory,
  ISalaryTypeCategoryView,
  IUnitType
} from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { SalaryTypeView } from './SalaryTypeView';

@Component({
  selector: 'app-company-salary-types',
  templateUrl: './CompanySalaryTypesNGX.component.html'
})
export class CompanySalaryTypesNgxComponent implements OnInit, OnDestroy {
  public gridData: SalaryTypeView[] = [];
  public salaryTypes: ISalaryType[];
  public selectedId: number;
  public accountTypes: IFinanceAccountType[] = [];
  public summaryCategories: ISalarySummaryCategory[] = [];
  public unitTypes: IUnitType[] = [];
  public persistanceTypes: ISalaryRecordPersistenceType[] = [];
  public overviewDisplayModes: ISalaryRecordsOverviewDisplayMode[] = [];
  public categories: ISalaryTypeCategoryView[] = [];
  public externalReferences: any[] = [];
  public newSalaryTypeDialogVisible: boolean;
  public translationDialogVisible: boolean;
  public translationSalaryType: SalaryTypeView;
  public SalaryTypeCategories: ISalaryTypeCategoryView[];
  public get isLanguageModuleEnable(): boolean {
    return this.sessionService.feature.hasModuleId(5);
  }
  public languages: ILanguage[];
  public hasSalaryTypeModule = false;
  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get isFromDenmark(): boolean {
    return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
  }
  public get isFromGreenland(): boolean {
    return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  constructor(
    private dataService: RxDataService,
    private staticDataService: StaticDataService,
    public sessionService: SessionService,
    public settingService: SettingService
  ) {}

  public ngOnInit(): void {
    this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ILanguage[]) => {
      this.languages = data;
    });
    this.staticDataService.FinanceAccountType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IFinanceAccountType[]) => (this.accountTypes = data)
    );
    this.staticDataService.SalarySummaryCategory.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalarySummaryCategory[]) => (this.summaryCategories = data)
    );
    this.staticDataService.SalaryRecordPersistenceType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalaryRecordPersistenceType[]) => (this.persistanceTypes = data)
    );
    this.staticDataService.SalaryRecordsOverviewDisplayMode.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalaryRecordsOverviewDisplayMode[]) => (this.overviewDisplayModes = data)
    );
    // this.dataService.SalaryTypes_GetAllSalaryTypeCategories().subscribe((data: ISalaryTypeCategoryView[]) => this.categories = data);
    this.staticDataService.SalaryTypeCategory.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalaryTypeCategory[]) => {
        this.categories = data as any;
      }
    );
    this.dataService.Integrations_GetAllExternalAccounts().subscribe((data: IExternalAccount[]) => {
      this.externalReferences = data;
      if (this.externalReferences && this.externalReferences.length > 0) {
        this.externalReferences.forEach((model: any) => {
          model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
        });
        this.convertExternalRefCodeToFriendlyName();
      }
    });
    this.unitTypes = Global.UNIT_TYPES;
    this.dataService.SalaryTypes_GetAllSalaryTypeCategories().subscribe((data: ISalaryTypeCategoryView[]) => {
      this.SalaryTypeCategories = data;
    });

    this.staticDataService.moduleCompanyView.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.getSalaryTypes());
    this.settingService.showModuleMessage('company.advancedsalarytypes');
    if (this.isMobile) {
      this.sessionService.isShowHugeFeaturesAlert = true;
    }
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public saveChanges(value: CellCloseEvent): void {
    value.dataItem.ExternalReference = this.getExteralReferenceCode(value.dataItem.ExternalReference, true);

    const salaryType: any = value.dataItem.toSalaryType();
    salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference);
    this.dataService.SalaryTypes_UpdateSalaryType(salaryType).subscribe(
      (data: ISalaryType) => {
        data.ExternalReference = this.getExteralReferenceCode(data.ExternalReference, true);
        this.transformSalaryTypeObject(data);
        Object.assign(value.dataItem, new SalaryTypeView(data, this.isLanguageModuleEnable));
      },
      (error: any) => this.getSalaryTypes()
    );
  }

  public onAddClick(): void {
    this.newSalaryTypeDialogVisible = true;
  }

  public onAddSalaryType(value: ISalaryType): void {
    this.salaryTypes.push(value);
    this.transformSalaryTypeObject(value);
    this.gridData.push(new SalaryTypeView(value, this.isLanguageModuleEnable));
    this.gridFilterData = filterBy(this.gridData, this.filter);
    this.selectedId = value.Id;
  }

  public onCellClick(event: GridCellClickEvent): void {
    if (
      ['Name', 'Description', 'DefaultPayslipText', 'Explanation'].includes(event.field) &&
      this.isLanguageModuleEnable
    ) {
      this.translationSalaryType = event.dataItem;
      this.translationDialogVisible = true;
    }
  }

  public onTranslateCompleted(): void {
    this.getSalaryTypes();
  }

  private getSalaryTypes(): void {
    this.gridData = [];
    if (this.isLanguageModuleEnable) {
      this.dataService.SalaryTypes_GetRawSalaryTypesTranslated().subscribe((data: ISalaryType[]) => {
        this.salaryTypes = data;
        const filterSalaryTypes: ISalaryType[] = data.filter((type: ISalaryType) => {
          return !type.BaseSalaryType || (type.BaseSalaryType.IsVisible && type.BaseSalaryType.IsActive);
        });
        const activeSalaryTypes: ISalaryType[] = filterSalaryTypes.filter((type: ISalaryType) => {
          return type.IsActive !== false;
        });
        const inActiveSalaryType: ISalaryType[] = filterSalaryTypes.filter((type: ISalaryType) => {
          return type.IsActive === false;
        });
        const sortedActiveSalaryTypes: ISalaryType[] = activeSalaryTypes.sort((a: ISalaryType, b: ISalaryType) => {
          return this.sortCustomSalaryTypes(a, b);
        });
        const sortedInActiveSalaryTypes: ISalaryType[] = inActiveSalaryType.sort((a: ISalaryType, b: ISalaryType) => {
          return this.sortCustomSalaryTypes(a, b);
        });
        const sortedSalaryTypes: ISalaryType[] = sortedActiveSalaryTypes.concat(sortedInActiveSalaryTypes);
        this.gridData = sortedSalaryTypes.map((salaryType: ISalaryType) => {
          this.transformSalaryTypeObject(salaryType);
          return new SalaryTypeView(salaryType, this.isLanguageModuleEnable);
        });
        this.gridFilterData = filterBy(this.gridData, this.filter);
        this.convertExternalRefCodeToFriendlyName();
      });
    } else {
      this.dataService.SalaryTypes_GetRawSalaryTypes().subscribe((data: ISalaryType[]) => {
        this.salaryTypes = data;
        const filterSalaryTypes: ISalaryType[] = data.filter((type: ISalaryType) => {
          return !type.BaseSalaryType || (type.BaseSalaryType.IsVisible && type.BaseSalaryType.IsActive);
        });
        const activeSalaryTypes: ISalaryType[] = filterSalaryTypes.filter((type: ISalaryType) => {
          return type.IsActive !== false;
        });
        const inActiveSalaryType: ISalaryType[] = filterSalaryTypes.filter((type: ISalaryType) => {
          return type.IsActive === false;
        });
        const sortedActiveSalaryTypes: ISalaryType[] = activeSalaryTypes.sort((a: ISalaryType, b: ISalaryType) => {
          return this.sortCustomSalaryTypes(a, b);
        });
        const sortedInActiveSalaryTypes: ISalaryType[] = inActiveSalaryType.sort((a: ISalaryType, b: ISalaryType) => {
          return this.sortCustomSalaryTypes(a, b);
        });
        const sortedSalaryTypes: ISalaryType[] = sortedActiveSalaryTypes.concat(sortedInActiveSalaryTypes);
        this.gridData = sortedSalaryTypes.map((salaryType: ISalaryType) => {
          this.transformSalaryTypeObject(salaryType);
          return new SalaryTypeView(salaryType, this.isLanguageModuleEnable);
        });
        this.gridFilterData = filterBy(this.gridData, this.filter);
        this.convertExternalRefCodeToFriendlyName();
      });
    }
  }

  private transformSalaryTypeObject(salaryType: ISalaryType): void {
    const existingSalaryType: ISalaryType = this.salaryTypes.find((s: ISalaryType) => s.Id === salaryType.Id);
    salaryType.SalaryTypeTranslations = salaryType.SalaryTypeTranslations
      ? salaryType.SalaryTypeTranslations
      : existingSalaryType
      ? existingSalaryType.SalaryTypeTranslations
      : undefined;
    salaryType.BaseSalaryType = salaryType.BaseSalaryType
      ? salaryType.BaseSalaryType
      : existingSalaryType
      ? existingSalaryType.BaseSalaryType
      : undefined;
  }

  private convertExternalRefCodeToFriendlyName(): void {
    if (this.gridData && this.gridData.length > 0 && this.externalReferences && this.externalReferences.length > 0) {
      this.gridData.forEach((salaryType: any) => {
        salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference, true);
      });
      this.gridFilterData = filterBy(this.gridData, this.filter);
    }
  }

  private sortCustomSalaryTypes(a: ISalaryType, b: ISalaryType): number {
    const aOrder: number =
      a.SortOrder || a.SortOrder === 0
        ? a.SortOrder
        : a.BaseSalaryType && (a.BaseSalaryType.SortOrder || a.BaseSalaryType.SortOrder === 0)
        ? a.BaseSalaryType.SortOrder
        : undefined;
    const bOrder: number =
      b.SortOrder || b.SortOrder === 0
        ? b.SortOrder
        : b.BaseSalaryType && (b.BaseSalaryType.SortOrder || b.BaseSalaryType.SortOrder === 0)
        ? b.BaseSalaryType.SortOrder
        : undefined;
    if ((aOrder === undefined && bOrder === undefined) || (aOrder === 0 && bOrder === 0)) {
      return a.Id - b.Id;
    } else if (aOrder === undefined && bOrder !== undefined) {
      return -1;
    } else if (aOrder !== undefined && bOrder === undefined) {
      return 1;
    }

    return aOrder - bOrder;
  }

  private getExteralReferenceCode(refCode: string, fullName?: boolean): any {
    if (!refCode) {
      return null;
    }

    const externalRefCode: any = this.externalReferences
      ? this.externalReferences.find((item: any) => {
          return refCode === item.AccountNumber || refCode === item.FriendlyName;
        })
      : null;

    if (externalRefCode) {
      return !fullName ? externalRefCode.AccountNumber : externalRefCode.FriendlyName;
    }

    return refCode;
  }

  public filter: CompositeFilterDescriptor;
  public gridFilterData: any[] = filterBy(this.gridData, this.filter);
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridFilterData = filterBy(this.gridData, filter);
  }
}
