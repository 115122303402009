import { ImportButtonAction, ImportTypesEnum } from '../../../Model/Enum';

export class ImportOptionHelper {
  static getImportTypeKey(importType: ImportTypesEnum): string {
    switch (importType) {
      case ImportTypesEnum.Employees:
        return 'ImportEmployees';
      case ImportTypesEnum.Initial_Balances:
        return 'ImportBalances';
      case ImportTypesEnum.Payroll:
        return 'ImportSalaryRecords';
      case ImportTypesEnum.PayrollRow:
        return 'ImportSalaryRecordsRow';
      case ImportTypesEnum.TimeEntries:
        return 'ImportTimeEntries';
      case ImportTypesEnum.TimeEntriesRow:
        return 'ImportTimeEntriesRow';
    }

    return '';
  }

  static createImportOptionObjects(importOptions: any): any {
    const options: any[] = [];
    if (!importOptions) {
      return undefined;
    }

    for (let index = 0; index < importOptions.length; index++) {
      if (importOptions[index].Options.length === 1) {
        options.push({
          DataType: 'CheckBox',
          ParameterValue: importOptions[index]['Value'],
          DisplayText: importOptions[index].DisplayName
            ? importOptions[index].DisplayName
            : importOptions[index].Options[0]['Value']
            ? importOptions[index].Options[0]['Value']
            : importOptions[index]['Value'],
          ControlValue: importOptions[index]['DefaultKey']
        });
      } else if (importOptions[index].Options.length > 1) {
        for (let subIndex = 0; subIndex < importOptions[index].Options.length; subIndex++) {
          const existedRadioOption: any = options.find(
            (radio: any) => radio.ParameterValue === importOptions[index]['Value']
          );
          if (existedRadioOption) {
            existedRadioOption.Options.push({
              DisplayText: importOptions[index].Options[subIndex]['Value'],
              RadioValue: importOptions[index].Options[subIndex]['Key']
            });
          } else {
            options.push({
              DataType: 'Radio',
              ParameterValue: importOptions[index]['Value'],
              ControlValue: importOptions[index]['DefaultKey'],
              Options: [
                {
                  DisplayText: importOptions[index].Options[subIndex]['Value'],
                  RadioValue: importOptions[index].Options[subIndex]['Key']
                }
              ]
            });
          }
        }
      }
    }

    return options;
  }

  static getInportOptionsString(importOptions: any, action: ImportButtonAction): string {
    const optionsString: string = this.getOptionsString(importOptions);
    let saveType: string;
    switch (action) {
      case ImportButtonAction.Test:
        saveType = 'save=never';
        break;
      case ImportButtonAction.Import:
        saveType = 'save=noerror';
        break;
      case ImportButtonAction.CompleteImport:
        saveType = 'save=always';
        break;
    }

    const result: string = saveType + (optionsString ? '-' + optionsString : '');
    return result;
  }

  static getOptionsString(importOptions: any): string {
    let optionsString = '';
    if (importOptions && importOptions.length > 0) {
      importOptions.forEach((option: any): void => {
        if (optionsString.indexOf(option.ParameterValue + '=') < 0) {
          optionsString +=
            (optionsString ? '-' : '') +
            option.ParameterValue +
            '=' +
            (option.ControlValue ? option.ControlValue : 'false');
        }
      });
    }

    return optionsString;
  }
}
