import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IRequestLogEntry } from '../Model/IRequestLogEntry';
import { SessionService } from './SharedServices/Session/SessionService';

@Injectable()
export class LogInterceptor implements HttpInterceptor {
  constructor(private service: SessionService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (this.service.requestLogEnabled) {
      return next.handle(req).pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            const entry: IRequestLogEntry = {
              method: req.method,
              url: req.urlWithParams,
              payload: req.body,
              status: event.status,
              response: event.body
            };
            this.service.logRequest(entry);
          }
        }),
        catchError((err: any, caught: Observable<any>) => {
          const entry: IRequestLogEntry = {
            method: req.method,
            url: req.urlWithParams,
            payload: req.body,
            status: err.status
          };
          this.service.logRequest(entry);
          return throwError(err);
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
