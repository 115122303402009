import { ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { ControlComponentBase } from '../Common/ControlComponentBase';
import { ReflectionHelper } from '../Common/ReflectionHelper';

@Component({
  selector: 'check-edit',
  templateUrl: './Checkbox.component.html'
})
export class CheckboxComponent extends ControlComponentBase<boolean> {
  @Input() public label: string;
  @Input() public tooltip = '';
  @Input() public disable: boolean;
  @Input() public helpLink: string;
  @Input() public helpTooltip = '';
  @Input() public isOpenLink = true;
  @Input() public customClass: string;
  @Input() public helpIcon = 'Help';
  @Input() public selfService = '';
  @Input() public isLink = false;

  public get labelClass(): string {
    return this.customClass + ' ' + (this.isGrid ? 'checkBox-label-center' : '');
  }

  public get isDisabled(): boolean {
    return this.disable ? this.disable : !this.editMode;
  }

  constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
  }

  protected valid(): boolean {
    return true;
  }

  protected convertValue(value: any): boolean {
    if (value && ReflectionHelper.isString(value)) {
      return value.toLowerCase() === 'true';
    } else {
      return value;
    }
  }
}
