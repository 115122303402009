<div class="FormElement">
  <div class="Card-title">{{ 'CompanyHiringStatus.Employees based on this template' | translate }}</div>
  <grid [data]="employees" [editMode]="false" class="Company-templateEmployeesGrid GridSelect">
    <gridColumn
      [field]="'FullName'"
      [type]="'text'"
      [width]="200"
      [title]="'CompanyHiringStatus.EmployeeGridFullName' | translate"
    ></gridColumn>
    <gridColumn
      [field]="'Title'"
      [type]="'text'"
      [width]="100"
      [title]="'CompanyHiringStatus.EmployeeGridTitle' | translate"
    ></gridColumn>
  </grid>
</div>
