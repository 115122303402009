import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISalaryBatchTotalsApiView, ISalaryTypeSumsByEmploymentView } from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
var SalaryBatchSalaryTypesComponent = /** @class */ (function () {
    function SalaryBatchSalaryTypesComponent(dataService, sessionService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.salaryTypePerEmployee = [];
        this.salaryTypeTotalsValue = [];
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(SalaryBatchSalaryTypesComponent.prototype, "SalaryBatchId", {
        get: function () {
            return this.salaryBatchIdValue;
        },
        set: function (value) {
            if (this.salaryBatchIdValue !== value) {
                this.salaryBatchIdValue = value;
                if (value) {
                    this.getTotals();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchSalaryTypesComponent.prototype, "SalaryTypeTotals", {
        get: function () {
            return this.salaryTypeTotalsValue;
        },
        set: function (value) {
            if (value !== this.salaryTypeTotalsValue) {
                this.salaryTypeTotalsValue = [];
                this.salaryTypeTotalsValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchSalaryTypesComponent.prototype.getTotals = function () {
        var _this = this;
        this.salaryTypePerEmployee = [];
        this.dataService
            .SalaryBatches_GetSalaryBatchTotalsBySalaryType(this.SalaryBatchId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.SalaryTypeTotals = data;
            if (data.length > 0) {
                _this.selectedSalaryTypeTotal = data[0];
                // this.onSelectedSalaryTypeTotalChange(this.selectedSalaryTypeTotal);
            }
        });
    };
    SalaryBatchSalaryTypesComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SalaryBatchSalaryTypesComponent.prototype.onSelectedSalaryTypeTotalChange = function (data) {
        var _this = this;
        this.dataService
            .SalaryBatches_GetSalaryTypeTotalsByEmployee(this.SalaryBatchId, data.SalaryTypeId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (salaryTypeSum) {
            _this.salaryTypePerEmployee = salaryTypeSum;
        });
    };
    SalaryBatchSalaryTypesComponent.prototype.onDoubleClick = function (data) {
        if (data && data.UserEmploymentId) {
            this.sessionService.currentUserEmploymentId = data.UserEmploymentId;
            this.sessionService.NavigateTo('tabs.employee.payrolldata');
        }
    };
    return SalaryBatchSalaryTypesComponent;
}());
export { SalaryBatchSalaryTypesComponent };
