import { EventEmitter } from '@angular/core';
import { RxDataService } from '../Services/RxDataService';
var AcceptDPAComponent = /** @class */ (function () {
    function AcceptDPAComponent(dataService) {
        this.dataService = dataService;
        this.isVisibleValue = false;
        this.isVisibleChange = new EventEmitter();
        this.accepted = new EventEmitter();
    }
    Object.defineProperty(AcceptDPAComponent.prototype, "isVisible", {
        get: function () {
            return this.isVisibleValue;
        },
        set: function (value) {
            if (value) {
                this.password = '';
            }
            this.isVisibleValue = value;
            this.isVisibleChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    AcceptDPAComponent.prototype.onDialogAction = function (action) {
        if (action === 'Create') {
            this.acceptDPA();
        }
        else {
            this.accepted.emit(false);
        }
    };
    AcceptDPAComponent.prototype.onAcceptDPAKeydown = function (event) {
        if (event.keyCode === 13) {
            this.acceptDPA();
        }
    };
    AcceptDPAComponent.prototype.acceptDPA = function () {
        var _this = this;
        this.dataService.Companies_AcceptTerms({ Password: this.password }).subscribe(function () {
            _this.isVisible = false;
            _this.accepted.emit(true);
        });
    };
    return AcceptDPAComponent;
}());
export { AcceptDPAComponent };
