<div
  class="Startup"
  *ngIf="(sessionService.role.IsFullAdmin || !editMode) && isVisibleStartupTask"
  [ngClass]="{ fadeout: startUpFadeout }"
>
  <div id="startUpProgressIndicator" [ngClass]="{ 'is-active': startUpIndicatorIsActive }" class="Startup-progress">
    <div class="Startup-progressHeader">
      <span class="Startup-header" (click)="openTaskList($event)">
        {{ 'StartUpTasks.Get up and running with Gratisal' | translate }}
      </span>
      <div class="width45">
        <action-button
          [buttonClass]="'Startup-skipTask'"
          [icon]="'Close'"
          (click)="hideStartupTasks($event)"
          [iconSize]="'small'"
          [tooltip]="'StartUpTasks.HideGetStarted' | translate"
        >
        </action-button>

        <button
          (click)="openTaskList($event)"
          class="Startup-toggleTaskList"
          type="button"
          [ngClass]="{ 'is-active': !isTaskListShown }"
        >
          <app-icon [icon]="'ChevronDown'" [size]="'small'"></app-icon>
        </button>
      </div>
    </div>

    <div
      id="startUpSteps"
      [ngClass]="{ 'is-active': startUpStepsIsActive }"
      [ngStyle]="{ 'max-height.px': startUpStepsMaxHeight }"
      class="Startup-list"
    >
      <ul id="startUpStepsList" [ngStyle]="{ 'max-height': 300 }">
        <li class="Startup-explain" [innerHTML]="'StartUpTasks.Intro' | translate"></li>
        <li *ngFor="let task of startupTasks">
          <div>
            <button type="button" (click)="completeTask(task)" *ngIf="task.StatusId === 0">
              <app-icon [icon]="'Circle'" [size]="'small'"></app-icon>
            </button>

            <button type="button" (click)="completeTask(task)" *ngIf="task.StatusId === 1">
              <app-icon [icon]="'SuccessNonGreen'" [size]="'small'"></app-icon>
            </button>

            <button type="button" (click)="skipTask(task)" *ngIf="task.StatusId === -1">
              <app-icon [icon]="'Clear'" [size]="'small'"></app-icon>
            </button>
          </div>

          <button-component
            [buttonClass]="'Startup-openTask'"
            [label]="task.Name"
            (click)="navigateToTask(task)"
            [tooltip]="task.Description | translate"
            *ngIf="task.StatusId == 0"
          >
          </button-component>

          <div class="Startup-completedTask" *ngIf="task.StatusId === 1 || task.StatusId === -1">
            {{ task.Name }}
          </div>

          <action-button
            [icon]="'Close'"
            *ngIf="task.IsSkippable && task.StatusId === 0"
            [buttonClass]="'Startup-skipTask'"
            (click)="skipTask(task)"
            [iconSize]="'small'"
            [tooltip]="'StartUpTasks.SkipTooltip' | translate"
          >
          </action-button>
        </li>
      </ul>
    </div>

    <div class="Startup-progressIndicator" (click)="openTaskList($event)">
      <div
        id="startUpTaskIndicatorProgressed"
        [ngStyle]="{ transform: 'translateX(' + startUpProgress + '%)' }"
        class="Startup-progressIndicatorBar"
      >
        {{ startUpProgress }}%
      </div>
    </div>
  </div>
</div>

<app-dialog
  [(visible)]="isShowingHiddenStartUp"
  (action)="onShowingHiddenStartUp($event)"
  [width]="'500'"
  [leadingText]="'StartUpTasks.HideGetStartedConfirm' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="HideStartUp"></app-dialog-action>
</app-dialog>

<app-dialog
  *ngIf="TaskClick"
  [(visible)]="isShowTaskMess"
  (action)="onTaskDialogAction($event)"
  [width]="'500'"
  [customVisible]="true"
  [leadingText]="TaskClick.GuideMessage | translate"
>
  <app-dialog-action type="Ok"></app-dialog-action>
  <app-dialog-action *ngIf="isShowmoreHelp && isExitLink" type="MoreHelp"></app-dialog-action>
</app-dialog>

<app-dialog
  *ngIf="finalTask && finalTask.SkipWarning"
  [(visible)]="isShowFianlDialog"
  (action)="finalDialogAction($event)"
  [width]="'500'"
  [leadingText]="finalTask.SkipWarning | translate"
>
  <app-dialog-action type="No"></app-dialog-action>
  <app-dialog-action type="Yes"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="isCompleteTask"
  (action)="onCompleteTask($event)"
  [width]="'400'"
  [leadingText]="'StartUpTasks.AllTaskDone' | translate"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
