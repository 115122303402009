import { OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
var SalaryBatchOtherErrorGridComponent = /** @class */ (function () {
    function SalaryBatchOtherErrorGridComponent(dataService, sessionService, translateService) {
        var _this = this;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.translateService = translateService;
        this.validationRecord = [];
        this.gridData = [];
        this.ngUnsubscribe = new Subject();
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService
                .get([
                'CompanySalaryBatches.OrtherStatusGridErrorIconTooltip',
                'CompanySalaryBatches.OrtherStatusGridWarningIconTooltip'
            ])
                .subscribe(function (translations) {
                _this.warningMessage = translations['CompanySalaryBatches.OrtherStatusGridWarningIconTooltip'];
                _this.errorMessage = translations['CompanySalaryBatches.OrtherStatusGridErrorIconTooltip'];
            });
        });
    }
    Object.defineProperty(SalaryBatchOtherErrorGridComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.loadErrorDetails();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchOtherErrorGridComponent.prototype, "salaryBatch", {
        get: function () {
            return this.currentSalaryBatch;
        },
        set: function (value) {
            if (this.currentSalaryBatch !== value) {
                this.currentSalaryBatch = value;
                this.loadErrorDetails();
            }
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchOtherErrorGridComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SalaryBatchOtherErrorGridComponent.prototype.loadErrorDetails = function () {
        var _this = this;
        if (this.visible &&
            this.salaryBatch &&
            this.salaryBatch.Id &&
            this.salaryBatch.StatusId &&
            this.salaryBatch.StatusId !== 40) {
            this.dataService
                .SalaryBatches_GetValidationIssues(this.salaryBatch.Id)
                .subscribe(function (validationIssues) {
                _this.validationRecord = validationIssues.map(function (issue) {
                    return {
                        icon: issue.IsError ? 'ErrorExclamation' : 'Warning',
                        tooltip: issue.IsError ? _this.errorMessage : _this.warningMessage,
                        description: issue.Description,
                        employeeName: issue.EmployeeName
                    };
                });
                _this.gridData = _this.validationRecord;
            });
        }
        else {
            this.gridData = [];
        }
    };
    SalaryBatchOtherErrorGridComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridData = filterBy(this.validationRecord, filter);
    };
    return SalaryBatchOtherErrorGridComponent;
}());
export { SalaryBatchOtherErrorGridComponent };
