/* tslint:disable:forin */
export class ReflectionHelper {
  static copyPropertyValues(sourceObject: any, targetObject: any): void {
    for (const sourcedObjectProperty in sourceObject) {
      for (const targetObjectProperty in targetObject) {
        if (sourcedObjectProperty === targetObjectProperty) {
          if (ReflectionHelper.isObject(sourcedObjectProperty) || ReflectionHelper.isArray(sourcedObjectProperty)) {
            ReflectionHelper.copyPropertyValues(sourcedObjectProperty, targetObjectProperty);
          } else {
            targetObject[targetObjectProperty] = sourceObject[sourcedObjectProperty as string];
          }
        }
      }
    }
  }

  static isObject(obj: any): boolean {
    return obj === Object(obj) && Object.prototype.toString.call(obj) !== '[object Array]';
  }

  static isArray(obj: any): boolean {
    return obj === Object(obj) && Object.prototype.toString.call(obj) === '[object Array]';
  }

  static isNumber(obj: any): boolean {
    return typeof obj === 'number' || obj instanceof Number;
  }

  static isString(obj: any): boolean {
    return typeof obj === 'string' || obj instanceof String;
  }

  static isDate(obj: any): boolean {
    return obj instanceof Date && !isNaN(+obj);
  }

  static isEqualShallow(objA: any, objB: any): boolean {
    // Check if both are undefined
    if (!objA && !objB) {
      return true;
    }

    // Check if only one of them is undefined
    if ((objA && !objB) || (!objA && objB)) {
      return false;
    }

    // Create arrays of property names
    const objAProperties: string[] = Object.getOwnPropertyNames(objA);
    const objBProperties: string[] = Object.getOwnPropertyNames(objB);

    // If number of properties is different, objects are not equivalent
    if (objAProperties.length !== objBProperties.length) {
      return false;
    }

    for (let i = 0; i < objAProperties.length; i++) {
      const propName: string = objAProperties[i];

      // If values of same property are not equal, objects are not equivalent
      if (objA[propName] !== objB[propName]) {
        return false;
      }
    }

    // If we made it this far, objects are considered equivalent
    return true;
  }
}
