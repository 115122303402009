/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../CustomControls/Dialog/Dialog.component.ngfactory";
import * as i2 from "../../../../CustomControls/Dialog/Dialog.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../Services/SharedServices/Session/SessionService";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../CustomControls/Dialog/DialogAction.component.ngfactory";
import * as i7 from "../../../../CustomControls/Dialog/DialogAction.component";
import * as i8 from "@angular/common";
import * as i9 from "./NegativeDefaultMoreInfoDialog.component";
import * as i10 from "../../../../Services/RxDataService";
var styles_NegativeDefaultMoreInfoDialogComponent = [];
var RenderType_NegativeDefaultMoreInfoDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NegativeDefaultMoreInfoDialogComponent, data: {} });
export { RenderType_NegativeDefaultMoreInfoDialogComponent as RenderType_NegativeDefaultMoreInfoDialogComponent };
function View_NegativeDefaultMoreInfoDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "app-dialog", [], null, [[null, "visibleChange"], [null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.showDialog = $event) !== false);
        ad = (pd_0 && ad);
    } if (("action" === en)) {
        var pd_1 = (_co.onDialogAction($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_DialogComponent_0, i1.RenderType_DialogComponent)), i0.ɵdid(2, 49152, null, 1, i2.DialogComponent, [i3.DomSanitizer, i4.SessionService, i0.ElementRef], { width: [0, "width"], leadingText: [1, "leadingText"], visible: [2, "visible"] }, { visibleChange: "visibleChange", action: "action" }), i0.ɵqud(603979776, 1, { buttonElements: 1 }), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, 0, 1, "app-dialog-action", [["type", "Ok"]], null, null, null, i6.View_DialogActionComponent_0, i6.RenderType_DialogActionComponent)), i0.ɵdid(6, 180224, [[1, 4]], 0, i7.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(7, 0, null, 0, 1, "app-dialog-action", [["type", "UsePositiveAmountThisTime"]], null, null, null, i6.View_DialogActionComponent_0, i6.RenderType_DialogActionComponent)), i0.ɵdid(8, 180224, [[1, 4]], 0, i7.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(9, 0, null, 0, 1, "app-dialog-action", [["type", "DisableThisBehaviour"]], null, null, null, i6.View_DialogActionComponent_0, i6.RenderType_DialogActionComponent)), i0.ɵdid(10, 180224, [[1, 4]], 0, i7.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 630; var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 4).transform("EmploymentPayrollData.More_Info_EnableSetting")); var currVal_2 = _co.showDialog; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "Ok"; _ck(_v, 6, 0, currVal_3); var currVal_4 = "UsePositiveAmountThisTime"; _ck(_v, 8, 0, currVal_4); var currVal_5 = "DisableThisBehaviour"; _ck(_v, 10, 0, currVal_5); }, null); }
function View_NegativeDefaultMoreInfoDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "app-dialog", [], null, [[null, "visibleChange"], [null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.showDialog = $event) !== false);
        ad = (pd_0 && ad);
    } if (("action" === en)) {
        var pd_1 = (_co.onDialogAction($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_DialogComponent_0, i1.RenderType_DialogComponent)), i0.ɵdid(2, 49152, null, 1, i2.DialogComponent, [i3.DomSanitizer, i4.SessionService, i0.ElementRef], { width: [0, "width"], leadingText: [1, "leadingText"], visible: [2, "visible"] }, { visibleChange: "visibleChange", action: "action" }), i0.ɵqud(603979776, 2, { buttonElements: 1 }), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, 0, 1, "app-dialog-action", [["type", "IKnowWhatIamDoing"]], null, null, null, i6.View_DialogActionComponent_0, i6.RenderType_DialogActionComponent)), i0.ɵdid(6, 180224, [[2, 4]], 0, i7.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(7, 0, null, 0, 1, "app-dialog-action", [["type", "EnableAutoCorrect"]], null, null, null, i6.View_DialogActionComponent_0, i6.RenderType_DialogActionComponent)), i0.ɵdid(8, 180224, [[2, 4]], 0, i7.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 630; var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 4).transform("EmploymentPayrollData.More_Info_DisableSetting")); var currVal_2 = _co.showDialog; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "IKnowWhatIamDoing"; _ck(_v, 6, 0, currVal_3); var currVal_4 = "EnableAutoCorrect"; _ck(_v, 8, 0, currVal_4); }, null); }
export function View_NegativeDefaultMoreInfoDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_NegativeDefaultMoreInfoDialogComponent_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NegativeDefaultMoreInfoDialogComponent_2)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.setting; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.setting; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_NegativeDefaultMoreInfoDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "negative-default-info", [], null, null, null, View_NegativeDefaultMoreInfoDialogComponent_0, RenderType_NegativeDefaultMoreInfoDialogComponent)), i0.ɵdid(1, 49152, null, 0, i9.NegativeDefaultMoreInfoDialogComponent, [i10.RxDataService], null, null)], null, null); }
var NegativeDefaultMoreInfoDialogComponentNgFactory = i0.ɵccf("negative-default-info", i9.NegativeDefaultMoreInfoDialogComponent, View_NegativeDefaultMoreInfoDialogComponent_Host_0, { setting: "setting", fieldName: "fieldName", dataItem: "dataItem", showDialog: "showDialog" }, { showDialogChange: "showDialogChange", usePositiveAmount: "usePositiveAmount", enableAutoCorrect: "enableAutoCorrect" }, []);
export { NegativeDefaultMoreInfoDialogComponentNgFactory as NegativeDefaultMoreInfoDialogComponentNgFactory };
