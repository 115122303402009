<app-dialog [(visible)]="visible" (action)="dialogAction($event)">
  <div class="FormElement">
    <label class="FormElement-label" for="Message">{{ 'CompanyGeneral.EnterListOfEmails' | translate }}</label>
    <div class="FormElement-control">
      <textarea class="FormElement-textarea MessageToEmployeesTextArea" id="Message" [(ngModel)]="emails"></textarea>
    </div>
  </div>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action
    [label]="'CompanyGeneral.Test' | translate"
    [disabled]="!emails"
    [action]="'Test'"
    [close]="false"
    [class]="'Button--primary'"
  ></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="close()"
  [(visible)]="errorDialogVisible"
  [leadingText]="'CompanyGeneral.FailedEmailExplanatoryText' | translate"
>
  <br />
  <br />
  <div [innerHTML]="failedMessage"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
