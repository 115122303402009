import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Global } from '../Common/Global';
import { SessionService } from '../Services/SharedServices/Session/SessionService';
var CompanySelectorComponent = /** @class */ (function () {
    function CompanySelectorComponent(sessionService, changeDetectorRef) {
        this.sessionService = sessionService;
        this.changeDetectorRef = changeDetectorRef;
        this.companies = [];
        this.selectedCompanyIdChange = new EventEmitter();
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.companyChange = new EventEmitter();
        this.comboBoxData = [];
        this.pageSize = 30;
        this.skip = 0;
        this.selectedRows = [];
        this.gridData = [];
        this.filterSubject = new BehaviorSubject('');
    }
    Object.defineProperty(CompanySelectorComponent.prototype, "selectedCompanyId", {
        get: function () {
            return this.selectedCompanyIdValue;
        },
        set: function (value) {
            if (this.selectedCompanyIdValue !== value) {
                this.selectedCompanyIdValue = value;
                this.selectedCompanyIdChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySelectorComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            var _this = this;
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
                if (value) {
                    if (Global.SESSION && Global.SESSION.IsAccountant) {
                        this.longList = true;
                        this.companies.forEach(function (company) {
                            company.DisplayName =
                                company.Name + (Global.SESSION.HasBackendAccess ? ' (' + company.Id + ')' : '');
                        });
                        this.companies.sort(this.compareByCompanyName);
                        this.gridData = this.companies;
                        if (!this.isMobile) {
                            setTimeout(function () { return _this.filterInput.nativeElement.focus(); });
                        }
                    }
                    else {
                        this.longList = false;
                        this.companies.forEach(function (company) {
                            company.DisplayName =
                                (company.Name.length < 30 ? company.Name : company.Name.substring(0, 30) + '...') +
                                    (Global.SESSION.HasBackendAccess ? ' (' + company.Id + ')' : '');
                        });
                        this.companies.sort(this.compareByCompanyName);
                        this.comboBoxData = this.companies;
                    }
                }
                else {
                    this.gridData = [];
                    this.comboBoxData = [];
                    this.filterValue = '';
                    this.selectedRows = [];
                }
                this.loadItems();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySelectorComponent.prototype, "filter", {
        get: function () {
            return this.filterValue;
        },
        set: function (value) {
            this.filterValue = value;
            this.filterSubject.next(value);
        },
        enumerable: true,
        configurable: true
    });
    CompanySelectorComponent.prototype.compareByCompanyName = function (company1, company2) {
        if (company1.DisplayName.toLowerCase() < company2.DisplayName.toLowerCase()) {
            return -1;
        }
        if (company1.DisplayName.toLowerCase() > company2.DisplayName.toLowerCase()) {
            return 1;
        }
        return 0;
    };
    Object.defineProperty(CompanySelectorComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    CompanySelectorComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.filterSubject
            .asObservable()
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(function (value) { return _this.applyFilter(value); });
    };
    CompanySelectorComponent.prototype.onFilterKeyDown = function (event) {
        var _this = this;
        if (event.key === 'Enter') {
            if (this.selectedRows && this.selectedRows.length === 1) {
                var companyId = this.selectedRows[0];
                this.emitCompanyId(companyId);
                return;
            }
            if (this.gridView.data.length === 1) {
                var companyId = this.gridView.data[0].Id;
                this.emitCompanyId(companyId);
            }
            else {
                this.selectedRows = this.gridView.data && this.gridView.data.length > 0 ? [this.gridView.data[0].Id] : [];
                this.grid.focusCell(0, 0);
            }
        }
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            if (this.gridView.data && this.gridView.data.length > 0) {
                var rowIndex = this.grid.activeRow && this.grid.activeRow.index
                    ? this.grid.activeRow.index
                    : this.selectedRows && this.selectedRows.length > 0
                        ? this.gridView.data.indexOf(this.gridView.data.find(function (model) { return model.Id === _this.selectedRows[0]; })) + 1
                        : 0;
                if (event.key === 'ArrowUp') {
                    rowIndex = rowIndex - 1;
                }
                else if (event.key === 'ArrowDown') {
                    rowIndex = rowIndex + 1;
                }
                if (rowIndex > this.gridView.data.length + 1) {
                    rowIndex = 1;
                }
                if (this.gridView.data[rowIndex - 1]) {
                    this.selectedRows = [this.gridView.data[rowIndex - 1].Id];
                }
                else {
                    this.selectedRows = [this.gridView.data[0].Id];
                    this.grid.focusCell(0, 0);
                }
            }
        }
        // if (event.key === 'ArrowDown') {
        //   this.selectedRows = this.gridView.data && this.gridView.data.length > 0 ? [this.gridView.data[0].Id] : [];
        //   this.grid.focusCell(0, 0);
        // }
        if (event.key === 'Escape') {
            this.visible = false;
        }
    };
    CompanySelectorComponent.prototype.onDialogEnter = function (event) {
        if (event.key === 'Enter') {
            if (this.selectedRows && this.selectedRows.length === 1) {
                var companyId = this.selectedRows[0];
                this.emitCompanyId(companyId);
                return;
            }
            if (this.gridView.data.length === 1) {
                var companyId = this.gridView.data[0].Id;
                this.emitCompanyId(companyId);
            }
            else {
                this.selectedRows = this.gridView.data && this.gridView.data.length > 0 ? [this.gridView.data[0].Id] : [];
                this.grid.focusCell(0, 0);
            }
        }
    };
    CompanySelectorComponent.prototype.onGridKeyDown = function (event) {
        var _this = this;
        if (event.key === 'Enter') {
            if (this.selectedRows && this.selectedRows.length > 0) {
                var companyId = this.selectedRows[0];
            }
        }
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            if (this.selectedRows && this.selectedRows.length > 0 && this.gridView.data && this.gridView.data.length > 0) {
                var rowIndex = this.grid.activeRow && this.grid.activeRow.index
                    ? this.grid.activeRow.index
                    : this.gridView.data.indexOf(this.gridView.data.find(function (model) { return model.Id === _this.selectedRows[0]; })) + 1;
                if (event.key === 'ArrowUp') {
                    rowIndex = rowIndex - 1;
                }
                else if (event.key === 'ArrowDown') {
                    rowIndex = rowIndex + 1;
                }
                if (rowIndex > this.gridView.data.length + 1) {
                    rowIndex = 1;
                }
                if (this.gridView.data[rowIndex - 1]) {
                    this.selectedRows = [this.gridView.data[rowIndex - 1].Id];
                }
                else {
                    this.selectedRows = [this.gridView.data[0].Id];
                    this.grid.focusCell(0, 0);
                }
            }
        }
        if (event.key === 'Escape') {
            this.visible = false;
        }
    };
    CompanySelectorComponent.prototype.onCellClick = function (event) {
        var companyId = event.dataItem.Id;
        this.emitCompanyId(companyId);
    };
    CompanySelectorComponent.prototype.pageChange = function (event) {
        this.skip = event.skip;
        this.loadItems();
    };
    CompanySelectorComponent.prototype.emitCompanyId = function (companyId) {
        var _this = this;
        setTimeout(function () {
            _this.companyChange.emit(companyId);
            _this.selectedCompanyId = companyId;
            _this.visible = false;
        });
    };
    CompanySelectorComponent.prototype.applyFilter = function (value) {
        if (this.companies) {
            if (value) {
                var searchText_1 = value.toLocaleLowerCase();
                this.gridData = this.companies.filter(function (c) {
                    return (c.VatRegistrationNumber && c.VatRegistrationNumber.toLocaleLowerCase().includes(searchText_1)) ||
                        c.DisplayName.toLocaleLowerCase().includes(searchText_1);
                });
            }
            else {
                this.gridData = this.companies;
            }
        }
        this.skip = 0;
        this.loadItems();
    };
    CompanySelectorComponent.prototype.loadItems = function () {
        this.gridView = {
            data: this.gridData.slice(this.skip, this.skip + this.pageSize),
            total: this.gridData.length
        };
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
    };
    return CompanySelectorComponent;
}());
export { CompanySelectorComponent };
