<!-- No Employment -->
<div class="Cards" *ngIf="!hasUserEmployment">
  <div class="Card">
    <div class="Card-inner">
      <h2 class="Card-title">{{ 'EmploymentPayrollData.No Employment' | translate }}</h2>
      <button-component
        buttonClass="Button--primary"
        *ngIf="allowEdit"
        (click)="createUserEmployment()"
        [label]="'EmployeeGeneral.Create employment' | translate"
      ></button-component>
    </div>
  </div>
</div>
<!-- /No Employment -->

<div *ngIf="hasUserEmployment">
  <!--<form id="employmentDetailForm" name="employmentDetailForm" novalidate onsubmit="return false;">-->
  <div class="Cards Cards--packed">
    <div *ngIf="!IsGreenlandCompany" class="Card">
      <div class="Card-inner">
        <additional-vacation
          [editMode]="editMode"
          *ngIf="!IsGreenlandCompany"
          [disable]="isAdditionalVacationDaysDisable"
          [(isValid)]="childIsValid[0]"
          [(isDirty)]="childIsDirty[0]"
          [(model)]="userEmployment"
          (valueChange)="onDataChanged()"
        ></additional-vacation>
      </div>
    </div>

    <div class="Card">
      <div class="Card-inner">
        <vacation
          [editMode]="editMode"
          [disable]="isVacationDisable"
          [(isValid)]="childIsValid[1]"
          [(isDirty)]="childIsDirty[1]"
          [(model)]="userEmployment"
          (valueChange)="onDataChanged()"
        ></vacation>
      </div>
    </div>

    <div class="Card">
      <div class="Card-inner">
        <time-and-absence
          [editMode]="editMode"
          [(model)]="userEmployment"
          [(isValid)]="childIsValid[2]"
          [(isDirty)]="childIsDirty[2]"
          (valueChange)="onDataChanged()"
        ></time-and-absence>
      </div>
    </div>

    <div class="Card" *ngIf="showMultiPurposeOffDayCompensation && !IsGreenlandCompany">
      <div class="Card-inner">
        <off-day-compensation
          [editMode]="editMode"
          [disable]="isMultiPurposeOffDayCompensationDisable"
          [(isValid)]="childIsValid[3]"
          [(isDirty)]="childIsDirty[3]"
          [(model)]="userEmployment"
          (valueChange)="onDataChanged()"
        ></off-day-compensation>
      </div>
    </div>

    <div class="Card">
      <div class="Card-inner">
        <tax-card
          id="employeeHiringStatusTaxCardView"
          [taxCard]="taxCardObject"
          [userEmployment]="userEmployment"
          [editMode]="editMode"
          [allowEdit]="allowEdit"
          [(isValid)]="childIsValid[4]"
          [(isDirty)]="childIsDirty[4]"
          (valueChange)="onDataChanged()"
          (reloadEmploymentEvent)="onDataChanged(true)"
          [(refreshTaxCard)]="triggerRefreshTaxCard"
          (requestTaxCard)="showRequestTaxcardDialog()"
          (viewTaxCardHistory)="showViewTaxCardHistory()"
          (requestTaxcardConfirm)="showRequestTaxcardConfirm($event)"
          [companyUser]="companyUser"
        ></tax-card>
      </div>
    </div>

    <div class="Card">
      <div class="Card-inner">
        <hiring-data
          [editMode]="editMode"
          [(userEmployment)]="userEmployment"
          [(isValid)]="childIsValid[5]"
          [(isDirty)]="childIsDirty[5]"
          (valueChange)="onDataChanged()"
        >
        </hiring-data>
      </div>
    </div>

    <div class="Card" *ngIf="showStatisticsSection">
      <div class="Card-inner">
        <employment-template-statistics
          [editMode]="editMode"
          [(isValid)]="childIsValid[6]"
          [(isDirty)]="childIsDirty[6]"
          [(statistics)]="userEmployment"
          (valueChange)="onDataChanged()"
        >
        </employment-template-statistics>
      </div>
    </div>

    <div class="Card" *ngIf="!IsGreenlandCompany">
      <div class="Card-inner">
        <tax-eincome
          [editMode]="editMode"
          [(model)]="userEmployment"
          [(isValid)]="childIsValid[8]"
          [(isDirty)]="childIsDirty[8]"
          (valueChange)="onDataChanged()"
        ></tax-eincome>
      </div>
    </div>

    <div
      class="Card"
      *ngIf="!SecondaryIncomeType && !editMode && !firstPensionProviderVisible && !secondPensionProviderVisible"
    >
      <div class="Card-inner">
        <form>
          <fieldset class="Form--horizontal">
            <legend class="Card-title">{{ 'EmployeeEmployment.Pension' | translate }}</legend>
            <div class="FormElement-static">{{ 'EmployeeEmployment.NoPension' | translate }}</div>
          </fieldset>
        </form>
      </div>
    </div>

    <div
      class="Card"
      id="employeeHiringStatusViewFirstPension"
      *ngIf="
        firstPensionProviderVisible || (editMode && (!firstPensionProviderVisible || secondPensionProviderVisible))
      "
    >
      <div class="Card-inner">
        <pension-provider
          [(model)]="userEmployment"
          [editMode]="editMode"
          [disable]="SecondaryIncomeType"
          [firstPension]="true"
          [(isValid)]="childIsValid[7]"
          [(isDirty)]="childIsDirty[7]"
          (valueChange)="onDataChanged()"
        ></pension-provider>
      </div>
    </div>

    <div
      class="Card"
      id="employeeHiringStatusViewSecondPension"
      *ngIf="secondPensionProviderVisible || (editMode && firstPensionProviderVisible)"
    >
      <div class="Card-inner">
        <pension-provider
          [(model)]="userEmployment"
          [editMode]="editMode"
          [disable]="SecondaryIncomeType"
          [firstPension]="false"
          [(isValid)]="childIsValid[9]"
          [(isDirty)]="childIsDirty[9]"
          (valueChange)="onDataChanged()"
        ></pension-provider>
      </div>
    </div>
  </div>
  <!--</form>-->
</div>

<!-- taxcard modal dialogs-->
<tax-card-request-dialog
  [(isVisible)]="requestTaxcardDialogVisible"
  [taxcardTypes]="taxCardObject?.taxCardTypes"
  [userEmploymentId]="taxCardObject?.userEmploymentId"
  (change)="onReloadTaxCard()"
></tax-card-request-dialog>

<view-tax-card-history-dialog
  [(isVisible)]="taxcardHistoryDialogVisible"
  [userEmploymentId]="taxCardObject?.userEmploymentId"
  (addNewTaxInfo)="onCreateTaxInfo($event)"
  (taxCardChange)="onReloadTaxCard()"
></view-tax-card-history-dialog>

<app-dialog
  [(visible)]="requestTaxcardWarningDialog"
  (action)="onRequestTaxcardWarningDialogAction($event)"
  [leadingText]="'EmployeeGeneral.RequestTaxCardForAlreadyRequested' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="AlreadyRequestedTaxCardConfirmButton"></app-dialog-action>
</app-dialog>
<!--modal dialogs-->
