import { IDynamicSalaryRecordImportColumn } from '../../../Services/ApiModel';
import { MappingObject } from './MappingObject';

export class ImportMappingDataItem {
  public userFileColumnHeader: string;
  public userFileId: string;
  public gratisalColumnsDropDownDataSource: IDynamicSalaryRecordImportColumn[] = [];
  public columnsTypeDropDownDataSource: MappingObject[] = [];
  private gratisalColumnsValue = '';
  private columnstypeValue: number = undefined;

  private isId: boolean;
  public get IsId(): boolean {
    return this.isId;
  }

  public get columnsTypeValue(): number {
    return this.columnstypeValue;
  }

  public set columnsTypeValue(value: number) {
    this.columnstypeValue = value;
  }

  get columnsTypeKey(): string {
    const id: number = this.columnsTypeValue;
    if (!id) {
      return '';
    }

    const columnTypeItem: MappingObject = this.findColumnTypeItem(id);
    if (!columnTypeItem) {
      return '';
    }

    return columnTypeItem.Key;
  }

  public get GratisalColumnsValue(): string {
    return this.gratisalColumnsValue;
  }

  public set GratisalColumnsValue(value: string) {
    this.gratisalColumnsValue = value;
    const gratisalColumn: IDynamicSalaryRecordImportColumn = this.findGratisalColumnItem(value);
    this.isId = gratisalColumn && gratisalColumn.IsId;
    if (this.isId) {
      //// set column type value undefined when it's disable
      this.columnsTypeValue = undefined;
    }
  }

  public setGratisalColumnDropDownDataSourceAndAutoMap(
    dataSource: IDynamicSalaryRecordImportColumn[],
    autoMap: boolean
  ): void {
    this.gratisalColumnsDropDownDataSource = dataSource;
    if (!autoMap) {
      return;
    }

    this.autoMapGratisalColumn();
  }

  public setColumnTypeDropDownDataSourceAndAutoMap(dataSource: MappingObject[]): void {
    this.columnsTypeDropDownDataSource = dataSource;
    //// this.autoMapColumnType();
  }

  private findGratisalColumnItem(value: string): IDynamicSalaryRecordImportColumn {
    const dataSource: IDynamicSalaryRecordImportColumn[] = this.gratisalColumnsDropDownDataSource;
    if (!value || !dataSource || dataSource.length === 0) {
      return undefined;
    }

    let result: IDynamicSalaryRecordImportColumn;
    if (dataSource) {
      result = dataSource.find((val: IDynamicSalaryRecordImportColumn): boolean => {
        return val.Value === value;
      });
    }

    return result;
  }

  public findColumnTypeItem(id: number): MappingObject {
    const dataSource: MappingObject[] = this.columnsTypeDropDownDataSource;
    if (!id || !dataSource || dataSource.length === 0) {
      return undefined;
    }

    let result: MappingObject;
    if (dataSource) {
      result = dataSource.find((val: MappingObject): boolean => {
        return val.Id === id;
      });
    }

    return result;
  }

  public findColumnTypeItemByKey(key: string): MappingObject {
    const dataSource: MappingObject[] = this.columnsTypeDropDownDataSource;
    if (!key || !dataSource || dataSource.length === 0) {
      return undefined;
    }

    let result: MappingObject;
    if (dataSource) {
      result = dataSource.find((val: MappingObject): boolean => {
        return val.Key === key;
      });
    }

    return result;
  }

  private autoMapGratisalColumn(): void {
    if (!this.gratisalColumnsDropDownDataSource) {
      return;
    }

    const gratisalColumn: IDynamicSalaryRecordImportColumn = this.findGratisalColumnByName(this.userFileColumnHeader);
    if (!gratisalColumn) {
      return;
    }

    this.GratisalColumnsValue = gratisalColumn.Value;
  }

  private findGratisalColumnByName(name: string): IDynamicSalaryRecordImportColumn {
    if (this.gratisalColumnsDropDownDataSource) {
      return this.gratisalColumnsDropDownDataSource.find(
        (ctype: IDynamicSalaryRecordImportColumn) => this.userFileColumnHeader === ctype.Name
      );
    }

    return undefined;
  }

  // private autoMapColumnType(): void {
  //     if (!this.columnsTypeDropDownDataSource) {
  //         return;
  //     }

  //     let columnType: MappingObject = this.findColumnTypeByName(
  //         this.userFileColumnHeader
  //     );

  //     if (!columnType) {
  //         return;
  //     }

  //     this.columnsTypeValue = columnType.Id;
  // }

  // private findColumnTypeByName(name: string): MappingObject {
  //     return this.columnsTypeDropDownDataSource.find(
  //         (ctype: MappingObject) => {
  //             return (
  //                 this.userFileColumnHeader &&
  //                 ctype.Name &&
  //                 this.userFileColumnHeader.trim().toLowerCase() ===
  //                     ctype.Name.trim().toLowerCase()
  //             );
  //         }
  //     );
  // }
}
