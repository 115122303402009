/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@progress/kendo-angular-l10n";
import * as i2 from "@progress/kendo-angular-tooltip";
import * as i3 from "@progress/kendo-angular-popup";
import * as i4 from "./toolTipPrice.component";
import * as i5 from "../../../../windowReference";
var styles_TooltippriceComponent = [];
var RenderType_TooltippriceComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TooltippriceComponent, data: {} });
export { RenderType_TooltippriceComponent as RenderType_TooltippriceComponent };
function View_TooltippriceComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.Tooltiptitle; _ck(_v, 0, 0, currVal_0); }); }
export function View_TooltippriceComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { tooltip: 0 }), (_l()(), i0.ɵand(0, [["template", 2]], null, 0, null, View_TooltippriceComponent_1)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "span", [["closable", "true"], ["kendoTooltip", ""], ["showOn", "none"]], [[1, "dir", 0]], null, null, null, null)), i0.ɵprd(256, null, i1.L10N_PREFIX, "kendo.tooltip", []), i0.ɵprd(131584, null, i1.LocalizationService, i1.LocalizationService, [i1.L10N_PREFIX, [2, i1.MessageService], [2, i1.RTL]]), i0.ɵdid(5, 9125888, [[1, 4], ["tooltip", 4]], 0, i2.TooltipDirective, [i0.ElementRef, i0.NgZone, i0.Renderer2, i3.PopupService, i1.LocalizationService, [2, i2.TooltipSettings], [2, i2.TOOLTIP_SETTINGS]], { showOn: [0, "showOn"], closable: [1, "closable"], tooltipWidth: [2, "tooltipWidth"], tooltipTemplate: [3, "tooltipTemplate"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, [["anchor", 1]], null, 0, "abbr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(i0.ɵnov(_v, 7)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, ":svg:svg", [["aria-hidden", "true"], ["class", "Icon Icon--medium u-middle"], ["ng-reflect-ng-class", "Icon u-middle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, ":svg:use", [[":xlink:href", "#info"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "none"; var currVal_2 = "true"; var currVal_3 = i0.ɵinlineInterpolate(1, "", _co.tooltipPackageWidth, ""); var currVal_4 = i0.ɵnov(_v, 1); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).direction; _ck(_v, 2, 0, currVal_0); }); }
export function View_TooltippriceComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tool-tip-price", [], null, null, null, View_TooltippriceComponent_0, RenderType_TooltippriceComponent)), i0.ɵdid(1, 49152, null, 0, i4.TooltippriceComponent, [i5.WindowReference], null, null)], null, null); }
var TooltippriceComponentNgFactory = i0.ɵccf("tool-tip-price", i4.TooltippriceComponent, View_TooltippriceComponent_Host_0, { Tooltiptitle: "Tooltiptitle", tooltipNumber: "tooltipNumber", isHideToolTip: "isHideToolTip" }, { show: "show", hide: "hide" }, []);
export { TooltippriceComponentNgFactory as TooltippriceComponentNgFactory };
