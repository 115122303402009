import { EventEmitter, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../../../Common/Global';
import { PayrolCommonl } from '../../../Common/payrolCommonl';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { SalaryRecordItemHelper } from '../Payroll/Controls/SalaryRecordItemHelper';
var EmployeePayrollDataMobileComponent = /** @class */ (function () {
    function EmployeePayrollDataMobileComponent(translateService, dataService, settingService, sessionService, renderer, staticDataService) {
        this.translateService = translateService;
        this.dataService = dataService;
        this.settingService = settingService;
        this.sessionService = sessionService;
        this.renderer = renderer;
        this.staticDataService = staticDataService;
        this.currentAddnew = false;
        this.isAddNewChange = new EventEmitter();
        this.DimensionXValue = [];
        this.showSelectPeriodDialog = false;
        this.recalculate = false;
        this.showInsertOrUpdatesalaryRecords = false;
        this.salaryRecordModel = {};
        this.flagUpdate = false;
        this.showNegativeDefaulChange = false;
        this.classShowSelectPeriodDialog = '';
        this.classoverlayScreen = '';
        this.isShowButtonPreview = true;
        this.confirmDeleteDialogVisible = false;
        this.isReloadBytype = false;
        this.ngUnsubscribe = new Subject();
        this.isHaveNavigate = false;
        this.isValidModel = false;
        this.negativeDefaultMoreDialogModel = {};
    }
    Object.defineProperty(EmployeePayrollDataMobileComponent.prototype, "userEmployment", {
        get: function () {
            return this.userEmploymentValue;
        },
        set: function (value) {
            if (this.userEmploymentValue !== value) {
                this.userEmploymentValue = value;
                if (value && value.Id) {
                    this.loadSalaryTypes();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataMobileComponent.prototype, "isAddNew", {
        get: function () {
            return this.currentAddnew;
        },
        set: function (value) {
            this.currentAddnew = value;
            this.isAddNewChange.emit(value);
            if (value) {
                this.onOpenCreateDialog();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataMobileComponent.prototype, "Dimension1Preference", {
        get: function () {
            return Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.find(function (pref) { return pref.Key === 'Dimensions.Dimension1Name'; })
                : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataMobileComponent.prototype, "Dimension2Preference", {
        get: function () {
            return Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.find(function (pref) { return pref.Key === 'Dimensions.Dimension2Name'; })
                : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataMobileComponent.prototype, "Dimension3Preference", {
        get: function () {
            return Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.find(function (pref) { return pref.Key === 'Dimensions.Dimension3Name'; })
                : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataMobileComponent.prototype, "isHasEmployeeTotal", {
        get: function () {
            if (this.totalButtonPreviewText) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataMobileComponent.prototype, "IsEnableDateAdjustment", {
        get: function () {
            var enableDateAdjustment = PayrolCommonl.getLocalStorageEnableDatePreferenceSetting();
            if (!enableDateAdjustment) {
                enableDateAdjustment = PayrolCommonl.createLocalStorageEnableDatePreferenceSetting(false);
            }
            var value = enableDateAdjustment.Value.toLowerCase();
            return value === 'true';
        },
        enumerable: true,
        configurable: true
    });
    EmployeePayrollDataMobileComponent.prototype.runEnableDateAdjustmentsRules = function () {
        var enableDateAdjustment = this.IsEnableDateAdjustment;
        this.showDateColumns(enableDateAdjustment);
    };
    EmployeePayrollDataMobileComponent.prototype.showDateColumns = function (show) {
        this.isHideDateColumns = !show;
    };
    Object.defineProperty(EmployeePayrollDataMobileComponent.prototype, "NumericOptions", {
        get: function () {
            if (this.numericOptions) {
                return this.numericOptions;
            }
            return (this.numericOptions = {
                format: 'n2',
                decimals: 2,
                step: 1,
                min: 0,
                spinners: false
            });
        },
        enumerable: true,
        configurable: true
    });
    EmployeePayrollDataMobileComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService
            .get(['EmploymentPayrollData.Per Unit', 'EmploymentPayrollData.Amount', 'EmploymentPayrollData.Units'])
            .subscribe(function (translations) {
            _this.perunitsText = translations['EmploymentPayrollData.Per Unit'];
            _this.amountText = translations['EmploymentPayrollData.Amount'];
            _this.unitText = translations['EmploymentPayrollData.Units'];
        });
    };
    EmployeePayrollDataMobileComponent.prototype.loadSalaryTypes = function () {
        var _this = this;
        this.salaryRecords = [];
        this.salaryTypes = [];
        this.disableGridToolbar = true;
        this.dataService.SalaryTypes_GetSalaryTypeViews().subscribe(function (salaryTypes) {
            if (_this.userEmploymentValue.IncomeTypeId === 5) {
                _this.salaryTypes = salaryTypes.filter(function (salaryType) { return salaryType.IsVisible && salaryType.RelatesToIncomeType5 !== false; });
            }
            else {
                _this.salaryTypes = salaryTypes.filter(function (salaryType) { return salaryType.IsVisible && !salaryType.RelatesToIncomeType5; });
            }
            _this.loadSalaryRecords();
        });
        if (this.Dimension1Preference && this.Dimension1Preference.Value) {
            this.dataService.Miscellaneous_GetDimensionValues(1).subscribe(function (data) {
                _this.DimensionXValue[0] = data.filter(function (value) { return value; });
            });
        }
        if (this.Dimension2Preference && this.Dimension2Preference.Value) {
            this.dataService.Miscellaneous_GetDimensionValues(2).subscribe(function (data) {
                _this.DimensionXValue[1] = data.filter(function (value) { return value; });
            });
        }
        if (this.Dimension3Preference && this.Dimension3Preference.Value) {
            this.dataService.Miscellaneous_GetDimensionValues(3).subscribe(function (data) {
                _this.DimensionXValue[2] = data.filter(function (value) { return value; });
            });
        }
    };
    EmployeePayrollDataMobileComponent.prototype.loadSalaryRecords = function () {
        var _this = this;
        if (!this.userEmployment) {
            return;
        }
        this.dataService
            .SalaryRecords_GetSalaryRecordsByEmployment(this.userEmployment.Id)
            .subscribe(function (salaryRecords) {
            _this.salaryRecords = salaryRecords;
            _this.disableGridToolbar = false;
            _this.translateText();
        });
    };
    EmployeePayrollDataMobileComponent.prototype.getNameByTypeId = function (typeId) {
        if (this.salaryTypes && this.salaryTypes.length > 0) {
            var typeObject = this.salaryTypes.find(function (z) { return z.SalaryTypeId === typeId; });
            if (typeObject && typeObject.Name) {
                return typeObject.Name;
            }
        }
        return '';
    };
    EmployeePayrollDataMobileComponent.prototype.convertDateToString = function (dateconvert) {
        if (dateconvert) {
            var currentdate = new Date(dateconvert);
            return this.sessionService.toString(currentdate);
        }
        return '';
    };
    EmployeePayrollDataMobileComponent.prototype.getStringStartDateAndEnddate = function (startdate, enddate) {
        if (startdate && enddate) {
            return this.convertDateToString(startdate) + ' - ' + this.convertDateToString(enddate);
        }
        return '';
    };
    EmployeePayrollDataMobileComponent.prototype.getUnitAndType = function (model) {
        if (this.unitTypes && this.unitTypes.length > 0 && model) {
            var unitObject = this.unitTypes.find(function (z) { return z.Id === model.UnitTypeId; });
            if (unitObject) {
                if (unitObject.Key === 'Percent') {
                    return model.BaseAmount ? this.formatunit(model.BaseAmount.toString()) + ' ' + unitObject.Name : '';
                }
                else {
                    return model.Units ? this.formatunit(model.Units.toString()) + ' ' + unitObject.Name : '';
                }
            }
        }
        return model && model.Units ? this.formatunit(model.Units.toString()) : '';
    };
    EmployeePayrollDataMobileComponent.prototype.getPerunit = function (model) {
        if (model) {
            if (this.unitTypes && this.unitTypes.length > 0) {
                var unitObject = this.unitTypes.find(function (z) { return z.Id === model.UnitTypeId; });
                if (unitObject) {
                    if (unitObject.Key === 'Percent') {
                        return model.Units ? this.formatunit(model.Units.toString()) + ' ' + this.unitText : '';
                    }
                    else {
                        return model.AmountPerUnit ? this.formatunit(model.AmountPerUnit.toString()) + ' ' + this.perunitsText : '';
                    }
                }
            }
            return model.AmountPerUnit ? this.formatunit(model.AmountPerUnit.toString()) + ' ' + this.perunitsText : '';
        }
        return '';
    };
    EmployeePayrollDataMobileComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    EmployeePayrollDataMobileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.unitTypes = Global.UNIT_TYPES;
        this.staticDataService.SalaryRecordPersistenceType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.persistanceTypes = data;
        });
    };
    EmployeePayrollDataMobileComponent.prototype.onCreateNewModel = function () {
        this.salaryRecordItemHelper = new SalaryRecordItemHelper(this.salaryRecordModel, this.renderer, this.persistanceTypes, this.salaryTypes, this.settingService);
        this.salaryRecordItemHelper.updateSalaryType(this.salaryTypes[0]);
        PayrolCommonl.assignEditableFields(this.salaryRecordModel, this.salaryTypes);
    };
    EmployeePayrollDataMobileComponent.prototype.onOpenCreateDialog = function () {
        this.flagUpdate = false;
        this.salaryRecordModel = {};
        this.onCreateNewModel();
        this.showInsertOrUpdatesalaryRecords = true;
        this.isReloadBytype = false;
    };
    EmployeePayrollDataMobileComponent.prototype.onOpenUpdateDialog = function (model) {
        this.flagUpdate = true;
        this.salaryRecordModel = model;
        PayrolCommonl.assignEditableFields(this.salaryRecordModel, this.salaryTypes);
        this.showInsertOrUpdatesalaryRecords = true;
        this.isReloadBytype = false;
    };
    EmployeePayrollDataMobileComponent.prototype.oncloseInsertOrUpdatesalaryRecords = function () {
        this.isAddNew = false;
        this.showInsertOrUpdatesalaryRecords = false;
        this.salaryRecordModel = {};
        this.loadSalaryRecords();
        this.recalculate = true;
        this.isReloadBytype = false;
        // setTimeout(() => {
        //     this.isReloadBytype = false;
        // })
    };
    EmployeePayrollDataMobileComponent.prototype.onValueControlChange = function (model, feildChange) {
        var _this = this;
        if (feildChange === 'SalaryTypeId' && this.isReloadBytype === false) {
            this.isReloadBytype = true;
            return;
        }
        if (model) {
            if (feildChange === 'SalaryTypeId') {
                var salaryTypesTemp = this.salaryTypes.filter(function (item) { return item.SalaryTypeId === _this.salaryRecordModel.SalaryTypeId; });
                if (salaryTypesTemp && salaryTypesTemp[0]) {
                    this.isHaveNavigate = salaryTypesTemp[0].NegativeDefault;
                }
            }
            var modifiedField = {};
            modifiedField[feildChange] = model[feildChange];
            this.salaryRecordItemHelper = new SalaryRecordItemHelper(model, this.renderer, this.persistanceTypes, this.salaryTypes, this.settingService);
            var valid = this.salaryRecordItemHelper.validate(modifiedField);
            if (!valid) {
                this.isValidModel = false;
                return;
            }
            this.isValidModel = true;
            var previousDataItem = JSON.parse(JSON.stringify(model));
            this.salaryRecordItemHelper.updateRelavantFields(modifiedField);
            PayrolCommonl.assignEditableFields(model, this.salaryTypes);
            var checkcheckAmountAutoFlip = PayrolCommonl.checkAmountAutoFlip(previousDataItem, model);
            if (feildChange &&
                (modifiedField[feildChange] === 'AmountPerUnit' || modifiedField[feildChange] === 'Amount') &&
                checkcheckAmountAutoFlip) {
                // this.salaryRecordItemHelper.showInCellPopupMessage(model, "Amount", feildChange);
                this.showNegativeDefaulChange = true;
            }
            this.dataBlur = model;
            switch (feildChange) {
                case 'Amount':
                    this.feildBlur = 'Amount';
                    if (this.isHaveNavigate) {
                        this.showNegativeDefaulChange = true;
                    }
                    break;
                case 'AmountPerUnit':
                    this.feildBlur = 'AmountPerUnit';
                    if (this.isHaveNavigate) {
                        this.showNegativeDefaulChange = true;
                    }
                    break;
                default:
                    break;
            }
        }
    };
    Object.defineProperty(EmployeePayrollDataMobileComponent.prototype, "isAutoFlipAmountsSetting", {
        get: function () {
            return PayrolCommonl.isAutoFlipAmountsSetting();
        },
        enumerable: true,
        configurable: true
    });
    EmployeePayrollDataMobileComponent.prototype.onNegativeDefaultMoreInfoClick = function (fieldName) {
        this.negativeDefaultMoreDialogModel = {
            setting: this.isAutoFlipAmountsSetting,
            fieldName: fieldName,
            dataItem: this.salaryRecordModel
        };
        this.showNegativeDefaultMoreInfoDialog = true;
    };
    EmployeePayrollDataMobileComponent.prototype.onMoreInfoUsePositiveAmount = function (args) {
        this.usePositiveAmount(args.dataItem, args.fieldName);
    };
    EmployeePayrollDataMobileComponent.prototype.usePositiveAmount = function (dataItem, fieldName) {
        this.salaryRecordItemHelper.usePositiveAmount(fieldName);
        this.onValueControlChange(dataItem, '');
    };
    EmployeePayrollDataMobileComponent.prototype.autoCorrect = function (args) {
        this.salaryRecordItemHelper.autoCorrect(args.fieldName);
        this.onValueControlChange(args.dataItem, '');
    };
    EmployeePayrollDataMobileComponent.prototype.onNegativeDefaulChange = function (event) {
        if (event) {
            switch (event) {
                case 'MoreInfo':
                    this.onNegativeDefaultMoreInfoClick(this.feildBlur);
                    break;
                case 'UsePositiveAmountThisTime':
                    this.usePositiveAmount(this.dataBlur, this.feildBlur);
                    break;
                default:
                    break;
            }
        }
    };
    EmployeePayrollDataMobileComponent.prototype.CreateNewModel = function () {
        var _this = this;
        if (this.salaryRecordModel) {
            var salaryRecord = PayrolCommonl.createSalaryRecord(this.salaryRecordModel, this.userEmployment.Id);
            this.dataService.SalaryRecords_CreateSalaryRecord(salaryRecord).subscribe(function (data) {
                _this.oncloseInsertOrUpdatesalaryRecords();
            }, function () {
                _this.oncloseInsertOrUpdatesalaryRecords();
            });
        }
    };
    EmployeePayrollDataMobileComponent.prototype.UpdateModel = function () {
        var _this = this;
        if (this.salaryRecordModel) {
            var salaryRecord = PayrolCommonl.createSalaryRecord(this.salaryRecordModel, this.userEmployment.Id);
            this.dataService.SalaryRecords_UpdateSalaryRecord(salaryRecord).subscribe(function (data) {
                _this.oncloseInsertOrUpdatesalaryRecords();
            }, function () {
                _this.oncloseInsertOrUpdatesalaryRecords();
            });
        }
    };
    EmployeePayrollDataMobileComponent.prototype.onShowSelectPeriodDialog = function () {
        var _this = this;
        if (this.classShowSelectPeriodDialog) {
            this.classShowSelectPeriodDialog = '';
            setTimeout(function () {
                _this.classoverlayScreen = '';
                _this.isShowButtonPreview = true;
            }, 200);
            return;
        }
        this.classoverlayScreen = 'overlayScreen-enable';
        setTimeout(function () {
            _this.classShowSelectPeriodDialog = 'PreviewGridMobileEnable';
        });
        this.isShowButtonPreview = false;
    };
    EmployeePayrollDataMobileComponent.prototype.formatunit = function (unitstring) {
        if (unitstring) {
            // if (Global.SESSION.SignOnToken.Language.Code === 'EN') {
            //   return this.sessionService.toString(parseFloat(unitstring), '0.00').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // }
            return this.sessionService.toString(parseFloat(unitstring), '0.00').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            // .replace(/.([^.]*)$/, ',$1');
        }
        return '';
    };
    EmployeePayrollDataMobileComponent.prototype.onClosePreviewArea = function () {
        this.classShowSelectPeriodDialog = '';
    };
    EmployeePayrollDataMobileComponent.prototype.DeleteModel = function () {
        var _this = this;
        if (this.salaryRecordModel && this.salaryRecordModel.Id) {
            this.dataService.SalaryRecords_DeleteSalaryRecord(this.salaryRecordModel.Id).subscribe(function () {
                _this.oncloseInsertOrUpdatesalaryRecords();
            }, function () {
                _this.oncloseInsertOrUpdatesalaryRecords();
            });
        }
    };
    Object.defineProperty(EmployeePayrollDataMobileComponent.prototype, "isHideUnitTypeControl", {
        get: function () {
            if (!this.salaryRecordModel.AllowEditUnitType &&
                (!this.salaryRecordModel.UnitTypeId ||
                    (this.salaryRecordModel.UnitTypeId && this.salaryRecordModel.UnitTypeId === -9999))) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    EmployeePayrollDataMobileComponent.prototype.onConfirmDeleteDialogAction = function (event) {
        if (event && event === 'Delete') {
            this.DeleteModel();
        }
    };
    return EmployeePayrollDataMobileComponent;
}());
export { EmployeePayrollDataMobileComponent };
