import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../Common/Constants';
import { Global } from '../../Common/Global';
import { PriceFormatPipe } from '../../Filters/PriceFormat.pipe';
import { AuthenticationService } from '../../Services/Authentication/AuthenticationService';
import { RxDataService } from '../../Services/RxDataService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
var ModuleCheckComponent = /** @class */ (function () {
    function ModuleCheckComponent(sessionService, staticDataService, dataService, authenticationService, priceFormatPipe) {
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.dataService = dataService;
        this.authenticationService = authenticationService;
        this.priceFormatPipe = priceFormatPipe;
        this.companyOption = false;
        this.showDialog = new EventEmitter();
        this.hasModuleChange = new EventEmitter();
        this.activateModuleDialogVisible = false;
        this.moduleActivatedDialogVisible = false;
        this.hasModuleValue = false;
        this.currentModules = [];
        this.moduleActivatedDialogMessage = 'AccountModules.ReloginForChanges';
        this.ngUnsubscribe = new Subject();
        this.IsGreenlandCompanyactivateModuleDialogVisible = false;
    }
    Object.defineProperty(ModuleCheckComponent.prototype, "hasModule", {
        get: function () {
            return this.hasModuleValue;
        },
        set: function (value) {
            if (value !== this.hasModuleValue) {
                this.hasModuleValue = value;
                // this.hasModuleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModuleCheckComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    ModuleCheckComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.moduleCompanyView
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (modules) {
            if (modules && modules.length > 0 && _this.currentModules.length === 0) {
                _this.currentModules = modules;
                _this.onGetModulesViewComplete();
            }
        });
    };
    ModuleCheckComponent.prototype.onGetModulesViewComplete = function () {
        var _this = this;
        var module = this.currentModules && this.currentModules.length
            ? this.currentModules.find(function (m) { return m.ModuleId === _this.moduleId; })
            : undefined;
        if (module) {
            this.hasModule = module.IsEnabled;
            this.modulePrice = module.ModulePrice;
            this.moduleName = module.ModuleName;
        }
        else {
            this.hasModule = false;
        }
        setTimeout(function () {
            _this.hasModuleChange.emit(_this.hasModule);
        });
    };
    ModuleCheckComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(ModuleCheckComponent.prototype, "translationParams", {
        //format the price before it gets passed as a translation param
        get: function () {
            return {
                moduleName: this.moduleName,
                modulePrice: this.priceFormatPipe.transform(this.modulePrice, true) === 'Price.Free'
                    ? '0,-'
                    : this.priceFormatPipe.transform(this.modulePrice, true)
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModuleCheckComponent.prototype, "isFullAdmin", {
        get: function () {
            return this.sessionService.role.IsFullAdmin;
        },
        enumerable: true,
        configurable: true
    });
    ModuleCheckComponent.prototype.onClick = function () {
        if (!this.hasModule) {
            if (!this.IsGreenlandCompany) {
                this.activateModuleDialogVisible = !this.noPopup;
                this.showDialog.emit();
            }
            else {
                this.IsGreenlandCompanyactivateModuleDialogVisible = true;
            }
        }
    };
    ModuleCheckComponent.prototype.dialogvisible = function () {
        return !this.noPopup && this.IsGreenlandCompany;
    };
    ModuleCheckComponent.prototype.reloadPage = function () {
        window.location.reload();
    };
    ModuleCheckComponent.prototype.onActivateModuleDialogAction = function (action) {
        var _this = this;
        switch (action) {
            case 'ViewPackages':
                this.sessionService.NavigateTo('tabs.company.modules');
                break;
            case 'ActivateModule':
                this.dataService.Modules_EnableModule(this.moduleId).subscribe(function (message) {
                    if (message) {
                        _this.moduleActivatedDialogMessage = message;
                    }
                    _this.staticDataService.loadStaticData().then(function () {
                        _this.moduleActivatedDialogVisible = true;
                    });
                }, function () {
                    // this.reloadPage();
                });
                break;
        }
    };
    ModuleCheckComponent.prototype.onIsGreenlandCompanyActivateModuleDialogAction = function (event) { };
    return ModuleCheckComponent;
}());
export { ModuleCheckComponent };
