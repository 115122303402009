/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../CustomControls/Dialog/Dialog.component.ngfactory";
import * as i2 from "../CustomControls/Dialog/Dialog.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../Services/SharedServices/Session/SessionService";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../CustomControls/PictureEdit.component.ngfactory";
import * as i7 from "../CustomControls/PictureEdit.component";
import * as i8 from "../Services/SharedServices/StaticData.service";
import * as i9 from "./ChangeAvatar.component";
import * as i10 from "../Services/RxDataService";
var styles_ChangeAvatarComponent = [];
var RenderType_ChangeAvatarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChangeAvatarComponent, data: {} });
export { RenderType_ChangeAvatarComponent as RenderType_ChangeAvatarComponent };
export function View_ChangeAvatarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "app-dialog", [["class", "Form--stacked ModalDialogInfo"], ["id", "avatarDialog"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.visible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DialogComponent_0, i1.RenderType_DialogComponent)), i0.ɵdid(1, 49152, null, 1, i2.DialogComponent, [i3.DomSanitizer, i4.SessionService, i0.ElementRef], { title: [0, "title"], width: [1, "width"], isAvatar: [2, "isAvatar"], overlayClose: [3, "overlayClose"], visible: [4, "visible"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 1, { buttonElements: 1 }), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, 0, 4, "app-picture-edit", [], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onAvatarChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_PictureEditComponent_0, i6.RenderType_PictureEditComponent)), i0.ɵdid(5, 49152, null, 0, i7.PictureEditComponent, [i4.SessionService, i8.StaticDataService], { editMode: [0, "editMode"], label: [1, "label"], changeText: [2, "changeText"], takePhotoText: [3, "takePhotoText"], isAvatar: [4, "isAvatar"], isEmployeeAvatar: [5, "isEmployeeAvatar"], imageSource: [6, "imageSource"] }, { change: "change" }), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, 0, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 3).transform("Account.ChooseUploadFile")); var currVal_1 = 350; var currVal_2 = true; var currVal_3 = false; var currVal_4 = _co.visible; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = true; var currVal_6 = i0.ɵunv(_v, 5, 1, i0.ɵnov(_v, 6).transform("Employee.Change picture")); var currVal_7 = i0.ɵunv(_v, 5, 2, i0.ɵnov(_v, 7).transform("Employee.Change picture")); var currVal_8 = i0.ɵunv(_v, 5, 3, i0.ɵnov(_v, 8).transform("Employee.Take photo")); var currVal_9 = true; var currVal_10 = true; var currVal_11 = _co.avatar; _ck(_v, 5, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_12 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform("Account.MaximumSizeOfProfilePictureMessage")); _ck(_v, 10, 0, currVal_12); }); }
export function View_ChangeAvatarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "changeavatar", [], null, null, null, View_ChangeAvatarComponent_0, RenderType_ChangeAvatarComponent)), i0.ɵdid(1, 49152, null, 0, i9.ChangeAvatarComponent, [i10.RxDataService, i4.SessionService], null, null)], null, null); }
var ChangeAvatarComponentNgFactory = i0.ɵccf("changeavatar", i9.ChangeAvatarComponent, View_ChangeAvatarComponent_Host_0, { visible: "visible", avatar: "avatar" }, { visibleChange: "visibleChange", avatarChanged: "avatarChanged" }, []);
export { ChangeAvatarComponentNgFactory as ChangeAvatarComponentNgFactory };
