<div class="MobileModal-mask" *ngIf="show"></div>
<div class="MobileModal" [ngClass]="{ 'is-active': transform }" *ngIf="show">
  <div class="MobileModal-header-wrap">
    <div>
      <span class="MobileModal-header-title">
        <ng-content select="mobile-modal-header"></ng-content>
      </span>

      <div class="Modal-xbuttonWrapper">
        <button class="" type="button" (click)="closeModal()">
          <app-icon [icon]="'Close'" [size]="'small'"></app-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="MobileModal-wrap">
    <div class="{{ customClass }}">
      <ng-content select="mobile-modal-content"></ng-content>
    </div>
  </div>

  <div class="MobileModal-footer-wrap">
    <!-- <button class="Button--secondary" (click)="raiseError()">Test Angular 6 error</button> -->
    <ng-content select="mobile-modal-footer"></ng-content>
  </div>
</div>
