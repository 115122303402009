import { CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WalkthroughModule } from 'angular-walkthrough';

import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';

import { NgProgressModule } from '@ngx-progressbar/core';

import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { UIRouterModule } from '@uirouter/angular';

import { DxDataGridModule, DxDateBoxModule, DxDropDownBoxModule } from 'devextreme-angular';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { MessageService } from '@progress/kendo-angular-l10n';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

import '@progress/kendo-angular-intl/locales/da-GL/all';
import '@progress/kendo-angular-intl/locales/da/all';
import '@progress/kendo-angular-intl/locales/en-GB/all';

import { environment } from '../environments/environment';
import { version } from '../version';
import { WindowReference } from '../windowReference';
import { AppComponent } from './app.component';

import { AccountantComponent } from './Accountant/Accountant.component';
import { AccountantAddNewCompanyDialogComponent } from './Accountant/Companies/AccountantAddNewCompanyDialog.component';
import { AccountantCompaniesComponent } from './Accountant/Companies/AccountantCompanies.component';
import { AccountantAddNewUserDialogComponent } from './Accountant/Users/AccountantAddNewUserDialog.component';
import { AccountantUsersComponent } from './Accountant/Users/AccountantUsers.component';
import { ApprovalComponent } from './Company/Approval/Approval.component';
import { CompanyComponent } from './Company/Company.component';
import { ConfigurationComponent } from './Company/Configuration/Configuration.component';
import { CompanyDataImportComponent } from './Company/Configuration/DataImport/CompanyDataImport.component';
import { CreateImportTemplateDialogComponent } from './Company/Configuration/DataImport/CreateImportTemplateDialog.component';
import { ImportErrorsDialogComponent } from './Company/Configuration/DataImport/ImportErrorsDialog.component';
import { ImportMappingDialogComponent } from './Company/Configuration/DataImport/ImportMappingDialog.component';
import { UploadImportFileDialogComponent } from './Company/Configuration/DataImport/UploadImportFileDialog.component';
import { IntegrationsComponent } from './Company/Configuration/Integrations/Integrations.component';
import { CompanySalaryTypesNgxComponent } from './Company/Configuration/SalaryTypes/CompanySalaryTypesNGX.component';
import { CompanySalaryTypeTranslationComponent } from './Company/Configuration/SalaryTypes/CompanySalaryTypeTranslation.component';
import { NewCustomSalaryTypeDialogComponent } from './Company/Configuration/SalaryTypes/NewCustomSalaryTypeDialog.component';
import { CompanyStandardSalaryTypesComponent } from './Company/Configuration/SimpleSalaryTypes/CompanyStandardSalaryTypes.component';
import { CompanyTemplatesComponent } from './Company/Configuration/Templates/CompanyTemplates.component';
import { CompanyTemplateEmployeeListComponent } from './Company/Configuration/Templates/Components/CompanyTemplateEmployee.component';
import { CompanyTemplateListComponent } from './Company/Configuration/Templates/Components/CompanyTemplateList.component';
import { AddTemplateComponent } from './Company/Configuration/Templates/Dialogs/AddTemplate.component';
import { OvertimeSupplementComponent } from './Company/Configuration/Templates/Dialogs/OvertimeSupplement.component';
import { SalaryTemplateComponent } from './Company/Configuration/Templates/Dialogs/SalaryTemplate.component';
import { SenioritySupplementComponent } from './Company/Configuration/Templates/Dialogs/SenioritySupplement.component';
import { NewCustomTimeEntryTypeDialogComponent } from './Company/Configuration/TimeEntryTypes/NewCustomTimeEntryTypeDialog.component';
import { TimeEntryTypeConfigurationsComponent } from './Company/Configuration/TimeEntryTypes/TimeEntryTypeConfigurations.component';
import { TimeEntryTypesNGXComponent } from './Company/Configuration/TimeEntryTypes/TimeEntryTypesNGX.component';
import { CompanyTimeEntryTypeTranslationComponent } from './Company/Configuration/TimeEntryTypes/TimeEntryTypeTranslation.component';
import { CompanyGeneralComponent } from './Company/General/CompanyGeneral.component';
import { CompanyContactComponent } from './Company/General/Components/CompanyContact.component';
import { CompanyDataComponent } from './Company/General/Components/CompanyData.component';
import { CompanyDepartmentComponent } from './Company/General/Components/CompanyDepartment.component';
import { CompanyOptionComponent } from './Company/General/Components/CompanyOption.component';
import { TestEmailComponent } from './Company/General/Dialogs/TestEmail.component';
import { CompanyModulesComponent } from './Company/Modules/CompanyModules.component';
import { AnonymousPriceComponent } from './Company/Modules/Price/AnonymousPrice.component';
import { ModuleComponent } from './Company/Modules/Price/Module.component';
import { ModulePackageComponent } from './Company/Modules/Price/ModulePackage.component';
import { PriceComponent } from './Company/Modules/Price/Price.component';
import { PriceSummaryComponent } from './Company/Modules/Price/PriceSummary.component';
import { TooltippriceComponent } from './Company/Modules/Price/toolTipPrice.component';
import { CompanyPayrollDataNGXComponent } from './Company/PayrollData/CompanyPayrollDataNGX.component';
import { SalaryTypeConfigurationDialogComponent } from './Company/PayrollData/SalaryTypeConfigurationDialog.component';
import { CompanySalaryBatchesComponent } from './Company/SalaryBatches/CompanySalaryBatches.component';
import { SalaryBatchPayslipDetailsComponent } from './Company/SalaryBatches/Components/Payslips/SalaryBatchPayslipDetails.component';
import { SalaryBatchPayslipIconsComponent } from './Company/SalaryBatches/Components/Payslips/SalaryBatchPayslipIcons.component';
import { SalaryBatchPayslipsComponent } from './Company/SalaryBatches/Components/Payslips/SalaryBatchPayslips.component';
import { SalaryBatchDetailsComponent } from './Company/SalaryBatches/Components/SalaryBatchDetails.component';
import { SalaryBatchesComponent } from './Company/SalaryBatches/Components/SalaryBatches.component';
import { SalaryBatchInfoComponent } from './Company/SalaryBatches/Components/SalaryBatchInfo.component';
import { SalaryBatchOtherErrorGridComponent } from './Company/SalaryBatches/Components/SalaryBatchOtherErrorDetails.component';
import { SalaryBatchSalaryTotalsComponent } from './Company/SalaryBatches/Components/Totals/SalaryBatchSalaryTotals.component';
import { SalaryBatchSalaryTypesComponent } from './Company/SalaryBatches/Components/Totals/SalaryBatchSalaryTypes.component';
import { SalaryBatchTimeAndVacationComponent } from './Company/SalaryBatches/Components/Totals/SalaryBatchTimeAndVacation.component';
import { BatchFinalizationStep2DialogueComponent } from './Company/SalaryBatches/Dialogs/BatchFinalizationStep2Dialogue.component';
import { BatchFinalizationStep3DialogueComponent } from './Company/SalaryBatches/Dialogs/BatchFinalizationStep3Dialogue.component';
import { BatchFinalizationStep4DialogueComponent } from './Company/SalaryBatches/Dialogs/BatchFinalizationStep4Dialogue.component';
import { LockSalaryBatchComponent } from './Company/SalaryBatches/Dialogs/LockSalaryBatch.component';
import { RevertPayslipComponent } from './Company/SalaryBatches/Dialogs/RevertPayslip.component';
import { RevertFinalizedBatchComponent } from './Company/SalaryBatches/Dialogs/RevertSalaryBatch.component';
import { SalaryBatchErrorsDialogComponent } from './Company/SalaryBatches/Dialogs/SalaryBatchErrorsDialog.component';
import { AutoCompleteTextBoxComponent } from './CustomControls/AutoCompleteTextBox.component';
import { ButtonComponent } from './CustomControls/Button.component';
import { ButtonPopUpComponent } from './CustomControls/ButtonPopUp.component';
import { CheckboxComponent } from './CustomControls/Checkbox.component';
import { ComboboxEditComponent } from './CustomControls/ComboboxEdit.component';
import { DatePickerControlComponent } from './CustomControls/DatePicker.component';
import { DialogComponent } from './CustomControls/Dialog/Dialog.component';
import { DialogActionComponent } from './CustomControls/Dialog/DialogAction.component';
import { DialogButtonsComponent } from './CustomControls/Dialog/DialogButtons.component';
import { TestDialogComponent } from './CustomControls/Dialog/TestDialog.component';
import { DropDownListFilterComponent } from './CustomControls/DropdownFilter.component';
import { FileUploadComponent } from './CustomControls/FileUpload.component';
import { CustomGridComponent } from './CustomControls/Grid/Grid.componnent';
import { GridActionComponent } from './CustomControls/Grid/GridAction.component';
import { GridColumnComponent } from './CustomControls/Grid/GridColumn.component';
import { GridColumnGroupComponent } from './CustomControls/Grid/GridColumnGroup.component';
import { GridInCellPopupComponent } from './CustomControls/Grid/GridInCellPopup.component';
import { IconComponent } from './CustomControls/Icon/Icon.component';
import { IconDefinitionsComponent } from './CustomControls/Icon/IconDefinitions.component';
import { LoadImageCountryComponent } from './CustomControls/loadImageCountry.component';
import { MobileModalComponent } from './CustomControls/MobileModal/MobileModal.component';
import { MobileModalContentComponent } from './CustomControls/MobileModal/MobileModalContent.component';
import { MobileModalFooterComponent } from './CustomControls/MobileModal/MobileModalFooter.component';
import { MobileModalHeaderComponent } from './CustomControls/MobileModal/MobileModalHeader.component';
import { MultiSelectComponent } from './CustomControls/MultiSelect.component';
import { NumericEditComponent } from './CustomControls/NumericEdit.component';
import { PayslipIconComponent } from './CustomControls/PayslipIcon.component';
import { PercentageEditComponent } from './CustomControls/PercentageEditControl.component';
import { PictureEditComponent } from './CustomControls/PictureEdit.component';
import { RadioEditComponent } from './CustomControls/Radio/RadioEdit.component';
import { RadioEditOptionComponent } from './CustomControls/Radio/RadioEditOption.compoenet';
import { ShareAmountComponent } from './CustomControls/ShareAmount.component';
import { SharePercentageComponent } from './CustomControls/SharePercentage.component';
import { TaxCardAddOnComponent } from './CustomControls/TaxCardAddOn.component';
import { TextEditUpdateComponent } from './CustomControls/TextEditUpdate.component';
import { TimePickerComponent } from './CustomControls/TimePicker.component';
import { DeleteEmployeeComponent } from './Employee/Dialogs/DeleteEmployee.component';
import { EmployeeComponent } from './Employee/Employee.component';
import { CprInfoComponent } from './Employee/EmployeeInfo/Controls/CprInfo.component';
import { EmployeeAvatarComponent } from './Employee/EmployeeInfo/Controls/EmployeeAvatar.component';
import { EmployeeNameComponent } from './Employee/EmployeeInfo/Controls/EmployeeName.component';
import { PositionInfoComponent } from './Employee/EmployeeInfo/Controls/PositionInfo.component';
import { EmployeeInfoComponent } from './Employee/EmployeeInfo/EmployeeInfo.component';
import { DepartmentFilterComponent } from './Employee/EmployeeList/Controls/DepartmentFilter.component';
import { SortByComponent } from './Employee/EmployeeList/Controls/SortBy.component';
import { EmployeeListComponent } from './Employee/EmployeeList/EmployeeList.component';
import { EmployeeTabsComponent } from './Employee/EmployeeTab/EmployeeTabs.component';
import { BankInfoComponent } from './Employee/EmployeeTab/General/Controls/BankInfo.component';
import { BenefitsComponent } from './Employee/EmployeeTab/General/Controls/Benefits.component';
import { ContactInfoComponent } from './Employee/EmployeeTab/General/Controls/ContactInfo.component';
import { EmployeeGeneralComponent } from './Employee/EmployeeTab/General/EmployeeGeneralView.component';
import { HiringDataComponent } from './Employee/EmployeeTab/HiringStatus/Controls/HiringData.component';
import { TaxCardComponent } from './Employee/EmployeeTab/HiringStatus/Controls/TaxCard.component';
import { VacationComponent } from './Employee/EmployeeTab/HiringStatus/Controls/Vacation.component';
import { TaxCardRequestDialogComponent } from './Employee/EmployeeTab/HiringStatus/Dialogs/TaxCardRequestDialog.component';
import { ViewTaxCardHistoryDialogComponent } from './Employee/EmployeeTab/HiringStatus/Dialogs/ViewTaxCardHistoryDialog.component';
import { EmployeeHiringStatusComponent } from './Employee/EmployeeTab/HiringStatus/EmployeeHiringStatus.component';
import { PayrollDataGridComponent } from './Employee/EmployeeTab/Payroll/Controls/PayrollDataGrid.component';
import { PayrollPreviewComponent } from './Employee/EmployeeTab/Payroll/Controls/PayrollPreview.component';
import { NegativeDefaultMoreInfoDialogComponent } from './Employee/EmployeeTab/Payroll/Dialogs/NegativeDefaultMoreInfoDialog.component';
import { EmployeePayrollDataComponent } from './Employee/EmployeeTab/Payroll/EmployeePayrollData.component';
import { EmployeePayrollDataMobileComponent } from './Employee/EmployeeTab/Payroll/EmployeePayrollDataMobile.component';
import { PayslipComponent } from './Employee/EmployeeTab/Payslip/Payslip.component';
import { PayslipMobileComponent } from './Employee/EmployeeTab/Payslip/PayslipMobile.component';
import { TabPanelComponent } from './Employee/EmployeeTab/TabPanel.component';
import { EmployeeTimeComponent } from './Employee/EmployeeTab/Time/EmployeeTime.component';
import { EmployeeTimeMobileComponent } from './Employee/EmployeeTab/Time/EmployeeTimeMobile.component';
import { NewEmploymentComponent } from './Employee/NewEmployment/NewEmployment.component';
import { NewEmployeeComponent } from './Employee/NewUser/NewEmployee.component';
import { CPRFormatPipe } from './Filters/CPRFormat.pipe';
import { DateFormatPipe } from './Filters/DateFormat.pipe';
import { KeepHtmlPipe } from './Filters/KeepHtml.pipe';
import { NumericFormatPipe } from './Filters/NumericFormat.pipe';
import { PriceFormatPipe } from './Filters/PriceFormat.pipe';
import { ToJsonPipe } from './Filters/ToJson.pipe';
import { AcceptDPAComponent } from './Main/AcceptDPA.component';
import { ChangeAvatarComponent } from './Main/ChangeAvatar.component';
import { CompanySelectorComponent } from './Main/CompanySelector.component';
import { LanguageChooserComponent } from './Main/LanguageChooser.component';
import { LoadingTipsComponent } from './Main/LoadingTips.component';
import { ChangePasswordComponent } from './Main/Login/ChangePassword.component';
import { ChangeTemporaryPasswordComponent } from './Main/Login/ChangeTemporaryPassword.component';
import { LoginComponent } from './Main/Login/Login.component';
import { ReloginComponent } from './Main/Login/Relogin.component';
import { ResetPasswordComponent } from './Main/Login/ResetPassword.component';
import { PreferenceComponent } from './Main/Preference.component';
import { TabsComponent } from './Main/Tabs.Component';
import { routerConfig, routerStates } from './router.config';
import { SelfServicePayslipComponent } from './SelfService/Payslip/SelfServicePayslip.component';
import { SelfServiceComponent } from './SelfService/SelfService.component';
import { AuthenticationService } from './Services/Authentication/AuthenticationService';
import { AuthenticationTokenInterceptor } from './Services/Authentication/AuthenticationToken.interceptor';
import { Broadcaster } from './Services/Broadcaster';
import { BusyInterceptor } from './Services/busy.interceptor';
import { ErrorInterceptor } from './Services/error.interceptor';
import { LogInterceptor } from './Services/log.interceptor';
import { RxApiDataService } from './Services/rx-data.service';
import { RxDataService } from './Services/RxDataService';
import { BusyIndicatorService } from './Services/SharedServices/BusyIndicatorService';
import { IFrameDialogComponent } from './Services/SharedServices/Dialog/IFrameDialog.component';
import { DownloadService } from './Services/SharedServices/DownloadService';
import { KendoTranslateService } from './Services/SharedServices/KendoTranslateService';
import { MissingTranslationService } from './Services/SharedServices/MissingTranslationService';
import { ModalService } from './Services/SharedServices/ModalService';
import { NotTranslatedService } from './Services/SharedServices/NotTranslatedService';
import { SessionService } from './Services/SharedServices/Session/SessionService';
import { SettingService } from './Services/SharedServices/SettingService';
import { StaticDataService } from './Services/SharedServices/StaticData.service';
import { UploadService } from './Services/SharedServices/UploadService';
import { AccountComponent } from './SharedComponents/Account.component';
import { ActionButtonComponent } from './SharedComponents/Actions/ActionButton.component';
import { EditButtonComponent } from './SharedComponents/Actions/EditButton.component';
import { AdditionalVacationComponent } from './SharedComponents/AdditionalVacation/AdditionalVacation.component';
import { AddressComponent } from './SharedComponents/AddressComponent';
import { HelpComponent } from './SharedComponents/Help/Help.component';
import { FeedbackComponent } from './SharedComponents/Info/Feedback.component';
import { ModuleCheckComponent } from './SharedComponents/ModuleCheck/ModuleCheck.component';
import { MoreMenuComponent } from './SharedComponents/More/MoreMenu.component';
import { OffDayCompensationComponent } from './SharedComponents/MultiPurposeCompensation/OffDayCompensation.component';
import { PensionProviderComponent } from './SharedComponents/Pension/PensionProvider.component';
import { ReportDialogComponent } from './SharedComponents/ReportsDialog/ReportDialog.component';
import { GetStartedComponent } from './SharedComponents/Startup/GetStarted.component';
import { StatisticsComponent } from './SharedComponents/Statistic/Statistics.component';
import { TaxComponent } from './SharedComponents/Tax/Tax.component';
import { TaxEIncomeComponent } from './SharedComponents/TaxEIncome/TaxEIncome.component';
import { TimeAndAbsenceComponent } from './SharedComponents/TimeAndAbsence/TimeAndAbsence.component';

import * as Raven from 'raven-js';
import { RavenErrorHandler } from './RavenErrorHandler';

Raven.config(environment.sentryDSN)
  .setRelease(version)
  .setEnvironment(environment.environment)
  .install();

import localeDa from '@angular/common/locales/da';
import { CustomLoader } from './Common/CustomLoader';
import { CheckboxGridComponent } from './CustomControls/CheckboxGrid.component';
registerLocaleData(localeDa);

@NgModule({
  imports: [
    CommonModule,
    TooltipModule,
    LayoutModule,
    BrowserModule,
    PopupModule,
    // RouterModule.forRoot([], { initialNavigation: false }),
    HttpClientModule,
    NgProgressModule.withConfig({
      trickleSpeed: 400,
      speed: 300
    }),
    FormsModule,
    DropDownsModule,
    InputsModule,
    DialogModule,
    IntlModule,
    DateInputsModule,
    BrowserModule,
    BrowserAnimationsModule,
    GridModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
        useClass: CustomLoader
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationService,
        deps: [NotTranslatedService]
      }
    }),
    UIRouterModule.forRoot({
      states: routerStates,
      useHash: true,
      config: routerConfig
    }),
    WalkthroughModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxDataGridModule
  ],
  providers: [
    { provide: MessageService, useClass: KendoTranslateService },
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    WindowReference,
    { provide: LOCALE_ID, useValue: 'da' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BusyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    Broadcaster,
    AuthenticationService,
    SessionService,
    ModalService,
    DownloadService,
    UploadService,
    BusyIndicatorService,
    SettingService,
    StaticDataService,
    RxApiDataService,
    RxDataService,
    PriceFormatPipe,
    File,
    FileOpener,
    FileChooser,
    FileTransfer,
    FilePath
  ],
  declarations: [
    AppComponent,
    PercentageEditComponent,
    ButtonComponent,
    PictureEditComponent,
    AdditionalVacationComponent,
    SenioritySupplementComponent,
    OvertimeSupplementComponent,
    TimeEntryTypeConfigurationsComponent,
    MobileModalComponent,
    MobileModalHeaderComponent,
    MobileModalFooterComponent,
    MobileModalContentComponent,
    CustomGridComponent,
    GridColumnGroupComponent,
    GridColumnComponent,
    NumericEditComponent,
    AutoCompleteTextBoxComponent,
    CheckboxComponent,
    CheckboxGridComponent,
    TimePickerComponent,
    TextEditUpdateComponent,
    ComboboxEditComponent,
    ShareAmountComponent,
    SharePercentageComponent,
    DatePickerControlComponent,
    PayslipIconComponent,
    TaxCardAddOnComponent,
    ActionButtonComponent,
    EditButtonComponent,
    DialogComponent,
    DialogActionComponent,
    DialogButtonsComponent,
    TestDialogComponent,
    HelpComponent,
    MoreMenuComponent,
    OffDayCompensationComponent,
    TestEmailComponent,
    TimeAndAbsenceComponent,
    FeedbackComponent,
    TaxEIncomeComponent,
    TaxComponent,
    StatisticsComponent,
    PensionProviderComponent,
    CompanyContactComponent,
    CompanyDataComponent,
    CompanyOptionComponent,
    LanguageChooserComponent,
    CompanyDepartmentComponent,
    IntegrationsComponent,
    DepartmentFilterComponent,
    SortByComponent,
    CompanyGeneralComponent,
    AddressComponent,
    BankInfoComponent,
    BenefitsComponent,
    ContactInfoComponent,
    VacationComponent,
    HiringDataComponent,
    PreferenceComponent,
    AccountComponent,
    TaxCardComponent,
    TaxCardRequestDialogComponent,
    CPRFormatPipe,
    DateFormatPipe,
    NumericFormatPipe,
    PriceFormatPipe,
    CompanySalaryTypeTranslationComponent,
    NewCustomSalaryTypeDialogComponent,
    TooltippriceComponent,
    CompanyTimeEntryTypeTranslationComponent,
    NewCustomTimeEntryTypeDialogComponent,
    AddTemplateComponent,
    SalaryTemplateComponent,
    GridActionComponent,
    ViewTaxCardHistoryDialogComponent,
    EmployeeHiringStatusComponent,
    CompanyTemplateEmployeeListComponent,
    CompanyTemplateListComponent,
    CompanyTemplatesComponent,
    EmployeeGeneralComponent,
    NewEmploymentComponent,
    EmployeeAvatarComponent,
    EmployeeNameComponent,
    PositionInfoComponent,
    CprInfoComponent,
    RadioEditComponent,
    RadioEditOptionComponent,
    EmployeeInfoComponent,
    NegativeDefaultMoreInfoDialogComponent,
    ChangePasswordComponent,
    SalaryBatchOtherErrorGridComponent,
    IconComponent,
    ModuleComponent,
    ModulePackageComponent,
    PriceSummaryComponent,
    PriceComponent,
    TabPanelComponent,
    CompanyModulesComponent,
    EmployeeTimeMobileComponent,
    EmployeeListComponent,
    NewEmployeeComponent,
    ModuleCheckComponent,
    AcceptDPAComponent,
    KeepHtmlPipe,
    GetStartedComponent,
    ReportDialogComponent,
    ToJsonPipe,
    ChangeAvatarComponent,
    DeleteEmployeeComponent,
    ChangeTemporaryPasswordComponent,
    PayslipComponent,
    PayslipMobileComponent,
    RevertPayslipComponent,
    CompanySalaryTypesNgxComponent,
    SelfServicePayslipComponent,
    ReloginComponent,
    SalaryBatchSalaryTotalsComponent,
    PayrollDataGridComponent,
    ResetPasswordComponent,
    SalaryBatchesComponent,
    SalaryBatchErrorsDialogComponent,
    LockSalaryBatchComponent,
    RevertFinalizedBatchComponent,
    SalaryBatchDetailsComponent,
    SalaryBatchInfoComponent,
    SalaryBatchPayslipsComponent,
    SalaryBatchPayslipDetailsComponent,
    SalaryBatchPayslipIconsComponent,
    SalaryBatchTimeAndVacationComponent,
    SalaryBatchSalaryTypesComponent,
    BatchFinalizationStep4DialogueComponent,
    AnonymousPriceComponent,
    BatchFinalizationStep3DialogueComponent,
    BatchFinalizationStep2DialogueComponent,
    CompanySalaryBatchesComponent,
    TimeEntryTypesNGXComponent,
    LoginComponent,
    ImportErrorsDialogComponent,
    ImportMappingDialogComponent,
    CreateImportTemplateDialogComponent,
    UploadImportFileDialogComponent,
    CompanyDataImportComponent,
    EmployeeTimeComponent,
    FileUploadComponent,
    GridInCellPopupComponent,
    IconDefinitionsComponent,
    ConfigurationComponent,
    SelfServiceComponent,
    ApprovalComponent,
    CompanyComponent,
    PayrollPreviewComponent,
    DropDownListFilterComponent,
    EmployeePayrollDataComponent,
    EmployeeTabsComponent,
    EmployeeComponent,
    TabsComponent,
    CompanyPayrollDataNGXComponent,
    SalaryTypeConfigurationDialogComponent,
    CompanyStandardSalaryTypesComponent,
    EmployeePayrollDataMobileComponent,
    LoadingTipsComponent,
    ButtonPopUpComponent,
    AccountantComponent,
    AccountantCompaniesComponent,
    AccountantUsersComponent,
    CompanySelectorComponent,
    AccountantAddNewCompanyDialogComponent,
    AccountantAddNewUserDialogComponent,
    IFrameDialogComponent,
    LoadImageCountryComponent,
    MultiSelectComponent
  ],
  entryComponents: [
    TabsComponent,
    EmployeeComponent,
    CompanyComponent,
    SelfServiceComponent,
    LoginComponent,
    AccountComponent,
    AccountantComponent,
    IFrameDialogComponent,
    DialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'translation/' + version + '-locale.', '.json');
}
