<div class="Cards Cards--flex">
  <div class="Card Form--stacked">
    <div class="Card-inner">
      <div class="FormElement">
        <div class="grid-header Company-salaryTotalsGrid">
          <grid
            (selectedItemChange)="onSelectedUnitTotalChange($event)"
            [data]="UnitTotals"
            [idColumn]="'Key'"
            [(selectedItem)]="selectedUnitTotals"
            [editMode]="false"
          >
            <gridColumn
              [field]="'Name'"
              [width]="200"
              [title]="'CompanySalaryBatches.TimeGridField Name' | translate"
              [type]="'text'"
            ></gridColumn>
            <gridColumn
              [field]="'Amount'"
              [width]="100"
              [title]="'CompanySalaryBatches.TimeGridField Amount' | translate"
              [type]="'numeric'"
            ></gridColumn>
          </grid>
        </div>
      </div>
    </div>
  </div>

  <div class="Card Form--stacked">
    <div class="Card-inner">
      <div class="FormElement">
        <div class="FormElement-grid Company-salaryTotalsGrid">
          <grid [data]="unitTotalsPerEmployee" [editMode]="false" (dblClick)="onDoubleClick($event)">
            <gridColumn
              [field]="'FullName'"
              [width]="200"
              [title]="'CompanySalaryBatches.TimeMiscDetailGridField Name' | translate"
              [type]="'text'"
            ></gridColumn>
            <gridColumn
              [field]="'AmountSum'"
              [width]="100"
              [title]="'CompanySalaryBatches.TimeMiscDetailGridField Value' | translate"
              [type]="'numeric'"
            ></gridColumn>
          </grid>
        </div>
      </div>
    </div>
  </div>
</div>
