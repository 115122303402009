import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../../Common/FormComponentBase';
import { Global } from '../../../../Common/Global';
import {
  ISalaryCycle,
  ITemplateCreationRequest,
  IUserEmploymentTemplate,
  IUserEmploymentView,
  UserEmploymentTemplate
} from '../../../../Services/ApiModel';
import { RxDataService } from '../../../../Services/RxDataService';
import { SessionService } from '../../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'add-template',
  templateUrl: './AddTemplate.component.html'
})
export class AddTemplateComponent extends FormComponentBase implements OnInit {
  private visibleValue = false;
  @Input()
  get visible(): boolean {
    return this.visibleValue;
  }
  set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
      this.templateName = '';
      this.salaryCycleId = this.salaryCycles && this.salaryCycles.length > 0 ? this.salaryCycles[0].Id : undefined;
      this.filterUserEmploymentBaseOnCycle();
    }
  }
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public addTemplateComplete: EventEmitter<IUserEmploymentTemplate> = new EventEmitter<
    IUserEmploymentTemplate
  >();

  public templateName: string;
  public salaryCycleId: number;
  public salaryCycles: ISalaryCycle[];
  public userEmploymentId: number;
  public userEmployements: IUserEmploymentView[] = [];
  public warningDialogVisible = false;

  private userEmploymentView: IUserEmploymentView[];

  constructor(
    private dataService: RxDataService,
    public sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.staticDataService.SalaryCycle.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalaryCycle[]) => (this.salaryCycles = data)
    );
    this.dataService.Employments_GetEmploymentViews().subscribe((userEmployments: IUserEmploymentView[]): void => {
      this.userEmploymentView = userEmployments;
      this.filterUserEmploymentBaseOnCycle();
    });
  }

  public onDialogAction(action: string): void {
    if (action === 'Create') {
      if (!this.templateName) {
        this.warningDialogVisible = true;
        return;
      }

      const userEmploymentTemplate: IUserEmploymentTemplate = this.createNewUserEmploymentObject(
        this.templateName,
        this.salaryCycleId,
        Global.COMPANY_ID
      );
      this.createEmploymentTemplate(userEmploymentTemplate);
    }
  }

  public onSalaryCycleChanged(): void {
    this.filterUserEmploymentBaseOnCycle();
  }

  private filterUserEmploymentBaseOnCycle(): void {
    if (this.salaryCycleId && this.userEmploymentView) {
      this.userEmployements = this.userEmploymentView.filter(
        (item: IUserEmploymentView) => item.SalaryCycleId.toString() === this.salaryCycleId.toString()
      );
    }

    this.userEmploymentId = undefined;
  }

  private createEmploymentTemplate(userEmploymentTemplate: IUserEmploymentTemplate): void {
    if (this.userEmploymentId) {
      const request: ITemplateCreationRequest = {
        BasedOnEmploymentId: this.userEmploymentId,
        Name: userEmploymentTemplate.Name
      };
      this.dataService
        .EmploymentTemplates_CreateTemplateFromEmployment(request)
        .subscribe((template: IUserEmploymentTemplate): void => {
          this.addTemplateComplete.emit(template);
          this.visible = false;
        });
    } else {
      this.dataService
        .EmploymentTemplates_CreateEmploymentTemplate(userEmploymentTemplate)
        .subscribe((template: IUserEmploymentTemplate): void => {
          this.addTemplateComplete.emit(template);
          this.visible = false;
        });
    }
  }

  private createNewUserEmploymentObject(
    templateName: string,
    salaryCycleId: number,
    companyId: number
  ): IUserEmploymentTemplate {
    const newUserEmploymentTemplate: IUserEmploymentTemplate = new UserEmploymentTemplate();
    newUserEmploymentTemplate.Name = templateName;
    newUserEmploymentTemplate.CompanyId = companyId;
    newUserEmploymentTemplate.FFAutoPayout = false;
    newUserEmploymentTemplate.SalaryCycleId = salaryCycleId;
    return newUserEmploymentTemplate;
  }
}
