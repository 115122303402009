import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { WindowReference } from '../../../../windowReference';
import { GridHelper } from '../../../Common/GridHelper';
import { IModuleCompanyView, ModuleCompanyView } from '../../../Services/ApiModel';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { ModulePackage } from './ModulePackage';

@Component({
  selector: 'price',
  templateUrl: './Price.component.html',
  styleUrls: ['./Price.component.less']
})
export class PriceComponent {
  @Input() public usersMonthly = 0;
  @Input() public users14Day = 0;
  @Input() public usersWeekly = 0;
  @Input() public isHideToolTip = false;

  private modulesValue: IModuleCompanyView[] = [];
  @Input()
  public get modules(): IModuleCompanyView[] {
    return this.modulesValue;
  }
  public set modules(value: IModuleCompanyView[]) {
    this.modulesValue = value;
    this.buildPackages().then(() => {
      if (value.length > 0) {
        this.isLoading = false;
      }
    });
  }

  @Output() public submit: EventEmitter<number> = new EventEmitter<number>();

  public get IsMobile(): boolean {
    return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
  }

  private currentCompanyClick = false;

  @Input()
  public get companyClick(): boolean {
    return this.currentCompanyClick;
  }
  public set companyClick(value: boolean) {
    if (value) {
      this.isHideToolTip = !this.isHideToolTip;
    }
    this.companyClickChange.emit(false);
  }

  @Output() private companyClickChange: EventEmitter<any> = new EventEmitter<any>();

  public submitEnabled = false;
  public packages: ModulePackage[] = [];
  public priceElements: string[] = [];
  public totalCost = 0;
  public initialModules: number[];
  public isLoading = true;
  public calculatedPricePerPayslip = 0;

  constructor(
    private windowRef: WindowReference,
    private sessionService: SessionService,
    private elementRef: ElementRef
  ) {}

  public onEmployeesChange(): void {
    this.updateTotal();
  }

  public onSubmit(): void {
    this.submit.emit(this.selectedPackageId);
  }

  public onPackageChange(packageId: number): void {
    const modulePackage: ModulePackage = this.packages.find((p: ModulePackage) => p.PackageId === packageId);
    modulePackage.Selected = !modulePackage.Selected;
    if (modulePackage.Selected) {
      this.selectPackage(modulePackage);
    } else {
      this.deSelectPackage(modulePackage);
    }
    this.updateTotal();
  }

  public automaticReselectOfPackages(activate: boolean): void {
    const nonExtraPackages: ModulePackage[] = this.packages
      .filter((p: ModulePackage) => p.PackageId !== 9999)
      .sort((a: ModulePackage, b: ModulePackage) => GridHelper.sortByNumberValue(a.Price, b.Price))
      .reverse();
    const nonExtraModulesArrays: IModuleCompanyView[][] = nonExtraPackages.map((mp: ModulePackage) => mp.Modules);
    const nonExtraModules: IModuleCompanyView[] = [].concat.apply([], nonExtraModulesArrays);

    if (activate) {
      // Logic to select package if price of modules exceeds pack price
      let selectPackageId: number;
      for (let index = 0; index <= nonExtraPackages.length - 1; index++) {
        const p: ModulePackage = nonExtraPackages[index];
        if (
          !nonExtraModules.some(
            (module: IModuleCompanyView) =>
              module.PackageId === p.PackageId && module.IsEnabled && module.ModulePrice > 0
          )
        ) {
          continue;
        }

        const packageSelectedModulesPrice: number = this.getPackageSelectedModulesPrice(
          nonExtraPackages,
          nonExtraModules,
          index
        );
        if (packageSelectedModulesPrice >= p.Price) {
          if (!p.Selected) {
            selectPackageId = p.PackageId;
            this.showAutoSelectPackageHint();
          }
          break;
        }
      }

      if (selectPackageId && selectPackageId !== 0) {
        this.selectPackage(this.getPackage(selectPackageId));
      }
    } else {
      this.showAutoSelectPackageHint(false);
      let selectPackage: ModulePackage;
      for (let index = 0; index <= nonExtraPackages.length - 1; index++) {
        const p: ModulePackage = nonExtraPackages[index];
        if (
          nonExtraModules
            .filter((m: IModuleCompanyView) => m.PackageId <= p.PackageId)
            .every((m: IModuleCompanyView) => m.IsEnabled)
        ) {
          selectPackage = p;
          break;
        }
      }

      let deselectPackage: ModulePackage = nonExtraPackages.find((p: ModulePackage) => p.Selected);
      deselectPackage === undefined ? (deselectPackage = undefined) : (deselectPackage.Selected = false);

      if (selectPackage !== null && selectPackage !== undefined) {
        selectPackage.Selected = true;
      }
    }
  }

  private getPackageSelectedModulesPrice(
    packages: ModulePackage[],
    nonExtraModules: IModuleCompanyView[],
    index: number
  ): number {
    let packagePrice = 0;
    const modulePackage: ModulePackage = index < packages.length ? packages[index] : undefined;
    if (modulePackage) {
      if (
        nonExtraModules
          .filter((fm: IModuleCompanyView) => fm.PackageId <= modulePackage.PackageId)
          .every((m: IModuleCompanyView) => m.IsEnabled)
      ) {
        packagePrice += modulePackage.Price;
      } else {
        nonExtraModules
          .filter((m: IModuleCompanyView) => m.PackageId === modulePackage.PackageId && m.IsEnabled)
          .forEach((m: IModuleCompanyView) => {
            packagePrice += m.ModulePrice;
          });

        packagePrice += this.getPackageSelectedModulesPrice(packages, nonExtraModules, index + 1);
      }
    }

    return packagePrice;
  }

  public AutoSelectPackageActive: boolean;
  private showAutoSelectPackageHint(active: boolean = true): void {
    this.AutoSelectPackageActive = active;
    ////if (active) {
    ////    setTimeout(() => { this.AutoSelectPackageActive = false; }, 7000);
    ////}
  }

  public onModuleChange(moduleId: number): void {
    const module: IModuleCompanyView = this.modules.find((m: IModuleCompanyView) => m.ModuleId === moduleId);
    if (module.InfoOnly === true || module.FreeUntilDate !== null) {
      return;
    }
    module.IsEnabled = !module.IsEnabled;

    const parentPackage: ModulePackage = this.packages.find((p: ModulePackage) => p.PackageId === module.PackageId);
    if (parentPackage) {
      this.automaticReselectOfPackages(module.IsEnabled);
    }

    this.updateTotal();
  }

  public get tooltipPosition(): string {
    return this.windowRef && this.windowRef.nativeWindow && this.windowRef.nativeWindow.innerWidth < 790
      ? 'left'
      : 'top';
  }

  public get tooltipWidth(): number {
    const width: number = this.windowRef && this.windowRef.nativeWindow ? this.windowRef.nativeWindow.innerWidth : 800;
    const result: number = width > 790 ? 500 : 500 - (790 - width) - 55;
    return result > 150 ? result : 150;
  }

  public get selectedPackageId(): number {
    const selectedPackage: ModulePackage = this.packages.find((p: ModulePackage) => p.Selected);
    return selectedPackage ? selectedPackage.PackageId : 0;
  }

  public getPackage(packageId: number): ModulePackage {
    return this.packages.find((p: ModulePackage) => p.PackageId === packageId) || {};
  }

  public getModule(moduleId: number): IModuleCompanyView {
    const module: IModuleCompanyView =
      this.modules.find((m: IModuleCompanyView) => m.ModuleId === moduleId) || new ModuleCompanyView();
    return module;
  }

  private selectPackage(modulePackage: ModulePackage): void {
    modulePackage.Selected = true;
    this.packages
      .filter((p: ModulePackage) => p.PackageId !== modulePackage.PackageId && p.Selected)
      .forEach((p: ModulePackage) => {
        this.deSelectPackage(p);
      });
    const modulesToEnable: any = this.modules.filter(
      (m: IModuleCompanyView) => (m.PackageId ? m.PackageId : Infinity) <= modulePackage.PackageId
    );
    modulesToEnable.forEach((m: IModuleCompanyView) => (m.IsEnabled = true));
  }

  private deSelectPackage(modulePackage: ModulePackage): void {
    modulePackage.Selected = false;
    this.modules
      .filter(
        (m: IModuleCompanyView) =>
          m.FreeUntilDate === null && !m.InfoOnly && (m.PackageId ? m.PackageId : Infinity) <= modulePackage.PackageId
      )
      .forEach((m: IModuleCompanyView) => (m.IsEnabled = false));
  }

  private updateTotal(): void {
    setTimeout(() => {
      this.priceElements = this.modules
        .filter((m: IModuleCompanyView) => m.IsEnabled)
        .map((m: IModuleCompanyView) => m.ModuleName);
      this.totalCost = this.calculateCost();
      const currentModules: number[] = this.modules
        .filter((m: IModuleCompanyView) => m.IsEnabled && !m.InfoOnly)
        .map((val: IModuleCompanyView) => val.ModuleId)
        .sort();
      // console.log(JSON.stringify(this.initialModules));
      // console.log(JSON.stringify(currentModules));
      const modulesIncludedInPerPayslipCalc: IModuleCompanyView[] = this.modules.filter(
        (m: IModuleCompanyView) => m.IsEnabled && m.ModuleBillingPrincipleId === 1 && !m.InfoOnly && !m.FreeUntilDate
      );
      const prices: number[] = modulesIncludedInPerPayslipCalc.map((m: IModuleCompanyView) => {
        if (this.selectedPackageId !== 0) {
          if (m.PackageId !== 0 && m.PackageId !== null && m.PackageId <= this.selectedPackageId) {
            return 0;
          } else {
            return m.ModulePrice;
          }
        } else {
          return m.ModulePrice;
        }
      });
      const currentPackagePrice: number = this.getPackage(this.selectedPackageId).Price;
      if (currentPackagePrice !== undefined) {
        prices.push(currentPackagePrice);
      }

      this.calculatedPricePerPayslip = prices.reduce((a: number, b: number) => a + b, 0);
      if (JSON.stringify(this.initialModules) !== JSON.stringify(currentModules)) {
        this.submitEnabled = true;
      } else {
        this.submitEnabled = false;
      }
    });
  }

  private calculateCost(): number {
    let total = 0;
    if (this.packages) {
      const selectedPackage: ModulePackage = this.packages.find((p: ModulePackage) => p.Selected);
      let selectedPackageId = 0;
      if (selectedPackage) {
        selectedPackageId = selectedPackage.PackageId;
        total = this.packagePrice(selectedPackage);
      }

      const otherModules: IModuleCompanyView[] = this.modules.filter(
        (m: IModuleCompanyView) =>
          m.FreeUntilDate === null && m.IsEnabled && (m.PackageId ? m.PackageId : Infinity) > selectedPackageId
      );
      if (otherModules) {
        otherModules.forEach((m: IModuleCompanyView) => (total += this.modulePrice(m)));
      }

      total = Math.round(total * 100) / 100;
    }

    return total;
  }

  public formatPrice(priceValue: number): string {
    if (priceValue === 0) {
      return 'Free';
    } else if (priceValue % 1 === 0) {
      return priceValue.toString() + ',-';
    } else if (priceValue === undefined) {
      return 'undefined';
    } else {
      return priceValue.toLocaleString('da-DK') + '0';
    }
  }

  private packagePrice(modulePackage: ModulePackage): number {
    return this.calculatePriceByBillingPrinciple(modulePackage.BillingPrincipleId, modulePackage.Price);
  }

  private modulePrice(module: IModuleCompanyView): number {
    return this.calculatePriceByBillingPrinciple(module.ModuleBillingPrincipleId, module.ModulePrice);
  }

  private calculatePriceByBillingPrinciple(billingPrincipleId: number, price: number): number {
    const totalUsers: number = 0 + this.users14Day + this.usersMonthly + this.usersWeekly;
    switch (billingPrincipleId) {
      case 1: // Per payslip
        return price * (this.usersMonthly + this.users14Day * 2.17 + this.usersWeekly * 4.33);
      case 2: // Per salary batch
        //// TODO: Ask Daniel
        return 0;
      case 3: // Per month
        return price;
      case 4: // Per user per month
        return price * totalUsers;
      case 5: // Per unit
        //// TODO: Ask Daniel
        return 0;
    }

    return 0;
  }

  private async buildPackages(): Promise<void> {
    this.packages = [];
    this.modules
      .filter((m: IModuleCompanyView) => m.InfoOnly || m.FreeUntilDate !== null)
      .forEach((m: IModuleCompanyView) => (m.IsEnabled = true));
    for (const module of this.modules) {
      const packageId: number = module.PackageId || 9999;
      if (!this.packages[packageId]) {
        this.packages[packageId] = {
          PackageId: packageId,
          Selected: false,
          Name: module.PackageName || 'Ekstra moduler',
          Description: module.PackageDescription || '',
          Price: module.PackagePrice || 0,
          BillingPrinciple: module.PackageBillingPrinciple || '',
          BillingPrincipleId: module.PackageBillingPrincipleId || 4,
          Modules: []
        };
      }

      this.packages[packageId].Modules.push(module);
    }

    this.packages = this.packages.filter((p: ModulePackage) => p.PackageId);
    const selectedPackage: ModulePackage = this.packages.find(
      (p: ModulePackage) => p.PackageId === this.modules[0].CompanyCurrentPackageLevel
    );
    if (selectedPackage) {
      this.selectPackage(selectedPackage);
    }

    this.updateTotal();
    this.initialModules = this.modules
      .filter((m: IModuleCompanyView) => m.IsEnabled && !m.InfoOnly)
      .map((val: IModuleCompanyView) => {
        return val.ModuleId;
      })
      .sort();
  }

  public isModuleLocked(moduleId: number): boolean {
    return this.getModule(moduleId).InfoOnly || this.getModule(moduleId).FreeUntilDate !== null;
  }

  public isModulePriceStrikethrough(moduleId: number): boolean {
    const module: IModuleCompanyView = this.getModule(moduleId);
    let minSelectedPackageId: number = module.PackageId;
    if (module.ModulePrice === 0) {
      return false;
    } else {
      if (module.FreeUntilDate !== null) {
        return true;
      }
      while (minSelectedPackageId <= 3) {
        if (this.getPackage(minSelectedPackageId).Selected && module.PackageId !== null) {
          return true;
        }
        minSelectedPackageId++;
      }
      return false;
    }
  }

  public isEnableTooltip = true;
  public onClick(event: any): void {
    this.isHideToolTip = !this.isHideToolTip;
    event.stopPropagation();
  }

  public moduleTooltipHtml(link: string, des: string): string {
    if (link && des) {
      if (this.sessionService.browser.iOSMobileDevice && this.sessionService.browser.isHybridApp) {
        return '<a class="tooltipStyle">' + des + '</a>';
      }
      return '<a class="tooltipStyle" href="' + link + '" target="_blank">' + des + '</a>';
    }
    return '';
  }
}
