export class ImportSaveMappingParameter {
  value: string;
  selectedGratisalColumnValue: string;
  selectedColumnTypeValue: string;
  selectedColumnTypeKey: string;
  public toString(): string {
    let key = '';
    if (this.isAmount() && this.selectedColumnTypeValue && this.selectedColumnTypeValue.toString() !== '-1') {
      key = this.selectedColumnTypeValue;
    }

    if (this.selectedGratisalColumnValue && this.selectedGratisalColumnValue.toString() !== '-1') {
      if (this.isIdentifyingColumn()) {
        key = ';' + this.selectedGratisalColumnValue;
      } else {
        key =
          this.selectedGratisalColumnValue +
          (this.selectedColumnTypeKey && this.selectedColumnTypeKey.toString() !== '-1'
            ? ';' + this.selectedColumnTypeKey
            : '');
      }
    }

    if (key) {
      return `{"Key":"${key}","Value":"${this.value}"}`;
    }

    return '';
  }

  private isAmount(): boolean {
    return this.selectedColumnTypeValue === '1';
  }

  private isUnit(): boolean {
    return this.selectedColumnTypeValue === '2';
  }

  private isIdentifyingColumn(): boolean {
    return (
      this.selectedGratisalColumnValue === 'cpr' ||
      this.selectedGratisalColumnValue === 'reference' ||
      this.selectedGratisalColumnValue === 'navn' ||
      this.selectedGratisalColumnValue === 'id'
    );
  }
}
