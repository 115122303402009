import { OnDestroy, OnInit } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { Global } from '../../../Common/Global';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { SalaryTypeView } from './SalaryTypeView';
var CompanySalaryTypesNgxComponent = /** @class */ (function () {
    function CompanySalaryTypesNgxComponent(dataService, staticDataService, sessionService, settingService) {
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.settingService = settingService;
        this.gridData = [];
        this.accountTypes = [];
        this.summaryCategories = [];
        this.unitTypes = [];
        this.persistanceTypes = [];
        this.overviewDisplayModes = [];
        this.categories = [];
        this.externalReferences = [];
        this.hasSalaryTypeModule = false;
        this.ngUnsubscribe = new Subject();
        this.gridFilterData = filterBy(this.gridData, this.filter);
    }
    Object.defineProperty(CompanySalaryTypesNgxComponent.prototype, "isLanguageModuleEnable", {
        get: function () {
            return this.sessionService.feature.hasModuleId(5);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryTypesNgxComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryTypesNgxComponent.prototype, "isFromDenmark", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryTypesNgxComponent.prototype, "isFromGreenland", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryTypesNgxComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.languages = data;
        });
        this.staticDataService.FinanceAccountType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.accountTypes = data); });
        this.staticDataService.SalarySummaryCategory.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.summaryCategories = data); });
        this.staticDataService.SalaryRecordPersistenceType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.persistanceTypes = data); });
        this.staticDataService.SalaryRecordsOverviewDisplayMode.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.overviewDisplayModes = data); });
        // this.dataService.SalaryTypes_GetAllSalaryTypeCategories().subscribe((data: ISalaryTypeCategoryView[]) => this.categories = data);
        this.staticDataService.SalaryTypeCategory.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.categories = data;
        });
        this.dataService.Integrations_GetAllExternalAccounts().subscribe(function (data) {
            _this.externalReferences = data;
            if (_this.externalReferences && _this.externalReferences.length > 0) {
                _this.externalReferences.forEach(function (model) {
                    model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
                });
                _this.convertExternalRefCodeToFriendlyName();
            }
        });
        this.unitTypes = Global.UNIT_TYPES;
        this.dataService.SalaryTypes_GetAllSalaryTypeCategories().subscribe(function (data) {
            _this.SalaryTypeCategories = data;
        });
        this.staticDataService.moduleCompanyView.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () { return _this.getSalaryTypes(); });
        this.settingService.showModuleMessage('company.advancedsalarytypes');
        if (this.isMobile) {
            this.sessionService.isShowHugeFeaturesAlert = true;
        }
    };
    Object.defineProperty(CompanySalaryTypesNgxComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryTypesNgxComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanySalaryTypesNgxComponent.prototype.saveChanges = function (value) {
        var _this = this;
        value.dataItem.ExternalReference = this.getExteralReferenceCode(value.dataItem.ExternalReference, true);
        var salaryType = value.dataItem.toSalaryType();
        salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference);
        this.dataService.SalaryTypes_UpdateSalaryType(salaryType).subscribe(function (data) {
            data.ExternalReference = _this.getExteralReferenceCode(data.ExternalReference, true);
            _this.transformSalaryTypeObject(data);
            Object.assign(value.dataItem, new SalaryTypeView(data, _this.isLanguageModuleEnable));
        }, function (error) { return _this.getSalaryTypes(); });
    };
    CompanySalaryTypesNgxComponent.prototype.onAddClick = function () {
        this.newSalaryTypeDialogVisible = true;
    };
    CompanySalaryTypesNgxComponent.prototype.onAddSalaryType = function (value) {
        this.salaryTypes.push(value);
        this.transformSalaryTypeObject(value);
        this.gridData.push(new SalaryTypeView(value, this.isLanguageModuleEnable));
        this.gridFilterData = filterBy(this.gridData, this.filter);
        this.selectedId = value.Id;
    };
    CompanySalaryTypesNgxComponent.prototype.onCellClick = function (event) {
        if (['Name', 'Description', 'DefaultPayslipText', 'Explanation'].includes(event.field) &&
            this.isLanguageModuleEnable) {
            this.translationSalaryType = event.dataItem;
            this.translationDialogVisible = true;
        }
    };
    CompanySalaryTypesNgxComponent.prototype.onTranslateCompleted = function () {
        this.getSalaryTypes();
    };
    CompanySalaryTypesNgxComponent.prototype.getSalaryTypes = function () {
        var _this = this;
        this.gridData = [];
        if (this.isLanguageModuleEnable) {
            this.dataService.SalaryTypes_GetRawSalaryTypesTranslated().subscribe(function (data) {
                _this.salaryTypes = data;
                var filterSalaryTypes = data.filter(function (type) {
                    return !type.BaseSalaryType || (type.BaseSalaryType.IsVisible && type.BaseSalaryType.IsActive);
                });
                var activeSalaryTypes = filterSalaryTypes.filter(function (type) {
                    return type.IsActive !== false;
                });
                var inActiveSalaryType = filterSalaryTypes.filter(function (type) {
                    return type.IsActive === false;
                });
                var sortedActiveSalaryTypes = activeSalaryTypes.sort(function (a, b) {
                    return _this.sortCustomSalaryTypes(a, b);
                });
                var sortedInActiveSalaryTypes = inActiveSalaryType.sort(function (a, b) {
                    return _this.sortCustomSalaryTypes(a, b);
                });
                var sortedSalaryTypes = sortedActiveSalaryTypes.concat(sortedInActiveSalaryTypes);
                _this.gridData = sortedSalaryTypes.map(function (salaryType) {
                    _this.transformSalaryTypeObject(salaryType);
                    return new SalaryTypeView(salaryType, _this.isLanguageModuleEnable);
                });
                _this.gridFilterData = filterBy(_this.gridData, _this.filter);
                _this.convertExternalRefCodeToFriendlyName();
            });
        }
        else {
            this.dataService.SalaryTypes_GetRawSalaryTypes().subscribe(function (data) {
                _this.salaryTypes = data;
                var filterSalaryTypes = data.filter(function (type) {
                    return !type.BaseSalaryType || (type.BaseSalaryType.IsVisible && type.BaseSalaryType.IsActive);
                });
                var activeSalaryTypes = filterSalaryTypes.filter(function (type) {
                    return type.IsActive !== false;
                });
                var inActiveSalaryType = filterSalaryTypes.filter(function (type) {
                    return type.IsActive === false;
                });
                var sortedActiveSalaryTypes = activeSalaryTypes.sort(function (a, b) {
                    return _this.sortCustomSalaryTypes(a, b);
                });
                var sortedInActiveSalaryTypes = inActiveSalaryType.sort(function (a, b) {
                    return _this.sortCustomSalaryTypes(a, b);
                });
                var sortedSalaryTypes = sortedActiveSalaryTypes.concat(sortedInActiveSalaryTypes);
                _this.gridData = sortedSalaryTypes.map(function (salaryType) {
                    _this.transformSalaryTypeObject(salaryType);
                    return new SalaryTypeView(salaryType, _this.isLanguageModuleEnable);
                });
                _this.gridFilterData = filterBy(_this.gridData, _this.filter);
                _this.convertExternalRefCodeToFriendlyName();
            });
        }
    };
    CompanySalaryTypesNgxComponent.prototype.transformSalaryTypeObject = function (salaryType) {
        var existingSalaryType = this.salaryTypes.find(function (s) { return s.Id === salaryType.Id; });
        salaryType.SalaryTypeTranslations = salaryType.SalaryTypeTranslations
            ? salaryType.SalaryTypeTranslations
            : existingSalaryType
                ? existingSalaryType.SalaryTypeTranslations
                : undefined;
        salaryType.BaseSalaryType = salaryType.BaseSalaryType
            ? salaryType.BaseSalaryType
            : existingSalaryType
                ? existingSalaryType.BaseSalaryType
                : undefined;
    };
    CompanySalaryTypesNgxComponent.prototype.convertExternalRefCodeToFriendlyName = function () {
        var _this = this;
        if (this.gridData && this.gridData.length > 0 && this.externalReferences && this.externalReferences.length > 0) {
            this.gridData.forEach(function (salaryType) {
                salaryType.ExternalReference = _this.getExteralReferenceCode(salaryType.ExternalReference, true);
            });
            this.gridFilterData = filterBy(this.gridData, this.filter);
        }
    };
    CompanySalaryTypesNgxComponent.prototype.sortCustomSalaryTypes = function (a, b) {
        var aOrder = a.SortOrder || a.SortOrder === 0
            ? a.SortOrder
            : a.BaseSalaryType && (a.BaseSalaryType.SortOrder || a.BaseSalaryType.SortOrder === 0)
                ? a.BaseSalaryType.SortOrder
                : undefined;
        var bOrder = b.SortOrder || b.SortOrder === 0
            ? b.SortOrder
            : b.BaseSalaryType && (b.BaseSalaryType.SortOrder || b.BaseSalaryType.SortOrder === 0)
                ? b.BaseSalaryType.SortOrder
                : undefined;
        if ((aOrder === undefined && bOrder === undefined) || (aOrder === 0 && bOrder === 0)) {
            return a.Id - b.Id;
        }
        else if (aOrder === undefined && bOrder !== undefined) {
            return -1;
        }
        else if (aOrder !== undefined && bOrder === undefined) {
            return 1;
        }
        return aOrder - bOrder;
    };
    CompanySalaryTypesNgxComponent.prototype.getExteralReferenceCode = function (refCode, fullName) {
        if (!refCode) {
            return null;
        }
        var externalRefCode = this.externalReferences
            ? this.externalReferences.find(function (item) {
                return refCode === item.AccountNumber || refCode === item.FriendlyName;
            })
            : null;
        if (externalRefCode) {
            return !fullName ? externalRefCode.AccountNumber : externalRefCode.FriendlyName;
        }
        return refCode;
    };
    CompanySalaryTypesNgxComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridFilterData = filterBy(this.gridData, filter);
    };
    return CompanySalaryTypesNgxComponent;
}());
export { CompanySalaryTypesNgxComponent };
