<span kendoTooltip #componentId class="FormElement-cursorHand" id="{{ componentId }}">
  <button
    *ngIf="!isUseOneClick"
    [title]="tooltip"
    [ngClass]="buttonClass"
    type="button"
    (click)="onClickEvent()"
    [disabled]="isDisabled"
  >
    {{ label }}
  </button>
  <button
    *ngIf="isUseOneClick"
    [title]="tooltip"
    [ngClass]="buttonClass"
    type="button"
    (click)="onClickEvent()"
    [disabled]="isDisabled && isFirstClick"
  >
    {{ label }}
  </button>
</span>
