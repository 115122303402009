export enum UnitTypeEnum {
  None = 0,
  Days = 1,
  Hours = 2,
  Pieces = 3,
  Kilometers = 4,
  Money = 5,
  Percent = 6
}

export enum ReportFormat {
  PDF = 1,
  XLSX = 2,
  CSV = 3,
  HTML = 4
}

export enum ViewType {
  BOTH = 0,
  EMPLOYEE = 1,
  BALANCE = 2
}

export enum UpdateChangeResult {
  Fail,
  Success,
  Discard
}

export enum ImportTypesEnum {
  None = 0,
  Employees = 1,
  Initial_Balances = 2,
  Payroll = 3,
  PayrollRow = 4,
  TimeEntries = 5,
  TimeEntriesRow = 6
}

export enum ActionTypesEnum {
  None = 0,
  GetTemplate = 1,
  Upload_File = 2,
  CustomImport = 3,
  Convert_From_Other_System = 4
}

export enum PayrollSelectedOptionEnum {
  None = 0,
  UpdateExistingRecords = 1,
  AlwaysInsertNewRecords = 2
}

export enum ImportResultEnum {
  FatalError, // Red error icon, with the FatalMessage displayed large.Text that nothing can be imported.
  Success, // Success Green or blue Icon, (discuss: close if click off of modal?) Text that everything was imported.
  Errors, // Red error icon, not as large as FatalError.Text that there were errors, but the other rows can be imported.
  Warnings // Yellow warning icon.Text that everything except the following rows were imported.
}

export enum ImportButtonAction {
  None = 0,
  Test = 1,
  Import = 2,
  CompleteImport = 3
}

export enum SalaryBatchStatusEnum {
  Draft = 10,
  ForApproval = 20,
  // Locked = 25,
  // Preliminary = 30,
  Finalized = 40,
  Cancelled = 90
}

export enum OverviewDisplayModeEnum {
  UnitsAndAmount = 1,
  AmountOnly = 2,
  UnitsOnly = 3
}
