<div *ngIf="hasDepartmentData">
  <label class="FormElement-label u-hiddenVisually" for="comboboxDepartment">
    {{ 'Employee.Search by department' | translate }}
  </label>

  <combobox-edit-control
    [editMode]="true"
    [textField]="'Text'"
    [idField]="'Id'"
    id="comboboxDepartment"
    [comboboxDataSource]="departmentDataSource"
    [(value)]="departmentId"
    (valueChange)="onComboBoxChanged()"
  ></combobox-edit-control>
</div>
