/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./MobileModalHeader.component";
var styles_MobileModalHeaderComponent = [];
var RenderType_MobileModalHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MobileModalHeaderComponent, data: {} });
export { RenderType_MobileModalHeaderComponent as RenderType_MobileModalHeaderComponent };
export function View_MobileModalHeaderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_MobileModalHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mobile-modal-header", [], null, null, null, View_MobileModalHeaderComponent_0, RenderType_MobileModalHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i1.MobileModalHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MobileModalHeaderComponentNgFactory = i0.ɵccf("mobile-modal-header", i1.MobileModalHeaderComponent, View_MobileModalHeaderComponent_Host_0, {}, {}, ["*"]);
export { MobileModalHeaderComponentNgFactory as MobileModalHeaderComponentNgFactory };
