/* tslint:disable:no-bitwise */
export function Utf8ArrayToStr(array: Uint8Array): string {
  // Source: http://stackoverflow.com/questions/17191945/conversion-between-utf-8-arraybuffer-and-string
  let out = '';
  const len: number = array.length;
  let i = 0;
  while (i < len) {
    const c: number = array[i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        let char2: number = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        const char3: number = array[i++];
        out += String.fromCharCode(((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0));
        break;
    }
  }

  return out;
}
