import { Component, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISalaryBatchValidationIssue, ISalaryBatchView } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';

@Component({
  selector: 'salary-batch-other-error-grid',
  templateUrl: './SalaryBatchOtherErrorDetails.component.html'
})
export class SalaryBatchOtherErrorGridComponent implements OnDestroy {
  private visibleValue: boolean;
  private validationRecord: any[] = [];

  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.loadErrorDetails();
    }
  }

  private currentSalaryBatch: ISalaryBatchView;
  @Input()
  public get salaryBatch(): ISalaryBatchView {
    return this.currentSalaryBatch;
  }
  public set salaryBatch(value: ISalaryBatchView) {
    if (this.currentSalaryBatch !== value) {
      this.currentSalaryBatch = value;
      this.loadErrorDetails();
    }
  }

  public gridData: Array<{ icon?: string; tooltip?: string; description?: string; employeeName?: string }> = [];
  public filter: CompositeFilterDescriptor;

  private errorMessage: string;
  private warningMessage: string;

  constructor(
    private dataService: RxDataService,
    private sessionService: SessionService,
    private translateService: TranslateService
  ) {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService
        .get([
          'CompanySalaryBatches.OrtherStatusGridErrorIconTooltip',
          'CompanySalaryBatches.OrtherStatusGridWarningIconTooltip'
        ])
        .subscribe((translations: { [key: string]: string }) => {
          this.warningMessage = translations['CompanySalaryBatches.OrtherStatusGridWarningIconTooltip'];
          this.errorMessage = translations['CompanySalaryBatches.OrtherStatusGridErrorIconTooltip'];
        });
    });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private loadErrorDetails(): void {
    if (
      this.visible &&
      this.salaryBatch &&
      this.salaryBatch.Id &&
      this.salaryBatch.StatusId &&
      this.salaryBatch.StatusId !== 40
    ) {
      this.dataService
        .SalaryBatches_GetValidationIssues(this.salaryBatch.Id)
        .subscribe((validationIssues: ISalaryBatchValidationIssue[]): void => {
          this.validationRecord = validationIssues.map((issue: ISalaryBatchValidationIssue) => {
            return {
              icon: issue.IsError ? 'ErrorExclamation' : 'Warning',
              tooltip: issue.IsError ? this.errorMessage : this.warningMessage,
              description: issue.Description,
              employeeName: issue.EmployeeName
            };
          });

          this.gridData = this.validationRecord;
        });
    } else {
      this.gridData = [];
    }
  }

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.validationRecord, filter);
  }
}
