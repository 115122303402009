import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { environment } from '../../../../environments/environment';
import { FilterDateTimeGrid } from '../../../Common/FilterDateTimeGrid';
import { Global } from '../../../Common/Global';
import { ICompanyUser, ISalaryStatement } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { DownloadService } from '../../../Services/SharedServices/DownloadService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';

@Component({
  selector: 'payslip',
  templateUrl: './Payslip.component.html'
})
export class PayslipComponent implements OnInit {
  @Input() public allowEdit: boolean;

  @Input()
  public get employee(): ICompanyUser {
    return this.currentEmployee;
  }
  public set employee(value: ICompanyUser) {
    if (value && this.currentEmployee !== value) {
      this.currentEmployee = value;
      this.gridData = [];
      this.initializeData();
    }
  }

  @Output() public createUserEmploymentClick: EventEmitter<any> = new EventEmitter<any>();

  public selectedFirstRow: boolean;
  public showRevertFinalizedDialog = false;
  public selectedSalaryStatement: ISalaryStatement;
  public currentEmployee: ICompanyUser;
  public salaryStatementsNoneType: any[] = [];
  public isHideWarnningColum = false;
  public previewDialogVisible = false;
  public previewContent: any;
  public newTabBlockedDialogVisible = false;
  public groupIconAction: any[] = [];
  public triggerUpdate = false;

  public get hasUserEmployment(): boolean {
    return (
      this.currentEmployee && this.currentEmployee.UserEmployments && this.currentEmployee.UserEmployments.length > 0
    );
  }
  public get IsPaymentApprover(): boolean {
    return Global.SESSION && Global.SESSION.IsPaymentApprover;
  }
  public get IsWeb(): boolean {
    return !this.sessionService.browser.isHybridApp;
  }
  public get IsAppOrDesktop(): boolean {
    return !this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
  }

  private get IsEmployeePayslipActive(): boolean {
    return this.sessionService.currentState === 'tabs.employee.payslip';
  }
  private get IsSelfServicePayslipActive(): boolean {
    return this.sessionService.currentState === 'tabs.selfservice.payslip';
  }

  public get isFirefox(): boolean {
    return this.sessionService.browser.isFirefox;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  constructor(
    private settingService: SettingService,
    private dataService: RxDataService,
    private downloadService: DownloadService,
    public renderer: Renderer2,
    private sessionService: SessionService
  ) {}

  public ngOnInit(): void {
    if (this.IsEmployeePayslipActive || this.IsSelfServicePayslipActive) {
      this.settingService.showModuleMessage(this.IsEmployeePayslipActive ? 'Employee.Payslip' : 'SelfService.Payslip');
    }

    if (this.IsAppOrDesktop) {
      const IconHtmloulineAction: any = {};
      IconHtmloulineAction.Icon = 'file-download';
      IconHtmloulineAction.Action = 'iconHtmlouline';
      IconHtmloulineAction.tooltip = 'EmploymentPayrollData.HTMLPopupToolTip';
      IconHtmloulineAction.Active = this.IsAppOrDesktop;
      if (!this.sessionService.browser.isHybridApp) {
        IconHtmloulineAction.isHidden = true;
        IconHtmloulineAction.IconPaysliphide = 'Icon--Payslip--hide';
      } else {
        IconHtmloulineAction.isHidden = false;
      }
      this.groupIconAction.push(IconHtmloulineAction);
    }

    if (this.IsWeb) {
      const IconHtmlAction: any = {};
      IconHtmlAction.Icon = 'ExternalLink';
      IconHtmlAction.Action = 'iconHtml';
      IconHtmlAction.tooltip = 'EmploymentPayrollData.HTMLFileToolTip';
      IconHtmlAction.Active = this.IsWeb;
      this.groupIconAction.push(IconHtmlAction);
    }

    const IconPdfAction: any = {};
    IconPdfAction.Icon = 'Download';
    IconPdfAction.Action = 'iconPdf';
    IconPdfAction.tooltip = 'CompanySalaryBatches.PaySlipPDFTooltip';
    IconPdfAction.Active = true;
    this.groupIconAction.push(IconPdfAction);

    //this.initializeData(true);
  }

  public onRevert(): void {
    this.showRevertFinalizedDialog = true;
  }

  public createUserEmployment(): void {
    this.createUserEmploymentClick.emit();
  }

  public onRevertBatchCompleted(): void {
    this.gridData = [];
    this.getSalaryStatements(this.currentEmployee.Id);
  }

  public clickIconAction(event: any): void {
    if (event && event.dataItem && event.iconAction) {
      this.selectedSalaryStatement = this.salaryStatementsNoneType.find((model: any) => model.Id === event.dataItem.Id);
      switch (event.iconAction) {
        case 'iconHtml': {
          this.downloadSalaryStatementNoneEvent(event.dataItem.Id, false);
          break;
        }
        case 'iconHtmlouline': {
          this.downloadSalaryStatementNoneEvent(event.dataItem.Id, true);
          if (this.sessionService.browser.isHybridApp) {
            window.screen.orientation.unlock();
          }
          break;
        }
        case 'iconPdf': {
          this.downloadSalaryStatementPDFNoneEvent(event.dataItem.Id);
          break;
        }
        default:
          break;
      }
    }
  }

  public onGridCellDbClick(event: any): void {
    if (event && event.Id) {
      this.downloadSalaryStatementNoneEvent(event.Id, true);
    }
  }

  public onSelectRow(event: any): void {
    this.selectedSalaryStatement = this.salaryStatementsNoneType.find((model: any) => model.Id === event.Id);
    if (this.salaryStatementsNoneType.length > 0) {
      this.selectedFirstRow = this.salaryStatementsNoneType[0].Id === this.selectedSalaryStatement.Id;
    }
  }

  private initializeData(showNavigationMessage: boolean = false): void {
    if (showNavigationMessage) {
      this.settingService.showNavigationMessage();
    }

    if (this.currentEmployee) {
      this.getSalaryStatements(this.currentEmployee.Id);
    }
  }

  private getSalaryStatements(companyUserId: number): void {
    this.dataService
      .SalaryStatements_GetSalaryStatementsByCompanyUser(companyUserId)
      .subscribe((salaryStatements: ISalaryStatement[]) => {
        if (salaryStatements) {
          salaryStatements = salaryStatements.sort(
            (salaryStatement1: ISalaryStatement, salaryStatement2: ISalaryStatement) => {
              const date1: any = salaryStatement1.PeriodTo;
              const date2: any = salaryStatement2.PeriodTo;
              return (
                new Date(date2).getFullYear() - new Date(date1).getFullYear() ||
                salaryStatement2.Id - salaryStatement1.Id
              );
            }
          );
          this.salaryStatementsNoneType = salaryStatements.map((model: ISalaryStatement) => {
            return {
              iconHtmlouline: 'file-download',
              iconHtml: 'ExternalLink',
              iconPdf: 'Download',
              PreliminaryWarning: model.IsFinalized ? '' : 'warning',
              Id: model.Id,
              UserEmployment: model.UserEmployment,
              PeriodFrom: model.PeriodFrom,
              PeriodTo: model.PeriodTo,
              PrimaryIncomeAmount: model.PrimaryIncomeAmount,
              HoursWorked: model.HoursWorked,
              AppliedTaxDeduction: model.AppliedTaxDeduction,
              TaxRate: model.TaxRate,
              PayoutAmount: model.PayoutAmount,
              VacationDaysSpent: model.VacationDaysSpent,
              VacationMoneyEarnedGross: model.VacationMoneyEarnedGross,
              VacationMoneyEarnedNet: model.VacationMoneyEarnedNet,
              IsFinalized: model.IsFinalized,
              PayoutDateDerived: model.PayoutDateDerived
            };
          });
        }
        this.selectedFirstRow = false;
        this.gridData = filterBy(this.salaryStatementsNoneType, this.filter);
        this.triggerUpdate = true;
      });
  }

  public get isAllfinalize(): boolean {
    return this.salaryStatementsNoneType &&
      this.salaryStatementsNoneType.length > 0 &&
      this.salaryStatementsNoneType.filter((model: any) => model.IsFinalized === true).length ===
        this.salaryStatementsNoneType.length
      ? true
      : false;
  }

  private getPayslipReportByToken(salaryStatementId: number, format: string): void {
    this.dataService.SalaryStatements_GetPayslipDownloadToken(salaryStatementId).subscribe((token: any): void => {
      const languageCode: string = this.sessionService.LanguageCode;
      const downloadUrl = `/api/salarystatements/${salaryStatementId}/token/${token}/lang/${languageCode}/format/${format}/Payslip`;
      setTimeout(() => {
        const newWindow: Window = window.open(environment.apiUrl + downloadUrl);
        if (!newWindow) {
          this.newTabBlockedDialogVisible = true;
        }
      });
    });
  }

  private onDownloadSalaryStatementHTMLCompleted(data: any, isPopup: boolean): void {
    if (!isPopup) {
      const newWindow: Window = window.open('about:blank');
      if (newWindow) {
        newWindow.document.write(data);
        newWindow.document.close();
      } else {
        this.newTabBlockedDialogVisible = true;
      }
    } else {
      this.previewContent = data;
      this.previewDialogVisible = true;
    }
  }

  private downloadSalaryStatementNoneEvent(salaryStatementId: number, isPopup?: boolean): void {
    if (isPopup && this.IsAppOrDesktop) {
      const format: string = this.IsWeb ? 'a4p' : 'html';
      this.dataService
        .SalaryStatements_GetHtml(salaryStatementId, format)
        .subscribe((data: string): void => this.onDownloadSalaryStatementHTMLCompleted(data, isPopup));
    } else {
      this.getPayslipReportByToken(salaryStatementId, 'html');
    }
  }

  private downloadSalaryStatementPDFNoneEvent(salaryStatementId: number): void {
    if (
      this.sessionService.browser.isHybridApp ||
      this.sessionService.browser.isIE ||
      this.sessionService.browser.isEdge
    ) {
      this.dataService.SalaryStatements_GetPdf(salaryStatementId).subscribe((data: string): void => {
        this.downloadService.download('Gratisal.pdf', data);
      });
    } else {
      this.getPayslipReportByToken(salaryStatementId, 'pdf');
    }
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = filterBy(this.salaryStatementsNoneType, this.filter);
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    if (filter && filter.filters && filter.filters.length > 0) {
      const filterDateTimeGrid = new FilterDateTimeGrid();
      const filtersDate: any = [];
      // Using FilterDateTimeGrid to filter date before filter another field
      this.filter = filterDateTimeGrid.convertFilterOperator(filter, filtersDate, 'PeriodFrom', 'PeriodTo');
      this.gridData = filterDateTimeGrid.doFilterDate(
        this.salaryStatementsNoneType,
        filtersDate,
        'PeriodFrom',
        'PeriodTo'
      );
      this.gridData = filterBy(this.gridData, this.filter);
      if (filtersDate.length > 0) {
        [].push.apply(this.filter.filters, filtersDate);
      }
    } else {
      this.gridData = this.salaryStatementsNoneType;
    }
  }
}
