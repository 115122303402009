import { EventEmitter } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Browser } from '../../Common/Browser';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
var EditButtonComponent = /** @class */ (function () {
    function EditButtonComponent(sessionService) {
        this.sessionService = sessionService;
        this.change = new EventEmitter();
        this.editModeChange = new EventEmitter();
        this.errorChangeEditMode = new EventEmitter();
        this.disable = false;
        this.allowCompanyPreference = true;
        this.isCanChangeEditMode = true;
        this.isReadOnly = false;
        this.editModeValue = false;
        this.isFirstClick = false;
        this.isReadOnly = this.sessionService.role.IsReadOnly;
        if (this.sessionService.currentState.includes('tabs.accountant')) {
            this.isReadOnly = false;
        }
    }
    Object.defineProperty(EditButtonComponent.prototype, "editMode", {
        get: function () {
            return this.editModeValue;
        },
        set: function (value) {
            if (this.editModeValue !== value) {
                this.editModeValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditButtonComponent.prototype, "buttonClass", {
        get: function () {
            return 'editButtonFor' + this.module;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditButtonComponent.prototype, "EditTooltip", {
        get: function () {
            if (this.allowCompanyPreference) {
                if (this.editMode) {
                    return 'Employee.Save';
                }
                else {
                    return 'Employee.Edit';
                }
            }
            return 'EmploymentTime.DisableGridTimeMessage';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditButtonComponent.prototype, "enableVisibleClass", {
        get: function () {
            return this.editMode ? 'isEnableVisible' : 'hide';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditButtonComponent.prototype, "disableVisibleClass", {
        get: function () {
            return this.editMode ? 'hide' : 'isEnableVisible';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditButtonComponent.prototype, "tooltipString", {
        get: function () {
            if (this.editMode) {
                return '';
            }
            return 'Employee.Edit';
        },
        enumerable: true,
        configurable: true
    });
    EditButtonComponent.prototype.onEditButtonClick = function (tooltipTarget) {
        var _this = this;
        if (!this.isCanChangeEditMode) {
            this.errorChangeEditMode.emit();
            return;
        }
        this.isFirstClick = true;
        this.tooltipDir.toggle(tooltipTarget);
        if (this.isReadOnly && this.sessionService.currentState !== 'tabs.selfservice.general') {
            return;
        }
        var browser = new Browser();
        if (browser.isMobile) {
            setTimeout(function () {
                _this.editMode = !_this.editMode;
                _this.change.emit(_this.editMode);
                _this.isFirstClick = false;
            }, 200);
            return;
        }
        if (browser.isChrome) {
            setTimeout(function () {
                _this.editMode = !_this.editMode;
                _this.change.emit(_this.editMode);
                _this.isFirstClick = false;
            }, 400);
        }
        else {
            setTimeout(function () {
                _this.editMode = !_this.editMode;
                _this.change.emit(_this.editMode);
                _this.isFirstClick = false;
            }, 800);
        }
    };
    return EditButtonComponent;
}());
export { EditButtonComponent };
