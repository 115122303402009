import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RxDataService } from '../Services/RxDataService';

@Component({
  selector: 'accept-dpa',
  templateUrl: './AcceptDPA.component.html'
})
export class AcceptDPAComponent {
  private isVisibleValue = false;
  @Input()
  public get isVisible(): boolean {
    return this.isVisibleValue;
  }
  public set isVisible(value: boolean) {
    if (value) {
      this.password = '';
    }

    this.isVisibleValue = value;
    this.isVisibleChange.emit(value);
  }
  @Output() public isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public accepted: EventEmitter<any> = new EventEmitter<any>();

  public password: string;

  constructor(private dataService: RxDataService) {}

  public onDialogAction(action: string): void {
    if (action === 'Create') {
      this.acceptDPA();
    } else {
      this.accepted.emit(false);
    }
  }

  public onAcceptDPAKeydown(event: any): void {
    if (event.keyCode === 13) {
      this.acceptDPA();
    }
  }

  private acceptDPA(): void {
    this.dataService.Companies_AcceptTerms({ Password: this.password }).subscribe(() => {
      this.isVisible = false;
      this.accepted.emit(true);
    });
  }
}
