<app-tab-panel [parentTab]="'company'"></app-tab-panel>

<div
  class="Main Island Scrollable companypadding"
  id="divCompany"
  [ngClass]="{ Mobile: IsMobile }"
  tabindex="-1"
  (click)="clickAreamenuToggledChange()"
>
  <app-company-general
    [TriigerEditMode]="editMode"
    [(isDirty)]="isDirty"
    [(editMode)]="editMode"
    *ngIf="isCompanyGeneralTabVisisble"
  ></app-company-general>

  <span class="company-payroll-data-padding-bottom">
    <app-company-payroll-data *ngIf="isCompanyPayrollDataTabVisisble"></app-company-payroll-data>
  </span>

  <company-salary-batches *ngIf="isCompanySalaryBatchesTabVisisble"></company-salary-batches>

  <configuration
    [(isDirty)]="isDirty"
    [(editMode)]="editMode"
    *ngIf="isCompanyConfigurationTabVisisble"
  ></configuration>

  <span class="company-modules-padding-bottom">
    <company-modules *ngIf="isCompanyExtraTabVisisble" [(companyClick)]="companyClick"></company-modules>
  </span>

  <app-approval *ngIf="isCompanyApprovalVisisble"></app-approval>

  <app-feedback></app-feedback>
</div>

<app-dialog [(visible)]="mobileWarningDialogVisible">
  <div [innerHTML]="'Company.AlertAppInProblematicViews' | translate"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onConfirmSaveDataOnChangeState($event)" [(visible)]="showSaveDataConfirmationChangeState">
  <div [innerHTML]="'Employee.UnsavedData' | translate"></div>
  <app-dialog-action type="DiscardAndLeave"></app-dialog-action>
  <app-dialog-action type="Stay"></app-dialog-action>
  <app-dialog-action type="ContinueAndSave"></app-dialog-action>
</app-dialog>
