import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { SessionService } from './Services/SharedServices/Session/SessionService';
import { StaticDataService } from './Services/SharedServices/StaticData.service';

@Component({
  selector: 'app',
  template: `
    <ng-progress id="gratisalProgress" [thick]="true" [spinner]="false" [color]="'#268abc'"></ng-progress>
    <icon-definitions></icon-definitions>
    <div ui-view></div>
  `
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private titleService: Title,
    private renderer: Renderer2,
    private sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {}

  public ngOnInit(): void {
    if (environment.environment !== 'PROD') {
      this.titleService.setTitle(environment.environment + ' Gratisal');
    }

    if (this.sessionService.IsAuthenticated) {
      if (!environment.production) {
        console.log('Already logged in');
      }

      this.staticDataService.loadStaticData();
    }

    this.sessionService.OnLocationChanged.subscribe((state: string) => {
      if (!this.sessionService.IsAuthenticated) {
        if (!environment.production) {
          console.log('app cmp goto login');
        }

        this.sessionService.NavigateTo('login');
      }
    });
  }

  public ngAfterViewInit(): void {
    this.renderer.listen(window, 'keyboardWillShow', () => this.renderer.addClass(document.body, 'mobileIOSApp'));
    this.renderer.listen(window, 'keyboardDidHide', () => this.renderer.removeClass(document.body, 'mobileIOSApp'));
  }
}
