import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { ITaxCardModel } from '../../../Model/ITaxCardModel';
import { ICompanyUser, ITaxCardType, IUserEmployment, IUserTaxInfo } from '../../../Services/ApiModel';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';

@Component({
  selector: 'app-employee-general',
  templateUrl: './EmployeeGeneralView.component.html'
})
export class EmployeeGeneralComponent extends FormComponentBase implements OnInit {
  @Output() reloadUserEmployment: EventEmitter<void> = new EventEmitter<void>();
  @Output() companyUserChange: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();
  @Output() userEmploymentChange: EventEmitter<IUserEmployment> = new EventEmitter<IUserEmployment>();
  @Input() isSelfService = false;

  @Input()
  public get companyUser(): ICompanyUser {
    return this.companyUserValue;
  }
  public set companyUser(value: ICompanyUser) {
    if (!this.companyUserValue && !value) {
      return;
    }

    this.companyUserValue = value;
    this.companyUserChange.emit(value);
  }

  @Input()
  public get userEmployment(): IUserEmployment {
    return this.userEmploymentValue;
  }
  public set userEmployment(value: IUserEmployment) {
    if (!this.userEmploymentValue && !value) {
      return;
    }
    this.taxCardDataChanged = true;
    this.userEmploymentValue = value;
    this.userEmploymentChange.emit(value);
  }

  @Output() public resendWelcomeEmailChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public resetPasswordChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public get resendWelcomeEmail(): boolean {
    return this.resendWelcomeEmailValue;
  }
  public set resendWelcomeEmail(value: boolean) {
    this.resendWelcomeEmailValue = value;
    this.resendWelcomeEmailChange.emit(value);
  }

  @Input()
  public get resetPassword(): boolean {
    return this.resetPasswordValue;
  }
  public set resetPassword(value: boolean) {
    this.resetPasswordValue = value;
    this.resetPasswordChange.emit(value);
  }

  @Input() public allowEdit: boolean;

  public resendEmailSuccessVisible: boolean;
  public resetPasswordSuccessVisible: boolean;
  public get taxCardObject(): ITaxCardModel {
    const userEmployment: IUserEmployment = this.userEmployment;
    this.setTaxCardData(userEmployment);
    return this.taxCardData;
  }

  public taxCardData: ITaxCardModel;
  private companyUserValue: ICompanyUser;
  private userEmploymentValue: IUserEmployment;
  private taxCardDataChanged = false;
  private resetPasswordValue: boolean;
  private resendWelcomeEmailValue: boolean;
  private resendEmployeeWelcomEmailVisibleValue: boolean;
  private resendEmailSuccessVisibleValue: boolean;
  private resetEmployeePasswordVisibleValue: boolean;
  private resetPasswordSuccessVisibleValue: boolean;
  private taxCardTypes: ITaxCardType[];
  public saveTaxInfo: IUserTaxInfo;

  constructor(
    private dataService: RxDataService,
    private settingService: SettingService,
    public sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {
    super();
    this.childIsValid = Array(4).fill(true);
    this.childIsDirty = Array(4).fill(false);
  }

  public ngOnInit(): void {
    if (this.IsActive) {
      this.settingService.showModuleMessage('Employee.General');
    }
  }

  private get IsActive(): boolean {
    return (
      this.sessionService.currentState === 'tabs.employee.general' ||
      this.sessionService.currentState === 'tabs.selfservice.general'
    );
  }

  private isLoadTaxCardType = false;
  private setTaxCardData(value: IUserEmployment, newtaxInfo?: IUserTaxInfo): void {
    if (this.taxCardDataChanged || newtaxInfo) {
      let isSpecialTaxBrief: boolean;
      if (value && (value.IncomeTypeId === 0 || value.IncomeTypeId === Constants.MAGIC_NUMBER)) {
        isSpecialTaxBrief = false;
      } else {
        isSpecialTaxBrief = true;
      }

      if (this.taxCardTypes && this.taxCardTypes.length > 0 && this.isLoadTaxCardType) {
        // Only create a new instance of taxcard if there is changes
        this.taxCardData = {
          taxInfo: newtaxInfo ? newtaxInfo : this.saveTaxInfo ? this.saveTaxInfo : undefined,
          taxCardRequestTime: value ? value.TaxCardRequested : undefined,
          taxCardTypes: this.taxCardTypes,
          userEmploymentId: value ? value.Id : undefined,
          specialTaxBrief: isSpecialTaxBrief
        };
        this.taxCardDataChanged = false;
      } else {
        this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ITaxCardType[]) => {
          this.taxCardTypes = data;
          // Only create a new instance of taxcard if there is changes
          this.taxCardData = {
            taxInfo: undefined,
            taxCardRequestTime: value ? value.TaxCardRequested : undefined,
            taxCardTypes: this.taxCardTypes,
            userEmploymentId: value ? value.Id : undefined,
            specialTaxBrief: isSpecialTaxBrief
          };
          this.taxCardDataChanged = false;
          this.isLoadTaxCardType = true;
        });
      }
    }
  }

  public onComponentChanged(isReload: boolean = false): void {
    this.onChange();
    if (isReload) {
      this.reloadUserEmployment.emit();
    }
  }

  public onresendEmployeeWelcomEmailConfirmCompleted(action: string): void {
    if (action === 'Proceed') {
      this.dataService.CompanyUsers_ResendUserEmploymentEmail(this.companyUser.Id).subscribe(() => {
        this.resendEmailSuccessVisible = true;
      });
    }
  }

  public onResetEmployeePasswordConfirmCompleted(action: string): void {
    if (action === 'Proceed') {
      this.dataService.CompanyUsers_ResetUserEmploymentPassword(this.companyUser.Id).subscribe((data: any): any => {
        this.resetPasswordSuccessVisible = true;
      });
    }
  }

  public requestTaxcardDialogVisible: boolean;
  public taxcardHistoryDialogVisible: boolean;
  public requestTaxcardWarningDialog: boolean;
  public triggerRefreshTaxCard: boolean;
  public onReloadTaxCard(): void {
    this.triggerRefreshTaxCard = !this.triggerRefreshTaxCard;
  }

  public onRequestTaxcardWarningDialogAction(action: string): void {
    if (action === 'AlreadyRequestedTaxCardConfirmButton') {
      this.showRequestTaxcardDialog();
    }
  }

  public showRequestTaxcardDialog(): void {
    this.requestTaxcardDialogVisible = true;
  }

  public showViewTaxCardHistory(value: IUserTaxInfo): void {
    this.saveTaxInfo = value;
    this.taxcardHistoryDialogVisible = true;
  }

  public showRequestTaxcardConfirm(value: IUserTaxInfo): void {
    this.saveTaxInfo = value;
    this.requestTaxcardWarningDialog = true;
  }

  public onCreateTaxInfo(value: IUserTaxInfo): void {
    if (value) {
      this.setTaxCardData(this.userEmployment, value);
    }
  }
}
