<div class="PreferencesView">
  <div class="Card Card--large" *ngIf="owner === 'company'">
    <div class="Card-inner panelbar-view">
      <div class="FormElement hintPayrollBatches">
        {{ 'Preference.Warning' | translate }}
      </div>
      <div class="FormElement" style="overflow:auto;">
        <div class="PreferencesControls header-only FormElement-grid k-grid k-widget">
          <table class="Preferences-list">
            <thead>
              <tr>
                <th>{{ 'Preference.Name' | translate }}</th>
                <th>{{ 'Preference.Description' | translate }}</th>
                <th>{{ 'Preference.Value' | translate }}</th>
              </tr>
            </thead>
          </table>
        </div>

        <kendo-panelbar
          #companyPanelBar
          [expandMode]="1"
          [animate]="false"
          (stateChange)="onCompanyPanelChanged($event)"
        >
          <kendo-panelbar-item *ngFor="let category of companyReferencesCategory" title="">
            <ng-template kendoPanelBarItemTitle let-dataItem>
              <span class="panel-title">{{ category.Name }}</span>
            </ng-template>

            <ng-template kendoPanelBarContent>
              <div class="PreferencesControls FormElement-grid k-grid k-widget">
                <table class="Preferences-list">
                  <tbody>
                    <tr *ngFor="let item of category.Preferences">
                      <td>{{ item.Name }}</td>
                      <td>
                        {{ item.Description }}
                        <span *ngIf="item.HelpUrl && !isIOSMobileApp" class="Grid-addon">
                          <app-icon
                            [icon]="'Question'"
                            [tooltip]="'Price.ReadMore' | translate"
                            [link]="item.HelpUrl"
                          ></app-icon>
                        </span>
                      </td>
                      <td class="align-center">
                        <module-check [moduleId]="item.RequiredModuleId" [(hasModule)]="item.hasModule">
                          <check-edit
                            *ngIf="isDataTypeBoolean(item.DataType)"
                            [(value)]="item.Value"
                            [disable]="item.RequiredModuleId && !item.hasModule"
                            customClass="checkBox-center"
                            [editMode]="!IsReadOnly"
                            (valueChange)="submitValueChange($event, item)"
                          ></check-edit>

                          <numeric-edit
                            *ngIf="isDataTypeNumber(item.DataType)"
                            [editMode]="!IsReadOnly"
                            [disable]="item.RequiredModuleId && !item.hasModule"
                            [option]="numericTextBoxOption"
                            [customModelOptions]="{ debounce: 1500 }"
                            [centerText]="true"
                            [(value)]="item.Value"
                            (valueChange)="submitValueChange($event, item)"
                          ></numeric-edit>

                          <app-text-edit
                            *ngIf="isDataTypeTextBox(item.DataType)"
                            [(value)]="item.Value"
                            [disabled]="item.RequiredModuleId && !item.hasModule"
                            inputClass="center"
                            [editMode]="!IsReadOnly"
                            (valueChange)="submitValueChange($event, item)"
                          ></app-text-edit>

                          <combobox-edit-control
                            *ngIf="isDataTypeCombobox(item.DataType)"
                            [editMode]="!IsReadOnly"
                            [textField]="item.DisplayText"
                            [idField]="item.UnderlyingId"
                            [disable]="item.RequiredModuleId && !item.hasModule"
                            [comboboxDataSource]="item.DataSource"
                            [(value)]="item.Value"
                            (valueChange)="submitValueChange($event, item)"
                          ></combobox-edit-control>
                        </module-check>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar>
      </div>
    </div>
  </div>

  <div class="Card Card--large" *ngIf="owner === 'user'">
    <div class="Card-inner">
      <h2 class="Card-title">{{ 'Preference.UserOption' | translate }}</h2>
      <div class="FormElement">
        <div class="PreferencesControls FormElement-grid k-grid k-widget">
          <table class="Preferences-list">
            <thead>
              <tr>
                <th>{{ 'Preference.Name' | translate }}</th>
                <th>{{ 'Preference.Description' | translate }}</th>
                <th>{{ 'Preference.Value' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of userPreferences">
                <td>{{ item.Name }}</td>
                <td>
                  {{ item.Description }}
                  <span *ngIf="item.HelpUrl && !isIOSMobileApp" class="Grid-addon">
                    <app-icon
                      [icon]="'Question'"
                      [tooltip]="'Price.ReadMore' | translate"
                      [link]="item.HelpUrl"
                    ></app-icon>
                  </span>
                </td>
                <td>
                  <check-edit
                    *ngIf="isDataTypeBoolean(item.DataType)"
                    [(value)]="item.Value"
                    customClass="checkBox-center"
                    [editMode]="true"
                    (valueChange)="submitValueChange($event, item)"
                  ></check-edit>

                  <numeric-edit
                    *ngIf="isDataTypeNumber(item.DataType)"
                    [editMode]="true"
                    [option]="numericTextBoxOption"
                    [customModelOptions]="{ debounce: 1500 }"
                    [centerText]="true"
                    [(value)]="item.Value"
                    (valueChange)="submitValueChange($event, item)"
                  ></numeric-edit>

                  <app-text-edit
                    *ngIf="isDataTypeTextBox(item.DataType)"
                    [(value)]="item.Value"
                    inputClass="center"
                    [editMode]="true"
                    (valueChange)="submitValueChange($event, item)"
                  ></app-text-edit>

                  <combobox-edit-control
                    *ngIf="isDataTypeCombobox(item.DataType)"
                    [editMode]="true"
                    [textField]="item.DisplayText"
                    [idField]="item.UnderlyingId"
                    [comboboxDataSource]="item.DataSource"
                    [(value)]="item.Value"
                    (valueChange)="submitValueChange($event, item)"
                  ></combobox-edit-control>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="Actions">
    <help-button module="company" currentTab="tabs.company.configuration.settings"></help-button>
  </div>
</div>
