import { EventEmitter } from '@angular/core';
import { Global } from '../../../../Common/Global';
import { RxDataService } from '../../../../Services/RxDataService';
var NegativeDefaultMoreInfoDialogComponent = /** @class */ (function () {
    function NegativeDefaultMoreInfoDialogComponent(dataService) {
        this.dataService = dataService;
        this.showDialogChange = new EventEmitter();
        this.usePositiveAmount = new EventEmitter();
        this.enableAutoCorrect = new EventEmitter();
        this.setting = true;
        this.showDialogValue = false;
    }
    Object.defineProperty(NegativeDefaultMoreInfoDialogComponent.prototype, "showDialog", {
        get: function () {
            return this.showDialogValue;
        },
        set: function (value) {
            this.showDialogValue = value;
            this.showDialogChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    NegativeDefaultMoreInfoDialogComponent.prototype.onUsePositiveAmount = function () {
        this.usePositiveAmount.emit({ dataItem: this.dataItem, fieldName: this.fieldName });
    };
    NegativeDefaultMoreInfoDialogComponent.prototype.onEnableAutoCorrect = function () {
        var _this = this;
        var userPref = { Key: 'SalaryRecords.AutoFlipAmounts', Value: 'true' };
        this.dataService.Preferences_SetUserPreference(userPref).subscribe(function () {
            _this.updateUserPreference(userPref);
            _this.enableAutoCorrect.emit({ dataItem: _this.dataItem, fieldName: _this.fieldName });
        });
    };
    NegativeDefaultMoreInfoDialogComponent.prototype.disableAutoCorrect = function () {
        var _this = this;
        var userPref = { Key: 'SalaryRecords.AutoFlipAmounts', Value: 'false' };
        this.dataService.Preferences_SetUserPreference(userPref).subscribe(function () {
            _this.updateUserPreference(userPref);
            _this.usePositiveAmount.emit({ dataItem: _this.dataItem, fieldName: _this.fieldName });
        });
    };
    NegativeDefaultMoreInfoDialogComponent.prototype.onDialogAction = function (action) {
        this.showDialogChange.emit(false);
        if (action === 'UsePositiveAmountThisTime') {
            this.onUsePositiveAmount();
        }
        if (action === 'DisableThisBehaviour') {
            this.disableAutoCorrect();
        }
        if (action === 'EnableAutoCorrect') {
            this.onEnableAutoCorrect();
        }
    };
    NegativeDefaultMoreInfoDialogComponent.prototype.updateUserPreference = function (userPref) {
        var userPreferences = Global.USER_PREFERENCES;
        var updatedUserPref;
        if (userPreferences) {
            updatedUserPref = userPreferences.find(function (data) {
                return data.Key === userPref.Key;
            });
        }
        if (updatedUserPref) {
            updatedUserPref.Value = userPref.Value;
        }
        else {
            userPreferences.push(userPref);
        }
        Global.USER_PREFERENCES = userPreferences;
    };
    return NegativeDefaultMoreInfoDialogComponent;
}());
export { NegativeDefaultMoreInfoDialogComponent };
