import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Global } from '../../Common/Global';
import { RxDataService } from '../../Services/RxDataService';
import { DownloadService } from '../../Services/SharedServices/DownloadService';
import { SessionService } from '../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../Services/SharedServices/StaticData.service';
import { ReportParametersDefaultValue } from './ReportParametersDefaultValue';
var ReportDialogComponent = /** @class */ (function () {
    function ReportDialogComponent(staticDataService, dataService, sessionService, downloadService, translateService) {
        var _this = this;
        this.staticDataService = staticDataService;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.downloadService = downloadService;
        this.translateService = translateService;
        this.visibleValue = false;
        this.noReportVisible = false;
        this.visibleChange = new EventEmitter();
        this.gridVisible = true;
        this.numericTextBoxOption = { min: 0, step: 1, spinners: false };
        this.missingRequiredFields = '';
        this.validationDialogVisible = false;
        this.newTabBlockedDialogVisible = false;
        this.safariWarningDialogVisible = false;
        this.safariWarning = '';
        this.htmlReportDialogVisible = false;
        this.htmlReportDialogContent = '';
        this.multiSelectValues = [];
        this.ngUnsubscribe = new Subject();
        this.translationServiceTerms = ['DropdownList.None'];
        this.staticDataService.ReportParameter.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.reportParameters = data); });
        this.staticDataService.ReportArea.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.reportAreas = data); });
        this.staticDataService.departments
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) { return (_this.departments = data); });
        this.staticDataService.SalaryCycle.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.salaryCycles = data); });
    }
    Object.defineProperty(ReportDialogComponent.prototype, "key", {
        get: function () {
            return this.keyValue;
        },
        set: function (value) {
            if (this.keyValue !== value) {
                this.keyValue = value;
                this.reports = undefined;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                if (this.isMobile) {
                    this.selectedReport = undefined;
                }
                this.gridVisible = true;
                this.visibleValue = value;
                if (value) {
                    if (!this.reports) {
                        this.getReports();
                    }
                    this.getSelectedReport();
                }
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "selectedReport", {
        get: function () {
            return this.selectedReportValue;
        },
        set: function (value) {
            if (value) {
                this.gridVisible = false;
            }
            this.selectedReportValue = value;
            this.selectedFormat = undefined;
            this.createReportControls();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "formatOptions", {
        get: function () {
            var options = [];
            if (this.selectedReport) {
                options = this.selectedReport.SupportedFormats.split(',').sort(function (a, b) {
                    var order = ['html', 'pdf', 'xlsx', 'csv', 'bank'];
                    return order.indexOf(a) - order.indexOf(b);
                });
                if (!this.selectedFormat && options) {
                    this.selectedFormat = options[0];
                }
            }
            return options;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "diaglogWidth", {
        get: function () {
            if (this.isMobile) {
                return 600;
            }
            return 750;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "displayFlex", {
        get: function () {
            if (this.isMobile) {
                return '';
            }
            return 'displayFlex';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "MultiSelectValues", {
        get: function () {
            return this.multiSelectValues;
        },
        set: function (value) {
            this.multiSelectValues = value || [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "hiddenScroll", {
        get: function () {
            if (this.isMobile) {
                return '';
            }
            return 'hiddenScroll';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "isCordovaApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    ReportDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService.get(_this.translationServiceTerms).subscribe(function (translations) {
                _this.noneItemText = translations['DropdownList.None'];
            });
        });
    };
    ReportDialogComponent.prototype.onSelectedChanged = function (values) {
        this.MultiSelectValues = values;
    };
    ReportDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    ReportDialogComponent.prototype.onDialogAction = function (action) {
        var _this = this;
        if (action === 'Download') {
            if (!this.validate()) {
                return;
            }
            var reportRequest = {
                ReportId: this.selectedReport.Id,
                ParameterValues: this.getReportParameterValues()
            };
            var request = void 0;
            switch (this.selectedFormat) {
                case 'pdf':
                    request = this.dataService.Reports_GetPdfReport(reportRequest);
                    this.safariWarning = 'Download.SafariWarning_PDF';
                    break;
                case 'html':
                    request = this.dataService.Reports_GetHtmlReportDownload(reportRequest);
                    break;
                case 'xlsx':
                    request = this.dataService.Reports_GetXlsxReportDownload(reportRequest);
                    this.safariWarning = 'Download.SafariWarning_XLSX';
                    break;
                case 'csv':
                    request = this.dataService.Reports_GetCsvReportDownload(reportRequest);
                    this.safariWarning = 'Download.SafariWarning_CSV';
                    break;
                case 'bank':
                    request = this.dataService.Reports_GetBankReportDownload(reportRequest);
                    break;
            }
            request.subscribe(function (response) {
                if (_this.selectedFormat === 'html') {
                    if (!_this.sessionService.browser.isMobile) {
                        var newWindow = window.open('about:blank');
                        if (newWindow) {
                            newWindow.document.write(response);
                            newWindow.document.close();
                            _this.visible = false;
                            if (!_this.isMobile) {
                                _this.reports = undefined;
                            }
                        }
                        else {
                            _this.newTabBlockedDialogVisible = true;
                        }
                    }
                    else {
                        _this.htmlReportDialogContent = response;
                        _this.htmlReportDialogVisible = true;
                        _this.visible = false;
                    }
                }
                else {
                    if (_this.sessionService.browser.isMobileSafari) {
                        _this.safariWarningDialogVisible = true;
                    }
                    else if (!_this.isMobile) {
                        _this.reports = undefined;
                    }
                    _this.downloadService.download(_this.selectedReport.Name, response);
                    _this.visible = false;
                }
            });
        }
    };
    ReportDialogComponent.prototype.getControlDefaultLabel = function (control) {
        return control.nullValueLabel ? control.nullValueLabel : 'Report.ComboboxDataSourceNoneFound';
    };
    ReportDialogComponent.prototype.getReports = function () {
        var _this = this;
        if (this.key) {
            this.staticRoleId = Global.SESSION.CurrentRole.Id;
            var area = this.reportAreas.find(function (a) { return a.Key === _this.key; });
            if (area && this.staticRoleId) {
                this.dataService.Reports_GetReportsByArea(area.Id).subscribe(function (reports) {
                    if (reports.length > 0) {
                        _this.reports = reports.sort(function (a, b) { return (a.SortKey > b.SortKey ? 1 : -1); });
                        _this.reports = _this.reports.filter(function (z) { return z.RequiredRoleId <= _this.staticRoleId; });
                        _this.getSelectedReport();
                    }
                    else {
                        _this.visible = false;
                        _this.noReportVisible = true;
                    }
                });
            }
        }
    };
    ReportDialogComponent.prototype.getReportParameterValues = function () {
        var _this = this;
        var parametersValue = [];
        var value;
        if (this.reportControls) {
            this.reportControls.forEach(function (reportControl) {
                value = reportControl.value;
                if (reportControl.type && reportControl.type.toLowerCase() === 'datepicker' && reportControl.value) {
                    value = reportControl.value;
                }
                if (reportControl.type && reportControl.type.toLowerCase() === 'checkbox' && !reportControl.value) {
                    value = false;
                }
                if (reportControl.type && reportControl.type.toLowerCase() === 'combobox') {
                    value = _this.getComboboxParameterValue(reportControl);
                }
                if (reportControl.type && reportControl.type.toLowerCase() === 'multiselectlist') {
                    value = _this.getMultiSelectListParameterValue(reportControl);
                }
                parametersValue.push({ Key: reportControl.key, Value: value });
            });
        }
        return parametersValue;
    };
    ReportDialogComponent.prototype.getMultiSelectListParameterValue = function (multiselectlistControl) {
        var valueString = '';
        var multiSelectValueIds = this.MultiSelectValues.map(function (item) {
            var id;
            id = item[multiselectlistControl.underlyingId];
            return id;
        });
        valueString = JSON.stringify(multiSelectValueIds);
        return valueString;
    };
    ReportDialogComponent.prototype.getComboboxParameterValue = function (comboboxControl) {
        var value;
        if (comboboxControl.value && comboboxControl.dataSource) {
            var comboObject = comboboxControl.dataSource.find(function (data) { return data[comboboxControl.underlyingId] === comboboxControl.value; });
            value = comboObject ? comboboxControl.value : undefined;
        }
        return value;
    };
    ReportDialogComponent.prototype.createReportControls = function () {
        var _this = this;
        setTimeout(function () {
            _this.reportControls = [];
            if (_this.selectedReport && _this.selectedReport.Parameters) {
                var parameters = _this.selectedReport.Parameters.split(',');
                if (parameters) {
                    parameters.forEach(function (parameter) {
                        var control = _this.createReportControl(parameter);
                        if (control) {
                            _this.reportControls.push(control);
                            if (control.type === 'ComboBox') {
                                control.placeholderText = 'Report.NoAvailableOptions';
                                _this.loadComboboxDataSource(control);
                            }
                            if (control.type === 'MultiSelectList') {
                                control.placeholderText = 'Report.NoAvailableOptions';
                                _this.loadMultiSelectList(control);
                            }
                        }
                    });
                }
            }
        });
    };
    ReportDialogComponent.prototype.loadMultiSelectList = function (control) {
        var _this = this;
        var listDataSourceParts = this.getPartsFromListDataSourceStringOfMultiSelect(control.listDataSource);
        var displayColumns = listDataSourceParts.displayText.split(',');
        this.translateService
            .get(['Report.FullName', 'Report.Title'])
            .subscribe(function (translations) {
            var displayTitleColumns = [translations['Report.FullName'], translations['Report.Title']];
            _this.columns = [
                { dataField: displayColumns[0], caption: displayTitleColumns[0] },
                { dataField: displayColumns[1], caption: displayTitleColumns[1] }
            ];
            _this.displayExp = displayColumns[0];
        });
        control.underlyingId = listDataSourceParts.underlyingId;
        this.dataService.httpGet(environment.apiUrl + '/' + listDataSourceParts.api).subscribe(function (data) {
            _this.assignControlDataSource(control, listDataSourceParts, data);
        });
    };
    ReportDialogComponent.prototype.getPartsFromListDataSourceStringOfMultiSelect = function (stringListDataSource) {
        stringListDataSource = stringListDataSource.replace(':', ';');
        var parts = stringListDataSource.split(';');
        // const employmentId: string = this.param && this.param.employmentId ? this.param.employmentId.toString() : '';
        return {
            api: parts[0],
            underlyingId: parts[1],
            displayText: parts[2]
        };
    };
    ReportDialogComponent.prototype.loadComboboxDataSource = function (control) {
        var _this = this;
        var listDataSourceParts = this.getPartsFromListDataSourceStringOfCombobox(control.listDataSource);
        control.underlyingId = listDataSourceParts.underlyingId;
        control.displayText = listDataSourceParts.displayText;
        if (control.nullValueLabel && control.nullValueLabel === 'DropdownList.None') {
            //control.nullValueLabel = nonCombo
        }
        if (control.key === 'CompanyUserId') {
            this.staticDataService.companyUsers
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(function (data) { return _this.assignControlDataSource(control, listDataSourceParts, data); });
        }
        else {
            this.dataService.httpGet(environment.apiUrl + '/' + listDataSourceParts.api).subscribe(function (data) {
                _this.assignControlDataSource(control, listDataSourceParts, data);
            });
        }
    };
    ReportDialogComponent.prototype.assignControlDataSource = function (control, listDataSourceParts, data) {
        var _this = this;
        var sameApiComboboxes = this.reportControls.filter(function (ctrl) {
            if (ctrl.listDataSource) {
                var ctrlListDataSourceParts = _this.getPartsFromListDataSourceStringOfCombobox(ctrl.listDataSource);
                return ctrlListDataSourceParts.api === listDataSourceParts.api;
            }
            else {
                return false;
            }
        });
        if (!sameApiComboboxes || sameApiComboboxes.length === 0) {
            return;
        }
        if (data && data.length > 0) {
            if (control.defaultValue === '{All}') {
                //control.defaultValue = data;
                //control.value = data;
                this.multiSelectValues = data;
            }
            else if (control.defaultValue && control.underlyingId) {
                var isExitDefaultValueDataSource = data.find(function (model) { return model[control.underlyingId] === control.defaultValue; });
                if (!isExitDefaultValueDataSource) {
                    var isExitIdValueDataSource = data.find(function (model) {
                        return (model.Id ? model.Id.toString() : model.Id) ===
                            (control.defaultValue ? control.defaultValue.toString() : control.defaultValue);
                    });
                    if (isExitIdValueDataSource) {
                        control.value = isExitIdValueDataSource[control.underlyingId];
                    }
                }
            }
            control.placeholderText = '';
            data.forEach(function (model) {
                if (!model[control.underlyingId]) {
                    control.nullValueLabel = model.Name;
                    var indexOf = data.indexOf(model, 0);
                    if (indexOf === 0) {
                        control.hasItemNull = true;
                    }
                    data.splice(indexOf, 1);
                }
            });
        }
        control.dataSource = data;
        control.dataSourceEmpty = data === undefined || data === null || data.length < 1;
        if (!control.dataSourceEmpty && control.defaultValue === '{First}') {
            if (control.hasItemNull) {
                control.value = undefined;
            }
            else {
                control.value = data[0][control.underlyingId];
            }
        }
        if (sameApiComboboxes && sameApiComboboxes.length > 0) {
            sameApiComboboxes.forEach(function (apiCombo) {
                if (!apiCombo.dataSource || apiCombo.dataSource.length === 0) {
                    apiCombo.dataSource = data;
                    apiCombo.dataSourceEmpty = data === undefined || data === null || data.length < 1;
                }
                if (!apiCombo.dataSourceEmpty && apiCombo.defaultValue === '{First}') {
                    if (control.hasItemNull) {
                        apiCombo.value = undefined;
                    }
                    else {
                        apiCombo.value = data[0][control.underlyingId];
                    }
                }
            });
        }
        if (control.value && control.value.length === 1) {
            var indexOfValue = control.dataSource.find(function (model) { return model[control.underlyingId] === control.value; });
            if (!indexOfValue) {
                control.nullValueLabel = control.value;
                control.value = undefined;
            }
        }
    };
    ReportDialogComponent.prototype.getPartsFromListDataSourceStringOfCombobox = function (stringListDataSource) {
        stringListDataSource = stringListDataSource.replace(':', ';');
        var parts = stringListDataSource.split(';');
        var employmentId = this.param && this.param.employmentId ? this.param.employmentId.toString() : '';
        return {
            api: parts[0].indexOf('{CurrentUserEmploymentId}') >= 0
                ? parts[0].replace('{CurrentUserEmploymentId}', employmentId)
                : parts[0],
            underlyingId: parts[1],
            displayText: parts[2]
        };
    };
    ReportDialogComponent.prototype.createReportControl = function (parameter) {
        var reportParameter = this.reportParameters.find(function (par) { return par.Key === parameter; });
        if (!reportParameter) {
            console.log('reportParameter is undefined for parameter: ' + parameter);
            return undefined;
        }
        var key = reportParameter['Key'];
        // let defaultValue: any = this.getSelectedReportControlDefaultValueByKey(key);
        var defaultValue = this.getSelectedReportControlDefaultValue(reportParameter['DefaultValue'])
            ? this.getSelectedReportControlDefaultValue(reportParameter['DefaultValue'])
            : undefined;
        if (!defaultValue && reportParameter['IsRequired']) {
            defaultValue = this.getSelectedReportControlDefaultValueByKey(key);
        }
        var nonCombo = '';
        if (!reportParameter['IsRequired'] && reportParameter['IsRequired'] === false) {
            nonCombo = 'DropdownList.None';
        }
        return {
            type: reportParameter['UiControlType'],
            defaultValue: defaultValue,
            value: defaultValue !== '{First}' ? defaultValue : undefined,
            listDataSource: reportParameter['ListDataSource'],
            nullValueLabel: nonCombo,
            isRequired: reportParameter['IsRequired'],
            name: reportParameter['Name'],
            key: key,
            sortKey: reportParameter['SortKey'],
            displayText: '',
            underlyingId: '',
            dataSource: [],
            dataSourceEmpty: false
        };
    };
    ReportDialogComponent.prototype.getSelectedReportControlDefaultValueByKey = function (key) {
        var defaultValue;
        switch (key) {
            case 'SalaryBatchId':
                defaultValue = this.param.salaryBatchId;
                break;
            case 'OptionalSalaryBatchId':
                defaultValue = this.param.salaryBatchId;
                break;
            case 'FilterByDepartment':
                if (this.departments.length > 0) {
                    defaultValue = this.departments[0];
                }
                break;
            case 'FilterBySalaryCycle':
                if (this.salaryCycles.length > 0) {
                    defaultValue = this.salaryCycles[0];
                }
                break;
            case 'CompanyUserId':
                defaultValue = this.param.employeeId;
                break;
            case 'SelfEmploymentId':
                defaultValue = this.param.employmentId;
                break;
            case 'UserEmploymentId':
                defaultValue = this.param.employmentId;
                break;
            case 'FromDate_YearBegin':
                defaultValue = this.getFirstDayOfCurrentYear();
                break;
            case 'ToDate_Today':
                defaultValue = this.staticDataService.getCurrentdate();
                break;
            case 'FromDate_LastMonthStart':
                defaultValue = this.getFirstDayOfPreviousMonth();
                break;
            case 'ToDate_LastMonthEnd':
                defaultValue = this.getLastDateOfPreviousMonth();
                break;
        }
        return defaultValue;
    };
    ReportDialogComponent.prototype.getSelectedReportControlDefaultValue = function (defaultValue) {
        switch (defaultValue) {
            case '{Current SalaryBatchId}':
                defaultValue = this.param.salaryBatchId;
                break;
            case '{Today}':
                defaultValue = this.staticDataService.getCurrentdate();
                break;
            case '{First day of current year}':
                defaultValue = this.getFirstDayOfCurrentYear();
                break;
            case '{First day of previous month}':
                defaultValue = this.getFirstDayOfPreviousMonth();
                break;
            case '{Last day of previous month}':
                defaultValue = this.getLastDateOfPreviousMonth();
                break;
        }
        return defaultValue;
    };
    ReportDialogComponent.prototype.getFirstDayOfCurrentYear = function () {
        var date = new Date();
        return this.staticDataService.getCurrentdate(undefined, new Date(date.getFullYear(), 0, 1));
    };
    ReportDialogComponent.prototype.getFirstDayOfPreviousMonth = function () {
        var date = new Date();
        var currentYear = date.getFullYear();
        var previousMonth = date.getMonth() - 1;
        if (previousMonth === 0) {
            currentYear -= 1;
            previousMonth = 12;
        }
        return this.staticDataService.getCurrentdate(undefined, new Date(currentYear, previousMonth, 1));
    };
    ReportDialogComponent.prototype.getLastDateOfPreviousMonth = function () {
        var date = new Date();
        return this.staticDataService.getCurrentdate(undefined, new Date(date.getFullYear(), date.getMonth(), 0));
    };
    ReportDialogComponent.prototype.validate = function () {
        this.missingRequiredFields = this.checkRequiredFields();
        if (this.missingRequiredFields) {
            this.validationDialogVisible = true;
            return false;
        }
        return true;
    };
    ReportDialogComponent.prototype.checkRequiredFields = function () {
        var _this = this;
        var requiredFields = '';
        if (this.reportControls) {
            this.reportControls.forEach(function (reportControl) {
                if (!_this.isReportControlValid(reportControl)) {
                    requiredFields += reportControl.name + ' ,';
                }
            });
        }
        if (!requiredFields) {
            return undefined;
        }
        requiredFields = requiredFields.slice(0, requiredFields.length - 2);
        return requiredFields;
    };
    ReportDialogComponent.prototype.isReportControlValid = function (reportControl) {
        if (!reportControl.isRequired) {
            return true;
        }
        if (reportControl.type && reportControl.type.toLowerCase() === 'checkbox') {
            return true;
        }
        if (reportControl.type &&
            reportControl.type.toLowerCase() === 'datepicker' &&
            (!reportControl.value || (reportControl.value && new Date(reportControl.value) === undefined))) {
            return false;
        }
        if (reportControl.type && reportControl.type.toLowerCase() === 'multiselectlist') {
            return true;
        }
        return reportControl.value;
    };
    ReportDialogComponent.prototype.onDoubleClick = function () {
        if (this.sessionService.isGetting) {
            return;
        }
        this.onDialogAction('Download');
    };
    ReportDialogComponent.prototype.onBackClick = function () {
        if (this.isMobile) {
            this.selectedReport = undefined;
            this.gridVisible = true;
        }
    };
    ReportDialogComponent.prototype.getSelectedReport = function () {
        var _this = this;
        if (this.reports && this.reports.length > 0 && !this.isMobile) {
            if (!this.selectedReport) {
                this.selectedReport = this.reports[0];
            }
            else {
                var existReportIndex_1 = -1;
                var existReport = this.reports.filter(function (report, index) {
                    if (report.Key === _this.selectedReport.Key) {
                        existReportIndex_1 = index;
                        return true;
                    }
                    return false;
                });
                if (existReport && existReport.length === 0 && existReportIndex_1 === -1) {
                    this.selectedReport = this.reports[0];
                }
                else {
                    this.selectedReport = this.reports[existReportIndex_1];
                }
            }
        }
    };
    return ReportDialogComponent;
}());
export { ReportDialogComponent };
