import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../Services/SharedServices/Session/SessionService';

@Pipe({
  name: 'NumericFormat'
})
export class NumericFormatPipe implements PipeTransform {
  constructor(private sessionService: SessionService) {}

  transform(value: number, format?: any): string {
    if (!value && value !== 0) {
      return '';
    }

    if (!format) {
      format = { minDecimals: 2, decimals: 0 };
    }

    const minDecimals: any = format.minDecimals || 0;
    return this.sessionService.toString(value, 'n' + minDecimals);
  }
}
