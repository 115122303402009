import * as tslib_1 from "tslib";
import { FormComponentBase } from '../../../Common/FormComponentBase';
var EmployeeNameComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeNameComponent, _super);
    function EmployeeNameComponent() {
        var _this = _super.call(this) || this;
        _this.childIsValid = Array(3).fill(true);
        _this.childIsDirty = Array(3).fill(false);
        return _this;
    }
    Object.defineProperty(EmployeeNameComponent.prototype, "companyUser", {
        get: function () {
            if (!this.companyUserValue) {
                this.companyUserValue = {};
            }
            return this.companyUserValue;
        },
        set: function (value) {
            this.companyUserValue = value;
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeNameComponent;
}(FormComponentBase));
export { EmployeeNameComponent };
