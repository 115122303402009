import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { orderBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../Common/Constants';
import { Global } from '../../../Common/Global';
import { GridHelper } from '../../../Common/GridHelper';
import { SalaryBatchStatusEnum } from '../../../Model/Enum';
import { ExtendSalaryPeriod } from '../../../Model/ExtendSalaryPeriod';
import { RxDataService } from '../../../Services/RxDataService';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { StaticDataService } from '../../../Services/SharedServices/StaticData.service';
import { CompanySalaryBatchesChildFormEntityContext } from '../CompanySalaryBatchesChildFormEntityContext';
import { SalaryBatchViewModel } from '../SalaryBatchViewModel';
var SalaryBatchDetailsComponent = /** @class */ (function () {
    function SalaryBatchDetailsComponent(dataService, sessionService, staticData) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.staticData = staticData;
        this.reloadSalaryBatches = new EventEmitter();
        this.closeDialog = new EventEmitter();
        this.entityContextChange = new EventEmitter();
        this.selectedEmployeeIdsChange = new EventEmitter();
        this.selectEmployeesGridData = [];
        this.session = Global.SESSION;
        this.loadingPeriods = false;
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "salaryCycles", {
        get: function () {
            return this.salaryCyclesValue;
        },
        set: function (value) {
            var isLoadDueToValueChanged = (!this.salaryCyclesValue || this.salaryCyclesValue.length === 0) && value && value.length > 0;
            this.salaryCyclesValue = value;
            if (isLoadDueToValueChanged) {
                this.loadDetailsData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "salaryBatch", {
        get: function () {
            return this.selectedSalaryBatchValue;
        },
        set: function (value) {
            if (this.selectedSalaryBatchValue !== value) {
                this.selectedSalaryBatchValue = value;
                if (value && value.Message) {
                    value.Message = this.decodeEntities(value.Message);
                }
                this.loadDetailsData();
            }
            else if (!value) {
                this.loadDetailsData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "entityContext", {
        get: function () {
            return this.entityContextValue ? this.entityContextValue : {};
        },
        set: function (value) {
            if (this.entityContextValue !== value) {
                this.entityContextValue = value;
                this.entityContextChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "selectedEmployeeIds", {
        get: function () {
            return this.selectedEmployeeIdsValue;
        },
        set: function (value) {
            if (this.selectedEmployeeIdsValue !== value) {
                this.selectedEmployeeIdsValue = value;
                this.selectedEmployeeIdsChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                if (value) {
                    this.loadDetailsData();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "IsExistingSalaryBatch", {
        get: function () {
            return !(this.entityContext.BatchId === undefined || this.entityContext.BatchId < 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "IsNewBatchWithEmployeeSelection", {
        get: function () {
            return this.entityContext.BatchId === -1 && this.entityContext.EmployeeCategory === 2;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "showMultiPurposeOffDayCompensation", {
        get: function () {
            var enableFlexBalanceSetting = Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.filter(function (pref) { return pref.Key === 'Company.EnableFlexBalance'; })[0]
                : undefined;
            return enableFlexBalanceSetting ? enableFlexBalanceSetting.Value === 'true' : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "isShowPaymentDate", {
        get: function () {
            return this.entityContext.BatchId === -1
                ? this.entityContext.EmployeeCategory !== 3
                : this.salaryBatch && this.salaryBatch.Id === this.entityContext.BatchId
                    ? !this.salaryBatch.EIncomeZeroReport
                    : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "PayoutAllFlexLabel", {
        get: function () {
            if (this.IsGreenlandCompany) {
                return 'CompanySalaryBatches.PayoutOffDayBalances GreenlandCompany';
            }
            return 'CompanySalaryBatches.PayoutOffDayBalances';
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticData.BankHoliday.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.bankHoliday = data;
        });
        this.companypreference = Global.COMPANY_PREFERENCES;
    };
    SalaryBatchDetailsComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SalaryBatchDetailsComponent.prototype.prepareEntityContext = function (batch) {
        this.loadingPeriods = true;
        if (batch) {
            var updateContext = new CompanySalaryBatchesChildFormEntityContext(this.sessionService);
            updateContext.BatchId = batch.Id;
            updateContext.StatusName = batch.StatusName;
            updateContext.ChangeDate = batch.Rejected ? batch.Rejected : batch.Approved ? batch.Approved : batch.Created;
            updateContext.SalaryBatchNumber = batch.SalaryBatchNumber;
            updateContext.PeriodId = batch.SalaryPeriodId;
            updateContext.IsDraft = batch.StatusId === SalaryBatchStatusEnum.Draft;
            updateContext.IsPendingForApproval = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
            updateContext.IsFinalized = batch.StatusId === SalaryBatchStatusEnum.Finalized;
            updateContext.IsCancelled = batch.StatusId === SalaryBatchStatusEnum.Cancelled;
            updateContext.IsLocked = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
            updateContext.IsPreliminary = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
            updateContext.PaymentDate = new Date(batch.PayoutDate.toString());
            updateContext.PayoutAllFlex = batch.PayoutAllFlex;
            updateContext.IsRecalcBeforeFinalize = !(updateContext.IsLocked || updateContext.IsPreliminary);
            updateContext.Message = batch.Message;
            updateContext.HasErrors = batch.HasErrors;
            updateContext.HasWarnings = batch.HasWarnings;
            updateContext.CycleId = batch.SalaryCycleId;
            updateContext.EmployeeCategory = 1;
            updateContext.HasBatch = false;
            this.entityContext = updateContext;
            this.onEmployeeCategoryChanged();
        }
        else {
            if ( /*!this.salaryBatchesContext || */!this.salaryCycles || this.salaryCycles.length === 0) {
                return;
            }
            var newContext = new CompanySalaryBatchesChildFormEntityContext(this.sessionService);
            newContext.BatchId = -1;
            newContext.StatusName = undefined;
            newContext.ChangeDate = undefined;
            newContext.IsDraft = true;
            newContext.CycleId = this.salaryCycles[0].Id;
            newContext.PeriodId = undefined;
            newContext.EmployeeCategory = 1;
            newContext.PayoutAllFlex = false;
            newContext.HasBatch = false;
            this.entityContext = newContext;
            this.onEmployeeCategoryChanged();
        }
    };
    SalaryBatchDetailsComponent.prototype.loadDetailsData = function () {
        var _this = this;
        this.prepareEntityContext(this.salaryBatch);
        if (!this.visible) {
            return;
        }
        this.isPreparingContent = true;
        if (this.entityContextValue && this.entityContextValue.CycleId) {
            this.getSalaryBatchesPeriods(this.entityContextValue.CycleId);
            this.getSalaryBatchUserEmployments();
            setTimeout(function () {
                _this.entityContext.IsDirty = false;
            });
        }
        setTimeout(function () {
            _this.isPreparingContent = false;
        }, 1000);
    };
    SalaryBatchDetailsComponent.prototype.onChildWindowSalaryCycleChange = function () {
        // if (this.loadingPeriods) {
        //     return;
        // }
        var cycleId = this.getChildWindowCycleId();
        if (cycleId) {
            this.loadingPeriods = true;
            this.getSalaryBatchesPeriods(cycleId);
            this.setSalaryBatchEmployeeGridDataSource();
        }
    };
    SalaryBatchDetailsComponent.prototype.onSalaryPeriodChanged = function () {
        this.setPaymentDateDueToPeriodChange();
        this.checkPeriod();
    };
    // public isValidPaymentDate(): void {
    //   if (this.bankHoliday && this.bankHoliday.length > 0 && this.entityContext && this.entityContext.PaymentDate) {
    //     const objectCheckvalidDate: any[] = this.bankHoliday.filter(
    //       (model: any) =>
    //         new DateTimeConvert(this.entityContext.PaymentDate).getDateString() ===
    //         new DateTimeConvert(new Date(model.Date)).getDateString()
    //     );
    //     if (
    //       (objectCheckvalidDate && objectCheckvalidDate.length > 0) ||
    //       this.entityContext.PaymentDate.getDay() === 6 ||
    //       this.entityContext.PaymentDate.getDay() === 0
    //     ) {
    //       let flagcheck: Date = new DateTimeConvert(
    //         new Date(
    //           (objectCheckvalidDate && objectCheckvalidDate[0] && objectCheckvalidDate[0].Date
    //             ? objectCheckvalidDate[0].Date
    //             : this.entityContext.PaymentDate
    //           ).toString()
    //         )
    //       ).getUTCDate();
    //       while (flagcheck) {
    //         const preflagcheck: Date = new Date(flagcheck.setDate(flagcheck.getDate() - 1));
    //         const nextObjectCheckvalidDate: any[] = this.bankHoliday.filter(
    //           (model: any) =>
    //             new DateTimeConvert(preflagcheck).getDateString() ===
    //             new DateTimeConvert(new Date(model.Date)).getDateString()
    //         );
    //         if (
    //           ((nextObjectCheckvalidDate && nextObjectCheckvalidDate.length === 0) || !nextObjectCheckvalidDate) &&
    //           (preflagcheck.getDay() !== 6 && preflagcheck.getDay() !== 0)
    //         ) {
    //           this.entityContext.PaymentDate = preflagcheck;
    //           flagcheck = undefined;
    //         } else {
    //           flagcheck = preflagcheck;
    //         }
    //       }
    //     }
    //   }
    // }
    SalaryBatchDetailsComponent.prototype.onEmployeeCategoryChanged = function (category) {
        this.onEmployeesGridDataSaveChanges();
    };
    SalaryBatchDetailsComponent.prototype.onEmployeeGridAction = function (action) {
        if (action === 'SelectAll') {
            this.onSelectAllEmployee(true);
        }
        else {
            this.onSelectAllEmployee(false);
        }
        this.onEmployeesGridDataSaveChanges();
    };
    SalaryBatchDetailsComponent.prototype.onEmployeesGridDataSaveChanges = function (event) {
        switch (this.entityContext.EmployeeCategory) {
            case 1: // All employeess
                this.selectedEmployeeIds = undefined;
                break;
            case 3: // No employees
                this.selectedEmployeeIds = [];
                break;
            case 2:
                // Selected employees
                this.selectedEmployeeIds = this.selectEmployeesGridData
                    .filter(function (e) { return e.IsChecked; })
                    .map(function (e) { return e.UserEmploymentId; });
                break;
            default:
                break;
        }
    };
    SalaryBatchDetailsComponent.prototype.onAllEmployeeSelectionChecked = function (isChecked) {
        this.entityContext.IsCheckedAllEmployees = !this.entityContext.IsCheckedAllEmployees;
        this.onSelectAllEmployee(this.entityContext.IsCheckedAllEmployees);
    };
    SalaryBatchDetailsComponent.prototype.onSelectAllEmployee = function (isChecked) {
        this.selectEmployeesGridData.forEach(function (e) { return (e.IsChecked = isChecked); });
        this.entityContext.IsCheckedAllEmployees = isChecked;
    };
    SalaryBatchDetailsComponent.prototype.getSalaryBatchUserEmployments = function () {
        var _this = this;
        this.dataService
            .Employments_GetEmploymentViews()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (userEmployments) {
            _this.entityContext.Employees = userEmployments;
            _this.setSalaryBatchEmployeeGridDataSource();
        });
    };
    SalaryBatchDetailsComponent.prototype.onRecalculateSingleEmployeeDone = function () {
        var _this = this;
        if (this.salaryBatch && this.recalculateSingleUserEmploymentId) {
            this.dataService
                .SalaryBatches_RecalculateSalaryBatchSingleEmployment(this.salaryBatch.Id, this.recalculateSingleUserEmploymentId)
                .subscribe(function (salaryBatch) {
                _this.reloadSalaryBatches.emit();
                _this.closeDialog.emit();
            });
        }
    };
    SalaryBatchDetailsComponent.prototype.getChildWindowCycleId = function () {
        var _this = this;
        var cycleId;
        var dataItem = this.salaryCycles.find(function (cycle) { return cycle.Id === _this.entityContext.CycleId; });
        if (!dataItem) {
            cycleId = this.entityContext.CycleId =
                this.salaryCycles && this.salaryCycles.length > 0 ? this.salaryCycles[0].Id : undefined;
        }
        else {
            cycleId = dataItem.Id;
        }
        return cycleId;
    };
    SalaryBatchDetailsComponent.prototype.getSalaryBatchesPeriods = function (cycleId) {
        var _this = this;
        this.dataService
            .SalaryBatches_GetSalaryPeriods(cycleId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (salaryPeriods) {
            if (salaryPeriods) {
                if (salaryPeriods.length > 0) {
                    _this.loadingPeriods = false;
                    var defaultPeriod = salaryPeriods[0];
                    var dataSource_1 = [];
                    var isUserFriendlyname_1 = _this.companypreference
                        ? _this.companypreference.some(function (p) { return p.Key === 'SalaryPeriods.FriendlyNames' && p.Value === 'true'; })
                        : false;
                    salaryPeriods = salaryPeriods.sort(function (data1, data2) {
                        return GridHelper.sortByDateValue(data1.StartDate, data2.StartDate);
                    });
                    if (salaryPeriods) {
                        salaryPeriods.forEach(function (salaryPeriod, key) {
                            var startDate = _this.sessionService.toString(salaryPeriod.StartDate ? new Date(salaryPeriod.StartDate) : undefined);
                            var endDate = _this.sessionService.toString(salaryPeriod.EndDate ? new Date(salaryPeriod.EndDate) : undefined);
                            var extendSalaryPeriod = new ExtendSalaryPeriod();
                            extendSalaryPeriod.Id = salaryPeriod.Id;
                            extendSalaryPeriod.StartDate = salaryPeriod.StartDate;
                            extendSalaryPeriod.EndDate = salaryPeriod.EndDate;
                            extendSalaryPeriod.SuggestedPayoutDate = salaryPeriod.SuggestedPayoutDate;
                            extendSalaryPeriod.Period = startDate + ' - ' + endDate;
                            if (isUserFriendlyname_1) {
                                extendSalaryPeriod.FriendlyName = ' ' + salaryPeriod.FriendlyName;
                            }
                            else {
                                extendSalaryPeriod.FriendlyName = startDate + ' - ' + endDate;
                            }
                            dataSource_1[key] = extendSalaryPeriod;
                        });
                    }
                    _this.entityContext.Periods = dataSource_1;
                    if (_this.entityContext && _this.entityContext.BatchId && _this.entityContext.BatchId < 0) {
                        _this.entityContext.PeriodId = defaultPeriod.Id;
                    }
                    if (_this.entityContext.BatchId === undefined || _this.entityContext.BatchId < 1) {
                        _this.getSalaryBatchesPeriodsSuggest(_this.entityContext.CycleId);
                    }
                }
                else {
                    _this.entityContext.Periods = [];
                    _this.entityContext.PaymentDate = undefined;
                }
            }
        });
    };
    SalaryBatchDetailsComponent.prototype.getSalaryBatchesPeriodsSuggest = function (cycleId) {
        var _this = this;
        var batchId = this.entityContext ? this.entityContext.BatchId : undefined;
        this.dataService.SalaryBatches_SuggestSalaryPeriod(cycleId).subscribe(function (salaryPeriod) {
            if (cycleId === _this.entityContext.CycleId && batchId === _this.entityContext.BatchId) {
                _this.entityContext.PeriodId = salaryPeriod.Id;
                _this.entityContext.PaymentDate = salaryPeriod.SuggestedPayoutDate
                    ? salaryPeriod.SuggestedPayoutDate
                    : salaryPeriod.EndDate;
            }
        });
    };
    SalaryBatchDetailsComponent.prototype.checkPeriod = function () {
        var _this = this;
        if (this.loadingPeriods) {
            return;
        }
        var periodItem = this.entityContext.Periods
            ? this.entityContext.Periods.find(function (period) { return period.Id === _this.entityContext.PeriodId; })
            : undefined;
        if (!periodItem) {
            this.entityContext.PeriodId =
                this.entityContext.Periods.length > 0 ? this.entityContext.Periods[0].Id : undefined;
        }
        var selectedSalaryBatch = this.salaryBatch;
        if (selectedSalaryBatch && this.entityContext.PeriodId === selectedSalaryBatch.SalaryPeriodId) {
            this.entityContext.HasBatch = false;
        }
        else {
            if (this.entityContext.PeriodId) {
                this.dataService
                    .SalaryBatches_SalaryPeriodHasBatches(this.entityContext.PeriodId)
                    .subscribe(function (hasBatch) { return (_this.entityContext.HasBatch = hasBatch); });
            }
        }
    };
    SalaryBatchDetailsComponent.prototype.setPaymentDateDueToPeriodChange = function () {
        var _this = this;
        var changedID = this.entityContext.PeriodId;
        var periods = this.entityContext.Periods;
        if (periods) {
            periods.forEach(function (salaryPeriod, key) {
                if (periods[key].Id === changedID) {
                    _this.entityContext.PaymentDate = periods[key].SuggestedPayoutDate;
                }
            });
            if (this.responsePaymentDate) {
                this.entityContext.PaymentDate = this.responsePaymentDate;
                this.responsePaymentDate = undefined;
            }
        }
    };
    SalaryBatchDetailsComponent.prototype.setSalaryBatchEmployeeGridDataSource = function () {
        if (this.entityContext.Employees) {
            var cycleId_1 = this.getChildWindowCycleId();
            var dataSource = [];
            if (cycleId_1) {
                dataSource = this.entityContext.Employees.filter(function (e) { return e.SalaryCycleId === cycleId_1 && e.IsActive; });
            }
            else {
                dataSource = this.entityContext.Employees.filter(function (e) { return e.IsActive; });
            }
            dataSource.forEach(function (e) {
                if (e.IsTerminated) {
                    e.FullName = '(' + e.FullName + ')';
                    e.IsChecked = false;
                }
                else {
                    e.IsChecked = true;
                }
            });
            dataSource = orderBy(dataSource, [{ field: 'IsTerminated', dir: 'asc' }, { field: 'FullName', dir: 'asc' }]);
            this.entityContext.IsCheckedAllEmployees = true;
            this.selectEmployeesGridData = dataSource;
        }
        else {
            this.selectEmployeesGridData = [];
        }
    };
    SalaryBatchDetailsComponent.prototype.decodeEntities = function (encodedString) {
        var translatere = /&(nbsp|amp|quot|lt|gt);/g;
        var translate = {
            nbsp: ' ',
            amp: '&',
            quot: '"',
            lt: '<',
            gt: '>'
        };
        return encodedString
            .replace(translatere, function (match, entity) {
            return translate[entity];
        })
            .replace(/&#(\d+);/gi, function (match, numStr) {
            var num = parseInt(numStr, 10);
            return String.fromCharCode(num);
        });
    };
    return SalaryBatchDetailsComponent;
}());
export { SalaryBatchDetailsComponent };
