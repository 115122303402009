import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormComponentBase } from '../../../Common/FormComponentBase';
import { ICompanyUser, IUserEmployment } from '../../../Services/ApiModel';
import { SessionService } from '../../../Services/SharedServices/Session/SessionService';
import { SettingService } from '../../../Services/SharedServices/SettingService';
import { Position } from './Position';

@Component({
  selector: 'position-info',
  templateUrl: './PositionInfo.component.html'
})
export class PositionInfoComponent extends FormComponentBase {
  @Output() public positionTextChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() public employmentSelectionChanged: EventEmitter<IUserEmployment> = new EventEmitter<IUserEmployment>();
  @Output() public companyUserChange: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();
  @Output() public createUserEmploymentClick: EventEmitter<void> = new EventEmitter<void>();

  @Input() public isEmployeeTab = false;
  @Input()
  public get companyUser(): ICompanyUser {
    return this.companyUserValue;
  }
  public set companyUser(value: ICompanyUser) {
    this.companyUserValue = value;
    this.companyUserChange.emit(value);

    if (this.hasNoEmployment) {
      this.userEmployments = [];
      this.userEmploymentDataSource = [];
    } else {
      this.userEmployments = this.companyUserValue.UserEmployments;
      const titles: Position[] = [];
      if (this.companyUserValue.UserEmployments) {
        this.companyUserValue.UserEmployments.forEach((item: IUserEmployment): void => {
          if (!item) {
            return;
          }

          const position: Position = this.createPosition(item);
          titles.push(position);
        });
      }

      this.userEmploymentDataSource = titles;
    }

    this.selectDefaultPosition();
  }

  @Input()
  public get currentPositionId(): number {
    return this.currentPositionIdValue;
  }
  public set currentPositionId(value: number) {
    this.setcurrentUserEmploymentValue(value);
  }

  @Input()
  public get newEmploymentAdded(): IUserEmployment {
    return this.newEmployment;
  }
  public set newEmploymentAdded(value: IUserEmployment) {
    if (value) {
      const existingEmployment: IUserEmployment = this.companyUserValue.UserEmployments.find(
        (employment: IUserEmployment) => employment.Id === value.Id
      );
      if (!existingEmployment) {
        // Update datasource
        this.companyUserValue.UserEmployments.push(value);
        const oldPositions: Position[] = this.positions.slice();
        const newPosition: Position = this.createPosition(value);
        oldPositions.push(newPosition);

        this.userEmploymentDataSource = [];
        this.userEmploymentDataSource = oldPositions;
      } else {
        const existingPosition: Position = this.userEmploymentDataSource.find(
          (employment: Position) => employment.Id === value.Id
        );
        this.companyUserValue.UserEmployments.splice(
          this.companyUserValue.UserEmployments.indexOf(existingEmployment),
          1,
          value
        );
        this.userEmploymentDataSource.splice(
          this.userEmploymentDataSource.indexOf(existingPosition),
          1,
          this.createPosition(value)
        );
      }

      this.newEmployment = value;
      this.selectDefaultPosition();
    }
  }

  @Input()
  public get deletedEmployment(): IUserEmployment {
    return this.deletedUserEmployment;
  }
  public set deletedEmployment(value: IUserEmployment) {
    if (value) {
      // Update datasource
      const deletedIndex: number = this.positions.findIndex((item: Position): boolean => item.Id === value.Id);
      if (deletedIndex !== -1) {
        const oldPositions: Position[] = this.positions.slice();
        oldPositions.splice(deletedIndex, 1);
        this.userEmploymentDataSource = oldPositions;
        this.setcurrentUserEmploymentValue();
      }
    }

    this.deletedUserEmployment = value;
  }

  public get hasMultipleEmployments(): boolean {
    return this.companyUser && this.userEmploymentDataSource && this.userEmploymentDataSource.length > 1;
  }

  public get hasSingleEmployment(): boolean {
    return this.companyUser && this.userEmploymentDataSource && this.userEmploymentDataSource.length === 1;
  }

  public get hasNoEmployment(): boolean {
    return !this.companyUser || !this.companyUser.UserEmployments || this.companyUser.UserEmployments.length === 0;
  }

  public get allowEditTitle(): boolean {
    return this.editMode && !this.hasNoEmployment;
  }

  public get userEmploymentDataSource(): Position[] {
    return this.positions;
  }

  public set userEmploymentDataSource(value: Position[]) {
    if (this.positions !== value) {
      this.positions = value;
    }
  }

  public get isTabSelfService(): boolean {
    return this.sessionService.currentState.includes('tabs.selfservice');
  }

  private companyUserValue: ICompanyUser;
  private userEmployments: IUserEmployment[];
  private positions: Position[];
  private currentPositionIdValue: number;
  public currentUserEmployment: IUserEmployment;
  private newEmployment: IUserEmployment;
  private deletedUserEmployment: IUserEmployment;

  constructor(private settingService: SettingService, private sessionService: SessionService) {
    super();
    this.childIsValid = Array(2).fill(true);
    this.childIsDirty = Array(2).fill(false);
  }

  private createPosition(employment: IUserEmployment): Position {
    const item: Position = new Position();
    item.DisplayTitle = employment.Title;
    const expireDate: Date = employment.ExpireDate ? new Date(employment.ExpireDate) : undefined;
    const today: Date = new Date();
    if (expireDate && expireDate < today) {
      item.DisplayTitle = '(' + employment.Title + ')';
    }

    item.Id = employment.Id;
    return item;
  }

  private selectDefaultPosition(): void {
    let currentEmployment: IUserEmployment;
    if (this.companyUser && this.companyUser.UserEmployments && this.companyUser.UserEmployments.length > 1) {
      currentEmployment = this.companyUserValue.UserEmployments.find(
        (item: IUserEmployment): boolean => item.Id === this.currentPositionId
      );
    }

    const employmentId: number = this.newEmployment
      ? this.newEmployment.Id
      : currentEmployment
      ? currentEmployment.Id
      : undefined;
    this.newEmployment = undefined;
    this.setcurrentUserEmploymentValue(employmentId);
    if (!employmentId) {
      this.onPositionChangedEvent();
    }
  }

  private setcurrentUserEmploymentValue(value?: number): void {
    setTimeout(() => {
      if (!value) {
        if (!this.positions || this.positions.length === 0) {
          this.currentPositionIdValue = undefined;
          this.currentUserEmployment = undefined;
        } else {
          if (this.settingService && this.settingService.stayEmploymentId) {
            this.currentPositionIdValue = this.settingService.stayEmploymentId;
            this.settingService.stayEmploymentId = undefined;
          } else {
            this.currentPositionIdValue = this.positions[0].Id;
          }
          this.currentUserEmployment = this.companyUserValue.UserEmployments.find(
            (item: IUserEmployment): boolean => item.Id === this.currentPositionIdValue
          );
        }
        return;
      }

      this.currentPositionIdValue = value;
      this.currentUserEmployment = this.companyUserValue.UserEmployments.find(
        (item: IUserEmployment): boolean => item.Id === this.currentPositionIdValue
      );
    });
  }

  public onPositionChangedEvent(): void {
    setTimeout(() => {
      this.employmentSelectionChanged.emit(this.currentUserEmployment);
    });
  }

  public onPositionTextInputChange(): void {
    this.onChange();
    if (this.isDirty) {
      this.positionTextChanged.emit(this.currentUserEmployment.Title);
    }
  }

  public onAddonClick(): void {
    this.createUserEmploymentClick.emit();
  }
}
